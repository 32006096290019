import React, { Component } from 'react';
import config from '../../shared/config';
import { withRouter } from 'react-router-dom';
import Forms from './Forms';
import { connect } from 'react-redux';
import { getMetaData } from '../../actions/metaAction';
import { getSectorData } from '../../actions/businessAction';
import store from '../../store';
import Helper from '../../shared/custom';
import { type } from 'os';

class Cards extends Component {

  constructor(props){
      super(props)
      this.state = {
            business:{},
            formActive:{},
            types : []
 
      };
      this.switchForm = this.switchForm.bind(this);
      store.subscribe(() => {

        var storeState = store.getState();
        var localMetaList = this.state.metaList;
        var storeMetaList = storeState.meta.metaList; 
        
        if(!localMetaList || JSON.stringify(localMetaList) != JSON.stringify(storeMetaList)){
            this.setState({metaList:storeMetaList});
        }

        var localBusiness = this.state.business;
        var storeBusiness = storeState.business.data;
        if(!localBusiness || JSON.stringify(localBusiness) != JSON.stringify(storeBusiness)){
            this.setState({business:storeBusiness});
        }

    });
  //    this.showPublicForm = this.showPublicForm.bind(this);
  //    this.showPrivateForm = this.showPrivateForm.bind(this);
   
  }

  componentDidMount() {
    var storeState = store.getState();      
    let meta = storeState.meta;
    let business = storeState.business;
    
    if(Helper.isEmpty(business.data) == true){            
        this.props.getSectorData();
    }else{
        this.setState({business:business.data},
          function(){
          }
          );
    }
   
    if(Helper.isEmpty(meta.metaList) == true){
        this.props.getMetaData();
    }else{
        this.setState({metaList:meta.metaList});
    }

    this.getEventsTypes();
  }

  getEventsTypes(){    
        let types  = [{
            'id':'PUBLIC',
            'name':'Public Event',
            'image':Helper.dataPath(config.defaultPublicEventIcon),
            'description':'Anyone on Investera can see this event and search for it.'
          },
          {
            'id':'PRIVATE',
            'name':'Private Event',
            'image':Helper.dataPath(config.defaultPrivateEventIcon),
            'description':'Only invited guests will see this event. The host can choose to let guests invite other people'
          }
                    ];
        this.setState({types:types}); 
  }

  switchForm(e){
    e.preventDefault();
    var formActive = this.state.formActive;
    formActive[e.target.name] = true;
    this.setState({formActive:formActive});
  }

  render() {
    let types = this.state.types;
    let formActive = this.state.formActive;
    let metaList = this.state.metaList;
    let business = this.state.business;
    let industryList = business?business.industriesArray:[];
   
    
    return (
      <div className="row full-width bold-headers">
        <div className="col-md-12 text-left m-t-20 m-b-20"> 
          <div className="col-md-12">
            <h1>Create an Event</h1>
            <h5 className="font-normal">Adding your Event on Investera+ allows you to promote it to all our users. Start off by letting us know if this is a private or public event.</h5>
          </div>     
       </div>
       
         <div className="col-md-12">
                {
                    types.map((type, index) => (
                        <div className="col-md-6 float-left create-event" key={index}>
                            <div className="card text-center">
                                {!formActive[type.id] ? 
                                    <div className="card-body opp-card">
                                        <h2> <img src={type.image} className="rounded-img img-circle" width="150"/></h2>
                                        <h4 className="card-title">{type.name}</h4>
                                        <p className="card-text">{type.description}</p>
                                        <button className="btn btn-theme-white m-t-80" onClick={this.switchForm} name={type.id} >Get Started</button>
                                    </div>
                                    :
                                    <div>
                                        <Forms title={type.name} desc={type.description} id={type.id} industryList={industryList}  />
                                    </div>
                                }                                
                            </div>
                        </div>
                    ))
                }
        </div>
      </div>
    )
  }
}

export default connect(null,{ getMetaData , getSectorData})(withRouter(Cards));