import React, { Component } from 'react';
import Helper from '../../shared/custom';
import config from '../../shared/config';
import PopoverElm from '../ui/Popover';
export default class MiniCard extends Component {

  constructor(props){
    super(props);
    this.getContent = this.getContent.bind(this);
  }

  getContent(type){
    let value = this.props.value;
    let show = this.props.show;
  
    switch(type){
      case "boolean":
        return value ? (<span className="float-right text-right font-12 font-bold text-green text-no-wrap">Yes</span>) : (
          value == false ? <span className="float-right text-right font-12 font-bold text-danger">No</span> : <span className="float-right text-right font-12 font-bold text-danger">-</span>
        )
      case "array" :
        let newValue = Array.isArray(value) ? value : [value];
        return <span className="float-right mini-arraycard text-default text-medium font-12">{newValue.join(", ")}</span>;
      case "left" :
        return <span className="float-left text-left font-12 m-t-5 font-medium">{value}</span>
      case "link" :
         return  show ? <a href={config.dataCdn+value} className="float-left text-left font-12 m-t-5 font-medium text-blue" >{Helper.lastSegment(value)}</a> : <a className="float-left text-left font-12 m-t-5 font-medium text-blue" >{Helper.lastSegment(value)}</a>
      case "email": 
         return   <a  href={"mailto:"+value} className="float-right text-right font-12 m-t-5 font-medium text-blue" >{value}</a>
      case "phone": 
         return   <a  href={"tel:"+value} className="float-right text-right font-12 m-t-5 font-medium text-blue" >{value}</a>
     case "canHide":
         return show ? <span className="float-left text-left font-12 font-medium">{value}</span>  : <span className="float-left bluredText text-left font-12 font-medium">{"XXXXXXXX"}</span>
      default :
        return <span className="float-right text-right font-12 font-medium">{value}</span>
    }
  }
    
  render() {
    let label = this.props.label;
    let value = this.props.value;
    let type = this.props.type ?this.props.type :""
    let view = this.props.view ?this.props.view :""
    let pophover = this.props.pophover ? this.props.pophover : false;
    return (
        <div className="col-md-12 col-sm-12 p-1 float-left grid-item">
            <div className="col-12 mini-card float-left">
                <div className="font-bold float-left font-12">{label} {pophover &&  <PopoverElm target={this.props.target}>This information accessible only for page admins and page owners</PopoverElm>}</div>
                <div class={view == "full" ? "col-md-12 col-sm-12 displayInlinedBlock p-0" : "col-md-12 col-sm-12 displayInlinedBlock p-r-0"}>
                {this.getContent(type)}
                </div>
            </div>
        </div>
    )
  }
}
