const scheme = process.env.REACT_APP_SCHEME; //"https://";
const gatewayHost = process.env.REACT_APP_GATEWAY_HOST; //"gw.investera.com:9091";
const host = process.env.REACT_APP_HOST; //"portal.investera.com";
const africahost = process.env.REACT_APP_HOST_AFRICA; 
const dataCdn = scheme + process.env.REACT_APP_DATA_CDN; //"local-cdn.investera.com/";
const captchaSiteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;
const linkedInClientId=process.env.REACT_APP_LINKEDIN_CLIENT_ID;
const kgUrl = process.env.REACT_APP_KG_URL;
const admin = process.env.ADMIN_URL;


//"http://a969d5cb370264294833019e630c5623-466814422.af-south-1.elb.amazonaws.com/api/kg"
//const scheme = "http://"
//const host = "localhost:3001";
//const gatewayHost = "localhost:9091";
//const dataCdn = scheme + "192.168.2.69/investeraCdn/";


const gatewayPath = scheme + gatewayHost + "/"; //end slash required
const siteUrl = scheme + host + "/"; //end slash is required
const gOuth = gatewayPath+"oauth2/authorize/google?redirect_uri=";

console.log(admin,"admin")


module.exports = {

  gatewayPath: gatewayPath,
  api: url => {
    return gatewayPath + url;
  },
  siteUrl: siteUrl,
  host:host,
  cdn: scheme + host + "/cdn/", // for app assets. end slash required
  dataCdn: dataCdn, // for image data and entity files, end slash is required
  dataCdnStatic: dataCdn+"static/",
  summaryLimit: 255,
  captchaKey: captchaSiteKey,
  defaultUser: { name: "-", email: null, image: "default/user.png" },
  kgUrl:kgUrl,
  adminUrl:admin,
  paymentGateway:"https://demo-ipg.comtrust.ae/PaymentEx/MerchantPay/Payment?lang=en&layout=C0STCBVLEI",
  reportUrlSample:"http://plus-cdn.investera.com/pdf/2019-MENA-Venture-Investment-Report.pdf",
  reportUrlFull:"http://plus-cdn.investera.com/pdf/2019-MENA-Venture-Investment-Report-sr9wtEX1pWbxn.pdf",
  reportFolder:"http://plus-cdn.investera.com/pdf/",
  defaultUserImage: "default/user.png",
  defaultCompanyImage: "default/company.png",
  defaultNewsIcon: "default/company.png",
  defaultCoverPhoto: "default/event-banner.png",
  defaultOpportunityIcon: "default/opportunity.png",
  defaultOpportunityCover: "default/opportunity-banner.png",
  // defaultNotifyIcon:"",
  defaultEventIcon: "default/event.png",
  defaultEventCover: "default/event-banner.png",
  defaultPrivateEventIcon: "default/event-private.png",
  defaultPublicEventIcon: "default/event-public.png",
  companyTypeIcon: { INVESTOR: "default/dollar_sign.svg" },
  oppDirectPayment:false,
  displayDateFormat: "dd-MMM-yyy",
  inputDateFormat: "DD-MMM-YYYY",
  inputDateTimeFormat: "DD-MMM-YYYY HH:mm",
  displayDateTimeFormat: "dddd, DD MMMM YYYY [at] HH:mm",
  defaultWaitTime: 3000,
  defaultPageSize: 6,
  defaultNotificationsPageSize: 50,//change to 50 after deploying the payment branch
  defaultLoadMoreSize: 2,
  defaultCarouselLength: 100,
  defaultGuestListingLength: 20,
  graphGeoScope:"GCC",
  defaultDescMaxLength: 150,
  pageStartLabel: "iDisplayStart", //to be replaced with the paginationQS
  pageLengthLabel: "iDisplayLength", //to be replaced with the paginationQSs
  paginationQS: params => {
    let offset = (params.page - 1) * params.length;
    return {
      iDisplayStart: offset,
      iDisplayLength: params.length,
      iSortCol_0: 1,
      sSortDir_0: "desc"
    };
  },
  pageRangeDisplayed: 5,
  thumbnailFetchingEndpoint: "https://api.linkpreview.net",
  thumbnailFetchingKey: "5c6579fee6cf2fae504c7f3bdce5b17da7664b3efeded",
  linkedInClientId: linkedInClientId,
  linkedInClientSecret: "4mQBvurxUJ9BrqNb",
  linkedInRedirect: siteUrl + "linkedin",

  //Define the possible ownership types for each company:
  //using shortcodes of the metadata objects.
  //Shows all ownership types if no company type index is defined
  companiesOwnershipById : {
            "private":new Array("seed-funding","series-a"),
            "public":new Array("seed-funding","series-a"),
            "startup":new Array("seed-funding","series-a")
          },
  
  startupDescription: "A young company that is just beginning to develop.",
  startupIcon: "default/company-startup.png",
  startupString: "Startup",
  privateString: "Private Company",
  companyPageTypeId: 1,
  publicCompanyMetaId: 15,
  startupPageSubTypeId: 5,
  privateCompanyMetaId: 14,
  ownershipTypes:{
    private:[1,2,4,5,10835,127,158,437],
    public: [10835,2,3],
    startup:[158,127,437]
  },
  facebookAppId: "529686990849411",
  gOuthUrl:gOuth+siteUrl,
  sessionTimeOut: 3540,
  sesstionCheck: 2628000000000,
  companyTypes :  [
    { 
      name :"Public",
      type:"public",
      id:15,
    },
    {
      name :"Private",
      type:"private",
      id:14,
    },
    {
      name :"Startup",
      type:"startup",
      id:14,
   }
  ],
  // companyCatagories:  [
  //   { 
  //     label :"Company",
  //     value:"company",
  //   },
  //   {
  //     label :"Start-up",
  //     value:"start_up",
  //   },
  //   {
  //     label :"Small Business",
  //     value:"small_business",
  //  }
  // ],
  companyCatagories:[                   
    {
      value:"COMPANY",
      label:"COMPANY"
    },
    {
      value:"START_UP",
      label:"STARTUP"
    },
    {
      value:"SMALL_BUSINESS",
      label:"SMALL BUSINESS"
    },{
      value:"INTERNATIONAL_COMPANY",
      label:"INTERNATIONAL COMPANY"
    }
  ],
  basic:20,
  premium:50,
  basic_pages:500,
  premium_pages:1000,
  totalRating:5,
  ratings:[{
    label:"+",
    value:1,
    color:"#3d8656"
  },{
    label:"+ +",
    value:2,
    color:"#6A7FB1"
  },{
    label:"+ + +",
    value:3,
    color:"#d07c29"
  },{
    label:"+ + + +",
    value:4,
    color:"#ffcd00"
  },{
    label:"+ + + + +",
    value:5,
    color:"#004f95"
  }],
  rankingNames:{
    GR_ACQUISITIONS:"ACQUISITIONS",
    GR_BASIC_INFORMATION:"BASIC INFORMATION",
    GR_FINANCIALS:"FINANCIALS",
    GR_FUNDING:"FUNDING",
    GR_INVESTMENTS:"INVESTMENTS",
    GR_INVESTORS:"INVESTORS",
    GR_NEWS:"NEWS",
    GR_STARTUP_DETAILS:"STARTUP DETAILS",
  },
  tinggCdnUrl:"https://developer.tingg.africa/logos/med96/",
  opportunityTypes:[{
    value:"INVESTMENT",
    label:"Investment",
    permission:"OPP_INV",
  },
  {
    value:"PARTNERSHIP",
    label:"Partnership",
    permission:"OPP_PRTNRSHP",

  },
  {
    value:"JOB",
    label:"Job",
    permission:"OPP_JOB",
  },
  {
    value:"MKT_BUYER",
    label:"Market Buyer",
    permission:"OPP_MKT_BUY",
  },
  {
    value:"MKT_SELLER",
    label:"Market Seller",
    permission:"OPP_MKT_SEL",
  },
  {
    value:"TENDER",
    label:"Tender",
    permission:"OPP_TNDR",
  }],
  timeline:[
    {
      value:"DAYS",
      label:"Days"
    },
    {
      value:"MONTHS",
      label:"Months"
    },
    {
      value:"YEARS",
      label:"Years"
    }
  ]
}
