import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link , withRouter, Switch} from 'react-router-dom';
import CompanySummaryMenu from './CompanySummaryMenu';
import Helpers from '../../shared/custom';
import { login } from '../../actions/authAction';
import { connect } from 'react-redux';
import store from '../../store';
import { getPackageDetails } from '../../actions/packageAction';

class SidemenuCompanies extends Component {
   constructor(props){
        super(props)
        this.state = {            
            isLoggedIn:false,
        }
        this.getLocationId = this.getLocationId.bind(this);
        
    } 


    componentDidMount(){

        var storeState = store.getState();  
        this.setState({
        isLoggedIn: storeState.auth.isAuthenticated
        },()=>{
           
        }) 
    
    } 

    componentWillReceiveProps(props){
        
    }

    getLocationId(locationIds){
        this.props.sendLocationToParent(locationIds);
    }

  render() {

    let absolutedMenu = "abs-sidebar";
    let sideMenuBg = "sideMenuBg";
    return (
        <Router>
            <div className={"left-sidebar sticky-nav nav lefted "+sideMenuBg}> 
                <div className="scroll-sidebar ">
                    <nav className={"sidebar-nav "+sideMenuBg}>
                    <div className={"left-aside "+absolutedMenu+" "+sideMenuBg}>
                            <div className={sideMenuBg}>
                                <CompanySummaryMenu isLoggedIn={this.state.isLoggedIn} getLocationIdFunc={this.getLocationId}/>
                                <br className="clearFix" />
                            </div>                         
                        </div>
                    </nav>
                </div>
            </div> 
        </Router>  
    )
    
  }
}

// const mapStateToProps = (state) =>({
//     auth:state.auth,
//     packageDetails:state.packageDetails,
//     errors:state.errors
// });

//export default connect(mapStateToProps,{getPackageDetails,login})(withRouter(SidemenuCompanies));
export default withRouter(SidemenuCompanies);
