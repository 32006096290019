import React, {useState,useEffect} from 'react';
import Axios from 'axios';
import Helper from "../../shared/custom";
import Slider from "react-slick";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import config from '../../shared/config';
import Follow from '../uiActions/Follow';

function SimularPeople(props) {
   
    const [uri, seturi] = useState(props.uri);
    const [data, setData] = useState([]);

    useEffect(() => {
        Axios.get(
            Helper.apiURL({
                uri:`ws/similar/people/${props.uri}`,
            })
        )
        .then( (response) => {           
            let data = response.data && response.data.result ? response.data.result : [];
            setData(data)
          })
        .catch((err) => {
        });
        return () => {
           // cleanup
        }
    }, [uri])


    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: (data.length  > 1 || !Helper.isMobile()) ? 2 : 1,
        slidesToScroll: (data.length  > 1 || !Helper.isMobile())  ? 2 : 1,
      };


    return (
        <div id="similarPeople">
            <div className="card text-left b-header">
                <div className="card-header text-left light-blue-header text-white">
                    Similar People
                </div>
                <div className="card-body companies blue-border">
                  

                    {!Helper.isEmpty(data) ? 
                        <Slider {...settings}>
                            {
                                data.map((list,i)=>(
                                    <div key={i} className="companyCard peopleCar">
                                        <div className="col-md-12 peopleCarB">
                                            <div className="m-t-20 topDiv text-left">
                                                    <Link to={"/people/"+list.page.uri} className="col-12 p-0">
                                                        <div className="roundImgCont"><img src={list.page && list.page.image ? Helper.dataPath(list.page.image) : Helper.dataPath(config.defaultCompanyImage)} className="float-left col-md-4 p-0" /></div>
                                                        <div className="col-md-8 float-left  p-0 text-left m-l-5">
                                                            <h5 className="text-info font-14 m-0 m-t-5"><span className="text-blue">{Helper.truncate(list.page.name,15)}</span></h5>
                                                            <p className="font-12 p-0 text-info font-normal m-b-0">{list.page.banner && list.page.banner.designation ? list.page.banner.designation+' at ':""} {list && list.page.banner &&  list.page.banner.uri ? <Link to={'/company/'+list.page.banner.uri}>{list.page.banner.company}</Link> : list && list.page.banner && list.page.banner.company ? list.page.banner.company : "-"}</p>
                                                            <p className="font-12 p-0 text-grey m-t-0  m-b-0">{list && list.page && list.page.city  ? list.page.city.name+', ' : ""} {list && list.page && list.page.country  ? list.page.country.name : ""}</p>
                                                        </div>
                                                    </Link>
                                            </div> 
                                            <div className="clearfix"></div>
                                            <div className="col-md-12 p-0 m-t-10 desc descDiv">
                                                <p className="text-left font-12 font-medium">
                                                    {list && list.people && list.people.summary ? Helper.truncate(list.people.summary,config.defaultDescMaxLength) : "-" }
                                                </p>
                                            </div>
                                            <div className="col-md-12 m-t-10 font-14 m-t-20 p-0 clearfix m-b-10">
                                                <Follow uri={list.page && list.page.uri ?list.page.uri:""} masterClassName={"like-comm btn-lil text-left float-right m-l-5"} type={"COMPANY"} />
                                            </div>
                                            </div>
                                    </div>      
                            ))
                            }
                        </Slider> 
                        :
                        <div className="text-center">No Data Found</div>
                        }
                    </div>
            </div>
        </div>
    )
}

export default SimularPeople
