import React, { Component } from 'react'

export default class PaymentMenu extends Component {
  render() {
    return (
      <div>
        <ul className="nav nav-pills nav-side nav-stacked list-style-none components profileMenu">
            <li>
                <a className="nav-link" href="#currentPlan"> 
                    Current Plan
                </a>
            </li>
            <li>
                 <a className="nav-link" href="#paymentHistory"> 
                    Payment History    
                </a>
            </li>
            
            </ul>
      </div>
    )
  }
}
