import React, { Component } from 'react';
import Helper from "../../shared/custom";
import store from '../../store';
import { login, fetchUserInfo, logoutUser  } from '../../actions/authAction';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Membership from "../new_home/Membership";
import VerifyEmail from '../../components/auth/VerifyEmail';

class Subscription extends Component {

  constructor(props){
    super(props);
    this.state={
        loading:false,
        isLoggedIn:false,
        authed:false,
        localProps:{},
        source:"subscribe",
        isEmailConfirmed:true,
    }
    this.onClickPlan = this.onClickPlan.bind(this);
    this.buyNow =  this.buyNow.bind(this);
    
  }

  componentDidMount(){
    window.scrollTo(0,0);    
    let source = Helper.lastSegment(this.props.location.pathname);    
    var storeState = store.getState();
    var auth = storeState.auth;
    var isEmailConfirmed = auth.user.emailConfirmed;
    this.setState({ isLoggedIn: auth.isAuthenticated, source:source,isEmailConfirmed:isEmailConfirmed});


    //let sideToggler = document.querySelectorAll(".nav-toggler");
   // sideToggler[0].classList.add("hidden");
  }

  componentWillReceiveProps(props){
    if(JSON.stringify(props) != JSON.stringify(this.state.localProps)){
        this.setState({
            localProps:props,
            isLoggedIn:props.auth.isAuthenticated
        });
    }
  }


  buyNow(e){
    let type = e.currentTarget.dataset.type;
    //let id = e.currentTarget.dataset.id
    let isLoggedIn = this.state.isLoggedIn;
    if(isLoggedIn){
        this.props.history.push({
            pathname: '/proceed',
            search: '?type='+type
        });
    }else{
        this.props.history.push({pathname:'/auth',state: { from: {pathname:'/proceed?type='+type}}});
        localStorage.setItem("lastUrl","/proceed?type="+type)
    }
  }

  

  onClickPlan(e){
      let plans = document.querySelectorAll(".planCont");
      plans.forEach((elm)=>{
        elm.classList.remove("activeCont");
      });
      e.currentTarget.classList.add("activeCont");
  }

  render() {
    let loading = this.state.loading;
    let isLoggedIn = this.state.isLoggedIn;
    let source = this.state.source;
    let paymentConfig = {
        proceed: this.buyNow,
        message:'Proceed to payment?'
     };

     let isEmailConfirmed =  this.state.isEmailConfirmed;

     return (
        <React.Fragment>
          { !isEmailConfirmed && isLoggedIn ? 
            <VerifyEmail/>
            :
            <div className="p-t-80 bg-white">
              <Membership mode="main" buyNow={this.buyNow}/>
            </div>
           
          }
       
        </React.Fragment>
    )
  }
}

const mapStateToProps = (state) =>({
    auth:state.auth,
    errors:state.errors
});

export default connect(mapStateToProps,{login,fetchUserInfo,logoutUser}) (withRouter (Subscription));