import React, { Component } from 'react';
import config from '../../shared/config';
import classNames from 'classnames/bind';
import Axios from 'axios';
import { BrowserRouter as Router, Route, Link ,Switch ,withRouter} from "react-router-dom";
import Helper from "../../shared/custom";
import MyPagination from "../ui/MyPagination"
import Helpers from '../../shared/custom';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import Interest from '../uiActions/Interest';
import EventCard from './EventCard';




class Upcoming extends Component {
  constructor(){
    super();
    this.state ={
      events:[],
      searchCriteria:{},
      upcomingEvents:[],
      pageOfItems: [],
      allItems: [],
      activePage: 1,
      totalRecords: 0,
      loading:false,
      itemsCountPerPage:config.defaultPageSize,
      countryIds:"",
    }
    this.handlePageChange = this.handlePageChange.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
  }

  handlePageChange(pageNumber) {
    this.setState({
      loading:true
    })
    this.setState({activePage: pageNumber}, function(){
      this.fetchEvents();
    });
  }

  componentWillReceiveProps(props){
      let locationIds="";
      let filterDates="";
      if(props && props.locationIds){
        locationIds = props.locationIds.join(",");
      }
      if(props && props.filterDates){
        filterDates = props.filterDates;
      }
      
       this.setState({searchCriteria:props.searchCriteria}, function(){
       this.state.searchCriteria.countryIds = locationIds;
       this.state.searchCriteria.startDate = filterDates.from;
       this.state.searchCriteria.endDate = filterDates.to;
       this.updateSearch();
     });
  }

  componentDidMount(){
 
    this.updateSearch();
  }

  updateSearch(){
    this.fetchEvents();
  }

  fetchEvents = () => {
    let uri = this.props.entityUri;
    if(!uri){
      this.setState({events:[]})
    }
    let qs = Helper.generatePaginationQS({"page":this.state.activePage, "length":this.state.itemsCountPerPage, "extraParams":this.state.searchCriteria ,"countryIds":this.state.countryIds});

    Axios.get(
        Helper.apiURL({
            uri:"events/upcoming",
            query:qs
        })
    )
    .then( (response) => {          
          let events = response.data.result.aaData || [];
          let totalRecords = response.data.result.iTotalRecords || 0;
          this.setState({
                  events:events,
                  totalRecords:totalRecords,
                  loading:false
                  });
       })
    .catch((err) => {
      this.setState({
        loading:false
      })
    });
}
  render() {

    return (
      <div>
          <div className="card text-left bold-headers">
                 <LoadingOverlay>
                    <Loader fullPage loading={this.state.loading}></Loader>
                 </LoadingOverlay>
                <div className="card-body">
                    <h5><b>Interested Events</b></h5>
                      <div className="row">
                        { (!Helpers.isEmpty(this.state.events) && (this.state.events.length > 0))?
                          this.state.events.map((item,index )=> (
                            <div className="col-md-6 m-t-10" key={index}>
                              <div className="border  p-1">
                                  <EventCard data={item} key={"EUS"+index}/>
                              </div>
                           </div>
                      ))
                      :
                      <div className="text-center col-12">No Upcoming Events</div>
                    }
                  </div>

                  <MyPagination
                    activePage={this.state.activePage}
                    totalRecords={this.state.totalRecords}
                    onChange={this.handlePageChange}
                  />
                 </div> 
             </div>      
        
      </div>
    )
  }
}
export default withRouter(Upcoming);

