import React, { Component } from 'react';
import Axios from 'axios';
import config from "../../shared/config";
import Helper from '../../shared/custom';
import { Link } from "react-router-dom";

class RelatedNews extends Component {
    constructor(props){
        super(props);
        this.state ={
            industries:null,
            news:[],
            entityUri:null,
            entityName:null
        }
    }
    componentDidMount(){
        var industries = this.props.industries;
        this.fetchRelatedNews(industries)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // do things with nextProps.someProp and prevState.cachedSomeProp
       
      
        if(JSON.stringify(nextProps.industries) !== JSON.stringify(prevState.industries)){
            return { industries: nextProps.industries};
        }
        return null;
      }
      
      componentDidUpdate(prevProps, prevState) {
      
         if(JSON.stringify(prevProps.industries) !== JSON.stringify(this.state.industries)){
           this.setState({
            industries: prevProps.industries,
           },()=>{
            this.fetchRelatedNews(this.state.industries)
           });
          
         }
       }

     addDefaultNewsImage(ev){
        ev.target.src = Helper.dataPath("default/news-default-image.png");
     }


    fetchRelatedNews = (industries) =>{
        var obj = {
            industryIds:industries,
        }
        
        Axios.post(
            config.api("news/search/all?limit=10"),
            obj)
        .then( (response) => {
            var news = response && response.data && response.data.result && response.data.result.feeds ? response.data.result.feeds : [];
            this.setState({
                news : news
            })
        })
        .catch((err) => {
          
        });

    }
    render() {
        let news = this.state.news;
        let newsFilterCriteria = {industries:this.state.industries.join(',')};
        let linkToFilteredNews = "/news"+Helper.jsonToQueryString(newsFilterCriteria);
        return (
            <>
            {!Helper.isEmpty(news) && 
            <div id="relatedNews">
                <div className="card text-left b-header">
                    <div className="card-header text-left light-blue-header text-white">
                            Related News
                    </div>
                    <div className="card-body companies">
                        {
                            news.map((data,i)=>(
                                <div className="col-md-12 newsDiv p-10" key={i}>
                                    <div className="col-md-6 news-img float-left">
                                        <img onError={this.addDefaultNewsImage} src={!Helper.isEmpty(data.profileImage) ? data.profileImage : Helper.dataPath(config.defaultNewsIcon)} alt="user" className="img-responsive imgNews"/>
                                    </div>
                                    <div className="col-md-6 float-left">
                                        <h2 className="text-blue font-14"><Link to={'/news/'+data.uri}>{Helper.truncate(data.name,50)}</Link></h2>
                                        <p className="font-12">{Helper.truncate(data.summary,100)}</p>
                                        <div className="font-12">
                                            <i class="material-icons font-12 text-blue v-a">language</i><a href={data.url} target="_blank"> {Helper.domainOnly(data.url)}</a> | <i class="mdi mdi-calendar"></i> {Helper.getDateInInputFormat(data.time)}
                                        </div>
                                    </div>
                                </div>
                            ))

                        }
                        <div className="m-t-10">                                
                                        <a href={linkToFilteredNews}>
                                            <span className="float-right text-blue">View all</span>
                                        </a>
                                </div>
                    </div>
                </div>
            </div>
    }
    </>
        )
    }
}
export default RelatedNews;