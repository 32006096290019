import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import ManageAdmins from '../ui/ManageAdmins';
import ManageGuests from './ManageGuests';
import Helper from '../../shared/custom';
import config from '../../shared/config';
import store from '../../store';
import Axios from "axios";
import Similar from '../wigets/Similar';
import ApiHelper from '../../shared/ApiHelper';

class Rightpanel extends Component {
    constructor(props){
        super(props);
        this.state={
            addAdmin:false,
            admins:[],
            eventDetails:null,
            lastPageState:null
        }
       
        this.fetchAdmins = this.fetchAdmins.bind(this);
        this.handleFetchAdmins = this.handleFetchAdmins.bind(this);
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            eventDetails:nextProps.eventDetails,
            lastPageState:nextProps.lastPageState
        }, function(){
            this.fetchAdmins();
        })
    }

      
    fetchAdmins(reload){
        if (Helper.isEmpty(this.state.eventDetails))
            return false; 

        if(!reload && this.state.uri && this.props.uri == this.state.uri ){
          return false;
        }
       // this.setState({loading:true});
        Axios.get(
          Helper.apiURL({ 
            uri:":type/:uri/admin/list",
            pathVar:{
              type:"event",
              uri:this.state.eventDetails.uri,
            }
          })
        )
        .then((response) => { 
          if(ApiHelper.responseSanityCheck(response)) {
                this.handleFetchAdmins(response);
          } 
        })
        .catch((response) => { 
          this.handleResponse("error",response); 
          
        });
        
      }

      handleFetchAdmins(response) {
    
        var data = response.data;    
        if(data.status == 200){
          
          let admins = data.result || [];      
       
          this.setState({
            admins:admins
          })
    
        }else{
          Helper.handleError(data);
        }
      }
    

    render() {

        let lastPageState = this.props.lastPageState;
        let eventDetails = this.props.eventDetails;  
        let editView = false;   
        let status = "";
        let textColor = "danger";
        
        let admins = [];
        let owner = null;
        let type = "event";
        let uri = null;
    
        if(eventDetails || lastPageState){
            editView = true;
            status = "In Creation";                
        }

        var showGuestsManagement = false;
        if(eventDetails){
            
            status = eventDetails.status;
            
            if(status == "APPROVED"){
                textColor = "green";
                showGuestsManagement = true;
            }

            if((eventDetails.type+"").toLocaleLowerCase() == "public" ){
              showGuestsManagement = false;
            }

            owner = eventDetails.owner;
            admins = this.state.admins;
            uri = eventDetails.uri;
        }

        return (          
        <div>
            {editView?
                <div>
                    <div className="card text-left">
                        <div className="card-body">
                        <h5 className="font-bold">Event Status</h5>
                        <h5 className={"font-bold text-"+textColor}>{status}</h5>
                        </div>
                    </div>
                    {owner && uri ? <ManageAdmins owner={owner} uri={uri} type={type} type="event" /> : "" }
                    {showGuestsManagement && owner && uri ? <ManageGuests admins={admins} owner={owner} uri={uri} type={type} type="event" /> : "" }
                           
                </div>
        
            :
            
            <Similar items="1.5" title="Similar Events" type="events" uri="events"/>
    
            }
            
        </div>
        
        )
      }
}
export default  withRouter(Rightpanel);