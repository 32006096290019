import React, { Component , useEffect, useState , useRef } from 'react';


import config from '../../shared/config';
import Helpers from '../../shared/custom';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import Axios from "axios";

import EditText from "../ui/EditText";
import EditDate from "../ui/EditDate";
import EditSelect from "../ui/EditSelect";
import EditTextArea from "../ui/EditTextArea";
import ApiHelper from "../../shared/ApiHelper";
import CompanyPs from '../wigets/CompanyPs';
import DeletePage from '../wigets/DeletePage';

let lastYear = new Date().getFullYear()+1 - 1960;
let days = Array(31).fill().map((item,index) => index+1)
let months = Array(12).fill().map((item,index) => index+1)
let years = Array(lastYear).fill().map((item,index) => 1960+index);
let totalDays = [31,28,31,30,31,30,31,31,30,31,30,31];


function SellerForm(props) {

    const [loading, setLoading] = useState(() => false);
    const [errors, setErrors] = useState(() => {});
    const [state, setState] = useState(() => {});
    const [owner, setOwner] = React.useState(false);
    

    useEffect(() => {
        if(!Helpers.isEmpty(props.data)){
          var dataset = props.data;
          setOwner(props.data.owner)
          if(dataset && dataset.timeline){
            dataset.years = {
              label:props.data.timeline.years,
              value:props.data.timeline.years
            }
            dataset.days = {
              label:props.data.timeline.days,
              value:props.data.timeline.days
            }
            dataset.months = {
              label:props.data.timeline.months,
              value:props.data.timeline.months
            }
          }
        
          setState(dataset);
        }
    },[props.data])

   const handleFieldValueChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name) {
            setState((prevState) => ({...prevState, [name]: value }));
        }
    }

    const  handleSelectChange = (option, e) => {
        let thisFieldName = e.name;
        setState((prevState) => ({...prevState, [thisFieldName]: option }));
        
    }

    const uploadDocs = (e)=>{
        let file = e.target.files ? e.target.files[0] : null;
        let {name} = e.target;

        setLoading(true)
        if (file) {
          ApiHelper.uploadFile({
            repoType: "OPP_DOCS",
            docTypeName: "OPP_JOB_ADVRT",
            fileType: "DOC",
            metaType:"OPPDOC",
            file: file,
            callback: result => {
              setState((prevState) => ({...prevState, [name]: result }));
              setLoading(false)
            },
            errCallback:err=>{
               setLoading(false)
            }
          });
        }else{
            setLoading(false)
        }
    }

    const handleEndDateValueChange = (date) =>{
        setState((prevState) => ({...prevState, targetDate: Helpers.getDateInInputFormat(date, true,config.inputDateTimeFormat)}));
    }

    const vaidateForm = () => {
        let isValid = true;
        let fields = [
            "quantities",
            "price",
            // "days",
            // "months",
            // "years",
            // "years",
            "engagementNature",
            "buyerQualification",
            "psDescription",
            "timeline",
            "timelineType"
         
        ]
    
    
        let errors = {}
    
        fields.forEach((field) => {
          if (Helpers.isEmpty(state[field])) {
            isValid = false;
            errors[field] = "This field is mandatory"
          } else {
            errors[field] = ""
          }
        })
    
        setErrors(errors);
        return isValid;
       }
    
        const saveAndProceed = ()=>{
            var formValid = vaidateForm();
            if(!formValid){
                Helpers.pushError("Please fill all the required fields.");
                return false;
            };
            setLoading(true)
            var payload = state;
    
            payload.psDescription = state.psDescription ;
            payload.buyerQualification = state.buyerQualification;
            // payload.
            // payload.timeline ={
            //   years:state.years.value,
            //   months:state.months.value,
            //   days:state.days.value
            // }
        
            Axios.put(Helpers.apiURL({ uri: "api/opportunity/" }), payload)
            .then(response => {
               setLoading(false)
                Helpers.pushSuccess("Opportunity Details has been updated!");
                let data = response.data;
                let id = data.id ? data.id : null;
                let paid = payload && payload.page.paid;
                let isPublished = payload && payload.page.regStatus && payload.page.regStatus.value == "PUBLISHED";
                if (id) {
                  if(config.oppDirectPayment){
                    window.location = paid ? `/opportunity/${payload.id}?isPublished=${isPublished}`:`/opportunity/proceed?targetEntityId=${payload.id}&paymentCategory=OPPORTUNITY`;
                  }else{
                    window.location = `/opportunity/${payload.id}?isPublished=${isPublished}`
                  }
                }
             
      
            })
            .catch(err => {
              setLoading(false)
              Helpers.pushError(err.response.data || err.response.data.message)
            });
    
        }
    
        const  handleObjectChange = (e) => {
            let psl = e;
            let existingPsl = state.psl;
            existingPsl = psl.map((item) => {
              let localPsl = existingPsl ? existingPsl.filter((x) => item.name ? item.name == x.name : item == x.name) : [];
              if (localPsl.length == 0) {
                let res = item.customOption ? {
                  id: null,
                  name: item.name,
                }
                  : item;
                return res;
              } else {
        
                return localPsl[0]
        
              }
            })
        
            state["psl"] = existingPsl
            setState(state);
          }

          const deleteDoc = (e)=>{
            e.stopPropagation();
            var name = e.currentTarget.dataset && e.currentTarget.dataset.name;
            if(name){
              setState((prevState) => ({...prevState, [name]: {} }));
            }
          }

        

 
    return (
        <div className="row full-width bold-headers">
            <LoadingOverlay>
              <Loader fullPage loading={loading} />
            </LoadingOverlay>
            <div className="col-md-12 text-left m-t-20 m-b-20">
                <div className="col-md-12">
                    <h1>IPA - Seller</h1>
                </div>
            </div>
            <div className="col-md-12">
                <div className="card">
                    <div className="card-body text-left">
                         <h5 className="m-t-0">Opportunity Details</h5>
                         <EditText
                                name="companyName"
                                title="Company Name"
                                value={state && state.companyName}
                                placeholder={""}
                                required={true}
                                onValueChange={handleFieldValueChange}
                                errorMsg={errors && errors["companyName"] ? errors["companyName"]  : ""}
                            />
                             <EditText
                                name="businessNature"
                                title="Nature of Business"
                                value={state &&  state.businessNature}
                                placeholder={""}
                                required={true}
                                onValueChange={handleFieldValueChange}
                                errorMsg={errors && errors["businessNature"] ? errors["businessNature"]  : ""}
                            />
                        
                        <div className="col-md-6 float-left p-l-0">
                          <CompanyPs
                              title = {"Products/Services Offered for Sale"}
                              required={true}
                              onChange={event => handleObjectChange(event)}
                              defaultValue={state ? state.psl ? state.psl : [] : []}
                              defaultSelected={ state ? !Helpers.isEmpty(state.psl) ? state.psl : [] : []}
                              errorMsg={errors && errors["ps"] ? errors["ps"]  : ""}
                            /> 
                        </div>
                          
                            
                             {/* <EditText
                                name="investmentOffer"
                                title="Investment Offer"
                                value={state &&  state.investmentOffer}
                                placeholder={""}
                                required={true}
                                  options={props && props.partnershipNature && Helpers.makeSelectOptionsFromEnum(props.partnershipNature)}
                                onValueChange={handleFieldValueChange}
                                errorMsg={errors && errors["investmentOffer"] ? errors["investmentOffer"]  : ""}
                            /> */}
                            <EditText
                                    name="quantities"
                                    title="Quanitites"
                                    value={state &&  state.quantities}
                                    placeholder={""}
                                    required={true}
                                    onValueChange={handleFieldValueChange}
                                    errorMsg={errors && errors["quantities"] ? errors["quantities"]  : ""}
                                    />
                                    
                             <EditText
                                    name="price"
                                    title="Price Requested (USD)"
                                    value={state &&  state.price}
                                    placeholder={""}
                                    required={true}
                                    onValueChange={handleFieldValueChange}
                                    inputType="number"
                                    errorMsg={errors && errors["price"] ? errors["price"]  : ""}
                                    />


                            <div className="col-md-6 float-left p-0">
                                <div className="col-md-6 float-left p-l-0">
                                    <EditText
                                        name="timeline"
                                        title="Timeline"
                                        value={state &&  state.timeline}
                                        placeholder={""}
                                        required={true}
                                        onValueChange={handleFieldValueChange}
                                        errorMsg={errors && errors["timeline"] ? errors["timeline"]  : ""}
                                        />
                                </div>
                              
                                <div className="col-md-6 float-left">
                                        <EditSelect
                                                    name="timelineType"
                                                    title={"timelineType"}
                                                    headingClass="v-hidden"
                                                    placeholder="Select "
                                                    // required={true}
                                                    value={state && state.timelineType}
                                                    // options={config.opportunityTypes}
                                                    options={config.timeline}
                                                    onChange={handleSelectChange}
                                                    additionalClassName="preFilled"
                                                    errorMsg={errors && errors["timelineType"] ? errors["timelineType"]  : ""}
                                                />
                                </div>
                                {/* <div className="col-md-4 float-left p-r-0">
                                        <EditSelect
                                                    name="years"
                                                    title=""
                                                    title={"Years"}
                                                    headingClass="v-hidden"
                                                    // required={true}
                                                    placeholder="Select Years…"
                                                    value={state && state.years}
                                                    // options={config.opportunityTypes}
                                                    options={years.map((item) => ({value : item , label : item}))}
                                                    onChange={handleSelectChange}
                                                    additionalClassName="preFilled"
                                                    errorMsg={errors && errors["years"] ? errors["years"]  : ""}
                                                />
                                </div> */}
                            </div>

                            <EditSelect
                                        name="engagementNature"
                                        title="Nature of Engagement with Buyer"
                                        placeholder=""
                                        required={true}
                                        value={state && state.engagementNature}
                                        // options={config.opportunityTypes}
                                        options={props && props.engagementNature && Helpers.makeSelectOptionsFromEnum(props.engagementNature)}
                                        onChange={handleSelectChange}
                                        additionalClassName="ediableInline preFilled"
                                        errorMsg={errors && errors["engagementNature"] ? errors["engagementNature"]  : ""}
                                    />

                            <div className="docUploadFields float-left col-md-12 p-0">
                                      <h5 className="font-14">Detailed Description of the Product/Services to Buy<span style={{color:"red"}}>*</span></h5>
                                        <label className="custom-file-upload col-md-6">
                                            <input type="file" 
                                                    name="psDescription" 
                                                    value=""
                                                    className="docUploader" 
                                                    // ref={"uploader"} 
                                                    onChange={uploadDocs}
                                                    accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"/> 
                                        </label>
                                        {errors && errors["psDescription"] && <small class="text-danger errorMsgCustom">{errors["psDescription"]}</small>}


                                        {
                                          state && !Helpers.isEmpty(state.psDescription) && 
                                                        <div key={state.psDescription.uniqueId} id={state.psDescription.uniqueId}> 
                                                        <a href={Helpers.viewFile(state.psDescription.uniqueId)} target="_blank">{state.psDescription.name}</a>
                                                        <a key={state.psDescription.uniqueId+"trigger"} data-id={state.psDescription.uniqueId} data-mainId={state.psDescription.id}
                                                                onClick={deleteDoc}
                                                                data-name="psDescription"
                                                                className="text-danger m-l-5"
                                                                >
                                                                <i className="fa fa-times" />
                                                                </a>
                                                    </div>
                                            }

                            </div>

                            <div className="docUploadFields float-left col-md-12 p-0">
                                      <h5 className="font-14">Qualifications and Criteria for Selection of Seller<span style={{color:"red"}}>*</span></h5>
                                        <label className="custom-file-upload col-md-6">
                                            <input type="file" 
                                                    name="buyerQualification" 
                                                    value=""
                                                    className="docUploader" 
                                                    // ref={"uploader"} 
                                                    onChange={uploadDocs}
                                                    accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"/> 
                                        </label>
                                        {errors && errors["buyerQualification"] && <small class="text-danger errorMsgCustom">{errors["buyerQualification"]}</small>}

                                        {
                                          state && !Helpers.isEmpty(state.buyerQualification) && 
                                                        <div key={state.buyerQualification.uniqueId} id={state.buyerQualification.uniqueId}> 
                                                        <a href={Helpers.viewFile(state.buyerQualification.uniqueId)} target="_blank">{state.buyerQualification.name}</a>
                                                        <a key={state.buyerQualification.uniqueId+"trigger"} data-id={state.buyerQualification.uniqueId} data-mainId={state.buyerQualification.id}
                                                                onClick={deleteDoc} data-name="buyerQualification"
                                                                className="text-danger m-l-5"
                                                                >
                                                                <i className="fa fa-times" />
                                                                </a>
                                                    </div>
                                            }
                            </div>
                            
                    </div>
                </div>
                <div className="col-sm-12 col-xs-12 p-0 m-t-10">
                               {state && !Helpers.isEmpty(state.id) && owner && <DeletePage type="Opportunity" uri={state.id} />}
                                <button
                                    type="submit"
                                    className="btn btn-info waves-effect- waves-light- float-right"
                                    onClick={saveAndProceed}
                                >
                                  {config.oppDirectPayment ? "Save and Proceed to Payment" : "Save"} 
                                </button>
                        </div>
            </div>
        </div>
    )
}

export default SellerForm
