import React, { Component } from 'react';
import EditMultiSelect from '../ui/EditMultiSelect';
import DatePicker from "react-datepicker";
import CompanySearch from '../wigets/CompanySearch';
import config from '../../shared/config';


export default class InvestmentRow extends Component {

    constructor(props){
        super(props);
        this.state={
            showForm :false,
            date:new Date(),
            addNewInvesment:false,
            addInvestor:false,
            showTable:false,
            rows:[1],
        }
  
        this.showForm = this.showForm.bind(this);
        this.addNewInvesment = this.addNewInvesment.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        
    }  

    showForm(){
        this.setState({
            showForm:this.state.showForm?false:true
        })
    }
    addNewInvesment(){
        this.setState({
            addNewInvesment:this.state.addNewInvesment?false:true
        })
    }
    handleDateChange(date){
        this.setState({
            date:date
        })
      }
    

  render() {

    let showForm = this.state.showForm;
    let addNewInvesment = this.state.addNewInvesment;

    return (
      <div className="m-t-20">
       <h5 className="text-black">Investment</h5>
        <div className="grayDiv">
          {showForm ?
              <div className="card card-gray ">
                <div className="card-body">
                  <h4 className="card-title font-medium">Investment Details <a href="javascript:void(0)" className="float-right" onClick={this.showForm}><i className="fa fa-times text-danger"></i></a></h4>
                  <table className="table font-14">
                                          <thead>
                                                <tr>
                                                    <th>Annnounced Date</th>
                                                    <th>Company Name</th>
                                                    <th>Lead Investor</th>
                                                    <th>Funding Type</th>
                                                    <th>Pre Money Valuation</th>
                                                    <th>Money Raised</th>
                                                    <th>Funding Stage</th>
                                                    <th>Equity Only Funding</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                         </thead>
                                         <tbody className="bg-white">
                                             <tr>
                                                     <td>
                                                       10/10/3020
                                                     </td>
                                                     <td>
                                                       Tesr
                                                     </td>
                                                     <td>
                                                       Yes
                                                     </td>
                                                     <td>
                                                       Type
                                                     </td>
                                                     <td>
                                                       10,000
                                                     </td>
                                                     <td>
                                                         10,000
                                                     </td>
                                                      <td>
                                                         stage
                                                     </td>
                                                     <td>
                                                        <a href="javascript:void(0)" onClick={this.showTable} >Edit</a>
                                                     </td>
                                                     <td>
                                                         <a href="">Delete</a>
                                                     </td>
                                             </tr>
                                         </tbody>
                                        </table>


                    </div>
                    <div>
                        {addNewInvesment ?
                           <div className="card-body">
                           <h4 className="card-title font-medium">Add New Investment<a href="javascript:void(0)" className="float-right" onClick={this.addNewInvesment}><i className="fa fa-times text-danger"></i></a></h4>
                            <div className="col-md-12 row">
                                <div className="col-md-3 col-sm-12 float-left">
                                   <h5 className="m-t-10 m-b-10 font-12">Annnounced Date</h5>
                                   <DatePicker className="form-control"
                                        selected={this.state.date}
                                        onChange={this.handleDateChange.bind(this)}
                                        placeholderText="Choose as of date"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        dateFormat= {config.inputDateFormat}
                                    />
                               </div>
                               <div className="col-md-3 col-sm-12  float-left">
                                   <h5 className="m-t-10 m-b-10 font-12">Company  Name</h5>
                                   <CompanySearch 
                                        title="" 
                                        headingClass="hidden" 
                                        onChange={this.handleDateChange} 
                                        name="acquiredCompanyName"  
                                        value="fdjfho" 
                                        defaultValue={""}
                                    />
                               </div>
                               <div className="col-md-3 col-sm-12 float-left">
                                    <h5 className="m-t-10 m-b-10 font-12">Lead Investor</h5>
                                    <div className="m-b-10">
                                        <div className="demo-radio-button">
                                            <div className="float-left col-3 p-0">
                                                <input name="group5" type="radio" id="radio_30" className="with-gap radio-col-blue" />
                                                <label htmlFor="radio_30" className="l-h-0">Yes</label>
                                            </div>
                                            <div className="float-left col-3 p-0">
                                                <input name="group5" type="radio" id="radio_31" className="with-gap radio-col-blue" />
                                                <label htmlFor="radio_31" className="l-h-0">No</label>
                                            </div>
                                    </div>
                                    </div>
                                </div>
                               <div className="col-md-3 col-sm-12  float-left">
                                   <h5 className="m-t-10 m-b-10 font-12">Funding Type</h5>
                                   <select className="form-control"></select>
                               </div>

                               </div>
                               <div className="col-md-12 row">

                               <div className="col-md-3 col-sm-12  float-left">
                                   <h5 className="m-t-10 m-b-10 font-12">Pre Money Valuation</h5>
                                   <input className="form-control"/>
                               </div>
                               <div className="col-md-3 col-sm-12  float-left">
                                   <h5 className="m-t-10 m-b-10 font-12">Money Raised</h5>
                                   <input className="form-control"/>
                               </div>
                               
                               <div className="col-md-3 col-sm-12  float-left">
                                   <h5 className="m-t-10 m-b-10 font-12">Funding Stage</h5>
                                   <select className="form-control"></select>
                               </div>
                                <div className="col-md-12 p-0">
                                    <button className="btn btn-info m-t-5 float-right">Save</button>
                                </div>
                                </div>
                           </div>
                         :
                         <div className="col-12 m-t-10">
                             <a href="javacript:void(0)" className="float-left" onClick={this.addNewInvesment}>+ Add Investment</a>
                         </div>

                        }
                       
                    </div>
               </div>
           :
           <div className="m-a text-center col-12 m-b-10">
                <a href="javascript:void(0)" className="text-default custom-plus-icon" onClick={this.showForm}></a>
                <div className="font-12 font-medium">Add Investments</div>
            </div>
        
           }
        </div>
      </div>
    )
  }
}
