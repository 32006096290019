
import React, { Component } from 'react';
import PrivateRouter from '../../utils/PrivateRouter';
import { BrowserRouter as Router, Route,Redirect, Link ,Switch ,withRouter} from "react-router-dom";
import AllCompanies from "./AllCompanies";
import MyCompanies from "./MyCompanies";
import CompanyBySector from "./CompanyBySector";
import CompanyByIndustry from "./CompanyByIndustry";
import CompanyByTags from "./CompanyByTags";

import { connect } from 'react-redux';
const routes = [
    {
        path: "/",
        component: AllCompanies,
        exact:true,
    },
    {
        path: "/companies",
        component: AllCompanies,
        exact:true,
    },
    
]  

const privateRoutes = [
    {
        path: "/companies/my-companies",
        component: MyCompanies,
        exact:true,
    }
    
]  


class CompanyRouter extends Component {
  constructor(){
    super();
    this.state ={
        searchCriteria:{},
        criteriaUpdate:undefined,
        isLoggedIn :false,
        setPageContext:undefined,
        context:undefined,
        locationIds:"",
    }

  }
  componentWillReceiveProps(props){
    this.setState({criteriaUpdate:props.criteriaUpdate,
                   searchCriteria:props.searchCriteria,
                   setPageContext:props.setPageContext,
                   context:props.context,
                   metaList:props.metaList,
                   business:props.business,
                   locationIds:props.locationIds

                  });
  }
    render() {
      return (
        <Switch>
              <Route
                  path='/companies'
                  exact={true}
                  render={(props) => (
                    true
                      ? <AllCompanies {...props}  
                              criteriaUpdate={this.state.criteriaUpdate}
                              searchCriteria={this.state.searchCriteria}
                              setPageContext={this.state.setPageContext}
                              locationIds = {this.state.locationIds}
                              criteriaUpdate />
                      : <Redirect to={{
                            pathname: '/login',
                            state: { from: props.location }
                          }}
                        />
                  )} />

                <Route
                    path='/'
                    exact={true}
                    render={(props) => (
                      true
                        ? <AllCompanies {...props}  
                                criteriaUpdate={this.state.criteriaUpdate}
                                searchCriteria={this.state.searchCriteria}
                                setPageContext={this.state.setPageContext}
                                locationIds = {this.state.locationIds}
                                criteriaUpdate />
                        : <Redirect to={{
                              pathname: '/login',
                              state: { from: props.location }
                            }}
                          />
                    )} />

                <Route
                    path='/companies/my-companies'
                    exact={true}
                    render={(props) => (
                      true
                        ? <MyCompanies {...props}  
                                criteriaUpdate={this.state.criteriaUpdate}
                                searchCriteria={this.state.searchCriteria}
                                setPageContext={this.state.setPageContext}
                                locationIds = {this.state.locationIds}
                                criteriaUpdate />
                        : <Redirect to={{
                              pathname: '/login',
                              state: { from: props.location }
                            }}
                          />
                    )} />
                <Route
                  path='/companies/sector/:sector'
                  exact={true}
                  render={(props) => (
                    true
                      ? <CompanyBySector {...props}  
                                criteriaUpdate={this.state.criteriaUpdate} 
                                searchCriteria={this.state.searchCriteria} 
                                setPageContext={this.state.setPageContext}
                                metaList={this.state.metaList} 
                                business={this.state.business}  
                                locationIds = {this.state.locationIds}
                              />
                      : <Redirect to={{
                            pathname: '/login',
                            state: { from: props.location }
                          }}
                        />
                  )} />
                   <Route
                  path='/companies/product/:productId'
                  exact={true}
                  render={(props) => (
                    true
                      ? <CompanyBySector {...props}  
                                criteriaUpdate={this.state.criteriaUpdate} 
                                searchCriteria={this.state.searchCriteria} 
                                setPageContext={this.state.setPageContext}
                                metaList={this.state.metaList} 
                                business={this.state.business}  
                                locationIds = {this.state.locationIds}
                              />
                      : <Redirect to={{
                            pathname: '/login',
                            state: { from: props.location }
                          }}
                        />
                  )} />
                <Route
                    path='/companies/tag/:tag'
                    exact={true}
                    render={(props) => (
                      true
                        ? <CompanyByTags {...props}  
                                  criteriaUpdate={this.state.criteriaUpdate} 
                                  searchCriteria={this.state.searchCriteria} 
                                  setPageContext={this.state.setPageContext}
                                  metaList={this.state.metaList} 
                                  business={this.state.business}  
                                  locationIds = {this.state.locationIds}
                                />
                        : <Redirect to={{
                              pathname: '/login',
                              state: { from: props.location }
                            }}
                          />
                    )} />
                    <Route
                    path='/companies/industryGroup/:industry'
                    exact={true}
                    render={(props) => (
                      true
                        ? <CompanyByIndustry {...props}  
                                  criteriaUpdate={this.state.criteriaUpdate} 
                                  searchCriteria={this.state.searchCriteria} 
                                  setPageContext={this.state.setPageContext}
                                  metaList={this.state.metaList} 
                                  business={this.state.business}  
                                  locationIds = {this.state.locationIds}
                                  type="gics_industry_group"
                                />
                        : <Redirect to={{
                              pathname: '/login',
                              state: { from: props.location }
                            }}
                          />
                    )} />

                 <Route
                    path='/companies/industry/:industry'
                    exact={true}
                    render={(props) => (
                      true
                        ? <CompanyByIndustry {...props}  
                                  criteriaUpdate={this.state.criteriaUpdate} 
                                  searchCriteria={this.state.searchCriteria} 
                                  setPageContext={this.state.setPageContext}
                                  metaList={this.state.metaList} 
                                  business={this.state.business}  
                                  locationIds = {this.state.locationIds}
                                  type="gics_industry"
                                />
                        : <Redirect to={{
                              pathname: '/login',
                              state: { from: props.location }
                            }}
                          />
                    )} />
                     <Route
                      path='/companies/subIndustries/:industry'
                      exact={true}
                      render={(props) => (
                        true
                          ? <CompanyByIndustry {...props}  
                                    criteriaUpdate={this.state.criteriaUpdate} 
                                    searchCriteria={this.state.searchCriteria} 
                                    setPageContext={this.state.setPageContext}
                                    metaList={this.state.metaList} 
                                    business={this.state.business}  
                                    locationIds = {this.state.locationIds}
                                    type="gics_sub_industry"
                                  />
                          : <Redirect to={{
                                pathname: '/login',
                                state: { from: props.location }
                              }}
                            />
                      )} />


           {/* <Switch>
              {privateRoutes.map((route, index) => (
                  <PrivateRouter
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component=  {withRouter(route.component)}
                   
                  />
               ))}
               {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component=  {route.component}
                   
                  />
               ))}
              </Switch> */}
        </Switch>
      )
    }
  }
  
  export default withRouter(CompanyRouter);
