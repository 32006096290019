import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import config from '../../shared/config';
import Helper from '../../shared/custom';
import { withRouter, Redirect ,Link} from 'react-router-dom';



export default class CoronaReport extends Component {
    render() {
        return (
            <div className="card additionalClass">
                 <div className="card-body">
                     <div class="page">
                         <img src={config.reportFolder+'corona/001-Cover-Page.png'} className="img-fluid"/>
                     </div>
                     <div class="page">
                         <img src={config.reportFolder+'corona/002-Introduction.png'} className="img-fluid"/>
                     </div>
                     <div class="page">
                         <img src={config.reportFolder+'corona/003-What-are-main-consumer-concerns.png'} className="img-fluid"/>
                     </div>

                     <div class="page">
                         <img src={config.reportFolder+'corona/004-What-are-their-knowledge-levels.png'} className="img-fluid"/>
                     </div> 

                     <div class="page">
                         <img src={config.reportFolder+'corona/005-Do-they-feel-that-they-have-enough-information-on-staying-healthy.png'} className="img-fluid"/>
                     </div>

                     <div class="page">
                         <img src={config.reportFolder+'corona/006-Do-they-trust-information-on-infection-and-mortality-rates.png'} className="img-fluid"/>
                     </div>
                     <div class="page">
                         <img src={config.reportFolder+'corona/007-How-have-their-daily-routines-changed.png'} className="img-fluid"/>
                     </div>

                     <div class="page">
                         <img src={config.reportFolder+'corona/008-Have-they-switched-to-online-buying.png'} className="img-fluid"/>
                     </div>

                     <div class="page">
                         <img src={config.reportFolder+'corona/009-Who-they-believe-is-responsible-for-managing-the-spread-of-virus.png'} className="img-fluid"/>
                     </div>

                     <div class="page">
                         <img src={config.reportFolder+'corona/010-What-do-they-think-about-the-likelihood-of-global-recession-due-to-the-Corona-outbreak.png'} className="img-fluid"/>
                     </div>

                     <div class="page">
                         <img src={config.reportFolder+'corona/011-Have-they-switched-to-digital-health-services.png'} className="img-fluid"/>
                     </div>

                     <div class="page">
                         <img src={config.reportFolder+'corona/012-What-they-think-are-the-biggest-benefits-of-digital-health-services.png'} className="img-fluid"/>
                     </div>

                    
                    
                    <div className="m-t-20 clearfix">
                      <a href="http://plus-cdn.investera.com/pdf/corona/Corona_Consumer_Outlook_Report.pdf" className="btn btn-action" download>Download PDF</a>
                     </div>
                 </div>
            </div>
        )
    }
}
