import React from 'react';
import config from '../../../shared/config';
import Helpers from '../../../shared/custom';
import StarRatings from 'react-star-ratings';

function Review({ranking,noOfRatings,users}) {
    return (
        <div className="card">
                <div class="card-header text-left light-blue-header"><h5 class="m-b-0 m-t-0 text-white">Opportunity Rating</h5></div>
                <div className="card-body p-t-2">
                    <div className="ratingDiv col-md-6 p-0">
                            <div className="col-md-6 p-0 text-center align-flex">
                                <StarRatings
                                            rating={ranking}
                                            starRatedColor={"#ffcd00"}
                                            numberOfStars={5}
                                            hoverOverStar={true}
                                            starSpacing={"2"}
                                            starDimension={"16px"}
                                            starSelectingHoverColor={"#ffcd00"}
                                            starHoverColor={"#ffcd00"}
                                            name='rating'
                                            isSelectable="false"
                                            svgIconPath="M2 1 h1 v1 h1 v1 h-1 v1 h-1 v-1 h-1 v-1 h1 z"
                                            svgIconViewBox="0 0 5 5" 
                                            />
                            </div>
                            <div className="ratingNumber col-md-6 p-0 text-center">
                                <h2 className="text-black m-t-10 m-b-0">{ranking}/5</h2>
                                <p className="text-white">{noOfRatings} Reviews</p>
                            </div>
                    </div>
                    <div className="row m-t-20">
                        { !Helpers.isEmpty(users) && 
                         users.map((list,i)=>(
                         <div class="col-md-6 normal-padding" key={"review"+i}>
                           <p className="border p-2 full-width float-left">
                               <img width="60" height="60" src={list.createdBy && list.createdBy.image ? Helpers.dataPath(list.createdBy.image) : Helpers.dataPath(config.defaultUserImage)} class="float-left img-circle"/>
                               <div className="m-l-5 float-left" style={{width:"70%"}}>
                                    <h5 class="text-blue font-14 m-0 m-t-5" title={list.createdBy.name}>{Helpers.truncate(list.createdBy.name,20)}</h5>
                                    <p class="font-12 p-0 text-info font-normal m-b-0 m-t-5">{list && list.created && list.created ? Helpers.getDateInInputFormat(list.created) : "" }</p>
                                    <StarRatings
                                        rating={list.review.rating}
                                        starRatedColor={"#ffcd00"}
                                        numberOfStars={5}
                                        hoverOverStar={true}
                                        starSpacing={"2"}
                                        starDimension={"16px"}
                                        starSelectingHoverColor={"#ffcd00"}
                                        starHoverColor={"#ffcd00"}
                                        name='rating'
                                        isSelectable="false"
                                        svgIconPath="M2 1 h1 v1 h1 v1 h-1 v1 h-1 v-1 h-1 v-1 h1 z"
                                        svgIconViewBox="0 0 5 5" 
                                        />
                            </div>
                            </p>
                            
                        </div>
                         ))
                        }
                        
                        
                    </div>
                    {/* {
                    page && page.attachments && page.attachments.length > 0  ?
                            page.attachments.map((doc,i)=>{
                                return <div className="attachments">
                                            <a href={Helpers.viewFile(doc.uniqueId)} target="_blank">
                                                <i class="fa fa-file-pdf-o font-20"></i> <span className="font-12">{doc.name}</span>
                                            </a> 
                                        </div>
                            })
                            :
                            <div className="text-center">No Data Found</div>
                    } */}

                </div>
        </div>
    )
}

export default Review
