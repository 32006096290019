import { SET_COMPANY_INFO, COMPANY_NOT_FOUND }  from '../actions/types';

const initialState = {    
    company:{
        entityUri:null,
        entityType:"company",
        entityInfo:null,
    },
    person:{
        entityUri:null,
        entityType:"people",
        entityInfo:null
    },
    loading:true,
    notFound:false,
}

export default function( state = initialState, action){

  
    switch(action.type){
        case SET_COMPANY_INFO :
        
        var facets = action.facets;
        var currentCompany = state.company;
        var newCompany = action.payload;
        if(currentCompany.entityUri != action.entityUri){
            //new company, reset everything
            currentCompany.entityUri = action.entityUri;
            currentCompany.entityInfo = {};
        }
        
        facets = (facets+"").split(",");
        facets.forEach((facet) => {
            currentCompany.entityInfo[facet] = newCompany[facet];
        });
        currentCompany.count_bod = newCompany.count_bod;
        currentCompany.count_founders = newCompany.count_founders;
        currentCompany.count_funding_round = newCompany.count_funding_round;
        currentCompany.count_team_members = newCompany.count_team_members;
        currentCompany.count_investments = newCompany.count_investments;
        currentCompany.count_investors = newCompany.count_investors;
        currentCompany.count_documents = newCompany.count_documents;

       
        state.company = currentCompany;
        state.loading = action.loading;
        state._pageClaimStatus = newCompany._pageClaimStatus;
        state.notFound = false;
       
        return {
            ...state,
            company:currentCompany
        }

        case COMPANY_NOT_FOUND :
        state.notFound = action.payload;
        return state;

        default:
            return state;
    }
}