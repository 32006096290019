import React, { Component, Children } from 'react';
import OppRightPanel from './OppRightPanel';
import Sidemenu from '../sidemenu/Sidemenu';
import OppSummaryRouter from './OppSummaryRouter';

import store from '../../store';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import Helpers from '../../shared/custom';

let locationIdsRevd;
// let filterDatesRevd;

class OppSummary extends Component {
  constructor(props){
    super(props)
    this.state ={
      isLoggedIn :false,
      filterDatesRcvd:{},
      locationIds:[],
      privileges:{},
      context:undefined,
    }
    
    store.subscribe(() => { 
      
      var storeState = store.getState();
      var auth = storeState.auth;
      var localAuth = this.state.auth;
      this.setState({
        isLoggedIn:auth.isAuthenticated
      },()=>{
       
      });

      // let packageDetails =  storeState.packageDetails;
      // let privileges = packageDetails.privileges;
      // if(JSON.stringify(privileges) != JSON.stringify(this.state.privileges)){
      //   this.setState({
      //       privileges:privileges
      //   },()=>{
      //     if(Helpers.isEmpty(privileges) || !privileges["INV_OPP"]){
      //       this.props.history.push(Helpers.upgradeUrl());
      //       return false;
      //     }
      //   })
      // }

    }); 
    this.criteriaUpdate = this.criteriaUpdate.bind(this);
    this.filterDates = this.filterDates.bind(this);
    this.setPageContext = this.setPageContext.bind(this);
  }

  getSelectedLocation(locationIds){
    locationIdsRevd = locationIds;
  }
 
  filterDates(filterDates){
    this.setState({
      filterDatesRcvd:filterDates
    })
  }
    
  criteriaUpdate(name, value){
    let sc = this.state.searchCriteria;
    sc[[name]] = value;
    this.setState({searchCriteria:sc});
  }

  componentDidMount(){
    var storeState = store.getState();
    var auth = storeState.auth;
    var localAuth = this.state.auth;
    this.setState({
      isLoggedIn:auth.isAuthenticated
    })

  }

  setPageContext(context){
    if (this.state.context != context){
       this.setState({context:context});
    }          
  }

  render() {
   
    return (
       <div>
            <div>
                <div >
                  <div className="row content-in m-t-80 p-t-10">
                    <div className="col-lg-3 col-xlg-3 col-md-3 sideMenuCont">
                       <Sidemenu mode="oppSummary" isLoggedIn={this.state.isLoggedIn}  criteriaUpdate={this.criteriaUpdate}  searchCriteria={this.state.searchCriteria} setPageContext={this.setPageContext}   sendLocationToParent={this.getSelectedLocation} getDateFilterValues={this.filterDates}/>
                    </div>
                    <div className="col-lg-6 col-xlg-6 col-md-6 centerDiv">
                       <OppSummaryRouter isLoggedIn={this.state.isLoggedIn} criteriaUpdate={this.criteriaUpdate} searchCriteria={this.state.searchCriteria} setPageContext={this.setPageContext} context={this.state.context}  metaList={this.state.metaList} business={this.state.business} locationIds={locationIdsRevd} filterDatesAll={this.state.filterDatesRcvd} />
                    </div>
                    <div className="col-lg-3 col-xlg-3 col-md-3 m-t-2 right-side">
                        <OppRightPanel isLoggedIn={this.state.isLoggedIn}  criteriaUpdate={this.criteriaUpdate}  searchCriteria={this.state.searchCriteria} setPageContext={this.setPageContext} context={this.state.context} metaList={this.state.metaList} business={this.state.business} />
                    </div>
                </div>
              </div>
            </div>
        
      </div>
    )
  }
}
export default  withRouter(OppSummary);