import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter , Table} from 'reactstrap';
import {login} from '../../actions/authAction';
import { withRouter, Link } from 'react-router-dom';
import Axios from 'axios';
import store from '../../store';
import { connect } from 'react-redux';
import Helpers from '../../shared/custom';
import config from "../../shared/config";
import { getRankingDetails } from '../../actions/rankingAction';
import StarRatings from 'react-star-ratings';




class StarRating extends Component {

    constructor(props){
        super(props);
        this.state ={
            starsTotal:config.totalRating,
            rating:0,
            modal: false,
            result:[],
            total:0,
            isLoggedIn:false,
        }
        this.renderStars = this.renderStars.bind(this);
        this.getStarRatingLegend = this.getStarRatingLegend.bind(this);
     
    }

    componentDidMount(){

        var storeState = store.getState();  
        this.setState({
            isLoggedIn: storeState.auth.isAuthenticated
        }) 
  


      let rating = this.props.rating;
      let page = this.props.page;
      this.setState({
        rating:rating,
      });

      if(page && page.uri){
        this.setState({
            uri:page.uri
          })
      }
    }

    componentWillReceiveProps(props){
        var storeState = store.getState();  
        this.setState({
            isLoggedIn: storeState.auth.isAuthenticated,
            rating:props.rating
        }) 
  
    }

    renderStars = () => {
            // let starsTotal = this.state.starsTotal;
            // var stars = [];
            // for(var i = 0; i < starsTotal; i++) {
            //     var klass = 'star-rating__star';
            //     if (this.state.rating > i &&  this.state.rating != null) {
            //       klass += ' is-selected';
            //     }
            //     if(this.state.rating == 5){
            //         klass += ' fiveStar';
            //     }else if(this.state.rating == 4){
            //         klass += ' fourStar';
            //     }else if(this.state.rating == 3){
            //         klass += ' threeStar';
            //     }else if(this.state.rating == 2){
            //         klass += ' twoStar';
            //     }else if(this.state.rating == 1){
            //         klass += ' oneStar';
            //     }else{
            //         klass += ' noStar';
            //     }
          
            //     stars.push(
            //       <label style={this.props.pointerStyle?{"cursor":"default"}:{"cursor":"pointer"}} onClick={this.showDetails}
            //         className={klass}
            //         >
            //         {/* ★ */}
            //         +
            //       </label>
            //     );
            //    }
            // return stars;
    }

    showDetails =()=>{
            let uri = this.state.uri;
            this.props.getRankingDetails(uri);
            if(this.props && this.props.scrollToDiv){
                this.props.scrollToDiv()
            }
    }

    getStarRatingLegend(numStars,score){
    return(<StarRatings
                        rating={score}
                        starRatedColor={"#ffcd00"}
                        //changeRating={false}
                        numberOfStars={numStars}
                        hoverOverStar={false}
                        starSpacing={"2"}
                        starDimension={"24px"}
                        starSelectingHoverColor={"#ffcd00"}
                        name='rating'
                        isSelectable="false"
                        svgIconPath="M2 1 h1 v1 h1 v1 h-1 v1 h-1 v-1 h-1 v-1 h1 z"
                        svgIconViewBox="0 0 5 5" 
                        />)
    }
    
    render() {
        let result = this.state.result;
        let total = this.state.total;
        let uri = this.state.uri;
        let withLabel = this.props.withLabel;
        let page = this.props.page;
        let masterClass = this.props.masterClass  ? this.props.masterClass : "text-left";
        let pointerStyle = this.props.pointerStyle? this.props.pointerStyle : "";

        return (
            <React.Fragment>
                { 
                    withLabel ?
                    <div className="text-center">
                        <span className="font-bold">{!Helpers.isEmpty(page) ? page.name :""}</span> Investera+ Rating is 
                           <div className="">
                            <StarRatings
                                rating={this.state.rating}
                                starRatedColor={"#ffcd00"}
                                //changeRating={false}
                                numberOfStars={5}
                                hoverOverStar={false}
                                starSpacing={2}
                                starDimension={"16px"}
                                starSelectingHoverColor={"#ffcd00"}
                                name='rating'
                                isSelectable="false"
                                svgIconPath="M2 1 h1 v1 h1 v1 h-1 v1 h-1 v-1 h-1 v-1 h1 z"
                                svgIconViewBox="0 0 5 5" 
                                />
                            </div>
                
                            <span class="mytooltip tooltip-effect-2 rankToolTip"> 
                                <i class="fa fa-info abt-info font-10 tooltip-item"></i>
                                 <span class="tooltip-content clearfix"> 
                                   <span class="tooltip-text">
                                      <span className="font-12"><span className="text-blue font-bold">Investera+</span> company rating</span>
                                      {/* <div className="clearfix m-t-10">{[1,1.5,2,3,4,5].map(()=>(<span className="font-18 ratingSpan"><i class="fa fa-plus is-selected fourStar star-rating__star" aria-hidden="true"></i></span>))}<span className="font-12"> 81 pts to 100 pts</span></div>
                                      <div className="clearfix">{[1,2,3,4].map(()=>(<span className="font-18 ratingSpan"><i class="fa fa-plus is-selected fourStar star-rating__star" aria-hidden="true"></i></span>))}<span className="font-12"> 61 pts to 80 pts</span></div>
                                      <div className="clearfix">{[1,2,3].map(()=>(<span className="font-18 ratingSpan"><i class="fa fa-plus is-selected fourStar star-rating__star" aria-hidden="true"></i></span>))}<span className="font-12"> 41 pts to 60 pts</span></div>
                                      <div className="clearfix">{[1,2].map(()=>(<span className="font-18 ratingSpan"><i class="fa fa-plus is-selected fourStar star-rating__star" aria-hidden="true"></i></span>))}<span className="font-12"> 21 pts to 40 pts</span></div> */}
                                      <div className="clearfix"><div className="col-12 font-12 ratingSpan stars-inlined ">{this.getStarRatingLegend(5,5)} 91 pts to 100 pts</div></div>
                                      <div className="clearfix"><div className="col-12 font-12 ratingSpan stars-inlined ">{this.getStarRatingLegend(5,4.5)} 81 pts to 90 pts</div></div>
                                      <div className="clearfix"><div className="col-12 font-12 ratingSpan stars-inlined ">{this.getStarRatingLegend(4,4)} 71 pts to 80 pts</div></div>
                                      <div className="clearfix"><div className="col-12 font-12 ratingSpan stars-inlined ">{this.getStarRatingLegend(4,3.5)} 61 pts to 70 pts</div></div>
                                      <div className="clearfix"><div className="col-12 font-12 ratingSpan stars-inlined ">{this.getStarRatingLegend(3,3)} 51 pts to 60 pts</div></div>
                                      <div className="clearfix"><div className="col-12 font-12 ratingSpan stars-inlined ">{this.getStarRatingLegend(3,2.5)} 41 pts to 50 pts</div></div>
                                      <div className="clearfix"><div className="col-12font-12 ratingSpan stars-inlined ">{this.getStarRatingLegend(2,2)} 31 pts to 40 pts</div></div>
                                      <div className="clearfix"><div className="col-12 font-12 ratingSpan stars-inlined ">{this.getStarRatingLegend(2,1.5)} 11 pts to 30 pts</div></div>
                                      <div className="clearfix"><div className="col-12 font-12 ratingSpan stars-inlined ">{this.getStarRatingLegend(1,1)} 0 pts to 10 pts</div></div>
                                  </span>
                                 </span> 
                            </span>
                    </div>
                    :
                    // <div className="text-left">{this.renderStars()}</div>
                    pointerStyle == "noLink" ? 
                    <div  onClick={this.showDetails} className={masterClass}>
                    <StarRatings
                        rating={this.state.rating}
                        starRatedColor={"#ffcd00"}
                        //changeRating={false}
                        numberOfStars={5}
                        hoverOverStar={false}
                        starSpacing={"2"}
                        starDimension={"16px"}
                        starSelectingHoverColor={"#ffcd00"}
                        name='rating'
                        isSelectable="false"
                        svgIconPath="M2 1 h1 v1 h1 v1 h-1 v1 h-1 v-1 h-1 v-1 h1 z"
                        svgIconViewBox="0 0 5 5" 
                        />
                    </div>
                    :
                    <a href="javascript:void(0)" onClick={this.showDetails} className={masterClass}>
                        <StarRatings
                            rating={this.state.rating}
                            starRatedColor={"#ffcd00"}
                            //changeRating={false}
                            numberOfStars={5}
                            hoverOverStar={false}
                            starSpacing={"2"}
                            starDimension={"16px"}
                            starSelectingHoverColor={"#ffcd00"}
                            name='rating'
                            isSelectable="false"
                            svgIconPath="M2 1 h1 v1 h1 v1 h-1 v1 h-1 v-1 h-1 v-1 h1 z"
                            svgIconViewBox="0 0 5 5" 
                            />
                        </a>
                
                    
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) =>({
    rankDetails:state.rankDetails,
    errors:state.errors
});

export default connect(mapStateToProps,{getRankingDetails})(withRouter(StarRating));
