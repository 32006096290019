import React, { Component , useEffect, useState , useRef } from 'react';


import config from '../../shared/config';
import Helpers from '../../shared/custom';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import Axios from "axios";

import EditText from "../ui/EditText";
import EditDate from "../ui/EditDate";
import EditSelect from "../ui/EditSelect";
import EditTextArea from "../ui/EditTextArea";
import ApiHelper from "../../shared/ApiHelper";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import DeletePage from '../wigets/DeletePage';

function PartnershipForm(props) {

    const [loading, setLoading] = useState(() => false);
    const [errors, setErrors] = useState(() => {});
    const [state, setState] = useState(() => {});
    const [options, setOptions] = useState([]);
    const [owner, setOwner] = React.useState(false);
    const imgUploader = useRef(null);

    const handleFieldValueChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name) {
            setState((prevState) => ({...prevState, [name]: value }));
          
        }
    }

    useEffect(() => {
      if(!Helpers.isEmpty(props.data)){
          setState(props.data);
          setOwner(props.data.owner)
      }
  },[props.data])
  
    const uploadDocs = (e) =>{
        let file = e.target.files ? e.target.files[0] : null;
         let {name} = e.target
        let docType = e.target.getAttribute("data-id");
        setLoading(true)
        if (file) {
          ApiHelper.uploadFile({
            repoType: "OPP_DOCS",
            docTypeName: "OPP_INV_PD",
            fileType: "DOC",
            metaType:"OPPDOC",
            file: file,
            callback: result => {
              setState((prevState) => ({...prevState, [name] :  result })); 
              setLoading(false)
            },
            errCallback:err=>{
               setLoading(false)
            }
          });
        }else{
            setLoading(false)
        }
      }

    const handleEndDateValueChange = (date) =>{
        setState((prevState) => ({...prevState, targetDate: Helpers.getDateInInputFormat(date, true,config.inputDateTimeFormat)}));
    }

    const uploadProfileImage = (e) =>{
        setLoading(true)
        let file = e.target.files ? e.target.files[0] : null;
        if (file) {
          ApiHelper.uploadFile({
            repoType: "COMPANY_IMAGES",
            fileType: "IMAGE",
            file: file,
            callback: result => {
                setLoading(false);
                setState((prevState) => ({...prevState, logo: result }));
            }
          });
        }
      }



      const  handleSelectChange = (option, e) => {
        let thisFieldName = e.name;
        setState((prevState) => ({...prevState, [thisFieldName]: option })); 
        if(thisFieldName == "partnershipNature" && option.value == "EXCLUSIVE"){
          setState((prevState) => ({...prevState, "noOfPartners": 1 })); 
        }
     }

    const triggerProfileImageUpload = (e) =>{
        imgUploader.current.click();
    }

    const vaidateForm = () => {
        let isValid = true;
        let fields = [
            "companyName",
            "logo",
            "psl",
            "partnershipNature",
            "qualifications",
            "companyBrochure",
            "noOfPartners"
        ]


        let errors = {}
    
        fields.forEach((field) => {
          if (Helpers.isEmpty(state[field])) {
            isValid = false;
            errors[field] = "This field is mandatory"
          } else {
            errors[field] = ""
          }
        })
    
        setErrors(errors);
        return isValid;
    }

    const saveAndProceed = ()=>{
        var formValid = vaidateForm();
        if(!formValid){
            Helpers.pushError("Please fill all the required fields.");
            return false;
        };
        setLoading(true)
        var payload = state;

        payload.companyBrochure = { uniqueId : state.companyBrochure.uniqueId}
        payload.logo = { uniqueId : state.logo.uniqueId}
        payload.qualifications = {uniqueId :  state.qualifications.uniqueId};
        payload.companyBrochure = {uniqueId :  state.companyBrochure.uniqueId}; 
        
        // payload.partnershipNature = state.partnershipNature.value;

        Axios.put(Helpers.apiURL({ uri: "api/opportunity/" }), payload)
        .then(response => {
           setLoading(false)
            Helpers.pushSuccess("Opportunity Details has been updated!");
            let data = response.data;
            let id = data.id ? data.id : null;
            let paid = payload && payload.page.paid;
            let isPublished = payload && payload.page.regStatus && payload.page.regStatus.value == "PUBLISHED";
            // if (id) {
            //     window.location = paid ? `/opportunity/${payload.id}?isPublished=${isPublished}`:`/opportunity/proceed?targetEntityId=${payload.id}&paymentCategory=OPPORTUNITY`;
            // }
            if (id) {
              if(config.oppDirectPayment){
                window.location = paid ? `/opportunity/${payload.id}?isPublished=${isPublished}`:`/opportunity/proceed?targetEntityId=${payload.id}&paymentCategory=OPPORTUNITY`;
              }else{
                window.location = `/opportunity/${payload.id}?isPublished=${isPublished}`
              }
            }
         
         
  
        })
        .catch(err => {
          setLoading(false)
          Helpers.pushError(err.response.data || err.response.data.message)
        });
    }


    const handleSearch = (query) => {
      setLoading(true);
      let url = config.api("api/company-psl/search")
      const SEARCH_URI = `${url}?query=${query}`;
      Axios.get(
        SEARCH_URI
      )
        .then((response) => {
          let objects = response.data.result;
          const options = objects.map((obj) => ({
            id: obj.id,
            name: obj.name,
          }));
  
          setOptions(options);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        })
  
  
    };

    const deleteDoc = (e)=>{
      e.stopPropagation();
      var name = e.currentTarget.dataset && e.currentTarget.dataset.name;
      if(name){
        setState((prevState) => ({...prevState, [name]: {} }));
      }
    }


   const  handleObjectChange = (e) => {
      let psl = e;
      let existingPsl = state.psl;
      existingPsl = psl.map((item) => {
        let localPsl = existingPsl.filter((x) => item.name ? item.name == x.name : item == x.name);
        if (localPsl.length == 0) {
          let res = item.customOption ? {
            id: null,
            name: item.name,
          }
            : item;
          return res;
        } else {
  
          return localPsl[0]
  
        }
      })
  
      state["psl"] = existingPsl
      setState(state);
    }

    const filterBy = () => true;

    console.log(state,"state")

    return (
        <div className="row full-width bold-headers">
            <LoadingOverlay>
              <Loader fullPage loading={loading} />
            </LoadingOverlay>
            <div className="col-md-12 text-left m-t-20 m-b-20">
                <div className="col-md-12">
                    <h1>Partnership</h1>
                </div>
            </div>
            <div className="col-md-12">
                <div className="card">
                    <div className="card-body text-left">
                         <h5 className="m-t-0">Opportunity Details</h5>
                         <EditText
                                name="companyName"
                                title="Company Name"
                                value={state && state.companyName}
                                placeholder={""}
                                required={true}
                                onValueChange={handleFieldValueChange}
                                errorMsg={errors && errors["companyName"] ? errors["companyName"]  : ""}
                            />

                    
                            <div className="float-left col-md-12 p-0">
                                <h6 className="">Logo<span style={{color:"red"}}>*</span></h6>
                                    <a
                                    href="javascript:void(0)"
                                        onClick={triggerProfileImageUpload}
                                        >
                                        {!state || Helpers.isEmpty(state.logo) ? (
                                            <div className="float-left newImgCard">
                                                <i className="fa fa-camera text-black m-t-20 font-40 " />
                                                <div className="font-10">Upload photo</div>
                                            </div>
                                        ) : (
                                            <img
                                            src={state && state.logo.uniqueId &&  Helpers.viewFile(state.logo.uniqueId)}
                                            className="float-left newImgCard"
                                            width="100"
                                            />
                                        )}
                                        <input
                                            type="file"
                                            id="userImg"
                                            name="userImg"
                                            style={{ display: "none" }}
                                            ref={imgUploader}
                                            onChange={uploadProfileImage}
                                        />
                                        </a>
                            </div>


                             {/* <EditText
                                name="psl"
                                title="Products & Services*"
                                value={state &&  state.psl}
                                placeholder={""}
                                required={true}
                                onValueChange={handleFieldValueChange}
                                errorMsg={errors && errors["psl"] ? errors["psl"]  : ""}
                            /> */}

<div className="col-md-6 p-0 float-left">
       <h5>Products & Service <span className="text-danger font-bold">*</span></h5> 
          <div>
          <AsyncTypeahead
                filterBy={filterBy}
                allowNew={true}
                multiple={true}
                isLoading={loading}
                newSelectionPrefix="Add a new item: "
                labelKey="name"
                minLength={1}
                onChange={(e) => handleObjectChange(e)}
                onSearch={handleSearch}
                options={options}
                selected={state ? state.psl ? state.psl : [] : []}
                placeholder="Type to search"
                renderMenuItemChildren={(option, props) => (
                  <React.Fragment>
                    <div key={"obj-" + option.value} user={option} >
                      <div>
                        <h6 className="font-bold  m-t-5">{option.name}
                        </h6>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              />
              {errors && errors["psl"] && <small class="text-danger errorMsgCustom">{errors["psl"]}</small>}
              </div>
              </div>

                            <EditSelect
                                name="partnershipNature"
                                title="Nature of Partnership"
                                required={true}
                                value={state && state.partnershipNature}
                                options={props && props.partnershipNature && Helpers.makeSelectOptionsFromEnum(props.partnershipNature)}
                                onChange={handleSelectChange}
                                additionalClassName="ediableInline preFilled"
                                errorMsg={errors && errors["partnershipNature"] ? errors["partnershipNature"]  : ""}
                            />

                       <EditText
                                name="noOfPartners"
                                title="Number of Partnership"
                                value={state && state.noOfPartners}
                                placeholder={""}
                                required={true}
                                onValueChange={handleFieldValueChange}
                                disabled={state && state.partnershipNature && state.partnershipNature.value == "EXCLUSIVE"}
                                errorMsg={errors && errors["noOfPartners"] ? errors["noOfPartners"]  : ""}
                            />
                           

                            <div className="docUploadFields float-left col-md-12 p-0">
                                      <h5 className="font-14">Company Brochure<span style={{color:"red"}}>*</span></h5>
                                        <label className="custom-file-upload col-md-6">
                                            <input type="file" 
                                                    name="companyBrochure" 
                                                    value=""
                                                    className="docUploader" 
                                                    // ref={"uploader"} 
                                                    onChange={uploadDocs}
                                                    accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"/> 
                                        </label>

                                        {
                                          state && !Helpers.isEmpty(state.companyBrochure) && 
                                                        <div key={state.companyBrochure.uniqueId} id={state.companyBrochure.uniqueId}> 
                                                        <a href={Helpers.viewFile(state.companyBrochure.uniqueId)} target="_blank">{state.companyBrochure.name}</a>
                                                        <a key={state.companyBrochure.uniqueId+"trigger"} data-id={state.companyBrochure.uniqueId} data-mainId={state.companyBrochure.id}
                                                                onClick={deleteDoc} data-name="companyBrochure"
                                                                className="text-danger m-l-5"
                                                                >
                                                                <i className="fa fa-times" />
                                                                </a>
                                                    </div>
                                            }
                            </div>
                            <div className="docUploadFields float-left col-md-12 p-0">
                                      <h5 className="font-14">Qualifications and Criteria for Partnership Selection<span style={{color:"red"}}>*</span></h5>
                                        <label className="custom-file-upload col-md-6">
                                            <input type="file" 
                                                    name="qualifications" 
                                                    value=""
                                                    className="docUploader" 
                                                    // ref={"uploader"} 
                                                    onChange={uploadDocs}
                                                    accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"/> 
                                        </label>

                                        {
                                          state && !Helpers.isEmpty(state.qualifications) && 
                                                        <div key={state.qualifications.uniqueId} id={state.qualifications.uniqueId}> 
                                                        <a href={Helpers.viewFile(state.qualifications.uniqueId)} target="_blank">{state.qualifications.name}</a>
                                                        <a key={state.qualifications.uniqueId+"trigger"} data-id={state.qualifications.uniqueId} data-mainId={state.qualifications.id}
                                                                onClick={deleteDoc} data-name="qualifications"
                                                                className="text-danger m-l-5"
                                                                >
                                                                <i className="fa fa-times" />
                                                                </a>
                                                    </div>
                                            }
                            </div>
                            
                    </div>
                </div>
                <div className="col-sm-12 col-xs-12 p-0 m-t-10">
                              {state && !Helpers.isEmpty(state.id) && owner && <DeletePage type="Opportunity" uri={state.id} />}
                                <button
                                    type="submit"
                                    className="btn btn-info waves-effect- waves-light- float-right"
                                    onClick={saveAndProceed}
                                >
                                  {config.oppDirectPayment ? "Save and Proceed to Payment" : "Save"} 
                                </button>
                        </div>
            </div>
        </div>
    )
}

export default PartnershipForm
