import React, { Component } from 'react'
import Helpers from '../../shared/custom';

export default class ExternalLink extends Component {
  render() {
    let link = this.props.link;
    let text = this.props.text || link;
    let title = this.props.title;
    let childClass=this.props.childClass;
    let headerClass=this.props.headerClass ? this.props.headerClass : "";
    let anchorClass= this.props.anchorClass ? this.props.anchorClass : "";

    if(link){
      if((link+"").substring(0,4) !="http"){
        link = "http://"+link;
      }
    }
    return (
      <div>
        {!Helpers.isEmpty(link) ? 
          <div className={childClass} >
            {link && title?
              
                <h6 className={headerClass}>{title}</h6>
                :
                ""
            }
            {link?
                <a href={link} rel="nofollow,noreferrer" target="_blank" className={anchorClass}>{text}</a>              
                :
                ""
            }
          </div>
          :
          ""
        }
      </div> 
    )
  }
}
