import React, { Component } from 'react';
import Slider from "react-slick";
import config from "../../shared/config";
import Axios from "axios";
import Helper from "../../shared/custom";



export class Documents extends Component {
    constructor(props){
        super(props);
        this.state = {
            docs:[],
            localProps:{}
        }
    }



    componentDidUpdate(prevProps) {
        if(JSON.stringify(this.state.localProps) != JSON.stringify(prevProps)){
            this.setState({localProps:prevProps});
            if(!Helper.isEmpty(prevProps.entityUri)){
               // this.fetchDocs(prevProps.entityUri)
               this.setState({docs:prevProps.data})
            }
            
        }
    }

    fetchDocs = (uri) =>{
       
        Axios.get(
            Helper.apiURL({
                uri:"api/page-documents/get-by-page-uri/"+uri,
            })
        )
        .then( (response) => {           
            let data = response.data && response.data? response.data : [];
            this.setState({
                docs:data
            })
          })
        .catch((err) => {
        });
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: (this.state.docs.length  > 1 || !Helper.isMobile())? 2 :1,
            slidesToScroll: (this.state.docs.length  > 1 || !Helper.isMobile()) > 1 ? 2 :1,
          };

        let { docs } =  this.state;
        
        return (
            <React.Fragment>
                    <div className="card text-left bold-headers documents" id="documents">
                    <div className="card-header text-left light-blue-header">
                        <h5 className="m-b-0 m-t-0 text-white">Documents</h5>
                    </div>
                    <div className="card-body">
                    {!Helper.isEmpty(docs) ? 
                     <Slider {...settings}>
                        {
                            docs.map((item,i)=>(
                                <div key={i} className="docCard">
                                <div className="col-md-12">
                                    <i className="fa fa-file-pdf-o"></i>
                                    <h5 className="font-normal m-small">{item.name}</h5>
                                    <a href={Helper.viewFile(item.uniqueId)} target="_blank" className="btn btn-info float-right docButton">View</a>
                                 </div>
                                </div>      
                          ))
                        }
                    </Slider> 
                    :
                    <div className="text-center">No Data Found</div>
                    }
                    </div>
                    </div>
               
            </React.Fragment>
        )
    }
}

export default Documents;
