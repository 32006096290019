import React, { useState ,useEffect , useMemo ,memo} from 'react';
import EditMultiSelect from "../ui/EditMultiSelect";
import Helpers from "../../shared/custom";
import store from "../../store";
import { getAllSectorData } from '../../actions/SectorsAction';
import Axios from "axios";
import { LoadingOverlay, Loader } from 'react-overlay-loader';

import { useSelector, shallowEqual } from 'react-redux';


function MetaEdit({options ,data ,onChangeMeta,error},props) {

    const [sectorOptions, setSectorOptions] = useState([]);
    const [igOptions, setIgOptions] = useState([]);
    const [industriesOptions, setIndustriesOptions] = useState([]);
    const [siOptions, setSiOptions] = useState([]);
    const [sectorLoaded, setSectorLoaded] = useState(false)


    const [selectedSectors, setSelectedSectors] = useState([]);
    const [selectedIg, setSelectedIg] = useState([]);
    const [selectedIndustries, setSelectedIndustries] = useState([]);
    const [selectedSi, setSelectedSi] = useState([]);

    const [loading , setLoading] = useState(false);

   



    const ALL_OPTIONS = {
        "sectors":{
            "displayName":"Sectors",
            "name":"sectors",
            "metaKey":"sectorsArray",
            "headingClass":"isMandatoryLabel",
            "options":sectorOptions,
            "additionalClassName":"ediableInline preFilled",
            "value":selectedSectors,
        },
        "industryGroups":{
            "displayName":"Industries Group",
            "name":"industryGroups",
            "metaKey":"industryGroupsArray",
            "headingClass":"isMandatoryLabel",
            "options":igOptions,
            "value":selectedIg,
            "additionalClassName":"ediableInline"
        },
        "industries":{
            "displayName":"Industries",
            "name":"industries",
            "metaKey":"industryArray",
            "headingClass":"isMandatoryLabel",
            "options":industriesOptions,
            "value":selectedIndustries,
            "additionalClassName":"ediableInline"
        },
        "sub_industries":{
            "displayName":"Sub-Industries",
            "name":"subIndustries",
            "metaKey":"subIndutriesArray",
            "headingClass":"isMandatoryLabel",
            "options":siOptions,
            "value":selectedSi,
            "additionalClassName":"ediableInline"
        },
    }

   

    useEffect(() => {
        let loadedSectors = localStorage.getItem("gSectors");
        if(Helpers.isEmpty(loadedSectors)){
            fetchSectors();
        }else{
            loadedSectors = JSON.parse(loadedSectors); 
            setSectorOptions(loadedSectors);
        }


        if(data && data.sectors && data.sectors.length > 0){
            let vals = data.sectors;
            setSelectedSectors(vals)
            fetchIG(vals)
        }

        if(data && data.industries && data.industries.length > 0){
            let vals = data.industries;
            setSelectedIndustries(vals)
            fetchIndustries(vals)
        }

        if(data && data.industryGroups && data.industryGroups.length > 0){
            let val = data.industryGroups;
            setSelectedIg(val)
            fetchSi(val)
        }

        if(data && data.subIndustries  && data.subIndustries.length > 0){
            setSelectedSi(data.subIndustries)
        }
     },[data])

    

     const fetchSectors = ()=>{
        Axios.get(
            Helpers.apiURL({
                uri:"api/gics/sectors",
            })
        )
        .then( (response) => {
            const result  = response.data || {};    
            setSectorLoaded(true)
            setSectorOptions(result && Helpers.makeSelectOptions(result));
            localStorage.setItem("gSectors",JSON.stringify(Helpers.makeSelectOptions(result)))
        })
        .catch((err) => {
       
        });
    }


    const handleSelectChange = (e,name) =>{
      
        if(name == 'sectors'){
            if(!Helpers.isEmpty(e)){
                setSelectedSectors(e);
                fetchIG(e)
            }else{
                setSelectedSectors([]);
                setIgOptions([]);
                setIndustriesOptions([]);
                setSiOptions([]);
            
                setSelectedIg([]);
                setSelectedIndustries([]);
                setSelectedSi([]);
            }
            
        }else if(name == 'industryGroups'){
            setSelectedIg(e);
            fetchIndustries(e)
        }
        else if(name == 'industries'){
            setSelectedIndustries(e)
            fetchSi(e)
        }else if(name == 'subIndustries'){
            setSelectedSi(e)
        }
        else{
            console.warn("No element has been rendered!")
        }
        onChangeMeta(name,e)
    }

    const resetValues = ()=>{
        setIgOptions([]);
        setIndustriesOptions([]);
        setSiOptions([]);
    }
    const fetchIG = (e) =>{
            let sectors = !Helpers.isEmpty(e) ? e.map((e)=>e.value).join(",")  : null;
            if(sectors){
                setLoading(true);
                Axios.get(
                    Helpers.apiURL({
                        uri:"api/gics/industry-groups",
                        query:{"sector-ids":sectors}
                    })
                )
                .then( (response) => {
                    const result  = response.data || {};    
                    setSectorLoaded(true)
                    setIgOptions(result && Helpers.makeSelectOptions(result)) 
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
            }
           
    }

    const fetchIndustries = (e) =>{
        let igs = !Helpers.isEmpty(e) ? e.map((e)=>e.value).join(",")  : null;
            
            if(igs){
                setLoading(true);
                Axios.get(
                    Helpers.apiURL({
                        uri:"api/gics/industries",
                        query:{"industry-group-ids":igs}
                    })
                )
                .then( (response) => {
                    const result  = response.data || {};    
                    setIndustriesOptions(result && Helpers.makeSelectOptions(result)) 
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                });
            }
    }

    const fetchSi = (e) =>{
        let inds = !Helpers.isEmpty(e) ? e.map((e)=>e.value).join(",")  : null;
        
        if(inds){
            setLoading(true);
            Axios.get(
                Helpers.apiURL({
                    uri:"api/gics/sub-industries",
                    query:{"industry-ids":inds}
                })
            )
            .then( (response) => {
                const result  = response.data || {};    
                setSiOptions(result && Helpers.makeSelectOptions(result)) 
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
        }
    }

   
    return (
        <React.Fragment key={"parentMeta"}>
               <LoadingOverlay>
                    <Loader fullPage loading={loading}/>
                </LoadingOverlay>
                 {!Helpers.isEmpty(options) &&
                    options.map((item,i)=>(
                        ALL_OPTIONS[item] &&  !Helpers.isEmpty(ALL_OPTIONS[item].name) &&
                            <React.Fragment key={ALL_OPTIONS[item].name+"parent"}>
                                <EditMultiSelect 
                                    keyVal={ALL_OPTIONS[item].name}
                                    name={ALL_OPTIONS[item].name}
                                    headingClass={ALL_OPTIONS[item].headingClass}
                                    title={ALL_OPTIONS[item].displayName}
                                    value={ALL_OPTIONS[item].value}
                                    options={ALL_OPTIONS[item].options}
                                    required={true}
                                    onChange={(e)=>handleSelectChange(e,ALL_OPTIONS[item].name)}
                                    additionalClassName={ALL_OPTIONS[item].additionalClassName}
                                />
                                {error && error[ALL_OPTIONS[item].name] && <small class="text-danger errorMsgCustom" key={ALL_OPTIONS[item].name+'error'}>{error[ALL_OPTIONS[item].name]}</small>}
                            </React.Fragment>

                    ))
                        
            }
          
        </React.Fragment>
    )
}




export default memo(MetaEdit);
