import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link ,withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import store from '../../store';
import { getPackageDetails } from '../../actions/packageAction';
import Helpers from '../../shared/custom';
import {login, fetchUserInfo , removeError} from '../../actions/authAction';
import Axios from 'axios';
import config from '../../shared/config';

import ModalBox from '../ui/ModalBox';


class ActionCard extends Component {
    constructor(props){
        super(props);
        this.state = {
            privileges:{},
            localProps:{},
            user:{},
            userMainDetails:{},
            userLoaded:false,
            businessCategory:{},
            currentSubscription:false,
            openModel:false,
            regStatus:null
        }
        store.subscribe(() => {
            var storeState = store.getState();
            let storeAuth = storeState.auth.isAuthenticated;
            this.setState({
                    auth:storeAuth,
                    userMainDetails:storeState.auth.user,
                    businessCategory:storeState.auth.user && storeState.auth.user.businessCategory ? storeState.auth.user.businessCategory : {},
                    currentSubscription:storeState.auth.user.currentSubscription,
                })
        })
      }

      componentDidMount(){
        let currentPath = this.props.location;
        let construtedPath = currentPath.pathname+currentPath.search;
    
        let menuList = document.querySelectorAll('.nav-link');
        menuList.forEach((elm)=>{
            if(elm.getAttribute("href") == construtedPath){
                elm.classList.add('active');
            }else{
                elm.classList.remove('active');
            }
        });
      }
    
      getUserDetails(nextProps){
        var storeState = nextProps;   
        let storeUri = storeState.auth.user.uri;
    
        this.setState({
            storeUser:storeState.auth && storeState.auth? storeState.auth.user.fullName :"",
            storeUri:storeUri
        },()=>{
            let profileDetails = localStorage && localStorage.profile ? JSON.parse(localStorage.profile) : {};
            if(!Helpers.isEmpty(this.state.storeUri) && Helpers.isEmpty(profileDetails)){
                Axios.get(
                    Helpers.apiURL({
                        uri:"user/:uri/profile",
                        pathVar:{uri:storeUri}
                    })
                )
                .then( (response) => {           
                    let profileDetails  = response.data.result || null;                
                    this.setState({
                        user:profileDetails,
                        userLoaded:true
                    })
                })
                .catch((err) => { 
                    //Helpers.pushTError("PROFILE_NOT_FOUND");
                });
            }else{
                this.setState({
                    user:profileDetails,
                })
            }
        })
       
    }

    openPostModel=()=>{
        this.props.openPost()
    }

    openModelFunc = ()=>{
        this.setState({
            openModel:true
        })
    }
    
      componentWillReceiveProps(nextProps){
        let store = nextProps;
        let storeState = nextProps;
        let packageDetails = nextProps.packageDetails;
        let privileges = packageDetails.privileges;
    
        if(JSON.stringify(nextProps) != JSON.stringify(this.state.localProps)){
            let userDetails = nextProps.auth.user;

            if(!Helpers.isEmpty(userDetails.pageDetails)){
                var pageDetails = userDetails.pageDetails;
                this.setState({
                    pageDetails:pageDetails,
                    viewBtn:true,
                    viewProfile:pageDetails && pageDetails.pageDto && pageDetails.pageDto.regStatus && pageDetails.pageDto.regStatus.value == "PUBLISHED" ? true : false,
                    regStatus:pageDetails && pageDetails.pageDto && pageDetails.pageDto.regStatus && pageDetails.pageDto.regStatus.value ? pageDetails.pageDto.regStatus.value  : null
                })
            }

            if(!Helpers.isEmpty(privileges)){
                this.setState({
                    privileges:privileges,
                    localProps:nextProps
                })
            }
            // let userLoaded = this.state.userLoaded;
            // if(!userLoaded){
            //     this.getUserDetails(nextProps)
            // }
        }
       
        if(storeState.auth.isAuthenticated){
            let profileDetails = localStorage && localStorage.profile ? JSON.parse(localStorage.profile) : {};
            this.setState({user:profileDetails})
        }
      }

    render() {

        let privileges = this.state.privileges;
        let regStatus = this.state.regStatus;
        let user = this.state.user;
         let profilePicUrl = !Helpers.isEmpty(user.profilePic)
            ? Helpers.isEmpty(regStatus) ? Helpers.dataPath(user.profilePic.publicUri) 
            : !Helpers.isEmpty(regStatus) && regStatus == "PUBLISHED" ? Helpers.dataPath(user.profilePic.publicUri) : Helpers.dataPath(user.profilePic.uri)
            : Helpers.dataPath(config.defaultUserImage);
        let userUri = user && user.user && user.user.uri ? user.user.uri : "";

        let { currentSubscription , openModel} = this.state;
        let plan = currentSubscription ? currentSubscription.toLowerCase() : {}

       
        return (
            <>
            <ModalBox isOpen={openModel} className={"upgradeModel"}>
                <h2 className="text-center font-22">In order to create an event/opportunity you need to upgrade</h2>
                <p className="m-t-30 text-center">Choose your preferred option below to proceed</p>
                <div className="float-left col-md-12 m-t-20 text-center">
                        <div className="float-left col-md-6">
                          <Link className="btn btn-white transperantBtn" to={"/create-company/start"}>Upgrade to Company</Link>
                        </div>
                        <div className="float-left col-md-6">
                           <Link className="btn btn-info" to={"/create-company/start"}>Upgrade to Startup</Link>
                        </div>
                </div>
            </ModalBox>

            <div className="card card-body">
                <div className="row">
                    <div className="col-sm-2  p-r-0">
                        <img src={profilePicUrl} className="img-circle m-t-5 text-center-m" width="50" height="50" />
                    </div>
                    <div className="col-sm-10 p-l-0">
                        <button className="btn-post" onClick={this.openPostModel}>What’s happening?</button>
                    </div>
               </div> 
               <hr/>
               <div className="row">
                     <div className="col-md-4">
                                        <Link to="/create-company/start">
                                            <div className="text-theme font-12">
                                                <i className="material-icons adjusted-icon">business</i>
                                                <span className="icon-text text-theme">&nbsp;Create New Company</span>
                                                {/* <div className="profile-status online m-l-20 font-12">Create New Company</div>  */}
                                            </div>
                                        </Link>
                    </div>
                   <div className="col-md-4">
                                {
                                plan == "basic" ? 
                                     <a onClick={this.openModelFunc}>
                                        <div className="text-theme font-12">
                                            <i className="mdi mdi-calendar adjusted-icon text-blue"></i> 
                                            <span className="icon-text text-theme">&nbsp;Create New Event</span>
                                            {/* <div className="profile-status online font-12 float-left"> Create New Event</div>  */}
                                        </div>
                                    </a>
                                :
                                <Link to="/create-event/start">
                                    <div className="text-theme font-12">
                                        <i className="mdi mdi-calendar adjusted-icon"></i> 
                                        <span className="icon-text text-theme">&nbsp;Create New Event</span>
                                        {/* <div className="profile-status online font-12 float-left"> Create New Event</div>  */}
                                    </div>
                                </Link>
                                }
                    </div>
                    <div className="col-md-4 p-r-0">
                            {
                             plan == "basic"  ? 
                                // <a onClick={this.openModelFunc}>
                                //     <div className="text-theme font-12">
                                //         <i className="mdi mdi-trending-up adjusted-icon text-blue"></i>
                                //         <span className="icon-text text-theme">&nbsp;Create New Opportunity</span>
                                //         {/* <div className="profile-status online font-12 float-left"> Create New Opportunity</div>  */}
                                //     </div>
                                // </a>
                           <Link to="/create-opportunity/start">
                                <div className="text-theme font-12">
                                    <i className="mdi mdi-trending-up adjusted-icon"></i>
                                    <span className="icon-text text-theme">&nbsp;Create New Opportunity</span>
                                    {/* <div className="profile-status online font-12 float-left"> Create New Opportunity</div>  */}
                                </div>
                            </Link>
                                :
                            <Link to="/create-opportunity/start">
                                <div className="text-theme font-12">
                                    <i className="mdi mdi-trending-up adjusted-icon"></i>
                                    <span className="icon-text text-theme">&nbsp;Create New Opportunity</span>
                                    {/* <div className="profile-status online font-12 float-left"> Create New Opportunity</div>  */}
                                </div>
                            </Link>
                             }
                   </div>
                 
                    {/* <div className="col-md-3">
                    
                    </div> */}
               </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = (state) =>({
    packageDetails:state.packageDetails,
    auth:state.auth,
    errors:state.errors
});

export default connect(mapStateToProps,{getPackageDetails,fetchUserInfo,login})(withRouter(ActionCard));