import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import store from '../../store';
import { connect } from 'react-redux';
import { getMetaData } from '../../actions/metaAction';
import Helpers from '../../shared/custom';
import FundingRound from './FundingRound';
import Axios from 'axios';
import Loader from '../ui/Loader';

class Funding extends Component {

  constructor(props){
      super(props);
      this.state = {
          loading:true,
          isEditable:false,

          uri:undefined,
          fundingRows:[],
          typeOptions:[],
          stageOptions:[],
          investorTypeOptions:[],
      }
      
      store.subscribe(() => {
        var storeState = store.getState();
        this.setMetaOptions(storeState.meta.metaList);
      })
      
      this.setMetaOptions = this.setMetaOptions.bind(this);
      this.addFunding = this.addFunding.bind(this);
      this.fetchFunding = this.fetchFunding.bind(this);
      this.handleError = this.handleError.bind(this);
  }  

  componentDidMount(){    
    window.scrollTo(0,0);
    var storeState = store.getState();    
    let meta = storeState.meta;
    if(Helpers.isEmpty(meta.metaList)){
      this.props.getMetaData();
    }else{      
      this.setMetaOptions(meta.metaList);
    }

  }

  setMetaOptions(metaList){
    let typeOptions = [];
    let stageOptions = [];
    let investorTypeOptions = [];
    if(metaList.FUT){
      typeOptions = Helpers.makeSelectOptions(metaList.FUT);
    }

    if(metaList.FUS){
      stageOptions = Helpers.makeSelectOptions(metaList.FUS);
    }

    if(metaList.ITY){
      investorTypeOptions = Helpers.makeSelectOptions(metaList.ITY);
    }

    this.setState({
      typeOptions:typeOptions,
      stageOptions:stageOptions,
      investorTypeOptions:investorTypeOptions
    })

  }

  componentWillReceiveProps(nextProps){
    let newUri = nextProps.uri;
    let currentUri = this.state.uri;

    if(!currentUri || currentUri != newUri){
      this.setState({
        uri:newUri
      },()=>{
        this.fetchFunding();
      })
    }
  }

  fetchFunding(){
    let uri = this.state.uri;
    if(!uri){
      return false;
    }

    Axios.get(
      Helpers.apiURL({
        uri:"company/:uri/funding-rounds",
        pathVar: {uri:uri}
      })
    )
    .then((response) => {
      let result = response.data.result || [];      
      this.setState({
        loading:false,
        isEditable:true,
        fundingRows:result
      })      
    })
    .catch((response) => {
      this.handleError(response);
    })
  }

  handleError(response){    
    this.setState({
      loading:false,
      isEditable:false,
      fundingRows:[]
    })
  }
  addFunding(){
    //add more here
    let rows = this.state.fundingRows;
    rows.push({uniqueId:undefined})

    this.setState({
      fundingRows:rows
    })
  }

  render() {
    let fundingRows = this.state.fundingRows;
    let typeOptions = this.state.typeOptions;
    let stageOptions = this.state.stageOptions;
    let investorTypeOptions = this.state.investorTypeOptions;
    let uri = this.state.uri;
    let isEditable = this.state.isEditable;


    return (
      <div>
          <h5 className="text-black">Funding Rounds</h5>
          {this.state.loading?
              <div>
                <center>
                  <Loader />
                </center>
              </div>
            :
            <div>
                {fundingRows.map((row,index)=>(
                    <div key={"FR-"+index}>
                      <FundingRound 
                        identify={"FR-"+index}
                        uri={uri}
                        fundingRound={row}
                        typeOptions={typeOptions}
                        stageOptions={stageOptions}  
                        investorTypeOptions={investorTypeOptions}
                      />                
                    </div>              
                  ))
                }
                <div className="grayDiv o-x">
                  {isEditable ? 
                    <div className="m-a text-center col-12 m-b-10">
                        <a href="javascript:void(0)" className="text-default custom-plus-icon" onClick={this.addFunding}></a>
                        <div className="font-12 font-medium">Add Funding Rounds</div>
                    </div>
                  :
                    <div className="m-a text-center col-12">
                        <div className="m-t-20 m-b-20"> 
                            {Helpers.getMessage("FUNDING_ROUNDS_NOT_ACCESSIBLE")}
                        </div>                        
                    </div>
                  }
                  
                </div> 
            </div>
          }
          

                 
      </div>
    )
  }
}

export default connect(null,{ getMetaData }) (withRouter(Funding));