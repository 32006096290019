import React, { Component } from 'react';
import config from '../../shared/config';
import classNames from 'classnames/bind';
import Axios from 'axios';
import MyPagination from "../ui/MyPagination"
import Helpers from '../../shared/custom';
import store from '../../store';
import { withRouter,Redirect } from 'react-router-dom';
import Follow from '../uiActions/Follow';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


class CompanyByIndustry extends Component {
  constructor(){
    super();
    this.state ={
      companies:[],
      indexedcompanies:[],
      industrys:[],
      pageTitle:'',
      countryIds:[],
      localBusiness:undefined,
      searchCriteria:{},
      pageOfItems: [],
      allItems: [],
      activePage: 1,
      setPageContext:undefined,
      totalRecords: 0,
      industryId:undefined,
      type:null,
      itemsCountPerPage:config.defaultPageSize,
      localProps:{}
    }
    
    this.handlePageChange = this.handlePageChange.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    // this.setupPage = this.setupPage.bind(this);
  }

  handlePageChange(pageNumber) {
    this.setState({activePage: pageNumber}, function(){
        this.updateSearch();
    });
  }

  componentDidMount(){
    var storeState = store.getState();
    var auth = storeState.auth;
    this.setState({
      isLoggedIn:auth.isAuthenticated
    },()=>{
      if(!this.state.isLoggedIn){
        this.props.history.push("/companies")
      }
    })
  }

  componentWillReceiveProps(props){

    var storeState = props;   
    if (!Helpers.isEmpty(storeState.business)){
        let storeBusiness = storeState.business;
        let storeindustryData = storeBusiness.industrys;
        if(!Helpers.isEmpty(storeindustryData)){
            this.setState({
                industrys : storeindustryData
            }, function(){
                //this.setupPage();
            }) 
        }
        
    }

    if(JSON.stringify(props) != JSON.stringify(this.state.localProps)){
      this.setState({type:props.type,localProps:props})
    }

    if (!Helpers.isEmpty(props.locationIds)){
      let locationIds=""
      if(props && props.locationIds){
         locationIds = props.locationIds.join(",");
         this.setState({countryIds:locationIds}, function(){
         this.updateSearch();
        });
      }
    }else{
      this.setState({countryIds:[]});
    }

        
     let industry = this.props.match.params.industry;
     if (!Helpers.isEmpty(industry)){
       this.setState({
                  searchCriteria:props.searchCriteria,
                  setPageContext:props.setPageContext,
                  "industryId":industry}, function(){
       // this.setupPage();
      
        this.updateSearch();
        if (this.state.setPageContext)
           this.state.setPageContext("companyByindustry");
      });
     }else{
      return "";
       
     }
  }

//   setupPage(){
//       let industryId = this.state.industryId;
//       if (!Helpers.isEmpty(this.state.industrys)){
//         let industry = this.state.industrys[industryId];
//         if (!Helpers.isEmpty(industry)){
//             this.setState({"pageTitle":industry.name});
//         }
//       }
//   }


  updateSearch(){
    this.fetchCompanies();
  }

  fetchCompanies = () => {
    let qs = Helpers.generatePaginationQS({"page":this.state.activePage, "length":this.state.itemsCountPerPage, "extraParams":this.state.searchCriteria});
    let { type } = this.state;
    qs.countryIds = this.state.countryIds;
    let industryId = this.state.industryId;
    if(Helpers.isEmpty(industryId)){
        this.setState({companies:[]})
        return
      }
    Axios.get(
        Helpers.apiURL({
            uri:"companies/"+type+"/"+industryId+"/list",  
            query:qs
        })
    )
    .then( (response) => {           
          let companies = response.data.result.aaData || [];
          let totalRecords = response.data.result.iTotalRecords || 0;
          this.setState({
                  companies:companies,
                  totalRecords:totalRecords
                  });
       })
    .catch((err) => {
        
    });
  }


  render() {

    let companies = this.state.companies;
    return (
      <div>
          <div className="card text-left bold-headers">
                <div className="card-body">
                    <h5><b>{this.state.pageTitle}</b></h5>
                      <div className="row col-12">
                        { (!Helpers.isEmpty(companies) && (companies.length > 0))?
                           companies.map((list,index )=> (
                             <div className="col-md-6 m-t-10 companyCard" key={index}>
                              <div className="row col-12 bp-card bp-card-company">
                              <Link to={"/company/"+list.page.uri} className="col-12">
                                <img src={list.page && list.page.image ? Helpers.dataPath(list.page.image) : Helpers.dataPath(config.defaultCompanyImage)} className="float-left col-md-4 p-0" />
                                  <div className="col-md-8 float-left  text-left">
                                      <h5 className="text-info font-12 m-0"> {Helpers.truncate(list.page.name,15)}</h5>
                                      <span className="font-12 p-0">{list.company && list.company.companyType ? list.company.companyType.name :""}</span>
                                  <span className="font-12 p-0">{list.company.primaryindustry ? list.company.primaryindustry.industry.name : (!Helpers.isEmpty(list.company.industrys)?list.company.industrys[0].industry.name:"")}</span>
                                  </div>
                                  <div className="font-bold col-md-2 float-right  p-0 investeraRank">
                                      87
                                  </div>
                              </Link>
                             <div className="clearfix"></div>
                            <div className="col-md-12 p-0 m-t-5 descDiv">
                                <p className="text-left font-12 font-medium">
                                  {list.company.shortDescription ?  Helpers.truncate(list.company.shortDescription,config.defaultDescMaxLength) : Helpers.truncate(list.company.longDescription,config.defaultDescMaxLength)}
                                </p>
                            </div>
                            <div className="col-md-12 m-t-10 font-14 m-t-20 p-0">
                                <Follow uri={list.page && list.page.uri ?list.page.uri:""} masterClassName={"like-comm m-t-20 text-left"} type={"COMPANY"} />
                            </div>
                            </div>
                      </div>
                      ))
                      :
                      <div className="text-center col-12">No Companies Found</div>
                    }
                  </div>

                  <MyPagination
                    activePage={this.state.activePage}
                    totalRecords={this.state.totalRecords}
                    onChange={this.handlePageChange}
                  />
                 </div> 
             </div>      
        
      </div>
    )
  }
}


export default withRouter(CompanyByIndustry)