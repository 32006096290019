import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Helper from '../../shared/custom';
import AuthorDetails from './AuthorDetails';
import CardActions from './CardActions';
import Comments from './Comments';
import CommentShareBtn from './CommentShareBtn';


export const NewStartUp = ({ data }) => {
    const [focusInput, setFocusInput] = useState(false);

    const focusInputFiled = () =>{
        setFocusInput(!focusInput)
    }

    return (
        <div>
            <div className="card">
                <div className="card-body">
                    <div className="col-lg-12 p-0">
                        <AuthorDetails data={data} />
                        <div className="col-lg-3 p-0 float-right text-right">
                            <CardActions data={data} />
                        </div>
                        <div className="clearfix"></div>

                        <div className="col-lg-12 p-0 m-t-40 ">
                            <div className="b-b-2 p-b-5">
                                <span> <i className="fas fa-rocket font-24 vm m-r-5"></i> <Link className="font-bold" to={`${data.author.type}/${data.author.uri}`}>{data.author.name}</Link> Has an Update</span>
                            </div>
                        </div>

                    </div>
                </div>



                <div className="card-body text-left p-t-0">
                    <div className="col-lg-12 p-0  font-14">
                        {data && data.fieldUpdateDto2 ? `${data.author.name} has updated their ${data.fieldUpdateDto2.value}` : "-"}
                    </div>
                    <CommentShareBtn data={data} 
                     showInterested={false}
                     focusInputArea={focusInputFiled} 
                    // updateCounts={this.updateCounts}
                    />
                    <Comments data={data}
                     enableFocus={focusInput}
                    />
                </div>

            </div>
        </div>
    );
};
