import React, { Component } from 'react'
import { withRouter, Redirect } from 'react-router-dom';
import config from '../../shared/config';
import Axios from 'axios';
import { login } from '../../actions/authAction';
import store from '../../store';
import Helpers from '../../shared/custom';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { connect } from 'react-redux';

import queryString from 'query-string'

class Resetpw extends Component {

    constructor(props,context){
        super(props);
        this.state = {
            password:"", 
            confirmPassword:"",      
            resetKey:"",
            email : "",
            loading:false,
            otpMode:false,
            mobile:"",
            showPassword:false,
            passwordType:"password",
        }
       
        this.validateForm    = this.validateForm.bind(this);       
        //this.resetPassword    = this.resetPassword.bind(this);       
        this.handleInputChange = this.handleInputChange.bind(this);
        this.resetPasswordConfirm = this.resetPasswordConfirm.bind(this);
        this.validateUser = this.validateUser.bind(this);
        
    }

    handleInputChange(e){
        this.setState({[e.target.name]:e.target.value});
        var elem = e.target;
        if(elem.value == ""){
            elem.classList.add('invalid-field');
        }else{
            elem.classList.remove('invalid-field');
        }
    }

    
    componentDidMount() {
       // this.validateUser();
        const values = queryString.parse(this.props.location.search);
        let location = window.location.href;
        let resetKey = Helpers.lastSegment(location);
        let mode = Helpers.getQueryParameter("location")
        this.setState({'email':values.email, 'resetKey':resetKey });


        if((this.props.location && this.props.location.mobile)){
            this.setState({
                otpMode:true,
                mobile:this.props.location.mobile
            })
        }
     
    }

    componentWillReceiveProps(nextProps){
        //     if (nextProps.auth !== this.props.auth) {
        //     let loggedIn = nextProps.auth.isAuthenticated;
        //     if(loggedIn){
        //         this.props.history.push("/");
        //         return;
        //     }
           
        // }
     }

    validateUser(){
        var storeState = store.getState();  
        let loggedIn = storeState.auth.isAuthenticated;

        if(loggedIn){
            this.props.history.push("/");
            return;
        }
    }

    resetPasswordConfirm(){
        let { otpMode } = this.state;

        let postData = {
            newPassword:this.state.password          
        }
        if(otpMode){
            postData.resetKey = this.state.otp;
            postData.mobile = this.state.mobile;
        }else{
            postData.resetKey = this.state.resetKey;
            postData.email = this.state.email;
        }

        Axios.post(
            Helpers.apiURL({
                uri: "user/reset-password"
            }), 
            postData
        )
        .then( (response) => { 
            Helpers.pushTSuccess("PASSWORD_UPDATED");
            this.setState({loading:false},()=>{this.props.history.push({pathname:"/auth", activeTab:"login"})});   
        })
        .catch((err) => {
            // let { otpMode } = this.state;
            this.setState({loading:false});
            Helpers.pushError(err.response.data.message)
            // if(otpMode){
            //     Helpers.pushTError("PASSWORD_RESET_ERROR")
            // }else{
            //     Helpers.pushTError("PASSWORD_RESET_ERROR_OTP")
            // }
            
        });

    }

    showPassword = (e)=>{
        e.preventDefault();
        this.setState(prevState =>{
            prevState.showPassword = !prevState.showPassword;
            prevState.passwordType = prevState.showPassword  ? "text" : "password";
            return prevState
        })
      }
      
    validateForm(event){
        event.preventDefault();        
        let password = this.state.password;      
        let confirmPassword = this.state.confirmPassword;    
        let {email,otp} = this.state;

        /*var event = new Event('input', { bubbles: true });*/
  
        //todo: central options should be there
        /*var node = document.getElementById('password');
        node.dispatchEvent(event);
        this.handleInputChange(event)
    
        var node = document.getElementById('confirmPassword');
        node.dispatchEvent(event);
        this.handleInputChange(event)*/

        if(this.state.otpMode){
            if( password == "" || confirmPassword == "" || email == ""){            
                Helpers.pushTError("FORM_EMPTY");
                return false;
            }
        }else{
            if( password == "" || confirmPassword == "" || otp == ""){            
                Helpers.pushTError("FORM_EMPTY");
                return false;
            }
        }
        
      


        if (password !== confirmPassword){
            Helpers.pushTError("PASSWORD_DOES_NOT_MATCH");
            return false;
        }

        this.validationMsg = "";        
        this.setState({
            loading:true,
        })
        this.resetPasswordConfirm();
        event.preventDefault();
    }


    render() {
        let {loading,
            otpMode,
            passwordType,
            showPassword} = this.state;
        
        return (
          <div>
               <LoadingOverlay>
                    <Loader fullPage loading={loading}/>
                </LoadingOverlay>
            <div className="login-register customCard">
                <div className="col-lg-6 m-a auth-card">
                    <div className="card-body m-t-40">
                        <h1 className="m-t-40 font-normal">Reset Password</h1>
                        <p className="font-medium m-t-10">
                         Please enter a new password below
                        </p>
                        <span id="error-message" className="error-message">{this.state.validationMsg}</span>
                        <form className="form-horizontal form-material  m-t-20 was-validated-" id="resetPasswordForm" noValidate onSubmit={this.validateForm}>
                            <div className="form-group m-b-5">
                                {
                                    otpMode ? 
                                    <div className="col-xs-12 col-md-8 col-lg-8 m-a">
                                        <input className="form-control" name="otp"  id="otp" type="text" required=""  placeholder="OTP" onChange={this.handleInputChange}  />
                                        <div className="invalid-feedback" />
                                    </div>
                                    :
                                    <div className="col-xs-12 col-md-8 col-lg-8 m-a">
                                        <input className="form-control" name="email"  id="email" type="text" required=""  placeholder="Email" onChange={this.handleInputChange}  />
                                        <div className="invalid-feedback" />
                                    </div>
                                }
                                 
                            </div>
                            {/* <div className="form-group m-b-5 m-t-20">
                                <div className="col-xs-12 col-md-8 col-lg-8 m-a">
                                    <input className="form-control" name="password"  id="password" type="password" required="" placeholder="New Password" onChange={this.handleInputChange}  />
                                    <div className="invalid-feedback" /> </div>
                            </div> */}

                            <div class="input-group mb-3 pwCont col-xs-12 col-md-8 col-lg-8 m-a">
                                         {/* <div className="col-xs-12 col-md-8 col-lg-8 m-a"> */}
                                        <input className="form-control" type={passwordType} required="" placeholder="New Password" name="password"   name="password" onChange={this.handleInputChange} maxLength="32" />
                                        {/* <small class="text-danger">{errors["password"]}</small> */}
                                        <div className="invalid-feedback"> </div>
                                        <div class="input-group-append">
                                        <button class="input-group-button showPwBtn liteBorder" onClick={this.showPassword}>
                                            {!showPassword ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                                        </button>
                                        </div>
                                        {/* </div> */}
                             </div>

                        
                            <div className="form-group m-b-5  m-t-20">
                                <div className="col-xs-12 col-md-8 col-lg-8 m-a">
                                    <input className="form-control" name="confirmPassword" id="confirmPassword" type="password" placeholder="Confirm Password" required=""  onChange={this.handleInputChange}  />
                                    <div className="invalid-feedback" /> </div>
                            </div>
                        
                            <div className="form-group text-center m-t-20">
                                <div className="col-xs-12 col-lg-8 m-a">
                                    <button className="btn btn-info btn-md btn-block text-uppercase waves-effect waves-light" type="submit">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            
          </div>
        )
      }
}

const mapStateToProps = (state) =>({
    auth:state.auth,
});

export default connect(mapStateToProps,{login})(withRouter(Resetpw))