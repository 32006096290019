import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Sidemenu from '../sidemenu/Sidemenu';
import UserFeedRightPanel from '../homepage/UserFeedRightPanel';

import tableau from "tableau-api";

const vizUrl =  "https://public.tableau.com/views/Investment_Report_Automation/Mobileversion?:embed=y&:display_count=yes";

export default class InvestmentReport extends Component {
    constructor(props){
        super(props);

    }
    componentDidMount(){
        this.initTableau();
       // this.vizResize()
    }

  

    initTableau = ()=>{
        const options = {
            hideTabs: true,
            width: "100%",
            maxWidth: "500px",
            height: "880px",
            site_root:"",
            host_url:"https%3A%2F%2Fpublic.tableau.com%2F",
            embed_code_version:"3",
        };
        
        let vizContainer = this.vizContainer;
        
 
        let viz = new window.tableau.Viz(vizContainer, vizUrl, options);
      
    }

    render() {
        return (
            <div>
                <div ref={div => this.vizContainer = div} className="col-md-12"></div>
            </div>
        )
    }
}
