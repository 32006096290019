import React, { Component } from 'react';
import Axios from 'axios';
import Helpers from '../../shared/custom';

export default class Role extends Component {

  constructor(props){
      super(props);
      this.state={
          roles:[],
          selectedRole:null,
      }
      this.handleRoles = this.handleRoles.bind(this);
      this.onCheck = this.onCheck.bind(this);
  }  
  handleRoles(){

     let payload = {}
     payload.id =  this.state.selectedRole;
     
     Axios.post(
        Helpers.apiURL({ 
          uri:"user/role/add",
        }),{
            id:payload.id
        })
      .then((response) => { 
    
        this.props.history.push('/register/loading-page'); 
       })
      .catch((response) => { 
        
      });
    
  }

  componentDidMount(){
    this.getRoles()
  }

  
  onCheck(event){      
      let userUniqueId = event.target.getAttribute("data-id");
      let id = event.target.value;
      this.setState({
          selectedRole:id,
          userUniqueId:userUniqueId
      });
  }
  getRoles(){
    Axios.get(
        Helpers.apiURL({
            uri:"user/roles/list ",
        })
    )
    .then( (response) => {
        let roles  = response.data.result || [];  
        this.setState({
            roles:roles
        })
    })
  }


  render() {
    let roles = this.state.roles;
    return (
      <div>
           <div className="grid-select">
            <div className="row p-20">
               <div className="col-lg-12 col-md-12 text-left font-bold">
               <h1 className="col-md-10 float-left">Lastly, what is your main role on Investera?</h1>
               <button className="float-right m-r-20 btn waves-effect waves-light btn-info" onClick={this.handleRoles}>Continue</button>
               </div>
            </div>
            <div className="row p-20" id="">
                  {roles.map((role, index) => ( 
                    <div className="col-lg-4 col-xlg-4 col-md-4 col-sm-12 roles" key={"roles"+index}>
                        <input type="radio" name={"role"} id={role.id+"id"} value={role.id} data-id={role.id} className="hidden" onChange={this.onCheck}/>
                        <label className="col-md-12 p-0 font-bold" htmlFor={role.id+"id"}>
                        <div className="card m-0">
                        <div className="card-body role-box">
                            <div className="overlay">
                               <div>
                                    <h5 className="font-bold font-24">{role.name}</h5>
                                    <span className="font-14">{role.description}</span>
                                </div>
                            </div>
                            
                            </div>
                          </div>
                        </label>
                   </div>
                  ))}
              </div>
            </div>
        
      </div>
    )
  }
}
