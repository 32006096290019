import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import TypeAHead from "../wigets/TypeAHead";
import AddNewInvestor from './AddNewInvestor';
import FundingRoundInvestors from './FundingRoundInvestors';
import config from '../../shared/config';
import currencies from '../../shared/currency';
import EditSelect from '../ui/EditSelect';
import Helpers from '../../shared/custom';
import Select from 'react-select';
import Axios from 'axios';
import STR from '../../shared/strings';

export default class FundingRound extends Component {

  constructor(props){
      super(props);
      this.state={
          showForm :false,
          date:new Date(),
          addNewInvestor:false,
          addInvestor:false,
          showTable:false,
          rows:[1],

          unmount:false,
          loading:false,
          fundingRound:{},
          editFunding: true,

          uniqueId:undefined,
          announcedDate:undefined,
          fundingType:null,
          fundingStage:null,
          preMoneyValuation: undefined,
          amountRaised: undefined,
          currency:null,
          equityOnlyFunding:false,

      }

      this.showForm = this.showForm.bind(this);
      this.addNewInvestor = this.addNewInvestor.bind(this);
      this.showTable = this.showTable.bind(this);
      
      this.handleSelectChange = this.handleSelectChange.bind(this);
      this.handleFieldValueChange = this.handleFieldValueChange.bind(this);
      this.handleAnnouncedDateChange = this.handleAnnouncedDateChange.bind(this);
      this.handleNewInvestor = this.handleNewInvestor.bind(this);
      this.handleCancelNewInvestor = this.handleCancelNewInvestor.bind(this);

      this.validateForm = this.validateForm.bind(this);
      this.saveFundindRound = this.saveFundindRound.bind(this);
      this.setFundingRound = this.setFundingRound.bind(this);
      this.editFunding = this.editFunding.bind(this);
      this.deleteFunding = this.deleteFunding.bind(this);
      this.cancelEditing = this.cancelEditing.bind(this);
      this.unmount = this.unmount.bind(this);
  }  

  componentDidMount(){    
    this.setFundingRound(this.props.fundingRound);
  }

  setFundingRound(fundingRound){
    this.setState({
      uniqueId:fundingRound.uniqueId,
      announcedDate:fundingRound.date?Helpers.getDateInInputFormat(fundingRound.date,true):undefined,
      fundingType:Helpers.makeSelectOption(fundingRound.fundingType),
      fundingStage:Helpers.makeSelectOption(fundingRound.fundingStage),
      preMoneyValuation: fundingRound.preMoneyValuation,
      amountRaised: fundingRound.amountRaised,
      currency:Helpers.makeSelectOption(fundingRound.currency),
      equityOnly:fundingRound.equityOnlyFunding,
    })
    
    if(fundingRound.uniqueId){
      this.setState({        
        editFunding:false,
      })
    }
  }

  unmount(){
    this.setState({
      unmount:true
    })
  }

  showForm(){
      this.setState({
          showForm:this.state.showForm?false:true
      },()=>{
          if(!this.state.showForm){
                this.setState({
                    rows:[1]
                })
          }
      })
  }

  showTable(){
    this.setState({
        showTable:this.state.showTable?false:true
    })
  }

  editFunding(){
    this.setState({
      editFunding:true,
    })
  }

  addNewInvestor(){
    this.setState({
         addNewInvestor:this.state.addNewInvestor?false:true
    })
  }

  handleCancelNewInvestor(){
    this.setState({
      addNewInvestor:false
    })
  }
  handleSelectChange(option,e){   
    let name = e.name;
    this.setState({[name]:option});
  }

  handleFieldValueChange(e){        
    let name = e.target.name;
    let value = e.target.value;    
    if(name){
        this.setState({[name]:value});
    }
  }

  handleAnnouncedDateChange(date,e){
    this.setState({announcedDate:date})
  }

  validateForm(){
    let isValid = true;
    let message = [];
    
    if(!this.state.announcedDate){
        isValid = false;
        message.push("ANNOUNCED_DATE_REQUIRED");
    }

    if(Helpers.isEmpty(this.state.fundingType)){
      isValid = false;
      message.push("FUNDING_TYPE_REQUIRED");
    }

    if(Helpers.isEmpty(this.state.fundingStage)){
      isValid = false;
      message.push("FUNDING_STAGE_REQUIRED");
    }

    /*Liju: Disabling because team does not have any info as of now
    if(Helpers.isEmpty(this.state.preMoneyValuation)){
      isValid = false;
      message.push("PRE_MONEY_VALUATION_REQUIRED");
    }*/

    /*if(Helpers.isEmpty(this.state.amountRaised)){
      isValid = false;
      message.push("MONEY_RAISED_REQUIRED");
    }*/

    // if(!this.state.currency || Helpers.isEmpty(this.state.currency.value)){
    //   isValid = false;
    //   message.push("CURRENCY_REQUIRED");
    // }

    if(message.length > 0){
        Helpers.pushTError(message);
        return isValid;
    }
    
    return isValid;
    }

  saveFundindRound(){
    let isValid = this.validateForm();
    if(!isValid){
      return false;
    }

    let payLoad = {
      "pageUri":this.props.uri,
      "preMoneyValuation":this.state.preMoneyValuation,
      "amountRaised": this.state.amountRaised,
      "date": Helpers.getDateInInputFormat(this.state.announcedDate),
      "equityOnlyFunding": this.state.equityOnlyFunding,
      "fundingStage": {
        "id": this.state.fundingStage.value
      },
      "fundingType": {
        "id": this.state.fundingType.value
      }
    }

    if(!Helpers.isEmpty(this.state.currency)){
      payLoad.currency = {"id":this.state.currency.value};
    }
    
    this.setState({loading:true})
    if(this.state.uniqueId){
      payLoad.uniqueId = this.state.uniqueId;

      Axios.put(
        Helpers.apiURL({
            uri:"funding-round"
        }),
        payLoad
      )
      .then( (response) => {
          this.setState({
            loading:false,
            editFunding:false
          })
          let fundingRound  = response.data.result || null;                
          if(!fundingRound){
              Helpers.pushTError("NOT_SAVED");
          }else{ 
              Helpers.pushTSuccess("SAVE_SUCCESS");
          }
      })
      .catch((err) => { 
        this.setState({loading:false})
        Helpers.pushTError("GENERIC_ERROR");       
      });

    }else{
      Axios.post(
        Helpers.apiURL({
            uri:"funding-round"
        }),
        payLoad
      )
      .then( (response) => {
          this.setState({loading:false})
          let fundingRound  = response.data.result || null;                
          if(!fundingRound){
              Helpers.pushTError("NOT_SAVED");
          }else{ 
              Helpers.pushTSuccess("SAVE_SUCCESS");                
              this.setState({
                uniqueId:fundingRound.uniqueId,
                editFunding:false
              })
          }
      })
      .catch((err) => { 
        this.setState({loading:false})
        Helpers.pushTError("GENERIC_ERROR");       
      });
    }

  }

  cancelEditing(){
    let uniqueId = this.state.uniqueId;
    if(!uniqueId){
      this.setState({
        unmount:true,
        loading:false,
      })
    }else{
      this.setState({
        editFunding:false,
        loading:false
      })
    }    
  }

  deleteFunding(){
    let uniqueId = this.state.uniqueId;
    if(!uniqueId){
      this.setState({
        unmount:true,
        loading:false,
      })
    }
    this.setState({
      loading:true,
    })
    Axios.delete(
      Helpers.apiURL({
        uri:"funding-round/:uniqueId/company/:uri",
        pathVar: {
          uniqueId:uniqueId,
          uri:this.props.uri
        }
      })
    )
    .then((response) => {
      Helpers.pushTSuccess("FUNDING_ROUND_DELETED");
      this.setState({
        unmount:true,
        loading:false,
      })
    })
    .catch((err) => {
      this.setState({
        loading:false,
      })
    })
  }

  handleInvestorChange(investor){
    
    this.setState({
      addNewInvestor:false
    })

    if(!investor){
      
    }else{
      //add to list
    }
  }
  
  handleNewInvestor(investor){
    //new investor added,
    //update the investor list
    this.setState({
      addNewInvestor:false
    })
  }
  
  render() {

    if(this.state.unmount){
      return (<div></div>)
    }

    let showForm = this.state.showForm;
    let addNewInvestor = this.state.addNewInvestor;
    let rows = this.state.rows;
    let editFunding = this.state.editFunding;

    let fundingType = this.state.fundingType && this.state.fundingType.label ? this.state.fundingType.label : "-";
    let fundingStage = this.state.fundingStage && this.state.fundingStage.label ? this.state.fundingStage.label : "-";
    let preMoneyValuation = this.state.preMoneyValuation ? this.state.preMoneyValuation : undefined;
    let amountRaised = this.state.amountRaised ? this.state.amountRaised : undefined;
    let currency = this.state.currency && this.state.currency.label ? this.state.currency.label : undefined;
    //let equityOnlyFunding = this.state.equityOnlyFunding == true || this.state.equityOnlyFunding == 'true' ? STR.YES : STR.NO;

    let equityOnlyFunding = this.state.equityOnlyFunding && (this.state.equityOnlyFunding == true ||  this.state.equityOnlyFunding == "true")
    

    let deleteAction = {
      proceed:this.deleteFunding
    }

    let cancelAction = {
      proceed:this.cancelEditing
    }

    return (
                    
        <div className="grayDiv">
                    {addNewInvestor?
                        <AddNewInvestor 
                          onAdd={this.handleNewInvestor} 
                          onCancel={this.handleCancelNewInvestor}
                          investorTypeOptions={this.props.investorTypeOptions}
                          identify={this.props.identify}
                          fundingUniqueId={this.state.uniqueId}
                         />
                        :                        
                        <div className="card card-gray m-b-5">
                            <div className="card-body m-t-0 p-t-0">
                                <h4 className="card-title font-medium m-t-10 m-b-0">Funding Round 
                                  {/* <a href="javascript:void(0)" className="float-right" onClick={this.deleteFunding}><i className="fa fa-times text-danger"></i></a> */}
                                </h4>
                                <div className="">
                                  { !editFunding ?
                                
                                    <table className="table font-14">
                                      <thead>
                                            <tr>
                                                <th>Annnounced Date</th>
                                                <th>Funding Type</th>
                                                <th>Funding Stage</th>
                                                <th>Pre Money Valuation</th>
                                                <th>Money Raised</th>
                                                <th>Equity Only</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                      </thead>
                                      <tbody className="bg-white">
                                          <tr>
                                            <td>{Helpers.getDateInInputFormat(this.state.announcedDate)} </td>
                                            <td>{fundingType}</td>
                                            <td>{fundingStage}</td>
                                            <td>{preMoneyValuation ? currency +" "+ Helpers.prettyNumber(preMoneyValuation):STR.UNDISCLOSED}</td>
                                            <td>{amountRaised ? currency +" "+ Helpers.prettyNumber(amountRaised):STR.UNDISCLOSED}</td>
                                            <td>{equityOnlyFunding ? STR.YES : STR.NO}</td>
                                            <td>
                                              {this.state.loading ?
                                                  STR.LOADING
                                              :
                                                <a href="javascript:void(0)" onClick={this.editFunding}>Edit</a>
                                              }                                                        
                                            </td>
                                            <td>
                                              {this.state.loading ?
                                                  STR.LOADING
                                              :
                                                <a href="javascript:void(0)" onClick={(event) => Helpers.actionConfirm(event,deleteAction)}>Delete</a>
                                              }                                                         
                                            </td>
                                          </tr>
                                      </tbody>
                                    </table>
                                

                                    :
                                    <div className="row fundingRound">
                                    
                                    <div className="col-12 p-0">                                      
                                        <div className="col-md-3 col-sm-12 float-left">
                                              <h5 className="m-t-10 m-b-10 font-12 isMandatoryLabel">Annnounced Date</h5>
                                              <DatePicker className="form-control"
                                                  selected={this.state.announcedDate}
                                                  onChange={this.handleAnnouncedDateChange}
                                                  placeholderText="Choose date"
                                                  peekNextMonth
                                                  showMonthDropdown
                                                  showYearDropdown
                                                  dropdownMode="select"
                                                  maxDate={new Date()}
                                              />
                                        </div>
                                        <div className="col-md-3 col-sm-12 float-left">
                                              <h5 className="m-t-10 m-b-10 font-12 isMandatoryLabel">Funding Type</h5>
                                              <Select 
                                                className="font-14 col-md-12 p-0 m-0" 
                                                name="fundingType" 
                                                options={this.props.typeOptions} 
                                                placeholder="Select funding type" 
                                                value={this.state.fundingType} 
                                                onChange={this.handleSelectChange} 
                                              /> 
                                        </div>
                                        <div className="col-md-3 col-sm-12 float-left">
                                              <h5 className="m-t-10 m-b-10 font-12 isMandatoryLabel">Funding Stage</h5>
                                              <Select 
                                                className="font-14 col-md-12 p-0 m-0" 
                                                name="fundingStage" 
                                                options={this.props.stageOptions} 
                                                placeholder="Select funding stage" 
                                                value={this.state.fundingStage} 
                                                onChange={this.handleSelectChange} 
                                              /> 
                                        </div>
                                        <div className="col-md-3 col-sm-12 float-left">
                                              <h5 className="m-t-10 m-b-10 font-12">Pre Money Valuation</h5>
                                              <input className="form-control" type="text" name="preMoneyValuation" value={this.state.preMoneyValuation} onChange={this.handleFieldValueChange} />
                                        </div>
                                    </div>
                                    <div className="col-12 p-0">
                                        <form>
                                        <div className="col-md-3 col-sm-12 float-left">
                                              <h5 className="m-t-10 m-b-10 font-12">Money Raised</h5>
                                              <input className="form-control" type="text" name="amountRaised" value={this.state.amountRaised} onChange={this.handleFieldValueChange}/>
                                        </div>
                                        <div className="col-md-3 col-sm-12 float-left">
                                            <h5 className="m-t-10 m-b-10 font-12">Currency</h5>
                                            <Select 
                                                className="font-14 col-md-12 p-0 m-0" 
                                                name="currency" 
                                                options={currencies} 
                                                placeholder="Select funding currency" 
                                                value={this.state.currency} 
                                                onChange={this.handleSelectChange} 
                                              /> 
                                        </div>
                                        <div className="col-md-3 col-sm-12 float-left">
                                              <h5 className="m-t-10 m-b-10 font-12">Equity Only Funding</h5>
                                              <div className="m-b-10">
                                              <div className="demo-radio-button">
                                                  <div className="float-left col-3 p-0">
                                                      <input name="equityOnlyFunding" type="radio" id={this.props.identify+"EoFuning1"} className="with-gap radio-col-blue" value={true} defaultChecked={equityOnlyFunding ? true : false} onChange={this.handleFieldValueChange} />
                                                      <label htmlFor={this.props.identify+"EoFuning1"} className="l-h-0">Yes</label>
                                                  </div>
                                                  <div className="float-left col-3 p-0">
                                                      <input name="equityOnlyFunding" type="radio" id={this.props.identify+"EoFuning2"} className="with-gap radio-col-blue" value={false} defaultChecked={!equityOnlyFunding ? true : false} onChange={this.handleFieldValueChange} />
                                                      <label htmlFor={this.props.identify+"EoFuning2"} className="l-h-0">No</label>
                                                  </div>
                                              </div>
                                              </div>
                                        </div> 
                                        
                                        </form>                                          
                                        <div className="col-md-3 col-sm-12 float-left">
                                              <div className="m-t-20 float-right">
                                                  {
                                                    this.state.loading ?
                                                      <button className="btn btn-white m-t-5">{STR.SAVING}</button>
                                                    :
                                                    <div>
                                                      <button className="btn btn-info m-t-5 m-r-10" onClick={this.saveFundindRound}>Save</button>
                                                      <button className="btn btn-danger m-t-5" onClick={(event) => Helpers.actionConfirm(event,cancelAction)}>Cancel</button>
                                                    </div>
                                                      
                                                  }
                                              </div>
                                        </div>
                                    </div>
                                    </div>

                                  }
                                  </div>
                                  {this.state.uniqueId? <hr/> : ""}
                                  {this.state.uniqueId? 
                                    <FundingRoundInvestors 
                                      identify={this.props.identify}
                                      fundingUniqueId={this.state.uniqueId}
                                      fundingCurrency={this.state.currency}
                                      investorChange={this.handleInvestorChange}
                                      addNewInvestor={this.addNewInvestor}
                                    />
                                    :
                                    ""
                                  }

                            </div>
                            <hr/>
                        </div>
                    }                    
        </div>        
               
    )
  }
}
