import React, { Component } from 'react';
import Helpers from "../../../shared/custom";
import Axios from "axios";
import config from '../../../shared/config';
import StarRating from "./StarRating";
import Interest from "../../uiActions/Interest";
import SmartLink from "../../uiActions/SmartLink";

import { withRouter } from "react-router-dom";
import InfoText from "../../ui/InfoText";
import BatchList from "../../ui/BatchList";
import ExternalLink from "../../ui/ExternalLink";
import Payment from './Payment';
import Review from './Review';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import StarRatings from 'react-star-ratings';
import Upgrade from './Upgrade';
import { getPackageDetails } from '../../../actions/packageAction';
import { connect } from 'react-redux';

export class OppContent extends Component {
    constructor(props){
        super(props);
        this.state ={
            uri:undefined,
            id:undefined,
            showContactDetails:false,
            contactPersons:{},
            loading:false,
            isPublished:false,
            modal:false,
            ranking:0,
            rankingDetails:{},
            reviewId:null,
            userRating:0,
            description:null,
            rankingMainId:null,
            owner:false,
            noOfRatings:0,
            fullRatingDetails:null,
            privileges:{},
            localProps:{},
            permissionCode:null
        }
        this.myDivToFocus = React.createRef()
    }

    componentWillReceiveProps(nextProps){
        let id = nextProps.uri;
        let currentId = this.state.id;
        let packageDetails = nextProps.packageDetails;
        let privileges = packageDetails.privileges;
     
        if(JSON.stringify(this.state.localProps) != JSON.stringify(nextProps)){
            this.setState({
                privileges:privileges,
                localProps:nextProps
            });
        }

        if(currentId != id){
            //console.log(nextProps)
            this.getOppInfo(id);
            this.fetchRanking(id);
            this.setState({
                id:id,
                page:{},
                isEditable:false,
                editedInfo:{},
                fullRatingDetails:[],
                privileges:privileges
            });
        } 
     }

     fetchRanking =(id)=>{
        Axios.get(
            Helpers.apiURL({
                uri:`api/opportunity/${id}/rating`,
                pathVar:{id:id}
            })
        )
        .then( (response) => {
            let data  = response.data || [];  
            this.setState({
                ranking:data && data.rating ? data.rating : 0,
                noOfRatings:data && data.noOfRatings ? data.noOfRatings : 0,
            })
          
        })
     }

     scrollToDiv=()=>{
        if(this.myDivToFocus.current){
          this.myDivToFocus.current.scrollIntoView({ 
              behavior: "smooth", 
              // block: "nearest",
          })
        }
      }

     getOppInfo = (id)=>{
        let isPublished = Helpers.getQueryParameter("isPublished");
        let uri = (isPublished === null ||  isPublished === 'true') ?  `api/opportunity/view/${id}` :  `api/opportunity/${id}`;

        this.setState({
            isPublished:isPublished
        })
        Axios.get(
            Helpers.apiURL({
                uri:uri,
            })
        )
        .then( (response) => {
            let data  = response.data || [];  
            let overview = data && data.page ? data.page : {};
            this.setState({
                page:data,
                uri:data.uri,
                permissionCode:config.opportunityTypes.filter(x=> x.value == data.oppType)[0].permission
               // rating:overview && overview.rating  ? overview.rating :0
            })

            if(isPublished != null && isPublished == 'false'){
                this.setState({
                    showContactDetails: true,
                    contactPersons:data.contactPersons[0],
                })
            }
          
        })
     }

     showContact =()=>{
         let id = this.state.id;
         this.setState({loading:true})
         Axios.get(
            Helpers.apiURL({
                uri:`api/opportunity/view/${id}/contact`,
            })
        )
        .then( (response) => {
            let data  = response.data || [];  
            this.setState({
                contactPersons:data,
                uri:data.uri,
                showContactDetails:true,
                loading:false
            })
          
        })
     }

     saveRatings = ()=>{
         var payload = {};
         let reviewId = this.state.reviewId;
         let rankingMainId = this.state.rankingMainId;
         payload.opportunity = {"id":this.state.id};
         payload.review = {
             "rating":this.state.userRating,
             "description":this.state.description,
             "id":reviewId,
           
         };
         payload.id = this.state.rankingMainId;
         payload.entityType =  "OPPORTUNITY";
         this.setState({
             loading:true
         })

         if(rankingMainId){
            Axios.put(
                config.api("api/opportunity/user-review/"),
                payload)
            .then( (response) => {
                let data  = response.data.review || [];  
                Helpers.pushSuccess("Your review has been updated successfully!");
                this.setState({
                    rankingDetails:data,
                    reviewId:data.id,
                    userRating:data.rating,
                    description:data.description,
                    modal:false,
                    loading:false
                })
            })
            .catch((err) => {
                Helpers.pushError(err.response.data.message)
                this.setState({
                    loading:false
                })
       
            });
         }else{
            Axios.post(
                config.api("api/opportunity/user-review/"),
                payload)
            .then( (response) => {
                let data  = response.data.review || [];  
                Helpers.pushSuccess("Thank you for your valuable review!");
                this.setState({
                    rankingDetails:data,
                    reviewId:data.id,
                    userRating:data.rating,
                    description:data.description,
                    modal:false,
                    loading:false
                })
            })
            .catch((err) => {
                Helpers.pushError(err.response.data.message);
                this.setState({
                    loading:false
                })

            });
         }

     }

     showRatings = ()=>{
       // this.scrollToDiv();
       let id = this.state.id;
       Axios.get( Helpers.apiURL({
            uri:`api/opportunity/user-review/search`,
            query:{
                size:30,
                opportunityIds:id,
                sort:"created"
            }
         }))
        .then( (response) => {
            let allData  = response.data.content;
            if(!Helpers.isEmpty(allData)){
                this.scrollToDiv();
            }
         
           // let data  = response.data.review || [];  
            this.setState({
                fullRatingDetails:allData
            })
        
        })
     }

     triggerRatings =(e)=>{
         this.setState({
            modal:!this.state.modal
         });
         let id = this.state.id;
         Axios.get(
            Helpers.apiURL({
                uri:`api/opportunity/${id}/my-review`,
            })
        )
        .then( (response) => {
            let allData  = response.data;
            let data  = response.data.review || [];  
            this.setState({
                rankingDetails:data,
                reviewId:data.id,
                userRating:data.rating,
                description:data.description,
                rankingMainId:allData.id
            })
          
        })

     }
     onChangeRating =(e)=>{
            this.setState({
                userRating:e
            })
     }

     handlePopupField = (e)=>{
         this.setState({
             [e.target.name]:e.target.value
         })
     }


     

    render() {
        let { page,isEditable,uri ,contactPersons,showContactDetails,loading ,ranking , privileges , permissionCode }  = this.state;
        let image = page && page.coverPhoto && page.coverPhoto.publicUri ? Helpers.dataPath(page.coverPhoto.publicUri) : page && page.coverPhoto && page.coverPhoto.uniqueId ?  Helpers.viewFile(page.coverPhoto.uniqueId) : Helpers.dataPath(config.defaultOpportunityIcon);
        let overview = page && page.page ? page.page : {};
        let location = overview && overview.city && overview.country ? overview.city.name+", "+overview.country.name : "";
        let logo = page && page.logo && page.logo.publicUri ? Helpers.dataPath(page.logo.publicUri) : page && page.logo && page.logo.uniqueId ?  Helpers.viewFile(page.logo.uniqueId) : Helpers.dataPath(config.defaultOpportunityIcon);
        if(Helpers.isEmpty(page)){
            return <div></div>
        }

       let owner = page.owner;

        let timeline = page && page.timeline ? page.timeline+" " : "";
        timeline +=  page && page.timelineType ? page.timelineType.label : "";

        let noOfRatings =  this.state.noOfRatings;

       // console.log(privileges,"privileges")
          
      
        return (
            <div className="row bold-headers text-left p-b-30 opp">
                  <Modal isOpen={this.state.modal} toggle={this.triggerRatings} className={""}>
                    <ModalHeader toggle={this.triggerRatings}>
                               Rate & Review
                    </ModalHeader>
                    <ModalBody>
                        <div className="col-md-12 p-1 p-l-0">
                            <h5>Opportunity Rating</h5>
                            <StarRatings
                                    rating={this.state.userRating ? this.state.userRating : 0}
                                    starRatedColor={"#ffcd00"}
                                    changeRating={this.onChangeRating}
                                    numberOfStars={5}
                                    hoverOverStar={true}
                                    starSpacing={"2"}
                                    starDimension={"24px"}
                                    starSelectingHoverColor={"#ffcd00"}
                                    starHoverColor={"#ffcd00"}
                                    name='rating'
                                    isSelectable="false"
                                    svgIconPath="M2 1 h1 v1 h1 v1 h-1 v1 h-1 v-1 h-1 v-1 h1 z"
                                    svgIconViewBox="0 0 5 5" 
                                    />
                        </div>
                        <div className="col-md-12 p-1 p-l-0">
                                <h5>Review</h5>
                                <textarea 
                                    className="form-control smallPlaceholder"
                                    placeholder="Write your detailed review here"
                                    onChange={this.handlePopupField} 
                                    value={this.state.description}
                                    name="description"></textarea>
                        </div>
                        <div className="col-md-12 p-1 p-l-0">
                            <button className="btn btn-info float-right" onClick={this.saveRatings}>Save</button>
                        </div>
                       
                    </ModalBody>
                 </Modal>
                <div className="col-md-12 col-lg-12 col-xlg-12 p-0">
                        <div className="card">
                            <div className="card-body banner-small">
                                <div className="col-lg-12 p-0">
                                    <div className="col-lg-12 p-0 d-flex align-center">
                                        <div className="m-t-0 img-holder">
                                            <img src={image} className="" width="150"/>
                                        </div>
                                        <div className="">
                                              <h2 className="card-title m-t-5 m-b-5 font-18">{page.title}</h2>
                                               <span className="btn oppBtn font-12 m-t-5">
                                                    {page && page.oppTypeLabel ? page.oppTypeLabel  : "--"}
                                                </span>
                                                <p className="m-b-5 m-t-5 font-12"><i className="fa fa-star"></i> {overview && overview.interestCount ? overview.interestCount : "0"} Interested</p>
                                                <p className="m-b-0 font-12"><i className="fa fa-eye"></i> {overview && overview.viewCount ? overview.viewCount : "0"} Views</p>
                                                <p>
                                                        <StarRating masterClass="float-left m-t-2" rating={ranking ? ranking: 0} triggerRatings={this.showRatings}/> {noOfRatings >0 && <span className="float-left font-medium font-14 m-l-5">{`${ranking}/5`}</span>} {noOfRatings >0 &&<span className="float-left font-medium font-14 m-l-5"> {` (${noOfRatings} ${noOfRatings > 1 ? "reviews":"review"})`} </span>}
                                                </p>
                                        </div>
                                    
                                    </div>
                                    <hr/>
                                  
                                </div>
                                {((privileges && privileges[permissionCode]) || owner)  &&
                                        <div className="clearfix">
                                        {page && !page.owner && <a onClick={this.triggerRatings}  className="float-right font-14"><button className="btn btn-dark m-l-10">Rate & Review</button></a>} 
                                        <Interest id={page.id} type={"OPPORTUNITY"} interested={overview._isIntrested} mode="btn" masterClassName="float-right font-14"/>
                                        <SmartLink
                                            showEdit={page.owner}
                                            superOwner={page.owner}
                                            ownerId={1}
                                            masterClassName="float-right font-14"
                                            pageFullUri={"/create-opportunity/start?id="+page.id}
                                        ></SmartLink>
                                        
                                        {/* <a href="#contact"  className="float-right font-14"><button className="btn btn-dark m-l-10">Contact</button></a> */}


                                        </div>
                                }
                               
                            </div>
                        </div>
                        {overview && overview.regStatus.value == "PAYMENT_PENDING" &&
                                <Payment fee={page.fee} currency={page && page.currency ? page.currency.name:"KES"} url={`/opportunity/proceed?targetEntityId=${this.state.id}&paymentCategory=OPPORTUNITY`} />
                        }
                       
                       {/* <Upgrade title="123"/> */}

                        <div className="card para-mb-0" id="details">
                               {(privileges && privileges[permissionCode] || owner) &&  <div class="card-header text-left light-blue-header"><h5 class="m-b-0 m-t-0 text-white">Opportunity Details</h5></div>}
                               
                                { page.oppType =="INVESTMENT" ?
                                   (privileges && privileges["OPP_INV"] || owner)? 
                                     <div className="card-body p-t-0">
                                        {page && page.investmentAmount && <InfoText title="Investment Amount (USD)" text={Helpers.prettyNumber(page.investmentAmount)} childClass="small-cardbox-nb" />} 
                                        {page && page.investmentOffer && <InfoText title="Investment Offer (USD)" text={Helpers.prettyNumber(page.investmentOffer)} childClass="small-cardbox-nb" />} 
                                        {page && page.targetDate && <InfoText title="Target Date" text={page.targetDate} childClass="small-cardbox-nb" />} 
                                        {page && page.pitchDeck &&
                                          <React.Fragment>
                                              <h6>Pitch Deck</h6>
                                              <div className="attachments">
                                                <a href={Helpers.viewFile(page.pitchDeck.uniqueId)} target="_blank">
                                                    <i class="fa fa-file-pdf-o font-20"></i> <span className="font-12">{page.pitchDeck.name}</span>
                                                </a> 
                                             </div>
                                          </React.Fragment>
                                        }
                                        {page && page.companyName && <InfoText title="Company Name" text={page.companyName} childClass="small-cardbox-nb" />} 
                                     </div>
                                    :
                                    <Upgrade title="Opportunity Details"/>
                                    :
                                    ""
                            
                                }




                                
                               
                                { page.oppType =="PARTNERSHIP" ?
                                   (privileges && privileges["OPP_PRTNRSHP"] || owner) ? 
                                    <div className="card-body p-t-0">
                                    {/* Partnership */}
                                        {page && page.companyName && <InfoText title="Company Name" text={page.companyName} childClass="small-cardbox-nb" />} 
                                        {page && page.logo && 
                                            <div className="">
                                            <h6>Logo</h6>
                                            <div className="m-t-0 img-holder">
                                                    <img src={logo} className="" width="150"/>
                                                </div>
                                            </div>
                                        } 
                                            {page && page.psl && <InfoText title="Products & Services" text={page.psl.map(x=>x.name).join(",")} childClass="small-cardbox-nb" />} 
                                            {page && page.partnershipNature && <InfoText title="Nature of Partnership" text={page.partnershipNature.label} childClass="small-cardbox-nb" />} 
                                            {page && page.noOfPartners && <InfoText title="Number of Partners" text={page.noOfPartners} childClass="small-cardbox-nb" />} 
                                            {page && page.companyBrochure &&
                                            <div className="">
                                                
                                                <h6>Company Brochure</h6>
                                                    <a href={Helpers.viewFile(page.companyBrochure.uniqueId)} target="_blank">
                                                        <i class="fa fa-file-pdf-o font-20"></i> <span className="font-12">{page.companyBrochure.name}</span>
                                                    </a> 
                                                </div>
                                        }
                                            {page && page.qualifications  &&
                                            <div className="">
                                                <h6>Qualifications and Criteria for Partnership Selection</h6>
                                                    <a href={Helpers.viewFile(page.qualifications.uniqueId)} target="_blank">
                                                        <i class="fa fa-file-pdf-o font-20"></i> <span className="font-12">{page.qualifications.name}</span>
                                                    </a> 
                                                </div>
                                        }
                                    </div>
                                    :
                                    <Upgrade title="Opportunity Details"/>
                                    :
                                    ""


                                }
                                   
                                { page.oppType =="JOB" ?
                                   (privileges && privileges["OPP_JOB"] || owner) ? 
                                     <div className="card-body p-t-0">
                                     {/* JOB */}
                                    {page && page.companyName && <InfoText title="Company Name" text={page.companyName} childClass="small-cardbox-nb" />} 
                                    {page && page.businessNature && <InfoText title="Nature of Business" text={page.businessNature} childClass="small-cardbox-nb" />} 
                                    {page && page.jobPosition && <InfoText title="Position being Advertised" text={page.jobPosition.name} childClass="small-cardbox-nb" />} 
                                    {page && page.noOfOpenings && <InfoText title="Number of Employees to be Hired" text={page.noOfOpenings} childClass="small-cardbox-nb" />} 
                                    {page && page.jobDescription && <InfoText title="Job Description" text={page.jobDescription} childClass="small-cardbox-nb" />} 
                                    {page && page.qualifications && <InfoText title="Qualifications and Criteria for Selection" text={page.qualifications} childClass="small-cardbox-nb" />} 
                                    {page && page.submitLastDate && <InfoText title="Last Date for Submission of Application" text={page.submitLastDate} childClass="small-cardbox-nb" />}  
                                    {page && page.advert && 
                                        <div className="">
                                            <h6>Advert on Company Letterhead (Signed & Stamped)</h6>
                                                <a href={Helpers.viewFile(page.advert.uniqueId)} target="_blank">
                                                    <i class="fa fa-file-pdf-o font-20"></i> <span className="font-12">{page.advert.name}</span>
                                                </a> 
                                            </div>
                                    }
                                    </div>
                                    :
                                    <Upgrade title="Opportunity Details"/>
                                    :
                                    ""
                                
                                }
                                   
                                { page.oppType =="TENDER" ?
                                     (privileges && privileges["OPP_TNDR"] || owner) ? 
                                     <div className="card-body p-t-0">
                                        {/* Tender */}
                                        {page && page.companyName && <InfoText title="Company Name" text={page.companyName} childClass="small-cardbox-nb" />} 
                                        {page && page.procuringEntity && <InfoText title="Procuring Entity" text={page.procuringEntity} childClass="small-cardbox-nb" />} 
                                        {page && page.tenderNumber && <InfoText title="Tender No." text={page.tenderNumber} childClass="small-cardbox-nb" />} 
                                        {page && page.tenderName && <InfoText title="Tender Name" text={page.tenderName} childClass="small-cardbox-nb" />} 
                                        {page && page.tenderSecurityAmount && <InfoText title="Tender Security Amount (USD)" text={Helpers.prettyNumber(page.tenderSecurityAmount)} childClass="small-cardbox-nb" />} 
                                        {page && page.submissionDate && <InfoText title="Submission Date & Time" text={page.submissionDate} childClass="small-cardbox-nb" />} 
                                        {page && page.tenderDocument &&
                                            <div className="">
                                                <h6>Tender Document</h6>
                                                    <a href={Helpers.viewFile(page.tenderDocument.uniqueId)} target="_blank">
                                                        <i class="fa fa-file-pdf-o font-20"></i> <span className="font-12">{page.tenderDocument.name}</span>
                                                    </a> 
                                                </div>
                                        }
                                    </div>
                                     :
                                     <Upgrade title="Opportunity Details"/>
                                     :
                                     ""

                                }
                                  
                                  { page.oppType =="MKT_BUYER" ?
                                   (privileges && privileges["OPP_MKT_BUY"] || owner) ? 
                                   <div className="card-body p-t-0">
                                        {page && page.companyName && <InfoText title="Company Name" text={page.companyName} childClass="small-cardbox-nb" />} 
                                        {page && page.businessNature && <InfoText title="Nature of Business" text={page.businessNature} childClass="small-cardbox-nb" />} 
                                        {page && page.psl && <InfoText title="Products/Services to Buy" text={page.psl.map(x=>x.name).join(",")} childClass="small-cardbox-nb" />} 
                                        {page && page.companyNquantitiesame && <InfoText title="Quanitites" text={page.quantities} childClass="small-cardbox-nb" />} 
                                        {page && page.price && <InfoText title="Price Offered (USD)" text={Helpers.prettyNumber(page.price)} childClass="small-cardbox-nb" />} 
                                        {page && page.timeline && <InfoText title="Timelines" text={timeline} childClass="small-cardbox-nb" />} 
                                        {page && page.engagementNature && <InfoText title="Nature of Engagement with Seller" text={page.engagementNature.label} childClass="small-cardbox-nb" />} 
                                        {page && page.psDescription &&
                                            <div className="">
                                                <h6>Detailed Description of the Product/Services to Buy</h6>
                                                    <a href={Helpers.viewFile(page.psDescription.uniqueId)} target="_blank">
                                                        <i class="fa fa-file-pdf-o font-20"></i> <span className="font-12">{page.psDescription.name}</span>
                                                    </a> 
                                                </div>
                                        }
                                        {page && page.sellerQualification &&
                                            <div className="">
                                                <h6>Qualifications and Criteria for Selection of Seller</h6>
                                                    <a href={Helpers.viewFile(page.sellerQualification.uniqueId)} target="_blank">
                                                        <i class="fa fa-file-pdf-o font-20"></i> <span className="font-12">{page.sellerQualification.name}</span>
                                                    </a> 
                                                </div>
                                        }
                                    </div>
                                     :
                                     <Upgrade title="Opportunity Details"/>
                                     
                                     :
                                     ""
                                  }

                                  { page.oppType =="MKT_SELLER" ?
                                        (privileges && privileges["OPP_MKT_SEL"] || owner) ? 
                                        <div className="card-body p-t-0">
                               
                                        {page && page.companyName && <InfoText title="Company Name" text={page.companyName} childClass="small-cardbox-nb" />} 
                                        {page && page.businessNature && <InfoText title="Nature of Business" text={page.businessNature} childClass="small-cardbox-nb" />} 
                                        {page && page.psl && <InfoText title="Products/Services Offered for Sale" text={page.psl.map(x=>x.name).join(",")} childClass="small-cardbox-nb" />} 
                                        {page && page.quantities && <InfoText title="Quanitites" text={page.quantities} childClass="small-cardbox-nb" />} 
                                        {page && page.price && <InfoText title="Price Requested (USD)" text={Helpers.prettyNumber(page.price)} childClass="small-cardbox-nb" />} 
                                        {page && page.timeline && <InfoText title="Timelines" text={timeline} childClass="small-cardbox-nb" />} 
                                        {page && page.engagementNature && <InfoText title="Nature of Engagement with Seller" text={page.engagementNature.label} childClass="small-cardbox-nb" />} 
                                        {page && page.psDescription &&
                                            <div className="">
                                                <h6>Detailed Description of the Product/Services to Sale</h6>
                                                    <a href={Helpers.viewFile(page.psDescription.uniqueId)} target="_blank">
                                                        <i class="fa fa-file-pdf-o font-20"></i> <span className="font-12">{page.psDescription.name}</span>
                                                    </a> 
                                                </div>
                                        }
                                        {page && page.buyerQualification &&
                                            <div className="">
                                                <h6>Qualifications and Criteria for Selection of Seller</h6>
                                                    <a href={Helpers.viewFile(page.buyerQualification.uniqueId)} target="_blank">
                                                        <i class="fa fa-file-pdf-o font-20"></i> <span className="font-12">{page.buyerQualification.name}</span>
                                                    </a> 
                                                </div>
                                        }
                                    </div>
                                     :
                                     <Upgrade title="Opportunity Details"/>
                                     
                                     :
                                     ""
                                  }
                                
                                  

                              
                        </div>

                        <div className="card para-mb-0" id="description">
                            {(privileges && privileges[permissionCode] || owner) ?
                                <>
                                    <div class="card-header text-left light-blue-header"><h5 class="m-b-0 m-t-0 text-white">Description</h5></div>
                                    <div className="card-body p-t-2">
                                    <InfoText title="" text={page && page.description ? page.description : "-"} childClass="small-cardbox-nb" />
                                    </div>
                                </>
                                :
                                <Upgrade title="Description"/>
                             }

                        </div>

                        <div className="card para-mb-0" id="oppInfo">
                            {(privileges && privileges[permissionCode] || owner) ?
                                    <>
                                <div class="card-header text-left light-blue-header"><h5 class="m-b-0 m-t-0 text-white">Opportunity Information</h5></div>
                                <div className="card-body p-t-2">
                                   {location && <InfoText title="Location" text={location} childClass="small-cardbox-nb" noborder={true}/>} 
                                   {page.sectors ? (
                                        <BatchList
                                            title="Sectors"
                                            items={page.sectors}
                                            type="sectors"
                                            noborder={true}
                                            noLink={true}
                                        />
                                        ) : (
                                        <BatchList
                                            title="Sectors"
                                            items={["-"]}
                                            noborder={true}
                                        />
                                        )}
                                        {page.industryGroups ? (
                                        <BatchList
                                            title="Industries Group"
                                            items={page.industryGroups}
                                            type="industryGroup"
                                            noborder={true}
                                            noLink={true}
                                        />
                                        ) : (
                                        <BatchList
                                            title="Industries Group"
                                            items={["-"]}
                                            noborder={true}
                                        />
                                        )}
                                        {page.industries ? (
                                        <BatchList
                                            title="Industries"
                                            items={page.industries}
                                            type="industries"
                                            noborder={true}
                                            noLink={true}
                                        />
                                        ) : (
                                        <BatchList
                                            title="Industries"
                                            items={["-"]}
                                            noborder={true}
                                        />
                                        )}
                                        {page.subIndustries ? (
                                        <BatchList
                                            title="Sub-Industries"
                                            items={page.subIndustries}
                                            noborder={true}
                                            type="subIndustries"
                                            noLink={true}
                                        />
                                        ) : (
                                        <BatchList
                                            title="Sub-Industries"
                                            noborder={true}
                                            items={["-"]}
                                        />
                                        )}
                                   
                                </div>
                                </>
                                :
                                <Upgrade title="Description"/>
                             }
                           </div>

                            <div className="card" id="contact" >
                              {(privileges && privileges[permissionCode] || owner) ?
                                <>
                                    <div class="card-header text-left light-blue-header"><h5 class="m-b-0 m-t-0 text-white">Contact</h5></div>
                                    <div className="card-body p-t-0">
                                        <LoadingOverlay>
                                            <Loader loading={loading}/>
                                        </LoadingOverlay>
                                       {!showContactDetails && <div className="text-center m-t-20"><button className="btn btn-info" onClick={this.showContact}>Show Contact Details</button></div>}
                                       
                                       {showContactDetails && !Helpers.isEmpty(contactPersons) && <React.Fragment>
                                            {contactPersons && contactPersons.firstname &&<InfoText title="Name" text={contactPersons.firstname} childClass="small-cardbox-nb" />} 
                                            {contactPersons && contactPersons.role && <InfoText title="Role" text={contactPersons.role} childClass="small-cardbox-nb" />} 
                                            {contactPersons && contactPersons.mobileCode && <InfoText title="Contact Number" fieldType="phone" text={contactPersons.mobileCode +"-"+contactPersons.mobile} childClass="small-cardbox-nb" />} 
                                            {contactPersons && contactPersons.email && <InfoText  title="Email Address" text={contactPersons.email} childClass="small-cardbox-nb" />} 
                                            {contactPersons && contactPersons.website && 
                                                <ExternalLink
                                                title="Website Address"
                                                link={contactPersons.website}
                                                text={contactPersons.website}
                                                anchorClass="font-12"
                                                // headerClass="font-12"
                                                />
                                                }
                                        </React.Fragment>}
                                    </div>
                                    </>
                                        :
                                    <Upgrade title="Contact"/>
                                }
                            </div>

                            <div className="card" id="photos">
                                {(privileges && privileges[permissionCode] || owner) ?
                                    <>
                                    <div class="card-header text-left light-blue-header"><h5 class="m-b-0 m-t-0 text-white">Photos</h5></div>
                                    <div className="card-body p-t-2">
                                        {page && page.photos && page.photos.length > 0 ? 
                                            page.photos.map((list,i)=>(
                                                    <div className="col-lg-6 col-md-6 float-left m-b-20" key={i}>
                                                        <a href={Helpers.viewFile(list.uniqueId)} target="_blank">
                                                        <img src={Helpers.viewFile(list.uniqueId)}  alt="user" className="img-responsive radius" />
                                                        </a>
                                                    </div>
                                                    
                                            ))
                                            :
                                            <div className="text-center">No Data Found</div>
                                        }
                                    </div>
                                    </>
                                        :
                                    <Upgrade title="Photos"/>
                                }
                            </div>

                            <div className="card" id="videos">
                                {(privileges && privileges[permissionCode] || owner) ?
                                    <>
                                    <div class="card-header text-left light-blue-header"><h5 class="m-b-0 m-t-0 text-white">Videos</h5></div>
                                    <div className="card-body p-t-2">
                                        {page && page.videoLinks && page.videoLinks.length > 0 ?
                                            page.videoLinks.map((link,i)=>{
                                                let videoLink = link;
                                                if (!Helpers.isEmpty(videoLink)) {
                                                  videoLink = Helpers.getEmbbedVideoLink(videoLink);
                                                }
                                                return <div className="col-lg-6 col-md-6 float-left m-b-20" key={i}>
                                                             <embed src={videoLink} width="100%" />
                                                      </div>
                                                    
                                            })
                                            :
                                            <div className="text-center">No Data Found</div>

                                        }
                                     </div>
                                    </>
                                        :
                                    <Upgrade title="Videos"/>
                                    }
                                   
                            </div>
                            <div className="card" id="attachments">
                                {(privileges && privileges[permissionCode] || owner) ?
                                    <>
                                    <div class="card-header text-left light-blue-header"><h5 class="m-b-0 m-t-0 text-white">Attachments</h5></div>
                                    <div className="card-body p-t-2">
                                        {
                                           page && page.attachments && page.attachments.length > 0  ?
                                                page.attachments.map((doc,i)=>{
                                                    return <div className="attachments">
                                                                 <a href={Helpers.viewFile(doc.uniqueId)} target="_blank">
                                                                     <i class="fa fa-file-pdf-o font-20"></i> <span className="font-12">{doc.name}</span>
                                                                 </a> 
                                                            </div>
                                                })
                                                :
                                                <div className="text-center">No Data Found</div>
                                        }

                                    </div>
                                    </>
                                        :
                                    <Upgrade title="Attachments"/>
                                    }
                            </div>
                      
                            <div id="review" ref={this.myDivToFocus}>
                              {  (privileges && privileges[permissionCode] || owner)?
                                 !Helpers.isEmpty(this.state.fullRatingDetails) && 
                                    <Review ranking={ranking} noOfRatings={noOfRatings} users={this.state.fullRatingDetails} />
                                    :
                                    <div className="card">
                                     { !Helpers.isEmpty(this.state.fullRatingDetails) &&    <Upgrade title="Opportunity Rating"/>}
                                    </div>
                                }
                            </div>
                         
                         

                </div>
             </div>
        )
    }
}


const mapStateToProps = (state) => ({
    packageDetails: state.packageDetails,
    errors: state.errors
  });
 export default connect(mapStateToProps, { getPackageDetails })(withRouter(OppContent));
  