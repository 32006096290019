import React, { Component } from 'react';
import config from '../../shared/config';
import { Link} from 'react-router-dom';
import Trending from "../wigets/Trending";
import LeaderBoard from "../wigets/LeaderBoard";
import NewsContent from "../newsfeed/NewsContent";
import {DefaultMeta} from "../../shared/MetaHelper";
import store from "../../store";
import Home from "../home/Home";
import Helpers from '../../shared/custom';
import { oauthLogin , fetchUserInfo } from '../../actions/authAction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class NewPublicHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            authenticated:false
        }
        store.subscribe(() => {
            var storeState = store.getState();
            this.setState({
                authenticated:storeState.auth.isAuthenticated,
            },()=>{
                if(this.state.isAuthenticated){
                    setTimeout(() => {
                       fetchUserInfo(); 
                    });
                }
            });
          
        });


    }

    componentDidMount(){
        const token = Helpers.getQueryParameter("token");
        const error = Helpers.getQueryParameter("error");

        if(!Helpers.isEmpty(token)){
             this.props.oauthLogin(token);
        }
        if(!Helpers.isEmpty(error)){
            Helpers.pushError(error);
       }
    }

    render() {
       
        return (
            this.state.authenticated? <Home/>
            :
            <div className="container-fluid p-0"> 
                 <DefaultMeta/>
                 <div className="row content-in m-t-50">
                     <div className="home-banner full-width">
                        <div className="jumbotron jumbotron-fluid no-bg">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <div className="col-md-8 text-left float-left">
                                            <h2 className="text-white font-24">A market leading research platform showcasing company insights
                                                from early-stage startups to public listed companies.</h2>
                                            <p className="text-white">Full data coverage on public and private companies with a suite of financial intelligence features.
                                                        Providing you with the whole ecosystem info, details, and insights.</p>
                                            <p className="text-white">Network with a community of over 3000+ investors and get the opportunity to pitch your ideas.</p>
                                            <p className="text-white">Discover startups, investors, opportunities, trending events, and much more…</p>
                                            <Link to={"/auth?from=register"} className="btn btn-white font-bold">Create an account</Link>
                                    </div>
                                    <div className="col-md-4 float-left main-img-holder">
                                         {/* <img src={config.cdn +'theme/images/main.png'} className="main-img"/>  */}
                                       <a href="https://www.crunchbase.com/" target="_blank" rel="noreferrer"> <img src={config.dataCdnStatic +'laptop.png?'}  className="main-img"/> </a>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div>

                   {/* <div className="detail-banner full-width bg-white m-t-5">
                              <div className="container-fluid" style={{"padding":"30px"}}>
                                 <div className="col-md-12 crunchbase">
                                   <img style={{"maxWidth":"100%"}} className="crunchbase-logo" src="https://investera-plus-cdn.s3.ap-south-1.amazonaws.com/social-icons/powered_by_crunchbase.png" />
                                 </div>
                              </div>
                     </div>  */}

                     <div className="detail-banner full-width bg-white m-t-5">
                              <div className="container-fluid" style={{"padding":"30px"}}>
                                 <div className="col-md-12">
                                   <h2 className="m-t-20 text-left text-mobile-center font-24">We Have At Your Disposal</h2>
                                </div>
                                <div className="col-md-12 m-t-20">
                                      <div className="col-md-3  float-left">
                                          <img src={config.cdn +'theme/images/market-researchers.png'}  className="detail-img"/>
                                          <h2 className="font-18"><a href="/market-researchers.html">Market Researchers <i className="fa fa-angle-right" aria-hidden="true"></i></a></h2>
                                          <p>Get access to highly insightful 
                                                industry reports, news and 
                                                market maps covering 100+ 
                                                sectors and sub-sectors</p>
                                     </div>
                                     <div className="col-md-3  float-left">
                                         <img src={config.cdn +'theme/images/startup-companies.png'}  className="detail-img"/>
                                         <h2 className="font-18"><a href="/startup.html">Startup Companies <i className="fa fa-angle-right" aria-hidden="true"></i></a></h2>
                                         <p>Network with our community 
                                                of 3000+ Investors and get 
                                                the opportunity to pitch their  
                                                products and services</p>
                                    </div>
                                     <div className="col-md-3  float-left">
                                          <img src={config.cdn +'theme/images/investment-analysts.png'}  className="detail-img"/>
                                          <h2 className="font-18"><a href="/market-researchers.html">Investment Analysts <i className="fa fa-angle-right" aria-hidden="true"></i></a></h2>
                                          <p>Navigate through the in-depth 
                                                information on 100K+ 
                                                companies and 20K+
                                                professionals</p>
                                     </div>
                                     <div className="col-md-3  float-left">
                                          <img src={config.cdn +'theme/images/venture-capital-firms-1.png'}  className="detail-img"/>
                                          <h2 className="font-18"><a href="/venture-capital.html">Venture Capital Firms <i className="fa fa-angle-right" aria-hidden="true"></i></a></h2>
                                          <p>Capture a 360-degree view of 
                                                stakeholders in an Entity via 
                                                state of the art knowledge 
                                                graphs</p>
                                     </div>
                                    
                                </div>
                              </div>
                     </div>

                     <div className="detail-banner full-width bg-white m-t-5">
                            <div className="container-fluid" style={{"padding":"30px"}}>
                                 <div className="col-md-12">
                                    <img src={"http://plus-cdn.investera.com/static/investera_plus_static.png"}  className="overview-img"/>
                                </div>
                            </div>
                     </div>


                     <div className="detail-banner full-width bg-white m-t-5 p-0">
                            <div className="container-fluid p-0">
                                <div className="col-md-12" style={{"padding":"30px","paddingBottom": "10px"}}>
                                   <h2 className="m-t-20 text-left font-24"> Introducing A One-Click <i>i</i>Knowledge Graph Feature</h2>
                                </div>
                           
                                 <div className="col-md-12 p-0">
                                    <img src={config.dataCdnStatic +'KG.png'}  className="overview-img"/>
                                </div>
                                <div className="m-t-10 text-right col-md-12">
                                    <a href="/graph" className="font-bold font-18 m-r-10">Learn More <i className="fa fa-angle-right" aria-hidden="true"></i></a>
                                </div>
                                <br/>
                            </div>
                     </div>



                     <footer className="newFooter  col-12">
                            <div className="container-fluid footer-fluid">
                                    <ul className="bd-footer-links text-left text-black font-14 font-medium">
                                        <li className="text-black">Investera Plus Africa &copy; 2021</li>
                                        <li><a href="#" target="_blank" className="text-black" title="about us" rel="noreferrer">About Us</a></li>
                                        <li><a href="#" target="_blank" className="text-black" title="Privacy Policy" rel="noreferrer">Privacy Policy</a></li>
                                        <li><a href="#" target="_blank" className="text-black" title="End User License" rel="noreferrer">End User License</a></li>
                                        <li><a href="#" target="_blank" className="text-black" title="Cookies Policy" rel="noreferrer">Cookies Policy</a></li>
                                        <li><a href="#" target="_blank" className="text-black" title="Contact us" rel="noreferrer">Contact Us</a></li>
                                    </ul>
                            </div>
                    </footer>

                  </div>
            </div>
        )
    }
}
const mapStateToProps = (state) =>({
    auth:state.auth,
    errors:state.errors
});

export default connect(mapStateToProps,{oauthLogin,fetchUserInfo})(withRouter(NewPublicHome));
