import React, { useState ,useEffect} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup } from 'reactstrap';

const ModalBox = (props) => {
  const {
    className,
    isOpen
  } = props;
  const [modal, setModal] = useState(false);
  const [backdrop, setBackdrop] = useState("static");

  const toggle = () => setModal(!modal);

 
  useEffect(() => {
      setModal(isOpen)
  }, [props])


  return (
      <Modal isOpen={modal} toggle={toggle} className={className} backdrop={backdrop} >
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody className="p-4">
            {
              props.children
            }
        </ModalBody>
        {/* <ModalFooter>
          {
            props.children
          }
        </ModalFooter> */}
      </Modal>
  );
}

export default ModalBox;