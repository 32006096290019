import {Helmet} from "react-helmet";
import React, { Component } from 'react';
import config from '../shared/config';

export class DefaultMeta extends Component {
    render(){
        return (
            <Helmet>
                <title>Investera Plus | 100,000+  Startups & Companies | investera.com‎</title>  
                <meta property="og:url" content="https://plus.investera.com" />
                <meta property="og:type" content="Website" />
                <meta property="og:title" content="Investera+ | 100,000+ Startups & Companies | investera.com‎‎" />
                <meta property="og:description" content="Investera+ | 100,000+  Startups & Companies | investera.com‎" />
                <meta property="og:image" content="https://plus.investera.com/cdn/theme/images/stats.png" />
            </Helmet>
        )
    }
}
class EntityMeta extends Component {
    constructor(props) {
        super(props);
    }
    render(){
        let title =  this.props.entityUri + " | Investera+";
        let description  = this.props.description
        let image = this.props.image;
        return (
        <Helmet>
          <title>{title}</title> 
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content={"website"} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={image} />
          <meta property="fb:app_id" content={config.facebookAppId} />
        </Helmet>
        )
    }
}
export class CompanyMeta extends EntityMeta {
    constructor(props) {
        super(props);
    }
    render(){
        let title = this.props.entityUri
        return (
         <EntityMeta
         entityUri = {this.props.entityUri}
         description = {this.props.description}
         image = {this.props.image}
         entityType = "Company"
         />
        )
    }
}

export class PeopleMeta extends EntityMeta {
    constructor(props) {
        super(props);
    }
    render(){
        let title = this.props.entityUri
        return (
         <EntityMeta
         entityUri = {this.props.entityUri}
         description = {this.props.description}
         image = {this.props.image}
         entityType = "People"
         />
        )
    }
}

export class EventMeta extends EntityMeta {
    constructor(props) {
        super(props);
    }
    render(){
        let title = this.props.entityUri
        return (
         <EntityMeta
         entityUri = {this.props.entityUri}
         description = {this.props.description}
         image = {this.props.image}
         entityType = "Event"
         />
        )
    }
}

export class ReportMeta extends EntityMeta {
    constructor(props) {
        super(props);
    }
    render(){
        let title = this.props.entityUri
        return (
         <EntityMeta
         entityUri = {this.props.entityUri}
         description = {this.props.description}
         image = {this.props.image}
         entityType = "Report"
         />
        )
    }
}