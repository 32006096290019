
import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import config from "../../shared/config"
import Select from 'react-select';
import Axios from "axios";
import Helpers from '../../shared/custom';

export default class OppForm  extends Component {
    constructor(props){
        super(props);
        this.state = {
            budgetRangeOptions:[]
        }
    }
    componentDidMount = ()=>{
        this.fetchEnum()
    }

    fetchEnum = ()=>{
        Axios.get(
            Helpers.apiURL({
                uri:`api/enum/list`,
            })
        )
        .then( (response) => {
            let data  = response.data.result || [];  
            this.setState({
                budgetRangeOptions: Helpers.makeSelectOptionsFromEnum(data.MoneyRange)
            })
        })
    }

  render() {
    let values = this.props.values;
    let {sectors,industryGroups,subIndustries,industries,industry} = this.props.values;

    console.log(this.state.budgetRangeOptions)
    return (
      <div>
                <div className="row">
                <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label font-bold font-18">Sector</label>
                            <Select
                                className="font-14 col-md-12 p-0 m-0"
                                name="sector"
                                options={sectors}
                                placeholder="Select sector"
                                value={values.sector}
                                onChange={this.props.onSelectChange}
                                isClearable={true}
                                isMulti
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group has-search">
                            <label className="control-label font-bold font-18">Industry Groups</label>
                            <Select
                                className="font-14 col-md-12 p-0 m-0"
                                name="industryGroup"
                                options={industryGroups}
                                placeholder="Select industry group"
                                value={values.industryGroup}
                                onChange={this.props.onSelectChange}
                                isClearable={true}
                                isMulti
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label font-bold font-18">Industry</label>
                            <Select
                                className="font-14 col-md-12 p-0 m-0"
                                name="industry"
                                options={industries}
                                placeholder="Select industry"
                                value={industry}
                                onChange={this.props.onSelectChange}
                                isClearable={true}
                                isMulti
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group has-search">
                            <label className="control-label font-bold font-18">Sub Industry</label>
                            <Select
                                className="font-14 col-md-12 p-0 m-0"
                                name="subIndustry"
                                options={subIndustries}
                                placeholder="Select sub industry"
                                value={values.subIndustry}
                                onChange={this.props.onSelectChange}
                                isClearable={true}
                                isMulti
                                styles={{
                                    // Fixes the overlapping problem of the component
                                    menu: provided => ({ ...provided, zIndex: 9999 })
                                  }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label font-bold font-18">Type</label>
                            <Select                            
                                className="font-14 col-md-12 p-0 m-0"
                                name="oppType"
                                options={config.opportunityTypes}
                                placeholder="Select Opportuniiy Tpe"
                                value={values.oppType}
                                onChange={this.props.onSelectChange}
                                isClearable={true}
                            />
                        </div>
                    </div>
                    
                    {values && values.oppType && values.oppType.value == "INVESTMENT" &&  <div className="col-md-6">
                                <div className="form-group  has-search">
                                    <label className="control-label font-bold font-18">Date of Investment Deadline</label>
                                    <span className="form-control-feedback"><i className="mdi mdi-calendar-range font-24"></i></span>
                                    <DatePicker className="form-control"
                                        name="investmentDeadline"
                                        selected={values.investmentDeadline}
                                        onChange={(event) => this.props.onDateChange(event,'investmentDeadline')}
                                        dateFormat="d-MMM-yyyy"

                            />
                        </div>
                    </div>
                }
                {values && values.oppType && values.oppType.value == "INVESTMENT" && <div className="col-md-6">
                    <div className="form-group">
                        <label className="control-label font-bold font-18">Investment Amount</label>
                        <Select                            
                            className="font-14 col-md-12 p-0 m-0"
                            name="investmentAmount"
                            options={this.state.budgetRangeOptions}
                            placeholder="Select budget range"
                            value={values.investmentAmount}
                            onChange={this.props.onSelectChange}
                            isClearable={true}
                        />
                    </div>
                </div>}
                </div>
      </div>
    )
  }
}
