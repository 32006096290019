const STR =  {
    "AREAS_SERVED":"Areas served",
    "NAME":"Name",
    "COMAPANY_NAME":"Company Name",
    "LEGAL_NAME":"Legal Name",
    "COMPANY_SIZE":"Company Size",
    "HEADQUARTERS":"Headquarters",
    "FOUNDED":"Founded",
    "PARENT_COMPANY":"Parent Company",
    "FOLLOW":"Follow",
    "UNFOLLOW":"Unfollow",
    "FOLLOWING":"Following",
    "INTERESTED":"Interested",
    "SAVING":"Saving...",
    "LOADING":"Loading...",
    "READ_MORE":"Read more",
    "READ_LESS":"Read less",
    "YES":"Yes",
    "NO":"No",
    "EDIT":"Edit",
    "DELETE":"Delete",
    "UNSAVE_WARNING": "Are you sure? All the unsaved changes will be lost.",
    "UNDISCLOSED":"Undisclosed",
    "NO_DATA_FOUND": "No Data Found"
}
export default STR;