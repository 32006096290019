import React, { Component } from 'react';
import Sidemenu from '../sidemenu/Sidemenu';
import UserFeedRightPanel from '../homepage/UserFeedRightPanel';
import store from '../../store';
import Helper from "../../shared/custom";
import config from '../../shared/config';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';


// import NewsRightPanel from './NewsRightPanel';
import BookmarksContent from './BookmarksContent';

class Bookmarks extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
       
    }
    componentDidMount(){
    }


  
  render() {
    return (
        <div>
            <div>
                <div className="container-fluid">
                <div className="row content-in m-t-80 p-t-10">
                    <div className="col-lg-3 col-xlg-3 col-md-3 sideMenuCont">
                     <Sidemenu/>
                    </div>
                    <div className="col-lg-6 col-xlg-6 col-md-6 centerDiv">
                        <BookmarksContent />
                    </div>
                    <div className="col-lg-3 col-xlg-3 col-md-3 m-t-2 newsRightPanel right-side">
                        <UserFeedRightPanel />
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    )
  }
}



export default Bookmarks;