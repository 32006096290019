import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import searchReducer from './searchReducer';
import entityReducer from './entityReducer';
import registerReducer from './registerReducer';
import metalistReducer from './metalistReducer';
import businessReducer from './businessReducer';
import industryGroups from './industryGroupReducer'
import subIndustries from './subIndustryReducer';
import pageReducer from './pageReducer';
import fundRoundReducer from './fundRoundReducer';
import feedReducer from './feedReducer';
import packageReducer from './packageReducer';
import rankingReducer from './rankingReducer';
import sectorsReducer from './sectorsReducer';


export default combineReducers({
    auth: authReducer,
    errors: errorReducer,
    search: searchReducer,
    entity: entityReducer,
    registerData: registerReducer,
    meta: metalistReducer,
    business: businessReducer,
    page: pageReducer,
    meta: metalistReducer,
    business: businessReducer,
    fundRound: fundRoundReducer,
    feedReducer: feedReducer,
    packageDetails: packageReducer,
    rankingReducer: rankingReducer,
    sectorsReducer: sectorsReducer,
    industryGroups: industryGroups,
    subIndustries: subIndustries
})