import React, {Component} from 'react';
import config from '../../shared/config';
import {connect} from 'react-redux';
import store from '../../store';
import Helper from '../../shared/custom';
import {withRouter, Redirect, Link} from 'react-router-dom';
import {Helmet} from "react-helmet";
import Axios from 'axios';
import queryString from 'query-string';


import PublicReport from "./PublicReport";
import UserReport from "./UserReport";
import UserFeedRightPanel from "../homepage/UserFeedRightPanel";
import Sidemenu from '../sidemenu/Sidemenu';
import {DefaultMeta} from "../../shared/MetaHelper";
import Select, {createFilter} from "react-select";

import CompanySearch from "../wigets/CompanySearch";

const PAGE_SIZE = 5;

class Report extends Component {
    constructor(props) {
        super(props);
        var reports = [];
        this.state = {
            loggedIn: false,
            localProps: {},
            numPages: null,
            pageNumber: 0,
            reports,
            isSearching: false,
            reportCategories: [],
            selectedCategories: [],
            selectedCompanies:[],
            sortFilter: {value:"DESC",label:"Latest"},
            loadReport: true,
            isLoading: false,
        };


        store.subscribe(() => {
            var storeState = store.getState();
            this.setState({loggedIn: storeState.auth.isAuthenticated});
        });
        
    }

    componentDidMount() {
        var storeState = store.getState();
        this.setState({
            loggedIn: storeState.auth.isAuthenticated,
        })
        document.body.classList.add('bg-black');
        document.body.classList.add('custom-body');
        document.getElementById("main-wrapper").classList.add("container");

        let query = this.props.location.search;
        let querySearch = queryString.parse(query);

        if(querySearch && querySearch.name){
            this.setState({
                            selectedCompanies:[{name:querySearch.name,uri:querySearch.uri,id:querySearch.id}],
                            filterState:{
                                companies:[{name:querySearch.name,uri:querySearch.uri,id:querySearch.id}]
                            },
                            isSearching:false,
                         },this.applySearch);
            
        } else {
            this.fetchReports();
            this.fetchFeaturedReport();
        }
        this.fetchReportCategories();

        window.addEventListener('scroll', this.loadOnScroll);
       

    }

    componentWillReceiveProps(props) {
        var storeState = store.getState();
        if (JSON.stringify(storeState) != JSON.stringify(this.state.localProps)) {
            this.setState({
                loggedIn: storeState.auth.isAuthenticated,
                localProps: storeState
            })
        }
    }

    fetchFeaturedReport = () => {
        Axios.get(Helper.apiURL({uri: "admin/report/featured"})).then(response => {
            var featuredReport = []
            if (response.data && response.data.result) {
                let obj = response.data.result
                featuredReport = {
                    name: obj.reportTitle,
                    image: obj.coverImage ? Helper.viewFile(obj.coverImage.uniqueId): '',// Helper.dataPath(obj.coverImage.uri) : '',
                    uri: "report/view/" + obj.reportUri,
                    date: obj.reportCreationDate,
                    reportSummary: Helper.truncate(obj.reportSummary.replace(/<[^>]*>?/gm, ''),250)
                }
            }
            this.setState({featuredReport});
        });
    }
    fetchReports = () => {
        var images = ["theme/images/report1.jpg", "theme/images/report2.jpg", "theme/images/report3.jpg", "theme/images/report4.jpg"]
        var payload = {
            featured: false,
            page: this.state.pageNumber,
            size: 5
        };

        
        if (this.state.filterState && this.state.filterState.companies) {
            payload.pageIds = this.state.filterState.companies.map(x => x.id).join(",")
        }
        if (this.state.filterState && this.state.filterState.categories) {
            payload.categories = this.state.filterState.categories
        }
        if (this.state.filterState && this.state.filterState.searchText) {
            payload.searchText = this.state.filterState.searchText
        }
        if (this.state.sortFilter){
            payload.sortField = "reportCreationDate";
            payload.sortOrder = this.state.sortFilter.value;
        }

        this.setState(prevState => { let isLoading = true; return { isLoading } });

        Axios.get(Helper.apiURL({uri: "admin/report/search", query: payload})).then(response => {
            var reports = []
            if (response.data && response.data.result) {
                if(response.data.result.length < PAGE_SIZE){
                    this.setState(prevState => { let loadReport = false; return { loadReport } })
                }
                response.data.result.forEach((obj, i) => {
                    if (obj.status == "PUBLISHED") {
                        reports.push({
                            name: obj.reportTitle,
                            image:obj.coverImage ?
                                Helper.viewFile(obj.coverImage.uniqueId):
                                config.cdn + images[i % images.length],
                            uri: "report/view/" + obj.reportUri,
                            date: obj.reportCreationDate,
                            reportSummary: Helper.truncate(obj.reportSummary.replace(/<[^>]*>?/gm, ''),150)
                        });
                    }
                });
            }
            if (response.data && response.data.result.length == 0)
                this.setState(prevState => { let loadReport = false; return { loadReport } })

            this.setState(prevState => {
                let prevReports = prevState.reports;
                reports = [...prevReports, ...reports];
                let isLoading = false;
                return { reports, isLoading };
            });
        }).catch((err) => {
            this.setState(prevState => { let isLoading = false; return { isLoading } });
        });
    }

    renderAdvanceSearch = (e) => {
        this.setState({
            isSearching: true,
            loadReport:false,
        });
    }

    fetchReportCategories() {
        Axios.get(Helper.apiURL({uri: "meta/list/RCT"})).then(response => {
            if (response.data && response.data.result) {
                this.setState({
                    reportCategories: response.data.result
                })
            }
        });
    }

    handleCategoriesFilterChange = (values) => {
        this.setState({
            selectedCategories: values
        });
    }

    handleCompanyChange = (values) =>{
        this.setState({
            selectedCompanies: values
        });
    }
    
    handleSearchFilterChange = (e) => {
        this.setState({
            searchFilter: e.target.value
        })
    }
    handleSortFilterChange = (e) => {
        this.setState({
            sortFilter: e , reports : [] 
        }, this.fetchReports)
    }

    applySearch = () => {
        let filterState = {
            searchText: null,
            categories: null,
            companies:null
        };
        if (this.state.selectedCategories && this.state.selectedCategories.length > 0) {
            filterState.categories = this.state.selectedCategories.map(x => x.value).join(",");
        }

        if(this.state.selectedCompanies && this.state.selectedCompanies.length > 0){
            filterState.companies = this.state.selectedCompanies;
        }
        if (this.state.searchFilter) {
            filterState.searchText = this.state.searchFilter
        }
        this.setState({
            isSearching: false,
            filterState , 
             reports : []
        }, this.fetchReports);
    }

    loadOnScroll = (e) => {
        var el = document.getElementById('content-end');
        if(!el) return;
        var rect = el.getBoundingClientRect();

        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            if (this.state.loadReport && !this.state.isLoading) {
                this.setState(prevState => { let pageNumber = prevState.pageNumber + 1; return { pageNumber } }, () => this.fetchReports());
            }
        }
    
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.loadOnScroll);
    }

    render() {
        let loggedIn = this.state.loggedIn;
        const {pageNumber, numPages} = this.state;
        let isMobile = Helper.isMobile();
        let { loadReport } = this.state;
        let reports = this.state.reports


        return (
            <div className="m-t-80">
                <DefaultMeta />
                <div className="row content-in m-t-80 p-t-10">
                    <div className="col-lg-3 col-xlg-3 col-md-3 sideMenuCont">
                       <Sidemenu />
                    </div>
                    <div className="col-lg-6 col-xlg-6 col-md-6 centerDiv">

                        <div className="card text-left b-header">
                            <div class="card-header text-left p-0">
                                <div className="col-md-6 float-left light-blue-header-half header-div">{this.state.isSearching === false ? "Reports Search" : "All Reports"}</div>
                                <div className="col-md-6 float-left text-right header-div font-14 text-blue">
                                    <a className="text-blue" onClick={this.renderAdvanceSearch}>Edit Search Criteria
                                    </a>
                                </div>
                            </div>

                            {this.state.isSearching ? this.getSearchCard() : this.getFeaturedCard()}
                        </div>
                        {!this.state.isSearching ? this.getAllReportDivs(reports) : ''}

                        {/* Start load more content when this div is visible*/}
                        {loadReport && <div id="content-end">
                            {Helper.loadMoreDiv()}
                        </div>}
                    </div>
                    <div className="col-lg-3 col-xlg-3 col-md-2 m-t-2 userProfileRight right-side">
                        <UserFeedRightPanel />
                    </div>
                </div>

            </div>
        )
    }

    getAllReportDivs(reports) {
        return <div className="card m-t-15">
            <div className="card-body companies">
                <div className="d-flex flex-row justify-content-end align-items-center col-md-12 p-0">
                    <label className="control-label font-bold font-18 m-r-5">Sort: </label>
                    <Select
                        className="font-12 col-md-3 p-0 m-0"
                        name="sortFilter"
                        value={this.state.sortFilter}
                        onChange={this.handleSortFilterChange}
                        options={[
                            {
                                value: "DESC",
                                label: "Latest"
                            },
                            {
                                value: "ASC",
                                label: "Oldest"
                            }
                        ]}
                    />
                </div>
                <div className="col-md-12 p-0">
                    {!Helper.isEmpty(reports) ?
                        reports.map((data, i) => (
                            <div className="col-md-12 p-0 m-t-15" key={i}>
                                <div className="card text-center">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-4 p-0">
                                                <div className="pro-img">
                                                    <Link to={"/" + data.uri}>
                                                        <img src={data.image}
                                                             className="img-responsive report-img-border report-img-logo" />
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-8 text-left">
                                                <div className="col-md-12 p-0">
                                                    <Link to={"/" + data.uri}>
                                                        <h6 className="text-blue">{data.name}</h6>
                                                    </Link>
                                                </div>
                                                <div className="col-md-12 h-50 p-0">
                                                    <p className="font-12 m-b-0">{data.reportSummary}</p>
                                                </div>
                                                <div className="d-flex flex-row justify-content-between align-items-end p-0 font-12">
                                                                    <span>
                                                                        <i className="mdi mdi-calendar font-16 vm m-r-5"></i>
                                                                        {data.date}
                                                                    </span>
                                                    <Link class="blue-bg font-12 text-white btn btn-sm btn-info"
                                                          to={"/" + data.uri}>
                                                        View Report
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        ))
                        :
                        ""
                    }
                </div>
            </div>
        </div>;
    }

    getFeaturedCard() {
        if (this.state.filterState && (this.state.filterState.searchText || this.state.filterState.categories || this.state.filterState.companies)) {
            return (<div className="card-body">
                    <div className="col-md-12 p-0">
                         <div className="col-md-12 p-0">
                            <label className="control-label font-bold font-18 col-md-12 p-0">Company: </label>
                            <p>{this.state.selectedCompanies.map(x => x.name).join(",")}</p>
                        </div>
                        <div className="col-md-12 p-0">
                            <label className="control-label font-bold font-18 col-md-12 p-0">Contains Words: </label>
                            <p>{this.state.filterState && this.state.filterState.searchText ? this.state.filterState.searchText : ''}</p>
                        </div>
                        <div className="col-md-12 p-0">
                            <label className="control-label font-bold font-18 col-md-12 p-0">Category: </label>
                            <p>{this.state.selectedCategories.map(x => x.label).join(",")}</p>
                        </div>
                    </div>
                </div>
            )
        }
        let data = this.state.featuredReport;
        if (data && data.name) {
            return (<div className="card-body">
                <div className="col-md-12 p-0">
                    <h4 className="text-uppercase">Featured Report</h4>
                </div>
                <div className="col-md-12 p-0">
                    <div className="col-md-12 p-0">
                        <div className="col-md-12 p-0">
                            <Link to={"/" + data.uri}>
                                <img src={data.image} style={{marginBottom:"10px"}}
                                     className="img-responsive radius float-left report-img-logo" />
                            </Link>
                        </div>
                        <div class="col-md-12 p-0">
                            <div className="col-md-12 p-0 m-t-15">
                                <Link to={"/" + data.uri}>
                                    <h6 className="text-blue">{data.name}</h6>
                                </Link>
                            </div>
                            <div className="col-md-12 p-0">
                                <p className="font-12">{data.reportSummary}</p>
                            </div>
                            <div className="d-flex flex-row justify-content-between align-items-end p-0 font-12">
                                                                    <span>
                                                                        <i className="mdi mdi-calendar font-16 vm m-r-5"></i>
                                                                        {data.date}
                                                                    </span>
                                <Link class="blue-bg font-12 text-white btn btn-sm btn-info"
                                      to={"/" + data.uri}>
                                    Read More
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
        }

    }

    getSearchCard() {
        return (<div className="card-body ">
                    <div className="col-md-12 p-0">
                            <label className="control-label font-bold font-18 col-md-12 p-0">Company: </label>
                                 <CompanySearch
                                            title=""
                                            multiple={true}
                                            headingClass=""
                                            onChange={event => this.handleCompanyChange(event)}
                                            name="company"
                                            defaultSelected={this.state.selectedCompanies}
                                            />
                    </div>
                    <div className="col-md-12 p-0">
                        <label className="control-label font-bold font-18 col-md-12 p-0">Contains Words: </label>
                        <input type="text" className="font-12 col-md-12 m-0 form-control"
                            onChange={this.handleSearchFilterChange}
                            value={this.state.searchFilter}
                        />
                    </div>
                    <div className="col-md-6 p-0">
                        <label className="control-label font-bold font-18 col-md-12 p-0">Category: </label>
                        <Select
                            className="font-12 col-md-12 p-0 m-0"
                            onChange={this.handleCategoriesFilterChange}
                            placeholder="Select Category"
                            value={this.state.selectedCategories}
                            isClearable={true}
                            isMulti={true}
                            options={this.state.reportCategories.map(x => {
                                return {value: x.id, label: x.name}
                            })}
                        />
                    </div>
                    <div className="col-md-12 p-0 m-t-15 ">
                        <a class="blue-bg font-12 text-white btn btn-sm btn-info float-right"
                        onClick={this.applySearch}>
                            Search
                        </a>
                    </div>
                </div>);
    }
}


export default Report;
