import React, { Component } from 'react'
import Helper from "../../shared/custom";

export default class SearchBannerBox extends Component {
  render() {
    let card = this.props.card;
    return (
        <div className="col-lg-6 col-xlg-3 col-md-6 float-left">
            <div className="card">
                <a href={card.url} className="card-a">
                    <img className="card-img-top img-responsive" src={Helper.dataPath(card.image)} alt="Card image cap" />
                </a>
                <div className="card-body text-left font-12">
                    <a href={card.url}>
                        <h4 className="card-title font-bold">{Helper.truncate(card.name,20)}</h4>
                    </a>
                    {card.title? <span className="font-10">{card.title}</span>: "" }
                    <p className="card-text card-text-fixed"> {Helper.truncate(card.description, 100)}</p>
                    {/* <div className="clearfix"></div> */}
                </div>
            </div>
        </div>
    )
  }
}