import React, { Component } from 'react';
import Axios from 'axios';
import { withRouter, Link } from 'react-router-dom';
import config from '../../shared/config';
import Helper from "../../shared/custom";
import Footer from './Footer';
import store from '../../store';
import moment from 'moment';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
// import { fetchUserInfo } from '../../actions/authAction';

import { getPackageDetails } from '../../actions/packageAction';

import { login, fetchUserInfo, logoutUser  } from '../../actions/authAction';
import { connect } from 'react-redux';

export class Membership extends Component {
    constructor(props){
        super(props);
        this.state = {
            plans : [],
            details:[],
            discount:{},
            result:[],
            mode:null,
            contactLength:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19],
            users:{},
            localProps:{},
            loggedIn:false,
            isAdmin:false,
            isExpired:false,
            basic:{},
            loading:false
        }
    }


    // getUserDetails(uri){
    
    //         let profileDetails = localStorage && localStorage.profile ? JSON.parse(localStorage.profile) : {};
    //         if(!Helper.isEmpty(uri) &&  Helper.isEmpty(profileDetails)){
    //             Axios.get(
    //                 Helper.apiURL({
    //                     uri:"user/:uri/profile",
    //                     pathVar:{uri:uri}
    //                 })
    //             )
    //             .then( (response) => {           
    //                 let profileDetails  = response.data.result || null;                
    //                 this.setState({
    //                     user:profileDetails,
    //                 });
    
    //                 localStorage.setItem("profile",JSON.stringify(profileDetails)); 
    
    //                 store.dispatch({
    //                     type:SET_CURRENT_USER_PROFILE,
    //                     payload:profileDetails
    //                 });
    
    //             })
    //             .catch((err) => { 
    //                 //Helpers.pushTError("PROFILE_NOT_FOUND");
    //             });
    //         }else{
    //             this.setState({
    //                 user:profileDetails,
    //             })
    //         }
       
    // }

    componentDidMount(){
       this.fetchPlans();
       this.setState({mode:this.props.mode});
      
       let auth = localStorage && localStorage.auth ? localStorage.auth  : null;

       let subscription = localStorage && localStorage.packageDetails ? JSON.parse(localStorage.packageDetails)  : {};
       var storeState = store.getState(); 

       let storeUri = storeState.auth.user.uri;

      
       let expireDate = new Date(subscription.nextBillingDate);
       let expDate = moment(expireDate).format("X") 
       let now = moment().format("X");

    //    if(Helper.isEmpty(subscription) && !Helper.isEmpty(auth)){
    //        this.props.getPackageDetails()
    //    }

       fetchUserInfo();

       let users = localStorage && localStorage.userInfo ? JSON.parse(localStorage.userInfo)  : {};

       if(!Helper.isEmpty(users)){
           this.setState({
                users:users,
                isAdmin:users.adminOwner,
                loggedIn:!Helper.isEmpty(auth),
                subscription : subscription.packageDetails,
                isExpired :now > expDate ? true : false,
                subscription:subscription.packageDetails,
           })
       }
    }


    componentDidUpdate(prevProps, prevState) {
      
        if (JSON.stringify(prevProps.mode) != JSON.stringify(this.props.mode)) {
            this.setState({localProps:prevProps})
            this.fetchPlans();
            this.setState({mode:this.props.mode});
           
            let auth = localStorage && localStorage.auth ? localStorage.auth  : null;
     
            let subscription = localStorage && localStorage.packageDetails ? JSON.parse(localStorage.packageDetails)  : {};
            var storeState = store.getState(); 
     
            let storeUri = storeState.auth.user.uri;
     
           
            let expireDate = new Date(subscription.nextBillingDate);
            let expDate = moment(expireDate).format("X") 
            let now = moment().format("X");
     
        
     
            fetchUserInfo();
     
            let users = localStorage && localStorage.userInfo ? JSON.parse(localStorage.userInfo)  : {};
     
            if(!Helper.isEmpty(users)){
                this.setState({
                     users:users,
                     isAdmin:users.adminOwner,
                     loggedIn:!Helper.isEmpty(auth),
                     subscription : subscription.packageDetails,
                     isExpired :now > expDate ? true : false,
                     subscription:subscription.packageDetails,
                })
            }
        }
   }



    
    
    // static getDerivedStateFromProps(nextProps, prevState) {
    //     let users = localStorage && localStorage.userInfo ? JSON.parse(localStorage.userInfo)  : {};
    //     let subscription = localStorage && localStorage.packageDetails ? JSON.parse(localStorage.packageDetails)  : {};
    //     var storeState = store.getState(); 
       
    //     let expireDate = new Date(subscription.nextBillingDate);
    //     let expDate = moment(expireDate).format("X") 
    //     let now = moment().format("X");

    //     if(JSON.stringify(nextProps) != JSON.stringify(prevState.localProps)){
    //          prevState.users =  users;
    //          prevState.localProps = nextProps;
    //          prevState.subscription = subscription.packageDetails;
    //          prevState.isExpired = now > expDate ? true : false;
    //          return {prevState}
    //     }else{
    //         return {
                
    //         }
    //     }
    // }

    buyNow = (e)=>{
        this.props.buyNow(e);
    }

    fetchPlans= ()=>{

        this.setState({loading:true})

        Axios.get(config.api("subscribe/packages/list"))
        .then( (response) => {     
            this.setState({loading:false})
           var json_data = response.data;
           
           var result = json_data.result;


   
           let plans = result.map((item)=> item.duration);
       
           plans = [...new Set(plans)];
   
        

           var mainData = [];
           let newObj = {};
           let basic = {}
           result.forEach((record,i)=>{
             
                let obj = {};
               //if(record.isEnabled){
                if(record.category != "BASIC"){
                    obj[record.duration] = record;
                }
                if(record.category == "BASIC"){
                    this.setState({
                        basic:record
                    })
                }
                // newObj[record.duration] = record.discount;
                // obj[record.duration] = record;
                mainData.push(obj);
              // }
           });


           //console.log(mainData,"newObjnewObj")

          

           this.setState({
                plans:plans,
                details:mainData,
                result:result,
                discount:newObj,
            // loggedIn:loggedIn
            })
        })
        .catch((err) => { 
            this.setState({loading:false})
            //Helper.pushTError("PROFILE_NOT_FOUND");
        });

        // var json_data = require('./Plans.json');
        // var result = json_data.result;

        // let plans = result.map((item)=> item.duration);
        // plans = [...new Set(plans)];

        // var mainData = [];
        // let newObj = {};
        // result.forEach((record,i)=>{
        //     let obj = {};
        //     newObj[record.duration] = record.discount;
        //     obj[record.duration] = record;
        //     mainData.push(obj);
        // });

       

        // this.setState({
        //     plans:plans,
        //     details:mainData,
        //     result:result,
        //     discount:newObj,
        //    // loggedIn:loggedIn
        // })
        // Axios.get(
        //     Helper.apiURL({
        //         uri:`admin/subscription/package/list`,
        //     })
        // )
        // .then( (response) => {     
        //     let data  = response.data.result || null;                
        // })
        // .catch((err) => { 
            
        // });
    }
    render() {
        let { plans , details ,result , contactLength , mode , users , isAdmin ,basic,loading} = this.state;
        let { from , innerRef }  = this.props;

        let currentSubscription = users && users.currentSubscriptionShortName ? users.currentSubscriptionShortName : null;
        let currentSubscriptionDuration = users && users.currentSubscriptionDuration ? users.currentSubscriptionDuration : null;
        let currentSubscriptionCategory = users && users.currentSubscriptionCategory ? users.currentSubscriptionCategory : null;
        let isExpired = this.state.isExpired;

        let discount = {
            "MONTHLY":"0",
            "QUARTERLY":"5",
            "YEARLY":"10"
        }

        console.log(basic,"basic")
       

        return (
            <React.Fragment>
                   <LoadingOverlay>
                    <Loader fullPage loading={loading}/>
                </LoadingOverlay>
                 <div className="new_home" id="membership" ref={innerRef}>
                <div className="container member-container">
                    <div className="row rowed text-left">
                        <div className="col-md-12">
                              <h2 className="h2 custom-heading text-center">Membership</h2>
                        </div>
                        {/* <div className="col-md-12">s
                            <div className="desc-black">
                                {/* <p>
                                    Membership is open to all the above and can be paid by either<br/>
                                    Card or Mpesa on Monthly or annual basis</p> */}
                                {/* <p>
                                    Every plan includes a 14-day free trial
                                </p>
                            </div>   
                        </div> */} 
                    </div>
                    <div className="details m-t-20">
                    <ul className="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
                        { plans &&
                            plans.map((item,i)=>(
                                <React.Fragment>
                                    <li className="nav-item" key={i}>
                                        <a className={`nav-link ${i==0 ? 'active':''}`} id={"pills-"+item+"-tab"} data-toggle="pill" href={"#pills-"+item} role="tab" aria-controls={"#pills-"+item}  aria-selected="true">
                                            {Helper.capitalizeString(item)}
                                        </a>
                                        <span className="discount">{discount && discount[item] !=0 ? <p className="">(Save {discount[item]}%)</p> :<p className="text-info">&nbsp;</p>}</span>
                                    </li>
                               
                                </React.Fragment>
                            ))
                        }
                              
                    </ul>
                    {/* {result && result[0] ?  <p className="text-right currency">Display prices in <b>{result[0].currency.shortName}</b></p> : ""} */}
                    {/* <div className="col-md-12"> */}
                      {/* {result && result[0] ?  <p className="text-right">Display prices in <b>{result[0].currency.shortName}</b></p> : ""} */}
                    {/* </div> */}
                    <div className="tab-content" id="pills-tabContent">
                        { plans &&
                            plans.map((item,i)=>(
                                <div key={i+'content'} className={`tab-pane fade show ${i==0 ? 'active':''}`} id={"pills-"+item} role="tabpanel" aria-labelledby={"pills-"+item+"-tab"}>
                                    <div className="row pricing-plan m-t-10">
                                            {!Helper.isEmpty(basic) && <CommonView 
                                                        data={basic} 
                                                        mode={mode} 
                                                        buyNow={this.buyNow} 
                                                        currentSubscription={currentSubscription} 
                                                        currentSubscriptionCategory={currentSubscriptionCategory}
                                                        currentSubscriptionDuration={currentSubscriptionDuration}
                                                        loggedIn={this.state.loggedIn}
                                                        isAdmin={isAdmin}
                                                        isExpired = {isExpired}
                                                        />}
                                            {
                                                details.filter((x)=> !x.isEnabled).map((record)=>(
                                                        record[item] && <CommonView 
                                                                            data={record[item]} 
                                                                            mode={mode} 
                                                                            buyNow={this.buyNow} 
                                                                            currentSubscription={currentSubscription} 
                                                                            currentSubscriptionCategory={currentSubscriptionCategory}
                                                                            currentSubscriptionDuration={currentSubscriptionDuration}
                                                                            loggedIn={this.state.loggedIn}
                                                                            isAdmin={isAdmin}
                                                                            isExpired = {isExpired}
                                                                            />
                                                ))
                                            }
                                            {/* <StaticView /> */}
                                            {/* <ContactUsView/> */}
                                    </div>
                                </div>
                            ))
                        }
                        
                        
                    </div>
                    </div>

                    {/* {mode != 'main' && <div className="row rowed text-left">
                        <div className="col-md-12">
                              <h2 className="h2 custom-heading">What Can You Do?</h2>
                        </div>
                        <div className="mt-5 col-md-12 desc">
                            <div className="col-md-4 float-left">
                                <img src={config.dataCdnStatic + "networking.png"} alt={"About Us"} className="img-responsive"/>
                            </div>
                            <div className="col-md-8 float-left">
                                    <ol className="olCustom">
                                        <li><span>Get access to highly insightful industry reports, news and market maps covering 100+ sectors and sub-sectors.</span></li>
                                        <li><span>Network with our community of 3000+ Investors and get the opportunity to pitch their products and services.</span></li>
                                        <li><span>Navigate through the in-depth information on 100K+ companies and 20K+ professionals</span>.</li>
                                        <li><span>Capture a 360-degree view of stakeholders in an Entity via state of the art knowledge graphs.</span></li>
                                    </ol>
                            </div>
                        </div>
                    </div>} */}
                </div>
                {mode == 'main' &&  <Footer />}
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) =>({
    auth:state.auth,
    errors:state.errors
});

export default connect(mapStateToProps,{login,fetchUserInfo,logoutUser,getPackageDetails}) (withRouter (Membership));




const CommonView = ({data,mode,buyNow,currentSubscription,currentSubscriptionDuration,currentSubscriptionCategory,loggedIn,isAdmin,isExpired})=>{
    let dataset = data;

    let checkName = !Helper.isEmpty(currentSubscriptionCategory) ? currentSubscriptionCategory : null;

  
   
    return (
        <div class="card-deck mb-3 text-center col-md-4">
            <div class="card mb-4 box-shadow">
            <div class="card-header">
                <h4 class="my-0 font-bold m-h-60">{dataset.name}</h4>
            </div>
            <div class="card-body">
                {dataset.category == "INVESTOR" ? <h2>Contact Us</h2>  : <h2 class="card-title pricing-card-title">{dataset.amount != 0 && dataset.name.toLowerCase() != 'basic' ? Helper.displayAmount({amount:dataset.amount, currency:dataset.currency}) : ""}</h2>}
                <ul class="list-unstyled mt-3 mb-4">
                            {dataset.privileges && dataset.privileges.map((data,i)=>(
                               <li className="price-row" key={"list"+i}>{data.name}</li>
                            ))}
                </ul>
                { 
                 dataset.category == 'INVESTOR' ? 
                      <Link to={"/contact"} className={"btn btn-theme-white full-width mt-3 package_btn"}>Contact Us</Link> 
                 :
                 dataset.name.toLowerCase() != 'basic' && !isAdmin?
                    mode == 'main' && loggedIn  ?
                             (!Helper.isEmpty(currentSubscription) && (currentSubscription == dataset.shortName)) ? 
                                 isExpired ? <a data-type={data.shortName} data-id={data.id} onClick={buyNow}  className={"btn btn-warning full-width mt-3 text-white package_btn"}>Renew Package</a> : 
                                             <a data-type={data.shortName} data-id={data.id} className={"btn btn-success full-width mt-3 text-white package_btn"}>Current Plan</a> 
                                            :
                                            (checkName == dataset.category ||   (currentSubscription && (currentSubscription.toLowerCase() == 'basic' || currentSubscription.toLowerCase() == 'noplan') && (dataset.shortName=="premium" || dataset.shortName=="premium_plus_monthly"))) ? 
                                                <a data-type={data.shortName} data-id={data.id}  onClick={buyNow} className={"btn btn-info full-width mt-3 package_btn"}>Buy Now</a> 
                                                :
                                                <a className={"btn btn-secondary full-width mt-3 package_btn"}>Not Applicable</a>
                                :
                            <Link to={{pathname:"/auth", activeTab:"register"}}  className={"btn btn-info full-width mt-3 package_btn"}>Get Started</Link>
                    :
                    isAdmin ? 
                    <a className={"btn btn-secondary full-width mt-3 package_btn"}>Not Applicable</a>
                    :
                    loggedIn ? <a className={"btn btn-secondary full-width mt-3 package_btn"}>Not Applicable</a> : <Link to={{pathname:"/auth", activeTab:"register"}}  className={"btn btn-info full-width mt-3 package_btn"}>Get Started</Link>
                }
            </div>
            </div>
        </div>
    )
}



const ContactUsView = (data)=>{
    let dataset = data.data;
    return (
        <div class="card-deck mb-3 text-center col-md-4">
            <div class="card mb-4 box-shadow">
            <div class="card-header">
                <h4 class="my-0 font-bold m-h-60">Investor</h4>
            </div>
            <div class="card-body">
                <h2 class="card-title pricing-card-title">&nbsp;</h2>
                <ul class="list-unstyled mt-3 mb-4">
                         <li className="price-row">Contact Sales</li>
                         <li className="price-row">Company features plus</li>
                         <li className="price-row">Unlimited emails within the platform</li>
                         <li className="price-row">Advisory services</li>
                         <li className="price-row">Customer Support</li>
                         <li className="price-row">Investment Opportunities</li>
                         <li className="price-row">Access additional data on Companies and Investors upon request</li>
                </ul>
                <Link to={"/contact"} className={"btn btn-theme-white full-width mt-3 package_btn"}>Contact Us</Link> 
                
            </div>
            </div>
        </div>
    )
}
