import React, { Component } from 'react'
import Select from 'react-select';
import Helpers from '../../shared/custom';
import countries from '../../shared/country';
import ApiHelper from '../../shared/ApiHelper';
import Axios from 'axios';
import STR from '../../shared/strings';


export default class AddNewInvestor extends Component {
  constructor(props){
      super(props);
      
      this.state = {
        
        uniqueId:undefined,
        name:undefined,
        investorType:[],
        country:null,
        city:null,
        pageType:null,
        amount:null,
        lead:false,
        fundingUniqueId:null,

        
        typeOptions:[],
        pageTypeOptions:[
            {value:"people",label:"Individual"},
            {value:"company",label:"Institution"},
        ],
        countryOptions:[],
        cityOptions:[],
      }

      this.hideInvestorForm = this.hideInvestorForm.bind(this);

      this.handleSelectChange = this.handleSelectChange.bind(this);
      this.handleCountryChange = this.handleCountryChange.bind(this);
      this.handleFieldValueChange = this.handleFieldValueChange.bind(this);
      this.saveInvestor = this.saveInvestor.bind(this);
      this.validateForm = this.validateForm.bind(this);
      this.handleAdd = this.handleAdd.bind(this);
  }

  componentDidMount(){
      let countryOptions = Helpers.makeSelectOptions(countries)
      this.setState({
          countryOptions:countryOptions,
          fundingUniqueId:this.props.fundingUniqueId
      })
  }
  
  hideInvestorForm(){
      this.props.addNewInvestor();
  }

  handleSelectChange(option,e){   
    let name = e.name;
    this.setState({[name]:option});
  }

  handleCountryChange(option,e){
    let country = option.value;
    let name = e.name;
    this.setState({[name]:option});
    ApiHelper.getCities(country,(cityList) => {
        let cityOptions = Helpers.makeSelectOptions(cityList);
        this.setState({
            cityOptions:cityOptions
        })
    })
  }

  handleFieldValueChange(e){
    let name = e.target.name;
    let value = e.target.value;
    if(name){
        this.setState({[name]:value});
    }
  }

  validateForm(){
    let isValid = true;
    let message = [];
    
    if(Helpers.isEmpty(this.state.name)){
        isValid = false;
        message.push("INVESTOR_NAME_REQUIRED");
    }

    if(Helpers.isEmpty(this.state.investorType)){
      isValid = false;
      message.push("INVESTOR_TYPE_REQUIRED");
    }

    if(Helpers.isEmpty(this.state.pageType)){
      isValid = false;
      message.push("INVESTOR_ID_TYPE_REQUIRED");
    }

    if(Helpers.isEmpty(this.state.country)){
      isValid = false;
      message.push("INVESTOR_COUNTRY_REQUIRED");
    }

    if(Helpers.isEmpty(this.state.city)){
      isValid = false;
      message.push("INVESTOR_CITY_REQUIRED");
    }

    if(message.length > 0){
        Helpers.pushTError(message);
        return isValid;
    }
    
    return isValid;
  }


  saveInvestor(){
    let isValid = this.validateForm();
    if(!isValid){
        return false;
    }

    var investorTypes = [];

    if(!Helpers.isEmpty(this.state.investorType)){
      this.state.investorType.forEach((data,i)=>{
        var obj = {}
        obj.id = data.value;
        investorTypes.push(obj)
      })
    }


     let payLoad = {
        "amount": Helpers.cleanNumber(this.state.amount),
        "lead": this.state.lead,
        "investorDetail":{
          "investorTypes": investorTypes,
          "page": {
            "city": {"id": this.state.city.value},
            "country": {"id": this.state.country.value},
            "name": this.state.name
          },
          "pageType": this.state.pageType.value,
  
        }
      }
      

      let fundingUniqueId = this.props.fundingUniqueId;
      let url = "funding-round/"+fundingUniqueId+"/create-investor";
      
      this.setState({loading:true})
      if(this.state.uniqueId){
        payLoad.uniqueId = this.state.uniqueId;
  
        Axios.put(
          Helpers.apiURL({
              uri:url,
          }),
          payLoad
        )
        .then( (response) => {
            this.setState({
              loading:false
            })
            let investor  = response.data.result || null;                
            if(!investor){
                Helpers.pushTError("NOT_SAVED");
            }else{ 
                Helpers.pushTSuccess("SAVE_SUCCESS");
            }
        })
        .catch((err) => { 
          this.setState({loading:false})
          Helpers.pushTError("GENERIC_ERROR");       
        });
  
      }else{
        Axios.post(
          Helpers.apiURL({
            uri:url
          }),
          payLoad
        )
        .then( (response) => {
            this.setState({loading:false})
            let investor = response.data.result || null;                
            if(!investor){
                Helpers.pushTError("NOT_SAVED");
            }else{ 
                Helpers.pushTSuccess("SAVE_SUCCESS");                
                this.setState({
                  uniqueId:investor.uniqueId
                })
                this.handleAdd(investor);
            }
        })
        .catch((err) => { 
          this.setState({loading:false})
          Helpers.pushTError("GENERIC_ERROR");       
        });
      }

  }

  handleAdd (investor){
    if(this.props.onAdd){
        this.props.onAdd(investor);
    }
  }
  render() {
    
    let name = this.state.name;
    return (
      <div>
            <div className="card card-gray">
                <div className="card-body">
                <h4 className="card-title font-medium">Add New Investor<a href="javascript:void(0)" className="float-right" onClick={this.props.onCancel}><i className="fa fa-times text-danger"></i></a></h4>
                 <div className="col-md-6 p-0">
                    <div className="col-md-12 col-sm-12 p-0">
                        <h5 className="m-t-10 m-b-10 font-12">Investor Name</h5>
                        <input className="form-control" name="name" 
                            value={name} 
                            onChange={this.handleFieldValueChange} type="text"/>
                    </div>

                    <div className="col-md-12 col-sm-12 p-0">
                        <h5 className="m-t-10 m-b-10 font-12">Investor Type</h5>
                        <Select 
                            className="font-14 col-md-12 p-0 m-0" 
                            name="investorType" 
                            options={this.props.investorTypeOptions} 
                            placeholder="Select investor type"
                            value={this.state.investorType} 
                            onChange={this.handleSelectChange} 
                            isMulti
                        />
                    </div>

                    <div className="col-md-12 col-sm-12 p-0">
                        <h5 className="m-t-10 m-b-10 font-12">Investor Indentity</h5>
                        <Select 
                            className="font-14 col-md-12 p-0 m-0" 
                            name="pageType" 
                            options={this.state.pageTypeOptions} 
                            placeholder="Select identity type"
                            value={this.state.pageType} 
                            onChange={this.handleSelectChange} 
                        />
                    </div>

                    <div className="col-md-12 col-sm-12 p-0">
                        <h5 className="m-t-10 m-b-10 font-12">Investor Country</h5>
                        <Select 
                            className="font-14 col-md-12 p-0 m-0" 
                            name="country" 
                            options={this.state.countryOptions} 
                            placeholder="Select country"
                            value={this.state.country} 
                            onChange={this.handleCountryChange} 
                        />
                    </div>
                    <div className="col-md-12 col-sm-12 p-0">
                        <h5 className="m-t-10 m-b-10 font-12">Investor City</h5>
                        <Select 
                            className="font-14 col-md-12 p-0 m-0" 
                            name="city" 
                            options={this.state.cityOptions} 
                            placeholder="Select city"
                            value={this.state.city} 
                            onChange={this.handleSelectChange} 
                        />
                    </div>  

                    <div className="col-md-12 col-sm-12 p-0">
                        <h5 className="m-t-10 m-b-10 font-12">Amount</h5>
                        <input 
                            className="font-14 col-md-12 p-0 m-0 form-control" 
                            name="amount" 
                            placeholder=" Enter Amount"
                            value={this.state.amount} 
                            onChange={this.handleFieldValueChange} 
                        />
                    </div>  

                    <div className="col-md-12 col-sm-12 p-0">
                        <h5 className="m-t-10 m-b-10 font-12">Lead Investor</h5>
                        <div className="m-b-10">
                            <div className="demo-radio-button">
                                <div className="float-left col-md-6 col-lg-3 p-0">
                                    <input name="lead" type="radio" id={this.props.identify+"-lead1"} className="with-gap radio-col-blue" value={true} defaultChecked={this.state.lead ? true : false} onChange={this.handleFieldValueChange} />
                                    <label htmlFor={this.props.identify+"-lead1"} className="l-h-0">Yes</label>
                                </div>
                                <div className="float-left col-md-6 col-lg-3 p-0">
                                    <input name="lead" type="radio" id={this.props.identify+"-lead2"} className="with-gap radio-col-blue" value={false} defaultChecked={!this.state.lead ? true : false} onChange={this.handleFieldValueChange} />
                                    <label htmlFor={this.props.identify+"-lead2"} className="l-h-0">No</label>
                                </div>
                            </div>
                        </div>
                    </div>  

                    <div className="col-md-12 p-0 m-t-10">
                        {
                            this.state.loading ? 
                            <button className="btn btn-white m-t-5 float-right">{STR.SAVING}</button>
                            :
                            <button className="btn btn-info m-t-5 float-right" onClick={this.saveInvestor}>Add</button>
                        }
                        
                    </div>
                </div>
                </div>
            </div>
      </div>
    )
  }
}
