import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import config from '../../shared/config';


class NotFound extends Component {
    constructor(props){
        super(props);     
    }
    render(){
        return(
                <div className="bg-white vh-full m-t-90">
                        <div className="col-xs-6 d-flex flex-column justify-content-center">
                            <div className="col-12">
                                <img src={config.cdn +'theme/images/404PageNotFound.gif?'}></img>
                            </div>
                            <div className="col-12">
                            <h3 className="m-t-20">404 - PAGE NOT FOUND</h3>
                            <p className="m-t-20">We can’t seem to find the page you’re looking for. <br/>You can either
                            Return to the previous page or visit our <Link to="/">Homepage</Link></p> 
                            </div>                                                                       
                    </div>
            </div>
        )
    }
}

export default withRouter(NotFound)