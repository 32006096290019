
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import config from '../../shared/config';
import { connect } from 'react-redux';
import { login } from '../../actions/authAction';
import { fetchUserInfo } from '../../actions/authAction';
import { getAllSectorData } from '../../actions/SectorsAction';
import { logoutUser } from '../../actions/authAction';
import store from '../../store';
import storeAuth from '../../utils/storeAuth';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import Helpers from '../../shared/custom';
import LinkedInAuth from './LinkedInAuth';
import GoogleAuth from '../auth/GoogleAuth';

var watchTimeout = undefined;
class Login extends Component {
    constructor(props,context){
        super(props);

        this.state = {
            auth:{},
            userName:"",
            password:"",     
            loading:false,
            greeted:false,
            localProps:{},
            localAuth:{},
            profileDetails:{}
        }
       
        this.validateForm    = this.validateForm.bind(this);       
        this.handleChange = this.handleChange.bind(this);
        this.logout = this.logout.bind(this);
        this.watchTimeout = this.watchTimeout.bind(this);
     

        store.subscribe(() => {
            var storeState = store.getState();
            var auth = storeState.auth;
            var localAuth = this.state.auth;
            var fromRegister = storeState.registerData.fromRegister;
            if(fromRegister){
                this.props.history.push('/welcome');
            }
                        
            if(JSON.stringify(auth) != JSON.stringify(localAuth)){ 
                storeAuth(); 
                this.setState({auth:storeState.auth});
                
                
                if(auth.isAuthenticated == true){                   
                    if(localAuth.isAuthenticated == false){                    
                     // this.props.fetchUserInfo(); 
                        this.setState({
                            loading:false,
                            greeted: auth.restored ? true: false,
                        })   
                    }else{
                        let user = auth.user;
                        var greeted = localStorage.getItem("greeted");
                        if(user.fullName && !this.state.greeted && !greeted){                            
                            Helpers.pushSuccess("Welcome "+user.fullName+".");
                        }
                        localStorage.setItem("greeted", true);
                        this.setState({
                            greeted:true,
                        })
                    }                
                }else{
                    
                }                  
            }
            if(storeState.errors){
                this.setState({
                    loading:storeState.errors.loading
                })   
            }
            
        }); 
    }

    watchTimeout(){
        if(watchTimeout){
            clearTimeout(watchTimeout);
        }
        watchTimeout = setTimeout(
            function() {
              //  this.props.fetchUserInfo();
            }
            .bind(this),
            config.sesstionCheck
        );
    }

    componentDidMount(){
        var storeState = store.getState();
        this.setState({auth:storeState.auth});       
    }
    
    validateForm(event){
        event.preventDefault();        
        const userName = this.state.userName;
        const password = this.state.password;  
        
        if( userName == "" || password == ""){
            Helpers.pushError("Please fill all required fields")
            return false;
        }
        const data = {username:userName,password:password}
        this.setState({
            loading:true
        })   
        this.props.login(data,this.props.history);
        event.preventDefault();
    }

    handleChange(event){
        this.setState({[event.target.name]:event.target.value});
    }

    logout(event){
        this.props.logoutUser();
        // window.location.reload();
        this.props.history.push("/auth");
    }

    componentWillReceiveProps(props){
        if(JSON.stringify(props.auth) != JSON.stringify(this.state.localAuth)){ 
            this.setState({
                localAuth:props.auth,
            })
        }
    }
     
  render() {
    const auth = this.state.auth;
    const user = auth.user;
    let profileDetails = localStorage && localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : null;
    const loggedIn = auth.isAuthenticated;
    let loading = this.state.loading;
    let pageDetails = user && user.pageDetails ? user.pageDetails : null;

    var regStatus = pageDetails && pageDetails.pageDto && pageDetails.pageDto.regStatus && pageDetails.pageDto.regStatus.value ? pageDetails.pageDto.regStatus.value  : null;

    let profilePicUrl = !Helpers.isEmpty(profileDetails) && !Helpers.isEmpty(profileDetails.profilePic)
    ? Helpers.isEmpty(regStatus) ? Helpers.dataPath(profileDetails.profilePic.publicUri) 
    : !Helpers.isEmpty(regStatus) && regStatus == "PUBLISHED" ? Helpers.dataPath(profileDetails.profilePic.publicUri) : Helpers.dataPath(profileDetails.profilePic.uri)
    : Helpers.dataPath(config.defaultUserImage);

 

    return (
            <div className="dropdown-menu dropdown-menu-right scale-up right-dd">
                 <LoadingOverlay>
                    <Loader fullPage loading={loading}/>
                </LoadingOverlay>
                {
                    loggedIn ? 
                    <div>
                        <ul className="dropdown-user loggedIn" >
                            <li>
                                <div className="dw-user-box">
                                {!Helpers.isEmpty(auth) ?
                                    <Link to={"/user/"+user.uri}>
                                        <div className="u-img"><img src={profilePicUrl} alt="user" /></div>
                                        <div className="u-text">
                                            <h4>{this.state.authenticated}</h4>
                                            <p className="text-muted">{user.fullName}</p>
                                            <button className="btn btn-info">View Profile</button>
                                        </div>
                                    </Link> 
                                    :
                                    <div>
                                        <div className="u-img"><img src={profilePicUrl} alt="user" /></div>
                                            <div className="u-text">
                                                <h4>{this.state.authenticated}</h4>
                                                <p className="text-muted">{user.fullName}</p>
                                                <button className="btn btn-rounded btn-danger btn-sm">View Profile</button>
                                        </div>
                                     </div>

                                }
                                                                       
                                </div>
                            </li>
                            <li role="separator" className="divider"></li>
                            <li>
                                    <Link to={"/user-details/"+user.uri}><i className="material-icons adjusted-icon-for-svg">
                                        person
                                        </i> Edit Profile
                                   </Link>
                            </li>  
                             <li>
                                    <Link to="/change-password"><i className="material-icons adjusted-icon-for-svg">
                                        vpn_key
                                        </i>Change Password
                                    </Link>
                            </li>
                            <li>
                                    <Link to="/subscription/details">
                                    <i className="fa fa-shopping-basket m-r-5" aria-hidden="true"></i>
                                        Subscriptions
                                    </Link>
                            </li>

                          
                                        
                            <li role="separator" className="divider"></li>
                            <li><button onClick={this.logout} className="col-md-12 point logout-button"><i className="fa fa-power-off"></i> Logout</button></li>

                            {/* <li><a href="#"><i className="ti-wallet"></i> My Balance</a></li>
                            <li><a href="#"><i className="ti-email"></i> Inbox</a></li>
                            <li><a href="#"><i className="ti-settings"></i> Account Setting</a></li> */}
                        </ul>
                     </div> 
                     :
                  <div>
                     <ul className="dropdown-user loggIn">
                        <li>
                            <form className="form-horizontal form-material p-10" noValidate onSubmit={this.validateForm}>
                                {/* <h3 className="box-title m-b-20">Sign In</h3> */}
                                <center>
                                    <div className="m-b-10">
                                        <LinkedInAuth />
                                        <GoogleAuth label="Login with Google" url={config.gOuthUrl} mode="topbar"/>
                                    </div>
                                </center>
                                <h4 className="box-title m-b-20 font-medium line-header">Or</h4>
                                
                                <div className="form-group ">
                                    <div className="col-xs-12">
                                        <input className="form-control p--5" type="text" required="" placeholder="Username" name="userName" onChange={this.handleChange} /> </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-xs-12">
                                        <input className="form-control p--5" type="password" required="" placeholder="Password" name="password" onChange={this.handleChange} /> </div>
                                </div>
                                <div className="form-group text-center m-t-20">
                                    <div className="col-md-8 m-a">
                                        <button type="submit" className="btn btn-action btn-max btn-lg btn-block btn-normal font-14 text-uppercase waves-effect waves-light text-default">Log In</button>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-md-12">
                                        <a href="/forgot-password" id="to-recover" className="text-dark text-center hover-enable">
                                            <i className="fa fa-lock"></i> Forgot password?
                                        </a> 
                                    </div>
                                </div>
                                <div className="form-group m-b-0">  
                                    <div className="col-sm-12 text-left">
                                        <a href={"/register/?referal="+window.location.pathname+window.location.hash} className="text-info col-sm-12 text-center hover-enable"><b>Don't have an account?</b></a>
                                    </div>
                                </div>
                            </form>
                        </li>
                     </ul>
                    </div>
                }
             </div>
       
    )
  }
}
// Login.propTypes = {
//     auth:PropTypes.object.required
// }

const mapStateToProps = (state) =>({
    auth:state.auth,
    errors:state.errors
});
export default connect(mapStateToProps,{login,fetchUserInfo,getAllSectorData,logoutUser})(withRouter(Login));
