import React, { Component } from 'react';
import store from '../../store';
import Helpers from '../../shared/custom';
import Moment from 'react-moment';
import NotUser from './NotUser';
import ListCard from '../wigets/ListCard';
import { withRouter, Link } from "react-router-dom";

class Aquisitions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            aquisition: [],
            aquisitionNew:[],
            noOfAq: null,
            tableData: [],
            entityUri: null,
            fundingSeeMore:false,
            newData:   [{
                label:"Announced Date",
                field:"date"
            },
            {
                label:"Price",
                field:"amountDisplay"
            }]
    
        }

        store.subscribe(() => {
            this.setAcquisitions();
        });
        this.setAcquisitions = this.setAcquisitions.bind(this);
        this.aquisitionDiv = React.createRef();
    }

    componentDidMount() {
        this.setAcquisitions();
    }

    setAcquisitions() {
        var entityState = store.getState();
        entityState = entityState.entity || null;
        var companyDetails = entityState ? entityState.company : null;
        var entityUri = entityState ? entityState.company.entityUri : null;
        var oldEntityUri = this.state.entityUri;

        if (oldEntityUri && oldEntityUri == entityUri) {
            return false;
        }

        if (!Helpers.isEmpty(companyDetails.entityInfo)) {

            let aquisitions = companyDetails.entityInfo.companyAquisitions;
            let aquisitionsLength = aquisitions ? aquisitions.length : 0;

            let tableData = [];
            if (aquisitionsLength > 0) {
                aquisitions.forEach((aqData, index) => {
                    var obj = {}
                    obj.acquiredCompany = aqData.acquiredCompany && aqData.acquiredCompany.name ? aqData.acquiredCompany.name : aqData.acquiredCompanyName;
                    obj.acquiredCompanyUri = aqData.acquiredCompany && aqData.acquiredCompany.uri ? aqData.acquiredCompany.uri : null;
                    obj.date = aqData.date;
                    obj.price = !Helpers.isEmpty(aqData.amount) ? Helpers.displayAmount({ amount: aqData.amount, currency: aqData.currency }) : "--";
                    tableData.push(obj);
                });

            }
            this.setState({
                aquisition: aquisitions,
                aquisitionNew:!Helpers.isEmpty(aquisitions) ? aquisitions.slice(0,4) :[],
                noOfAq: aquisitionsLength,
                tableData: tableData
            });
        }
    }

    componentWillReceiveProps(){
        var query = Helpers.getQueryParameter("div");
        if(query == "aquisition"){
          this.scrollToAquisitionDiv()
        }
    }

    scrollToAquisitionDiv=()=>{
        if(this.aquisitionDiv.current){
            this.aquisitionDiv.current.scrollIntoView({
                behavior: "smooth",
                // block: "nearest",
            })
        }
      }

    handleFundingSeeMore = () => {
        let fundingSeeMore = this.state.fundingSeeMore;
        let aquisition = this.state.aquisition;
        if(fundingSeeMore){
            this.setState({
                aquisitionNew: aquisition.slice(0,4),
                fundingSeeMore: !fundingSeeMore
            });
        } else {
            this.setState({
                aquisitionNew:aquisition,
                fundingSeeMore: !fundingSeeMore
            });
        }
    }
    


    render() {
        let theme = Helpers.getCardTheme(this.props.theme);
        let showContent = this.props.showContent;
        let pageViewsLeft = this.props.pageViewsLeft;
        let type = this.props.type;
        let newData = this.state.newData;
        let uri = this.props.entityUri;
        let mode = this.props.mode;
        
        return (
            <React.Fragment>
              {(this.state.tableData || this.state.aquisitionNew )&& 
              <>
              {!Helpers.isEmpty(this.state.aquisitionNew) ?
                <div className="card text-left bold-headers" id={this.props.id} id="acq"  ref={this.aquisitionDiv}>
                    {!showContent || pageViewsLeft < 1 ?
                        <NotUser title="Acquisitions" showContent={this.props.showContent} pageViewsLeft={this.props.pageViewsLeft} />
                        :
                        ""
                    }
                    <div>
                        {showContent && pageViewsLeft > 0 ?
                        <div class="col-md-12 p-0">
                            <div className={"col-md-6 float-left card-header text-left light-blue-header-half " + theme.headerClass}>
                                    Aquisitions
                            </div>
                            <div className="col-md-6 float-left text-right">
                                <span className="font-12">Number of Acquisitions  {this.state.noOfAq}</span> <br/>
                            </div>
                        </div>
                        : ""
                        }
                            <div className="card-body blue-border float-left full-width">
                                      {!Helpers.isEmpty(this.state.aquisitionNew) && showContent && pageViewsLeft > 0 ?
                                        <div>
                                            {type !=  "card" ? 
                                            <div className="table-responsive m-t-10">
                                                {!Helpers.isEmpty(this.state.tableData) ?
                                                    <table className="table color-table muted-table dataTable font-14">
                                                        <thead>
                                                            <tr>
                                                                <th>Acquired Organisation</th>
                                                                <th>Announced Date</th>
                                                                <th>Price</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.tableData.map((tblData, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            {
                                                                                tblData.acquiredCompanyUri ?
                                                                                    <a href={Helpers.sitePath('company/' + tblData.acquiredCompanyUri)}>
                                                                                        {tblData.acquiredCompany}
                                                                                    </a>
                                                                                    :
                                                                                    tblData.acquiredCompany
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {Helpers.getDateInInputFormat(tblData.date)}
                                                                        </td>
                                                                        <td>
                                                                            {tblData.price}
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                    :
                                                    ""
                                                }
                                            </div>
                                        :
                                        <div className="row">
                                            { 
                                            this.state.aquisitionNew.map((row,i)=>(
                                                <ListCard dataset={row} mode={{type:"aquisitions"}} newData={newData}/>
                                            ))}
                                        </div>

                                    }
                                      {mode == "funding" ? 
                                            <div className="justify-content-center flex-row d-flex col-xs-12">
                                                <h5 className="text-blue m-2 cursor-pointer" onClick={this.handleFundingSeeMore}>See More <i className={"m-l-5 fa "+ (this.state.fundingSeeMore?"fa-chevron-up":"fa-chevron-down")+" font-18"}></i></h5>
                                            </div> 
                                            :
                                            <div className="col-md-12 font-14 text-right float-right p-0"><Link to={`/company-funding-details/${uri}?div=aquisition`}>View All</Link></div>
                                        }
                                </div>
                                :( Helpers.isEmpty(this.state.aquisitionNew) && showContent && pageViewsLeft > 0 ? <div className="text-center">No Data Available</div> : "")
                            }
                        </div>
                    </div>
                </div>
                   :
                   ""
                 }
                 </>
              }
            </React.Fragment>
        )
    }
}

export default Aquisitions;
