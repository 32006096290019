import React, { Component } from "react";
import { withRouter, Redirect, Link } from "react-router-dom";
import Helper from "../../shared/custom";
import Axios from "axios";
import Helpers from "../../shared/custom";

class Publisher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      readyToPublish: false,
      isPublished: false,
      redirect: false,
      uri: "",
      messageToUser: "Your profile should be complet to be able to Publish it."
    };

    this.requestPublish = this.requestPublish.bind(this);
    this.renderRedirect = this.renderRedirect.bind(this);
    this.setRedirect = this.setRedirect.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      readyToPublish: nextProps.profileDetails
        ? nextProps.profileDetails.readyToPublish
        : false,
      uri:
        !Helpers.isEmpty(nextProps.profileDetails) &&
        !Helpers.isEmpty(nextProps.profileDetails.user) &&
        !Helpers.isEmpty(nextProps.profileDetails.user.uri)
          ? nextProps.profileDetails.user.uri
          : ""
    });
  }

  setRedirect = () => {
    this.setState({
      redirect: true
    });
  };

  renderRedirect = () => {
    let profileUri = "/people/" + this.state.uri;
    if (this.state.redirect) {
      return <Redirect to={profileUri} />;
    }
  };

  requestPublish() {
    if (this.state.readyToPublish == false) return false;

    // this.setState({loading:true});
    Axios.post(
      Helper.apiURL({
        uri: "user/profile/publish"
      })
    )
      .then(response => {
        this.setState({
          messageToUser: "Your Profile is published now",
          isPublished: true
        });
      })
      .catch(response => {
       
      });
  }

  render() {
    let readyToPublish = this.state.readyToPublish;
    let messageToUser = this.state.messageToUser;
    let isPublished = this.state.isPublished;
    return (
      <div>
        <div className="card text-left">
          <div className="card-body">
            <h5 className="font-bold">Publish your profile</h5>
           
            {readyToPublish ? (
              <React.Fragment>
                  <h6>
                  In order to build your network and gain more opportunities, you
                  need your profile to be accessible by Investera users.
                  </h6>
                  <div>
                    {isPublished ? (
                      <div>
                        {this.renderRedirect()}
                        <button
                          type="submit"
                          className="btn btn-success waves-effect waves-light m-r-10 float-right"
                          onClick={this.setRedirect}
                        >
                          View your profile
                        </button>
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-info waves-effect waves-light m-r-10 float-right"
                        onClick={this.requestPublish}
                      >
                        Publish your profile
                      </button>
                    )}
                  </div>
              </React.Fragment>
            ) : (
              <div>
                  <h6>
                  Your profile lacks enough details to be published, we recommend that you fill as much details as possible.
                  </h6>
                {/* <button
                  type="submit"
                  className="btn btn-info waves-effect waves-light m-r-10 float-right"
                  onClick={this.completeMyProfile}
                >
                  Complete your profile
                </button> */}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Publisher);
