const Messages = {
  ERR001: "It seems like the link has expired, please request a new one.",
  ERR002: "Username cannot contain spaces.",
  ERR003: "Password and Confirm Password does not match.",
  ERR004: "Password and Confirm Password values are required.",
  ERR005: "Invalid values found on the request, please re-check.",
  ERR006: "Entered email address already exists in our records.",
  ERR007: "Entered username already exists in our records.",
  ERR008:
    "Entered email does not belong to a valid user, please check the entered email.",
  ERR009: "Invalid captcha response, please validate.",
  ERR010:
    "Password must be of minimum 8 characters containing atleast one digit, one upper case letter, one lower case letter, one special character (from @#$%^&+=), and no space",
  ERR011: "Kindly login to change the password.",
  ERR012: "Invalid Object type",
  ERR013: "Unable to find the user!",
  ERR014: "Password saved successfully.",
  ERR015: "An error occurred",
  ERR016: "Access denied",
  ERR017: "Email sent successfully",
  ERR018: "User interests added successfully",
  ERR019: "User's company preferences saved successfully",
  ERR020: "The email link is invalid",
  ERR021: "It seems like the link has expired.",
  ERR022: "User not found",
  ERR023: "You have been logged out, please login to continue.",
  ERR024: "Invalid Email address",
  ERR025: "User Added",
  ERR026: "User email address has been confirmed.",
  ERR027: "Resent Email for user's email confirmation",
  ERR028: "Password changed successfully",
  ERR029: "Opportunity not found",
  ERR030: "Opportunity added successfully",
  ERR031: "Opportunity updated successfully",
  ERR032: "Opportunity deleted successfully",
  ERR033: "Invalid Opportunity status",
  ERR034: "Admin is not allowed to approve or reject the Opportunity",
  ERR035: "Opportunity status updated successfully",
  ERR036: "Opportunity fetched successfully",
  ERR037: "Access Denied to opportunity.",
  ERR038:
    "Pending approval! New admins cannot be added unless the listing is approved.",
  ERR039: "User Account is not confirmed",
  ERR040: "Opportunity admin added successfully",
  ERR041: "Owner cannot remove him/her self as admin",
  ERR042: "Admin does not exist",
  ERR043: "Opportunity admin removed successfully",
  ERR044: "Opportunity admins fetched successfully",
  ERR045: "Opportunities not found",
  ERR046: "The given file type is not supported",
  ERR047: "Event not found.",
  ERR048: "Event added.",
  ERR049: "Event updated.",
  ERR050: "Event deleted.",
  ERR051: "Event Fetched.",
  ERR052: "Company is not verified and published",
  ERR053: "Access Denied to event.",
  ERR054: "Event is not verified and published",
  ERR055: "Event is not private",
  ERR056: "No Internal guests found for this event",
  ERR057: "Guest not found",
  ERR058: "No External guests found for this event",
  ERR059: "Event admin added successfully",
  ERR060: "Event admin removed successfully",
  ERR061: "Event admins fetched successfully",
  ERR062: "Event guest added successfully",
  ERR063: "Event guest removed successfully",
  ERR064: "Event guests fetched successfully",
  ERR065: "Event Fetched for the logged in user.",
  ERR066: "Invalid Event status",
  ERR067: "Page Type is invalid.",
  ERR068: "Entity Info fetched successfully",
  ERR069: "Atleast a Text, image or video is mandatory for post.",
  ERR070: "Post not found.",
  ERR071: "Access Denied, you are not author of this post.",
  ERR072: "Post added.",
  ERR073: "Post updated.",
  ERR074: "Post deleted.",
  ERR075: "Company not found.",
  ERR076: "Access Denied to company. User is not admin",
  ERR077: "Company fetched successfully",
  ERR078: "Company added.",
  ERR079: "Company updated.",
  ERR080: "Company deleted.",
  ERR085: "Acquired Company not found.",

  ERR086: "Acquired Company name or unique id is mandatory.",
  ERR087: "Acquisition not found.",
  ERR088: "Individual name or id is mandatory.",
  ERR089: "Acquisition deleted",
  ERR090: "Acquisition Added",
  ERR091: "User is not Portal Admin",
  ERR092: "Sector already exists with given name",
  ERR093: "Sector not found",
  ERR094: "Parent Sector not found",
  ERR095: "Type is not valid",
  ERR096: "An entry already exist for the given type and name",
  ERR097: "Name is required",
  ERR098: "Order is required",
  ERR099: "A record already exist for this type and order",
  ERR100: "MetaData not found.",
  ERR101: "User profile not found.",
  ERR102: "User profile exists for the user.",
  ERR103: "Funding round details not found",
  ERR104: "Admin Already Exists",
  ERR105: "Party investment details not found.",
  ERR106: "Invested party not found.",
  ERR107: "Comment Not Found",
  ERR108: "Parent Unique id is mandatory",
  ERR109: "Share Details Not Found",
  ERR110: "News Not Found",
  ERR111: "Unable to find the investor information.",
  ERR112: "Funding round mapping not found",
  ERR113: "Guest is already invited.",
  ERR114: "Education entry not found.",
  ERR115: "Career entry not found.",
  ERR116: "End date is mandatory if it is not current.",
  ERR117: "Currency is Mandatory for financial Details",
  ERR118: "TagId not found.",
  ERR119: "Incorrect event date",
  ERR120: "Enter captcha code",
  ERR121: "Maximum 320 characters allowed in Email/Username",
  ERR122: "Password must be minimum 8 and maximum 32 characters long",
  ERR123: "Full name must be maximum 255 characters long",
  ERR124: "Duplicate Companies Entered.",
  ERR125: "The person is already mapped to the same company with same title",
  ERR126: "Invalid Name provided to create entity",
  ERR127: "Invalid opportunity details found in the request",
  ERR128: "Invalid post details found in the request",
  ERR129: "Invalid Founded Date",
  ERR130: "Invalid Employee Count",
  ERR131: "Technical Error: couldn't share by email.",
  ERR132: "Amount is mandatory",
  ERR133: "Notification not found",
  ERR134: "Invalid setting request",
  ERR135: "Technical Error: couldn't share by email.",
  ERR136: "Admin access request already Exists",
  ERR137: "Invalid Subscription Type passed",
  ERR138: "Invalid Payment request",
  ERR139: "Invalid Transaction Id",
  ERR140: "Payment finalization failed",
  ERR141: "Invalid Feed ID in the request",
  ERR142: "Privilege not found",
  ERR143: "Privilege already exists",
  ERR144: "Package Not Found",
  ERR145: "Access limit exceeded",


  GENERIC_ERROR: "Unable to process your request, please try again.",
  TITLE_REQUIRED: "Position is required.",
  PROFILE_IMAGE_REQUIRED: "Profile image is required.",
  OPP_IMAGE_REQUIRED: "Opportunity image is required.",
  ROLE_CREATOR_REQUIRED: "Role of the creator is required.",
  CHOOSE_COUNTRY: "Please choose country.",
  CHOOSE_CITY: "Please choose city.",
  INVESTMENT_TYPE_REQUIRED: "Investment type is required.",
  INVESTMENT_SUBTYPE_REQUIRED: "Investment sub type is required.",
  INDUSTRY_REQUIRED: "Industry is required.",
  TYPE_REQUIRED: "Type is required.",
  SUBTYPE_REQUIRED: "Subtype is required.",
  INVESTMENT_AMOUNT_REQUIRED: "Investment amount is required.",
  INVESTMENT_DEADLINE_REQUIRED: "Investment deadline is required.",
  PITCHER_CAPACITY_REQUIRED: "Pitcher capacity is required.",
  RISK_REQUIRED: "Risk level is required.",
  AREA_SERVED_REQUIRED: "Area served is required.",
  OWNERSHIP_REQUIRED: "Ownership status is required",
  DESCRIPTION_REQUIRED: "Description is required.",
  SHORT_DESCRIPTION_REQUIRED: "Short description is required.",
  LONG_DESCRIPTION_REQUIRED: "Long description is required.",
  ADDRESS_REQUIRED: "Address is required.",
  PHONE_NUMBER_REQUIRED: "Phone number is required.",
  EMAIL_REQUIRED: "Email is required.",
  WEBSITE_REQUIRED: "Website is required.",
  EMAIL_REQUIRED: "Email should not be empty.",
  INVALID_EMAIL: "Invalid email address.",
  INVALID_MOBILE: "Invalid mobile number.",
  ADMIN_EMAIL_EXIST: "Entered email address is already existing in admin list.",
  ADMIN_ADDED: "Admin has been successfully added.",
  ADMIN_REMOVED: "Admin has been successfully removed.",
  GUEST_ADDED: "The guest has been successfully invited.",
  GUEST_REMOVED: "The guest invitation has been revoked.",

  OPP_NOT_FOUND:
    "Requested opportunity details not found. Either the opportunity got removed, or you may not have enough permission to access the opportunity.",
  OPP_NOT_LOADED: "Unable to load requested opportunity details.",
  OPP_SUBMIT_APPROVAL: "Opportunity details has been submitted for approval.",
  OPP_NOT_SAVED: "Unable to save the opportunity details, please try again.",
  EVENT_SUBMIT_APPROVAL: "Event details has been submitted for approval.",
  EVENT_NOT_SAVED: "Unable to save the event details, please try again.",
  EVENT_SAVED: "Event saved successfully",
  EVENT_DELETED: "Event Deleted successfully",
  EVENT_DELETE_FAILED: "Unable to delete the event",
  COVER_PHOTO_REQUIRED: "Cover photo is required.",
  SUMMARY_REQUIRED: "Summary is required",
  NOT_DELETABLE_ITEM: "Item cannot be deleted.",
  EVENT_NOT_LOADED: "Unable to load requested event details.",
  EVENT_NOT_FOUND:
    "Requested event details not found. Either the event got removed, or you may not have enough permission to access it.",
  BODY_REQUIRED: "Post body is required.",
  POST_PUBLISHED: "Post has been published.",
  POST_NOT_PUBLISHED: "Unable to publish your post.",
  POST_DELETED:"Post deleted successfully",
  BAD_CREDENTIALS: "Unable to login, Please check your credentials.",

  SELECT_AREA_SERVED: "Areas served is required.",
  ENTER_COMPANY_NAME: "Company name is required.",
  ENTER_LEGAL_NAME: "Legal name is required.",
  CHOOSE_COMPANY_SIZE: "Company size is required.",
  CHOOSE_HQ_COUNTRY: "Country of headquarters is required.",
  CHOOSE_HQ_CITY: "City of headquarters is required.",
  ENTER_FOUNDED_YEAR: "Founded year is required.",
  ENTER_EMPLOYEE_COUNT: "Approximate employee count is required.",
  ENTER_COMPANY_ADDRESS: "Company address is required.",
  ENTER_COMPANY_ADDRESS_CITY: "Please choose the city of company address.",
  ENTER_COMPANY_ADDRESS_COUNTRY:
    "Please choose the country of company address.",
  ENTER_COMPANY_PHONE_NUMBER: "Please enter company phone number.",
  ENTER_COMPANY_EMAIL: "Please enter company email.",
  COMPANY_SUBMIT_APPROVAL: "Company details has been submitted for approval.",
  CHOOSE_COMPANY_SECTOR: "Please choose company sector",
  CHOOSE_MIN_ONE_IND: "Please choose at least one industry",
  COMP_NOT_FOUND:"Requested company details not found. Either the company got removed, or you may not have enough permission to access the company.",
  COMP_NOT_LOADED: "Unable to load the company details.",
  I_CURRENT_PASSWORD: "Invalid current password.",

  LINKEDIN_FAIL:
    "Unable to perform LinkedIn authentication. Please register or login using your credentials.",

  STARTDATE_REQUIRED: "Start Date is required",
  ENDDATE_REQUIRED: "End Date is required",
  I_CURRENT_PASSWORD: "Invalid current password.",
  PASSWORD_UPDATED: "Password has been successfully updated.",
  PASSWORD_RESET_ERROR: "Unable to reset your password, please check the link and enter correct email.",
  PASSWORD_RESET_ERROR_OTP: "Unable to reset your password!",
  FORM_EMPTY:"Please fill all the fields.",
  PASSWORD_DOES_NOT_MATCH: "Passwords does not match.",
  CATEGORY_REQUIRED: "Category is required",

  PROFILE_NOT_FOUND: "Please login to continue.",
  PROFILE_CREATED: "Profile have been created successfully.",
  PROFILE_UPDATED: "Profile have been updated successfully.",
  PROFILE_NOT_SAVED: "Unable to save profile changes, please try again.",

  SELECT_INDIVIDUAL: "Select an individual to add to the list.",
  SELECT_INDIVIDUAL_TITLE: "Please select the title of individual.",
  FIRST_NAME_REQUIRED: "Firstname is required.",
  LAST_NAME_REQUIRED: "Lastname is required.",
  PERSON_ADDED: "Person has been successfully added.",
  PERSON_UPDATED: "Person details has been updated.",
  SAVE_SUCCESS: "Saved Successfully.",

  R_addressLine1: "Address is required.",
  R_description: "Description is required",

  ANNOUNCED_DATE_REQUIRED: "Announced date is required.",
  FUNDING_TYPE_REQUIRED: "Funding type is required.",
  FUNDING_STAGE_REQUIRED: "Funding stage is required.",
  PRE_MONEY_VALUATION_REQUIRED: "Pre money valuation is required.",
  MONEY_RAISED_REQUIRED: "Money raised is required.",
  CURRENCY_REQUIRED: "Currency is required.",
  NOT_SAVED: "Unable to save the changes, please try again.",
  FUNDING_ROUNDS_NOT_ACCESSIBLE:
    "Unable to load funding round information, you may not have enough permission to access the funding rounds.",
  FUNDING_ROUND_DELETED: "Funding round has been removed.",
  FUNDING_ROUND_REQUIRED: "Funding round is required.",

  INVESTOR_REQUIRED: "Investor is required.",
  INVESTED_AMOUNT_REQUIRED: "Invested amount is required.",
  INVESTMENT_CREATION_FAILED: "Unable to add investment, please try again.",
  INVESTMENT_DELETE_FAILED: "Unable to remove investment, please try again.",

  START_DATE_REQUIRED: "Start Date is required",
  CAREER_TITLE_REQUIRED: "Career Title is required",
  CAREER_COMPANY_REQUIRED: "Company name is required",
  END_DATE_REQUIRED: "End Date is required",

  DEGREE_REQUIRED: "Degree is required",
  SUBJECT_REQUIRED: "Subject is required",
  SUBJECT_REQUIRED: "Subject is required",
  INSTITUTION_NAME_REQUIRED: "Institution name is required",
  R_CAPTCHA_CODE: "Please confirm you are not a robot.",
  ENDDATE_SHOULD_BE_AFTER_STARTDATE: "Event Dates should be set properly",
  INVALID_FACEBOOK_LINK: "Invalid Facebook link",
  INVALID_WEBSITE: "Invalid Website",
  INVALID_TWITTER_LINK: "Invalid Twitter link",
  INVALID_LINKEDIN_LINK: "Invalid Linkedin link",
  SUCCESS:"Saved Successfully.",
  R_CAPTCHACODE: "Captcha code is required.",
  ERR122:"Password must be minimum 5 and maximum 32 characters long.",
  I_EMAIL:"Invalid Email Address.",
  ITEM_DELETED :"Deleted Successfully.",

  RESET_EMAIL_SENT: "Please check your inbox, password reset instructions have been sent to the registered email.",
  OTP_SENT:"OTP has been sent to your register mobile number",
  SECTOR_REQUIRED: "Sector is required",

  EMAIL_NOT_REGISTERED: "Provided email does not exist in or records. Please provide a registered email.",
  MOBILE_NOT_REGISTERED:"Provided mobile number does not exist in or records. Please provide a registered mobile number.",

  ADMIN_ASSIGNED_SUCCESSFULLY: "You have been assigned to this company as an admin!",
  ADMIN_IS_ALREADY_ASSIGNED: "You are already an admin of this page!",
  CLAIM_REQUEST_SET: "Our team will contact you soon to verify your request.",
  CLAIM_REQUEST_ALREADY_SET: "Admin access request already Exists.",

  COMPANY_NAME_REQUIRED: "The Company name is required.",
  AMOUNT_REQUIRED: "The Amount is required",
  ENTER_SERVED_AREA:"The Served Area is required.",

  INVESTOR_SELECTION_REQUIRED:"Select an Investor.",

  PEOPLE_NAME_REQUIRED : "Name is required.",
  PEOPLE_ADD_SUCCESS : "Thank you. We will review your request and get back to you shortly."

};

export default Messages;
