export const GET_ERRORS = 'GET_ERRORS';
export const SET_TOKEN = 'SET_TOKEN';
export const UNSET_TOKEN = 'UNSET_TOKEN';
export const SET_USER = 'SET_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_USER_PROFILE = 'SET_CURRENT_USER_PROFILE';
export const REMOVE_CURRENT_USER_PROFILE = 'REMOVE_CURRENT_USER_PROFILE';
export const REMOVE_CURRENT_USER = 'REMOVE_CURRENT_USER';
export const REGISTER_NEW_USER = 'REGISTER_NEW_USER';
export const REGISTER_AUTHENTICATED = 'REGISTER_AUTHENTICATED';
export const SET_EMAIL_VERIFIED = 'SET_EMAIL_VERIFIED';
export const UNSET_EMAIL_VERIFIED = 'UNSET_EMAIL_VERIFIED';


export const GET_SEARCH_DATA = 'GET_SEARCH_DATA';
export const REMOVE_SEARCH_DATA = 'REMOVE_SEARCH_DATA';
export const SET_SEARCH_PARAMS = 'SET_SEARCH_PARAMS';
export const SAVE_SEARCH_CRITERIA = "SAVE_SEARCH_CRITERIA";
export const SAVE_ADVANCE_SEARCH_CRITERIA = "SAVE_ADVACE_SEARCH_CRITERIA";
export const SAVE_NEWS_SEARCH_CRITERIA = "SAVE_NEWS_SEARCH_CRITERIA";
export const FEED_LOADING_STARTED = "FEED_LOADING_STARTED";
export const FEED_LOADING_FINISHED = "FEED_LOADING_FINISHED";

export const SET_COMPANY_INFO = "SET_COMPANY_INFO";
export const UNSET_COMPANY_INFO = "UNSET_COMPANY_INFO";
export const COMPANY_NOT_FOUND = "COMPANY_NOT_FOUND";

export const SET_PAGE_INFO = "SET_PAGE_INFO";
export const SET_PAGE_REAL_INFO = "SET_PAGE_REAL_INFO";
export const UNSET_PAGE_INFO = "UNSET_PAGE_INFO";

export const INITIAL_REQUEST = "INITIAL_REQUEST";
export const GET_META_DATA = "GET_META_DATA";

export const GET_BUSINESS_DATA = "GET_BUSINESS_DATA";
export const GET_SECTOR_DATA = "GET_BUSINESS_DATA";
export const GET_SUB_INDUSTRY_DATA = "GET_SUB_INDUSTRY_DATA";
export const GET_INDUSTRY_GROUP_DATA = "GET_INDUSTRY_GROUP_DATA";;
export const SET_SELECTED_SECTOR = "SET_SELECTED_SECTOR";
export const SET_SELECTED_COMPANIES = "SET_SELECTED_COMPANIES";

export const SET_TAB_STATUS = "SET_TAB_STATUS";
export const GET_FUNDING_ROUND_DATA = "GET_FUNDING_ROUND_DATA";

export const DELETE_FEED_CARDS = "DELETE_FEED_CARDS";
export const REMOVED_BOOKMARKS = "REMOVED_BOOKMARKS";

export const GET_CURRENT_PLAN = "GET_CURRENT_PLAN";
export const GET_PAGE_VIEWS = "GET_PAGE_VIEWS";


export const GET_RANKING_DETAILS = "GET_RANKING_DETAILS";
export const REMOVE_RANKING_DETAILS = "REMOVE_RANKING_DETAILS";



export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
