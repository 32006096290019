import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class ProductService extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {

    }

    componentDidMount() {

    }


    render() {
        let { psl, type, title, mode } = this.props;

        console.log(psl, "pslpsl")
        return (

            <div className="card text-left bold-headers" id="pands">
                <div className="card-header text-left light-blue-header">
                    <h5 className="m-b-0 m-t-0 text-white">
                        {title || "Products / Services"}
                    </h5>

                </div>
                <div className="card-body  clearfix p-t-10">
                    <div className="clearfix grey-border round-border p-8 p-t-0">
                        {
                            type == "text" ?
                                <p className="font-14">{psl}</p>
                                :
                                <div className="breadcumb no-bg p-0 m-l-0 col-md-12 float-left">
                                    {psl ?
                                        psl.map((item, index) => (
                                            // <p key={index} className="full-width">
                                            //     <span  className="float-left l-h-j full-width"><Link to={"/companies/product/"+ (item.id) }  className={"label-rounded font-12 batch-label blue-text p-l-0 m-l-0 "} key={"IND"+item.id}>{item.name}</Link></span>
                                            // </p>
                                            <p key={index} className="full-width">
                                                <span className="float-left l-h-j full-width font-14">{item.name}</span>
                                            </p>
                                        ))
                                        :
                                        ""
                                    }
                                </div>
                        }

                    </div>
                </div>
            </div>

        );
    }
}


export default ProductService;