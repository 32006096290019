import React, { Component } from 'react'
import { withRouter, Redirect } from 'react-router-dom';
import config from '../../shared/config';
import Axios from 'axios';
import store from '../../store';
import Helpers from '../../shared/custom';
import ReactTooltip from 'react-tooltip';
import { fetchUserInfo } from '../../actions/authAction';
import { connect } from 'react-redux';


class ChangePassword extends Component {

    constructor(props,context){
        super(props);
        this.state = {
            newPassword:"",
            password:"",       
            confirmPassword:"",
            validationMsg : "",
            verifiedPassword:true,
            isMinimumChar:false,
            isOneNumber:false,
            isOneUpperCase:false,
            isOneLowerCase:false,
            isOneSplChar:false,
            showPassword:false,
            passwordType:"password",
            success:false,
        }
       
       // this.validateForm    = this.validateForm.bind(this);       
      //  this.changePassword    = this.changePassword.bind(this);       
        // this.handleInputChange = this.handleInputChange.bind(this);
        
    }

    handleInputChange = (e)=>{
        if(e.target.name == "newPassword"){
            this.checkPasswordStrength(e.target.value)
        }
        this.setState({[e.target.name]:e.target.value});
        var elem = e.target;
        if(elem.value == ""){
            elem.classList.add('invalid-field');
        }else{
            elem.classList.remove('invalid-field');
        }

    }

    checkPasswordStrength=(value)=>{
        let hasLowerCase = Helpers.hasLowerCase(value);
        let hasUpperCase = Helpers.hasUpperCase(value);
        let hasOneNumber = Helpers.hasOneNumber(value);
        let hasMinLength = Helpers.hasMinLength(value);
        let hasOneSplChar = Helpers.hasOneSplChar(value);
        this.setState({
            isOneLowerCase:hasLowerCase,
            isOneUpperCase:hasUpperCase,
            isMinimumChar:hasMinLength,
            isOneNumber:hasOneNumber,
            isOneSplChar:hasOneSplChar,
        })
      
    }

    componentDidMount(){
        var storeState = store.getState();
        var auth = storeState.auth;
        if(!auth.isAuthenticated){
            this.props.history.push("/")
        }
    }


    showPassword = (e)=>{
        e.preventDefault();
        this.setState(prevState =>{
            prevState.showPassword = !prevState.showPassword;
            prevState.passwordType = prevState.showPassword  ? "text" : "password";
            return prevState
        })
      }

    

changePassword = (userData) => {
    delete(userData.validationMsg);
    Axios.post(config.api("user/change-password"), userData
    )
    .then( (response) => { 
        const validationMsgs = Helpers.getMessage(response.data.message);        
        this.setState({'validationMsg':validationMsgs});
        if (200 == response.data.status) {
            fetchUserInfo(); 
            this.setState({
                success:true
            })
        }
    })
    .catch((err) => {
        this.setState({'validationMsg':"Unexpected error",success:false});
    });
}




      
    validateForm = (event)=>{
        event.preventDefault();        
        const password = this.state.password;      
        const newPassword = this.state.newPassword;
        const confirmPassword = this.state.confirmPassword;    
        
        var event = new Event('input', { bubbles: true });
  
        //todo: central options should be there
        var node = document.getElementById('password');
        node.dispatchEvent(event);
        this.handleInputChange(event)
    
        var node = document.getElementById('newPassword');
        node.dispatchEvent(event);
        this.handleInputChange(event)
        
        var node = document.getElementById('confirmPassword');
        node.dispatchEvent(event);
        this.handleInputChange(event)
        
        if( newPassword == "" || password == "" || confirmPassword == ""){
            this.setState({'validationMsg':"All the fields should be filled"});
            return false;
        }
        if (newPassword !== confirmPassword){
            this.setState({'validationMsg':"Passwords don't confirm!"});
            return false;
        }
        this.validationMsg = "";
        this.changePassword(this.state);
        event.preventDefault();
    }
    
  render() {
      
    let verifiedPassword = (this.state.newPassword == this.state.confirmPassword);
    let {
        isMinimumChar,
        isOneNumber,
        isOneUpperCase,
        isOneLowerCase,
        isOneSplChar,
        passwordType,
        showPassword,
        success
    } = this.state;
    return (
      <div>
          <div className="login-register customCard">
            <div className="col-lg-6 m-a auth-card">
                <div className="card-body m-t-40">
                    <h1 className="m-t-40 font-normal">Change Password</h1>
                    <p className="font-medium m-t-10">
                     Please enter the old and new password below
                    </p>
                   {success ?  <span id="success-message" className="green-text">{this.state.validationMsg}</span> :
                               <span id="error-message" className="error-message">{this.state.validationMsg}</span>}
                    <form className="form-horizontal  m-t-20 was-validated-" id="changePasswordForm" noValidate onSubmit={this.validateForm}>
                        <div className="form-group">
                            <div className="col-xs-12 col-md-8 col-lg-8 m-a">
                                <label className="control-label text-black m-b-0">Current Password</label>
                                <input className="form-control" name="password" id="password" type="password" required=""  onChange={this.handleInputChange}  />
                                <div className="invalid-feedback" /> </div>
                        </div>
                        <div className="form-group">
                            {/* <div className="col-xs-12 col-md-8 col-lg-8 m-a">
                            <label className="control-label text-black m-b-0">New Password</label>
                                <input className="form-control" type={passwordType} required="" placeholder="New Password" name="newPassword"   onChange={this.handleInputChange} value={this.state.newPassword}  data-tip data-for="password" data-event="click" maxLength="32"/>
                                <div className="invalid-feedback" /> 
                           </div> */}


                           <div class="input-group mb-3 pwCont col-xs-12 col-md-8 col-lg-8 m-a">
                            <label className="control-label text-black m-b-0 pwLabel">New Password</label>
                            <br/>
                            <input className="form-control" type={passwordType} id="newPassword" required=""  name="newPassword"   onChange={this.handleInputChange} value={this.state.newPassword}  data-tip data-for="password" data-event="click" maxLength="32"/>
                            <div className="invalid-feedback" /> 
                            <div class="input-group-append">
                            <button class="input-group-button showPwBtn" onClick={this.showPassword}>
                                {!showPassword ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                            </button>
                            </div>
                        </div>


                                <ReactTooltip id="password" globalEventOff="click" className="pwToolTip" place="right" effect="solid">
                                        <div className="text-white text-left pwToolTip">
                                            <h5 className="text-white text-left">Password Requirements:</h5>
                                            <ul className="text-white float-left no-style">
                                                <li className="text-white text-left fullfiled">{isMinimumChar ? <i class="fa fa-check-circle text-green" aria-hidden="true"></i> : <i class="fa fa-times-circle text-danger" aria-hidden="true"></i>} 8 characters minimum</li>
                                                <li className="text-white text-left">{isOneNumber ? <i class="fa fa-check-circle text-green" aria-hidden="true"></i> : <i class="fa fa-times-circle text-danger" aria-hidden="true"></i>} one number</li>
                                                <li className="text-white text-left">{isOneUpperCase ? <i class="fa fa-check-circle text-green" aria-hidden="true"></i> : <i class="fa fa-times-circle text-danger" aria-hidden="true"></i>} one uppercase letter</li>
                                                <li className="text-white text-left">{isOneLowerCase ? <i class="fa fa-check-circle text-green" aria-hidden="true"></i> : <i class="fa fa-times-circle text-danger" aria-hidden="true"></i>} one lower case letter</li>
                                                <li className="text-white text-left">{isOneSplChar ? <i class="fa fa-check-circle text-green" aria-hidden="true"></i> : <i class="fa fa-times-circle text-danger" aria-hidden="true"></i>} one special character</li>
                                            </ul>
                                       </div>
                                </ReactTooltip>
                        </div>
                        <div className="form-group">
                            <div className="col-xs-12 col-md-8 col-lg-8 m-a">
                              <label className="control-label text-black m-b-0">Confirm Password</label>
                                <input className="form-control" name="confirmPassword" id="confirmPassword" type="password" required=""  onChange={this.handleInputChange} maxLength="32" />
                                <div className="invalid-feedback" /> </div>
                                { !verifiedPassword ? 
                                        <div className="form-group text-center font-12 col-12 p-0 m-b-0">
                                            <div className="text-danger text-center">Password does not match</div> 
                                        </div>
                                            : "" 
                                }
                        </div>
                    
                        <div className="form-group text-center m-t-20">
                            <div className="col-xs-12 col-lg-8 m-a">
                                <button className="btn btn-info btn-md btn-block text-uppercase waves-effect waves-light" type="submit">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        
      </div>
    )
  }
}


export default connect(null,{fetchUserInfo})(withRouter(ChangePassword));
