import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Helper from "../../shared/custom";
import config from "../../shared/config";
import store from "../../store";
import Axios from "axios";
import Publisher from "./Publisher";

class Rightpanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileDetails: null
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      profileDetails: nextProps.profileDetails
    });
  }

  render() {
    let profileDetails = this.props.profileDetails;
    let showPublisher = true; //Helper.isEmpty(profileDetails.page) ? true : false;
    return (
      <div>
        {showPublisher ? <Publisher profileDetails={profileDetails} /> : ""}
      </div>
    );
  }
}
export default withRouter(Rightpanel);
