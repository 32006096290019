import React, { Component } from 'react';
import config from '../../shared/config';
import Helper from '../../shared/custom';
import CardActions from './CardActions';
import Comments from './Comments';
import CommentShareCount from './CommentShareCount';
import CommentShareBtn from './CommentShareBtn';
import AuthorDetails from './AuthorDetails';
import ReadMoreAndLess from 'react-read-more-less';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

export default class OppFeedCard extends Component {

    constructor(props){
        super(props);
        this.state = {
            focusInput:false,
            updatedId :null
        }
        this.focusInputFiled = this.focusInputFiled.bind(this);
        this.updateCounts = this.updateCounts.bind(this);
    }

    focusInputFiled(){
        this.setState({
            focusInput: !this.state.focusInput
        })
    }
    updateCounts(id){
        if(!Helper.isEmpty(id)){
            this.setState({
                updatedId:id
            })
        }
      }

    
  render() {
    let data = this.props.data;
    let shared = this.props.shared;
    return (
      <div>
          <div className="card" id="oppFeedCard">
                    <div className="card-body">
                    {
                        shared ? 

                            <div className="col-lg-12 p-0">
                                    <AuthorDetails data={data} type="shared" />
                                    <div className="col-lg-3 p-0 float-right text-right">
                                    <CardActions data={data}/>
                                    </div>
                                <div className="clearfix"></div>

                                <div className="col-lg-12 p-0 m-t-20">
                                <hr/>
                                        <AuthorDetails data={data}/>
                                </div>
                                <div className="clearfix"></div>
                          </div>
                       :
                            <div className="col-lg-12 p-0">
                                <AuthorDetails data={data}/>
                                <div className="col-lg-3 p-0 float-right text-right">
                                    <CardActions data={data}/>
                                </div>
                            </div>

                    }
                        <div className="clearfix"></div>
                            <div className="col-lg-12 p-0 m-t-10 ">

                            <div className="col-lg-12 p-0 m-t-40 ">
                                <div className="b-b-2 p-b-5">
                                  <span> <i className={`mdi ${Helper.getOppIcon(data.type.value)} font-24 vm m-r-5`}></i> <Link className="font-bold" to="/">{data.author.name}</Link> Added an Opportunity</span>
                                </div>
                            </div>

                                {/* <div className="m-a text-center">
                                    <h2><i className={!Helper.isEmpty(data && data.type) ?  "text-info font-80 mdi "+Helper.getOppIcon(data.type.value):""}></i></h2>
                                    <h4 className="font-18">{data.author.name} Added an Opportunity</h4>
                                    <h4 className="text-info font-18">{data.name}</h4>
                                </div> */}
                            </div>
                    </div>
              
                    <div className="card-body laptop p-0">
                         <div className="col-lg-12 p-0 m-t-10 ">
                                <div className="m-a text-center">
                                <img src={Helper.dataPath(data.profilePic && data.profilePic.publicUri ? data.profilePic.publicUri : config.defaultCoverPhoto)} className="m-w feed-img" />
                                </div>
                            </div>
                    </div>

                    <div className="card-body opportunity-card m-t-5">
                         <div className="col-lg-12 p-0 m-t-10 ">
                                 {/* <ReadMoreAndLess
                                          ref={this.readMore}
                                          className="read-more-content font-14"
                                          charLimit={config.summaryLimit}
                                          readMoreText="Read more"
                                          readLessText="Read less"
                                      >
                                      {data.summary?data.summary:"-"}
                                  </ReadMoreAndLess> */}
                                  <Link className="font-bold font-16 col-md-12 row m-b-10"  to={"/opportunity/"+data.uri}>
                                 {data.name}
                                 </Link>
                                  
                                  <p className="sub-heading p-0 m-t-4">
                                      <span className="m-0"><i className="far fa-money-bill-alt"/></span>
                                       Primary Investment Type: {data.type.value}
                                  </p>
                                  <p className="font-14">{data && data.summary?Helper.truncate(data.summary,200):"-"}</p>
                               
                        </div>
                        
                        {/* C<CommentShareCount data={data} updatedId={this.state.updatedId}/> */}
                        <CommentShareBtn data={data} showInterested={true} focusInputArea={this.focusInputFiled} updateCounts={this.updateCounts}/>
                        <Comments data={data} enableFocus={this.state.focusInput} />
                    </div>
        </div>
        
      </div>
    )
  }
}
