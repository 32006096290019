import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Banner from "../profile/Banner";
import Founders from "../profile/Founders";
import Funding from "../profile/Funding";
import People from "../profile/People";
import Events from "../profile/Events";
import Investors from "../profile/Investor";
import Aquisitions from "../profile/Aquisitions";

import Stockgraph from "../profile/Stockgraph";
import Investments from "../profile/Investments";
import Helper from "../../shared/custom";
import store from "../../store";
import Helpers from "../../shared/custom";
import config from '../../shared/config';
import RatingDetails from '../ui/RatingDetails';
import NotFound from '../ErrorPages/NotFound';



import { connect } from 'react-redux';
import { getPackageDetails } from '../../actions/packageAction';
import { removeRankingDetails } from '../../actions/rankingAction';

import SimilarCompanies from "../content/SimilarCompanies";
import RelatedNews from "../content/RelatedNews";
import CompanyNews from "../content/CompanyNews";
import RelatedReports from "../content/RelatedReports";
import Documents from '../wigets/Documents';
import { CompanyMeta } from "../../shared/MetaHelper";
import Similar from '../wigets/Similar';


class Companydetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContent: false,
      entityUri: null,
      location: props.location,
      stockData: {},
      privileges: {},
      pageOwnerId: null,
      pageViewsLeft: 0,
      industries: [],
      isOwner: false,
      page: {}
    };
    store.subscribe(() => {
      var storeState = store.getState();
      let storeAuth = storeState.auth.isAuthenticated;
      let localAuth = this.state.authenticated;
      let entityData = storeState.entity.company.entityInfo;
      let notFound = storeState.entity.notFound;

      this.setState({
        entityData: entityData,
        notFound: notFound
      });



      if (entityData && entityData.page && entityData.page.owner) {
        let users = storeState.auth.user;
        let page = entityData.page;
        let ownerDetails = page && page.owner ? page.owner.id : null;

        let isOwner = (users.id == ownerDetails || users.adminOwner);
        this.setState({ isOwner: isOwner })

        if (this.state.pageOwnerId != entityData.page.owner.id) {
          this.setState({
            pageOwnerId: entityData.page.owner.id,
            page: entityData.page
          });
        }
      }

      if (JSON.stringify(storeAuth) != JSON.stringify(localAuth)) {

        let authenticated = storeState.auth.isAuthenticated;

        let companyData;
        if (!Helper.isEmpty(entityData)) {
          companyData = entityData.company;
        }
        let stockData;
        if (!Helper.isEmpty(companyData)) {
          stockData = companyData.ipoData;
        }

        if (!Helper.isEmpty(stockData)) {
          this.setState({
            stockData: stockData
          });
        }

        var industryData = companyData && companyData.industries ? companyData.industries : [];
        let industries = [];

        if (!Helpers.isEmpty(industryData)) {
          industryData.forEach(function (data, i) {
            industries.push(data.industry.id)
          })
          this.setState({ industries: industries })

        } else {
          this.setState({ industries: [] })
        }

        this.setState({
          showContent: authenticated
        });
      }
    });
    this.myDivToFocus = React.createRef()
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let location = this.state.location.pathname;
    this.props.removeRankingDetails()
    let entityUri = Helper.lastSegment(location);
    // var storeState = store.getState();
    // let storeAuth = storeState.auth.isAuthenticated;
    let storeAuth = Helper.authStatus();
    let pageViewsLeft = Helper.getPageViewsLeft();



    this.setState({ entityUri: entityUri, showContent: storeAuth, pageViewsLeft: pageViewsLeft, notFound: false });
  }

  componentWillReceiveProps(nextProps) {
    var storeState = store.getState();

    let currentUri = this.state.entityUri || undefined;
    if (nextProps.location && nextProps.location.pathname) {
      let newUri = Helper.lastSegment(nextProps.location.pathname);
      if (newUri !== currentUri) {
        window.scrollTo(0, 0);
        this.setState({ entityUri: newUri });
        this.props.removeRankingDetails()
      }
    }


    let packageDetails = nextProps.packageDetails;
    let privileges = packageDetails.privileges;
    let pageViewsLeft = packageDetails.pageViewsLeft ? packageDetails.pageViewsLeft : 0;

    if (!Helpers.isEmpty(privileges)) {
      this.setState({
        privileges: privileges,
        pageViewsLeft: pageViewsLeft
      })
    }
  }
  scrollToRankDiv = () => {
    if (this.myDivToFocus.current) {
      this.myDivToFocus.current.scrollIntoView({
        behavior: "smooth",
        // block: "nearest",
      })
    }
  }



  render() {
    let privileges = this.state.privileges;
    let pageViewsLeft = this.state.pageViewsLeft;
    let entityUri = this.state.entityUri;
    let showContent = this.state.showContent;
    let stockData = this.state.stockData;
    let pageTypes = this.state.entityData && this.state.entityData.page && this.state.entityData.page.pageTypes ? this.state.entityData.page.pageTypes : null;
    let companyType = this.state.entityData && this.state.entityData.company && this.state.entityData.company.companyType ? this.state.entityData.company.companyType : null;
    let documents = this.state.entityData && this.state.entityData.page && this.state.entityData.page.documents ? this.state.entityData.page.documents : [];
    let { pageOwnerId, isOwner } = this.state;
    let notFound = this.state.notFound;
    let entityName = this.state.entityData && this.state.entityData.page ? this.state.entityData.page.name : "";
    let description = this.state.entityData && this.state.entityData.page &&
      this.state.entityData.page.bannerDescription && this.state.entityData.page.bannerDescription.company ?
      this.state.entityData.page.bannerDescription.company : "";
    let image = this.state.entityData && this.state.entityData.page ?
      Helper.dataPath(this.state.entityData.page.image) : "";



    let entity = this.state.entityData && this.state.entityData.page ?
      this.state.entityData.page : "";

    let psl = [];
    if (this.state.entityData && this.state.entityData.company) {
      psl = this.state.entityData.company.psl;
    }


    return (

      <div>
        <CompanyMeta
          entityUri={entityName}
          description={description}
          image={image}
        />
        <Banner entityUri={entityUri} privileges={privileges} pageOwnerId={pageOwnerId} showContent={showContent} pageViewsLeft={pageViewsLeft} psl={psl} scrollToRank={this.scrollToRankDiv} />
        {!notFound ?
          <div>
            {/* { privileges &&  privileges["FUND_INFO"] &&  <Funding entityUri={entityUri} showContent={showContent} pageViewsLeft={pageViewsLeft} id="funding"/>}
      {
          !Helpers.isStartupCompany(pageTypes) && privileges &&  privileges["FUND_INFO"]  ?
          <Investments entityUri={entityUri} showContent={showContent} pageViewsLeft={pageViewsLeft} id="investments"  type="card"/>
          :
          ""
        }  
         */}
            {/* {privileges &&  privileges["FUND_INFO"] && <Aquisitions entityUri={entityUri} showContent={showContent} pageViewsLeft={pageViewsLeft} id="aquisition" type="card"/>}

         */}
            {/*       
      {privileges && privileges["FKP_BOD"] ?
        <div>
          
          <Founders 
            entityUri={entityUri} 
            showContent={showContent} 
            pageViewsLeft={pageViewsLeft}
            id="founders"
          />          
          <People 
            title="Team Members"
            type="keyPeople"
            entityUri={entityUri}
            showContent={showContent}
            pageViewsLeft={pageViewsLeft}
            id="team"
          />
          <People
            title="Board Of Directors"
            type="bod"
            entityUri={entityUri}
            showContent={showContent}
            pageViewsLeft={pageViewsLeft}
            id="bod"
          />
        </div>
        :
        ""
        }        
        */}

            {/* {privileges &&  privileges["FUND_INFO"] ? 
            <Investors entityUri={entityUri} showContent={showContent} pageViewsLeft={pageViewsLeft} id="investors" type="card"/>
            :
            ""
        } 
    
        {Helper.isPublicCompany(companyType)? (
          <Stockgraph stockData={stockData} showContent={showContent} pageViewsLeft={pageViewsLeft} id="ipo"/>
         ):""} 

         */}

            {privileges && privileges["EVT"] ?
              <Events entityUri={entityUri} id="events" showContent={showContent} pageViewsLeft={pageViewsLeft} />
              :
              ""}

            {/* {privileges && privileges["ADD_INFO"] ? */}
            {isOwner && <Documents entityUri={this.state.entityUri} type="COMPANY" data={documents} />}
            {/* :""} */}
            {/* {privileges && privileges["RPTS"] ? <RelatedReports entity={entity} type="COMPANY"/> : ""} */}
            {/* {privileges && privileges["NWS"] && <CompanyNews entityUri={this.state.entityUri} type="COMPANY" entity={entity} />}
        {privileges && privileges["NWS"] && <RelatedNews  uri={this.state.entityUri} industries={this.state.industries}/> } */}
            <SimilarCompanies uri={this.state.entityUri} type="COMPANY" />

            <Similar items="2" title="Related Events" type="events" uri={`search/advanced?t=event&industry=${this.state.industries}`} page="related" industries={this.state.industries} />

            <div ref={this.myDivToFocus} style={{ minHeight: "100px" }}>
              <RatingDetails data={this.state.entityData} />
            </div>
          </div>
          : <NotFound />
        }
      </div>

    );
  }
}


const mapStateToProps = (state) => ({
  packageDetails: state.packageDetails,
  errors: state.errors
});

export default connect(mapStateToProps, { getPackageDetails, removeRankingDetails })(withRouter(Companydetails));
