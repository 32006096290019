import React, { Component } from 'react';
import Collapse, { Panel } from 'rc-collapse';
import 'rc-collapse/assets/index.css';
import config from '../../shared/config';
import Footer from './Footer';
import { withRouter, Link } from 'react-router-dom';

export class Faq extends Component {
    constructor(props){
        super(props);
        this.state = {
            activeKey:0
        }
        this.onChange = this.onChange.bind(this);
        this.expandIcon = this.expandIcon.bind(this);
    }
    onChange = (activeKey) => {
        this.setState({
          activeKey,
        });
      }
    faqs  = [
        {
            question:" What are the membership requirements?",
            answer: <div>
                        <ul>
                            <li>For Individual: need to sign up with their emails and mobile number.</li>
                            <li>For Business (Startup and companies): KRA Pin Certificate and Certificate of Incorporation.</li>
                            <li>For Government/Investors: should contact <a href="mailto:sales@investeraplusafrica.com">(sales@investeraplusafrica.com)</a>.</li>
                        </ul>
                    </div>
        },
        {
            question:"What are the payment plans?",
            answer: <div>
                         Look through our subscription packages here <Link to={"/membership"}>(https://investeraplus.africa/membership)</Link> and see which one fits your needs best!
                    </div>
        },
        {
            question:"What can I expect once I register?",
            answer: <div>
                        <ul>
                            <li>Get access to highly insightful industry reports, news and market maps covering 100+ sectors and sub-sectors.</li>
                            <li>Network with our community of 3000+ Investors and get the opportunity to pitch their products and services.</li>
                            <li>Navigate through the in-depth information on 100K+ companies and 20K+ professionals.</li>
                            <li>Capture a 360-degree view of stakeholders in an Entity via state-of-the-art knowledge graphs.</li>
                        </ul>
                    </div>
           
        },
        {
            question:"Does Investera Plus Africa provide funding?",
            answer: "We do not invest in businesses. However, the platform allows businesses to access investors."
           
        },
        {
            question:"How do I unsubscribe from the platform?",
            answer: <div>
                        If this is the case for you and/or your company, we are very sorry to hear that! To unsubscribe, get in touch with our team here <Link to="contact">(https://investeraplus.africa/contact)</Link> - so we can better understand why you’d like to unsubscribe and how we can better improve our product!
                    </div>
           
        },
        {
            question:"Is this platform just for Kenyan Companies?",
            answer: "Investera Plus Africa is built for the benefit of Africa. Any company from across the world with business interest in Africa can join the community. "
           
        },
        
    ]
    expandIcon = object => {
        return <i className="mr-2 fa">{!object.isActive? '+' : '-'}</i>
    }
    render() {
        const activeKey = this.state.activeKey;
        let { from , ref}  = this.props;
        
        return (
        <React.Fragment>
          <div className="new_home" id="faq" ref={ref}>
            <div className="container faq-container">
                    <div className="row rowed text-left">
                        <div className="col-md-12">
                              <h2 className="h2 custom-heading">Frequently Asked<br/>
                                Questions</h2>
                        </div>
                        <div className="col-md-12">
                            <div className="faqs mt-4">
                                    <Collapse
                                    accordion={true}
                                    onChange={this.onChange}
                                    activeKey={activeKey}
                                    expandIcon={this.expandIcon}>
                                        {
                                            this.faqs.map((faq,index)=>{
                                                    return(
                                                        <Panel className="faq-Header" header={faq.question} key={index}>
                                                            <p>{faq.answer}</p>
                                                        </Panel>
                                                    )
                                            })
                                        }                            
                                    </Collapse>
                            </div>   
                        </div>
                    </div>
                 </div>
                {from != "home" && <Footer/>}
              </div>
           </React.Fragment>
        )
    }
}

export default Faq
