import React, { Component } from 'react';
import Helper from '../../shared/custom';
import countries from "../../shared/country";
import Select from 'react-select';
import areas from '../../shared/areas';

export default class SubForm1 extends Component {

    constructor(props){
        super(props);
        this.state = {
            countryOptions:[],
            areasServedOptions:[],
            ownershipStatusOptions:[],
            type:null
        }   
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount(){        
       
        let countryOptions = Helper.makeSelectOptions(countries);
        let areasServedOptions = Helper.makeSelectOptions(areas);
        let ownershipStatusOptions = [];
        
        let metaList = this.props.metaList;
        if(metaList && metaList.COS){
            ownershipStatusOptions = Helper.makeSelectOptions(Helper.getOwnerShipOptionsByType(metaList.COS,this.props.type));
        }

        this.setState({
            countryOptions:countryOptions,
            areasServedOptions:areasServedOptions,
            ownershipStatusOptions:ownershipStatusOptions,
            type:this.props.type
        })
        
    }

    onChange(){
        let metaList = this.props.metaList;
        let ownershipStatusOptions = [];
        
        if(metaList && metaList.COS){
            ownershipStatusOptions = Helper.makeSelectOptions(Helper.getOwnerShipOptionsByType(metaList.COS,this.props.type));
            this.setState({
                ownershipStatusOptions:[],
            },()=>{
                this.setState({
                    ownershipStatusOptions:ownershipStatusOptions,
                })
            })
           
        }

    }

    componentWillReceiveProps(props){    
       
        let ownershipStatusOptions = [];
        let metaList = this.props.metaList;
        if(metaList && metaList.COS){
            ownershipStatusOptions = Helper.getOwnerShipOptionsByType(Helper.makeSelectOptions(metaList.COS),this.state.type);
           // ownershipStatusOptions = Helper.makeSelectOptions(metaList.COS);
        }
        this.setState({
            ownershipStatusOptions:ownershipStatusOptions,
            type:this.props.type
        })
    }

    render() {
        let areasServedOptions = this.state.areasServedOptions;
        let ownershipStatusOptions = this.state.ownershipStatusOptions;
        return (
            <div>
                <div className="form-body opp-form">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="control-label">Ownership Type</label>
                                    <Select
                                        name="ownershipStatus"
                                        class="select-2"
                                        options={ownershipStatusOptions}
                                        value={this.props.values.ownershipStatus}              
                                        onChange={this.props.onSelectChange}
                                        onMenuOpen={this.onChange}
                                        placeholder="What is the ownership status of your company"
                                    />

                                    <label className="control-label m-t-20 isMandatoryLabel">Area(s) Served</label>
                                    <Select
                                        name="areasServed"
                                        class="select-2"
                                        isMulti
                                        options={areasServedOptions}
                                        value={this.props.values.areasServed}
                                        onChange={this.props.onSelectChange}
                                        placeholder="Where does your company operate? Choose as many options as required"
                                    />                                
                                </div>
                            </div>                          
                        </div>
                    </div>        
            </div>
        )
    }
}
