import React, { Component } from 'react';
import Loginpage from "./Loginpage";
import Register from "./Register";
import Helper from "../../shared/custom";

export default class Auth extends Component {
    constructor(props){
        super(props);
        this.state ={
            activeTab:"register",
            localProps:{}
        }
        this.setActive = this.setActive.bind(this);
    }

    componentDidMount(){
        this.setState({
            activeTab:this.props.location.activeTab !=null?this.props.location.activeTab:"register"
        })
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(JSON.stringify(nextProps) != JSON.stringify(prevState.localProps)){
             prevState.activeTab =  nextProps.location.activeTab != null ? nextProps.location.activeTab:"login";
             prevState.localProps = nextProps;
            return {prevState}
        }else{
            return {
                
            }
        }
    }

    setActive = (e)=>{
        let name = e.currentTarget.name
        this.setState({
           activeTab : name,
           test:name
        })
    }
    render() {
        let { activeTab } = this.state;

        return (
            <div>
                   <div className="login-register customCard col-md-6 col-sm-12 m-a auth-div">
                        <ul className="nav nav-tabs auth-tabs" role="tablist">
                            <li className="nav-item"> <a onClick={this.setActive} name="login" className={"nav-link " + (activeTab=="login"?"active show":"")}  data-toggle="tab" href="#login" role="tab" aria-selected={activeTab=="login"?"true":"false"}><span className="hidden-sm-up"><i className="ti-home"></i></span> <span className="hidden-xs-down">Login</span></a> </li>
                            <li className="nav-item"> <a onClick={this.setActive} name="register" className={"nav-link " + (activeTab=="register"?"active show":"")} data-toggle="tab" href="#register" role="tab" aria-selected={activeTab=="register"?"true":"false"}><span className="hidden-sm-up"><i className="ti-user"></i></span> <span className="hidden-xs-down">Register</span></a> </li>
                        </ul>
                        <div className="tab-content card">
                            <div id="login" className={"container tab-pane "  + (activeTab=="login"?"active":"")}><br/>
                                <Loginpage from="auth-pane"/>
                            </div>
                            <div id="register" className={"container tab-pane " + (activeTab=="register"?"active":"")}><br/>
                                <Register from="auth-pane"/>
                            </div>
                        </div>
                    
                </div>
            </div>
        )
    }

}
