import React, { Component } from 'react';
import TrendingNews from '../wigets/TrendingNews';
import ExpireDate from "../wigets/ExpireDate";

export default class NewsRightPanel extends Component {
    constructor(props){
        super(props);
    }

  render() {
    return (
      <div className="sticky rightItems">
          {/* <ExpireDate/> */}
          <TrendingNews />
      </div>
    
    )
  }
}
