import React, { Component } from 'react';
import config from '../../shared/config';
import Helper from '../../shared/custom';
import LoaderLocal from '../ui/Loader';
import Axios from 'axios';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { BrowserRouter as Router, Route, Link ,withRouter } from "react-router-dom";
import Helpers from '../../shared/custom';

class AllNotification extends Component {
  constructor(props){
      super(props);
      this.state = {
        uri:null,
        type:null,
        notifications:[],
        totalCount:0,
        remaining:0,
        actionLoading:false,
        pagination:{
            page:1,
            length:config.defaultNotificationsPageSize
        },
        isFetching:false,
        localProps:{},
        mode:null,
        endPoint:"user/notifications/summary",
        reqMode:"slider",
        loading:true,
        localDeletedFeed:null,
        localRemovedBookmarks:null,
        localStore:{},
        isPostAdded:false
      }

      this.handleRead = this.handleRead.bind(this);
  }  
  componentDidMount(){
      window.addEventListener('scroll', this.loadOnScroll);
      
      this.loadNotifications();
       
 }

 componentWillUnmount(){
    window.removeEventListener('scroll', this.loadOnScroll);
}

loadOnScroll = (e) =>{
    if(this.state.totalCount == 0) return;
    var el = document.getElementById('content-end');
    
    if (!el) return;

    var rect = el.getBoundingClientRect();

    var isAtEnd = (
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /*or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /*or $(window).width() */
    );

    // if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
    //     this.loadNotifications();
    // }

    //User at the end of content. load more content
  
    if(isAtEnd){
      this.loadNotifications();
    }
}


clearLoadedNotifications = () => {
    this.setState({
        notifications: [],
        totalCount:0
    });
    
}


  loadNotifications = () => {
  
  

    let state = this.state;
    let iDisplayStart = state.iDisplayStart;
    let iDisplayLength = state.iDisplayLength;

    let queryParam = {};

    if(!Helper.isEmpty(iDisplayStart)){
            queryParam.iDisplayStart = iDisplayStart
    }
    if(!Helper.isEmpty(iDisplayLength)){
        queryParam.iDisplayLength = iDisplayLength
    }


    if(this.state.isFetching) return

    this.setState({isFetching:true}, function(){

        let endPoint = this.state.endPoint;

       
        this.setState({
            loading:true
        })
        
        Axios.get(
            Helper.apiURL({
                uri:endPoint,
                query:Helper.generatePaginationQS(this.state.pagination)
            })
        )
        .then( (response) => {
       
            this.setState({
                loading:false
            });

            let notifications = [];
            let totalCount = 0;
           
            if (!Helper.isEmpty(response.data.result) && !Helper.isEmpty(response.data.result.aaData) ){
                notifications = response.data.result.aaData;
                totalCount = response.data.result.iTotalDisplayRecords;
            }
              
            this.setState(() => {
                return {
                    notifications:[
                            ...this.state.notifications,
                            ...notifications
                            ],
                    totalCount:totalCount,
                    remaining: (totalCount - this.state.pagination.page * this.state.pagination.length),
                    pagination:{
                                page:this.state.pagination.page+1,
                                length:config.defaultNotificationsPageSize
                               },
                    isFetching:false,
                    reqMode:"slider"
                    };
                }, () => {
             
                });
        })
        .catch((err) => {  
            this.setState({
                loading:false
          })
        });
    });
}


  handleRead(e){
        e.stopPropagation();
        e.preventDefault();
        let payload = {};
        let id = e.currentTarget.attributes.getNamedItem('data-id').value;
        let category = e.currentTarget.attributes.getNamedItem('data-category').value;
        let uri = e.currentTarget.attributes.getNamedItem('data-uri') && e.currentTarget.attributes.getNamedItem('data-uri').value;
        uri= !Helper.isEmpty(uri) ?  Helper.lastSegment(uri) : "";
        let feedUniqueId =  e.currentTarget.attributes.getNamedItem('data-feeduniqueid') ? e.currentTarget.attributes.getNamedItem('data-feeduniqueid').value : "";
       

        payload.isRead = true;
        payload.id = id;
        Axios.put(
        Helper.apiURL({
          uri: "user/notification/mark",
        }),
        payload
       )
        .then(response => {
          this.setState({
            read: true
          },()=>{
            if(!Helper.isEmpty(feedUniqueId)){
                this.props.history.push("/feed/details/"+feedUniqueId);
            }else{
                this.props.history.push("/"+category+"/"+uri);
            }
          });
          
        })
        .catch(err => {
            if(!Helper.isEmpty(feedUniqueId)){
                this.props.history.push("/feed/details/"+feedUniqueId);
            }else{
                this.props.history.push("/"+category+"/"+uri);
            }
        });
  }

  handleDelete=(e)=>{
    e.stopPropagation();
   let id = e.currentTarget.attributes.getNamedItem('data-id').value;
   
   
  this.setState({actionLoading:true})
   Axios.delete(
       Helper.apiURL({ 
           uri: `user/notification/${id}`
       }),{
           headers: { 'Content-Type': 'text/plain' }
         }
   ).then(response => {
           document.getElementById(`${id}-div`).remove();
           this.setState({actionLoading:false})
       })
       .catch(err => {
           Helper.pushError("Cannot delete at the moment!");
           this.setState({actionLoading:false})
       });
}

toggleList = (e, index ) =>{
    e.preventDefault();
    e.stopPropagation();
    this.setState(prevState => {
        let notifications = prevState.notifications;
        notifications[index]["isOpen"] = !notifications[index]["isOpen"];
        return {notifications}
    })

}

  render() {
    let {loading , notifications , showHearBeat , actionLoading} = this.state;
    if(loading){
        return (<div><LoaderLocal/></div>);
    }
    return (

                <div className="all-notifications mailbox notification white-backgroud" aria-labelledby="2">
                     <LoadingOverlay>
                        <Loader fullPage loading={actionLoading}/>
                    </LoadingOverlay>

                    <div className="card text-left bold-headers">
                        <div className="card-header text-left light-blue-header">
                            <h5 className="m-b-0 m-t-0 text-white">Company News</h5>
                        </div>

                        <div className="card-body">

                        <ul className="notif-style">
                            <li>
                                <div className="message-center">
                                    {!Helper.isEmpty(notifications) ?
                                            notifications.map((data,index)=>(
                                                <React.Fragment>
                                                <a 
                                                style={{
                                                    backgroundColor: !data.read  ? '#edf7f0' : '#fff',
                                                    borderLeft : !data.read ? '2px solid #359f55' : 'none',
                                                    paddingInline: '20px',
                                                    paddingBlock: '15px'
                                                }} 
                                                data-category={data.category} data-feeduniqueid={data.feedUniqueId} data-uri={data.uri} data-id={data.id} key={"notify"+index} id={data.id+'-div'} className={data.read ? "text-left" : "notif-is-unread text-left"} onClick={this.handleRead}>
                                                    <span className="noti-close no-default" data-id={data.id}  data-feeduniqueid={data.feedUniqueId}  onClick={this.handleDelete} >
                                                        <i class="fa fa-times"></i>
                                                    </span>
                                                    <div className="img-circle-noti">
                                                        <img src={!Helper.isEmpty(data.image) ? Helper.dataPath(data.image):config.cdn +'theme/images/notification.png'} className={!Helper.isEmpty(data.image) ? "original img-circle" :"default"} />
                                                    </div>
                                                    <div className="mail-contnet">
                                                        <h5 className="font-14 font-normal">{!Helper.isEmpty(data.title) ? data.title :""}</h5>
                                                    </div>
                                                    <div className="noti-time">
                                                         <h5 style={{fontWeight : 300 , fontSize : '11px' , color : 'silver'}} className="font-14 font-normal"><i class="far fa-clock"></i>  {!Helper.isEmpty(data.date) ? Helper.getDateInAgoFormat(data.date, "twitter") : ""}</h5>
                                                    </div>
                                                
                                                    { !Helper.isEmpty(data.grouped) ? <span onClick={(e) => this.toggleList(e,index)} className="float-right">  <i className={`fas fa-chevron-right ${data.isOpen ? 'arrowDown':''}`} ></i></span> : "" }
                                                     </a>

                                                        { !Helper.isEmpty(data.grouped) && data.isOpen ? 
                                                         data.grouped.map((item)=>(
                                                                    <a className="childNoti"    style={{
                                                                        backgroundColor: !item.read  ? '#edf7f0' : '#fff',
                                                                        // borderLeft : !item.read ? '2px solid #359f55' : 'none',
                                                                        paddingInline: '20px',
                                                                        paddingBlock: '15px'
                                                                    }}  href="javascript:void(0)" 
                                                                         style={{
                                                                            backgroundColor: !item.read  ? '#edf7f0' : '#fff',
                                                                        }}
                                                                        data-category={item.category} data-feeduniqueid={item.feedUniqueId} data-uri={item.uri} key={"notify"+index}  data-id={item.id} id={item.id+'-div'} className={item.read ? "text-left" : "notif-is-unread text-left"} onClick={this.handleRead}>
                                                                            <span className="noti-close no-default" data-id={item.id}  data-feeduniqueid={item.feedUniqueId}  onClick={this.handleDelete} >
                                                                                <i class="fa fa-times"></i>
                                                                            </span>
                                                                            <div className="img-circle-noti">
                                                                                <img src={!Helper.isEmpty(item.image) ? Helper.dataPath(item.image):config.cdn +'theme/images/notification.png'} className={!Helper.isEmpty(item.image) ? "original img-circle" :"default"} />
                                                                            </div>
                                                                            <div className="mail-contnet">
                                                                                <h5 className="font-14 font-normal">{!Helper.isEmpty(item.title) ? item.title :""}</h5>
                                                                            </div>
                                                                            <div className="noti-time">
                                                                                 <h5 style={{fontWeight : 300 , fontSize : '11px' , color : 'silver'}} className="font-14 font-normal"><i class="far fa-clock"></i>  {!Helper.isEmpty(item.date) ? Helper.getDateInAgoFormat(item.date, "twitter") : ""}</h5>
                                                                               
                                                                            </div>
                                                                        </a>
                                                            
                                                                ))   
                                                                :
                                                                ""
                                                            
                                                            }
                                                </React.Fragment>

                                              
                                            ))
                                            :
                                            <div className="text-center p-2">
                                                No Notifications
                                            </div>
                                    }
                                </div>

                                { /* Start load more content when this div is visible*/
                                (this.state.remaining > 0)?
                                    <div id="content-end">
                                    {Helper.loadMoreDiv()}
                                    </div>: null
                                }
                            </li>
                            {/* <li>
                                <a className="nav-link text-center" href=""> <strong>See all e-Mails</strong> <i className="fa fa-angle-right"></i> </a>
                            </li> */}
                        </ul>
                        </div>
                    </div>
                </div>
    )
  }
}

export default withRouter(AllNotification);
