import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Helpers from "../../shared/custom";
import Loader from "../ui/Loader";
import Axios from "axios";
import Trending from "../wigets/Trending";
import Relatednews from "./Relatednews";

class Rightpanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollItems:1.5,
      uri:undefined
    };

  }

  componentDidMount() {
    if(Helpers.isMobile()){
      this.setState({
        scrollItems :1,
      })
    }
    if(!Helpers.isEmpty(this.props.uri)){
      this.setState({
        uri :this.props.uri
      })
    }
  }

  render() {
    let loading = this.state.loading;
    let scrollItems = this.state.scrollItems;
    let uri = this.state.uri;

    return (
      <div>
        {/* <BoxSlider
          type="opportunity"
          title="Trending Opportunities"
          items={scrollItems}
          uri="opportunities"
          key={"opportunities"}
        />
        <BoxSlider
          type="events"
          title="Trending Events"
          items={scrollItems}
          uri="events"
          key={"events"}
        /> */}
        {/* <div className="card">
            <div className="card-body p-0">
                 <div className="col-md-12 crunchbase p-0">
                    <a href="https://www.crunchbase.com/" target="_blank"><img style={{"max-width":"100%"}} className="crunchbase-logo" src="http://plus-cdn.investera.com/social-icons/powered_by_crunchbase.png" /></a>
                  </div>
            </div>
        </div> */}
         <Trending items="1.5" title="Trending Opportunities" type="opportunity" uri="opportunities" key={"opportunities"}/>
         <Trending items="1.5" title="Trending Events" type="events" uri="events" key={"events"}/>
        <Relatednews entityUri={uri} type="PEOPLE" />
      </div>
    );
  }
}
export default withRouter(Rightpanel);
