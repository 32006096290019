import React, { Component } from 'react';
import { Link , withRouter } from 'react-router-dom';
import Helper from "../../shared/custom";
import config from '../../shared/config';
import store from '../../store';
import NotUser from './NotUser';


class Founders extends Component {

    constructor(props){
        super(props);
        this.state = {
            overview : null,
            entityUri : null,
            loading:true,
        }

        store.subscribe(() => {
            var storeState = store.getState();
            var entityState = storeState.entity || null;
            var company = entityState?entityState.company:null;
            if(!company){
                return;
            }
            if(company.entityUri == this.state.entityUri){
                return;
            }  
            
            this.setState({
                overview:company.entityInfo.company,
                entityUri:company.entityUri,
            });
            
            setTimeout(() => this.setState({ loading: entityState.loading }),1000); 
            
        });
    }

  render() {
    let founder = !Helper.isEmpty(this.state.overview) ? this.state.overview.founders : [];
    let showContent = this.props.showContent;
    let pageViewsLeft = this.props.pageViewsLeft;

    return (
      <div>
        {!Helper.isEmpty(founder) ?
         <div className="card bold-headers text-left" id={this.props.id}>
         { showContent && pageViewsLeft > 0 ?
            <div>

            <div className="col-md-6 float-left card-header text-left light-blue-header-half">
                <h5 className="m-b-0 m-t-0 text-white">Founders</h5>
            </div>
            <div className="col-md-6 float-left text-right">
                      <span className="font-12">Number of Founders {!Helper.isEmpty(founder) ? founder.length : 0}</span>
            </div>
            <div className="card-body text-left blue-border clearfix">
               <div className="col-md-12 row m-t-10">
                        {!Helper.isEmpty(founder) ?
                            founder.map((data,i)=>(
                                <div className="col-md-4 p-1">
                                    <div className="col-md-12 grey-border round-border d-flex p-1" key={"founder-"+i}>
                                    {data.page && data.page.uri? 
                                        <Link to={"/people/"+data.page.uri}>
                                            <center> 
                                                <div className="col-12 float-left d-flex justify-content-center p-0 text-center" >
                                                    <img src={Helper.dataPath(data.page && data.page.image ? data.page.image : config.defaultUserImage)} className="img-circle m-w" style={{width:"45px",height:"45px"}}  width="45px"  />
                                                </div>
                                                <div className="d-flex justify-content-center flex-column col-12 p-t-10 m-t-10">
                                                    <h4 className="card-title text-center m-b-0 m-t-0 text-blue font-14">{data.name}</h4>
                                                    <h5 className="text-center m-t-0 font-12 text-default font-normal"><i className="fa fa-suitcase m-r-8" aria-hidden="true"></i>{data.banner ? data.banner : ""}</h5>
                                                </div>
                                            </center>
                                        </Link>
                                        
                                    :
                                        <center> 
                                            <div className="col-12 float-left d-flex justify-content-center p-0 text-center">
                                                <img src={Helper.dataPath(data.page && data.page.image ? data.page.image : config.defaultUserImage)} className="img-circle m-w" style={{width:"45px",height:"45px"}}  width="45px"   />
                                            </div>
                                            <div className="d-flex justify-content-center flex-column col-12 p-t-10 m-t-10">
                                                <h4 className="card-title text-center m-b-0 m-t-0 text-blue font-14">{data.name}</h4>
                                                <h5 className="text-center m-t-0 font-12 text-default font-normal"><i className="fa fa-suitcase m-r-8" aria-hidden="true"></i>{data.banner ? data.banner : ""}</h5>
                                            </div>
                                    </center>
                                    }
                                    </div>
                                </div>

                            ))

                            :
                            <div className="col-md-12 text-center no-data-text">
                                No Data Available
                            </div>


                        }
                    {
                       

                    }
                    
                </div>
            </div>

            </div>
            :
            <NotUser title={"Founders"} showContent={this.props.showContent} pageViewsLeft={this.props.pageViewsLeft} /> 
        }
        </div>
        :
        ""}
      </div>
    )
  }
}

export default withRouter(Founders);