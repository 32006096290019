import React, { Component } from 'react';
import Helpers from '../../shared/custom';
import Axios from 'axios';
import config from '../../shared/config';
export default class NewsLetterPublic extends Component {
    constructor(props){
        super(props);
        this.state = {
            data:null
        };
    }
    componentDidMount(){
        let newsletterId = this.props.match.params.newsletterId;
        Axios.get(Helpers.apiURL({ uri: "admin/newsletter/"+newsletterId })).then((res)=>{
            if(res.data && res.data.result){

                let data = {};
                let response = res.data.result;
                if(response.news && response.news.length>0){
                    data.news= {};
                    data.news.mainNews = {};
                    data.news.subNews = [];
                    data.events = [];
                    if(response.newsLetterDate){
                        data.newsLetterDate = response.newsLetterDate;
                    }
                    response.news.forEach((object,index)=>{
                        if(index==0){
                            data.news.mainNews = {
                            img:object.image?object.image:"http://plus-cdn.investera.com/social-icons/news-default-image.svg",
                            title:object.headLine?object.headLine:object.description?object.description:"",
                            via: object.site? object.site:"",
                            viaLink:object.uri?"https://plus.investera.com/news/"+object.uri:"",
                            description:object.description?object.description:"Description",
                            link:object.uri?"https://plus.investera.com/news/"+object.uri:"",
                            date:object.date?Helpers.getDateInFormat(object.date):""
                            }
                        }
                        else {
                            data.news.subNews.push({
                            img:object.image?object.image:"http://plus-cdn.investera.com/social-icons/news-default-image.svg",
                            title:object.headLine?object.headLine:object.description?object.description:"",
                            via: object.site? object.site:"",
                            viaLink:object.uri?"https://plus.investera.com/news/"+object.uri:"",
                            description:object.description?object.description:"Description",
                            link:object.uri?"https://plus.investera.com/news/"+object.uri:"",
                            date:object.date?Helpers.getDateInFormat(object.date):""
                            });
                        }
                    });
                    }
                if(response.events && response.events.length>0){
                    response.events.forEach((object,index)=>{
                    data.events.push({
                        img:object.coverPhoto?"https://local-cdn.investera.com/"+object.coverPhoto.uri:"http://plus-cdn.investera.com/social-icons/news-default-image.svg",
                        title:object.name?object.name:object.summary?object.summary:"",
                        description:object.summary?object.summary:"",
                        link:object.uri?"https://plus.investera.com/event/"+object.uri:"",
                        date:object.startDate?Helpers.getDateInFormat(object.startDate):""
                        });
                    });
                }
            this.setState({data:data});

                // this.props.history.push({
                //     pathname: '/newsletter/preview',
                //   //  search: '?query=abc',
                //     state: { payload: {
                //                 request: payload,
                //                 response: response.data.result
                //                 }
                //      }
                //   })
            }
        })
    }
    
    render() {
         let data = this.state.data;
        // data.news = {};
        // data.news.mainNews = {};
        // data.news.subNews = [];
        // data.events = [];
        // data.newsLetterDate = "13, Nov, 2019";
        // let object = [];
        let eventTitleLimit = 20;
        let eventDescriptionLimit = 70
        let newsDescriptionLimit  = 100;
        let newsHeadLineLimitMain = 80;  
        let newsHeadLineLimitSub = 25;  
        // data.news.mainNews = {
        //     img:object.image?object.image:"https://static.mubasher.info/File.Story_Image/4e157e1f2345d6d01835211b090d98f6/1024.jpg",
        //     title:object.headLine?object.headLine:object.description?object.description:"Higher Investment, fee income drive Abu Dhabi Islamic Bank Profits Higher",
        //     via: object.site? object.site:"via gulfnews, ",
        //     viaLink:object.url?object.url:"#",
        //     description:object.description?object.description:"Description",
        //     link:object.url?object.url:"#",
        //     date:object.date?Helpers.getDateInFormat(object.date):"13, Nov, 2019"
        //     }
        //     for(var i=0; i<5;i++){
        //         data.news.subNews.push({
        //             img:"https://static.mubasher.info/File.Story_Image/4e157e1f2345d6d01835211b090d98f6/1024.jpg",
        //             title:"Higher Investment, fee income drive Abu Dhabi Islamic Bank Profits Higher",
        //             via: "via gulfnews, ",
        //             viaLink:"a",
        //             description:"Description",
        //             link:"a",
        //             date:"13, Nov, 2019"
        //         });
        //         data.events.push({
        //             img:"https://static.mubasher.info/File.Story_Image/4e157e1f2345d6d01835211b090d98f6/1024.jpg",
        //             title:"Higher Investment, fee income drive Abu Dhabi Islamic Bank Profits Higher",
        //             via: "via gulfnews, ",
        //             viaLink:"a",
        //             description:"Description",
        //             link:"a",
        //             date:"13, Nov, 2019"
        //         });
        //     }
            
        return (
         (data) ?
        <table style={{margin:"0px auto",padding:"0px 20px",backgroundColor:"#fff"}}>
            <tbody>
                <tr>
                    <td style={{fontSize:"0"}}>{'\u00A0'}</td>
                    <td style={{width: "700px",padding:"0px 10px",align:"left"}}>
                    <div>
                    <div className="content">
                        <table style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                            <td>
                                <table style={{ width: "100%" }}>
                                <tbody>
                                    <tr>
                                    <td style={{ width: "15%" }}>
                                        <img
                                        style={{ width: "100%" }}
                                        src={config.dataCdnStatic +'plus-logo.png?'} 
                                        />
                                    </td>
                                    <td style={{ width: "83%" }}>
                                        <div
                                        style={{
                                            backgroundColor: "#dab200",
                                            height: "100%",
                                            margin: "13px 8px 0px 0px"
                                        }}
                                        >
                                        &nbsp;
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                            </td>
                            </tr>
                        </tbody>
                        </table>
                        <table
                        style={{
                            width: "100%",
                            padding: "0px",
                            margin: "30px 0px 0px 0px"
                        }}
                        >
                        <tbody>
                            <tr>
                            <td>
                                <h5
                                style={{
                                    fontWeight: "bold",
                                    borderBottom: "solid 2px #b0b0b0",
                                    marginRight: "8px",
                                    textAlign: "left"
                                }}
                                >
                                News
                                </h5>
                            </td>
                            </tr>
                            <tr>
                            <td>
                                <div
                                style={{
                                    marginRight: "8px",
                                    backgroundColor: "#154f91",
                                    fontSize: "12px",
                                    color: "#fff",
                                    borderRadius: "2px"
                                }}
                                >
                                <p style={{ textAlign: "left", color: "#fff" }}>
                                    <span style={{paddingLeft:"8px", color: "#fff" }}>
                                    {data.newsLetterDate}
                                    </span>
                                    <span
                                    style={{
                                        float: "right",
                                        marginBottom: "4px",
                                        paddingRight: "4px"
                                    }}
                                    >
                                    {/* <a style={{ color: "#fff" }} href="#">
                                        <img
                                        src="http://plus-cdn.investera.com/social-icons/globe.png"
                                        style={{
                                            width: "13px",
                                            paddingBottom: "3px",
                                            marginRight: "5px"
                                        }}
                                        />
                                         <span style={{ color: "#fff" }}> View on web</span> 
                                    </a> */}
                                    </span>
                                </p>
                                </div>
                            </td>
                            </tr>
                        </tbody>
                        </table>
                        <div></div>
                        <table style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                            <td
                                className="left-inner-content "
                                style={{
                                width: "60%",
                                borderRight: "solid 1px #b0b0b0",
                                paddingRight: "16px",
                                textAlign: "left"
                                }}
                            >
                                <div>
                                <img
                                    style={{
                                    width:"100%",
                                    marginBottom: "8px",
                                    border: "solid 1px #b0b0b0"
                                    }}
                                    src={data.news.mainNews.img}
                                    alt="First slide"
                                />
                                <h5 style={{ fontSize: "14px", marginBottom: "0px" }}>
                                    {Helpers.truncate(data.news.mainNews.title,newsHeadLineLimitMain)}
                                </h5>
                                <p style={{ fontSize: "12px" }}>
                                    <a
                                    href={data.news.mainNews.viaLink}
                                    style={{ color: "#4d7ac6" }}
                                    >
                                    {data.news.mainNews.via}
                                    </a>{" "}
                                    {data.news.mainNews.date}
                                </p>
                                <h5 style={{ fontSize: "12px", fontWeight: "normal" }}>
                                    {Helpers.truncate(data.news.mainNews.description,newsDescriptionLimit)}
                                </h5>
                                <p
                                    style={{
                                    fontSize: "12px",
                                    textAlign: "right",
                                    color: "#4d7ac6"
                                    }}
                                >
                                    <a href={data.news.mainNews.link}>Read More</a>
                                </p>
                                </div>
                                <div>
                                <table style={{marginBottom:"10px"}}>
                                    <tbody>
                                    {data.news.subNews.map(object => {
                                        return (
                                        <tr style={{ paddingBottom: "16px" }}>
                                            <td
                                            style={{
                                                width: "50%",
                                                paddingBottom: "16px"
                                            }}
                                            >
                                            <img
                                                src={object.img}
                                                style={{
                                                border: "solid 1px #b0b0b0",
                                                height:"140px",
                                                width:"200px"
                                                }}
                                            />
                                            </td>
                                            <td
                                            style={{
                                                margin: "auto 0px",
                                                padding: "0px 0px 16px 8px"
                                            }}
                                            >
                                            <h6
                                                style={{
                                                fontSize: "12px",
                                                marginBottom: "0px"
                                                }}
                                            >
                                                {Helpers.truncate(object.title,newsHeadLineLimitSub)}
                                            </h6>
                                            <p
                                                style={{
                                                fontSize: "12px",
                                                marginBottom: "4px"
                                                }}
                                            >
                                                <a href={object.viaLink}>{object.via}</a>{" "}
                                                {object.date}
                                            </p>
                                            <p
                                                style={{
                                                fontSize: "12px",
                                                marginBottom: "4px"
                                                }}
                                            >
                                                {Helpers.truncate(object.description,newsDescriptionLimit)}
                                            </p>
                                            <p style={{ fontSize: "12px" }}>
                                                <a href={object.link}>Read More</a>
                                            </p>
                                            </td>
                                        </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                                </div>
                            </td>
                            <td
                                className="right-inner-content"
                                style={{
                                width: "39%",
                                top: "0%",
                                verticalAlign: "baseline",
                                padding: "0px 8px 0px 16px",
                                textAlign: "left"
                                }}
                            >
                                <table style={{ width: "100%", marginBottom:"10px"}}>
                                <thead>
                                    <tr style={{ borderBottom: "solid 2px #b0b0b0" }}>
                                    <td
                                        colSpan={2}
                                        style={{
                                        borderBottom: "solid 2px #b0b0b0",
                                        marginBottom: "8px"
                                        }}
                                    >
                                        <h5 style={{ fontWeight: "bold" }}>
                                        Upcoming Events
                                        </h5>
                                    </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr></tr>
                                </tbody>
                                </table>
                                <table>
                                <tbody>
                                    {data.events.map(object => {
                                    return (
                                        <tr>
                                        <td style={{ width: "40%", paddingTop: "8px" }}>
                                            <div style={{ width: "100%" }}>
                                            <img
                                                style={{ width: "100%" }}
                                                src={object.img}
                                            />
                                            <p
                                                style={{
                                                fontSize: "8px",
                                                position: "relative",
                                                bottom: "30%",
                                                background: "rgba(80, 80, 80, 0.8)",
                                                color: "#fff",
                                                paddingLeft: "4px"
                                                }}
                                            >
                                                {object.date}
                                            </p>
                                            </div>
                                        </td>
                                        <td style={{ width: "59%", paddingLeft: "8px" }}>
                                            <h6 style={{ fontSize: "10px", marginBottom:"0px" }}>
                                            {Helpers.truncate(object.title,eventTitleLimit)}
                                            </h6>
                                            <p style={{ fontSize: "8px" , marginBottom:"0px" }}>
                                            {Helpers.truncate(object.description,eventDescriptionLimit)}
                                            </p>
                                            <p style={{ fontSize: "8px" , marginBottom:"0px" }}>
                                            <a href={object.link}>Read More</a>
                                            </p>
                                        </td>
                                        </tr>
                                    );
                                    })}
                                </tbody>
                                </table>
                            </td>
                            </tr>
                             <tr>
                        <td colSpan={2} style={{paddingTop:"30px"}}>
                            <p style={{textAlign: 'center'}}>
                            <a href="https://plus.investera.com/news" style={{backgroundColor: '#154f91', color: '#fff', padding: '5px 8px', marginTop:"10px", borderRadius: '5px'}}>Read
                                More News</a>
                            </p>
                        </td>
                        </tr> 
                        </tbody>
                        </table>
                        <table style={{ width: "100%", margin: "16px auto 0px auto" }}>
                        <tbody>
                            <tr>
                            <td>
                                <div
                                className="footer-newsletter"
                                style={{ background: "#b8b8b8" }}
                                >
                                <div>
                                    <div
                                    style={{
                                        margin: "0px auto",
                                        width: "100%",
                                        padding: "16px"
                                    }}
                                    >
                                    <table
                                        style={{
                                        margin: "0px auto",
                                        fontSize: "25px",
                                        color: "#fff"
                                        }}
                                    >
                                        <tbody>
                                        <tr>
                                            <td>
                                            <a href="https://www.facebook.com/InvesteraAE">
                                                <img
                                                src="http://plus-cdn.investera.com/social-icons/facebook.png"
                                                style={{ margin: "10px", width: "25px" }}
                                                className="fa fa-facebook-square"
                                                aria-hidden="true"
                                                />
                                            </a>
                                            </td>
                                            <td>
                                            <a href="https://twitter.com/InvesteraAE">
                                                <img
                                                src="http://plus-cdn.investera.com/social-icons/twitter.png"
                                                style={{ margin: "10px", width: "27px" }}
                                                className="fa fa-twitter-square"
                                                aria-hidden="true"
                                                />
                                            </a>
                                            </td>
                                            <td>
                                            <a href="https://www.instagram.com/investeraae/">
                                                <img
                                                src="http://plus-cdn.investera.com/social-icons/instagram.png"
                                                style={{ margin: "10px", width: "27px" }}
                                                className="fa fa-twitter-square"
                                                aria-hidden="true"
                                                />
                                            </a>
                                            </td>
                                            <td>
                                            <a href="https://www.linkedin.com/company/28606227/">
                                                <img
                                                src="http://plus-cdn.investera.com/social-icons/linkedin.png"
                                                style={{ margin: "10px", width: "25px" }}
                                                className="fa fa-twitter-square"
                                                aria-hidden="true"
                                                />
                                            </a>
                                            </td>
                                            <td>
                                            <a href="https://youtube.com/channel/UCckQbMmD5JyWIhxv1X_sKBg">
                                                <img
                                                src="http://plus-cdn.investera.com/social-icons/youtube.png"
                                                style={{ margin: "10px", height: "25px" }}
                                                className="fa fa-twitter-square"
                                                aria-hidden="true"
                                                />
                                            </a>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div
                                        style={{
                                        padding: "0px auto",
                                        textAlign: "center",
                                        fontSize: "12px",
                                        color: "#fff"
                                        }}
                                    >
                                        <p>
                                        <a
                                            style={{ color: "#fff" }}
                                            href="https://investera.com/wp-content/uploads/pdf/privacy%20policy.pdf"
                                        >
                                            Privacy Policy
                                        </a>
                                        </p>
                                        <p style={{ color: "#fff" }}>
                                        <a
                                            style={{ color: "#fff" }}
                                            href="https://investera.com/"
                                        >
                                            Investera
                                        </a>
                                        | Al Bateen, Abu Dhabi | UAE
                                        </p>
                                        <p style={{ color: "#fff" }}>
                                        © Copyright Investera 2020. All Right Reserved
                                        </p>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                </td>
                <td style={{fontSize:"0"}}>{'\u00A0'}</td>
                </tr>
            </tbody>
            </table>
                                    :""
        )
    }
}
