import React, { Component } from 'react';
import { Route , Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRouter  =({component :Component,auth,...rest }) =>{

  return (<Route {...rest} render={(props) => (
    auth.isAuthenticated === true
      ?  <Component {...props} /> 
      : <Redirect to={{
                    pathname: '/auth',
                    state: { from: props.location }
                }}
              />
  )} />)


    

              }


const mapStateToProps = state =>({
  auth:state.auth
});

export default connect(mapStateToProps,null)(PrivateRouter);