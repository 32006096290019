import React, { Component } from 'react';
import Sidemenu from '../sidemenu/Sidemenu';
import UserFeedRightPanel from '../homepage/UserFeedRightPanel';
import AllNotifications from './AllNotifications';

class Notifications extends Component {
    constructor(props){
        super(props);
        this.state = {
            editBanner:false,
            editBasicDetails:false,
            feeds:[],
            user:[],
            open:false,
            storeUser:''
        }
       
    }
    componentDidMount(){
        // fetchFeed();
    }


  
  render() {
    return (
        <div>
            <div>
                <div className="container-fluid">
                <div className="row content-in m-t-80 p-t-10">
                    
                    <div className="col-lg-3 col-xlg-3 col-md-3 sideMenuCont">
                        <Sidemenu mode="userprofile" />
                    </div>
                    <div className="col-lg-6 col-xlg-6 col-md-6 centerDiv">
                        <AllNotifications />
                    </div>
                    <div className="col-lg-3 col-xlg-3 col-md-3 m-t-2 newsRightPanel right-side">
                        <UserFeedRightPanel />
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    )
  }
}



export default Notifications;