import React, { Component } from 'react';
import Trending from "../wigets/Trending"; 
import LeaderBoard from "../wigets/LeaderBoard";
import ExpireDate from "../wigets/ExpireDate";
import config from '../../shared/config';
import { Link } from 'react-router-dom';

export default class UserFeedRightPanel extends Component {
  render() {
    return (
      <React.Fragment>
           {/* <div className="card">
           <div className="card-body p-0">
                 <div className="col-md-12 crunchbase p-0">
                     <a href="https://www.crunchbase.com/" target="_blank"><img style={{"maxWidth":"100%"}} className="" src="http://plus-cdn.investera.com/social-icons/powered_by_crunchbase.png" /></a>
                    </div>
              </div>
          </div> */}
           <Trending items="1" title="Trending Opportunities" type="opportunity" uri="opportunities" key={"opportunities"}/>
           <LeaderBoard title="Top Companies" key={"companies"}/>
           <div className="sticky rightItems">
              <Trending items="1.5" title="Trending Events" type="events" uri="events" key={"events"}/>
             <div className="card no-bg-card">
                <div className="card-body">
                    <div className="">
                            <div className="col-sm-12 float-left footer-card">
                                    <div className="col-md-6 float-left brb footer-subcard">
                                        <a href="/about" target="_blank" className="font-12" rel="noreferrer">About Us</a>
                                    </div>
                                    <div className="col-md-6  float-left footer-subcard">
                                         <Link to="/faq" target="_blank" className="font-12">FAQ</Link>
                                    </div>
                            </div>
                            <div className="col-sm-12 m-t-5 float-left footer-card">
                                     <div className="col-md-6 float-left brb footer-subcard">
                                         <a href="https://cdn.investeraplus.africa/static/legal_docs.pdf?" target="_blank" className="font-12" title="End User License" rel="noreferrer">End User License</a>
                                       
                                    </div>
                                    <div className="col-md-6  float-left footer-subcard">
                                         <a href="https://cdn.investeraplus.africa/static/cookie_policy.pdf?" target="_blank" className="font-12" title="Cookies Policy" rel="noreferrer">Cookies Policy</a>
                                    </div>
                            </div>
                            <div className="col-sm-12 m-t-5 float-left footer-card">
                                     <div className="col-md-6 float-left brb footer-subcard">
                                         <a href="https://cdn.investeraplus.africa/static/privacy_policy.pdf?" target="_blank" rel="noreferrer"  className="font-12">Privacy Policy</a>
                                    </div>
                                    <div className="col-md-6  float-left footer-subcard">
                                          <a href="/contact" target="_blank" rel="noreferrer" className="font-12">Contact Us</a>
                                    </div>
                            </div>
                               

                    </div>
                    <div className="clearfix m-t-10 float-left col-md-12">
                            <div className="col-md-12 font-10 float-left">
                                <a className="sideboxLogo" href="#" target="_blank">
                                        <img  src={config.dataCdnStatic +'plus-logo.png?'}  alt="homepage" className="dark-logo logo-home side-footer-img float-left m-a" /> <span className="float-left m-t-2">Investera Plus Africa @ 2021</span>
                                </a>
                            </div>
                    </div>
                </div>
            </div>
           </div>
           

      </React.Fragment>
    )
  }
}
