import React from 'react';
// import config from '../../shared/config';
// import Helpers from '../../shared/custom';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import Axios from "axios";
import Content from "./Content";
import UserFeedRightPanel from '../../homepage/UserFeedRightPanel';


function Summary() {
    return (
        <div className="container-fluid"> 
            <div className="row content-in m-t-80 p-t-10">
                <div className="col-lg-9 col-xlg-9 col-md-6 centerDiv">
                     <Content />
                  </div>
                  <div className="col-lg-3 col-xlg-3 col-md-2 m-t-2 userProfileRight right-side">
                      <UserFeedRightPanel />
                  </div>
            </div>
        </div>
    )
}

export default Summary
