import React, { Component } from 'react';
import config from '../../shared/config';
import Login from '../auth/Login';
import store from '../../store';
import { connect } from 'react-redux';
import { globalSearch } from '../../actions/searchAction';
import { getPackageDetails } from '../../actions/packageAction';
import { withRouter, Redirect } from 'react-router-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {Typeahead} from 'react-bootstrap-typeahead';
import Helpers from '../../shared/custom';
import TopNotification from '../Notifcation/TopNotification';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import  TopSearch  from '../wigets/TopSearch';
import $ from 'jquery';
import TopMenu from './TopMenu';
import { throttle, debounce } from 'throttle-debounce';

class Navbar extends Component {

    constructor(props){
        super(props);        
        this.state = {
            userImage:"",
            isAuthenticated:false,
            searchQuery:"",
            searchCriteriaInStore:{},
            suggestion:[],
            searchVal:"",
            loading:false,
            extQuery:"",
            openPost:false,
            currentPath:"",
            privileges:{},
            showNav:false,
            user:{},
            profileDetails:{},
            localprops:{}

        }
        const unsubscribe = store.subscribe(() => {
            var storeState = store.getState();
            var newState = {};
            var stateUpdateRequired = false;

            let subscription =  storeState.packageDetails;
            let privileges = subscription.privileges;
            if(this.state.privileges != privileges){
                this.setState({
                    privileges:subscription.privileges
                })
            }

           if(JSON.stringify(storeState.search.searchCriteria) !=  JSON.stringify(this.state.searchCriteriaInStore)){
                this.setState({
                    searchCriteriaInStore:storeState.search.searchCriteria
                })
           } 

           if(this.state.userImage != storeState.auth.user.image){           
            stateUpdateRequired = true;
            newState.userImage = storeState.auth.user.image;
           }     
           
           if(this.state.isAuthenticated != storeState.auth.isAuthenticated){
            stateUpdateRequired = true;
            newState.loggedInUser = storeState.auth.isAuthenticated;
           }  

           if(storeState.auth.isAuthenticated){
               this.setState({
                   user:storeState.auth.user,
                   
               },()=>{
                   this.setState({
                    profileDetails:localStorage && localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : null
                   })
               })
           }
           if(this.state.searchQuery != storeState.search.searchQuery){
            stateUpdateRequired = true;
            newState.searchQuery = storeState.search.searchQuery;
           }

           if(stateUpdateRequired){
            this.setState(newState);
           }

        }); 
        this.globalSearch = this.globalSearch.bind(this);
        this.openSearch = this.openSearch.bind(this);
        this.selectSuggestion = this.selectSuggestion.bind(this);
        this.clickSuggestion = this.clickSuggestion.bind(this);
        this.moveFocus = this.moveFocus.bind(this);
        this.createPost = this.createPost.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.enableClickActive = this.enableClickActive.bind(this);
        this.debouncedOpenSearch = throttle(1000,false,(e)=>{this.openSearch(e)},true);
        this.suggestionList = React.createRef();

    } 

    componentDidMount(){
        setTimeout(()=>{
            $(".nav-toggler").click(function () {
                $("body").toggleClass("show-sidebar");
                $(".nav-toggler i").toggleClass("ti-menu");
                $(".nav-toggler i").addClass("ti-close");
            });
        },1000)

        let extQuery = Helpers.getQueryParameter("query");
        this.setState({
            searchVal : !Helpers.isEmpty(extQuery) ? extQuery : ""
        });
       
        if(!Helpers.isEmpty(extQuery)){
            var criteria = this.state.searchCriteriaInStore;
            //criteria.scope = config.graphGeoScope;
            criteria.query = extQuery;
            this.props.globalSearch(criteria);
        }
       
        if((Helpers.getQueryParameter("from") == "home") && Helpers.isEmpty(extQuery)){
            var criteria = this.state.searchCriteriaInStore;
            this.props.globalSearch(criteria);
        }
        var storeState = store.getState();
        this.setState({
            loggedInUser:storeState.auth.isAuthenticated,
            userImage:storeState.auth.user.image,
            user:storeState.auth.user,
            profileDetails:localStorage && localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : null
        });

        document.body.addEventListener('click', (e) => {
            let autoSuggest = e.target.parentNode.className;
            if(autoSuggest != "autocomplete-items"){
                this.setState({
                    suggestion:[]
                 })
            }
        });

        let isLoggedIn = storeState.auth.isAuthenticated;
        
        if(isLoggedIn){
            $(".nav-toggler").removeClass("hidden");
        }else{
            $(".nav-toggler").addClass("hidden");
        }
        
        let location = window.location.pathname;
        if((!isLoggedIn && location == "/") || location =="/home" || location =="/login" || location =="/welcome"  || location =="/register" || location == "/register/sectors" || location == "/register/follow" || location == "/register/role" || location == "/register/loading-page"){
            this.setState({
                showNav:false
            })
        }else{
            this.setState({
                showNav:true
            })
        }

    }

    componentWillReceiveProps(nextProps){
       
        let currentPath = this.props.location;
        let construtedPath = currentPath.pathname+currentPath.search;
        var storeState = store.getState();
    //     console.log("456789")

    //    if(JSON.stringify(nextProps) != JSON.stringify(this.state.localprops)){
    //        console.log(storeState.auth.user)
    //        this.setState({
    //         localprops:nextProps,
    //         userImage:storeState.auth.user.image,
    //         user:storeState.auth.user,
    //         profileDetails:localStorage && localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : null
    //        })

    //    }


      
       // if(window.location.pathname == "/search/advanced" ||  window.location.pathname == "/graph" ||  window.location.pathname == "")

     
    
        setTimeout(() => {
            let menuList = document.querySelectorAll('.nav-link');
            menuList.forEach((elm)=>{
                if(elm.getAttribute("href") == construtedPath){
                    elm.classList.add('activeLink');
                }else{
                    elm.classList.remove('activeLink');
                }
            });
        });

        var storeState = store.getState(); 
        let subscription =  storeState.packageDetails;
    
        this.setState({ privileges:subscription.privileges });

        let isLoggedIn = storeState.auth.isAuthenticated;
        let location = window.location.pathname;

        if((!isLoggedIn && location == "/") || location =="/login" || location == "/welcome" || location =="/register" || location == "/register/sectors" || location == "/register/follow" || location == "/register/role" || location == "/register/loading-page"){
            this.setState({
                showNav:false
            })
        }else{
            this.setState({
                showNav:true
            })
        }

    }

    clearSearch(){
        // document.getElementById("globalSearch").value="";
        this.setState({
            searchVal:"",
            openPost:false
        }, function(){
            let currentPath = Helpers.lastURISegment(window.location);
            if (currentPath == "/search"){
                let newPath = "/search?query="+this.state.searchVal; 
            this.props.history.push(newPath);
            }
        })
    }

    createPost(e){     
        
        let location = this.props.location;
        this.props.history.push({
            pathname: '/',
            search: '?post=enabled',
            openPost:this.state.openPost ? false : true,
        });        
    }

    moveFocus() {
        
    }

    componentWillMount(){
        this.props.history.listen(location => {
        if (location.pathname !== this.props.location.pathname) {
            this.props.location.pathname = location.pathname;
            this.setState({currentPath:this.props.location.pathname});
        }
        });
    }

    globalSearch(e){
        let query = !Helpers.isEmpty(e[0]) ?e[0].value : "";
        this.setState({
            searchVal:query,
            openPost:false
        }, function(){
            let currentPath = Helpers.lastURISegment(window.location);
            if (currentPath == "/search"){
                let newPath = "/search?query="+this.state.searchVal; 
                this.props.history.push(newPath);
            }
        });
        let elm = !Helpers.isEmpty(e[0]) ?e[0]: null;
        var url = null;
        if(!Helpers.isEmpty(elm) && !Helpers.isEmpty(elm.uri)){
            url = elm.uri;
        }
        if(url){
            this.props.history.push(url);
        }
    }


    clickSuggestion(e){
        var url = e.target.dataset.url;
        if(url){
            this.props.history.push(url);
        }
        this.setState({
            suggestion:[]
        })
    }

    selectSuggestion(e){
       
    }

    openSearch(e){
        if(e.key === 'Enter' && e.target.value != '') {
        let query = e.target.value;
        this.setState({
            searchVal:query,
            openPost:false
        }, function(){
            let currentPath = Helpers.lastURISegment(window.location);
            if (currentPath == "/search"){
                let newPath = "/search?query="+this.state.searchVal; 
                this.props.history.push(newPath);
            }
        });
       
            var criteria = this.state.searchCriteriaInStore;
            criteria.query = query;
            this.props.globalSearch(criteria, null, () => {
                    var storeState = store.getState();
                    let searchData = storeState.search.searchResults
                    if(!Helpers.isEmpty(query)){
                        this.setState({
                            suggestion:searchData
                        })
                    }else{
                        this.setState({
                            suggestion:[]
                        })
                    }
            });
      //  }

      //  if(e.key === 'Enter' && e.target.value != '') {
                let currentPath = this.props.location.pathname;
                let newPath = "/search"; 
                if (currentPath != newPath){
                    let newPath = "/search?query="+e.target.value;
                    this.props.history.push(newPath);
                    this.globalSearch(e, true);
                }
        }
        this.setState({
            suggestion:[],
        });
       
    }

    enableClickActive(e){
        let activeElm = e.currentTarget;
        let path = activeElm.getAttribute("data-href");
        let menuList = document.querySelectorAll('.topLink');
        menuList.forEach((elm)=>{
                elm.classList.remove('active');
        });
        activeElm.classList.add("active");
        this.props.history.push(path);
       
}
   


    getType(page){
        let type = "event";
        if(page.primaryPageType){
            type = page.primaryPageType.type.type;
            type = (type+"").toLowerCase();
        }
        return type;
    }

    renderSuggestion(data,index){
        let entry = data.page ? data.page : data;
        let type = this.getType(entry);
        let image = Helpers.isEmpty(entry.image) ? Helpers.getDefaultImage(type) : entry.image;
        let name = entry.name? entry.name : "";
        let banner = Helpers.isEmpty(entry.banner) ?  "" : " - "+entry.banner.primary;

        let url = undefined;
        if(type && entry.uri){
            url = "/"+type+"/"+entry.uri;
        }


        if(type == "event"){
            image = entry.coverPhoto && entry.coverPhoto.publicUri ? entry.coverPhoto.publicUri : Helpers.getDefaultImage(type)
        }

       // image = Helpers.dataPath(image);

        return (
                <div key={"suggest"+index} data-value={name} data-url={url}  onClick={this.clickSuggestion} className="font-16 font-medium text-info items">
                    <img src={!Helpers.isEmpty(image)?  Helpers.dataPath(image) :  Helpers.dataPath(config.defaultUserImage)} className="profile-pic"/>
                    &nbsp;
                    {"  "+name} 
                    <span className="font-10 text-sec"> {type}  {banner}</span>
                </div>
               )
    }
  render() {
    let privileges= this.state.privileges;
    var userImage = this.state.userImage;  
    let currentPath = this.state.currentPath ? this.state.currentPath:window.location.pathname;
    let locationPath =  window.location.pathname;
    let loggedInUser = this.state.loggedInUser;
    let loading = this.state.loading;
    let upgradeUrl = Helpers.upgradeUrl();
    let suggestion=[];
  
        suggestion = this.state.suggestion

    
   let mode = this.props.mode;
   let headerClasses = mode == "public" ?  "container-fluid navbar top-navbar navbar-expand-md navbar-light fixed-top" : "container navbar top-navbar navbar-expand-md navbar-light fixed-top";
   let localTalkingToLive = ( (process.env.REACT_APP_HOST === "plus.investera.com") && (window.location.hostname !== "plus.investera.com") )? true: false;
   if (localTalkingToLive) 
        headerClasses += " red-background-imp";

    let user = this.state.user;

    let profileDetails = this.state.profileDetails;
    let pageDetails = user && user.pageDetails ? user.pageDetails : null;

    var regStatus = pageDetails && pageDetails.pageDto && pageDetails.pageDto.regStatus && pageDetails.pageDto.regStatus.value ? pageDetails.pageDto.regStatus.value  : null;

    let profilePicUrl =  !Helpers.isEmpty(profileDetails) && !Helpers.isEmpty(profileDetails.profilePic)
    ? Helpers.isEmpty(regStatus) ? Helpers.dataPath(profileDetails.profilePic.publicUri) 
    : !Helpers.isEmpty(regStatus) && regStatus == "PUBLISHED" ? Helpers.dataPath(profileDetails.profilePic.publicUri) : Helpers.dataPath(profileDetails.profilePic.uri)
    : Helpers.dataPath(config.defaultUserImage);

        
   
    return (
      <div>
         <LoadingOverlay>
             <Loader fullPage loading={loading}/>
        </LoadingOverlay>
           {mode ==  "public" && (currentPath =="/" || locationPath=="/" ) ||  !this.state.showNav? 
              <div>
                    <header className="topbar navbar top-navbar navbar-expand-md navbar-light fixed-top public-nav">
                      <nav className={headerClasses}>
                        <div className="navbar-header">
                            <a href="/" className="navbar-brand">
                                <span className="public">
                                    <img src={config.dataCdnStatic +'plus-logo.png?'} alt="Investera +" className="dark-logo logo-home" />
                                    <img src={config.dataCdnStatic +'plus-logo.png?'} className="light-logo  logo-home" alt="homepage" />
                                </span> 
                            </a>
                        </div>
                        <div className={"navbar-collapse"}>
                            {/* <div className="sub-navbar navbar-collapse">
                            
                                    <a href="/" title="Home">
                                        <i className="mdi mdi-home font-24 vm"></i> 
                                        <span className="text-md font-medium text-desktop  vm">Home</span>
                                    </a> 
                            
                                
                                {/* <a href="javascript:void(0)" data-href="/companies" className="topLink" onClick={this.enableClickActive}>
                                    <i className="material-icons font-24 vm">business</i>
                                    <span className="text-md font-medium text-desktop text-white vm">Companies</span>
                                </a>
                                */}
                                {/* <a href="javascript:void(0)" data-href="/search/advanced?t=startup" className="topLink" onClick={this.enableClickActive} title="Startups">
                                        <i className="mdi mdi-rocket font-24 vm"></i> 
                                        <span className="text-md font-medium text-desktop  vm">Startups</span>
                                </a>
                                
                                <a href="javascript:void(0)" data-href="/search/advanced?t=investor" className="topLink" onClick={this.enableClickActive} title="Investors">
                                    <i className="material-icons font-24 vm">monetization_on</i>
                                    <span className="text-md font-medium text-desktop  vm">Investors</span>
                                </a>

                                <a href="javascript:void(0)" data-href="/search/advanced?t=people" className="topLink" onClick={this.enableClickActive} title="People">
                                    <i className="material-icons font-24 vm">people</i>
                                    <span className="text-md font-medium text-desktop  vm">People</span>
                                </a> */}

                                {/* <a href="javascript:void(0)" data-href="/opportunities" className="topLink" onClick={this.enableClickActive}>
                                    <i className="mdi mdi-trending-up font-24 vm"></i> 
                                    <span className="text-md font-medium text-desktop text-white vm">Opportunities</span>
                                </a>
                            
                                <a href="javascript:void(0)" data-href="/events" className="topLink" onClick={this.enableClickActive}>
                                    <i className="mdi mdi-calendar font-24 vm"></i> 
                                    <span className="text-md font-medium text-desktop text-white vm">Events</span>
                                </a> */}
{/* 
                                <a href="javascript:void(0)" data-href="/news" className="topLink" onClick={this.enableClickActive} title="News">
                                    <i className="mdi mdi-earth font-24 vm"></i> 
                                    <span className="text-md font-medium text-desktop vm">News</span>
                                </a> */}
                                
                                {/* <a href="javascript:void(0)" data-href={privileges && privileges["EVT"] ? "/events" : upgradeUrl } className="topLink" onClick={this.enableClickActive}> */}
                                {/* <a href="javascript:void(0)" data-href="/search/advanced?t=event" className="topLink" onClick={this.enableClickActive} title="Events"> 
                                    <i className="mdi mdi-calendar font-24 vm"></i> 
                                    <span className="text-md font-medium text-desktop  vm">Events</span>
                                </a>

                                <a href="javascript:void(0)" data-href="/report" className="topLink" onClick={this.enableClickActive} title="Reports">
                                    <i className="mdi mdi-file-pdf font-24 vm"></i> 
                                    <span className="text-md font-medium text-desktop vm">Reports</span>
                                    <span className="badge badge-red ml-auto new-badge">NEW</span>
                                </a> 
                            </div> 
                             */}
                                {/* <ul className="navbar-nav mt-md-0 topSearch">
                                <li className="nav-item  full-width"> 
                                <div className="col-md-12">
                                     <div className="form-group has-search m-b-0 topSearch">
                                            <i className="material-icons form-control-feedback">
                                            search
                                            </i>
                                            <TopSearch 
                                                    onChange={this.globalSearch}
                                                    onKeyDown={this.debouncedOpenSearch}
                                                    value={Helpers.isEmpty(this.state.searchVal)?"":this.state.searchVal}
                                                    privileges={privileges}
                                            />
                                    </div> 
                                </div>

                                </li>
                            </ul>
*/}
                            <ul className="navbar-nav mr-auto mt-md-0 navUl public-page m-r-0-i  text-center">
                                <li className="nav-item dropdown text-center pull-right">
                                        <Link to={{pathname:"/auth", activeTab:"login"}} className="font-14">Login |</Link>
                                        <Link to={{pathname:"/auth", activeTab:"register"}} className="font-14 m-l-5" >Register</Link>
                                </li>
                            </ul> 
                        </div>
                    </nav>
                </header>
             </div>
            :
            <div>
                <header className="topbar navbar top-navbar navbar-expand-md navbar-light fixed-top">
                <nav className={headerClasses}>
                    <div className="navbar-header">
                        <Link className="navbar-brand" to="/">
                            <span>
                                <img src={config.dataCdnStatic +'plus-logo.png?'}  alt="Investera +" className="dark-logo logo-home" />
                                <img src={config.dataCdnStatic +'plus-logo.png?'}  className="light-logo  logo-home" alt="homepage" />
                            </span> 
                        </Link>
                    </div>
                    <div className={(currentPath == "/" && !loggedInUser) ?  "navbar-collapse" : "navbar-collapse authNav" }>
                    { (currentPath == "/" && !loggedInUser) ?   
                        ""
                        :
                        <ul className="navbar-nav mr-auto mt-md-0">
                            <li className="nav-item"> <a onClick={(e)=>e.preventDefault()} className="nav-link nav-toggler hidden-md-up text-muted waves-effect waves-dark" href="#"><i className="mdi mdi-menu"></i></a> </li>
                            <li className="nav-item"> <a onClick={(e)=>e.preventDefault()} className="nav-link sidebartoggler hidden-sm-down text-muted waves-effect waves-dark" href="#"><i className="ti-menu"></i></a> </li>
                        </ul>
                    }
                    { (!loggedInUser) ?   
                        ""
                        : 
                        <ul className="navbar-nav mt-md-0 topSearch">
                        <li className="nav-item  full-width"> 
                        <div className="col-md-12">
                            <div className="form-group has-search m-b-0 topSearch">
                                    <i className="material-icons form-control-feedback">
                                    search
                                    </i>
                                    <TopSearch 
                                            onChange={this.globalSearch}
                                            onKeyDown={this.debouncedOpenSearch}
                                            value={Helpers.isEmpty(this.state.searchVal)?"":this.state.searchVal}
                                            privileges={privileges}
                                    />
                            </div>
                        </div>

                        </li>
                    </ul>
                    } 

                    <ul className="navbar-nav  mr-auto mt-md-0">
                        <li className="nav-item dropdown">
                            { (loggedInUser) ?   
                                <Link className="nav-link waves-effect waves-dark topMenuOpt activePage text-blue font-14 advSearch"  to={privileges && privileges["AD_SER"] ? "/search/advanced?t=common" : upgradeUrl } > 
                                    Advanced Search
                                </Link>
                            :
                            ""
                            
                            }

                            { (loggedInUser) ?   
                                <Link className="nav-link waves-effect waves-dark topMenuOpt activePage text-blue font-14 advSearchMobile"  to={privileges && privileges["AD_SER"] ? "/search/advanced?t=common" : upgradeUrl } > 
                                   <i className="material-icons">policy</i>
                                </Link>
                            :
                            ""
                            
                            }
    
                        </li> 
                    </ul>

                        <ul className="navbar-nav  mr-auto mt-md-0">
                            <li className="nav-item dropdown">
                            { (loggedInUser) ?   
                                <Link className="nav-link waves-effect waves-dark topMenuOpt activePage" to={privileges && privileges["KG"] ? "/graph" : upgradeUrl } > 
                                    <i className="material-icons"> scatter_plot</i>
                                    <span className={"text-md font-medium font-14 text-desktop text-blue kgText"}>
                                        <i className="r-font-family font-14
                                        ">i</i>Knowledge Graph
                                    </span>
                                </Link>
                                :
                                ""
                                
                                }
                            </li> 
                            {loggedInUser ? <TopNotification /> :""}
                        </ul>
                        <ul className="navbar-nav mr-auto mt-md-0 navUl m-r-0-i">
                        <li className="nav-item dropdown">
                                {!loggedInUser ? 
                                    <a className="btn btn-info dropdown-toggle text-muted waves-effect waves-dark loginDropdown text-white" id="loginDropdown" href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Sign In</a>
                                    :
                                    <a className="nav-link dropdown-toggle text-muted waves-effect waves-dark loginDropdown" id="loginDropdown" href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src={profilePicUrl} alt="user" className="profile-pic" /></a>
                                }
                                <Login/>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
           {loggedInUser ?  <TopMenu loggedInUser={loggedInUser} createPost={this.createPost} privileges={privileges}/> : ""}
       </div>
      }
      </div>
    )
  }
}

//export default Navbar;
export default connect(null,{globalSearch,getPackageDetails})(withRouter(Navbar));
