// import { createStore } from 'redux'
import { createStore, applyMiddleware ,compose} from 'redux';
import thunk from 'redux-thunk';


import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
 
import rootReducer from './index';

const middleware = [thunk];
 
const persistConfig = {
  key: 'storeData',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
 
const ConfigStore = () => {
  let store = createStore(persistedReducer,applyMiddleware(...middleware))
  let persistor = persistStore(store)
  return { store, persistor }
}

export default ConfigStore;
