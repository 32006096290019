import React, { Component } from 'react';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import config from '../../shared/config';
import Helper from '../../shared/custom';
import Axios from "axios";

export default class Unsubscribe extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading:false,
            success:false,
            fail:false,
            type:null,
            token:null
        }

       
    }

    componentDidMount(){
        let type = this.props.match.params.type;
        let token = this.props.match.params.token;

        this.setState({
            type:type,
            token:token
        });
    }

    unsubscribe = (e)=>{
        e.preventDefault()
        this.setState({
            loading: true
          });

        let { type , token } = this.state;
         Axios.put(
            Helper.apiURL({
              uri: "unsubscribe/:type/:token",
              pathVar: { type: type,token:token }
            }),
          )
            .then(response => {
              this.setState({
                loading: false,
                success:true,
                fail:false
              });
            //  Helper.pushTSuccess("COMPANY_SUBMIT_APPROVAL");
            })
            .catch(err => {
              this.setState({
                loading: false,
                fail:true,
                success:false,
              });
              Helper.handleError(err.response);
            });
    }
    render() {
        let { success ,fail , loading} = this.state;
        return (
            <div className="row content-in m-t-80 p-t-10">
                 <LoadingOverlay>
                    <Loader fullPage loading={loading}/>
                </LoadingOverlay>
                    <div className="card col-md-12 unsubscribe">
                            <div className="card-body p-10-percent">
                                { success || fail ? 
                                    <div>
                                        { success ? 
                                             <div className="alert alert-success" role="alert">
                                                <h2 className="l-h-28">You have been unsubscribed from our mailing list. Feel free to check back anytime.</h2>
                                             </div>
                                             :
                                             <div className="alert alert-danger" role="alert">
                                               <h2 className="l-h-28">Unsubscribe failed, you might have already unsubscribed. If you still face issues, please contact <a href="mailto:nfo@investera.com">info@investera.com</a></h2>
                                             </div>
                                        }
                                      <p>Visit us at <a href="/">Investera+</a></p> 
                                       
                                    </div>
                                        :
                                    <div>
                                        <h2>We are sad that you are leaving. Are you sure you want to unsubscribe?</h2>
                                        <button type="button" className="btn font-medium gold-bg text-blue" onClick={this.unsubscribe}>
                                            <b> Yes, Proceed</b>
                                        </button>
                                    </div>
                                }
                            </div>
                    </div>
            </div>
        )
    }
}
