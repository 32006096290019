import React, { Component } from "react";
import store from "../../store";
import config from "../../shared/config";
import Helper from "../../shared/custom";
import { withRouter } from "react-router-dom";
import Axios from "axios";
import ApiHelper from "../../shared/ApiHelper";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import CustomLoader from "../ui/CustomLoader";
import Mobile from "../ui/Mobile";

import InfoTextArray from "../ui/InfoTextArray";
import ManageCareerHistory from "../people/ManageCareerHistory";
import ManageEducationHistory from "../people/ManageEducationHistory";

import InfoText from "../ui/InfoText";
import ReadMoreAndLess from "react-read-more-less";

import EditText from "../ui/EditText";
import EditTextArea from "../ui/EditTextArea";
import EditSelect from "../ui/EditSelect";
import EditMultiSelect from "../ui/EditMultiSelect";
import countries from "../../shared/country";
import ObjectsSearch from "../wigets/ObjectsSearch";

import { login } from '../../actions/authAction';
import { fetchUserInfo } from '../../actions/authAction';
import { logoutUser } from '../../actions/authAction';
import { getPackageDetails } from '../../actions/packageAction';
import CustomArray from "../ui/CustomArray";
import { Button } from "bootstrap";

class UserProfileContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      metaList: [],
      id: null,
      editSummary: true,
      editSpeakers: true,
      name: "",
      companyName: "",
      displayName: "",
      sector: [],
      sectorOptions: [],
      profilePicUrl: "",
      description: "",
      industryList: [],
      sectorList: [],
      industries: [],
      profilePic: "",
      industryOptions: [],
      followers: 0,
      following: 0,
      loading:false,
      addLoader:false,

      careerHistory: [],
      educationHistory: [],

      createProfile: false,
      editBasicDetails: false,
      editAddInfo:false,
      country: undefined,
      city: undefined,
      cityList: [],

      company: null,
      titleObj:null,
      summary: "",
      business: undefined,

      uri:undefined,
      packageDetails:{},
      catagoryOption:config.companyCatagories,
        genderOptions:[
         
          {
            value:"MALE",
            label:"MALE"
          },
          {
            value:"FEMALE",
            label:"FEMALE"
          },
          {
            value:"OTHERS",
            label:"OTHERS"
          }
        ],

              mobileCode:"",
              mobile:"",
              email:"",
              gender:"",
              dob:"",
              regNo:"",
              regYear:"",
              category:"",
              noOfEmployees:"",
              natureOfBusiness:"",
              phoneCode:"",
              phone:"",
              companyEmail:"",
              kraPin:"",
              
              addressLine1:"",
              poBox:"",
              
              firstNameC1:"",
              lastNameC1:"",
              mobileCodeC1:"",
              mobileC1:"",
              emailC1:"",
              genderC1:"",
              dobC1:"",
              
              firstNameC2:"",
              lastNameC2:"",
              mobileCodeC2:"",
              mobileC2:"",
              emailC2:"",
              genderC2:"",
              dobC2:"",
              errorMsg:{},

    };

    // store.subscribe(() => {
    //     var storeState = store.getState();
    //     var localBusiness = this.state.business;
    //     var storeBusiness = storeState.business.data;
    //     if(!localBusiness || JSON.stringify(localBusiness) != JSON.stringify(storeBusiness)){
    //         this.setState({business:storeBusiness});
    //     }

    //   });

    this.editBasicDetails = this.editBasicDetails.bind(this);
    this.setMetaList = this.setMetaList.bind(this);
    this.uploadProfileImage = this.uploadProfileImage.bind(this);
    this.handleFieldValueChange = this.handleFieldValueChange.bind(this);
    this.uploadImg = this.uploadImg.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.setCityList = this.setCityList.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.fetchUserInfoTst = this.fetchUserInfoTst.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  validateForm() {
    let isValid = true;
    let message = [];

   

    if (Helper.isEmpty(this.state.titleObj)) {
      isValid = false;
      message.push("TITLE_REQUIRED");
    }

    if (Helper.isEmpty(this.state.profilePicUrl)) {
      isValid = false;
      message.push("COVER_PHOTO_REQUIRED");
    }

   
    



    if (message.length > 0) {
      Helper.pushTError(message);
    }
    return isValid;
  }

  setupForm(readMode) {
    if (Helper.isEmpty(readMode)) readMode = this.state.createProfile;
    else {
      this.setState({ readMode: readMode }, function() {});
    }

    if (readMode) {
      this.setState({
        editBasicDetails: false,
        editSummary: false,
        editSpeakers: false
      });
    } else {
      this.setState({
        editBasicDetails: true,
        editSummary: true,
        editSpeakers: true
      });
    }
  }


  validateAddtionalForm = ()=>{

    let isValid = true;
    let message = [];
    let packageName  =  Helper.getProperPackageName(this.state.packageDetails.name);

    var errorMsg = {};

    if(Helper.isEmpty(this.state.mobileCode)) {
      isValid = false;
      errorMsg["mobileCode"] = "This field is required";
      //message.push("COVER_PHOTO_REQUIRED");
    }

    if(Helper.isEmpty(this.state.mobile)) {
      isValid = false;
      errorMsg["mobile"] = "This field is required";
      //message.push("COVER_PHOTO_REQUIRED");
    }


    // if(!Helper.isEmpty(this.state.mobileCode)) {
    //   isValid = false;
    //   errorMsg["mobileCode"] = "Please enter only numbers";
    //   //message.push("COVER_PHOTO_REQUIRED");
    // }

    if(!Helper.isEmpty(this.state.mobile) &&  !Helper.allowNumberOnly(this.state.mobile)) {
      isValid = false;
      errorMsg["mobile"] = "Please enter only numbers";
      //message.push("COVER_PHOTO_REQUIRED");
    }


    if(packageName == "company"  || packageName == "startup"){

        if(Helper.isEmpty(this.state.regNo)) {
          isValid = false;
          errorMsg["regNo"] = "This field is required";
          //message.push("COVER_PHOTO_REQUIRED");
        }

        if(Helper.isEmpty(this.state.regYear)) {
          isValid = false;
          errorMsg["regYear"] = "This field is required";
          //message.push("COVER_PHOTO_REQUIRED");
        }

        if(Helper.isEmpty(this.state.companyEmail)) {
          isValid = false;
          errorMsg["companyEmail"] = "This field is required";
          //message.push("COVER_PHOTO_REQUIRED");
        }

        if(!Helper.isValidEmail(this.state.companyEmail)) {
          isValid = false;
          errorMsg["companyEmail"] = "Please enter a valid email";
          //message.push("COVER_PHOTO_REQUIRED");
        }


          if(Helper.isEmpty(this.state.firstNameC1)) {
            isValid = false;
            errorMsg["firstNameC1"] = "This field is required";
            //message.push("COVER_PHOTO_REQUIRED");
          }
      
      
          if(Helper.isEmpty(this.state.firstNameC1)) {
            isValid = false;
            errorMsg["firstNameC1"] = "This field is required";
            //message.push("COVER_PHOTO_REQUIRED");
          }
          if(Helper.isEmpty(this.state.lastNameC1)) {
            isValid = false;
            errorMsg["lastNameC1"] = "This field is required";
            //message.push("COVER_PHOTO_REQUIRED");
          }
          if(Helper.isEmpty(this.state.mobileCodeC1)) {
            isValid = false;
            errorMsg["mobileCodeC1"] = "This field is required";
            //message.push("COVER_PHOTO_REQUIRED");
          }
          if(Helper.isEmpty(this.state.mobileC1)) {
            isValid = false;
            errorMsg["mobileC1"] = "This field is required";
            //message.push("COVER_PHOTO_REQUIRED");
          }

          // if(!Helper.isEmpty(this.state.mobileCodeC1) &&  !Helper.allowNumberOnly(this.state.mobileCodeC1)) {
          //   isValid = false;
          //   errorMsg["mobileCodeC1"] = "Please enter only numbers";
          //   //message.push("COVER_PHOTO_REQUIRED");
          // }

          if(!Helper.isEmpty(this.state.mobileC1) &&  !Helper.allowNumberOnly(this.state.mobileC1)) {
            isValid = false;
            errorMsg["mobileC1"] = "Please enter only numbers";
            //message.push("COVER_PHOTO_REQUIRED");
          }

          if(Helper.isEmpty(this.state.emailC1)) {
            isValid = false;
            errorMsg["emailC1"] = "This field is required";
            //message.push("COVER_PHOTO_REQUIRED");
          }

          if(!Helper.isValidEmail(this.state.emailC1)) {
            isValid = false;
            errorMsg["emailC1"] = "Please enter a valid email";
            //message.push("COVER_PHOTO_REQUIRED");
          }
          
          if(Helper.isEmpty(this.state.firstNameC2)) {
            isValid = false;
            errorMsg["firstNameC2"] = "This field is required";
            //message.push("COVER_PHOTO_REQUIRED");
          }
          if(Helper.isEmpty(this.state.lastNameC2)) {
            isValid = false;
            errorMsg["lastNameC2"] = "This field is required";
            //message.push("COVER_PHOTO_REQUIRED");
          }
          if(Helper.isEmpty(this.state.mobileCodeC2)) {
            isValid = false;
            errorMsg["mobileCodeC2"] = "This field is required";
            //message.push("COVER_PHOTO_REQUIRED");
          }
          if(Helper.isEmpty(this.state.mobileC2)) {
            isValid = false;
            errorMsg["mobileC2"] = "This field is required";
            //message.push("COVER_PHOTO_REQUIRED");
          }
          // if(!Helper.isEmpty(this.state.mobileCodeC2) &&  !Helper.allowNumberOnly(this.state.mobileCodeC2)) {
          //   isValid = false;
          //   errorMsg["mobileCodeC2"] = "Please enter only numbers";
          //   //message.push("COVER_PHOTO_REQUIRED");
          // }

          if(!Helper.isEmpty(this.state.mobileC2) &&  !Helper.allowNumberOnly(this.state.mobileC2)) {
            isValid = false;
            errorMsg["mobileC2"] = "Please enter only numbers";
            //message.push("COVER_PHOTO_REQUIRED");
          }
          if(Helper.isEmpty(this.state.emailC2)) {
            isValid = false;
            errorMsg["emailC2"] = "This field is required";
            //message.push("COVER_PHOTO_REQUIRED");
          }

          if(!Helper.isValidEmail(this.state.emailC2)) {
            isValid = false;
            errorMsg["emailC2"] = "Please enter a valid email";
            //message.push("COVER_PHOTO_REQUIRED");
          }

    }

   
    this.setState({errorMsg:errorMsg})
    return isValid;
  }


  handleAdditionalSave = ()=>{
    let isValid = this.validateAddtionalForm();
    if (!isValid) {
      Helper.pushError("Please fill all the required fields!")
      return false;
    }
    this.setState({
      addLoader:true,
    })

    let packageName  =  Helper.getProperPackageName(this.state.packageDetails.name);

    var payload = {
          "userId":this.state.id, // this is required
          //"email":this.state.email,
          "gender":this.state.gender ? this.state.gender.value  : null, //MALE, FEMALE, OTHERS
          "dob":this.state.dob,
          "mobileCode":this.state.mobileCode,
          "mobile":this.state.mobile,
    };


    if(packageName == "company"  || packageName == "startup"){
      payload["regNo"] = this.state.regNo;
      payload["regYear"] = this.state.regYear;
      payload["category"] = this.state.category ? this.state.category  : null;
      payload["noOfEmployees"] = this.state.noOfEmployees;
      payload["natureOfBusiness"] = this.state.natureOfBusiness;
      payload["phoneCode"] = this.state.phoneCode;
      payload["phone"] = this.state.phone;
      payload["companyEmail"] = this.state.companyEmail;
      payload["kraPin"] = this.state.kraPin;
      payload["address"] = {
          "id":null,
          "addressLine1":this.state.addressLine1,
          "poBox":this.state.poBox
      };
      payload["contactPersons"] = [
          {
            "id":null,
            "firstname":this.state.firstNameC1,
            "lastname":this.state.lastNameC1,
            "email":this.state.emailC1,
            "mobileCode":this.state.mobileCodeC1,
            "mobile":this.state.mobileC1,
            "gender":this.state.genderC1 ? this.state.genderC1.value :  null,
            "dob":this.state.dobC1
          },
          {
            "id":null,
            "firstname":this.state.firstNameC2,
            "lastname":this.state.lastNameC2,
            "email":this.state.emailC2,
            "mobileCode":this.state.mobileCodeC2,
            "mobile":this.state.mobileC2,
            "gender":this.state.genderC2 ? this.state.genderC2.value : null,
            "dob":this.state.dobC2
          }
      ]
    }
    

   
      Axios.post(
        Helper.apiURL({
          uri: "user/user-info"
        }),
        payload
      )
      .then((response) => {
        this.setState({
          addLoader:false,
        })
        Helper.pushTSuccess("PROFILE_UPDATED");
      })
      .catch(err => {
        this.setState({
          addLoader:false,
        })
        Helper.pushTError(err.response.data.message);
      });

  }

  handleSubmit() {
    let isValid = this.validateForm();
    if (!isValid) {
      return false;
    }

    

    let payload = {
      summary: this.state.summary,
      title: { id: Helper.isEmpty(this.state.titleObj.id)?this.state.titleObj.value:this.state.titleObj.id },
      country: { id: this.state.country ? this.state.country.value : null },
      city: { id: this.state.city ? this.state.city.value : null },
      profilePic: {
        uniqueId: this.state.profilePic ? this.state.profilePic : null
      },
      company:
        this.state.company instanceof Object
          ? this.state.company
          : { id: null, name: this.state.company },
      displayName: this.state.displayName
    };

    // if (this.state.sector) {
    //   payload.sectors = [{ id: this.state.sector.value }];
    // }



    if (this.state.sector) {  
      let sectors = [];
      this.state.sector.forEach(sector => {
        sectors.push({ id: sector.value });
      });
      payload.sectors = sectors;
    }


    if (this.state.industries) {
      let industries = [];
      this.state.industries.forEach(industry => {
        industries.push({ id: industry.value });
      });
      payload.industries = industries;
    }

    var component = this;
    var thisProps = this.props;
    let createProfile = this.state.createProfile;
    
    if (createProfile) {
      //edit
        Axios.post(
          Helper.apiURL({
            uri: "user/profile"
          }),
          payload
        )
        .then((response) => {

          let result = response.data.result;
          let profilePicUrl = result.profilePic ? Helper.viewFile(result.profilePic.uniqueId) : Helper.dataPath(config.defaultUserImage);
          this.setState({
            profileDetails: result,
            profilePicUrl:profilePicUrl,
          },()=>{
              this.fetchUserInfoTst();
          });
          this.setupForm(true);
          Helper.pushTSuccess("PROFILE_CREATED");   

         
        })
        .catch(err => {
          Helper.pushError(err.response.data.message);
        });
    } else {
        
        payload.id = this.state.id;
        Axios.put(
          Helper.apiURL({
            uri: "user/profile"
          }),
          payload
        )
        .then((response) => {
          let result = response.data.result;
          let profilePicUrl = result.profilePic ? Helper.viewFile(result.profilePic.uniqueId) : Helper.dataPath(config.defaultUserImage);

          this.setState({
            profileDetails: result,
            profilePicUrl:profilePicUrl,
          },()=>{
              this.fetchUserInfoTst();
          });
          this.setupForm(true);    
          Helper.pushTSuccess("PROFILE_UPDATED");
        })
        .catch((err) => {
            Helper.pushError(err.response.data.message);
        });
    }
  }

  fetchUserInfoTst(){    
    var storeState = store.getState();

    
    this.setState({auth:storeState.auth},()=>{
        if(this.state.auth.isAuthenticated){
            setTimeout(() => {
                fetchUserInfo(); 
                //this.props.getPackageDetails();
            });
            
        }
    });  
  }

  setCityList(cityList) {
    this.setState({ cityList: cityList });
  }

  handleSelectChange(option, e) {
    let thisFieldName = e.name;
    switch (thisFieldName) {
      case "country":
        ApiHelper.getCities(option.value, this.setCityList);
        this.setState({
          [thisFieldName]: option,
          headquartersCity: false,
          cityList: [],
          cityOptions: [],
          city:null
        });
        break;
      case "city":
        ApiHelper.getCities(option.value, this.setAddressCityList);
        this.setState({
          [thisFieldName]: option,
          addressCity: false,
          addressCityList: [],
          addressCityOptions: []
        });
        break;
      default:
        this.setState({ [thisFieldName]: option });
        break;
    }
  }

  setMetaList(metaList) {
      let degreesOptions = Helper.makeSelectOptions(metaList.DEG);
      let subjectsOptions = Helper.makeSelectOptions(metaList.SUB);
    this.setState({ metaList: metaList,degreesOptions,subjectsOptions }, function() {
    });
  }

  componentDidMount() {
    var storeState = store.getState();
    let metaList = storeState.meta.metaList;
    this.setState({
      metaList: metaList
    });

    if (Helper.isEmpty(metaList)) {
      ApiHelper.getMetaList(this.setMetaList);
    }

  }

  uploadImg(e) {
    this.refs.imgUploader.click();
  }

  static getDerivedStateFromProps(nextProps, prevState){


    
    let update = {};
    if(nextProps.business!==prevState.business){
        update.business= nextProps.business;
    }

    let currentUri = prevState.uri;
    let nextUri = nextProps.currentUser ? nextProps.currentUser.uri : undefined;
    if(!nextUri){
      nextUri = nextProps.profileDetails.uri ? nextProps.profileDetails.user.uri : undefined;
    }
    if(currentUri && currentUri == nextUri){
      return update;
    }


    let currentUser = nextProps.currentUser;    
    if (currentUser) {
      update.displayName = currentUser.fullName;
      update.profilePicUrl = currentUser.image;
      update.uri = currentUser.uri;
      //update.userId = 
    }


    
    let profileDetails = nextProps.profileDetails;



    if (!Helper.isEmpty(profileDetails)) {

   
      //this.setupForm(true);
      profileDetails.city = Helper.makeSelectOption(profileDetails.city);
      profileDetails.country = Helper.makeSelectOption(profileDetails.country);

      let industries = [];
      if (profileDetails.industries) {
        profileDetails.industries.forEach(element => {
          industries.push(Helper.makeSelectOption(element));
        });
      }

      let sectors = [];
      if (profileDetails.sectors) {
        profileDetails.sectors.forEach(element => {
          sectors.push(Helper.makeSelectOption(element));
        });
      }

      
      let profilePicUrl = prevState.profilePicUrl ? prevState.profilePicUrl : Helper.dataPath(config.defaultUserImage);
      
      update.createProfile = false;
      update.uri = profileDetails.user ? profileDetails.user.uri : undefined;
      update.summary = profileDetails.summary?profileDetails.summary:"";
      update.titleObj = profileDetails.title;
      update.country = Helper.makeSelectOption(profileDetails.country);
      update.city = Helper.makeSelectOption(profileDetails.city);
      update.id = profileDetails.user.id;
      update.user = profileDetails.user;
      update.profilePic = profileDetails.profilePic ? profileDetails.profilePic.uniqueId : "";

      update.profilePicUrl = profileDetails.profilePic ? Helper.viewFile(profileDetails.profilePic.uniqueId) : Helper.dataPath(config.defaultUserImage);
      update.company = profileDetails.company;
     // update.sector = !Helper.isEmpty(profileDetails.sectors) ? Helper.makeSelectOption(profileDetails.sectors) : null;
      update.sector = sectors;
      update.industries = industries;
      update.followers =  profileDetails.followers;
      update.following =  profileDetails.following;
      update.displayName =  profileDetails.displayName;
      
    } else {
      update.createProfile = true ;
    }

    if (Helper.isEmpty(update))
        return null;

    return update;
  }



  componentDidUpdate(prevProps, prevState) {
    if(prevProps.business!==this.props.business){

      let business = this.props.business;
      let industryOptions = [];
      if (!Helper.isEmpty(business)) {
        industryOptions = Helper.makeSelectOptions(business.industriesArray);
      }

      this.setState({
        business: business,
        industryOptions: industryOptions
      });
    }

    if(prevProps !== this.props){


      this.setState({
        createProfile: this.props.createProfile,
        uri:this.props.uri,
        summary: this.props.summary,
        titleObj: this.props.titleObj,
        country: this.props.country,
        city: this.props.city,
        id: this.props.id,
        user: this.props.user,
        profilePic: this.props.profilePic,
        profilePicUrl: this.props.profilePicUrl,
        company: this.props.company,
        sector: this.props.sector,
        industries: this.props.industries,
        followers: this.props.followers,
        following: this.props.following,
        displayName: this.props.displayName,
        packageDetails:this.props.packageDetails && this.props.packageDetails.packageDetails && this.props.packageDetails.packageDetails.subscriptionTypeDto ? this.props.packageDetails.packageDetails.subscriptionTypeDto : {},
      }, () => {
        if (!Helper.isEmpty(this.state.country)) 
          ApiHelper.getCities(this.state.country.value, (list) => { this.setCityList(list);} );
      })
      if(this.state.id){
      this.fetchAddFields()}
    }

  
      
  
  }

  //componentWillReceiveProps(props) {

    // let currentUri = this.state.uri;
    // let nextUri = props.currentUser ? props.currentUser.uri : undefined;
    // if(!nextUri){
    //   nextUri = props.profileDetails.uri ? props.profileDetails.user.uri : undefined;
    // }
    // if(currentUri && currentUri == nextUri){
    //   return;
    // }

    // let currentUser = props.currentUser;    
    // if (currentUser) {
    //   this.setState(
    //     {
    //       displayName: currentUser.fullName,
    //       profilePicUrl: currentUser.image,
    //       uri:currentUser.uri,
    //     },
    //     function() {
          
    //     }
    //   );
    // }

    // let profileDetails = props.profileDetails;

    // if (!Helper.isEmpty(profileDetails)) {
    //   this.setupForm(true);
    //   profileDetails.city = Helper.makeSelectOption(profileDetails.city);
    //   profileDetails.country = Helper.makeSelectOption(profileDetails.country);

    //   let industries = [];
    //   if (profileDetails.industries) {
    //     profileDetails.industries.forEach(element => {
    //       industries.push(Helper.makeSelectOption(element));
    //     });
    //   }

    //   let profilePicUrl = this.state.profilePicUrl ? this.state.profilePicUrl : Helper.dataPath(config.defaultUserImage);
      
    //   this.setState(
    //     {
    //       createProfile: false,
    //       uri:profileDetails.user ? profileDetails.user.uri : undefined,
    //       summary: profileDetails.summary?profileDetails.summary:"",
    //       titleObj: profileDetails.title,
    //       country: Helper.makeSelectOption(profileDetails.country),
    //       city: Helper.makeSelectOption(profileDetails.city),
    //       id: profileDetails.id,
    //       user: profileDetails.user,
    //       profilePic: profileDetails.profilePic ? profileDetails.profilePic.uniqueId : "",
    //       profilePicUrl: profileDetails.profilePic ? Helper.viewFile(profileDetails.profilePic.uniqueId) : Helper.dataPath(config.defaultUserImage),
    //       company: profileDetails.company,
    //       sector: !Helper.isEmpty(profileDetails.sectors) ? Helper.makeSelectOption(profileDetails.sectors.pop()) : null,
    //       industries: industries,
    //       followers: profileDetails.followers,
    //       following: profileDetails.following,
    //       displayName: profileDetails.displayName
    //     },
    //     function() {
    //       this.setupForm(false);
    //     }
    //   );
    // } else {
    //   this.setState({ createProfile: true });
    // }
  //}

  handleFieldValueChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    if (name) {
      switch (name) {
        default:
          this.setState({ [name]: value });
          break;
      }
    }
  }

  mobileValues = (data)=>{
    this.setState({
      mobileCode:data.ext,
      mobile:data.mobile,
    })
  }

  editBasicDetails() {
    this.setState({
      editBasicDetails: this.state.editBasicDetails ? false : true
    });
  }

  editAddInfo =()=>{
    this.setState({
      editAddInfo: this.state.editAddInfo ? false : true
    });
  }

  uploadProfileImage(e) {
    this.setState({
      loading:true
    })
    let file = e.target.files ? e.target.files[0] : null;
    if (file) {
      ApiHelper.uploadFile({
        repoType: "PROFILE_IMAGES",
        fileType: "IMAGE",
        file: file,
        callback: result => {
          this.setState({
            profilePic: result.uniqueId,
            profilePicUrl: Helper.viewFile(result.uniqueId),
            loading:false
          });
        }
      });
    }
  }

  handleTitleChange = (event) => {
    this.setState({ titleObj: event[0] });

  }

  fetchAddFields = () =>{
    
      Axios.get(
        Helper.apiURL({
            uri:"user/user-info?userId="+this.state.id,
              })
          )
          .then( (response) => {           
           
              let data = response.data;
           
              
              this.setState({
                mobileCode:data.mobileCode,
                mobile:data.mobile,
                email:data.email,
                gender: data.gender ? {
                      "value":data.gender,
                      "label":data.gender
                    }:"",
                dob:data.dob,
                regNo:data.regNo,
                regYear:data.regYear,
                category:data.category  ? data.category :"",
                noOfEmployees:data.noOfEmployees,
                natureOfBusiness:data.natureOfBusiness,
                phoneCode:data.phoneCode,
                phone:data.phone,
                companyEmail:data.companyEmail,
                kraPin:data.kraPin,
                addressLine1:data.address.addressLine1,
                poBox:data.address.poBox,
                
            })

               const contacts1 = data && data.contactPersons && data.contactPersons[0] ? data.contactPersons[0] : []

               if(contacts1){
                this.setState({
                  firstNameC1:data.contactPersons[0].firstname,
                  lastNameC1:data.contactPersons[0].lastname,
                  mobileCodeC1:data.contactPersons[0].mobileCode,
                  mobileC1:data.contactPersons[0].mobile,
                  emailC1:data.contactPersons[0].email,
                  genderC1:data.contactPersons[0].gender  ? {
                    "value":data.contactPersons[0].gender,
                    "label":data.contactPersons[0].gender
                  }:"",
                  dobC1:data.contactPersons[0].dob,
                 })
               }

               const contacts2 = data && data.contactPersons && data.contactPersons[1] ? data.contactPersons[1] : []

               if(contacts2){
                this.setState({
                  firstNameC2:data.contactPersons[1].firstname,
                  lastNameC2:data.contactPersons[1].lastname,
                  mobileCodeC2:data.contactPersons[1].mobileCode,
                  mobileC2:data.contactPersons[1].mobile,
                  emailC2:data.contactPersons[1].email,
                  genderC2:data.contactPersons[1].gender  ? {
                    "value":data.contactPersons[1].gender,
                    "label":data.contactPersons[1].gender
                  }:"",
                  dobC2:data.contactPersons[1].dob,
                 })
               }
              

            })
          .catch((err) => {


          });
    
  }


  render() {


    let isEditable = true;


    let profilePicUrl = Helper.dataPath(this.state.profilePicUrl);
    let countryOptions = Helper.makeSelectOptions(countries);
    let cityOptions = Helper.makeSelectOptions(this.state.cityList);


  
    

    let subscriptionType = "BUSINESS";

    let catagoryOption = this.state.catagoryOption;

    let cityName = !Helper.isEmpty(this.state.city)
      ? this.state.city.label
      : "";
    let countryName = !Helper.isEmpty(this.state.country)
      ? this.state.country.label
      : "";
    let companyName = !Helper.isEmpty(this.state.company)
      ? this.state.company.name
      : "";
    let companyUri = !Helper.isEmpty(this.state.company)
      ? this.state.company.uri
      : "";
    let titleName = !Helper.isEmpty(this.state.titleObj)
      ? this.state.titleObj.name
      : "";
    let degreesOptions = this.state.degreesOptions;
    let subjectsOptions = this.state.subjectsOptions;
    // let sectorName = !Helper.isEmpty(this.state.sector)
    //   ? this.state.sector.label
    //   : "";

    let sector = Helper.selectOptionsToTextArray(this.state.sector);
    let industries = Helper.selectOptionsToTextArray(this.state.industries);
    let sectors = this.state.sector;
    //let industryOptions = Helper.makeSelectOptions(this.state.metaList.CIN);
    let sectorOptions = [];
    if (!Helper.isEmpty(this.state.business)) {
      sectorOptions = Helper.makeSelectOptions(
        this.state.business.sectorsArray
      );
    }

    let industryOptions = industryOptions ? industryOptions : [];
    let profileUri = this.user ? this.user.uri : "";
    let loading = this.state.loading;

    let {errorMsg,addLoader } = this.state;

    let  packageName  = this.state.packageDetails &&  this.state.packageDetails.name ? Helper.getProperPackageName(this.state.packageDetails.name) : "";
   


    
    return (
      <div>
          <LoadingOverlay>
            <Loader loading={addLoader} fullPage />
          </LoadingOverlay>
        <div className="row bold-headers text-left p-b-30">
          <div className="col-md-12 col-lg-12 col-xlg-12 p-0">
            <div className="card" id="overview">
              <div className="card-body">
                <div className="col-lg-12 p-0">
                  <div className="col-lg-9 p-0">
                       <div>
                      
                        {isEditable && this.state.editBasicDetails ? (
                          <a href="javascript:void(0)" onClick={this.uploadImg}>
                           <div style={{"position": "relative"}}>
                             <img
                              src={profilePicUrl}
                              className="float-left newImg"
                              width="100" height="100"
                            />
                              <div style={{"position": "absolute", "top": "50px", "left": "50px" }}>
                              <LoadingOverlay>
                                <Loader loading={loading} />
                              </LoadingOverlay>
                              </div>
                          </div>
                            <input
                              type="file"
                              id="userImg"
                              name="userImg"
                              style={{ display: "none" }}
                              ref="imgUploader"
                              onChange={this.uploadProfileImage}
                            />
                            <br />
                          </a>
                        ) : (
                          <img
                            src={profilePicUrl}
                            className="img-circle float-left"
                            width="100" height="100"
                          />
                        )}
                       
                        <div className="card-body-right float-left m-l-20 text-left half-width">
                          {isEditable && this.state.editBasicDetails ? (
                            <input type="text" className="form-control" name="displayName" value={this.state.displayName} placeholder="Your Name" onChange={this.handleFieldValueChange} />
                          ) : (
                            <h2 className="card-title m-b-0 m-t-5 ">
                              {this.state.displayName}
                            </h2>
                          )}
                          <p className="m-0 horizontalChilds font-16">
                            <span>{titleName}</span>
                            {companyName ? (<span className="m-l-5 m-r-5">at</span>) : ("")}
                            {!Helper.isEmpty(companyUri)?<Link to={'/company/'+companyUri}>{companyName}</Link> : <span>{companyName}</span>}
                          </p>
                          <p className="m-0 font-14 text-grey">
                            {cityName}
                            {cityName && countryName ? ", " : ""}

                            {countryName}
                          </p>
                        </div>
                      </div>
                  </div>
                  <div className="col-lg-3 float-right p-0 text-right">
                    {isEditable ? (
                      <div className="m-t-10">
                        <div>
                          {this.state.editBasicDetails ? (
                            <a
                              onClick={this.editBasicDetails}
                              className="text-danger"
                            >
                              <i className="fa fa-times" />
                            </a>
                          ) : (
                            <a
                              onClick={this.editBasicDetails}
                              className="text-info"
                            >
                              Edit
                            </a>
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="clearfix" />

                {false ? (
                  <div>
                    {/* <div className="col-md-12 p-0">
                      <hr />
                      <div className="col-md-2 float-left">
                        <h3 className="font-16 l-h-0">Following</h3>
                        <div className="text-info font-bold">
                          {this.state.following}
                        </div>
                      </div>
                      <div className="col-md-2 float-left">
                        <h3 className="font-16 l-h-0">Followers</h3>
                        <div className="text-info font-bold">
                          {this.state.followers}
                        </div>
                      </div>
                      <div className="col-md-3 float-left">
                        <h3 className="font-16 l-h-0">Profile Views</h3>
                        <div className="text-info font-bold">To be set</div>
                      </div>
                    </div> */}
                    <div className="clearfix" />
                    <div className="col-md-12 p-0">
                      <hr />
                      <div className="col-md-3 float-left font-14 p-0">
                        <div className="">
                          <i className="fa fa-pencil-alt point" />
                          &nbsp;Create New Post
                        </div>
                      </div>
                      <div className="col-md-3 float-left font-14 p-0">
                        <a
                          className=""
                          target="_blank"
                          href="/create-opportunity/start"
                        >
                          <i className="fa fa-pencil-alt" />
                          &nbsp;Create New Opportunity
                        </a>
                      </div>
                      <div className="col-md-3 float-left font-14">
                        <a
                          className=""
                          target="_blank"
                          href="/create-event/start"
                        >
                          <i className="fa fa-calendar-check" />
                          &nbsp;Create New Event
                        </a>
                      </div>
                      <div className="col-md-3 float-left font-14 p-0">
                        <a
                          className=""
                          target="_blank"
                          href="/create-company/start"
                        >
                          <i className="fa fa-pencil-alt" />
                          &nbsp;Create New Company
                        </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {isEditable && this.state.editBasicDetails ? (
                  <div>
                    <EditTextArea
                      value={this.state.summary}
                      name="summary"
                      title="Summary"
                      onValueChange={this.handleFieldValueChange}
                    />
                    <ObjectsSearch
                      title="Title"
                      headingClass=""
                      onChange={event => this.handleTitleChange(event)}
                      name="titleObj"
                      defaultValue={this.state.titleObj?this.state.titleObj:null}
                      defaultSelected={!Helper.isEmpty(this.state.titleObj) ? [this.state.titleObj] : []}
                    />
                    <EditText
                      title="Company"
                      name="company"
                      value={companyName}
                      placeholder="Company Name"
                      onValueChange={this.handleFieldValueChange}
                    />
                    <EditSelect
                      title="Country"
                      name="country"
                      value={this.state.country}
                      options={countryOptions}
                      onChange={this.handleSelectChange}
                      additionalClassName="date-minimal-width ediableInline"
                    />
                    <EditSelect
                      title="City/Town"
                      name="city"
                      value={this.state.city}
                      options={cityOptions}
                      reference="cityDropdown"
                      onChange={this.handleSelectChange}
                      additionalClassName="date-minimal-width ediableInline"
                    />
                    <EditMultiSelect
                      name="sector"
                      title="Sector"
                      value={this.state.sector}
                      options={sectorOptions}
                      onChange={this.handleSelectChange}
                      additionalClassName="ediableInline "
                    />
                    <EditMultiSelect
                      name="industries"
                      title="Industries"
                      value={this.state.industries}
                      options={this.state.industryOptions}
                      onChange={this.handleSelectChange}
                      additionalClassName="ediableInline"
                    />
                    <div className="card-body">
                      <div className="clearfix" />

                      <div className="col-sm-12 col-xs-12 p-0 text-right">
                        <button
                          type="submit"
                          className="btn btn-info waves-effect waves-light m-r-0 float-right"
                          onClick={this.handleSubmit}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <h6>Summary</h6>
                    <ReadMoreAndLess
                      ref={this.readMore}
                      className="read-more-content"
                      charLimit={config.summaryLimit}
                      readMoreText="Read more"
                      readLessText="Read less"
                    >
                      {this.state.summary ? this.state.summary : ""}
                    </ReadMoreAndLess>
                    <InfoText title="Title" text={titleName} />
                    <InfoText title="Company" text={companyName} uri={companyUri}/>
                    <InfoText title="Country" text={countryName} />
                    <InfoText title="City/Town" text={cityName} />
                    <InfoTextArray title="Sector" textArray={sector} />
                    <InfoTextArray textArray={industries} title="Industries" />
                  </div>
                )}
              </div>
            </div>

            <div className="card text-left bold-headers" id="section1">
              <div className="card-body">
                <div className="col-lg-12 p-0 m-h-30">
                  <div className="col-lg-6 p-0 float-left">
                    <h4>Additional Informations</h4>
                  </div>
                  {/* <div className="col-lg-3 float-right p-0 text-right">
                    {isEditable ? (
                      <div className="m-t-10">
                        <div>
                          {this.state.editAddInfo ? (
                            <a
                              onClick={this.editAddInfo}
                              className="text-danger"
                            >
                              <i className="fa fa-times" />
                            </a>
                          ) : (
                            <a
                              onClick={this.editAddInfo}
                              className="text-info"
                            >
                              Edit
                            </a>
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div> */}
                </div>
                <div className="col-md-12 p-0">
                      {/* {this.state.editAddInfo ?  */}
                            <div className="col-md-12 clearfix p-0">
                              {/* <div className="col-md-6 float-left p-0">
                                 <EditText
                                    value={this.state.mobileCode}
                                    name="mobileCode"
                                    title="Mobile No"
                                    addClass="full-width"
                                    onValueChange={this.handleFieldValueChange}
                                    errorMsg={errorMsg["mobileCode"]}
                                    required={true}
                                  />
                              </div>
                              <div className="col-md-6 float-left p-r-0">
                                  <EditText
                                    value={this.state.mobile}
                                    name="mobile"
                                    title={""}
                                    addClass="full-width m-t-20"
                                    onValueChange={this.handleFieldValueChange}
                                    errorMsg={errorMsg["mobile"]}f
                                    required={true}
                                  />
                                </div> */}

                                <Mobile title="Mobile No"
                                            sendValue={this.mobileValues}
                                            extVal={this.state.mobileCode}
                                            mobileVal={this.state.mobile}
                                            mobileName={"mobile"}
                                            extName={"mobileCode"}
                                            // required={true}
                                            error={errorMsg}
                                            full={true}
                                            /> 
                          
                                {/* <EditText
                                  value={this.state.email}
                                  name="email"
                                  title="Email"
                                  addClass="full-width"
                                  onValueChange={this.handleFieldValueChange}
                                  errorMsg={errorMsg["email"]}
                                  required={true}
                                /> */}
                                 <EditSelect
                                  options={this.state.genderOptions}
                                  value={this.state.gender}
                                  name="gender"
                                  title="Gender"
                                  addClass="full-width"
                                  onChange={this.handleSelectChange}
                                />

                              <EditText
                                  value={this.state.dob}
                                  name="dob"
                                  title="Date Of Birth"
                                  inputType="date"
                                  addClass="full-width"
                                  onValueChange={this.handleFieldValueChange}
                                />

                                {/* { subscriptionType == "PREMIUM" &&
                                  <div className="premium">
                                      <EditTexts
                                          value={this.state.occupation}
                                          name="occupation"
                                          title={"Occupation"}
                                          addClass="full-width"
                                          onValueChange={this.handleFieldValueChange}
                                        />
                                      <EditText
                                          value={this.state.employerCompany}
                                          name="employerCompany"
                                          title={"Employer / Company"}
                                          addClass="full-width"
                                          onValueChange={this.handleFieldValueChange}
                                        />
                                  </div>
                                } */}


                                {(packageName == "company"  || packageName == "startup") && <div className="business">
                                    {/* <EditText
                                        value={this.state.businessName}
                                        name="businessName"
                                        title={"Company / Business Name"}
                                        addClass="full-width"
                                        onValueChange={this.handleFieldValueChange}
                                      /> */}
                                  <EditText
                                        value={this.state.regNo}
                                        name="regNo"
                                        title={"Registration Number"}
                                        addClass="full-width"
                                        onValueChange={this.handleFieldValueChange}
                                        errorMsg={errorMsg["regNo"]}
                                        required={true}
                                      />

                                <EditText
                                        value={this.state.regYear}
                                        name="regYear"
                                        title={"Year of Registration / Incorporation"}
                                        addClass="full-width"
                                        onValueChange={this.handleFieldValueChange}
                                        errorMsg={errorMsg["regYear"]}
                                        required={true}
                                      />

                                  <EditSelect
                                        title="Category"
                                        name="category"
                                        required={true}
                                        value={this.state.category}
                                        options={this.state.catagoryOption}
                                        onChange={this.handleSelectChange}
                                        // errorMsg={errorMsg["regYear"]}
                                        //additionalClassName="ediableInline"
                                      />

                                  <EditText
                                        value={this.state.noOfEmployees}
                                        name="noOfEmployees"
                                        title={"Number of Employees"}
                                        addClass="full-width"
                                        onValueChange={this.handleFieldValueChange}
                                      />

                                  <EditText
                                        value={this.state.natureOfBusiness}
                                        name="natureOfBusiness"
                                        title={"Nature of Business"}
                                        addClass="full-width"
                                        onValueChange={this.handleFieldValueChange}
                                      />
                                     {/* <EditText
                                        value={this.state.regYear}
                                        name="regYear"
                                        title={"Nature of Business"}
                                        addClass="full-width"
                                        onValueChange={this.handleFieldValueChange}
                                      /> */}
                                       <div className="col-md-6 float-left p-0">
                                        <EditText
                                            value={this.state.phoneCode}
                                            name="phoneCode"
                                            title="Telephone No."
                                            addClass="full-width"
                                            onValueChange={this.handleFieldValueChange}
                                          />
                                      </div>
                                      <div className="col-md-6 float-left p-r-0">
                                          <EditText
                                            value={this.state.phone}
                                            name="phone"
                                            title={""}
                                            addClass="full-width m-t-20"
                                            onValueChange={this.handleFieldValueChange}
                                            errorMsg={errorMsg["phone"]}
                                            required={true}
                                          />
                                        </div>
                                        <EditText
                                            value={this.state.companyEmail}
                                            name="companyEmail"
                                            title={"Company email"}
                                            addClass="full-width"
                                            onValueChange={this.handleFieldValueChange}
                                            errorMsg={errorMsg["companyEmail"]}
                                            required={true}
                                          />
                                          <EditText
                                            value={this.state.kraPin}
                                            name="kraPin"
                                            title={"KRA PIN"}
                                            addClass="full-width"
                                            onValueChange={this.handleFieldValueChange}
                                           
                                          />
                                          <div className="col-md-12 flex m-t-10 p-0">
                                             <h2 className="clearfix">Address</h2>
                                          </div>
                                          <EditText
                                            value={this.state.addressLine1}
                                            name="addressLine1"
                                            title={"Physical Address"}
                                            addClass="full-width"
                                            onValueChange={this.handleFieldValueChange}
                                          />
                                          <EditText
                                            value={this.state.poBox}
                                            name="poBox"
                                            title={"Postal Address"}
                                            addClass="full-width"
                                            onValueChange={this.handleFieldValueChange}
                                          />
                                        <div className="col-md-12 flex m-t-10 p-0">
                                          <h2 className="clearfix m-t-10">Contact Person 1</h2>
                                        </div>
                                       <div className="col-md-6 float-left p-0">
                                        <EditText
                                            value={this.state.firstNameC1}
                                            name="firstNameC1"
                                            title="First Name"
                                            addClass="full-width"
                                            onValueChange={this.handleFieldValueChange}
                                            errorMsg={errorMsg["firstNameC1"]}
                                            required={true}
                                          />
                                      </div>
                                      <div className="col-md-6 float-left p-r-0">
                                          <EditText
                                            value={this.state.lastNameC1}
                                            name="lastNameC1"
                                            title={"Last Name"}
                                            addClass="full-width"
                                            onValueChange={this.handleFieldValueChange}
                                            errorMsg={errorMsg["lastNameC1"]}
                                            required={true}
                                          />
                                        </div>

                                        <div className="col-md-6 float-left p-0">
                                          <EditText
                                              value={this.state.mobileCodeC1}
                                              name="mobileCodeC1"
                                              title="Mobile No"
                                              addClass="full-width"
                                              onValueChange={this.handleFieldValueChange}
                                              errorMsg={errorMsg["mobileCodeC1"]}
                                              required={true}
                                            />
                                        </div>
                                        <div className="col-md-6 float-left p-r-0">
                                            <EditText
                                              value={this.state.mobileC1}
                                              name="mobileC1"
                                              title={""}
                                              addClass="full-width m-t-20"
                                              onValueChange={this.handleFieldValueChange}
                                              errorMsg={errorMsg["mobileC1"]}
                                              required={true}
                                            />
                                          </div>
                                    
                                          <EditText
                                            value={this.state.emailC1}
                                            name="emailC1"
                                            title="Email"
                                            addClass="full-width"
                                            onValueChange={this.handleFieldValueChange}
                                            errorMsg={errorMsg["emailC1"]}
                                            required={true}
                                          />
                                          <EditSelect
                                            value={this.state.genderC1}
                                            options={this.state.genderOptions}
                                            name="genderC1"
                                            title="Gender"
                                            addClass="full-width"
                                            onChange={this.handleSelectChange}
                                          />

                                        <EditText
                                            value={this.state.dobC1}
                                            name="dobC1"
                                            title="Date Of Birth"
                                            inputType="date"
                                            addClass="full-width"
                                            onValueChange={this.handleFieldValueChange}
                                          />

                                        <div className="col-md-12 flex m-t-10 p-0">
                                          <h2 className="clearfix m-t-10">Contact Person 2</h2>
                                        </div>
                                       <div className="col-md-6 float-left p-0">
                                        <EditText
                                            value={this.state.firstNameC2}
                                            name="firstNameC2"
                                            title="First Name"
                                            addClass="full-width"
                                            onValueChange={this.handleFieldValueChange}
                                            errorMsg={errorMsg["firstNameC2"]}
                                            required={true}
                                          />
                                      </div>
                                      <div className="col-md-6 float-left p-r-0">
                                          <EditText
                                            value={this.state.lastNameC2}
                                            name="lastNameC2"
                                            title={"Last Name"}
                                            addClass="full-width"
                                            onValueChange={this.handleFieldValueChange}
                                            errorMsg={errorMsg["lastNameC2"]}
                                            required={true}
                                          />
                                        </div>

                                        <div className="col-md-6 float-left p-0">
                                          <EditText
                                              value={this.state.mobileCodeC2}
                                              name="mobileCodeC2"
                                              title="Mobile No"
                                              addClass="full-width"
                                              onValueChange={this.handleFieldValueChange}
                                              errorMsg={errorMsg["mobileCodeC2"]}
                                              required={true}
                                            />
                                        </div>
                                        <div className="col-md-6 float-left p-r-0">
                                            <EditText
                                              value={this.state.mobileC2}
                                              name="mobileC2"
                                              title={""}
                                              addClass="full-width m-t-20"
                                              onValueChange={this.handleFieldValueChange}
                                              errorMsg={errorMsg["mobileC2"]}
                                              required={true}
                                            />
                                          </div>
                                    
                                          <EditText
                                            value={this.state.emailC2}
                                            name="emailC2"
                                            title="Email"
                                            addClass="full-width"
                                            onValueChange={this.handleFieldValueChange}
                                            errorMsg={errorMsg["emailC2"]}
                                            required={true}
                                          />
                                          <EditSelect
                                            value={this.state.genderC2}
                                            options={this.state.genderOptions}
                                            name="genderC2"
                                            title="Gender"
                                            addClass="full-width"
                                            onChange={this.handleSelectChange}
                                          />

                                        <EditText
                                            value={this.state.dobC2}
                                            name="dobC2"
                                            title="Date Of Birth"
                                            inputType="date"
                                            addClass="full-width"
                                            onValueChange={this.handleFieldValueChange}
                                          />

                                  </div>
                                
                                }

                                <button  className="btn btn-info float-right clearfix m-t-20" onClick={this.handleAdditionalSave}>Save</button>

                            </div>
                            {/* :
                            <div className="">View</div> */}

                      {/* } */}
                </div>
              </div>
            </div>

            {/* <div className="card text-left bold-headers" id="section1">
              <div className="card-body">
                <div className="col-lg-12 p-0 m-h-30">
                  <div className="col-lg-6 p-0 float-left">
                    <h4>Contact Details</h4>
                  </div>
                  <div className="col-lg-3 float-right p-0  text-right">
                    {isEditable ? (
                      <div className="m-t-10">
                        <div>
                          {this.state.formActive.contact ? (
                            <a
                              onClick={this.hideForm}
                              data-type="contact"
                              className="text-danger fa fa-times"
                            />
                          ) : (
                            <a
                              onClick={this.showForm}
                              data-type="contact"
                              className="text-info"
                            >
                              Edit
                            </a>
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div>
                  {isEditable && this.state.formActive.contact ? (
                    <div>
                      <EditText
                        title="Address"
                        name="addressLine1"
                        value={this.state.addressLine1}
                        placeholder="Address Line one"
                        onValueChange={this.handleFieldValueChange}
                      />
                      <EditText
                        name="addressLine2"
                        value={this.state.addressLine2}
                        placeholder="Address Line two (optional)"
                        onValueChange={this.handleFieldValueChange}
                      />
                      <EditText
                        name="addressLine3"
                        value={this.state.addressLine3}
                        placeholder="Address Line three (optional)"
                        onValueChange={this.handleFieldValueChange}
                      />
                      <EditText
                        name="stateOrProvince"
                        value={this.state.stateOrProvince}
                        placeholder="State/Province/Region (optional)"
                        onValueChange={this.handleFieldValueChange}
                      />
                      <EditText
                        name="pobox"
                        value={this.state.pobox}
                        placeholder="PO Box (optional)"
                        onValueChange={this.handleFieldValueChange}
                      />
                      <EditText
                        name="zip"
                        value={this.state.zip}
                        placeholder="Zip (optional)"
                        onValueChange={this.handleFieldValueChange}
                      />
                      <EditSelect
                        title="country"
                        name="addressCountry"
                        value={this.state.addressCountry}
                        options={this.state.countryOptions}
                        onChange={this.handleSelectChange}
                        additionalClassName="ediableInline "
                      />
                      <EditSelect
                        title="city"
                        name="addressCity"
                        value={this.state.addressCity}
                        options={this.state.addressCityOptions}
                        onChange={this.handleSelectChange}
                        additionalClassName="ediableInline mt-10"
                      />

                      <EditText
                        name="phone"
                        title="Phone Number"
                        value={this.state.phone}
                        onValueChange={this.handleFieldValueChange}
                      />
                      <EditText
                        name="email"
                        title="Email"
                        value={this.state.email}
                        onValueChange={this.handleFieldValueChange}
                      />
                      <EditText
                        name="facebook"
                        title="Facebook"
                        value={this.state.facebook}
                        onValueChange={this.handleFieldValueChange}
                      />
                      <EditText
                        name="twitter"
                        title="Twitter"
                        value={this.state.twitter}
                        onValueChange={this.handleFieldValueChange}
                      />
                      <EditText
                        name="linkedIn"
                        title="LinkedIn"
                        value={this.state.linkedIn}
                        onValueChange={this.handleFieldValueChange}
                      />
                      <EditText
                        name="website"
                        title="Website"
                        value={this.state.website}
                        onValueChange={this.handleFieldValueChange}
                      />
                    </div>
                  ) : (
                    <div>
                      <InfoAddress address={addressDto} title="Address" />

                      <InfoText text={this.state.phone} title="Phone" />
                      <InfoText text={this.state.email} title="Email" />

                      {this.state.facebook ||
                      this.state.twitter ||
                      this.state.linkedIn ? (
                        <h6>Social Media Presence</h6>
                      ) : (
                        ""
                      )}
                      <ExternalLink
                        link={this.state.facebook}
                        text="Facebook"
                      />
                      <ExternalLink link={this.state.twitter} text="Twitter" />
                      <ExternalLink
                        link={this.state.linkedIn}
                        text="LinkedIn"
                      />
                      <ExternalLink title="Website" link={this.state.website} />
                    </div>
                  )}
                </div>
              </div>
            </div> */}

            {!this.state.createProfile ? (
              <div>
                <ManageCareerHistory
                  uri={profileUri}
                  rows={[]}
                 // titlesOptions={titlesOptions}
                  careerHistory={
                    this.props.profileDetails.careerDtos
                      ? this.props.profileDetails.careerDtos
                      : []
                  }
                />

                <ManageEducationHistory
                  uri={profileUri}
                  rows={[]}
                  degreesOptions={degreesOptions}
                  subjectsOptions={subjectsOptions}
                  educationHistory={
                    this.props.profileDetails.educationDtos
                      ? this.props.profileDetails.educationDtos
                      : []
                  }
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) =>({
  auth:state.auth,
  packageDetails:state.packageDetails,
  errors:state.errors
});

export default connect(mapStateToProps,{login,fetchUserInfo,logoutUser})(withRouter(UserProfileContent));
