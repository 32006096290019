import { GET_RANKING_DETAILS, REMOVE_RANKING_DETAILS }  from '../actions/types';

const initialState = {
    
}

export default function( state = initialState, action){
   
    switch(action.type){
        case GET_RANKING_DETAILS :
            return {
                ...state,
                rankDetails : action.payload,
                loading:false
            }
        case REMOVE_RANKING_DETAILS :
                return {
                    ...state,
                    rankDetails : action.payload,
                    loading:false
                }
        default:
            return state;
    }
}