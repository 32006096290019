let packages = {
  "TM1":{
    "price":"109",
    "currency":"KES",
    "currencySymbol":"KES",
    "term":"MONTHLY",
    "display":"TM1",
    "key":"TM1",
  },
  "individual":{
    "price":"0",
    "currency":"KES",
    "currencySymbol":"KES",
    "term":"MONTHLY",
    "display":"Individual",
    "key":"Individual",
  },
  "premium":{
    "price":"399",
    "currency":"KES",
    "currencySymbol":"KES",
    "term":"MONTHLY",
    "display":"Premium",
    "key":"Premium",
  },
  "startup":{
    "price":"3999",
    "currency":"KES",     
    "currencySymbol":"KES",
    "term":"MONTHLY",
    "display":"Startup",
    "key":"Startup",
  },
  "start_up":{
    "price":"3999",
    "currency":"KES",     
    "currencySymbol":"KES",
    "term":"MONTHLY",
    "display":"Startup",
    "key":"Startup",
  },
  "international_company":{
    "price":"5999",
    "currency":"KES",     
    "currencySymbol":"KES",
    "term":"MONTHLY",
    "display":"International Company",
    "key":"Company",
  },
  "small_business":{
    "price":"5999",
    "currency":"KES",     
    "currencySymbol":"KES",
    "term":"MONTHLY",
    "display":"Small Business/Company",
    "key":"Company",
  },
  "company":{
    "price":"5999",
    "currency":"KES",     
    "currencySymbol":"KES",
    "term":"MONTHLY",
    "display":"Company",
    "key":"Company",
  },
  "individual_quarterly_":{
    "price":"855",
    "currency":"KES",     
    "currencySymbol":"KES",
    "term":"QUARTERLY",
    "key":"Individual (Quarterly)",
    "display":"Individual",
  },
  "premium_quarterly_":{
    "price":"1079",
    "currency":"KES",     
    "currencySymbol":"KES",
    "term":"QUARTERLY",
    "key":"Premium (Quarterly)",
    "display":"Premium",
  },
  "startup_quarterly_":{
    "price":"10799",
    "currency":"KES",     
    "currencySymbol":"KES",
    "term":"QUARTERLY",
    "key":"Startup (Quarterly)",
    "display":"Startup",
  },
  "start_up_quarterly_":{
    "price":"10799",
    "currency":"KES",     
    "currencySymbol":"KES",
    "term":"QUARTERLY",
    "key":"Startup (Quarterly)",
    "display":"Startup",
  },
  "company_quarterly_":{
    "price":"5999",
    "currency":"KES",     
    "currencySymbol":"KES",
    "term":"QUARTERLY",
    "key":"Company (Quarterly)",
    "display":"Company",
  },
  "individual_annual_":{
    "price":"0",
    "currency":"KES",
    "currencySymbol":"KES",
    "term":"ANNUAL",
    "key":"Individual (Yearly)",
    "display":"Individual",
  },
  "premium_annual_":{
    "price":"3999",
    "currency":"KES",
    "currencySymbol":"KES",
    "term":"ANNUAL",
    "key":"Premium (Yearly)",
    "display":"Premium",
  },
  "startup_annual_":{
    "price":"39999",
    "currency":"KES",     
    "currencySymbol":"KES",
    "term":"ANNUAL",
    "key":"Startup (Yearly)",
    "display":"Startup",
  },
  "start_up_annual_":{
    "price":"39999",
    "currency":"KES",     
    "currencySymbol":"KES",
    "term":"ANNUAL",
    "key":"Startup (Yearly)",
    "display":"Startup",
  },
  "company_annual_":{
    "price":"59999",
    "currency":"KES",     
    "currencySymbol":"KES",
    "term":"ANNUAL",
    "key":"Company (Yearly)",
    "display":"Company",
  }
    
}

module.exports = packages;