import React, { Component } from 'react';
import Helper from "../../shared/custom";
import config from '../../shared/config';
import Feeds from '../wigets/Feeds';

class BookmarksContent extends Component {
  constructor(props){
      super(props);
      this.state = {
            mode : "bookmarks"
      }
  }
  render() {
    return (
      <div>
         <Feeds mode={this.state.mode} />
      </div>
    )
  }
}

export default  BookmarksContent;