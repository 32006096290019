import React, { Component } from 'react';
import Videos from '../opportunity/Videos'
import Upload from '../opportunity/Upload';
import Helpers from '../../shared/custom';
import InfoDate from "../ui/InfoDate";
import InfoText from '../ui/InfoText';
import ExternalLink from '../ui/ExternalLink';
import Interest from "../uiActions/Interest";
import SmartLink from "../uiActions/SmartLink";
import ReadMoreAndLess from 'react-read-more-less';
import EntityExtraOptions from "../uiActions/EntityExtraOptions";
import store from "../../store";
import InfoTextArray from "../ui/InfoTextArray";

class EventDetailsContent extends Component {

constructor(props){
    super(props);
    this.state={
        uri:props.uri,
        showExtraOptions:true,
        event:null
    }
    store.subscribe(() => {
        var storeState = store.getState();
        let storeAuth = storeState.auth.isAuthenticated;
        this.setState({auth:storeAuth})
    })
}

componentWillReceiveProps(props){
    this.setState({event:props.eventDetails});
}

  render() {
    let event = this.state.event;
    if(!event){
        return (<div></div>)
    }

    event.videos = event.videos || [];
    let ownerName = !Helpers.isEmpty(event.owner) ? (event.owner.name || event.owner.fullName) :'';
    let countryName = !Helpers.isEmpty(event.country)?event.country.name:'';
    let cityName = !Helpers.isEmpty(event.city)?event.city.name:'';

    let addressArr = [];
    let addObj = event.address;
    if (addObj.addressLine1) addressArr.push(addObj.addressLine1);
    if (addObj.addressLine2) addressArr.push(addObj.addressLine2);
    if (addObj.addressLine3) addressArr.push(addObj.addressLine3);
    let addressAsString = addressArr.toString();

    let industryName = event.industry?event.industry.name:'';
    let industry = event.industries?Helpers.selectOptionsToTextArrayByName(event.industries):[];
    let categoryLabel = Helpers.isEmpty(event.category)?'':Helpers.camelize(event.category.label);


    
    let address = addressAsString+",\n"+cityName+"\n"+countryName;
    return (
      <div>
        <div className="row bold-headers text-left p-b-30">
            <div className="col-md-12 col-lg-12 col-xlg-12 p-0">
         
            <div className="coverPic text-center">
               <img src={event.coverPhotoUrl} className="img-responsive"/>
            </div>
  
            <div className="card">
                    <div className="card-body banner-small">
                        {/* <div className="float-right text-right">
                        {
                        (this.state.showExtraOptions) && <EntityExtraOptions objectType="event" uri={event.uri}/>
                        }
                        </div>
                         */}
                        <div className="col-lg-12 p-0 banner-small">
                            <div className="col-lg-12 p-0 float-left">
                                <div className="m-t-0">
                                        <h2 className="m-t-0 font-14">{event.name}</h2>
                                        <h6 className="font-normal m-t-0 m-b-5 v-a"><i class="mdi mdi-calendar font-16 vm"></i> {Helpers.getDateAndTimeInDisplayFormat(event.startDate)}</h6>
                                        <h6 className="font-normal m-t-0 m-b-5  v-a"><i class="material-icons  v-a font-14" title="location">place</i> {cityName}, {countryName}</h6>
                                        <h6 className="font-normal m-t-0 m-b-5">{!Helpers.isEmpty(event.type) ? Helpers.camelize(event.type):''} Event - Created by <span className="text-info">{ownerName}</span></h6>
                                 </div>
                            </div>
                            <div className="clearfix floar-right">
                                <hr className="greyLine"/>
                                {event.rsvpLink ? <a href={event.rsvpLink} target="_blank"  className="float-right font-14"><button className="btn btn-dark m-l-10">RSVP</button></a> : ""}
                                <Interest id={event.id} type={"EVENT"} interested={event._isInterested} mode="btn" masterClassName="float-right font-14"/>
                                {this.state.auth?
                                    <SmartLink
                                    showEdit={true}
                                    ownerId={1}
                                    masterClassName="float-right font-14"
                                    pageFullUri={"/event-details/"+event.uri}>                                   
                                    </SmartLink>
                                    : ""
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>

                {/* card end */}
                <div className="card " id="details">
                     <div class="card-header text-left light-blue-header"><h5 class="m-b-0 m-t-0 text-white">Basic Details</h5></div>
                    <div className="card-body banner-small">
                        <div className="col-lg-12 p-0">
                            <div className="clearfix"></div>
                            <InfoText title="Category" text={categoryLabel} childClass="small-cardbox"/>
                            <InfoTextArray title="Industry" textArray={industry}  additionalClassName="text-info" childClass="small-cardbox"/>
                           
                            <InfoText text={address} title="Event Venue" childClass="small-cardbox"/>
                            <InfoDate
                                title="Date & Time"
                                preText="Start:"
                                date={event.startDate}
                                isDateTime={true}
                                childClass="small-cardbox"
                            />
                            <InfoDate
                                title=""
                                preText="End:"
                                date={event.endDate}
                                isDateTime={true}
                                childClass="small-cardbox"
                            />
                            <InfoText text={event.phoneNumber} title="Phone Number" childClass="small-cardbox"/>                             
                            <InfoText text={event.email} title="Email" childClass="small-cardbox"/>
                            <ExternalLink link={event.website} title="Website" childClass="small-cardbox"/> 

                        </div>
                     </div>
                  </div>      


                  <div className="card" id="summary">
                    <div class="card-header text-left light-blue-header"><h5 class="m-b-0 m-t-0 text-white">Summary</h5></div>
                    <div className="card-body">
                       <div className="col-lg-12 p-0">
                        <div className="text-info">
                                <div>
                                                  
                                    {/* <ReadMoreAndLess
                                        ref={this.readMore}
                                        className="read-more-content"
                                        charLimit={config.summaryLimit}
                                        readMoreText="Read more"
                                        readLessText="Read less"
                                    >
                                    {event.summary?event.summary:""}
                                    </ReadMoreAndLess>    */}

                                    {event.summary ? Helpers.newLineToBr(event.summary) : ""}
                                </div>
                               
                         </div>   
                         
                       </div>
                    </div>
                  </div>   

                  <div className="card" id="speakers">
                    <div class="card-header text-left light-blue-header"><h5 class="m-b-0 m-t-0 text-white">Speakers</h5></div>
                    <div className="card-body">
                                {event && event.speakers ? <p>{event.speakers}</p> : <p className="text-center">No data found!</p>}
                    </div>
                </div> 
                            
                  <div className="card" id="photos">
                    <div class="card-header text-left light-blue-header"><h5 class="m-b-0 m-t-0 text-white">Photos</h5></div>
                    <div className="card-body">
                         <Upload 
                            title = ""
                            description = "Upload all relevant photos here"
                            icon="plus"                           
                            items={event.images}
                            itemType="image"
                            itemKey="photos"
                            itemRepoType=""
                            itemChange={null}                            
                            isEditable={false}  
                        />
                    </div>
                </div> 


                <div className="card" id="videos">
                    <div class="card-header text-left light-blue-header"><h5 class="m-b-0 m-t-0 text-white">Videos</h5></div>
                    <div className="card-body">
                         <Videos 
                            title=""
                            description="Include your videos here"
                            icon="plus"
                            items={event.videos} 
                            itemKey="videos"
                            itemChange={null}
                            isEditable={false}                
                        />
                    </div>
                </div>

                {/* <div className="col-sm-12 col-xs-12 p-0">
                    <button className="btn btn-danger waves-effect waves-light m-r-10 ">Delete Event</button>
                    <button type="submit" className="btn btn-info waves-effect waves-light m-r-10 float-right">Submit for Moderation</button>
                </div> */}

            </div>
        </div>
      </div>
    )
  }
}

export default EventDetailsContent;