import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Sidemenu from '../../sidemenu/Sidemenu';
import OppContent from './OppContent'; 
import OpportunityRightpanel from './OpportunityRightpanel';
import Helper from "../../../shared/custom";
import UserFeedRightPanel from '../../homepage/UserFeedRightPanel';

class Details extends Component {
    constructor(props){
        super(props);
        this.state = {
            uri:null,
            newUri:null,
            location:props.location,    
        }

    }  
    componentDidMount(){
        let location = this.state.location.pathname;
        let uri = Helper.lastSegment(location);
        this.setState({"uri":uri});
        setTimeout(() => {
            window.scrollTo(0, 0);
          });
    }

    static getDerivedStateFromProps(nextProps, prevState){
        let newLocation = nextProps.location.pathname;
        let uri = Helper.lastSegment(newLocation);
        if(!Helper.isEmpty(uri)){
            return { newUri: uri};
        }
        else return null;
      }

      componentDidUpdate(){
          if(this.state.uri != this.state.newUri){
              this.setState({
                    uri :this.state.newUri
              })
          }
          setTimeout(() => {
            window.scrollTo(0, 0);
          });
      }

    render() {
        let uri = this.state.uri;
        return (
        <div>
            <div className="container-fluid">
                  <div className="row content-in m-t-80 p-t-10">
                    <div className="col-lg-3 col-xlg-3 col-md-3 searchMenu sideMenuCont">
                         <Sidemenu mode="opportunity" uri={uri} />
                    </div>
                    <div className="col-lg-6 col-xlg-6 col-md-6 centerDiv">
                        <OppContent uri={uri} />
                    </div>
                    <div className="col-lg-3 col-xlg-3 col-md-3 m-t-2 right-side">
                         <UserFeedRightPanel />
                    </div>
                </div>
            </div>
        </div>
        )
    }
}
export default withRouter(Details);
