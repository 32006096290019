import React, { Component } from 'react';
import config from '../../shared/config';
import classNames from 'classnames/bind';
import Axios from 'axios';
import MyPagination from "../ui/MyPagination"
import Helpers from '../../shared/custom';
import store from '../../store';
import { withRouter,Redirect } from 'react-router-dom';
import Follow from '../uiActions/Follow';
import StarRating from "../ui/StarRating";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import { LoadingOverlay, Loader } from 'react-overlay-loader';


let prevQueryString = "";
class CompanyBySector extends Component {
  constructor(){
    super();
    this.state ={
      companies:[],
      indexedcompanies:[],
      sectors:[],
      pageTitle:'',
      countryIds:[],
      localBusiness:undefined,
      searchCriteria:{},
      pageOfItems: [],
      allItems: [],
      activePage: 1,
      setPageContext:undefined,
      totalRecords: 0,
      sectorId:undefined,
      itemsCountPerPage:config.defaultPageSize,
      productId  : 0

    }
    
    this.handlePageChange = this.handlePageChange.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.setupPage = this.setupPage.bind(this);
  }

  handlePageChange(pageNumber) {
    this.setState({activePage: pageNumber}, function(){
        this.updateSearch();
    });
  }

  componentDidMount(){
    var storeState = store.getState();
    var auth = storeState.auth;
    this.setState({
      isLoggedIn:auth.isAuthenticated
    },()=>{
      if(!this.state.isLoggedIn){
        this.props.history.push("/companies")
      }
    })

   // let location  = this.props.location;
    let sectorId =  this.props.match.params.sector;

    

  }

  componentWillReceiveProps(props){

    var storeState = props;   
    if (!Helpers.isEmpty(storeState.business)){
        let storeBusiness = storeState.business;
        let storeSectorData = storeBusiness.sectors;
        if(!Helpers.isEmpty(storeSectorData)){
            this.setState({
                sectors : storeSectorData
            }, function(){
                this.setupPage();
            }) 
        }
        
    }
   

    if (!Helpers.isEmpty(props.locationIds)){
      let locationIds=""
     
      if(props && props.locationIds){
         locationIds = props.locationIds.join(",");
         this.setState({countryIds:locationIds}, function(){
         this.updateSearch();
        });
      }
    }else{
      this.setState({countryIds:[]});
    }

   

        
     let sector = this.props.match.params.sector;
     let {productId} = this.props.match.params;
     
     if (!Helpers.isEmpty(sector)){
       this.setState({searchCriteria:props.searchCriteria,setPageContext:props.setPageContext,"sectorId":sector}, function(){
        this.setupPage();
        this.updateSearch();
        if (this.state.setPageContext)
           this.state.setPageContext("companyBySector");
      });
     }else if(!Helpers.isEmpty(productId)){
      this.updateSearch();
     }
     else{
      return "";
       
     }
  }

  setupPage(){
      let sectorId = this.state.sectorId;
      if (!Helpers.isEmpty(this.state.sectors)){
        let sector = this.state.sectors[sectorId];
        if (!Helpers.isEmpty(sector)){
            this.setState({"pageTitle":sector.name});
        }
      }
  }


  updateSearch(){
    this.fetchCompanies();
  }

  fetchCompanies = () => {
    let qs = Helpers.generatePaginationQS({"page":this.state.activePage, "length":this.state.itemsCountPerPage, "extraParams":this.state.searchCriteria});
    qs.countryIds = this.state.countryIds;
    let sectorId = this.state.sectorId;
    let productId = this.props.match.params.productId;
    
    
    if(prevQueryString == JSON.stringify(qs)){
      return;
    }else{
      prevQueryString = JSON.stringify(qs);
    }
    if(Helpers.isEmpty(sectorId)){
        this.setState({companies:[]})
        
      }
     
this.setState(prevState =>({loading : true}))
      if(sectorId){
    Axios.get(
        Helpers.apiURL({
            uri:"companies/gics_sector/"+sectorId+"/list",  
            query:qs
        })
    )
    .then( (response) => {           
          let companies = response.data.result.aaData || [];
          let totalRecords = response.data.result.iTotalRecords || 0;
          this.setState({
            loading : false,
                  companies:companies,
                  totalRecords:totalRecords
                  });
       })
    .catch((err) => {
      this.setState(prevState =>({loading : false}))
    });
  }
  if(productId){
    
    Axios.get(
      Helpers.apiURL({
          uri:"companies/products/"+productId+"/list",  
          query:qs
      })
  )
  .then( (response) => {           
        let companies = response.data.result.aaData || [];
        let totalRecords = response.data.result.iTotalRecords || 0;
        this.setState({
          loading : false,
                companies:companies,
                totalRecords:totalRecords
                });
     })
  .catch((err) => {
    this.setState(prevState =>({loading : false}))
  });
  }

  }

componentWillUnmount(){
  prevQueryString = "";
  this.state = [];
}
  render() {

    let companies = this.state.companies;
    let sectorId = this.state.sectorId;
    let {loading} = this.state;

    return (

      <div>
     <LoadingOverlay>
           <Loader fullPage loading={loading} />
      </LoadingOverlay>

          <div className="card text-left bold-headers">
                  <div className="card-header text-left  p-0">
                    <div className="light-blue-header-half float-left col-md-6 header-div">All Companies</div>
                    <div className="float-left col-md-6 text-right">
                      <span className="font-12 verticle-single-text">{this.state.pageTitle}</span>
                    </div>
                </div>
                <div className="card-body blue-border">
                      <div className="row">
                        { (!Helpers.isEmpty(companies) && (companies.length > 0))?
                           companies.map((list,index )=> (
                             <div className="col-md-6 col-sm-12 m-t-10 companyCard" key={index}>
                                  <div className="bp-card bp-card-company">
                                  <Link to={"/company/"+list.page.uri} className="col-12 p-0">
                                    <div className="roundImgCont"><img src={list.page && list.page.image ? Helpers.dataPath(list.page.image) : Helpers.dataPath(config.defaultCompanyImage)} className="float-left col-md-4 p-0" /></div>
                                      <div className="col-md-8 float-left  p-0 text-left m-l-5">
                                          <h5 className="text-info font-14 m-0 m-t-5 "> {Helpers.truncate(list.page.name,15)}</h5>
                                          <p className="font-12 p-0 text-info font-normal m-b-0">{list.company && list.company.companyType ? list.company.companyType.name :""}</p>
                                          <p className="font-12 p-0 text-grey m-t-0  m-b-0">{list && list.company && list.company.primarySector ? list.company.primarySector.sector.name : (!Helpers.isEmpty(list.company.sectors)?list.company.sectors[0].sector.name:"")}</p>
                                      </div>
                                      <div className="font-bold col-md-1 float-right  p-0 investeraRank">
                                          87
                                      </div>
                                  </Link>
                                  
                                  
                                  <div className="clearfix"></div>
                                  <StarRating rating={list.company.rankingDetails && list.company.rankingDetails.ranking ? list.company.rankingDetails.ranking : 0} page={list.page}/>
                                  <div className="col-md-12 p-0 m-t-5 descDiv">
                                      <p className="text-left font-12 font-medium">
                                        {list.company.shortDescription ?  Helpers.truncate(list.company.shortDescription,config.defaultDescMaxLength) : Helpers.truncate(list.company.longDescription,config.defaultDescMaxLength)}
                                      </p>
                                  </div>
                                  <div className="col-md-12 m-t-10 font-14 m-t-20 p-0 clearfix">
                                    <Follow uri={list.page && list.page.uri ?list.page.uri:""} masterClassName={"like-comm btn-lil text-left float-right m-l-5"} type={"COMPANY"} />
                                    <Link to={"/graph?uri=" + list.page.uri + "&type=COMPANY" } title="Knowladge Graph" className="float-right m-l-5 m-t-2">
                                      <i className="material-icons font-24"> scatter_plot</i>
                                    </Link> 
                                    <Link to={"/company/"+list.page.uri}  className="float-right m-l-5 m-t-2"><i className="material-icons font-24">language</i></Link>
                                </div>
                                </div>
                              </div>
                      ))
                      :
                          <div className="text-center col-12">No Companies Found</div>
                      }
                    </div>

                  <MyPagination
                    activePage={this.state.activePage}
                    totalRecords={this.state.totalRecords}
                    onChange={this.handlePageChange}
                  />
                 </div> 
             </div>      
        
      </div>
    )
  }
}


export default withRouter(CompanyBySector)