import Axios from 'axios';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Select, { components } from 'react-select';
import config from '../../shared/config';
import Helpers from "../../shared/custom";
import CompanyPs from "../wigets/CompanyPs";

export default class StartupForm extends Component {

   
    constructor(props) {
        super(props);
        this.state = {
            loadedSectors  : false,
            sectors : [],
            industryGroups : [],
            selectedIndustryGroups : this.props.industryGroups ? this.props.industryGroups : [],
            industries : [],
            selectedIndustries : this.props.industry ? this.props.industry : [],
            subIndustries : [],
            selectedSubIndustries : this.props.subIndustry ? this.props.subIndustry : []


        }
    }
        
   



render() {
    let values = this.props.values;
    let {sectors,industryGroups,subIndustries,industries,industry} = this.props.values;
        return (
            <div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group  has-search">
                            <label className="control-label font-bold font-18">Year Founded</label>
                            <Select
                                className="font-14 col-md-12 p-0 m-0"
                                name="foundedYear"
                                options={values.yearOptions}
                                placeholder="Select founded year"
                                value={values.foundedYear}
                                onChange={this.props.onSelectChange}
                                isClearable={true}
                            />
                        </div>
                    </div>
                  
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label font-bold font-18">Sector</label>
                            <Select
                                className="font-14 col-md-12 p-0 m-0"
                                name="sector"
                                options={sectors}
                                placeholder="Select sector"
                                value={values.sector}
                                onChange={this.props.onSelectChange}
                                isClearable={true}
                                isMulti
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group has-search">
                            <label className="control-label font-bold font-18">Industry Groups</label>
                            <Select
                                className="font-14 col-md-12 p-0 m-0"
                                name="industryGroup"
                                options={industryGroups}
                                placeholder="Select industry group"
                                value={values.industryGroup}
                                onChange={this.props.onSelectChange}
                                isClearable={true}
                                isMulti
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label font-bold font-18">Industry</label>
                            <Select
                                className="font-14 col-md-12 p-0 m-0"
                                name="industry"
                                options={industries}
                                placeholder="Select industry"
                                value={industry}
                                onChange={this.props.onSelectChange}
                                isClearable={true}
                                isMulti
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group has-search">
                            <label className="control-label font-bold font-18">Sub Industry</label>
                            <Select
                                className="font-14 col-md-12 p-0 m-0"
                                name="subIndustry"
                                options={subIndustries}
                                placeholder="Select sub industry"
                                value={values.subIndustry}
                                onChange={this.props.onSelectChange}
                                isClearable={true}
                                isMulti
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label font-bold font-18">Products/Services</label>
                            <CompanyPs
                                allowNew={'no'}
                                onChange={event => this.props.companyPslChange(event)}
                                defaultValue={values.companyPsl ? values.companyPsl.name : null}
                                defaultSelected={!Helpers.isEmpty(values.companyPsl) ? values.companyPsl : []}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    {/* <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label font-bold font-18">Company Size</label>
                            <Select
                                className="font-14 col-md-12 p-0 m-0"
                                name="companySize"
                                options={values.companySizeOptions}
                                placeholder="Select company size"
                                value={values.companySize}
                                onChange={this.props.onSelectChange}
                                isClearable={true}
                            />
                        </div>
                    </div> */}

                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label font-bold font-18">Revenue Generating</label>
                            <Select
                                className="font-14 col-md-12 p-0 m-0"
                                name="revenueGenerating"
                                options={values.booleanOptions}
                                placeholder="Select an option"
                                value={values.revenueGenerating}
                                onChange={this.props.onSelectChange}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label font-bold font-18">Currently Raising Fund</label>
                            <Select
                                className="font-14 col-md-12 p-0 m-0"
                                name="isRaisingFund"
                                options={values.booleanOptions}
                                placeholder="Select an option"
                                value={values.isRaisingFund}
                                onChange={this.props.onSelectChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label font-bold font-18">Current Fundraising Amount</label>
                            <Select
                                className="font-14 col-md-12 p-0 m-0"
                                name="fundRaisingAmount"
                                options={values.fundRaisingAmountOptions}
                                placeholder="Select amount"
                                value={values.fundRaisingAmount}
                                onChange={this.props.onSelectChange}
                                isClearable={true}
                            />
                        </div>
                    </div>
                    {/* <div className="col-md-6">
                        <div className="form-group  has-search">
                            <label className="control-label font-bold font-18">Rating</label>
                            <Select
                                className="bold-options font-14 col-md-12 p-0 m-0"
                                name="rating"
                                options={config.ratings}
                                placeholder="Select ratings"
                                value={values.ratings}
                                onChange={this.props.onSelectChange}
                                isClearable={true}
                                components={{ Option }}
                                styles={customStyles}
                            />
                        </div>
                    </div> */}

                    {/* <div className="col-md-6">
                    <div className="form-group  has-search">
                        <label className="control-label font-bold font-18">Product Service</label>
                        <Select                            
                            className="bold-options font-14 col-md-12 p-0 m-0"
                            name="psl"
                            options={values.productServices}
                            placeholder="Select product Service"
                            value={values.psl}
                            onChange={this.props.onSelectChange}
                            isClearable={true}
                            components={{Option}}
                            styles={customStyles}
                            isMulti={true}
                        />
                    </div>
                </div>  */}

                </div>
            </div>
        )
    }
}
