
import React, { Component } from 'react';
import config from '../../shared/config';
import classNames from 'classnames/bind';
import Axios from 'axios';
import MyPagination from "../ui/MyPagination"
import Helpers from '../../shared/custom';
import store from '../../store';
import { withRouter,Redirect } from 'react-router-dom';
import Interest from '../uiActions/Interest';



class EventsBySector extends Component {
  constructor(){
    super();
    this.state ={
      events:[],
      indexedEvents:[],
      sectors:[],
      pageTitle:'',
      localBusiness:undefined,
      searchCriteria:{},
      upcomingEvents:[],
      pageOfItems: [],
      allItems: [],
      activePage: 1,
      setPageContext:undefined,
      totalRecords: 0,
      sectorId:undefined,
      itemsCountPerPage:config.defaultPageSize
    }
    
    this.handlePageChange = this.handlePageChange.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.setupPage = this.setupPage.bind(this);
  }

  handlePageChange(pageNumber) {
    this.setState({activePage: pageNumber}, function(){
        this.updateSearch();
    });
  }

  componentDidMount(){
    var storeState = store.getState();
    var auth = storeState.auth;
    this.setState({
      isLoggedIn:auth.isAuthenticated
    },()=>{
      if(!this.state.isLoggedIn){
        this.props.history.push("/events")
      }
    })
  }

  componentWillReceiveProps(props){
    var storeState = props;   
    if (!Helpers.isEmpty(storeState.business)){
        let storeBusiness = storeState.business;
        let storeSectorData = storeBusiness.sectors;
        if(!Helpers.isEmpty(storeSectorData)){
            this.setState({
                sectors : storeSectorData
            }, function(){
                
            }) 
        }
        
    }
      

   

    let locationIds="";
    let filterDates="";
    if(props && props.locationIds){
      locationIds = props.locationIds.join(",");
    }
    if(props && props.filterDates){
      filterDates = props.filterDates;
    }

     let sector = this.props.match.params.sector;
     if (Helpers.isEmpty(sector))
         return "";
       this.setState({searchCriteria:props.searchCriteria,setPageContext:props.setPageContext,"sectorId":sector}, function(){
        this.state.searchCriteria.countryIds = locationIds;
        this.state.searchCriteria.startDate = filterDates.from;
        this.state.searchCriteria.endDate = filterDates.to;
         this.setupPage();
         this.updateSearch();
         if (this.state.setPageContext)
            this.state.setPageContext("eventsBySector");
       });
  }

  setupPage(){
      let sectorId = this.state.sectorId;
      if (!Helpers.isEmpty(this.state.sectors)){
   
        let sector = this.state.sectors[sectorId];
        this.setState({"pageTitle":sector.name});
      }
  }


  updateSearch(){
    this.fetchEvents();
  }

  fetchEvents () {
    let qs = Helpers.generatePaginationQS({"page":this.state.activePage, "length":this.state.itemsCountPerPage, "extraParams":this.state.searchCriteria});
 
    let sectorId = this.state.sectorId;

    if(Helpers.isEmpty(sectorId)){
        this.setState({events:[]})
        return
      }
    Axios.get(
        Helpers.apiURL({
            uri:"events/sector/"+sectorId,  
            query:qs
        })
    )
    .then( (response) => {           
          let events = response.data.result.aaData || [];
          let totalRecords = response.data.result.iTotalRecords || 0;
          this.setState({
                  events:events,
                  totalRecords:totalRecords
                  });
       })
    .catch((err) => {
        
    });
  }


  render() {

    return (
      <div>
          <div className="card text-left bold-headers">
                <div class="card-header text-left  p-0">
                    <div class="light-blue-header-half float-left col-md-6 header-div">All Events</div>
                    <div class="float-left col-md-6 text-right">
                      <span class="font-12 verticle-single-text">{this.state.pageTitle}</span>
                    </div>
                </div>
                <div className="card-body blue-border">
                      <div className="row">
                        { (!Helpers.isEmpty(this.state.events) && (this.state.events.length > 0))?
                          this.state.events.map((item,index )=> (
                             <div className="col-md-6 m-t-10" key={index}>
                              <div className="col-12 border full-height eventsCard">
                                  <div className="card-img-container">
                                    <img className="m-w" src={item.coverPhoto && item.coverPhoto.publicUri ? Helpers.dataPath(item.coverPhoto.publicUri) : Helpers.dataPath(config.defaultEventIcon)}  alt="Image"/>
                                  </div>
                                   <div className="col-md-12 font-14  p-0 event-top">
                                      <div className="col-md-12 float-left text-left p-0">
                                        <h5 className="font-bold m-0 m-l-5 p-0 smallText">
                                         <a href={'/event/'+item.uri}>
                                             {item.name}
                                          </a>
                                        </h5>
                                        <span className="font-10 clearfix m-0">
                                        <i class="mdi mdi-calendar"></i> {Helpers.getDateInFormat(item.startDate, "dd, D MMM")} - {" "}<br/>
                                        <i class="mdi mdi-map-marker"></i> {item.city.name}, {item.country.name}
                                        </span>
                                        {/* <span className="font-10"><i class="mdi mdi-star"></i> {interestCount} Interested</span> */}
                                      </div>
                                      
                                      <div className="col-md-12 m-t-10 font-14 m-t-20 p-0 clearfix">
                                            <div className="like-comm text-left"> 
                                                <Interest id={item.id} type={"EVENT"} interested={item._isIntrested} masterClassName="float-right m-t-5 m-l-5 font-12"/>
                                              </div>
                                                <a href="" className="float-right m-l-5 m-t-2"><i class="material-icons font-24">language</i></a>
                                            </div>
                                    </div>
                                </div>
                            </div>
                      ))
                      :
                      <div className="text-center col-12">No Events Found</div>
                    }
                  </div>

                  <MyPagination
                    activePage={this.state.activePage}
                    totalRecords={this.state.totalRecords}
                    onChange={this.handlePageChange}
                  />
                 </div> 
             </div>      
        
      </div>
    )
  }
}


export default withRouter(EventsBySector)