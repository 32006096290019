import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import Helper from "../../shared/custom";
import config from "../../shared/config";
import store from "../../store";

import EditText from "../ui/EditText";
import CompanyProgresBar from "../ui/CompanyProgressBar";
import EditTextArea from "../ui/EditTextArea";
import EditSelect from "../ui/EditSelect";
import EditMultiSelect from "../ui/EditMultiSelect";
import DatePicker from "react-datepicker";

import InfoText from "../ui/InfoText";
import InfoTextArray from "../ui/InfoTextArray";
import InfoDate from "../ui/InfoDate";
import ExternalLink from "../ui/ExternalLink";

import ReadMoreAndLess from "react-read-more-less";
import Stockgraph from "../profile/Stockgraph";
import ManageAcquisition from "./ManageAcquisition";
import CreatePeople from "./CreatePeople";
import ApiHelper from "../../shared/ApiHelper";
import countries from "../../shared/country";
import InfoAddress from "../ui/InfoAddress";
import Axios from "axios";
import currencies from "../../shared/currency";
import STR from "../../shared/strings";
import ViewFunding from "../financials/ViewFunding";

import DeletePage from "../wigets/DeletePage";
import YesNoRadio from "../ui/YesNoRadio";
import InfoYesNo from "../ui/InfoYesNo";
import ViewInvestments from "../financials/ViewInvestments";
import ViewInvestors from "../financials/ViewInvestors";
import areas from '../../shared/areas';
import ObjectsSearch from '../wigets/ObjectsSearch';

import Helpers from '../../shared/custom';
import CustomArray from "../ui/CustomArray";

import DocEdit from '../wigets/DocEdit';

import MetaEdit from '../ui/MetaEdit';
import Mobile from "../ui/Mobile";

import packages from '../../shared/packageConfig';
import countryCode from '../../shared/countryCode';
import CompanyPs from "../wigets/CompanyPs";


class CompanyDetailsContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editbanner: true,
      editBasicDetails: false,
      isEditable: true,
      mode: "create",
      loading: false,
      fromCreate: false,

      formActive: {
        banner: false,
        overview: false,
        contact: false,
        contactPerson: false,
        ipo: false,
        startup: false
      },
      showKrpin: false,
      showProgress: false,
      progress: "0%",

      selectedTags: [],
      business: null,
      metaList: null,
      cityList: [],
      addressCityList: [],

      // sectorOptions: [],
      // industryOptions: [],
      // sectorAllOptions:[],
      // industryAllOptions: [],
      sectors: [],
      industryGroups: [],
      industries: [],
      subIndustries: [],

      typeOptions: [],
      companySizeOptions: [],
      companyStatusOptions: [],
      companyBusinessStatusOptions: [],
      areasServedOptions: [],
      countryOptions: [],
      cityOptions: [],
      addressCityOptions: [],
      newCity: "",
      businessTypeOptions: [],
      developmentStageOptions: [],
      fundRaisingAmountOptions: [],
      previousAmountRaisedOptions: [],
      previousFundSourceOptions: [],

      lastPageState: null,
      companyDetails: null,

      showForm: false,
      id: undefined,
      uri: null,
      pageDto: {
        id: null
      },

      isStartup: false,
      acquisitions: [],
      areasServed: [],
      companyCatagories: [],

      bods: [],
      keyPeople: [],
      individuals: [],

      // industries: [],
      // sector: [],
      regNo: "",
      kraPin: "",
      category: {},
      errors: {},
      psl: [],
      psText: "",
      businessStatus: null,
      companySize: null,
      companyStatus: null,
      companyType: null,

      email: undefined,
      employeeCount: undefined,
      employeeCountAsOfDate: null,

      grossProfit: undefined,
      netIncome: undefined,
      revenue: undefined,
      financialCurrency: null,

      financingStatus: undefined,
      formerName: undefined,

      ipoDate: undefined,
      exchange: undefined,
      moneyRaised: undefined,
      sharePrice: undefined,
      stockSymbol: undefined,
      ipoCurrency: null,
      valuation: null,
      ipoStatus: undefined,

      lastFinancingDate: undefined,
      legalName: undefined,
      formerName: undefined,
      otherName: undefined,
      longDescription: "",
      shortDescription: "",

      otherName: undefined,

      ownershipStatus: undefined,

      city: undefined,
      country: undefined,
      facebook: undefined,
      twitter: undefined,
      linkedIn: undefined,
      website: undefined,

      name: undefined,
      image: undefined,

      parentCompany: undefined,
      phone: undefined,

      addressLine1: undefined,
      addressLine2: undefined,
      addressLine3: undefined,
      stateOrProvince: undefined,
      pobox: undefined,
      zip: undefined,
      addressCity: undefined,
      addressCountry: undefined,

      //startup fields
      businessType: null,
      businessTypes: [],
      developmentStage: null,
      isRevenueGenerating: null,
      isWithTwoYrProjection: null,
      hasBusinessPlan: null,
      fundRaisingAmount: null,
      previousAmountRaised: null,
      previousFundSource: null,
      isRaisingFunds: null,

      profilePic: undefined,
      profilePicUrl: undefined,
      uploadedFile: [],
      metaDatam: [],
      productServicesOptions: [],
      mobile: "",
      mobileCode: "",
      mobileCn: "",
      phoneCode: "",
      phoneCn: "",
      phone: "",

    };

    this.editbanner = this.editbanner.bind(this);
    this.triggerProfileImageUpload = this.triggerProfileImageUpload.bind(this);
    this.uploadProfileImage = this.uploadProfileImage.bind(this);
    this.editBasicDetails = this.editBasicDetails.bind(this);
    this.showForm = this.showForm.bind(this);
    this.hideForm = this.hideForm.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.setMetaList = this.setMetaList.bind(this);
    this.setCityList = this.setCityList.bind(this);
    this.setAddressCityList = this.setAddressCityList.bind(this);
    this.handleFieldValueChange = this.handleFieldValueChange.bind(this);
    this.handleBooleanValueChange = this.handleBooleanValueChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleFundingRoundChange = this.handleFundingRoundChange.bind(this);

    this.handleAsOfDateChange = this.handleAsOfDateChange.bind(this);
    this.handleIpoDateChange = this.handleIpoDateChange.bind(this);
    this.handleLastFinancingDateChange = this.handleLastFinancingDateChange.bind(
      this
    );
  }

  showForm(e) {
    let type = e.target.dataset.type ? e.target.dataset.type : null;
    if (type) {
      let formActive = this.state.formActive;
      formActive[type] = true;
      this.setState({ formActive: formActive });
    }
  }

  hideForm(e) {
    let type = e.target.dataset.type ? e.target.dataset.type : null;
    if (type) {
      let formActive = this.state.formActive;
      formActive[type] = false;
      this.setState({ formActive: formActive });
    }
  }

  uploadedFile = (files) => {
    this.setState({
      uploadedFile: files
    })
  }

  componentDidMount() {
    // let sectorOptions = [];
    // let industryOptions = [];


    var storeState = store.getState();
    this.setState({
      isOwner: storeState && storeState.auth && storeState.auth.user && storeState.auth.user.adminOwner ? storeState.auth.user.adminOwner : false
    })

    let companyCatagories = config.companyCatagories;
    this.setState({
      areasServedOptions: areasServedOptions,
      companyCatagories: companyCatagories
    });

    let fromCreate = Helper.getQueryParameter("create") ? Helper.getQueryParameter("create") : false;
    this.setState({ fromCreate: fromCreate })

    let typeOptions = [];
    let areasServedOptions = Helper.makeSelectOptions(areas);
    let ownershipStatusOptions = [];
    let companySizeOptions = [];
    let companyBusinessStatusOptions = [];
    let companyStatusOptions = [];
    let countryOptions = Helper.makeSelectOptions(countries);
    let cityOptions = [];

    let businessTypeOptions = [];
    let developmentStageOptions = [];
    let fundRaisingAmountOptions = [];
    let previousAmountRaisedOptions = [];
    let previousFundSourceOptions = [];
    let productServicesOptions = []

    let isEditable = true;

    this.setState({
      metaList: this.props.metaList,
      business: this.props.business,
      isEditable: isEditable,
      // sectorOptions: sectorOptions,
      // industryOptions: industryOptions,
      //industryClassification:industryClassification,
      typeOptions: typeOptions,
      areasServedOptions: areasServedOptions,
      ownershipStatusOptions: ownershipStatusOptions,
      productServicesOptions: productServicesOptions,
      companySizeOptions: companySizeOptions,
      companyStatusOptions: companyStatusOptions,
      companyBusinessStatusOptions: companyBusinessStatusOptions,
      countryOptions: countryOptions,
      cityOptions: cityOptions,

      businessTypeOptions: businessTypeOptions,
      developmentStageOptions: developmentStageOptions,
      fundRaisingAmountOptions: fundRaisingAmountOptions,
      previousAmountRaisedOptions: previousAmountRaisedOptions,
      previousFundSourceOptions: previousFundSourceOptions
    });


    
  

    //Added as a workaround in order to force the Acquisitions to
    setTimeout(() => {
      this.setState({
        counter: ++this.state.counter,
        unchangingProp: this.state.unchangingProp ? false : true
      });
    }, 1000);
  }

  handleTagsChange = (event) => {
    this.setState({ selectedTags: event }, () => {
    });
  }

  onChangeMeta = (name, slug) => {
    this.setState(prevState => {
      prevState[name] = slug;
      return prevState;
    })
  }

  fetchMeta = ()=>{
    Axios.get(
      Helpers.apiURL({
          uri:"meta/list/COS",
        })
    )
    .then( (response) => {
        let pbOption = response.data.result || {};
        this.setState({
          ownershipStatusOptions:Helpers.makeSelectOptions(pbOption)
        })
        
    })
    .catch((err) => {
       
    });
  }

  componentWillReceiveProps(nextProps) {
    // let sectorOptions = [];
    // let industryOptions = [];
    let sectorAllOptions = []
    let industryAllOptions = [];
    let typeOptions = [];
    //let areasServedOptions = [];
    let ownershipStatusOptions = [];
    let companySizeOptions = [];
    let companyBusinessStatusOptions = [];
    let companyStatusOptions = [];

    let businessTypeOptions = [];
    let developmentStageOptions = [];
    let fundRaisingAmountOptions = [];
    let previousAmountRaisedOptions = [];
    let previousFundSourceOptions = [];
    let productServicesOptions = [];

    let metaList = nextProps.metaList;
    let business = nextProps.business;

    this.fetchMeta()


    if (!Helper.isEmpty(metaList)) {
      typeOptions = Helper.makeSelectOptions(metaList.CTU);
      companySizeOptions = Helper.makeSelectOptions(metaList.CSI);
      companyBusinessStatusOptions = Helper.makeSelectOptions(metaList.CBS);
      companyStatusOptions = Helper.makeSelectOptions(metaList.CST);
     // ownershipStatusOptions = Helper.makeSelectOptions(metaList.COS);
      productServicesOptions = Helper.makeSelectOptions(metaList.PSL);
      businessTypeOptions = Helper.makeSelectOptions(metaList.BTY);
      developmentStageOptions = Helper.makeSelectOptions(metaList.DES);
      fundRaisingAmountOptions = Helper.makeSelectOptions(metaList.FRA);
      previousAmountRaisedOptions = Helper.makeSelectOptions(metaList.PAR);
      previousFundSourceOptions = Helper.makeSelectOptions(metaList.PFS);
    }

    // if (!Helper.isEmpty(business)) {
    //   sectorOptions = Helper.makeSelectOptions(business.sectorsArray);
    //   industryOptions = Helper.makeSelectOptions(business.industriesArray);
    //   industryAllOptions = business.industriesArray;
    //   sectorAllOptions = business.sectorsArray;
    // }

    let lastPageState = nextProps.lastPageState;
    let companyDetails = nextProps.companyDetails;
    let isStartup = false;

    if (lastPageState) {
      let type = lastPageState.type;
      if (type.name == config.startupString) {
        isStartup = true;
      }

      this.setState({
        showForm: true,
        isStartup: isStartup,
        mode: "create",
        name: lastPageState.name,
        legalName: lastPageState.legalName,
        formerName: lastPageState.formerName,
        otherName: lastPageState.otherName,
        parentCompany: lastPageState.parentCompany,
        foundedDate: lastPageState.foundedDate,
        areasServed: lastPageState.areasServed,
        ownershipStatus: lastPageState.ownershipStatus,
        companySize: lastPageState.companySize,
        headquartersCity: lastPageState.headquartersCity,
        headquartersCountry: lastPageState.headquartersCountry,
        // sector: lastPageState.sector,
        // industries: lastPageState.industries,
        type: Helper.makeSelectOption(lastPageState.type),
        // sectorOptions: sectorOptions,
        // sectorAllOptions:sectorAllOptions,
        //industryOptions: industryOptions,
        //industryAllOptions: industryAllOptions,
        typeOptions: typeOptions,
       // ownershipStatusOptions: ownershipStatusOptions,
        productServicesOptions: productServicesOptions,
        //  areasServedOptions: areasServedOptions,
        companySizeOptions: companySizeOptions,
        companyBusinessStatusOptions: companyBusinessStatusOptions,
        companyStatusOptions: companyStatusOptions,
        companyDetails: companyDetails,
        businessTypeOptions: businessTypeOptions,
        developmentStageOptions: developmentStageOptions,
        fundRaisingAmountOptions: fundRaisingAmountOptions,
        previousAmountRaisedOptions: previousAmountRaisedOptions,
        previousFundSourceOptions: previousFundSourceOptions,
        counter: ++this.state.counter,
        unchangingProp: this.state.unchangingProp ? false : true
      }, () => {
        if (!Helper.isEmpty(this.state.addressCountry))
          ApiHelper.getCities(this.state.addressCountry.value, (list) => { this.setAddressCityList(list); });
        if (!Helper.isEmpty(this.state.headquartersCountry))
          ApiHelper.getCities(this.state.headquartersCountry.value, (list) => { this.setCityList(list); });
      });
    } else if (companyDetails) {
      let type =
        companyDetails.pageDto && companyDetails.pageDto.pageTypes
          ? companyDetails.pageDto.pageTypes[0]
          : undefined;
      if (
        type &&
        type.subType &&
        type.subType.id == config.startupPageSubTypeId
      ) {
        isStartup = true;
      }

      let pageDto = companyDetails.pageDto;
      let addressDto = pageDto ? pageDto.addressDto : null;




      let ownershipStatus = companyDetails.ownershipStatus
        ? Helper.makeSelectOption(companyDetails.ownershipStatus)
        : null;
      let areasServed = [];
      if (companyDetails.areasServed) {
        companyDetails.areasServed.forEach(element => {
          areasServed.push(Helper.makeSelectOption(element));
        });
      }


      if (areasServed.some(e => e.label == 'Kenya')) {
        this.setState({
          showKrpin: true
        })
      } else {
        this.setState({
          showKrpin: false
        })
      }

      let businessTypes = [];
      if (companyDetails.startupDetails && companyDetails.startupDetails.businessTypes) {
        companyDetails.startupDetails.businessTypes.forEach(element => {
          businessTypes.push(Helper.makeSelectOption(element));
        });



      }

      // if (companyDetails){
      //   this.setState({
      //     selectedTags:Helper.groupIdNameToValueName(companyDetails.pageDto.tags)
      //   });
      // }

      let contactPersons = companyDetails && companyDetails.contactPersons ? companyDetails.contactPersons[0] : null;
      let pageContactNumbers = companyDetails && companyDetails.pageDto && companyDetails.pageDto.pageContactNumbers ? companyDetails.pageDto.pageContactNumbers[0] : null;

      this.setState({
        counter: ++this.state.counter,
        unchangingProp: this.state.unchangingProp ? false : true,
        uri: pageDto.uri,
        pageDto: {
          id: pageDto.id
        },
        companyDetails: companyDetails,
        mode: "update",
        isStartup: isStartup,
        acquisitions: [],
        bods: [],
        keyPeople: [],
        individuals: [],
        fundingRounds: [],
        mobile: contactPersons && contactPersons.mobile ? contactPersons.mobile : "",
        mobileCode: contactPersons && contactPersons.mobileCode ? contactPersons.mobileCode : "+254",
        mobileCn: contactPersons && contactPersons.mobileCode ? countryCode[contactPersons.mobileCode] : "KE",
        contactName: contactPersons && contactPersons.firstname ? contactPersons.firstname : "",
        contactEmail: contactPersons && contactPersons.email ? contactPersons.email : "",
        phoneCode: pageContactNumbers && pageContactNumbers.code ? pageContactNumbers.code : "+254",
        phoneCn: pageContactNumbers && pageContactNumbers.code ? countryCode[pageContactNumbers.code] : "KE",
        phone: pageContactNumbers && pageContactNumbers.number ? pageContactNumbers.number : "",
        contactId: contactPersons && contactPersons.id ? contactPersons.id : null,
        kraPin: companyDetails && companyDetails.kraPin ? companyDetails.kraPin : "",
        regNo: companyDetails && companyDetails.regNo ? companyDetails.regNo : "",
        natureOfBusiness: companyDetails && companyDetails.natureOfBusiness ? companyDetails.natureOfBusiness : "",
        psl: companyDetails && companyDetails.psl ? companyDetails.psl.map((item => { return { id: item.id, name: item.name } })) : [],
        category: companyDetails && companyDetails.category ? companyDetails.category : null,

        areasServed: areasServed,
        ownershipStatus: ownershipStatus,
        sectors: companyDetails && companyDetails.industryClassification && companyDetails.industryClassification.sectors ? Helper.makeSelectOptions(companyDetails.industryClassification.sectors) : [],
        industries: companyDetails && companyDetails.industryClassification && companyDetails.industryClassification.industries ? Helper.makeSelectOptions(companyDetails.industryClassification.industries) : [],
        industryGroups: companyDetails && companyDetails.industryClassification && companyDetails.industryClassification.industryGroups ? Helper.makeSelectOptions(companyDetails.industryClassification.industryGroups) : [],
        subIndustries: companyDetails && companyDetails.industryClassification && companyDetails.industryClassification.subIndustries ? Helper.makeSelectOptions(companyDetails.industryClassification.subIndustries) : [],
        metaDatam: {
          sectors: companyDetails && companyDetails.industryClassification && companyDetails.industryClassification.sectors ? Helper.makeSelectOptions(companyDetails.industryClassification.sectors) : [],
          industries: companyDetails && companyDetails.industryClassification && companyDetails.industryClassification.industries ? Helper.makeSelectOptions(companyDetails.industryClassification.industries) : [],
          industryGroups: companyDetails && companyDetails.industryClassification && companyDetails.industryClassification.industryGroups ? Helper.makeSelectOptions(companyDetails.industryClassification.industryGroups) : [],
          subIndustries: companyDetails && companyDetails.industryClassification && companyDetails.industryClassification.subIndustries ? Helper.makeSelectOptions(companyDetails.industryClassification.subIndustries) : [],

        },
        businessStatus: null,
        companyStatus: null,
        companySize: Helper.makeSelectOption(companyDetails.companySize),
        companyType: companyDetails.companyType,
        type: Helper.makeSelectOption(companyDetails.companyType),

        email: pageDto.contactEmails[0], /////////////change this to support multiple
        employeeCount: companyDetails.employeeCount,
        employeeCountAsOfDate: companyDetails.employeeCountAsOfDate,
        // employeeCountAsOfDate: Helper.isValidDate(companyDetails.employeeCountAsOfDate)? Helper.getDateInInputFormat(
        //   companyDetails.employeeCountAsOfDate,
        //   true
        // ):"",
        employeeCountAsOfDate: companyDetails.employeeCountAsOfDate ? new Date(companyDetails.employeeCountAsOfDate) : null,

        grossProfit: companyDetails.financialData
          ? companyDetails.financialData.grossProfit
          : undefined,
        netIncome: companyDetails.financialData
          ? companyDetails.financialData.netIncome
          : undefined,
        revenue: companyDetails.financialData
          ? companyDetails.financialData.revenue
          : undefined,
        financialCurrency: companyDetails.financialData
          ? Helper.makeSelectOption(companyDetails.financialData.currency)
          : undefined,

        financingStatus: undefined,
        formerName: companyDetails.formerName
          ? companyDetails.formerName
          : undefined,

        ipoDate:
          companyDetails.ipoData && companyDetails.ipoData.date
            ? Helper.getDateInInputFormat(companyDetails.ipoData.date, true)
            : undefined,
        exchange:
          companyDetails.ipoData && companyDetails.ipoData.exchange
            ? companyDetails.ipoData.exchange
            : undefined,
        moneyRaised:
          companyDetails.ipoData && companyDetails.ipoData.moneyRaised
            ? companyDetails.ipoData.moneyRaised
            : undefined,
        sharePrice:
          companyDetails.ipoData && companyDetails.ipoData.sharePrice
            ? companyDetails.ipoData.sharePrice
            : undefined,
        stockSymbol:
          companyDetails.ipoData && companyDetails.ipoData.stockSymbol
            ? companyDetails.ipoData.stockSymbol
            : undefined,
        ipoCurrency:
          companyDetails.ipoData && companyDetails.ipoData.currency
            ? Helper.makeSelectOption(companyDetails.ipoData.currency)
            : undefined,
        valuation:
          companyDetails.ipoData && companyDetails.ipoData.valuation
            ? companyDetails.ipoData.valuation
            : undefined,
        ipoStatus:
          companyDetails.ipoData && companyDetails.ipoData.ipoStatus
            ? companyDetails.ipoData.ipoStatus
            : undefined,

        lastFinancingDate: undefined,

        legalName: companyDetails.legalName,
        otherName: companyDetails.otherName,
        formerName: companyDetails.formerName,
        longDescription: companyDetails.longDescription,
        shortDescription: companyDetails.shortDescription,
        otherName: companyDetails.otherName,
        foundedDate: companyDetails.foundedDate,

        // ownershipStatus : undefined,

        headquartersCity: Helper.makeSelectOption(pageDto.city),
        headquartersCountry: Helper.makeSelectOption(pageDto.country),
        facebook: pageDto.facebook,
        twitter: pageDto.twitter,
        linkedIn: pageDto.linkedIn,
        website: pageDto.website,

        name: pageDto.name,
        image: undefined,

        parentCompany: companyDetails.parentCompany,
        // phone: pageDto.contactNumbers[0], //change this to multiple

        addressLine1: addressDto ? addressDto.addressLine1 : undefined,
        addressLine2: addressDto ? addressDto.addressLine2 : undefined,
        addressLine3: addressDto ? addressDto.addressLine3 : undefined,
        stateOrProvince: addressDto ? addressDto.stateOrProvince : undefined,
        pobox: addressDto ? addressDto.poBox : undefined,
        zip: addressDto ? addressDto.zip : undefined,
        addressCity: addressDto
          ? Helper.makeSelectOption(addressDto.city)
          : undefined,
        addressCountry: addressDto
          ? Helper.makeSelectOption(addressDto.country)
          : undefined,

        profilePic: companyDetails.profilePic
          ? companyDetails.profilePic.uniqueId
          : undefined,
        profilePicUrl: companyDetails.profilePic
          ? Helper.viewFile(companyDetails.profilePic.uniqueId)
          : undefined,


        businessTypes: businessTypes,
        developmentStage:
          companyDetails.startupDetails &&
            companyDetails.startupDetails.developmentStage
            ? Helper.makeSelectOption(
              companyDetails.startupDetails.developmentStage
            )
            : null,
        isRevenueGenerating:
          companyDetails.startupDetails &&
            companyDetails.startupDetails.isRevenueGenerating
            ? companyDetails.startupDetails.isRevenueGenerating
            : undefined,
        isWithTwoYrProjection:
          companyDetails.startupDetails &&
            companyDetails.startupDetails.isWithTwoYrProjection
            ? companyDetails.startupDetails.isWithTwoYrProjection
            : undefined,
        hasBusinessPlan:
          companyDetails.startupDetails &&
            companyDetails.startupDetails.hasBusinessPlan
            ? companyDetails.startupDetails.hasBusinessPlan
            : undefined,
        fundRaisingAmount:
          companyDetails.startupDetails &&
            companyDetails.startupDetails.fundRaisingAmount
            ? Helper.makeSelectOption(
              companyDetails.startupDetails.fundRaisingAmount
            )
            : null,
        previousAmountRaised:
          companyDetails.startupDetails &&
            companyDetails.startupDetails.previousAmountRaised
            ? Helper.makeSelectOption(
              companyDetails.startupDetails.previousAmountRaised
            )
            : null,
        previousFundSource:
          companyDetails.startupDetails &&
            companyDetails.startupDetails.previousFundSource
            ? Helper.makeSelectOption(
              companyDetails.startupDetails.previousFundSource
            )
            : null,
        isRaisingFunds:
          companyDetails.startupDetails &&
            companyDetails.startupDetails.isRaisingFunds
            ? companyDetails.startupDetails.isRaisingFunds
            : undefined,

        progress: companyDetails.profileCompletness
          ? Math.ceil(companyDetails.profileCompletness)
          : 0,
        showProgress: !Helper.isEmpty(companyDetails.profileCompletness) ? true : false,

        // sectorOptions: sectorOptions,
        // industryOptions: industryOptions,
        industryAllOptions: industryAllOptions,
        typeOptions: typeOptions,
        //areasServedOptions: areasServedOptions,
        //ownershipStatusOptions: ownershipStatusOptions,
        productServicesOptions: productServicesOptions,
        companySizeOptions: companySizeOptions,
        businessTypeOptions: businessTypeOptions,
        developmentStageOptions: developmentStageOptions,
        fundRaisingAmountOptions: fundRaisingAmountOptions,
        previousAmountRaisedOptions: previousAmountRaisedOptions,
        previousFundSourceOptions: previousFundSourceOptions
      }, () => {
        if (!Helper.isEmpty(this.state.addressCountry))
          ApiHelper.getCities(this.state.addressCountry.value, (list) => { this.setAddressCityList(list); });
        if (!Helper.isEmpty(this.state.headquartersCountry))
          ApiHelper.getCities(this.state.headquartersCountry.value, (list) => { this.setCityList(list); });

      });
    }
  }

  setMetaList(metaList) {
    let typeOptions = Helper.makeSelectOptions(metaList.CTU);
    let companySizeOptions = Helper.makeSelectOptions(metaList.CSI);

    this.setState({
      typeOptions: typeOptions,
      companySizeOptions: companySizeOptions
    });
  }

  setCityList(cityList) {
    let cityOptions = Helper.makeSelectOptions(cityList);
    this.setState({
      cityList: cityList,
      cityOptions:  [...cityOptions, { value: 'other', label: 'Other' }]
    });
  }

  setAddressCityList(cityList) {
    let cityOptions = Helper.makeSelectOptions(cityList);
    this.setState({
      addressCityList: cityList,
      addressCityOptions: [...cityOptions, { value: 'other', label: 'Other' }]
    });
  }

  triggerProfileImageUpload(e) {
    this.refs.imgUploader.click();
  }

  editbanner() {
    this.setState({
      editbanner: this.state.editbanner ? false : true
    });
  }

  editBasicDetails() {
    this.setState({
      editBasicDetails: this.state.editBasicDetails ? false : true
    });
  }

  validateForm() {
    // let isValid = true;
    // let message = [];
    //   var mandatoryFields = [
    //   {name: 'name', msg: "ENTER_COMPANY_NAME"}, 
    //   {name: 'profilePic', msg: 'PROFILE_IMAGE_REQUIRED'}, 
    //   {name: 'headquartersCountry', msg: 'CHOOSE_HQ_COUNTRY'},
    //   {name: 'shortDescription', msg: 'SHORT_DESCRIPTION_REQUIRED'},
    //   {name: 'headquartersCity', msg: 'CHOOSE_HQ_CITY'},
    //   {name: 'longDescription', msg: 'LONG_DESCRIPTION_REQUIRED'},
    //   {name: 'ownershipStatus', msg: 'OWNERSHIP_REQUIRED'},
    //   // {name: 'sectors', msg: 'SECTOR_REQUIRED'},
    //   // {name: 'industries', msg: 'INDUSTRY_REQUIRED'},
    //   {name: 'legalName', msg: 'ENTER_LEGAL_NAME'},
    //   {name: 'foundedDate', msg: 'ENTER_FOUNDED_YEAR'},
    //   {name: 'companySize', msg: 'CHOOSE_COMPANY_SIZE'},
    //   {name: 'sectors', msg: 'Sector is mandatory'},
    //   {name: 'industryGroups', msg: 'Industries Group is mandatory'},
    //   {name: 'industries', msg: 'Industry is mandatory'},
    //   {name: 'subIndustries', msg: 'Sub-Industries is mandatory'},

    // ];

    // mandatoryFields.map(obj =>{ 
    //   if (Helper.isEmpty(this.state[obj.name])) {
    //     isValid = false;
    //     message.push(obj.msg);
    //   }
    // });



    // if (Helper.isEmpty(this.state.areasServed)) {
    //   isValid = false;
    //   message.push("ENTER_SERVED_AREA");
    // }

    // if (message.length > 0) {
    //   //push basic field errors
    //   Helper.pushTError(message);
    //   return isValid;
    // }

    // if (Helper.isEmpty(this.state.addressLine1)) {
    //   isValid = false;
    //   message.push("ENTER_COMPANY_ADDRESS");
    // }

    // if (Helper.isEmpty(this.state.addressCountry)) {
    //   isValid = false;
    //   message.push("ENTER_COMPANY_ADDRESS_COUNTRY");
    // }

    // if (Helper.isEmpty(this.state.addressCity)) {
    //   isValid = false;
    //   message.push("ENTER_COMPANY_ADDRESS_CITY");
    // }

    // if (Helper.isEmpty(this.state.phone)) {
    //   isValid = false;
    //   message.push("ENTER_COMPANY_PHONE_NUMBER");
    // }

    // if (Helper.isEmpty(this.state.email)) {
    //   isValid = false;
    //   message.push("ENTER_COMPANY_EMAIL");
    // }

    // if (!Helper.isValidEmail(this.state.email)) {
    //   isValid = false;
    //   message.push("INVALID_EMAIL");
    // }

    // if (message.length > 0) {
    //   //push basic field errors
    //   Helper.pushTError(message);
    //   return isValid;
    // }

    //rest of validations will be based on company type

    let isValid = true;
    let fields = [
      "name",
      "regNo",
      "foundedDate",
      // "kraPin",  
      "ownershipStatus",
      "category",
      "sectors",
      "industryGroups",
      "subIndustries",
      "industries",
      "areasServed",
      "employeeCount",
      "natureOfBusiness",
      // "psText",
      "psl",
      "email",
      "addressLine1",
      "contactName",
      "contactEmail",
      "mobile",
      "mobileCode",
      "phone",
      "phoneCode",
      "addressCity",
      "addressCountry"
    ];

    if (this.state.showKrpin) {
      fields.push("kraPin")
    } else {
      Helpers.removeByValue(fields, "kraPin")
    }


    let errors = {}
    fields.forEach((field) => {
      if (Helpers.isEmpty(this.state[field])) {
        isValid = false;
        errors[field] = "This field is mandatory"
      } else {
        errors[field] = ""
      }
      if (Helpers.isEmpty(this.state[field] && field == "kraPin")) {
        isValid = false;
        errors["kraPin"] = "KRA pin number is mandatory for Kenyan companies"
      }
    })

    this.setState({
      errors: errors
    })


    return isValid;
  }

  fetchPsl = () => {
    Axios.get(
      Helpers.apiURL({
        uri: "api/company-psl/search ",
      })
    )
      .then((response) => {
        console.log("fetchPsl =>", response);

      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleSubmit() {
    let uploadedFile = this.state.uploadedFile;

    let documents = uploadedFile && uploadedFile.map((item) => ({ "uniqueId": item.uniqueId, "docType": { "id": item && item.docType && item.docType.id ? item.docType.id : null } }));
    this.setState({
      counter: ++this.state.counter,
      unchangingProp: this.state.unChangingProp ? false : true,
      uploadedFile: uploadedFile
    });

    if (!this.state.fromCreate) {
      let isValid = this.validateForm();
      console.log(this.validateForm());
      if (!isValid) {
        Helper.pushError("Please fill all the required fields!")
        return false;
      }
    }


    let pageDto = this.state.pageDto;
    let addressDto = pageDto && pageDto.addressDto ? pageDto.addressDto : null;

    let businessTypes = [];
    if (this.state.businessTypes) {
      this.state.businessTypes.forEach(area => {
        businessTypes.push({ id: area.value });
      });
    }
    console.log("b4 payload state.psl", this.state.psl);
    let payload = {
      profilePic: { uniqueId: this.state.profilePic },
      documents: documents,
      pageDto: {
        id: pageDto.id ? pageDto.id : null,
        primaryPageType: {
          type: { id: config.companyPageTypeId },
          subType: { id: null }
        },
        city: 
        this.state.headquartersCity && this.state.headquartersCity.value != "other" ? 
          {
            id: this.state.headquartersCity && this.state.headquartersCity.value ? this.state.headquartersCity.value : null
          }
          :
          {
              id: null,
              name:this.state.newheadquartersCity
          },
        country: { id: this.state.headquartersCountry && this.state.headquartersCountry.value ? this.state.headquartersCountry.value : null },
        facebook: this.state.facebook,
        image: null,
        linkedIn: this.state.linkedIn,
        name: this.state.name,
        twitter: this.state.twitter,
        website: this.state.website,
        contactEmails: [this.state.email],
        addressDto: {
          id: addressDto ? addressDto.id : null,
          addressLine1: this.state.addressLine1,
          addressLine2: this.state.addressLine2,
          addressLine3: this.state.addressLine3,
          stateOrProvince: this.state.stateOrProvince,
          poBox: this.state.pobox,
          zip: this.state.zip,
          city: this.state.addressCity.value != "other" ? 
          {
            id: this.state.addressCity && this.state.addressCity.value ? this.state.addressCity.value : null
          }
          :
          {
              id: null,
              name:this.state.newCity
          },
          country: {
            id: this.state.addressCountry && this.state.addressCountry.value
              ? this.state.addressCountry.value
              : null
          }
        },
      },
      contactPersons: [
        {
          id: this.state.contactId,
          firstname: this.state.contactName,
          email: this.state.contactEmail,
          mobile: this.state.mobile,
          mobileCode: this.state.mobileCode
        }
      ],
      employeeCount: this.state.employeeCount,
      natureOfBusiness: this.state.natureOfBusiness,
      //employeeCountAsOfDate: this.state.employeeCountAsOfDate,
      employeeCountAsOfDate: Helper.isValidDate(this.state.employeeCountAsOfDate) ? Helper.getDateInInputFormat(
        this.state.employeeCountAsOfDate
      ) : null,
      parentCompany: this.state.parentCompany,
      foundedDate: parseInt(this.state.foundedDate),
      regNo: this.state.regNo,
      kraPin: this.state.kraPin,
      mobile: this.state.mobile,
      mobileCode: this.state.mobileCode,
      category: this.state.category,
      contactNumbers: [{
        code: this.state.phoneCode,
        number: this.state.phone,
      }],
      shortDescription: this.state.shortDescription,
      longDescription: this.state.longDescription,
      otherName: this.state.otherName,
      formerName: this.state.formerName,
      legalName: this.state.legalName,
      companyType: { id: this.state.type && this.state.type.value ? this.state.type.value : null },
      companySize: { id: this.state.companySize && this.state.companySize.value ? this.state.companySize.value : null },

      financialData: {
        grossProfit: this.state.grossProfit,
        netIncome: this.state.netIncome,
        revenue: this.state.revenue,
        currency: !Helper.isEmpty(this.state.financialCurrency) ? { id: this.state.financialCurrency.value } : null,
      },
      psl: this.state.psl,
      startupDetails: {
        businessTypes: businessTypes,
        developmentStage: {
          id: this.state.developmentStage && this.state.developmentStage.value
            ? this.state.developmentStage.value
            : null
        },
        isRevenueGenerating: this.state.isRevenueGenerating,
        isWithTwoYrProjection: this.state.isWithTwoYrProjection,
        hasBusinessPlan: this.state.hasBusinessPlan,
        fundRaisingAmount: {
          id: this.state.fundRaisingAmount && this.state.fundRaisingAmount.value
            ? this.state.fundRaisingAmount.value
            : null
        },
        previousAmountRaised: {
          id: this.state.previousAmountRaised && this.state.previousAmountRaised.value
            ? this.state.previousAmountRaised.value
            : null
        },
        previousFundSource: {
          id: this.state.previousFundSource && this.state.previousFundSource.value
            ? this.state.previousFundSource.value
            : null
        },
        isRaisingFunds: this.state.isRaisingFunds
      },
      // sectors: [],
      // industries: [],
      areasServed: [],
      ownershipStatus: {
        id: this.state.ownershipStatus && this.state.ownershipStatus.value ? this.state.ownershipStatus.value : null
      }
      //"acquisitions":[],
    };

    if (this.state.mode == "create") {
      if (this.state.isStartup) {
        payload.pageDto.primaryPageType.subType = {
          id: config.startupPageSubTypeId
        };
      } else {
        payload.pageDto.primaryPageType.subType = null;
      }
    } else {
      payload.pageDto.primaryPageType.subType = pageDto.primaryPageType
        ? { id: pageDto.primaryPageType.subType }
        : null;
    }

    if (this.state.areasServed) {
      let areasServed = [];
      this.state.areasServed.forEach(area => {
        areasServed.push({ id: area.value });
      });
      payload.areasServed = areasServed;
    }

    let industryClassification = {};



    if (!Helper.isEmpty(this.state.sectors)) {
      industryClassification.sectors = this.state.sectors.map((x) => ({ "id": x.value }));
    }

    if (!Helper.isEmpty(this.state.industryGroups)) {
      industryClassification.industryGroups = this.state.industryGroups.map((x) => ({ "id": x.value }));
    }

    if (!Helper.isEmpty(this.state.industries)) {
      industryClassification.industries = this.state.industries.map((x) => ({ "id": x.value }));
    }

    if (!Helper.isEmpty(this.state.subIndustries)) {
      industryClassification.subIndustries = this.state.subIndustries.map((x) => ({ "id": x.value }));
    }

    if (!Helper.isEmpty(this.state.sectors) || !Helper.isEmpty(this.state.industryGroups) || !Helper.isEmpty(this.state.industries) || !Helper.isEmpty(this.state.subIndustries)) {
      payload.industryClassification = industryClassification;
    }


    payload.ipoData = {
      date: this.state.ipoDate
        ? Helper.getDateInInputFormat(this.state.ipoDate)
        : null,
      status: this.state.ipoStatus,
      exchange: this.state.exchange,
      currency: {
        id: this.state.ipoCurrency && this.state.ipoCurrency.value ? this.state.ipoCurrency.value : null
      },
      moneyRaised: this.state.moneyRaised,
      sharePrice: this.state.sharePrice,
      valuation: this.state.valuation,
      stockSymbol: this.state.stockSymbol
    };

    this.setState({
      loading: true
    });
    if (this.state.uri) {
      //payload.id = this.state.id;
      Axios.put(
        Helper.apiURL({
          uri: "company/:uri/edit",
          pathVar: { uri: this.state.uri }
        }),
        payload
      )
        .then(response => {
          this.setState({
            loading: false
          });
          Helper.pushTSuccess("COMPANY_SUBMIT_APPROVAL");
          if (this.state.fromCreate) {
            //  window.location.replace("/")
            this.props.history.push({ pathname: "/", notification: true });
          }
        })
        .catch(err => {
          this.setState({
            loading: false
          });
          Helper.pushError(err.response.data.message)
          // Helper.handleError(err.response);
        });
    } else {
      //new
      Axios.post(Helper.apiURL({ uri: "company/create" }), payload)
        .then(response => {

          Helper.pushTSuccess("COMPANY_SUBMIT_APPROVAL");
          let data = response.data.result;
          let uri = data.pageDto.uri ? data.pageDto.uri : null;

          this.props.history.push({ pathname: "/", notification: true });

          // if (uri) {
          //   setTimeout(function() {
          //     window.location = Helper.sitePath("company-details/" + uri);
          //   }, config.defaultWaitTime);
          // }
        })
        .catch(err => {
          this.setState({
            loading: false
          });
          Helper.pushError(err.response.data.message)
          // Helper.handleError(err.response);
        });
    }
  }

  handleFieldValueChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    if (name) {
      //this.setState({ [name]: Helpers.parseBoolean(value) });
      //common method used for all values - dont change to parseBoolean
      this.setState({ [name]: value });
    }
  }

  handleBooleanValueChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    if (name) {
      this.setState({ [name]: value });
    }
  }


  handleSelectChange(option, e) {
    let thisFieldName = e.name;
    switch (thisFieldName) {
      case "headquartersCountry":
        ApiHelper.getCities(option.value, this.setCityList);
        this.setState({
          [thisFieldName]: option,
          headquartersCity: false,
          cityList: [],
          cityOptions: []
        });
        break;
      case "addressCountry":
        ApiHelper.getCities(option.value, this.setAddressCityList);
        this.setState({
          [thisFieldName]: option,
          addressCity: false,
          addressCityList: [],
          addressCityOptions: []
        });
        break;
      case "sector":
        this.setState({ [thisFieldName]: option });
        this.setState({
          industryOptions: Helper.industriesBySector(this.state.industryAllOptions, option)
        });
        break;
      case "areasServed":
        if (option.some(e => e.label == 'Kenya')) {
          this.setState({
            showKrpin: true
          })
        } else {
          this.setState({
            showKrpin: false
          })
        }
        this.setState({ [thisFieldName]: option });
        break;
      default:
        this.setState({ [thisFieldName]: option });
        break;
    }
  }

  handleDateChange(date, e) {
    //Helper.getDateInInputFormat
    //this.setState({deadline:new Date(date)})
  }

  handleAsOfDateChange(date, e) {
    this.setState({ employeeCountAsOfDate: date });
  }

  handleIpoDateChange(date, e) {
    this.setState({ ipoDate: date });
  }

  handleLastFinancingDateChange(date, e) {
    this.setState({ ipoDate: date });
  }

  uploadProfileImage(e) {
    let file = e.target.files ? e.target.files[0] : null;
    if (file) {
      ApiHelper.uploadFile({
        repoType: "COMPANY_IMAGES",
        fileType: "IMAGE",
        file: file,
        callback: result => {
          this.setState({
            profilePic: result.uniqueId,
            profilePicUrl: Helper.viewFile(result.uniqueId)
          });
        }
      });
    }
  }

  handleFundingRoundChange(fundingRounds) {

  }

  mobileValues = (data) => {
    this.setState({
      phoneCode: data.ext,
      phone: data.mobile,
    })
  }

  mobileValuesContact = (data) => {
    this.setState({
      mobileCode: data.ext,
      mobile: data.mobile,
    })
  }

  handleObjectChange = (e) => {
    let psl = e;
    let existingPsl = this.state.psl;

    existingPsl = psl.map((item) => {
      let localPsl = existingPsl.filter((x) => item.name ? item.name == x.name : item == x.name);
      if (localPsl.length == 0) {
        let res = item.customOption ? {
          id: null,
          name: item.name,
        }
          : item;
        return res;
      } else {

        return localPsl[0]

      }
    })

    console.log("psl/event", existingPsl);
    this.setState({ psl: existingPsl });
  }


  render() {
    let companyUri = Helper.lastSegment(this.props.location.pathname);
    let mode = this.props.mode;
    let isCreation = this.state.mode == "create" ? true : false;
    let isStartup = this.state.isStartup;
    let areasServedOptions = this.state.areasServedOptions;

    let addressDto = {
      addressLine1: this.state.addressLine1,
      addressLine2: this.state.addressLine2,
      addressLine3: this.state.addressLine3,
      stateOrProvince: this.state.stateOrProvince,
      pobox: this.state.pobox,
      zip: this.state.zip,
      city: this.state.addressCity
        ? { name: this.state.addressCity.label }
        : null,
      country: this.state.addressCountry
        ? { name: this.state.addressCountry.label }
        : null
    };

    let isEditable = this.state.isEditable;

    let location = "";
    
    if (this.state.headquartersCity) {
      location = this.state.headquartersCity.label == 'Other' ? this.state.newheadquartersCity : this.state.headquartersCity.label;
    }
    if (this.state.headquartersCountry) {
      if (!Helper.isEmpty(location) && !Helper.isEmpty(this.state.headquartersCountry.label)) {
        location += " , " + this.state.headquartersCountry.label;
      } else {
        location += this.state.headquartersCountry.label;
      }

    }

    let profilePicUrl = this.state.profilePicUrl
      ? this.state.profilePicUrl
      : Helper.dataPath(config.defaultCompanyImage);
    let industries = Helper.selectOptionsToTextArray(this.state.industries);
    let sectors = Helper.selectOptionsToTextArray(this.state.sectors);
    let areasServed = Helper.selectOptionsToTextArray(this.state.areasServed);
    let businessTypes = Helper.selectOptionsToTextArray(
      this.state.businessTypes
    );
    let metaList = this.props.metaList;
    let titleList = metaList ? metaList.CTI : [];
    let docList = metaList ? metaList.COMDOC : [];
    let companyDetails = this.state.companyDetails;
    let isPublicCompany = companyDetails && companyDetails.companyType ? Helper.isPublicCompany(companyDetails.companyType) : null;

    let employeeCountAsOfDate = this.state.employeeCountAsOfDate;//Helper.isValidDate(this.state.employeeCountAsOfDate)? Helper.getDateInInputFormat(this.state.employeeCountAsOfDate):null;
    let showProgress = this.state.showProgress;

    let { metaDatam, productServicesOptions, showKrpin, fromCreate, errors, isOwner } = this.state;
    let phoneNumber = this.state.phone ? this.state.phoneCode + this.state.phone : "";
    let mobileNumber = this.state.mobileCode && this.state.mobile ? this.state.mobileCode + this.state.mobile : "";


    let sectorText = [];
    if (!Helpers.isEmpty(this.state.sectors)) {

      this.state.sectors.forEach((item) => {
        sectorText.push(item.label)
      })
    }



    return (
      <div>
        <div className="row bold-headers text-left p-b-30 autoOverflow">
          <div className="col-md-12 col-lg-12 col-xlg-12 p-0">
            <div className="card">
              <div className="card-body banner-small">
                <div className="col-lg-12 p-0 ">
                  <div className="col-lg-9 p-0">
                    {this.state.editbanner ? (
                      <div className="m-t-0">
                        <a
                          href="javascript:void(0)"
                          onClick={this.triggerProfileImageUpload}
                        >
                          {Helper.isEmpty(this.state.profilePicUrl) ? (
                            <div className="img-circle float-left newImg">
                              <i className="fa fa-camera text-black m-t-20 font-40 " />
                              <div className="font-10">Upload logo</div>
                            </div>
                          ) : (
                            <img
                              src={this.state.profilePicUrl}
                              className="float-left newImg"
                              width="100"
                            />
                          )}
                          <input
                            type="file"
                            id="userImg"
                            name="userImg"
                            style={{ display: "none" }}
                            ref="imgUploader"
                            onChange={this.uploadProfileImage}
                          />
                        </a>
                        <div className="card-body-right float-left m-l-20 m-t-10 text-left">
                          <div className="row">
                            <input
                              type="input"
                              name="name"
                              placeholder="Enter Company Name"
                              className="form-control isMandatoryFieldGroup"
                              value={this.state.name}
                              onChange={this.handleFieldValueChange}
                            />
                          </div>
                          {/* {this.state.type ? this.state.type.label : "type1"}
                          <h5 className="m-t-0 m-b-0">
                            {this.state.type ? this.state.type.label : "type"}
                          </h5> */}
                          <h6 className="card-custom-subtitle text-default font-14 m-t-0 m-b-0 font-medium">
                            {sectorText ? sectorText.join(",") : ""}
                          </h6>
                          <h6 className="card-custom-subtitle text-grey font-12  m-t-5">{location}</h6>
                        </div>
                      </div>
                    ) : (
                      <div className="m-t-0">
                        <img
                          src={profilePicUrl}
                          className="img-circle float-left"
                          width="100"
                        />

                        <div className="card-body-right float-left m-l-20 text-left">
                          <h2 className="card-title m-t-5 m-b-0">
                            {this.state.name}
                            <span className="text-info" />
                          </h2>
                          {/* <h5 className="m-t-0 m-b-0">
                            {this.state.type ? this.state.type.label : "--"}
                          </h5> */}
                          <h6 className="card-custom-subtitle text-default font-14 m-t-0 m-b-0 font-medium">
                            {sectorText ? sectorText.join(",") : ""}
                          </h6>
                          <h6 className="card-custom-subtitle text-grey font-12  m-t-5">{location}</h6>
                        </div>

                      </div>
                    )}
                  </div>
                  <div className="col-lg-3 float-right p-0 verticle-h-banner text-right">
                    {this.state.isEditable ? (
                      <div className="m-t-10">
                        <div>
                          {this.state.editbanner ? (
                            <a
                              onClick={this.editbanner}
                              className="text-danger"
                            >
                              <i className="fa fa-times" />
                            </a>
                          ) : (
                            <a onClick={this.editbanner} className="text-info">
                              Edit
                            </a>
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>

            {this.state.showProgress ? (
              <CompanyProgresBar progress={this.state.progress}></CompanyProgresBar>
            ) : (
              ""
            )}
            {!fromCreate &&
              <React.Fragment>

                <div className="card text-left bold-headers" id="overview">
                  <div className="card-body">
                    <div className="col-lg-12 p-0 m-h-30">
                      <div className="col-lg-6 p-0 float-left">
                        <h4>Overview</h4>
                      </div>
                      <div className="col-lg-3 float-right p-0  text-right">
                        {isEditable ? (
                          <div className="m-t-10">
                            <div>
                              {this.state.editBasicDetails ? (
                                <a
                                  onClick={this.editBasicDetails}
                                  className="text-danger"
                                >
                                  <i className="fa fa-times" />
                                </a>
                              ) : (
                                <a
                                  onClick={this.editBasicDetails}
                                  className="text-info"
                                >
                                  Edit
                                </a>
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div>
                      {isEditable && this.state.editBasicDetails ? (
                        <div className="clearfix">
                          <EditText
                            name="shortDescription"
                            title="Short Description"
                            //addLabelClass="isMandatoryLabel"
                            value={this.state.shortDescription}
                            onValueChange={this.handleFieldValueChange}
                          />
                          <EditTextArea
                            name="longDescription"
                            // addLabelClass="isMandatoryLabel"
                            title="Long Description"
                            value={this.state.longDescription}
                            onValueChange={this.handleFieldValueChange}
                          />

                          <EditSelect
                            name="ownershipStatus"
                            title="Ownership Status"
                            required={true}
                            errorMsg={errors["ownershipStatus"]}
                            // headingClass="isMandatoryLabel"
                            value={this.state.ownershipStatus}
                            options={this.state.ownershipStatusOptions}
                            onChange={this.handleSelectChange}
                            additionalClassName="ediableInline "
                          />
                          <EditMultiSelect
                            name="areasServed"
                            //titleClass="isMandatoryLabel"
                            title="Countries/Regions of Operation"
                            required={true}
                            errorMsg={errors["areasServed"]}
                            value={this.state.areasServed}
                            options={areasServedOptions}
                            onChange={this.handleSelectChange}
                            additionalClassName="ediableInline "
                          />

                          {/* <ObjectsSearch
                      
                        title="Categories"
                        inputClassName="ediableInline "
                        headingClass=""
                        onChange={event => this.handleTagsChange(event)}
                        name="tags"
                        multiple={true}
                        allowNew={true}
                        endPoint="categories"
                        defaultValue={this.state.selectedTags?this.state.selectedTags:null}
                        defaultSelected={!Helper.isEmpty(this.state.selectedTags) ? this.state.selectedTags : []}
                      /> */}

                          <EditSelect
                            name="category"
                            title="Category"
                            required={true}
                            errorMsg={errors["category"]}
                            //headingClass="isMandatoryLabel"
                            value={this.state.category}
                            options={this.state.companyCatagories}
                            onChange={this.handleSelectChange}
                            additionalClassName="ediableInline"
                          />

                          <EditText
                            name="legalName"
                            //addLabelClass="isMandatoryLabel"
                            title="Legal Name"
                            value={this.state.legalName}
                            onValueChange={this.handleFieldValueChange}
                          />

                          <EditText
                            name="otherName"
                            // addLabelClass="isMandatoryLabel"
                            title="Known as"
                            value={this.state.otherName}
                            onValueChange={this.handleFieldValueChange}
                          />

                          <EditText
                            name="formerName"
                            //addLabelClass="isMandatoryLabel"
                            title="Former Name"
                            value={this.state.formerName}
                            onValueChange={this.handleFieldValueChange}
                          />

                          <EditText
                            name="parentCompany"
                            title="Parent Company"
                            value={this.state.parentCompany}
                            onValueChange={this.handleFieldValueChange}
                          />


                          <MetaEdit options={["sectors", "industryGroups", "industries", "sub_industries"]} data={metaDatam} onChangeMeta={this.onChangeMeta} />

                          {/* <EditText
                        name="legalName"
                        title="Legal Name"
                        value={this.state.legalName}
                        onValueChange={this.handleFieldValueChange}
                      /> */}
                          <EditText
                            name="regNo"
                            title="Business Registration Number"
                            value={this.state.regNo}
                            placeholder={"Business registration number of your company"}
                            errorMsg={errors["regNo"]}
                            required={true}
                            onValueChange={this.handleFieldValueChange}
                          />
                          <EditText
                            name="foundedDate"
                            required={true}
                            errorMsg={errors["foundedDate"]}
                            //addLabelClass="isMandatoryLabel"
                            title="Year of Registration/Incorporation"
                            value={this.state.foundedDate}
                            onValueChange={this.handleFieldValueChange}
                          />
                          {showKrpin && <EditText
                            name="kraPin"
                            title="KRA Pin Number"
                            value={this.state.kraPin}
                            placeholder={"KRA pin number of your company"}
                            required={showKrpin ? true : false}
                            onValueChange={this.handleFieldValueChange}
                            errorMsg={errors["kraPin"]}
                          />}
                          <EditSelect
                            name="companySize"
                            //headingClass="isMandatoryLabel"
                            title="Choose company size"
                            value={this.state.companySize}
                            options={this.state.companySizeOptions}
                            onChange={this.handleSelectChange}
                            additionalClassName="ediableInline "
                          />

                          <EditSelect
                            title="Country of headquarters"
                            name="headquartersCountry"
                            // headingClass="isMandatoryLabel"
                            value={this.state.headquartersCountry}
                            options={this.state.countryOptions}
                            onChange={this.handleSelectChange}
                            additionalClassName="ediableInline "
                          />
                          <EditSelect
                            title="City of headquarters"
                            name="headquartersCity"
                            // headingClass="isMandatoryLabel"
                            value={this.state.headquartersCity}
                            options={this.state.cityOptions}
                            onChange={this.handleSelectChange}
                            additionalClassName="ediableInline mt-10"
                          />

                         {this.state.headquartersCity && this.state.headquartersCity.value == 'other' ?
                            <EditText
                              name="newheadquartersCity"
                              title=""
                              value={this.state.newheadquartersCity}
                              placeholder={"Enter city name"}
                              onValueChange={this.handleFieldValueChange}
                            />
                            :
                            ""
                          }

                          <EditText
                            name="employeeCount"
                            title="Number of Employees"
                            // addLabelClass="isMandatoryLabel"
                            required={true}
                            errorMsg={errors["employeeCount"]}
                            value={this.state.employeeCount}
                            onValueChange={this.handleFieldValueChange}
                          />
                          <EditTextArea
                            name="natureOfBusiness"
                            //  addLabelClass="isMandatoryLabel"
                            title="Nature of Business"
                            value={this.state.natureOfBusiness}
                            onValueChange={this.handleFieldValueChange}
                            errorMsg={errors["natureOfBusiness"]}
                            required={true}
                            rowClass={'form-control textarea-sm'}
                          />
                          <div>
                            <div className="col-lg-6 p-0 float-left">
                              <h5>{"Products/Services"}</h5>
                              <CompanyPs
                                required={true}
                                onChange={event => this.handleObjectChange(event)}
                                defaultValue={this.state.psl ? this.state.psl.map(item => { return item.name }) : ""}
                                defaultSelected={this.state.psl ? this.state.psl.map(item => { return item.name }) : ""}
                              />
                            </div>
                          </div>

                          <div>
                            <div className="col-lg-12 p-0 float-left">
                              <h5>Employee count as of date</h5>
                              <div className="col-md-12 p-0">
                                <div className="ediableInline">
                                  <DatePicker
                                    className="form-control"
                                    selected={employeeCountAsOfDate}
                                    onChange={this.handleAsOfDateChange.bind(this)}
                                    placeholderText="Choose as of date"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <EditText
                            name="revenue"
                            title="Revenue"
                            inputType="number"
                            value={this.state.revenue}
                            onValueChange={this.handleFieldValueChange}
                          />
                          <EditText
                            name="grossProfit"
                            title="Gross Profit"
                            inputType="number"
                            value={this.state.grossProfit}
                            onValueChange={this.handleFieldValueChange}
                          />
                          <EditText
                            name="netIncome"
                            title="Net Income"
                            inputType="number"
                            value={this.state.netIncome}
                            onValueChange={this.handleFieldValueChange}
                          />
                          <EditSelect
                            name="financialCurrency"
                            title="Financial Data Currency"
                            value={this.state.financialCurrency}
                            options={currencies}
                            onChange={this.handleSelectChange}
                            additionalClassName="ediableInline "
                          />

                        </div>
                      ) : (
                        <div>
                          <h6>Short Description</h6>
                          <ReadMoreAndLess
                            ref={this.readMore}
                            className="read-more-content"
                            charLimit={config.summaryLimit}
                            readMoreText="Read more"
                            readLessText="Read less"
                          >
                            {this.state.shortDescription
                              ? this.state.shortDescription
                              : "-"}
                          </ReadMoreAndLess>

                          <h6>Long Description</h6>
                          <ReadMoreAndLess
                            ref={this.readMore}
                            className="read-more-content"
                            charLimit={config.summaryLimit}
                            readMoreText="Read more"
                            readLessText="Read less"
                          >
                            {this.state.longDescription
                              ? this.state.longDescription
                              : "-"}
                          </ReadMoreAndLess>
                          <InfoText
                            text={
                              this.state.ownershipStatus
                                ? this.state.ownershipStatus.label
                                : ""
                            }
                            name="ownershipStatus"
                            title="Ownership Status"
                          />
                          <InfoTextArray
                            textArray={areasServed}
                            title="Areas Served"
                          />

                          <CustomArray
                            textArray={this.state.sectors}
                            name="sector"
                            title="Sectors"
                          />
                          <CustomArray
                            textArray={this.state.industryGroups}
                            title="Industries Group"
                          />
                          <CustomArray
                            textArray={this.state.industries}
                            title="Industries"
                          />
                          <CustomArray
                            textArray={this.state.subIndustries}
                            title="Sub-Industries"
                          />

                          <InfoText
                            text={this.state.regNo}
                            title="Business Registration Number"
                          />
                          <InfoText
                            text={this.state.foundedDate}
                            title="Year of Registration/Incorporation"
                          />
                          {showKrpin && <InfoText
                            text={this.state.kraPin}
                            title="KRA Pin Number"
                          />
                          }
                          {this.state.category && <InfoText
                            text={this.state.category.label}
                            title="Category"
                          />}

                          <InfoText
                            text={this.state.parentCompany}
                            title="Parent Company"
                          />

                          <InfoText
                            text={this.state.legalName}
                            title="Legal Name"
                          />
                          <InfoText
                            text={this.state.otherName}
                            title="Known As"
                          />
                          <InfoText
                            text={this.state.formerName}
                            title="Former Name"
                          />
                          {/* <InfoText
                        text={this.state.founded}
                        name="founded"
                        title="Founded"
                      /> */}

                          <InfoText
                            text={
                              this.state.companySize
                                ? this.state.companySize.label
                                : ""
                            }
                            name="companySize"
                            title="Company Size"
                          />
                          <InfoText text={location} title="Headquarters" />

                          <InfoText
                            text={this.state.employeeCount}
                            title="Employee Count"
                          />
                          <InfoText
                            text={this.state.natureOfBusiness}
                            title="Nature of Business"
                          />
                          <InfoText
                            text={this.state.psl ? this.state.psl.map(item => { return `${item.name},\n` }) : ""}
                            title="Products/Services"
                          />
                          <InfoDate
                            date={this.state.employeeCountAsOfDate}
                            title="Employee Count as of date"
                          />

                          <InfoText
                            text={Helper.displayAmount({
                              amount: this.state.revenue,
                              currency: this.state.financialCurrency
                            })}
                            name="revenue"
                            title="Revenue"
                          />
                          <InfoText
                            text={Helper.displayAmount({
                              amount: this.state.grossProfit,
                              currency: this.state.financialCurrency
                            })}
                            name="grossProfit"
                            title="Gross Profit"
                          />
                          <InfoText
                            text={Helper.displayAmount({
                              amount: this.state.netIncome,
                              currency: this.state.financialCurrency
                            })}
                            name="netIncome"
                            title="Net Income"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card text-left bold-headers" id="contact">
                  <div className="card-body">
                    <div className="col-lg-12 p-0 m-h-30">
                      <div className="col-lg-6 p-0 float-left">
                        <h4>Contact Details</h4>
                      </div>
                      <div className="col-lg-3 float-right p-0  text-right">
                        {isEditable ? (
                          <div className="m-t-10">
                            <div>
                              {this.state.formActive.contact ? (
                                <a
                                  onClick={this.hideForm}
                                  data-type="contact"
                                  className="text-danger fa fa-times"
                                />
                              ) : (
                                <a
                                  onClick={this.showForm}
                                  data-type="contact"
                                  className="text-info"
                                >
                                  Edit
                                </a>
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div>
                      {isEditable && this.state.formActive.contact ? (
                        <div>
                          <EditText
                            title="Address"
                            // addLabelClass="isMandatoryLabel"
                            name="addressLine1"
                            value={this.state.addressLine1}
                            required={true}
                            errorMsg={errors["addressLine1"]}
                            placeholder="Address Line one"
                            onValueChange={this.handleFieldValueChange}
                          />
                          <EditText
                            name="addressLine2"
                            value={this.state.addressLine2}
                            placeholder="Address Line two (optional)"
                            onValueChange={this.handleFieldValueChange}
                          />
                          <EditText
                            name="addressLine3"
                            value={this.state.addressLine3}
                            placeholder="Address Line three (optional)"
                            onValueChange={this.handleFieldValueChange}
                          />
                          <EditText
                            name="stateOrProvince"
                            value={this.state.stateOrProvince}
                            placeholder="State/Province/Region (optional)"
                            onValueChange={this.handleFieldValueChange}
                          />
                          <EditText
                            name="pobox"
                            value={this.state.pobox}
                            placeholder="PO Box (optional)"
                            onValueChange={this.handleFieldValueChange}
                          />
                          <EditText
                            name="zip"
                            value={this.state.zip}
                            placeholder="Zip (optional)"
                            onValueChange={this.handleFieldValueChange}
                          />
                          <EditSelect
                            title="Country"
                            // headingClass="isMandatoryLabel"
                            name="addressCountry"
                            value={this.state.addressCountry}
                            options={this.state.countryOptions}
                            onChange={this.handleSelectChange}
                            additionalClassName="ediableInline"
                            errorMsg={errors["addressCountry"]}
                            required={true}
                          />
                          <EditSelect
                            title="City/Town"
                            // headingClass="isMandatoryLabel"
                            name="addressCity"
                            value={this.state.addressCity}
                            options={this.state.addressCityOptions}
                            onChange={this.handleSelectChange}
                            additionalClassName="ediableInline mt-10"
                            errorMsg={errors["addressCity"]}
                            required={true}
                          />
                          {this.state.addressCity && this.state.addressCity.value == 'other' ?
                            <EditText
                              name="newCity"
                              title=""
                              value={this.state.newCity}
                              placeholder={"Enter city name"}
                              onValueChange={this.handleFieldValueChange}
                            />
                            :
                            ""
                          }

                          <Mobile
                            full={true}
                            sendValue={this.mobileValues}
                            extVal={this.state.phoneCode}
                            cnVal={this.state.phoneCn}
                            mobileVal={this.state.phone}
                            mobileName={"phone"}
                            extName={"phoneCode"}
                            title="Tel. Number"
                            required={true}
                            error={errors}
                          />

                          {/* <EditText
                        name="phone"
                        addLabelClass="isMandatoryLabel"
                        title="Phone Number"
                        value={this.state.phone}
                        onValueChange={this.handleFieldValueChange}
                      /> */}
                          <EditText
                            name="email"
                            // addLabelClass="isMandatoryLabel"
                            errorMsg={errors["email"]}
                            required={true}
                            title="Company Email"
                            value={this.state.email}
                            onValueChange={this.handleFieldValueChange}
                          />
                          <EditText
                            name="facebook"
                            title="Facebook"
                            value={this.state.facebook}
                            onValueChange={this.handleFieldValueChange}
                          />
                          <EditText
                            name="twitter"
                            title="Twitter"
                            value={this.state.twitter}
                            onValueChange={this.handleFieldValueChange}
                          />
                          <EditText
                            name="linkedIn"
                            title="LinkedIn"
                            value={this.state.linkedIn}
                            onValueChange={this.handleFieldValueChange}
                          />
                          <EditText
                            name="website"
                            title="Website"
                            value={this.state.website}
                            onValueChange={this.handleFieldValueChange}
                          />

                        </div>
                      ) : (
                        <div>
                          <InfoAddress address={addressDto} title="Address" />
                          <InfoText text={phoneNumber} title="Tel. Number" fieldType="phone" />
                          <InfoText text={this.state.email} title="Email" />

                          {this.state.facebook ||
                            this.state.twitter ||
                            this.state.linkedIn ? (
                            <h6>Social Media Presence</h6>
                          ) : (
                            ""
                          )}
                          <ExternalLink
                            link={this.state.facebook}
                            text="Facebook"
                          />
                          <ExternalLink link={this.state.twitter} text="Twitter" />
                          <ExternalLink
                            link={this.state.linkedIn}
                            text="LinkedIn"

                          />
                          <ExternalLink title="Website" link={this.state.website} />

                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="card text-left bold-headers" id="contactPersons">
                  <div className="card-body">
                    <div className="col-lg-12 p-0 m-h-30">
                      <div className="col-lg-6 p-0 float-left">
                        <h4>Company Contact Person Information</h4>
                      </div>
                      <div className="col-lg-3 float-right p-0  text-right">
                        {isEditable ? (
                          <div className="m-t-10">
                            <div>
                              {this.state.formActive.contactPerson ? (
                                <a
                                  onClick={this.hideForm}
                                  data-type="contactPerson"
                                  className="text-danger fa fa-times"
                                />
                              ) : (
                                <a
                                  onClick={this.showForm}
                                  data-type="contactPerson"
                                  className="text-info"
                                >
                                  Edit
                                </a>
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="clearfix col-md-12 p-0">
                      {isEditable && this.state.formActive.contactPerson ? (
                        <div>
                          <EditText
                            name="contactName"
                            title="Name"
                            value={this.state.contactName}
                            placeholder={""}
                            onValueChange={this.handleFieldValueChange}
                            required={true}
                            errorMsg={errors["contactName"]}
                          />
                          <EditText
                            name="contactEmail"
                            title="Email"
                            value={this.state.contactEmail}
                            placeholder={""}
                            onValueChange={this.handleFieldValueChange}
                          //disabled={Helper.isEmpty(this.state.contactEmail) ? false : true}
                          />
                          <Mobile
                            full={true}
                            sendValue={this.mobileValuesContact}
                            extVal={this.state.mobileCode}
                            mobileVal={this.state.mobile}
                            cnVal={this.state.mobileCn}
                            mobileName={"mobile"}
                            extName={"mobileCode"}
                            title="Contact Number"
                            required={true}
                            error={errors}
                          />

                        </div>
                      ) : (
                        <div>
                          <InfoText text={this.state.contactName} title="Name" />
                          <InfoText text={this.state.contactEmail} title="Email" />
                          <InfoText text={mobileNumber} title="Contact Number" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* {isStartup ? (
              <div className="card text-left bold-headers" id="startup">
                <div className="card-body">
                  <div className="col-lg-12 p-0 m-h-30">
                    <div className="col-lg-6 p-0 float-left">
                      <h4>Startup Info</h4>
                    </div>
                    <div className="col-lg-3 float-right p-0  text-right">
                      {isEditable ? (
                        <div className="m-t-10">
                          <div>
                            {this.state.formActive.startup ? (
                              <a
                                onClick={this.hideForm}
                                data-type="startup"
                                className="text-danger fa fa-times"
                              />
                            ) : (
                              <a
                                onClick={this.showForm}
                                data-type="startup"
                                className="text-info"
                              >
                                Edit
                              </a>
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div>
                    {isEditable && this.state.formActive.startup ? (
                      <div className="clearfix">
                        {/* <EditSelect title= "Business Type" name="businessType" value={this.state.businessType} options={this.state.businessTypeOptions} onChange={this.handleSelectChange} additionalClassName="ediableInline mt-10" />    */}
                {/* <EditMultiSelect
                          title="Business Types"
                          name="businessTypes"
                          value={this.state.businessTypes}
                          options={this.state.businessTypeOptions}
                          onChange={this.handleSelectChange}
                          additionalClassName="ediableInline "
                        />
                        <EditSelect
                          title="Development Stage"
                          name="developmentStage"
                          value={this.state.developmentStage}
                          options={this.state.developmentStageOptions}
                          onChange={this.handleSelectChange}
                          additionalClassName="ediableInline mt-10"
                          isClearable={true}
                        />
                        <YesNoRadio
                          title="Revenue Generating"
                          name="isRevenueGenerating"
                          default={this.state.isRevenueGenerating}
                          onChange={this.handleBooleanValueChange}
                          additionalClassName="ediableInline mt-10"
                        />
                        <YesNoRadio
                          title="Two Year Projection Available"
                          name="isWithTwoYrProjection"
                          default={this.state.isWithTwoYrProjection}
                          value={this.state.isWithTwoYrProjection?"radio-yes-isWithTwoYrProjection":"radio-no-isWithTwoYrProjection"}
                          onChange={this.handleBooleanValueChange}
                          additionalClassName="ediableInline mt-10"
                        />
                        <YesNoRadio
                          title="Business Plan Ready"
                          name="hasBusinessPlan"
                          default={this.state.hasBusinessPlan}
                          onChange={this.handleBooleanValueChange}
                          additionalClassName="ediableInline mt-10"
                        />

                        <YesNoRadio
                          title="Currently Raising Funds"
                          name="isRaisingFunds"
                          default={this.state.isRaisingFunds}
                          onChange={this.handleBooleanValueChange}
                          additionalClassName="ediableInline mt-10"
                        />
                        <EditSelect
                          title="Current Fund Raising Amount"
                          name="fundRaisingAmount"
                          value={this.state.fundRaisingAmount}
                          options={this.state.fundRaisingAmountOptions}
                          onChange={this.handleSelectChange}
                          additionalClassName="ediableInline mt-10"
                        />

                        <EditSelect
                          title="Last Fundraising Amount"
                          name="previousAmountRaised"
                          value={this.state.previousAmountRaised}
                          options={this.state.previousAmountRaisedOptions}
                          onChange={this.handleSelectChange}
                          additionalClassName="ediableInline mt-10"
                        />
                        <EditSelect
                          title="Last Fundraising Source"
                          name="previousFundSource"
                          value={this.state.previousFundSource}
                          options={this.state.previousFundSourceOptions}
                          onChange={this.handleSelectChange}
                          additionalClassName="ediableInline mt-10"
                        />
                      </div>
                    ) : (
                      <div className="clearfix">
                        {/* <InfoText text={this.state.businessType ? this.state.businessType.label : ""} title="Business Type" /> */}
                {/* <InfoTextArray
                          textArray={businessTypes}
                          title="Business Types"
                        />
                        <InfoText
                          text={
                            this.state.developmentStage
                              ? this.state.developmentStage.label
                              : ""
                          }
                          title="Development Stage"
                        />
                        <InfoText
                          text={
                            this.state.previousAmountRaised
                              ? this.state.previousAmountRaised.label
                              : ""
                          }
                          title="Last Fundraising Amount"
                        />
                        <InfoText
                          text={
                            this.state.previousFundSource
                              ? this.state.previousFundSource.label
                              : ""
                          }
                          title="Last Fundraising Source"
                        />
                        <InfoText
                          text={
                            this.state.fundRaisingAmount
                              ? this.state.fundRaisingAmount.label
                              : ""
                          }
                          title="Current Fundraising Amount"
                        />

                        <InfoYesNo
                          text={this.state.isRevenueGenerating}
                          title="Revenue Generating"
                        />
                        <InfoYesNo
                          text={this.state.isWithTwoYrProjection}
                          title="Two Year Projection Available"
                        />
                        <InfoYesNo
                          text={this.state.hasBusinessPlan}
                          title="Business Plan Ready"
                        />
                        <InfoYesNo
                          text={this.state.isRaisingFunds}
                          title="Currently Raising Funds"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}  */}

                {/* {!isCreation && !isStartup && isPublicCompany ? (
              <div className="card text-left bold-headers" id="ipo">
                <div className="card-body">
                  <div className="col-lg-12 p-0 m-h-30">
                    <div className="col-lg-6 p-0 float-left">
                      <h4>IPO &amp; Stock Price Details</h4>
                    </div>
                    <div className="col-lg-3 float-right p-0  text-right">
                      {isEditable ? (
                        <div className="m-t-10">
                          <div>
                            {this.state.formActive.ipo ? (
                              <a
                                onClick={this.hideForm}
                                data-type="ipo"
                                className="text-danger fa fa-times"
                              />
                            ) : (
                              <a
                                onClick={this.showForm}
                                data-type="ipo"
                                className="text-info"
                              >
                                Edit
                              </a>
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {isEditable && this.state.formActive.ipo ? (
                    <div>
                      <div>
                        <div className="col-lg-12 p-0 float-left">
                          <h5>IPO Date</h5>
                          <div className="col-md-12 p-0">
                            <div className="ediableInline">
                              <DatePicker
                                className="form-control"
                                selected={this.state.ipoDate}
                                onChange={this.handleIpoDateChange.bind(this)}
                                placeholderText="Choose IPO Date"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <EditText
                        name="ipoStatus"
                        title="IPO Status"
                        value={this.state.ipoStatus}
                        onValueChange={this.handleFieldValueChange}
                      />
                      <EditText
                        name="exchange"
                        title="Exchange"
                        value={this.state.exchange}
                        onValueChange={this.handleFieldValueChange}
                      />
                      <EditSelect
                        name="ipoCurrency"
                        title="Currency"
                        value={this.state.ipoCurrency}
                        options={currencies}
                        onChange={this.handleSelectChange}
                        additionalClassName="ediableInline "
                      />
                      <EditText
                        name="moneyRaised"
                        title="Money Raised"
                        value={this.state.moneyRaised}
                        onValueChange={this.handleFieldValueChange}
                      />
                      <EditText
                        name="sharePrice"
                        title="Share Price"
                        value={this.state.sharePrice}
                        onValueChange={this.handleFieldValueChange}
                      />
                      <EditText
                        name="valuation"
                        title="Valuation"
                        value={this.state.valuation}
                        onValueChange={this.handleFieldValueChange}
                      />
                      <EditText
                        name="stockSymbol"
                        title="Stock Symbol"
                        value={this.state.stockSymbol}
                        onValueChange={this.handleFieldValueChange}
                      />
                    </div>
                  ) : (
                    <div className="col-lg-12 p-0 float-left">
                      <div>
                        <InfoDate date={this.state.ipoDate} title="IPO Date" />
                        <InfoText
                          text={this.state.ipoStatus}
                          title="IPO Status"
                        />
                        <InfoText text={this.state.exchange} title="Exchange" />
                        <InfoText
                          text={
                            this.state.ipoCurrency
                              ? this.state.ipoCurrency.label
                              : ""
                          }
                          title="Currency"
                        />
                        <InfoText
                          text={Helper.displayAmount({
                            amount: this.state.moneyRaised
                          })}
                          title="Money Raised"
                        />
                        <InfoText
                          text={Helper.displayAmount({
                            amount: this.state.sharePrice
                          })}
                          title="Share Price"
                        />
                        <InfoText
                          text={Helper.displayAmount({
                            amount: this.state.valuation
                          })}
                          title="Valuation"
                        />
                      </div>
                      {this.state.stockSymbol ? (
                        <div>
                          <InfoText text={this.state.stockSymbol} title="Stock Symbol" />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              ""
            )} */}

                {/* {!isCreation ? <ViewFunding uri={companyUri} /> : ""} */}
                {/* {!isCreation ? <ViewInvestments uri={companyUri} /> : ""}    */}
                {/* {!isCreation ? (
              <ManageAcquisition
                uri={companyUri}
                rows={[]}
                loading={this.state.loading}
                companyDetails={companyDetails}
                acquisitions={this.state.companyDetails.acquisitions}
                unchangingProp={this.state.unChangingProp}
                counter={this.state.counter}
              />
            ) : (
              ""
            )} */}


                {/* {!isCreation ? (
              <CreatePeople
                sectionTitle="People"
                uri={this.state.uri}
                titleList={titleList}
                type="company"
              />
            ) : (
              ""
            )} */}


              </React.Fragment>

            }

          </div>
          {/* {!fromCreate && !isCreation ? <ViewInvestors uri={companyUri} /> : ""}	 */}

          <DocEdit uri={companyUri} uploadedFile={this.uploadedFile} docList={docList} />

          <div className="col-sm-12 col-xs-12 p-0">
            <DeletePage type="company" uri={companyUri} />
            {this.state.loading ? (
              <button
                type="submit"
                className="btn btn-white waves-effect- waves-light- float-right"
              >
                {STR.SAVING}
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-info waves-effect- waves-light- float-right"
                onClick={this.handleSubmit}
              >
                {isOwner ? 'Save' : 'Submit for Approval'}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(CompanyDetailsContent);
