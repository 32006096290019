import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import ApiHelper from "../../shared/ApiHelper";
import countries from "../../shared/country";
import Helper from '../../shared/custom';
import qs from 'query-string';

import EditText from "../ui/EditText";




class Forms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventTitle: null,
            title: "",
            pageUri: "",
            id: null,
            type: null,
            desc: null,
            startDate: null,
            endDate: null,

            countryOptions: [],
            cityList: [],
            industry: [],
            country: undefined,
            city: undefined,
            newCity:"",
        }

        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.setCityList = this.setCityList.bind(this);

    }


    handleInputChange(e) {
        var elem = e.target;
        if (e.target.value == "") {
            elem.classList.add('invalid-field');
        } else {
            elem.classList.remove('invalid-field');
        }
        this.setState({
            [elem.name]: elem.value
        });
    }
    handleStartDateChange(date) {
        this.setState({
            startDate: date
        });
    }
    handleEndDateChange(date) {
        this.setState({
            endDate: date
        });
    }

    handleCountryChange(option) {
        this.setState({ country: option });
        let countryId = option.value;
        if (countryId) {
            ApiHelper.getCities(countryId, this.setCityList);
            this.setState({
                city: null
            })
        }
    }

    handleCityChange(option) {
        this.setState({ city: option });
    }
    componentDidMount() {
        let countryList = countries;
        let countryOptions = [];
        let pageUri = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).pageUri;


        countryList.forEach(function (item, index) {
            countryOptions.push({ value: item.id, label: item.name });
        });

        this.setState({
            title: this.props.title,
            eventTitle: this.props.eventTitle,
            countryOptions: countryOptions,
            desc: this.props.desc,
            type: this.props.id,
            industryList: this.props.industryList,
            id: this.props.id,
            pageUri: pageUri
        })
    }

    setCityList(cityList) {
        this.setState({ cityList: cityList })
    }

    validateForm(e) {
        e.preventDefault();

        if (Helper.isEmpty(this.state.eventTitle)) {
            Helper.pushError("Please enter a title for this event.");
            return false;
        }

        if (Helper.isEmpty(this.state.industry)) {
            Helper.pushError("Please select an industry.");
            return false;
        }

        if (Helper.isEmpty(this.state.city) || Helper.isEmpty(this.state.country)) {
            Helper.pushError("Please select country and city for this opportunity.");
            return false;
        }

        
        if (
            Helper.isValidDate(this.state.startDate) === false ||
            Helper.isValidDate(this.state.endDate) === false ||
            this.state.endDate < this.state.startDate
        ) {
            Helper.pushError("Please provide a valid Start Date and End Date!");
            return false;
        }

        let search = !Helper.isEmpty(this.state.pageUri) ? '?pageUri=' + this.state.pageUri : '';

        this.props.history.push({
            pathname: '/event-details/new',
            search: search,
            state: this.state
        });

        // $(this.modal).modal('show');
        // $(this.modal).on('hidden.bs.modal');
    }

    handleIndustryChange(option) {
        this.setState({ industry: option });
    }

    render() {
        const title = this.state.title;
        const desc = this.state.desc;
        const id = this.state.id;
        let cityList = this.state.cityList;
        let cityOptions = [];
        cityList.forEach(function (item, index) {
            cityOptions.push({ value: item.id, label: item.name });
        });

        if (this.state.country && this.state.country.value) {
            cityOptions.push({ value: 'other', label: 'Other' });
        }

        let countryOptions = this.state.countryOptions;

        let industryList = this.props.industryList;
        let industryOptions = [];
        if (industryList) {
            industryList.forEach(function (item, index) {
                var data = {}
                data.value = item.id;
                data.label = item.name;
                industryOptions.push(data);
            });
        }

        return (
            <div>
                <div className="card-body opp-card text-left">
                    <h4 className="card-title">{title}</h4>
                    <p className="card-text">{desc}</p>
                    {/* <button className="btn btn-info m-t-80" onClick={this.showForm}>Get Started</button> */}
                    <form className="m-0">
                        <div className="form-body opp-form">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="control-label">Event Name</label>
                                        <input type="text" name="eventTitle" className="form-control" placeholder="Give your Event a name" onChange={this.handleInputChange} />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="control-label">Industry</label>
                                        <Select name="industry" isMulti value={this.state.industry} className="font-14 col-md-12 p-0 m-0" options={industryOptions} onChange={this.handleIndustryChange.bind(this)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="control-label">Country</label>
                                        <Select name="country" value={this.state.country} className="font-14 col-md-12 p-0 m-0" options={countryOptions} onChange={this.handleCountryChange.bind(this)} />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="control-label">City/Town</label>
                                        <Select value={this.state.city} className="font-14 col-md-12 p-0 m-0" options={cityOptions} onChange={this.handleCityChange.bind(this)} />
                                        {/* {
                                            this.state.city && this.state.city.value == 'other' ?
                                            <EditText
                                                name="newCity"
                                                title=""
                                                value={this.state.newCity}
                                                placeholder={"Enter city name"}
                                                onValueChange={this.handleInputChange}
                                            />
                                            :
                                            ""
                                        } */}
                                    </div>
                                </div>


                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="control-label">Date & Time</label>
                                        <div>
                                            <span className="font-12">Starts (UTC)</span>
                                            <DatePicker className="form-control"
                                                selected={this.state.startDate}
                                                onChange={this.handleStartDateChange}
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                placeholderText="Enter a Start Date for the event"
                                            />
                                            <span className="font-12">Ends (UTC)</span>
                                            <DatePicker className="form-control"
                                                selected={this.state.endDate}
                                                onChange={this.handleEndDateChange}
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                placeholderText="Enter an End Date for the event"
                                            />
                                        </div>

                                    </div>
                                </div>
                                {
                                    this.state.city && this.state.city.value == 'other' ?
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="text" name="newCity" className="form-control" placeholder="Enter city name" onChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                        :
                                        ""
                                }



                                <div className="col-md-12">
                                    <button name={id} className="btn btn-info btn-md btn-custom-md" onClick={this.validateForm}>Continue</button>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        )
    }
}

export default withRouter(Forms);
