import React, { Component , useEffect, useState , useRef } from 'react';
import Helpers from '../../shared/custom';
import InvestmentForm from "./InvestmentForm";
import PartnershipForm from "./PartnershipForm";
import JobForm from "./JobForm";
import TenderForm from "./TenderForm";
import BuyerForm from "./BuyerForm";
import SellerForm from "./SellerForm";
import Axios from "axios";


function NextForm(props) {

    const [id, setId] = useState(null);
    const [type, setType] = useState(null);
    const [data, setData] = useState(()=>{});
    const [enumData, setEnumData] = useState({});

    useEffect(() => {
        let id = Helpers.getQueryParameter("id");
        fetchEnum()
        if(id){
            fetchOpportunityDetails(id)
        }
    }, []);

    const fetchEnum = ()=>{
        
        Axios.get(
            Helpers.apiURL({
                uri:`api/enum/list`,
            })
        )
        .then( (response) => {
            let data  = response.data.result || [];  
            setEnumData(data);
        })
    }

    const fetchOpportunityDetails = (id)=>{
        console.log(id,"inside")
        Axios.get(
            Helpers.apiURL({
                uri:`api/opportunity/${id}`,
            })
        )
        .then( (response) => {
            let data  = response.data || [];  
            setData(prevSttate => data)
            setType(data.oppType)
          
        })
    }

    const navigation = (url) => {
        props.history.push(url);
   }
 

    const getForm = (type)=>{
        switch(type){
            case "INVESTMENT":
                return <InvestmentForm data={data} />;
                break;
            case "PARTNERSHIP":
                return <PartnershipForm navigation={navigation} data={data}  partnershipNature={enumData.PartnershipNature}/>;
                break;
            case "JOB":
                return <JobForm navigation={navigation} data={data} partnershipNature={enumData.PartnershipNature}/>;
                break;
            case "MKT_BUYER":
                return <BuyerForm navigation={navigation} data={data} engagementNature={enumData.EngagementNature}/>;
                break;
            case "MKT_SELLER":
                return <SellerForm navigation={navigation}  engagementNature={enumData.EngagementNature} data={data}/>;
                break;
            case "TENDER": //
                return <TenderForm navigation={navigation} data={data} />;
                break;


        }
    }
    console.log(enumData,"enumData")
    return (
        <div className="container-fluid"> 
            <div className="row content-in m-t-80 p-t-10">
                {
                  type && getForm(type)
                // getForm("JOB")
                }
            </div>
        </div>
    )
}

export default NextForm
