import React, { Component } from 'react';
import store from '../../store';
import InfiniteScroll from 'react-infinite-scroller';
import Follow from '../uiActions/Follow';


import OwlCarousel, { Options } from 'react-owl-carousel';
import config from '../../shared/config';
import classNames from 'classnames/bind';
import Axios from 'axios';
import Helper from "../../shared/custom";

import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import AllCompaniesPublic from '../companiessummary/AllCompaniesPublic';
import { normalize } from 'path';
import CompanyCard from './CompanyCard';


import { getMetaData } from '../../actions/metaAction';
import { getSectorData } from '../../actions/businessAction';





class AllCompanies extends Component {
constructor(props){
    super(props)
    this.state ={
        isLoggedIn :false, 
        followingCompanies:[],
        chosenCompany:[],
        companyBySector:[],
        qs:config.paginationQS({"page":1, "length":config.defaultCarouselLength}),
        scrollItems:2,
        localProps:[],
        criteriaUpdate:undefined,
        business:null,
        metaList:[],
        type:"",
        options: {
            loop: false,
            margin: 10,
            nav:true,
            autoplay:true,
            autoplaySpeed:400,
            dots: true,
            scrollItems:2,
          },

          hasMoreItems: true,
          selectedSectors:[],
          companiesBySector:[],

          scrollReady:true,
          scrollIndex:0,
    }

    store.subscribe(() => { 
        var storeState = store.getState();
        var auth = storeState.auth;
        var localAuth = this.state.auth;
        this.setState({
          isLoggedIn:auth.isAuthenticated
        },()=>{
          
        })

        var localMetaList = this.state.metaList;
        var storeMetaList = storeState.meta.metaList; 
        
        if(!localMetaList || JSON.stringify(localMetaList) != JSON.stringify(storeMetaList)){
          this.setState({metaList:storeMetaList},function(){
         
          });
          
      }

      var localBusiness = this.state.business;
      var storeBusiness = storeState.business.data;
      if(!localBusiness || JSON.stringify(localBusiness) != JSON.stringify(storeBusiness)){
          this.setState({business:storeBusiness});
      }

      }); 

      this.handleLoadMore = this.handleLoadMore.bind(this);
      this.onResizeCar = this.onResizeCar.bind(this);
    
}
componentDidMount(){

  
    var storeState = store.getState();
    var auth = storeState.auth;
    var localAuth = this.state.auth;



    //var storeState = store.getState();
    
    let meta = storeState.meta;

    if(Helper.isEmpty(meta.metaList) == true){
      this.props.getMetaData();
    }else{
      this.setState({metaList:meta.metaList});
    }

    let business = storeState.business;
    
    if(Helper.isEmpty(business.data) == true){            
        this.props.getSectorData();
    }else{
        this.setState({business:business.data});
    }

    var auth = storeState.auth;
    var localAuth = this.state.auth;
    this.setState({
      isLoggedIn:auth.isAuthenticated
    },()=>{
      
    })

    this.setState({
      isLoggedIn:auth.isAuthenticated
    },()=>{
        if(this.state.isLoggedIn){
         this.fetchFollowingCompanies();
       //  this.fetchChosenCompany();
        // this.fetchSelectedSectors();
        }else{
          //  this.fetchChosenCompany();
        }
    })

    if(Helper.isMobile()){
      this.setState({
        scrollItems :1
      })
    }

}


openEventsSectorPage(e){
  let type = "companies"
  window.location.replace('/'+type+'/sector/'+e.target.name);
  var sectorId = e.target.name;
  let sectorLink = document.getElementsByClassName("sectorLink");
  let activeLink = document.getElementsByClassName("activeSector");
  let navLink = document.getElementsByClassName("nav-link active");
  

  if (activeLink.length > 0) {
    activeLink[0].classList.remove("activeSector")
  }
  
  if (navLink.length > 0) {
    navLink[0].classList.remove("active")
  }
      
  e.target.classList.add("activeSector");
}


onResizeCar(){
}


fetchSelectedSectors(){
    Axios.get(
        Helper.apiURL({
            uri:"user/sector",
        })
    )
    .then( (response) => {           
        let sector = response.data && response.data.result ? response.data.result : [];
        this.setState({
            selectedSectors:sector
        })
      })
    .catch((err) => {
    });
  }


fetchFollowingCompanies = () => {
 
    Axios.get(
        Helper.apiURL({
            uri:"companies/interested",
            query:this.state.qs
        })
    )
    .then( (response) => {          
         let followingCompanies = response.data.result.aaData || [];
         this.setState({followingCompanies:followingCompanies});
       })
    .catch((err) => {
        
    });
}

fetchChosenCompany = () => {
     Axios.get(
         Helper.apiURL({
             uri:"companies/my-preference",
             query:this.state.qs
         })
     )
     .then( (response) => {          
          let chosenCompany = response.data.result.aaData || [];
          this.setState({chosenCompany:chosenCompany});
        })
     .catch((err) => {
         
     });
 }

 handleLoadMore(page){
    let isLoggedIn = this.state.isLoggedIn;
    if(!isLoggedIn){
      return false;
    }

    if(!this.state.scrollReady){
      return false;
    }

    let sector = this.state.selectedSectors;
    let scrollIndex = this.state.scrollIndex;
    scrollIndex++;

    if(scrollIndex > sector.length){
      this.setState({
        hasMoreItems:false
      })
      return false;
    }

    this.setState({
      scrollReady:false,
      hasMoreItems:false
    })

    this.getDataBySector(scrollIndex);

}

getDataBySector(scrollIndex){
    let sector = this.state.selectedSectors;

  
    let sectorId = sector[scrollIndex-1].id || null;
    let sectorName = sector[scrollIndex-1].name || null;

    Axios.get(
     Helper.apiURL({
       uri:"companies/sector/"+sectorId+"?iDisplayStart=0&iDisplayLength=4&iSortCol_0=1&sSortDir_0=desc&countryIds=",  
     })
     )
    .then( (response) => {   
        let companiesBySector = this.state.companiesBySector;

       // companiesBySector[sectorId] = response.data.result.aaData;
         companiesBySector[scrollIndex] = response.data && response.data.result ? response.data.result.aaData:[];
         companiesBySector[sectorId].sectorName = sectorName;

       this.setState({
         companiesBySector:companiesBySector,
         scrollReady:true,
         hasMoreItems:true,
         scrollIndex:scrollIndex
       })      
      })
    .catch((err) => {
      this.setState({
        scrollReady:true,
        hasMoreItems:true,
        scrollIndex:scrollIndex
      })      
    });
}


// renderSectorItems(){

//   let items = [];
//   let item = undefined;
//   let companiesBySector = this.state.companiesBySector;
//   let innerItem = null;
//   let scrollItems = this.state.scrollItems;
  

//   if(!Helper.isEmpty(companiesBySector)){
//     companiesBySector.forEach((companies,i) => {

//       item  = (
//         <div className="card text-left bold-headers" key={i}>
//          <div className="card-body companies">
//              <h5><b>{companies.sectorName} Companies</b></h5>
//               {!Helper.isEmpty(companies)?
//               <OwlCarousel ref="car" touchDrag={true} className="owl-theme" items={scrollItems} autoplay="true" loop={companies.length>3 ? true :false} margin={10} autoWidth={false} nav={true} >
//               {companies.map((list, index) => (
//                   <CompanyCard data ={list} key={"CS"+index}/>
//                 ))}
//             </OwlCarousel>
//             :
//             <div className="text-center col-12">No Data Available</div>
//             }
//           </div>
//           </div>
//       );
      
//       items.push(item);
//     });
//   }
//   return items;
// }

 
 

  render() {
    let isLoggedIn = this.state.isLoggedIn;
    let followingCompanies = this.state.followingCompanies;
    let chosenCompany = this.state.chosenCompany;
    let companyBySector = this.state.companyBySector;
    //let items = this.renderSectorItems();
    let scrollItems = this.state.scrollItems;
    let dots = this.props.dots;
    let onlyFollow = this.props.onlyFollow;
    

    let sectorsList = null;
    if (this.state.business){
      sectorsList = this.state.business.sectorsArray;
    }



    return (
      <div>
        {isLoggedIn?
       
          <div>
            { !onlyFollow  ? 
            <div className="card text-left b-header">
                 <div class="card-header text-left p-0">
                    <div class="col-md-6 float-left light-blue-header-half header-div">All Companies</div>
                    <div class="col-md-6 float-left text-right">
                    </div>
                  </div>
                <div className="card-body companies blue-border">
                      <div className="col-md-12 p-0">
                          {!Helper.isEmpty(sectorsList) ? 
                                sectorsList.map((data,i) =>(
                                        <div className="col-md-6 float-left" key={i}>
                                              <div class="card text-center">
                                                   <div class="card-body">
                                                      <div className="pro-img">
                                                         <img src={Helper.dataPath(data.image)}  className="img-responsive radius float-left"/> 
                                                      </div>
                                                      <div className="font-14 imgLabel">{data.name}</div>
                                                  </div>
                                                  <a class="card-footer blue-bg font-12 text-white" onClick={this.openEventsSectorPage} name={data.id}>
                                                        View Companies 
                                                  </a>
                                              </div>
                                        </div>
                                ))
                                :
                                ""
                          }
                    </div>
                 
               
                </div>
            </div>
            :
              
              <div className="card text-left b-header">
                 <div class="card-header text-left p-0">
                    <div class="col-md-8 float-left light-blue-header-half header-div">Companies You Follow</div>
                    <div class="col-md-4 float-left text-right">
                    </div>
                  </div>
               
                <div className="card-body companies blue-border">
                  {!Helper.isEmpty(followingCompanies)? 
                     <OwlCarousel className="owl-theme" items={scrollItems} id="CF"  dots={true} loop={followingCompanies.length > 3 ? true:false} margin={10} autoWidth={false} nav={true} option={this.state.options} >
                     {followingCompanies.map((list, i) => (
                                <CompanyCard data ={list} key={"CF"+i}/>
                              ))}
                      </OwlCarousel>
                      :<div className="text-center col-12">No Data Available</div>
                   }
                </div>
                </div>
              
              }

    {/* {
      Helper.isEmpty(onlyFollow) ? 

                  {/* {!Helper.isEmpty(followingCompanies)?
                    <OwlCarousel className="owl-theme" items={scrollItems} id="dsd"  dots={true} loop={followingCompanies.length > 3 ? true:false} margin={10} autoWidth={false} nav={true} option={this.state.options} >
                        {followingCompanies.map((list, i) => (
                              <CompanyCard data ={list} key={"CF"+i}/>
                            ))}
                    </OwlCarousel>
                    :<div className="text-center col-12">No Data Available</div>
                  } 

                  
      <div className="card text-left b-header">
             <div className="card-body companies">
              <h5><b>Companies chosen for you</b></h5>
              <br/>
              {!Helper.isEmpty(chosenCompany)?
                <OwlCarousel className="owl-theme" items={scrollItems} id="chosen" autoplay="true" loop={chosenCompany.length >3 ? true:false} margin={10} autoWidth={false} nav={true} option={this.state.options} >
                {chosenCompany.map((list, i) => (
                        <CompanyCard data ={list} key={"CC"+i}/>
                    ))}
            </OwlCarousel>
             :<div className="text-center col-12">No Data Available</div>
              }
              
          </div>
         </div>

         :
         ""
      
    } */}
    {/* {
      Helper.isEmpty(onlyFollow) ? 
              <InfiniteScroll
                    pageStart={0}
                    loadMore={this.handleLoadMore}
                    hasMore={this.state.hasMoreItems}
                    threshold = {1}
                    loader={<div className="loader" key={0}><img src={config.cdn +'theme/images/small-loader.gif'}  /></div>}
                    useWindow={true}
                >
                    {items}
              </InfiniteScroll>
        :
        ""} */}
      </div>

      :
      <AllCompaniesPublic followingCompanies={this.state.chosenCompany}/>
     }
      </div>
    )
  }
}




export default  connect(null,{ getMetaData, getSectorData})(withRouter(AllCompanies));
