import React, { Component } from 'react';
import config from '../../shared/config';
import Helper from '../../shared/custom';
import Axios from 'axios';
import Follow from '../uiActions/Follow';
import store from '../../store';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { LoadingOverlay, Loader } from 'react-overlay-loader';

import { deletedFeeds, deletedBookmarks } from '../../actions/FeedAction';


class CardActions extends Component {

  constructor(props){
        super(props);
        this.state = {
                parentUniqueId:null,
                feedUniqueId:null,
                reason:null,
                objectIds:[],
                objectType:null,
                enableReport:false,
                enableShare:false,
                email:null,
                loading:false,
                enableDelete:false,
                addDeleteBookmark:false,
                mode:null,
                data:{}

        }
        this.shareByEmail = this.shareByEmail.bind(this);
        this.enableReport = this.enableReport.bind(this);
        this.enableShare = this.enableShare.bind(this);
        this.report = this.report.bind(this);
        this.onChangeReport = this.onChangeReport.bind(this);
        this.addOrDeleteBookMark = this.addOrDeleteBookMark.bind(this);
        //this.removeBookMark = this.removeBookMark.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.deletePost = this.deletePost.bind(this);

        
  }    
  
  componentDidMount(){
        var storeState = store.getState();
        var auth = storeState.auth;

        this.setState({
             email:auth.user.email,
             data:this.props.data
        });

        if(!Helper.isEmpty(this.props.data)){
                this.setState({
                        parentUniqueId:this.props.data.uniqueId,
                        feedUniqueId:this.props.data.feedUniqueId,
                        objectType:this.props.data.entityType == "COMPANY"  ? "PAGE": this.props.data.entityType,
                        addDeleteBookmark: this.props.data._isBookmarked,
                        mode:this.props.data.mode

                },()=>{
                        
                })
                let data = this.props.data;
                let user = data.shared ? data.shareAuthor : data.author;
                let action = data.action;
                let storeUser = auth.user;

                if((action == "NEW_POST" || data.shared) && user.type == "USER" && user.uri == storeUser.uri){
                        this.setState({
                                enableDelete:true,   
                        })
                }
        }
  }

 componentWillReceiveProps(props){
        var storeState = store.getState();
        var auth = storeState.auth;
         let propUniqueId = props.data && props.data.uniqueId ? props.data.uniqueId : null;
         if(this.state.parentUniqueId != props.data && propUniqueId){
                this.setState({
                   parentUniqueId:props.data.uniqueId,
                   feedUniqueId:this.props.data.feedUniqueId,
                   objectType:this.props.data.entityType == "COMPANY"  ? "PAGE": this.props.data.entityType,
                   data:props.data
                   //addDeleteBookmark:this.props._isBookmarked ? this.props._isBookmarked : false
                }) 
                let data = props.data;
                let user = data.author;
                let action = data.action;
                let storeUser = auth.user;
                

                if(action == "NEW_POST" && user.type == "USER" && user.uri == storeUser.uri){
                        this.setState({
                                enableDelete:true,   
                        })
                }
         }
 }
 enableReport(e){
         e.stopPropagation();
        this.setState({
                enableReport:this.state.enableReport ? false:true
        })
 }
 enableShare(e){
        e.stopPropagation();
        this.setState({
                enableShare:this.state.enableShare ? false:true
        })
 }

 onChangeReport(e){
        this.setState({
                reason:e.target.value
        })
 }


 onChangeEmail(e){
        this.setState({
                email:e.target.value
        })
 }


 report(e){
         this.setState({
                 loading:true
         })
        let payload ={};
        if(Helper.isEmpty(this.state.parentUniqueId)){
                Helper.pushTError("Cannot report at the moment");
                return false;
        }
        if(Helper.isEmpty(this.state.reason)){
                Helper.pushTError("Please select a reason");
                return false;
        }
        payload.uniqueIds = [this.state.parentUniqueId];
        payload.objectType = this.state.objectType;
        payload.reason = this.state.reason;

         Axios.post(
                Helper.apiURL({
                    uri:"user/report",
                }),
                payload
            ).then((response) => {
                let result = response.data.result;
                this.setState({
                 loading:false,
                 enableReport:false
                });
                this.closeDropDown()
                Helper.pushTSuccess("Thanks for your feedback");
            }).catch((err) => {
               this.setState({
                loading:false
                })
            })
 }

 addOrDeleteBookMark(){
        let feedUniqueId = this.state.feedUniqueId;
        let uniqueId = this.state.parentUniqueId;
        this.setState({
                loading:true
        })
        let payload ={};
        if(Helper.isEmpty(this.state.feedUniqueId)){
                Helper.pushTError("Cannot add bookmark at the moment");
                return false;
        }
      
        payload.feedUniqueIds = [this.state.feedUniqueId];
        if(this.state.mode != "bookmarks"){
                this.setState({
                        addDeleteBookmark:this.state.addDeleteBookmark ? false : true
                });
        }
       
        let addDeleteBookmark = this.state.addDeleteBookmark ? false : true;
        payload.addDeleteBookmark = this.state.addDeleteBookmark ? false : true;
        Axios.post(
                Helper.apiURL({
                    uri:"user/feed/bookmark",
                }),
                payload
            ).then((response) => {
                let result = response.data.result;
                if(!addDeleteBookmark && this.state.mode != "bookmarks"){
                        Helper.pushTInfo("Bookmark removed successfully.");
                }else if(!addDeleteBookmark && this.state.mode == "bookmarks"){
                        this.props.deletedBookmarks(uniqueId);
                        Helper.pushTInfo("Bookmark removed successfully.");
                }else{
                        Helper.pushTSuccess("Bookmark saved successfully.");
                }
                if(this.state.mode == "bookmarks"){
                        this.setState({

                        })
                }
                this.setState({
                        loading:false
                        })
            }).catch((err) => {
               Helper.pushTSuccess(err);
               this.setState({
                loading:false
                })
            })

 }


 shareByEmail(){
        this.setState({
                loading:true
        })

         let feedUniqueId = this.state.feedUniqueId;
         let objectType = this.state.objectType;

         let payload ={};
         payload.feedUniqueId = feedUniqueId;
         payload.type = objectType;
     
        if(Helper.isValidEmail(this.state.email)){
                payload.email = this.state.email;
        }else{
                Helper.pushTError("Please enter a valid Email address");
                this.setState({
                        loading:false
                })
                return false;
        }
         Axios.post(
                Helper.apiURL({
                    uri:"share/by-email",
                }),
                payload
            ).then((response) => {
                let result = response.data.result;
                Helper.pushTSuccess("Shared Successfully");
                this.closeDropDown()
                this.setState({
                        loading:false,
                        enableShare:false
                        })

               
            }).catch((err) => {
                Helper.pushTError("Cannot share via email at the moment");
                this.setState({
                        loading:false
                        })
            })
 }
 
 deletePost(){
        let uniqueId = this.state.parentUniqueId;
        let feedUniqueId = this.state.feedUniqueId;
        let data = this.state.data
        if(data.shared){
                if(feedUniqueId){
                        this.setState({loading:true});
                        Axios.delete(
                            Helper.apiURL({
                                uri:"feed/:feedUniqueId",
                                pathVar:{feedUniqueId:feedUniqueId}
                            })
                        )
                        .then(() => {
                           Helper.pushTSuccess("Deleted Successfully");
                           this.props.deletedFeeds(feedUniqueId);
                           this.setState({loading:false});
                        })
                        .catch(() => {
                            this.setState({loading:false});
                            Helper.pushTError("NOT_DELETABLE_ITEM")
                        })
                    }
        }else{
                if(uniqueId){
                        this.setState({loading:true});
                        Axios.delete(
                                Helper.apiURL({
                                uri:"post/:uniqueId/delete",
                                pathVar:{uniqueId:uniqueId}
                                })
                        )
                        .then(() => {
                                Helper.pushTSuccess("POST_DELETED");
                                this.props.deletedFeeds(uniqueId);
                                this.setState({loading:false});
                        })
                        .catch(() => {
                                this.setState({loading:false});
                                Helper.pushTError("NOT_DELETABLE_ITEM")
                        })
                        }
        }
       
 }

 closeDropDown(){
        let elem = document.getElementsByClassName("dropdown-menu-right");
        if(!Helper.isEmpty(elem)){
           for(var i=0; i<elem.length; i++){
                elem[i].classList.remove("show")
           }
       }
 }

  render() {
        let enableReport = this.state.enableReport;
        let enableShare = this.state.enableShare;
        let enableDelete = this.state.enableDelete;
        let data = this.props.data;
        let loading = this.state.loading;
        let deleteConfig = {
                proceed: this.deletePost
        };
        let mode = data.mode;
        let addDeleteBookmark = this.state.addDeleteBookmark;

      
    return (
      <div>
        <LoadingOverlay>
                <Loader fullPage loading={loading}/>
        </LoadingOverlay>
            <div className="dropdown">
                <a className="text-muted dropdown-toggle newsDropdown"  id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-ellipsis-h text-default"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right mailbox ddMailbox"  aria-labelledby="dropdownMenuButton1">
                    <div className="message-center">
                            <Follow fieldType="anchor" uri={data && data.uri ?data.uri:""} type={data && data.entityType ? data.entityType :""}/>
                            
                            <a className="dropdown-item report" href="javascript:void(0)" onClick={this.enableReport}>
                                    <div className="dpIcon m-r-5">   
                                            <i className="fas fa-exclamation-circle"></i>
                                    </div>
                                    <div className="mail-contnet dd-content">
                                        <h5 className="font-medium font-14">Report Post</h5>
                                            <span className="mail-desc font-I-10">Is this post offensive or inappropriate?</span> 
                                    </div>
                            </a>

                            {enableReport?
                                <div className="col-12 reportDD">
                                        <select className="form-control font-14" onChange={this.onChangeReport} defaultValue={"null"}>
                                                <option disabled="disabled" value="null">Please select</option>
                                                <option value="False News">False News</option>
                                                <option value="Unauthorized">Unauthorized</option>
                                                <option value="Spam">Spam</option>
                                                <option value="Spam">Nudity</option>
                                        </select>
                                        <button className="btn btn-danger btn-sm float-right m-t-5" onClick={this.report}>Report</button>
                                        <button className="btn btn-plain btn-sm float-right m-t-5" onClick={this.enableReport}>Cancel</button>
                                </div>
                                :
                                ""
                            }

                            {addDeleteBookmark ?
                                  <a className="dropdown-item text-blue" href="javascript:void(0)" onClick={this.addOrDeleteBookMark}>
                                        <div className="dpIcon m-r-5">   
                                        <i className="fas fa-bookmark"></i>
                                        </div>
                                        <div className="mail-contnet dd-content">
                                        <h5 className="font-medium font-14 text-blue">Remove Bookmark</h5>
                                        <span className="mail-desc font-I-10 text-blue">Remove this bookmark</span> 
                                        </div>
                                </a>
                                :

                                <a className="dropdown-item" href="javascript:void(0)" onClick={this.addOrDeleteBookMark}>
                                        <div className="dpIcon m-r-5">   
                                        <i className="fas fa-bookmark"></i>
                                        </div>
                                        <div className="mail-contnet dd-content">
                                        <h5 className="font-medium font-14">Bookmark Post</h5>
                                        <span className="mail-desc font-I-10">Save this post and read it later</span> 
                                        </div>
                                </a>

                            }

                           
                            <a className="dropdown-item report" href="javascript:void(0)" onClick={this.enableShare}>
                                    <div className="dpIcon m-r-5">   
                                        <i className="fas fa-envelope"></i>
                                    </div>
                                    <div className="mail-contnet dd-content">
                                        <h5 className="font-medium font-14">Share by Email</h5>
                                        <span className="mail-desc font-I-10">Send this post to friends by email</span> 
                                    </div>
                            </a>
                            {enableShare?
                                <div className="col-12 reportDD">
                                        <input className="form-control font-14" onChange={this.onChangeEmail}  />
                                        <button className="btn btn-danger btn-sm float-right m-t-5" onClick={this.shareByEmail}>Send</button>
                                        <button className="btn btn-plain btn-sm float-right m-t-5" onClick={this.enableShare}>Cancel</button>
                                </div>
                                :
                                ""
                            }
                            {enableDelete  ?
                                       <a className="dropdown-item report" href="javascript:void(0)"  onClick={event =>
                                                  Helper.actionConfirm(event, deleteConfig)
                                        }>
                                        <div className="dpIcon m-r-5">   
                                        <i className="fas fa-trash"></i>
                                        </div>
                                        <div className="mail-contnet dd-content">
                                        <h5 className="font-medium font-14">Delete Post</h5>
                                        <span className="mail-desc font-I-10">Delete this post permenently.</span> 
                                        </div>
                                </a>
                                :
                                ""

                            }

                             
                        </div>
                    </div>
            </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
        deletedFeeds:state.deletedFeeds,
        deletedBookmarks:state.deletedBookmarks
});

export default connect(mapStateToProps,{deletedFeeds,deletedBookmarks})(withRouter(CardActions));