import React, { Component } from "react";
import Helpers from "../../shared/custom";
import DatePicker from "react-datepicker";
import currencies from "../../shared/currency";
import CompanySearch from "../wigets/CompanySearch";
import Axios from "axios";
import Select from "react-select";
import { withRouter } from "react-router-dom";
import Aquisitions from "../profile/Aquisitions";

class ManageAcquisition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uri: null,
      editAcq: false,
      rows: [],
      currency: "",
      date: new Date(),
      total: 0,
      companyDetails: [],
      acquisitions: [],
      amount: ""
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleAddMore = this.handleAddMore.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  

  static getDerivedStateFromProps(nextProps, prevState){
    if(JSON.stringify(nextProps.companyDetails) !== JSON.stringify(prevState.companyDetails)){
        return { companyDetails: nextProps.companyDetails};
    }
    return null;
  }
  
  componentDidUpdate(prevProps, prevState) {
   // if(prevProps.someValue!==this.props.someValue){
      //Perform some operation here
   //   this.setState({someState: someValue});

    if(JSON.stringify(prevProps.companyDetails) !== JSON.stringify(this.props.companyDetails)){
      this.setState({
        companyDetails: prevProps.companyDetails,
        acquisitions: prevProps.companyDetails.acquisitions
      });
    }
  }

  
  handleClick() {

    let editAcq = true;
    let acquisitions = this.state.acquisitions;
    let rows = this.state.rows;
  

    if (this.state.editAcq) {
      editAcq = false;
      //convert rows into acquisitions
      let rowss = this.state.rows && this.state.rows.map(row => {
        if ( typeof row.currency === 'string' ) {
       
          row = { ...row, currency: Helpers.valueLabelToIdName(currencies.filter(curr => { if (curr.value == row.currency) return curr})[0]) };
        }

        if (row.acExistantOrNew){
          row.acquiredCompanyName = row.acExistantOrNew.name;
          row.acquiredCompany = row.acExistantOrNew;
          delete(row.acExistantOrNew);
        }
        return row;
      });

      acquisitions = rowss;
    
    } else {
      let acqs = this.state.acquisitions && this.state.acquisitions.map(acq => {
        if (acq.acquiredCompany != null) {
          acq = { ...acq, acExistantOrNew: acq.acquiredCompany, acquiredCompanyName: acq.acquiredCompany.name};
          delete(acq.acquiredCompany);
        }
        return acq;
      });
      editAcq = true;
      rows = acqs;
    }

    this.setState({
      editAcq: editAcq,
      acquisitions: acquisitions,
      rows: rows
    });
  }

  handleChange(index, event, name) {
    var value = 0;
    if (event && event.target && event.target.value) {
      value = event.target.value;
    } else if (event && event.value) {
      value = event.value;
    } else if (event) {
      if (event[0] && event[0].value)
        value = event[0];
      else if (event[0] && event[0].customOption)
        value = event[0];
    }

    if (name == "date") {
      this.setState({
        date: value
      });
      //value = 
    }
    if (name == "amount") {
      value = parseInt(value)
    }

    let rows = this.state.rows;
    rows[index][name] = value;



    this.setState({
      rows: rows
    });
  }


  handleDateChange(index, value) {

    let rows = this.state.rows;
    rows[index]['date'] = value;
    this.setState({
      rows: rows,
      date: value
    });
  }

  componentDidMount() {
    let uri = this.props.uri
      ? this.props.uri
      : Helpers.lastSegment(window.location);
    this.setState(
      {
        uri: uri
      },
      () => {}
    );

    this.setState({
      companyDetails: this.props.companyDetails,
      acquisitions: this.props.companyDetails?this.props.companyDetails.acquisitions:[]
    });

    //this.getCompanyDetails(uri)
  }

  getCompanyDetails() {
    let uri = this.state.uri;
    Axios.get(
      Helpers.apiURL({
        uri: "company/" + uri + "/details"
      })
    )
      .then(response => {
        let companyDetails = response.data.result || null;
        this.setState({
          companyDetails: companyDetails
        });
        if (!Helpers.isEmpty(companyDetails)) {
          let acquisitions = companyDetails.acquisitions;
          acquisitions = !Helpers.isEmpty(acquisitions) ? acquisitions : null;
          this.setState({
            acquisitions: acquisitions
          });
        }
      })
      .catch(err => {});
  }

  handleAddMore() {
    var rows = Helpers.isEmpty(this.state.rows)?[]:this.state.rows;
    rows.push({
      id: null,
      acquiredCompanyName: "",
      amount: "",
      currency: null,
      date: new Date()
    });
    this.setState({ rows: rows });
  }

  validateForm(postData){
      let isValid = true;
      let message = [];

      if (postData && postData.length > 0){
        postData.forEach((data, index) => {
          if (Helpers.isEmpty(data.id)){
            if (Helpers.isEmpty(data.acExistantOrNew)){
              isValid = false;
              message.push("COMPANY_NAME_REQUIRED");
            }
          }else{
            if (Helpers.isEmpty(data.acExistantOrNew) && Helpers.isEmpty(data.acquiredCompanyName)) {
              isValid = false;
              message.push("COMPANY_NAME_REQUIRED");
            }
          }
          
          // if (Helpers.isEmpty(data.amount)) {
          //   isValid = false;
          //   message.push("AMOUNT_REQUIRED");
          // }
          // if (Helpers.isEmpty(data.currency)) {
          //   isValid = false;
          //   message.push("CURRENCY_REQUIRED");
          // }
        });
      }


      if (message.length > 0) {
        Helpers.pushTError(message);
      }
      return isValid;
    }


  handleSave() {
      
    let postData = this.state.rows;
    let isValid = this.validateForm(postData);
    if (!isValid) {
      return false;
    }
    let payload = [];
    if (postData && postData.length > 0){
      postData.forEach((data, index) => {
        let obj = {};
        if (data.acExistantOrNew){
          let acExistantOrNewObj = data.acExistantOrNew;
          if (acExistantOrNewObj.customOption && acExistantOrNewObj.customOption === true){
            obj.acquiredCompanyName = data.acExistantOrNew.name;
          }else{
            obj.acquiredCompany = {
              uri:  acExistantOrNewObj.uri
            };
          }
        }else{
          obj.acquiredCompanyName = data.acquiredCompanyName
        }
        
        
        
        // if (data.acquiredCompany && !Helpers.isEmpty(data.acquiredCompany.uri)){
        //   obj.acquiredCompany = {
        //     uri:  data.acquiredCompany.uri
        //   };
        // }else{
        //   obj.acquiredCompanyName = data.acquiredCompanyName
        // }
        
        obj.amount = data.amount;
        obj.currency = {
          id: data.currency && data.currency.id ? data.currency.id : data.currency
        };
        obj.date = Helpers.getDateInInputFormat(data.date);
        payload.push(obj);
      });
    }
    this.saveData(payload);
  }

  saveData(payload) {
    let payloadData = payload;
    let uri = this.state.uri;

    Axios.post(
      Helpers.apiURL({
        uri: "company/" + uri + "/acquisitions"
      }),
      payloadData
    )
      .then(response => {
        Helpers.pushSuccess("Acquisition(s) saved successfully");
        //this.getCompanyDetails();
        this.setState({
          editAcq: this.state.editAcq ? false : true,
          acquisitions: response.data.result,
          rows: Helpers.isEmpty(response.data.result)?[]:response.data.result
        });
      })
      .catch(error => {
        Helpers.pushTError(error.response.data.message);
      });
  }

  deleteRow(index,event) {
  
    // var rows = [...this.state.rows];
    // rows.splice(index, 1);
    // this.setState({ rows: rows });

    if (event.target && event.target.dataset.id) {
      let uri = this.state.uri;
      let id = event.target.dataset.id;

      Axios.delete(
        Helpers.apiURL({
          uri: "company/" + uri + "/acquisition/" + id + "/remove"
        })
      )
        .then(response => {
          Helpers.pushSuccess("Acquisition(s) deleted successfully");
          let newRows = this.state.rows.filter(el => el.id != id);
          this.setState(prevState => ({
            rows: newRows,
            acquisitions: newRows
          }));
          //this.getCompanyDetails();
        })
        .catch(err => {
          Helpers.pushError("Unable to remove selected entry. Please try again.");
        });
    }else{
      if (!Helpers.isEmpty(index)){
        let newRows = this.state.rows.filter(function(element, ind){return (ind != index) });
            this.setState(prevState => ({
              rows: newRows
            }));
      }
    }
  }

  render() {
    let rows = this.state.rows;
    let rowData = this.state.rowData;
    let acquisitions = this.state.acquisitions;
    let total = acquisitions ? acquisitions.length : 0;

    return (
      <div>
        <div className="card text-left" id="acq">
          <div className="card-body">
            <div className="col-lg-12 p-0 float-left">
              <h5 className="m-t-0">Acquisitions</h5>
              <div className="uploadTag">
                <a href="javascript:void(0)" onClick={this.handleClick}>
                  + Add/Edit Acquisitions
                </a>
              </div>
              <div className="result m-t-20">
                <div className="row">
                  <div className="col-lg-12 col-md-6 m-b-20">
                    {this.state.editAcq ? (
                      <div>
                        <a
                          onClick={this.handleClick}
                          className="text-danger float-right"
                        >
                          <i className="fa fa-times" />
                        </a>
                        <br />

                        <table className="table color-table muted-table aqTable">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th className="text-center">Date</th>
                              <th>Currency</th>
                              <th className="text-center">Price</th>
                              <th className="text-center">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {rows &&
                              rows.length > 0 &&
                              rows.map((row, i) => (
                                <tr key={i}>
                                  <td className="aqCompany">
                                    {!Helpers.isEmpty(row) &&
                                    !Helpers.isEmpty(row.id) ? (
                                      rows[i].acquiredCompany &&
                                      rows[i].acquiredCompany.name ? (
                                        rows[i].acquiredCompany.name
                                      ) : (
                                        rows[i].acquiredCompanyName
                                      )
                                    ) : (
                                      <CompanySearch
                                        title=""
                                        allowNew={true}
                                        headingClass="hidden"
                                        onChange={event =>
                                          this.handleChange(
                                            i,
                                            event,
                                            "acExistantOrNew"
                                          )
                                        }
                                        name="acExistantOrNew"
                                        // selected={
                                        //   rows[i].acquiredCompany &&
                                        //   rows[i].acquiredCompany.name
                                        //     ? rows[i].acquiredCompany
                                        //     : (rows[i].acExistantOrNew &&
                                        //       rows[i].acExistantOrNew.name
                                        //         ? rows[i].acExistantOrNew
                                        //         : null)
                                        // }
                                        defaultValue={
                                          rows[i].acquiredCompany &&
                                          rows[i].acquiredCompany.name
                                            ? rows[i].acquiredCompany.name
                                            : (rows[i].acExistantOrNew &&
                                              rows[i].acExistantOrNew.name
                                                ? rows[i].acExistantOrNew.name
                                                : "")
                                        }
                                      />
                                    )}
                                  </td>
                                  <td className="text-center aqDate" >
                                    <DatePicker
                                      onChange={event =>
                                        this.handleDateChange(i, event)
                                      }
                                      selected={
                                        rows[i].date
                                          ? new Date(rows[i].date)
                                          : this.state.date
                                      }
                                      className="form-control date"
                                      todayButton={"Today"}
                                      placeholderText="From"
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      index={i}
                                    />
                                  </td>
                                  <td className="aqCurrency">
                                    <Select
                                      ref={"currency"}
                                      className="font-14 col-md-12 p-0 m-0 currency"
                                      options={currencies}
                                      name="currency"
                                      defaultValue={{
                                        label:
                                          rows[i].currency &&
                                          rows[i].currency.name
                                            ? rows[i].currency.name
                                            : "Select Currency",
                                        value:
                                          rows[i].currency &&
                                          rows[i].currency.id
                                            ? rows[i].currency.id
                                            : ""
                                      }}
                                      placeholder={"Select Currency"}
                                      onChange={event =>
                                        this.handleChange(i, event, "currency")
                                      }
                                      index={i}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <input
                                      type="text"
                                      className="form-control amount"
                                      name="amount"
                                      index={i}
                                      defaultValue={
                                        rows[i] && rows[i].amount
                                          ? rows[i].amount
                                          : ""
                                      }
                                      placeholder="Please enter the price"
                                      onChange={event =>
                                        this.handleChange(i, event, "amount")
                                      }
                                    />
                                  </td>
                                  <td className="text-center">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={(event) => this.deleteRow(i, event)}
                                      index={i}
                                      key={i}
                                      className="fa fa-times text-danger"
                                      data-id={rows[i] ? rows[i].id : ""}
                                      data-rowid={i}
                                      id={i}
                                    />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        <div className="uploadTag">
                          <a
                            href="javascript:void(0)"
                            onClick={this.handleAddMore}
                          >
                            {" "}
                            + Add More{" "}
                          </a>
                            {rows && rows.length > 0?
                              <a
                              href="javascript:void(0)"
                              className="btn btn-info float-right"
                              onClick={this.handleSave}
                            >
                              {" "}
                              Save{" "}
                            </a>
                            :
                            ""
                            }
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="col-md-12 p-0">
                          <div className="row ">
                            <div className="col-md-12 float-left">
                              <div className="col-md-12 box float-left">
                                <div className="col-md-9 float-left">
                                  Number of Acquisitions{" "}
                                </div>
                                <div className="col-md-3 float-left font-bold text-right">
                                  {total}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="table-responsive m-t-10">
                            <table className="table color-table muted-table font-14">
                              <thead>
                                <tr>
                                  <th>Acquired Organisation Name</th>
                                  <th>Announced Date</th>
                                  <th>Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                {!Helpers.isEmpty(acquisitions) ? (
                                  acquisitions.map((data, index) => (
                                    <tr
                                      key={
                                        data.acquiredCompany &&
                                        data.acquiredCompany.id
                                          ? data.acquiredCompany.id
                                          : index
                                      }
                                    >
                                      <td>
                                        {data.acquiredCompany &&
                                        data.acquiredCompany.name
                                          ? data.acquiredCompany.name
                                          : data.acquiredCompanyName}
                                      </td>
                                      <td>
                                        {data.date
                                          ? Helpers.getDateInInputFormat(
                                              data.date
                                            )
                                          : ""}
                                      </td>
                                      <td>
                                        {data.currency && data.amount
                                          ? Helpers.displayAmount({amount:data.amount,currency:data.currency})
                                          : "--"}
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="3" className="text-center">
                                      No Data Found
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ManageAcquisition);
