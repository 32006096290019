import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import config from '../../shared/config';
import Helper from '../../shared/custom';
import { withRouter, Redirect ,Link} from 'react-router-dom';



export default class Adtech extends Component {
    render() {
        let imgs = [1,2,3,4,5,6,7,8,9,10,11,12]
        return (
            <div className="card additionalClass">
                 <div className="card-body">
                     {
                        imgs.map((data,i)=>(
                            <div class="page" key={i}>
                                <img src={config.reportFolder+'ad-tech/'+data+'.png'} className="img-fluid"/>
                            </div>
                         ))
                     }
                    
                    <div className="m-t-20 clearfix">
                      <a href="http://plus-cdn.investera.com/pdf/ad-tech/Ad-Tech_Report_V2.pdf" className="btn btn-action" download>Download PDF</a>
                     </div>
                 </div>
            </div>
        )
    }
}
