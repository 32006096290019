import React, { Component } from 'react';
import UserFeedRightPanel from "../homepage/UserFeedRightPanel";
import { connect } from 'react-redux';
import store from '../../store';
import Helper from '../../shared/custom';
import PublicReport from "./PublicReport";
import UserReport from "./UserReport";
import CoronaReport from "./CoronaReport";
import Adtech from "./Adtech";
import InvestmentReport from "./InvestmentReport";
import MeAi from "./MeAi";

class AllReports extends Component {
    constructor(props){
        super(props);
        this.state ={
            loggedIn:false,
            localProps:{},
        }

        store.subscribe(() => {   
            var storeState = store.getState();     
            this.setState({loggedIn:storeState.auth.isAuthenticated});
        }); 

    }

    componentDidMount(){
        var storeState = store.getState();  
        this.setState({
            loggedIn:storeState.auth.isAuthenticated,
        })   
        document.body.classList.add('bg-black');
        document.body.classList.add('custom-body');
    }


    componentWillReceiveProps(props){
        var storeState = store.getState();  
        if(JSON.stringify(storeState) != JSON.stringify(this.state.localProps)){
            this.setState({
                loggedIn:storeState.auth.isAuthenticated,
                localProps:storeState
            })
        }
    }

    getContent=()=>{
        let uri = Helper.lastSegment(this.props.location.pathname);
        let isAuthenticated = this.state.loggedIn;
  
    
            switch(uri){
                case "mena":
                    return isAuthenticated ? <UserReport/> : <PublicReport/>;
                    break;
                case  "corona":
                    return isAuthenticated ? <CoronaReport/> : <CoronaReport/>;   
                    break; 
                case "adtech":
                    return <Adtech/>;
                    break;
                case "meai":
                    return <MeAi/>;
                    break;
                case "investment-report":
                    return <InvestmentReport />;
                    break;
            }
       
    }
    render() {
        return (
            <div>
                 <div className="m-t-80">
                    <div className="row content-in m-t-80 p-t-10">
                        <div className="col-lg-9 col-xlg-9 col-md-9 centerDiv">
                            {this.getContent()}
                        </div>
                        <div className="col-lg-3 col-xlg-3 col-md-2 m-t-2 userProfileRight right-side">
                            <UserFeedRightPanel />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default  AllReports;