
import Axios from 'axios';
import config from '../shared/config';
import { GET_ERRORS , SET_TOKEN, SET_USER, REMOVE_CURRENT_USER,SET_EMAIL_VERIFIED,UNSET_EMAIL_VERIFIED, SET_CURRENT_USER_PROFILE , SHOW_LOADER , HIDE_LOADER }  from './types';
import setAuthTokenHeader from '../utils/setAuthTokenHeader'; 
import Helper from "../shared/custom";
import store from "../store";
import ReactGA from 'react-ga';
// import { dispatch } from 'd3';



//import storeAuth from '../../utils/storeAuth';


export const login = (loginData,history , $props) => dispatch => {
    showLoader();

    Axios.post(
        config.api("authenticate"),
        loginData)
    .then( (response) => {
        const token  = response.data;
        setAuthTokenHeader(token);
        dispatch(setToken(token));
        let userInfo = JSON.parse(localStorage.getItem("userInfo"))
        if(Helper.isEmpty(userInfo)){
          fetchUserInfo(history , $props);
        }
        removeError()
    })
    .catch((err) => {
        Helper.pushTError("BAD_CREDENTIALS")
        dispatch({
            type: GET_ERRORS,
            payload: err.response
        })
    });
}

export const oauthLogin = (token) => dispatch =>{
    setAuthTokenHeader(token);
    dispatch(setToken(token));
    debugger;
    let userInfo = JSON.parse(localStorage.getItem("userInfo"))
    if(Helper.isEmpty(userInfo)){
      fetchUserInfo();
    }
    removeError();
}


export const showLoader = () => dispatch =>{
   dispatch({
       type: SHOW_LOADER,
       payload: {}
   })
}

export const hideLoader = () => dispatch =>{
    dispatch({
        type: HIDE_LOADER,
        payload: {}
    })
 }

 
//  export const fetchUserInfo1 = ()  => {
//  }
 
export const fetchUserInfo = ($history = '' , $props = null) => {

    Axios.get(config.api("user-details"))
    .then( (response) => {
        const userData  = response.data.result;
        localStorage.setItem("userInfo",JSON.stringify(userData));
        userData.image = userData.image || config.defaultUserImage;
        ReactGA.ga('set','userId', userData.uri);
        ReactGA.set({ userId: userData.uri });
        store.dispatch(setUser(userData));
        //storeAuth()
        // TODO: check this
        setUserProfile(userData.uri)
        
        //if user profile is not complete redirect, should be commented out again after enabling backend changes on prod
      

        let packageType = userData.promptSubUpgradeTo ? userData.promptSubUpgradeTo.value.toLowerCase() : null;
       
        if(userData.emailConfirmed){
            if(!userData.registrationCompleted && window.location.pathname !=  "/welcome"){
                window.location.href = "/register/sectors"
            }else if(userData.registrationCompleted && userData.resetPassword && window.location.pathname !=  "/change-password"){
                    window.location.href = "/change-password"
            }else{
                if(userData.promptUpgrade && packageType && window.location.pathname !=  "/proceed"){
                    window.location.href = "/proceed?type="+packageType
                }
            }
            if($history){
                $props.push($history);
            }
        }else{
            if(window.location.pathname  != "/verify-email"){
            window.location.href = "/verify-email"}
        }

       // hideLoader()
        removeError();   
    })
    .catch((err) => {
        store.dispatch({
            type: GET_ERRORS,
            payload: err
        })
    });
   
}

export const resetUserInfo = () => dispatch =>{
    dispatch(setUser({name:null,email:null}));
}

export const tokenAuthenticate = (token) => dispatch => {
    setAuthTokenHeader(token);
    dispatch(setToken(token));
    let userInfo = JSON.parse(localStorage.getItem("userInfo"))
    if(Helper.isEmpty(userInfo)){
      fetchUserInfo();
    }
}

export const setToken = (token) =>{
    return {
        type : SET_TOKEN,
        payload : token
    }
}

export const setUser = (userInfo) =>{
    
    return {
        type : SET_USER,
        payload : userInfo
    }
}

export const setUserProfile = (uri) => {
   
    var storeState = store.getState();
    if(storeState.auth.userProfile){
        return false;
    }

    Axios.get(
        Helper.apiURL({
            uri:`user/:uri/profile`,
            pathVar:{uri}
        })
    )
    .then( (response) => {     
        let profileDetails  = response.data.result || null;   
        localStorage.setItem("profile",JSON.stringify(profileDetails)); 

        store.dispatch({
            type:SET_CURRENT_USER_PROFILE,
            payload:profileDetails
        });
    })
    .catch((err) => { 
        //Helper.pushTError("PROFILE_NOT_FOUND");
    });
}


export const logoutUser = () => dispatch =>{
   localStorage.removeItem("greeted");
   localStorage.removeItem('auth');
   localStorage.removeItem('referal');
   localStorage.removeItem('notiOpen');
   localStorage.removeItem('totalNoti');
   localStorage.removeItem('lastUrl');
   localStorage.removeItem('userInfo');
   localStorage.removeItem('profile');
   localStorage.removeItem('metaList');
   setAuthTokenHeader(null);
   dispatch(setToken(null));
   dispatch(removeCurrentUser());
}

export const removeCurrentUser = () =>{
    return {
        type : REMOVE_CURRENT_USER,
        payload : config.defaultUser
    }
}

export const removeError = () => dispatch =>{
    dispatch({
        type: GET_ERRORS,
        payload: null
    })
}

export const emailVerified = () => {
    return {
        type: SET_EMAIL_VERIFIED,
        payload: {}
    }
}

export const unsetEmailVerified = () =>{
    return {
        type: UNSET_EMAIL_VERIFIED,
        payload: {}
    }
}
