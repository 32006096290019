import React, { Component } from "react";
import SelectableTag from "./SelectableTag";
import SelectableTagsOptionalAdd from "./SelectableTagsOptionalAdd";
import Helper from "../../shared/custom";

export default class SelectableTags extends Component {

    constructor(props){
        super(props)
        this.state = {
            options:props.options,
            permanentOptions:props.permanentOptions,
            selectedOptions:props.selectedOptions || [],
            enableChange:props.enableChange    
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            permanentOptions: nextProps.permanentOptions,
            enableChange: nextProps.enableChange,
            options:nextProps.options
        };
    }
    handleSelectableChange = (option)=>{
        let options = this.state.selectedOptions;
        if(option.selected){
            options.push(option.option)
            this.setState({
                selectedOptions : options
            },()=>{
                if(this.props.onChange){
                    this.props.onChange(this.state.selectedOptions)
                }
            })
        }else {
            options = options.filter(x=>{return x.value != option.option.value});
             this.setState({
                selectedOptions : options && options.length >0?options:[]
             },()=>{
                if(this.props.onChange){
                    this.props.onChange(this.state.selectedOptions)
                }
            })
        }
    }
    render(){
        let permanentOptions = this.state.permanentOptions || [];
        let options = this.state.options || [];
        return (
            <div className="p-2">
                <div className="col-12 m-0 p-0">
                    {permanentOptions && permanentOptions.map(x=>{
                        return <SelectableTag 
                                key={this.props.id+"_selectable_tag_permanent"+x.value}
                                id={this.props.id+"_selectable_tag_permanent"+x.value}
                                selected={this.state.selectedOptions.map(y=>y.value).includes(x.value)}
                                option={x}
                                enableChange={this.state.enableChange}
                                onChange={this.handleSelectableChange}
                                />
                        })
                    }
                    {!Helper.isEmpty(options) &&
                        options.map(x=>{
                            return <SelectableTag 
                                    key={this.props.id+"_selectable_tag_temp_"+x.value}
                                    id={this.props.id+"_selectable_tag_temp_"+x.value}
                                    option={x}
                                    selected={this.state.selectedOptions.map(y=>y.value).includes(x.value)}
                                    enableChange={this.state.enableChange}
                                    onChange={this.handleSelectableChange}
                                    />
                            })
                    }
                </div>
            </div>
        )
    }

}