import React, { Component } from 'react'
import config from "../../shared/config";
import Helper from '../../shared/custom';
import { withRouter } from 'react-router-dom';
import Feeds from '../wigets/Feeds';

class Wall extends Component {
    constructor(props){
        super(props);
        this.state ={
            uri:null,
            type:null
        } 
                
    }

    componentDidMount(){
        this.setState({
            uri:this.props.uri,
            type:"people"
          });
    }
    
    
    componentWillReceiveProps(props){
        
       if (Helper.isEmpty(props.uri)) return;
       if (props.uri == this.props.uri) return;
       if(this.state.uri != props.uri){
        this.setState({
            uri:props.uri,
          });
       }

       if(this.state.type != props.type){
        this.setState({
            type:"people"
          });
       }
      

          
    }

   render() {
       let uri = this.state.uri;
       let type = this.state.type;
       
    return (
        <div>
            <Feeds uri={uri} type={type}/>
        </div>
    )
  }
}

export default (withRouter(Wall));