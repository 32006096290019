import React, { Component } from 'react';
import {register , registerAuthenticated} from '../../actions/registerAction';
import {login, fetchUserInfo , removeError} from '../../actions/authAction';
import { withRouter ,Redirect ,Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import ReactTooltip from 'react-tooltip';
import GoogleAuth from '../auth/GoogleAuth';

// import Helpers from '../../shared/custom';



import LinkedInAuth from './LinkedInAuth';
import config from '../../shared/config';
import Recaptcha  from 'react-recaptcha';
import store from '../../store';
import Helper from '../../shared/custom';
import qs from "query-string";
import CountryCode from "../wigets/CountryCode";

let recaptchaInstance;



 class Register extends Component {

    
   constructor(props){
        super(props)
        this.key = 0;

        this.state = {
          registerData:{},
          fullName:"",
          userName:"",
          email:"",
          password:"",
          confirmPassword:"",
          mobile:"",
          mobileCode:"",
          linkedinKey:null,
          captchaCode:null  ,
          error:undefined,    
          loading:false,  
          auth:{},
          verifiedPassword:true,
          loginUserName:"",
          loginPassword:"",
          ext:"+254",
          cn:"KE",
          mobileNumber:"",
          isMinimumChar:false,
          isOneNumber:false,
          isOneUpperCase:false,
          isOneLowerCase:false,
          isOneSplChar:false,
          errors:{},
          showPassword:false,
          passwordType:"password",
        }

        this.validateForm    = this.validateForm.bind(this);  
        this.validateLoginForm = this.validateLoginForm.bind(this);     
        this.handleChange = this.handleChange.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
        this.openSearch = this.openSearch.bind(this);
        this.callback= this.callback.bind(this);
        this.confirmPassword = this.confirmPassword.bind(this);
        
        store.subscribe(() => {   
            var storeState = store.getState();       
            this.setState({auth:storeState.auth});
            
        }); 

    }  


    
    componentDidMount(){
        window.scrollTo(0,0);
        if(!Helper.isEmpty(recaptchaInstance)){
            recaptchaInstance.reset();  
        }
             
        
        if (Helper.lastSegment(this.props.location.pathname) === "register"){
            let referal = qs.parse(this.props.location.search, {
                ignoreQueryPrefix: true
              }).referal;

            referal += window.location.hash;
            this.setState({referal:referal});
            localStorage.setItem('referal', referal);
        }

        var storeState = store.getState();  
        this.setState({auth:storeState.auth});  

        if(storeState.auth && storeState.isAuthenticated){
                
        }else{            
            let lastState = this.props.location && this.props.location.state ? this.props.location.state : undefined;
            if(lastState && lastState.linkedin){
                let password = Helper.randomPassword();
                this.setState({
                    fullName:lastState.linkedin.fullName,
                    email:lastState.linkedin.email,
                    password:password,
                    confirmPassword:password,
                    linkedinKey:lastState.linkedin.linkedinKey,
                    mobile : " ",
                    ext :  " " 

                })
            }
        }
        
        
    }
    openSearch(e){
        let currentPath = this.props.location.pathname;
        let newPath = "/search"; 
        if(currentPath != newPath){
            this.props.history.push(newPath);
        }
    }
    callback(){
        
    };

    confirmPassword(e){
        this.setState({
            verifiedPassword : (this.state.password == e.target.value)
        })
    }

    componentWillReceiveProps(nextProps){
        let isRegistered = nextProps.registerData.isRegistered;
        let userData = nextProps.registerData.userData; 
        let isAuthenticated = nextProps.auth.isAuthenticated;
        
        if(!Helper.isEmpty(nextProps.errors.error) && !isAuthenticated){
            this.setState({
                loading:nextProps.errors.loading
            })
            if(nextProps.errors.error.data){
                Helper.pushTError(nextProps.errors.error.data.message);
                this.props.removeError();
            }   
         
        }
    }

    handleChange(event){

        if(event.target.name == "password"){
            this.checkPasswordStrength(event.target.value)
        }
        this.setState({[event.target.name]:event.target.value});
    }

    onChangeExt = (e,cn)=> {
        this.setState({
            ext:e,
            cn:cn
        })
    }


    checkPasswordStrength=(value)=>{
            let hasLowerCase = Helper.hasLowerCase(value);
            let hasUpperCase = Helper.hasUpperCase(value);
            let hasOneNumber = Helper.hasOneNumber(value);
            let hasMinLength = Helper.hasMinLength(value);
            let hasOneSplChar = Helper.hasOneSplChar(value);
            this.setState({
                isOneLowerCase:hasLowerCase,
                isOneUpperCase:hasUpperCase,
                isMinimumChar:hasMinLength,
                isOneNumber:hasOneNumber,
                isOneSplChar:hasOneSplChar,
            })
          
    }

    verifyCallback(response){
            if(!Helper.isEmpty(response)){
                this.setState({
                    captchaCode : response
                })
            }
    }

    showPassword = (e)=>{
        e.preventDefault();
        this.setState(prevState =>{
            prevState.showPassword = !prevState.showPassword;
            prevState.passwordType = prevState.showPassword  ? "text" : "password";
            return prevState
        })
      }

    validateLoginForm(event){
        event.preventDefault();
        const userName = this.state.loginUserName;
        const password = this.state.loginPassword;  
        
        if( userName == "" || password == ""){
            Helper.pushError("Please fill all required fields")
            return false;
        }
        const data = {username:userName,password:password}
        this.setState({
            loading:true
        })   
        this.props.login(data,this.props.history);
        event.preventDefault();
    }

    validateForm(event){
        event.preventDefault();
        const fullName = this.state.fullName;
        const email = this.state.email;
        const password = this.state.password;   
        const confirmPassword = this.state.confirmPassword;
        const captchaCode = this.state.captchaCode;
        const linkedinKey = this.state.linkedinKey;
        const cn = this.state.cn;

        let { errors ,ext, mobile} = this.state;
        let isValid = true;

        if(Helper.isEmpty(fullName)){
            errors["fullName"]= "This field is mandatory";
            isValid = false; 
        }else{
            errors["fullName"]= "";
        }
        if(Helper.isEmpty(email)){
            errors["email"]= "This field is mandatory";
            isValid = false; 
        }else{
            errors["email"]= "";
        }
        if(!Helper.isValidEmail(email)){
            errors["email"]= "Please enter a valid email";
            isValid = false; 
        }else{
            errors["email"]= "";
        }
        if(Helper.isEmpty(ext) && Helper.isEmpty(linkedinKey)){
            errors["ext"]= "This field is mandatory";
            isValid = false; 
        }else{
            errors["ext"]= "";
        }

        if(Helper.isEmpty(cn)){
            errors["ext"]= "Please enter a valid country code";
            isValid = false; 
        }else{
            errors["ext"]= "";
        }

        

        if(Helper.isEmpty(mobile) && Helper.isEmpty(linkedinKey)){
            errors["mobile"]= "This field is mandatory";
            isValid = false; 
        }else{
            errors["mobile"]= "";
        }

        if(Helper.isEmpty(password)){
            errors["password"]= "This field is mandatory";
            isValid = false; 
        }else{
            errors["password"]= "";
        }

        if(Helper.isEmpty(confirmPassword)){
            errors["confirmPassword"]= "This field is mandatory";
            isValid = false; 
        }else{
            errors["confirmPassword"]= "";
        }

        
        if(!isValid){
            Helper.pushError("Please fill all required fields");
            this.setState({errors:errors})
            return false;
        }


        // if(Helper.isEmpty(fullName) || Helper.isEmpty(email)  || Helper.isEmpty(password) || Helper.isEmpty(confirmPassword) ){            
        //     Helper.pushError("Please fill all required fields");
        //     return false;
        // }
        
        if(password != confirmPassword){
            return false;
        }
        this.setState({
            loading:true,
            error:undefined,
        })
      
        const regData = {
                    fullName:fullName,
                    email:email,
                    password:password,
                    confirmPassword:confirmPassword,
                    mobile:mobile,
                    mobileCode:ext,
                    captchaCode:captchaCode,
                    linkedinKey:linkedinKey,
                    authProvider: !Helper.isEmpty(linkedinKey) ? "linkedIn" : "local"
                }
               
        this.props.register(regData,this.props.history);
        event.preventDefault();
    }


  
  render() {

    let verifiedPassword = this.state.verifiedPassword;
    let error =  this.state.error;
    let mode = this.props.mode;
    let registerStyle = this.props.registerStyle;
    let loading = this.state.loading;
    let showForm = this.state.linkedinKey ? false : true;
    let page = this.props.page;
    let subPage = this.props.subPage;
    
    let auth = this.state.auth;    
    if(auth.isAuthenticated){
        return Helper.afterAuthRedirect(this.props.location);
    }
    
    let from = this.props.from;

    let {
        isMinimumChar,
        isOneNumber,
        isOneUpperCase,
        isOneLowerCase,
        isOneSplChar,
        errors,
        passwordType,
        showPassword
    } = this.state;

  
    return (
      <div>
         {  page == "newHome"  ?
                    subPage == "login" ? 
                    <div>
                        <div>
                            <div className="m-t-15 clearfix">
                            <LinkedInAuth label="Log In with LinkedIn"/>
                        </div>
                        <h4 className="box-title m-b-20 m-t-20 smallAuto font-medium line-header">Or</h4>
                                <form>
                                <div className="row">
                                    <div className="col-md-12  col-sm-12 m-t-5">
                                        <div className="form-group">
                                                <label className="go-away" htmlFor="loginUserName" >Email/Mobile No.</label>
                                                <input className="form-control font-14 loginInput" type="email" required="" value={this.state.loginUserName} name="loginUserName" id="loginUserName" onChange={this.handleChange} maxLength="255" placeholder="Email"/> 
                                        </div>
                                        {/* <div className="font-12">
                                                <label className="text-black font-medium"><input type="checkbox" className="normal-checkbox" onChange={this.onCheck}/> Remember Me</label>
                                        </div> */}
                                    </div>
                                    <div className="col-md-12 col-sm-12 m-t-5">
                                        <div className="form-group">
                                                <label className="go-away" htmlFor="loginPassword" >Password</label>
                                                <input className="form-control font-14 loginInput" type="password" required=""    value={this.state.loginPassword} name="loginPassword"  id="loginPassword" onChange={this.handleChange} maxLength="255" placeholder="Password"/> 
                                                <div className="text-right l-h-0 m-t-5">
                                                    <a href="/forgot-password" id="to-recover" className="text-dark hover-enable font-12 font-medium">
                                                        Forgot password?
                                                    </a> 
                                                </div>
                                        </div>
                                        
                                    </div>
                                
                                    <div className="col-md-12 col-sm-12  m-t-5">
                                        <div className="form-group text-center">
                                                <label className="control-label text-theme m-b-0">&nbsp;</label>
                                            <button className="registerBtn btn btn-action btn-max font-14 btn-block text-uppercase waves-effect waves-light font-bold"   onClick={this.validateLoginForm}>Login</button>
                                            </div>
                                    </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        :
                        <div>
                            <div>
                             <div className="m-t-15 clearfix">
                              <LinkedInAuth label="Register with LinkedIn"/>
                             </div>
                           <h4 className="box-title m-b-20 m-t-20 smallAuto font-medium line-header">Or</h4>

                            <div className="row">
                                 <div className="col-md-12 m-t-5 smallAuto">
                                    <div className="form-group">
                                            <input className={errors["fullName"] ? "form-control font-14 loginInput is-invalid" : "form-control font-14 loginInput is-valid"} placeholder="Full Name" type="text" required="" value={this.state.fullName} name="fullName" onChange={this.handleChange} maxLength="255"/> 
                                            <small className="text-danger">{errors["fullName"]}</small>
                                    </div>
                                </div>
                                <div className="col-md-12 m-t-5 smallAuto">
                                    <div className="form-group">
                                            <input className="form-control font-14 loginInput" placeholder="Email"  type="text" required="" value={this.state.email} name="email" onChange={this.handleChange} /> 
                                            <small className="text-danger">{errors["email"]}</small>
                                    </div>
                                </div>
                                <div className="form-group  float-left full-width">
                                        <div className="col-md-12 p-0 float-left">
                                            <div className="col-md-4 p-0 float-left">
                                                 <label htmlFor="company">Mobile No*</label>
                                                  <CountryCode name="ext" value={this.state.ext} onChange={this.onChangeExt}/>
                                                  <small className="text-danger">{errors["ext"]}</small>
                                            </div>
                                            <div className="col-md-8 p-r-0 m-p-0 float-left paralelInput">
                                                <label htmlFor="mobile">&nbsp;</label>
                                                <input type="text" 
                                                        className="form-control" 
                                                        id="mobile" 
                                                        name="mobile"
                                                        aria-describedby="mobile" 
                                                        placeholder="Mobile No*" 
                                                        onChange={this.onChange}
                                                        value={this.state.mobile}/>
                                                 <small className="text-danger">{errors["mobile"]}</small>
                                            </div>
                                        </div>
                                </div>
                                <div className="col-md-12 m-t-5 smallAuto">
                                    <div className="form-group">
                                            <input className="form-control font-14 loginInput" placeholder="Password" data-tip data-for="password" data-event="click" type="password" required="" value={this.state.password}  name="password" onChange={this.handleChange} maxLength="32"/> 
                                            <small className="text-danger">{errors["password"]}</small>
                                    </div>
                                    <ReactTooltip id="password" globalEventOff="click" className="pwToolTip" place="right" effect="solid">
                                        <div className="text-white text-left pwToolTip">
                                            <h5 className="text-white text-left">Password Requirements:</h5>
                                            <ul className="text-white float-left no-style">
                                                <li className="text-white text-left fullfiled">{isMinimumChar ? <i className="fa fa-check-circle text-green" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>} 8 characters minimum</li>
                                                <li className="text-white text-left">{isOneNumber ? <i className="fa fa-check-circle text-green" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>} one number</li>
                                                <li className="text-white text-left">{isOneUpperCase ? <i className="fa fa-check-circle text-green" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>} one uppercase letter</li>
                                                <li className="text-white text-left">{isOneLowerCase ? <i className="fa fa-check-circle text-green" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>} one lower case letter</li>
                                                <li className="text-white text-left">{isOneSplChar ? <i className="fa fa-check-circle text-green" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>} one special character</li>
                                            </ul>
                                       </div>
                                    </ReactTooltip>
                                    
                                </div>
                                <div className="col-md-12 m-t-5 smallAuto">
                                    <div className="form-group">
                                            <input className="form-control font-14 loginInput" placeholder="Confirm password" type="password" required="" value={this.state.confirmPassword}  name="confirmPassword" onChange={this.handleChange} onInput={this.confirmPassword} maxLength="32"/> 
                                            <small className="text-danger">{errors["confirmPassword"]}</small>
                                    </div>
                                </div>
                                    { !verifiedPassword ? 
                                        <div className="form-group text-center font-12 col-12 p-0 m-b-0">
                                            <div className="text-danger text-center">Password does not match</div> 
                                        </div>
                                            : "" 
                                     }
                                 <div className="col-md-12">
                                    <div className="col-xs-12 smallAuto p-0 col-md-8 col-lg-8 m-a m-t-10">
                                        <center>
                                            <Recaptcha
                                                sitekey={config.captchaKey}
                                                verifyCallback={this.verifyCallback}
                                                render="explicit"
                                                ref={e => recaptchaInstance = e}
                                                onloadCallback={this.callback}
                                                size="normal"
                                            />
                                        </center>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-lg-12 m-a smallAuto p-0">
                                    <div className="form-group text-center m-t-20">
                                            <button className="registerBtn btn btn-action btn-max font-14 btn-block text-uppercase waves-effect waves-light font-bold" onClick={this.validateForm}>Create Account</button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                      </div>

                    :


      <div className={!Helper.isEmpty(registerStyle) ? registerStyle+" login-register customCard" : "login-register customCard"}>
         { mode == "homepage" ?
                <div className="col-lg-6 m-a auth-card m-t-20 auth-bottom m-b-20">
                    <div className="card-body">
                        {/* <p className="font-medium text-default m-0 text-center">Already have an account? <Link to="/login" className="text-info">Log in</Link></p> */}
                        <form>
                          <div className="row">
                                 <div className="col-md-6  col-sm-12 m-t-5 p-0">
                                    <div className="form-group">
                                            <label className="control-label text-black m-b-0" htmlFor="email">Email</label>
                                            <input className="form-control font-14" type="email" required="" value={this.state.loginUserName} name="loginUserName" onChange={this.handleChange} maxLength="255"/> 
                                    </div>
                                    <div className="font-12">
                                            <label className="text-black font-medium"><input type="checkbox" className="normal-checkbox" onChange={this.onCheck}/> Remember Me</label>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12 m-t-5 p-0-m">
                                    <div className="form-group">
                                            <label className="control-label text-black m-b-0" htmlFor="password">Password</label>
                                            <input className="form-control font-14" type="password" required=""    value={this.state.loginPassword} name="loginPassword" onChange={this.handleChange} maxLength="255"/> 
                                    </div>
                                    <div className="text-right l-h-0">
                                       <a href="/forgot-password" id="to-recover" className="text-dark hover-enable font-12 font-medium">
                                             Forgot password?
                                        </a> 
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-12  m-t-5 p-0">
                                    <div className="form-group">
                                    <label className="control-label text-black m-b-0">&nbsp;</label>
                                      <button className="registerBtn btn btn-action btn-max font-14 btn-block text-uppercase waves-effect waves-light"   onClick={this.validateLoginForm}>Login</button>
                                    </div>
                                </div>
                        </div>
                        
                        </form>
                    </div>
                </div>
                :
                ""
            }
            <div className={from == "auth-pane" ? "col-lg-12 authPane" : "col-lg-6 m-a auth-card extra-shadow p-0"}>          
                <div className={from == "auth-pane" ? "" : "card-body"}>
                    {from == "auth-pane" ?  "" :<h1 className="m-t-40 font-normal heading">Join Investera Today.</h1>}
                    {from == "auth-pane" ? 

                     ""
                     :
                     <p className="font-medium desc">
                        Create an account to personalize your homepage, 
                        find investment opportunities and follow the people, 
                        companies and industries that matter to you.
                    </p>

                    }
                    
                    {showForm ?
                        <div className="col-md-12  float-left">
                            <div className="col-md-6 float-left"><LinkedInAuth /></div>
                            <div className="col-md-6 float-left"><GoogleAuth label="Login with Google" url={config.gOuthUrl}/></div>
                            {/* <div className="col-md-4 float-left"><LinkedInAuth /></div> */}
                        </div>
                        :
                        ""   
                    }
                    <div className="clearfix" />
                    <LoadingOverlay>
                        <Loader loading={loading} fullPage></Loader>
                        <form className="m-t-20 clearfix" id="registerForm" >
                            <br/>
                        {showForm ? <h4 className="box-title m-b-20  clearfix smallAuto font-medium line-header">Or</h4> : "" }                            
                        {
                            error ? 
                                <div className="alert alert-danger m-b-40">
                                    {error}
                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">×</span> </button>
                                </div>
                            :
                            ""
                        }
                        
                        {showForm ?
                            <div className="row">
                                 <div className="col-md-12 m-t-5 smallAuto">
                                    <div className="form-group">
                                            <label className="control-label text-black m-b-0" htmlFor="fullName">Full Name</label>
                                            <input className={!Helper.isEmpty(errors["fullName"]) ? "form-control font-14 is-invalid" : "form-control font-14"} type="text" required="" value={this.state.fullName} name="fullName" onChange={this.handleChange} maxLength="255"/> 
                                            <small className="text-danger">{errors["fullName"]}</small>
                                    </div>
                                </div>
                                <div className="col-md-12 m-t-5 smallAuto">
                                    <div className="form-group">
                                            <label className="control-label text-black m-b-0" htmlFor="email">Email</label>
                                            <input className={!Helper.isEmpty(errors["email"]) ? "form-control font-14 is-invalid" : "form-control font-14"} type="text" required="" value={this.state.email} name="email" onChange={this.handleChange} /> 
                                            <small className="text-danger">{errors["email"]}</small>
                                    </div>
                                </div>
                                <div className="col-md-12 m-t-5 smallAuto">
                                    <div className="form-group  float-left full-width">
                                            <div className="col-md-12 p-0 float-left">
                                                <div className="col-md-4 p-0 float-left mobileCodeDiv">
                                                    <label htmlFor="company" className="control-label text-black m-b-0">Mobile No*</label>
                                                    <CountryCode name="ext" value={this.state.ext} onChange={this.onChangeExt} className={"m-t-5 homeElement"}/>
                                                    <small className="text-danger">{errors["ext"]}</small>
                                                </div>
                                                <div className="col-md-8 p-r-0 m-p-0 float-left mobileNumberDiv m-m-t-5">
                                                    <label htmlFor="mobile" className="reg-mob">&nbsp;</label>
                                                    <input type="text" 
                                                            className={!Helper.isEmpty(errors["mobile"]) ? "form-control font-14 is-invalid" : "form-control font-14"}
                                                            id="mobile" 
                                                            name="mobile"
                                                            aria-describedby="mobile" 
                                                            placeholder="" 
                                                            onChange={this.handleChange}
                                                            value={this.state.mobile}/>
                                                    <small className="text-danger">{errors["mobile"]}</small>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                <div className="col-md-12 m-t-5 smallAuto">
                                    {/* <div className="form-group">
                                            <label className="control-label text-black m-b-0" htmlFor="password">Password</label>
                                            <input className={!Helper.isEmpty(errors["password"]) ? "form-control font-14 is-invalid" : "form-control font-14"} data-tip data-for="password" data-event="click" type="password" required="" value={this.state.password}  name="password" onChange={this.handleChange} maxLength="32"/> 
                                            <small class="text-danger">{errors["password"]}</small>
                                    </div> */}

                                    <div class="input-group mb-3 pwCont">
                                        <label className="control-label text-black m-b-0 pwLabel">Password</label>
                                        <br/>
                                        <input className="form-control" type={passwordType} required=""  name="password" data-tip data-for="password" data-event="click" required="" value={this.state.password}   onChange={this.handleChange} maxLength="32" />
                                        <small class="text-danger">{errors["password"]}</small>
                                        <div class="input-group-append">
                                        <button class="input-group-button showPwBtn" onClick={this.showPassword}>
                                            {!showPassword ? <i class="fa fa-eye" aria-hidden="true"></i> : <i class="fa fa-eye-slash" aria-hidden="true"></i>}
                                        </button>
                                        </div>
                                    </div>


                                    <ReactTooltip id="password" globalEventOff="click" className="pwToolTip" place="right" effect="solid">
                                        <div className="text-white text-left pwToolTip">
                                            <h5 className="text-white text-left">Password Requirements:</h5>
                                            <ul className="text-white float-left no-style">
                                                <li className="text-white text-left fullfiled">{isMinimumChar ? <i className="fa fa-check-circle text-green" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>} 8 characters minimum</li>
                                                <li className="text-white text-left">{isOneNumber ? <i className="fa fa-check-circle text-green" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>} one number</li>
                                                <li className="text-white text-left">{isOneUpperCase ? <i className="fa fa-check-circle text-green" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>} one uppercase letter</li>
                                                <li className="text-white text-left">{isOneLowerCase ? <i className="fa fa-check-circle text-green" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>} one lower case letter</li>
                                                <li className="text-white text-left">{isOneSplChar ? <i className="fa fa-check-circle text-green" aria-hidden="true"></i> : <i className="fa fa-times-circle text-danger" aria-hidden="true"></i>} one special character</li>
                                            </ul>
                                       </div>
                                    </ReactTooltip>
                                    
                                </div>
                                <div className="col-md-12 m-t-5 smallAuto">
                                    <div className="form-group">
                                            <label className="control-label text-black m-b-0" htmlFor="confirmPassword">Confirm password</label>
                                            <input className={!Helper.isEmpty(errors["confirmPassword"]) ? "form-control font-14 is-invalid" : "form-control font-14"} type="password" required="" value={this.state.confirmPassword}  name="confirmPassword" onChange={this.handleChange} onInput={this.confirmPassword} maxLength="32"/> 
                                            <small className="text-danger">{errors["confirmPassword"]}</small>
                                    </div>
                                </div>
                                    { !verifiedPassword ? 
                                        <div className="form-group text-center font-12 col-12 p-0 m-b-0">
                                            <div className="text-danger text-center">Password does not match</div> 
                                        </div>
                                            : "" 
                                     }
                                
                            </div>
                        :
                        ""
                        }
                        

                        <div className="form-group">
                            <div className="col-xs-12 smallAuto p-0 col-md-8 col-lg-8 m-a m-t-10">
                                <center>
                                    <Recaptcha
                                        sitekey={config.captchaKey}
                                        verifyCallback={this.verifyCallback}
                                        render="explicit"
                                        ref={e => recaptchaInstance = e}
                                        onloadCallback={this.callback}
                                        size="normal"
                                    />
                                </center>
                            </div>
                        </div>
                        <div className="col-xs-12 col-lg-12 m-a smallAuto p-0">
                            <div className="form-group text-center m-t-20">
                                    <button className="registerBtn btn btn-action btn-max font-14 btn-block text-uppercase waves-effect waves-light" onClick={this.validateForm}>Create Account</button>
                            </div>
                        </div>
                        { mode == "homepage" || from == "auth-pane" ?
                                ""
                                :
                                <div className="form-group p-3 extra-shadow p-0">
                                    <div className="col-sm-12 text-center">
                                        <p className="text-center">Already have an account?  <a href={"/login?referal="+this.state.referal} className="text-info m-l-5"><b>Sign in</b></a></p>
                                    </div>
                                </div>

                        }

                    {from == "auth-pane" ?
                        <p className="m-t-10 font-mb-10 text-center">By clicking on Register, you agree to <a href="">Terms of Service</a> and <a href={config.dataCdnStatic +'privacy_policy.pdf?'} target="_blank">Privacy Policy</a> </p>
                        : 
                    ""
                    }
                        
                    </form>

                    </LoadingOverlay>
                   
                  
                </div>
            </div>
           
        </div>
    
         }   

</div>
 
    )
  }
}

const mapStateToProps = (state) =>({
    registerData:state.registerData,
    errors:state.errors,
    auth:state.auth
});
export default connect(mapStateToProps,{register, login, registerAuthenticated, fetchUserInfo ,removeError})(withRouter(Register));
