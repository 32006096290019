import React, { Component } from "react";
import Helper from "../../shared/custom";

export default class EducationHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      educationHistory: []
    };
  }

  componentWillReceiveProps(props) {
    let educationHistory = props.educationHistory;

   // if (!Helper.isEmpty(educationHistory)) {
      this.setState(
        {
          educationHistory: !Helper.isEmpty(educationHistory) ? educationHistory : []
        },
        function() {}
      );
   // }
  }

  render() {
    let educationHistory = this.state.educationHistory;
    return (
      <div>
        <div className="card text-left" id="education">
           <div className="card-header text-left light-blue-header"><h5 className="m-b-0 m-t-0 text-white">Education History</h5></div>
          <div className="card-body">
            <div className="clearfix" />
            <div>
              <div className="result">
                <div className="row">
                  <div className="col-lg-12 col-md-6 m-b-20">
                    <div>
                      <div className="col-md-12 p-0">
                        {
                         !Helper.isEmpty(educationHistory) ?
                          <div className="table-responsive">
                          <h5>Education</h5>
                          <table className="table color-table muted-table  m-t-10 font-14">
                            <thead>
                              <tr>
                                <th>Insitute Name</th>
                                <th>Degree</th>
                                <th>Subject</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              { !Helper.isEmpty(educationHistory) ?
                                educationHistory.map((r, i) => (
                                  <tr key={i}>
                                    <td>{r.institutionName}</td>
                                    <td>{r.degree ? r.degree.name : ""}</td>
                                    <td>{r.subject ? r.subject.name : ""}</td>
                                    <td>{r.startDate ? Helper.getDateInInputFormat(r.startDate) : "-"}</td>
                                    <td>{r.endDate ? Helper.getDateInInputFormat(r.endDate) : "-"}</td>
                                  </tr>
                                ))
                                :
                                 <tr>
                                    <td colspan="5" className="text-center">No Data Available</td>
                                  </tr>
                              }
                            </tbody>
                          </table>
                        </div>
                        :
                        <div className="table-responsive text-center m-t-10">
                          No Data Available
                        </div>
                        
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
