import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import { connect } from 'react-redux';
import CompanyDetailsContent from './CompanyDetailsContent';
import CompanyDetailsRight from './CompanyDetailsRight';
import Sidemenu from '../sidemenu/Sidemenu';
import Helpers from "../../shared/custom";

import { getMetaData } from '../../actions/metaAction';
import { getSectorData } from '../../actions/businessAction';
import store from '../../store';


class CompanyDetailPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            location: props.location,
            lastPageState: null,
            companyDetails: null,
            uri: undefined,
            action: null,

            metaList: undefined,
            business: undefined
        }

        store.subscribe(() => {
            var storeState = store.getState();
            var localMetaList = this.state.metaList;
            var storeMetaList = storeState.meta.metaList;

            if (!localMetaList || JSON.stringify(localMetaList) != JSON.stringify(storeMetaList)) {
                this.setState({ metaList: storeMetaList });
            }
            var localBusiness = this.state.business;
            var storeBusiness = storeState.business.data;
            if (!localBusiness || JSON.stringify(localBusiness) != JSON.stringify(storeBusiness)) {
                this.setState({ business: storeBusiness });
            }

        });
    }
    componentDidMount() {

        var storeState = store.getState();
        let meta = storeState.meta;
        let business = storeState.business;

        if (Helpers.isEmpty(business.data) == true) {
            this.props.getSectorData();
        } else {
            this.setState({ business: business.data });
        }

        if (!Helpers.isEmpty(this.props.location.action)) {
            this.setState({
                action: this.props.location.action
            })
        }


        let localMetaList = JSON.parse(localStorage.getItem("metaList"));
        if (Helpers.isEmpty(localMetaList)) {
            this.props.getMetaData();
        } else {
            let localMetaList = JSON.parse(localStorage.getItem("metaList"));
            this.setState(prevState => {
                prevState.metaList = localMetaList;
                return { prevState }
            })
        }


        let lastPageState = this.state.location.state;

        let existingUri = this.state.uri;
        let uri = this.props.match.params.uri;

        if (existingUri && existingUri == uri) {
            return false;
        }

        if (!uri) {
            this.props.history.push({
                pathname: '/create-company/start'
            });
        }


        if (uri == "new") {

            //lastPageState = this.state.location.state;
            if (!lastPageState || !lastPageState.sector) {
                this.props.history.push({
                    pathname: '/create-company/start'
                });
            }
            this.setState({
                lastPageState: lastPageState
            })

        } else {

            Axios.get(
                Helpers.apiURL({
                    uri: "company/:uri/details",
                    pathVar: { uri: uri }
                })
            )
                .then((response) => {
                    console.log("RES =>", response);
                    let company = response.data ? response.data.result ? response.data.result : null : null;
                    if (response.data && (response.data.status == 401 || response.data.status == 403)) {
                        Helpers.pushTError("COMP_NOT_FOUND");
                        this.props.history.push("/errorpage/404");
                        return;
                    }
                    if (!company) {
                        Helpers.pushTError("COMP_NOT_FOUND");
                    } else {
                        this.setState({
                            companyDetails: company
                        })
                    }
                })
                .catch((err) => { Helpers.pushTError("COMP_NOT_LOADED"); this.props.history.push("/errorpage/404"); });

        }

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (JSON.stringify(nextProps) != JSON.stringify(prevState.localProps)) {
            let localMetaList = JSON.parse(localStorage.getItem("metaList"));
            if (!Helpers.isEmpty(localMetaList)) {
                prevState.metaList = localMetaList;
                return { prevState }
            }
        }
    }


    render() {
        let lastPageState = this.state.lastPageState;
        let lastPageProps = this.props.location ? this.props.location.state : null;
        let fromWhere = !Helpers.isEmpty(lastPageProps) ? lastPageProps.modeFrom : "";
        let location = window.location.pathname;
        let action = Helpers.lastSegment(location);
        let path = this.props.match.path;
        let companyDetails = this.state.companyDetails;


        return (
            <div>
                <div className="container-fluid">
                    <div className="row content-in m-t-80 p-t-10">
                        <div className="col-lg-3 col-xlg-3 col-md-3 searchMenu sideMenuCont p-0">
                            {
                                fromWhere == "create" || action == "new" ?
                                    <Sidemenu mode="companyCreate"
                                        lastPageState={
                                            lastPageState
                                        }
                                    />
                                    :
                                    <Sidemenu mode={path == "/company-details/:uri" ? "editCompany" : "company"}
                                        lastPageState={lastPageState}
                                        companyDetails={companyDetails}
                                    />
                            }

                        </div>
                        <div className="col-lg-6 col-xlg-6 col-md-6 centerDiv">
                            <CompanyDetailsContent
                                lastPageState={this.state.lastPageState}
                                companyDetails={this.state.companyDetails}
                                metaList={this.state.metaList}
                                business={this.state.business}
                            />
                        </div>
                        <div className="col-lg-3 col-xlg-3 col-md-2 m-t-2 right-side">
                            <CompanyDetailsRight lastPageState={this.state.lastPageState} companyDetails={this.state.companyDetails} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default connect(null, { getMetaData, getSectorData })(withRouter(CompanyDetailPage));