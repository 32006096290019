import React, { Component } from 'react'
import Helper from "../../shared/custom";
import store from "../../store";
import $ from  'jquery';
import Helpers from '../../shared/custom';
import Axios from 'axios';
import config from "../../shared/config";
// import Scrollspy from 'react-scrollspy'

export default class Profilemenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: {},
      overview: [],
      page: null,
      entityUri: null,
      startup: null,
      loading: true,
      privileges:{},
      localProps:{},
      type:null,
      news:null,
      similarCompanies:[],
      isOwner:false
    };

    store.subscribe(() => {
      var storeState = store.getState();
      var entityState = storeState.entity || null;
      var company = entityState ? entityState.company : null;
      if (!company) {
        return;
      }
      if (company.entityUri == this.state.entityUri) {
        return;
      }

      let users = storeState.auth.user;
  

      this.setState({
        entityInfo:company,
        overview: company.entityInfo.company,
        aquisitions:company.entityInfo.companyAquisitions,
        page: company.entityInfo.page,
        entityUri: company.entityUri,
        type: company.type,
        startup: company.entityInfo.startup,
        funding:storeState && storeState.fundRound? storeState.fundRound.data : [],
        auth: storeState.auth,
      },()=>{
        this.fetchSimilarCompanies();
        if(this.state.auth.isAuthenticated){
          let page = this.state.page;
          let ownerDetails = page && page.owner ? page.owner.id : null;
          let isOwner = (users.id == ownerDetails || users.adminOwner);
          this.setState({
            isOwner:isOwner
          })
        }
       
      });

    });
  }

  componentDidMount(){
    setTimeout(() => {
      this.enableScroll();
    }, 4000);
    
  }

  componentWillReceiveProps(nextProps){
      //if(JSON.stringify(this.state.localProps) != JSON.stringify(nextProps)){

        // var storeState = store.getState();
        // let users = storeState.auth.user;
        // let page = this.state.page;
        // let ownerDetails = page && page.owner ? page.owner.id : null;

        // let isOwner = (users.id == ownerDetails || users.adminOwner);

        // this.setState({
        //   localProps:nextProps,
        //   isOwner:isOwner
        // })

        
    //  }
  }

  fetchSimilarCompanies=()=>{
    Axios.get(
        Helper.apiURL({
            uri:"ws/similar/company/:pageUri",
            pathVar:{pageUri:this.state.entityUri,pageType:this.state.type},
        })
    )
    .then( (response) => {           
        let data = response.data && response.data.result ? response.data.result : [];
        this.setState({
            similarCompanies:data
        })
      })
    .catch((err) => {
    });
}

      fetchRelatedNews = (industries) =>{
        var obj = {
            industryIds:industries,
        }
        
        Axios.post(
            config.api("news/search/all?limit=10"),
            obj)
        .then( (response) => {
            var news = response && response.data && response.data.result && response.data.result.feeds ? response.data.result.feeds : [];
            this.setState({
                news : news
            })
        })
        .catch((err) => {
          
        });

      }

  enableScroll = ()=>{
    var offset = 120;
    var $root = $('html, body');
      $('.nav-side li a').click(function(event) {
        event.preventDefault();
        var href = $.attr(this, 'href');
        
        $('.nav-side li a').removeClass('active')
        $(this).addClass('active')
        if(!Helpers.isEmpty($(href).offset())){
            $root.animate({
                scrollTop: $(href).offset().top - (offset)
            }, 500, function () {
            });
        }
    });
      $('body').scrollspy({
        target:".nav-side",
        offset:380
    });
  }
  componentWillReceiveProps(props){
    if(JSON.stringify(props.privileges) != JSON.stringify(this.state.privileges)){
      this.setState({
        privileges:props.privileges
      });
    
      setTimeout(() => {
        this.enableScroll();
      }, 4000);
    }
  }



  render() {
    let page = this.state.page;
    let overview = this.state.overview;
    let pageTypes = page && page.pageTypes?page.pageTypes:[];
    let companyType = overview && overview.companyType ? overview.companyType:null;
    let companyAquisitions = !Helpers.isEmpty(this.state.aquisitions) ? this.state.aquisitions : [];
    let privileges = this.state.privileges;
    let funding = this.state.funding;
  

    let entityInfo = this.state.entityInfo;
   // let financial = entityInfo.entityInfo.financialData;

    let revenue =  overview && overview.financialData && overview.financialData.revenue ? overview.financialData.revenue : null;
    let grossProfit =  overview && overview.financialData && overview.financialData.grossProfit ? overview.financialData.grossProfit : null;
    let netIncome =  overview && overview.financialData && overview.financialData.netIncome ? overview.financialData.netIncome : null;
  
    let isOwner = this.state.isOwner;
    let isStartup = Helper.isStartupCompany(pageTypes);

    return (
      <div>
        <ul className="nav nav-pills nav-side nav-stacked list-style-none components profileMenu">
                     { privileges &&  privileges["SUM"] &&
                        <li>
                            <a className={"nav-link-- inv-nav-link"} href="#summary"> 
                                Summary
                            </a>
                        </li>
                        }

                       { privileges &&  privileges["CLASS"] &&
                        <li>
                            <a className="nav-link-- inv-nav-link" href="#classification"> 
                            Classification
                            </a>
                        </li>}

                        <li>
                            <a className="nav-link-- inv-nav-link" href="#pands"> 
                                Product &amp; Services
                            </a>
                        </li> 

                        { privileges && privileges["AED_CI"] ?
                          <li>
                            <a className="nav-link-- inv-nav-link" href="#overview"> 
                                Overview
                            </a>
                        </li> 
                        :
                        ""

                        }

                       
                        
                        {/* {
                          overview && !Helper.isEmpty(overview.financialData) && (overview.financialData.revenue || overview.financialData.grossProfit || overview.financialData.netIncome) ?
                            <li>
                            <a className="nav-link-- inv-nav-link" href="#financial"> 
                              Financial Details
                            </a>
                            </li> 
                            :
                            ""
                        }
                        */}
                        {/* {isStartup? (
                          <li>
                              <a className="nav-link-- inv-nav-link" href="#startup"> 
                              Startup Details
                              </a>
                          </li>
                          ):""} */}

                        {/* {page && ( page.website || page.contactEmails || page.contactNumbers || page.addressDto || page.facebook || page.twitter ||  page.linkedIn) && */}
                         <li>
                            <a className="nav-link-- inv-nav-link" href="#contact"> 
                            Contact
                            </a>
                        </li>
                        {/* } */}
                        {/* {
                          entityInfo && entityInfo.count_funding_round > 0  && privileges &&  privileges["FUND_INFO"]?
                                <li>
                                  <a className="nav-link-- inv-nav-link" href="#funding"> 
                                    Funding
                                  </a>
                              </li>
                              :
                              ""
                        } */}
                        {/* {!isStartup && entityInfo &&  entityInfo.count_investments > 0 && privileges &&  privileges["FUND_INFO"]?  (
                        <li>
                            <a className="nav-link-- inv-nav-link" href="#investments"> 
                              Investments
                            </a>
                        </li>
                          ):""}
                         {
                           companyAquisitions.length > 0 && privileges &&  privileges["FUND_INFO"] ?(
                            <li>
                                <a className="nav-link-- inv-nav-link" href="#acq"> 
                                      Acquisitions
                                </a>
                            </li>
                        ):""} */}
                        {/* {privileges && privileges["FKP_BOD"]? 
                          overview && !Helpers.isEmpty(overview.founders) ? 
                          <li>
                              <a className="nav-link-- inv-nav-link" href="#founders"> 
                              Founders
                              </a>
                          </li>
                          :
                          ""
                        :
                        ""}
                        {privileges && privileges["FKP_BOD"]? 
                           entityInfo && entityInfo.count_team_members > 0 ? 
                            <li>
                                <a className="nav-link-- inv-nav-link" href="#team"> 
                                  Team Members
                                </a>
                            </li>
                            :
                            ""
                        :
                        ""}
                        {privileges && privileges["FKP_BOD"]? 
                          entityInfo && entityInfo.count_bod > 0  ?
                              <li>
                                  <a className="nav-link-- inv-nav-link" href="#bod"> 
                                    Board of Directors
                                  </a>
                              </li>
                              :
                              ""
                        :
                        ""
                        } */}
                       
                       
                        {/* {
                          funding && funding.noOfInvestors > 0  && privileges &&  privileges["FUND_INFO"]  &&
                          <li>
                              <a className="nav-link-- inv-nav-link" href="#investors"> 
                                Investors
                              </a>
                          </li>
                       } */}
                       
{/* 
                        {Helper.isPublicCompany(companyType)? (
                        <li>
                            <a className="nav-link-- inv-nav-link" href="#ipo"> 
                            IPO Details
                            </a>
                        </li>
                        ):""} */}

                        {privileges &&  privileges["EVT"] && entityInfo && entityInfo.count_events > 0 ? 
                          <li className="eventsMenu">
                              <a className="nav-link-- inv-nav-link" href="#events"> 
                                Events
                              </a>
                          </li>
                          :
                          ""
                        }

                       {/* {
                          privileges &&  privileges["ADD_INFO"] ? */}
                            <li>
                              <a className="nav-link-- inv-nav-link" href="#verificationDetails"> 
                                  Verification Details
                              </a>
                          </li>

                           {isOwner &&
                              <li>
                                <a className="nav-link-- inv-nav-link" href="#documents"> 
                                    Documents
                                </a>
                              </li>
                           }
                           
                              
                              {/* :
                              ""
                        } */}
                         
                          {/* {
                          privileges &&  privileges["RPTS"] ?
                            <li className="eventsMenu" >
                              <a className="nav-link-- inv-nav-link" href="#relatedReports"> 
                               Related Reports
                              </a>
                          </li> 
                          :
                          ""
                         } */}
                         {/* {
                          privileges && privileges["NWS"] && this.state.news &&   this.state.news.length > 0 && <li className="">
                              <a className="nav-link-- inv-nav-link" href="#relatedNews"> 
                                Related News
                              </a>
                           </li>
                        } */}
                          {/* {this.state.similarCompanies && this.state.similarCompanies.length > 0 &&  */}
                          <li className="">
                              <a className="nav-link-- inv-nav-link" href="#similarCompanies"> 
                                 Similar Companies
                              </a>
                          </li>
                          {/* } */}
                    
         </ul>
      </div>
    )
  }
}
