import React, { Component } from 'react';
import Helper from '../../shared/custom';
import countries from "../../shared/country";
import Select from 'react-select';
import ApiHelper from '../../shared/ApiHelper';


export default class SubForm2 extends Component {
    constructor(props){
        super(props);
        this.state = {
            countryOptions:[],
            cityOptions:[]
        }  

        this.onCountryChange = this.onCountryChange.bind(this);
        this.setCityList = this.setCityList.bind(this);
    }

    componentDidMount(){        
        let countryOptions = Helper.makeSelectOptions(countries);
        this.setState({
            countryOptions:countryOptions            
        })
    }

    onCountryChange(option,e){
        ApiHelper.getCities(option.value,this.setCityList);
        let onSelectChange = this.props.onSelectChange; 
        if(onSelectChange){
            onSelectChange(option,e);
        }
        this.setState({cityOptions:[]});
    }

    setCityList(cityList){
        this.setState({cityOptions:Helper.makeSelectOptions(cityList)});
    }

    render() {    
    
    let metaList = this.props.metaList;
    let companySizeOptions = Helper.makeSelectOptions(metaList.CSI);
    //let industryOptions = Helper.makeSelectOptions(this.state.industryList);

    return (
      <div>
          <div className="form-body opp-form">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="control-label isMandatoryLabel">Company Name</label>
                            <input type="text" name="name"  className="form-control" value={this.props.values.name}  placeholder="Your company name" onChange={this.props.onInputChange}/>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="control-label isMandatoryLabel">Legal Name</label>
                            <input type="text" name="legalName"  className="form-control" value={this.props.values.legalName}  placeholder="Legal name of your company" onChange={this.props.onInputChange}/>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="control-label isMandatoryLabel">Company Size</label>
                            <Select
                                name="companySize"
                                class="select-2"   
                                options={companySizeOptions}
                                value={this.props.values.companySize}  
                                onChange={this.props.onSelectChange}
                                placeholder="Choose company size"
                            />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="control-label ">Parent Company</label>
                            <input type="text" name="parentCompany"  className="form-control" placeholder="Optionally, enter parent company name" onChange={this.props.onInputChange}/>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="control-label isMandatoryLabel">Headquarters - Country</label>
                            <Select
                                name="headquartersCountry"
                                class="select-2"
                                options={this.state.countryOptions}
                                value={this.props.values.headquartersCountry}
                                onChange={this.onCountryChange}
                                placeholder="Choose country"
                            />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="control-label isMandatoryLabel">Headquarters - City/Town</label>
                            <Select
                                name="headquartersCity"
                                class="select-2"                                                           
                                options={this.state.cityOptions}
                                value={this.props.values.headquartersCity}      
                                onChange={this.props.onSelectChange}
                                placeholder="Choose city"
                            />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group">
                            <label className="control-label isMandatoryLabel">Founded Year</label>
                            <input type="text" name="founded" className="form-control" value={this.props.values.founded}  placeholder="Year in which your company was founded" onChange={this.props.onInputChange}/>
                        </div>
                    </div>
                    
                </div>
             </div>
      </div>
    )
  }
}
