

import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import Helper from "../../shared/custom";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import SectorFilter from "../wigets/SectorFilter";

import UserFeedRightPanel from '../homepage/UserFeedRightPanel';

export default class CompanyRightpanel extends Component {
  constructor(props){
      super(props);
      this.state={
          criteriaUpdate:undefined,
          business:null,
          metaList:[]
      }
  
}

componentWillReceiveProps(props){
  this.setState({
    criteriaUpdate:props.criteriaUpdate,
    business:props.business,
    metaList:props.metaList
  });
}

// openEventsSectorPage(e){
//   // this.props.history.push('/events/sector/'+e.target.name);
//   var sectorId = e.target.name;
// }



  render() {
    let isLoggedIn = this.props.isLoggedIn;
    let sectorsList = null;
    if (this.state.business){
      sectorsList = this.state.business.sectorsArray;
    }
     
 
    return (
      <div>
        {isLoggedIn?
          //  <SectorFilter type="companies"/>
              this.props.context == "companyBySector" 
                ?
                <SectorFilter type="companies"/>
                :
                <UserFeedRightPanel />
            :
            <div className="card text-left bold-headers">
            <div className="card-body m-a">
                <a href="/register" className="btn btn-info">Register New User</a>
            </div>
        </div>

        }
            
      </div>
    )
  }
}
