import React, { Component } from "react";
import Sidemenu from "../sidemenu/Sidemenu";
import Helper from "../../shared/custom";
import {PeopleMeta} from "../../shared/MetaHelper";


import Axios from "axios";
import UserProfileView from "./UserProfileView";
import Rightpanel from "./Rightpanel";

export class CreatePeopleDetailsNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      peopleDetails: {},
      stats:{},
      uri: "",
      pageDetails: {},
      view: "default",
      newUri:undefined
    };

    this.fetchPeopleData = this.fetchPeopleData.bind(this);
  }

  componentDidMount() {
    let uri = this.props.match.params.uri;
    this.fetchPeopleData(uri);
  }


  static getDerivedStateFromProps(nextProps, prevState){
    let newUri = nextProps &&  nextProps.match && nextProps.match.params ? nextProps.match.params.uri  : undefined;
    if(!Helper.isEmpty(newUri)){
      if(newUri !== prevState.uri){
        return { uri: newUri};
     }
    }
    else return null;
  }


  componentDidUpdate(prevProps){
    if (prevProps.match.params.uri != this.state.uri) {
       this.fetchPeopleData(this.state.uri);
    }
  }

  fetchPeopleData(uri){
    if (!uri) {
      this.props.history.push({
        pathname: "/"
      });
    }

    this.setState({ uri: uri });
    window.scrollTo(0, 0);

    Axios.get(
      Helper.apiURL({
        uri: "people/:uri",
        pathVar: { uri: uri },
        query:{facets:"page,people"}
      })
    )
      .then(response => {
        let result = response.data.result || null;
        if (!Helper.isEmpty(result)) {
          this.setState({
            peopleDetails: result.people,
            pageDetails: result.page,
            stats:{"viewsCount":result._viewCount}
          });
        } else {
          this.setState({
            peopleDetails: null
          });
        }

      })
      .catch(err => {
        //Helper.pushTError("PROFILE_NOT_FOUND");
      });
  }
 
  render() {
    let uri = this.state.uri;
    let peopleDetails = this.state.peopleDetails;
    let pageDetails = this.state.pageDetails;
    let stats = this.state.stats;
    let entityName = peopleDetails?peopleDetails.name:"";
    let description = pageDetails && pageDetails.banner && pageDetails.banner.primary?pageDetails.banner.primary:"";
    let image = pageDetails && pageDetails.image?Helper.dataPath(pageDetails.image):"";
    
    return (
      <div>
        <PeopleMeta
          entityUri = {entityName}
          description = {description}
          image = {image}
        />
        <div>
          <div className="row content-in m-t-80 p-t-10">
            <div className="col-lg-3 col-xlg-3 col-md-3 sideMenuCont">
             <Sidemenu mode="people" />
            </div>
            <div className="col-lg-6 col-xlg-6 col-md-6 centerDiv">
              <UserProfileView
                pageDetails={pageDetails}
                peopleDetails={peopleDetails}
                uri={uri}
                stats={stats}
              />
            </div>
            <div className="col-lg-3 col-xlg-3 col-md-3 m-t-2">
              <Rightpanel uri={uri}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreatePeopleDetailsNew;
