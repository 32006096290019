import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Route,
  Switch,
  withRouter
} from "react-router-dom";
import Content from "../components/content/Content";
import SearchPage from "../components/search/SearchPage";
import PrivateRouter from "../utils/PrivateRouter";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import Notifications from "../components/Notifcation/Notifications"
import Forgotpw from "../components/auth/Forgotpw";
import Resetpw from "../components/auth/Resetpw";
import ChangePassword from "../components/auth/ChangePassword";
import Register from "../components/auth/Register";
import Welcome from "../components/auth/Welcome";
import Sectors from "../components/auth/Sectors";
import Role from "../components/auth/Role";
import Companies from "../components/auth/Companies";
import Home from "../components/homepage/Home";
import AboutUs from "../components/new_home/AboutUs";
import Membership from "../components/new_home/Membership";
import Features from "../components/new_home/Features";
import Loading from "../components/auth/Loading";
import Opportunities from "../components/opportunity/Opportunities";
import OpportunityDetails from "../components/opportunity/OpportunityDetails";
import OpportunityDetailsNew from "../components/opportunity/OpportunityDetailsNew";
import CreateOpportunities from "../components/Opportunities/CreateOpportunities";

import CreateEvent from "../components/events/CreateEvent";
import EventDetails from "../components/events/EventDetails";
import CreateCompany from "../components/createCompany/CreateCompany";
import CompanyDetailPage from "../components/createCompany/CompanyDetailPage";
import CreateEventDetails from "../components/events/CreateEventDetails";
import AddPost from "../components/post/AddPost";
import EventSummary from "../components/eventSummary/EventSummary";
import OppSummary from "../components/Oppsummary/OppSummary";
import EmailConfirm from "../components/auth/EmailConfirm";
import CompanySummary from "../components/companiessummary/CompanySummary";
import UserProfile from "../components/userprofile/UserProfile";
import UserProfileNew from "../components/userprofile/UserProfileNew";
import CreatePeopleDetailsNew from "../components/people/CreatePeopleDetailsNew";
import ManageFinancials from "../components/financials/ManageFinancials";
import AdvanceSearch from "../components/search/AdvanceSearch";

import NewsFeed from "../components/newsfeed/NewsFeed";
import NewsDetails from "../components/newsfeed/NewsDetails";
import D3Relations from "../components/graph/D3Relations";
import PostDetails from "../components/newsfeed/PostDetails";
import Bookmarks from "../components/Bookmarks/Bookmarks";

import SubscriptionNew from "../components/subscription/SubscriptionNew";
import Subscription from "../components/subscription/Subscription";
import Unsubscribe from "../components/subscription/Unsubscribe";
import PaymentStatus from "../components/subscription/PaymentStatus";
import ProceedPay from "../components/subscription/ProceedPay";
import ProceedPayPublic from "../components/subscription/ProceedPayPublic";

import PaymentSummary from "../components/subscription/PaymentSummary";
import ContactUs from "../components/subscription/ContactUs"
import FeedDetails from "../components/wigets/FeedDetails";

import NewsLetterPublic from '../components/Newsletters/NewsLetterPublic';
import NotFound from '../components/ErrorPages/NotFoundHeadless';
// import Faq from '../components/faq/Faq';
import Faq from '../components/new_home/Faq';
import Contact from "../components/new_home/Contact"
import Report from '../components/reports/Report';
import ViewReport from '../components/reports/ViewReport';
import AllReports from '../components/reports/AllReports';
import FundingDetails from '../components/companyDetails/FundingDetails';

import Summary from "../components/Opportunities/Summary";
import Details from "../components/Opportunities/Details";
import MyOpp from "../components/Opportunities/MyOpp/MyOpp";
import InterestedOpp from "../components/Opportunities/MyOpp/InterestedOpp";
import ProceedPayOpp from "../components/Opportunities/ProceedPayOpp";



import CompanyPrintPage from "../components/companyDetails/CompanyPrintPage";


import Poc from "../components/dev/poc";
import Card from "../components/people/Card";
import Upload from "../components/people/Upload";
import NextForm from "../components/Opportunities/NextForm";


import Auth from "../components/auth/Auth";
import VerifyEmail from "../components/auth/VerifyEmail";
import store from '../store';

const routes = [
  {
    path: "/",
    exact: true,
    component: Home
  },
  {
    path: "/features",
    exact: true,
    component: Features
  },
  
  {
    path:"/about",
    exact:true,
    component:AboutUs
  },
  {
    path:"/membership",
    exact:true,
    component:Membership
  },
  {
    path:"/faq",
    exact:true,
    component:Faq
  },
  {
    path:"/contact",
    exact:true,
    component:Contact
  },
  {
    path: "/login",
    exact: true,
    component: Auth
  },
  {
    path:"/auth",
    exact:true,
    component:Auth
  },
  {
    path: "/forgot-password",
    exact: true,
    component: Forgotpw
  },
  {
    path: "/reset-password",
    component: Resetpw
  },
  {
    path: "/change-password",
    exact: true,
    component: ChangePassword
  },
  {
    path: "/linkedin",
    exact: true,
    component: LinkedInPopUp
  },
  {
    path: "/register",
    exact: true,
    component: Register
  },
  {
    path: "/user/confirm/email/:id?",
    exact: true,
    component: EmailConfirm
  },
  {
    path: "/company",
    component: Content,
    exact: false
  },
  {
    path: "/company-print/:uri",
    component: CompanyPrintPage,
    exact: true
  },
  {
    path: "/search",
    exact:true,
    component: SearchPage,
  },
  {
    path: "/event/:uri",
    component: EventDetails,
    exact: false
  },
  {
    path: "/events",
    component: EventSummary,
  },
  {
    path: "/companies",
    component: CompanySummary
  },
  {
    path: "/people/:uri",
    component: CreatePeopleDetailsNew
  },
  {
    path:"/subscription",
    exact:true,
    component:SubscriptionNew
  },
  {
    path:"/subscribe",
    exact:true,
    component:Subscription
  },
  {
    path:"/subscribe/upgrade",
    exact:true,
    component:Subscription
  },
  {
    path: "/public/newsletter/:newsletterId",
    component: NewsLetterPublic
  },
  {
    path: "/errorpage/404",
    component: NotFound,
    exact:true
  }, 
  {
    path: "/news/:uri",
    component: NewsDetails,
    exact: true
  },
  {
    path: "/event-details/:uri",
    component: CreateEventDetails,
    persistSearch:true,
  },
  // {
  //   path: "/faq",
  //   component: Faq
  // },
  {
    path:"/report",
    component:Report,
    exact:true,

  },
  {
    path:"/report/:uri",
    component:AllReports,
    exact:true,

  },
  {
    path:"/report/view/:uri",
    component:ViewReport,
    exact:true,

  },
  {
    path:"/dev",
    component:Poc,
    // exact:true,

  },
  {
    path:"/proceed/payment",
    component:ProceedPayPublic,
     exact:true,

  }, 
  {
    path: "/payment",
    component: PaymentStatus,
  },
  {
    path: "/unsubscribe/:type/:token",
    component: Unsubscribe,
  },
  {
    path:"/opportunity/proceed",
    component:ProceedPayOpp,
    exact:true
  }
];

const privateRoutes = [
  {
    path: "/event/:private/:uri",
    component: EventDetails,
    exact: true
  },
  {
    path: "/search/advanced",
    exact:true,
    component: AdvanceSearch
  },
  {
    path: "/welcome",
    component: Welcome
  },
  {
    path: "/register/sectors",
    component: Sectors
  },
  {
    path: "/register/role",
    component: Role
  },
  {
    path: "/register/follow",
    component: Companies
  },
  {
    path: "/register/loading-page",
    component: Loading
  },
  {
    path: "/create-opportunity/start",
    component: CreateOpportunities,
    exact: true
  },
  {
    path: "/create-opportunity/next",
    component: NextForm,
    exact: true
  },
  {
    path: "/opportunity",
    component: OpportunityDetails,
    exact: true
  },
  {
    path: "/opportunity-details/:uri",
    component: OpportunityDetailsNew,
    exact: false
  },
  {
    path: "/create-company/start",
    component: CreateCompany
  },
  // {
  //   path: "/profile-details/new",
  //   component: CreatePeopleDetails
  // },
  // { To be removed, just check if the related modules are used anywhere else before deletion
  //   path: "/profile-details/:uri", //As people entity
  //   component: CreatePeopleDetails
  // },
  {
    path: "/company-details/:uri",
    component: CompanyDetailPage,
    persistSearch:true,
  },
  {
    path: "/create-event/start",
    component: CreateEvent
  },
  {
    path: "/create-post/add",
    component: AddPost
  },
  {
    path: "/user-details/:uri", //Normal Registered profile
    component: UserProfile
  },
  {
    path: "/user/:uri",
    component: UserProfileNew,
    persistSearch:true,
  },
  {
    path: "/financials/:uri",
    component: ManageFinancials,
    persistSearch:true,
  },
  
  {
    path: "/news",
    component: NewsFeed,
    exact: true
  },
  {
    path: "/notifications",
    component: Notifications,
    exact: true
  },
  {
    path: "/graph",
    component: D3Relations,
    exact: true
  },
  {
    path: "/post/:uri",
    component: PostDetails,
    exact: true
  },
  {
    path:"/feed/details/:uri",
    component:FeedDetails,
    exact: true
  },
  {
    path: "/bookmarks",
    component: Bookmarks,
    exact: true
  },
  {
    path:"/proceed",
    component:ProceedPay,
    exact:true
  }
  ,{
    path: "/subscription/details",
    component: PaymentSummary,
  },
  {
    path:"/verify-email",
    exact:true,
    component:VerifyEmail
  },
  {
    path:"/verify-email/finish",
    exact:true,
    component:VerifyEmail
  },
  {
    path:"/contact",
    component:ContactUs
  },
 {
    path:"/company-funding-details/:entityUri",
    component: Content,
 },
 {
  path:"/company-funding-details/:entityUri",
  component: Content,
 },
 {
  path:"/people-details/create",
  component: Card,
  exact:true
 },
 {
  path:"/people-details/upload",
  component: Upload,
  exact:true
 },
 {
  path:"/opportunity/:id",
  component:Details,
  exact: true
 },
 {
   path:"/my-opportunities",
   component:MyOpp,
   exact: true
 },{
  path: "/opportunities",
  component:Summary,
  exact: true
 },{
   path:"/opportunities/interested",
   component:InterestedOpp,
   exact: true
 }

];

class Approuter extends Component {
  constructor(props){
    super(props);
   this.state = {
    isEmailVerified:true,
    userAuthState:{},
    isAuthenticated:{},
    resetPassword:false,
   }
    store.subscribe(() => { 
      var storeState = store.getState();
            if(this.state.userAuthState != storeState.auth.user){
              this.setState({
                isAuthenticated:storeState.auth.isAuthenticated,
                userAuthState:storeState.auth.user,
                isEmailVerified:storeState.auth.user.emailConfirmed,
                resetPassword:storeState.auth.user.resetPassword
              });  
            }            
    }); 
  }
  getRouteComponent = (route)=>{
  
    let {isEmailVerified,isAuthenticated,userAuthState,resetPassword} = this.state;


    if(userAuthState){

      if(!isEmailVerified && isAuthenticated){
        return (VerifyEmail)
      }
      // if(!resetPassword){
      //   return Resetpw
      // }
      if(userAuthState.registrationCompleted == false){
        if(route.path == "/register/follow"){
          return Companies
        }
        if(route.path == "/register/role"){
          return Role
        }
        if(route.path == "/register/loading-page"){
          return Loading
        }
        return (Sectors)
      }
      return route.component;
    }
    return route.component;
  }
  render() {
 
    return (
      <div>
        <Switch>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component = {route.component}
          />
        ))}
        
          {privateRoutes.map((route, index) => {
            return(
            <PrivateRouter
              key={index + "private"}
              path={route.path}
              exact={route.exact}
              component = {this.getRouteComponent(route)}
            />
            
          )})}

          <Route  component={NotFound}/>
        </Switch>
      </div>
    );
  }
}

export default withRouter(connect()(Approuter));
