import { GET_CURRENT_PLAN, GET_PAGE_VIEWS }  from '../actions/types';

const initialState = {
    packageDetails : null,
    privileges:undefined,
    privilegesLoaded:false,
    pageViewsLeft:1,
    pageViews:0,
}

export default function( state = initialState, action){
    switch(action.type){
        case GET_CURRENT_PLAN :
            let packageDetails = action.payload;
        
            // if(!packageDetails.userPrivileges && packageDetails.privileges){
            //     packageDetails.userPrivileges = packageDetails.privileges
            // }
            
            let privileges =  packageDetails.userPrivileges || []; 
            let pageViewsLeft = packageDetails.pageViewsLeft;
            let pageViews = packageDetails.pageViews;
            let privilegeMap = {};
            privileges.forEach((data,i) => {
                privilegeMap[data.featureCode] = data
            });

            return {
                ...state,
                packageDetails:action.payload,
                privileges:privilegeMap,
                privilegesLoaded:true,
                pageViewsLeft:pageViewsLeft,
                pageViews:pageViews
            }

        case GET_PAGE_VIEWS :
            let stats = action.payload;  
            return {
                ...state,
                pageViewsLeft:stats.pageViewsLeft,
                pageViews:stats.pageViews
            }
        default:
        return state;
    }
}