import React, { Component } from 'react';
import config from "../../shared/config";
import Helper from '../../shared/custom';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import FeedCards from '../homepage/FeedCards';
import { LoadingOverlay, Loader } from 'react-overlay-loader';

import UserFeedRightPanel from '../homepage/UserFeedRightPanel';
import Sidemenu from '../sidemenu/Sidemenu';

export default class FeedDetails extends Component {
  constructor(props){
      super(props);
      this.state= {
          feedUniqueId:undefined,
          data:{},
          loading:false,
      }
      this.getFeedDetails = this.getFeedDetails.bind(this);
  }


  componentWillReceiveProps(props){
    let url = this.props.location;
    if(props.location != this.props.location){
        let feedUniqueId =  Helper.lastSegment(url.pathname);
        this.getFeedDetails(feedUniqueId)
    }
  }

  componentDidMount(){
      let url = this.props.location;
      let feedUniqueId =  Helper.lastSegment(url.pathname)
      this.getFeedDetails(feedUniqueId)
  }

  getFeedDetails(feedUniqueId){
    this.setState({
        loading:true,
      })
      Axios.get(
        Helper.apiURL({
            uri:"user/feed/:feedUniqueId",
            pathVar:{feedUniqueId:feedUniqueId},
        })
    )
    .then( (response) => {           
        let data = response.data.result;
        this.setState({
            loading:false,
            data:data
        })
    })
    .catch((err) => { 
        this.setState({
            loading:false,
          })
    });
  }
  render() {
    let data = [this.state.data];
    let loading = this.state.loading;
    
    return (
        <div>
             <LoadingOverlay>
                <Loader fullPage loading={loading}/>
            </LoadingOverlay>
            <div className="row content-in m-t-80 p-t-10">
                    <div className="col-lg-3 col-xlg-3 col-md-3 sideMenuCont">
                         <Sidemenu/>
                    </div>
                    <div className="col-lg-6 col-xlg-6 col-md-6 centerDiv">
                        <FeedCards data={data} />
                    </div>
                    <div className="col-lg-3 col-xlg-3 col-md-3 m-t-2 right-side">
                        <UserFeedRightPanel/>
                    </div>
            </div>
        </div>
    )
  }
}
