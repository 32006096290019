import Axios from 'axios';

const setAuthTokenHeader = token =>{
    /*return true;*/
    if(token){
        Axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
    }else{
        delete Axios.defaults.headers.common['Authorization'];
    }
}

export default setAuthTokenHeader;