import React from 'react';
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';

export default class PopoverElm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false,
      placement:"top"
    };
  }

  open = () => {
    this.setState({
      popoverOpen: true
    });
  }
  close = () => {
    this.setState({
      popoverOpen: false
    });
  }


  toggle = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  render() {
    let target  = this.props.target;
    let {popoverOpen,placement} = this.state;

    
    return (
      <React.Fragment>
        <i className="fa fa-info-circle pointer" id={target} onMouseOver={this.open} onMouseLeave={this.close} style={{display: 'inline-flex',fontSize: "14px"}}></i>
        <Popover placement={placement} isOpen={popoverOpen} target={target}>
          {this.props.children}
        </Popover>
      </React.Fragment>
    );
  }
}