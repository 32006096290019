import React, { Component } from 'react'
import Helper from "../../shared/custom";
import {Link} from 'react-router-dom';
import StartRating from '../ui/StarRating';

export default class SearchBannerCard extends Component {

  render() {
    let card = this.props.card;


    return (
      <div className="card card-body">
      <div className="row p-10">
          <Link to={card.url ? card.url :''}>
            <img src={Helper.dataPath(card.image)} className="img-circle m-l-5" width="48"  height="48" />
          </Link>              
          <div className="col-md-8 col-lg-8 col-sm-12 text-left m-t-5">
              <h3 className="box-title m-b-0 text-info font-14 l-h-18 font-normal">
                <Link  to={card.url ? card.url :''}>
                  {card.name}
                </Link>
              </h3> 


              {/* {this.props.type && this.props.type != "people"? */}
                <div className="text-black font-14">                
                  {!Helper.isEmpty(card.companyUri) && card.title ? <Link className="" to={card.companyUri}>{card.title}</Link>  : card.title}
                </div>
               
              {card.star ?
                      <StartRating pointerStyle={card.pointerStyle ="noLink"} rating={!Helper.isEmpty(card.ranking) ? card.ranking : 0} />
                      :
                      ""
               } 
                
              {/* : */}
                <div className="text-grey font-14">                
                      {card.subTitle ? " - "+ card && !Helper.isEmpty(card.companyUri) ? <Link className="text-black" to={card.companyUri}>{card.subTitle}</Link> : card.subTitle : ""}
                </div>
              {/* }                 */}
              {card.description? 
                <div className="m-b-10">
                    <small className="col-xs-5">{Helper.getCleanText(Helper.truncate(card.description,100))}</small>
                </div>
               : ""}
          </div>
          <div className="col-md-2 col-lg-2 col-sm-12  p-0">
              <div className="button-box m-t-10 float-right p-0">                  
                  <Link  to={card.url ? card.url :''}>
                    <button className="btn btn-theme-white btn-wide">View</button>
                  </Link>
              </div>
          </div>
      </div>
      </div>
    )
  }
}