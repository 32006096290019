import React, { Component } from 'react';
import OwlCarousel, { Options } from 'react-owl-carousel';
import config from '../../shared/config';
import Helper from "../../shared/custom";

class Searchrightpanel extends Component {
    constructor(){
        super();
        this.state ={
          options: {
            loop: true,
            margin: 10,
            nav:true,
            autoplay:true,
            autoplaySpeed:400,
            items:1,
        },
        scrollItems:1.5
        }
      } 
      componentDidMount(){
        if(Helper.isMobile()){
          this.setState({
            scrollItems :1
          })
        }
      }
     
  render() {
    return (
      <div>
          <div className="card">
            <div className="card-body">
                 <h4 className="font-bold text-left">Trending Opportunities</h4>

                 <OwlCarousel ref="car" className="owl-theme" items={this.state.scrollItems} autoplay="true" loop margin={10} autoWidth={false} nav={true} option={this.state.options} >
                      <div>
                            <img className="card-img-top img-responsive" src={config.cdn +'theme/images/users/1.jpg'} alt="Card image cap" />
                            <div className="card-body text-left font-12">
                                <h4 className="card-title font-bold">One JBR</h4>
                                <span className="font-10">Real Estate - 12 People Interested</span>
                                <p className="card-text">An eye-catching icon against the Dubai skyline and the gatekeeper to JBR, this 
                                    statuesque tower features luxurious 2, 3  and 4 bedroom apartments and 5 
                                    bedroom penthouses. </p>
                                <div className="like-comm text-left"> 
                                            <a href="javascript:void(0)" className="link m-r-10 text-primary">
                                            <i className="fa fa-star"></i> Interested</a> 
                                </div>
                            </div>
                           
                      </div>
                      <div >
                            <img className="card-img-top img-responsive" src={config.cdn +'theme/images/users/2.jpg'} alt="Card image cap" />
                                <div className="card-body text-left font-12">
                                    <h4 className="card-title font-bold">One JBR</h4>
                                    <span className="font-10">Real Estate - 12 People Interested</span>
                                    <p className="card-text">An eye-catching icon against the Dubai skyline and the gatekeeper to JBR, this 
                                        statuesque tower features luxurious 2, 3  and 4 bedroom apartments and 5 
                                        bedroom penthouses. </p>
                                        <div className="like-comm text-left"> 
                                                <a href="javascript:void(0)" className="link m-r-10 text-primary">
                                                <i className="fa fa-star"></i> Interested</a> 
                                        </div>
                                </div>
                               
                      </div>
                      <div >
                            <img className="card-img-top img-responsive" src={config.cdn +'theme/images/users/3.jpg'} alt="Card image cap" />
                                <div className="card-body text-left font-12">
                                    <h4 className="card-title font-bold">One JBR</h4>
                                    <span className="font-10">Real Estate - 12 People Interested</span>
                                    <p className="card-text">An eye-catching icon against the Dubai skyline and the gatekeeper to JBR, this 
                                        statuesque tower features luxurious 2, 3  and 4 bedroom apartments and 5 
                                        bedroom penthouses. </p>

                                        <div className="like-comm text-left"> 
                                                <a href="javascript:void(0)" className="link m-r-10 text-primary">
                                                <i className="fa fa-star"></i> Interested</a> 
                                        </div>
                                </div>
                                
                      </div>
                      <div>
                            <img className="card-img-top img-responsive" src={config.cdn +'theme/images/users/4.jpg'} alt="Card image cap" />
                                <div className="card-body text-left font-12">
                                    <h4 className="card-title font-bold">One JBR</h4>
                                    <span className="font-10">Real Estate - 12 People Interested</span>
                                    <p className="card-text">An eye-catching icon against the Dubai skyline and the gatekeeper to JBR, this 
                                        statuesque tower features luxurious 2, 3  and 4 bedroom apartments and 5 
                                        bedroom penthouses. </p>
                                        <div className="like-comm text-left"> 
                                                <a href="javascript:void(0)" className="link m-r-10 text-primary">
                                                <i className="fa fa-star"></i> Interested</a> 
                                        </div>
                                </div>
                               
                      </div>
                  </OwlCarousel>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
               <h4 className="font-bold text-left">Top Companies</h4>
                    <ul className="nav nav-pills  inner-div nav-justified m-t-30 m-b-30 full-width">
                        <li className="nav-item">
                                <a href="#all" className="nav-link active float-left" data-toggle="tab">All</a> 
                        </li>
                        <li className="nav-item">
                                <a href="#private" className="nav-link float-left" data-toggle="tab">Private</a>
                        </li>
                        <li className="nav-item"> 
                            <a href="#public" className="nav-link float-left" data-toggle="tab">Public</a>
                        </li>
                        <li className="nav-item"> 
                            <a href="#startup" className="nav-link float-left" data-toggle="tab">Startups</a>
                        </li>
                    </ul>
            <div className="tab-content text-left tab-side">
                    <div id="all" className="tab-pane fade in active show">
                    <ul className="list-group list-group-full">
                        <li className="list-group-item">
                                <span>
                                <img src={config.cdn +'/theme/images/users/2.jpg'} className="img-circle pull-left m-r-5  float-left" width="35" />
                                <span className="font-normal v-a">Cras justo odio</span>
                                </span>
                            <span className="float-right">
                                    <span className="font-bold">93</span>
                                    <span className="font-12 text-megna font-medium"> +3</span>
                            </span>
                        </li>
                        <li className="list-group-item">
                                <span>
                                <img src={config.cdn +'/theme/images/users/2.jpg'} className="img-circle pull-left m-r-5  float-left" width="35" />
                                <span className="font-normal v-a">Cras justo odio</span>
                                </span>
                            <span className=" float-right">
                                    <span className="font-bold">93</span>
                                    <span className="font-12 text-megna font-medium"> +3</span>
                            </span>
                        </li>
                        <li className="list-group-item">
                                <span>
                                <img src={config.cdn +'/theme/images/users/2.jpg'} className="img-circle pull-left m-r-5  float-left" width="35" />
                                <span className="font-normal v-a">Cras justo odio</span>
                                </span>
                            <span className="float-right">
                                    <span className="font-bold">93</span>
                                    <span className="font-12 text-megna font-medium"> +3</span>
                            </span>
                        </li>
                        <li className="list-group-item">
                                <span>
                                <img src={config.cdn +'/theme/images/users/2.jpg'} className="img-circle pull-left m-r-5  float-left" width="35" />
                                <span className="font-normal v-a">Cras justo odio</span>
                                </span>
                            <span className=" float-right">
                                    <span className="font-bold">93</span>
                                    <span className="font-12 text-megna font-medium"> +3</span>
                            </span>
                        </li>
                        
                    </ul> 
                    </div>
                    <div id="private" className="tab-pane fade in">
                        2
                    </div>
                    <div id="public" className="tab-pane fade in ">
                        3
                    </div>
                    <div id="startup" className="tab-pane fade in">
                        4
                    </div>
             </div>
            <div className="col-md-12 text-right m-t-10">
                <a href="">View All Companies</a>
            </div>

            </div>
          </div>

      </div>
    )
  }
}

export default  Searchrightpanel;