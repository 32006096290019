import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { login, fetchUserInfo, logoutUser  } from '../../actions/authAction';
import ApiHelper from "../../shared/ApiHelper";
import store from '../../store';
import Helpers from '../../shared/custom';
import STR from '../../shared/strings';
import Axios from 'axios';
import { LoadingOverlay, Loader } from 'react-overlay-loader';

class Interest extends Component {
  constructor(props,context){
      super(props);
      this.state = {
          auth:{},
          isAuthenticated:false,
          
          uri:undefined,
          type:undefined,
          id:undefined,
          loading:false,

          interested:false,
      }

      store.subscribe(() => {
        var storeState = store.getState();
        var auth = storeState.auth;
        let isAuthenticated = auth.isAuthenticated?true:false;        
        this.setState({
            auth:auth, 
            isAuthenticated:isAuthenticated
        });
      }); 

      
      this.performAction = this.performAction.bind(this);
      this.interestAction = this.interestAction.bind(this);
  }

  componentDidMount(){
    
    let storeState = store.getState();
    let isAuthenticated = storeState.auth && storeState.auth.isAuthenticated ? true : false;

    this.setState({
        uri:this.props.uri,
        type:this.props.type,
        id:this.props.id,
        interested:this.props.interested,
        isAuthenticated:isAuthenticated,
        auth:storeState.auth
    },() => {
        
    })
  }

  componentWillReceiveProps(nextProps){
    
    let currentUri = this.state.uri;
    let currentType = this.state.type;
    let currentId = this.state.id;

  
    if(nextProps.type == currentType && nextProps.uri == currentUri && nextProps.id == currentId){
        return false;
    }
    
    
    this.setState({
        uri:this.props.uri,
        type:this.props.type,
        id:this.props.id,        
        interested:this.props.interested
    },() => {
        
    })

  }

  performAction(e){
    let dataSet = e.currentTarget.dataset;
    if(dataSet.interest == 'true'){
        this.interestAction(false);
    }else{
        this.interestAction(true);
    }
  }

  interestAction(actionType){
    let id = this.state.id;
    let type = this.state.type;
    
    if(!id){
        return false;
    }
    this.setState({
      loading:true
    })
    Axios.post(
        Helpers.apiURL({
            uri:"user-interests"
        }),
        {
            objectType:type,
            objectIds:[id],
            interested:actionType
        }
    )
    .then( (response) => {        
        this.setState({interested:actionType,loading:false});

    })
    .catch((err) => {
        this.setState({
          loading:false
        })
    });
  }

 

  
  render() {
    let masterClassName = this.props.masterClassName || "float-left";
    let interest = this.state.interested;
    let id = this.state.id;
    let btnText = STR.INTERESTED;
    let loading = this.state.loading;

    if(Helpers.isEmpty(id)){
        return(<div></div>);
    }

    let mode = this.props.mode;
    let btnClass;

    if(interest && mode != "btn"){
        btnClass = "text-blue";
    }
    else if(interest && mode=="btn"){
        btnClass = "golden-btn"
    }
    else{
      if(mode == "feed"){
         btnClass = "text-muted";
       }else if(mode == "btn"){
        //btnClass = "btn-theme-white";
        btnClass="golden-btn-border"
      }
      else{
        btnClass ="text-interest";
      }
    }

    const getContent = () => {
      switch(mode){
        case "feed":
          return <a data-interest={interest} onClick={this.performAction} href="javascript:void(0)" className={'link p-15 '+btnClass}>
                      <i className="fa fa-star v-text font-16"></i> <span className="v-text">{btnText}</span>
                 </a> 
        case "btn" :
            return <button data-interest={interest} onClick={this.performAction} className={'btn btn-interest '+btnClass+' '+masterClassName}>
                         <i className="fa fa-star"></i> {btnText}
                    </button> 
        default :
           return  <div className={masterClassName}>
                      <a data-interest={interest} onClick={this.performAction} href="javascript:void(0)" className={'link '+btnClass}>
                          <i className="fa fa-star"></i> {btnText}
                      </a> 
                    </div>
      
      }
    };

   
    return (
      <span> 
        <LoadingOverlay>
           <Loader fullPage loading={loading}/>
        </LoadingOverlay>
        {getContent()}
      </span>
    )
  }

}

export default connect(null,{login,fetchUserInfo,logoutUser}) (withRouter (Interest));