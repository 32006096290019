import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import config from '../../shared/config';

import Helper from '../../shared/custom';
import ApiHelper from "../../shared/ApiHelper";
import $ from 'jquery';
import countries from "../../shared/country";
import Select from 'react-select';

class Forms extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: {},
            countryOptions: [],
            cityList: [],
            industryList: [],

            title: "",
            industry: [],
            subType: undefined,
            budgetRange: undefined,
            country: undefined,
            city: undefined,
            deadline: null,
            orgDeadline: null

        }

        this.handleDateChange = this.handleDateChange.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubTypeChange = this.handleSubTypeChange.bind(this);
        this.setCityList = this.setCityList.bind(this);
    }

    handleInputChange(e) {
        var elem = e.target;
        if (e.target.value == "") {
            elem.classList.add('invalid-field');
        } else {
            elem.classList.remove('invalid-field');
        }
        //this.state[elem.name] = elem.value;
        this.setState({ [e.target.name]: e.target.value })
    }

    handleSubTypeChange(option) {
        this.setState({ subType: option });
    }

    handleCountryChange(option) {
        this.setState({ country: option });
        let countryId = option.value;
        if (countryId) {
            ApiHelper.getCities(countryId, this.setCityList);
            this.setState({
                city: null
            })
        }
    }

    handleCityChange(option) {
        this.setState({ city: option });
    }

    handleBudgetRangeChange(option) {
        this.setState({ budgetRange: option });
    }

    handleIndustryChange(option) {
        this.setState({ industry: option });
    }
    handleDateChange(date) {
        this.setState({
            deadline: date,//Helper.getDateInInputFormat(date),
            orgDeadline: date
        }, () => {
        })

    }

    setCityList(cityList) {
        this.setState({ cityList: cityList })
    }



    componentDidMount() {

        let countryList = countries;
        let countryOptions = [];
        countryList.forEach(function (item, index) {
            countryOptions.push({ value: item.id, label: item.name });
        });

        this.setState({
            type: this.props.type,
            countryOptions: countryOptions,
            industryList: this.props.industryList,
        })

    }

    validateForm(e) {
        e.preventDefault();

        if (Helper.isEmpty(this.state.title)) {
            Helper.pushError("Please enter a title for this opportunity.");
            return false;
        }

        if (Helper.isEmpty(this.state.industry)) {
            Helper.pushError("Please select an industry.");
            return false;
        }

        if (Helper.isEmpty(this.state.subType)) {
            Helper.pushError("Please select secondary investment type.");
            return false;
        }

        if (Helper.isEmpty(this.state.budgetRange)) {
            Helper.pushError("Please select investment amount.");
            return false;
        }

        if (Helper.isEmpty(this.state.city) || Helper.isEmpty(this.state.country)) {
            Helper.pushError("Please select country and city for this opportunity.");
            return false;
        }

        this.props.history.push({
            pathname: '/opportunity-details/new',
            state: this.state
        });

        // $(this.modal).modal('show');
        // $(this.modal).on('hidden.bs.modal');
    }

    render() {

        let budgetRange = this.props.budgetRange;
        let cityList = this.state.cityList;
        let cityOptions = [];
        cityList.forEach(function (item, index) {
            cityOptions.push({ value: item.id, label: item.name });
        });

        if (this.state.country && this.state.country.value) {
            cityOptions.push({ value: 'other', label: 'Other' });
        }

        let countryOptions = this.state.countryOptions;
        let type = this.props.type;
        let subTypes = type.subTypes;
        let subTypeOptions = [];
        if (subTypes) {
            subTypes.forEach(function (item, index) {
                var data = {}
                data.value = item.id;
                data.label = item.name;
                subTypeOptions.push(data);
            });
        }

        let budgetRangeList = this.props.budgetRangeList;
        let budgetRangeOptions = [];
        if (budgetRangeList) {
            budgetRangeList.forEach(function (item, index) {
                var data = {}
                data.value = item.id;
                data.label = item.name;
                budgetRangeOptions.push(data);
            });
        }

        let industryList = this.props.industryList;
        let industryOptions = [];
        if (industryList) {
            industryList.forEach(function (item, index) {
                var data = {}
                data.value = item.id;
                data.label = item.name;
                industryOptions.push(data);
            });
        }

        return (
            <div>
                <div className="card-body opp-card text-left">
                    <h4 className="card-title">{type.name}</h4>
                    <p className="card-text">{type.description}</p>
                    <form className="m-0">
                        <div className="form-body opp-form">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="control-label">Title</label>
                                        <input type="text" name="title" value={this.state.title} className="form-control" placeholder="Give your Investment Opportunity a title" onChange={this.handleInputChange} />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="control-label">Industry</label>
                                        <Select isMulti name="industry" value={this.state.industry} className="font-14 col-md-12 p-0 m-0" options={industryOptions} onChange={this.handleIndustryChange.bind(this)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="control-label">Secondary Investment Type</label>
                                        <Select name="subType" value={this.state.subType} className="font-14 col-md-12 p-0 m-0" options={subTypeOptions} onChange={this.handleSubTypeChange.bind(this)} />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="control-label">Investment Amount</label>
                                        <Select name="budgetRange" value={this.state.budgetRange} className="font-14 col-md-12 p-0 m-0" options={budgetRangeOptions} onChange={this.handleBudgetRangeChange.bind(this)} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="control-label">Country</label>
                                        <Select name="country" value={this.state.country} className="font-14 col-md-12 p-0 m-0" options={countryOptions} onChange={this.handleCountryChange.bind(this)} />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="control-label">City/Town</label>
                                        <Select value={this.state.city} className="font-14 col-md-12 p-0 m-0" options={cityOptions} onChange={this.handleCityChange.bind(this)} />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="control-label">Investment Deadline</label>
                                        <DatePicker className="form-control"
                                            selected={this.state.orgDeadline}
                                            onChange={this.handleDateChange}
                                            placeholderText="When is the deadline for Investors to contact you?"
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            dateFormat={config.displayDateFormat}
                                        />
                                    </div>
                                </div>

                                {
                                    this.state.city && this.state.city.value == 'other' ?
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="control-label">Enter City/Town</label>
                                                <input type="text" name="newCity" className="form-control" placeholder="Enter city/town name" onChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                        :
                                        ""
                                }
                                <div className="col-md-12">
                                    <button className="btn btn-info btn-md btn-custom-md" onClick={this.validateForm}>Continue</button>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="modal fade text-center" id="exampleModal" ref={modal => this.modal = modal} role="dialog" aria-labelledby="exampleModalLabel1">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">

                                <div className="modal-body m-t-80">
                                    <h2>Your Investment Opportunity has
                                        been submitted for approval.</h2>
                                    <p className="m-t-40 font-medium">We will get back to you within 24 hours</p>
                                </div>
                                <div className="modal-footer block-dom">
                                    <button type="button" className="btn btn-info btn-custom-md" data-dismiss="modal">Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default withRouter(Forms);
