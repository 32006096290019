const currencies =  [
    {"value":"1","label":"AFN","name":"Afghani"},
    {"value":"2","label":"EUR","name":"Euro"},
    {"value":"3","label":"ALL","name":"Lek"},
    {"value":"4","label":"DZD","name":"Algerian Dinar"},
    {"value":"5","label":"USD","name":"US Dollar"},
    {"value":"6","label":"AOA","name":"Kwanza"},
    {"value":"7","label":"XCD","name":"East Caribbean Dollar"},
    {"value":"8","label":"ARS","name":"Argentine Peso"},
    {"value":"9","label":"AMD","name":"Armenian Dram"},
    {"value":"10","label":"AWG","name":"Aruban Florin"},
    {"value":"11","label":"AUD","name":"Australian Dollar"},
    {"value":"12","label":"AZN","name":"Azerbaijan Manat"},
    {"value":"13","label":"BSD","name":"Bahamian Dollar"},
    {"value":"14","label":"BHD","name":"Bahraini Dinar"},
    {"value":"15","label":"BDT","name":"Taka"},
    {"value":"16","label":"BBD","name":"Barbados Dollar"},
    {"value":"17","label":"BYN","name":"Belarusian Ruble"},
    {"value":"18","label":"BZD","name":"Belize Dollar"},
    {"value":"19","label":"BMD","name":"Bermudian Dollar"},
    {"value":"20","label":"INR","name":"Indian Rupee"},
    {"value":"21","label":"BTN","name":"Ngultrum"},
    {"value":"22","label":"BOB","name":"Boliviano"},
    {"value":"23","label":"BOV","name":"Mvdol"},
    {"value":"24","label":"BAM","name":"Convertible Mark"},
    {"value":"25","label":"BWP","name":"Pula"},
    {"value":"26","label":"BRL","name":"Brazilian Real"},
    {"value":"27","label":"BND","name":"Brunei Dollar"},
    {"value":"28","label":"BGN","name":"Bulgarian Lev"},
    {"value":"29","label":"BIF","name":"Burundi Franc"},
    {"value":"30","label":"CVE","name":"Cabo Verde Escudo"},
    {"value":"31","label":"KHR","name":"Riel"},
    {"value":"32","label":"XAF","name":"CFA Franc BEAC"},
    {"value":"33","label":"CAD","name":"Canadian Dollar"},
    {"value":"34","label":"KYD","name":"Cayman Islands Dollar"},
    {"value":"35","label":"CLP","name":"Chilean Peso"},
    {"value":"36","label":"CLF","name":"Unidad de Fomento"},
    {"value":"37","label":"CNY","name":"Yuan Renminbi"},
    {"value":"38","label":"COP","name":"Colombian Peso"},
    {"value":"39","label":"COU","name":"Unidad de Valor Real"},
    {"value":"40","label":"KMF","name":"Comorian Franc "},
    {"value":"41","label":"CDF","name":"Congolese Franc"},
    {"value":"42","label":"NZD","name":"New Zealand Dollar"},
    {"value":"43","label":"CRC","name":"Costa Rican Colon"},
    {"value":"44","label":"HRK","name":"Kuna"},
    {"value":"45","label":"CUP","name":"Cuban Peso"},
    {"value":"46","label":"CUC","name":"Peso Convertible"},
    {"value":"47","label":"CZK","name":"Czech Koruna"},
    {"value":"48","label":"DKK","name":"Danish Krone"},
    {"value":"49","label":"DJF","name":"Djibouti Franc"},
    {"value":"50","label":"DOP","name":"Dominican Peso"},
    {"value":"51","label":"EGP","name":"Egyptian Pound"},
    {"value":"52","label":"SVC","name":"El Salvador Colon"},
    {"value":"53","label":"ERN","name":"Nakfa"},
    {"value":"54","label":"ETB","name":"Ethiopian Birr"},
    {"value":"55","label":"FKP","name":"Falkland Islands Pound"},
    {"value":"56","label":"FJD","name":"Fiji Dollar"},
    {"value":"57","label":"GMD","name":"Dalasi"},
    {"value":"58","label":"GEL","name":"Lari"},
    {"value":"59","label":"GHS","name":"Ghana Cedi"},
    {"value":"60","label":"GIP","name":"Gibraltar Pound"},
    {"value":"61","label":"GTQ","name":"Quetzal"},
    {"value":"62","label":"GBP","name":"Pound Sterling"},
    {"value":"63","label":"GNF","name":"Guinean Franc"},
    {"value":"64","label":"GYD","name":"Guyana Dollar"},
    {"value":"65","label":"HTG","name":"Gourde"},
    {"value":"66","label":"HNL","name":"Lempira"},
    {"value":"67","label":"HKD","name":"Hong Kong Dollar"},
    {"value":"68","label":"HUF","name":"Forint"},
    {"value":"69","label":"ISK","name":"Iceland Krona"},
    {"value":"70","label":"IDR","name":"Rupiah"},
    {"value":"71","label":"XDR","name":"SDR (Special Drawing Right)"},
    {"value":"72","label":"IRR","name":"Iranian Rial"},
    {"value":"73","label":"IQD","name":"Iraqi Dinar"},
    {"value":"74","label":"ILS","name":"New Israeli Sheqel"},
    {"value":"75","label":"JMD","name":"Jamaican Dollar"},
    {"value":"76","label":"JPY","name":"Yen"},
    {"value":"77","label":"JOD","name":"Jordanian Dinar"},
    {"value":"78","label":"KZT","name":"Tenge"},
    {"value":"79","label":"KES","name":"Kenyan Shilling"},
    {"value":"80","label":"KPW","name":"North Korean Won"},
    {"value":"81","label":"KRW","name":"Won"},
    {"value":"82","label":"KWD","name":"Kuwaiti Dinar"},
    {"value":"83","label":"KGS","name":"Som"},
    {"value":"84","label":"LAK","name":"Lao Kip"},
    {"value":"85","label":"LBP","name":"Lebanese Pound"},
    {"value":"86","label":"LSL","name":"Loti"},
    {"value":"87","label":"ZAR","name":"Rand"},
    {"value":"88","label":"LRD","name":"Liberian Dollar"},
    {"value":"89","label":"LYD","name":"Libyan Dinar"},
    {"value":"90","label":"MOP","name":"Pataca"},
    {"value":"91","label":"MKD","name":"Denar"},
    {"value":"92","label":"MGA","name":"Malagasy Ariary"},
    {"value":"93","label":"MWK","name":"Malawi Kwacha"},
    {"value":"94","label":"MYR","name":"Malaysian Ringgit"},
    {"value":"95","label":"MVR","name":"Rufiyaa"},
    {"value":"96","label":"MRU","name":"Ouguiya"},
    {"value":"97","label":"MUR","name":"Mauritius Rupee"},
    {"value":"98","label":"XUA","name":"ADB Unit of Account"},
    {"value":"99","label":"MXN","name":"Mexican Peso"},
    {"value":"100","label":"MXV","name":"Mexican Unidad de Inversion (UDI)"},
    {"value":"101","label":"MDL","name":"Moldovan Leu"},
    {"value":"102","label":"MNT","name":"Tugrik"},
    {"value":"103","label":"MZN","name":"Mozambique Metical"},
    {"value":"104","label":"MMK","name":"Kyat"},
    {"value":"105","label":"NAD","name":"Namibia Dollar"},
    {"value":"106","label":"NPR","name":"Nepalese Rupee"},
    {"value":"107","label":"NIO","name":"Cordoba Oro"},
    {"value":"108","label":"NGN","name":"Naira"},
    {"value":"109","label":"OMR","name":"Rial Omani"},
    {"value":"110","label":"PKR","name":"Pakistan Rupee"},
    {"value":"111","label":"PAB","name":"Balboa"},
    {"value":"112","label":"PGK","name":"Kina"},
    {"value":"113","label":"PYG","name":"Guarani"},
    {"value":"114","label":"PEN","name":"Sol"},
    {"value":"115","label":"PHP","name":"Philippine Peso"},
    {"value":"116","label":"PLN","name":"Zloty"},
    {"value":"117","label":"QAR","name":"Qatari Rial"},
    {"value":"118","label":"RON","name":"Romanian Leu"},
    {"value":"119","label":"RUB","name":"Russian Ruble"},
    {"value":"120","label":"RWF","name":"Rwanda Franc"},
    {"value":"121","label":"SHP","name":"Saint Helena Pound"},
    {"value":"122","label":"WST","name":"Tala"},
    {"value":"123","label":"STN","name":"Dobra"},
    {"value":"124","label":"SAR","name":"Saudi Riyal"},
    {"value":"125","label":"XOF","name":"CFA Franc BCEAO"},
    {"value":"126","label":"RSD","name":"Serbian Dinar"},
    {"value":"127","label":"SCR","name":"Seychelles Rupee"},
    {"value":"128","label":"SLL","name":"Leone"},
    {"value":"129","label":"SGD","name":"Singapore Dollar"},
    {"value":"130","label":"ANG","name":"Netherlands Antillean Guilder"},
    {"value":"131","label":"XSU","name":"Sucre"},
    {"value":"132","label":"SBD","name":"Solomon Islands Dollar"},
    {"value":"133","label":"SOS","name":"Somali Shilling"},
    {"value":"134","label":"SSP","name":"South Sudanese Pound"},
    {"value":"135","label":"LKR","name":"Sri Lanka Rupee"},
    {"value":"136","label":"SDG","name":"Sudanese Pound"},
    {"value":"137","label":"SRD","name":"Surinam Dollar"},
    {"value":"138","label":"NOK","name":"Norwegian Krone"},
    {"value":"139","label":"SZL","name":"Lilangeni"},
    {"value":"140","label":"SEK","name":"Swedish Krona"},
    {"value":"141","label":"CHF","name":"Swiss Franc"},
    {"value":"142","label":"CHE","name":"WIR Euro"},
    {"value":"143","label":"CHW","name":"WIR Franc"},
    {"value":"144","label":"SYP","name":"Syrian Pound"},
    {"value":"145","label":"TWD","name":"New Taiwan Dollar"},
    {"value":"146","label":"TJS","name":"Somoni"},
    {"value":"147","label":"TZS","name":"Tanzanian Shilling"},
    {"value":"148","label":"THB","name":"Baht"},
    {"value":"149","label":"TOP","name":"Pa?anga"},
    {"value":"150","label":"TTD","name":"Trinidad and Tobago Dollar"},
    {"value":"151","label":"TND","name":"Tunisian Dinar"},
    {"value":"152","label":"TRY","name":"Turkish Lira"},
    {"value":"153","label":"TMT","name":"Turkmenistan New Manat"},
    {"value":"154","label":"UGX","name":"Uganda Shilling"},
    {"value":"155","label":"UAH","name":"Hryvnia"},
    {"value":"156","label":"AED","name":"UAE Dirham"},
    {"value":"157","label":"USN","name":"US Dollar (Next day)"},
    {"value":"158","label":"UYU","name":"Peso Uruguayo"},
    {"value":"159","label":"UYI","name":"Uruguay Peso en Unidades Indexadas (UI)"},
    {"value":"160","label":"UYW","name":"Unidad Previsional"},
    {"value":"161","label":"UZS","name":"Uzbekistan Sum"},
    {"value":"162","label":"VUV","name":"Vatu"},
    {"value":"163","label":"VES","name":"Bol?var Soberano"},
    {"value":"164","label":"VND","name":"Dong"},
    {"value":"165","label":"XPF","name":"CFP Franc"},
    {"value":"166","label":"MAD","name":"Moroccan Dirham"},
    {"value":"167","label":"YER","name":"Yemeni Rial"},
    {"value":"168","label":"ZMW","name":"Zambian Kwacha"},
    {"value":"169","label":"ZWL","name":"Zimbabwe Dollar"},
    {"value":"170","label":"XBA","name":"Bond Markets Unit European Composite Unit (EURCO)"},
    {"value":"171","label":"XBB","name":"Bond Markets Unit European Monetary Unit (E.M.U.-6)"},
    {"value":"172","label":"XBC","name":"Bond Markets Unit European Unit of Account 9 (E.U.A.-9)"},
    {"value":"173","label":"XBD","name":"Bond Markets Unit European Unit of Account 17 (E.U.A.-17)"},
    {"value":"174","label":"XTS","name":"Codes specifically reserved for testing purposes"},
    {"value":"175","label":"XXX","name":"The codes assigned for transactions where no currency is involved"},
    {"value":"176","label":"XAU","name":"Gold"},
    {"value":"177","label":"XPD","name":"Palladium"},
    {"value":"178","label":"XPT","name":"Platinum"},
    {"value":"179","label":"XAG","name":"Silver"}
    ];
export default currencies;