import React, { Component } from 'react';
import store from '../../store';
import { login } from '../../actions/authAction';
import { withRouter ,Redirect ,Link} from 'react-router-dom';
import { connect } from 'react-redux';

class Welcome extends Component {
    constructor(props){
        super(props)
        store.subscribe(() => { 
            var storeState = store.getState();
            let authenticated = storeState.auth.isAuthenticated;
            if(!authenticated){
                this.props.history.push('/login');
            }    
         }); 

         this.handleRedirect = this.handleRedirect.bind(this);
    }  
    componentDidMount(){
        var storeState = store.getState();
        let authenticated = storeState.auth.isAuthenticated;
        if(!authenticated){
            this.props.history.push('/login');
        }
    } 
    handleRedirect(){
        // this.props.history.push('/register/sectors');
    }
  render() {
    return (
      <div>
          <div className="login-register customCard">
            <div className="col-lg-6 m-a auth-card">
                <div className="card-body m-t-40">
                    <h1 className="m-t-10 font-bold">Welcome to Investera<sup>+</sup> Africa</h1>
                    <h1 className="m-t-30 font-normal">Where Opportunities are Seized.</h1>
                    <p className="font-medium m-t-30">
                        In the next few screens you’ll need to gather some 
                        information about your investment interests so that 
                        we can personalize Investera for you
                    </p>
                    <div className="col-xs-12 col-lg-4 col-md-12 m-a">
                        <a className="btn  font-12 btn-info btn-block text-uppercase waves-effect waves-light"  href="/register/sectors">Lets get started</a>
                    </div>
                </div>
            </div>
        </div>
        
      </div>
    )
  }
}


const mapStateToProps = (state) =>({
    auth:state.auth,
    errors:state.errors
});
export default connect(mapStateToProps,{login})(withRouter(Welcome));
