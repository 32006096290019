import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link,withRouter } from "react-router-dom";
import Helpers from '../../shared/custom';

class TopMenu extends Component {

constructor(props){
    super(props);
    this.state = {
        privileges:{}
    }
    this.createPost = this.createPost.bind(this);
    this.enableClickActive = this.enableClickActive.bind(this);

}

createPost(){
    this.props.createPost()
}

componentDidMount(){
    this.setState({
        privileges:this.props.privileges
    })
    let currentPath = this.props.location;
    let construtedPath = currentPath.pathname+currentPath.search;
    
    
    setTimeout(() => {
        let menuList = document.querySelectorAll('.topLink');
        menuList.forEach((elm)=>{
            if(elm.getAttribute("href") == construtedPath){
                elm.classList.add('active');
            }else{
                elm.classList.remove('active');
            }
        });
    });
    
}
componentWillReceiveProps(props){
    if(JSON.stringify(props.privileges) != JSON.stringify(this.state.privileges)){
        this.setState({
            privileges:props.privileges
        })
    }
   
}

enableClickActive(e){
        e.preventDefault()
        let activeElm = e.currentTarget;
        let path = activeElm.getAttribute("data-href");
        let id = activeElm.getAttribute("data-id");
        let name = activeElm.getAttribute("data-name");
        let menuList = document.querySelectorAll('.topLink');
        menuList.forEach((elm)=>{
                elm.classList.remove('active');
        });
        activeElm.classList.add("active");

        let option = {};
        // if(!Helpers.isEmpty(id)){
        //     option.value = id;
        //     option.label = name;
        //  //   this.props.history.push(path);
        //  this.props.history.push({pathname: path , activeTab: "login"});
        //     // this.props.history.push({
        //     //     path,
        //     //     state:{"option":option}
        //     // });
        // }else{
            this.props.history.push(path);
        // }
        
}
   
  render() {
    let loggedInUser = this.props.loggedInUser;
    let privileges = this.state.privileges;
    let upgradeUrl = Helpers.upgradeUrl();

   

    return (
      <div className="subMenu navbar top-navbar navbar-expand-md navbar-light fixed-top">
        <div className="sub-navbar navbar-collapse">
            <div className="container">
            {loggedInUser ?
                <a href="javascript:void(0)" data-href="/" className="topLink" onClick={this.enableClickActive} title="Home">
                    <i className="mdi mdi-home font-18 vm"></i> 
                    <span className="text-md font-medium text-desktop text-white vm">Home</span>
                </a> 
            :
                <a href="/" title="Home">
                    <i className="mdi mdi-home font-18 vm"></i> 
                    <span className="text-md font-medium text-desktop text-white vm">Home</span>
                </a> 
            }
            
            {/* <a href="javascript:void(0)" data-href="/companies" className="topLink" onClick={this.enableClickActive}>
                <i className="material-icons font-24 vm">business</i>
                <span className="text-md font-medium text-desktop text-white vm">Companies</span>
            </a>
             */}
             
          

            <div className="btn-group float-left topLinks">
                <a className="btn dropdown-toggle topLink" data-toggle="dropdown" href="#">
                   <i className="mdi mdi-rocket font-1"></i> <span className="text-md font-medium text-desktop text-white vm">Business</span>
                    <span className="caret"></span>
                </a>
                <ul className="dropdown-menu">
                    <li>
                      <a href="javascript:void(0)" className="topLink" data-href={privileges && privileges["AD_SER"] ? "/search/advanced?t=startup" : upgradeUrl }  onClick={this.enableClickActive} title="Startups">
                            {/* <i className="mdi mdi-rocket font-18 vm text-black"></i>  */}
                            <span className="text-md font-medium text-sub-desktop vm text-black">Startups</span>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" className="topLink" data-href={privileges && privileges["AD_SER"] ? "/search/advanced?t=company" : upgradeUrl }  onClick={this.enableClickActive} title="company">
                            {/* <i className="mdi mdi-rocket font-18 vm text-black"></i>  */}
                            <span className="text-md font-medium text-sub-desktop vm text-black">Companies</span>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" className="topLink" data-href={privileges && privileges["AD_SER"] ? "/search/advanced?t=SMALL_BUSINESS" : upgradeUrl }  onClick={this.enableClickActive} title="small business">
                            {/* <i className="mdi mdi-rocket font-18 vm text-black"></i>  */}
                            <span className="text-md font-medium text-sub-desktop vm text-black">Small Business</span>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" className="topLink" data-href={privileges && privileges["AD_SER"] ? "/search/advanced?t=INTERNATIONAL_COMPANY" : upgradeUrl }  onClick={this.enableClickActive} title="international company">
                            {/* <i className="mdi mdi-rocket font-18 vm text-black"></i>  */}
                            <span className="text-md font-medium text-sub-desktop vm text-black">International Company</span>
                      </a>
                    </li>
                </ul>
            </div>
            <a href="javascript:void(0)" data-href={"/search/advanced?t=people" } className="topLink" onClick={this.enableClickActive} title="People">
                <i className="material-icons font-18 vm">people</i>
                <span className="text-md font-medium text-desktop text-white vm">People</span>
            </a>
            <a href="javascript:void(0)"  data-href={privileges && privileges["NWS"] ? "/news" : upgradeUrl } className="topLink" onClick={this.enableClickActive} title="News">
                <i className="mdi mdi-earth font-18 vm"></i> 
                <span className="text-md font-medium text-desktop text-white vm">News</span>
            </a>

            <div className="btn-group float-left topLinks">
                <a className="btn dropdown-toggle topLink" data-toggle="dropdown" href="#">
                    <i className="fa fa-usd" aria-hidden="true"></i> <span className="text-md font-medium text-desktop text-white vm">Investors</span>
                    <span className="caret"></span>
                </a>
                <ul className="dropdown-menu">
                    <li>
                      <a href="javascript:void(0)" className="topLink" data-id={58528} data-name={"Angel Group"} data-href={privileges && privileges["AD_SER"] ? "/search/advanced?t=investor&id=58528&l=Angel Group" : upgradeUrl }  onClick={this.enableClickActive}  title="Startups">
                            {/* <i className="mdi mdi-rocket font-18 vm text-black"></i>  */}
                            <span className="text-md font-medium text-sub-desktop vm text-black">Angel Investors</span>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" className="topLink" data-href={privileges && privileges["AD_SER"] ? "/search/advanced?t=investor&id=58523&l=Venture Capital" : upgradeUrl } onClick={this.enableClickActive}   title="Startups">
                            {/* <i className="mdi mdi-rocket font-18 vm text-black"></i>  */}
                            <span className="text-md font-medium text-sub-desktop vm text-black">Venture Capitals</span>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" className="topLink" data-href={privileges && privileges["AD_SER"] ? "/search/advanced?t=investor"  : upgradeUrl } onClick={this.enableClickActive}   title="Startups">
                            {/* <i className="mdi mdi-rocket font-18 vm text-black"></i>  */}
                            <span className="text-md font-medium text-sub-desktop vm text-black">Others</span>
                      </a>
                    </li>
                </ul>
            </div>

            <div className="btn-group float-left topLinks">
                <a className="btn dropdown-toggle topLink" data-toggle="dropdown" href="#">
                   <i className="mdi mdi-rocket font-1"></i> <span className="text-md font-medium text-desktop text-white vm">Government</span>
                    <span className="caret"></span>
                </a>
                <ul className="dropdown-menu">
                    <li>
                      <a href="javascript:void(0)" href={"/coming-soon.html"} target="_blank"  title="Startups">
                            {/* <i className="mdi mdi-rocket font-18 vm text-black"></i>  */}
                            <span className="text-md font-medium text-sub-desktop vm text-black">Government Structures</span>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" href={"/coming-soon.html"} target="_blank"   title="Startups">
                            {/* <i className="mdi mdi-rocket font-18 vm text-black"></i>  */}
                            <span className="text-md font-medium text-sub-desktop vm text-black">Counties</span>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" href={"/coming-soon.html"} target="_blank"   title="Startups">
                            {/* <i className="mdi mdi-rocket font-18 vm text-black"></i>  */}
                            <span className="text-md font-medium text-sub-desktop vm text-black">Parastatal</span>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" href={"/coming-soon.html"} target="_blank"   title="Startups">
                            {/* <i className="mdi mdi-rocket font-18 vm text-black"></i>  */}
                            <span className="text-md font-medium text-sub-desktop vm text-black">Others</span>
                      </a>
                    </li>
                </ul>
            </div>
            
            {/* <a href="javascript:void(0)" data-href="/search/advanced?t=investor" className="topLink" onClick={this.enableClickActive} title="Investors">
                <i className="material-icons font-24 vm">monetization_on</i>
                <span className="text-md font-medium text-desktop text-white vm">Investors</span>
            </a> */}


{/* FKP_BOD */}
           

            {/* <a href="javascript:void(0)" data-href="/opportunities" className="topLink" onClick={this.enableClickActive}>
                <i className="mdi mdi-trending-up font-24 vm"></i> 
                <span className="text-md font-medium text-desktop text-white vm">Opportunities</span>
            </a>
           
            <a href="javascript:void(0)" data-href="/events" className="topLink" onClick={this.enableClickActive}>
                <i className="mdi mdi-calendar font-24 vm"></i> 
                <span className="text-md font-medium text-desktop text-white vm">Events</span>
            </a> */}

           
            
            <a href="javascript:void(0)" data-href={privileges && privileges["EVT"] ? "/search/advanced?t=event" : upgradeUrl } className="topLink" onClick={this.enableClickActive} title="Events">
                <i className="mdi mdi-calendar font-18 vm"></i> 
                <span className="text-md font-medium text-desktop text-white vm">Events</span>
            </a>
            {/* <a href="javascript:void(0)" data-href={privileges && privileges["INV_OPP"] ? "/opportunities" : upgradeUrl } className="topLink" onClick={this.enableClickActive}>
                <i className="mdi mdi-trending-up font-18 vm"></i> 
                <span className="text-md font-medium text-desktop text-white vm">Opportunities</span>
            </a> */}

            <a href="javascript:void(0)" data-href={"/opportunities"} className="topLink" onClick={this.enableClickActive}>
                <i className="mdi mdi-trending-up font-18 vm"></i> 
                <span className="text-md font-medium text-desktop text-white vm">Opportunities</span>
            </a>

             {/* <a href="javascript:void(0)" data-href={privileges && privileges["RPTS"] ? "/report" : upgradeUrl }  className="topLink" onClick={this.enableClickActive} title="Reports">
                <i className="mdi mdi-file-pdf font-18 vm"></i> 
                <span className="text-md font-medium text-desktop text-white vm">Reports</span>
                <span className="badge badge-red ml-auto new-badge">NEW</span>
            </a>  */}
           


            {/* <a href="javascript:void(0)" data-href={privileges && privileges["AED_CI"] ? "/companies" : upgradeUrl } className="topLink" onClick={this.enableClickActive}>
                <i className="material-icons font-24 vm">business</i>
                <span className="text-md font-medium text-desktop text-white vm">Companies</span>
            </a>            
            
            <a href="javascript:void(0)" data-href={privileges && privileges["AED_CI"] ? "/search/advanced?t=startup" : upgradeUrl } className="topLink" onClick={this.enableClickActive}>
                    <i className="mdi mdi-rocket font-24 vm"></i> 
                    <span className="text-md font-medium text-desktop text-white vm">Startups</span>
            </a> 
            
            <a href="javascript:void(0)" data-href={privileges && privileges["AED_CI"] ? "/search/advanced?t=investor" : upgradeUrl } className="topLink" onClick={this.enableClickActive}>
                <i className="material-icons font-24 vm">monetization_on</i>
                <span className="text-md font-medium text-desktop text-white vm">Investors</span>
            </a>

            <a href="javascript:void(0)" data-href={privileges && privileges["AED_CI"] ? "/search/advanced?t=people" : upgradeUrl } className="topLink" onClick={this.enableClickActive}>
                <i className="material-icons font-24 vm">people</i>
                <span className="text-md font-medium text-desktop text-white vm">People</span>
            </a>

            <a href="javascript:void(0)" data-href={privileges && privileges["INV_OPP"] ? "/opportunities" : upgradeUrl } className="topLink" onClick={this.enableClickActive}>
                <i className="mdi mdi-trending-up font-24 vm"></i> 
                <span className="text-md font-medium text-desktop text-white vm">Opportunities</span>
            </a>
             <a href="javascript:void(0)" data-href={privileges && privileges["NWS"] ? "/news" : upgradeUrl } className="topLink" onClick={this.enableClickActive}>
                <i className="mdi mdi-earth font-24 vm"></i> 
                <span className="text-md font-medium text-desktop text-white vm">News</span>
            </a> */}
                
           
           

           
           
            {/* {loggedInUser ?
                <ul className="navbar-nav navUl float-right desktopMenu">
                       <li className="nav-item dropdown">
                            <a className="dropdown-toggle text-muted waves-effect waves-dark btn font-14 nav-btn dropdown" href="" id="3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span className="topAdd">Add</span> <i className="mdi mdi-plus font-bold"></i>
                            </a>
                            <div className="dropdown-menu mailbox dropdown-menu-right scale-up" aria-labelledby="3">
                                <ul className="addDD">
                                    <li>
                                        <div className="message-center no-border">
                                            <a  href="javascript:void(0)" onClick={this.createPost}>
                                                <div className="text-default">
                                                    <i className="mdi mdi-border-color"></i>
                                                    <span className="profile-status online"> Create New Post</span> 
                                                </div>
                                            </a>
                                            <Link to="/create-opportunity/start">
                                                <div className="text-default">
                                                    <i className="mdi mdi-trending-up adjusted-icon-font"></i>
                                                    <span className="profile-status online"> Create New Opportunity</span> 
                                                </div>
                                            </Link>
                                            <Link to="/create-event/start">
                                                <div className="text-default">
                                                    <i className="mdi mdi-calendar"></i> 
                                                    <span className="profile-status online"> Create New Event</span> 
                                                </div>
                                            </Link>
                                            <Link to="/create-company/start">
                                                <div className="text-default">
                                                    <i className="material-icons adjusted-icon">business</i>
                                                    <span className="profile-status online m-l-5">Create New Company</span> 
                                                </div>
                                            </Link>
                                        </div>
                                    </li>
                                    
                                </ul>
                            </div>
                        </li>
                </ul>
            :
            ""
            } */}
            </div>
        </div>
      </div>
    )
  }
}


export default withRouter(TopMenu); 