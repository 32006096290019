import React, { Component } from 'react';
import config from '../../shared/config';
import classNames from 'classnames/bind';
import Axios from 'axios';
import Helper from "../../shared/custom";

export default class AllEventsPublic extends Component {

    constructor(){
        super();
        this.state ={
          events:[],
        }
      }
    
      componentWillReceiveProps(props){
        this.setState({
          events:props.upcomingEvents
        },
        function(){
        })
      //  this.fetchEvents();
      }
    
      
  render() {
    let events = this.state.events;
    return (
      <div>

            <div className="card text-left bold-headers">
                <div className="card-body">
                    <h5 className="m-t-0"><b>Events</b></h5>
                    <div className="row">
                     {(!Helper.isEmpty(events) && (events.length > 0))?
                        events.map((item,index )=> (
                            <div className="col-md-6 m-t-10" key={index}>
                              <div className="border text-center p-10">
                                  {/* <img src={!Helper.isEmpty(item.image) ? Helper.dataPath(item.image) : Helper.dataPath(config.defaultEventCover)} className="carImg" alt="Image"/> */}
                                  <img className="img-responsive"  src={item.coverPhoto && item.coverPhoto.publicUri ? Helper.dataPath(item.coverPhoto.publicUri) : Helper.dataPath(config.defaultEventIcon)}  alt="Event"/>
                                  <div className="col-md-12 m-t-10 font-14 m-t-20 p-0">
                                      
                                  <div className="col-md-12 float-left text-left p-0">
                                    <h5 className="font-bold m-0 m-l-5 p-0 smallText">
                                      <a href={item.uri ? "/event/"+item.uri :""} className="font-10">{item.name}</a>
                                    </h5>
                                    <span className="font-10 clearfix m-0">
                                    <i class="mdi mdi-calendar"></i> {Helper.getDateInFormat(item.startDate, "dd, D MMM")} - {" "}<br/>
                                    <i class="mdi mdi-map-marker"></i> {item.city.name}, {item.country.name}
                                    </span>
                                    <span className="font-10"><i class="mdi mdi-star"></i> {item._intrestedCount ? item._intrestedCount :0}  People Interested</span>
                                  </div>
                                  
                                  <div className="col-md-12 m-t-10 font-14 m-t-20 p-0 clearfix">
                                        {/* <div className="like-comm text-left"> 
                                            <Interest id={event.id} type={"EVENT"} interested={event._isIntrested} masterClassName="float-right m-t-5 m-l-5 font-12"/>
                                          </div> */}
                                            <a href="" className="float-right m-l-5 m-t-2"><i class="material-icons font-24">language</i></a>
                                        </div>
                                  </div>
                                </div>
                            </div>
                        
                      ))
                      :
                      <div className="col-12 text-center">No Events</div>
                    }
                    </div>
                 </div>
            </div>   
        
      </div>
    )
  }
}
