import React, { Component } from 'react';
import { withRouter, Redirect ,Link} from 'react-router-dom';
import Helper from '../../shared/custom';
import config from "../../shared/config";
import Axios from 'axios';
import { connect } from 'react-redux';
import store from '../../store';

class ContactUs extends Component {
    constructor(props){
        super(props);
        this.state ={
            showForm:true,
            fullName:"",
            phone:"",
            userEmail:"",
            description:"",
            reason:undefined
            
        }
        this.getFieldsValue = this.getFieldsValue.bind(this);
        this.sendQuery = this.sendQuery.bind(this);
    }

    getFieldsValue(event){
        this.setState({
            [event.target.name]:event.target.value
        });
    }

    componentDidMount(){
        let storeState = store.getState();  
        let auth =  storeState.auth.isAuthenticated;

        if(!auth){
            this.props.history.push("/");
            return false;
        }

        let user = storeState.auth.user;;
      
        if(!Helper.isEmpty(user)){
            this.setState({
                userEmail : user.email,
                fullName : user.fullName,
            },()=>{
             
            })
        }
      
    }
  

    sendQuery(){
        let payload = {};
        payload.fullName = this.state.fullName;
        payload.phone = this.state.phone;
        payload.userEmail = this.state.userEmail;
        payload.description = this.state.description;
        payload.reason = this.state.reason;
        if(!Helper.isValidEmail(payload.userEmail)){
            Helper.pushTError("Please enter a valid email address");
            return false;
        }

        if(Helper.isEmpty(payload.description) || Helper.isEmpty(payload.fullName) || Helper.isEmpty(payload.phone) || Helper.isEmpty(payload.reason) || Helper.isEmpty(payload.userEmail)){
            Helper.pushTError("All fields are mandatory");
            return false;
        }
        Axios.post(
            config.api("payment/contact-us/add"),
            payload)
        .then( (response) => {
            let status = response.status;
            Helper.pushTSuccess("Query sent succesfully")
            if(status == 200){
                this.setState({
                    showForm:false
                })
            }
            
        })
        .catch((err) => {
           Helper.pushTError("Cannot send query at the moment, Please try again later")
        });
    }

  render() {
     let showForm = this.state.showForm;
    return (
      <div>
          <div className="login-register customCard contactUs">
            <div className="col-lg-6 m-a auth-card">
                {!showForm ?
                    <div className="card-body m-t-40">
                        <h2 className="font-24 m-t-20">Thank you for contacting us</h2>
                        <h3 className="font-normal font-24 m-t-5">We will get back to you soon.</h3>
                        <a href="/" className="btn btn-info">Home</a>
                    </div>
                    :
                   <div className="card-body m-t-40">
                     <h2 className="font-24  m-t-20">Contact the Investera Plus Team</h2>
                     <h3 className="font-normal font-24 m-t-5">Get in touch and let us know how we can help.</h3>
                    
                     <div>
                        <div className="col-md-8 m-a m-t-15">
                            <div className="form-group m-t-10">
                                <label className="control-label m-b-0">Full Name</label>
                                <input type="text" id="fullName" name="fullName" className="form-control" placeholder="Enter Fullname" onChange={this.getFieldsValue} value={this.state.fullName}/>
                            </div>
                            <div className="form-group m-t-10">
                                <label className="control-label m-b-0">Email</label>
                                <input type="text" id="userEmail" name="userEmail" className="form-control" placeholder="Enter email " onChange={this.getFieldsValue} value={this.state.userEmail}/>
                            </div>
                            <div className="form-group m-t-10">
                                <label className="control-label m-b-0">Phone</label>
                                <input type="text" id="phone" name="phone" className="form-control" placeholder="Enter phone number with country code" onChange={this.getFieldsValue}/>
                            </div>
                            <div className="form-group m-b-0">
                                <label className="control-label m-b-0">Reason for getting in touch</label>
                                <select className="form-control custom-select" data-placeholder="Choose a Reason" id="reason" name="reason" onChange={this.getFieldsValue}>
                                     <option value="">Please select</option>
                                    <option value="Payment Failure">Payment related</option>
                                    <option value="Refund">Upgrading package</option>
                                    <option value="Technical Issue">Others</option>
                                </select>
                            </div>
                            <div className="form-group m-t-10">
                                <label className="control-label m-b-0">Details</label>
                                <textarea className="form-control contact-text"  id="description" name="description" data-placeholder="Tell us how we can help you" row="5" onChange={this.getFieldsValue}>
                                </textarea>
                            </div>
                            <div className="m-t-20">
                                <button onClick={this.sendQuery} className="btn btn-info proceedBtn full-width" type="submit">Send</button>
                            </div>
                        </div>
                    </div>
                </div>


                }
                
            </div>
          </div>
      </div>
    )
  }
}

export default withRouter(ContactUs)