import React, { Component } from "react";
import SelectableTag from "./SelectableTag";
import {Nav, NavItem, NavDropdown, MenuItem  } from 'react-bootstrap'

export default class SelectableTagsOptionalAdd extends Component {

    constructor(props){
        super(props);
        this.state = {
            options:props.options||[],
            selectedOptions:[]
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            options: nextProps.options
        };
    }

    handleSelectableChange = (option)=>{
        let options = this.state.selectedOptions;
        if(!options.map(x=>x.value).includes(option.value)){
            options.push(option)
            this.setState({
                selectedOptions : options
            },()=>{
                if(this.props.onChange){
                    this.props.onChange(this.state.selectedOptions)
                }
            })
        }else {
             this.setState({
                 selectedOptions : options.filter(x=>{return x.value != option.value})
             },()=>{
                if(this.props.onChange){
                    this.props.onChange(this.state.selectedOptions)
                }
            })
        }
    }
    onClick = (x)=>{
        
    }
    render(){
        let selectedOptions = this.state.selectedOptions;
        return (
                <div className="col-12 d-flex justify-content-end text-blue p-0">
                                {/* <Dropdown.Toggle className="no-bg-dropdown-toggle" id={this.props.id+"_dropdown_"}>
                                </Dropdown.Toggle> */}
                                <div className="dropdown filter-dropdown-toggle m-r-8">
                                        <a className="text-muted dropdown-toggle newsDropdown "  id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fa fa-plus-circle font-16 text-blue"><span className="text-blue m-l-5 font-14">Add</span></i> 
                                        </a>
                                    <div className="dropdown-menu dropdown-menu-right" id='dropdown'>
                                        {
                                        this.state.options.map(x=>{
                                            return <p   className="dropdown-item p-l-5"  key={this.props.id+"_selectable_tag_"+x.value}
                                                        className={selectedOptions && selectedOptions.map(y=>y.value).includes(x.value)?"selected":""}
                                                        id={this.props.id+"_selectable_child_tag_"}
                                                        enableChange={this.state.enableChange}
                                                        onClick={()=>{this.handleSelectableChange(x)}}>
                                                        <a>{x.label}</a>
                                                    </p >
                                            })   
                                        }  
                                    </div>
                                </div>
                                
                </div>
        )
    }
}