import React, { useEffect, useState , useRef } from 'react';
import { Link } from 'react-router-dom';
import Helper from '../../shared/custom';
import ListCard from '../wigets/ListCard';
import AuthorDetails from './AuthorDetails';
import CardActions from './CardActions';
import Comments from './Comments';
import CommentShareBtn from './CommentShareBtn';



export const NewAcquisitions = ({ data }) => {
    let dataset = !Helper.isEmpty(data.groupedFieldUpdates) ? data.groupedFieldUpdates : [];
    const [count, setCount] = useState(() => 1)
    const [toggle, setToggle] = useState(() => false);
    const [list, setList] = useState(() => [])
    const [focusInput, setFocusInput] = useState(false);

    const elementRef = useRef(null);
    const containerRef = useRef(null);


    let mode = { type: "aquisitions" }
    let newData = [{
        label: "Announced Date",
        field: "announceDate"
    },
    {
        label: "Price",
        field: "price"
    }];

    const focusInputFiled = () =>{
        setFocusInput(!focusInput)
    }

    useEffect(() => {
        if (dataset) {
            setList(prevState => dataset.slice(0, count))
        }
    }, [count])

    const displayMembers = () => {
        setToggle(prevState => !prevState);
        setCount(prevState => prevState == 1 ? dataset.length : 1);
        if(toggle){
        var headerHeight = 100; 
        var scrollToEl = elementRef.current;
       // var container = containerRef.current.offsetHeight;
        var topOfElement = window.pageYOffset + scrollToEl.getBoundingClientRect().top - headerHeight;
        window.scroll({ top: topOfElement, behavior: "smooth" });
        }
    }
    return (
        <div className="card">
            <div className="card-body">
                <div className="col-lg-12 p-0">
                    <AuthorDetails data={data} />
                    <div className="col-lg-3 p-0 float-right text-right">
                        <CardActions data={data} />
                    </div>
                                    </div>
                <div className="clearfix"></div>
                <div ref={elementRef} className="col-lg-12 p-0 m-t-40 ">
                    <div className="b-b-2 p-b-5">
                        <span> <i class="fas fa-dollar-sign icon-round"></i> <Link className="font-bold" to={`/${data.entityType}/${data.uri}`}>{data.author.name}</Link> Has {dataset.length} new Acquisition(s)</span>
                    </div>

                    {list && list.map((item, index) => <ListCard
                        dataset={item}
                        mode={mode}
                        newData={newData}
                        isNewsFeedComponent={true}
                    />
                    )}
                    <div className="clearfix"></div>
                    {dataset.length > 1 && <span    style={{ cursor: 'pointer' }} onClick={displayMembers} className="font-12"><i className={`fa ${!toggle ? 'fa-chevron-down' : 'fa-chevron-up'}`} /> &nbsp;&nbsp;{!toggle ? `${dataset.length - 1} more other acquisition` : 'Hide'}</span>}
                    <div className="clearfix"></div>

                    <div className="float-right m-t-5">
                        <Link className="font-14" to={`company-funding-details/${data.uri}/?div=aquisition`}>View All</Link>
                    </div>

                    <div class="clearfix"></div>

                    <CommentShareBtn data={data} showInterested={false}
                     focusInputArea={focusInputFiled} 
                    // updateCounts={this.updateCounts}
                    />
                    <Comments data={data}
                     enableFocus={focusInput}
                    />


                </div>
            </div>
        </div>

    );
}


{/*    */ }