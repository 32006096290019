import React, { Component } from 'react';
import config from '../../shared/config';
import Helper from '../../shared/custom';
import CardActions from './CardActions';
import Comments from './Comments';
import CommentShareCount from './CommentShareCount';
import CommentShareBtn from './CommentShareBtn';
import AuthorDetails from './AuthorDetails';
import ReadMoreAndLess from 'react-read-more-less';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


export default class UpdatesCard extends Component {

  constructor(props){
      super(props);
      this.state = {
        focusInput:false,
        updatedId :null
    }
      this.getContent = this.getContent.bind(this);
      this.focusInputFiled = this.focusInputFiled.bind(this);
      this.updateCounts = this.updateCounts.bind(this);
  }



focusInputFiled(){
    this.setState({
        focusInput: !this.state.focusInput
    })
}
updateCounts(id){
    if(!Helper.isEmpty(id)){
        this.setState({
            updatedId:id
        })
    }
  }

  getContent(data){

    let type = data.action == "FIELD_UPDATE" ? data && data.fieldupdate && data.fieldupdate.field ? data.fieldupdate.field : null : data.action;

    if(!Helper.isEmpty(type)){
        switch(type){
          case "FUNDING_STATUS":
          return(
            <div>
                   <div className="card-body p-t-0">
                       <div className="col-lg-8 p-0 m-a text-center">
                       <h2><i className="text-green material-icons font-80">monetization_on</i></h2>
                       <h5 className="text-center m-t-10 font-18 font-bold">{data.author.name} {data.fieldupdate.value === "true"? "has started raising funds" : "has stopped raising funds"}</h5>
                       {/* <h5 className="text-center m-t-5"><span className="text-green font-18 font-bold">{data.fieldupdate.value ? "YES" : "NO"}</span></h5> */}
                       </div>
                   </div>
                   <div className="card-body p-t-0">
                       <div className="col-lg-12 m-a text-center">
                        <Link className="btn btn-info btn-card" to={"/"+data.entityType+"/"+data.uri}>View Company Details</Link>
                       </div>
                       <CommentShareCount data={data} updatedId={this.state.updatedId}/>
                       <CommentShareBtn data={data}  focusInputArea={this.focusInputFiled} updateCounts={this.updateCounts}/>
                       <Comments data={data} enableFocus={this.state.focusInput} />
                   </div>
               </div>
         )

          case "REVENUE":
          case "GROSS_PROFIT":
          case "NET_INCOME" :
          return(
             <div>
                    <div className="card-body p-t-0">
                        <div className="col-lg-8 p-0 m-a text-center">
                        <h2><i className="text-green material-icons font-80">monetization_on</i></h2>
                        <h5 className="text-center m-t-10 font-18 font-bold">{data.author.name} updated their {Helper.getActionName(type)} to:</h5>
                        <h5 className="text-center m-t-5"><span className="text-green font-18 font-bold">{data.fieldupdate && data.fieldupdate.currency  ?  data.fieldupdate.currency.shortName : ""} {Helper.displayAmount({amount:data.fieldupdate.value})}</span></h5>
                        </div>
                    </div>
                    <div className="card-body p-t-0">
                        <div className="col-lg-12 m-a text-center">
                         <Link className="btn btn-info btn-card" to={"/"+data.entityType+"/"+data.uri}>View Company Details</Link>
                        </div>
                        <CommentShareCount data={data} updatedId={this.state.updatedId}/>
                        <CommentShareBtn data={data}  focusInputArea={this.focusInputFiled} updateCounts={this.updateCounts}/>
                        <Comments data={data} enableFocus={this.state.focusInput} />
                    </div>
                </div>
          )

          case "NEW_FUND":
            return(
                <div>
                   <div className="card-body p-t-0">
                       <div className="col-lg-8 p-0 m-a text-center">
                       <h2><i className="text-info font-80 mdi mdi-eye"></i></h2>
                       <h5 className="text-center m-t-10 font-18 font-bold">{data.author.name} added new {Helper.getActionName(type)}:</h5>
                       <h5 className="text-center m-t-5"><span className="text-info font-18 font-bold">
                       {data.fieldupdate && data.fieldupdate.currency  ?  data.fieldupdate.currency.shortName : ""} {Helper.displayAmount({amount:data.fieldupdate.value})}</span></h5>
                       </div>
                   </div>
                   <div className="card-body p-t-0">
                       <div className="col-lg-12 m-a text-center">
                        <Link className="btn btn-info btn-card" to={"/"+data.entityType+"/"+data.uri}>View Company Details</Link>
                       </div>
                       <CommentShareCount data={data} updatedId={this.state.updatedId}/>
                       <CommentShareBtn data={data}  focusInputArea={this.focusInputFiled} updateCounts={this.updateCounts}/>
                       <Comments data={data} enableFocus={this.state.focusInput} />
                   </div>
               </div> 
            )

          case "NEW_INVESTMENT":
          case "INVESTMENT_EXIT":          
          case "NEW_INVESTOR":
          return(
             <div>
                    <div className="card-body p-t-0">
                        <div className="col-lg-8 p-0 m-a text-center">
                        <h2><i className="text-info font-80 mdi mdi-eye"></i></h2>
                        <h5 className="text-center m-t-10 font-18 font-bold">{data.author.name} - {Helper.getActionName(type)}:</h5>
                        <h5 className="text-center m-t-5"><span className="text-info font-18 font-bold">
                         {data.fieldupdate.value}</span></h5>
                        </div>
                    </div>
                    <div className="card-body p-t-0">
                        <div className="col-lg-12 m-a text-center">
                         <Link className="btn btn-info btn-card" to={"/"+data.entityType+"/"+data.uri}>View Company Details</Link>
                        </div>
                        <CommentShareCount data={data} updatedId={this.state.updatedId}/>
                        <CommentShareBtn data={data}  focusInputArea={this.focusInputFiled} updateCounts={this.updateCounts}/>
                        <Comments data={data} enableFocus={this.state.focusInput} />
                    </div>
                </div>
          )
          case "CEO":
          case "KEY_PERSON":
          case "BOD":
          return(
            <div>
              <div className="card-body p-t-0">
                     <div className="col-lg-5 p-0 m-a">
                            <h5 className="text-center m-t-10">{data.author.name} has a new  {!Helper.isEmpty(data.fieldupdate && data.fieldupdate.title) ? data.fieldupdate.title : ""}</h5>
                            <div className="col-lg-12 text-center">
                                <img src={Helper.dataPath(data.fieldupdate && data.fieldupdate.peopleProfileImage ? data.fieldupdate.peopleProfileImage : config.defaultUserImage)} className="m-t-10 img-rounded" width="100" height="100"/>
                            </div>
                            <h5 className="text-info text-center">{data.fieldupdate.peopleName}</h5>
                        </div>
                        <div className="clearfix"></div>
                </div>

                <div className="card-body">
                    <div className="col-lg-12 m-a text-center">
                     <Link className="btn btn-info btn-card" to={"/people/"+data.fieldupdate.uri}>View  Details</Link>
                    </div>
                    <CommentShareCount data={data} updatedId={this.state.updatedId}/>
                    <CommentShareBtn data={data}  focusInputArea={this.focusInputFiled} updateCounts={this.updateCounts}/>
                    <Comments data={data} enableFocus={this.state.focusInput} />
                </div>
            </div>
         )
          case "NEW_COMPANY":
          return(
              <div>
                <div className="card-body p-t-0">
                         <div className="col-lg-5 p-0 m-a">
                                <h5 className="text-center m-t-10">New Company</h5>
                                <h5 className="text-info text-center">{data.name}</h5>
                                <div className="col-lg-12 text-center">
                                    <img src={Helper.dataPath(data.profilePic && data.profilePic.publicUri ? data.profilePic.publicUri : config.defaultCompanyImage)} className="m-t-10 half-width img-rounded" width="100" height="100"/>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                    </div>

                    <div className="card-body">
                        <div className="col-lg-12 m-a text-center">
                         <Link className="btn btn-info btn-card" to={"/"+data.entityType+"/"+data.uri}>View Company Details</Link>
                        </div>
                        <CommentShareCount data={data} updatedId={this.state.updatedId}/>
                        <CommentShareBtn data={data}  focusInputArea={this.focusInputFiled} updateCounts={this.updateCounts}/>
                        <Comments data={data} enableFocus={this.state.focusInput} />
                    </div>
                </div>
          )

      }
    }else{
      return <div></div>
    }
  }

  render() {
    let data = this.props.data;
    let btnText = "View Details"//mode == "sharePrice || fund" ? "View Company Profile" : "View Profile";
    let shared = this.props.shared;

    return (
      <div>
          <div className="card" id="section2">
                    <div className="card-body">
                    {
                        shared ? 
                            <div className="col-lg-12 p-0">
                                    <AuthorDetails data={data} type="shared" />
                                    <div className="col-lg-3 p-0 float-right text-right">
                                    <CardActions data={data}/>
                                    </div>
                                <div className="clearfix"></div>

                                <div className="col-lg-12 p-0 m-t-20">
                                <hr/>
                                        <AuthorDetails data={data}/>
                                </div>
                                <div className="clearfix"></div>
                        </div>
                       :
                            <div className="col-lg-12 p-0">
                                <AuthorDetails data={data}/>
                                <div className="col-lg-3 p-0 float-right text-right">
                                    <CardActions data={data}/>
                                </div>
                            </div>

                    }
                    </div>

                       { this.getContent(data)}

                </div>
      </div>
    )
  }
}
