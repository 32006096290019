import React, { Component } from 'react';
import EventRightpanel from './EventRightPanel';
//import Sidemenu from '../sidemenu/Sidemenu';
import SidemenuEvents from '../sidemenu/SidemenuEvents';
import EventRouter from './EventRouter';
// import { getMetaData } from '../../actions/metaAction';
// import { getSectorData } from '../../actions/businessAction';
import Axios from 'axios';
import store from '../../store';
import { connect } from 'react-redux';
import Helper from "../../shared/custom";
import { withRouter, Redirect } from 'react-router-dom';
import Helpers from '../../shared/custom';

import { getMetaData } from '../../actions/metaAction';
import { getSectorData } from '../../actions/businessAction';

let locationIdsRevd;
let filterDatesRevd;

class EventSummary extends Component {
  constructor(props){
    super(props)
    this.state ={
      isLoggedIn :false,
      searchCriteria:{},
      context:undefined,
      
      metaList:undefined,
      business:undefined,
      localMetaList:{},
      localBusiness:{},
      privileges:{},

      locationIds:[],
      filterDates:{}
      
    }
    
    store.subscribe(() => {
      var storeState = store.getState();
      var auth = storeState.auth;
      var localAuth = this.state.auth;
      this.setState({
        isLoggedIn:auth.isAuthenticated
      },()=>{
        
      });

      let packageDetails =  storeState.packageDetails;
      let privileges = packageDetails.privileges;
      var localMetaList = this.state.metaList;
      var storeMetaList = storeState.meta.metaList; 
      
         
          if(JSON.stringify(privileges) != JSON.stringify(this.state.privileges)){
              this.setState({
                  privileges:privileges
              },()=>{
                if(!Helper.isEmpty(privileges) && !privileges["EVT"]){
                  this.props.history.push(Helpers.upgradeUrl());
                  return false;
                }
              })
          }

          if(!localMetaList || JSON.stringify(localMetaList) != JSON.stringify(storeMetaList)){
            this.setState({metaList:storeMetaList},function(){
            });
        }
  
        var localBusiness = this.state.business;
        var storeBusiness = storeState.business.data;
        if(!localBusiness || JSON.stringify(localBusiness) != JSON.stringify(storeBusiness)){
            this.setState({business:storeBusiness});
        }
  
        this.setState({
          isLoggedIn:auth.isAuthenticated
        });
    }); 

    this.criteriaUpdate = this.criteriaUpdate.bind(this);
    this.setPageContext = this.setPageContext.bind(this);
    this.filterDates = this.filterDates.bind(this);
  }

  getSelectedLocation(locationIds){
     locationIdsRevd = locationIds;
  }

  filterDates(filterDates){
    this.setState({
        filterDates:filterDates
    })
  }
  

  criteriaUpdate(name, value){
    let sc = this.state.searchCriteria
    sc[[name]] = value;
    this.setState({searchCriteria:sc},
      function(){
       
      });
  }


  setPageContext(context){
    if (this.state.context != context)
      this.setState({context:context},
        function(){
        }
        );
  }

  componentDidMount(){
    var storeState = store.getState();

    let meta = storeState.meta;    
    if(Helper.isEmpty(meta.metaList) == true){
      this.props.getMetaData();
    }else{
      this.setState({metaList:meta.metaList});
    }

    let business = storeState.business;        
    if(Helper.isEmpty(business.data) == true){            
        this.props.getSectorData();
    }else{
        this.setState({business:business.data});
    }


    var auth = storeState.auth;
    var localAuth = this.state.auth;
    this.setState({
      isLoggedIn:auth.isAuthenticated
    },()=>{
    })
  }


  render() {
    return (
      <div>
            <div>
                <div >
                  <div className="row content-in m-t-80 p-t-10">
                    <div className="col-lg-3 col-xlg-3 col-md-3 sideMenuCont">
                        <SidemenuEvents mode="eventSummary" isLoggedIn={this.state.isLoggedIn}  criteriaUpdate={this.criteriaUpdate}  searchCriteria={this.state.searchCriteria} setPageContext={this.setPageContext}  context={this.state.context} sendLocationToParent={this.getSelectedLocation} getDateFilterValues={this.filterDates}/>
                    </div>
                    <div className="col-lg-6 col-xlg-6 col-md-6 centerDiv">
                       <EventRouter isLoggedIn={this.state.isLoggedIn} criteriaUpdate={this.criteriaUpdate} searchCriteria={this.state.searchCriteria} setPageContext={this.setPageContext} context={this.state.context}  metaList={this.state.metaList} business={this.state.business} locationIds={locationIdsRevd} filterDates={this.state.filterDates} />
                    </div>
                    <div className="col-lg-3 col-xlg-3 col-md-3 m-t-2 right-side">
                        <EventRightpanel isLoggedIn={this.state.isLoggedIn}  criteriaUpdate={this.criteriaUpdate}  searchCriteria={this.state.searchCriteria} setPageContext={this.setPageContext} context={this.state.context} metaList={this.state.metaList} business={this.state.business} />
                    </div>
                </div>
              </div>
            </div>
        
      </div>
    )
  }
}

// export default withRouter(EventSummary);

 export default  connect(null,{ getMetaData, getSectorData})(withRouter(EventSummary));