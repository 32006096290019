import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect ,Link} from 'react-router-dom';
import store from '../../store';
import { login } from '../../actions/authAction';
import Helper from '../../shared/custom';
import storeAuth from '../../utils/storeAuth';
import { render } from 'react-dom';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import messages from '../../shared/messages'
import LinkedInAuth from './LinkedInAuth';
import GoogleAuth from '../auth/GoogleAuth';
import { fetchUserInfo } from '../../actions/authAction';
import { getAllSectorData } from '../../actions/SectorsAction';
import { logoutUser } from '../../actions/authAction';
import qs from "query-string";
import config from '../../shared/config';


class Loginpage extends Component {

  constructor(props){
      
      super(props)

      this.state = {
        auth:{},
        userName:"",
        password:"",
        loading:false,    
        error:"",
        referal:"",
        localProps:{},
        location:this.props.location,
        showPassword:false,
        passwordType:"password",
      }
      this.validateForm    = this.validateForm.bind(this);       
      this.handleChange = this.handleChange.bind(this);

      store.subscribe(() => {
        var storeState = store.getState();
        var auth = storeState.auth;
        var localAuth = this.state.auth;
        var fromRegister = storeState.registerData.fromRegister;
        if(fromRegister){
            this.props.history.push('/welcome');
        }
       
        if(JSON.stringify(auth) != JSON.stringify(localAuth)){ 
            storeAuth(); 
            this.setState({auth:storeState.auth});
        }
        if(storeState.errors){
            this.setState({
                loading:storeState.errors.loading
            })   
        }
        
    }); 
  }  

  componentDidMount(){
    var storeState = store.getState();
    this.setState({auth:storeState.auth},()=>{
        if(this.state.auth.isAuthenticated){
            setTimeout(() => {
                //this.props.fetchUserInfo(); 
              
            });
            
        }
    });    
    
    
    let referal = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true
      }).referal;
      referal += window.location.hash;
    this.setState({referal:referal});

  }

  showPassword = (e)=>{
    e.preventDefault();
    this.setState(prevState =>{
        prevState.showPassword = !prevState.showPassword;
        prevState.passwordType = prevState.showPassword  ? "text" : "password";
        return prevState
    })
  }

  componentWillReceiveProps(nextProps){

    if(!Helper.isEmpty(nextProps.errors)){
        this.setState({
            loading:nextProps.errors.loading,
        });
        let error = nextProps.errors.error;
    }

    if(JSON.stringify(nextProps) != JSON.stringify(this.state.localProps)){
        this.setState({
            localProps:nextProps
        })
        var storeState = nextProps;
        this.setState({auth:storeState.auth},()=>{
            if(this.state.auth.isAuthenticated){
                setTimeout(() => {
                    //Liju : Commented
                   // this.props.fetchUserInfo(); 
                });
                
            }
        });  
    }
    

  }

  
  validateForm(event){
    event.preventDefault();
      
    const userName = this.state.userName;
    const password = this.state.password;      
    if( userName == "" || password == ""){
        Helper.pushError("Please fill all required fields")
        return false;
    }
    this.setState({
        loading:true
    })
    const data = {username:userName,password:password};
    let {location} = this.state;
 
    let history = location.state && location.state.from  && location.state.from.pathname ? location.state.from.pathname:null;
  if(location.state && location.state.from){
      history = location.state.from;
  }
    this.props.login(data,history , this.props.history);
//     if(!Helper.isEmpty(history)){
//     this.props.history.push(history)
//     localStorage.setItem("referal");
// }
    event.preventDefault();

    }

    handleChange(event){
        this.setState({[event.target.name]:event.target.value});
    }
    
  render() {
   

   
    let isAuthenticated = this.state.auth.isAuthenticated;

   
    if(isAuthenticated){
        let referal = localStorage.getItem("referal");
        let redirectTo = (Helper.isEmpty(referal))?null:referal;
        let lastPath = this.props.location.state && this.props.location.state.from  && this.props.location.state.from.pathname ? this.props.location.state.from.pathname:'/';
     
        localStorage.removeItem("referal");

        if (!Helper.isEmpty(redirectTo) && redirectTo != "/verify-email"){
            return <Redirect to={redirectTo} />;
        }

     
        // if (lastPath && lastPath != "/verify-email"){
        //     console.log("ininin")
        //     return (<Redirect to={lastPath} />);
        // }

        
    }

    const { loading ,showPassword ,passwordType} = this.state;
    let from = this.props.from;
    return (
      <div>
        {   from == "auth-pane" ? 
            
                <div className="m-t-40">
                    <div className="col-md-12 m-b-20  float-left">
                        <div className="col-md-6 float-left"><LinkedInAuth /></div>
                        <div className="col-md-6 float-left"><GoogleAuth label="Login with Google" url={config.gOuthUrl}/></div>
                        {/* <div className="col-md-4 float-left"><LinkedInAuth /></div> */}
                    </div>
                    
                    <LoadingOverlay className="clearfix">
                        <Loader loading={loading}/>
                      
                        <form className="form-horizontal col-10 m-t-20 m-a clearfix" id="loginform" action="index.html">
                            <h4 className="box-title m-b-20 font-medium line-header">Or</h4>

                            <div className="form-group">
                                    <label className="control-label text-black m-b-0">Email/Mobile No.</label>
                                    <input className="form-control" type="text" required=""  name="userName" onChange={this.handleChange}/> 
                            </div>
                            {/* <div className="form-group">
                                    <label className="control-label text-black m-b-0">Password</label>
                                    <input className="form-control" type="password" required=""  name="password" onChange={this.handleChange} maxLength="32"/> 
                                    <div className="input-group-append">
                                        <span className="input-group-text" id="basic-addon2">@example.com</span>
                                    </div>
                            </div> */}
                         

                            <div className="input-group mb-3 pwCont">
                                <label className="control-label text-black m-b-0 pwLabel">Password</label>
                                <br/>
                                <input className="form-control" type={passwordType} required=""  name="password" onChange={this.handleChange} maxLength="32" />
                                <div className="input-group-append">
                                <button className="input-group-button showPwBtn" onClick={this.showPassword}>
                                    {!showPassword ? <i className="fa fa-eye" aria-hidden="true"></i> : <i className="fa fa-eye-slash" aria-hidden="true"></i>}
                                </button>
                                </div>
                            </div>
                            
                            <div className="form-group text-center m-t-20">
                                <div className="col-xs-12 col-lg-8 m-a">
                                    <button className="btn btn-action btn-max btn-md btn-block text-uppercase waves-effect waves-light" onClick={this.validateForm}>Sign In</button>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="col-md-8 col-lg-8 m-a text-center">
                                <Link to="/forgot-password" id="to-recover" className="text-dark text-info"><i className="fa fa-lock m-r-5"></i>I forgot my password</Link> </div>
                            </div>
                        
                            <div className="form-group m-t-40">
                                <div className="col-sm-12">
                                <p className="text-center">Don't have an account? <Link to={{pathname:"/auth", activeTab:"register"}} className="text-info m-l-5"><b>Create One</b></Link></p>
                                </div>
                            </div>
                        </form>

                    </LoadingOverlay>
        </div>
        :

        <div className="login-register customCard">
            <div className="col-lg-6 m-a auth-card">
                <div className="card-body m-t-40">
                    <h1 className="m-t-40 font-normal">Welcome Back</h1>
                    <p className="font-medium">
                        Sign in to access your personalized homepage, 
                        find investment opportunities and follow the people, 
                        companies and industries that matter to you.
                    </p>
                    <div className="col-md-8">
                        <div className="col-md-6 float-left"><LinkedInAuth abel="Register with Linked In"/></div>
                        <div className="col-md-6 float-left"><GoogleAuth label="Register with Google" url="http://localhost:8086/oauth2/authorize/google?redirect_uri=http://localhost:3000/oauth2/callback/google"/></div>
                        {/* <div className="col-md-4 float-left"><LinkedInAuth /></div> */}
                    </div>
                    <LoadingOverlay>
                        <Loader loading={loading}/>
                        <form className="form-horizontal col-10 m-t-20 m-a" id="loginform" action="index.html">
                            <h4 className="box-title m-b-20 font-medium line-header">Or</h4>

                            <div className="form-group">
                                    <label className="control-label text-black m-b-0">Email/Mobile No.</label>
                                    <input className="form-control" type="text" required=""  name="userName" onChange={this.handleChange}/> 
                            </div>
                            <div className="form-group">
                                    <label className="control-label text-black m-b-0">Password</label>
                                    <input className="form-control" type="password" required=""  name="password" onChange={this.handleChange} maxLength="32"/> 
                           
                            </div>

                            
                            
                            <div className="form-group text-center m-t-20">
                                <div className="col-xs-12 col-lg-8 m-a">
                                    <button className="btn btn-action btn-max btn-md btn-block text-uppercase waves-effect waves-light" onClick={this.validateForm}>Sign In</button>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="col-md-8 col-lg-8 m-a text-center">
                                <Link to="/forgot-password" id="to-recover" className="text-dark text-info"><i className="fa fa-lock m-r-5"></i>I forgot my password</Link> </div>
                            </div>
                        
                            <div className="form-group m-t-40">
                                <div className="col-sm-12">
                                <p className="text-center">Don't have an account? <Link to={{pathname:"/auth", activeTab:"register"}} className="text-info m-l-5"><b>Create One</b></Link></p>
                                </div>
                            </div>
                        </form>

                    </LoadingOverlay>
                </div>
            </div>
        </div>
        
        }
          
      </div>
    )
  }
}

const mapStateToProps = (state) =>({
    auth:state.auth,
    errors:state.errors
});

export default connect(mapStateToProps,{login,fetchUserInfo,getAllSectorData,logoutUser})(withRouter(Loginpage));
