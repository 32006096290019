import React, { Component } from 'react';
import config from '../../shared/config';
import Helper from '../../shared/custom';
import CardActions from './CardActions';
import CommentShareCount from './CommentShareCount';
import CommentShareBtn from './CommentShareBtn';
import Comments from './Comments';
import AuthorDetails from './AuthorDetails';
import ReadMoreAndLess from 'react-read-more-less';
import {Link} from 'react-router-dom';


export default class ContentCard extends Component {
    constructor(props){
        super(props);
        this.state = {
            focusInput:false,
            updatedId :null
        }
        this.focusInputFiled = this.focusInputFiled.bind(this);
        this.updateCounts = this.updateCounts.bind(this);
    }

    focusInputFiled(){
        this.setState({
            focusInput: !this.state.focusInput
        })
    }
    updateCounts(id){
        if(!Helper.isEmpty(id)){
            this.setState({
                updatedId:id
            })
        }
    }
    addDefaultNewsImage(ev) {
        ev.target.src = Helper.dataPath("default/news-default-image.png");
    }
    render() {

        let data = this.props.data;
    let additionalClass = this.props.additionalClass ? this.props.additionalClass :"";
    let shared = this.props.shared;
    let page = this.props.page;

        return (
            <div>
                <div className="card additionalClass">
                    <div className="card-body action-body">
                        {
                            shared ?
                                <div className="col-lg-12 ">
                                    <AuthorDetails data={data} type="shared" />
                                    <div className="col-lg-3 p-0 float-right text-right">
                                        {false && <CardActions data={data} />}
                                    </div>
                                    <div className="clearfix"></div>

                                    <div className="col-lg-12 p-0 m-t-20">
                                    <hr/>
                                        {/* <AuthorDetails data={data}/> */}
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            :
                                page == "public" ? 
                                ""
                                :
                                <div className="col-lg-12 p-0">
                                    {/* <AuthorDetails data={data}/> */}
                                    <div className="col-lg-3 p-0 float-right text-right">
                                     { false &&     <CardActions data={data}/> }
                                    </div>
                                </div>

                        }
                    </div>

                    <div className="card-body laptop p-b-0">
                        <div className="col-lg-12 p-0 ">
                            <div className="m-a text-center ">
                                <div className="c_img_al">
                                       <img onError={this.addDefaultNewsImage} src={Helper.dataPath(Helper.isEmpty(data && data.profileImage) ? config.defaultNewsIcon : data.profileImage)} className="feed-img img-curved m-w" />
                                 </div>

                                <div className="col-lg-12 p-0 font-12 text-left m-t-5 f">
                                    <a className="m-0 v-a text-blue" href={!Helper.isEmpty(data.url) ? Helper.properExternalUrl(data.url) : ""}><i className="material-icons font-12 v-a">language</i> {!Helper.isEmpty(data.url) ? Helper.extractHostname(data.url) : ""}</a>
                                    <span className="v-a">&nbsp;&nbsp;|&nbsp;&nbsp;<i className="material-icons font-12 v-a">calendar_today</i><span className="v-a v-a-span">&nbsp;{Helper.getDateInInputFormat(data.time)}</span></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-body p-t-0">

                        <div className="col-lg-12 m-t-5 p-0 text-left flexBox">
                            <h5 className="font-14 l-h-22 ellipsisDiv"><Link to={"/news/" + data.uri} className="text-blue">{data.name}</Link></h5>
                        </div>
                        <div className="col-lg-12 p-0 text-left font-14">
                                 {data.summary? Helper.truncate(data.summary,200):"-"}
                        </div>
                        {page == "public" ? 
                            ""
                            :
                            <CommentShareCount data={data}  updatedId={this.state.updatedId}/>      
                        }

                        {page == "public" ? 
                            ""
                            :
                            <CommentShareBtn data={data}  focusInputArea={this.focusInputFiled} updateCounts={this.updateCounts}/>    
                        }
                        
                        {page == "public" ? 
                            ""
                            :
                            <Comments data={data} enableFocus={this.state.focusInput} />      
                        }
                        
                        
                        
                    </div>
                  

                </div>
        
      </div>
    )
  }
}
