import React, { Component } from 'react';
import config from '../../shared/config';
import { withRouter, Link } from 'react-router-dom';
import './style.css';
import Scrollspy from 'react-scrollspy';
import Helper from "../../shared/custom";


export default class NavBar extends Component {
    constructor(props){
        super(props);
    }
   
    render() {
        const  location  = window.location.pathname; 
        return (
            <div className="new_home">
            <nav className="navbar navbar-expand-lg navbar-light fixed-top">
                <div className="container">
                    <a className="navbar-brand" href="/">
                        <img src={config.dataCdnStatic +'plus-logo.png?'} className="nav-logo" />
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent" role="navigation">
                        
                         {
                            location == '/'  ? 
                                <Scrollspy className="navbar-nav mr-auto"  items={['aboutus','membership','faq','contact']}  currentClassName={"active"}>
                                        <li className={`nav-item`} >
                                            <a className="nav-link" href="#aboutus">About</a>
                                        </li>   
                                        <li className={`nav-item`}>
                                            <a className="nav-link" href="#membership" >Membership</a>
                                        </li>   
                                        <li className={`nav-item`} >
                                            <a className="nav-link" href="#faq">FAQ</a>
                                        </li>  
                                        <li className={`nav-item`}>
                                            <a className="nav-link" href="#contact">Contact Us </a>
                                        </li> 
                                        <li className={`nav-item`} >
                                            <Link className="nav-link" to="/features">Features</Link>
                                        </li>
                                </Scrollspy>

                                :
                                <ul className="navbar-nav mr-auto" >

                                </ul>

                            } 
                      
                        <div className="form-inline my-2 my-lg-0">
                            <Link to={{pathname:"/auth", activeTab:"login"}}  className="btn btn-empty my-2 my-sm-0">Login</Link>
                            <Link to={{pathname:"/auth", activeTab:"register"}}  className="btn btn-theme my-2 my-sm-0">Join</Link>
                        </div> 
                    </div>
                </div>
            </nav>
            </div>
        )
    }
}
