import React, { Component } from 'react';
import EditMultiSelect from '../ui/EditMultiSelect';
import DatePicker from "react-datepicker";
import CompanySearch from '../wigets/CompanySearch';
import InvestmentRow from "./InvestmentRow";
import Select from 'react-select';
import Axios from 'axios';
import Helpers from '../../shared/custom';
import STR from '../../shared/strings';

export default class Investments extends Component {

    constructor(props){
        super(props);
        this.state={
            uri:undefined,

            addNewInvesment:false,  
            entries:[],
            investedIn:null,
            fundingRound:null,
            fundingRoundList:[],
            amountInvested:undefined,
            amountPlaceholder:"Amount invested",
            investmentLead:false,
        }
        
        this.addNewInvesment = this.addNewInvesment.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);      
        this.handleFieldValueChange = this.handleFieldValueChange.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this); 
        this.fetchFunding = this.fetchFunding.bind(this); 
        this.mapInvestorToFundingRound = this.mapInvestorToFundingRound.bind(this);
        this.removeInvestorFromFundingRound = this.removeInvestorFromFundingRound.bind(this);
        this.fetchInvestments = this.fetchInvestments.bind(this);
        this.renderInvestmentRow = this.renderInvestmentRow.bind(this);
    }  
    
    componentDidMount(){
        this.setState({
            uri:this.props.uri
        },()=>{
            this.fetchInvestments();
        })
    }

    componentWillReceiveProps(nextProps){
        let newUri = nextProps.uri;
        let currentUri = this.state.uri;

        if(!currentUri || currentUri != newUri){
            this.setState({
                uri:newUri
            },()=>{
                this.fetchInvestments();
            })
        }
    }

    addNewInvesment(){
        this.setState({
            addNewInvesment:this.state.addNewInvesment?false:true
        })
    }

    handleCompanyChange(options){  
        let company = options && options.length > 0 ? options[0] : undefined;
        if(!company){
            return;
        }
        this.fetchFunding(company.uri);        
    }

    handleSelectChange(option,e){   
        let name = e.name;        
        if(name == "fundingRound"){
            let currency = option.roundDetails ? option.roundDetails.currency : null
            let placeholder = "Amount invested";
            if(currency){
                placeholder+= " in "+currency.name;
            }
            this.setState({
                amountPlaceholder:placeholder,
                [name]:option
            })
        }else{
            this.setState({[name]:option});
        }        
    }  

    handleFieldValueChange(e){        
        let name = e.target.name;
        let value = e.target.value;        
        
        if(name){
            this.setState({[name]:value});
        }
    }

    fetchFunding(uri){        
        if(!uri){
          return false;
        }
        
        this.setState({loading:true});
        Axios.get(
          Helpers.apiURL({
            uri:"company/:uri/funding-rounds",
            pathVar: {uri:uri}
          })
        )
        .then((response) => {
          let result = response.data.result || [];   
          let fundingRoundList = [];
          result.forEach(fundingRound => {
            let label = fundingRound.fundingStage ? fundingRound.fundingStage.name : undefined;

            if(!label){
                label = fundingRound.fundingType ? fundingRound.fundingType.name: "";
            }else{
                label = fundingRound.fundingType ? label+" - "+fundingRound.fundingType.name: "";
            }
            fundingRoundList.push({
                value:fundingRound.uniqueId,
                label:label,
                roundDetails:fundingRound       
            });
          });   
          this.setState({
            loading:false,
            isEditable:true,
            fundingRoundList:fundingRoundList
          })
        })
        .catch((response) => {
            this.setState({
                loading:false,
                fundingRoundList:[]
            })
        })
    }
    
    validateForm(){
        let isValid = true;
        let message = [];
        
        if(Helpers.isEmpty(this.state.fundingRound)){
            isValid = false;
            message.push("FUNDING_ROUND_REQUIRED");
          }

        if(Helpers.isEmpty(this.state.amountInvested)){
          isValid = false;
          message.push("INVESTED_AMOUNT_REQUIRED");
        }
    
        if(message.length > 0){
            Helpers.pushTError(message);
            return isValid;
        }
        
        return isValid;
    }

    mapInvestorToFundingRound(){
        
        let isValid = this.validateForm();
        if(!isValid){
            return false;
        }
        
        let fundingRound = this.state.fundingRound;
        let roundDetails = fundingRound.roundDetails;
        let companyDetails = this.props.companyDetails;
        let pageUniqueId = undefined;
        if(companyDetails){
            pageUniqueId = companyDetails.pageDto ? companyDetails.pageDto.uniqueId : undefined
        }
        
        if(!pageUniqueId){
            Helpers.pushTError("INVESTMENT_CREATION_FAILED");
            return false;
        }

        let payload = {
            "amount": Helpers.cleanNumber(this.state.amountInvested),
            "currency": null,
            "investor": {
              "uniqueId": pageUniqueId
            },
            "lead": this.state.investmentLead,
            "partners": []
        }

        if(roundDetails.currency && roundDetails.currency.id){

            payload.currency = {
                "id": roundDetails.currency.id,
            }
        }
        this.setState({loading:true})        
        Axios.post(
            Helpers.apiURL({
                uri:"funding-round/:fundingUniqueId/investor",
                pathVar:{
                    fundingUniqueId:roundDetails.uniqueId
                }
            }),
            payload
        )
        .then((response) => {
            Helpers.pushTSuccess("SAVE_SUCCESS")
            this.setState({
                addNewInvesment:false,
                loading:false,
                investedIn:null,
                fundingRound:null,
                fundingRoundList:[],
                amountInvested:undefined,
                amountPlaceholder:"Amount invested",
                investmentLead:false,
            })
            this.fetchInvestments();
        })
        .catch((err) => {
            Helpers.pushTError("INVESTMENT_CREATION_FAILED");
            this.setState({loading:false})
        })
       
    }

    fetchInvestments(){
        this.setState({loading:true})
        Axios.get(
            Helpers.apiURL({
                uri:"investments/:uri",
                pathVar:{uri:this.props.uri}
            })
        )
        .then((response) => {
            this.setState({loading:false})
            let investments = response.data.result;
            if(investments){
                this.setState({entries:investments})
            }
        })
        .catch((err) => {
            this.setState({loading:false})
        })

    }
    removeInvestorFromFundingRound(element){
        
        let companyDetails = this.props.companyDetails;
        let dataset = element.dataset;
        let fundingUniqueId = dataset.fundinground;
        let index = dataset.index;

        let pageUniqueId = undefined;
        if(companyDetails){
            pageUniqueId = companyDetails.pageDto ? companyDetails.pageDto.uniqueId : undefined
        }
        
        if(!pageUniqueId || !fundingUniqueId){
            Helpers.pushTError("INVESTMENT_DELETE_FAILED");
            return false;
        }


        this.setState({loading:true })
        Axios.delete(
            Helpers.apiURL({
                uri:"funding-round/:fundingUniqueId/investor/:investorUniqueId",
                pathVar:{
                    fundingUniqueId:fundingUniqueId,
                    investorUniqueId:pageUniqueId
                }
            })
        )
        .then((response) => {
            Helpers.pushTSuccess("ITEM_DELETED");            
            if(index){
                let entries = this.state.entries;
                entries.splice(index,1);
                this.setState({
                    entries:entries,
                    addNewInvesment:false,
                    loading:false
                })
            }else{
                this.fetchInvestments();
            }
            
        })
        .catch((err) => {
            this.setState({loading:false})
        })


    }
    renderInvestmentRow(entry, index){
        let page = entry.page;
        let fundingRound = "";
        if(entry.stage && entry.stage.name != "—"){
            fundingRound=entry.stage.name;
        }
        if(entry.type){
            fundingRound += Helpers.isEmpty(fundingRound) ? entry.type.name : Helpers.isEmpty(entry.stage.name) || entry.stage.name == "—"  ? ""+entry.type.name : "- "+entry.type.name;
        }
        
        let removeConfirm = {
            proceed:this.removeInvestorFromFundingRound
        }
        return( 
            <tr key={"FR-IN-"+index}>
                <td>{page.name? page.name : ""}</td>
                <td>{fundingRound}</td>
                <td>{entry.investedAmount && entry.investedAmount != 0 ? Helpers.displayAmount({amount:entry.investedAmount, currency: entry.investedAmountCurrency}) : "-"}</td>
                <td>{entry.isLead ? STR.YES : STR.NO}</td>                
                <td><a className="point" onClick={(event) => Helpers.actionConfirm(event,removeConfirm)} data-index={index} data-fundinground={entry.fundingRoundUniqueId} >{this.state.loading? "..." : STR.DELETE}</a></td>
            </tr>
        )

        /*let image = entry.investor && entry.investor.image ? Helpers.dataPath(entry.investor.image) : Helpers.dataPath(config.defaultUserImage);
        let removeConfirm = {
            proceed:this.removeInvestorFromFundingRound
        }
        return(
            <tr key={"FR-"+this.state.fundingUniqueId+"-"+index}>
                <td>
                    <img src={image}  className="img-circle" width="30" /> &nbsp;{entry.investor.name}
                </td>
                <td>{Helpers.prettyNumber(entry.amount)}</td>
                <td>{entry.lead ? STR.YES : STR.NO }</td>                
                <td><a className="point" onClick={(event) => Helpers.actionConfirm(event,removeConfirm)} data-index={index} data-uniqueid={entry.investor.uniqueId}>{this.state.loading? "..." : STR.DELETE}</a></td>
            </tr>
        )*/
    }

  render() {

   
    let addNewInvesment = this.state.addNewInvesment;
    let fundingRoundList = this.state.fundingRoundList;
    let investments = this.state.entries;
    let noOfInvestments = investments.length;
    return (
      <div className="m-t-20">
       <h5 className="text-black">Investments</h5>
        <div className="grayDiv">          
              <div className="card card-gray m-b-10">                   
                {noOfInvestments > 0 ?
                    <div className="card-body">
                        <table className="table font-14">
                            <thead>
                                <tr>                            
                                    <th>Investment Name</th>
                                    <th>Funding Stage and Type</th>
                                    <th>Invested Amount</th>
                                    <th>Lead Investor</th>   
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody className="bg-white">
                                {
                                    investments.map((item,index) => (
                                        this.renderInvestmentRow(item,index)
                                    ))
                                } 
                            </tbody>
                        </table>
                    </div>
                :
                ""} 
                <div>
                    {addNewInvesment ?
                        <div className="card-body">
                            <h4 className="card-title font-medium">Add New Investment
                                <a href="javascript:void(0)" className="float-right" onClick={this.addNewInvesment}>
                                    <i className="fa fa-times text-danger"></i>
                                </a>
                            </h4>
                        <div className="row">   
                        <div className="col-md-12 row">
                            <div className="col-md-3 col-sm-12  float-left">
                                <h5 className="m-t-10 m-b-10 font-12">Invested In</h5>
                                <CompanySearch 
                                    title="" 
                                    headingClass="hidden" 
                                    onChange={this.handleCompanyChange} 
                                    name="investedIn"
                                    value=""
                                    defaultValue={""}
                                />
                            </div>
                            <div className="col-md-3 col-sm-12  float-left">
                                <h5 className="m-t-10 m-b-10 font-12 isMandatoryLabel">Funding Round</h5>
                                <Select 
                                    className="font-14 col-md-12 p-0 m-0" 
                                    name="fundingRound"
                                    options={fundingRoundList}
                                    value={this.state.fundingRound} 
                                    placeholder="Select Funding round" 
                                    onChange={this.handleSelectChange} 
                                />
                            </div>
                            <div className="col-md-2 col-sm-12  float-left">
                                <h5 className="m-t-10 m-b-10 font-12 isMandatoryLabel">Amount Invested</h5>
                                <input className="form-control" type="text" name="amountInvested" placeholder={this.state.amountPlaceholder} value={this.state.amountInvested} onChange={this.handleFieldValueChange} />
                            </div>
                            <div className="col-md-2 col-sm-12 float-left">
                                <h5 className="m-t-10 m-b-10 font-12">Leading Investor</h5>
                                <div className="m-b-10">
                                    <div className="demo-radio-button">
                                        <div className="float-left col-md-6 col-lg-6 p-0">
                                            <input name="investmentLead" type="radio" id="addInvestmentLeadYes"  value={true} onChange={this.handleFieldValueChange} className="with-gap radio-col-blue" />
                                            <label htmlFor="addInvestmentLeadYes" className="l-h-0">Yes</label>
                                        </div>
                                        <div className="float-left col-md-6 col-lg-6 p-0">
                                            <input name="investmentLead" type="radio" id="addInvestmentLeadNo"  value={false} onChange={this.handleFieldValueChange} className="with-gap radio-col-blue" />
                                            <label htmlFor="addInvestmentLeadNo" className="l-h-0">No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 col-sm-12  float-left">
                                <div className="m-t-20">
                                    
                                     <div>
                                        {this.state.loading ?
                                            <button className="btn btn-white m-t-5 float-left m-r-10">{STR.SAVING}</button>
                                        :
                                            <button className="btn btn-info m-t-5 float-left m-r-10" onClick={this.mapInvestorToFundingRound}>Save</button>
                                        }
                                        
                                        <button className="btn btn-danger m-t-5 float-left " onClick={this.addNewInvesment}>Cancel</button>
                                     </div>
                                    
                                    
                                </div>
                            </div>
                            </div> 

                            </div>                           
                        </div>
                        :
                        // <div className="col-12 m-t-10">
                        //     <a href="javacript:void(0)" className="float-left" onClick={this.addNewInvesment}>+ Add Investment</a>
                        // </div>

                        <div className="m-a text-center col-12 m-t-10">
                            <a href="javascript:void(0)" className="text-default custom-plus-icon" onClick={this.addNewInvesment}></a>
                            <div className="font-12 font-medium">Add Investment</div>
                        </div>

                    }                    
                </div>
               </div>
        </div>
      </div>
    )
  }
}
