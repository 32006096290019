const areas =  [
    {"id":"7546","iso2":"MENA","iso3":"MENA","name":"MENA"},
    {"id":"7547","iso2":"GCC","iso3":"GCC","name":"GCC"},
    {"id":"7552","iso2":"WW","iso3":"WW","name":"Worldwide"},

    {"id":"7553","iso2":null,"iso3":null,"name":"EMEA"},
    {"id":"7554","iso2":null,"iso3":null,"name":"Asia"},
    {"id":"7555","iso2":null,"iso3":null,"name":"South Asia"},
    {"id":"7556","iso2":null,"iso3":null,"name":"Southeast Asia"},
    {"id":"7557","iso2":null,"iso3":null,"name":"East Asia"},
    {"id":"7558","iso2":null,"iso3":null,"name":"Europe"},
    {"id":"7559","iso2":null,"iso3":null,"name":"Africa"},
    {"id":"7560","iso2":null,"iso3":null,"name":"North America"},
    {"id":"7561","iso2":null,"iso3":null,"name":"South America"},
    {"id":"7562","iso2":null,"iso3":null,"name":"Australasia"},

    {"id":"210","iso2":"AE","iso3":"ARE","name":"United Arab Emirates"},    
    {"id":"170","iso2":"SA","iso3":"SAU","name":"Saudi Arabia"},    
    {"id":"16","iso2":"BH","iso3":"BHR","name":"Bahrain"},
    {"id":"1","iso2":"AF","iso3":"AFG","name":"Afghanistan"},
    {"id":"2","iso2":"AX","iso3":"ALD","name":"Aland"},
    {"id":"3","iso2":"AL","iso3":"ALB","name":"Albania"},
    {"id":"4","iso2":"DZ","iso3":"DZA","name":"Algeria"},
    {"id":"5","iso2":"AS","iso3":"ASM","name":"American Samoa"},
    {"id":"6","iso2":"AD","iso3":"AND","name":"Andorra"},
    {"id":"7","iso2":"AO","iso3":"AGO","name":"Angola"},
    {"id":"8","iso2":"AQ","iso3":"ATA","name":"Antarctica"},
    {"id":"9","iso2":"AG","iso3":"ATG","name":"Antigua and Barbuda"},
    {"id":"10","iso2":"AR","iso3":"ARG","name":"Argentina"},
    {"id":"11","iso2":"AM","iso3":"ARM","name":"Armenia"},
    {"id":"12","iso2":"AW","iso3":"ABW","name":"Aruba"},
    {"id":"13","iso2":"AU","iso3":"AUS","name":"Australia"},
    {"id":"14","iso2":"AT","iso3":"AUT","name":"Austria"},
    {"id":"15","iso2":"AZ","iso3":"AZE","name":"Azerbaijan"},
    {"id":"17","iso2":"BD","iso3":"BGD","name":"Bangladesh"},
    {"id":"18","iso2":"BB","iso3":"BRB","name":"Barbados"},
    {"id":"19","iso2":"BY","iso3":"BLR","name":"Belarus"},
    {"id":"20","iso2":"BE","iso3":"BEL","name":"Belgium"},
    {"id":"21","iso2":"BZ","iso3":"BLZ","name":"Belize"},
    {"id":"22","iso2":"BJ","iso3":"BEN","name":"Benin"},
    {"id":"23","iso2":"BM","iso3":"BMU","name":"Bermuda"},
    {"id":"24","iso2":"BT","iso3":"BTN","name":"Bhutan"},
    {"id":"25","iso2":"BO","iso3":"BOL","name":"Bolivia"},
    {"id":"26","iso2":"BA","iso3":"BIH","name":"Bosnia and Herzegovina"},
    {"id":"27","iso2":"BW","iso3":"BWA","name":"Botswana"},
    {"id":"28","iso2":"BR","iso3":"BRA","name":"Brazil"},
    {"id":"29","iso2":"BN","iso3":"BRN","name":"Brunei"},
    {"id":"30","iso2":"BG","iso3":"BGR","name":"Bulgaria"},
    {"id":"31","iso2":"BF","iso3":"BFA","name":"Burkina Faso"},
    {"id":"32","iso2":"BI","iso3":"BDI","name":"Burundi"},
    {"id":"33","iso2":"KH","iso3":"KHM","name":"Cambodia"},
    {"id":"34","iso2":"CM","iso3":"CMR","name":"Cameroon"},
    {"id":"35","iso2":"CA","iso3":"CAN","name":"Canada"},
    {"id":"36","iso2":"CV","iso3":"CPV","name":"Cape Verde"},
    {"id":"37","iso2":"KY","iso3":"CYM","name":"Cayman Islands"},
    {"id":"38","iso2":"CF","iso3":"CAF","name":"Central African Republic"},
    {"id":"39","iso2":"TD","iso3":"TCD","name":"Chad"},
    {"id":"40","iso2":"CL","iso3":"CHL","name":"Chile"},
    {"id":"41","iso2":"CN","iso3":"CHN","name":"China"},
    {"id":"42","iso2":"CO","iso3":"COL","name":"Colombia"},
    {"id":"43","iso2":"KM","iso3":"COM","name":"Comoros"},
    {"id":"44","iso2":"CG","iso3":"COG","name":"Congo (Brazzaville)"},
    {"id":"45","iso2":"CD","iso3":"COD","name":"Congo (Kinshasa)"},
    {"id":"46","iso2":"CK","iso3":"COK","name":"Cook Islands"},
    {"id":"47","iso2":"CR","iso3":"CRI","name":"Costa Rica"},
    {"id":"48","iso2":"HR","iso3":"HRV","name":"Croatia"},
    {"id":"49","iso2":"CU","iso3":"CUB","name":"Cuba"},
    {"id":"50","iso2":"CW","iso3":"CUW","name":"Curacao"},
    {"id":"51","iso2":"CY","iso3":"CYP","name":"Cyprus"},
    {"id":"52","iso2":"CZ","iso3":"CZE","name":"Czech Republic"},
    {"id":"53","iso2":"DK","iso3":"DNK","name":"Denmark"},
    {"id":"54","iso2":"DJ","iso3":"DJI","name":"Djibouti"},
    {"id":"55","iso2":"DM","iso3":"DMA","name":"Dominica"},
    {"id":"56","iso2":"DO","iso3":"DOM","name":"Dominican Republic"},
    {"id":"57","iso2":"TL","iso3":"TLS","name":"East Timor"},
    {"id":"58","iso2":"EC","iso3":"ECU","name":"Ecuador"},
    {"id":"59","iso2":"EG","iso3":"EGY","name":"Egypt"},
    {"id":"60","iso2":"SV","iso3":"SLV","name":"El Salvador"},
    {"id":"61","iso2":"GQ","iso3":"GNQ","name":"Equatorial Guinea"},
    {"id":"62","iso2":"ER","iso3":"ERI","name":"Eritrea"},
    {"id":"63","iso2":"EE","iso3":"EST","name":"Estonia"},
    {"id":"64","iso2":"ET","iso3":"ETH","name":"Ethiopia"},
    {"id":"65","iso2":"FK","iso3":"FLK","name":"Falkland Islands"},
    {"id":"66","iso2":"FO","iso3":"FRO","name":"Faroe Islands"},
    {"id":"67","iso2":"FM","iso3":"FSM","name":"Federated States of Micronesia"},
    {"id":"68","iso2":"FJ","iso3":"FJI","name":"Fiji"},
    {"id":"69","iso2":"FI","iso3":"FIN","name":"Finland"},
    {"id":"70","iso2":"FR","iso3":"FRA","name":"France"},
    {"id":"71","iso2":"PF","iso3":"PYF","name":"French Polynesia"},
    {"id":"72","iso2":"GA","iso3":"GAB","name":"Gabon"},
    {"id":"73","iso2":"GE","iso3":"GEO","name":"Georgia"},
    {"id":"74","iso2":"DE","iso3":"DEU","name":"Germany"},
    {"id":"75","iso2":"GH","iso3":"GHA","name":"Ghana"},
    {"id":"76","iso2":"GI","iso3":"GIB","name":"Gibraltar"},
    {"id":"77","iso2":"GR","iso3":"GRC","name":"Greece"},
    {"id":"78","iso2":"GL","iso3":"GRL","name":"Greenland"},
    {"id":"79","iso2":"GD","iso3":"GRD","name":"Grenada"},
    {"id":"80","iso2":"GU","iso3":"GUM","name":"Guam"},
    {"id":"81","iso2":"GT","iso3":"GTM","name":"Guatemala"},
    {"id":"82","iso2":"GN","iso3":"GIN","name":"Guinea"},
    {"id":"83","iso2":"GW","iso3":"GNB","name":"Guinea Bissau"},
    {"id":"84","iso2":"GY","iso3":"GUY","name":"Guyana"},
    {"id":"85","iso2":"HT","iso3":"HTI","name":"Haiti"},
    {"id":"86","iso2":"HN","iso3":"HND","name":"Honduras"},
    {"id":"87","iso2":"HK","iso3":"HKG","name":"Hong Kong S.A.R."},
    {"id":"88","iso2":"HU","iso3":"HUN","name":"Hungary"},
    {"id":"89","iso2":"IS","iso3":"ISL","name":"Iceland"},
    {"id":"90","iso2":"IN","iso3":"IND","name":"India"},
    {"id":"91","iso2":"ID","iso3":"IDN","name":"Indonesia"},
    {"id":"92","iso2":"IR","iso3":"IRN","name":"Iran"},
    {"id":"93","iso2":"IQ","iso3":"IRQ","name":"Iraq"},
    {"id":"94","iso2":"IE","iso3":"IRL","name":"Ireland"},
    {"id":"95","iso2":"IM","iso3":"IMN","name":"Isle of Man"},
    {"id":"96","iso2":"IL","iso3":"ISR","name":"Israel"},
    {"id":"97","iso2":"IT","iso3":"ITA","name":"Italy"},
    {"id":"98","iso2":"CI","iso3":"CIV","name":"Ivory Coast"},
    {"id":"99","iso2":"JM","iso3":"JAM","name":"Jamaica"},
    {"id":"100","iso2":"JP","iso3":"JPN","name":"Japan"},
    {"id":"101","iso2":"JO","iso3":"JOR","name":"Jordan"},
    {"id":"102","iso2":"KZ","iso3":"KAZ","name":"Kazakhstan"},
    {"id":"103","iso2":"KE","iso3":"KEN","name":"Kenya"},
    {"id":"104","iso2":"KI","iso3":"KIR","name":"Kiribati"},
    {"id":"105","iso2":"","iso3":"KOS","name":"Kosovo"},
    {"id":"106","iso2":"KW","iso3":"KWT","name":"Kuwait"},
    {"id":"107","iso2":"KG","iso3":"KGZ","name":"Kyrgyzstan"},
    {"id":"108","iso2":"LA","iso3":"LAO","name":"Laos"},
    {"id":"109","iso2":"LV","iso3":"LVA","name":"Latvia"},
    {"id":"110","iso2":"LB","iso3":"LBN","name":"Lebanon"},
    {"id":"111","iso2":"LS","iso3":"LSO","name":"Lesotho"},
    {"id":"112","iso2":"LR","iso3":"LBR","name":"Liberia"},
    {"id":"113","iso2":"LY","iso3":"LBY","name":"Libya"},
    {"id":"114","iso2":"LI","iso3":"LIE","name":"Liechtenstein"},
    {"id":"115","iso2":"LT","iso3":"LTU","name":"Lithuania"},
    {"id":"116","iso2":"LU","iso3":"LUX","name":"Luxembourg"},
    {"id":"117","iso2":"MO","iso3":"MAC","name":"Macau S.A.R"},
    {"id":"118","iso2":"MK","iso3":"MKD","name":"Macedonia"},
    {"id":"119","iso2":"MG","iso3":"MDG","name":"Madagascar"},
    {"id":"120","iso2":"MW","iso3":"MWI","name":"Malawi"},
    {"id":"121","iso2":"MY","iso3":"MYS","name":"Malaysia"},
    {"id":"122","iso2":"MV","iso3":"MDV","name":"Maldives"},
    {"id":"123","iso2":"ML","iso3":"MLI","name":"Mali"},
    {"id":"124","iso2":"MT","iso3":"MLT","name":"Malta"},
    {"id":"125","iso2":"MH","iso3":"MHL","name":"Marshall Islands"},
    {"id":"126","iso2":"MR","iso3":"MRT","name":"Mauritania"},
    {"id":"127","iso2":"MU","iso3":"MUS","name":"Mauritius"},
    {"id":"128","iso2":"MX","iso3":"MEX","name":"Mexico"},
    {"id":"129","iso2":"MD","iso3":"MDA","name":"Moldova"},
    {"id":"130","iso2":"MC","iso3":"MCO","name":"Monaco"},
    {"id":"131","iso2":"MN","iso3":"MNG","name":"Mongolia"},
    {"id":"132","iso2":"ME","iso3":"MNE","name":"Montenegro"},
    {"id":"133","iso2":"MA","iso3":"MAR","name":"Morocco"},
    {"id":"134","iso2":"MZ","iso3":"MOZ","name":"Mozambique"},
    {"id":"135","iso2":"MM","iso3":"MMR","name":"Myanmar"},
    {"id":"136","iso2":"NA","iso3":"NAM","name":"Namibia"},
    {"id":"137","iso2":"NP","iso3":"NPL","name":"Nepal"},
    {"id":"138","iso2":"NL","iso3":"NLD","name":"Netherlands"},
    {"id":"139","iso2":"NC","iso3":"NCL","name":"New Caledonia"},
    {"id":"140","iso2":"NZ","iso3":"NZL","name":"New Zealand"},
    {"id":"141","iso2":"NI","iso3":"NIC","name":"Nicaragua"},
    {"id":"142","iso2":"NE","iso3":"NER","name":"Niger"},
    {"id":"143","iso2":"NG","iso3":"NGA","name":"Nigeria"},
    {"id":"144","iso2":"KP","iso3":"PRK","name":"North Korea"},
    {"id":"145","iso2":"","iso3":"CYN","name":"Northern Cyprus"},
    {"id":"146","iso2":"MP","iso3":"MNP","name":"Northern Mariana Islands"},
    {"id":"147","iso2":"NO","iso3":"NOR","name":"Norway"},
    {"id":"148","iso2":"OM","iso3":"OMN","name":"Oman"},
    {"id":"149","iso2":"PK","iso3":"PAK","name":"Pakistan"},
    {"id":"150","iso2":"PW","iso3":"PLW","name":"Palau"},
    {"id":"151","iso2":"PS","iso3":"PSE","name":"Palestine"},
    {"id":"152","iso2":"PA","iso3":"PAN","name":"Panama"},
    {"id":"153","iso2":"PG","iso3":"PNG","name":"Papua New Guinea"},
    {"id":"154","iso2":"PY","iso3":"PRY","name":"Paraguay"},
    {"id":"155","iso2":"PE","iso3":"PER","name":"Peru"},
    {"id":"156","iso2":"PH","iso3":"PHL","name":"Philippines"},
    {"id":"157","iso2":"PL","iso3":"POL","name":"Poland"},
    {"id":"158","iso2":"PT","iso3":"PRT","name":"Portugal"},
    {"id":"159","iso2":"PR","iso3":"PRI","name":"Puerto Rico"},
    {"id":"160","iso2":"QA","iso3":"QAT","name":"Qatar"},
    {"id":"161","iso2":"RO","iso3":"ROU","name":"Romania"},
    {"id":"162","iso2":"RU","iso3":"RUS","name":"Russia"},
    {"id":"163","iso2":"RW","iso3":"RWA","name":"Rwanda"},
    {"id":"164","iso2":"KN","iso3":"KNA","name":"Saint Kitts and Nevis"},
    {"id":"165","iso2":"LC","iso3":"LCA","name":"Saint Lucia"},
    {"id":"166","iso2":"VC","iso3":"VCT","name":"Saint Vincent and the Grenadines"},
    {"id":"167","iso2":"WS","iso3":"WSM","name":"Samoa"},
    {"id":"168","iso2":"SM","iso3":"SMR","name":"San Marino"},
    {"id":"169","iso2":"ST","iso3":"STP","name":"Sao Tome and Principe"},
    {"id":"171","iso2":"SN","iso3":"SEN","name":"Senegal"},
    {"id":"172","iso2":"RS","iso3":"SRB","name":"Serbia"},
    {"id":"173","iso2":"SC","iso3":"SYC","name":"Seychelles"},
    {"id":"174","iso2":"SL","iso3":"SLE","name":"Sierra Leone"},
    {"id":"175","iso2":"SG","iso3":"SGP","name":"Singapore"},
    {"id":"176","iso2":"SK","iso3":"SVK","name":"Slovakia"},
    {"id":"177","iso2":"SI","iso3":"SVN","name":"Slovenia"},
    {"id":"178","iso2":"SB","iso3":"SLB","name":"Solomon Islands"},
    {"id":"179","iso2":"SO","iso3":"SOM","name":"Somalia"},
    {"id":"180","iso2":"SO","iso3":"SOL","name":"Somaliland"},
    {"id":"181","iso2":"ZA","iso3":"ZAF","name":"South Africa"},
    {"id":"182","iso2":"GS","iso3":"SGS","name":"South Georgia and the Islands"},
    {"id":"183","iso2":"KR","iso3":"KOR","name":"South Korea"},
    {"id":"184","iso2":"SS","iso3":"SSD","name":"South Sudan"},
    {"id":"185","iso2":"ES","iso3":"ESP","name":"Spain"},
    {"id":"186","iso2":"LK","iso3":"LKA","name":"Sri Lanka"},
    {"id":"187","iso2":"SD","iso3":"SDN","name":"Sudan"},
    {"id":"188","iso2":"SR","iso3":"SUR","name":"Suriname"},
    {"id":"189","iso2":"SJ","iso3":"SJM","name":"Svalbard and Jan Mayen Islands"},
    {"id":"190","iso2":"SZ","iso3":"SWZ","name":"Swaziland"},
    {"id":"191","iso2":"SE","iso3":"SWE","name":"Sweden"},
    {"id":"192","iso2":"CH","iso3":"CHE","name":"Switzerland"},
    {"id":"193","iso2":"SY","iso3":"SYR","name":"Syria"},
    {"id":"194","iso2":"TW","iso3":"TWN","name":"Taiwan"},
    {"id":"195","iso2":"TJ","iso3":"TJK","name":"Tajikistan"},
    {"id":"196","iso2":"TZ","iso3":"TZA","name":"Tanzania"},
    {"id":"197","iso2":"TH","iso3":"THA","name":"Thailand"},
    {"id":"198","iso2":"BS","iso3":"BHS","name":"The Bahamas"},
    {"id":"199","iso2":"GM","iso3":"GMB","name":"The Gambia"},
    {"id":"200","iso2":"TG","iso3":"TGO","name":"Togo"},
    {"id":"201","iso2":"TO","iso3":"TON","name":"Tonga"},
    {"id":"202","iso2":"TT","iso3":"TTO","name":"Trinidad and Tobago"},
    {"id":"203","iso2":"TN","iso3":"TUN","name":"Tunisia"},
    {"id":"204","iso2":"TR","iso3":"TUR","name":"Turkey"},
    {"id":"205","iso2":"TM","iso3":"TKM","name":"Turkmenistan"},
    {"id":"206","iso2":"TC","iso3":"TCA","name":"Turks and Caicos Islands"},
    {"id":"207","iso2":"TV","iso3":"TUV","name":"Tuvalu"},
    {"id":"208","iso2":"UG","iso3":"UGA","name":"Uganda"},
    {"id":"209","iso2":"UA","iso3":"UKR","name":"Ukraine"},
    {"id":"211","iso2":"GB","iso3":"GBR","name":"United Kingdom"},
    {"id":"212","iso2":"US","iso3":"USA","name":"United States of America"},
    {"id":"213","iso2":"VI","iso3":"VIR","name":"United States Virgin Islands"},
    {"id":"214","iso2":"UY","iso3":"URY","name":"Uruguay"},
    {"id":"215","iso2":"UZ","iso3":"UZB","name":"Uzbekistan"},
    {"id":"216","iso2":"VU","iso3":"VUT","name":"Vanuatu"},
    {"id":"217","iso2":"VA","iso3":"VAT","name":"Vatican (Holy Sea)"},
    {"id":"218","iso2":"VE","iso3":"VEN","name":"Venezuela"},
    {"id":"219","iso2":"VN","iso3":"VNM","name":"Vietnam"},
    {"id":"220","iso2":"EH","iso3":"SAH","name":"Western Sahara"},
    {"id":"221","iso2":"YE","iso3":"YEM","name":"Yemen"},
    {"id":"222","iso2":"ZM","iso3":"ZMB","name":"Zambia"},
    {"id":"223","iso2":"ZW","iso3":"ZWE","name":"Zimbabwe"}
]
export default areas;