import React, { Component } from "react";
import Helper from "../../shared/custom";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

export default class CareerHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      careerHistory: [],
      currentJobsCount: 0,
      previousJobsCount: 0
    };
    this.updateCareerHistoryStats = this.updateCareerHistoryStats.bind(this);
  }

  componentWillReceiveProps(props) {
    let careerHistory = props.careerHistory;

    //if (!Helper.isEmpty(careerHistory)) {
      this.setState(
        {
          careerHistory: !Helper.isEmpty(careerHistory) ? careerHistory : []
        },
        function() {
          this.updateCareerHistoryStats();
        }
      );
   // }
  }

  updateCareerHistoryStats() {
    let currentJobsCount = 0;
    let previousJobsCount = 0;
    this.state.careerHistory.forEach(function(job) {
      if (job.current == true) currentJobsCount++;
      else previousJobsCount++;
    });
    this.setState({
      currentJobsCount: currentJobsCount,
      previousJobsCount: previousJobsCount
    });
  }

  render() {
    let careerHistory = this.state.careerHistory;
    return (
      <div>
        <div className="card text-left" id="career">
        <div className="card-header text-left light-blue-header"><h5 className="m-b-0 m-t-0 text-white">Career History</h5></div>
          <div className="card-body">
            {/* <div className="col-lg-12 p-0 float-left">
              <div className="col-lg-6 p-0 float-left">
                <h5>Career History</h5>
              </div>
            </div> */}

            <div className="clearfix" />

            <div>
              <div className="result m-t-20">
                <div className="row">
                  <div className="col-lg-12 col-md-6 m-b-20">
                    <div>
                    { !Helper.isEmpty(careerHistory) ?
                        <div className="col-md-12 p-0">
                        <div className="row ">
                          <div className="col-md-6 float-left">
                            <div className="col-md-12 box float-left">
                              <div className="col-md-9 float-left">
                                Number of Current Jobs
                              </div>
                              <div className="col-md-3 float-left font-bold">
                                {this.state.currentJobsCount}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 float-left">
                            <div className="col-md-12 box float-left">
                              <div className="col-md-9 float-left">
                                Number of Previous Jobs
                              </div>
                              <div className="col-md-3 float-left font-bold">
                                {this.state.previousJobsCount}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="table-responsive">
                          <h5>All Jobs</h5>
                          <table className="table color-table muted-table  m-t-10 font-14">
                            <thead>
                              <tr>
                                <th>Organization Name</th>
                                <th>Title</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!Helper.isEmpty(careerHistory) ?
                              
                                careerHistory.map((r, i) => (
                                  <tr key={i}>
                                    <td>{!Helper.isEmpty(r.company.uri) ?  <a href={'/company/'+r.company.uri}>{r.company.name}</a> : r.company.name}</td>
                                    <td>{r.title?r.title.name:""}</td>
                                    <td>
                                      {r.startDate ? Helper.getDateInInputFormat(r.startDate) : "-" }
                                    </td>
                                    <td>
                                      {r.current == true ? "Current" : (r.endDate ? Helper.getDateInInputFormat(r.endDate) : "-") }
                                    </td>
                                  </tr>
                                ))
                                :
                                <tr>
                                  <td colspan="4" className="text-center">No Data Available</td>
                                </tr>
                            
                            }
                            </tbody>
                          </table>
                        </div>
                      </div>
                       : 
                       <div className="table-responsive text-center m-t-10">
                          No Data Available
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
