import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Select,{components} from 'react-select';
import config from '../../shared/config';
import { array } from 'prop-types';


export default class CompanyForm extends Component {
  //Since the Select Component uses strict equality operator for comparion of options and value to correctly scroll to the select value,
  // the selected option that mataches the given array is being returned
  getSelectedOption(arr,selectedOption){
    if(arr && arr.length>0 && selectedOption && selectedOption.value){
        var selectedOptionResult;
        arr.forEach(function (element){
            if(element.value && element.value == selectedOption.value){
                selectedOptionResult = element;
            }
        });
        return selectedOptionResult;
    }
    return null;
  }
  render() {
    let values = this.props.values;  
    const Option = props => {
        return (
          <div style={{ color: props.data.color }}>
            <components.Option {...props} />
          </div>
        );
      }; 

      const customStyles = {
          singleValue: (provided, state) => {
            return { ...provided, color:state.data.color};
          }
    }

    return (
      <div>
            <div className="row">
                {/* <div className="col-md-6">
                    <div className="form-group">
                        <label className="control-label font-bold font-18">Sector</label>
                        <Select                            
                            className="font-14 col-md-12 p-0 m-0 zIndexIncreased"
                            name="sector"
                            options={this.props.values.sectorOptions}
                            placeholder="Select sector"
                            value={this.getSelectedOption(values.sectorOptions,values.sector)}
                            onChange={this.props.onSectorChange}
                            isClearable={true}
                        />
                    </div>
                </div>
                <div className="col-md-6"> */}
                    {/* <div className="form-group">
                        <label className="control-label font-bold font-18">Industry</label>
                        <Select                            
                            className="font-14 col-md-12 p-0 m-0 zIndexIncreased"
                            name="industry"
                            options={this.props.values.industryOptions}
                            placeholder="Select industry"
                            value={this.getSelectedOption(values.industryOptions,values.industry)}
                            onChange={this.props.onSelectChange}
                            isClearable={true}
                        />
                    </div>
                </div> */}
            {/* </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="control-label font-bold font-18">Company Size</label>
                        <Select                            
                            className="font-14 col-md-12 p-0 m-0"
                            name="companySize"
                            options={values.companySizeOptions}
                            placeholder="Select company size"
                            value={values.companySize}
                            onChange={this.props.onSelectChange}
                            isClearable={true}
                        />
                    </div>
                </div> */}
                {/* <div className="col-md-6">
                    <div className="form-group  has-search">
                        <label className="control-label font-bold font-18">Year Founded</label>
                        <Select                            
                            className="font-14 col-md-12 p-0 m-0"
                            name="foundedYear"
                            options={values.yearOptions}
                            placeholder="Select founded year"
                            value={values.foundedYear}
                            onChange={this.props.onSelectChange}
                            isClearable={true}
                        />
                    </div>
                </div> */}
                
                <div className="col-md-6">
                    <div className="form-group  has-search">
                        <label className="control-label font-bold font-18">Funding Stage</label>
                        <Select                            
                            className="font-14 col-md-12 p-0 m-0"
                            name="fundingStage"
                            options={values.fundingStageOptions}
                            placeholder="Select Funding Stage"
                            value={values.fundingStage}
                            onChange={this.props.onSelectChange}
                            isClearable={true}
                            />
                    </div>
                </div> 
                <div className="col-md-6">
                    <div className="form-group  has-search">
                        <label className="control-label font-bold font-18">Funding Type</label>
                        <Select                            
                            className="font-14 col-md-12 p-0 m-0"
                            name="fundingType"
                            options={values.fundingTypeOptions}
                            placeholder="Select Funding Type"
                            value={values.fundingType}
                            onChange={this.props.onSelectChange}
                            isClearable={true}
                            />
                    </div>
                </div> 
                <div className="col-md-6">
                    <div className="form-group  has-search">
                        <label className="control-label font-bold font-18">Equity Only Funding</label>
                        <Select                            
                            className="font-14 col-md-12 p-0 m-0"
                            name="isEquityFunding"
                            options={values.booleanOptions}
                            placeholder="Select Option"
                            value={values.isEquityFunding}
                            onChange={this.props.onSelectChange}
                            isClearable={true}
                            />
                    </div>
                </div> 
            </div>
      </div>
    )
  }
}
