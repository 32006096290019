import React, { Component } from 'react'
import classNames from 'classnames/bind';
import Select from 'react-select';
import Helpers from '../../shared/custom';

export default class EditMultiSelect extends Component {

  constructor(props){
    super(props);
  }
    
  render() {     
    let title = this.props.title; 
    let placeholder = this.props.placeholder || "Select...";  
    let titleClass = this.props.titleClass;  
    let { keyVal , required } = this.props;
    let errorMsg = this.props.errorMsg ? this.props.errorMsg : "";
    
    return (
      <div className={classNames(!Helpers.isEmpty(this.props.additionalClassName)?this.props.additionalClassName:'')} key={keyVal}>
          <div className="col-lg-12 p-0 float-left select" key={keyVal+'div'}>
            {title?<h5 className={titleClass?titleClass:""}>{title}{required && <span className="text-danger font-bold">*</span>}</h5>:""} 
            <div>
              <Select 
                  isDisabled = {this.props.isOptionDisabled}
                  className="font-14 col-md-12 p-0 m-0"  
                  key={keyVal+'select'} 
                  name={this.props.name} 
                  isMulti 
                  options={this.props.options} 
                  placeholder={placeholder} 
                  value={this.props.value} 
                  onChange={this.props.onChange}   />                
              {errorMsg && <small class="text-danger errorMsgCustom">{errorMsg}</small>}
            </div>     
          </div>
      </div>
    )
  }
}
