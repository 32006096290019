import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import * as d3 from "d3";
import rd3 from 'react-d3-library';
import D3Explore from './D3Explore';
import Helpers from '../../shared/custom';
import country from '../../shared/country';
import countryNames from '../../shared/countryNames';

import Axios from 'axios';
import config from '../../shared/config';
import Helper from "../../shared/custom";
import { LoadingOverlay, Loader } from 'react-overlay-loader';

let showTooltip = function(d) {
  d3.select(this).classed("node-hovered", true);
  tooltip
    .transition()
    .duration(200)           

  var newPosition = toolTipPosition(d3.mouse(this));
    tooltip
      .style("opacity", 1)
      .html(tootTipContent(d))
      .style("left", newPosition.left + "px")     
      .style("top", newPosition.top + "px");    
    d3.select(".ttb").on("click",function(){      
      component.switchGraph(this);
    });
}

let moveTooltip = function(d) {
tooltip
  .style("left", (d3.mouse(this)[0]+30) + "px")
  .style("top", (d3.mouse(this)[1]+30) + "px")
}

let hideTooltip = function(d) {


if(d && d!= "forced"){
  //d3.select(this).attr("class","node-normal"); 
  d3.select(this).classed("node-hovered", false);
}

if(d == "forced" || !d){
    tooltip
    .style('top', '-600px');
}

}

let toolTipPosition = function(nodePosition){
  var zp = graphConfig.zp;
  var left = (nodePosition[0]*zp.k)+zp.x+15;
  var top = (nodePosition[1]*zp.k)+zp.y+15;
  return{left:left,top:top};
}

let tootTipContent = (d) => {
  let image = d.page.image ? d.page.image : config.defaultCompanyImage;
  let uri = "/company/"+d.page.uri;  
  return '<div>\
              <div>\
                <a target="_blank" href="'+uri+'"><img src="'+Helpers.dataPath(image)+'" width="50" class="float-left"/></a>\
              </div>\
              <div class="m-l-5 float-left text-grey">\
                <a target="_blank" href="'+uri+'"><div class="font-14">'+d.page.name+"</div></a>\
                <div class='font-10'>"+(d.page.city ? (d.page.city.name || d.page.city) : "")+(d.page.country && d.page.city ? ", ": "") + (d.page.country ? (countryNames[d.page.country.id] || d.page.country): "")+"</div>\
              </div><br class='clearfix' />\
              <span class='tooltip-btn ttb' data-uri='"+d.page.uri+"'> Explore </span>\
          </div>";
}   

const style = {
  minHeight: "90vh",
  width: "100%",
  marginLeft: "8px",
  marginTop:"8px",
  background: "#ffffff"
};
var graph, g, link, node, text, tooltip = undefined;
var simulation, component = undefined;

var currentTicks = 1;
var graphConfig = {
  showTitle:true,
  showImage:false,
  nodeRadius: 10,
  nodeBg:"#407ACC",
  nodeBgExternal:"#EEEEEE",
  nodeHoverBg:"#A6C6F8",
  nodeStroke:"#CCC",
  collisionRadius:4,
  tickInterval:1,
  distance:300,
  zp:{k: 1, x: 0, y: 0},
  hideTooltip: function(d) {
    if(d && d!= "forced"){
      d3.select(this).attr("class","node-normal"); 
    }
    if(d == "forced"){
        tooltip        
        .style('top', '-600px');
        //tooltip.remove()
    }
    
  }
}

class D3RelationsContent extends Component {

    constructor(props){
        super(props);
        this.state = {
          loading:true,
          saveData:false,
          summaryLoaded:false,
          graphData:{nodes:[],links:[]},
          detailGraphData:{},
            filterPayload:{country:103,city:3857,linked:true,countryName:'Kenya',cityName:'Nairobi'},
          showDetail:false,
          uri:undefined,
          countryNames:{},
          noDataFound:false,
          localProps:{}
        }
        
       this.switchGraph = this.switchGraph.bind(this);
       this.showDetails = this.showDetails.bind(this);
       this.startGraph = this.startGraph.bind(this);
       this.updateGraph = this.updateGraph.bind(this);
       this.fetchSummaryData = this.fetchSummaryData.bind(this);
       this.fetchDetailData = this.fetchDetailData.bind(this);
    }


    componentDidMount() { 
      Helpers.demand(this,{code:"KG"});
      this.constructGraph();
      this.fetchSummaryData();      
    }

    fetchSummaryData(){
      
      this.setState({
        "loading":true, 
        graphData:{nodes:[],links:[]},
        summaryLoaded:true,
        },
        ()=>{  
            this.startGraph();
        }
      );

      var filterPayload = this.state.filterPayload;
      let kgUrl = config.kgUrl;

      Axios.get(

        // "http://192.168.32.133:9091/knowledge/graph/summary",
        //  "http://ec2-15-185-171-189.me-south-1.compute.amazonaws.com:8084/api/kg",
        //{params: filterPayload},
        // Helpers.apiURL({
        //   uri:"knowledge/graph/summary",
        //   query:filterPayload,
        // })
     
         Helpers.apiURL({
           uri:kgUrl,
           query:filterPayload,
           externel:true,
         })
      )
      .then( (response) => {
          const summary  = response.data.result || response.data || {};
          let loadingState = this.state.loading;
          let saveData = this.state.saveData;
          if(loadingState){
            this.setState({graphData:summary, "loading":false},()=>{
              if(!Helper.isEmpty(this.state.graphData.nodes)){
                this.setState({
                  noDataFound:false,
                })
                this.startGraph();
              }else{
                this.setState({
                  noDataFound:true,
                })
              }
              //this.startGraph();
            });
          }else if(saveData){
            //this.setState({graphData:summary, "loading":false});
          }
      })
      .catch((err) => {
          this.setState({"loading":false});
          this.setState({
            graphData:{nodes:[],links:[]},
            loading:false
          },()=>{
            this.startGraph();
          });
      });
  }
  
  fetchDetailData(uri){
    //detailGraphData
  
  }

  

    componentWillReceiveProps(props){
      
      Helpers.demand(this,{code:"KG"});  

      let uri = props.uri;
      let mode = props.mode;
      let payload = props.payload;
      //if(JSON.stringify(props) != JSON.stringify(this.state.localProps)){     
       
      if(uri != this.state.uri || mode == "summary" ){
          this.setState({
              localProps : props
          });
          if(mode == "summary" ){
            this.setState({
              uri:null,
              showDetail:false,
              filterPayload:payload
            }, ()=>{              
              this.updateGraph();
            })

          }else{
            this.setState({
              uri:uri,
              showDetail:true,
              filterPayload:null,
              //loading:true,
            },()=>{
              this.fetchDetailData(this.state.uri)
            })
          
          }
      }

    }

    showDetails(uri){
      this.setState({
        showDetail:true,
        uri:uri,
        saveData:true
      },()=>{
        graphConfig.hideTooltip("forced");          
      })
    }

    switchGraph(button,view){
      if(view && view == "summary"){ 
        this.setState({
          showDetail:false,
          saveData:false,
          //uri:null,
          // localProps:{
          //   uri:null
          // }
        },()=>{          
          graphConfig.hideTooltip("forced");
          this.props.onClickBack();
        })
      }else{
        let dataset = button && button.dataset ? button.dataset : null;
        this.setState({
          showDetail:true,
          uri:dataset ? dataset.uri : null,
          saveData:true,
          loading:false,
        },()=>{
          graphConfig.hideTooltip("forced");          
        })
      }
    }
 
    constructGraph(){
      component = this;      
      tooltip = d3.select("#mainGraph")
          .append("div")
          .style("opacity", 0)
          .attr("class", "tooltip")
          .html('Tooltip')
          

      let width = 1000;
      let height = 700;
      let svg = this.refs.svg;
      let fullSvg = d3.select("#mainSvg");
      fullSvg.on("click",hideTooltip);

      simulation = d3
        .forceSimulation()
        .force("link", d3.forceLink().id(d => d.id).distance(graphConfig.distance).strength(2))
        .force("charge", d3.forceManyBody())
        .force("center", d3.forceCenter(width / 2, height / 2));
    
      g = d3
        .select("#mainSvg").append("g")
        .attr("class", "everything graph-svg")
      
      link = g.append("g")
        .attr("class", "links")
        .selectAll("line")

      node = g.append("g")
        .attr("class", "nodes")
        .selectAll("circle")

      g.append("defs").append("marker")
        .attr("id", "arrow1")
        .attr("viewBox", "0 -5 10 10")
        .attr("refX", 20)
        .attr("refY", 0)
        .attr("markerWidth", 8)
        .attr("markerHeight", 8)
        .attr("orient", "auto")
        .append("svg:path")
        .attr("fill", function(d) { 
            return d3.color("#ccc"); 
        })
        .attr("d", "M0,-5L10,0L0,5");  


      var zoom_handler = d3.zoom()
      .on("zoom", ()=>{
         g.attr("transform", d3.event.transform);
         graphConfig.zp = d3.event.transform;
         hideTooltip("forced");
      });
      zoom_handler(fullSvg); 

      d3.selectAll('button').on('click', function() {
        if (this.id === 'zoom_in') {
          transition(1.2); // increase on 0.2 each time
        }
        if (this.id === 'zoom_out') {
          transition(0.8); // deacrease on 0.2 each time
        }
        if (this.id === 'zoom_init') {
          d3.transition()
              .delay(100)
              .duration(700)
              .call(zoom_handler.scaleTo, 1); 
        }
      });
      let transition= (zoomLevel) => {
        d3.transition()
            .delay(100)
            .duration(700)
            .call(zoom_handler.scaleBy, zoomLevel);
      }
     
      component.startGraph()
    }

    updateGraph(){
      this.fetchSummaryData();
    }

    startGraph = () =>{
      hideTooltip("forced");
      graph = this.state.graphData;
      
      if(graph.nodes){
        if(graph.nodes.length > 10000){            
            graphConfig.tickInterval = 20;
            graphConfig.nodeRadius = 5;
            graphConfig.collisionRadius = 1;
            graphConfig.showTitle = false;
            graphConfig.showImage = false;
            graphConfig.distance = 20;

        }else if(graph.nodes.length > 3000){
            graphConfig.tickInterval = 5;
            graphConfig.nodeRadius = 10;
            graphConfig.collisionRadius = 1;
            graphConfig.showTitle = false;
            graphConfig.showImage = false;
            graphConfig.distance = 50;

        }else if(graph.nodes.length > 500){ 
            graphConfig.showTitle = false;
            graphConfig.showImage = false;
            graphConfig.distance = 100;
        }else{
            graphConfig.showTitle = true;
            graphConfig.showImage = false;
        }        
     }

     if(graphConfig.showImage){
      let internalNodes = graph.nodes.filter(function(d){return d.e !=1 });
      g.append("defs").selectAll("logo-company")
        .data(internalNodes)
        // .filter(function(d) { return true; /*return d.e != 1 || d.e != true;*/ })
        .enter()
        .append("pattern")
        .attr("class","logo-company")
        .attr("id",function(d){return d.id})
        .attr("height","100%")
        .attr("width","100%")
        .attr("patternContentUnits","objectBoundingBox")
        .append("image")
        .attr("height","1")
        .attr("width","1")
        .attr("preserveAspectRatio","none")
        .attr("xmlns:xlink","http://www.w3.org/1999/xlink")
        .attr("xlink:href",function(d){
          
          if(!Helpers.isEmpty(d.page.image)){
            return Helpers.dataPath(d.page.image);
          }else if(d.page && d.page.page && d.page.page.image){
            return Helpers.dataPath(d.page.page.image)
          }else{
            return d.t == null || d.t == "COM" ? Helpers.dataPath(config.defaultCompanyImage) : Helpers.dataPath(config.defaultUserImage);
          }
      });
     }
     

      link = link.data(graph.links);              
      link.exit().remove();
      link = link.enter().append("line")
              .attr("stroke", function(d) { return d3.color(graphConfig.nodeStroke); })
              .attr("marker-end", "url(#arrow1)")
              .merge(link);
     
      node = node.data(graph.nodes)
      node.exit().remove()
      node = node.enter()
                .append("circle")
                .attr("r", graphConfig.nodeRadius)
                .attr("class",function(d){ 
                  
                  if(!graphConfig.showImage){
                    return d.e == 1 || d.e == true ? "node-external node-normal" : "node-normal"
                  }else{
                    return d.e == 1 || d.e == true ? "node-external-stroke node-normal-stroke" : "node-normal-stroke"
                  }

                  })
                .attr("fill", function(d){
                  if(d.e ==1 || d.e == true){
                    return d3.color(graphConfig.nodeBgExternal);
                  }else{
                    if(graphConfig.showImage){
                      return "url(#"+d.id+")"; 
                    }else{
                      return d3.color(graphConfig.nodeBg);
                    }
                  }
                              
                })
                .style("stroke", function(d) { 
                    return d3.color(graphConfig.nodeStroke); 
                }).call(
                  d3
                    .drag()
                    .on("start", d => this.dragstarted(d, simulation))
                    .on("drag", d => this.dragged(d))
                    .on("end", d => this.dragended(d, simulation))
                )
                .on('mouseover',showTooltip).on('mouseout',hideTooltip)
                .on("click", function (d) {
                  d3.event.stopImmediatePropagation();
                })
                .merge(node);
      
      
      
      // if(graphConfig.showTitle){
      
      //   if(!text){          
      //     text = g.append("g")
      //     .attr("class", "labels")
      //     .selectAll(".label") 
      //   }

      //   text = text.data(graph.nodes);
      //   text.exit().remove();
      //   text = text.enter().append("g")
      //             .attr("class", "label")
      //             .append("text")
      //             .attr("x", 12)
      //             .attr("y", ".31em")
      //             .style("font-family", "sans-serif")
      //             .style("font-size", "0.7em")
      //             .text(function (d) {   
      //                 return d.page.name;
      //               })
      //             .merge(text);
      // }else{
      //   if(text){
      //     text = text.data([])
      //     text.exit().remove()
      //     .merge(text);
      //   }
      // }

      if(graphConfig.showTitle){
      
        if(!text){          
          text = g.append("g")
          .attr("class", "labels")
          .selectAll(".label") 
        }

        text = text.data(graph.nodes);
        text.exit().remove();
        text = text.enter().append("g")
                  .attr("class", "label")
                  .append("text")
                  .attr("x", 12)
                  .attr("y", ".31em")
                  .style("font-family", "sans-serif")
                  .style("font-size", "0.7em")
                  .attr("fill", function(d) { 
                    return d3.color("#fff"); 
                  })
                  .text(function (d) {   
                      return d.page.name;
                    })
                  .merge(text);
      }else{
        if(text){
          text = text.data([])
          text.exit().remove()
          .merge(text);
          text = undefined;
        }
      }





      simulation.nodes(graph.nodes).on("tick", () => this.ticked(link, node));
      simulation.force("link").links(graph.links);
      simulation.alpha(1).restart();
    }

  //  INIT ACTIONS

  dragstarted = (d, simulation) => {
    if (!d3.event.active) simulation.alphaTarget(0.3).restart();
    d.fx = d.x;
    d.fy = d.y;
  };

  dragged = d => {
    d.fx = d3.event.x;
    d.fy = d3.event.y;
  };

  dragended = (d, simulation) => {
    if (!d3.event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
  };

  ticked = (link, node) => {
        currentTicks++;
        if(currentTicks < graphConfig.tickInterval){
          return;
        }
      currentTicks = 0;
        node
        .attr("cx", function(d) { return d.x; })
        .attr("cy", function(d) { return d.y; });
            
        //update link positions 
        link
            .attr("x1", function(d) { return d.source.x; })
            .attr("y1", function(d) { return d.source.y; })
            .attr("x2", function(d) { return d.target.x; })
            .attr("y2", function(d) { return d.target.y; });
        if(graphConfig.showTitle){
            text
            .attr("transform", function (d) { return "translate(" + d.x + "," + d.y + ")"; });
        }
    };
  
    
  
  render() {
    let showDetail = this.state.showDetail;  
    let noDataFound = this.state.noDataFound; 
    let loading = this.state.loading;
    let detailClass =   showDetail ? "columns large-12 small-12 medium-12 container-graph" : "columns large-12 small-12 medium-12 container-graph hidden";
    let summaryClass =  showDetail ? "columns large-12 small-12 medium-12 container-graph  hidden" : "columns large-12 small-12 medium-12 container-graph ";    
    return (
        <div className="graphContent">
          <div className="kg-logo">
              <img src={config.dataCdnStatic +'plus-logo.png?'}  alt="Investera Plus" className="full-width" />
          </div>
          
           { showDetail ?
              <div className={detailClass}>
                  <D3Explore uri={this.state.uri} onExplore={this.switchGraph}/>
                  <a href ="javascript:void(0)" className="btn btn-info" onClick={(target)=>{this.switchGraph(target,"summary")}}>Go Back</a>
              </div>
            :
            ''
            }
            <LoadingOverlay>
              <Loader loading={loading}/>
              {noDataFound ?
                <div className="noGraphData">
                    <i className="fa fa-frown text-grey font-24" aria-hidden="true"></i>
                      <br/>
                      No Data Found
                </div>
                
                :
                ""
              }
              <div className={summaryClass} style={style} id="mainGraph">
                  <svg width="100%" height="90vh" ref="svg" id="mainSvg" />
                  <div className="btn-group  d3-zoom-btn" role="group" aria-label="Basic example">
                    <button id="zoom_in" type="button" className="btn btn-secondary">+</button>
                    <button id="zoom_out" type="button" className="btn btn-secondary">-</button>
                    <button id="zoom_init" type="button" className="btn btn-secondary">Reset</button>
                  </div>
                  {/* <button className="btn btn-info" onClick={this.updateGraph}>Update</button> */}
                </div>
                
          </LoadingOverlay>  

          {
            showDetail ? 

            <div className="alert alert-warning graph-legend col-md-3 col-sm-12">
                <button type="button" className="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">×</span> </button>
                <ul className="list-style-none m-t-10">
                  <li className="clearfix top-gap m-t-5">
                      <div className="line-label line-company  float-left">
                      </div> 
                      <div className="rounded-label-text">
                        Company
                      </div>
                  </li>
                  <li className="clearfix top-gap m-t-5">
                      <div className="line-label line-people float-left">
                      </div> 
                      <div className="rounded-label-text">
                          People
                      </div>
                  </li>   
                </ul> 
            </div>

            :

            <div className="alert alert-warning graph-legend col-md-3 col-sm-12">
                <button type="button" className="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">×</span> </button>
                <ul className="list-style-none m-t-10">
                  <li>
                      <div className="rounded-label blue  float-left">
                      </div> 
                      <div className="rounded-label-text">
                        Within search criteria
                      </div>
                  </li>
                  <li className="clearfix top-gap p-t-10">
                      <div className="rounded-label ash float-left">
                      </div> 
                      <div className="rounded-label-text">
                          Outside search criteria
                      </div>
                  </li>                
                </ul> 
            </div>

          }
             
        </div>
    )
  }
}

export default withRouter(D3RelationsContent) ;
