import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import CompanyForm from './CompanyForm';
import StartupForm from './StartupForm';
import InvestorsForm from './InvestorsForm';
import PeopleForm from './PeopleForm';
import OppForm from './OppForm';
import EventForm from './EventForm';
import FundingForm from './FundingForm';
import queryString from 'query-string';
import Helper from "../../shared/custom";
import moment from "moment";
import Helpers from '../../shared/custom';
import countries from '../../shared/country';
import ApiHelper from '../../shared/ApiHelper';
import Select, { createFilter } from 'react-select';
import store from '../../store';
import { getMetaData } from '../../actions/metaAction';
import { getSectorData } from '../../actions/businessAction';
import { storeAdvanceSearch } from '../../actions/searchAction';
import { EntityBlock } from './EntityBlock';
import { Loader } from 'react-overlay-loader';
import Axios from 'axios';
import SelectableTags from '../ui/SelectableTags'
import SelectableTagsOptionalAdd from '../ui/SelectableTagsOptionalAdd';
import CompanySearch from '../wigets/CompanySearch';
import EditMultiSelect from "../ui/EditMultiSelect";
import DatePicker from "react-datepicker";
import config from '../../shared/config';

class EventsAdvSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "company",
            editSearch: true,
            preSearch: false,
            searchResults: [],
            loading: true,

            metaList: undefined,
            business: undefined,
            metaReady: false,
            businessReady: false,
            roles: [],
            roleOptions: [],
            yearOptions: Helpers.yearOptions(),
            booleanOptions: Helpers.booleanOptions(),

            query: "",
            country: null,
            city: null,
            sector: [],
            industry: [],
            companySize: null,
            foundedYear: null,
            rating: null,
            revenueGenerating: null,
            isRaisingFund: null,
            fundRaisingAmount: null,
            currentInvestors: [],
            investorType: null,
            investorStage: null,
            peopleCompany: [],
            peopleJobTitle: undefined,
            peopleRole: null,
            investmentAmount: null,
            deadline: null,
            date: null,
            privileges: {},
            industryAllOptions: [],
            industryOptions: [],
            fundingStage: null,
            fundingType: null,
            isEquityFunding: null,
            sectionFilter:"sector",
            sectorOptions:[],
            countryCodes:[],
            sectorIds:[],
            startDate: null,
            endDate: null,

            industries: "", 
            isDefValueRendered : false,
            isInitialIndustryValueUpdated : false,          
        }

        store.subscribe(() => {
            var storeState = store.getState();
            var storeMetaList = storeState.meta ? storeState.meta.metaList : null;
            if (storeMetaList) {
                this.setMetaOptions(storeMetaList);
            }
            var storeBusinessList = storeState.business ? storeState.business.data : null;
            if (storeBusinessList) {
                this.setBusinessOptions(storeBusinessList);
            }
            console.log(storeState,"storeMetaList")
            if(storeBusinessList && storeBusinessList.sectorsArray){
                this.setState({
                  userSectorOptions:storeBusinessList.sectorsArray
                })
            }

        });

        var storeState = store.getState();
        if(storeState.search && storeState.search.advanceSearchCriteria){
            this.state.sectorOptions = Helper.makeSelectOptions(storeState.business.data.sectorsArray);
            this.state.sectorIds = storeState.search.advanceSearchCriteria.sectorIds || [];
            this.state.countryCodes = storeState.search.advanceSearchCriteria.countryCodes || [];
            this.state.sectionFilter = storeState.search.advanceSearchCriteria.sectionFilter || "sector";    
          }

        this.changeType = this.changeType.bind(this);
        this.getForm = this.getForm.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.doSearch = this.doSearch.bind(this);
        this.editSearch = this.editSearch.bind(this);
        this.setMetaOptions = this.setMetaOptions.bind(this);
        this.setBusinessOptions = this.setBusinessOptions.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.getCriteriaFor = this.getCriteriaFor.bind(this);
        this.renderSearchCriteria = this.renderSearchCriteria.bind(this);
        this.renderSearchResults = this.renderSearchResults.bind(this);

    }

    componentWillReceiveProps(newProps) {
        let type = this.state.type;
        //Helpers.demand(this,{code:"AD_SER"});
        if (type && type != newProps.type && (this.props.type != newProps.type)) {
            this.setState({
                type: newProps.type,
                preSearch: true,
                editSearch: false
            }, () => {
                this.doSearch();
            })
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        //Helpers.demand(this,{code:"AD_SER"});
        var storeState = store.getState();
        let meta = storeState.meta;
        let business = storeState.business;
        let searchCriteria = !Helpers.isEmpty(storeState.search) ? storeState.search.advanceSearchCriteria : undefined;

        let query = this.props.location.search;
        let querySearch = queryString.parse(query);
        if (querySearch && querySearch.t ) {
            this.setState({
                type: querySearch.t,
                preSearch: true,
                industries : querySearch["industry"] ? querySearch["industry"].split(",") : [],
                editSearch: false
            }, () => {
                this.doSearch();
            })

        } else {
            if (searchCriteria) {
                this.setState(
                    {
                        type: searchCriteria.type,
                        query: searchCriteria.query,
                        country: searchCriteria.country,
                        city: searchCriteria.city,
                        sector: searchCriteria.sector,
                        industry: searchCriteria.industry,
                        companySize: searchCriteria.companySize,
                        foundedYear: searchCriteria.foundedYear,
                        rating: searchCriteria.rating,
                        revenueGenerating: searchCriteria.revenueGenerating,
                        isRaisingFund: searchCriteria.isRaisingFund,
                        fundRaisingAmount: searchCriteria.fundRaisingAmount,
                        currentInvestors: searchCriteria.currentInvestors,
                        investorType: searchCriteria.investorType,
                        investorStage: searchCriteria.investorStage,
                        peopleCompany: searchCriteria.peopleCompany,
                        peopleJobTitle: searchCriteria.peopleJobTitle,
                        peopleRole: searchCriteria.peopleRole,
                        investmentAmount: searchCriteria.investmentAmount,
                        deadline: searchCriteria.deadline,
                        date: searchCriteria.date,
                        fundingStage: searchCriteria.fundingStage,
                        fundingType: searchCriteria.fundingType,
                        isEquityFunding: searchCriteria.isEquityFunding,
                        sectionFilter:searchCriteria.sectionFilter
                    }, () => {
                        if (!Helpers.isEmpty(searchCriteria)) {
                            this.doSearch()
                        }
                    }
                )
            }
        }

        if (Helpers.isEmpty(business.data)) {
            this.props.getSectorData();
        } else {
            this.setBusinessOptions(business.data);
        }

        if (Helpers.isEmpty(meta.metaList)) {
            this.props.getMetaData();
        } else {
            this.setMetaOptions(meta.metaList);
        }

        let countryOptions = Helpers.makeSelectOptions(countries)
        this.setState({ countryOptions: countryOptions })
        this.getRoles();

    }


    setMetaOptions(metaList) {

        let companySizeOptions = [];
        let fundRaisingAmountOptions = [];
        let investorTypeOptions = [];
        let investmentStageOptions = [];
        //let peopleJobTitleOptions  = [];
        let budgetRangeOptions = [];
        let fundingStageOptions = [];
        let fundingTypeOptions = [];

        if (!Helpers.isEmpty(metaList)) {
            companySizeOptions = Helpers.makeSelectOptions(metaList.CSI);
            fundRaisingAmountOptions = Helpers.makeSelectOptions(metaList.FRA);
            investorTypeOptions = Helpers.makeSelectOptions(metaList.ITY);
            investmentStageOptions = Helpers.makeSelectOptions(metaList.IST);
            //peopleJobTitleOptions = Helpers.makeSelectOptions(metaList.CTI);
            budgetRangeOptions = Helpers.makeSelectOptions(metaList.BUD);
            fundingStageOptions = Helpers.makeSelectOptions(metaList.FUS);
            fundingTypeOptions = Helpers.makeSelectOptions(metaList.FUT);

        }

        this.setState({
            metaList: metaList,
            metaReady: true,
            companySizeOptions: companySizeOptions,
            fundRaisingAmountOptions: fundRaisingAmountOptions,
            investorTypeOptions: investorTypeOptions,
            investmentStageOptions: investmentStageOptions,
            //peopleJobTitleOptions:peopleJobTitleOptions,
            budgetRangeOptions: budgetRangeOptions,
            fundingStageOptions: fundingStageOptions,
            fundingTypeOptions: fundingTypeOptions
        })

    }

    setBusinessOptions(business) {
        let sectorOptions = [];
        let industryOptions = [];
        let industryAllOptions = [];



        if (!Helpers.isEmpty(business)) {
            sectorOptions = Helpers.makeSelectOptions(business.sectorsArray);
            industryOptions = Helpers.makeSelectOptions(business.industriesArray);
            industryAllOptions = business.industriesArray;
        }
         if(this.state.industries.length > 0 && !this.state.isDefValueRendered && this.state.industryOptions.length ){
             let {industries} = this.state;
             let industry= industryOptions.filter(x => industries.indexOf(x.value.toString()) >= 0);
             this.setState({isDefValueRendered : true , industry : industry})
         }
       
        this.setState({
            business: business,
            businessReady: true,
            sectorOptions: sectorOptions,
            industryOptions: industryOptions,
            industryAllOptions: industryAllOptions
        })
    }

    getRoles() {
        Axios.get(
            Helpers.apiURL({
                uri: "user/roles/list ",
            })
        )
            .then((response) => {
                let roles = response.data.result || [];
                this.setState({
                    roles: roles,
                    roleOptions: Helpers.makeSelectOptions(roles)
                })
            })
    }

    handleSelectChange(value, element) {
        let name = element.name;
        this.setState({ [name]: value })
    }


    // handleSectorChange = (value, element) => {
    //     let name = element.name;
    //     this.setState({ [name]: value });

    //     this.setState({
    //         industryOptions: Helpers.industriesBySector(this.state.industryAllOptions, value)
    //     });

    // }

    handleSectorChange = (options)=>{
        this.setState({
          sectorIds:options
        },()=>{this.doSearch()})
      }

    handleFieldChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value
        })
    }

    handleCompanyChange(value) {
        this.setState({
            "peopleCompany": value
        })
    }

    handleDateChange(value, name) {
        this.setState({
            [name]: value
        })
    }

    editSearch() {
        this.setState({
            editSearch: true
        })
    }

    doSearch(e) {

        if (e) {
            e.preventDefault();
        }
        this.setState({
            loading: true
        })
        window.scrollTo(0, 0)
        let type = this.state.type;

        let criteria = {
            type: type,
            query: this.state.query,
            country: this.state.country,
            city: this.state.city,
            sector: this.state.sector,
            industry: this.state.industry,
            companySize: this.state.companySize,
            foundedYear: this.state.foundedYear,
            rating: this.state.rating,
            revenueGenerating: this.state.revenueGenerating,
            isRaisingFund: this.state.isRaisingFund,
            fundRaisingAmount: this.state.fundRaisingAmount,
            currentInvestors: this.state.currentInvestors,
            investorType: this.state.investorType,
            investorStage: this.state.investorStage,
            peopleCompany: this.state.peopleCompany,
            peopleJobTitle: this.state.peopleJobTitle,
            peopleRole: this.state.peopleRole,
            investmentAmount: this.state.investmentAmount,
            deadline: this.state.deadline,
            date: this.state.date,
            fundingStage: this.state.fundingStage,
            fundingType: this.state.fundingType,
            isEquityFunding: this.state.isEquityFunding,
            sectionFilter:this.state.sectionFilter,
            countryCodes:this.state.countryCodes,
            startDate: this.state.startDate ? Helper.getDateInInputFormat(this.state.startDate) : null,
            endDate: this.state.endDate ? Helper.getDateInInputFormat(this.state.endDate) : null,

        }

        this.props.storeAdvanceSearch(criteria)

        //build query for search
        let query = {
            searchType: type.toUpperCase(),
            searchText: this.state.query ? this.state.query : null,
            country: this.state.country ? this.state.country.value : null,
            city: this.state.city ? this.state.city.value : null,

            sector: null,
            industry: [],
            companySize: null,
            foundedYear: null,
            rating: null,
            revenueGenerating: null,
            isRaisingFund: null,
            fundRaisingAmount: null,
            investorType: null,
            investorStage: null,
            peopleCompany: null,
            peopleJobTitle: undefined,
            peopleRole: null,
            investmentAmount: null,
            investmentDeadline: null,
            date: null,
            fundingStage: null,
            fundingType: null,
            isEquityFunding: null
        };

        var industryQuery  = [];
        if(!Helpers.isEmpty(this.state.industry)){
            this.state.industry.forEach((val,i)=>{
                industryQuery.push(val.value)
            })
        }



        query.industry = !Helpers.isEmpty(industryQuery) ? industryQuery.join(","): null;
        if(Helpers.isEmpty(query.industry)){
            if(!Helper.isEmpty(this.state.industries) && !this.state.isInitialIndustryValueUpdated){
              query.industry = this.state.industries.join(",");
              this.setState({isInitialIndustryValueUpdated : true})
            }
        }

        switch (type) {
            case "company":
                query.sector = this.state.sector ? this.state.sector.value : null;
                query.industry = this.state.industry ? this.state.industry.value : null;
                query.companySize = this.state.companySize ? this.state.companySize.value : null;
                query.foundedYear = this.state.foundedYear ? this.state.foundedYear.value : null;
                if (!Helpers.isEmpty(this.state.rating)) {
                    query.fromRating = this.state.rating ? this.state.rating.value : null;
                    query.toRating = this.state.rating ? this.state.rating.value : null;
                }
                break;

            case "startup":
                query.revenueGenerating = this.state.revenueGenerating && this.state.revenueGenerating.value != "any" ? (this.state.revenueGenerating.value == "yes" ? true : false) : null;
                query.isRaisingFund = this.state.isRaisingFund && this.state.isRaisingFund.value != "any" ? (this.state.isRaisingFund.value == "yes" ? true : false) : null;
                query.fundRaisingAmount = this.state.fundRaisingAmount ? this.state.fundRaisingAmount.value : null;
                query.sector = this.state.sector ? this.state.sector.value : null;
                query.industry = this.state.industry ? this.state.industry.value : null;
                query.foundedYear = this.state.foundedYear ? this.state.foundedYear.value : null;
                if (!Helpers.isEmpty(this.state.rating)) {
                    query.fromRating = this.state.rating ? this.state.rating.value : null;
                    query.toRating = this.state.rating ? this.state.rating.value : null;
                }
                query.companySize = this.state.companySize ? this.state.companySize.value : null;
                //add current investors here
                break;

            case "investor":
                query.investorType = this.state.investorType ? this.state.investorType.value : null;
                query.investorStage = this.state.investorStage ? this.state.investorStage.value : null;

                break;

            case "people":
                //query.peopleJobTitle = this.state.peopleJobTitle ? this.state.peopleJobTitle.value : null;
                query.peopleJobTitle = this.state.peopleJobTitle ? this.state.peopleJobTitle : null;
                query.peopleRole = this.state.peopleRole ? this.state.peopleRole.value : null;
                let company = this.state.peopleCompany;
                company = company[0] ? company[0] : undefined;
                if (company) {
                    query.peopleCompany = company.uri;
                }
                break;

            case "opportunity":
                query.sector = this.state.sector ? this.state.sector.value : null;
                query.industry = this.state.industry ? this.state.industry.value : null;
                query.investmentAmount = this.state.investmentAmount ? this.state.investmentAmount.value : null;
                query.investmentDeadline = this.state.investmentDeadline ? Helpers.getDateInInputFormat(this.state.investmentDeadline) : null;
                break;

            case "event":
                query.sector = this.state.sectorIds ? this.state.sectorIds.map(x=>x.value).join(",") : null;
                query.country = this.state.countryCodes ? this.state.countryCodes.join(",") : null;
               
                break;

            case "funding":
                query.sector = this.state.sector ? this.state.sector.value : null;
                query.industry = this.state.industry ? this.state.industry.value : null;
                query.companySize = this.state.companySize ? this.state.companySize.value : null;
                query.foundedYear = this.state.foundedYear ? this.state.foundedYear.value : null;
                query.fundingStage = this.state.fundingStage ? this.state.fundingStage.value : null;
                query.fundingType = this.state.fundingType ? this.state.fundingType.value : null;
                query.isEquityFunding = this.state.isEquityFunding && this.state.isEquityFunding.value != "any" ? (this.state.isEquityFunding.value == "yes" ? true : false) : null;
                break;

            default:
                break;
        }


        Axios.get(
            Helpers.apiURL({
                uri: "advance-search",
                query: query
            })
        ).then((response) => {
            let result = response.data.result || response.data;
            this.setState({
                editSearch: false,
                searchResults: result,
                loading: false
            })

        })
            .catch((response) => {
               
            })


    }

    changeType(e) {
    
    }

    handleCountryChange = (countryOptions)=>{
        this.setState({
          countryCodes:countryOptions.map(x=>x.value)
        },()=>{this.doSearch()})
      }

    handleTitleChange = (event) => {
        this.setState({ peopleJobTitle: event[0] });
    }

    handleStartDateChange =(date)=>{
        this.setState({
            startDate:date
        });
      }
    
      handleEndDateChange=(date)=>{
        this.setState({
          endDate:date
        });
      }


    getForm(type) {
 
        switch (type) {
            case "startup":
                return <StartupForm onChange={this.handleFieldChange} onSelectChange={this.handleSelectChange} onSectorChange={this.handleSectorChange} values={this.state} />;
            case "investor":
                return <InvestorsForm onChange={this.handleFieldChange} onSelectChange={this.handleSelectChange} onSectorChange={this.handleSectorChange} values={this.state} />;
            case "people":
                return <PeopleForm handleTitleChange={this.handleTitleChange} onChange={this.handleFieldChange} onSelectChange={this.handleSelectChange} onSectorChange={this.handleSectorChange} values={this.state} onCompanyChange={this.handleCompanyChange} />;
            case "opportunity":
                return <OppForm onChange={this.handleFieldChange} onSelectChange={this.handleSelectChange} onSectorChange={this.handleSectorChange} values={this.state} onDateChange={this.handleDateChange} />;
            case "event":
                return <EventForm onChange={this.handleFieldChange} onSelectChange={this.handleSelectChange} onSectorChange={this.handleSectorChange} values={this.state} onDateChange={this.handleDateChange} />;
            // case "funding":
            //     return <FundingForm onChange={this.handleFieldChange} onSelectChange={this.handleSelectChange} onSectorChange={this.handleSectorChange} values={this.state} onDateChange={this.handleDateChange} />;
            default:
                return <CompanyForm onChange={this.handleFieldChange} onSelectChange={this.handleSelectChange} onSectorChange={this.handleSectorChange} values={this.state} />;
        }

    }

    getCriteriaFor(type) {
        switch (type) {
            case "company":
                return (
                    <div>
                        {this.state.sector ? <h3 className="font-12 m-t-0 l-h-22">Sector: <span className="font-normal">{this.state.sector.label}</span></h3> : ""}
                        {this.state.industry ? <h3 className="font-12 m-t-0 l-h-22">Industry: <span className="font-normal">{this.state.industry.label}</span></h3> : ""}
                        {this.state.companySize ? <h3 className="font-12 m-t-0 l-h-22">Company Size: <span className="font-normal">{this.state.companySize.label}</span></h3> : ""}
                        {this.state.foundedYear ? <h3 className="font-12 m-t-0 l-h-22">Found Year: <span className="font-normal">{this.state.foundedYear.label}</span></h3> : ""}
                        {this.state.rating ? <h3 className="font-12 m-t-0 l-h-22">Rating: <span className="font-normal" style={{ color: "rgb(255, 205, 0)" }}>{this.state.rating.label}</span></h3> : ""}
                    </div>
                )
                break;

            case "startup":
                return (
                    <div>
                        {this.state.sector ? <h3 className="font-12 m-t-0 l-h-22">Sector: <span className="font-normal">{this.state.sector.label}</span></h3> : ""}
                        {this.state.industry ? <h3 className="font-12 m-t-0 l-h-22">Industry: <span className="font-normal">{this.state.industry.label}</span></h3> : ""}
                        {this.state.companySize ? <h3 className="font-12 m-t-0 l-h-22">Company Size: <span className="font-normal">{this.state.companySize.label}</span></h3> : ""}
                        {this.state.foundedYear ? <h3 className="font-12 m-t-0 l-h-22">Found Year: <span className="font-normal">{this.state.foundedYear.label}</span></h3> : ""}
                        {this.state.revenueGenerating ? <h3 className="font-12 m-t-0 l-h-22">Revenue Generating: <span className="font-normal">{this.state.revenueGenerating.label}</span></h3> : ""}
                        {this.state.isRaisingFund ? <h3 className="font-12 m-t-0 l-h-22">Currently Raising Fund: <span className="font-normal">{this.state.isRaisingFund.label}</span></h3> : ""}
                        {this.state.fundRaisingAmount ? <h3 className="font-12 m-t-0 l-h-22">Fund Raising Amount: <span className="font-normal">{this.state.fundRaisingAmount.label}</span></h3> : ""}
                        {this.state.rating ? <h3 className="font-12 m-t-0 l-h-22">Rating: <span className="font-normal" style={{ color: this.state.rating.color }}>{this.state.rating.label}</span></h3> : ""}
                    </div>
                )
                break;

            case "investor":
                return (
                    <div>
                        {this.state.investorType ? <h3 className="font-12 m-t-0 l-h-22">Investor Type: <span className="font-normal">{this.state.investorType.label}</span></h3> : ""}
                        {this.state.investorStage ? <h3 className="font-12 m-t-0 l-h-22">Investor Stage: <span className="font-normal">{this.state.investorStage.label}</span></h3> : ""}
                    </div>
                )
                break;

            case "people":
                return (
                    <div>
                        {/* {this.state.peopleCompany && this.state.peopleCompany[0] ? <h3 className="font-12 m-t-0 l-h-22">Company: <span className="font-normal">{this.state.peopleCompany[0].name}</span></h3> : ""}
                        {this.state.peopleJobTitle ? <h3 className="font-12 m-t-0 l-h-22">Job Title: <span className="font-normal">{this.state.peopleJobTitle}</span></h3> : ""} */}
                        {/* {this.state.peopleRole ? <h3 className="font-16 m-t-0 l-h-22">Role on Investera: <span className="font-normal">{this.state.peopleRole.label}</span></h3>:""} */}
                    </div>
                )
                break;

            case "opportunity":
                return (
                    <div>
                        {this.state.sector ? <h3 className="font-12 m-t-0 l-h-22">Sector: <span className="font-normal">{this.state.sector.label}</span></h3> : ""}
                        {this.state.industry ? <h3 className="font-12 m-t-0 l-h-22">Industry: <span className="font-normal">{this.state.industry.label}</span></h3> : ""}
                        {this.state.investmentAmount ? <h3 className="font-12 m-t-0 l-h-22">Investment Amount: <span className="font-normal">{this.state.investmentAmount.label}</span></h3> : ""}
                        {this.state.investmentDeadline ? <h3 className="font-12 m-t-0 l-h-22">Investment Deadline: <span className="font-normal">{Helpers.getDateInInputFormat(this.state.investmentDeadline)}</span></h3> : ""}
                    </div>
                )
                break;

            case "event":
                return (
                    <div>
                        
                    </div>
                )
                break;

            case "funding":
                return (
                    <div>
                        {this.state.sector ? <h3 className="font-12 m-t-0 l-h-22">Sector: <span className="font-normal">{this.state.sector.label}</span></h3> : ""}
                        {this.state.industry ? <h3 className="font-12 m-t-0 l-h-22">Industry: <span className="font-normal">{this.state.industry.label}</span></h3> : ""}
                        {this.state.companySize ? <h3 className="font-12 m-t-0 l-h-22">Company Size: <span className="font-normal">{this.state.companySize.label}</span></h3> : ""}
                        {this.state.foundedYear ? <h3 className="font-12 m-t-0 l-h-22">Found Year: <span className="font-normal">{this.state.foundedYear.label}</span></h3> : ""}
                        {this.state.revenueGenerating ? <h3 className="font-12 m-t-0 l-h-22">Funding Stage: <span className="font-normal">{this.state.fundingStage.label}</span></h3> : ""}
                        {this.state.isRaisingFund ? <h3 className="font-12 m-t-0 l-h-22">Funding Type: <span className="font-normal">{this.state.fundingType.label}</span></h3> : ""}
                        {this.state.fundRaisingAmount ? <h3 className="font-12 m-t-0 l-h-22">Equity Funding: <span className="font-normal">{this.state.isEquityFunding.label}</span></h3> : ""}
                        {/* {this.state.rating ? <h3 className="font-12 m-t-0 l-h-22">Rating: <span className="font-normal" style={{ color: this.state.rating.color }}>{this.state.rating.label}</span></h3> : ""} */}
                    </div>
                )
                break;


        }

    }

    renderSearchCriteria() {

        let types = {
            company: "Company",
            startup: "Startup",
            investor: "Investor",
            people: "People",
            opportunity: "Opportunity",
            event: "Event", 
            funding: "Funding"
        }

        let searchResults = this.state.searchResults;
        let total = searchResults ? searchResults.length : 0;
        return (<div className="col-12 p-0 text-left m-t-10">

            {this.state.query ? <h3 className="font-12 m-t-0 l-h-22">Contains words: <span className="font-normal">{this.state.query}</span></h3> : ""}
            {this.state.country ? <h3 className="font-12 m-t-0 l-h-22">Country: <span className="font-normal">{this.state.country.label}</span></h3> : ""}
            {this.state.city ? <h3 className="font-12 m-t-0 l-h-22">City: <span className="font-normal">{this.state.city.label}</span></h3> : ""}

            {/* {this.getCriteriaFor(this.state.type)} */}


        </div>
        );
    }

    renderSearchResults(searchResults) {
        return (
            <div>
                {!Helpers.isEmpty(searchResults) ? 
                    searchResults.map((result, index) => (
                        <EntityBlock pointerStyle={"noLink"} key={index} index={index} result={result} ></EntityBlock>
                    ))
                    :
                    <div class="card card-body">
                        <div class="row">
                               <div className="text-center col-md-12">No data found</div>
                        </div>
                    </div>
                }
              

            </div>
        )

    }

    handleSectionFilterChange = (e)=>{
        let countryCodes = this.state.countryCodes;
        let sectorIds = this.state.sectorIds;
        let newState = {
          sectionFilter:e.target.value
        }
        if(e.target.value=="sector"){
          newState.countryCodes = [];
          newState.countryTemporaryOptions = []
        }
        else{
          newState.sectorIds = [];
          newState.sectorTemporaryOptions = []
        }
        this.setState(
          {
            ...newState
          }
        )
      }

      handleCountryDropdownChange = (options)=>{
        this.setState({
          countryTemporaryOptions: options
        })
      }
      handleSectorDropdownChange = (options)=>{
        this.setState({
          sectorTemporaryOptions:options
        })
      }
    render() {
        let form = this.getForm(this.state.type);
        let editSearch = this.state.editSearch;
        let searchResults = this.state.searchResults;
        let type = this.state.type;
        let loading = this.state.loading;

        let total = searchResults ? searchResults.length : 0;
        let types = {
            company: "Company",
            startup: "Startup",
            investor: "Investor",
            people: "People",
            opportunity: "Opportunity",
            event: "Event", 
            funding:"Funding"
        }
        let countrySearchConfig = {
            matchFrom: 'start'
        }
        let countryOptions = Helper.makeSelectOptions(countries.map(x=>{return {id:x.id,name:x.name}}));
        let africaCountries = Helper.makeSelectOptions(countries.filter(x=> x.region=="africa" && x.preffered != true && x.iso2 !="QA").map(x=>{return {id:x.iso2,name:x.name}}));
        let africaPrefferedCountries = Helper.makeSelectOptions(countries.filter(x=>x.region == "africa" && x.preffered == true).map(x=>{return {id:x.iso2,name:x.name}}));

        
        let sectorSubOptions = [];
        let sectorOptions = this.state.sectorOptions;
        if(this.state.userSectorOptions){
            sectorSubOptions = sectorOptions.filter(x=>!this.state.userSectorOptions.map(y=>y.id).includes(x.value));
        }

        let startDate = Helper.getDateInInputFormat(this.state.startDate);
        let endDate = Helper.getDateInInputFormat(this.state.endDate);


        var industryLabel = [];
        if(!Helpers.isEmpty(this.state.industry)){
            this.state.industry.forEach((val,i)=>{
                industryLabel.push(val.label)
            })
        }


        return (
            <div>
                <div className="card bold-headers p-b-10 resultMob">
                    <div className="card-header text-left  custom-card-header p-0 top-radius">
                        <div className="col-12 p-0 text-left">
                            {/* <div className="col-md-4 float-left p-15 crossCut top-radius">
                                <h5 className="m-b-0 m-t-0 text-white">Event Search</h5>
                            </div> */}
                            <div className="col-md-4 float-left p-15 crossCut top-radius">
                                    <h5 className="m-b-0 m-t-0 text-white">Event Search</h5>
                                </div>
                                {
                                editSearch ? 
                                        ""
                                        :
                                        <div className="col-md-8 text-right float-left bg-white p-10">
                                        <a href="javascript:void(0)" className="text-dark-info  font-14" onClick={this.editSearch}>Edit Search Criteria</a>
                                        </div>
                                }
                        </div>
                        
                                <div>
                                {/* <div className="light-blue-header col-md-12 float-left">
                               
                                    <h3 className="text-white font-16 p-b-10">Search Results:</h3>
                                </div> */}
                                <div className="col-12 p-0 m-0 float-left ">
                                <div className="col-6 p-1 d-inline-flex d-flex float-left">
                                      <div className="col-md-12 d-flex justify-content-start p-0 m-t-4 font-I-10">
                                        <input
                                        id="sectorFilter"
                                        className="with-gap radio-col-indigo" 
                                        name="section-filter"
                                        type="radio"
                                        value="sector" 
                                        defaultChecked={this.state.sectionFilter == "sector"}
                                        onChange={this.handleSectionFilterChange}/>
                                      <label htmlFor="sectorFilter" className="m-r-8">Sector</label>
                                      <input
                                            id="countryFilter"
                                            className="with-gap radio-col-indigo" 
                                            name="section-filter"
                                            type="radio"
                                            value="country" 
                                            defaultChecked={this.state.sectionFilter == "country"}
                                            onChange={this.handleSectionFilterChange} />
                                      <label htmlFor="countryFilter">Country</label>                    
                                      </div>                   
                                </div>
                                <div className="col-6 d-inline-flex p-0 m-t-5">
                                {this.state.sectionFilter!="sector" && <SelectableTagsOptionalAdd
                                    //id="sectors"
                                    options={this.state.sectionFilter=="sector"?sectorSubOptions:africaCountries}
                                    onChange={this.state.sectionFilter=="sector"?this.handleSectorDropdownChange:this.handleCountryDropdownChange}
                                /> } 
                                </div>
                                <div className="col-12 d-flex d-inline-flex p-0 text-left">
                                {
                                    this.state.sectionFilter=="sector"?
                                    <SelectableTags
                                        id={"sectors"}
                                        key={"sectors"}
                                        permanentOptions={Helper.makeSelectOptions(this.state.userSectorOptions)}
                                        options={this.state.sectorTemporaryOptions}
                                        selectedOptions={sectorOptions.filter(x=>this.state.sectorIds?this.state.sectorIds.includes(x.value):false)}
                                        enableChange={!this.state.loading} 
                                        onChange={this.handleSectorChange}
                                        />
                                        :
                                        <SelectableTags
                                        id={"countries"}
                                        key={"countries"}
                                        permanentOptions={africaPrefferedCountries}
                                        options={this.state.countryTemporaryOptions}
                                        selectedOptions={countryOptions.filter(x=>this.state.countryCodes.includes(x.value))}
                                        enableChange = {!this.state.loading} 
                                        onChange={this.handleCountryChange}
                                        />
                                    }


                                  
                                </div>

                                {
                                        editSearch ? 
                                        <div className="col-md-12 float-left text-left">
                                            {/* <h3 className="text-black font-16 p-0 underline">Search for: </h3> */}
                                        </div>
                                        :
                                            <div className="lcol-md-12 float-left text-left p-0">
                                            
                                        </div>
                                    }
                                    { editSearch  ? 
                  <div className="card-body">
                  <div className="col-12 p-0 text-left">
                  <form className="shortForm">
                
                  <div className="row">
                      <div className="col-md-12">
                          <div className="form-group">
                              <label className="control-label font-bold font-18">Contains Words</label>
                              <input type="text" id="contain-words" className="form-control" name="query" value={this.state.query} onChange={this.handleFieldChange} />
                          </div>
                      </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                        <div className="form-group has-search">
                            <label className="control-label font-bold font-18">From Date</label>
                               <span className="form-control-feedback"><i className="mdi mdi-calendar-range font-24"></i></span>
                               <DatePicker className="form-control"
                                    selected={this.state.startDate}
                                    onChange={this.handleStartDateChange}
                                    dateFormat={config.getDateInDisplayFormat}
                                    placeholderText="From Date"
                                />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group has-search">
                            <label className="control-label font-bold font-18">To Date</label>
                                <span className="form-control-feedback"><i className="mdi mdi-calendar-range font-24"></i></span>
                                 <DatePicker className="form-control"
                                    selected={this.state.endDate}
                                    onChange={this.handleEndDateChange}
                                    dateFormat={config.getDateInDisplayFormat}
                                    placeholderText="To Date"
                                />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label font-bold font-18">Industry</label>
                            <Select                            
                                className="font-14 col-md-12 p-0 m-0"
                                name="industry"
                                options={this.state.industryOptions}
                                placeholder="Select industry"
                                value={this.state.industry}
                                onChange={this.handleSelectChange}
                                isClearable={true}
                                isMulti
                            />
                        </div>
                    </div>
                  </div>
                 
                  <div className="row m-t-10">
                      <div className="col-md-12">
                            <button className="btn btn-theme float-right text-white btn-normal font-16" onClick={this.doSearch}>Search</button>
                        </div>
                   </div>
                 </form>
                </div>
                </div>

                :
                <div className="card-body p-t-0">
                    <div className="col-12 p-0 text-left m-t-10">
                      {/* <h3 className="text-dark-info font-18">Search Results for:</h3> */}
                      {this.state.query ? <h3 className="font-12 m-t-0 l-h-22">Contains words: <span className="font-normal">{this.state.query}</span></h3> : ""}
                      {/* {!Helper.isEmpty(this.state.companyName) ? <h3 className="font-12 m-t-0 l-h-22 m-b-5">Company: <span className="font-normal">{companyName}</span></h3> : ""} */}
                      {/* {!Helper.isEmpty(this.state.selectedSectors) ? <h3 className="font-12 m-t-0 l-h-22 m-b-5">Sector: <span className="font-normal">{selectedSectors.join(", ")}</span></h3> : ""}*/}
                      {!Helper.isEmpty(this.state.industry) ? <h3 className="font-12 m-t-0 l-h-22">Industry: <span className="font-normal">{industryLabel.join(",")}</span></h3> : ""}
                      <h3 className="font-12  m-t-0 l-h-22 text-black m-b-5">
                          {Helper.isValidDate(this.state.startDate) && Helper.isValidDate(this.state.endDate) ? "Date: " :""}
                          {Helper.isValidDate(this.state.startDate) && !Helper.isValidDate(this.state.endDate) ? "From: " :""}
                          {!Helper.isValidDate(this.state.startDate) && Helper.isValidDate(this.state.endDate) ? "To: " :""}
                          {Helper.isValidDate(this.state.startDate) ? <span className="font-normal text-black">{startDate }</span> : ""}
                          {Helper.isValidDate(this.state.startDate) && Helper.isValidDate(this.state.endDate) ? " to " :""}
                          {Helper.isValidDate(this.state.endDate) ? <span className="font-normal text-black">{endDate }</span>: ""}
                        </h3>
                    </div>
                    {/* <div className="col-12 p-0 text-left">
                        <a href="javascript:void(0)" className="text-dark-info float-right font-16" onClick={this.doSearch}>Edit Search Criteria</a>
                    </div> */}
                </div>
            
             }

                                    
                             </div>
                             </div>
                    </div>
                </div>
                {editSearch ? "" : this.renderSearchResults(searchResults)}
            </div>
        )
    }
}
export default connect(null, { getMetaData, getSectorData, storeAdvanceSearch })(withRouter(EventsAdvSearch));