
import React, { Component } from 'react';
import Select from 'react-select';
export default class InvestorsForm  extends Component {
  render() {
    let values = this.props.values;
    return (
      <div>                
        <div className="row">
            <div className="col-md-6">
                <div className="form-group">
                    <label className="control-label font-bold font-18">Investor Type</label>
                    <Select                            
                        className="font-14 col-md-12 p-0 m-0"
                        name="investorType"
                        options={values.investorTypeOptions}
                        placeholder="Select type"
                        value={values.investorType}
                        onChange={this.props.onSelectChange}
                        isClearable={true}
                        isMulti 
                        
                    />
                </div>
            </div>
            {/* <div className="col-md-6">
                <div className="form-group">
                    <label className="control-label font-bold font-18">Investor Stage</label>
                    <Select                            
                        className="font-14 col-md-12 p-0 m-0"
                        name="investorStage"
                        options={values.investmentStageOptions}
                        placeholder="Select stage"
                        value={values.investorStage}
                        onChange={this.props.onSelectChange}
                        isClearable={true}
                    />
                </div>
            </div> */}
        </div>
      </div>
    )
  }
}
