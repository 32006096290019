import { SET_TOKEN, UNSET_TOKEN, SET_USER,SET_EMAIL_VERIFIED,UNSET_EMAIL_VERIFIED,SHOW_LOADER,HIDE_LOADER}  from '../actions/types';
import { SET_CURRENT_USER , REMOVE_CURRENT_USER, SET_CURRENT_USER_PROFILE, REMOVE_CURRENT_USER_PROFILE}  from '../actions/types';

const initialState = {
   // loading:false
}

export default function( state = initialState, action){
  
    switch(action.type){
        case SHOW_LOADER: return {
            ...state,
            loading : true,
        }

        case HIDE_LOADER: return {
            ...state,
            loading : false,
        }

        case SET_EMAIL_VERIFIED: return {
            ...state,
            isEmailVerified : true,
        }
       
        case UNSET_EMAIL_VERIFIED: return {
            ...state,
            isEmailVerified : false,
        }
        case SET_TOKEN :
        var isAuthenticated = action.payload ?true:false;
        return {
            ...state,
            isAuthenticated : isAuthenticated,
            token : action.payload,
            loading:true
        }

        case UNSET_TOKEN :
        return {
            ...state,
            isAuthenticated : false,
            token : null
        }

        case SET_USER :
        return {
            ...state,
            user : action.payload,
            loading:false

        }

        case REMOVE_CURRENT_USER :
        return{
            ...state,
            isAuthenticated:false,
            user:action.payload
        }
        case SET_CURRENT_USER_PROFILE: 
        return{
            ...state,
            userProfile:action.payload
        }
        default:
            return state;
    }
}