import React, { Component } from 'react';
import store from '../../store';

import OwlCarousel, { Options } from 'react-owl-carousel';
import config from '../../shared/config';
import classNames from 'classnames/bind';
import Axios from 'axios';
import Helper from "../../shared/custom";

import CompanyCard from '../companiessummary/CompanyCard';


class SimilarCompanies extends Component {
    constructor(props){
        super(props);
        this.state = {
            similarCompanies:[],
            uri:null,
            type:null,
            scrollItems:2,
            localprops:{}
        }
    }

    componentDidMount(){
        // this.setState({
        //     uri:this.props.uri,
        //     type:this.props.type,
        //     localprops:props
        // },()=>{
        //     this.fetchSimilarCompanies();
        // })
    }

    componentWillReceiveProps(props){
        if(JSON.stringify(props) != JSON.stringify(this.state.localprops)){
        this.setState({
            uri:props.uri,
            type:props.type,
            localprops:props
        },()=>{
            this.fetchSimilarCompanies();
        })
        if(Helper.isMobile()){
            this.setState({
              scrollItems :1
            })
          }
        }
    }

    fetchSimilarCompanies=()=>{
        Axios.get(
            Helper.apiURL({
                uri:"ws/similar/:pageType/:pageUri",
                pathVar:{pageUri:this.state.uri,pageType:this.state.type},
            })
        )
        .then( (response) => {           
            let data = response.data && response.data.result ? response.data.result : [];
            this.setState({
                similarCompanies:data
            })
          })
        .catch((err) => {
        });
    }
    render() {
        let dataset = this.state.similarCompanies;
        let scrollItems = this.state.scrollItems;
        return (
            <>
            {/* {!Helper.isEmpty(dataset) &&  */}
            <div id="similarCompanies">
                <div className="card text-left b-header">
                    <div className="card-header text-left light-blue-header text-white">
                        Similar Companies
                    </div>
                    <div className="card-body companies blue-border">
                         {!Helper.isEmpty(dataset)? 
                            <OwlCarousel className="owl-theme" items={scrollItems} id="SC"  dots={true} loop={dataset.length > 3 ? true:false} margin={10} autoWidth={false} nav={true}  >
                              {dataset.map((list, i) => (
                                        <CompanyCard data ={list} key={"SC"+i}/>
                                    ))}
                            </OwlCarousel>
                            :<div className="text-center col-12">No Data Available</div>
                        }
                        </div>
                </div>
            </div>
    {/* } */}
    </>
        )
    }
}

export default  SimilarCompanies;