import React, { Component } from 'react';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import Axios from 'axios';
import Helper from "../../shared/custom";
import config from '../../shared/config';
import packages from '../../shared/packageConfig';
import store from '../../store';
import { withRouter ,Link} from 'react-router-dom';

import CountryCode from "../wigets/CountryCode";

import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

class ProceedPay extends Component {
    constructor(props){
        super(props);
        this.state={
            loading:false,
            isLoggedIn:false,
            type:null,
            modal:false,
            mobile:"",
            mpesaSuccess:false,
            mpesaFail:false,
            tinggSuccess:false,
            tinggFail:false,
            errors:{},
            responseError:null,
            identifier:null,
            showContinue:true,
            plans:{},
            isProfessionalBody:false,
            selectedGateway:null,
            merchantTransactionID:null,
            paymentOptions:"",
            
            paymentOptionsFormated:{},
            clientCode:"AIRTELKE"
           
        }
        this.proceedPayment =  this.proceedPayment.bind(this);
        this.backToPage = this.backToPage.bind(this);
        this.myDivToFocus = React.createRef()
      }

      componentDidMount(){
        let type = Helper.getQueryParameter("type");
        this.fetchSubscriptionDetails(type)
        this.setState({
            type:type
        })
      }

      isProfessionalBody = (e)=>{
          this.setState({
            isProfessionalBody:e.currentTarget.name == "no"  ? true:false
          },()=>{
            this.scrollToDiv();
          });
         
        
      }
      fetchSubscriptionDetails = (type)=>{

        this.setState({
            loading:true
        })

          Axios.get(config.api("subscribe/packages/list"))
          .then( (response) => {     
         
             var json_data = response.data;
             var result = json_data.result;
           
             if(!Helper.isEmpty(result)){
                var obj = {}
                result.forEach((item,i)=>{
                    let identifier = item.shortName;
                    obj[identifier] = item;
                })
                this.setState({
                    plans:obj,
                    loading:false
                  })
               // newData.push(obj)
             }
          })
          .catch((err) => { 
            this.setState({
                loading:false
            })
              //Helper.pushTError("PROFILE_NOT_FOUND");
          });
      }


      getUserDetails = ()=>{
        var storeState = store.getState();
        var auth = storeState.auth.user;
        let userId = auth.id;

        Axios.get(
            Helper.apiURL({
                uri:`user/user-info?userId=${userId}`,
            })
        )
        .then( (response) => {
            let data = response.data || {};
            let mobileNumber = "254"+(data && data.mobile ? data.mobile : " XXX XXXX");
            this.setState({
                mobile : !Helper.isEmpty(data.lastPaymentMobileNumber) ? data.lastPaymentMobileNumber : mobileNumber
             })
           
        })
        .catch((err) => {
           
        });
      }

      finishPayment = (e)=>{
          let {  mobile , errors } = this.state;
          let isValid = true;

          mobile = mobile.replaceAll("+", "").replaceAll(" ","");
        
         if(!Helper.allowNumberOnly(mobile)){
            errors["mobile"] = "Please enter valid numbers";
            isValid = false;
         }else{
            errors["mobile"] = "";
         }

         this.setState({errors})
         if(!isValid){
             Helper.pushError("Please fill the mandatory fields");
             return false;
         }

         var extraParams = {};
         extraParams.selectedGateway=this.state.selectedGateway
         extraParams.mobileNumber = mobile;

         this.proceedPayment(e,extraParams)

      }

      finishFullPayment =(e)=>{
          let payerModeID = e.currentTarget.dataset.id;
          let merchantTransactionID = this.state.merchantTransactionID;


          var payload = {
             payerModeID : payerModeID,
             merchantTransactionID : merchantTransactionID
          };

          this.setState({
            loading:true,
          })

          Axios.post(
            config.api("payment/charge"),
            payload)
            .then((response) => {
                this.setState({
                    loading:false,
                    tinggSuccess:response.data.status == 200? true : false,
                    tinggFail:response.data.status != 200? true : false,
                    responseError:response.data.message,
                    modal:false,
                    identifier:merchantTransactionID
                });
            })
            .catch((err) => {
               Helper.pushTError(err);
               this.setState({
                    loading:false,
                    tinggFail:true,
                    //responseError:response.data.message,
                    modal:false,
                    identifier:merchantTransactionID
                });
            });
         
      }

      toggle = ()=>{
            this.setState(prevState=>{
                prevState.modal = !prevState.modal
                return prevState;
            })
      }

    

      onChange = (e)=>{
        e.preventDefault()
        this.setState({[e.target.name]:e.target.value})
     }

     clearX = (e)=>{
        this.setState(prevState=>{
            let mobile = this.state.mobile;
            prevState.mobile = mobile.replace(' XXX XXXX', '');
            return prevState;
        })
     }


      backToPage(){
        this.props.history.push('/');
      }

     

      proceedPayment(e,extraParams){
         e.preventDefault();
         let type = Helper.getQueryParameter("type");
         let plans = this.state.plans;

         let typeKey = type; 

         this.setState({
            loading:true,
         })

         let url = null;
         if(!Helper.isEmpty(extraParams)){
             url = `${config.api("payment/register")}?selectedGateway=${extraParams.selectedGateway}&mobileNumber=${extraParams.mobileNumber}`
         }else{
            url = config.api("payment/register");
         }

         Axios.post(
                url,
                typeKey,
                {headers: {"Content-Type": "text/plain"},query:{extraParams}})
            .then( (response) => {
                if(!Helper.isEmpty(extraParams)){
                    if(extraParams.selectedGateway == "tingg"){
                        var result = response && response.data && response.data.result;
                        var obj = {}
                        if(result && result.checkoutResponse && result.checkoutResponse.paymentOptions){
                            result.checkoutResponse.paymentOptions.forEach((item,i)=>{
                                    obj[item.payerClientCode] = item;
                            })
                        }
                     
                        this.setState({
                            loading:false,
                            // mpesaSuccess:response.data.status == 200? true : false,
                            // mpesaFail:response.data.status != 200? true : false,
                            responseError:response.data.message,
                            //modal:false,
                            merchantTransactionID:response.data.result.checkoutResponse.merchantTransactionID,
                            paymentOptionsFormated:obj,
                            paymentOptions:result.checkoutResponse.paymentOptions

                        }) 
                    }else{
                        this.setState({
                            loading:false,
                            mpesaSuccess:response.data.status == 200? true : false,
                            mpesaFail:response.data.status != 200? true : false,
                            responseError:response.data.message,
                            modal:false,
                            identifier:response.data.result.transactionId
                        }) 
                    }
                   
                    
                }else{
                    let data = response.data.result;
                    let form = document.getElementById('theForm');
                    form.action = data.paymentUrl;
                    if(data.method =="get"){
                        this.setState({
                            loading:false
                        }) 
                        window.location.replace(data.paymentUrl)
                    }else{
                        document.getElementById('transactionId').value = data.transactionId;
                        setTimeout(()=>{
                            form.submit();
                            this.setState({
                                loading:false
                            }) 
                        })
                    }
                }
               
            })
            .catch((err) => {
                Helper.pushTError("Cannot subscribe at the moment! Please contact the administator");
               
                this.setState({
                    loading:false,
                    modal:false,
                })
            });
    }

    proceedPaymentMpesa = (e)=>{
        this.getUserDetails()
        this.setState(prevState=>{
            prevState.modal = !prevState.modal;
            prevState.selectedGateway = "safaricom"
            return prevState;
        })
    }

    proceedPaymentTingg = ()=>{
        this.getUserDetails()
        this.setState(prevState=>{
            prevState.modal = !prevState.modal;
            prevState.selectedGateway = "tingg"
            return prevState;
        })
      }

      toggleContent = (e)=>{
        let clientCode = e.currentTarget.dataset.id;
        this.setState(prevState=>{
            prevState.clientCode = clientCode
            return prevState;
        })
      }

    scrollToDiv=()=>{
        if(this.myDivToFocus.current){
          this.myDivToFocus.current.scrollIntoView({ 
              behavior: "smooth", 
              // block: "nearest",
          })
        }
      }

    clickContinue = (e)=>{
        e.preventDefault();
        let identifier =  this.state.identifier;
        if(identifier){
            this.setState({loading:true})
            Axios.get(
                Helper.apiURL({
                    uri:`payment/details?identifier=${identifier}`,
                })
            )
            .then( (response) => {
                
                let status = response.data.status;
                let requestType = response.data.requestType;

                this.setState({
                    showContinue:status == "SUCCESS" && requestType =="FINALIZE" ?  false : true,
                    loading:false
                });

                if(status == "SUCCESS" && requestType =="FINALIZE"){
                    Helper.pushSuccess("Payment is successful and subscription is upgraded.")
                }else if(status == "SUCCESS" && requestType =="REGISTER"){
                    Helper.pushInfo("Payment is pending. The account will be automatically upgrade once the payment is approved.");
                }else{
                    Helper.pushError(response.data.errorMessage)
                }
               
            })
            .catch((err) => {
                this.setState({
                    showContinue:true,
                    loading:false
                })
            });
        }

       
    }
    render() {
        let loading = this.state.loading;
        let typeKey = null;
        let price = 0;
        let currencySymbol = "";
        let term = "";
        let display = "";
        let privileges = {};
        let category = ""; 
        let { errors , mobile , plans , mpesaSuccess , mpesaFail ,tinggSuccess,tinggFail, responseError , showContinue , type , clientCode , paymentOptions , paymentOptionsFormated} = this.state;
     
        if(!Helper.isEmpty(type) && !Helper.isEmpty(plans)){
            //typeKey = type.toUpperCase();
            currencySymbol = plans[type].currency.shortName;
            price = plans[type].amount;
            term = plans[type].duration;
            display = plans[type].name;
            privileges = plans[type].privileges;
            category = plans[type].category;
        }else{
            return <div></div>
        }

        let plusPayment = Helper.getQueryParameter("paymentstatus");
       

        //console.log(plans[type],"category")
       
      
        return (
                <div className="container content-in m-t-80 p-t-10">
                    <LoadingOverlay>
                        <Loader fullPage loading={loading}/>
                    </LoadingOverlay>
                    <form id="theForm"  method="post" className="hidden">
                        <input type="text" name='TransactionID' placeholder="transaction id" id="transactionId"></input>
                        <button>Submit </button>
                    </form>

                    <MDBContainer>
                        <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
                            <MDBModalHeader toggle={this.toggle}>{Helper.isEmpty(paymentOptions) ? "Please confirm your mobile number!" : "Please select on of the payment options!"}</MDBModalHeader>
                            <MDBModalBody>
                                   {
                                        Helper.isEmpty(paymentOptions) ? 
                                            <div className="form-group  float-left full-width">
                                                <div className="col-md-12 p-0 float-left">
                                                    <div className="col-md-12 p-r-0 m-p-0 float-left paralelInput">
                                                        <label htmlFor="mobile">Mobile No with country code</label>
                                                        <input type="text" 
                                                                className="form-control" 
                                                                id="mobile" 
                                                                name="mobile"
                                                                aria-describedby="mobile" 
                                                                placeholder="Mobile No with country code*" 
                                                                onChange={this.onChange}
                                                                onFocus={this.clearX}
                                                                value={this.state.mobile}/>
                                                        <small className="text-danger">{errors["mobile"]}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        : 
                                        <div>
                                            <div className="row paymentBtns">
                                                {
                                                    this.state.paymentOptions.map((list,i)=>(
                                                        <button onClick={this.toggleContent} className="proceedBtn tinggBtn" data-id={list.payerClientCode}>
                                                            <img src={config.tinggCdnUrl +list.clientLogo} className={list.payerClientCode == clientCode ? "tingg active" : "tingg"}/>
                                                        </button>
                                                    ))
                                                }
                                               
                                            </div>
                                            <div className="col-md-12 m-t-20 instruction">
                                                {paymentOptionsFormated && paymentOptionsFormated[clientCode] &&
                                                    <div key={clientCode} className="content" dangerouslySetInnerHTML={{__html:paymentOptionsFormated[clientCode].paymentInstructions}}></div>
                                                }
                                                 
                                            </div>
                                           
                                        </div>
                                   }
                                    
                            </MDBModalBody>
                            <MDBModalFooter>
                            <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn>
                                {   
                                    Helper.isEmpty(paymentOptions) ? 
                                        <MDBBtn className="btn btn-info" onClick={this.finishPayment}>Proceed</MDBBtn> :  
                                        <MDBBtn className="btn btn-info" onClick={this.finishFullPayment} data-id={paymentOptionsFormated[clientCode].payerModeID}>Proceed</MDBBtn>
                                }
                            </MDBModalFooter>
                        </MDBModal>
                    </MDBContainer>

                    <div className="col-lg-12">
                        <div className="col-lg-12 col-xlg-12 col-md-12">
                            <div className="row">
                                {(mpesaSuccess || tinggSuccess) &&
                                    <div className="full-width alert alert-success alert-dismissible fade show" role="alert">
                                        {showContinue && <p>Thank you! We have initiated the{mpesaSuccess ? " M-Pesa " : ""}payment request, you will shortly receive a notification to confirm your payment. Your account will be upgraded upon payment confirmation.</p>}
                                        {showContinue && <p className="complete-text">If you have paid the full amount. Wait a few minutes then click 'Complete'</p>}
                                        {showContinue && <button type="button" className="btn btn-success" onClick={this.clickContinue}>
                                            Complete
                                        </button>}
                                        {!showContinue && <p>Thank you! We have received your payment and your account has been now upgraded.<a href="/" className="">Click here to continue to home page</a></p>}
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>  
                                }

                                {(mpesaFail || tinggFail) &&
                                    <div className="full-width alert alert-danger alert-dismissible fade show" role="alert">
                                       <p>{`${responseError}. Please contact the administator`}</p>
                                       <p><a href="/" className="">Click here to redirect to homepage</a></p>
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>  
                                }




                               {!mpesaSuccess && !tinggSuccess &&
                                <div className="col-md-12 subscribe proceed">
                                    <div className="card mainCard vh-justify">
                                     <div className="card-body mainCard-top">
                                        <h2 className="font-24">Investera Plus Africa Subscription Plan</h2>
                                        {/* <h2 className="font-24 font-normal">Choose the plan that best suits your needs</h2> */}
                                        <div className="row m-t-30">
                                            <div className="col-md-6 col-lg-6  col-sm-12 float-left m-a">
                                                <div className="card sub-card">
                                                        <div className="card-header blue-bg">
                                                            <h4 className="m-t-10 text-white font-20 capInit">Order Details</h4></div>
                                                        {/* <div className="card-body bg-grey">
                                                            <h3 className="card-title font-24">{currencySymbol} {Helper.prettyNumber(price)}/{term}</h3>
                                                        </div>
                                                </div>
                                           
                                                <div className="card sub-card"> */}
                                                    <div className="card-body proccedMainCard">
                                                        <p className="card-text text-grey m-t-10 text-left">Subscription: <span className="capInit font-bold">{display}</span></p>
                                                        <p className="card-text text-left  m-t-10 ">Total Payment: <span className="font-bold"> {currencySymbol} {Helper.prettyNumber(price)}/{term}</span></p>
                                                    </div>
                                                    <div className="card-body">
                                                         <ul className="list-unstyled ss">
                                                            {privileges && 
                                                                 privileges.map((item,i)=>(
                                                                    <li className="price-row" key={"privileges"+i}>{item.name}</li>
                                                                 ))
                                                            }
                                                              
                                                         </ul>
                                                    {(category == "PREMIUM_PLUS" && plusPayment) ||  category != "PREMIUM_PLUS" ?   
                                                        <div className="m-t-20">
                                                    {/* <button onClick={this.backToPage} className="btn btn-action">Back</button> */}
                                                            <button onClick={this.proceedPaymentMpesa} className="proceedBtn m-t-10"><img src={config.dataCdnStatic +'Pmpesa.png?'} /></button>
                                                            <button onClick={this.proceedPayment} className="proceedBtn"><img src={config.dataCdnStatic +'PPayPal.png?'} /></button>
                                                            {/* <button onClick={this.proceedPaymentTingg} className="proceedBtn">
                                                                <img src={config.dataCdnStatic +'tingg.png?'} className="tingg"/>
                                                             </button> */}
                                                        </div>
                                                        :
                                                        ""
                                                    }
                                                    {category == "PREMIUM_PLUS" &&  !plusPayment? 
                                                        <React.Fragment>
                                                            <p className="font-18 bolder text-center m-t-20">
                                                                Are you registered under any Professional Body?
                                                            </p>
                                                            <p className="m-t-10">
                                                                <a onClick={this.isProfessionalBody} name="no" className="btn btn-info small-btn">No</a>
                                                                <Link onClick={this.isProfessionalBody} to={"/people-details/create"} name="yes" className="btn btn-info small-btn m-l-10">Yes</Link>
                                                            </p>
                                                            {this.state.isProfessionalBody ? 
                                                               <div ref={this.myDivToFocus} >
                                                                   
                                                                    <p className="m-t-20 text-center bolder" style={{color:"red",fontWeight:600}}><i className="fa fa-exclamation-triangle"></i>&nbsp;To subscribe under Premium Plus <i className="font-12">(for Professional)</i></p>
                                                                    <p className="m-t-10 text-center font-14 proceedTC">You need to be member of any Professional Regulatory Body.<br/>
                                                                    To access People, please subscribe to <a href={"/proceed?type=premium"}>Premium package</a></p>
                                                              </div>
                                                            :
                                                            ""
                                                            }
                                                        </React.Fragment>
                                                        :
                                                        <p className="proceedTC font-14 m-t-20">
                                                        By clicking “Proceed to Payment”, you acknowledge that you have read and agreed to our <a href="https://investera.com/wp-content/uploads/2019/05/End-User-License-Agreement-TO-190519.pdf" target="_blank">Terms of Service.</a> 
                                                        </p>
                                                       }
                                                        <div className="m-t-20 m-b-20">
                                                            <a href="javascript:void(0)" onClick={this.backToPage} className="" >   &#60; Back</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                     </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                        </div>
                </div>
        )
    }

}

export default ProceedPay;