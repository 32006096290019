import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import restoreAuth  from './utils/restoreAuth';
import Approuter from './utils/Approuter';
import $ from 'jquery';
// import MainRouter from './utils/MainRouter';
import Navbar  from './components/navbar/Navbar';
import NavBar from './components/new_home/NavBar';
import { ToastContainer, toast } from 'react-toastify';
import PublicHomePage from './components/homepage/PublicHomepage';
import 'react-overlay-loader/styles.css';
import ReactGA from 'react-ga';
import Helpers from './shared/custom';
import VerifyEmail from './components/auth/VerifyEmail';


import { PersistGate } from 'redux-persist/integration/react';
import  ConfigStore   from './reducers/StorePersist';

import WhatsAppWidget from 'react-whatsapp-widget'
import 'react-whatsapp-widget/dist/index.css'

//const { store , persistor } = ConfigStore();
// ReactGA.initialize('UA-194592521-1');
// ReactGA.pageview(window.location.pathname + window.location.search);

ReactGA.initialize(
  [ 
    {
      trackingId: 'UA-194592521-1',
    },
    {
      trackingId: 'AW-324604381',
      gaOptions: {
        name: 'tracker1',
      }
    },
   
  ],
   { debug: false, alwaysSendToDefaultTracker: false }
);

ReactGA.pageview(window.location.pathname + window.location.search);
ReactGA.pageview('/payment', ['tracker1']);
require('dotenv').config();


restoreAuth();
class App extends Component {

  constructor(props){
    super(props)
    this.state = {
      view :"default",
      isLoading : true,
      isLoggedIn:false,
      enableNav:false,
      popupStatus:false,
      showNav:true,
      userAuth:{
        emailConfirmed:false,
        registrationCompleted:false,
       }
    }
    store.subscribe(()=>{
         var storeState = store.getState();  
         this.setState({
          isLoggedIn: storeState.auth.isAuthenticated,
          userAuth:storeState.auth.user
        }) 
    });
  } 

  //This is a quick fix for the indexed links related to the informative website.
  redirectToInvesteraComInfoIfFixingLink = () => {
    let currentHash = window.location.hash;
    if (Helpers.isEmpty(currentHash)) return;
    let listOfHashes = ["#sec-contact","#sec-home","#sec-contact"];
    if (listOfHashes.indexOf(currentHash) > -1)
    window.location = 'https://investera.com/info/'+currentHash;
  }

  componentDidMount() {
     this.redirectToInvesteraComInfoIfFixingLink();
    // setTimeout(()=>{
      this.setState({
        isLoading: false,
       });
    // },1000)
    
     var storeState = store.getState(); 
     this.setState({
       isLoggedIn:storeState.auth.isAuthenticated,
       userAuth:storeState.auth.user
     });

     let localData = localStorage.getItem("auth");

     if(!Helpers.isEmpty(localData)){
      localData = JSON.parse(atob(localData));
        this.setState({
          isLoggedIn: localData.isAuthenticated,
          userAuth:localData.user
        });
     }
     
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   var storeState = store.getState(); 
  //   let resetPassword = storeState.auth.user.resetPassword;
  //   let promptUpgrade = storeState.auth.user.promptUpgrade;
  //   let packageType = storeState.auth.user && storeState.auth.user.promptSubUpgradeTo ? storeState.auth.user.promptSubUpgradeTo.value.toLowerCase()  : null;
  //    if(resetPassword && window.location.pathname !=  "/change-password"){
  //      window.location.href = "/change-password"
  //    }
  //    if(promptUpgrade && packageType && window.location.pathname !=  "/proceed"){
  //       window.location.href = "/proceed?type="+packageType
  //    }
  //    return {
  //    }
  // }


  render() {    
    let isLoggedIn = this.state.isLoggedIn;
    let popupStatus = this.state.popupStatus;
    let location = window.location.pathname;
    let showNav = false;
    let userAuth = this.state.userAuth;
    ReactGA.pageview(window.location.pathname + window.location.search);
    if(isLoggedIn && (userAuth.emailConfirmed && !userAuth.registrationCompleted) && (location != "/verify-email" || location != "/proceed")){
      localStorage.removeItem("lastUrl")
    }
   
    if((!isLoggedIn && location == "/") || 
                      //  location == "/about" || location == "/faq" || location == "/contact" || 
                      //  location == "/membership" || 
                       location =="/home"  || 
                       location =="/auth"  || 
                       location =="/login" || 
                       location =="/register" || 
                       location == "/register/sectors" || 
                       location == "/register/follow" || location == "/errorpage/404" || location == "/register/role" || location == "/register/loading-page" || location == "/register/follow" || location == "/register/role" || location == "/register/loading-page" || location == "/register/loading-page" || location.includes("/public/newsletter/")){
        showNav = false
    }else{  
        if(isLoggedIn && (userAuth.emailConfirmed && !userAuth.registrationCompleted)){
          showNav = false
        }else {
          showNav = true
        }
    }

    let from  = location == "/" ? 'home' : null;
 
    return (
      <Provider store={store}>
         {/* <PersistGate loading={null} persistor={persistor}> */}
            <Router>
              <div>
              {  this.state.isLoading ? 
                    "loading" : 
                    <div>
                        <ToastContainer />
                        <WhatsAppWidget phoneNumber='+254796000666' />
                          {!isLoggedIn? 
                            <div className={`App ${location == "/" || location == "/features" ? "container-fluid" : "container"} custom-container`} id="main-wrapper">
                              <NavBar from={from}/> 
                              <Approuter/>
                            </div>
                          :
                            <div className="App container custom-container" id="main-wrapper">
                              {showNav ? <Navbar/>  : ""}
                              <Approuter/>
                              {/* {userAuth.emailConfirmed ? <Approuter/> : <VerifyEmail/>} */}
                            </div>
                          }
                          
                    </div>              
              }            
              </div>
            </Router>
        {/* </PersistGate> */}
      </Provider>
    );
  }
}
export default App;
