import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Link ,Switch ,withRouter} from "react-router-dom";

class BatchList extends Component {

  constructor(props){
    super(props);
    this.getContent = this.getContent.bind(this);
  }

  getContent(type,item,extraClass,noLink){
    switch(type){
      case "sectors":
        return  <div  key={item.id} className="float-left l-h-j">
                    {noLink ?
                     <p className="font-12 l-h-j">{item.name}</p>:
                     <Link to={"/companies/sector/"+ (item.id ? item.id : item._id) }  className={"label-rounded batch-label font-12 blue-text p-l-0 m-l-0"+extraClass} key={"SEC"+item.id}>{item.name}</Link>
                    }
                </div>
      case "industryGroup":
          return  <div  key={item.id} className="float-left l-h-j">
                     {noLink ?
                     <p className="font-12  l-h-j">{item.name}</p>:<Link to={"/companies/industryGroup/"+ (item.id ? item.id : item._id) }  className={"label-rounded batch-label font-12 blue-text p-l-0 m-l-0"+extraClass} key={"SEC"+item.id}>{item.name}</Link>}
                    </div>
      case "industries":
         return  <div key={item.id} className="float-left l-h-j">
                    {noLink ?<p className="font-12  l-h-j">{item.name}</p> :<Link to={"/companies/industry/"+ (item.id ? item.id : item._id) }  className={"label-rounded font-12 batch-label blue-text p-l-0 m-l-0 "+extraClass} key={"IND"+item.id}>{item.name}</Link>}</div>
      case "subIndustries":
                     return  <div  key={item.id} className="float-left l-h-j">{noLink ?<p className="font-12 l-h-j">{item.name}</p> :<Link to={"/companies/subIndustries/"+ (item.id ? item.id : item._id) }  className={"label-rounded font-12 batch-label blue-text p-l-0 m-l-0 "+extraClass} key={"IND"+item.id}>{item.name}</Link>}</div>
      case "categories" :
                     return <div  key={item.id} className="float-left l-h-j"> {noLink ?<p className="font-12 l-h-j">{item.name}</p> :<Link to={"/companies/tag/"+item.id}  className={"label-rounded font-12 batch-label blue-text p-l-0 m-l-0 "+extraClass} key={"TAG"+item.id}>{item.name}</Link>}</div>
      default:
       return <div  className="float-left l-h-j">{item}</div>
    }

  }
  render() {
    let title = this.props.title;
    let itemList = this.props.items;
    let extraClass = this.props.extraClass? this.props.extraClass :"";
    let type = this.props.type;
    let noborder =  this.props.noborder;
    let noLink = this.props.noLink;

    return (
        <div className={noborder ? "clearfix p-t-10" : "clearfix grey-border round-border p-8" } key={title}>
          {title && itemList?
            <h6 className="m-t-5 m-0 font-14">{title}</h6>
            :
            ""
          }
          <ul className={noLink ? "breadcrumb noLink no-bg p-0 m-l-0" : "breadcrumb no-bg p-0 m-l-0"}>
          {itemList?
                itemList.map((item, index) => (
                   <li key={index}>{this.getContent(type,item,extraClass,noLink)}</li>
                ))
            
            :
          ""
          }
          </ul>
        </div>
    )
  }
}

export default  withRouter(BatchList);