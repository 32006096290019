import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link ,Switch } from "react-router-dom";
import Companydetails from '../components/companyDetails/Companydetails';
import FundingDetails from "../components/companyDetails/FundingDetails";

class Subrouter extends Component {
    
  render() {
    return (
            <div>
                <Switch>
                    <Route path="/company/:entityUri" component={Companydetails}  persistSearch = {true}/>
                    <Route path="/company-funding-details/:entityUri" component={FundingDetails} persistSearch = {true}/>
                    {/* <Route path="/company/claim/:entityUri" component={CompanyClaim} />                     */}
                </Switch>
            </div>
    )
  }
}

export default  Subrouter;
