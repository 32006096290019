import React, { Component } from 'react';
import Helpers from '../../shared/custom';
import Axios from 'axios';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

export default class ObjectsSearch extends Component {

  constructor(props){
    super(props);
    this.state = {
      allowNew: props.allowNew ? true : false,
      multiple: props.multiple ? true : false,
      extraQuery:props.extraQuery,
      isLoading: false,
      options: [],
      newSelectionPrefix:"Add a new item: "
    }
    this._handleSearch = this._handleSearch.bind(this);
    this._onChange = this._onChange.bind(this);
  }

  render() {
   
    let title = this.props.title;
    let headingClass = this.props.headingClass? this.props.headingClass : "";
    let defaultSelected = this.props.defaultSelected;
    let required = this.props.required;
    let className = this.props.className;
    let {isLoading, options,allowNew} = this.state;

    return (
      <div className="col-lg-12 p-0 float-left">
         {!Helpers.isEmpty(title) ? <h5 className={headingClass}>{title}{required && <span className="text-danger font-bold">*</span>}</h5> : ""}
         <div className={!Helpers.isEmpty(this.props.inputClassName)?this.props.inputClassName:"col-12 p-0 "}>
         <AsyncTypeahead
         // id={"async-search"}
          minLength={1}
          {...this.state}
          labelKey="name"
          selected={defaultSelected || []}
          placeholder="Type to search"
          onSearch={this._handleSearch}
          onChange={this._onChange}
          renderMenuItemChildren={(option, props) => (
              <div key={"obj-"+option.value} user={option} >
                  <div>
                      <h6 className="font-bold  m-t-5">{option.name} 
                      </h6> 
                  </div>
              </div>
          )}
        
        />
         </div>
      </div>
    )
  }

  _onChange(e){
    this.props.onChange(e);
  }

  _handleSearch = (query) => {
    this.setState({isLoading: true});
    let endPoint = this.props.endPoint?this.props.endPoint:"title";
    var payload = {
        query:query
    }

    if(!Helpers.isEmpty(this.state.extraQuery)){
       this.state.extraQuery.forEach((item,i)=>{
           payload[item.key] = item.value;
       })
    }else{
       payload.all = 1
    }

    
    Axios.get(
        Helpers.apiURL({
          uri:endPoint,
          query:payload
        })
      )
      .then((response) => {
         let objects = response.data.result;
         
         let objectsOptions = [];
          let option = { 
             name: undefined,
             shortName: undefined,
             value:null,
             image:null
          };

          if(!Helpers.isEmpty(objects)){
            objects.forEach(obj => {          
              objectsOptions.push(
                      {
                        name: obj.name,
                        shortName: obj.shortName || obj.description,
                        value:obj.id
                      }
                    )             

            });
          }

        
        this.setState({
          isLoading: false,
          options: objectsOptions,
        })
        // return false;
      })
      .catch((err) => {
        this.setState({
          isLoading: false 
        })
      })
      
  }


}
