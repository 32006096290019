import React, { Component } from 'react';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import Axios from 'axios';
import Helper from "../../shared/custom";
import config from '../../shared/config';
import store from '../../store';

class ProceedPayPublic extends Component {
    constructor(props){
        super(props);
        this.state={
            loading:false,
            isLoggedIn:false,
            type:'basic',
            amount:0,
        }
        this.proceedPayment =  this.proceedPayment.bind(this);
        this.backToPage = this.backToPage.bind(this);
      }

      componentDidMount(){
        // let type = Helper.getQueryParameter("type");
        // this.setState({
        //     type:type
        // })
      }

      handleAmount = (e) =>{
          this.setState({[e.target.name]: e.target.value})
      }

      backToPage(){
        this.props.history.push('/subscribe');
      }

      proceedPayment(e){
          e.preventDefault();
         let type = this.state.type;
         let amount = this.state.amount;
         this.setState({
            loading:true,
         })

           type = "null";
            Axios.post(
                config.api("payment/register?amount="+amount),
                type,
                {headers: {"Content-Type": "text/plain"}})
            .then( (response) => {
                let data = response.data.result;
                let form = document.getElementById('theForm');
                form.action = data.paymentUrl;
                document.getElementById('transactionId').value = data.transactionId;
                setTimeout(()=>{
                    form.submit();
                    this.setState({
                        loading:false
                    }) 
                })
            })
            .catch((err) => {
                Helper.pushTError("Cannot subscribe at the moment! Please contact the administator")
                this.setState({
                    loading:false
                })
            });
    }
    render() {
        let loading = this.state.loading;
        let type;
        if(!Helper.isEmpty(this.state.type)){
            type = this.state.type;
        }

        //(this.state)
      
        return (
            <div>
                <div>
                    <LoadingOverlay>
                        <Loader fullPage loading={loading}/>
                    </LoadingOverlay>
                    <form id="theForm"  method="post" className="hidden">
                        <input type="text" name='TransactionID' placeholder="transaction id" id="transactionId"></input>
                        <button>Submit </button>
                    </form>

                    <div className="row content-in m-t-80 p-t-10">
                        <div className="col-lg-12 col-xlg-12 col-md-12">
                            <div className="row">
                                <div className="col-12 subscribe proceed">
                                    <div className="card mainCard vh-justify">
                                     <div className="card-body mainCard-top">
                                        <h2 className="font-32">Investera Plus Africa Subscription Plan</h2>
                                        {/* <h2 className="font-24 font-normal">Choose the plan that best suits your needs</h2> */}
                                        <div className="row">
                                            <div className="col-md-4 col-lg-4  col-sm-12 float-left m-a">
                                                <div className="card sub-card">
                                                        <div className="card-header blue-bg">
                                                            <img src={config.cdn +'theme/images/plus-logo-white.png?'} alt="Investera +" className="dark-logo logo-home" />
                                                        </div>
                                                       
                                                </div>
                                            </div>
                                           
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 col-lg-4  col-sm-12 float-left m-a">
                                                <div className="card sub-card">
                                                    <div className="card-body bg-grey">
                                                        <h3 className="card-title font-24 m-t-10">Order Details</h3>
                                                        <p className="card-text text-grey m-t-10">Subscription</p>
                                                        <p className="card-text font-bold">Total Payment: $ <input type="number" name="amount" onChange={this.handleAmount}/></p>

                                                        <div className="m-t-20">
                                                            {/* <button onClick={this.backToPage} className="btn btn-action">Back</button> */}
                                                            <button onClick={this.proceedPayment} className="btn btn-info proceedBtn" type="submit">Proceed to payment</button>
                                                        </div>
                                                        <p className="proceedTC font-14">
                                                        By clicking “Proceed to Payment”, you acknowledge that you have read and agreed to our <a href="https://investera.com/wp-content/uploads/2019/05/End-User-License-Agreement-TO-190519.pdf" target="_blank">Terms of Service.</a> 
                                                        </p>
                                                        <div className="m-t-20">
                                                            <a href="javascript:void(0)" onClick={this.backToPage} className="" >   &#60; Back</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                     </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                </div>
            </div>
        )
    }

}

export default ProceedPayPublic;
