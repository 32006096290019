import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Helper from "../../shared/custom";
import store from '../../store';
import Helpers from '../../shared/custom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ForceUpgrade extends Component {

  constructor(props){
    super(props);
    this.state = {
        modal:false,
    }
  }

  componentDidMount(){    
    // this.setState({
    //   modal:this.props.hidePeople
    // })
  }

  showPopup = ()=>{
      this.setState({
        modal:!this.state.modal
      })
  }


  render() {
    const {showPopup} = this.state;
    

    return (
      <React.Fragment>  
          <Modal isOpen={this.state.modal} toggle={this.showPopup} className={"forceUpgradeModel"}>
          <ModalHeader toggle={this.showPopup}>
             
          </ModalHeader>
          <ModalBody>
             <div className="col-md-12 text-center body">
                    <i class="material-icons peopleIcon">people</i>
                    <h2 className="font-18 m-t-20">“People” mean individuals who have a service to<br/>
                        offer to the members of Investera Plus Africa.</h2>
              </div>
              <ul className="font-14 m-t-20">
                  <li>To be listed and have access on “People”, upgrade to <Link to="/proceed?type=premium_plus_monthly">Premium Plus <i className="font-10">(for
                      Professionals)</i></Link> and update your profile.</li>
                   <li>To access “People”, please upgrade to <Link to="/proceed?type=premium">Premium.</Link></li>
              </ul>
              <div className="text-center m-t-50 p-b-20">
                  <Link className="btn btn-info" to={"/subscribe"}>Upgrade Now</Link>
              </div>
          </ModalBody>
        </Modal>
      <div className="card-body forceUpgrade">
         {/* <h5 className="text-black">{title}</h5> */}
            <div className="text-center col-8 m-a text-black">
              <div>
                 <a onClick={this.showPopup}> <i className="fa fa-lock login-icon"></i></a>
              </div>
            </div>
      </div>
      </React.Fragment>
    )
  }
}
export default withRouter(ForceUpgrade);
