import React, { Component } from 'react';
import config from '../../shared/config';
import Helper from '../../shared/custom';
import CardActions from './CardActions';
import CommentShareCount from './CommentShareCount';
import CommentShareBtn from './CommentShareBtn';
import ReadMoreAndLess from 'react-read-more-less';
import Comments from './Comments';
import AuthorDetails from './AuthorDetails';

export default class UserPostCard extends Component {
    constructor(props){
        super(props);
        this.state = {
            focusInput:false,
            updatedId :null
        }
        this.focusInputFiled = this.focusInputFiled.bind(this);
        this.updateCounts = this.updateCounts.bind(this);
    }

    focusInputFiled(){
        this.setState({
            focusInput: !this.state.focusInput
        })
    }
    updateCounts(id){
        if(!Helper.isEmpty(id)){
            this.setState({
                updatedId:id
            })
        }
      }

  render() {
    let data = this.props.data;
    let shared = this.props.shared;
    let validUrl = Helper.isUrl(data.summary);
    
  
    return (
      <div key={data.id}>
          <div className="card" id="" >
                    <div className="card-body">
                        {/* <div className="col-lg-12 p-0">
                            <div className="col-lg-8 p-0">
                                <AuthorDetails data={data}/>
                            </div>
                            <div className="col-lg-3 p-0 float-right text-right">
                               <CardActions data={data}/>
                            </div>
                        </div> */}
                         {
                        shared ? 

                            <div className="col-lg-12 p-0">
                                    <AuthorDetails data={data} type="shared" />
                                    <div className="col-lg-3 p-0 float-right text-right">
                                    <CardActions data={data}/>
                                    </div>
                                <div className="clearfix"></div>

                                <div className="col-lg-12 p-0 m-t-20">
                                <hr/>
                                        <AuthorDetails data={data}/>
                                </div>
                                <div className="clearfix"></div>
                           </div>
                       :
                            <div className="col-lg-12 p-0">
                                <AuthorDetails data={data}/>
                                <div className="col-lg-3 p-0 float-right text-right">
                                    <CardActions data={data}/>
                                </div>
                            </div>

                    }
                        <div className="clearfix"></div>
                            <div className="col-lg-12 p-0 m-t-20 text-left">
                               <div className="font-medium">
                               { !Helper.isEmpty(data.summary) ?
                                   validUrl ?
                                        Helper.isVideoUrl(data.summary) ?
                                            <embed src={Helper.getEmbbedVideoLink(data.summary)}  style={{"width":"100%", "height":" 400px"}}/>
                                            :
                                            <a rel="noreferrer" href={Helper.properExternalUrl(data.summary)} target="_blank">{data.summary}</a>
                                            :
                                        Helper.newLineToBrWithLink(data.summary).map((elem, index)=>(
                                            <div key={index} className="content" dangerouslySetInnerHTML={{__html:elem.props.children}}></div> 
                                        ))
                                    :
                                    ""
                               }
                               </div>
                            </div>
                      </div>

                      {!Helper.isEmpty(data.images) ?
                            <div className="card-body laptop p-0">
                            <div className="col-lg-12 p-0 ">
                                    <div className="m-a text-center">
                                        <img src={Helper.dataPath(data.images[0].publicUri)} className="full-width card-img" />
                                    </div>
                                </div>
                            </div>
                            :
                            ""
                      }
                      
                    <div className="card-body text-left">
                         <CommentShareCount data={data} updatedId={this.state.updatedId} />
                         <CommentShareBtn data={data} focusInputArea={this.focusInputFiled} updateCounts={this.updateCounts}/>
                         <Comments data={data} enableFocus={this.state.focusInput} />
                    </div>
                    
        </div>
        
      </div>
    )
  }
}
