import React, { Component } from 'react';
import store from '../../store';
import config from '../../shared/config';
import Helper from '../../shared/custom';
import Axios from 'axios';
import FeedCards from "./FeedCards";
import PostPopup from '../post/PostPopup';
import InfiniteScroll from 'react-infinite-scroller';
import Feeds from '../wigets/Feeds';
import { withRouter ,Redirect ,Link} from 'react-router-dom';
import AdFeedCard from '../feeds/AdFeedCard';
import ActionCard from '../post/ActionCard';


class UserFeed extends Component {
    constructor(props){
        super(props);
        this.state = {
            editBanner:false,
            editBasicDetails:false,
            feeds:[],
            user:[],
            storeUri:undefined,
            open:false,
            storeUser:undefined,
            localAuth:undefined,
            isPostAdded:false,
            localProps:{},
            isCompanySuccess:false,
            isPeopleSuccess:false
        }
        this.editbanner = this.editbanner.bind(this);
        this.editBasicDetails = this.editBasicDetails.bind(this);
        this.openModal = this.openModal.bind(this);
        this.isPostAdded = this.isPostAdded.bind(this);

        store.subscribe(() => { 
            var storeState = store.getState();
            var auth = storeState.auth;
            var localAuth = this.state.localAuth;
            
            if(JSON.stringify(auth) != JSON.stringify(localAuth)){
                this.setState({
                    localAuth:auth,
                   
                })
               // Liju: This is causing problem
               // repeated calls recorded
               // this.getUserDetails()      
            } 

            if(auth.isAuthenticated){
                let profileDetails = localStorage && localStorage.profile ? JSON.parse(localStorage.profile) : {};
                this.setState({user:profileDetails})
            }
           
        })
    }

    isPostAdded(added){
        this.setState({
            isPostAdded:added
        })
    }

    componentDidMount(){
        var storeState = store.getState();
        var auth = storeState.localAuth;
        this.setState({
          isLoggedIn:auth.isAuthenticated,
          localAuth:auth
        })
    }

    closeModel=()=>{
        this.setState({ open: false });
    }
   

    openModal() {
        this.setState({ open: true }, function () {
          this.setState({ open: true });
        })
    }

    editbanner(){
        this.setState({
            editbanner: this.state.editbanner? false : true
           })
    }
    editBasicDetails(){
        this.setState({
            editBasicDetails: this.state.editBasicDetails ? false : true
          })
    }
    componentDidMount(){
        this.setState({
            isCompanySuccess:this.props.location.notification ? this.props.location.notification :false,
            isPeopleSuccess:this.props.location.notificationPeople ? this.props.location.notificationPeople : false
        })
    }

    componentWillReceiveProps(props){
        let location = window.location.href;
        let param = Helper.getQueryParameter("post");
        if(JSON.stringify(props) != JSON.stringify(this.state.localProps)){
            this.setState({
                localProps:props,
                open: param == "enabled" && props.location.openPost  ? true : false
            })
        }else{
            this.setState({
              //  open: false
            })
        }
        let profileDetails = localStorage && localStorage.profile ? JSON.parse(localStorage.profile) : {};
        this.setState({
            user:profileDetails,
        })
    }

getUserDetails(){
        var storeState = store.getState();   
        let storeUri = storeState.auth.user.uri;        
        this.setState({
            storeUser:storeState.auth && storeState.auth? storeState.auth.user.fullName :"",
            storeUri:storeUri
        },()=>{
            let profileDetails = localStorage && localStorage.profile ? JSON.parse(localStorage.profile) : {};
            if(!Helper.isEmpty(this.state.storeUri) && Helper.isEmpty(profileDetails)){
                Axios.get(
                    Helper.apiURL({
                        uri:"user/:uri/profile",
                        pathVar:{uri:storeUri}
                    })
                )
                .then( (response) => {           
                    let profileDetails  = response.data.result || null;                
                    this.setState({
                        user:profileDetails
                    })
                })
                .catch((err) => { 
                    Helper.pushTError("PROFILE_NOT_FOUND");
                });
            }else{
                this.setState({
                    user:profileDetails,
                })
            }
        })
       
}


  render() {
      
      let feeds = this.state.feeds;
      let user = this.state.user;
      let profilePicUrl = (user && !Helper.isEmpty(user.profilePic))?Helper.dataPath(user.profilePic.publicUri):Helper.dataPath(config.defaultUserImage);
      let cityName = user && !Helper.isEmpty(user.city)?user.city.name:'';
      let countryName = user &&  !Helper.isEmpty(user.country)?user.country.name:'';

      let location = cityName ? cityName: "";

      if(countryName){
        location+= cityName ? ", "+countryName : countryName ;
      }

      let companyName = user && !Helper.isEmpty(user.company) ? user.company.name: "";
      let companyUri = user && !Helper.isEmpty(user.company) ? user.company.uri : ""; 
      let titleName = user && !Helper.isEmpty(user.title) ? user.title.name : "";
      let userName = user && !Helper.isEmpty(user.displayName) ? user.displayName : this.state.storeUser;

      let isPostAdded = this.state.isPostAdded;

      let { isCompanySuccess , isPeopleSuccess} = this.state;

    return (
      <div>
          <div className="row bold-headers text-left p-b-30">
                <div className="col-md-12 col-lg-12 col-xlg-12 p-0">
                {/* card */}
                {/* <div className="card">
                    <div className="card-body">
                        <div className="col-lg-12 p-0">
                            <div className="col-lg-8 p-0">
                                <div>
                                    <img src={profilePicUrl} className="img-circle float-left" width="100" height="100"/>
                                    <div className="card-body-right float-left m-l-20 text-left">
                                        <h2 className="card-title m-b-5 font-20 l-h-18 m-t-20 font-bold">{userName}</h2>
                                        <p className="m-0 font-normal font-16">
                                            {titleName}
                                            {companyName ? (<span className="m-l-5 m-r-5">at</span>) : ("")}
                                            {!Helper.isEmpty(companyUri)?<Link to={'/company/'+companyUri} className="m-l-5">{companyName}</Link>:<span>{companyName}</span>}
                                        </p>
                                        <p className="m-0 font-normal font-14 text-grey">{location}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="clearfix"></div>
                        <div className="col-md-12 p-0">
                            <hr/>
                            <div className="col-md-3 col-sm-4 float-left p-0">
                                <h3 className="font-16 l-h-0">Following</h3>
                                <div className="text-info font-bold">{user.following}</div>
                            </div>
                            <div className="col-md-3 col-sm-4 float-left p-0">
                                <h3 className="font-16 l-h-0">Followers</h3>
                                <div className="text-info font-bold">{user.followers}</div>
                            </div>
                            <div className="col-md-4 col-sm-4 float-left p-0">
                                 <h3 className="font-16 l-h-0">Profile Views</h3>
                                 <div className="text-info font-bold">{user.profileViewCount}</div>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        <div className="col-md-12 p-0">
                            <hr/>
                            <div className="col-md-6 col-xlg-3 float-left font-14 p-0">
                                <a className="point text-default" href="javascript:void(0)" onClick={this.openModal}><i className="mdi mdi-border-color"></i>&nbsp;Create New Post</a>
                            </div>
                            <div className="col-md-6 col-xlg-3 float-left font-14 p-0">
                                <a className="point text-default" target="_blank" href="/create-opportunity/start"><i className="mdi mdi-trending-up"></i>&nbsp;Create New Opportunity</a>
                            </div>
                            <div className="col-md-6 col-xlg-3 float-left font-14 p-0">
                                <a className="point text-default" target="_blank" href="/create-event/start"><i className="mdi mdi-calendar"></i>&nbsp;Create New Event</a>
                            </div>
                            <div className="col-md-6  col-xlg-3 float-left font-14 p-0">
                                <a className="point text-default" target="_blank" href="/create-company/start"><i className="material-icons adjusted-icon">business</i>&nbsp;Create New Company</a>
                            </div>
                        </div>
                    </div>
                </div> */}

               
                <ActionCard openPost={this.openModal}/>
                {isCompanySuccess && 
                <div class="alert alert-success  alert-dismissible fade show" role="alert">
                    <h4 class="alert-heading">Thank you!  
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </h4>
                    <p>We have received your request and documents.Your submission is currently under review.</p>
                    <hr />
                    <p class="mb-0">The contact person will shortly receive an email with credentials to access and make the payment.</p>
                    </div>
                }
                {isPeopleSuccess && 
                    <div class="alert alert-success  alert-dismissible fade show" role="alert">
                    <h4 class="alert-heading">Thank you for updating your profile.
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </h4>
                    <p>Any change made to your profile will reflect on your account once our team has verified the changes.</p>
                    </div>

                }
                <AdFeedCard/>
                <Feeds isPostAdded={isPostAdded}/>
                <PostPopup open={this.state.open} postAdded={this.isPostAdded} onClose={this.closeModel}></PostPopup>
                </div>
          </div>
        
      </div>
    )
  }
}


export default withRouter(UserFeed);