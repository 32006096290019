import React, { Component } from 'react';
import * as d3 from "d3";
import Helpers from '../../shared/custom';
import Axios from 'axios';
import config from '../../shared/config';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import countryNames from '../../shared/countryNames';

const expandNode = {
  BOD: "Directors",
  INVT: "Investments",
  INVR: "Investors",
  FN: "Founders"
}

const expandLink = {
  BOD: "director",
  INVT: "invested in",
  INVR: "investor",
  FN: "founder"
} 

const strokeColor = {
  PROD:"#c79c00",
  DUM:"#ccc",
  INVT:"#fff",
  INVR:"#fff",
  COM:"#fff",
  MAN:"#fff",
  KP:"#18988b",
  GP:"#18988b",
  FN:"#18988b",
  BOD:"#18988b"
}

var component = undefined;

let showTooltip = function(d) {
 
    if( d.t == "DUM"){
      hideTooltip("forced");
      return false;
    } 
    //d3.select(this).attr("class","node-hovered");
    d3.select(this).classed("node-hovered", true);
    tooltip
      .transition()
      .duration(200) 
  
    var newPosition = toolTipPosition(d3.mouse(this));
      tooltip
        .style("opacity", 1)
        .html(tootTipContent(d))
        .style("left", newPosition.left + "px")
        .style("top", newPosition.top + "px");    
      d3.select(".ttb-explore").on("click",function(){  component.openDetails(this); });
      d3.select(".ttb-expand").on("click",function(){  component.openDetails(this); });
      d3.select(".ttb-remove").on("click",function(){ component.removeNode(d);});
  }
  
  let moveTooltip = function(d) {
  tooltip
    .style("left", (d3.mouse(this)[0]+30) + "px")
    .style("top", (d3.mouse(this)[1]+30) + "px")
  }
  
  let hideTooltip = function(d) {
  
  if(d && d!= "forced"){
    //d3.select(this).attr("class","node-normal"); 
    d3.select(this).classed("node-hovered", false);
  }
  
  if(d == "forced" || !d){      
    if(tooltip){
      tooltip
      .style('top', '-600px');
    }
  }
  
  }
  
  let toolTipPosition = function(nodePosition){
    var zp = graphConfig.zp;
    var left = (nodePosition[0]*zp.k)+zp.x+15;
    var top = (nodePosition[1]*zp.k)+zp.y+15;
    return{left:left,top:top};
  }
  
  let tootTipContent = (d) => {
    
    let image = d.page ? d.page.image : null;
    let uri = d.page ? d.page.uri : null;
    let name =  d.page ? d.page.name : (d.name ? d.name : null);
    
    if(d.page && d.page.page){
      image = d.page.page.image ? d.page.page.image : "";
      uri = d.page.page.uri;
    }
    
    if(!image){
      image = d.t == null || d.t == "COM" ? config.defaultCompanyImage : config.defaultUserImage;
    }

    let btnText = d.t == null ? "Reload" : "Explore";

    let explorebtn = uri ? '<span class="tooltip-btn ttb-explore" data-uri="'+d.page.uri+'" data-type="explore">'+btnText+'</span>' : "";
    let expandBtn = uri ? '<span class="tooltip-btn ttb-expand btn-action btn-expand" data-uri="'+d.page.uri+'" data-type="expand">'+'+ Expand'+'</span>' : "";
    let removeBtn = uri ? '<span class="tooltip-btn ttb-remove btn-action btn-remove" data-uri="'+d.page.uri+'" data-type="remove">'+'- Remove'+'</span>' : "";
    let location = "";
    let banner = "";
    let type = "company"; 

    if(d.t == "BOD" || d.t == "FN" || d.t == "PPL"){
      type = "people";
      explorebtn = "";
      expandBtn = "";
      banner = d.page.banner ? "<div class='font-10'>"+d.page.banner+'</div>' : "";
    }else if (d.t == "COM" || d.t == null){
      banner = "<div class='font-10'>"+(d.page.city ? d.page.city.name: "")+(d.page.country && d.page.city ? ", ": "") + (d.page.country ? countryNames[d.page.country.id]: "")+"</div>";
    }

    if(d.page && d.page.uri && rootNodes.indexOf(d.page.uri) > -1){
      expandBtn = "";
    }

    uri = '/'+type+"/"+uri;

     return '<div>\
                  <div>\
                    <a target="_blank" href="'+ uri +'">\
                      <img src="'+Helpers.dataPath(image)+'" width="50" class="float-left"/>\
                    </a>\
                  </div>\
                  <div class="m-l-5 float-left text-grey">\
                    <div class="font-14 text-default"><a target="_blank" href="'+ uri +'">'+d.page.name+'</a></div>\
                    '+banner+'\
                  </div>\
                  <br class="clearfix" />'+explorebtn+expandBtn+removeBtn+'\
             </div>';
  }   
  //\
  
var rootId = 1;
var rootNodes = [];
var rootUid = undefined;
var currentTicks = 1;
var lineLabels  = undefined;
var graph, g, link, node, text, tooltip = undefined;
var simulation, component = undefined;
var text;
var graphConfig = {
    showTitle:true,
    showLineLabels:true,
    nodeRadius: 10,
    nodeBg:"#407ACC",
    nodeHoverBg:"#A6C6F8",
    nodeStroke:"#CCC",
    collisionRadius:4,
    tickInterval:1,
    distance:300,
    zp:{k: 1, x: 0, y: 0},
 
}



class D3Explore extends Component {
    constructor(props){
        super(props);
        this.state = {
          graphData:{nodes:[],links:[]},
          nodeMap:{},
          linkCount:{},
          loading:false,
          showDetail:false,
          uri:undefined,
        }
        
       this.openDetails = this.openDetails.bind(this);
       this.removeNode = this.removeNode.bind(this);
       this.startGraph = this.startGraph.bind(this); 
       this.fetchDetailData = this.fetchDetailData.bind(this);
    }

    openDetails(button){
        hideTooltip("forced");
        let dataset = button && button.dataset ? button.dataset : null;

        if(dataset && dataset.uri){
          this.fetchDetailData(dataset.uri, dataset.type);
        }
    }   
    removeNode(d){
      
      let id = d.id;
      let nodes = this.state.graphData.nodes;
      let links = this.state.graphData.links;
      let newGraphData = this.toggleVisibilityOfNodesAndLinks(this.state.graphData, d , false);      
      
      this.setState({
        graphData:{
          nodes:[],
          links:[]
        }
      },()=>{
          this.startGraph();
          this.setState({
              graphData:{
                nodes:newGraphData.nodes,
                links:newGraphData.links,
                nodeMap:newGraphData.nodeMap,
                linkCount:newGraphData.linkCount,
              }
          },()=>{
            this.startGraph();
          })        
      })
      
    }

    componentDidMount() {
        component = this;
        let uri = this.props.uri;
        this.constructGraph();
        this.fetchDetailData(uri);
    }

    componentWillReceiveProps(props){
        let uri = this.state.uri;
        let newUri = props.uri;
        if(uri !== newUri){
          this.setState({
            uri:newUri
          },()=>{
            this.fetchDetailData(newUri);
          })
        }
    }
   
    fetchDetailData(uri,type){

      type = type || "explore";

      rootNodes.push(uri);
      rootId = uri;
      rootUid = undefined;

      
      let nodeMap = this.state.nodeMap;
      let linkCount = this.state.linkCount;
      let existingGraphData = this.state.graphData;
      let nodes = existingGraphData.nodes;
      let links = existingGraphData.links;

      this.setState({
        loading:true,
        uri:uri
      })

      Axios.get(
        //"http://192.168.32.133:9091/knowledge/graph/"+uri+"/details"
            Helpers.apiURL({
                uri:"knowledge/graph/:uri/details",
                pathVar:{uri:uri},
            })
          )
      .then( (response) => {
          const companyDetails  = response.data.result || {};              

          if(type == "explore"){
            nodeMap = {};
            linkCount = {};
            nodes = [];
            links = [];
            rootNodes = [uri];
          }

          if(companyDetails.nodes){
            companyDetails.nodes.forEach(node => {
              if(nodeMap[node.id] === undefined ){
                nodeMap[node.id] = true;
                node.show = true;
                nodes.push(node);
              }
            });
          }

          var newLinks = companyDetails.links;
          
          if(companyDetails.links){
            var newLinks = [];
            companyDetails.links.forEach(link => {
              link.show = true;
              if(nodeMap[link.source] && nodeMap[link.target]){
                newLinks.push(link);
                linkCount[link.source] = linkCount[link.source] ? linkCount[link.source]+1  : 1; 
                linkCount[link.target] = linkCount[link.target] ? linkCount[link.target]+1  : 1; 
              }              
            })
            links = links.concat(newLinks);
          }

          let newData = {
            nodes:nodes,
            links:links
          }

          component.setState({
            loading:true,
            graphData:{nodes:[],links:[]}
          }, () => {
              this.startGraph();
              component.setState({
                graphData:newData,
                loading:false,
                nodeMap:nodeMap,
                linkCount:linkCount,
              },()=>{             
                  this.startGraph();
              })
          })
        
      })
      .catch((err) => {
        let newValues = {loading:false};
        if(type == "explore"){
          newValues.graphData = {nodes:[],links:[]};
          newValues.nodeMap = {};
          newValues.linkCount = {};
        }
        this.setState(newValues,()=>{
              this.startGraph();
        })
        Helpers.pushError("Unable to load data for selected company.");
      });
    }

      constructGraph(){
        component = this;      
        tooltip = d3.select("#exploreGraph")
            .append("div")
            .style("opacity", 0)
            .attr("class", "tooltip")
            .html('Tooltip')
         
        let width = 1000;
        let height = 700;
        let svg = this.refs.svg;
        let fullSvg = d3.select("#exploreMainSvg");
        fullSvg.on("click",hideTooltip);
  
        simulation = d3
          .forceSimulation()
          .force("link", d3.forceLink()
              .id(d => d.id)
              .distance(function(d){ 
                if(rootUid && (d.source.id == rootUid || d.target.id == rootUid )){
                  return 300;
                }else{
                  return 100;
                }
              })
              .strength(2))
          //.force("link", d3.forceLink().id(d => d.id).strength(-1))
          .force("charge", d3.forceManyBody())
          .force("center", d3.forceCenter(width / 2, height / 2));
      
        g = d3
          .select("#exploreMainSvg").append("g")
          .attr("class", "everything graph-svg")
        
        link = g.append("g")
          .attr("class", "links")
          .selectAll("line")
  
        node = g.append("g")
          .attr("class", "nodes")
          .selectAll("circle")

         
        
        g.append("defs").append("marker")
        .attr("id", "arrow")
        .attr("viewBox", "0 -5 10 10")
        .attr("refX", 20)
        .attr("refY", 0)
        .attr("markerWidth", 8)
        .attr("markerHeight", 8)
        .attr("orient", "auto")
        .append("svg:path")
        .attr("fill", function(d) { 
            return d3.color("#FFF"); 
        })
        .attr("d", "M0,-5L10,0L0,5"); 

        // if(graphConfig.showTitle){
        //   text = g.append("g")
        //   .attr("class", "labels")
        //   .selectAll(".label")        
        // }

        text = g.append("g")
              .attr("class", "labels")
              .selectAll(".label") 
              
        lineLabels = g.append("g").attr("class", "lineLabels").selectAll(".lineLabels")

        var zoom_handler = d3.zoom()
        .on("zoom", ()=>{
           g.attr("transform", d3.event.transform);
           graphConfig.zp = d3.event.transform;
           hideTooltip("forced");
           //graphConfig.hideTooltip("forced");
        });
        zoom_handler(fullSvg); 


        d3.selectAll('button').on('click', function() {
          if (this.id === 'zoom_in') {
            transition(1.2); // increase on 0.2 each time
          }
          if (this.id === 'zoom_out') {
            transition(0.8); // deacrease on 0.2 each time
          }
          if (this.id === 'zoom_init') {
            d3.transition()
                .delay(100)
                .duration(700)
                .call(zoom_handler.scaleTo, 1); 
          }
        });

        let transition= (zoomLevel) => {
          d3.transition()
              .delay(100)
              .duration(700)
              .call(zoom_handler.scaleBy, zoomLevel);
        }
        component.startGraph()
      }
    
  
    startGraph = () =>{
        hideTooltip("forced");        
        graph = this.state.graphData; 
        if(graph.nodes && graph.nodes.length > 0){
          rootUid = graph.nodes[0].id;
        }else{
          rootUid = undefined;
        }
        
        //show only visible nodes
        graph.nodes = graph.nodes.filter(n => n.show)
        graph.links = graph.links.filter(l => l.show)

        link = link.data(graph.links);
        link.exit().remove();
        link = link.enter().append("line")
                .attr("stroke", function(d) { 
                  //return d3.color(graphConfig.nodeStroke); 
                  
                  var type = null;
                  type = d.i ? d.i.t : null;                            
                  var color = type && strokeColor[type] ? strokeColor[type] : "#ccc";
                  return d3.color(color); 

                })
                //.attr("marker-end", "url(#arrow)")
                .merge(link);


         g.append("defs").selectAll("logo-company")
                .data(graph.nodes)
                .enter()
                .append("pattern")
                .attr("class","logo-company")
                .attr("id",function(d){
                  return d.id
                })
                .attr("height","100%")
                .attr("width","100%")
                .attr("patternContentUnits","objectBoundingBox")
                .append("image")
                .attr("height","1")
                .attr("width","1")
                .attr("preserveAspectRatio","none")
                .attr("xmlns:xlink","http://www.w3.org/1999/xlink")
                .attr("xlink:href",function(d){
                  if(!Helpers.isEmpty(d.page.image)){
                    return Helpers.dataPath(d.page.image);
                  }else if(d.page && d.page.page && d.page.page.image){
                    return Helpers.dataPath(d.page.page.image);
                  }else{
                    return d.t == null || d.t == "COM" ? Helpers.dataPath(config.defaultCompanyImage) : Helpers.dataPath(config.defaultUserImage);
                  }
                });
                
        node = node.data(graph.nodes)
        node.exit().remove()
        node = node.enter()
                  .append("circle")
                  .attr("r", function(d){ 
                    if(d.page && d.page.uri && rootNodes.indexOf(d.page.uri) > -1){
                      return graphConfig.nodeRadius*1.25;
                    }        
                    if(d.t == 'DUM'){
                      return graphConfig.nodeRadius*.5;
                    }            
                    return graphConfig.nodeRadius;                    
                  })
                  .attr("class",function(d){ 
                    // if(d.t == null){
                    //       return "node-normal node-root parentNodeRoot"
                    // }else if((d.page && rootId && d.page.uri == rootId ) || d.t == "DUM"){
                    //   return "node-normal node-root"
                    // }else{
                    //    return "node-normal"
                    // }
                    if(d.t == "DUM"){
                      return "node-dummy-stroke node-dummy";
                    }

                    // if(d.t == "DUM"){
                    //   return "node-normal-stroke node-dummy-stroke";
                    // }

                    if(d.page && d.page.uri && rootNodes.indexOf(d.page.uri) > -1){
                      return "node-normal-stroke node-root-stroke";
                    }

                    return "node-normal-stroke";
                  })
                  .attr("fill", function(d){
                    return "url(#"+d.id+")";
                  })
                  .style("stroke", function(d) { 
                      return d3.color(graphConfig.nodeStroke); 
                  }).call(
                    d3
                      .drag()
                      .on("start", d => this.dragstarted(d, simulation))
                      .on("drag", d => this.dragged(d))
                      .on("end", d => this.dragended(d, simulation))
                  )
                  .on('mouseover',showTooltip).on('mouseout',hideTooltip)
                  .on("click", function (d) {
                    d3.event.stopImmediatePropagation();
                  })
                  .merge(node);

                  
        text = text.data(graph.nodes);
        text.exit().remove();
        text = text.enter()
                .append("g")
                .attr("class", "label")
                .append("text")
                .attr("x", 12)
                .attr("y", ".31em")
                .style("font-family", "sans-serif")
                .style("font-size", "0.7em")
                .attr("fill", function(d) { 
                  return d3.color("#fff"); 
                })
                .text(function (d) {   
                    let name = d.page ? d.page.name : undefined;
                    if(name && expandNode[name]){
                      return expandNode[name];
                    }
                    return name ? name : "";
                  })
                .merge(text);
        
        lineLabels = lineLabels.data(graph.links);
        lineLabels.exit().remove();
        lineLabels = lineLabels
              .enter().append("g")
              .append("text")
              .attr("x", 12)
              .attr("y", ".31em")
              .style("font-family", "sans-serif")
              .style("font-size", "0.3em")
              .attr("fill", function(d) { 
                return d3.color("#fff"); 
              })
              .text(function (d) { 

              
                let text = d.i ? d.i.t : undefined
                if(text && expandLink[text]){
                  return expandLink[text];
                }
                
                return text ? text : "";

              })
              .merge(lineLabels);

        simulation.nodes(graph.nodes).on("tick", () => this.ticked(link, node));
        simulation.force("link").links(graph.links);
        simulation.alpha(0.5).restart();
      }
  
    //  INIT ACTIONS
    
    toggleVisibilityOfNodesAndLinks = (graph, d, visibility) => {
      const parents = [d.id];
      let loopCount = 0;
      let nodeMap = this.state.nodeMap;
      let linkCount = this.state.linkCount;

      while (parents.length > 0) {
        const parent = parents.shift();
        graph.links.forEach(link => {

         if(link.show !== visibility){
            if(link.source.id === parent){
              link.show = visibility;
              
              if(!visibility){
                linkCount[link.source.id] = linkCount[link.source.id] - 1;
                linkCount[link.target.id] = linkCount[link.target.id] - 1;
              }
              //push only the target node is not investors dummy
              if(link.target.t == "DUM"){
                if(link.target.page.name != "INVR"){
                  parents.push(link.target.id);
                }
              }else{
                //check if source has only one link, then hide
                if(linkCount[link.target.id] < 1){
                  parents.push(link.target.id);
                }

              }
            }

            if(link.target.id === parent){
              link.show = visibility; 
              if(!visibility){
                linkCount[link.source.id] = linkCount[link.source.id] - 1;
                linkCount[link.target.id] = linkCount[link.target.id] - 1;
              }         
              //check if link source is dummy
              if(link.source.t == "DUM"){
                if(link.source.page.name == "INVR"){
                  //hide only if dummy node is ivestor
                  parents.push(link.source.id);

                }              
              }else{
                //check if source has only one link, then hide
                if(linkCount[link.source.id] < 1){
                  parents.push(link.source.id);
                }
              }

            }
         }
          

        });
        graph.nodes.forEach(node => {
          if (node.id === parent ) { //&& loopCount !== 0
            node.show = visibility;
            nodeMap[node.id] = visibility;
          }
        });
        loopCount += 1;
      }
      graph.nodeMap = nodeMap;
      graph.linkCount = linkCount;
      return graph;
    }
  
    dragstarted = (d, simulation) => {
      if (!d3.event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    };
  
    dragged = d => {
      d.fx = d3.event.x;
      d.fy = d3.event.y;
    };
  
    dragended = (d, simulation) => {
      if (!d3.event.active) simulation.alphaTarget(0);
      d.fx = d.x;
      d.fy = d.y;
    };
  
    ticked = (link, node) => {
          currentTicks++;
          if(currentTicks < graphConfig.tickInterval){
            return;
          }
          currentTicks = 0;
          
          node
          .attr("cx", function(d) { return d.x; })
          .attr("cy", function(d) { return d.y; });
                        
          link
              .attr("x1", function(d) { return d.source.x; })
              .attr("y1", function(d) { return d.source.y; })
              .attr("x2", function(d) { return d.target.x; })
              .attr("y2", function(d) { return d.target.y; });
        
          lineLabels
              .attr("x", function(d) {
                  return ((d.source.x + d.target.x)/2);
              })
              .attr("y", function(d) {
                  return ((d.source.y + d.target.y)/2);
              });

          text
          .attr("transform", function (d) { return "translate(" + d.x + "," + d.y + ")"; });                          
         
      };


      
  render() {
    let loading = this.state.loading;
    return (
      <div>
          {/**/}
          <LoadingOverlay>
              <Loader loading={loading}/>
              <div id="exploreGraph">
                <svg width="100%" height="80vh" ref="svg" id="exploreMainSvg" className="graph">
                </svg>
                <div className="btn-group  d3-zoom-btn" role="group" aria-label="Basic example">
                    <button id="zoom_in" type="button" className="btn btn-secondary">+</button>
                    <button id="zoom_out" type="button" className="btn btn-secondary">-</button>
                    <button id="zoom_init" type="button" className="btn btn-secondary">Reset</button>
                </div>
              </div>
          </LoadingOverlay>  
         
      </div>
    )
  }
}
export default  D3Explore ;