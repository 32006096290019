import React, { Component } from 'react';
import { withRouter , useLocation } from 'react-router-dom';
import NewPublicHome from "./NewPublicHome";
import UserHomepage from './UserHomepage';
import store from '../../store';
import NewHome from "../../components/new_home/Home";
import {  fetchUserInfo } from '../../actions/authAction';
import { connect } from 'react-redux';
import Helpers from '../../shared/custom';

import { getMetaData } from '../../actions/metaAction';
import { getSectorData } from '../../actions/businessAction';

import { LoadingOverlay, Loader } from 'react-overlay-loader';


class Home extends Component {
  
    constructor(props){
        super(props);
        this.state={
            authenticated :localStorage.getItem("auth"),
            auth:{},
            isEmailVerified:true,
            localProps:{},
            loading:true,
        }

        store.subscribe(() => { 
            var storeState = store.getState();
            let greeted = localStorage.getItem("greeted")
            this.setState({
              authenticated:storeState.auth.isAuthenticated,
              isEmailVerified:storeState.auth.user.emailConfirmed
            });
            if(!storeState.auth.user.emailConfirmed && storeState.auth.user.isAuthenticated && greeted){
              this.props.history.push("/verify-email");
            }
            if(storeState.auth.user.emailConfirmed && !storeState.auth.user.registrationCompleted && window.location.pathname !=  "/welcome"){
              window.location.href = "/register/sectors"
            }               
        }); 
        localStorage.removeItem('referal');
    }


    componentDidMount(){
      var storeState = store.getState();
      let greeted = localStorage.getItem("greeted");
      let token = Helpers.getQueryParameter('token');

      this.setState({
        loading:this.props.auth.loading,
      })
      setTimeout(()=>{
        if(storeState.auth.isAuthenticated && !storeState.auth.user.emailConfirmed && greeted){
          this.props.history.push('/verify-email');
        }else{
          // this.props.getMetaData();
          // this.props.getSectorData();
        }
      },1000)
    }
   
    componentWillReceiveProps(props){

      if(JSON.stringify(props) != JSON.stringify(this.state.localProps)){

        this.setState({
          localProps:props,
          loading:props.auth.loading,
        })

       
        let token = Helpers.getQueryParameter('token');
        let greeted = localStorage.getItem("greeted")
         var storeState = store.getState();
          this.setState({
            authenticated:storeState.auth.isAuthenticated,
            isEmailVerified:storeState.auth.user.emailConfirmed
          });
          if(storeState.auth.user.emailConfirmed && !storeState.auth.user.registrationCompleted && window.location.pathname !=  "/welcome"){
            window.location.href = "/register/sectors"
          }  

          setTimeout(()=>{
            if(!storeState.auth.user.emailConfirmed && storeState.auth.isAuthenticated && greeted){
               this.props.history.push('/verify-email');
            }
            // this.setState({
            //   loading:false,
            // })
          },1000)
          

          if(props.auth.isAuthenticated && !Helpers.isEmpty(token) && !greeted){
             // this.props.fetchUserInfo();
              window.history.pushState(null, null, '/');
          }


      }
    }

  render() {
    let authenticated = this.state.authenticated;
    let openPost = this.props.openPost;
    let isEmailVerified = this.state.isEmailVerified;
    let  { loading } = this.state; //&& this.props.auth && this.props.auth.loading ? this.props.auth.loading : false;


    return (
      <div>

        {
            authenticated == false || authenticated == null ?
            <NewHome/>
            :
            <UserHomepage openPost={openPost}/>
          }
      </div>
    )
  }
}


const mapStateToProps = (state) =>({
  auth:state.auth,
  errors:state.errors
});

export default connect(mapStateToProps,{fetchUserInfo,getSectorData,getMetaData})(withRouter(Home));
