import React, { Component } from 'react';
import config from '../../shared/config';
import Helper from '../../shared/custom';
import Axios from 'axios';
import store from '../../store';
import { withRouter, Redirect , Link} from 'react-router-dom';

class SimilarNews extends Component {
    constructor(props){
        super(props);
        this.state = {
            news : [],
            parentNewsUri:props.uri,
        }
        this.fetchNews = this.fetchNews.bind(this);
    }

    componentDidMount(){       
        this.fetchNews();
    }

    componentWillReceiveProps(props){
            if(props.uri != this.state.parentNewsUri){
                this.setState({
                    parentNewsUri:props.uri 
                },()=>{
                    this.fetchNews();
                })
            }
    }

    

    fetchNews(){
        let uri = this.state.parentNewsUri;
        Axios.get(
            Helper.apiURL({
                uri:`news/${uri}/similar`,
            })
        )
        .then( (response) => {     
            var news = response.data.result || [];
            this.setState({
                news:news
            })
        })
        .catch((err) => {
            
        });
    }

    addDefaultNewsImage(ev){
        ev.target.src = Helper.dataPath("default/news-default-image.png");
     }


  render() {
    let news = this.state.news;
    let newsLength = news.length;
    return (
        <div>
          <div className="card text-left bold-headers">
                <div className="card-header text-left light-blue-header">
                    <h5 className="m-b-0 m-t-0 text-white">Similar News</h5>
                </div>
                <div className="card-body p-t-2 font-14">
                   <div className="profiletimeline m-0 ">
                   <div>
                    {news.map((news,i)=>(
                        <div className="sl-item" key={i}>
                            <div className="sl-right border p-2 round-border">
                            <div className="row">
                                <div className="col-md-4 col-xs-12 p-r-0 newsImgContainer">
                                     <Link to={{pathname: "/news/"+news.uri , "parentUrl":this.props && this.props.parentUrl?this.props.parentUrl :"" }}>
                                       <img onError={this.addDefaultNewsImage}  src={!Helper.isEmpty(news.image) ? news.image : Helper.dataPath(config.defaultNewsIcon)} alt="user" className="img-responsive round-border" />
                                     </Link>
                               </div>
                                <div className="col-md-8 col-xs-12 font-12">
                                   <Link to={{pathname: "/news/"+news.uri , "parentUrl":this.props && this.props.parentUrl?this.props.parentUrl :"" }}>
                                        <h5 className="font-medium font-bold text-blue font-12 m-b-5 m-t-0">{Helper.truncate(news.headLine,50)}</h5> 
                                   </Link>
                                   <p className="font-12 l-h-12 m-t-5">{Helper.truncate(news.description,150)}</p>
                                   <a rel="noreferrer" href={Helper.properExternalUrl(news.url)} target="_blank" className="m-0 font-12"> <i className="material-icons font-12 v-a">language</i>{Helper.extractHostname(news.url)}</a> |
                                   <i className="material-icons font-12 v-a m-l-5">calendar_today</i><span className="m-l-5">{Helper.getDateInInputFormat(news.date)}</span>
                                </div>
                            </div>
                            </div>
                        </div>
                    ))
                    }
                    </div>
                    </div>
                </div>
            </div>
      </div>
    
    )
  }
}

export default withRouter(SimilarNews)
