import React, { Component } from 'react';
import Helper from "../../shared/custom";
import ApiHelper from "../../shared/ApiHelper";
import Axios from "axios";
import { data } from 'jquery';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import store from '../../store';

export class DocEdit extends Component {
    constructor(props){
        super(props);
        this.state ={
            isEditable:true,
            uploadedDocs:[],
            docByKey:{},
            uri:null,
            actionLoading:false,
            metaDocs:{},
            localProps:{}
        }

    }

    componentDidMount(){
        let uri = this.props.uri;
        let docList = this.props.docList;
        this.setState({uri:uri,metaDocs:docList});
        this.fetchDocs(uri)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(JSON.stringify(nextProps) != JSON.stringify(prevState.localProps)){
             prevState.metaDocs =  nextProps.docList != null ? nextProps.docList:{};
             prevState.localProps = nextProps;
            return {prevState}
        }
    }

    editDocs = ()=>{
        this.setState(prevState=>({isEditable:!prevState.isEditable}))
    }

    triggerDocUpload = (e) =>{
        this.refs.docUploader.click();
    }

    fetchDocs = (uri)=>{
        Axios.get(
            Helper.apiURL({
                uri:"api/page-documents/get-by-page-uri/"+uri,
            })
        )
        .then( (response) => {           
            let data = response.data && response.data? response.data : [];
            let docByKey = [];
            var obj={};
            if(data){
                data.forEach((item,i)=>{
                    if(item && item.docType && item.docType.shortName){
                        obj[item.docType.shortName] = item;
                        docByKey.push(obj)
                    }
                })
            }

            this.setState({
                uploadedDocs:data,
                docByKey:docByKey
            },()=>{
                this.getAllFiles()
            })
          })
        .catch((err) => {
        });
    }


    getAllFiles = ()=>{
        var files = this.state.uploadedDocs;
        if(!Helper.isEmpty()){
            files.map((item,i)=>item.uniqueId);
        }
        this.props.uploadedFile(files);
    }

    deleteDoc = (e)=>{
            e.stopPropagation();
            let id = e.currentTarget.attributes.getNamedItem('data-id').value;
            let mainId = e.currentTarget.attributes.getNamedItem('data-mainId').value;
            this.setState({actionLoading:true})
            Axios.delete(
                Helper.apiURL({ 
                    uri: `api/page-documents/delete-by-unique-id/${id}`
                }),{
                   // headers: { 'Content-Type': 'text/plain' }
                    }
            ).then(response => {
                    //document.getElementById(id).value = "";
                    let newFile = this.state.uploadedDocs;
                    let newFileFilter =  newFile.filter((el)=>{
                        return el.uniqueId  !== id;
                    });

                  
                  //  

                    let docByKey = [];
                    var obj={};
                    if(newFileFilter){
                        newFileFilter.forEach((item,i)=>{
                            if(item && item.docType && item.docType.shortName){
                                obj[item.docType.shortName] = item;
                                docByKey.push(obj)
                            }
                        })
                    }
                   
                    this.setState({
                        actionLoading:false,
                        uploadedDocs:newFileFilter,
                        docByKey:docByKey
                    });
                    this.props.uploadedFile(newFileFilter);
                    Helper.pushSuccess("File deleted successfully");
                   
                    //e.target.value = "";
                })
                .catch(err => {
                    Helper.pushError("Cannot delete at the moment!");
                    this.setState({actionLoading:false})
                });
    }

     uploadDocs = (e) =>{
        let file = e.target.files ? e.target.files[0] : null;

        let docType = e.target.getAttribute("data-id");
        this.setState({actionLoading:true})
        if (file) {
          ApiHelper.uploadFile({
            repoType: "PAGE_DOCUMENTS",
            docType:docType,
            fileType: "DOC",
            file: file,
            callback: result => {
              let newFile = this.state.uploadedDocs;
              newFile = newFile.filter((item)=> !Helper.isEmpty(item.docType) && item.docType.id !=  docType);
              newFile.push(result)
           
              this.setState({
                  files:newFile,
                  uploadedDocs:newFile
              },()=>{
                 this.props.uploadedFile(newFile);
                 let docByKey = [];
                    var obj={};
                    if(newFile){
                        newFile.forEach((item,i)=>{
                            if(item && item.docType && item.docType.shortName){
                                obj[item.docType.shortName] = item;
                                docByKey.push(obj)
                            }
                        })
                    }


                 this.setState({actionLoading:false,docByKey:docByKey,})
              });
             
            },
            errCallback:err=>{
                this.setState({actionLoading:false})
            }
          });
        }else{
            this.setState({actionLoading:false})
        }
      }

    render() {
        let { isEditable , uploadedDocs , files , actionLoading , metaDocs , docByKey} = this.state;
        docByKey = docByKey[0];
      
        return (
            <div className="col-md-12 p-0">
                <LoadingOverlay>
                    <Loader fullPage loading={actionLoading}/>
                </LoadingOverlay>
                <div className="card text-left" id="acq">
                    <div className="card-body">
                                <div className="col-lg-12 p-0 m-h-30">
                                <div className="col-lg-6 p-0 float-left">
                                    <h4>Document</h4>
                                </div>
                                <div className="col-lg-3 float-right p-0  text-right">
                                <div className="m-t-10">
                                </div>
                                
                            </div>
                            </div>

                            {isEditable ?
                                <div className="col-md-12 clearfix p-0 m-t-30">
                                    {!Helper.isEmpty(metaDocs) && 
                                        metaDocs.map((item,i)=>(
                                            <div className="docUploadFields clearfix m-t-10" key={i}>
                                                <h5>{item.name}</h5>
                                                <label className="custom-file-upload">
                                                  <input type="file" 
                                                            id={item.id} 
                                                            name="docUploader" 
                                                            value=""
                                                            data-id={item.id} 
                                                            className="docUploader" 
                                                            ref={item.shortName} 
                                                            onChange={this.uploadDocs}
                                                            accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"/> 
                                                </label>
                                               {
                                                !Helper.isEmpty(docByKey) &&  docByKey[item.shortName] &&
                                                         <div key={item.uniqueId} id={item.uniqueId}> 
                                                            <a href={Helper.viewFile(docByKey[item.shortName].uniqueId)} target="_blank">{docByKey[item.shortName].name}</a>
                                                            <a key={docByKey[item.shortName].uniqueId+"trigger"} data-id={docByKey[item.shortName].uniqueId} data-mainId={docByKey[item.shortName].id}
                                                                    onClick={this.deleteDoc}
                                                                    className="text-danger m-l-5"
                                                                    >
                                                                    <i className="fa fa-times" />
                                                                    </a>
                                                        </div>
                                                }
                                            </div>
                                        ))
                                      
                                    }
                                    {/* <a href="javascript:void(0)" className="m-t-20" onClick={this.triggerDocUpload}> + Upload all relevant documents here</a>
                                    <p>Choose a file…</p>
                                    <input type="file" id="docUploader" name="docUploader"  style={{display: "none"}} ref="docUploader" onChange={this.uploadDocs}/> */}
                                </div>
                                :
                                <div className="col-md-12 p-0 clearfix text-left">
                                    <ul className="no-list-style float-left p-0">
                                        {!Helper.isEmpty(uploadedDocs) ?
                                            uploadedDocs.map((item,i)=>(
                                                <li key={item.uniqueId} id={item.uniqueId}> <a href={Helper.viewFile(item.uniqueId)} target="_blank">{item.name}</a>
                                                           <a  key={item.uniqueId+"trigger"} data-id={item.uniqueId}
                                                                    onClick={this.deleteDoc}
                                                                    className="text-danger m-l-5"
                                                                    >
                                                                    <i className="fa fa-times" />
                                                                    </a>
                                                </li>
                                            ))
                                            :
                                            ""
                                        }
                                       
                                    </ul>
                                </div>
                             }

                    </div>
                </div>
            </div>
        )
    }
}

export default DocEdit;
