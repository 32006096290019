import React, { Component } from 'react';
import config from '../../shared/config';
import store from '../../store';
import Helper from '../../shared/custom';
import NotUser from './NotUser';
import { Link } from 'react-router-dom';

export default class People extends Component {
  
    constructor(props){
        super(props);
        this.state = {
            uri:undefined,
            people : [],
        }
       store.subscribe(() => {
            let type = this.props.type;
            var entityState = store.getState();
            entityState = entityState.entity || null;
            var companyDetails = entityState?entityState.company:null;
            var uri = companyDetails.entityUri;
           
            if(this.state.uri != uri){
                 this.setState({
                     uri:uri
                 })
                }
                if(!Helper.isEmpty(companyDetails.entityInfo)){
                    let people = companyDetails.entityInfo[type];
                    let uniquePeople = {};
                    let refinedList = []
                    if(!Helper.isEmpty(people)){
                        people.forEach((person, index) => {
                            if(person.page){                          
                                if(!uniquePeople[person.page.uniqueId]){
                                    uniquePeople[person.page.uniqueId] = person;
                                    refinedList.push(person);
                                }else{
                                    refinedList = refinedList.map(p =>
                                        p.page && person.page && p.page.uniqueId === person.page.uniqueId
                                          ? { ...p, banner: p.banner+" | "+person.banner }
                                          : p
                                      );
                                }
                            }else{
                                uniquePeople[index] = person;
                                refinedList.push(person);
                            } 
                        });
                        this.setState({
                            people:refinedList
                        })
                    }else{
                        this.setState({people:[]})
                    }
                }else{
                    this.setState({people:[]})
                }
            
           
            
        });

        this.renderPerson = this.renderPerson.bind(this);
        this.getSubtitle = this.getSubtitle.bind(this);
    }

    componentDidMount(){     
       this.setState({
           uri:this.props.uri,
           people :[]
       })
     }

    componentWillReceiveProps(nextProps){  
        let currentUri = this.state.uri;
        let newUri = nextProps.entityUri; 
        if(currentUri && currentUri != newUri){         
            this.setState({
                uri:newUri,
                people :[]
            })
        }
     }

    getSubtitle(){
        let id = this.props.id;
        if(id=="bod") return "Number of Board of Directors ";
        if(id=="team") return "Number of Team Members ";
        return "";
    }

    renderPerson(people, index){
        let page = people.page ? people.page : undefined;
        let image = config.defaultUserImage;
        let uri = undefined;

        if(page){
            image = !Helper.isEmpty(page.image) ? people.page.image : config.defaultUserImage;
            uri = page.uri ? page.uri : undefined;
        }

        image = Helper.dataPath(image);

        return(
            <div className="col-md-4 p-1">
                <div className="col-md-12 mini-round grey-border round-border p-1" key={"person-"+index}>
                            {uri ? 
                                <Link to={"/people/"+uri}>
                                    <center> 
                                        <div className="col-12 float-left d-flex justify-content-center">
                                            <img onError={this.addDefaultNewsImage} src={image} className="img-circle float-left m-w" style={{width:"45px",height:"45px"}}  />
                                        </div>
                                        <div className="d-flex flex-column justify-content-center text-left col-12 p-t-10 m-t-10">
                                            <h4 className="card-title text-center text-info m-b-0 m-t-0 font-14">{people.name}</h4>
                                            <h5 className="m-t-0 font-10 text-center text-default p-1 font-12 font-normal"><i class="fa fa-suitcase m-r-8" aria-hidden="true"></i>{people.banner}</h5>
                                        </div>
                                    </center>
                                </Link>
                            :
                            <center> 
                                <div className="col-4 float-left p-0">
                                    <img onError={this.addDefaultNewsImage} src={image} className="img-circle float-left m-w" style={{width:"45px",height:"45px"}}  />
                                </div>
                                <div className="d-flex flex-column col-12 p-t-10 m-t-10">
                                    <h4 className="text-info text-center text-blue m-b-0 m-t-0 font-14">{people.name}</h4>
                                    <h5 className="m-t-0 text-default text-center p-1 font-12 font-normal"><i class="fa fa-suitcase m-r-8" aria-hidden="true"></i>{people.banner}</h5>
                                </div>
                            </center>
                            }
                        </div>
            </div>
                )
    }
  render() {
    let people = this.state.people;
    let title = this.props.title;
    let peopleLength = people?people.length:0;
    let showContent = this.props.showContent;
    let pageViewsLeft = this.props.pageViewsLeft;

    return (
      <div>
       {peopleLength ?
        <div className="card text-left bold-headers" id={this.props.id}>
            { showContent && pageViewsLeft > 0 ?
            <div>
                <div className="card-header text-left p-0">
                    <div className="col-md-6 float-left light-blue-header-half header-div">
                        {title}
                    </div>
                    <div className="col-md-6 float-left text-right">
                      <span className="font-12">{this.getSubtitle() + peopleLength}  </span>
                    </div>
                </div>
                <div className="card-body max-card blue-border clearfix ">
                    {peopleLength ?
                        <div>
                            <div className="row">
                                {people.map((people, index) => (
                                    this.renderPerson(people, index)
                                ))}
                            </div>
                        
                            {/* <div className="m-t-10">
                                    <a href={'/'+this.props.type} className="float-right">View All Details ></a>
                            </div> */}
                        </div>
                    :
                    <div className="row">
                        <div className="col-md-12 no-data-text">
                            <center>No Data Available</center>
                        </div>
                        
                    </div>
                    }
                    
                </div>
            </div>
            :

            <NotUser title={title} showContent={this.props.showContent} pageViewsLeft={this.props.pageViewsLeft} />
            }
        </div>
        :
        ""
        }
      </div>
    )
  }
}
