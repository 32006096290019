import { GET_INDUSTRY_GROUP_DATA, GET_ERRORS } from './types';
import Axios from 'axios';
import Helper from "../shared/custom";

export const getIndustryGroupsData = () => dispatch => {
    let sectorIds = "";
    Axios.get(
        Helper.apiURL({
            uri: 'api/gics/industry-groups',
            query: { 'sector-ids': sectorIds }

        })).then((response) => {
            const data = response.data;
            let industryGroupsObj = {};
            var industryGroups = {};
            var industryGroupsArray = [];

            data.forEach((item, index) => {
                item = {
                    id: item.id,
                    name: item.name
                }
                industryGroups[item.id] = item;
                industryGroupsArray.push(item);
            });

            industryGroupsObj.industryGroups = industryGroups;
            industryGroupsObj.industryGroupsArray = industryGroupsArray;

            dispatch({
                type: GET_INDUSTRY_GROUP_DATA,
                payload: industryGroupsObj,
            })

        })
        .catch((err) => {
            dispatch({
                type: GET_ERRORS,
                payload: err
            })
        });

}