import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import store from '../../store';
import Axios from 'axios';
import config from '../../shared/config';
import { GET_ERRORS , SET_USER }  from '../../actions/types';
import Helpers from '../../shared/custom';
import { LoadingOverlay, Loader } from 'react-overlay-loader';

class VerifyEmail extends Component {

  constructor(props){
    super(props);
    this.state= {
        loading:true,
        isLoggedIn:false
    }   

    this.removeError = this.removeError.bind(this);
    this.setUser = this.setUser.bind(this);
    this.fetchUserInfo = this.fetchUserInfo.bind(this);
   
    this.unsubscribe = store.subscribe(()=>{
      var storeState = store.getState();
      
      if (storeState.auth && storeState.auth.user){
        if(storeState.auth.user.emailConfirmed){
          this.unsubscribe();
          let lastUrl = localStorage.getItem("lastUrl");
          !Helpers.isEmpty(lastUrl) ? props.history.push(lastUrl) : props.history.push("/");
          
        }
        if(this.state.emailConfirmed!=storeState.auth.user.emailConfirmed){
          this.setState({
            emailConfirmed:storeState.auth.user.emailConfirmed,
            loading : false
          })
        }
      }
    })
  }
 removeError ( dispatch) {
    dispatch({
        type: GET_ERRORS,
        payload: null
    })
 }
  setUser(userInfo) {
    return {
        type : SET_USER,
        payload : userInfo
    }
  }
  fetchUserInfo() {
    //debugger;
    var context = this;
    Axios.get(config.api("user-details"))
    .then( (response) => {
        const userData  = response.data.result; 
        localStorage.setItem("userInfo",JSON.stringify(userData));
        userData.image = userData.image || config.defaultUserImage;
        
        store.dispatch(context.setUser(userData));
        this.setState({
          emailConfirmed:userData.emailConfirmed,
          loading : false
        })


        let packageType = userData.promptSubUpgradeTo ? userData.promptSubUpgradeTo.value.toLowerCase() : null;
       
        if(userData.emailConfirmed){
            if(!userData.registrationCompleted && window.location.pathname !=  "/welcome"){
                window.location.href = "/register/sectors"
            }else if(userData.registrationCompleted && userData.resetPassword && window.location.pathname !=  "/change-password"){
                    window.location.href = "/change-password"
            }else{
                if(userData.promptUpgrade && packageType && window.location.pathname !=  "/proceed"){
                    window.location.href = "/proceed?type="+packageType
                }
            }
        }else{
          if(window.location.pathname  != "/verify-email"){
            window.location.href = "/verify-email"}
        }
    })
  }

  resendEmail =(e)=>{
      e.preventDefault();
      Axios.post(
        config.api("user/confirm/email/resend"))
        .then( (response) => {
           Helpers.pushSuccess("Email sent successfully,Please check your inbox.")
        })
        .catch((err) => {
           Helpers.pushTError("We are facing some technical issue, Please contact administrator.");
        });
  }

  componentDidMount () {
    this.fetchUserInfo();
  }
  render() {
    let {loading} = this.state;
    return (
            <div> 
              {loading &&  <LoadingOverlay>
              <Loader fullPage loading={loading}></Loader>
          </LoadingOverlay> }

            {!loading &&!this.state.emailConfirmed ? 
                <div className="row content-in m-t-80 p-t-10">
                  <div className="col-lg-12 col-xlg-12 col-md-12">
                      <div className="row">
                        <div className="col-12 subscribe">
                          <div className="card mainCard">
                              <div className="card-body">
                                      <div className="upgradeMsg gold-bg text-center font-18 p-05 verifyEmail">
                                          <h2 className="subHeader">Verify Your Email</h2>                                        
                                      </div>
                                      <p className="text-center m-t-5 font-14">You’re almost there! We sent an email to<br/>
                                        your registered email address</p>
                                      <br/>
                                      <p className="text-center m-t-5 font-14">Just click on the button in the email to complete your signup.<br/>
                                          If you don’t see it, you may need to <b style={{fontWeight:"revert"}}>check your spam</b> folder.</p>
                                      <br/>
                                      <p className="text-center m-t-5 font-14">Still can’t find the email?</p>
                                        <button className="btn btn-info m-t-5" onClick={this.resendEmail}>
                                           Resend Email
                                      </button> 
                                      <p className="text-center m-t-10 font-14">Need Help? <a href="/contact">Contact Us</a></p>
                                     
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
                :
                ""
              }
              
            </div>
    )
  }
}

export default withRouter(VerifyEmail);