import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link ,Switch ,withRouter} from "react-router-dom";
import OwlCarousel, { Options } from 'react-owl-carousel';
import config from '../../shared/config';
import classNames from 'classnames/bind';
import Axios from 'axios';
import Helper from "../../shared/custom";
import PublicOpps from "./PublicOpps";
import InfiniteScroll from 'react-infinite-scroller';
import store from '../../store';
import OppCarousal from './OppCarousal';
import { connect } from 'react-redux';

import OppCard from "./OppCard";

import { getMetaData } from '../../actions/metaAction';
import { getSectorData } from '../../actions/businessAction';


class AllOpportunities extends Component {

  constructor(){
    super();
      this.state ={
        opps:[],
        searchCriteria:[],
        interestedOpp:[],
        chosenOpp:[],
       // myEvents:[],
       
       
        isLoggedIn:false,
        qs:Helper.generatePaginationQS({"page":1, "length":config.defaultCarouselLength}),

        hasMoreItems: true,
        selectedSectors:[],
        oppBySector:[],

        scrollReady:true,
        scrollIndex:0,

        criteriaUpdate:undefined,
        business:null,
        metaList:[],
        type:"",
        localProps:[]


      }
      store.subscribe(() => { 
        var storeState = store.getState();
       
        var auth = storeState.auth;
        var localAuth = this.state.auth;
        this.setState({
          isLoggedIn:auth.isAuthenticated
        },()=>{
          
        })


        var localMetaList = this.state.metaList;
        var storeMetaList = storeState.meta.metaList; 
        
        if(!localMetaList || JSON.stringify(localMetaList) != JSON.stringify(storeMetaList)){
            this.setState({metaList:storeMetaList},function(){
           
            });
            
        }
  
        var localBusiness = this.state.business;
        var storeBusiness = storeState.business.data;
        if(!localBusiness || JSON.stringify(localBusiness) != JSON.stringify(storeBusiness)){
            this.setState({business:storeBusiness});
        }
  

      }); 
      this.updateSearch = this.updateSearch.bind(this);
      this.handleLoadMore = this.handleLoadMore.bind(this);
  }

  componentWillReceiveProps(props){

    this.setState({searchCriteria:props.searchCriteria}, function(){
     
    });
 }

 componentDidMount(){
   var storeState = store.getState();

 
    //var storeState = store.getState();

    let meta = storeState.meta;

    if(Helper.isEmpty(meta.metaList) == true){
      this.props.getMetaData();
    }else{
      this.setState({metaList:meta.metaList});
    }

    let business = storeState.business;

    if(Helper.isEmpty(business.data) == true){            
        this.props.getSectorData();
    }else{
        this.setState({business:business.data});
    }


   var auth = storeState.auth;
   var localAuth = this.state.auth;
   this.setState({
     isLoggedIn:auth.isAuthenticated
   },()=>{
     this.updateSearch()
     this.fetchSelectedSectors()

   })
 }


 openEventsSectorPage(e){
  let type = "opportunities"
  window.location.replace('/'+type+'/sector/'+e.target.name);
  var sectorId = e.target.name;
  let sectorLink = document.getElementsByClassName("sectorLink");
  let activeLink = document.getElementsByClassName("activeSector");
  let navLink = document.getElementsByClassName("nav-link active");


  if (activeLink.length > 0) {
    activeLink[0].classList.remove("activeSector")
  }
  
  if (navLink.length > 0) {
    navLink[0].classList.remove("active")
   
  }
      
  e.target.classList.add("activeSector");
}



 fetchSelectedSectors(){
  Axios.get(
      Helper.apiURL({
          uri:"user/sector",
      })
  )
  .then( (response) => {           
     
    let sector = response.data && response.data.result ? response.data.result : [];
    this.setState({
        selectedSectors:sector
    })
    })
  .catch((err) => {
  });
}

updateSearch(){
  if (this.state.isLoggedIn){
      this.fetchInterestedOpp();
      this.fetchChosenOpp();
      
   }else{
      this.setState({
        qs:Helper.generatePaginationQS({"page":1, "length":config.defaultGuestListingLength}), 
      },()=>{
        this.fetchInterestedOpp();
      })
   }
}



setInterested = (event) =>{
  
  this.postSetInterested(event.target.id);
}

postSetInterested = (uniqueId) => {
 
}

fetchInterestedOpp = () => {
  let uri = this.props.entityUri;
  if(!uri){
    this.setState({events:[]})
  }
  Axios.get(
      Helper.apiURL({
          uri:"opportunities/interested",
          query:this.state.qs
      })
  )
  .then( (response) => {           
      let opps = response.data.result.aaData || [];
      this.setState({interestedOpp:opps});
     })
  .catch((err) => {
      
  });
}

fetchChosenOpp = () => {
  let uri = this.props.entityUri;
  if(!uri){
    this.setState({events:[]})
  }

// return async dispatch => {
  Axios.get(
      Helper.apiURL({
          uri:"opportunities/my-preference",
          query:this.state.qs
      })
  )
  .then( (response) => {         
      let opps = response.data.result.aaData || [];
      this.setState({chosenOpp:opps});
    })
  .catch((err) => {
      
  });
}

handleLoadMore(page){
  let isLoggedIn = this.state.isLoggedIn;
  if(!isLoggedIn){
    return false;
  }

  if(!this.state.scrollReady){
    return false;
  }

  let sector = this.state.selectedSectors;
  let scrollIndex = this.state.scrollIndex;
  scrollIndex++;

  if(scrollIndex > sector.length){
    this.setState({
      hasMoreItems:false
    })
    return false;
  }

  this.setState({
    scrollReady:false,
    hasMoreItems:false
  })

  this.getDataBySector(scrollIndex);

}

getDataBySector(scrollIndex){
  let sector = this.state.selectedSectors;
  let sectorId = sector[scrollIndex-1].id || null;
  let sectorName = sector[scrollIndex-1].name || null;
  Axios.get(
   Helper.apiURL({
     uri:"opportunities/sector/"+sectorId+"?iDisplayStart=0&iDisplayLength=4&iSortCol_0=1&sSortDir_0=desc&countryIds=",  
   })
   )
  .then( (response) => {   
      let oppBySector = this.state.oppBySector;
      oppBySector[sectorId] = response.data && response.data.result ? response.data.result.aaData:[];
      oppBySector[sectorId].sectorName = sectorName;
      this.setState({
        oppBySector:oppBySector,
        scrollReady:true,
        hasMoreItems:true,
        scrollIndex:scrollIndex
      })      
    })
  .catch((err) => {
    this.setState({
      scrollReady:true,
      hasMoreItems:true,
      scrollIndex:scrollIndex
    })      
  });
}


renderSectorItems(){

let items = [];
let item = undefined;
let oppBySector = this.state.oppBySector;
let innerItem = null;


if(!Helper.isEmpty(oppBySector)){
  oppBySector.forEach((opps,i) => {
    item  = (
      <OppCarousal title ={opps.sectorName+" Opportunities"} data={opps} key={opps.sectorName}/>
    );
    
    items.push(item);
  });
}
return items;
}


  
  render() {
   
    let isLoggedIn = this.state.isLoggedIn;
    let items = this.renderSectorItems();


    let interestedOpp=[];
    if(!Helper.isEmpty(this.state.interestedOpp)){
      interestedOpp = this.state.interestedOpp;
    }
   
    let chosenOpp=[];
    if(!Helper.isEmpty(this.state.chosenOpp)){
      chosenOpp = this.state.chosenOpp;
    }

    var onlyInterested = this.props.onlyInterested;



    let sectorsList = null;
    if (this.state.business){
      sectorsList = this.state.business.sectorsArray;
    }



    return (
      <div>
        <div>
          {isLoggedIn?
            <div>
              {/* <OppCarousal title ="Interested Opportunities" data={interestedOpp} keyName="int" items={this.props.items}/>
              {Helper.isEmpty(onlyInterested) ? 
                <OppCarousal title ="Chosen Opportunities" data={chosenOpp} keyName="choosen"/>
              :
              ""
              }
               {Helper.isEmpty(onlyInterested) ? 
                <InfiniteScroll
                    pageStart={0}
                    loadMore={this.handleLoadMore}
                    hasMore={this.state.hasMoreItems}
                    threshold = {1}
                    loader={<div className="loader" key={0}><img src={config.cdn +'theme/images/small-loader.gif'}  /></div>}
                    useWindow={true}
                >
                    {items}
               </InfiniteScroll>
              :
              ""
              }
               */}
               <div className="card text-left b-header">
                 <div class="card-header text-left p-0">
                    <div class="col-md-8 float-left light-blue-header-half header-div">{!onlyInterested  ? "All Opportunities" : "Interested Opportunities"}</div>
                    <div class="col-md-4 float-left text-right">
                    </div>
                  </div>
                  {!onlyInterested ? 
                    <div className="card-body companies blue-border">
                          <div className="col-md-12 p-0">
                              {!Helper.isEmpty(sectorsList) ? 
                                    sectorsList.map((data,i) =>(
                                            <div className="col-md-6 float-left" key={i}>
                                                  <div class="card text-center">
                                                      <div class="card-body">
                                                          <div className="pro-img">
                                                            <img src={Helper.dataPath(data.image)}  className="img-responsive radius float-left"/> 
                                                          </div>
                                                          <div className="font-14 imgLabel">{data.name}</div>
                                                      </div>
                                                      <a class="card-footer blue-bg font-12 text-white" onClick={this.openEventsSectorPage} name={data.id}>
                                                            View Opportunities 
                                                      </a>
                                                  </div>
                                            </div>
                                    ))
                                    :
                                    ""
                              }
                        </div>
                        </div>
                        :
                    
                         <div className="card-body companies blue-border">
                           <div className="col-md-12 p-0">
                             {/* <OppCarousal title ="" data={chosenOpp} keyName="choosen"/> */}

                             {(Helper.isEmpty(interestedOpp)) || (interestedOpp.length ==  0 )?
                                  <div className="text-center">No Data Available</div>
                                  :
                                  <OwlCarousel  key={"choosen"} className="owl-theme" items={2} autoplay="false" loop={interestedOpp.length>3 ? true :false}  margin={10} autoWidth={false} nav={true} option={this.state.options} >
                                          {interestedOpp.map((data,index)=>(
                                              <OppCard data={data} keyName={index}/>
                                          ))}  
                                  </OwlCarousel>
                              }
                           </div>
                          </div>
                     

                  }
                    
                    
            </div>
            </div>
          :
          <PublicOpps data={interestedOpp}/>

          }
            
        </div>
      </div>
    )
  }
}

export default  connect(null,{ getMetaData, getSectorData})(withRouter(AllOpportunities));
