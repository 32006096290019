import React, { Component } from 'react';
import config from '../../shared/config';
import Axios from 'axios';
import MyPagination from "../ui/MyPagination"
import OppCard from "./OppCard";
import Helpers from '../../shared/custom';
import store from '../../store';
import { withRouter,Redirect } from 'react-router-dom';



class OppBySector extends Component {
  constructor(){
    super();
    this.state ={
      opps:[],
      sectors:[],
      localSector:[],
      pageTitle:'',
      localBusiness:undefined,
      searchCriteria:{},
      pageOfItems: [],
      allItems: [],
      activePage: 1,
      setPageContext:undefined,
      totalRecords: 0,
      sectorId:undefined,
      itemsCountPerPage:config.defaultPageSize,
      countryIds:[],
    }
    
    this.handlePageChange = this.handlePageChange.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
    this.setupPage = this.setupPage.bind(this);
  }

  handlePageChange(pageNumber) {
    this.setState({activePage: pageNumber}, function(){
        this.updateSearch();
    });
  }

  componentDidMount(){
    var storeState = store.getState();

    var auth = storeState.auth;
    this.setState({
      isLoggedIn:auth.isAuthenticated
    },()=>{
      if(!this.state.isLoggedIn){
        this.props.history.push("/opportunities")
      }
    })
  }

  componentWillReceiveProps(props){
   // var storeState = props; 

    var storeState = store.getState();
    let storeSector = storeState.business.data.sectors;
    this.setState({
        localSector : storeSector
    })
   
    if(storeSector !=  this.state.localSector){
      if (!Helpers.isEmpty(storeSector)){
       // let storeBusiness = storeState.business;
        let storeSectorData = storeSector;

        if(!Helpers.isEmpty(storeSectorData)){
            this.setState({
                sectors : storeSectorData
            }, function(){
                this.setupPage();
            }) 
        }
        
    }
    }



    let locationIds="";
    let filterDates="";
    if(props && props.locationIds){
      locationIds = props.locationIds.join(",");
    }
    if(props && props.filterDates){
      filterDates = props.filterDates;
    }

  
   
     let sector = this.props.match.params.sector;
     if (Helpers.isEmpty(sector))
         return "";
       this.setState({searchCriteria:Helpers.isEmpty(props.searchCriteria) ? {} :props.searchCriteria,setPageContext:props.setPageContext,"sectorId":sector}, function(){
         this.state.searchCriteria.countryIds = locationIds;
         this.state.searchCriteria.startDate = filterDates.from;
         this.state.searchCriteria.endDate = filterDates.to;
       
         this.setupPage();
         this.updateSearch();
         if (this.state.setPageContext)
            this.state.setPageContext("oppBySector");
           
       });

  }

  setupPage(){
      let sectorId = this.state.sectorId;
      if (!Helpers.isEmpty(this.state.sectors)){
        let sector = this.state.sectors[sectorId];
        if (!Helpers.isEmpty(sector)){
          this.setState({"pageTitle":sector.name});
        }
        
      }
  }


  updateSearch(){
    this.fetchOpps();
  }

  fetchOpps = () => {
    let qs = Helpers.generatePaginationQS({"page":this.state.activePage, "length":this.state.itemsCountPerPage, "extraParams":this.state.searchCriteria});
    let sectorId = this.state.sectorId;
    if(Helpers.isEmpty(sectorId)){
        this.setState({opps:[]})
        return
      }
    Axios.get(
        Helpers.apiURL({
            uri:"opportunities/sector/"+sectorId,  
            query:qs
        })
    )
    .then( (response) => {           
          let opps = response.data.result.aaData || [];
          let totalRecords = response.data.result.iTotalRecords || 0;
          this.setState({
                  opps:opps,
                  totalRecords:totalRecords
                  });
       })
    .catch((err) => {
        
    });
  }


  render() {
    return (
      <div>
            <div className="card text-left bold-headers">
              <div class="card-header text-left  p-0">
                    <div class="light-blue-header-half float-left col-md-6 header-div">All Opportunities</div>
                    <div class="float-left col-md-6 text-right">
                      <span class="font-12 verticle-single-text">{this.state.pageTitle}</span>
                    </div>
                </div>
                      
                <div className="card-body blue-border">
                      <div className="row">
                        { (!Helpers.isEmpty(this.state.opps) && (this.state.opps.length > 0))?
                              this.state.opps.map((data,index )=> (
                                <div className="col-md-6 m-t-10" key={index}>
                                  <OppCard data={data} from="sector"/>
                                </div>
                          ))
                          :
                          <div className="text-center col-12">No opportunities Found</div>
                        }
                      </div>

                  <MyPagination
                    activePage={this.state.activePage}
                    totalRecords={this.state.totalRecords}
                    onChange={this.handlePageChange}
                  />
                 </div> 
             </div>      
        
      </div>
    )
  }
}


export default withRouter(OppBySector)