import React, { Component } from 'react';
import Helpers from '../../shared/custom';

export default class CreateInvestment extends Component {

    constructor(props){
        super(props);
        this.state={
            addAdmin:false,
            editData:false,
            companyName:"wipro",
            rows:[]
        }
        this.handleClick =this.handleClick.bind(this);
        this.handleAddMore = this.handleAddMore.bind(this);
        this.deleteRow = this.deleteRow.bind(this)
    }  
 
    handleClick(){
        this.setState({
            editData: this.state.editData?false:true,
            cloneTextbox:true
        })
     }
     handleAddMore(){
        var rows = this.state.rows
        rows.push('new row')
        this.setState({rows: rows})
     }
     deleteRow(index){
         var rows = [...this.state.rows];
         rows.splice(index, 1);
         this.setState({rows});
     }

  render() {
      let rows = this.state.rows; 
      let editable = this.props.editable;
    return (
      <div>
          <div className="card text-left" id="section3">
            <div className="card-body">
                <div className="col-lg-12 p-0 float-left">
                    <div className="col-lg-6 p-0 float-left">
                        <h5>Investments</h5>
                    </div>
                    <div className="col-lg-3 p-0 float-right text-right">
                    {editable ?
                        <div>
                            {!this.state.editData ? 
                                    <a href="javascript:void(0)" onClick={this.handleClick} className="col-12 text-info">
                                    Edit
                                    </a>
                                    :
                                    <a href="javascript:void(0)" onClick={this.handleClick} className="col-12 text-danger">
                                    save
                                    </a>
                                }
                        </div>
                        :
                        ""
                    }
                    </div>
                </div>

                <div className="clearfix"></div>
                
                <div>
                <div className="result m-t-20">
                    <div className="row">
                    <div className="col-lg-12 col-md-6 m-b-20">
                            {this.state.editData ? 
                                <div>
                                    <table className="table color-table muted-table">
                                            <thead>
                                                  <tr>
                                                        <th className="text-center">Announced Date</th>
                                                        <th className="text-center">Organization Name</th>
                                                        <th className="text-center">Lead Investor</th>
                                                        <th className="text-center">Funding Round</th>
                                                        <th className="text-center">Money Raised</th>
                                                        <th className="text-center">Action</th>
                                                    </tr>
                                            </thead>
                                            <tbody>
                                              {rows.map((r,i) => (
                                                <tr key={i} >
                                                    <td>
                                                        <input type="text" className="form-control" placeholder="Please enter the name" />
                                                    </td>
                                                    <td className="text-center">
                                                        <input type="text" className="form-control" placeholder="Please enter the Title" />
                                                    </td>
                                                    <td className="text-center">
                                                        <input type="text" className="form-control" placeholder="Please enter Start Date" />
                                                    </td>
                                                    <td className="text-center">
                                                        <input type="text" className="form-control" placeholder="Please enter End Date" />
                                                    </td>
                                                    <td className="text-center">
                                                        <input type="text" className="form-control" placeholder="Please enter End Date" />
                                                    </td>
                                                    <td className="text-center">
                                                        <a href="javascript:void(0)" onClick={this.deleteRow} index={i} className="fa fa-times text-danger" id={i}></a>
                                                    </td>
                                                </tr>
                                                ))}
                                               
                                            </tbody>
                                        </table>
                                        <div className="uploadTag">
                                            <a href="javascript:void(0)" onClick={this.handleAddMore}> + Add More </a>
                                        </div>
                                </div>
                                :
                                <div>
                                    <div className="col-md-12 p-0">
                                    <div className="row ">
                                        <div className="col-md-6 float-left">
                                            <div className="col-md-12 box float-left">
                                                <div className="col-md-9 float-left">Number of Investments</div>
                                                <div className="col-md-3 float-left font-bold">2</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                            <h5>All Investments</h5>
                                            <table className="table color-table muted-table  m-t-10 font-14">
                                                <thead>
                                                    <tr>
                                                        <th>Announced Date</th>
                                                        <th>Organization Name</th>
                                                        <th>Lead Investor</th>
                                                        <th>Funding Round</th>
                                                        <th>Money Raised</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                            <tr>
                                                              <td>
                                                                    {Helpers.getDateInDisplayFormat(10/10/2019)}
                                                                </td>
                                                                <td>Test</td>
                                                                <td>Tahrik</td>
                                                                <td>
                                                                    Yes
                                                                </td>
                                                                <td>
                                                                   5.2 M
                                                                </td>
                                                               
                                                            </tr>
                                
                                                </tbody>
                                            </table>
                                        </div>
                                        
                                    </div>
                                </div>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        
      </div>
    )
  }
}
