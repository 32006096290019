import React from 'react';
import { Link } from 'react-router-dom';
import Helpers from '../../shared/custom';
import Follow from '../uiActions/Follow';


export const MemberListCard = (props) => {
    let { result, index, className } = props;
    return (
        <div>
            <div className={` card-body ${className} p-l-0 p-r-0 `} key={"ad-s-" + index}>
                <div className="row">

                    <Link to={"/" + result.type + "/" + result.uri}>
                        <img
                            src={
                                result.image
                                    ? Helpers.dataPath(result.image)
                                    : Helpers.dataPath(Helpers.getDefaultImage(result.type))
                            }
                            className="img-circle m-l-15"
                            width="48"
                            height="48"
                        />
                    </Link>

                    <div className="col-md-10 col-lg-10 m-l-10 col-sm-10 text-left p-r-0">
                        <h3 className="box-title m-b-0 text-info font-14 m-t-0 l-h-18">
                            <Link to={"/" + result.type + "/" + result.uri}>
                                {result.title}
                            </Link>

                            <div className="float-right col-md-5 col-sm-12 p-r-0">
                                <div className="like-comm btn-lil text-left float-right m-l-5">
                                    <button className="btn btn-theme-white" data-following="false">Follow</button>
                                </div>
                            </div>


                        </h3>

                        <div className="text-black font-12">
                            {result.primaryTitleDesignation && result.primaryTitleDesignation + " at "}
                            {result.primaryTitleDesignation && <Link to={"/company/" + result.primaryTitleCompanyUri} >
                                {result.primaryTitleCompanyName}
                            </Link>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
