
import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Helpers from '../../shared/custom';
import config from '../../shared/config';
import CommentShareBtn from '../feeds/CommentShareBtn';
import Comments from '../feeds/Comments';


const ListCard = ({ dataset, mode, newData, isNewsFeedComponent }) => {

    var data = dataset;

    if (data && data.partners && data.partners.length > 0) {
        data.partners = data.partners;

    }



    if (mode && mode.type == "investment") {
        
        data.name = data && data.page && data.page.name ? data.page.name  : "";
        data.type = data && data.pageType ? data.pageType : data.investment.type;
        data.uri = data.page.uri;
        data.image = data.page.image;

        data.preMoneyValuationFormated = !Helpers.isEmpty(data.preMoneyValuation)
            ? Helpers.displayAmount({ amount: data.preMoneyValuation, currency: data.investedAmountCurrency })
            : "-";
        data.amountRaisedFormated = !Helpers.isEmpty(data.amountRaised)
            ? Helpers.displayAmount({ amount: data.amountRaised, currency: data.investedAmountCurrency })
            : "Undisclosed"
        data.isLead = (data.isLead == true || data.isLead.toString().toLowerCase() == "yes") ? "Yes" : "No";
        data.equityOnlyFunding = (data.equityOnlyFunding == true || data.equityOnlyFunding.toString().toLowerCase() == "yes") ? "Yes" : "No";
    }

    if (mode && mode.type == "investor") {
      
        data.leadInvestor = (data.leadInvestor == true || data.leadInvestor.toString().toLowerCase() == "yes") ? "Yes" : "No";
        var investorTypesLabel = [];
        if (data && !Helpers.isEmpty(data.investorTypes)) {
            data.investorTypes.forEach((item, i) => {
                investorTypesLabel.push(item.name)
            })
        }
        data.investorTypesLabel = !Helpers.isEmpty(investorTypesLabel) ? investorTypesLabel.join(",") : "-"
    }

    if (mode && mode.type == "aquisitions") {
        data.amountDisplay = !Helpers.isEmpty(data.amount) ? Helpers.displayAmount({ amount: data.amount, currency: data.currency }) : "--";
        data.type = "company";
        data.uri = data && data.acquiredCompany && data.acquiredCompany.uri ? data.acquiredCompany.uri : null;
        data.name = data && data.acquiredCompany && data.acquiredCompany.name ? data.acquiredCompany.name : null;
        data.image = data && data.acquiredCompany && data.acquiredCompany.image ? data.acquiredCompany.image : null;
    }


    let image = !Helpers.isEmpty(data.image) ? Helpers.dataPath(data.image) : Helpers.dataPath(config.defaultCompanyImage);

    return (
        <div className={isNewsFeedComponent ? "col-md-12 p-l-0 p-r-0 m-t-20 m-b-15" : "col-md-6 float-left p-10"} key={data.uri}>
            <div className={"card list-card " + (isNewsFeedComponent ? " border-bottom-radius-0" : "")}>
                <div className="card-header card-header-custom">
                         <img src={image} className="list-img"/><Link className="m-l-10 font-12 font-bold" to={"/"+data.type+"/"+data.uri}> {data.name} </Link>
                    </div>
                    <ul className="list-group list-group-flush">
                        {
                            !Helpers.isEmpty(newData) ? 
                                newData.map((item,i)=>(
                                     <li className="list-group-item"><span className="font-bold">{item.label}: </span>
                                                 { !Helpers.isEmpty(data[item.field]) ? 
                                                         data[item.field] : 
                                                         '-'}
                                    </li>
                                ))
                                :
                            ""
                            // lists()
                        }
                        {/* <li className="list-group-item">Cras justo odio</li>
                        <li className="list-group-item">Dapibus ac facilisis in</li>
                        <li className="list-group-item">Vestibulum at eros</li> */}
                    </ul>
                </div>
            </div>
    )
}

export default ListCard;