import React, { Component } from 'react';
import ManageAdmins from '../ui/ManageAdmins';
import FacebookFeed from '../wigets/FacebookFeed';
import TwitterFeed from '../wigets/TwitterFeed';
export default class CompanyDetailsRight extends Component {

    constructor(props){
        super(props);
        this.state={
            addAdmin:false,
            companyDetails:null,
            lastPageState:null,
        }
       
    }  
 
    componentWillReceiveProps(nextProps){
        
        this.setState({
            companyDetails:nextProps.companyDetails,
            lastPageState:nextProps.lastPageState
        })
    }

    render() {
        let lastPageState = this.props.lastPageState;
        let companyDetails = this.props.companyDetails;    

        let status = "";
        let textColor = "danger";     
        let type = "company";
        let uri = null;
        let facebookUrl = undefined;
        let twitterUrl = undefined;

        if(lastPageState){        
            status = "In Creation"; 
        }

        if(companyDetails){
            let pageDto = companyDetails.pageDto;
            status = pageDto && pageDto.status ? pageDto.status.name : "PENDING";
            if(status == "APPROVED"){
                textColor = "green";                
            }
            uri = pageDto.uri? pageDto.uri: null;
            facebookUrl = pageDto.facebook ? pageDto.facebook : undefined;
            twitterUrl = pageDto.twitter ? pageDto.twitter : undefined;
        }

        return (
        <div>
            {twitterUrl ? <TwitterFeed url={twitterUrl} /> : ""}
            {facebookUrl ? <FacebookFeed url={facebookUrl} /> : ""}
            <div className="sticky rightItems">
                <div className="card text-left">
                    <div className="card-body">
                    <h5 className="font-bold">Listing Status</h5>
                    <h5 className={"font-bold text-"+textColor}>{status}</h5>
                    </div>
                </div>
                {/* {uri ? <ManageAdmins uri={uri} type={type} /> : "" } */}
            </div>
        </div>
        )
    }
}
