import Axios from 'axios';
import config from '../shared/config';
import { GET_RANKING_DETAILS, REMOVE_RANKING_DETAILS ,GET_ERRORS}  from './types';
import Helpers from '../shared/custom';

export const getRankingDetails = (uri) => dispatch => {

    if(!Helpers.isEmpty(uri)){
        Axios.get(
            Helpers.apiURL({
                uri:"company/"+uri+"/ranking-details",  
            })
        )
        .then( (response) => {           
            let result = response.data.result && response.data.result.company && response.data.result.company.rankingDetails && response.data.result.company.rankingDetails.parameters ? response.data.result.company.rankingDetails.parameters : [];
            if(!Helpers.isEmpty(result)){
                dispatch(setRankingDetails(result))
            }
         })
        .catch((err) => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            })
        });
    }
  
}

export const setRankingDetails = (result) =>{
    return {
        type : GET_RANKING_DETAILS,
        payload : result
    }
}


export const removeRankingDetails = () =>{
    return {
        type : REMOVE_RANKING_DETAILS,
        payload : {}
    }
}