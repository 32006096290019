import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Helpers from "../../shared/custom";
import Axios from "axios";
import Loader from "../ui/Loader";
import STR from "../../shared/strings";
import $ from "jquery";

class ViewInvestments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      investments: [],
      uri: undefined,
      total: 0,
      isAuthenticated: true,
      totalLead: 0,
      loading: false,
    };

    this.getInvestmentDetails = this.getInvestmentDetails.bind(this);
    this.calculateInvestments = this.calculateInvestments.bind(this);
    this.renderInvestment = this.renderInvestment.bind(this);
  }
  
  componentDidMount() {

    let uri = this.props.uri;
    this.setState({
      investments:[],
      uri:this.props.uri,
      total:0,
      totalLead:0
    },() => {
      this.getInvestmentDetails(uri);
    })

  }

  componentWillReceiveProps(nextProps) {   
    let uri = nextProps.uri;
    let currentUri = this.state.uri;
    if(uri){
      if(currentUri && currentUri == uri){
        return false;
      }

      this.setState({
        investments:[],
        uri:this.props.uri,
        total:0,
        totalLead:0
      },()=>{
        this.getInvestmentDetails(uri);
      })
      
    }
    
  }

  getInvestmentDetails(uri) {
    
    this.setState({
      loading: true
    });

    Axios.get(
      Helpers.apiURL({
          uri:"investments/:uri",
          pathVar:{uri:uri}
      })
    )
    .then((response) => {
        this.setState({loading:false})
        let investments = response.data.result;
        if(investments){
            this.calculateInvestments(investments);
        }
    })
    .catch((err) => {
        this.setState({loading:false})
    })

      
  }

  calculateInvestments(investments){
    
    investments = investments || [];    
    let totalLead = 0;
    investments.forEach((data, index) => {
      totalLead += data.isLead ? 1 : 0;
    });

    this.setState({
        total: investments.length,
        totalLead: totalLead,
        investments:investments
      },
      () => {
        if ($.fn.DataTable.isDataTable(this.refs.investmentTable) === false){
          $(this.refs.investmentTable).DataTable({searching: false, info: false, });
        }
      })
  }

  renderInvestment(data,index){
    let amountRaised;
 
    if(!Helpers.isEmpty(data.amountRaised)){
      amountRaised =  Helpers.displayAmount({amount:data.amountRaised, currency:data.investedAmountCurrency})
    }
    else{
      amountRaised= ""
    }
    return (
    <tr key={"view-iv-"+index}>
      <td>{Helpers.getDateInInputFormat(data.fromDate)}</td>
      <td><Link to={ (data.page ? "/company/"+ data.page.uri : "#")}>{data.page ? data.page.name : ""}</Link></td>
      <td>{data.isLead ? STR.YES : STR.NO}</td>
      <td>{data.type ? data.type.name : ""}</td>
      <td>{data.preMoneyValuation ? Helpers.displayAmount({amount:data.preMoneyValuation, currency:data.investedAmountCurrency}) : ""}</td>
      <td>{amountRaised}</td>
      <td>{data.stage ? data.stage.name : ""}</td>
      <td>{data.equityOnlyFunding ? STR.YES : STR.NO}</td>
    </tr>)

  }

  render() {

    let loading = this.state.loading;
    let uri = this.props.uri;
    let investments = this.state.investments;
    let total = this.state.total;
    let totalLead = this.state.totalLead;
    
    return (
      <div className="col-md-12 col-lg-12 col-xlg-12 p-0">
        {loading ? (
          <Loader />
        ) : (
          <div className="card text-left bold-headers" id="investments">            
              <div>                
                <div className="card-body">

                <div className="row m-b-10">
                    <div className="col-md-10 float-left">
                        <h5 className="m-t-0">Investments</h5>
                    </div>
                    <div className="col-md-2 text-right">
                        <Link to={"/financials/"+uri}>
                            <span data-type="ipo" className="text-info point">Edit</span>
                        </Link>
                        
                    </div>
                </div>

                  <div className="row ">
                    <div className="col-md-6 float-left">
                      <div className="col-md-12 box float-left">
                        <div className="col-md-8 float-left topLabel">
                          Number of Investments{" "}
                        </div>
                        <div className="col-md-4 float-left font-bold font-14 text-right">
                          {total}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 float-left">
                      <div className="col-md-12 box float-left">
                        <div className="col-md-8 float-left topLabel">
                          Number of Lead Investments
                        </div>
                        <div className="col-md-4 float-left font-bold font-14 text-right">
                          {totalLead}
                        </div>
                      </div>
                    </div>
                  </div>

                  <h6>All Investments</h6>
                  
                  {Helpers.isEmpty(investments) ? (
                    <div className="table-responsive text-center m-t-10">
                      No Data Available
                    </div>
                  ) : (
                    <div className="table-responsive">
                      <table   ref="investmentTable" className="table color-table muted-table dataTable">
                        <thead>
                          <tr>
                            <th>Announced Date</th>
                            <th>Company Name</th>
                            <th>Lead Investor</th>
                            <th>Funding Type</th>
                            <th>Pre Money Valuation</th>
                            <th>Money Invested</th>
                            <th>Funding Stage</th>
                            <th>Equity Only Funding</th>
                          </tr>
                        </thead>
                        <tbody>
                          {investments.map((data, index) => (
                            this.renderInvestment(data,index)
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>           
          </div>
        )}
      </div>
    );
  }
}
export default withRouter(ViewInvestments);
