import React,{useState,useEffect} from 'react';
import OppCard from  "./OppCard";
import MyPagination from "../../ui/MyPagination";
import Axios from 'axios';
import Helpers from "../../../shared/custom";
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { Link } from 'react-router-dom';

function Paginator(props) {

    const [opps, setOpps] = useState({});
    const [totalRecords, setTotalRecords] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const [itemsCountPerPage, setItemsCountPerPage] = useState(9);
    const [loading, setLoading] = useState(() => false);
    const [payload, setPayload] = useState(0);

    useEffect(() => {
        setPayload(props.payload)
        fetchOpps(props.payload,activePage);
    }, [props.payload,activePage])

    const handlePageChange = (pageNumber)=> {
        setActivePage(pageNumber);
    }

    const fetchOpps = (payload,pageNumber) => {
        var obj = {};
        obj.size = itemsCountPerPage;
        obj.title =  payload && payload.title ? payload.title : null;
        obj.countryIds = payload && payload.countryIds ? payload.countryIds : null;
        obj.cityIds = payload && payload.cityIds ? payload.cityIds : null;
        obj.oppTypes = payload && payload.oppTypes ? payload.oppTypes : null;
        obj.page = activePage;
        obj.size = itemsCountPerPage;
  
        if(payload && payload.mode){
            obj[payload.mode] = true
        }

        setLoading(true)
        
        Axios.get(
            Helpers.apiURL({
                uri:"api/opportunity/search",
                query:obj
            })
            )
            .then((response) => {
                let result = response.data;
                // setActivePage(result.number);
                setOpps(result.content);
                setTotalRecords(result.totalElements);
                setLoading(false)
            
            })
            .catch((response) => {
                setLoading(false)
            })
    }

    return (
        <React.Fragment>
             <LoadingOverlay>
              <Loader fullPage loading={loading} />
            </LoadingOverlay>
              <div className="row">
                    { (!Helpers.isEmpty(opps) && (opps.length > 0))?
                        opps.map((data,index )=> (
                            <div className="col-md-4 m-t-10" key={index}>
                            <OppCard data={data} from="filter"/>
                            </div>
                    ))
                    :
                    <div className="text-center col-12">No opportunities Found</div>
                    }
                </div>
               
               {totalRecords > 9 && <MyPagination
                    activePage={activePage}
                    totalRecords={totalRecords}
                    onChange={handlePageChange}
                    itemsCountPerPage={9}
                />}
                
        </React.Fragment>
    )
}

export default Paginator
