import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getPageInfo } from '../../actions/pageAction';
import store from '../../store';
import config from '../../shared/config';
import Helper from '../../shared/custom';
import Axios from 'axios';
import EditableFields from './EditableFields';
import Upload from './Upload';
import Videos from './Videos'
import InfoTextArray from "../ui/InfoTextArray";
import InfoText from "../ui/InfoText";
import InfoDate from "../ui/InfoDate";
import InfoFinancial from '../ui/InfoFinancial';
import ExternalLink from '../ui/ExternalLink';
import ReadMoreAndLess from 'react-read-more-less';
import InfoAddress from '../ui/InfoAddress';
import Interest from "../uiActions/Interest";
import SmartLink from "../uiActions/SmartLink";

import EditText from "../ui/EditText";
import InfoEmail from '../ui/InfoEmail';

class OppContent extends Component {
    constructor(props){
        super(props);
        this.state= {
            editBasicDetails:false,
            editContactDetails:false,
            editBanner:false,
            fileName: '',
            profilePic:config.cdn +'/theme/images/default-photo.png',
            photos:[
                /*config.cdn +'theme/images/users/1.jpg',
                config.cdn +'theme/images/users/2.jpg',
                config.cdn +'theme/images/users/3.jpg',*/
            ],
            videos:[
                /*"https://www.youtube.com/embed/tgbNymZ7vqY",
                "https://www.youtube.com/embed/tgbNymZ7vqY",
                "https://www.youtube.com/embed/tgbNymZ7vqY",
                "https://www.youtube.com/embed/tgbNymZ7vqY",
                "https://www.youtube.com/embed/tgbNymZ7vqY",
                "https://www.youtube.com/embed/tgbNymZ7vqY"*/
            ],

            details : {},
            uri : null,
            auth: {},
            metaList: [],            
            isEditable:false,
            originalDetails:{},
            editedDetails:{},
            fullData:{}
        }

  
        //subscribe to store for opp changes
        store.subscribe(() => { 
            var storeState = store.getState();
            var auth = storeState.auth;
            var isEditable = false;
            var pageState = storeState.page || null;           
            var opportunityPage = pageState?pageState.opportunity:null;

            console.log(opportunityPage,"opportunityPage")
            
            if(!opportunityPage){
                return;
            }

            if(opportunityPage.uri == this.state.uri){
                return;
            }
            var ownerUniqueId = opportunityPage.details && opportunityPage.details.opportunity ? opportunityPage.details.opportunity.ownerUniqueId : null;
            var thisUserUniqueId = auth.user.uniqueId?auth.user.uniqueId:null;
            if(thisUserUniqueId && thisUserUniqueId == ownerUniqueId){
                isEditable = true;     
            }
            this.setState({
                details : opportunityPage.details,
                uri: opportunityPage.uri,
                isEditable:isEditable,
                auth:auth,
                originalDetails:{},
                editedDetails:{},
                fullData:opportunityPage
            })
            
        });

    }


    componentDidMount(){        
        var storeState = store.getState();
        var auth = storeState.auth;
        var details = {};
        var uri = null;
        let fullData = {};
      

        if(storeState.page && storeState.page.opportunity){
            fullData = storeState.page.opportunity
            details = storeState.page.opportunity.details;
            uri = storeState.page.opportunity.uri;
        }    
        
        this.setState({
            metaList:storeState.meta,
            auth:storeState.auth,
            uri:uri,
            details:details,
            fullData :fullData
        });
    }

    componentWillReceiveProps(nextProps){
        
        let uri = nextProps.uri;
        let currentUri = this.state.uri;
        if(currentUri != uri){
            this.props.getPageInfo("OPPORTUNITY", uri,"page,opportunity");
            this.setState({
                uri:null,
                details:{},
                isEditable:false,
                editedInfo:{},
            });
        } 

        if(nextProps.errors){
         
        }
     }
    
  render() { 
    let fullData = this.state.fullData;
    let details = this.state.details || null;
    let page = details.page || null;
    let opportunity = details.opportunity || null;


    let images = opportunity && opportunity.images ? opportunity.images : [];
    let videos = opportunity && opportunity.videos ? opportunity.videos : [];
    let attachments = opportunity && opportunity.attachments ? opportunity.attachments : [];

    let owner = details.owner;
    let isEditable = this.state.isEditable;

    let area = opportunity && opportunity.area ?  opportunity.area : undefined;
    if(area && opportunity.areaUnit){
        area+=" "+opportunity.areaUnit.name
    }

    if(!page){
        return (<div></div>);
    }
   

    page.image = page.image || config.defaultOpportunityIcon;
    let ownerImage = opportunity && opportunity.owner && opportunity.owner.image ? opportunity.owner.image : config.defaultUserImage;
    let ownerName = opportunity && opportunity.owner && opportunity.owner.fullName ? opportunity.owner.fullName : "-";
    let email = page && page.contactEmails && page.contactEmails[0]? page.contactEmails[0] : null;
    let phoneNumber = page && page.contactNumbers && page.contactNumbers[0]? page.contactNumbers[0] : null;

    let industry = opportunity && opportunity.industries?Helper.selectOptionsToTextArrayByName(opportunity.industries):[];
    console.log(opportunity,"opportunity")
    return (
      <div>
            <div className="row bold-headers text-left p-b-30">
                  <div className="col-md-12 col-lg-12 col-xlg-12 p-0">
                   <div className="card">
                    <div className="card-body banner-small">
                        <div className="col-lg-12 p-0">
                            <div className="col-lg-12 p-0">
                                <div className="m-t-0">
                                    <img src={Helper.dataPath(page.image)} className="img-circle float-left m-w" width="100"/>
                                    <div className="m-l-20 text-left m-t-10 float-left">
                                        <h2 className="card-title m-t-5 m-b-5 font-14">{page.name}</h2>
                                    </div>
                                </div>
                               
                            </div>
                            <div className="col-lg-3 float-right p-0 verticle-h-banner text-right">
                                
                                { isEditable?
                                    <div className="m-t-10">
                                        <div> 
                                            <button className="btn btn-info waves-effect waves-light m-r-10 float-right">Edit</button>                                            
                                        </div>
                                    </div>
                                : 
                                "" 
                                }

                            </div>
                        </div>
                        <div className="clearfix">
                            <hr className="greyLine"/>
                            <a href="#contact"  className="float-right font-14"><button className="btn btn-dark m-l-10">Contact</button></a>
                            <Interest id={opportunity.id} type={"OPPORTUNITY"} interested={fullData._isInterested} mode="btn" masterClassName="float-right font-14"/>
                            <SmartLink
                                showEdit={true}
                                ownerId={1}
                                masterClassName="float-right font-14"
                                pageFullUri={"/opportunity-details/"+this.state.uri}
                            ></SmartLink>
                           
                        </div>
                    </div>
                </div>

                <div className="card" id="details">
                    <div class="card-header text-left light-blue-header"><h5 class="m-b-0 m-t-0 text-white">Basic Details</h5></div>
                    <div className="card-body">
                        

                        <div className="col-lg-12 p-0">
                            <div  className="small-cardbox float-left full-width">
                                <div className="col-lg-12 p-0 float-left">
                                    <h5>Investment Opportunity Created by</h5>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <img src={Helper.dataPath(ownerImage)} className="img-circle pull-left m-r-20 m-b-10 float-left" width="60" height="60"/>
                                            <div className="p-0 m-0">
                                                <h6 className="font-bold text-info m-b-0 p-0 m-t-10">{ownerName}</h6>
                                                {/* <p className="p-0 m-0">CEO at Fetchr</p> */}
                                            </div>
                                    </div>
                                    </div>
                                </div>
                            </div>

                            { 
                            <div className="col-lg-12 p-0 float-left">
                                <InfoText title="Location" text={page.city.name+", "+page.country.name} childClass="small-cardbox"/> 
                                <InfoText title="Primary Investment Type" text={opportunity.type.name} childClass="small-cardbox"/> 
                                <InfoText title="Secondary Investment Type" text={opportunity.subType.name} childClass="small-cardbox"/>  
                                <InfoTextArray title="Industry" textArray={industry}  additionalClassName="text-info" childClass="small-cardbox"/>
                                <InfoText title="Area" text={area} childClass="small-cardbox"/>
                                <InfoText title="Investment Amount" text={opportunity.budgetRange ? opportunity.budgetRange.name: ""} childClass="small-cardbox"/>
                                <InfoDate title="Investment Deadline" date={opportunity.date} childClass="small-cardbox"/>
                                <InfoFinancial title="Investment Amount" text={opportunity.budgetRange ? opportunity.budgetRange.name: ""} childClass="small-cardbox"/>
                                <InfoFinancial title="Turnover" value={opportunity.turnover} currency={opportunity.turnoverCurrency?opportunity.turnoverCurrency.name:""} childClass="small-cardbox"/>
                                {/* <InfoText title="Areas Active" text={opportunity.areaServed.name} /> */}
                            </div>
                            }
                            
                        </div>
                    </div>
                </div>

                <div className="card" id="description">
                    <div class="card-header text-left light-blue-header"><h5 class="m-b-0 m-t-0 text-white">Description</h5></div>
                    <div className="card-body">                        
                        {      
                            <div>
                                {/* <h4>Description</h4>                   */}
                                {/* <ReadMoreAndLess
                                    ref={this.readMore}
                                    className="read-more-content"
                                    charLimit={config.summaryLimit}
                                    readMoreText="Read more"
                                    readLessText="Read less"
                                >{opportunity.description}
                                </ReadMoreAndLess>    */}
                                {opportunity.description ? Helper.newLineToBr(opportunity.description) : ""}

                            </div>
                        }
                    </div>
                </div>

                
                <div className="card" id="contact">
                    <div class="card-header text-left light-blue-header"><h5 class="m-b-0 m-t-0 text-white">Contact Details</h5></div>
                     <div className="card-body">
                        {/* <div className="col-lg-12 p-0">
                            <div className="col-lg-6 p-0 float-left">
                                <h4>Contact Details</h4>
                            </div>
                            <div className="col-lg-3 float-right p-0  text-right">
                            </div>
                        </div> */}

                        <div className="col-lg-12 p-0 float-left">                                           
                            <div>
                                <InfoAddress address={opportunity.addressDto} title="Address" childClass="small-cardbox"/>
                                <InfoText text={phoneNumber} title="Phone Number" childClass="small-cardbox"/>
                                <InfoEmail text={email} title="Email" childClass="small-cardbox"/>
                                <ExternalLink link={page.website} title="Website" childClass="small-cardbox"/> 
                            </div>       
                        </div>
                    </div>
                </div>
                
                <div className="card" id="photos">
                     <div class="card-header text-left light-blue-header"><h5 class="m-b-0 m-t-0 text-white">Photos</h5></div>
                    <div className="card-body">
                        <Upload 
                            title = ""
                            items={images}
                            isAuthenticated = {this.state.auth.isAuthenticated}
                        />
                    </div>
                </div>

                <div className="card" id="videos">
                    <div class="card-header text-left light-blue-header"><h5 class="m-b-0 m-t-0 text-white">Videos</h5></div>
                    <div className="card-body">
                        <Videos 
                            title=""
                            items={videos} 
                            name="video" 
                            fieldType ="video"  
                            showText="Include your videos here" 
                            icon="plus"
                            isEditable={false}
                            isAuthenticated = {this.state.auth.isAuthenticated}
                        />
                    </div>
                </div>

                <div className="card" id="attachment">
                     <div class="card-header text-left light-blue-header"><h5 class="m-b-0 m-t-0 text-white">Attachments</h5></div>
                    <div className="card-body">                         
                        <Upload 
                            title = ""                   
                            items={attachments} 
                            itemType="DOC"
                            isAuthenticated = {this.state.auth.isAuthenticated}
                        />
                    </div>
                </div>
            </div>
         </div>
        
      </div>
    )
  }
}

const mapStateToProps = (state) =>({
    details:state.page
});
export default connect(mapStateToProps,{getPageInfo})(OppContent);