import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Messages from "../../shared/messages";

import Axios from "axios";
import Helper from "../../shared/custom";
import store from "../../store";
import Helpers from "../../shared/custom";

class CompanyClaim extends Component {
  constructor(props) {
    super(props);
    this.state = {
      haveResponse: "",
      requestPendingApproval: false,
      showRequestSent:true,
      isAuthenticated: false,
      entityUri: null,
      location: props.location,
      parentStatus:null,
    };
    store.subscribe(() => {
      var storeState = store.getState();
      let storeAuth = storeState.auth.isAuthenticated;
      let localAuth = this.state.authenticated;
      let entityData = storeState.entity.company.entityInfo;
      this.setState({
        entityData: entityData
      });
      if (JSON.stringify(storeAuth) != JSON.stringify(localAuth)) {
        let authenticated = storeState.auth.isAuthenticated;

        this.setState({
          isAuthenticated: authenticated
        });
      }
    });
  }

  setResponseMsg = msg => {
    this.setState(
      {
        haveResponse: msg
      },
      () => {
        
      }
    );
  };

  requestClaim = () => {
    Axios.post(
      Helpers.apiURL({
        uri: "claim/:pageType/:uri",
        pathVar: { pageType: "company", uri: this.state.entityUri }
      })
    )
      .then(response => {
        if (response.status == 200) {
          // no need for message
          //Helpers.pushTSuccess("CLAIM_REQUEST_SET");
          this.setState({ requestPendingApproval: true });
          this.setResponseMsg(Messages.CLAIM_REQUEST_SET);
   
        } else {
          Helpers.pushTError("GENERIC_ERROR");
          this.setResponseMsg(Messages.GENERIC_ERROR);
        }
        this.closeModal();
      })
      .catch(err => {
        if (err.response && err.response.data.status) {
          if (err.response.data.status == 400)
            if (err.response.data.message == '"ERR104"') {
              //Helpers.pushTSuccess("ADMIN_IS_ALREADY_ASSIGNED");
              this.setState({ requestPendingApproval: true, showRequestSent: false });
              this.setResponseMsg(Messages.ADMIN_IS_ALREADY_ASSIGNED);
          
            } else if (err.response.data.message == '"ERR136"') {
              //Helpers.pushTError("CLAIM_REQUEST_ALREADY_SET");
              this.setState({ requestPendingApproval: true, showRequestSent: false  });
              this.setResponseMsg(Messages.CLAIM_REQUEST_ALREADY_SET);
   
            } else {
              Helpers.pushTError("GENERIC_ERROR");
              this.setResponseMsg(Messages.GENERIC_ERROR);
            }
        }
      });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    let location = this.state.location.pathname;
    let entityUri = Helper.lastSegment(location);
    var storeState = store.getState();
    let storeAuth = storeState.auth.isAuthenticated;
    this.setState({ entityUri: entityUri, isAuthenticated: storeAuth ,parentStatus:this.props.parentStatus});
  }

  componentWillReceiveProps(nextProps) {
    let currentUri = this.state.entityUri || undefined;
    if (nextProps.location && nextProps.location.pathname) {
      let newUri = Helper.lastSegment(nextProps.location.pathname);
      if (newUri !== currentUri) {
        window.scrollTo(0, 0);
        this.setState({ entityUri: newUri ,parentStatus:nextProps.parentStatus});
      }
    }
  }

  render() {
    let isAuthenticated = this.state.isAuthenticated;
    let parentStatus = this.state.parentStatus;

    if(parentStatus == "PENDING"){
      
     return (<div className="card yellow-card bold-headers">
      <div className="card-body font-16">
        <div className="">
          <h3>Request is under process</h3>
          <i className="material-icons card-main-icon">history</i>
          <p className="card-main-text">
          Our team will contact you soon to verify
            your request
          </p>
        </div>
      </div>
      </div>)
    }else{
      if (this.state.requestPendingApproval) {
        return (
          <div class="">
              <div className="card green-card bold-headers" id="summary">
                <div className="card-body font-16">
                  <div className="">
                    {this.state.showRequestSent? <h3>Request sent successfully.</h3> : ""}
                    <i className="fas fa-check card-main-icon"></i>
                    <p className="card-main-text">
                    {this.state.haveResponse}
                    </p>
                  </div>
                </div>
              </div>
          </div>
        );
      } else if (this.props.parentStatus){
          return (
              <div class="">
                  <div className="card green-card bold-headers" id="summary">
                      <div className="card-body font-16">
                          <div className="">
                              <h3>The Page has already been claimed</h3>
                              <i className="fas fa-check card-main-icon"></i>
                              <p className="card-main-text">
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
          );
      }
      else {
        return (
          <div className="card blue-card bold-headers" id="summary">
            <div className="card-body font-16">
              <div className="">
                <h3>Claim This Company Page</h3>
                <i className="material-icons card-main-icon">vpn_key</i>
                <p className="card-main-text">
                  Claiming your company page allows you to keep your followers
                  updated on your latest financial data, business decisions, news
                  and company events.
                </p>
                <div className="text-center">
                  {isAuthenticated ? (
                    !Helper.isEmpty(this.state.haveResponse) ? (
                      <div>
                        <p>{this.state.haveResponse}</p>
                        <button
                          className={"btn btn-white-on-blue m-t-10"}
                          onClick={event => Helper.redirectToHome(this.props)}
                        >
                          Continue Browsing...
                        </button>
                      </div>
                    ) : (
                      <button
                        className={"btn btn-white-on-blue m-t-10"}
                        onClick={this.requestClaim}
                      >
                        Request Admin Access
                      </button>
                    )
                  ) : (
                    <button
                      className={"btn btn-white-on-blue m-t-10"}
                      onClick={Helper.triggerLogin}
                    >
                      Login to Request Admin Access
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
    }
    
}

export default withRouter(CompanyClaim);
