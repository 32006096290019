import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ListCard from '../wigets/ListCard';
import AuthorDetails from './AuthorDetails';
import CardActions from './CardActions';
import Comments from './Comments';
import CommentShareBtn from './CommentShareBtn';
import Helper from '../../shared/custom';


export const NewInvestment = ({ data }) => {
    const [focusInput, setFocusInput] = useState(false);

    const focusInputFiled = () =>{
        setFocusInput(!focusInput)
    }
    let dataset = !Helper.isEmpty(data.groupedFieldUpdates) ? data.groupedFieldUpdates : [];

    let newData = [{
        label: "Announced Date",
        field: "announcedDate"
    },
    {
        label: "Lead Investor",
        field: "isLead"
    },
    {
        label: "Funding Type",
        field: "fundingType"
    },
    {
        label: "Pre Money Valuation",
        field: "preMoneyValuationFormated"
    },
    {
        label: "Money Raised",
        field: "amountRaisedFormated"
    },
    {
        label: "Funding Stage",
        field: "fundingStage"
    }, {
        label: "Equity Only Funding",
        field: "equityOnlyFunding"
    }]

    let mode = { type: "investment" }



   
    return (
        <div>
            <div className="card">
                <div className="card-body">
                    <div className="col-lg-12 p-0">
                        <AuthorDetails data={data} />
                        <div className="col-lg-3 p-0 float-right text-right">
                            <CardActions data={data} />
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-lg-12 p-0 m-t-40 ">
                        <div className="b-b-2 p-b-5">
                            <span> <i class="fas fa-dollar-sign icon-round"></i> <Link className="font-bold" to={`/${data.entityType}/${data.uri}`}>{data.author.name}</Link> Has a new Investment</span>
                        </div>

                        {
                            dataset.map((item)=>(
                                    <ListCard
                                    dataset={item}
                                    mode={mode}
                                    newData={newData}
                                    isNewsFeedComponent={true}
                                    />

                            ))

                        }
                       
                        <div className="clearfix"></div>

                        <div className="float-right m-t-5">
                            <Link className="font-14" to={`company-funding-details/${data.uri}/?div=investment`}>View All</Link>
                        </div>
                        <div class="clearfix"></div>

                        <CommentShareBtn data={data} showInterested={false}
                         focusInputArea={focusInputFiled} 
                        // updateCounts={this.updateCounts}
                        />
                        <Comments data={data}
                          enableFocus={focusInput}
                        />

                    </div>
                </div>
            </div>
        </div>

    );
}
