import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import ManageAdmins from '../../ui/ManageAdmins';
import Similar from '../../wigets/Similar';


class OpportunityRightpanel extends Component {
    constructor(props){
        super(props);
        this.state={
            addAdmin:false,
            oppDetails:null,
            lastPageState:null
        }
       
    }  
 
    componentWillReceiveProps(nextProps){
        this.setState({
            oppDetails:nextProps.oppDetails,
            lastPageState:nextProps.lastPageState
        })
    }

    render() {

    let lastPageState = this.props.lastPageState;
    let oppDetails = this.props.oppDetails;    
    let editView = false;   
    let status = "";
    let textColor = "danger";
        
    let owner = null;
    let type = "opportunity";
    let uri = null;

    if(oppDetails || lastPageState){
        editView = true;
        status = "In Creation"; 
    }

    if(oppDetails){
        status = oppDetails.status;        
        uri = oppDetails.pageDto? oppDetails.pageDto.uri: null;
        if(status == "APPROVED"){
            textColor = "green";
            owner = oppDetails.owner;
        }        
    }

    return (          
    <div>
        {editView?
            <div>
                <div className="card text-left">
                    <div className="card-body">
                    <h5 className="font-bold">Investment Opportunity Status</h5>
                    <h5 className={"font-bold text-"+textColor}>{status}</h5>
                    </div>
                </div>
                {uri ? <ManageAdmins uri={uri} type={type} /> : "" }
                       
            </div>
    
        :
        
        <Similar items="1.5" title="Trending Opportunities" type="opportunity" uri="opportunities"/>

        }
        
    </div>
    
    )
  }
}
export default  withRouter(OpportunityRightpanel);

