import React, { Component } from "react";
import { withRouter,Link } from "react-router-dom";

import Banner from "../profile/Banner";
import Founders from "../profile/Founders";
import Funding from "../profile/Funding";
import People from "../profile/People";
import Events from "../profile/Events";
import Investors from "../profile/Investor";
import Aquisitions from "../profile/Aquisitions";

import Stockgraph from "../profile/Stockgraph";
import Investments from "../profile/Investments";
import Helper from "../../shared/custom";
import store from "../../store";
import Helpers from "../../shared/custom";
import config from '../../shared/config';
import RatingDetails from '../ui/RatingDetails';
import NotFound from '../ErrorPages/NotFound';

import { connect } from 'react-redux';
import { getPackageDetails } from '../../actions/packageAction';
import { removeRankingDetails } from '../../actions/rankingAction';

import SimilarCompanies from "../content/SimilarCompanies";
import {Helmet} from "react-helmet";
import { CompanyMeta } from "../../shared/MetaHelper";
import Axios from "axios";
import {GET_CURRENT_PLAN, REMOVE_RANKING_DETAILS} from "../../actions/types";
import FundingTable from "../profile/FundingTable";
import Rightpanel from "../content/Rightpanel";

class FundingDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showContent: false,
            entityUri: null,
            location: props.location,
            stockData: {},
            privileges:{},
            pageOwnerId:null,
            pageViewsLeft:0,
        };
        Axios.get(
            Helper.apiURL({
                uri:"user/subscription/details",
            })
        )
            .then( (response) => {
                const packageDetails  = response.data.result || {};
                store.dispatch({
                    type: GET_CURRENT_PLAN,
                    payload: packageDetails,
                })
            })
            .catch((err) => {

            });
        store.dispatch({
            type : REMOVE_RANKING_DETAILS,
            payload : {}
        });
        store.subscribe(() => {
            var storeState = store.getState();
            let storeAuth = storeState.auth.isAuthenticated;
            let localAuth = this.state.authenticated;
            let entityData = storeState.entity.company.entityInfo;
            let notFound = storeState.entity.notFound;

            this.setState({
                entityData:entityData,
                notFound:notFound
            });
            if (entityData && entityData.page && entityData.page.owner){
                if (this.state.pageOwnerId != entityData.page.owner.id) {
                    this.setState({
                        pageOwnerId: entityData.page.owner.id
                    });
                }
            }

            if (JSON.stringify(storeAuth) != JSON.stringify(localAuth)) {

                let authenticated = storeState.auth.isAuthenticated;

                let companyData;
                if (!Helper.isEmpty(entityData)) {
                    companyData = entityData.company;
                }
                let stockData;
                if (!Helper.isEmpty(companyData)) {
                    stockData = companyData.ipoData;
                }

                if (!Helper.isEmpty(stockData)) {
                    this.setState({
                        stockData: stockData
                    });
                }

                this.setState({
                    showContent: authenticated
                });
            }
        });
        this.myDivToFocus = React.createRef();
        this.investorDiv = React.createRef();
        
        this.aquisitionDiv = React.createRef();
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let location = this.state.location.pathname;
        this.props.removeRankingDetails()
        let entityUri = Helper.lastSegment(location);
        // var storeState = store.getState();
        // let storeAuth = storeState.auth.isAuthenticated;
        let storeAuth = Helper.authStatus();
        let pageViewsLeft = Helper.getPageViewsLeft();
        this.setState({ entityUri: entityUri, showContent: storeAuth, pageViewsLeft: pageViewsLeft });
       
        if(this.investorDiv.current){
            this.investorDiv.current.scrollIntoView({
                behavior: "smooth",
                // block: "nearest",
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        let currentUri = this.state.entityUri || undefined;
        if (nextProps.location && nextProps.location.pathname) {
            let newUri = Helper.lastSegment(nextProps.location.pathname);
            if (newUri !== currentUri) {
                window.scrollTo(0, 0);
                this.setState({ entityUri: newUri });
                this.props.removeRankingDetails()
            }
        }

        let packageDetails = nextProps.packageDetails;
        let privileges = packageDetails.privileges;
        let pageViewsLeft = packageDetails.pageViewsLeft ? packageDetails.pageViewsLeft : 0;

        if(!Helpers.isEmpty(privileges)){
            this.setState({
                privileges:privileges,
                pageViewsLeft:pageViewsLeft
            })
        }
    }
    scrollToRankDiv=()=>{
        if(this.myDivToFocus.current){
            this.myDivToFocus.current.scrollIntoView({
                behavior: "smooth",
                // block: "nearest",
            })
        }
    }
    scrollToaquisition=()=>{
        if(this.aquisitionDiv.current){
            this.aquisitionDiv.current.scrollIntoView({
                behavior: "smooth",
                // block: "nearest",
            })
        }
    }
    scrollToInvestor=()=>{
        if(this.investorDiv.current){
            this.investorDiv.current.scrollIntoView({
                behavior: "smooth",
                // block: "nearest",
            })
        }
    }
   

    render() {
        let privileges = this.state.privileges;
        let pageViewsLeft = this.state.pageViewsLeft;
        let entityUri = this.state.entityUri;
        let showContent = this.state.showContent;
        let stockData = this.state.stockData;
        let pageTypes = this.state.entityData && this.state.entityData.page && this.state.entityData.page.pageTypes?this.state.entityData.page.pageTypes:null;
        let companyType = this.state.entityData && this.state.entityData.company && this.state.entityData.company.companyType?this.state.entityData.company.companyType:null;
        let {pageOwnerId} = this.state;
        let notFound = false;
        let entityName = this.state.entityData && this.state.entityData.page?this.state.entityData.page.name:"";
        let description = this.state.entityData && this.state.entityData.page &&
        this.state.entityData.page.bannerDescription && this.state.entityData.page.bannerDescription.company?
            this.state.entityData.page.bannerDescription.company:"";
        let image = this.state.entityData && this.state.entityData.page?
            Helper.dataPath(this.state.entityData.page.image):"";

        return (

            <div>
                
                <CompanyMeta
                    entityUri = {entityName}
                    description = {description}
                    image = {image}
                />
                <Banner entityUri={entityUri} privileges={privileges} pageOwnerId={pageOwnerId} showContent={showContent} pageViewsLeft={pageViewsLeft} scrollToRank={this.scrollToRankDiv} mode="funding"/>
                {!notFound?
                    <div>
                        <FundingTable entityUri={entityUri} showContent={showContent} pageViewsLeft={pageViewsLeft} id="funding"/>

                        {privileges &&  privileges["AED_CI"] ?
                            <Investors entityUri={entityUri} showContent={showContent} pageViewsLeft={pageViewsLeft} id="investors" type="card" mode="funding" scrollToInvestor={this.scrollToInvestor}/>
                            :
                            ""
                        }
                        {
                            !Helpers.isStartupCompany(pageTypes)?
                                <Investments entityUri={entityUri} showContent={showContent} pageViewsLeft={pageViewsLeft} id="investments"  type="card"  mode="funding" scrollToInvestment={this.scrollToInvestment}/>
                                :
                                ""
                        }

                        <Aquisitions entityUri={entityUri} showContent={showContent} pageViewsLeft={pageViewsLeft} id="aquisition" type="card"  mode="funding" scrollToaquisition={this.scrollToaquisition}/>
                    </div>
                    : <NotFound/>
                }

            </div>

        );
    }
}


const mapStateToProps = (state) =>{
    return {
        packageDetails:state.packageDetails,
        errors:state.errors
    }
};
function mapDispatchToProps(dispatch) {
    return {
        getPackageDetails,
        removeRankingDetails,
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(FundingDetails));
