import React, { Component } from 'react';
import PopoverElm from '../ui/Popover';

export default class EditText extends Component {

  render() {
    let title = this.props.title;
    let name = this.props.name;
    let value = this.props.value ? this.props.value : undefined;
    let placeholder = this.props.placeholder;
    let onValueChange = this.props.onValueChange;
    let inputType = this.props.inputType || "text";
    let addClass = this.props.addClass ? this.props.addClass : "ediableInline";
    let addLabelClass = this.props.addLabelClass ? this.props.addLabelClass : "";
    let type = this.props.type ? this.props.type  : "text";
    let errorMsg = this.props.errorMsg ? this.props.errorMsg : "";
    let required = this.props.required ? this.props.required : false;
    let disabled = this.props.disabled ? this.props.disabled : false;
    let pophover = this.props.pophover ? this.props.pophover : false;
    let hoverText = this.props.hoverText ? this.props.hoverText : "Add multiple items separated by comma";
    return (
      <React.Fragment>
          <div className="col-lg-12 p-0 float-left">
    { title ?  <h5 className={addLabelClass}>{title} {required && <span className="text-danger font-bold">*</span>} {pophover &&  <PopoverElm target={this.props.target}>{hoverText}</PopoverElm>}</h5> : <span className="m-t-5">&nbsp;</span>}
                <div className="col-md-12 p-0">
                    <input type={inputType} className={"form-control "+addClass} name={name} value={value} placeholder={placeholder} onChange={onValueChange} disabled={disabled} />
                    {errorMsg && <small class="text-danger errorMsgCustom">{errorMsg}</small>}
                </div>
          </div>
      </React.Fragment>
    )
  }
}
