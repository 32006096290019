import React, { Component } from 'react';
import Helpers from '../../shared/custom';
import { withRouter } from 'react-router-dom';
import EducationRow from './EducationRow';

class ManageEducationHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            uri: null,
            editEducationHistory: true,
            rows: [],
            currency: "",
            date: new Date(),
            confirmDelete: false,
            total: 0,
            educationHistory: [],
            amount: ""
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleAddMore = this.handleAddMore.bind(this);


    }


    handleClick() {
        this.setState({
            editEducationHistory: this.state.editEducationHistory ? false : true,
        })

        let educationHistory = this.state.educationHistory;
        if (!Helpers.isEmpty(educationHistory)) {
            this.state.rows = educationHistory;
        }
    }


    componentDidMount() {
        let uri = this.props.uri ? this.props.uri : Helpers.lastSegment(window.location);
        this.setState({
            uri: uri
        }, () => {
        });
        //this.getCompanyDetails(uri)
    }

    componentWillReceiveProps(props) {
        let educationHistory = props.educationHistory;
        if (!Helpers.isEmpty(educationHistory)) {
            this.setState({
                educationHistory: educationHistory,
                rows: educationHistory.map(x=>{
                    return {
                        [x.id]:x
                    };
                })
            })
        }
    }

    handleAddMore() {
        var rows = this.state.rows
        rows.push({
            id: null,
            company: null,
            title: null,
            startDate: new Date(),
            endDate: new Date(),
            isCurrent: false
        })
        this.setState({ rows: rows })
    }

    


    fetchEducationChild = (savedData)=>{
        if(this.props && this.props.fetchEducation){
             this.props.fetchEducation(savedData)
        }
    }



    render() {
        let rows = this.state.rows;
        let rowData = this.state.rowData;
        let educationHistory = this.state.educationHistory;

        let total = educationHistory.length;
        let subjectsOptions = this.props.subjectsOptions;
        let degreesOptions = this.props.degreesOptions;


     

        return (
            <div>
                <div className="card text-left" id="education">
                    <div className="card-body">
                        <div className="col-lg-12 p-0 float-left">
                            <h5>Education History</h5>
                            <div className="result m-t-20">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 m-b-20">
                                        <div>
                                            <table className="table color-table muted-table aqTable">
                                                <thead>
                                                    <tr>
                                                        <th>Institution Name</th>
                                                        <th>Level of Education</th>
                                                        <th>Field of study</th>
                                                        <th>Start Date</th>
                                                        <th>End Date</th>
                                                        {this.state.editEducationHistory ?
                                                            <th className="text-center">Action</th>
                                                            :
                                                            ""
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        !Helpers.isEmpty(rows) ?
                                                            rows.map((row, i) => (
                                                                <EducationRow key={i} index={i} row={row} degreesOptions={degreesOptions} subjectsOptions={subjectsOptions} editEducationHistory={this.state.editEducationHistory} fetchEducationChild={this.fetchEducationChild}></EducationRow>
                                                            ))
                                                        :
                                                        <tr><td colspan="6" className="text-center">No Data Found</td></tr>
                                                    }
                                                </tbody>
                                            </table>
                                            {
                                                this.state.editEducationHistory ?
                                                    <div className="uploadTag">
                                                        <a href="javascript:void(0)" onClick={this.handleAddMore} className="text-blue"> + Add More </a>
                                                    </div>
                                                    :
                                                    ""
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}


export default withRouter(ManageEducationHistory)
