import React, {Component} from "react";
import DatePicker from "react-datepicker";
import CompanySearch from "../wigets/CompanySearch";
import Helpers from "../../shared/custom";
import Select from "react-select";
import Axios from "axios";
import STR from "../../shared/strings";
import ObjectsSearch from '../wigets/ObjectsSearch';
import CommonSearch from "../wigets/CommonSearch";


export default class EducationRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editableRow: false,
            id: null,
            institutionName: null,
            degree: [],
            subject: [],
            startDate: new Date(),
            endDate: new Date(),
            unmount: false,
            loading: false,
            savedData:[]
        };

        this.handleSave = this.handleSave.bind(this);
        this.handleFieldValueChange = this.handleFieldValueChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);

        this.validateForm = this.validateForm.bind(this);
        this.setupRow = this.setupRow.bind(this);
        this.handleEditableNotEditable = this.handleEditableNotEditable.bind(this);
        this.unmount = this.unmount.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);

        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);

        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        if(this.props.row){
            this.setupRow(this.props.row[Object.keys(this.props.row)[0]]);
        }
    }

    setupRow(row) {
        if(row) {
            this.setState({
                id: row.id,
                institutionName: row.institutionName,
                degree: Helpers.makeSelectOption(row.degree),
                subject: Helpers.makeSelectOption(row.subject),
                startDate: row.startDate
                    ? Helpers.getDateInInputFormat(row.startDate, true)
                    : undefined,
                endDate: row.endDate
                    ? Helpers.getDateInInputFormat(row.endDate, true)
                    : undefined
            });
        }
        if (Helpers.isEmpty(row)) {
            this.setState({
                editableRow: true
            });
        }
    }

    unmount() {
        this.setState({
            unmount: true
        });
    }

    handleEditableNotEditable() {
        this.setState(
            {
                editableRow: this.state.editableRow ? false : true
            },
            function () {
                if (this.state && Helpers.isEmpty(this.state.id) && this.state.editableRow == false)
                    this.unmount();
            }
        );
    }

    handleCompanyChange(event) {
        this.setState({institutionName: event[0]});
    }

    handleFieldValueChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        if (name) {
            this.setState({[name]: value});
        }
    }

    handleStartDateChange(date) {
        this.setState({startDate: date});
    }

    handleEndDateChange(date) {
        this.setState({endDate: date});
    }

    handleSelectChange(option, e) {
        let name = e.name;
        this.setState({[name]: option});
    }

    handleSubjectChange = (event) => {
        this.setState({ subject: Helpers.makeSelectOption(event[0])}, () => {
        });
    }
    

    validateForm() {
        let isValid = true;
        let message = [];

        if (!Helpers.isValidDate(this.state.startDate)) {
            isValid = false;
            message.push("START_DATE_REQUIRED");
        }

        if (Helpers.isEmpty(this.state.degree)) {
            isValid = false;
            message.push("DEGREE_REQUIRED");
        }

        if (Helpers.isEmpty(this.state.subject)) {
            isValid = false;
            message.push("SUBJECT_REQUIRED");
        }

        if (Helpers.isEmpty(this.state.institutionName)) {
            isValid = false;
            message.push("INSTITUTION_NAME_REQUIRED");
        }

        if (!Helpers.isValidDate(this.state.endDate)) {
            isValid = false;
            message.push("END_DATE_REQUIRED");
        }

        if (message.length > 0) {
            Helpers.pushTError(message);
            return isValid;
        }

        return isValid;
    }

    handleDelete(target) {
        if (target && target.dataset.objectid) {
            let id = target.dataset.objectid;

            Axios.delete(
                Helpers.apiURL({
                    uri: "user/profile/education/" + id
                })
            )
                .then(response => {
                    let savedData = this.state.savedData;
                    savedData = savedData.filter((x)=>x.id != id);
                   // this.props.fetchEducationChild(savedData)
                    
                    if (response.status == 200) {
                        this.unmount();
                    }
                    Helpers.pushSuccess("Degree is deleted successfully");
                })
                .catch(err => {
                    Helpers.pushError("Degree cannot be deleted");
                });
        } else {
            this.unmount();
        }
    }

    handleSave() {
        if (true !== this.validateForm()) return;

        let toSaveRow = {};

        toSaveRow.degree = Helpers.valueLabelToIdName(this.state.degree); //Helpers.makeSelectOption(this.state.degree);
        toSaveRow.subject = Helpers.valueLabelToIdName(this.state.subject); //Helpers.makeSelectOption(this.state.degree);
        toSaveRow.institutionName = this.state.institutionName;
        toSaveRow.id = this.state.id;
        toSaveRow.startDate = Helpers.getDateInInputFormat(this.state.startDate);
        toSaveRow.endDate = Helpers.getDateInInputFormat(this.state.endDate);
        let payload = [];
        payload.push(toSaveRow);

        this.saveData(payload);
    }

    saveData(payload) {
        let payloadData = payload;

        Axios.post(
            Helpers.apiURL({
                uri: "user/profile/education"
            }),
            payloadData
        )
            .then(response => {
                Helpers.pushSuccess("Degree added successfully");
                let result = response.data.result;
                let addedUpdatedObject = result[0];
                this.setupRow(addedUpdatedObject);

                let savedData = this.state.savedData;
                savedData.push(addedUpdatedObject);

               
                //this.props.fetchEducationChild(savedData)


                this.setState(
                    {
                        editableRow: false,
                        id: addedUpdatedObject.id
                    },
                    function () {
                    }
                );
            })
            .catch(error => {
                Helpers.pushTError("Cannot Save the Degree");
            });
    }

    handleClick() {
        //this.handleCompanyChange();
        this.setState({institutionName: this.state.institutionName});
        var event = new Event("input", {bubbles: true});
        this.myinput.dispatchEvent(event);
    }

    render() {
        if (this.state.unmount) {
            return <React.Fragment></React.Fragment>;
        }

       

        let editableRow = this.state.editableRow;
        let institutionName = this.state.institutionName;
        let degree = !Helpers.isEmpty(this.state.degree) ? Helpers.makeSelectOption(this.state.degree) : [];
        let subject = this.state.subject;
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        let objId = this.state.id;
        let editEducationHistory = this.props.editEducationHistory;
        let degreesOptions = this.props.degreesOptions;
        let subjectsOptions = this.props.subjectsOptions ? this.props.subjectsOptions.splice(0, 100) : [];
        let deleteConfig = {
            proceed: this.handleDelete
        };


       // console.log(degree,"degreedegree")

     

        return (
            <tr className={"rbtWidthFix"}>
                {editableRow == true ? (
                    <td>
                        <input
                            type="text"
                            name="institutionName"
                            value={institutionName}
                            className="form-control t-form-control"
                            placeholder="Institution name"
                            onChange={this.handleFieldValueChange}
                        />
                    </td>
                ) : (
                    <td>{institutionName ? institutionName : ""}</td>
                )}

                {editableRow == true ? (
                    <td>
                        <Select
                            name="degree"
                            value={degree}
                            className="inline-minimal-width font-14 col-md-12 p-0 m-0"
                            options={degreesOptions}
                            onChange={this.handleSelectChange}
                        />
                    </td>
                ) : (
                    <td>{degree && degree.name ? degree.name : ""}</td>
                )}
                {editableRow == true ? (
                    <td>
                        {/* <Select
                            name="subject"
                            value={subject}
                            isSearchable={true}
                            className="inline-minimal-width font-14 col-md-12 p-0 m-0"
                            options={subjectsOptions}
                            onChange={this.handleSelectChange}
                            filterOption={null}
                        /> */}

                        <CommonSearch
                            title=""
                            allowNew={true}
                            onChange={event => this.handleSubjectChange(event)}
                            name="subject"
                            endPoint="meta/search/by-name-and-key"
                            extraQuery = {[{key:"key",value:"SUB"}]}
                            defaultValue={this.state.subject ? this.state.subject.name : ""}
                            defaultSelected={!Helpers.isEmpty(this.state.subject) ? [this.state.subject] : []}
                        />

                           {/* <ObjectsSearch
                                title=""
                                inputClassName="ediableInline "
                                headingClass=""
                                onChange={event => this.handleSubjectChange(event)}
                                name="subject"
                                multiple={false}
                                allowNew={true}
                                extraQuery = {[{key:"key",value:"SUB"}]}
                                endPoint="meta/search/by-name-and-key"
                                defaultValue={this.state.subject?this.state.subject:[]}
                                defaultSelected={!Helpers.isEmpty(this.state.subject) ? [this.state.subject] : []}
                            /> */}
                    </td>
                ) : (
                    <td>{subject && subject.name ? subject.name : ""}</td>
                )}
                {editableRow == true ? (
                    <td className="text-center">
                        <DatePicker
                            onChange={this.handleStartDateChange.bind(this)}
                            selected={startDate ? new Date(startDate) : this.state.startDate}
                            className="form-control date"
                            todayButton={"Today"}
                            placeholderText="Please enter Start Date"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                        />
                    </td>
                ) : (
                    <td>{startDate ? Helpers.getDateInInputFormat(startDate) : ""}</td>
                )}
                {editableRow == true ? (
                    <td className="text-center">
                        {false ? (
                            ""
                        ) : (
                            <DatePicker
                                onChange={this.handleEndDateChange.bind(this)}
                                selected={endDate ? new Date(endDate) : this.state.endDate}
                                className="form-control date"
                                todayButton={"Today"}
                                placeholderText="Please enter End Date"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                        )}
                    </td>
                ) : (
                    <td>
                        {false
                            ? "Current"
                            : endDate
                                ? Helpers.getDateInInputFormat(endDate)
                                : ""}
                    </td>
                )}
                {editEducationHistory ? (
                    <td className="text-center">
                        {editableRow == true ? (
                            <div className="horizontalChilds">
                                <a
                                    href="javascript:void(0)"
                                    className="btn btn-xs btn-info text-white m-t-5 m-l-5"
                                    onClick={event => this.handleSave(objId, event)}
                                >
                                    {" "}
                                    Save{" "}
                                </a>
                                <a
                                    onClick={this.handleEditableNotEditable}
                                    className="btn btn-xs btn-danger text-white m-t-5 m-l-5"
                                >
                                    {" "}
                                    Cancel{" "}
                                </a>
                            </div>
                        ) : (
                            <React.Fragment>
                                <a
                                    href="javascript:void(0)"
                                    className="btn btn-xs btn-danger text-white m-t-5"
                                    data-objectid={objId}
                                    onClick={event =>
                                        Helpers.actionConfirm(event, deleteConfig)
                                    }
                                >
                                    Delete
                                </a>
                                <a
                                    href="javascript:void(0)"
                                    index={objId}
                                    className="btn btn-xs btn-info text-white m-t-5 m-l-5"
                                    id={objId}
                                    onClick={this.handleEditableNotEditable}
                                >
                                    {" "}
                                    Edit{" "}
                                </a>
                            </React.Fragment>
                        )
                        }
                    </td>
                ) : (
                    ""
                )}
            </tr>
        );
    }
}
