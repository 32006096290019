import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Helpers from '../../shared/custom';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import Axios from 'axios';
import Messages from '../../shared/messages';


let inputs =
{
  name: {
    val: '',
    validation: [
      { req: true, msg: Messages.PEOPLE_NAME_REQUIRED },
    ]
  },
  linkedinUrl: {
    val: '',
  },
  twitterUrl: {
    val:'',
  },
  facebookUrl: {
    val: '',
  },
  instagramUrl: {
    val: '',
  }
}


export const CreatePeople = ({ showModal, toggleModal }) => {
  const [errors, setErrors] = useState(() => { });
  const [formData, setFormData] = useState(() => Object.assign({}, inputs))
  const [loading, setLoading] = useState(() => false)


  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => {
      prevState[name].val = value;
      return { ...prevState }
    })
    handleValidations()
  }


  const handleValidations = () => {
    let $errors = errors || {};
    let valid = true;
    for (const input in formData) {
      if (formData[input]["validation"]) {
        let validation = formData[input]["validation"];
        let value = formData[input].val
        let name = input;
        for (const key in validation) {
          let _valid = validation[key];
          if ($errors[name])
            delete $errors[name];
          if (_valid.req && !value) {
            valid = false;
            $errors[name] = _valid.msg;
            break;
          } 
        }
      }
    }
    setErrors(prevState => { return { ...prevState, ...$errors } });
    return valid;
  }

  useEffect(() => {
     let data = formData;
     Object.keys(data).forEach((key,index) => {
       data[key].val = "";
     })
     setFormData(prevState => data);
  },[])


  const handleSubmit =  (e) => {
    e.preventDefault();
    if (handleValidations()) {
      let payload = {};
      if(!formData.name.val){
        Helpers.pushError(Messages.INVALID_PEOPLE_NAME);
        return false;
      }
      payload.name = formData.name.val;
      payload.linkedinUrl = formData.linkedinUrl.val;
      payload.twitterUrl = formData.twitterUrl.val;
      payload.facebookUrl = formData.facebookUrl.val;
      payload.instagramUrl = formData.instagramUrl.val;
      
      Axios.post(
        Helpers.apiURL({
          uri:"people/add-requests",
      }),
       payload
    ).then((response) => {
      Helpers.pushSuccess(Messages.PEOPLE_ADD_SUCCESS);
      toggleModal();
    })
      .catch((err) => {
        Helpers.pushError(Messages.GENERIC_ERROR);
        toggleModal();
      })
    }
  }

  return (
    <React.Fragment>
      <div>
      {
        <LoadingOverlay>
          <Loader fullPage loading={true} />
        </LoadingOverlay>
      }
        <Modal
          className="show "
          dialogClassName={"show ie-custom-modal"}
          show={showModal}
          centered="true"
          onHide={toggleModal}
        >
          <Modal.Header className="light-blue-header">
            <Modal.Title className="text-white" id="contained-modal-title-vcenter">Add People</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12 form-group">
                  <label className="control-label">Name<span style={{ color: 'red' }}>*</span></label>
                  <input onChange={handleInput} type="text" name="name" id="name"
                    className={`form-control  ${errors && errors.name ? 'is-invalid' : ''}  `} 
                    placeholder="Name" />
                  {errors && errors.name && <span className="invalid-feedback">{errors.name}</span>}
                </div>

                <div className="col-md-12 form-group">
                  <label className="control-label">LinkedIn</label>
                  <input   onChange={handleInput} type="text" name="linkedinUrl" id="linkedinUrl" className="form-control" placeholder="LinkedIn" />
                </div>

                <div className="col-md-12 form-group">
                  <label className="control-label">Twitter</label>
                  <input onChange={handleInput} type="text" name="twitterUrl" id="twitterUrl" className="form-control" placeholder="Twitter" />
                </div>

                <div className="col-md-12 form-group">
                  <label className="control-label">Facebook</label>
                  <input  onChange={handleInput} type="text" name="facebookUrl" id="facebookUrl" className="form-control" placeholder="Facebook" />
                </div>

                <div className="col-md-12 form-group">
                  <label className="control-label">Instagram</label>
                  <input  onChange={handleInput} type="text" name="instagramUrl" id="instagramUrl" className="form-control" placeholder="Instagram" />
                </div>

                <div className="col-md-12 ">
                  <button type="submit" className="btn btn-theme float-right text-white btn-normal font-16">Save</button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </React.Fragment>
  );
}
