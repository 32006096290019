import React, { Component } from 'react';
import config from '../../shared/config';
import { companiesBySectors } from '../../actions/searchAction';
import { connect } from 'react-redux';
import store from '../../store';
import Helper from '../../shared/custom';
import Axios from 'axios';

class Companies extends Component {

  constructor(props){
    super(props);
    this.state ={
        sectorsIds:[],
        selectedCompanies:[],
        companies:[],
        companiesAdded:false,
        localCompanies:[],
    
        types:"COMPANY",
    }
    
    store.subscribe(() => { 
      var storeState = store.getState();       
      var storeCompanies = storeState.meta.companiesAdded;
      var localCompanies = this.state.localCompanies;

      if(!Helper.isEmpty(storeState.search.searchResults)){
        if(JSON.stringify(localCompanies) != JSON.stringify(storeCompanies)){
          this.setState({
            companies: storeState.search.searchResults,
          }) 
       }
      }

      
  }); 

  this.handleCompanies =   this.handleCompanies.bind(this);
  this.onCheckCompanies = this.onCheckCompanies.bind(this);

  }

  componentDidMount(){


    let sectorIds = this.props.location.state.sectorIds;

    this.setState({
      sectorIds : sectorIds
    })

    let types = this.state.types;
    let criteria = {
       types: types,
       sectors : sectorIds.join(",")
    }
    this.props.companiesBySectors(criteria)
  }


 onCheckCompanies(e){

    const selectedCompanies = this.state.selectedCompanies;
    let index;
    if (e.target.checked) {
      selectedCompanies.push(+e.target.value)
    } else {
      index = selectedCompanies.indexOf(+e.target.value)
      selectedCompanies.splice(index, 1)
    }
    this.setState({ selectedCompanies: selectedCompanies },()=>{
    });

 }


 handleCompanies(e){
 
  e.preventDefault();
    var selectedCompanies = this.state.selectedCompanies;
    this.setState({
       selectedCompanies: selectedCompanies.length
    })


    if(selectedCompanies.length < 3){
       Helper.pushError("Please select atleast one company to proceed");
       return false;
    }
 
    let selectedData = {
       objectType : this.state.types,
       objectIds : selectedCompanies,
       following:true,
    }
    if(!Helper.isEmpty(this.state.selectedCompanies)){
     //  this.props.followCompanies(selectedData);
     Axios.post(
      config.api("user-follow"),
      selectedData)
      .then((response) => {
        this.props.history.push('/register/role');
      })
      .catch((err) => {
         Helper.pushTError(err);
      });
    }
}


  render() {

    let companiesList = this.state.companies;
    
    return (
      <div>
        <div className="grid-select">
            <div className="row p-20">
               <div className="col-lg-12 col-md-12 text-left font-bold">
                 <h1 className="col-md-9 float-left">Great! Now select some companies to follow.</h1>
                 {this.state.selectedCompanies.length < 3 ?
                     <button className="float-right m-r-20 btn waves-effect waves-light btn-theme-white btn-custom-md font-16">Select 3 or more to continue</button>
                     :
                     <button className="float-right m-r-20 btn waves-effect waves-light btn-info btn-custom-md font-16" onClick={this.handleCompanies}>Continue</button>
                }
                 
               </div>
            </div>

            <div className="row p-10" id="sectors">
           { !Helper.isEmpty(companiesList) ? 
                  companiesList.map((list, index) => (
                    <div className="col-lg-4 col-xlg-4 col-md-6 col-sm-12 checkboxHolder" key={index}>
                          <input type="checkbox"  id={'company'+list && list.page ? list.page.id : ""} name={'company'+list && list.page ? list.page.id : ""} value={list && list.page ? list.page.id : ""} className="hidden" onChange={this.onCheckCompanies}/>
                          <label className="m-b-20 col-md-12 p-0 imageHolder companyDetails font-bold" htmlFor={'company'+list && list.page ? list.page.id : ""} >
                              <div className="card">
                                    <div className="card-body">
                                      <div className="col-lg-12 text-left p-0 v-a-contents" style={{minHeight:"100px"}}>
                                        <img className="rounded-circle rounded-profile float-left" src={Helper.dataPath(list.page && list.page.image ? list.page.image : config.defaultCompanyImage)} alt="Generic placeholder image"  />
                                        <div className="card-body-right float-left m-l-10  text-left">
                                          <h3 className="font-bold font-14 m-t-0 m-b-0 l-h-18 ">{list && list.page ? Helper.truncate(list.page.name,30) : ""}</h3>
                                          <h6 className="font-normal m-t-0 m-b-0">{list && list.page ? list.page.banner.company : ""}</h6>
                                          <h6 className="font-normal text-grey m-t-0 m-b-0">{list && list.page ? list.page.subBanner.company : ""}</h6>
                                          <h6 className="font-normal text-grey m-t-0 m-b-0">
                                          {list && list.page && list.page.city?list.page.city.name:""}
                                          {list && list.page && list.page.country? (list.page.city ? " , " : "")+list.page.country.name:""}
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                              </div>
                          </label>
                      </div>

                ))
                :
                ""

           }    
              </div>
            </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  meta:state.meta,
});

export default connect(mapStateToProps,{companiesBySectors})(Companies);