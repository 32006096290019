import React, { Component } from 'react';
import Footer from './Footer';
import { withRouter, Link } from 'react-router-dom';

export class Features extends Component {
    render() {
        return (
            <React.Fragment>
              <div className="new_home m-t-60" id="features">
                  <div className="features">
                    <div className="row rowed">
                        <h2 className="full-width">Investera+ Africa</h2>
                        <h2 className="full-width font-normal m-t-30">Network with a community of over 3000+ investors</h2>
                        <p  className="full-width font-normal">Discover startups, investors, opportunities. trending events, and much more…</p>
                    </div>
                    <div className="row m-t-50 cards">
                        <div className="col-md-6">
                                <img alt="Investment and Startup Market Research Platform" 
                                  className="img-responsive"
                                  src="https://investera.com/wp-content/uploads/2021/10/investment-and-startup-market-research.png"></img>
                        </div>

                        <div className="col-md-6 text-left right-side">
                            <h2 className="text-left">Market Research</h2>
                            <p>
                                 Navigate through the in-depth information on 100K+ companies and 20K+ professionals. Full data coverage on public and private companies with a suite of financial intelligence feature providing you with the whole ecosystem info, details, and insights.
                            </p>
                            <Link to="/auth">Register</Link>
                        </div>
                    </div>
                    <div className="row m-t-50 cards">
                        <div className="col-md-6 text-left right-side">
                            <h2 className="text-left">Manage Companies and Startups Information</h2>
                            <p>
                            Company/startup owners/managers can keep their company/startup profile up-to-date with the latest contact information, funding round details,mergers and acquition, team members, financials and other related information.
                            </p>
                            <Link to="/auth">Register</Link>
                        </div>
                        <div className="col-md-6">
                                <img alt="Manage Startup and Company Details" 
                                  className="img-responsive"
                                  src="https://investera.com/wp-content/uploads/2021/10/manage-startups-and-company-details.png"></img>
                        </div>
                    </div>
                    <div className="row m-t-50 cards">
                        <div className="col-md-6">
                                <img alt="Find Investment Opportunities" 
                                  className="img-responsive"
                                  src="https://investera.com/wp-content/uploads/2021/10/find-investment-opportunities.png"></img>
                        </div>

                        <div className="col-md-6 text-left right-side">
                            <h2 className="text-left">Create and Mange Opportunities</h2>
                            <p>
                                 Create prospective investment opportunities to get funding for their existing startups or to pitch in to the investors for a new idea.
                            </p>
                            <p>Network with a community of over 3000+ investors in the MENA region and get the opportunity to pitch their products and services.</p>
                            <Link to="/auth">Register</Link>
                        </div>
                    </div>
                    <div className="row m-t-50 cards">
                        <div className="col-md-6 text-left right-side">
                            <h2 className="text-left">Personalized Feed</h2>
                            <p>
                             The platform offers various interactive features like following interested companies, express interest for an opportunity/event and receive updates in personalized feed, comment on posts, bookmark cards which later appear on the feeds, view live Facebook and Twitter feeds, and share posts on feed and via email.
                            </p>
                            <Link to="/auth">Register</Link>
                        </div>
                        <div className="col-md-6">
                                <img alt="Personalized Investment Information Feed" 
                                  className="img-responsive"
                                  src="https://investera.com/wp-content/uploads/2021/10/personalized-investment-information.png"></img>
                        </div>
                    </div>
                    <div className="row m-t-50 cards">
                        <div className="col-md-6">
                                <img alt="Investments, Investors and Startups Knowledge Graph" 
                                  className="img-responsive"
                                  src="https://investera.com/wp-content/uploads/2021/10/investment-iknowledge-graph.png"></img>
                        </div>

                        <div className="col-md-6 text-left right-side">
                            <h2 className="text-left">iKnowledge Graph</h2>
                            <p>
                                Capture a 360-degree view of stakeholders in an Entity via state of the art knowledge graphs. Rich media visualization of connections between entities showcasing investments, investors, founders, directors etc.
                            </p>
                            <p>
                                 Through iknowledge graph, it would be possible for users to enter search parameters and view search results in a dynamic and interactive way with the option to drill down to other layers of related/connected data.
                            </p>
                            <Link to="/auth">Register</Link>
                        </div>
                    </div>
                    <div className="row m-t-50 cards">
                        <div className="col-md-6 text-left right-side">
                            <h2 className="text-left">Industry and Market Research Reports</h2>
                            <p>
                            Get access to highly insightful industry reports, news and market maps covering 100+ sectors and sub-sectors. Get insights into the latest and trending sectors and industry, get quarterly funding and investment reports for various MENA countries, and many more.
                            </p>
                            <Link to="/auth">Register</Link>
                        </div>
                        <div className="col-md-6">
                                <img alt="Industry and Market Research Reports" 
                                  className="img-responsive"
                                  src="https://investera.com/wp-content/uploads/2021/10/industry-and-market-research-reports.png"></img>
                        </div>
                    </div>
                    <div className="row m-t-50 cards">
                        <div className="col-md-6">
                                <img alt="Investments, Investors and Startups Knowledge Graph" 
                                  className="img-responsive"
                                  src="https://investera.com/wp-content/uploads/2021/10/advanced-startup-and-investor-search.png"></img>
                        </div>

                        <div className="col-md-6 text-left right-side">
                            <h2 className="text-left">Advance Search</h2>
                            <p>
                                  Use the advance search to discover startups, investors, opportunities, events based on various search criterias like sector, industry, country and many more relevant criteria
                            </p>
                            <Link to="/auth">Register</Link>
                        </div>
                    </div>

                    <div className="row m-t-50 cards">
                        <div className="col-md-6 text-left right-side">
                            <h2 className="text-left">News & Newsletter</h2>
                            <p>
                            Keep up-to-date with the news of the companies you follow, the industry/sector of your interest. Receive daily and weekly newsletters based for your interested companies, industuries and sectors. In addition get quick insights and tips on various trending topics in the venture capital and startup world.
                            </p>
                            <Link to="/auth">Register</Link>
                        </div>
                        <div className="col-md-6">
                                <img alt="Industry and Market Research Reports" 
                                  className="img-responsive"
                                  src="https://investera.com/wp-content/uploads/2021/10/startup-news-and-financial-news-letters.png"></img>
                        </div>
                    </div>
                    {/* <div className="row m-t-50 cards">
                        <div className="col-md-6">
                                <img alt="Investments, Investors and Startups Knowledge Graph" 
                                  className="img-responsive"
                                  src="https://cdn-bdgbl.nitrocdn.com/dTJtgKdftaggOmkHoNpgFNxhrGctPBUf/assets/static/optimized/rev-f86a8b2/wp-content/uploads/2021/05/get-free-market-insights.png"></img>
                        </div>

                        <div className="col-md-6 text-left right-side">
                            <h2 className="text-left">Market Insights</h2>
                            <p>
                            Get in-depth information for a sector which includes market size, number of companies, number of investors, largest deal, estimated market size next year. In addition get details about trends of investment over time, companies to watch for in the chosen sector, top investors, promsing opportunities and much more information
                            </p>
                            <Link to="/auth">Register</Link>
                        </div>
                    </div> */}
                  </div>
                  <Footer/>
              </div>
            </React.Fragment>
        )
    }
}

export default Features
