import React, { Component } from 'react';
import config from "../../shared/config";
import Helpers from "../../shared/custom";

class DonutChart extends Component {
    constructor(props){
        super(props);

        this.state ={
            size:116,
            strokewidth:26,
        }
    }
  
    render() {
        let icon = this.props.icon;
        let total = this.props.total;
        let value = this.props.value;
        let _class = this.props._class;

       
        const halfsize = (this.state.size * 0.5);
        const radius = halfsize - (this.state.strokewidth * 0.5);
        const circumference = 2 * Math.PI * radius;
        const strokeval = ((value * circumference) / total);
        const dashval = (strokeval + ' ' + circumference);
    
        const trackstyle = {strokeWidth: this.state.strokewidth};
        const indicatorstyle = {strokeWidth: this.state.strokewidth, strokeDasharray: dashval}
        const rotateval = 'rotate(-90 '+halfsize+','+halfsize+')';
        return (
          <svg width={this.state.size} height={this.state.size} className={"donutchart "+_class}>
               <linearGradient id={"GR_NEWS"} x1="0" y1="0" x2="1" y2="100%">
                    <stop offset="0%" stopColor="#46a35b"></stop>
                    <stop offset="100%" stopColor="#522323"></stop>
              </linearGradient>
              <linearGradient id={"GR_FINANCIALS"} x1="0" y1="0" x2="1" y2="100%">
                    <stop offset="0%" stopColor="#e06666"></stop>
                    <stop offset="100%" stopColor="#232352"></stop>
              </linearGradient>
              <linearGradient id={"GR_FUNDING"} x1="0" y1="0" x2="1" y2="100%">
                    <stop offset="0%" stopColor="#4646a3"></stop>
                    <stop offset="100%" stopColor="#232352"></stop>
              </linearGradient>
              <linearGradient id={"GR_INVESTORS"} x1="0" y1="0" x2="1" y2="100%">
                    <stop offset="0%" stopColor="#c39960"></stop>
                    <stop offset="100%" stopColor="#624d30"></stop>
              </linearGradient>
              <linearGradient id={"GR_INVESTMENTS"} x1="0" y1="0" x2="1" y2="100%">
                    <stop offset="0%" stopColor="#46a35b"></stop>
                    <stop offset="100%" stopColor="#471855"></stop>
              </linearGradient>
              <linearGradient id={"GR_ACQUISITIONS"} x1="0" y1="0" x2="1" y2="100%">
                    <stop offset="0%" stopColor="#3290a7"></stop>
                    <stop offset="100%" stopColor="#194854"></stop>
              </linearGradient>
              <linearGradient id={"GR_STARTUP_DETAILS"} x1="0" y1="0" x2="1" y2="100%">
                    <stop offset="0%" stopColor="#a34663"></stop>
                    <stop offset="100%" stopColor="#522332"></stop>
              </linearGradient>
              <linearGradient id={"GR_BASIC_INFORMATION"} x1="0" y1="0" x2="1" y2="100%">
                    <stop offset="0%" stopColor="#46a383"></stop>
                    <stop offset="100%" stopColor="#235242"></stop>
              </linearGradient>
            <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={trackstyle} className="donutchart-track"/>
            <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={indicatorstyle} className="donutchart-indicator" stroke={"url(#"+_class+")"}/>
            <text className="donutchart-text" x={halfsize} y={halfsize} style={{textAnchor:'middle'}} >
             
              <tspan className="donutchart-text-val">{value+"/"+total}</tspan>
              <tspan className="donutchart-text-label" x={halfsize} y={halfsize+20}>{Helpers.ratingIcons(_class)}</tspan>
            </text>
            
          </svg>
        );
      }
}
export default DonutChart;