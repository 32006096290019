import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import store from "../../store";
import Helpers from "../../shared/custom";
import Moment from "react-moment";
import NotUser from "./NotUser";
import Axios from "axios";
import Loader from "../ui/Loader";
import ListCard from '../wigets/ListCard';
const $ = require('jquery');
$.DataTable = require('datatables.net');

class Investments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      investments: [],
      investmentNew:[],
      uri: undefined,
      total: 0,
      isAuthenticated: true,
      noOfInv: 0,
      totalLead: 0,
      loading: false,
      entityType: "company",
      fundingSeeMore:false
    };
    this.investmentDiv = React.createRef();
  }
  componentDidMount() {
   
    this.setState({
      newData:   [{
                label:"Announced Date",
                field:"fromDate"
            },
            {
                label:"Lead Investor",
                field:"isLead"
            },
              {
                label:"Funding Type",
                field:"fundingType"
            },
            {
              label:"Pre Money Valuation",
              field:"preMoneyValuationFormated"
            },
            {
              label:"Money Raised",
                field:"amountRaisedFormated"
            },
            {
                label:"Funding Stage",
                field:"fundingStage"
            },{
              label:"Equity Only Funding",
              field:"equityOnlyFunding"
          }]
    })
  }

  getInvestmentDetails(entityUri, entityType) {
    entityType = Helpers.isEmpty(entityType) ? "company" : entityType;
    let currentUri = this.state.uri;

    

    if (currentUri && currentUri == entityUri) {
      return false;
    }
    
    if (!this.props.showContent) {
      return false;
    }


    this.setState({
      loading: true,
      uri: entityUri,
      entityType: entityType
    });

    Axios.get(
      Helpers.apiURL({
        uri: ":type/:entityUri/investments",
        pathVar: { entityUri: entityUri, type: entityType }
        // query:{type:"company"}
      })
    )
      .then(response => {
        this.setState({
          loading: false
        });
        const companyDetails = response.data.result || {};
        // investments

        //if (!Helpers.isEmpty(companyDetails)) {
          var invState = !Helpers.isEmpty(companyDetails) ?  companyDetails : [];
          this.setState(
            {
              investment: invState,
              investmentNew:invState.slice(0,4),
            },
            () => {}
          );

          var total = 0;
          var totalLead = 0;
          invState.forEach((data, index) => {
            total += data.amountRaised;
            totalLead+=(data.isLead  == true || data.isLead.toString().toLowerCase() == "yes") ? 1 : 0;
          });
          this.setState(
            {
              total: total,
              noOfInv: invState.length,
              totalLead: totalLead
            },
            () => {
              if ($.fn.DataTable.isDataTable(this.refs.investmentTable) === false){
                $(this.refs.investmentTable).DataTable({searching: false, info: false, });
              }
            }
          );
       // }
      })
      .catch(err => {
        this.setState({
          loading: false
        });
      });
  }



  componentWillReceiveProps(nextProps) {
    var query = Helpers.getQueryParameter("div");
    if(query == "investment"){
      this.scrollToInvestment()
    }
    if (nextProps.entityUri)
      this.getInvestmentDetails(nextProps.entityUri, nextProps.entityType);
  }


  handleFundingSeeMore = () => {
    let fundingSeeMore = this.state.fundingSeeMore;
    let investment = this.state.investment;
    if(fundingSeeMore){
        this.setState({
            investmentNew: investment.slice(0,4),
            fundingSeeMore: !fundingSeeMore
        });
    } else {
        this.setState({
            investmentNew:investment,
            fundingSeeMore: !fundingSeeMore
        });
    }
 }

  scrollToInvestment=()=>{
    if(this.investmentDiv.current){
        this.investmentDiv.current.scrollIntoView({
            behavior: "smooth",
            // block: "nearest",
        })
    }
  }

  render() {
    let showContent = this.props.showContent;
    let pageViewsLeft = this.props.pageViewsLeft;
    let loading = this.state.loading;
    let theme = Helpers.getCardTheme(this.props.theme);
    let type = this.props.type;
    let investment = this.state.investmentNew;
    let newData = this.state.newData;
    let mode = this.props.mode;
    let uri = this.props.entityUri;


    
    return (
      <div>
        {
        !Helpers.isEmpty(this.state.investment) ? 
        
        loading ? (
          <Loader />
        ) : (
          <div className="card text-left bold-headers" id={this.props.id} ref={this.investmentDiv}>
            {showContent && pageViewsLeft > 0 ? (
              <div>
                <div className={"col-md-6 float-left card-header text-left light-blue-header-half " + theme.headerClass}>
                  Investments
                </div>
                <div className="col-md-6 float-left text-right">
                      <span className="font-12">Number of Investments {this.state.noOfInv}</span> <br/>
                      <span className="font-12">Number of Lead Investments {this.state.totalLead}</span> <br/>
                </div>
                <div className="card-body blue-border clearfix">
                  {Helpers.isEmpty(this.state.investment) ? (
                    <div className="table-responsive text-center m-t-10">
                      No Data Available
                    </div>
                  ) : (
                     <div className="col-md-12">
                       { type != "card" ?
                        <div className="table-responsive">
                          <table   ref="investmentTable" className="table color-table muted-table dataTable">
                            <thead>
                              <tr>
                                <th>Announced Date</th>
                                <th>Company Name</th>
                                <th>Lead Investor</th>
                                <th>Funding Type</th>
                                <th>Pre Money Valuation</th>
                                <th>Money Raised</th>
                                <th>Funding Stage</th>
                                <th>Equity Only Funding</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.investment.map((data, index) => (
                                <tr key={index}>
                                  <td>
                                    {Helpers.getDateInInputFormat(data.fromDate)}
                                  </td>
                                  <td>
                                    <Link
                                      to={"/" + data.pageType + "/" + data.page.uri}
                                    >
                                      {data.page ? data.page.name : ""}
                                    </Link>
                                  </td>
                                  <td>{data.isLead ? "Yes" : "No"}</td>
                                  <td>
                                    {data.fundingType ? data.fundingType : "--"}
                                  </td>
                                  <td>
                                    {!Helpers.isEmpty(data.preMoneyValuation)
                                      ? Helpers.displayAmount({amount:data.preMoneyValuation, currency:data.investedAmountCurrency})
                                      : "--"}
                                  </td>
                                  <td>
                                    {/* {data.amountRaisedCurrencyDto
                                      ? data.amountRaisedCurrencyDto.shortName
                                      : "" + " " + data.amountRaised
                                      ? data.amountRaised
                                      : ""} */}
                                      {!Helpers.isEmpty(data.amountRaised)
                                      ? Helpers.displayAmount({amount:data.amountRaised, currency:data.investedAmountCurrency})
                                      : "Undisclosed"}

                                      {/* {Helpers.displayAmount({amount:data.amountRaised,currency:data.investedAmountCurrency})} */}
                                  </td>
                                  <td>
                                    {data.earlyStageVenture
                                      ? data.earlyStageVenture
                                      : "--"}
                                  </td>
                                  <td>{data.equityOnlyFunding ? "Yes" : "No"}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        :
                          <div className="row">
                              { 
                                investment.map((row,i)=>(
                                    <ListCard dataset={row} mode={{type:"investment"}} newData={newData}/>
                                ))}
                          </div>
                         }
                          {mode == "funding" ? 
                              <div className="justify-content-center flex-row d-flex col-xs-12">
                                  <h5 className="text-blue m-2 cursor-pointer" onClick={this.handleFundingSeeMore}>See More <i className={"m-l-5 fa "+ (this.state.fundingSeeMore?"fa-chevron-up":"fa-chevron-down")+" font-18"}></i></h5>
                              </div> 
                              :
                              <div className="col-md-12 font-14 text-right float-right p-0"><Link to={`/company-funding-details/${uri}?div=investment`}>View All</Link></div>
                          }
                    </div>
                  )}
                </div>
               
              </div>
            ) : (
              <NotUser title="Investments" showContent={this.props.showContent} pageViewsLeft={this.props.pageViewsLeft} />
            )}
          </div>
        )
        :
        ""
      }
      </div>
    );
  }
}
export default withRouter(Investments);
