import Axios from 'axios';
import { GET_CURRENT_PLAN , GET_ERRORS , GET_PAGE_VIEWS}  from './types';
import Helper from "../shared/custom"

export const getPackageDetails= () => dispatch => {
    Axios.get(
        Helper.apiURL({
            uri:"user/subscription/details",
        })
    )
    .then( (response) => {
        const packageDetails  = response.data.result || {};    
        localStorage.setItem("packageDetails",JSON.stringify(packageDetails))
        dispatch({
            type: GET_CURRENT_PLAN,
            payload: packageDetails,
        })   
    })
    .catch((err) => {
       
    });
}

export const getPageViews = () => dispatch => {
    Axios.get(
        Helper.apiURL({
            uri:"user/subscription/left",
        })
    )
    .then( (response) => {
        const payload  = response.data.result || {};     
        dispatch({
            type: GET_PAGE_VIEWS,
            payload: payload,
        })   
    })
    .catch((err) => {
       
    });
}