import React, { Component } from 'react';
import StartRating from "../ui/StarRating";
import Helpers from "../../shared/custom";
import DonutChart from './DonutChart';
import store from '../../store';
import { connect } from 'react-redux';
import { getRankingDetails } from '../../actions/rankingAction';
import { withRouter, Link } from 'react-router-dom';
import config from "../../shared/config";

class RatingDetails extends Component {
    constructor(props){
        super(props);
        this.state = {
            companyDetails:{},
            ratings:0,
            rankingDetails:{},
            mainDetails:[],
            maxTotal:0,
            scoreTotal:0,
            uri:null,
        }
       
    }
    componentDidMount(){
        let data = this.props.data;
        
        if(!Helpers.isEmpty(data)){
            this.setState({
                companyDetails:data,
                ratings:data && data.company && data.company.rankingDetails && data.company.rankingDetails.ranking ? data.company.rankingDetails.ranking:0
            })
        }
       
    }
    componentWillReceiveProps(nextProps){
        let data = nextProps.data;
        if(!Helpers.isEmpty(data)){
            if(JSON.stringify(this.state.companyDetails) != JSON.stringify(data)){
                this.setState({
                    companyDetails:data,
                    uri:data.page.uri,
                    ratings:data && data.company && data.company.rankingDetails && data.company.rankingDetails.ranking ? data.company.rankingDetails.ranking:0
                })
            }
        }

       
   
        var storeState = store.getState();
        let rankingDetails = storeState && storeState.rankingReducer && storeState.rankingReducer.rankDetails ? storeState.rankingReducer.rankDetails : {}
       
   
        if(JSON.stringify(this.state.rankingDetails) != JSON.stringify(rankingDetails)){
            let mainDetails  = [];
            let newDetails = {
                GR_NEWS:[],
                GR_FINANCIALS:[],
                GR_FUNDING:[],
                GR_INVESTORS:[],
                GR_INVESTMENTS:[],
                GR_ACQUISITIONS:[],
                GR_STARTUP_DETAILS:[],
                GR_BASIC_INFORMATION:[],
            };
            let { maxTotal,scoreTotal} = this.state;

            if(!Helpers.isEmpty(rankingDetails)){
               maxTotal = 0;
               scoreTotal = 0;
                rankingDetails.forEach((data,i)=>{
                    maxTotal += data.maxScore;
                    scoreTotal += data.score;
                    this.setState({
                        maxTotal:maxTotal,
                        scoreTotal:scoreTotal
                    })
                    
                    var curr = newDetails[data.group]
                    if (!curr) {
                        newDetails[data.group] = [];
                    }
                    newDetails[data.group].push({
                        code:data.code,
                        displayValue:data.displayValue,
                        group:data.group,
                        maxScore:data.maxScore,
                        score:data.score,
                    });
                    mainDetails.push(newDetails);
                })
                
           
            }
            this.setState({
                rankingDetails:rankingDetails,
                mainDetails:mainDetails,
                scoreTotal:scoreTotal,
                maxScore:scoreTotal
            })
        }

    }

    getTotalandScore = (type)=>{
        let mainDetails = this.state.mainDetails;
        let maxTotal = 0;
        let scoreTotal = 0;
        if(!Helpers.isEmpty(mainDetails[0])){
            if(!Helpers.isEmpty(mainDetails[0][type])){
                mainDetails[0][type].forEach((data,i)=>{
                    maxTotal += data.maxScore
                    scoreTotal += data.score
                })
                return {maxTotal:maxTotal,scoreTotal:scoreTotal}
            }
            return {maxTotal:maxTotal,scoreTotal:scoreTotal}
        }else{
            return {maxTotal:maxTotal,scoreTotal:scoreTotal}
        }
       
    }
    render() {
        let { ratings , companyDetails ,rankingDetails ,mainDetails} = this.state;
        let page = companyDetails.page;
        let type = page && page.primaryPageType &&  page.primaryPageType.subType && page.primaryPageType.subType.type ? page.primaryPageType.subType.type : null;
        mainDetails = mainDetails[0];

       

        return (
          <React.Fragment>
            {!Helpers.isEmpty(mainDetails) ?
              <div className="card bold-headers text-left ratingBanner" id={this.props.id}>
                  <div className="card-header text-left light-blue-header">
                    <h5 className="m-b-0 m-t-0 text-white">Company Profile Rating</h5>
                  </div>
                  <div className="card-body">
                    <p className="text-center">
                      <StartRating rating={ratings} withLabel={true} masterClass="text-center"/>
                    </p>
                      <div className="card-body text-left ratingDetails">
                          <a href="" className="font-12 font-bold underline text-blue">{page && page.name ?  page.name :""} Rating Detail</a>
                          <div className="col-md-12 m-t-10 p-0 clearfix">
                                <div className="col-md-6 col-sm-12 p-0 float-left">
                                   { !Helpers.isEmpty(mainDetails["GR_FINANCIALS"]) ?
                                        <div className="card-body bg-white p-2  m-t-5">
                                        <h5 className="font-12 text-blue m-t-0">FINANCIALS</h5>
                                       {mainDetails["GR_FINANCIALS"].map((data,i)=>( 
                                           <div className="font-12"><label className="half-width">{data.displayValue}</label> <span className="float-right">{data.score} pts/{data.maxScore} pts</span></div>
                                        ))}
                                      </div>
                                    :
                                    ""
                                   }
                                 { !Helpers.isEmpty(mainDetails["GR_NEWS"]) ?
                                        <div className="card-body bg-white p-2 m-t-5">
                                        <h5 className="font-12 text-blue m-t-0">NEWS</h5>
                                       {mainDetails["GR_NEWS"].map((data,i)=>( 
                                          <div className="font-12"><label className="half-width">{data.displayValue}</label> <span className="float-right">{data.score} pts/{data.maxScore} pts</span></div>
                                        ))}
                                      </div>
                                   
                                    :
                                    ""
                                   }

                                  { !Helpers.isEmpty(mainDetails["GR_FUNDING"]) ?
                                        <div className="card-body bg-white p-2 m-t-5">
                                        <h5 className="font-12 text-blue m-t-0">FUNDING</h5>
                                       {mainDetails["GR_FUNDING"].map((data,i)=>( 
                                          <div className="font-12"><label className="half-width">{data.displayValue}</label> <span className="float-right">{data.score} pts/{data.maxScore} pts</span></div>
                                        ))}
                                      </div>
                                   
                                    :
                                    ""
                                   }

                                  {!Helpers.isEmpty(mainDetails["GR_INVESTORS"]) ?
                                        <div className="card-body bg-white p-2 m-t-5">
                                        <h5 className="font-12 text-blue m-t-0">INVESTORS</h5>
                                       {mainDetails["GR_INVESTORS"].map((data,i)=>( 
                                          <div className="font-12"><label className="half-width">{data.displayValue}</label> <span className="float-right">{data.score} pts/{data.maxScore} pts</span></div>
                                        ))}
                                      </div>
                                   
                                    :
                                    ""
                                   }
                                    {!Helpers.isEmpty(mainDetails["GR_INVESTMENTS"]) ?
                                        <div className="card-body bg-white p-2 m-t-5">
                                        <h5 className="font-12 text-blue m-t-0">INVESTMENTS</h5>
                                       {mainDetails["GR_INVESTMENTS"].map((data,i)=>( 
                                          <div className="font-12"><label className="half-width">{data.displayValue}</label> <span className="float-right">{data.score} pts/{data.maxScore} pts</span></div>
                                        ))}
                                      </div>
                                   
                                    :
                                    ""
                                   }
                                  {!Helpers.isEmpty(mainDetails["GR_ACQUISITIONS"]) ?
                                        <div className="card-body bg-white p-2 m-t-5">
                                        <h5 className="font-12 text-blue m-t-0">ACQUISITIONS</h5>
                                       {mainDetails["GR_ACQUISITIONS"].map((data,i)=>( 
                                          <div className="font-12"><label className="half-width">{data.displayValue}</label> <span className="float-right">{data.score} pts/{data.maxScore} pts</span></div>
                                        ))}
                                      </div>
                                   
                                    :
                                    ""
                                   }
                                </div>
                                <div className="col-md-6 col-sm-12 float-left right-d-pading">
                                   {!Helpers.isEmpty(mainDetails["GR_STARTUP_DETAILS"]) && type == "STARTUP" ?
                                        <div className="card-body bg-white p-2  m-t-5">
                                        <h5 className="font-12 text-blue m-t-0">STARTUP DETAILS</h5>
                                       {mainDetails["GR_STARTUP_DETAILS"].map((data,i)=>( 
                                          <div className="font-12"><label className="half-width">{data.displayValue}</label> <span className="float-right">{data.score} pts/{data.maxScore} pts</span></div>
                                        ))}
                                      </div>
                                   
                                    :
                                    ""
                                   }
                                    {!Helpers.isEmpty(mainDetails["GR_BASIC_INFORMATION"]) ?
                                        <div className="card-body bg-white p-2 m-t-5">
                                        <h5 className="font-12 text-blue m-t-0">BASIC INFORMATION</h5>
                                       {mainDetails["GR_BASIC_INFORMATION"].map((data,i)=>( 
                                          <div className="font-12"><label className="half-width">{data.displayValue}</label> <span className="float-right">{data.score} pts/{data.maxScore} pts</span></div>
                                        ))}
                                      </div>
                                   
                                    :
                                    ""
                                   }
                                </div>
                          </div>
                          <hr className="black"/>
                          <div className="font-12 font-bold text-blue text-right">TOTAL POINTS {this.state.scoreTotal}/{this.state.maxTotal}</div>
                    </div>
                    <div className="card-body">
                          <h5 className="font-14 text-left font-normal">Information available about <b>{page && page.name ?  page.name :""}</b></h5>
                          <div className="row m-t-20">
                              {!Helpers.isEmpty(mainDetails) ?

                                   type != "STARTUP"  ? 

                                    Object.keys(mainDetails).map((keyName, i) => {
                                        if(keyName != "GR_STARTUP_DETAILS") {
                                            return <div className="col-md-4 col-sm-12 font-12 m-t-20 m-b-20" key={"don"+i}>
                                                <DonutChart value={this.getTotalandScore(keyName).scoreTotal} total={this.getTotalandScore(keyName).maxTotal} _class={keyName}/>
                                                <h5 className="font-bold text-center font-12">{config.rankingNames[keyName]}</h5>
                                                <hr className="greyLine"/>
                                                {mainDetails[keyName].map((data,i)=>(
                                                    <div className="rankDetails clearfix">
                                                        <i className={data.score > 0 ? "fas fa-check available float-left m-t-2" : "fas fa-times not-available  float-left m-t-2"}></i>
                                                        <div className="col-10 p-0 float-left m-l-5">{data.displayValue}</div>
                                                    </div>
                                                ))
                                                }
                                           </div>
                                        }
                                        
                                    })
                                    :
                                    Object.keys(mainDetails).map((keyName, i) => {
                                            return <div className="col-md-4 col-sm-12 font-12 m-t-20 m-b-20" key={"don"+i}>
                                                <DonutChart value={this.getTotalandScore(keyName).scoreTotal} total={this.getTotalandScore(keyName).maxTotal} _class={keyName}/>
                                                <h5 className="font-bold text-center font-12">{config.rankingNames[keyName]}</h5>
                                                <hr className="greyLine"/>
                                                {mainDetails[keyName].map((data,i)=>(
                                                    <div className="rankDetails clearfix">
                                                        <i className={data.score > 0 ? "fas fa-check available float-left m-t-2" : "fas fa-times not-available  float-left m-t-2"}></i>
                                                        <div className="col-10 p-0 float-left m-l-5">{data.displayValue}</div>
                                                    </div>
                                                ))
                                                }
                                           </div>
                                    })
                                 
                                 :
                                 ""
                              }
                          </div>
                    </div>
                </div>
                
                {/* // <div className="card-body">
                //     <p className="text-center">
                //       No data available
                //     </p>
                // </div> */}
                
            </div>
            :
                ""
        }
            </React.Fragment>
        )
    }
}


export default withRouter(RatingDetails);
