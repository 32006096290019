import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link , withRouter, Switch} from 'react-router-dom';
import Defaultmenu from './Defaultmenu';
import Searchmenu from './Searchmenu';
import Profilemenu from './Profilemenu';
import Oppmenu  from  './Oppmenu';
import Eventmenu from './Eventmenu';
import Eventsummarymenu from './Eventsummarymenu';
import Oppsummarymenu from './Oppsummarymenu';
import CompanySummaryMenu from './CompanySummaryMenu';
import ProfileCreateMenu from './ProfileCreateMenu';
import ProfileEditMenu from './ProfileEditMenu';
import UserProfileMenu from './UserProfileMenu';
import Peoplemenu from './Peoplemenu';
import NewsMenu  from './NewsMenu';
import PaymentMenu from './PaymentMenu';
import $ from 'jquery';
import Helpers from '../../shared/custom';
import { login } from '../../actions/authAction';
import { connect } from 'react-redux';
import store from '../../store';
import { getPackageDetails } from '../../actions/packageAction';

class Sidemenu extends Component {
   constructor(props){
        super(props)
        this.state = {
            view :"default",
            context:undefined,
            filterDates:{},
            query:undefined,
            privileges:{},
            localProps:{},
            isLoggedIn:false,
            privilegesLoaded:false,
        }
        this.getLocationId = this.getLocationId.bind(this);
        this.getDateFilterVal = this.getDateFilterVal.bind(this);
        
    } 


componentDidMount(){

    var storeState = store.getState();  
    this.setState({
     isLoggedIn: storeState.auth.isAuthenticated
    },()=>{
        if(this.state.isLoggedIn && this.props.getPackageDetails){
            this.setState({privilegesLoaded:true})
            this.props.getPackageDetails();
        }
    }) 
   
    this.setState({
        view:this.props.mode
    })

    this.setState({
        query: this.props.query ? this.props.query : ""
    })

} 

componentWillReceiveProps(props){
    if(JSON.stringify(props) != JSON.stringify(this.state.localProps)){
        
        
        let newLogin = props.auth.isAuthenticated;
        let privilegesLoaded = this.state.privilegesLoaded;
        
        let updatedState = {
            localProps:props,
            context:props.context,
            view:this.props.mode,
        }

        if(props.packageDetails && props.packageDetails.privileges){
            updatedState.privileges = props.packageDetails.privileges;
            updatedState.privilegesLoaded = true;
        }
        
        this.setState(updatedState,()=>{
            if(newLogin && !privilegesLoaded){
                this.props.getPackageDetails();
            }
        })

            //     this.setState({
                    
            //     },()=>{
            //         if(props.auth && props.auth.isAuthenticated){
                    
            //         }
            //     });
     }
}

getLocationId(locationIds){
    this.props.sendLocationToParent(locationIds);
}

getDateFilterVal(filterDates){
     this.props.getDateFilterValues(filterDates);
     this.setState({
        filterDates:filterDates
     })
}


  render() {

    
    let view  = this.state.view;
    let privileges = this.state.privileges;
    
    let sideMenu = "";
    if(view == "company"){
        sideMenu = <Profilemenu lastPageState={this.props.lastPageState} privileges={this.state.privileges}/>
    }
    else if(view == "companyCreate"){
        sideMenu = <ProfileCreateMenu lastPageState={this.props.lastPageState} companyDetails={this.props.companyDetails}/> 
    }
    else if(view == "editCompany"){
        sideMenu = <ProfileEditMenu lastPageState={this.props.lastPageState} companyDetails={this.props.companyDetails}/> 
    }
    else if(view == "people"){
        sideMenu = <Peoplemenu />
    }else if(view == "search"){
        sideMenu = <Searchmenu/>
    }
    else if(view == "opportunity"){
        sideMenu = <Oppmenu />
    }
    else if(view == "events"){
        sideMenu = <Eventmenu  context={this.state.context} />
    }
    else if(view == "payment"){
        sideMenu = <PaymentMenu  context={this.state.context} />
    }
    else if(view == "eventSummary" && this.props.isLoggedIn){
        sideMenu = <Eventsummarymenu isLoggedIn={this.props.isLoggedIn}  context={this.state.context} getLocationIdFunc={this.getLocationId} getDateFilterValues={this.getDateFilterVal} />
    }
    else if(view == "oppSummary" && this.props.isLoggedIn){
        sideMenu = <Oppsummarymenu isLoggedIn={this.props.isLoggedIn} context={this.state.context} getLocationIdFunc={this.getLocationId} getDateFilterValues={this.getDateFilterVal}  />
    }
    else if((view == "companySummary" ) && this.props.isLoggedIn){        
        sideMenu = <CompanySummaryMenu isLoggedIn={this.props.isLoggedIn} getLocationIdFunc={this.getLocationId}/>
    }
    else if(view == "userprofile"){
       sideMenu = <Defaultmenu isLoggedIn={this.props.isLoggedIn}/>
    }
    else if(view == "news" && this.props.isLoggedIn){
        sideMenu = <NewsMenu isLoggedIn={this.props.isLoggedIn}/>
    }else{
        
        sideMenu = <Defaultmenu isLoggedIn={this.props.isLoggedIn} />
    }
        
    let absolutedMenu = view == "search" ?  "fix-sidebar" : "abs-sidebar";
    let sideMenuBg = "sideMenuBg";

    if(Helpers.isEmpty(view) || view == "userprofile" ){
        sideMenuBg = "no-bg";
    }else if(view == "userprofile"){
        sideMenuBg = "no-bg";
    }else{
        sideMenuBg = "sideMenuBg"
    }

    return (
        // <Router>
            <div className={"left-sidebar sticky-nav nav lefted "+sideMenuBg}> 
                <div className="scroll-sidebar ">
                    <nav className={"sidebar-nav "+sideMenuBg}>
                    <div className={"left-aside "+absolutedMenu+" "+sideMenuBg}>
                            <div className={sideMenuBg}>
                                {/* {view} */}
                                {sideMenu}
                                <br className="clearFix" />
                            </div>                         
                        </div>
                    </nav>
                </div>
            </div> 
        // </Router>      
    )

    // return (
    //     // <Switch>
    //         <div className={"left-sidebar sticky-nav nav m-t-80 lefted " + sideMenuBg1}> 
    //             <div className="scroll-sidebar ">
    //                 <nav className={"sidebar-nav "+sideMenuBg1}>
    //                 <div className={"left-aside "+absolutedMenu+" "+sideMenuBg1}>
    //                         <div className={sideMenuBg1}>
    //                             {/* {view} */}
    //                             {sideMenu}
    //                             <br className="clearFix" />
    //                         </div>                         
    //                     </div>
    //                 </nav>
    //             </div>
    //         </div> 
    //     // </Switch>      
    // )
  }
}

const mapStateToProps = (state) =>({
    auth:state.auth,
    packageDetails:state.packageDetails,
    errors:state.errors
});

export default connect(mapStateToProps,{getPackageDetails,login})(withRouter(Sidemenu));
//export default withRouter(Sidemenu);
