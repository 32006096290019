import React, { Component } from "react";
import Helper from "../../shared/custom";
import Helpers from "../../shared/custom";

class Videos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editView: false
    };
    this.handleAdd = this.handleAdd.bind(this);
    this.handleLinkChange = this.handleLinkChange.bind(this);
    this.handleAddMore = this.handleAddMore.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleAdd() {
    this.setState({
      editView: true
    });
  }

  handleAddMore() {
    let itemChange = this.props.itemChange;
    let itemKey = this.props.itemKey;
    let newListOfVideos = [];
    let listOfVideos = document.querySelectorAll(".videoInput");

    listOfVideos.forEach(video => {
      if (video.value != "") {
        newListOfVideos.push(video.value);
      }
    });

    newListOfVideos.push("");

    itemChange(itemKey, newListOfVideos);
  }

  handleConfirm() {
    let itemChange = this.props.itemChange;
    let itemKey = this.props.itemKey;
    let newListOfVideos = [];
    let listOfVideos = document.querySelectorAll(".videoInput");
    listOfVideos.forEach(video => {
      if (
        video.value != "" &&
        (Helpers.isValidVimeo(video.value) ||
          Helpers.isValidYouTube(video.value))
      ) {
        newListOfVideos.push(video.value);
      }
    });
    this.setState({
      editView: false
    });
    itemChange(itemKey, newListOfVideos);
  }

  handleLinkChange(e) {
    let link = e.target.value;
    if (!Helpers.isValidVimeo(link) && !Helpers.isValidYouTube(link))
      e.target.classList.add("not-invalid-field");
    else {
      e.target.classList.remove("not-invalid-field");
    }
  }

  handleDelete = (videoLink, e) => {
    let listOfVideos = this.props.items;
    let videos = listOfVideos.slice(); //create a copy of that array first
    videos.find(function(foundLink, ToDelIndex) {
      let videoLinkClone = videoLink;
      let foundLinkClone = foundLink;

      if (
        foundLinkClone.split(/\/|=/).pop() == videoLinkClone.split(/\/|=/).pop()
      ) {
        if (ToDelIndex != -1) {
          videos.splice(ToDelIndex, 1);
        }
        return true;
      }
    }, this);

    this.props.itemChange(this.props.itemKey, videos);
  };

  renderItem(link) {
    let videoLink = link;
    if (!Helper.isEmpty(videoLink)) {
      videoLink = Helper.getEmbbedVideoLink(videoLink);
    }
    let className = this.state.editView
      ? "col-lg-12 col-md-6 m-b-20"
      : "col-md-4";
    return (
      <div className={className}>
        {this.state.editView ? (
          <input
            className="form-control videoInput"
            name="videos[]"
            defaultValue={link}
            onChange={this.handleLinkChange}
            placeholder="Paste a Youtube, Vimeo, or other video link, and click Save below"
          />
        ) : (
          <React.Fragment>
            <embed src={videoLink} />
            {this.props.isEditable ? (
              <button
                className="text-inverse point btn-plain fa fa-times"
                onClick={e => this.handleDelete(videoLink, e)}
              />
            ) : (
              ""
            )}
          </React.Fragment>
        )}
      </div>
    );
  }

  render() {
    let title = this.props.title;
    let description = this.props.description;
    let icon = this.props.icon;

    let items = this.props.items || [];
    let itemKey = this.props.itemKey;
    let isEditable = this.props.isEditable;
    let editView = this.state.editView;
    let noItemsUploadedLabel = this.props.noItemsUploadedLabel || "No items uploaded!";
    return (
      <div>
        <div className="col-lg-12 p-0 float-left">
          <h5>{title}</h5>
          {isEditable ? (
            <div className="uploadTag">
              <a href="javascript:void(0)" onClick={this.handleAdd}>
                <i className={"fas fa-" + icon} /> {description}
              </a>
            </div>
          ) : (
            ""
          )}
          {items.length? (
          <div className="result m-t-20">
            <div className="row">
              {items.map((item, index) => this.renderItem(item))}
            </div>
          </div>):
          (<div className="result m-t-20">
            <center>{noItemsUploadedLabel}</center>
        </div>)
        }

          {isEditable && editView ? (
            <div className="uploadTag">
              <a href="javascript:void(0)" onClick={this.handleAddMore}>
                {" "}
                <i className={"fas fa-" + icon} /> Add More{" "}
              </a>
              <a
                href="javascript:void(0)"
                className="m-l-10"
                onClick={this.handleConfirm}
              >
                {" "}
                <i className={"fas fa-check"} /> Save{" "}
              </a>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default Videos;
