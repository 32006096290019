import React, { useState , useEffect} from 'react';
import { Link } from 'react-router-dom';
import AuthorDetails from './AuthorDetails';
import CardActions from './CardActions';
import { Report } from './Report';

import Comments from './Comments';
import CommentShareBtn from './CommentShareBtn';



export const NewReport = ({ data }) => {
    let dataset = data.report;
    const [focusInput, setFocusInput] = useState(false);
    const focusInputFiled = () =>{
        setFocusInput(!focusInput)
    }
    

    return (
        <div>
            <div className="card">
                <div className="card-body">
                    <div className="col-lg-12 p-0">
                        <AuthorDetails data={data} />
                        <div className="col-lg-3 p-0 float-right text-right">
                            <CardActions data={data} />
                        </div>
                    </div>

                    <div className="clearfix"></div>
                    <div className="col-lg-12 p-0 m-t-40 ">
                        <div className="b-b-2 p-b-5">
                            <span> <i className="fa fa-file-alt font-24 vm m-r-5"></i> <Link className="font-bold" to="/">{data.author.name}</Link> Has a new related Report</span>
                        </div>
                    </div>

                    <Report data={dataset} />
                    <div className="clearfix"></div>

                    <div className="float-right m-t-5">
                        <Link className="font-14" to={`report/?uri${data.author.uri}&name=${data.author.uri}`}>View All</Link>
                    </div>
                    <div class="clearfix"></div>

                    <CommentShareBtn data={data} showInterested={false}
                       focusInputArea={focusInputFiled} 
                    // updateCounts={this.updateCounts}
                    />
                    <Comments data={data}
                       enableFocus={focusInput}
                    />

                </div>
            </div>
        </div>
    );
}

