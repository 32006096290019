import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Sidemenu from '../sidemenu/Sidemenu';
import D3RelationsContent from './D3RelationsContent';
import GraphFilter from './GraphFilter';

import Axios from 'axios';
import config from '../../shared/config';
import Helper from "../../shared/custom";
import Helpers from '../../shared/custom';
import store from '../../store';


class D3Relations extends Component {
  constructor(props){
    super(props);
    this.state={
      payload :{},
      uri:undefined,
      mode:undefined,
      privileges:{}
    
    }
    this.fetchFilterData = this.fetchFilterData.bind(this);
    this.onCompanyChange = this.onCompanyChange.bind(this);
    this.onClickBack = this.onClickBack.bind(this);

    this.filter = React.createRef();

  }

  componentDidMount(){
    let uri = Helpers.getQueryParameter("uri");
    if(uri){
      this.setState({
        uri:uri,        
        mode:"details"
      })
    }     
    
    var storeState = store.getState();
    var auth = storeState.auth;
    var localAuth = this.state.auth;
    let packageDetails =  storeState.packageDetails;
    let privileges = packageDetails.privileges;
    
    
    // if(JSON.stringify(privileges) != JSON.stringify(this.state.privileges)){
    //     this.setState({
    //         privileges:privileges
    //     },()=>{
    //       if(Helper.isEmpty(privileges) || !privileges["KG"]){
    //         this.props.history.push(Helpers.upgradeUrl());
    //         return false;
    //       }
    //     })
    //   }

          
    
  }

  onClickBack(){

  }
  
  onCompanyChange(uri){
      this.setState({
        uri:uri,
        mode:"details"
      })
  }

  fetchFilterData(payload){
    this.setState({
        uri:null,
        payload:payload,
        mode:"summary"
    })
   
  }

  render() {
    let uri = this.state.uri;
    let mode = this.state.mode;
    let payload = this.state.payload;
    return (
        <div>
                <div className="row content-in m-t-80 p-t-10">
                    <GraphFilter getFiterData={this.fetchFilterData} ref="filter" onChangeCompany={this.onCompanyChange} />
                    <div className="col-xlg-12 col-lg-12 col-md-12 p-r-0 graphContainer">
                        <D3RelationsContent uri={uri} mode={mode} payload={payload} onClickBack={this.onClickBack}/>                       
                    </div>
                </div>
        </div>
    )
  }
}
export default withRouter(D3Relations) ;