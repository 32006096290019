

import React, { useState, useEffect } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import config from '../../shared/config';
import Helpers from '../../shared/custom';
import Axios from 'axios';



const CompanyPs = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleSearch = (query) => {
    setIsLoading(true);
    let url = config.api("api/company-psl/search")
    const SEARCH_URI = `${url}?query=${query}`;
    Axios.get(
      SEARCH_URI
    )
      .then((response) => {
        let objects = response.data.result;
        const options = objects.map((obj) => ({
          id: obj.id,
          name: obj.name,
        }));

        setOptions(options);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      })


  };

  const _onChange = (e) => {
    props.onChange(e);
  }


  const filterBy = () => true;



  const allowNew = props.allowNew == 'no' ? false : true;

  return (
    <>
                  { props.title ?  <h5 className={props.addLabelClass}>{props.title} {props.required && <span className="text-danger font-bold">*</span>} </h5>  : ""}
    <AsyncTypeahead
      filterBy={filterBy}
      // id="async-example"
      allowNew={allowNew}
      multiple={true}
      isLoading={isLoading}
      newSelectionPrefix="Add a new item: "
      labelKey="name"
      minLength={1}
      onSearch={handleSearch}
      options={options}
      onChange={_onChange}
      selected={props.defaultSelected || []}
      placeholder="Type to search"
      renderMenuItemChildren={(option, props) => (
        <React.Fragment>
          <div key={"obj-" + option.value} user={option} >
            <div>
              <h6 className="font-bold  m-t-5">{option.name}
              </h6>
            </div>
          </div>
        </React.Fragment>
      )}
    />
      {props.errorMsg && <small class="text-danger errorMsgCustom">{props.errorMsg}</small>}
    </>
  );
};

export default CompanyPs;