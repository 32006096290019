import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Sidemenu from '../sidemenu/Sidemenu';
import OppContentNew from './OppContentNew';
import OpportunityRightpanel from './OpportunityRightpanel';
import Helper from "../../shared/custom";
import ApiHelper from '../../shared/ApiHelper';
import Axios from 'axios';
import store from "../../store";
import { connect } from "react-redux";
import { getMetaData } from "../../actions/metaAction";

import { getSectorData } from "../../actions/businessAction";

class OpportunityDetailsNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            location: props.location,
            metaList: {},
            industries: [],
            types: [],
            lastPageState: null,
            oppDetails: null,
            business: [],

        }


        store.subscribe(() => {
            var storeState = store.getState();
            var localMetaList = this.state.metaList;
            var storeMetaList = storeState.meta.metaList;

            if (
                !localMetaList ||
                JSON.stringify(localMetaList) != JSON.stringify(storeMetaList)
            ) {
                this.setState({ metaList: storeMetaList });
            }

            var localBusiness = this.state.business;
            var storeBusiness = storeState.business.data;
            if (
                !localBusiness ||
                JSON.stringify(localBusiness) != JSON.stringify(storeBusiness)
            ) {
                this.setState({ business: storeBusiness });
            }
        });



        this.setMetaList = this.setMetaList.bind(this);
    }

    componentDidMount() {

        ApiHelper.getMetaList(this.setMetaList);
        ApiHelper.getOpportunityTypes(this.setTypes);

        let lastPageState = this.state.location.state;

        var storeState = store.getState();
        let meta = storeState.meta;
        let business = storeState.business;


        if (Helper.isEmpty(business.data) == true) {
            this.props.getSectorData();
        } else {
            this.setState({ business: business.data }, function () { });
        }

        if (Helper.isEmpty(meta.metaList) == true) {
            this.props.getMetaData();
        } else {
            this.setState({ metaList: meta.metaList });
        }

        /*lastPageState = {
                budgetRange: {value: 44, label: "0 - 100K USD"},
                city: {value: 5638, label: "Sakakah"},
                country: {value: "170", label: "Saudi Arabia"},
                deadline: "Thu Feb 21 2019 19:43:32 GMT+0400 (Gulf Standard Time)",
                industry: {value: 144, label: "Integrated Telecommunication Services"},
                subType: {value: 2, label: "Single Transaction Sub Type"},
                title: "Title of Opportunity",
                type:{
                        id: 1, 
                        name: "Single Transaction", 
                        description: "Single transaction details goes here", 
                        image: "opportunity/type/1.jpg", 
                        subTypes: [
                                    {id: 2, name: "Single Transaction Sub Type", description: null, image: null, subTypes: null},
                                    {id: 3, name: "Single Transaction Sub Type 2", description: null, image: null, subTypes: null}
                                ]
                    },
                industryList: [
                    {id: 150, name: "Movies & Entertainment", image: null, reviewRequired: false},
                    {id: 145, name: "Wireless Telecommunication Services", image: null, reviewRequired: false},
                    {id: 151, name: "Interactive Home Entertainment", image: null, reviewRequired: false}
                ]
            }*/

        let uri = this.props.match.params.uri;
        if (!uri) {
            this.props.history.push({
                pathname: '/create-opportunity/start'
            });
        }

        if (uri == "new") {
            //new opportunity
            lastPageState = this.state.location.state;
            if (!lastPageState || !lastPageState.country.value) {
                this.props.history.push({
                    pathname: '/create-opportunity/start'
                });
            }
            this.setState({
                lastPageState: lastPageState
            })

        } else {

            Axios.get(
                Helper.apiURL({
                    uri: "opportunity/:uri/details",
                    pathVar: { uri: uri }
                })
            )
                .then((response) => {
                    let opportunity = response.data.result || null;
                    if (!opportunity) {
                        Helper.pushTError("OPP_NOT_FOUND");
                    } else {

                        this.setState({
                            oppDetails: opportunity
                        })
                    }
                })
                .catch((err) => {
                    Helper.pushTError("OPP_NOT_FOUND");
                    this.props.history.push("/opportunities");
                });

        }

    }

    setMetaList(metaList) {
        this.setState({ metaList: metaList });
    }

    setTypes(types) {
        this.setState({ types: types });
    }

    render() {
        let lastPageState = this.state.lastPageState;
        let oppDetails = this.state.oppDetails || null;
        let business = this.state.business;
        let industryList = business ? business.industriesArray : [];

        return (
            <div>
                <div className="container-fluid">
                    <div className="row content-in m-t-80 p-t-10">
                        <div className="col-lg-3 col-xlg-3 col-md-3 searchMenu sideMenuCont">
                            <Sidemenu mode="opportunity" />
                        </div>
                        <div className="col-lg-6 col-xlg-6 col-md-6 centerDiv">
                            <OppContentNew lastPageState={lastPageState} oppDetails={oppDetails} industryList={industryList} />
                        </div>
                        <div className="col-lg-3 col-xlg-3 col-md-3 m-t-2 right-side">
                            <OpportunityRightpanel lastPageState={lastPageState} oppDetails={oppDetails} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    null,
    { getMetaData, getSectorData }
)(withRouter(OpportunityDetailsNew));