import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Popup from "reactjs-popup";
import config from '../../shared/config';
import ReadMoreAndLess from 'react-read-more-less';
import EditTextArea from '../ui/EditTextArea';
import Helpers from '../../shared/custom';
import Axios from 'axios';
import MyPopup from '../ui/MyPopup';
import ApiHelper from "../../shared/ApiHelper";
import Upload from '../opportunity/Upload';
import store from '../../store';
import { LoadingOverlay, Loader } from 'react-overlay-loader';

class PostPopup extends Component {

  constructor(props){
      super(props);
      this.state = {
        auth:{},
        editPost:true,
        body:"",
        imas:[],
        images:[],
        open: false,
        publishedVideo:null,
        timeout:null,
        uniqueId:"",
        loading:false,
      }

      this.openModal = this.openModal.bind(this);
      this.closeModal = this.closeModal.bind(this);
      this.uploadImg = this.uploadImg.bind(this);
      this.uploadPostPhoto = this.uploadPostPhoto.bind(this);
      this.handleFieldValueChange = this.handleFieldValueChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleUpload = this.handleUpload.bind(this);
      this.handleResponse = this.handleResponse.bind(this);
      this.handleVideoDelete = this.handleVideoDelete.bind(this);
      this.handleThumbnailGrabbing = this.handleThumbnailGrabbing.bind(this);
      this.renderThumbnail = this.renderThumbnail.bind(this);

      //    let handleUpload = this.props.handleUpload;
      

      /*if (props.uniqueId){
        this.setState({ uniqueId: props.uniqueId });  
        this.populatePost();   
      }*/
  }
  
  componentWillReceiveProps(props){
    let storeState = store.getState();
    let auth = storeState.auth;
    this.setState({
        open:props.open,
        auth:auth,
        uniqueId:props.uniqueId
    })
  }

  handleVideoDelete(e){
    this.setState({ publishedVideo: null });
  }


  handleThumbnailGrabbing(target){
    if (Helpers.isEmpty(target))
        return;

    let query = {q: target, key: config.thumbnailFetchingKey};
    let thumbnail = "";
    Axios.get(
      Helpers.apiURL({
          uri:config.thumbnailFetchingEndpoint,
          dataType: 'jsonp',
          query:query
      })
      )
      .then((response) => {
          let thumbnailData = response.data;
          let publishedVideo = {
            'image': thumbnailData.image,
            'description': thumbnailData.description,
            'title': thumbnailData.title,
            'videoUrl':target
          }
          this.updateVideoThumbnail(publishedVideo);
      })
      .catch((err) => {
          //this.handleResponse("error",err);
      });
  }


  updateVideoThumbnail(publishedVideo){
    this.setState({ publishedVideo: publishedVideo });
  }

  resetPopup(){
    this.setState({ 
        body:"",
        imas:[],
        images:[],
        publishedVideo:null,
        uniqueId:""
    });
    this.closeModal(); 
  }

  openModal (){
    let added = false;
    this.props.postAdded(added);
    this.setState({ open: true })
  }

  closeModal () {
    let added = false;
    this.props.postAdded(added);
    this.setState({ open: false },()=>{
      if (this.props.onClose)
         this.props.onClose();
    });
   
  }

  handleResponse(type,response){
    if(type == "success"){
        let result  = response.data.result;
        this.setState({
            uniqueId:result.uniqueId
        });
        Helpers.pushTSuccess("POST_PUBLISHED");
    }else{
        Helpers.pushTError("POST_NOT_PUBLISHED");
    }
  }

  validateForm(){
    let isValid = true;
    let message = [];

    if(Helpers.isEmpty(this.state.body)){
        isValid = false;
        message.push("BODY_REQUIRED");
    }

    if(message.length > 0){
        Helpers.pushTError(message);
    }
    return isValid;
  }

  populatePost(){
    let uniqueId = this.state.uniqueId;
    Axios.get(
        Helpers.apiURL({
            uri:"post/"+uniqueId+"/details"
        })
    )
    .then((response) => {
        let postToEdit = response.data.result;
        this.setState({
            "body":postToEdit.body,
            "imas": postToEdit.images,
            "publishedVideo": {"videoUrl":postToEdit.videos[0]}
        });
        this.renderThumbnail("body", this.state.body, true);
    })
    .catch((err) => {
        this.handleResponse("error",err);
    });
  }
   
  uploadImg(e) {
        this.refs.imgUploader.click();
  }

  handleSubmit(){

    let isValid = this.validateForm();
    if(!isValid){
        return false;
    }     

    let payload = {
      body : this.state.body
    }

    let imas = this.state.imas;
    let images = [];
    imas.forEach(ima => {
        images.push({uniqueId:ima.uniqueId})
    });
    payload.images = images;
    
    let videos = [];
    if (this.state.publishedVideo){
        let toBePublished = this.state.publishedVideo.videoUrl;
        videos.push(toBePublished);
    }
    payload.videos = videos;
  
    this.setState({loading:true});
    if(this.state.uniqueId){
        //edit
        let uniqueId = this.state.uniqueId;
        payload.uniqueId = this.state.uniqueId
        Axios.put(
            Helpers.apiURL({
                uri:"post/"+uniqueId+"/edit"
            }),
            payload
        )
        .then((response) => {
            this.setState({loading:false})
            this.handleResponse("success",response);
            this.resetPopup();
            
        })
        .catch((err) => {
            this.setState({loading:false})
            this.handleResponse("error",err);
        });
    }else{
        //new
        this.setState({loading:true});
        Axios.post(
            Helpers.apiURL({
                uri:"post"
            }),
            payload
        )
        .then((response) => {
            this.setState({loading:false});
            let added = true;
            this.props.postAdded(added);
            this.handleResponse("success",response);
            this.resetPopup();
        })
        .catch((err) => {
            this.setState({loading:false})
            this.handleResponse("error",err);
        });
    }
   
    
  }


  uploadPostPhoto(e){
    let file = e.target.files?e.target.files[0]:null;
    if(file){
        ApiHelper.uploadFile({
            repoType:"POST_IMAGES",
            fileType:"IMAGE",
            file:file,
            callback: (result) =>{
                this.setState({
                    coverPhoto:result.uniqueId,
                    coverPhotoUrl:Helpers.viewFile(result.uniqueId)
                })
            }
        });
    }    
  }

  handleUpload(itemKey,newItems){
    this.setState({[itemKey]:newItems});
  }

  renderThumbnail(name, value, forceRendering){
    forceRendering = (Helpers.isEmpty(forceRendering))?false:true;
    clearTimeout(this.state.timeout);
    let instance = this;
    if(name){
        this.setState({[name]:value});
        this.state.timeout = setTimeout(function() {
            let target = Helpers.grabFirstLink(value);
            if (forceRendering || Helpers.isEmpty(instance.state.publishedVideo) || (target != instance.state.publishedVideo.videoUrl))
                instance.handleThumbnailGrabbing(target);
        }, 500);
    }
  }

  handleFieldValueChange(e){      
    let name = e.target.name;
    let value = e.target.value;
    this.renderThumbnail(name, value);

  }

  render() { 
    let isEditable = true;
    let auth = this.state.auth;
    let userImage = auth.user && auth.user.image ? auth.user.image : config.defaultUserImage;
    let loading= this.state.loading;

    return (
        <MyPopup 
        open={this.state.open}
        onClose={this.closeModal}
        content={
                  <div>
                      <h2 className="text-center"> <img src={Helpers.dataPath(userImage)} className="rounded-img img-circle" width="50"/></h2>
                      <div className="clearfix"></div>
                      { isEditable && this.state.editPost?
                          <div className="">
                            <EditTextArea value={this.state.body} name="body" title="" placeholder="Write a Post..." onValueChange={this.handleFieldValueChange} additionalClassName="borderless-textareas" maxlength="512"/>
                            {this.state.publishedVideo ?
                                <div className="image-in-text">
                                    <img className="post-image" src={this.state.publishedVideo.image}/>
                                    <div className="post-text">
                                    {this.state.publishedVideo.description}
                                    </div>
                                    {isEditable? <div className="col-md-1 font-12 float-right text-right " ><button className="text-inverse point btn-plain fa fa-times" onClick={this.handleVideoDelete}></button></div> : ""}
                                </div>
                            :
                            ''
                            }
                          </div>
                      :      
                          <div>
                              <h6></h6>                  
                              <ReadMoreAndLess
                                  ref={this.readMore}
                                  className="read-more-content"
                                  charLimit={config.summaryLimit}
                                  readMoreText="Read more"
                                  readLessText="Read less"
                              >
                              {this.state.body?this.state.body:""}
                              </ReadMoreAndLess>   
                          </div>
                      }
                      <div className="clearfix"></div>
                      <hr/>
                      <div className="col-lg-12 p-0">
                        <div className="col-lg-10 p-0 float-left text-left">
                            <div>
                                <Upload 
                                        title = ""
                                        description = ""
                                        chooseFileLabel = " "
                                        noItemsUploadedLabel = " "
                                        icon=""                           
                                        items={this.state.imas}
                                        itemType="image"
                                        chooseFileLabelAsHtml={
                                            <figure className="float-left post-image" >
                                                <img src={config.cdn +'theme/images/default-photo.png'} alt="Publish a Photo" width="75"/>
                                                <figcaption>Add Photo</figcaption>
                                            </figure>
                                        }
                                        itemKey="imas"
                                        itemRepoType="POST_IMAGES"
                                        itemChange={this.handleUpload}                            
                                        isEditable={isEditable}  
                                    />
                            </div>
                        </div>
                        <div className="col-lg-2 p-0 float-right text-right">
                          <button className="btn btn-info m-t-10 m-b-10"  name="get-started"  onClick={this.handleSubmit} >Publish</button>
                        </div>
                      </div>
                </div>
        }
    >
     <LoadingOverlay>
             <Loader fullPage loading={loading}/>
     </LoadingOverlay>
    </MyPopup>
    );
  }
}

export default (withRouter(PostPopup));