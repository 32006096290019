import { GET_SECTOR_DATA  , GET_ERRORS } from './types';
import Axios from 'axios';
import config from '../shared/config';

export const getAllSectorData = () => dispatch =>{
    Axios.get(config.api("api/gics/sectors"))
    .then( (response) => {
        const dataset  = response.data.result; 
        dispatch({
            type: GET_SECTOR_DATA,
            payload:dataset,
        })
               
    })
    .catch((err) => {
        dispatch({
            type: GET_ERRORS,
            payload: err
        })
    });
}