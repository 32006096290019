import React, { Component } from 'react';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import Helpers from "../../shared/custom";
import ApiHelper from "../../shared/ApiHelper";
import { getMetaData } from '../../actions/metaAction';
import { connect } from 'react-redux';
import Axios from "axios";
export class Upload extends Component {
    constructor(props){
        super(props);
        this.state ={
            loading:false,
            profilePicUrl:"",
            metaDocs:{},
            metaList:{},
            uploadedDocs:[],
            docByKey:{},
            actionLoading:false,
            uri:null,
            profilePic:null,
            title:null,
            firstName:null,
            lastName:null,
            nationalId:null,
            dob:null,
            mobileCode:null,
            mobile:null,
            email:null,
            confirmEmail:null,
            country:null,
            regions:null,
            summary:null,
            professionalBody:null,
            professionalBodyFull:null,
            qualification:null,
            regNo:null,
            psl:null,
            facebook:null,
            twitter:null,
            linkedIn:null,
            paid:false,
            verificationStatus:null
        }
    }

    componentDidMount(){
        let uri = Helpers.getQueryParameter("uri");
        this.setState({uri:uri})
        this.props.getMetaData();
        this.fetchIndividual(uri);
        this.fetchMeta()
    }


    fetchMeta= ()=>{
        Axios.get(
          Helpers.apiURL({
              uri:"meta/list/PPLDOC",
            })
        )
        .then( (response) => {
            let metaDocs = response.data.result || {};
            this.setState({
                metaDocs:metaDocs
            })
            
        })
        .catch((err) => {
           
        });
      }


    fetchIndividual =(uri)=>{
        Axios.get(
            Helpers.apiURL({
                uri:`people/:uri/details`,
                pathVar:{uri}
            })
        )
        .then( (response) => {     
            let result = response.data.result;
          
           
           this.setState({
                title:result.title,
                firstName:result.firstName,
                lastName:result.lastName,
                nationalId:result.nationalId,
                dob:result.dob,
                mobileCode:result.mobileCode,
                mobile:result.mobile,
                email:result.email,
                confirmEmail:result.email,
                country:{"id":result && result.country && result.country.id ? result.country.id : null},
                regions:!Helpers.isEmpty(result.regions) ? result.regions.map((item)=>{
                    return{
                        id:item.id,
                        name:item.name
                    }
                }) : [],
                summary:result.summary,
                otherProfessionalBodies:!Helpers.isEmpty(result.otherProfessionalBodies) ? result.otherProfessionalBodies :null,
                professionalBody:!Helpers.isEmpty(result.professionalBody) ? [{"id": result.professionalBody[0].id}] :null,
                professionalBodyFull:!Helpers.isEmpty(result.professionalBody) ?  result.professionalBody[0] :null,
                profQualifications:result.profQualifications,
                regMembershipNo:result.regMembershipNo,
                psl:result.psl,
                facebook:result.facebook,
                twitter:result.twitter,
                linkedIn:result.linkedIn,
                documents:result.documents,
                profilePic:result.profilePic,
                profilePicUrl: !Helpers.isEmpty(result.profilePic) ? Helpers.viewFile(result.profilePic.uniqueId) : null,
                paid:result.pageDto.paid,
                regStatus:result.pageDto.regStatus
           },()=>{
               
           })

           

            let data = result && result.documents ? result.documents :   [];
            let docByKey = [];
            var obj={};
            var others = [];
            if(data){
                data.forEach((item,i)=>{
                    if(item && item.docType && item.docType.shortName){
                        if(item.docType.shortName != "PPL_OTHERS"){
                            obj[item.docType.shortName] = item;
                            docByKey.push(obj)
                        }else{
                            others.push(item);
                            obj[item.docType.shortName]  = others;
                        }
                    }
                })
            }

            this.setState({
                uploadedDocs:data,
                docByKey:docByKey
            })

        })
        .catch((err) => { 
           
        });
    }

    getProfile=()=>{
        let userInfo = localStorage && localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : null;
        let uri = userInfo && userInfo.uri ? userInfo.uri : null;
        if(uri){
            Axios.get(
                Helpers.apiURL({
                uri: "user/:uri/profile",
                pathVar: { uri: uri }
              })
            )
              .then(response => {
                let profileDetails = response.data.result || null;
                if (!Helpers.isEmpty(profileDetails)) {
                  localStorage.setItem("profile",JSON.stringify(profileDetails)); 
                  
                  this.setState(
                    {
                      profileDetails: profileDetails
                    },
                    function() {
                      
                    }
                  );
                } else {
                  this.setState({
                    profileDetails: null
                  });
                }
              })
              .catch(err => {
                Helpers.pushTError("PROFILE_NOT_FOUND");
              });
        }
      
      }

    saveAndProceed = ()=>{
        let payload = {
            title:this.state.title,
            firstName:this.state.firstName,
            lastName:this.state.lastName,
            country:this.state.country,
            regions:this.state.regions,
            nationalId:this.state.nationalId,
            dob:this.state.dob,
            mobileCode:this.state.mobileCode,
            mobile:this.state.mobile,
            email:this.state.email,
            
            summary:this.state.summary,
            professionalBody:this.state.professionalBody,
            profQualifications:this.state.profQualifications,
            regMembershipNo:this.state.regMembershipNo,
            facebook:this.state.facebook,
            twitter:this.state.twitter,
            linkedIn:this.state.linkedIn,
            pageDto:{"uri":this.state.uri}
            //careerHistory:careerHistory,
            //educationHistory:educationHistory,
        };

   

        if(this.state.professionalBodyFull && this.state.professionalBodyFull.name.toLowerCase() == "other"){
            payload.otherProfessionalBodies = this.state.otherProfessionalBodies;
        }

        let psl = this.state.psl;
        if(!Helpers.isEmpty(psl)){
            var pslVal = psl.map((item)=>{
                 if(item.customOption){
                    return{
                        id:null,
                        name:item.name
                    }
                 }else{
                    return{
                        id:item.value,
                        name:item.name
                    }
                 }
                
            })
            payload.psl = pslVal;
        }


        var files = this.state.uploadedDocs;

      

        if(!Helpers.isEmpty()){
            files.map((item,i)=>item.uniqueId);
        }

      
        payload.documents =  !Helpers.isEmpty(files) ? files.map((x)=>{
            return {
                "uniqueId":x.uniqueId, 
                "docType":{"id":x && x.docType && x.docType.id ? x.docType.id :null}}
        }):[];

        let profilePic = this.state.profilePic;

        if(!Helpers.isEmpty(profilePic)){
            payload.profilePic = {
                uniqueId:profilePic.uniqueId,
                publicUri:profilePic.uri,
                name:profilePic.name
            };
        }

        this.setState({
            actionLoading:true
        })
     

        Axios.put(
            Helpers.apiURL({
                uri: "people/:uri/edit",
                pathVar: { uri: this.state.uri }
            }),
            payload
          )
          .then(response => {
            this.setState({
                actionLoading: false
            });
            let regStatus =  this.state.regStatus;
            Helpers.pushTSuccess("Profile details saved successfully.");
            this.getProfile();
            if(this.state.paid){
                this.props.history.push({pathname:"/", notificationPeople:true});
            }else{
                window.location.replace("/proceed?type=premium_plus_monthly&paymentstatus=true")
            }
           
         
          })
          .catch(err => {
            this.setState({
                actionLoading: false
            });
            Helpers.pushError(err.response.data.message)
           // Helpers.handleError(err.response);
          });

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(JSON.stringify(nextProps) != JSON.stringify(prevState.localProps)){
          let localMetaList = JSON.parse(localStorage.getItem("metaList"));
          if(!Helpers.isEmpty(localMetaList)){
            prevState.metaList = localMetaList;
            //prevState.metaDocs = localMetaList.PPLDOC
            return {prevState}
          }
        }
    }

    
    uploadDocs = (e) =>{
        let file = e.target.files ? e.target.files[0] : null;

        let docType = e.target.getAttribute("data-id");
        this.setState({actionLoading:true})
        if (file) {
          ApiHelper.uploadFile({
            repoType: "PAGE_DOCUMENTS",
            docType:docType,
            fileType: "DOC",
            file: file,
            callback: result => {
              let newFile = this.state.uploadedDocs;

              if(result.docType.name != "Others"){
                 newFile = newFile.filter((item)=> !Helpers.isEmpty(item.docType) && item.docType.id !=  docType);
              }
              newFile.push(result)

           
              this.setState({
                  files:newFile,
                  uploadedDocs:newFile
              },()=>{
                 //this.props.uploadedFile(newFile);
                 let docByKey = [];
                    var obj={};
                    var others = []
             
                    if(newFile){
                        newFile.forEach((item,i)=>{
                            if(item && item.docType && item.docType.shortName){
                                if(item.docType.shortName != "PPL_OTHERS"){
                                    obj[item.docType.shortName] = item;
                                    docByKey.push(obj)
                                }else{
                                    others.push(item);
                                    obj[item.docType.shortName]  = others;
                                }
                            }
                        })
                    }

                 this.setState({actionLoading:false,docByKey:docByKey,})
              });
             
            },
            errCallback:err=>{
                this.setState({actionLoading:false})
            }
          });
        }else{
            this.setState({actionLoading:false})
        }
      }

      deleteDoc = (e)=>{
        e.stopPropagation();
        let id = e.currentTarget.attributes.getNamedItem('data-id').value;
        let mainId = e.currentTarget.attributes.getNamedItem('data-mainId').value;
        this.setState({actionLoading:true})
        Axios.delete(
            Helpers.apiURL({ 
                uri: `api/page-documents/delete-by-unique-id/${id}`
            }),{
               // headers: { 'Content-Type': 'text/plain' }
                }
        ).then(response => {
                //document.getElementById(id).value = "";
                let newFile = this.state.uploadedDocs;
                let newFileFilter =  newFile.filter((el)=>{
                    return el.uniqueId  !== id;
                });

              
              //  

                let docByKey = [];
                var obj={};
                var others = [];
                if(newFileFilter){
                    newFileFilter.forEach((item,i)=>{
                        if(item && item.docType && item.docType.shortName){
                            if(item.docType.shortName != "PPL_OTHERS"){
                                obj[item.docType.shortName] = item;
                                docByKey.push(obj)
                            }else{
                                others.push(item);
                                obj[item.docType.shortName]  = others;
                            }
                        }
                    })
                }
               
                this.setState({
                    actionLoading:false,
                    uploadedDocs:newFileFilter,
                    docByKey:docByKey
                });
                //this.props.uploadedFile(newFileFilter);
                Helpers.pushSuccess("File deleted successfully");
               
                //e.target.value = "";
            })
            .catch(err => {
                Helpers.pushError("Cannot delete at the moment!");
                this.setState({actionLoading:false})
            });
     }

     addMore = ()=>{
         
        let metaDocs  = this.state.metaDocs;
        var newDoc = {
            description: "",
            id: null,
            image: "",
            name: "",
            orderBy: 1,
            shortName: "PPL_OTHERS",
            status: null,
            type: "PPLDOC"
        }

        metaDocs.push(newDoc)
        this.setState({
            metaDocs:metaDocs
        })

     }
    //  getAllFiles = ()=>{
    //     var files = this.state.uploadedDocs;
    //     if(!Helpers.isEmpty()){
    //         files.map((item,i)=>item.uniqueId);
    //     }
    //   //  this.props.uploadedFile(files);
    // }

    uploadProfileImage = (e) =>{
        this.setState({
            actionLoading:true
        })
        let file = e.target.files ? e.target.files[0] : null;
        if (file) {
          ApiHelper.uploadFile({
            repoType: "COMPANY_IMAGES",
            fileType: "IMAGE",
            file: file,
            callback: result => {
              this.setState({
                profilePic: result,
                profilePicUrl: Helpers.viewFile(result.uniqueId),
                actionLoading:false
              });
            }
          });
        }
      }

      handleSubmit = ()=>{

      }

      triggerProfileImageUpload = (e) =>{
        this.refs.imgUploader.click();
      }
    

    render() {
        let { loading } = this.state;
        let { isEditable , uploadedDocs , files , actionLoading , metaDocs , docByKey ,paid} = this.state;
        docByKey = docByKey && !Helpers.isEmpty(docByKey[0]) ? docByKey[0] :[];

        console.log(metaDocs,"docByKeydocByKeydocByKey")

        //console.log(this.state,"actionLoading")
       // let profilePicUrl = !Helpers.isEmpty(this.state.profilePic) ? Helpers.viewFile(this.state.profilePic.uniqueId) : null;
        
        return (
            <div className="container-fluid"> 
                <LoadingOverlay>
                    <Loader fullPage loading={actionLoading}/>
                </LoadingOverlay>
                <div className="row content-in m-t-80 p-t-10">
                    <div className="row full-width bold-headers">
                            <div className="col-md-12 text-left m-t-20 m-b-20"> 
                            <div className="col-md-12">
                                <h1 className="font-22">Premium Plus <i className="font-16">(for Professionals)</i></h1>
                            </div>     
                        </div>
                    </div>
                    <div className="col-md-12"> 
                        <div className="card">
                            <div className="card-body text-left">
                              <h5 className="m-t-0">Documents Upload</h5>
                              {!Helpers.isEmpty(metaDocs) && 
                                        metaDocs.map((item,i)=>(
                                            <div className="docUploadFields clearfix m-t-30 col-md-6 p-0" key={i}>
                                                <h5 className="font-14">{item.shortName == "PPL_OTHERS"  ? "Additional Documents" : item.name}{item.shortName != "PPL_OTHERS" &&  <span style={{color:"red"}}>*</span>}</h5>
                                                <label className="custom-file-upload">
                                                  <input type="file" 
                                                            id={item.id} 
                                                            name="docUploader" 
                                                            value=""
                                                            data-id={item.id} 
                                                            className="docUploader" 
                                                            ref={item.shortName} 
                                                            onChange={this.uploadDocs}
                                                            accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"/> 
                                                </label>
                                               {
                                                !Helpers.isEmpty(docByKey) &&  docByKey[item.shortName] ? 
                                                    item.shortName == "PPL_OTHERS" ? 

                                                         docByKey[item.shortName].map((list,i)=>(
                                                            <div key={list.uniqueId} id={list.uniqueId}> 
                                                                <a href={Helpers.viewFile(list.uniqueId)} target="_blank">{list.name}</a>
                                                                <a key={list.uniqueId+"trigger"} data-id={list.uniqueId} data-mainId={list.id}
                                                                        onClick={this.deleteDoc}
                                                                        className="text-danger m-l-5"
                                                                        >
                                                                        <i className="fa fa-times" />
                                                                        </a>
                                                            </div>
                                                         ))
                                                        :
                                                        <div key={item.uniqueId} id={item.uniqueId}> 
                                                            <a href={Helpers.viewFile(docByKey[item.shortName].uniqueId)} target="_blank">{docByKey[item.shortName].name}</a>
                                                            <a key={docByKey[item.shortName].uniqueId+"trigger"} data-id={docByKey[item.shortName].uniqueId} data-mainId={docByKey[item.shortName].id}
                                                                    onClick={this.deleteDoc}
                                                                    className="text-danger m-l-5"
                                                                    >
                                                                    <i className="fa fa-times" />
                                                                    </a>
                                                        </div>

                                                        :
                                                        ""
                                                }
                                            </div>
                                        ))
                                      
                                    }

                                    {/* {

                                    } */}
                                    {/* <div className="col-md-12 p-0 font-14">
                                        <a onClick={this.addMore}><i className="fa fa-plus"></i>&nbsp;Add More</a>
                                    </div> */}
                                    <div className="m-t-50 col-md-12 p-0">
                                    <h6 className="">Photo<span style={{color:"red"}}>*</span></h6>
                                        <a
                                        href="javascript:void(0)"
                                            onClick={this.triggerProfileImageUpload}
                                            >
                                            {Helpers.isEmpty(this.state.profilePicUrl) ? (
                                                <div className="float-left newImgCard">
                                                <i className="fa fa-camera text-black m-t-20 font-40 " />
                                                <div className="font-10">Upload photo</div>
                                                </div>
                                            ) : (
                                                <img
                                                src={this.state.profilePicUrl}
                                                className="float-left newImgCard"
                                                width="100"
                                                />
                                            )}
                                            <input
                                                type="file"
                                                id="userImg"
                                                name="userImg"
                                                style={{ display: "none" }}
                                                ref="imgUploader"
                                                onChange={this.uploadProfileImage}
                                            />
                                            </a>
                                        </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-xs-12 p-0 m-t-10">
                            <button
                                type="submit"
                                className="btn btn-info waves-effect- waves-light- float-right"
                                onClick={this.saveAndProceed}
                            >
                               {paid ?  "Save Profile":"Save and Proceed to Payment"}
                            </button>
                        </div>
                    </div>
                </div>
           </div>
        )
    }
}

// export default Upload;

export default connect(null,{getMetaData})(Upload);