import React, { Component } from 'react';
import Sidemenu from '../sidemenu/Sidemenu';
import { withRouter } from 'react-router-dom';

import config from '../../shared/config';
import ReadMoreAndLess from 'react-read-more-less';
import EditTextArea from '../ui/EditTextArea';
import Helpers from '../../shared/custom';
import Axios from 'axios';
import ApiHelper from "../../shared/ApiHelper";
import Upload from '../opportunity/Upload';
import PostPopup from './PostPopup';

class AddPost extends Component {
  
  constructor(props){
    super(props);
    this.state = {
      open: false,
    }

    this.openModal = this.openModal.bind(this);
    // this.closeModal = this.closeModal.bind(this);
    // this.uploadImg = this.uploadImg.bind(this);
    // this.uploadPostPhoto = this.uploadPostPhoto.bind(this);
    // this.handleFieldValueChange = this.handleFieldValueChange.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
    // this.handleResponse = this.handleResponse.bind(this);
    // this.handleVideoDelete = this.handleVideoDelete.bind(this);
    // this.handleThumbnailGrabbing = this.handleThumbnailGrabbing.bind(this);
    // this.renderThumbnail = this.renderThumbnail.bind(this);

    // this.handleUpload = this.handleUpload.bind(this);
    

    // if (props.uniqueId){
    //   this.setState({ uniqueId: props.uniqueId });  
    //   this.populatePost();    
      
    // }
}

  openModal() {
    this.setState({ open: true }, function () {
      this.setState({ open: true });
    })
    
  }


// handleVideoDelete(e){
//   this.setState({ publishedVideo: null });
// }


// handleThumbnailGrabbing(target){
//   if (Helpers.isEmpty(target))
//       return;

//   let query = {q: target, key: config.thumbnailFetchingKey};
//   let thumbnail = "";
//   Axios.get(
//     Helpers.apiURL({
//         uri:config.thumbnailFetchingEndpoint,
//         dataType: 'jsonp',
//         query:query
//     })
//     )
//     .then((response) => {
//         let thumbnailData = response.data;
//         let publishedVideo = {
//           'image': thumbnailData.image,
//           'description': thumbnailData.description,
//           'title': thumbnailData.title,
//           'videoUrl':target
//         }
//         this.updateVideoThumbnail(publishedVideo);
//     })
//     .catch((err) => {
//         //this.handleResponse("error",err);
//     });
// }


// updateVideoThumbnail(publishedVideo){
//   this.setState({ publishedVideo: publishedVideo });
// }

// resetPopup(){
//   this.setState({ 
//       body:"",
//       imas:[],
//       images:[],
//       publishedVideo:null,
//       uniqueId:""
//   });
//   this.closeModal(); 
// }

// closeModal () {
//   this.setState({ open: false })
// }

// handleResponse(type,response){
//   if(type == "success"){
//       let result  = response.data.result;
//       this.setState({
//           uniqueId:result.uniqueId
//       });
//       Helpers.pushTSuccess("POST_PUBLISHED");
//   }else{
//       Helpers.pushTError("POST_NOT_PUBLISHED");
//   }
// }

// validateForm(){
//   let isValid = true;
//   let message = [];

//   if(Helpers.isEmpty(this.state.body)){
//       isValid = false;
//       message.push("BODY_REQUIRED");
//   }

//   if(message.length > 0){
//       Helpers.pushTError(message);
//   }
//   return isValid;
// }

// populatePost(){
//   let uniqueId = this.state.uniqueId;
//   Axios.get(
//       Helpers.apiURL({
//           uri:"post/"+uniqueId+"/details"
//       })
//   )
//   .then((response) => {
//       let postToEdit = response.data.result;
//       this.setState({
//           "body":postToEdit.body,
//           "imas": postToEdit.images,
//           "publishedVideo": {"videoUrl":postToEdit.videos[0]}
//       });
//       this.renderThumbnail("body", this.state.body, true);
//   })
//   .catch((err) => {
//       this.handleResponse("error",err);
//   });
// }
 
// uploadImg(e) {
//       this.refs.imgUploader.click();
// }

// handleSubmit(){

//   let isValid = this.validateForm();
//   if(!isValid){
//       return false;
//   }     

//   let payload = {
//     body : this.state.body
//   }

//   let imas = this.state.imas;
//   let images = [];
//   imas.forEach(ima => {
//       images.push({uniqueId:ima.uniqueId})
//   });
//   payload.images = images;
  
//   let videos = [];
//   if (this.state.publishedVideo){
//       let toBePublished = this.state.publishedVideo.videoUrl;
//       videos.push(toBePublished);
//   }
//   payload.videos = videos;
  
//   if(this.state.uniqueId){
//       //edit
//       let uniqueId = this.state.uniqueId;
//       payload.uniqueId = this.state.uniqueId
//       Axios.put(
//           Helpers.apiURL({
//               uri:"post/"+uniqueId+"/edit"
//           }),
//           payload
//       )
//       .then((response) => {
//           this.handleResponse("success",response);
//           this.resetPopup();
//       })
//       .catch((err) => {
//           this.handleResponse("error",err);
//       });
//   }else{
//       //new
//       Axios.post(
//           Helpers.apiURL({
//               uri:"post"
//           }),
//           payload
//       )
//       .then((response) => {
//           this.handleResponse("success",response);
//           this.resetPopup();
//       })
//       .catch((err) => {
//           this.handleResponse("error",err);
//       });
//   }
 
  
// }


// uploadPostPhoto(e){
//   let file = e.target.files?e.target.files[0]:null;
//   if(file){
//       ApiHelper.uploadFile({
//           repoType:"POST_IMAGES",
//           fileType:"IMAGE",
//           file:file,
//           callback: (result) =>{
//               this.setState({
//                   coverPhoto:result.uniqueId,
//                   coverPhotoUrl:Helpers.viewFile(result.uniqueId)
//               })
//           }
//       });
//   }    
// }

// handleUpload(itemKey,newItems){
//   this.setState({[itemKey]:newItems});
// }

// renderThumbnail(name, value, forceRendering){
//   forceRendering = (Helpers.isEmpty(forceRendering))?false:true;
//   clearTimeout(this.state.timeout);
//   let instance = this;
//   if(name){
//       this.setState({[name]:value});
//       this.state.timeout = setTimeout(function() {
//           let target = Helpers.grabFirstLink(value);
//           if (forceRendering || Helpers.isEmpty(instance.state.publishedVideo) || (target != instance.state.publishedVideo.videoUrl))
//               instance.handleThumbnailGrabbing(target);
//       }, 500);
//   }
// }

// handleFieldValueChange(e){      
//   let name = e.target.name;
//   let value = e.target.value;
//   this.renderThumbnail(name, value);

// }




  render() {
    return (
      <div className="col-lg-6 col-xlg-6 col-md-7">
            <br /><br /><br /><br />
            <button className="button" onClick={this.openModal}>
                Controlled Popup
            </button>
            <PostPopup open={this.state.open}></PostPopup>
      </div>
    )
  }
}

export default  (withRouter(AddPost));