import React, { Component } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import Axios from "axios";
import { withRouter, Link } from "react-router-dom";
import config from "../../shared/config";
import Helpers from "../../shared/custom";
import ApiHelper from "../../shared/ApiHelper";

import EditableFields from "./EditableFields";
import Videos from "../opportunity/Videos";
import Upload from "../opportunity/Upload";

import InfoText from "../ui/InfoText";
import InfoDate from "../ui/InfoDate";
import store from "../../store";
import EditDate from "../ui/EditDate";
import ReadMoreAndLess from "react-read-more-less";
import InfoAddress from "../ui/InfoAddress";

import EditText from "../ui/EditText";
import EditTextArea from "../ui/EditTextArea";
import EditSelect from "../ui/EditSelect";
import countries from "../../shared/country";
import SweetAlert from "react-bootstrap-sweetalert";
import ExternalLink from "../ui/ExternalLink";
import CustomArray from "../ui/CustomArray";
import EditMultiSelect from "../ui/EditMultiSelect";
import InfoTextArray from "../ui/InfoTextArray";

class MainContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uniqueId: null,
      business: {},
      industryList: [],
      editBasicDetails: false,
      mode: "create",
      editMainDetails: false,
      editCoverPhoto: false,
      editSummary: false,
      editSpeakers: false,
      speakers: "",
      ownerName: "",
      //newCity: "",
      auth: {},
      metaList: [],
      isEditable: false,
      confirmDelete: false,

      address: "",
      country: undefined,
      city: null,
      newCity:"",
      cityList: [],

      coverPhoto: undefined,
      coverPhotoUrl: undefined,
      email: undefined,
      images: [],

      industry: [],
      eventTitle: "",
      owner: {
        id: "",
        userName: "",
        uri: "",
        fullName: "",
        image: null,
        email: ""
      },
      pageUri: "",
      phoneNumber: "",
      startDate: null,
      endDate: null,
      status: "", //DRAFT
      summary: "",
      type: undefined,
      category: null,
      photos: [],
      videos: [],
      website: "",
      rsvpLink: undefined,
      eventDetails: {},
      addressDto: {
        addressLine1: undefined,
        addressLine2: undefined,
        addressLine3: undefined,
        stateOrProvince: undefined,
        pobox: undefined,
        zip: undefined
      }
    };
    store.subscribe(() => {
      var storeState = store.getState();
      var localBusiness = this.state.business;
      var storeBusiness = storeState.business.data;
      if (
        !localBusiness ||
        JSON.stringify(localBusiness) != JSON.stringify(storeBusiness)
      ) {
        this.setState({ business: storeBusiness });
      }
    });

    this.editBasicDetails = this.editBasicDetails.bind(this);
    this.editMainDetails = this.editMainDetails.bind(this);
    this.editSummary = this.editSummary.bind(this);
    this.editSpeakers = this.editSpeakers.bind(this);
    this.editCoverPhoto = this.editCoverPhoto.bind(this);
    this.uploadImg = this.uploadImg.bind(this);
    this.uploadCoverPhoto = this.uploadCoverPhoto.bind(this);

    this.handleFieldValueChange = this.handleFieldValueChange.bind(this);
    this.handleStartDateValueChange = this.handleStartDateValueChange.bind(
      this
    );
    this.handleEndDateValueChange = this.handleEndDateValueChange.bind(this);

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.setCityList = this.setCityList.bind(this);
    // this.handleAddressChange = this.handleAddressChange.bind(this);
    this.setMetaList = this.setMetaList.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleVideoChange = this.handleVideoChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
  }

  setupForm() {
    // return false;
    if (!Helpers.isEmpty(this.state.uniqueId)) {
      this.setState({
        editBasicDetails: false,
        editMainDetails: false,
        editCoverPhoto: false,
        editSummary: false,
        editSpeakers: false
      });
    } else {
      this.setState({
        editBasicDetails: true,
        editMainDetails: true,
        editCoverPhoto: true,
        editSummary: true,
        editSpeakers: true
      });
    }
  }

  confirmDelete() {
    this.setState({
      confirmDelete: this.state.confirmDelete ? false : true
    });
  }
  // Name, Cover Photo, Email, Open Or Private, Start Date , End Date, Summary
  validateForm() {
    let isValid = true;
    let message = [];

    if (Helpers.isEmpty(this.state.addressDto.addressLine1)) {
      isValid = false;
      message.push("ADDRESS_REQUIRED");
    }

    if (Helpers.isEmpty(this.state.eventTitle)) {
      isValid = false;
      message.push("TITLE_REQUIRED");
    }

    if (Helpers.isEmpty(this.state.coverPhoto)) {
      isValid = false;
      message.push("COVER_PHOTO_REQUIRED");
    }

    if (Helpers.isEmpty(this.state.category)) {
      isValid = false;
      message.push("CATEGORY_REQUIRED");
    }

    if (
      Helpers.isValidDate(this.state.startDate) &&
      Helpers.isValidDate(this.state.endDate) &&
      this.state.endDate < this.state.startDate
    ) {
      isValid = false;
      message.push("ENDDATE_SHOULD_BE_AFTER_STARTDATE");
    }

    if (Helpers.isEmpty(this.state.type)) {
      isValid = false;
      message.push("TYPE_REQUIRED");
    }

    if (Helpers.isEmpty(this.state.email)) {
      isValid = false;
      message.push("EMAIL_REQUIRED");
    } else {
      if (!Helpers.isValidEmail(this.state.email)) {
        isValid = false;
        message.push("INVALID_EMAIL");
      }
    }

    if (Helpers.isEmpty(this.state.summary)) {
      isValid = false;
      message.push("SUMMARY_REQUIRED");
    }

    if (message.length > 0) {
      Helpers.pushTError(message);
    }
    return isValid;
  }

  handleDelete() {
    let uniqueId = this.state.uniqueId;
    if (Helpers.isEmpty(uniqueId)) {
      Helpers.pushTError("NOT_DELETABLE_ITEM");
      this.props.history.push('/events');
      return;
    }
    Axios.delete(
      Helpers.apiURL({
        uri: "event/" + uniqueId + "/delete"
      })
    )
      .then(response => {
        Helpers.pushTSuccess("EVENT_DELETED");
        this.props.history.push('/events');
      })
      .catch(err => {
        Helpers.pushTError("EVENT_DELETE_FAILED");
      });
  }

  handleSubmit() {
    let isValid = this.validateForm();
    if (!isValid) {
      return false;
    }

    var industryIds = [];

    if (!Helpers.isEmpty(this.state.industry)) {
      this.state.industry.forEach((data, i) => {
        industryIds.push({ "id": data.value });
      })
    }

    let payload = {
      address: {
        id: this.state.addressDto.id ? this.state.addressDto.id : null,
        addressLine1: this.state.addressDto.addressLine1,
        addressLine2: this.state.addressDto.addressLine2,
        addressLine3: this.state.addressDto.addressLine3,
        stateOrProvince: this.state.addressDto.stateOrProvince,
        poBox: this.state.addressDto.poBox,
        zip: this.state.addressDto.zip,
        city: this.state.city.value != "other" ? 
        {
          id: this.state.city && this.state.city.value ? this.state.city.value : null
        }
        :
        {
            id: null,
            name:this.state.newCity
        },
        country: { id: this.state.country ? this.state.country.value : null }
      },
      country: { id: this.state.country ? this.state.country.value : null },
      city: this.state.city.value != "other" ? 
      {
        id: this.state.city && this.state.city.value ? this.state.city.value : null
      }
      :
      {
          id: null,
          name:this.state.newCity
      },
      coverPhoto: {
        uniqueId: this.state.coverPhoto ? this.state.coverPhoto : null
      },
      images: { uniqueId: this.state.images ? this.state.images : null },
      email: this.state.email,
      speakers: this.state.speakers,
      endDate: Helpers.getDateAndTimeInputFormat(this.state.endDate),
      startDate: Helpers.getDateAndTimeInputFormat(this.state.startDate),
      name: this.state.eventTitle,
      pageUri: this.state.pageUri,
      industries: industryIds,
      owner: { id: this.state.owner ? this.state.owner.id : null },
      phoneNumber: this.state.phoneNumber,
      status: this.state.status,
      summary: this.state.summary,
      type: this.state.type.value,
      category: this.state.category ? this.state.category.value : "MEETUP",
      videos: this.state.videos,
      website: this.state.website,
      rsvpLink: this.state.rsvpLink,
    };

    var thisProps = this.props;
    let photos = this.state.photos;
    let images = [];
    photos.forEach(photo => {
      images.push({ uniqueId: photo.uniqueId });
    });
    payload.images = images;

    if (this.state.uniqueId) {
      //edit
      let uniqueId = this.state.uniqueId;
      // let pageUri = this.state.pageUri;
      Axios.put(
        Helpers.apiURL({
          uri: "event/" + uniqueId + "/edit"
        }),
        payload
      )
        .then(response => {
          Helpers.pushTSuccess("EVENT_SAVED");
          this.setupForm();
        })
        .catch(err => {
          //Helpers.pushTError("EVENT_NOT_SAVED");
          Helpers.pushTError(err.response.data.message);
        });
    } else {
      payload.status = "DRAFT";

      //new
      Axios.post(
        Helpers.apiURL({
          uri: "event/add"
        }),
        payload
      )
        .then(response => {
          let result = response.data.result;
          this.setState({
            uri: result.uri,
            eventDetails: result,
            pageUri: result.uri,
            uniqueId: result.uniqueId
          });
          if (result.status == "DRAFT")
            Helpers.pushTSuccess("EVENT_SUBMIT_APPROVAL");
          else Helpers.pushTSuccess("EVENT_SAVED");
          //this.setupForm();
          thisProps.history.push("/event-details/" + result.uri);
        })
        .catch(err => {
          Helpers.pushTError(err.response.data.message);
        });
    }
  }

  componentDidMount() {
    let isEditable = true;

    this.setState(
      {
        //    auth:storeState.auth,
        industryList: this.props.industryList,
        isEditable: isEditable
      },
      function () { }
    );
    if (Helpers.isEmpty(this.state.metaList)) {
      ApiHelper.getMetaList(this.setMetaList);
    }
  }

  componentWillReceiveProps(nextProps) {
    let lastPageState = nextProps.lastPageState;
    let eventDetails = nextProps.eventDetails;

    if (lastPageState) {
      this.setState(
        {
          eventTitle: lastPageState.eventTitle,
          industry: lastPageState.industry,
          type: { label: lastPageState.type, value: lastPageState.type },
          country: lastPageState.country,
          pageUri: this.state.pageUri
            ? this.state.pageUri
            : lastPageState.pageUri,
          city: lastPageState.city,
          newCity:lastPageState.newCity,
          startDate: new Date(lastPageState.startDate),
          endDate: new Date(lastPageState.endDate),
          industryList: lastPageState.industryList
        },
        function () {
          this.setupForm();
          if (!Helpers.isEmpty(this.state.country))
            ApiHelper.getCities(this.state.country.value, (list) => { this.setCityList(list); });
        }
      );
    } else if (eventDetails) {
      eventDetails.city = Helpers.makeSelectOption(eventDetails.city);
      eventDetails.country = Helpers.makeSelectOption(eventDetails.country);

      this.setState(
        {
          showForm: true,
          industryList: nextProps.industryList,
          speakers: eventDetails.speakers,
          uniqueId: eventDetails.uniqueId,
          eventTitle: eventDetails.name,
          industry: Helpers.makeSelectOptions(eventDetails.industries),
          type: { label: eventDetails.type, value: eventDetails.type },
          country: Helpers.makeSelectOption(eventDetails.country),
          city: Helpers.makeSelectOption(eventDetails.city),
          startDate: new Date(eventDetails.startDate),
          endDate: new Date(eventDetails.endDate),
          pageUri: eventDetails.uri,
          coverPhoto: eventDetails.coverPhoto.uniqueId,
          coverPhotoUrl: Helpers.viewFile(eventDetails.coverPhoto.uniqueId),
          ownerName: eventDetails.owner ? eventDetails.owner.fullName : null,
          ownerTitle: undefined,
          ownerImage: undefined,
          summary: eventDetails.summary,
          addressDto: eventDetails.address,
          category: { label: eventDetails.category, value: eventDetails.category },
          email: eventDetails.email,
          phoneNumber: Helpers.isEmpty(eventDetails.phoneNumber) ? "" : eventDetails.phoneNumber,
          website: Helpers.isEmpty(eventDetails.website) ? "" : eventDetails.website,
          rsvpLink: Helpers.isEmpty(eventDetails.rsvpLink) ? "" : eventDetails.rsvpLink,
          photos: Helpers.isEmpty(eventDetails.images) ? [] : eventDetails.images,
          videos: eventDetails.videos
        },
        function () {
          this.setupForm();
          if (!Helpers.isEmpty(this.state.country))
            ApiHelper.getCities(this.state.country.value, (list) => { this.setCityList(list); });
        }
      );
    }
  }

  uploadImg(e) {
    this.refs.imgUploader.click();
  }

  editBasicDetails() {
    this.setState({
      editBasicDetails: this.state.editBasicDetails ? false : true
    });
  }

  editSummary() {
    this.setState({
      editSummary: this.state.editSummary ? false : true
    });
  }

  editSpeakers() {
    this.setState({
      editSpeakers: this.state.editSpeakers ? false : true
    });
  }

  editMainDetails() {
    this.setState({
      editMainDetails: this.state.editMainDetails ? false : true
    });
  }

  editCoverPhoto() {
    this.setState({
      editCoverPhoto: this.state.editCoverPhoto ? false : true
    });
  }

  uploadCoverPhoto(e) {
    let file = e.target.files ? e.target.files[0] : null;
    // this.setState({
    //     coverPhotoUrl: URL.createObjectURL(file)
    // })
    if (file) {
      ApiHelper.uploadFile({
        repoType: "EVENT_IMAGES",
        fileType: "IMAGE",
        file: file,
        callback: result => {
          this.setState({
            coverPhoto: result.uniqueId,
            coverPhotoUrl: Helpers.viewFile(result.uniqueId)
          });
        }
      });
    }
  }

  handleFieldValueChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    if (name) {
      switch (name) {
        case "addressLine1":
        case "addressLine2":
        case "addressLine3":
        case "stateOrProvince":
        case "poBox":
        case "zip":
          let addressDto = this.state.addressDto;
          addressDto[name] = value;
          this.setState({ addressDto: addressDto });
          break;
        default:
          this.setState({ [name]: value });
          break;
      }
    }
  }

  handleStartDateValueChange(date) {
    let startDate = new Date(date);

    this.setState({ startDate: startDate }); //Helpers.getDateInInputFormat(, true,config.inputDateTimeFormat)
  }
  handleEndDateValueChange(date) {
    this.setState({ endDate: new Date(date) });
    // this.setState({endDate:Helpers.getDateInInputFormat(date, true,config.inputDateTimeFormat)});
  }

  handleSelectChange(option, e) {
    let thisFieldName = e.name;
    switch (thisFieldName) {
      case "country":
        let cityDropdown = this.refs.cityDropdown;
        // var ev = new Event('select', { bubbles: true});
        // ev.simulated = true;

        ApiHelper.getCities(option.value, this.setCityList);
        this.setState({
          [thisFieldName]: option,
          city: null,
          cityList: []
        });

        break;
      default:
        this.setState({ [thisFieldName]: option });
        break;
    }
  }

  setCityList(cityList) {
    this.setState({ cityList: cityList });
  }

  setMetaList(metaList) {
    this.setState({ metaList: metaList });
  }

  handleUpload(itemKey, newItems) {
    this.setState({ [itemKey]: newItems });
  }

  handleVideoChange(itemKey, newItems) {
    this.setState({ [itemKey]: newItems });
  }

  renderOwner(ownerName) {
    return Helpers.isEmpty(ownerName) ? (
      <span />
    ) : (
      [
        <div className="m-l-5" key={ownerName}>
          - Created by <span className="text-info m-l-5"> {ownerName}</span>
        </div>
      ]
    );
  }

  render() {
    // let isCreation = this.state.mode == "create" ? true : false;
    let eventTitle = this.state.eventTitle;
    let isEditable = this.state.isEditable;
    let coverPhotoUrl = this.state.coverPhotoUrl
      ? this.state.coverPhotoUrl
      : Helpers.dataPath(config.defaultCoverPhoto);

    let countryOptions = Helpers.makeSelectOptions(countries);
    let cityOptions = Helpers.makeSelectOptions(this.state.cityList);
    if (this.state.country && this.state.country.value) {
      cityOptions.push({ value: 'other', label: 'Other' });
    }
    let subTypeOptions = [];
    if (this.state.type) {
      let subtTypes = this.state.type.subTypes || [];
      subTypeOptions = Helpers.makeSelectOptions(subtTypes);
    }

    let metaList = this.state.metaList;

    let categoriesList = this.props.categories;
    let categoriesOptions = [];
    if (categoriesList) {
      categoriesList.forEach(function (item, index) {
        var data = {};
        data.value = item.id;
        data.label = item.name;
        categoriesOptions.push(data);
      });
    }

    let industryOptions = Helpers.makeSelectOptions(this.state.industryList);
    let industryName = this.state.industry ? this.state.industry.name : "";
    let email = this.state.email ? this.state.email : "";
    let categoryLabel = Helpers.isEmpty(this.state.category)
      ? ""
      : Helpers.camelize(this.state.category.label);



    return (
      <div>
        <div className="row bold-headers text-left p-b-30">
          <div className="col-md-12 col-lg-12 col-xlg-12 p-0">
            {Helpers.isEmpty(coverPhotoUrl) ? (
              ""
            ) : (
              <div className="coverPic text-center">
                <img src={coverPhotoUrl} className="img-responsive" />
                {true || this.state.editCoverPhoto ? (
                  <div className="changeCoverPic">
                    <a
                      href="javascript:void(0)"
                      name="coverPhoto"
                      className="text-white"
                      onClick={this.uploadImg}
                    >
                      <i className="fa fa-camera font-40" />
                      <div className="font-10 font-bold">
                        Update cover photo
                      </div>
                    </a>
                    <input
                      type="file"
                      id="coverPhoto"
                      name="coverPhoto"
                      style={{ display: "none" }}
                      ref="imgUploader"
                      onChange={this.uploadCoverPhoto.bind(this)}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}

            <div className="card">
              <div className="card-body banner-xs">
                <div className="col-lg-12 p-0">
                  <div className="col-lg-9 p-0 float-left">
                    <div className="m-t-0">
                      <h2 className="m-b-0 m-t-0">
                        {this.state.editMainDetails ? (
                          <EditText
                            title=""
                            name="eventTitle"
                            value={this.state.eventTitle}
                            placeholder="Enter Event Title"
                            onValueChange={this.handleFieldValueChange}
                            addClass="m-b-5"
                          />
                        ) : (
                          <InfoText title="" text={this.state.eventTitle} />
                        )}
                      </h2>
                      <h6 className="font-normal m-t-0 m-b-2">
                        <div className="horizontalChilds">
                          <InfoDate
                            title=""
                            date={this.state.startDate}
                            isDateTime={true}
                            addClass="m-0"
                          />
                        </div>
                      </h6>
                      <h6 className="font-normal m-t-0 m-b-2 horizontalChilds">
                        {Helpers.camelize(
                          this.state.type && this.state.type.label
                            ? this.state.type.label
                            : ""
                        )}
                        <div className="m-l-5"> Event</div>
                        {this.renderOwner(this.state.ownerName)}
                      </h6>
                      <h6 className="font-normal horizontalChilds m-t-0 m-b-2">
                        <InfoText
                          title=""
                          text={
                            Helpers.isEmpty(this.state.city)
                              ? ""
                              : this.state.city == 'other' ? this.state.newCity : this.state.city.label + ", "
                          }
                        />
                        <InfoText
                          title=""
                          text={
                            Helpers.isEmpty(this.state.country)
                              ? ""
                              : this.state.country.label
                          }
                        />
                      </h6>
                    </div>
                  </div>
                  <div className="col-lg-3 float-right p-0 verticle-h-banner text-right">
                    <div className="m-0">
                      <div>
                        {this.state.editMainDetails ? (
                          <a
                            onClick={this.editMainDetails}
                            className="text-danger"
                          >
                            <i className="fa fa-times" />
                          </a>
                        ) : (
                          <a
                            onClick={this.editMainDetails}
                            className="text-info"
                          >
                            Edit
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clearfix" />
              </div>
            </div>

            {/* card end */}
            <div className="card" id="details">
              <div className="card-body banner-small">
                <div className="col-lg-12 p-0">
                  <div className="col-lg-6 p-0 float-left">
                    <h4>Basic Details</h4>
                  </div>
                  <div className="col-lg-3 float-right p-0  text-right">
                    <div className="m-t-10">
                      <div>
                        {this.state.editBasicDetails ? (
                          <a
                            onClick={this.editBasicDetails}
                            className="text-danger"
                          >
                            <i className="fa fa-times" />
                          </a>
                        ) : (
                          <a
                            onClick={this.editBasicDetails}
                            className="text-info"
                          >
                            Edit
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clearfix" />
                <div className="col-lg-12 p-0">
                  {isEditable && this.state.editBasicDetails ? (
                    <div>
                      <EditMultiSelect
                        title="Industries"
                        name="industry"
                        value={this.state.industry}
                        options={industryOptions}
                        onChange={this.handleSelectChange}
                        additionalClassName="col-6 p-0"
                      />
                      <EditSelect
                        title="Category"
                        name="category"
                        value={
                          Helpers.isEmpty(this.state.category)
                            ? ""
                            : this.state.category
                        }
                        options={[
                          { value: "MEETUP", label: "MEETUP" },
                          { value: "CONFERENCE", label: "CONFERENCE" }
                        ]}
                        onChange={this.handleSelectChange}
                        additionalClassName="col-6 p-0"
                      />

                      <EditText
                        title="Event Venue"
                        name="addressLine1"
                        value={
                          Helpers.isEmpty(this.state.addressDto)
                            ? ""
                            : this.state.addressDto.addressLine1
                        }
                        placeholder="Address Line one (Mandatory)"
                        onValueChange={this.handleFieldValueChange}
                      />
                      <EditText
                        name="addressLine2"
                        value={this.state.addressDto.addressLine2}
                        placeholder="Address Line two (optional)"
                        onValueChange={this.handleFieldValueChange}
                      />
                      <EditText
                        name="addressLine3"
                        value={this.state.addressDto.addressLine3}
                        placeholder="Address Line three (optional)"
                        onValueChange={this.handleFieldValueChange}
                      />
                      <EditText
                        name="poBox"
                        value={this.state.addressDto.poBox}
                        placeholder="PO Box (optional)"
                        onValueChange={this.handleFieldValueChange}
                      />
                      <EditText
                        name="zip"
                        value={this.state.addressDto.zip}
                        placeholder="Zip (optional)"
                        onValueChange={this.handleFieldValueChange}
                      />
                      <EditSelect
                        title="Country"
                        name="country"
                        value={this.state.country}
                        options={countryOptions}
                        onChange={this.handleSelectChange}
                        additionalClassName="half-width"
                      />
                      <EditSelect
                        title="City/Town"
                        name="city"
                        value={this.state.city}
                        options={cityOptions}
                        reference="cityDropdown"
                        onChange={this.handleSelectChange}
                        additionalClassName="half-width"
                      />
                      {this.state.city && this.state.city.value == 'other' ?
                        <EditText
                          name="newCity"
                          title=""
                          value={this.state.newCity}
                          placeholder={"Enter city name"}
                          onValueChange={this.handleFieldValueChange}
                          additionalClassName="col-6 p-0"
                        />
                        :
                        ""
                      }
                      <EditDate
                        title="Update Date & Time"
                        isDateTime={true}
                        name="startDate"
                        value={new Date(this.state.startDate)}
                        placeholder="Select A Start Date"
                        onValueChange={this.handleStartDateValueChange}
                      />
                      <EditDate
                        title=""
                        isDateTime={true}
                        name="endDate"
                        value={new Date(this.state.endDate)}
                        placeholder="Select an End Date"
                        onValueChange={this.handleEndDateValueChange.bind(this)}
                      />
                      <EditText
                        title="Enter Phone Number"
                        name="phoneNumber"
                        value={this.state.phoneNumber}
                        placeholder="Phone Number(Optional)"
                        onValueChange={this.handleFieldValueChange}
                      />
                      <EditText
                        title="Enter Email Address"
                        name="email"
                        value={email}
                        placeholder="Email (Mandatory)"
                        onValueChange={this.handleFieldValueChange}
                        inputType="email"
                      />
                      <EditText
                        title="Enter Website Address"
                        name="website"
                        value={this.state.website}
                        placeholder="Website (Optional)"
                        onValueChange={this.handleFieldValueChange}
                      />

                      <EditText
                        title="Enter RSVP Link"
                        name="rsvpLink"
                        value={this.state.rsvpLink}
                        placeholder="RSVP (Optional)"
                        onValueChange={this.handleFieldValueChange}
                      />

                    </div>
                  ) : (
                    <div>
                      <InfoText title="Category" text={categoryLabel} />
                      <InfoTextArray
                        title="Industries"
                        textArray={Helpers.selectOptionsToTextArray(this.state.industry)}
                        additionalClassName="text-info"
                      />
                      <InfoAddress
                        title="Event Venue"
                        address={this.state.addressDto}
                      />
                      {/* <InfoText title="" text={this.state.city.label}/>
                            <InfoText title="" text={this.state.country.label}/> */}

                      <InfoDate
                        title="Date & Time"
                        preText="Start:"
                        date={this.state.startDate}
                        isDateTime={true}
                      />
                      <InfoDate
                        title=""
                        preText="End:"
                        date={this.state.endDate}
                        isDateTime={true}
                      />
                      <InfoText
                        title="Phone Number"
                        text={this.state.phoneNumber}
                      />
                      <InfoText title="Email Address" text={email} />
                      <ExternalLink
                        title="Website Address"
                        link={this.state.website}
                        text={this.state.website}
                      />
                      <ExternalLink
                        title="RSVP"
                        link={this.state.rsvpLink}
                        text={this.state.rsvpLink}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="card" id="summary">
              <div className="card-body">
                <div className="col-lg-12 p-0">
                  <div className="col-lg-6 p-0 float-left">
                    <h4>Summary</h4>
                  </div>
                  <div className="col-lg-3 float-right p-0  text-right">
                    <div className="m-t-10">
                      <div>
                        {this.state.editSummary ? (
                          <a onClick={this.editSummary} className="text-danger">
                            <i className="fa fa-times" />
                          </a>
                        ) : (
                          <a onClick={this.editSummary} className="text-info">
                            Edit
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-info-">
                  <div className="clearfix" />
                  {isEditable && this.state.editSummary ? (
                    <EditTextArea
                      value={this.state.summary}
                      name="summary"
                      title=""
                      onValueChange={this.handleFieldValueChange}
                    />
                  ) : (
                    <div>
                      <h6 />
                      <ReadMoreAndLess
                        ref={this.readMore}
                        className="read-more-content"
                        charLimit={config.summaryLimit}
                        readMoreText="Read more"
                        readLessText="Read less"
                      >
                        {this.state.summary
                          ? Helpers.newLineToBr(this.state.summary)
                          : ""}
                      </ReadMoreAndLess>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="card" id="speakers">
              <div className="card-body">
                <div className="col-lg-12 p-0">
                  <div className="col-lg-6 p-0 float-left">
                    <h4>Speakers</h4>
                  </div>
                  <div className="col-lg-3 float-right p-0  text-right">
                    <div className="m-t-10">
                      <div>
                        {this.state.editSpeakers ? (
                          <a
                            onClick={this.editSpeakers}
                            className="text-danger"
                          >
                            <i className="fa fa-times" />
                          </a>
                        ) : (
                          <a onClick={this.editSpeakers} className="text-info">
                            Edit
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-info-">
                  <div className="clearfix" />
                  {isEditable && this.state.editSpeakers ? (
                    <EditTextArea
                      value={this.state.speakers}
                      name="speakers"
                      title=""
                      onValueChange={this.handleFieldValueChange}
                    />
                  ) : (
                    <div>
                      <h6 />
                      <ReadMoreAndLess
                        ref={this.readMore}
                        className="read-more-content"
                        charLimit={config.summaryLimit}
                        readMoreText="Read more"
                        readLessText="Read less"
                      >
                        {this.state.speakers
                          ? Helpers.newLineToBr(this.state.speakers)
                          : ""}
                      </ReadMoreAndLess>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="card" id="photos">
              <div className="card-body">
                <Upload
                  title="Photos"
                  description="Upload all relevant photos here"
                  icon="plus"
                  items={this.state.photos}
                  itemType="image"
                  itemKey="photos"
                  itemRepoType="EVENT_IMAGES"
                  itemChange={this.handleUpload}
                  isEditable={isEditable}
                />
              </div>
            </div>

            <div className="card" id="videos">
              <div className="card-body">
                <Videos
                  title="Videos"
                  description="Add/Update relevent videos here"
                  icon="plus"
                  items={this.state.videos}
                  itemKey="videos"
                  itemChange={this.handleVideoChange}
                  isEditable={isEditable}
                />
              </div>
            </div>

            <div className="col-sm-12 col-xs-12 p-0">
              <button
                className="btn btn-danger waves-effect- waves-light- m-r-10 "
                onClick={this.confirmDelete}
              >
                Delete Event
              </button>
              <button
                type="submit"
                className="btn btn-info waves-effect- waves-light- m-r-0 float-right"
                onClick={this.handleSubmit}
              >
                {this.state.status == "DRAFT"
                  ? "Submit for Approval"
                  : "Save" + this.state.status}
              </button>
              <SweetAlert
                showCancel
                closeOnClickOutside={true}
                show={this.state.confirmDelete}
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                btnSize="sm"
                title="Are you sure?"
                onConfirm={this.handleDelete}
                onCancel={this.confirmDelete}
              >
                You want to delete this event?
              </SweetAlert>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
/*export default  OppContent;*/

export default withRouter(MainContent);
