import React, { Component } from 'react'

export default class EditableFields extends Component {

 constructor(props){
     super(props);
 }

 renderSwitch(fieldType,name,selectedValue) {
    switch(fieldType) {
      case 'SELECT':
        return  <select className="form-control ediableInline" id={name+'Field'} name={name} defaultValue=""></select>;
     case 'TEXTAREA':
        return  <textarea className="form-control textarea-lg" id={name+'Field'} name={name} row="10" defaultValue=""></textarea>;
     case 'ADDRESS': 
        return  <div>
                  <input type="text" className="form-control m-b-5 ediableInline"  name="addressLine1" defaultValue=""  placeholder="Address Line 1"/><br/>
                  <input type="text" className="form-control m-b-5 ediableInline"  name="addressLine2" defaultValue=""  placeholder="Address Line 2 (Optional)" /><br/>
                  <input type="text" className="form-control m-b-5 ediableInline"  name="city" defaultValue=""  placeholder="City/Town" /><br/>
                  <input type="text" className="form-control m-b-5 ediableInline"  name="state" defaultValue=""  placeholder="State/Province/Region (Optional)" /><br/>
                  <input type="text" className="form-control m-b-5 ediableInline"  name="zip" defaultValue=""  placeholder="ZIP/Postal Code" /><br/>
                  <input type="text" className="form-control m-b-5 ediableInline"  name="country" defaultValue=""  placeholder="Country" />
                </div>
      case 'WEB': 
         return <input type="text" className="form-control ediableInline" id={name+'Field'} name={name} defaultValue=""  />;
      default:
        return <input type="text" className="form-control ediableInline" id={name+'Field'} name={name} defaultValue=""  />;
    }
  }


  render() {
    let heading = this.props.heading;
    let name = this.props.name;
    let value = this.props.value;
    let editBasicDetails = this.props.editBasicDetails;
    let editContactDetails = this.props.editContactDetails;
    let fieldType = this.props.fieldType;
    let selectedValue = this.props.selectedValue;
    fieldType = fieldType.toUpperCase();
  
    return (
      <div>
          <div className="col-lg-12 p-0 float-left">
                <h5>{heading}</h5>
                {(editBasicDetails || editContactDetails) ?
                   <div className="col-md-12 p-0">{this.renderSwitch(fieldType,name,selectedValue)}</div>
                    :
                    fieldType == "WEB" ? <p><a href={value}>{value}</a></p> : <p>{value}</p>
                }
          </div>
      </div>
    )
  }
}
