import React, { Component } from 'react';
import config from '../../shared/config';
import { withRouter, Link } from 'react-router-dom';

const Footer = ()=>{
    return (
        
        <footer className="mainfooter" role="contentinfo">
            <div className="footer-middle">
            <div className="container">
                <div className="row text-left">
                <div className="col-md-6">
                     <Link className="navbar-brand" to="/">
                        <img src={config.dataCdnStatic +'plus-logo.png?'} className="nav-logo" />
                    </Link>
                    <div className="footer-pad-horizon mt-3">
                        <p>&copy; Investera+ Africa 2021. All rights reserved </p>
                        <ul className="list-unstyled">
                            <li><a href={config.dataCdnStatic +'privacy_policy.pdf?'} target="_blank">Privacy Policy</a></li>
                            <li><a href={config.dataCdnStatic +'legal_docs.pdf?'} target="_blank">End User License</a></li>
                            <li><a href={config.dataCdnStatic +'cookie_policy.pdf?'} target="_blank">Cookies Policy</a></li>
                        </ul>
                        <ul className="list-unstyled mt-2">
                            <li><a href="https://www.facebook.com/InvesteraPlusAfrica?_rdc=1&_rdr" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/investeraplusafrica/" target="_blank"><i className="fab fa-linkedin no-style"></i></a></li>
                            <li><a href="https://www.instagram.com/investeraplusafrica/?hl=en" target="_blank"><i className="fab fa-instagram  no-style"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-3 col-sm-6">
                 
                    <div className="footer-pad">
                    <h4>Company</h4>
                    <ul className="list-unstyled mt-3">
                        <li><Link to="/about">About</Link></li>
                        {/* <li><Link to="#">Partners</Link></li> */}
                        <li><Link to="/faq">FAQ</Link></li>
                    </ul>
                    </div>
                </div>
                {/* <div className="col-md-2 col-sm-6">
                    <div className="footer-pad">
                    <h4>Explore</h4>
                    <ul className="list-unstyled mt-3">
                        <li><a href="#">Individual</a></li>
                        <li><a href="#">Startup</a></li>
                        <li><a href="#">Company</a></li>
                    </ul>
                    </div>
                </div> */}
                <div className="col-md-3 col-sm-6">
                    <div className="footer-pad">
                    <h4>Get in Touch</h4>
                    <ul className="list-unstyled mt-3">
                        <li><Link to="/contact">Contact Us</Link></li>
                    </ul>
                    </div>
                </div>
                </div>
            </div>
            </div>
            </footer>
    )
}

export default Footer;