import React, { Component } from 'react';
import Axios from 'axios';
import Helpers from "../../shared/custom";
import { withRouter, Link} from 'react-router-dom';
import store from '../../store';
import * as d3 from "d3";
import config from '../../shared/config';
import { login } from '../../actions/authAction';
import { connect } from 'react-redux';
import { LoadingOverlay, Loader } from 'react-overlay-loader';

const expandNode = {
    BOD: "Directors",
    INVT: "Investments",
    INVR: "Investors",
    FN: "Founders"
}

var component = undefined;
var rootId = 1;
var rootNodes = [];
var rootUid = undefined;
var currentTicks = 1;
var graph, g, link, node, text, tooltip = undefined;
var simulation, component = undefined;
var text;
var graphConfig = {
    showTitle:true,
    showLineLabels:true,
    nodeRadius: 5,
    nodeBg:"#407ACC",
    nodeHoverBg:"#A6C6F8",
    nodeStroke:"#CCC",
    collisionRadius:4,
    tickInterval:1,
    distance:100,
    zp:{k: 1, x: 0, y: 0}, 
}

class MiniGraph extends Component {

    constructor(props){
        super(props);
        this.state = {
            uri : undefined,
            name : undefined,
            image: undefined,
            
            isAuthenticated:false,
            showGraph:false,
            showCard:false,
            graphData:{nodes:[],links:[]},
            nodeMap:{},
            loading:false,
        }
    }

    componentDidMount(){
        let storeState = store.getState(); 
        let isAuthenticated = storeState.auth && storeState.auth.isAuthenticated;

        this.setState({
            uri: isAuthenticated? this.props.uri: undefined,
            name: this.props.name,
            image: this.props.image,
            isAuthenticated:isAuthenticated,
            showCard:true,
        },() => {            
            this.constructGraph();           
            if(this.state.isAuthenticated){
                this.fetchDetailData(this.props.uri);
            }           
        })
    }

    componentWillReceiveProps(nextProps){        
      
        let uri = nextProps.uri;
        let name = nextProps.name;
        let image = nextProps.image;        
        
        let storeState = store.getState();        
        if(storeState.auth && storeState.auth.isAuthenticated){
            
            if( this.state.uri && this.state.uri == uri &&
                this.state.isAuthenticated){
                return false;
            }
            
            this.setState({
                isAuthenticated:true,                               
                showCard:true,
                uri: uri,
                name: name,
                image: image 
            },() => {
                //this.fetchNews(uri);
                this.fetchDetailData(uri);
            })

        }else{
            this.setState({
                uri: undefined,
                isAuthenticated:false,
                showGraph:false
            })
            return false;
        }
    }

    fetchDetailData(uri){
          
        rootNodes = [uri];
        rootId = uri;
        rootUid = undefined;  
        
        let nodeMap = {};
        let existingGraphData = {nodes:[],links:[]};
        let nodes = [];
        let links = [];
  
        this.setState({
          loading:true,
          uri:uri
        })
  
        Axios.get(          
              Helpers.apiURL({
                  uri:"knowledge/graph/:uri/details",
                  pathVar:{uri:uri},
              })
            )
        .then( (response) => {
            const companyDetails  = response.data.result || {};   
            if(companyDetails.nodes){
              companyDetails.nodes.forEach(node => {
                if(!nodeMap[node.id]){
                  nodeMap[node.id] = true;
                  nodes.push(node);
                }
              });
            }
  
            if(companyDetails.links){
              links = links.concat(companyDetails.links);
            }
            
            let showGraph = nodes.length > 1 ? true : false;  
           
            let newData = {
              nodes:nodes,
              links:links
            }
  
            component.setState({
              loading:true,
              graphData:{nodes:[],links:[]}
            }, () => {
                this.startGraph();
                component.setState({
                    graphData:newData,
                    loading:false,
                    nodeMap:nodeMap,
                    showGraph:showGraph
                },()=>{             
                    
                    this.startGraph();
                })
            })
          
        })
        .catch((err) => {
          let newValues = {
              loading:false,
              graphData:{nodes:[],links:[]},
              nodeMap:{},
              showGraph:false,
            };   
          this.setState(newValues,()=>{             
                this.startGraph();
          })          
        });
    }

    constructGraph(){
        component = this;    
        let width = 220;
        let height = 300;        
        let fullSvg = d3.select("#exploreMainSvg");       

        simulation = d3
            .forceSimulation()
            .force("link", d3.forceLink()
                .id(d => d.id)
                .distance(function(d){ 
                    if(rootUid && (d.source.id == rootUid || d.target.id == rootUid )){
                        return 100;
                    }else{
                        return 50;
                    }
                })
                .strength(2))            
            .force("charge", d3.forceManyBody())
            .force("center", d3.forceCenter(width / 2, height / 2));
        
        g = d3
            .select("#exploreMainSvg").append("g")
            .attr("class", "everything graph-svg")
        
        link = g.append("g")
            .attr("class", "links")
            .selectAll("line")

        node = g.append("g")
            .attr("class", "nodes")
            .selectAll("circle")
        
        g.append("defs").append("marker")
        .attr("id", "arrow")
        .attr("viewBox", "0 -5 10 10")
        .attr("refX", 20)
        .attr("refY", 0)
        .attr("markerWidth", 8)
        .attr("markerHeight", 8)
        .attr("orient", "auto")
        .append("svg:path")
        .attr("fill", function(d) { 
            return d3.color("#CCC"); 
        })
        .attr("d", "M0,-5L10,0L0,5"); 

        text = g.append("g")
                .attr("class", "labels")
                .selectAll(".label") 

        var zoom_handler = d3.zoom()
        .on("zoom", ()=>{
            g.attr("transform", d3.event.transform);
            graphConfig.zp = d3.event.transform;                    
        });
        zoom_handler(fullSvg); 
        component.startGraph()
    }

    startGraph(){        
        graph = this.state.graphData; 
        if(graph.nodes && graph.nodes.length > 0){
          rootUid = graph.nodes[0].id;
        }else{
          rootUid = undefined;
        }

        link = link.data(graph.links);
        link.exit().remove();
        link = link.enter().append("line")
                .attr("stroke", function(d) { return d3.color(graphConfig.nodeStroke); })
                .attr("marker-end", "url(#arrow)")
                .merge(link);
                
        node = node.data(graph.nodes)
        node.exit().remove()
        node = node.enter()
                    .append("circle")
                    .attr("r", function(d){ 
                        if(d.page && d.page.uri && rootNodes.indexOf(d.page.uri) > -1){
                        return graphConfig.nodeRadius*1.25;
                        }                    
                        return graphConfig.nodeRadius;                    
                    })
                    .attr("class",function(d){                    
                        if(d.t == "DUM"){
                        return "node-normal node-dummy";
                        }
                        if(d.page && d.page.uri && rootNodes.indexOf(d.page.uri) > -1){
                        return "node-normal node-root";
                        }
                        return "node-normal";
                    })
                    .style("stroke", function(d) { 
                        return d3.color(graphConfig.nodeStroke); 
                    }).call(
                        d3
                        .drag()
                        .on("start", d => this.dragstarted(d, simulation))
                        .on("drag", d => this.dragged(d))
                        .on("end", d => this.dragended(d, simulation))
                    )                  
                    .on("click", function (d) {
                        d3.event.stopImmediatePropagation();
                    })
                    .merge(node);         
                  
        text = text.data(graph.nodes);
        text.exit().remove();
        text = text.enter()
                .append("g")
                .attr("class", "label")
                .append("text")
                .attr("x", 12)
                .attr("y", ".31em")
                .style("font-family", "sans-serif")
                .style("font-size", "0.7em")
                .text(function (d) {   
                    let name = d.page ? d.page.name : undefined;
                    if(name && expandNode[name]){
                      return expandNode[name];
                    }
                    return name ? name : "";
                  })
                .merge(text);
        simulation.nodes(graph.nodes).on("tick", () => this.ticked(link, node));
        simulation.force("link").links(graph.links);
        simulation.alpha(0.5).restart();
    }
    
    render() {
        let name = this.state.name;
        let uri = this.state.uri;
        let image = this.props.image ? this.props.image : config.defaultCompanyImage;
        let showCard = this.state.showCard;
        let showGraph = this.state.showGraph;
        let loading = this.state.loading;
        
        return (
            <div className={showCard ? "sticky rightItems" : "hidden"}>   
            <LoadingOverlay>
              <Loader loading={loading}/>             
                <div className="card text-left bold-headers">
                    <div className="card-header text-left light-blue-header">
                        <h5 className="m-b-0 m-t-0 text-white"><i className="r-font-family font-18">i</i>Knowledge Graph</h5>
                    </div>
                    <div className="card-body">
                        <div className="m-0 ">
                            <div className={showGraph ? "" : "hidden"}>
                                <svg width="100%" height="300" ref="svg" id="exploreMainSvg" className="graph"></svg>
                            </div>
                            {!showGraph ?
                                <div className="graphShow">
                                   <img src={"/cdn/theme/images/kg-sm.png"} className="full-width" alt="iKnowledge Graph" /> 
                                    <img src={Helpers.dataPath(image)} width="50"className="companyLogoKg" />
                                </div>
                            :
                                ""   
                            }                
                        </div>
                        <div className="text-center">
                            <Link className="btn btn-info" to={"/graph?uri="+this.props.uri}><i className="r-font-family font-18">i</i>Knowledge Graph</Link>
                        </div>
                    </div>
                </div> 
              </LoadingOverlay>      
            </div>
        )
    }

    //graph extra functions
    dragstarted = (d, simulation) => {
    if (!d3.event.active) simulation.alphaTarget(0.3).restart();
    d.fx = d.x;
    d.fy = d.y;
    };

    dragged = d => {
    d.fx = d3.event.x;
    d.fy = d3.event.y;
    };

    dragended = (d, simulation) => {
    if (!d3.event.active) simulation.alphaTarget(0);
    d.fx = d.x;
    d.fy = d.y;
    };

    ticked = (link, node) => {
        currentTicks++;
        if(currentTicks < graphConfig.tickInterval){
            return;
        }
        currentTicks = 0;
        
        node
        .attr("cx", function(d) { return d.x; })
        .attr("cy", function(d) { return d.y; });
                        
        link
            .attr("x1", function(d) { return d.source.x; })
            .attr("y1", function(d) { return d.source.y; })
            .attr("x2", function(d) { return d.target.x; })
            .attr("y2", function(d) { return d.target.y; });
        text
        .attr("transform", function (d) { return "translate(" + d.x + "," + d.y + ")"; });                          
        
    };
}

const mapStateToProps = (state) =>({
    auth:state.auth.isAuthenticated,
    errors:state.errors
});

export default connect(mapStateToProps,{login})(withRouter(MiniGraph));
