import React, { Component } from 'react';
import Funding from './Funding';
import Investor from './Investor'
import Investments from './Investments';
import { withRouter } from 'react-router-dom';
import STR from '../../shared/strings';
import Helpers from '../../shared/custom';
import Axios from 'axios';
class ManageFinancials extends Component {
 
  constructor(props){
    super(props)
    this.state = {
      uri:undefined,
      companyDetails:undefined,
    }

    this.goBack = this.goBack.bind(this);
    this.fetchCompanyInfo = this.fetchCompanyInfo.bind(this);
  }

  componentDidMount(){
    let uri = this.props.match.params.uri;
    this.setState({
      uri:uri
    })
    this.fetchCompanyInfo(uri);
  }

  componentWillReceiveProps(nextProps){
    let newUri = nextProps.match.params.uri;
    let currentUri = this.state.uri;

    if(!currentUri || currentUri != newUri){
      this.setState({
        uri:newUri
      })
      this.fetchCompanyInfo(newUri);
    }
  }

  goBack(){
    //do a confirm here before going

    let uri = this.state.uri;
    this.props.history.push("/company-details/"+uri);   
  }

  fetchCompanyInfo(uri){
    Axios.get(
      Helpers.apiURL({
          uri:"company/:uri/details",
          pathVar:{uri:uri}
      })
    )
    .then( (response) => {
        let company  = response.data.result || null;                
        if(!company){
          Helpers.pushTError("COMP_NOT_FOUND");
            this.props.history.push("/companies/");
        }else{                 
            this.setState({
                companyDetails:company
            })
        }
    })
    .catch((err) => { 
      Helpers.pushTError("COMP_NOT_LOADED");
      this.props.history.push("/companies/"); 
    });
  }
  render() {
    let uri = this.state.uri;
    let companyDetails = this.state.companyDetails;
    let backAction ={
      proceed:this.goBack,
      placement:"left"
    }
    return (
        <div className="row m-t-40 p-t-5 financial-div bold-headers text-left">
           <div className="p-5 col-12">
              <h2 className="text-black">Edit Financial Details <a href="javascript:void(0)" className="float-right font-14 text-danger font-normal" onClick={this.goBack}>&lt; Back</a></h2>
              <div className="m-t-40 p-t-5 p-r-10 col-12">
                    <Funding uri={uri} />
                    <Investments uri={uri} companyDetails={companyDetails} />
                    {/* <Investor uri={uri} /> */}
              </div>
           </div>
       </div>
    )
  }
}
export default withRouter(ManageFinancials);