import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link ,Switch ,withRouter} from "react-router-dom";
import OwlCarousel, { Options } from 'react-owl-carousel';

import InfiniteScroll from 'react-infinite-scroller';
import config from '../../shared/config';
import classNames from 'classnames/bind';
import Axios from 'axios';
import Helper from "../../shared/custom";
import AllEventsPublic from "./AllEventsPublic";
import store from '../../store';
import Helpers from '../../shared/custom';
import Interest from '../uiActions/Interest';
import EventCard from './EventCard';
import { connect } from 'react-redux';

import { getMetaData } from '../../actions/metaAction';
import { getSectorData } from '../../actions/businessAction';



class Allevents extends Component {
    constructor(){
        super();
          this.state ={
            events:[],
            searchCriteria:[],
            upcomingEvents:[],
           // myEvents:[],
           
            chosenEvents:[],
            interestedEvents:[],
            isLoggedIn:false,
            localProps:{},
           
           
            qs:Helper.generatePaginationQS({"page":1, "length":config.defaultCarouselLength}),
            options: {
              loop: false,
              margin: 10,
              nav:true,
              autoplay:true,
              autoplaySpeed:400,
              items:3,
            },
            scrollItems:2,
            hasMoreItems: true,
            selectedSectors:[],
            eventsBySector:[],

            scrollReady:true,
            scrollIndex:0,

            criteriaUpdate:undefined,
            business:null,
            metaList:[],
            type:"",
            localProps:[]

          }
          store.subscribe(() => { 
            var storeState = store.getState();
            var auth = storeState.auth;
            var localAuth = this.state.auth;
            this.setState({
              isLoggedIn:auth.isAuthenticated
            },()=>{
              
            })


              var localMetaList = this.state.metaList;
              var storeMetaList = storeState.meta.metaList; 
              
              if(!localMetaList || JSON.stringify(localMetaList) != JSON.stringify(storeMetaList)){
                  this.setState({metaList:storeMetaList},function(){
                
                  });
                  
              }
        
              var localBusiness = this.state.business;
              var storeBusiness = storeState.business.data;
              if(!localBusiness || JSON.stringify(localBusiness) != JSON.stringify(storeBusiness)){
                  this.setState({business:storeBusiness});
              }
          }); 




          this.updateSearch = this.updateSearch.bind(this);
          this.handleLoadMore = this.handleLoadMore.bind(this);
      }

      
      componentWillReceiveProps(props){
         this.setState({searchCriteria:props.searchCriteria}, function(){
           this.updateSearch();
         });

         if(JSON.stringify(this.state.localProps) != JSON.stringify(props)){
           this.setState({
            localProps:props,
            scrollItems:props.items
           })
         }
      }

      openEventsSectorPage(e){
        let type = "events"
        window.location.replace('/'+type+'/sector/'+e.target.name);
        var sectorId = e.target.name;
        let sectorLink = document.getElementsByClassName("sectorLink");
        let activeLink = document.getElementsByClassName("activeSector");
        let navLink = document.getElementsByClassName("nav-link active");
       
      
        if (activeLink.length > 0) {
          activeLink[0].classList.remove("activeSector")
        }
        
        if (navLink.length > 0) {
          navLink[0].classList.remove("active")
          // navLink.forEach((elm,index)=>{
          //   elm.classList.remove("active")
          // })
        }
            
        e.target.classList.add("activeSector");
      }


      componentDidMount(){
        var storeState = store.getState();
        var auth = storeState.auth;
        var localAuth = this.state.auth;
        this.setState({
          isLoggedIn:auth.isAuthenticated
        },()=>{
          this.updateSearch()
          this.fetchSelectedSectors()
   
        });

        let meta = storeState.meta;

        if(Helper.isEmpty(meta.metaList) == true){
          this.props.getMetaData();
        }else{
          this.setState({metaList:meta.metaList});
        }

        let business = storeState.business;

        if(Helper.isEmpty(business.data) == true){            
            this.props.getSectorData();
        }else{
            this.setState({business:business.data});
        }
        

        if(Helper.isMobile()){
          this.setState({
            scrollItems :1
          })
        }
      }

     

    fetchSelectedSectors(){
        Axios.get(
            Helper.apiURL({
                uri:"user/sector",
            })
        )
        .then( (response) => {    
            let sector = response.data && response.data.result ? response.data.result : [];
            this.setState({
                selectedSectors:sector
            })
          })
        .catch((err) => {
        });
      }


      updateSearch(){
        if (this.state.isLoggedIn){
            this.fetchChosenEvents();
            this.fetchUpcomingEvents();
            this.fetchInterestedEvents();
         }else{
            this.setState({
              qs:Helper.generatePaginationQS({"page":1, "length":config.defaultGuestListingLength}), 
            },()=>{
              this.fetchUpcomingEvents();
            })
         }
      }

      setInterested = (event) =>{
        this.postSetInterested(event.target.id);
      }
      
      postSetInterested = (uniqueId) => {
       
      }


     fetchUpcomingEvents = () => {
        let uri = this.props.entityUri;
        if(!uri){
          this.setState({events:[]})
        }
        Axios.get(
            Helper.apiURL({
                uri:"events/upcoming",
                query:this.state.qs
            })
        )
        .then( (response) => {           
            let events = response.data.result.aaData || [];
            this.setState({upcomingEvents:events});
           })
        .catch((err) => {
            
        });
    }
  
    fetchChosenEvents = () => {
      let uri = this.props.entityUri;
      if(!uri){
        this.setState({events:[]})
      }

    // return async dispatch => {
      Axios.get(
          Helper.apiURL({
              uri:"events/my-preference",
              query:this.state.qs
          })
      )
      .then( (response) => {         
          let events = response.data.result.aaData || [];
          this.setState({chosenEvents:events});
        })
      .catch((err) => {
          
      });
  }

  fetchInterestedEvents = () => {
    let uri = this.props.entityUri;
    if(!uri){
      this.setState({events:[]})
    }

  // return async dispatch => {
    Axios.get(
        Helper.apiURL({
            uri:"events/interested",
            query:this.state.qs
        })
    )
    .then( (response) => {         
        let events = response.data.result.aaData || [];
        this.setState({interestedEvents:events});
      })
    .catch((err) => {
        
    });
}

  ///events/interested

    handleLoadMore(page){
      let isLoggedIn = this.state.isLoggedIn;
      if(!isLoggedIn){
        return false;
      }

      if(!this.state.scrollReady){
        return false;
      }

      let sector = this.state.selectedSectors;
      let scrollIndex = this.state.scrollIndex;
      scrollIndex++;

      if(scrollIndex > sector.length){
        this.setState({
          hasMoreItems:false
        })
        return false;
      }

      this.setState({
        scrollReady:false,
        hasMoreItems:false
      })

      this.getDataBySector(scrollIndex);

  }

  getDataBySector(scrollIndex){
      let sector = this.state.selectedSectors;
      let sectorId = sector[scrollIndex-1].id || null;
      let sectorName = sector[scrollIndex-1].name || null;
      Axios.get(
       Helper.apiURL({
         uri:"events/sector/"+sectorId+"?iDisplayStart=0&iDisplayLength=4&iSortCol_0=1&sSortDir_0=desc&countryIds=",  
       })
       )
      .then( (response) => {   
          let eventsBySector = this.state.eventsBySector;
           eventsBySector[sectorId] = response.data && response.data.result ? response.data.result.aaData:[];
           eventsBySector[sectorId].sectorName = sectorName;
          this.setState({
            eventsBySector:eventsBySector,
            scrollReady:true,
            hasMoreItems:true,
            scrollIndex:scrollIndex
          })      
        })
      .catch((err) => {
        this.setState({
          scrollReady:true,
          hasMoreItems:true,
          scrollIndex:scrollIndex
        })      
      });
  }


  renderSectorItems(){

    let items = [];
    let item = undefined;
    let eventsBySector = this.state.eventsBySector;
    let innerItem = null;
    let scrollItems = this.state.scrollItems;

    if(!Helpers.isEmpty(eventsBySector)){
      eventsBySector.forEach((events,i) => {
        item  = (
          <div className="card text-left bold-headers" key={i}>
           <div className="card-body">
           <h5><b>{events.sectorName} Events</b></h5>
            <br/>

            {!Helpers.isEmpty(events)?
            <OwlCarousel ref="car" className="owl-theme" items={scrollItems} autoplay="true" loop={events.length>3 ? true :false} margin={10} autoWidth={false} nav={true} >
            {events.map((event, index) => (
                <EventCard data={event} key={"EUS"+index}/>
            ))}
          </OwlCarousel>
          :
            <div className="text-center">No Data Available</div>
          
           }
            
            </div>
            </div>
        );
        
        items.push(item);
      });
    }
    return items;
  }

  render() {
    

    let isLoggedIn = this.state.isLoggedIn;
    let scrollItems = this.state.scrollItems;
    var events =[]
    if(!Helper.isEmpty(this.state.events)){
        events = this.state.events;
    }


    let upcomingEvents=[];
    if(!Helper.isEmpty(this.state.upcomingEvents)){
        upcomingEvents = this.state.upcomingEvents;
    }
   
    let chosenEvents=[];
    if(!Helper.isEmpty(this.state.chosenEvents)){
      chosenEvents = this.state.chosenEvents;
    }

    let interestedEvents = [];
    if(!Helper.isEmpty(this.state.interestedEvents)){
      interestedEvents = this.state.interestedEvents;
    }


    var onlyInterested = this.props.onlyInterested;
    

    let items = this.renderSectorItems();
  

    let sectorsList = null;
    if (this.state.business){
      sectorsList = this.state.business.sectorsArray;
    }

    return (
    <div>
     {isLoggedIn?
     <div>
      {/* <div id="eventsContainer" className="card text-left bold-headers">
          <div className="card-body">
              <h5><b>Interested Events</b></h5>
              <br/>
              {!Helper.isEmpty(upcomingEvents) ? 
                <OwlCarousel ref="car" className="owl-theme" items={scrollItems} autoplay="true" loop={upcomingEvents.length>3 ? true :false} margin={10} autoWidth={false} nav={true} >
                {upcomingEvents.map((event, index) => (
                      <EventCard data={event} key={"EUP"+index}/>
                  ))}
                </OwlCarousel>
                :
                <div className="col-12 text-center">No Data Available</div>
              }
             
            
            
          </div>
      </div> */}
{/*       
      {Helper.isEmpty(onlyInterested) ? 
          <div className="card text-left bold-headers">
            <div className="card-body">
                <h5><b>Events chosen for you</b></h5>
                <br/>


              {!Helper.isEmpty(chosenEvents) ? 
                <OwlCarousel ref="car" className="owl-theme" items={scrollItems} autoplay="true" loop={chosenEvents.length>3 ? true :false} margin={10} autoWidth={false} nav={true} option={this.state.options} >
                    {chosenEvents.map((event, index) => (
                        <EventCard data={event} key={"EUC"+index}/>
                      ))}
                  </OwlCarousel>
                  :
                  <div className="col-12 text-center">No Data Available</div>
                }
            </div>
          </div>
         :
        ""
      } */}
      
      {/* {Helper.isEmpty(onlyInterested) ? 
        <InfiniteScroll
              pageStart={0}
              loadMore={this.handleLoadMore}
              hasMore={this.state.hasMoreItems}
              threshold = {1}
              loader={<div className="loader" key={0}><img src={config.cdn +'theme/images/small-loader.gif'}  /></div>}
              useWindow={true}
          >
              {items}
        </InfiniteScroll>
        :
        ""
      } */}

            <div className="card text-left b-header">
                            <div class="card-header text-left p-0">
                                <div class="col-md-6 float-left light-blue-header-half header-div">Interested Events</div>
                                <div class="col-md-6 float-left text-right">
                                </div>
                              </div>
                              {!onlyInterested ?
                                <div className="card-body companies blue-border">
                                  <div className="col-md-12 p-0">
                                      {!Helper.isEmpty(sectorsList) ? 
                                            sectorsList.map((data,i) =>(
                                                    <div className="col-md-6 float-left" key={i}>
                                                          <div class="card text-center">
                                                              <div class="card-body">
                                                                  <div className="pro-img">
                                                                    <img src={Helper.dataPath(data.image)}  className="img-responsive radius float-left"/> 
                                                                  </div>
                                                                  <div className="font-14 imgLabel">{data.name}</div>
                                                              </div>
                                                              <a class="card-footer blue-bg font-12 text-white" onClick={this.openEventsSectorPage} name={data.id}>
                                                                    View Event 
                                                              </a>
                                                          </div>
                                                    </div>
                                            ))
                                            :
                                            ""
                                      }
                                   </div>
                                 </div>
                                 :
                                 <div className="card-body companies blue-border">
                                  <div className="col-md-12 p-0">
                                   {!Helper.isEmpty(interestedEvents) ? 
                                    <OwlCarousel ref="car" className="owl-theme" items={2} autoplay="true" loop={interestedEvents.length>3 ? true :false} margin={10} autoWidth={false} nav={true} option={this.state.options} >
                                        {interestedEvents.map((event, index) => (
                                            <EventCard data={event} key={"EUC"+index}/>
                                          ))}
                                      </OwlCarousel>
                                      :
                                      <div className="col-12 text-center">No Data Available</div>
                                    }
                                    </div>
                                  </div>
                                  

                              }
                              
                        </div>
                        </div>

     
        :
        <AllEventsPublic upcomingEvents={this.state.upcomingEvents}/>
        }

      </div>
    )
  }
}

export default  connect(null,{ getMetaData, getSectorData})(withRouter(Allevents));
