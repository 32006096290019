import React, { Component } from 'react'
import Helpers from '../../shared/custom';
import config from '../../shared/config';
import { withRouter, Link } from 'react-router-dom';
import Axios from 'axios';
import Interest from '../uiActions/Interest';

class CommentShareBtn extends Component {
  constructor(props){
    super(props);
    this.state={
      id:null,
      feedUniqueId: null,
     
    }
   this.proceed = this.proceed.bind(this);
   this.sharePost = this.sharePost.bind(this);
   this.focusInput = this.focusInput.bind(this);
}   

componentDidMount(){
  let mainData = this.props.data;
  if(!Helpers.isEmpty(mainData)){
    let feedUniqueId = mainData.feedUniqueId;
    this.setState({
      feedUniqueId:feedUniqueId
    },()=>{
       
    });
  }
}

focusInput(){
  this.props.focusInputArea()
}

proceed(){
  this.sharePost()
}

cancel(){
 return false;
}


sharePost(){
 
  let payload ={};
  payload.feedUniqueId = this.state.feedUniqueId;
  Axios.post(
    config.api("share/"),
    payload)
  .then( (response) => {
      let data = response.data.result;
      Helpers.pushTSuccess("Shared successfully");
      this.props.updateCounts(data.feedUniqueId);
  })
  .catch((err) => {
      Helpers.pushTError("Cannot share this post at the moment")
  });
}




  render() {
    let data = this.props.data;
    let action = {
      proceed:this.proceed,
      cancel:this.cancel
   }
   let interested;
   let id;
   let entityType;

   if(!Helpers.isEmpty(data)){
       interested = data && data._isIntrested ? data._isIntrested : false;
       id =  data.entityId;
       entityType= data.entityType;
   }
  
   let showInterested = this.props.showInterested ? this.props.showInterested : false;
    return (
      <div>
          <hr/>
          <div className="col-lg-12 m-a text-center cmtShare">
            {showInterested ? <Interest id={id} type={entityType} interested={interested}  mode="feed"/> :""}
            <a href="javascript:void(0)" className="p-15 text-muted font-14" onClick={this.focusInput}><i className="mdi mdi-comment font-18"></i> <span className="v-text">Comment</span></a>
            <a href="javascript:void(0)" className="p-15 text-muted font-14" onClick={event => Helpers.actionConfirm(event,action)}><i className="fa fa-share font-18"></i> <span className="v-text">Share</span></a>
          </div>
      </div>
    )
  }
}

export default CommentShareBtn ;
