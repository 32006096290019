import React, { Component } from 'react';
import config from '../../shared/config';
import Footer from './Footer';

const AboutUs = (props)=>{

    let { from }  = props;
  
    return (
        <React.Fragment>
         <div className="new_home">
            <div className="container-fluid  about-content relative">
                <div className="row rowed">
                    <div className="col-md-7 left text-left">
                        <h2>About<br/> Us</h2>  
                        <div className="desc">
                            <p>Investera Plus Africa provides a digital platform which serves as an
                               International business resource centre that brings together businesses,
                               government entities and investors for Africa.</p>
                            <p>The platform is a customization of Investera Plus, a UAE-based leading market
                               research tool that offers highly insightful industry reports, news and market
                               maps with a community of over 3,000 investors, upwards of 100,000
                               companies, 20,000 professionals and 11,000 start-ups; All these entities
                               interact, connect and do business seamlessly across different markets around
                               the world.</p>
                            <p>The Investera Plus Africa platform shall provide intelligent business information and interactions in four tiers:</p>
                            <ul className="no-list-style">
                                <li>Tier 1: Directory of Companies and company insights in the African market</li>
                                <li>Tier 2: Business Networking on two levels, that is B2C & B2B</li>
                                <li>Tier 3: Connecting Start-ups and Companies with Investors</li>
                                <li>Tier 4: Provide an end-to-end investment management cycle</li>
                            </ul>
                        </div>                  
                    </div>
                    <div className="col-md-5 right">
                        <img src={config.dataCdnStatic + "people.png"} alt={"About Us"} className="img-responsive aboutImg"/>
                    </div>
                </div>
                <div className="row blue-bg-content">
                    <div className="container text-left">
                            <h2 className="mt-2">Vision</h2> 
                            <p className="mt-2">To enhance Africa’s economic prosperity through the centralization of the day-to-day business operations between businesses, investors, government entities and the general public for.</p>
                            <h2 className="mt-4">Mission</h2> 
                            <p className="mt-2">To create a business environment where interactions lead to informed decisions through the use of credible data.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="container text-left paddedDiv">
                            <h2 className="m-t-100">Philosophy</h2> 
                            <p className="m-t-50">We aim to interconnect and simplify the business eco system operations in the African region.</p>
                            {/* <p className="m-t-50">Investera believes in great <span className="blue-bolder">IDEAS</span> and their potential to be realized as thriving and sustainable business.</p> */}
                    </div>
                </div>
                {/* <div className="row">
                    <div className="container text-left paddedDiv">
                            <h2 className="m-t-100">Our Values</h2> 
                    </div>
                    <div className="row m-t-50 full-width">
                        <div className="col-sm-12 col-md-12 col-lg-7">
                            <img src={config.dataCdnStatic + "our_values.png"} alt={"our"} className="img-responsive"/>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-5">
                                <ul className="special-ul">
                                    <li><span>I</span>nnovation</li>
                                    <li><span>D</span>iversity</li>
                                    <li><span>E</span>fficiency</li>
                                    <li><span>A</span>ccuracy</li>
                                    <li><span>S</span>ustainability</li>
                                </ul>
                        </div>
                    </div>
                </div> */}
                {/* <div className="row">
                    <div className="container text-left paddedDiv">
                            <h2 className="m-t-100">What We Are Solving</h2> 
                            <ol className="m-t-50 number-list">
                                <li><span>Lack of a platform with credible information on companies, products and services in Africa.</span> </li>
                                <li><span>Businesses have a challenge in connecting with each other to share resources, ideas and opportunities.</span></li>
                                <li><span>Investors have a hard time identifying and managing investment opportunities in the continent.</span></li>
                                <li><span>Startups have insufficient empowerment to scale and go to the next level beyond the country.</span></li>
                            </ol>
                    </div>
                    
                </div> */}
            </div>
            {from != "home" && <Footer/>}
            </div>
        </React.Fragment>
    )
}

export default AboutUs;