import React, { Component } from 'react';
import BoxSlider from "./BoxSlider"; 
import Axios from 'axios';
import Helper from "../../shared/custom";

class Trending extends Component {
    constructor(props){
        super(props);
        this.state ={
            trendingOpps:[]
        }
        this.fetchTrendingOpp = this.fetchTrendingOpp.bind(this);
    }

    componentDidMount(){
        let uri = this.props.uri;
        this.fetchTrendingOpp(uri)
    }

    fetchTrendingOpp(uri){
        Axios.get(
            Helper.apiURL({
                uri:uri == "opportunities" ? "api/opportunity/trending" : uri+"/trending",
            })
        )
        .then( (response) => {     
            let trending = uri == "opportunities" ? response.data.content : response.data && response.data.result ? response.data.result : [];
            this.setState({
                trending:trending
            })
        })
        .catch((err) => {
        });
    }

  render() {
    let trendingOpps = this.state.trending;
    let type  = this.props.type;
    let title = this.props.title;
    let uri = this.props.uri;
    let mode = this.props.mode;
    return (
      <div className={type}>
          <BoxSlider items="1" title={title} type={type} dataset={trendingOpps} uri={uri} keyName={type} mode={mode}/>
      </div>
    )
  }
}
export default Trending;