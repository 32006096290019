import React, { Component } from 'react';
import config from '../../shared/config';
import Axios from 'axios';
import Helper from "../../shared/custom";
import ReadMoreAndLess from 'react-read-more-less';
import store from '../../store';

export default class Relatednews extends Component {

    constructor(props){
        super(props);
        this.state = {
            uri : undefined,
            type : undefined,
            news : [],
        }
    }

    
    componentWillReceiveProps(nextProps){
        let type = this.props.type;
        let uri = nextProps.entityUri;
        let storeState = store.getState();

        if(!storeState.auth || !storeState.auth.isAuthenticated){
            this.setState({
                type: undefined,
                uri: undefined,
                news: []
            })
            return false;
        }

        if(this.state.uri && this.state.uri == uri && this.state.type && this.state.type == type){
            return false;
        }

        
        this.setState({
            type: this.props.type,
            uri: uri,
        },() => {
            this.fetchNews(uri);
        })
        
    }

    fetchNews(entityUri){
        let type = this.props.type;
        let uri = entityUri;
       
        if(!uri || !type){
            this.setState({
                type: undefined,
                uri: undefined,
                news: []
            })
            return false;
        }

        Axios.get(
            Helper.apiURL({
                uri:":type/:uri/news",
                pathVar:{type: type, uri: uri}
            })
        )
        .then( (response) => {     
            var news = response.data.result || [];
            this.setState({
                news: news, 
                uri: uri, 
                type: type
            })
        })
        .catch((err) => {
            
        });
    }

  render() {
    let news = this.state.news;
    let newsLength = news.length;
    return (
        <div>
            <div className="card text-left bold-headers" id="section6">
                <div className="card-header text-left light-blue-header">
                    <h5 className="m-b-0 m-t-0 text-white">Related News</h5>
                </div>
                <div className="card-body">
                    <div className="profiletimeline m-0 ">
                {newsLength > 0 ?
                    <div>
                            {news.map((news, index) => (
                                <div className="sl-item" key={"index"}>
                                <div className="sl-right">
                                <div className="m-t-20 row">
                                    <div className="col-md-3 col-xs-12"><img src={!Helper.isEmpty(news.image) ? news.image : Helper.dataPath("news/default.jpg")} alt="user" className="img-responsive radius img-news" /></div>
                                    <div className="col-md-9 col-xs-12">
                                        <h5>UAE customers of Fetchr courier service facing delays of up to two weeks 
for deliveries.</h5> 
                                        <ReadMoreAndLess
                                            ref={this.ReadMore}
                                            className="read-more-content"
                                            charLimit={config.summaryLimit}
                                            readMoreText="Read more"
                                            readLessText="Read less"
                                        >
                                         The company has blamed the situation on busy Black Friday sales on social media
                                        </ReadMoreAndLess>
                                        <span className="sl-date bottom-elm black-text font-bold">Apr 24, 2018</span>
                                    </div>
                                 </div>
                                </div>
                                </div>
                            ))}
                        <div className="m-t-10">
                                <a href={'/'+this.props.type} className="float-right">View All</a>
                        </div>
                    </div>
                 :
                <div>
                    <center>No data found!</center>
                </div>
                } 
                
            </div>
        </div>
      </div>
      </div>
    )
  }
}
