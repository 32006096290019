import React,{ useState , useEffect} from 'react';
import CountryCode from "../wigets/CountryCode";
import Helper from '../../shared/custom';
function Mobile({
                    extVal,
                    mobileVal,
                    sendValue,
                    addLabelClass,
                    title,
                    required,
                    error,
                    extName,
                    mobileName,
                    full,
                    cnVal
                }) {

 

    const [ext, setExt] = useState(extVal);
    const [cn, setCn] = useState("KE");
    const [mobile, setMobile] = useState(mobileVal || "");
   // const [error ,setError]= useState(error);
   


    const onChangeMobile = (e)=>{
        setMobile(e.target.value);
    }
    const onChangeExt = (e,cn)=>{

        console.log(cn,"123")

        var name = extName || "ext";
        if(!Helper.isEmpty(cn)){
            setExt(e);
            if(error && error[name]){
                error[name] = "";
            }
           
        }else{
            setExt("");
            if(error && error[name]){
                error[name] = "Please enter a valid country code";
            }
        }
    }

    useEffect(() => {
        setMobile(mobileVal);
        setExt(extVal);
        setCn(cnVal);
    },[extVal]);

   
    useEffect(() => {
        let obj = {};
        obj.ext = ext;
        obj.mobile = mobile;
        if(sendValue){
             sendValue(obj)
        }
    }, [ext,mobile])

   

    return (
        <div className="col-lg-12 p-0 float-left">
            <h5 className={addLabelClass}>{title}{required && <span className="text-danger font-bold">*</span>}</h5>
            <div className="form-group  float-left full-width">
                    <div className={full ? "col-md-12 p-0 float-left":"col-md-6 p-0 float-left"}>
                        <div className="col-md-3 col-sm-3 p-0 float-left mobileCodeDiv">
                            <CountryCode name={extName || "ext"} defaultValue={ext} value={ext} onChange={onChangeExt} className={"homeElement"}  defaultCountry={cn}/>
                            {error && error[extName || "ext"] && <small class="text-danger errorMsgCustom">{error[extName || "ext"]}</small>}
                        </div>
                        <div className="col-md-8 col-sm-8 p-r-0 m-p-0 float-left mobileNumberDiv card-mobile">
                            <input type="text" 
                                    className="form-control" 
                                    id="mobile" 
                                    name={mobileName || "mobile"}
                                    aria-describedby="mobile" 
                                    placeholder="Mobile No*" 
                                    onChange={onChangeMobile}
                                    value={mobile}
                                    data-testid={mobileName || "mobile"}
                                    />
                           {error && error[mobileName || "mobile"] && <small class="text-danger errorMsgCustom">{error[mobileName || "mobile"]}</small>}
                        </div>
                    </div>
            </div>
        </div>
    )
};


export default Mobile
