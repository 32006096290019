import React, { useRef, useState , forwardRef } from 'react';
import { Link } from 'react-router-dom';
import Helper from '../../shared/custom';
import { MemberListCard } from '../wigets/MemberListCard';
import AuthorDetails from './AuthorDetails';
import CardActions from './CardActions';
import Comments from './Comments';
import CommentShareBtn from './CommentShareBtn';
import ReactDOM from 'react-dom';
import { pointer } from 'd3-selection';


export const NewMember = ({ data, index }) => {
    const [toggle, setToggle] = useState(() => false);
    const [count, setCount] = useState(() => 1)
    const [focusInput, setFocusInput] = useState(false);
    //let members = [];


    const focusInputFiled = () =>{
        setFocusInput(!focusInput)
    }
    
    let members = !Helper.isEmpty(data.groupedFieldUpdates) ? data.groupedFieldUpdates.map(item => ({
        type : item && item.person ? item.person.type : '',
        uri : item && item.person ? item.person.uri : '',
        image : item && item.person ? item.person.image : '',
        title : item && item.person ? item.person.name : '',
        primaryTitleDesignation : item && item.designation ? item.designation  : '',
        primaryTitleCompanyUri : data.uri,
        primaryTitleCompanyName : data.name,
    })) : [];


    const elementRef = useRef(null);
    const containerRef = useRef(null);

    const displayMembers = () => {
        setToggle(prevState => !prevState);
        setCount(prevState => prevState == 1 ? members.length : 1);
        if(toggle){
            var headerHeight = 100; 
            var scrollToEl = elementRef.current;
            var topOfElement = window.pageYOffset + scrollToEl.getBoundingClientRect().top - headerHeight;
            window.scroll({ top: topOfElement, behavior: "smooth" });
            }
            
    }


  

    return (
        <div>
            <div className="card">
                <div className="card-body">
                    <div className="col-lg-12 p-0">
                        <AuthorDetails data={data} />
                        <div className="col-lg-3 p-0 float-right text-right">
                             <CardActions data={data}/>
                        </div>                   
                     </div>

                    <div className="clearfix"></div>
                    <div  className="col-lg-12 p-0 m-t-40 ">
                        <div ref={elementRef} className="b-b-2 p-b-5">
                            <span> <i className="mdi mdi-account-multiple font-24 vm m-r-5"></i> <Link className="font-bold" className="font-bold" to={`${data.author.type}/${data.author.uri}`}>{data.author.name}</Link>{members.length > 1 ? ` Added ${members.length} new Members` : ` Added a new Member`} </span>
                        </div>
                        {members.slice(0, count).map((item, index) =>  <MemberListCard   result={item} index={index} className={`${toggle ? members.length-1 > index ? "border-bottom" : "" : ""} ${members.length == 1 ? 'p-b-0' : ""}`} /> )}
                        {members.length > 1 && <span   style={{ cursor: 'pointer' }}  onClick={displayMembers} className="font-12"><i className={`fa ${!toggle ? 'fa-chevron-down' : 'fa-chevron-up'}`} /> &nbsp;&nbsp;{!toggle ? `${members.length - 1} more other members` : 'Hide'}</span> }
                    </div>


                    <CommentShareBtn data={data} showInterested={false}
                     focusInputArea={focusInputFiled} 
                    // updateCounts={this.updateCounts}
                    />
                    <Comments data={data}
                     enableFocus={focusInput}
                    />
                </div>

            </div>
        </div>
    );
}