import React, { Component , useEffect, useState , useRef } from 'react';


import config from '../../shared/config';
import Helpers from '../../shared/custom';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import Axios from "axios";

import EditText from "../ui/EditText";
import EditDate from "../ui/EditDate";
import ApiHelper from "../../shared/ApiHelper";
import DeletePage from '../wigets/DeletePage';




function InvestmentForm(props) {

    const [loading, setLoading] = useState(() => false);
    const [errors, setErrors] = useState(() => {});
    const [state, setState] = useState(() => {});
    const [data, setData] = useState(() => {});
    

    const [investmentAmount, setInvestmentAmount] = useState(() => {});
    const [pitchDeck, setPitchDeck] = useState(() => {});
    const [investmentOffer, setInvestmentOffer] = useState(() => {});
    const [targetDate, setTargetDate] = useState(() => {});
    const [owner, setOwner] = React.useState(false);


    useEffect(() => {
        if(!Helpers.isEmpty(props.data)){
          setInvestmentAmount(props.data.investmentAmount);
          setPitchDeck(props.data.pitchDeck);
          setInvestmentOffer(props.data.investmentOffer);
          setTargetDate(props.data.targetDate);
          setOwner(props.data.owner)
          var dataProps = props.data;
         
          dataProps["investmentAmount"] = dataProps.investmentAmount;
          dataProps["investmentOffer"] = dataProps.investmentOffer;
          dataProps["targetDate"] = dataProps && dataProps.targetDate ? new Date(dataProps.targetDate): new Date();
          dataProps["pitchDeck"] = dataProps.pitchDeck;
          
          setState(dataProps);
        }
    }, [props.data])
    

    const handleFieldValueChange = (e) => {
        e.stopPropagation()
        let name = e.target.name;
        let value = e.target.value;
        if (name) {
          state[name] = value;
            setState((prevState) => {
              return {...state}
            });
        }
    }

    const uploadDocs = (e) =>{
        let file = e.target.files ? e.target.files[0] : null;

        let docType = e.target.getAttribute("data-id");
        setLoading(true)
        if (file) {
          ApiHelper.uploadFile({
            repoType: "OPP_DOCS",
            docTypeName: "OPP_INV_PD",
            fileType: "DOC",
            metaType:"OPPDOC",
            file: file,
            callback: result => {
              setState((prevState) => ({...prevState, ["pitchDeck"]: result })); 
              setLoading(false)
            },
            errCallback:err=>{
               setLoading(false)
            }
          });
        }else{
            setLoading(false)
        }
      }

    const handleEndDateValueChange = (date) =>{
        console.log(Helpers.getDateInInputFormat(date),"123")
        state["targetDate"] = date;
        setState((prevState) => {
          return {...state}
        });
    }

    const vaidateForm = () => {
        let isValid = true;
        let fields = [
            "investmentAmount",
            "investmentOffer",
           // "targetDate",
            "pitchDeck"
        ]


        let errors = {}
    
        fields.forEach((field) => {
          if (Helpers.isEmpty(state[field])) {
            isValid = false;
            errors[field] = "This field is mandatory"
          } else {
            errors[field] = ""
          }
        })
    
        setErrors(errors);
        return isValid;
    }

    const deleteDoc = (e)=>{
            e.stopPropagation();
            setState((prevState) => ({...prevState, ["pitchDeck"]: {} }));
    }

    const saveAndProceed = ()=>{
        var formValid = vaidateForm();
        if(!formValid){
            Helpers.pushError("Please fill all the required fields.");
            return false;
        };
        setLoading(true)
        var payload = state;
        payload.investmentAmount = state.investmentAmount;
        payload.investmentOffer = state.investmentOffer;
        payload.targetDate = Helpers.getDateInInputFormat(state.targetDate);
        payload.pitchDeck = state.pitchDeck;
        
       

        Axios.put(Helpers.apiURL({ uri: "api/opportunity/" }), payload)
        .then(response => {
           setLoading(false)
            Helpers.pushSuccess("Opportunity Details has been updated!");
            let data = response.data;
            let id = data.id ? data.id : null;
            let paid = payload && payload.page.paid;
            let isPublished = payload && payload.page.regStatus && payload.page.regStatus.value == "PUBLISHED";
            if (id) {
              if(config.oppDirectPayment){
                window.location = paid ? `/opportunity/${payload.id}?isPublished=${isPublished}`:`/opportunity/proceed?targetEntityId=${payload.id}&paymentCategory=OPPORTUNITY`;
              }else{
                window.location = `/opportunity/${payload.id}?isPublished=${isPublished}`
              }
            }
         
         
  
        })
        .catch(err => {
          setLoading(false)
          Helpers.pushError(err.response.data || err.response.data.message)
        });

    }

    console.log(state,"state")


    return (
        <div className="row full-width bold-headers">
            <LoadingOverlay>
              <Loader fullPage loading={loading} />
            </LoadingOverlay>
            <div className="col-md-12 text-left m-t-20 m-b-20">
                <div className="col-md-12">
                    <h1>Investment</h1>
                </div>
            </div>
            <div className="col-md-12">
                <div className="card">
                    <div className="card-body text-left">
                         <h5 className="m-t-0">Opportunity Details</h5>
                         <EditText
                                name="investmentAmount"
                                title="Investment Amount (USD)"
                                value={state && state.investmentAmount}
                                inputType="number"
                                placeholder={""}
                                required={true}
                                onValueChange={handleFieldValueChange}
                                errorMsg={errors && errors["investmentAmount"] ? errors["investmentAmount"]  : ""}
                            />
                             <EditText
                                name="investmentOffer"
                                title="Minimum Investment Offer (USD)"
                                inputType="number"
                                value={state &&  state.investmentOffer}
                                placeholder={""}
                                pophover={true}
                                target={"investmentOffer"}
                                hoverText="This shall mean the minimum amount an investor can invest in."
                                required={true}
                                onValueChange={handleFieldValueChange}
                                errorMsg={errors && errors["investmentOffer"] ? errors["investmentOffer"]  : ""}
                            />
                            <EditDate
                                    name="targetDate"
                                    title="Target Date"
                                    showTimeSelect={false}
                                    value={state &&  state.targetDate}
                                    placeholder={""}
                                    required={true}
                                    onValueChange={handleEndDateValueChange}
                                    errorMsg={errors && errors["targetDate"] ? errors["targetDate"]  : ""}
                                    />

                            <div className="docUploadFields float-left col-md-6 p-0">
                                      <h5 className="font-14">Pitch Deck<span style={{color:"red"}}>*</span></h5>
                                        <label className="custom-file-upload">
                                            <input type="file" 
                                                    name="uploader" 
                                                    value=""
                                                    className="docUploader" 
                                                    // ref={"uploader"} 
                                                    onChange={uploadDocs}
                                                    accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"/> 
                                        </label>
                                        {
                                          state && !Helpers.isEmpty(state.pitchDeck) && 
                                                        <div key={state.pitchDeck.uniqueId} id={state.pitchDeck.uniqueId}> 
                                                        <a href={Helpers.viewFile(state.pitchDeck.uniqueId)} target="_blank">{state.pitchDeck.name}</a>
                                                        <a key={state.pitchDeck.uniqueId+"trigger"} data-id={state.pitchDeck.uniqueId} data-mainId={state.pitchDeck.id}
                                                                onClick={deleteDoc}
                                                                className="text-danger m-l-5"
                                                                >
                                                                <i className="fa fa-times" />
                                                                </a>
                                                    </div>
                                            }
                            </div>
                            
                    </div>
                </div>
                <div className="col-sm-12 col-xs-12 p-0 m-t-10">
                               {state && !Helpers.isEmpty(state.id) && owner && <DeletePage type="Opportunity" uri={state.id} />}
                                <button
                                    type="submit"
                                    className="btn btn-info waves-effect- waves-light- float-right"
                                    onClick={saveAndProceed}
                                >
                                  {config.oppDirectPayment ? "Save and Proceed to Payment" : "Save"} 
                                </button>
                        </div>
            </div>
        </div>
    )
}

export default InvestmentForm
