import React, { Component } from 'react'

import { LinkedIn } from 'react-linkedin-login-oauth2';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { tokenAuthenticate, login } from '../../actions/authAction';
import { LoadingOverlay, Loader } from 'react-overlay-loader';

import config from '../../shared/config';
import store from '../../store';
import Axios from 'axios';
import Helpers from '../../shared/custom';

class LinkedInAuth extends Component {
    constructor(props){      
        super(props);
        
        this.state = {
            code: undefined,
            errorMessage: undefined,
            token:undefined,
            userInfo:{},
            emailInfo:{},
            
            loading:false,
            debug:false, //// for testing, should be false in production
            debugRegister:false, 
        }

        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleFailure = this.handleFailure.bind(this);
        this.exchangeForToken = this.exchangeForToken.bind(this);
        this.handleFetchUserInfo = this.handleFetchUserInfo.bind(this);
        this.registerUser = this.registerUser.bind(this);
        this.authenticateUser = this.authenticateUser.bind(this);

        store.subscribe(() => { 
          var storeState = store.getState();       
          this.setState({auth:storeState.auth});        
        }); 
    } 
    
    handleSuccess(data){       
        this.exchangeForToken(data);
    }

    handleFailure(data){
        Helpers.pushTError("LINKEDIN_FAIL");
    }

    exchangeForToken(response){        
        let code = response.code;
        this.setState({loading:true});
        Axios.get(
            Helpers.apiURL({
                uri:"api/linkedin/token-profile",
                query:{linkedCode:code}
            }),            
        ).then((response) => {
            let result = response.data.result; 
            let userInfo = result.profile;
            let emailInfo = result.email;

            if(typeof userInfo === "string"){
                userInfo = JSON.parse(userInfo);                
            }

            if(typeof emailInfo === "string"){
                emailInfo = JSON.parse(emailInfo);                
            }

            this.handleFetchUserInfo(userInfo,emailInfo);     

            return;
        })
        .catch((err) => {
            this.setState({loading:false});
            Helpers.pushError(err.response.data.message)
        })
    }


    handleFetchUserInfo(userInfo,emailInfo){
        
        this.setState({
            userInfo:userInfo,
            emailInfo:emailInfo
        });

        if(this.state.debug){
            userInfo = {
                "firstName": "Test User",
                "headline": "position at company",
                "id": "6CR3h-_ADD",
                "lastName": "Test Lastname",
                "siteStandardProfileRequest": {
                    "url": "https://www.linkedin.com/profile/view"
                }
            }
            this.authenticateUser(userInfo.id,userInfo);
            return;
        }
        
        let userId = userInfo.id || null;
        if(userId){   
            this.authenticateUser(userId,userInfo,emailInfo);
        }else{
            this.setState({loading:false});
            Helpers.pushTError("LINKEDIN_FAIL");
        }

    }

    authenticateUser(id,userInfo,emailInfo){

        if(this.state.debug && this.state.debugRegister){
            id="123";
        }

        Axios.post(
            Helpers.apiURL({
                uri:"api/linkedin/login"
            }),
            id,
            {headers: {'Content-Type': 'text/plain'}}
        ).then((response)=>{
            let token = response.data.result;
            this.setState({loading:false});
            if(token){
                this.props.tokenAuthenticate(token)
            }    
        }).catch((err)=>{
            let response = err.response;
            if(response.status == "403"){
                this.registerUser(emailInfo,userInfo);
                //this.fetchEmail(userInfo);            
            }else{
                Helpers.pushTError("LINKEDIN_FAIL");
                this.setState({loading:false});
            }           
        })
    }


    registerUser(emailInfo,userInfo){
       
        let registerSate = {
            fullName:undefined,
            email:undefined,
            linkedinKey: undefined
        }

        if(emailInfo.elements && emailInfo.elements.length > 0){
            let element = emailInfo.elements[0];
            registerSate.email = element["handle~"] ? element["handle~"].emailAddress : undefined;
        }

        if(userInfo){
            let fullName = userInfo.firstName ? userInfo.firstName+" " : "";
            fullName+=userInfo.lastName ? userInfo.lastName : "";
            registerSate.fullName = fullName;
            registerSate.linkedinKey = userInfo.id;
        }
        
        
        this.props.history.push({
            pathname:'/register',
            state:{linkedin:registerSate}
        });
        

    }

    showButton(label){
        return (
            // <div className="row">
            //         <div className="col-xs-12">
            //             <div className="social font-14">
            //                 <div className="btn btn-info btn-lg btn-block btn-normal font-14 waves-effect waves-light text-white no-hover" title=""> 
                                <div><i aria-hidden="true" className="fab fa-linkedin-in font-14"></i> &nbsp; | &nbsp;{label} </div>
                //         </div>
                //     </div>
                // </div>
        )
    }

    render() {        
        let scope = "r_liteprofile,r_emailaddress";//,w_member_social";
        let linkedInBtnText = this.props.label || "Login with LinkedIn";

        return (
        <div className="empty-buttons smallAuto">
            {
                this.state.loading ?
                    <div>
                        <button type="button" className="btn-linkedin m-t-10 m-b-15">
                                {this.showButton("Authenticating...")}
                        </button>
                        <LoadingOverlay>
                            <Loader fullPage loading={this.state.loading}/>
                        </LoadingOverlay>
                    </div>
                :
                    <LinkedIn
                        clientId={config.linkedInClientId}
                        onFailure={this.handleFailure}
                        onSuccess={this.handleSuccess}
                        redirectUri={config.linkedInRedirect}
                        scope={scope}
                        className="btn-linkedin"
                    >
                        {this.showButton(linkedInBtnText)}
                    </LinkedIn>            
            }
            
        </div>
        )
    }
}

export default connect(null,{tokenAuthenticate})(withRouter(LinkedInAuth))
