import React, { Component } from 'react';
import Helper from "../../shared/custom";
import config from '../../shared/config';
import OppCard from './OppCard';

export default class PublicOpps extends Component {

  constructor(props){
    super(props);
    this.state ={
      opps:[],
      indexedEvents:[],
      pageTitle:'',
      pageOfItems: [],
      allItems: [],
      activePage: 1,
      setPageContext:undefined,
      totalRecords: 0,
      sectorId:undefined,
      itemsCountPerPage:config.defaultPageSize,
    }
    
  }
  componentWillReceiveProps(props){
    var data = props.data;
    this.setState({
      opps:props.data
    })
  }
  render() {
    let dataset =[]
    if(!Helper.isEmpty(this.state.opps)){
      dataset  = this.state.opps
    }
    return (
        <div>
            <div className="card text-left bold-headers">
                <div className="card-body">
                    <h5><b>Opportunities</b></h5>
                   <div className="row">
                        { (!Helper.isEmpty(dataset) && (dataset.length > 0))?
                              dataset.map((data,index )=> (
                                <div className="col-md-6 m-t-10" key={index+"public"}>
                                  <div>
                                      <OppCard data={data} from="publicOpp"/>
                                    </div>
                                </div>
                          ))
                          :
                          <div className="text-center col-12">No opportunities Found</div>
                        }
                    </div>
               </div> 
             </div>     
      </div>
     
    )
  }
}
