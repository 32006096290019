import React, { Component } from 'react';

class CompanyProgresBar extends Component {
  
  constructor(props){
    super(props);
    this.state = {
        progress: this.props.progress,
    }
   
  }

  render() { 
    var progressBarStyle = { width: this.state.progress + "%", height: "30px" };
    let progress = Math.ceil(this.state.progress);
    return (
        <div className="card  bold-headers text-center" id="progress">
        <div className="card-body m-b-30 m-t-30">
          <h4>
            Your Company Profile is{" "}
            <span className="text-green">{progress}% </span>
            Complete.
          </h4>
          <div className="col-8 m-a">
            <div className="progress progress-radius m-t-20">
              <div
                className="progress-bar bg-green"
                role="progressbar"
                style={progressBarStyle}
                role="progressbar"
              >
                {" "}
                {progress}{" "}
              </div>
            </div>
          </div>
          <h4 className="font-normal">
            Fill all the sections displayed below to complete profile and improve your company's online strength.
          </h4>
          {/* <button className="m-t-10 btn btn-info waves-effect waves-light">
            Add More Company Details
          </button> */}
        </div>
      </div>
    );
  }
}

export default (CompanyProgresBar);