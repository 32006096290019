import React, { Component } from 'react';
import { BrowserRouter as Router, Route,Redirect, Link ,Switch ,withRouter} from "react-router-dom";
import AllOpportunities from './AllOpportunities';
import MyOpportunity from "./MyOpportunity";
import OppBySector from "./OppBySector";
import store from '../../store';
import { connect } from 'react-redux';
import PrivateRouter from '../../utils/PrivateRouter';




class OppSummaryRouter extends Component {

  constructor(){
    super();
    this.state ={
        searchCriteria:{},
        criteriaUpdate:undefined,
        isLoggedIn :false,
        setPageContext:undefined,
        context:undefined,
        locationIds:"",
        filterDates:{}
    }

  }


  componentWillReceiveProps(props){
    this.setState({criteriaUpdate:props.criteriaUpdate,
                   searchCriteria:props.searchCriteria,
                   setPageContext:props.setPageContext,
                   context:props.context,
                   metaList:props.metaList,
                   business:props.business,
                   locationIds:props.locationIds,
                   filterDates:props.filterDatesAll
                  });
  }

  render() {
    return (
      <Switch>
              <Route
                path='/opportunities/my-opportunities'
                exact={true}
                render={(props) => (
                  true
                    ? <MyOpportunity {...props}  
                              criteriaUpdate={this.state.criteriaUpdate} 
                              searchCriteria={this.state.searchCriteria} 
                              setPageContext={this.state.setPageContext}
                              locationIds = {this.state.locationIds}
                              filterDates ={this.state.filterDates} 
                            />
                    : <Redirect to={{
                          pathname: '/login',
                          state: { from: props.location }
                        }}
                      />
                )} />

              <Route
                path='/opportunities/sector/:sector'
                exact={true}
                render={(props) => (
                  true
                    ? <OppBySector {...props}  
                              criteriaUpdate={this.state.criteriaUpdate} 
                              searchCriteria={this.state.searchCriteria} 
                              setPageContext={this.state.setPageContext}
                              metaList={this.state.metaList} 
                              business={this.state.business}  
                              locationIds = {this.state.locationIds} 
                              filterDates ={this.state.filterDates} 
                            />
                    : <Redirect to={{
                          pathname: '/login',
                          state: { from: props.location }
                        }}
                      />
                )} />
              <Route
                path='/opportunities'
                exact={true}
                render={(props) => (
                    <AllOpportunities {...props}  
                            criteriaUpdate={this.state.criteriaUpdate} 
                            searchCriteria={this.state.searchCriteria} 
                            setPageContext={this.state.setPageContext} 
                            metaList={this.state.metaList} 
                            business={this.state.business} 
                    />
                )} />

              <Route
                path='/'
                exact={true}
                render={(props) => (
                    <AllOpportunities {...props}  
                            criteriaUpdate={this.state.criteriaUpdate} 
                            searchCriteria={this.state.searchCriteria} 
                            setPageContext={this.state.setPageContext}  
                            metaList={this.state.metaList} 
                            business={this.state.business} 
                            />
                )} />

      </Switch>
    )
  }
}


export default withRouter(OppSummaryRouter);

