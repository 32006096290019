import React, { Component } from 'react';
import Sidemenu from '../sidemenu/Sidemenu';
import store from '../../store';
import Helper from "../../shared/custom";
import config from '../../shared/config';
import UserFeedRightPanel from '../homepage/UserFeedRightPanel';
import { connect } from 'react-redux';
import { withRouter, Redirect, Link} from 'react-router-dom';
import AuthorDetails from '../feeds/AuthorDetails';
import ReadMoreAndLess from 'react-read-more-less';
import Axios from 'axios';

export default class PostDetails extends Component { 

    constructor(props){
        super(props);
        this.state ={
            uri:undefined,
            postDetails:[],
            parentLocation:undefined,
        }
    this.getPostDetails = this.getPostDetails.bind(this);
  }
  componentDidMount(){
    this.setState({
        uri:this.props.match.params.uri,
    },()=>{
        if(!Helper.isEmpty(this.state.uri)){
          this.getPostDetails(this.state.uri);
        }
        
    })
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
}

getPostDetails(uri){
    Axios.get(
        Helper.apiURL({
            uri:"post/"+uri+"/details",
        })
    )
    .then( (response) => {     
        var post = response.data.result || [];
        this.setState({
            postDetails:!Helper.isEmpty(post) ? post : []
        })
    })
    .catch((err) => {
        this.setState({
            postDetails:[]
        })
    });
}

  render() {
    let data =  this.state.postDetails;
    let sharedUrl = data && data.uri ? "/user/"+data.uri : "";
    let validUrl = Helper.isUrl(data.body);

    return (
      <div>
          <div className="row content-in m-t-80 p-t-10">
                    
                    <div className="col-lg-3 col-xlg-3 col-md-3 sideMenuCont">
                         <Sidemenu/>
                    </div>
                    <div className="col-lg-6 col-xlg-6 col-md-6 centerDiv">
                        { <div>
                            {!Helper.isEmpty(data) ? 
                                 <div className="card additionalClass" id="contentCard">
                                    <div className="card-body text-left">
                                        <Link to={sharedUrl}>
                                            <img src={Helper.dataPath(Helper.isEmpty(data.owner && data.owner.image) ? config.defaultUserImage : data.owner.image)} className="img-circle float-left" width="50" height="50"/> 
                                            <div className="m-l-10  m-t-10 float-left text-default">
                                                <div className="font-14 font-bold m-b-u-10">{data && data.owner && data.owner.fullName ? data.owner.fullName  :""} shared a POST<span className="text-info"></span></div>
                                                <span className="font-10">{Helper.getDateInAgoFormat(data.updatedDate ? data.updatedDate :new Date())}</span>
                                            </div>
                                        </Link>
                                    </div>
                              
                                    {!Helper.isEmpty(data && data.images) ?
                                         <div className="card-body">
                                             <div className="col-lg-12 p-0">
                                                 <div className="m-a text-center">
                                                 <img src={Helper.dataPath(Helper.isEmpty(data.images && data.images[0])?config.defaultNewsIcon : data.images[0].publicUri)} className="controled-img m-w" />
                                                 </div>
                                             </div>
                                         </div>
                                         :
                                         ""
                                    }
                                    <div className="card-body text-left">
                                        <div className="col-lg-12 p-0 font-14">
                                            <div className="col-lg-12 p-0  text-left">
                                                <div className="font-medium">
                                                { !Helper.isEmpty(data.body) ?
                                                    validUrl ?
                                                            Helper.isVideoUrl(data.body) ?
                                                                <embed src={Helper.getEmbbedVideoLink(data.body)}  style={{"width":"100%", "height":" 400px"}}/>
                                                                :
                                                                <a rel="noreferrer" href={Helper.properExternalUrl(data.body)} target="_blank">{data.body}</a>
                                                                :
                                                            Helper.newLineToBrWithLink(data.body).map((elem)=>(
                                                                <div className="content" dangerouslySetInnerHTML={{__html:elem.props.children}}></div> 
                                                            ))
                                                        :
                                                        ""
                                                }
                                                </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>

                                :
                          
                                <div className="card additionalClass" id="contentCard">
                                    <div className="card-body">
                                        <div className="col-lg-12 p-0 text-center">
                                            No Data Available
                                        </div>
                                    </div>
                                </div>

                            }
                          
                      </div> }
                    </div>
                    <div className="col-lg-3 col-xlg-3 col-md-3 m-t-2 right-side">
                        <UserFeedRightPanel/>
                    </div>
                </div>
      </div>
    )
  }
}
