import React, { Component } from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
 
export class MapContainer extends Component {
  render() {
    return (
      <Map 
            google={this.props.google}
            zoom={14}
            initialCenter={{
                lat: this.props.lat,
                lng: this.props.lng
              }}
            >
        <Marker onClick={this.onMarkerClick}
                name={'Current location'} />
      </Map>
    );
  }
}
 
export default GoogleApiWrapper({
  apiKey: ("AIzaSyCxfcp0_HulWwnmPoYWM3Lx1RIZErGSa4c")
})(MapContainer)