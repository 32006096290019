import React, { Component } from "react";
import Helper from "../../shared/custom";
import Interest from "../uiActions/Interest";
import config from "../../shared/config";

export default class EventCard extends Component {
  render() {
    let event = this.props.data;
    let keyName = !Helper.isEmpty(this.props.key) ? this.props.key : this.props.keyName;
    let interested = false;
    let interestCount = 0;

    if (!Helper.isEmpty(event._isIntrested)) {
      interested = event._isIntrested;
    } else {
      interested = event.interested;
    }
    if (!Helper.isEmpty(event._intrestedCount)) {
      interestCount = event._intrestedCount;
    } else {
      interestCount = event.interestCount;
    }

    let image ;
    let url;
    if(this.props.mode =="my-events"){
      image = event.coverPhoto && event.coverPhoto.uniqueId ?   Helper.viewFile(event.coverPhoto.uniqueId) : event.image ? Helper.dataPath(event.image) : Helper.dataPath(config.defaultEventIcon);
      url = event.uri ? "/event-details/" + event.uri : ""
    }else{
      image =  event.coverPhoto && event.coverPhoto.uri ?  Helper.dataPath(event.coverPhoto.uri) : event.image ? Helper.dataPath(event.image) : Helper.dataPath(config.defaultEventIcon); 
      url = event.uri ? "/event/" + event.uri : ""
    }

    let page = this.props.page;
    let mode = this.props.mode;

    return (
      <div key={keyName} className="eventsCard">
        {mode == "public" ? 
            ""
            :
            <a href={url} className="eventHolder overflow-hidden">
              <img
                className="m-w"
                src={
                  image
                }
                alt="Image"
              />
            </a>
        }
        
        <div className="col-md-12 font-14  p-0 event-top">
          <div className="col-md-12 float-left text-left p-0">
            <h5 className="font-bold m-0 m-l-5 p-0 smallText">
              <a href={url} className="font-14 m-t-5">
                {Helper.truncate(event.name,50)}
              </a>
            </h5>
            <span className="font-10 clearfix m-0">
            <i className="mdi mdi-calendar"></i> {Helper.getDateInInputFormat(event.startDate)}<br/>
            { event && event.location && <><i className="mdi mdi-map-marker"></i> {event && event.location}</> }
            {event && event.city && <><i className="mdi mdi-map-marker"></i> {event && event.city && event.city.name ? event.city.name : ""}, {event && event.country && event.country.name ? event.country.name : ""}</>}
            </span>
            {mode == "public" ?  "" : <span className="font-10"><i className="mdi mdi-star"></i> {interestCount} Interested</span>}
          </div>
          
          <div className="col-md-12 m-t-10 font-14 m-t-20 p-0 clearfix">
                 <div className="like-comm text-left"> 
                     <Interest id={event.id} type={"EVENT"} interested={event._isIntrested} masterClassName="float-right m-t-5 m-l-5 font-12"/>
                   </div>
                   {Helper.isEmpty(event.website) ? 
                      "": 
                     <a rel="noreferrer" href={Helper.properExternalUrl(event.website)}  target="_blank" className="float-right m-l-5 m-t-2"><i className="material-icons font-24">language</i></a>
                   }
                     </div>
        </div>
      </div>
    );
  }
}
