import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Sidemenu from "../sidemenu/Sidemenu";
import UserProfileContent from "./UserProfileContent";
// import OpportunityRightpanel from './OpportunityRightpanel';
import Helper from "../../shared/custom";
import { connect } from "react-redux";

import Axios from "axios";
import { getMetaData } from "../../actions/metaAction";
import { getSectorData } from "../../actions/businessAction";
import store from "../../store";
import Rightpanel from "./Rightpanel";

import UserFeedRightPanel  from "../homepage/UserFeedRightPanel";


import { setUserProfile } from '../../actions/authAction';

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      business: {},
      metaList: {},
      currentUser: {},
      industryList: [],
      types: [],
      isProfileOwner: false,
      profileDetails: {},
      localProps:{}

    };

    store.subscribe(() => {
      var storeState = store.getState();
      var localMetaList = this.state.metaList;
      var storeMetaList = storeState.meta.metaList;

      
      if (
        JSON.stringify(localMetaList) != JSON.stringify(storeMetaList)
      ) {
        this.setState({ metaList: storeMetaList });
      }

      var localBusiness = this.state.business;
      var storeBusiness = storeState.business.data;
      if (
        JSON.stringify(localBusiness) != JSON.stringify(storeBusiness)
      ) {
        this.setState({ business: storeBusiness });
      }
    });
  }

  componentDidMount() {

   
    let uri = this.props.match.params.uri;
    var storeState = store.getState();
    let meta = storeState.meta;
    let profileDetails = storeState.auth.profileDetails;
   // let business = {}
    if (storeState.auth.isAuthenticated === true) {
      this.setState(
        {
          currentUser: storeState.auth.user,
          isProfileOwner: storeState.auth.user.uri === uri ? true : false
        },
        function() {
         
        }
      );
    }

    if(Helper.isEmpty(profileDetails)){
        this.getProfile(uri)
    }else{
      this.setState({
        profileDetails:profileDetails
      })
    }

   // if (Helper.isEmpty(business.data) == true) {
      this.props.getSectorData();
    // } else {
    //   this.setState({ business: business.data }, function() {});
    // }
     this.getProfile(uri)
    
  }


  componentWillReceiveProps(props){
      if(JSON.stringify(props) != JSON.stringify(this.state.localProps)){
        this.setState({
          localProps:props
        })
      }
      let uri = props.match.params.uri;
      this.getProfile(uri)
  }


  getProfile=(uri)=>{
    if (!uri) {
      this.props.history.push({
        pathname: "/"
      });
    }

    Axios.get(
      Helper.apiURL({
        uri: "user/:uri/profile",
        pathVar: { uri: uri }
      })
    )
      .then(response => {
        let profileDetails = response.data.result || null;
        if (!Helper.isEmpty(profileDetails)) {
          localStorage.setItem("profile",JSON.stringify(profileDetails)); 
          
          this.setState(
            {
              profileDetails: profileDetails
            },
            function() {
              
            }
          );
        } else {
          this.setState({
            profileDetails: null
          });
        }
      })
      .catch(err => {
        Helper.pushTError("PROFILE_NOT_FOUND");
      });
  }

  render() {
    if (!this.state.isProfileOwner) return <div />;

    return (
      <div>
        <div className="container-fluid">
          <div className="row content-in m-t-80 p-t-10">
            <div className="col-lg-3 col-xlg-3 col-md-3 searchMenu sideMenuCont">
              <Sidemenu mode="people" />
            </div>
            <div className="col-lg-6 col-xlg-6 col-md-6 centerDiv">
              <UserProfileContent
                profileDetails={this.state.profileDetails}
                currentUser={this.state.currentUser}
                business={this.state.business}
                metaList={this.state.metaList}
              />
            </div>
            <div className="col-lg-3 col-xlg-3 col-md-3 m-t-2 userProfileRight right-side">
              {/* <Rightpanel profileDetails={this.state.profileDetails} /> */}
              <UserFeedRightPanel/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { getMetaData, getSectorData,setUserProfile }
)(withRouter(UserProfile));
