import React, { Component , useEffect, useState , useRef } from 'react';
import { Link } from 'react-router-dom';
import config from '../../../shared/config';
import countries from "../../../shared/country";
import Helpers from '../../../shared/custom';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import Axios from "axios";
import ApiHelper from "../../../shared/ApiHelper";
import Carousal from "./Carousal";

import EditText from "../../ui/EditText";
import EditSelect from "../../ui/EditSelect";
import Paginator from "./Paginator";
import EditMultiSelect from "../../ui/EditMultiSelect";
import store from '../../../store';
import { getPackageDetails } from '../../../actions/packageAction';


function Content() {
    const [countryOptions, setCountryOptions] = useState(() => []);
    const [loading, setLoading] = useState(() => false);
    const [cityList, setCityList] = useState(() => []);
    const [cityOptions, setCityOptions] = useState(() => []);
    const [state, setState] = useState(() => []);
    const [filterMode, setFilterMode] = useState(() => false);
    const [showFilter, setShowFilter] = useState(() => true);
    const [showFilterPanel, setShowFilterPanel] = useState(() => false);

    const [featured, setFeatured] = useState(() => {});
    const [recommended, setRecommended] = useState(() => {});
    const [latest, setLatest] = useState(() => {});

    const [payload, setPayload] = useState(() => {});
    const [arrowClass, setArrowClass] = useState(() => "fa-chevron-right");

    const [ privileges,setPrivileges] = useState(() => {});

    const types = [
        {
            key:"search",
            label:"Latest",
            data:{}
        },
        {
            key:"featured",
            label:"Featured",
            data:{}
        },
        {
            key:"recommended",
            label:"Recommended",
            data:{}
        }
    ]


    useEffect(() => {
        // getPackageDetails()
        // var storeState = store.getState();
        // let subscription =  storeState.packageDetails;
        // setPrivileges(subscription.privileges);
        // console.log(subscription,"subscription")
        let countryOptions = Helpers.makeSelectOptions(countries);
        setCountryOptions(countryOptions);
        fetchSummaryData();
    }, [])

    const fetchSummaryData = ()=>{
        let sortBy = "created";
        types.forEach((list)=>{
            setLoading(true)
            Axios.get(
                Helpers.apiURL({
                    uri:"api/opportunity/:type",
                    pathVar: {type:list.key},
                    query:{sort:sortBy,size:30}
                })
                )
                .then((response) => {
                     let result = response.data.content;
                     if(list.key == "featured"){
                        setFeatured(result)
                     }else if(list.key == "recommended"){
                        setRecommended(result)
                     }else{
                        setLatest(result)
                     }
                     setLoading(false)
                })
                .catch((response) => {
                    setLoading(false)
                })
        });

    }



    const  handleSelectChange = (option, e) => {
        let thisFieldName = e.name;
  
        if (option && option.length == 1 && !Helpers.isEmpty(option[0].value) && thisFieldName == "country") {
            setLoading(false)
            ApiHelper.getCities(option[0].value, setAddressCityList);
          }else{
              setCityList({});
              setState((prevState) => ({...prevState, ["city"]: [] }));
             // setCityOptions([]);
          }
      
          setState((prevState) => ({...prevState, [thisFieldName]: option }));
        
    }

    const handleFieldValueChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name) {
            setState((prevState) => ({...prevState, [name]: value }));
        }
    }


    const setAddressCityList = (cityList) => {
        let cityOptions = Helpers.makeSelectOptions(cityList);
        setCityList(cityList);
        setCityOptions([...cityOptions]);
        setLoading(false)
    }

    const toggleClass = ()=>{
        let chevron = arrowClass != "fa-chevron-down" ? "fa-chevron-down" : "fa-chevron-right";
        setArrowClass(prevState=>chevron);
    }

    const doSearch = (mode)=>{
    
        var qs = {};
        qs.title =  state && state.title ? state.title : null;
        qs.countryIds = state && state.country ? state.country.map(x=>x.value).join(",") : [];
        qs.cityIds = state && state.city ? state.city.map(x=>x.value).join(",") : [];
        qs.oppTypes = state && state.oppTypes ? state.oppTypes.map(x=>x.value).join(","): [];
       // debugger;
        if(!Helpers.isEmpty(mode)){ qs.mode = mode}
        setPayload(qs);
        setShowFilter(false);
        setShowFilterPanel(false);
        setFilterMode(true)
    }

    const back = ()=>{
        setFilterMode(false);
        setShowFilter(true);
        setShowFilterPanel(false);
        setState({})
    }


   const backToSearch = ()=>{
      setShowFilter(prevState=>true);
      setShowFilterPanel(true);
   }


   console.log(showFilterPanel,"showFilterPanel")

    return (
        <div className="col-md-12">
             <LoadingOverlay>
              <Loader fullPage loading={loading} />
            </LoadingOverlay>
            {showFilter ? 
               <div className="card text-left b-header">
                      <div className="card-header text-left p-0">
                          <div className="col-md-8 float-left light-blue-header-half header-div">Opportunities</div>
                          <div className="col-md-4 float-left text-right">
                                 {filterMode && <a onClick={back} className="font-14 m-t-15 float-left"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</a>}
                                 <Link to="/create-opportunity/start" className="btn btn-theme-white float-right addBtn m-t-10"> <i className="fas fa-user-plus"></i> Add Opportunity</Link>
                         </div>
                     </div>
                    
                    <div className="card-body p-t-10 p-b-5 bg-white filterBody">
                        <div className="panel-group">
                                <div className="panel panel-default">
                                    <div className="panel-heading pointer" data-toggle="collapse" href="#collapse">
                                        <h4 className="panel-title text-left formLabel" onClick={toggleClass}>
                                            Filter: <i className={`fa ${arrowClass} float-right`}></i>
                                        </h4>
                                    </div>
                                    <div id="collapse" className={showFilterPanel ? "panel-collapse collapse p-b-20 show" : "panel-collapse collapse p-b-20"}>
                                        <div className="row text-left">
                                            <div className="col-md-6 float-left m-t-10">
                                                <EditText
                                                        name="title"
                                                        title="Title"
                                                        value={state.title}
                                                        addLabelClass={"formLabel"}
                                                        placeholder={"Opportunity Title"}
                                                        addClass="mt-10 col-md-12"
                                                        onValueChange={handleFieldValueChange}
                                                    />
                                            </div>
                                            <div className="col-md-6 float-left m-t-10">
                                                    <EditMultiSelect
                                                                name="oppTypes"
                                                                title="Type"
                                                                value={state.oppTypes}
                                                                options={config.opportunityTypes}
                                                                onChange={handleSelectChange}
                                                                additionalClassName="preFilled"
                                                                titleClass={"formLabel"}
                                                                isClearable={true}
                                                            />
                                            </div>
                                            <div className="col-md-6 float-left m-t-10">
                                                    <EditMultiSelect
                                                                title="Country"
                                                                name="country"
                                                                value={state.country}
                                                                options={countryOptions}
                                                                onChange={handleSelectChange}
                                                                additionalClassName=" "
                                                                titleClass={"formLabel"}
                                                                isClearable={true}
                                                         
                                                            />
                                            </div>
                                            <div className="col-md-6 float-left m-t-10">
                                                    <EditMultiSelect
                                                                title="City/Town"
                                                                name="city"
                                                                value={state.city}
                                                                options={cityOptions}
                                                                onChange={handleSelectChange}
                                                                additionalClassName="mt-10"
                                                                titleClass={"formLabel"}
                                                                isClearable={true}
                                                                isOptionDisabled = {state && state.country && state.country.length > 1}
                                                            />
                                            </div>
                                        </div>
                                        <div className="row clearfix m-t-20">
                                            <div className="col-md-12">
                                                <button className="btn btn-theme float-right text-white btn-normal font-16" onClick={doSearch}>Search</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
            </div>
            : 
                <div className="card text-left b-header">
                        <div className="card-header text-left p-0">
                            <div className="col-md-8 float-left light-blue-header-half header-div">Opportunities</div>
                            <div className="col-md-4 float-left text-right">
                                    {filterMode && <a onClick={back} className="font-14 m-t-15 float-left"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</a>}
                                    <Link to="/create-opportunity/start" className="btn btn-theme-white float-right addBtn m-t-10"> <i className="fas fa-user-plus"></i> Add Opportunity</Link>
                            </div>
                       </div>
                       
                        <div className="card-header text-left p-0">
                            <div className="col-md-12 float-left light-blue-header text-white header-div">Search Results for:</div>
                        </div>
                        <div className="card-body p-t-10 p-b-5 bg-white filterBody">
                                {!Helpers.isEmpty(state.title) ? <h3 className="font-12 m-t-0 l-h-22">Title: <span className="font-normal">{state.title}</span></h3> : ""}
                                {!Helpers.isEmpty(state.oppTypes) ? <h3 className="font-12 m-t-0 l-h-22">Type: <span className="font-normal">{state.oppTypes.map(x=>x.label).join(",")}</span></h3> : ""}
                                {!Helpers.isEmpty(state.country) ? <h3 className="font-12 m-t-0 l-h-22">Country: <span className="font-normal">{state.country.map(x=>x.label).join(",")}</span></h3> : ""}
                                {!Helpers.isEmpty(state.city) ? <h3 className="font-12 m-t-0 l-h-22">City/Town: <span className="font-normal">{state.city.map(x=>x.label).join(",")}</span></h3> : ""}
                                <div className="row clearfix m-t-20">
                                    <div className="col-md-12">
                                        <a className="float-right text-danger btn-normal font-16" onClick={backToSearch}><i className="fa fa-edit"></i> Edit Search</a>
                                    </div>
                                </div>
                        </div>
                </div>
            }
            
            
               
                 {filterMode ? 
                    <div className="card">
                        <div className="card-body">
                             <Paginator payload={payload}/>
                        </div>
                     </div>
                     :
                     types.map((list,i)=>(
                       <React.Fragment key={i+"opp"}>
                          {list.key == "featured"  && 
                                    <div className="card">
                                        <div className="card-body">
                                            <Carousal heading={list.label} data={featured} doSearch={doSearch} mode="featured"/>
                                        </div>
                                    </div>}
                          {list.key == "recommended"  && 
                                        <div className="card">
                                            <div className="card-body">
                                                <Carousal heading={list.label} data={recommended}  doSearch={doSearch} mode="recommended"/>
                                            </div>
                                        </div>}
                          {list.key == "search"  && 
                                            <div className="card">
                                                <div className="card-body">
                                                    <Carousal heading={list.label} data={latest}  doSearch={doSearch} mode="latest"/>
                                                </div>
                                            </div>}
                       
                       </React.Fragment>
                     ))
                    }
        </div>
    )
}

export default Content
