import React, { Component } from 'react';
import config from '../../shared/config';
import classNames from 'classnames/bind';
import Axios from 'axios';
import Helper from "../../shared/custom";
import MyPagination from "../ui/MyPagination"
import Helpers from '../../shared/custom';
import store from '../../store';
import Follow from '../uiActions/Follow';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { BrowserRouter as Router, Route, Link ,Switch ,withRouter} from "react-router-dom";
import StarRating from "../ui/StarRating";

class MyCompanies extends Component {

  constructor(){
    super();
    this.state ={
      companies:[],
      pageOfItems: [],
      allItems: [],
      activePage: 1,
      totalRecords: 0,
      itemsCountPerPage:config.defaultPageSize,
      countryIds:[],
      localProps:"",
      loading:false,
    }
    this.handlePageChange = this.handlePageChange.bind(this);
    this.renderCompany = this.renderCompany.bind(this);
  }


  handlePageChange(pageNumber) {
    this.setState({activePage: pageNumber}, function(){
       this.fetchCompanies();
    });
  }

  componentWillReceiveProps(props){
    this.setState({
      localProps:props
    });


    let locationIds=[]
    if(props && props.locationIds){
       locationIds = props.locationIds.join(",");
       this.setState({countryIds:locationIds}, function(){
         this.fetchCompanies();
      });
    }


    if(this.state.localProps != props){
      this.setState({searchCriteria:props.searchCriteria}, function(){
        //this.fetchCompanies();
      });
    }
  }

  componentDidMount(){
    
    var storeState = store.getState();
    var auth = storeState.auth;
    this.setState({
      isLoggedIn:auth.isAuthenticated
    },()=>{
      if(!this.state.isLoggedIn){
        this.props.history.push("/companies")
      }else{
        this.fetchCompanies();
      }
    })
  }

  renderCompany(list,index){
      //let image = list.pageDto && list.pageDto.image ? Helper.dataPath(list.pageDto.image) : Helper.dataPath(config.defaultCompanyImage);
      let image = list.profilePic ? Helper.viewFile(list.profilePic.uniqueId) : Helper.dataPath(config.defaultCompanyImage);
      let name = list.pageDto && list.pageDto.name ? list.pageDto.name: "";

      return(<div className="col-md-4 col-sm-12 m-t-10 companyCard p-2" key={index}>
                <div className="bp-card  bp-card-company">
                    <Link to={"/company-details/"+list.pageDto.uri}  className="col-12 p-0">
                        <img src={image} className="float-left col-md-4 p-0" />
                        <div className="col-md-9 float-left text-left m-l-5 p-0 m-h-70">
                            <h5 className="text-info font-14 m-0"> {Helpers.truncate(name,15)}</h5>
                            <p className="font-12 p-0 m-0 text-black">{list && list.companyType ? list.companyType.name :""}</p>
                            <p className="font-12 p-0 text-grey ">{list.primarySector ? list.primarySector.sector.name : (!Helper.isEmpty(list.sectors)?Helpers.truncate(list.sectors[0].sector.name,30):"")}</p>
                        </div>
                        <div className="font-bold col-md-2 float-right  p-0 investeraRank">
                            87
                        </div>
                    </Link>
                    <div className="clearfix"></div>
                    <StarRating rating={list.company && list.company.rankingDetails && list.company.rankingDetails.ranking ? list.company.rankingDetails.ranking : 0} page={list.pageDto}/>
                    <div className="col-md-12 p-0 m-t-5 descDiv">
                        <p className="text-left font-12 font-medium">
                        {list.shortDescription ? Helper.truncate(list.shortDescription,config.defaultDescMaxLength) : Helper.truncate(list.longDescription,config.defaultDescMaxLength) }
                        </p>
                    </div>
                    </div>

        </div>)
    
  }

  fetchCompanies = () => {
    this.setState({
      loading:true,
    })

    let qs = config.paginationQS({"page":this.state.activePage, "length":this.state.itemsCountPerPage});
    qs.countryIds = this.state.countryIds;

    Axios.get(
        Helper.apiURL({
            uri:"companies/my-companies/summary",
            query:qs
        })
    )
    .then( (response) => {           
      let companies = response.data.result.aaData || [];
      let totalRecords = response.data.result.iTotalRecords || 0;
        this.setState({
            companies:companies,
            totalRecords:totalRecords,
             loading:false,
            });
       })
    .catch((err) => {
        this.setState({
          loading:false,
        })
    });
}

  render() {
    let companies = this.state.companies;
    let loading = this.state.loading;
    return (
      <div>
         <LoadingOverlay>
             <Loader fullPage loading={loading}/>
        </LoadingOverlay>
        <div className="card text-left bold-headers">
          <div className="card-body">
              <h5><b>My Companies</b></h5>
              <div className="row">

                  { (!Helpers.isEmpty(companies) && (companies.length > 0))?
                      companies.map((list,index )=> (
                          this.renderCompany(list,index)
                      ))
                  :
                      <div className="text-center col-12">No Data Available</div>
                  }
                </div>

                <MyPagination
                    activePage={this.state.activePage}
                    totalRecords={this.state.totalRecords}
                    onChange={this.handlePageChange}
                  />
            </div>
        </div>
      </div>
    )
  }
}


export default withRouter(MyCompanies);