import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Sidemenu from "../sidemenu/Sidemenu";
import UserProfileContentNew from "./UserProfileContentNew";
import UserFeedRightPanel  from "../homepage/UserFeedRightPanel";
import Helper from "../../shared/custom";
import { connect } from "react-redux";
import store from "../../store";

import { SET_CURRENT_USER_PROFILE }  from '../../actions/types';

import {fetchUserInfo} from '../../actions/authAction';

import Axios from "axios";

class UserProfileNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      business: {},
      currentUser: {},
      industryList: [],
      types: [],
      profileDetails: {},
      packageDetails:{}
    };
  }

  componentDidMount() {
    let uri = this.props.match.params.uri;

    if (!uri) {
      this.props.history.push({
        pathname: "/"
      });
    }
    fetchUserInfo();
    this.setState({ uri: uri });

    Axios.get(
      Helper.apiURL({
        uri: "user/:uri/profile",
        pathVar: { uri: uri }
      })
    )
      .then(response => {
        let profileDetails = response.data.result || null;
        localStorage.setItem("profile",JSON.stringify(profileDetails));
        if (!Helper.isEmpty(profileDetails)) {
          this.setState({
            profileDetails: profileDetails
          });
           store.dispatch({
              type:SET_CURRENT_USER_PROFILE,
              payload:profileDetails
           });
        } else {
          this.setState({
            profileDetails: null
          });
        }
      })
      .catch(err => {
        Helper.pushTError("PROFILE_NOT_FOUND");
      });
  }

  componentWillReceiveProps(props){
    var storeState = store.getState();
    this.setState({
      packageDetails:storeState.packageDetails && storeState.packageDetails.packageDetails && storeState.packageDetails.packageDetails.subscriptionTypeDto ? storeState.packageDetails.packageDetails.subscriptionTypeDto : {},
    })
    
  }



  render() {
    let  packageName  = this.state.packageDetails &&  this.state.packageDetails.name ? Helper.getProperPackageName(this.state.packageDetails.name) : "";
   
    return (
      <div>
        <div className="container-fluid">
          <div className="row content-in m-t-80 p-t-10">
            <div className="col-lg-3 col-xlg-3 col-md-3 searchMenu sideMenuCont">
               <Sidemenu mode="userprofile" />
            </div>
            <div className="col-lg-6 col-xlg-6 col-md-6 centerDiv">
              <UserProfileContentNew
                profileDetails={this.state.profileDetails}
                uri={this.state.uri}
                packageName ={packageName}
              />
            </div>
            <div className="col-lg-3 col-xlg-3 col-md-3 m-t-2 right-side">
               <UserFeedRightPanel />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(withRouter(UserProfileNew));
