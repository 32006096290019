import React, { Component } from 'react';
import config from '../../shared/config';
import classNames from 'classnames/bind';
import Axios from 'axios';
import Helper from "../../shared/custom";
import MyPagination from "../ui/MyPagination"
import Helpers from '../../shared/custom';
import EventCard from './EventCard';
import store from '../../store';
import { BrowserRouter as Router, Route,Redirect, Link ,Switch ,withRouter} from "react-router-dom";
class Myevent extends Component {
  constructor(){
    super();
    this.state ={
      events:[],
      pageOfItems: [],
      allItems: [],
      activePage: 1,
      totalRecords: 0,
      itemsCountPerPage:config.defaultPageSize,
      countryIds:[]
    }
    this.handlePageChange = this.handlePageChange.bind(this);

  }





  handlePageChange(pageNumber) {
    this.setState({activePage: pageNumber}, function(){
      this.fetchEvents();
    });
  }

  componentWillReceiveProps(props){
    if(props && props.locationIds){
      this.setState({countryIds:props.locationIds},()=>{
        this.fetchEvents();
      });

    }
  }

  

  // componentDidMount(){
  //   this.fetchEvents();
  // }


  componentDidMount(){
    var storeState = store.getState();
    var auth = storeState.auth;
    var localAuth = this.state.auth;
    this.setState({
      isLoggedIn:auth.isAuthenticated
    },()=>{
      if(this.state.isLoggedIn){
        this.fetchEvents();
      }else{
        this.props.history.push("/events")
      }
    })
  }

  fetchEvents = () => {
  
    let qs = Helper.generatePaginationQS({"page":this.state.activePage, "length":this.state.itemsCountPerPage});
    
    Axios.get(
        Helper.apiURL({
            uri:"events/my-events/summary",
            query:qs
        })
    )
    .then( (response) => {           
       let events = response.data.result.aaData || [];
       let totalRecords = response.data.result.iTotalRecords || 0;
        this.setState({
                events:events,
                totalRecords:totalRecords
                });
       })
    .catch((err) => {
        
    });
}
  render() {
    let events = this.state.events;
    // events)
    return (
      <div>
          <div className="card text-left bold-headers">
                <div className="card-body">
                    <h5><b>My Events</b></h5>
                    <div className="row">
                     {(!Helpers.isEmpty(events) && (events.length > 0))?
                        events.map((item,index )=> (
                            <div className="col-md-6 m-t-10" key={index}>
                               <div className="border p-1">
                                  <EventCard data={item} key={"myEvent"+index} mode="my-events"/>
                                </div>
                            </div>
                        
                      ))
                      :
                      <div className="col-12 text-center">No Events</div>
                    }
                    </div>

                  <MyPagination
                    activePage={this.state.activePage}
                    totalRecords={this.state.totalRecords}
                    onChange={this.handlePageChange}
                  />

                 </div> 
             </div>      
        
      </div>
    )
  }
}


export default withRouter(Myevent) 