import React, { Component } from 'react';
import config from '../../shared/config';
import OwlCarousel, { Options } from 'react-owl-carousel';
import classNames from 'classnames/bind';
import Axios from 'axios';
import Helper from "../../shared/custom";
import store from '../../store';
//import IsInterestedBtn from './IsInterestedBtn';
const $ = window.$;



export default class Events extends Component {
 
  constructor(){
    super();
    this.state ={
      events:[],
      uri:undefined,
      options: {
          loop: false,
          margin: 10,
          nav:true,
          autoplay:true,
          autoplaySpeed:400,
          items:3,
      },
      scrollItems:2
      
    }
    
  }

componentWillReceiveProps(props){
  this.fetchEvents(props.entityUri);
}
componentDidMount(){
  if(Helper.isMobile()){
    this.setState({
      scrollItems :1
    })
  }
}

setInterested (event) {
  this.postSetInterested(event.target.id);
}

postSetInterested(uniqueId){
  
}

fetchEvents(entityUri) {
    let uri = entityUri;    
    let storeState = store.getState();

    if(!store.auth || !store.auth.isAuthenticated || !uri){
      this.setState({events:[]})
      return false;
    }

    if(this.state.uri && this.state.uri == entityUri){
      return false;
    }

    Axios.get(
          Helper.apiURL({
              uri:"page/:uri/events",
              pathVar:{uri:uri}
          })
    )
    .then( (response) => {           
        let events = response.data.result || [];
     
        this.setState({
          events:events,
          uri:entityUri
        });
       })
    .catch((err) => {
        
    });
}

  render() {
    const itemStyle = {
      width: '500px',
    };
    var events = this.state.events;
    let scrollItems = this.state.scrollItems;

   
    return (
      <div>
      { events && events.length > 0 ?  
           <div className="card text-left bold-headers" id={this.props.id}>
                <div className="card-header text-left light-blue-header">
                    <h5 className="m-b-0 m-t-0 text-white">Events</h5>
                </div>
                <div className="card-body">
                    {events && events.length > 0 ?
                      <OwlCarousel ref="car" className="owl-theme" autoplay="true" margin={10} items="3.5" autoWidth={false} nav={true} >
                        {events.map((event, index) => (
                          <div key={index}>
                              <img src={!Helper.isEmpty(event.coverPhoto.publicUri) ? Helper.dataPath(event.coverPhoto.publicUri) : Helper.dataPath(config.defaultEventIcon)}  alt="Event"/>
                              <div className="col-md-12 m-t-10 font-14 m-t-20 p-0">
                                  <div className="col-md-4 float-left text-left p-0  calendarBox">
                                    <div className="font-light text-danger text-center font-12">{Helper.getDateInFormat(event.startDate,"MMMM")}</div>
                                    <div className="font-bold text-center">{Helper.getDateInFormat(event.startDate,"D")}</div>
                                  </div>
                                  <div className="col-md-8 float-left p-0">
                                    <h5 className="font-bold m-0">{event.name}</h5>
                                    <span>{event.startDate} -{event.city.name} {event.address} </span>
                                    <span>{event.industry.name}</span>
                                  </div>
                                  <div className="like-comm m-t-20 text-left"> 
                                        <a id={event.uniqueId} onClick={this.setInterested.bind(this)} className={classNames('link', 'm-r-10', {'text-primary': false
                                      })}>
                                        <i className="fa fa-star"></i> Interested</a> 
                                  </div>
                              </div>
                          </div>
                        ))}
                      </OwlCarousel>
                    :
                    <div>
                      <center className="m-t-10">No upcoming events</center>
                    </div>
                    }
                   
                </div>
            </div>
            :
            ""
           } 
      </div>
    )

   
  }
}
