import React, { Component } from 'react';
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import Axios from 'axios';
import store from '../../store';

import config from '../../shared/config';
import Helper from '../../shared/custom';
import ApiHelper from "../../shared/ApiHelper";

import Upload from './Upload';
import Videos from './Videos';
import InfoTextArray from "../ui/InfoTextArray";
import CustomArray from '../ui/CustomArray';

import InfoText from "../ui/InfoText";
import InfoDate from "../ui/InfoDate";
import InfoFinancial from '../ui/InfoFinancial';
import ExternalLink from '../ui/ExternalLink';
import ReadMoreAndLess from 'react-read-more-less';
import InfoAddress from '../ui/InfoAddress';

import EditText from "../ui/EditText";
import EditTextArea from '../ui/EditTextArea';
import EditSelect from '../ui/EditSelect';
import countries from '../../shared/country';
import currencies from '../../shared/currency';
import STR from '../../shared/strings';

import DeletePage from '../wigets/DeletePage';
import InfoEmail from '../ui/InfoEmail';
import areas from '../../shared/areas';

import EditMultiSelect from "../ui/EditMultiSelect";

class OppContentNew extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editBasicDetails: false,
            editDescription: false,
            editContactDetails: false,
            editBanner: false,
            loading: false,

            auth: {},
            metaList: [],
            types: [],
            subTypeOptions: {},
            cityList: [],
            addressCityList: [],
            industryList: [],
            isEditable: false,

            showForm: false,
            id: undefined,
            uri: null,
            oppDetails: {},

            profilePic: undefined,
            profilePicUrl: undefined,

            title: "",
            industry: [],
            type: undefined,
            subType: undefined,
            budgetRange: undefined,
            country: undefined,
            city: undefined,
            newCity:"",
            deadline: null,

            image: undefined,
            showOwner: false,
            owner: undefined,
            ownerName: undefined,
            ownerTitle: undefined,
            ownerImage: undefined,
            role: undefined,

            area: undefined,
            areaUnit: undefined,
            annualGrowth: undefined,
            annualGrowthCurrency: undefined,
            pitcherCapacity: undefined,
            pitcherContact: undefined,
            risk: undefined,

            turnover: undefined,
            turnoverCurrency: undefined,
            areaServed: undefined,
            description: undefined,

            addressDto: {
                addressLine1: undefined,
                addressLine2: undefined,
                addressLine3: undefined,
                stateOrProvince: undefined,
                pobox: undefined,
                zip: undefined,
            },
            addressCountry: undefined,
            addressCity: undefined,
            newCityAddress:"",
            addressCityOptions: [],

            pageDto: {
                id: null
            },

            email: undefined,
            phoneNumber: undefined,
            website: undefined,

            photos: [],
            videos: [],
            attachments: []

        }

        this.editBasicDetails = this.editBasicDetails.bind(this);
        this.editDescription = this.editDescription.bind(this);
        this.editContactDetails = this.editContactDetails.bind(this);
        this.editbanner = this.editbanner.bind(this);
        this.uploadImg = this.uploadImg.bind(this);
        this.uploadProfileImage = this.uploadProfileImage.bind(this);

        this.handleFieldValueChange = this.handleFieldValueChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.setCityList = this.setCityList.bind(this);
        this.setAddressCityList = this.setAddressCityList.bind(this);
        this.setMetaList = this.setMetaList.bind(this);
        this.setTypes = this.setTypes.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleVideoChange = this.handleVideoChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleResponse = this.handleResponse.bind(this);
        this.validateForm = this.validateForm.bind(this);
    }

    validateForm() {
        let isValid = true;
        let message = [];

        if (Helper.isEmpty(this.state.title)) {
            isValid = false;
            message.push("TITLE_REQUIRED");
        }

        if (Helper.isEmpty(this.state.profilePic)) {
            isValid = false;
            message.push("PROFILE_IMAGE_REQUIRED");
        }

        if (Helper.isEmpty(this.state.industry)) {
            isValid = false;
            message.push("INDUSTRY_REQUIRED");
        }

        if (Helper.isEmpty(this.state.budgetRange)) {
            isValid = false;
            message.push("INVESTMENT_AMOUNT_REQUIRED");
        }

        if (Helper.isEmpty(this.state.email)) {
            isValid = false;
            message.push("EMAIL_REQUIRED");
        }

        if (Helper.isEmpty(this.state.pitcherCapacity)) {
            isValid = false;
            message.push("PITCHER_CAPACITY_REQUIRED");
        }


        if (Helper.isEmpty(this.state.type)) {
            isValid = false;
            message.push("TYPE_REQUIRED");
        }

        if (Helper.isEmpty(this.state.subType)) {
            isValid = false;
            message.push("SUBTYPE_REQUIRED");
        }

        if (Helper.isEmpty(this.state.description)) {
            isValid = false;
            message.push("DESCRIPTION_REQUIRED");
        }

        let addressDto = this.state.addressDto || null;

        if (!addressDto || Helper.isEmpty(addressDto.addressLine1)) {
            isValid = false;
            message.push("ADDRESS_REQUIRED");
        }

        if (message.length > 0) {
            Helper.pushTError(message);
        }
        return isValid;
    }

    handleSubmit() {

        let isValid = this.validateForm();
        if (!isValid) {
            return false;
        }

        var industryIds = [];

        if (!Helper.isEmpty(this.state.industry)) {
            this.state.industry.forEach((data, i) => {
                industryIds.push({ "id": data.value });
            })
        }




        let payload = {
            "role": this.state.role,
            "annualGrowth": this.state.annualGrowth,
            "annualGrowthCurrency": { "id": this.state.annualGrowthCurrency ? this.state.annualGrowthCurrency.value : null },
            "area": this.state.area,
            "areaServed": { "id": this.state.areaServed ? this.state.areaServed.value : null },
            "areaUnit": { "id": this.state.areaUnit ? this.state.areaUnit.value : null },

            "budgetRange": { "id": this.state.budgetRange.value },
            "date": this.state.deadline ? Helper.getDateInInputFormat(this.state.deadline) : null,
            "description": this.state.description,
            "industries": industryIds,
            "pitcherCapacity": { "id": this.state.pitcherCapacity ? this.state.pitcherCapacity.value : null },
            "pitcherContact": this.state.pitcherContact,
            "profilePic": { "uniqueId": this.state.profilePic },
            "images": [],
            "attachments": [],
            "videos": this.state.videos,
            "risk": { "id": this.state.risk ? this.state.risk.value : null },
            "subType": { "id": this.state.subType.value },
            "turnover": this.state.turnover,
            "turnoverCurrency": { "id": this.state.turnoverCurrency ? this.state.turnoverCurrency.value : null },
            "type": { "id": this.state.type.id },
            "pageDto": {
                "id": this.state.pageDto.id ? this.state.pageDto.id : null,
                "uri": this.state.pageDto.uri ? this.state.pageDto.uri : null,
                "facebook": null,
                "image": null,
                "linkedIn": null,
                "name": this.state.title,
                "stateOrProvince": null,
                "twitter": null,
                "website": this.state.website,
                "city": this.state.city.value != "other" ? 
                {
                  id: this.state.city && this.state.city.value ? this.state.city.value : null
                }
                :
                {
                    id: null,
                    name:this.state.newCity
                },
                "country": { "id": this.state.country ? this.state.country.value : null },
                "contactEmails": [this.state.email],
                "contactNumbers": [this.state.phoneNumber]
            },
            "addressDto": {
                "id": this.state.addressDto.id ? this.state.addressDto.id : null,
                "addressLine1": this.state.addressDto.addressLine1,
                "addressLine2": this.state.addressDto.addressLine2,
                "addressLine3": this.state.addressDto.addressLine3,
                "stateOrProvince": this.state.addressDto.stateOrProvince,
                "poBox": this.state.addressDto.pobox,
                "zip": this.state.addressDto.zip,
                "city": 
                this.state.addressCity && this.state.addressCity.value != "other" ? 
                {
                  id: this.state.addressCity && this.state.addressCity.value ? this.state.addressCity.value : null
                }
                :
                {
                    id: null,
                    name:this.state.newCityAddress
                },
                "country": { "id": this.state.addressCountry ? this.state.addressCountry.value : null }
            },
        }



        let photos = this.state.photos || [];
        let images = [];
        photos.forEach(photo => {
            images.push({ uniqueId: photo.uniqueId })
        });
        payload.images = images;

        let attachmentList = this.state.attachments || [];
        let attachments = [];
        attachmentList.forEach(attachment => {
            attachments.push({ uniqueId: attachment.uniqueId })
        });
        payload.attachments = attachments;

        this.setState({
            loading: true
        })
        if (this.state.id) {
            payload.id = this.state.id;
            Axios.put(
                Helper.apiURL({ uri: "opportunity" }),
                payload
            )
                .then((response) => { this.handleResponse("success", response); })
                .catch((err) => { this.handleResponse("error", err); });
        } else {
            //new
            Axios.post(
                Helper.apiURL({ uri: "opportunity" }),
                payload
            )
                .then((response) => {
                    this.handleResponse("success", response);
                    let data = response.data.result;
                    let uri = data.pageDto.uri ? data.pageDto.uri : null;
                    if (uri) {
                        setTimeout(function () {
                            window.location = Helper.sitePath("opportunity-details/" + uri);
                        }, config.defaultWaitTime);
                    }
                })
                .catch((err) => { this.handleResponse("error", err); });
        }
    }

    handleResponse(type, response) {
        this.setState({
            loading: false
        })
        if (type == "success") {
            let result = response.data.result;
            this.setState({
                id: result.id,
                uri: result.uri,
                oppDetails: result
            });
            Helper.pushTSuccess("OPP_SUBMIT_APPROVAL");
        } else {
            Helper.pushTError("OPP_NOT_SAVED");
        }
    }

    componentDidMount() {
        var storeState = store.getState();
        let metaList = storeState.meta.metaList;
        let isEditable = true;



        this.setState({
            metaList: metaList,
            auth: storeState.auth,
            isEditable: isEditable,
            industryList: this.props.industryList,
        });

        if (Helper.isEmpty(metaList)) {
            ApiHelper.getMetaList(this.setMetaList);
        }

        ApiHelper.getOpportunityTypes(this.setTypes)
    }

    componentWillReceiveProps(nextProps) {
        let lastPageState = nextProps.lastPageState;
        let oppDetails = nextProps.oppDetails;



        if (lastPageState) {
            this.setState({
                showForm: true,
                showOwner: false,
                title: lastPageState.title,
                industry: lastPageState.industry,
                type: lastPageState.type,
                subType: lastPageState.subType,
                budgetRange: lastPageState.budgetRange,
                country: lastPageState.country,
                city: lastPageState.city,
                newCity: lastPageState.newCity,
                deadline: lastPageState.deadline ? new Date(lastPageState.deadline) : null,
                industryList: lastPageState.industryList,
            }, () => {
                ApiHelper.getCities(lastPageState.country.id, (list) => { this.setCityList(list); });
            })
        } else if (oppDetails) {


            oppDetails.addressDto.city = Helper.makeSelectOption(oppDetails.addressDto.city);
            oppDetails.addressDto.country = Helper.makeSelectOption(oppDetails.addressDto.country);
            let pageDto = oppDetails.pageDto;
            this.setState({

                showForm: true,
                id: oppDetails.id,
                pageDto: oppDetails.pageDto,
                title: oppDetails.pageDto.name,
                industry: Helper.makeSelectOptions(oppDetails.industries),
                type: Helper.makeSelectOption(oppDetails.type),
                subType: Helper.makeSelectOption(oppDetails.subType),
                budgetRange: Helper.makeSelectOption(oppDetails.budgetRange),
                country: Helper.makeSelectOption(oppDetails.pageDto.country),
                city: Helper.makeSelectOption(oppDetails.pageDto.city),
                deadline: oppDetails.date ? new Date(oppDetails.date) : null,

                profilePic: oppDetails.profilePic.uniqueId,
                profilePicUrl: Helper.viewFile(oppDetails.profilePic.uniqueId),
                role: oppDetails.role ? oppDetails.role : undefined,

                showOwner: true,
                owner: oppDetails.owner ? oppDetails.owner : null,
                ownerName: oppDetails.owner ? oppDetails.owner.fullName : null,
                ownerTitle: undefined,
                ownerImage: undefined,

                area: oppDetails.area,
                areaUnit: Helper.makeSelectOption(oppDetails.areaUnit),
                annualGrowth: oppDetails.annualGrowth,
                annualGrowthCurrency: Helper.makeSelectOption(oppDetails.annualGrowthCurrency),
                pitcherCapacity: Helper.makeSelectOption(oppDetails.pitcherCapacity),
                pitcherContact: oppDetails.pitcherContact,
                risk: Helper.makeSelectOption(oppDetails.risk),

                turnover: oppDetails.turnover,
                turnoverCurrency: Helper.makeSelectOption(oppDetails.turnoverCurrency),
                areaServed: Helper.makeSelectOption(oppDetails.areaServed),
                description: oppDetails.description,

                addressDto: oppDetails.addressDto,
                addressCity: Helper.makeSelectOption(oppDetails.addressDto.city),
                addressCountry: Helper.makeSelectOption(oppDetails.addressDto.country),

                email: pageDto.contactEmails[0],
                phoneNumber: pageDto.contactNumbers[0],
                website: oppDetails.pageDto.website,

                photos: oppDetails.images,
                videos: oppDetails.videos,
                attachments: oppDetails.attachments,
                industryList: nextProps.industryList

            }, () => {
                if (oppDetails.pageDto.country && oppDetails.pageDto.country.id) {
                    ApiHelper.getCities(oppDetails.pageDto.country.id, (list) => { this.setCityList(list); });
                }
                if (oppDetails.addressDto.country && oppDetails.addressDto.country.id) {
                    ApiHelper.getCities(oppDetails.addressDto.country.id, (list) => { this.setAddressCityList(list); });
                }

            })
        }
    }

    uploadImg(e) {
        this.refs.imgUploader.click();
    }

    editDescription() {
        this.setState({
            editDescription: this.state.editDescription ? false : true
        })
    }
    editBasicDetails() {
        this.setState({
            editBasicDetails: this.state.editBasicDetails ? false : true
        })
    }
    editContactDetails() {
        this.setState({
            editContactDetails: this.state.editContactDetails ? false : true
        })
    }

    editbanner() {
        this.setState({
            editbanner: this.state.editbanner ? false : true
        })
    }

    uploadProfileImage(e) {
        let file = e.target.files ? e.target.files[0] : null;
        if (file) {
            ApiHelper.uploadFile({
                repoType: "OPP_IMAGES",
                fileType: "IMAGE",
                file: file,
                callback: (result) => {
                    this.setState({
                        profilePic: result.uniqueId,
                        profilePicUrl: Helper.viewFile(result.uniqueId)
                    })
                }
            });
        }
    }


    handleFieldValueChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        if (name) {
            switch (name) {
                case "addressLine1":
                case "addressLine2":
                case "addressLine3":
                case "stateOrProvince":
                case "pobox":
                case "zip":
                    let addressDto = this.state.addressDto;
                    addressDto[name] = value;
                    this.setState({ addressDto: addressDto });
                    break;
                default:
                    this.setState({ [name]: value });
                    break;
            }
        }
    }

    handleDateChange(date, e) {
        //Helper.getDateInInputFormat
        this.setState({ deadline: date })
    }

    handleSelectChange(option, e) {
        let thisFieldName = e.name;
        switch (thisFieldName) {
            case "country":
                ApiHelper.getCities(option.value, this.setCityList);
                this.setState({ [thisFieldName]: option, city: null, cityList: [] });
                break;
            case "addressCountry":
                ApiHelper.getCities(option.value, this.setAddressCityList);
                this.setState({ [thisFieldName]: option, addressCity: null, addressCityList: [] });
                break;
            default:
                this.setState({ [thisFieldName]: option });
                break;
        }

    }

    setAddressCityList(cityList) {
        this.setState({ addressCityList: cityList });
    }
    setCityList(cityList) {

        this.setState({ cityList: cityList });
    }

    setMetaList(metaList) {
        this.setState({ metaList: metaList });
    }

    setTypes(types) {

        let subTypeOptions = {};
        types.forEach(type => {
            subTypeOptions[type.id] = Helper.makeSelectOptions(type.subTypes);

        });

        this.setState({
            types: types,
            subTypeOptions: subTypeOptions
        });
    }

    handleUpload(itemKey, newItems) {
        this.setState({ [itemKey]: newItems });
    }

    handleVideoChange(itemKey, newItems) {
        this.setState({ [itemKey]: newItems });
    }

    render() {


        let idUri = Helper.isEmpty(this.state.id) ? null : this.state.id;

        let isEditable = this.state.isEditable;
        let profilePicUrl = this.state.profilePicUrl ? this.state.profilePicUrl : Helper.assetPath("theme/images/default-photo.png");

        let countryOptions = Helper.makeSelectOptions(countries);
        let areaServedOptions = Helper.makeSelectOptions(areas);
        let cityOptions = Helper.makeSelectOptions(this.state.cityList);
        let addressCityOptions = Helper.makeSelectOptions(this.state.addressCityList)
        if (this.state.addressCountry && this.state.addressCountry.value) {
            console.log("this.state.country.value", this.state.country.value);
            addressCityOptions.push({ value: 'other', label: 'Other' });
        }

        if (this.state.country && this.state.country.value) {
            console.log("this.state.country.value", this.state.country.value);
            cityOptions.push({ value: 'other', label: 'Other' });
        }

        let subTypeOptions = [];


        let typeId = this.state.type ? this.state.type.id : null;
        if (typeId) {
            subTypeOptions = this.state.subTypeOptions[typeId] ? this.state.subTypeOptions[typeId] : []
        }


        let metaList = this.state.metaList;
        let riskOptions = Helper.makeSelectOptions(metaList.RIS);
        let pitcherCapacityOptions = Helper.makeSelectOptions(metaList.PIC);
        let areaUnitOptions = Helper.makeSelectOptions(metaList.ARU);
        let budgetRangeOptions = Helper.makeSelectOptions(metaList.BUD);
        let industryOptions = Helper.makeSelectOptions(this.state.industryList);

        let turnoverCurrency = this.state.turnoverCurrency ? this.state.turnoverCurrency.label : null;
        let annualGrowthCurrency = this.state.annualGrowthCurrency ? this.state.annualGrowthCurrency.label : null;

        let area = this.state.area ? this.state.area : null;
        if (this.state.area && this.state.areaUnit) {
            area += " " + this.state.areaUnit.label;
        }

        let areaServed = this.state.areaServed ? this.state.areaServed.label : null;
        let addressDto = this.state.addressDto;
        addressDto.country = this.state.addressCountry;
       
        addressDto.city = this.state && this.state.addressCity && this.state.addressCity.label != "Other" ? this.state.addressCity : this.state.newCityAddress



        let location = "";
        if (this.state.city) {
            location += this.state.city.label == "Other" ? this.state.newCity : this.state.city.label;
        }


        if (this.state.country) {
            location += this.state.city ? "," : "";
            location += this.state.country.label;
        }

        let owner = this.state.owner ? this.state.owner : null;
        let showOwner = this.state.showOwner && owner ? true : false;
        let ownerImage = owner && owner.image ? Helper.dataPath(owner.image) : Helper.dataPath(config.defaultUserImage);

        if (!this.state.showForm) {
            return (<div></div>)
        }

        return (
            <div>
                <div className="row bold-headers text-left p-b-30">
                    <div className="col-md-12 col-lg-12 col-xlg-12 p-0">
                        <div className="card">
                            <div className="card-body">
                                <div className="col-lg-12 p-0">
                                    <div className="col-lg-6 p-0">
                                        {isEditable && this.state.editbanner ?
                                            <div className="m-t-0">
                                                <a href="javascript:void(0)" onClick={this.uploadImg}>
                                                    <img src={profilePicUrl} className="float-left newImg img-circle" width="100" height="100" />
                                                    <input type="file" id="userImg" name="userImg" style={{ display: "none" }} ref="imgUploader" onChange={this.uploadProfileImage} />
                                                </a>
                                                <div className="card-body-right float-left m-l-20 text-left ">
                                                    <h2 className="card-title m-t-5 m-b-5 ">
                                                        <input type="input" name="title" className="form-control" value={this.state.title} onChange={this.handleFieldValueChange} />
                                                    </h2>
                                                </div>
                                            </div>
                                            :

                                            <div className="m-t-0">
                                                <img src={profilePicUrl} className="img-circle float-left m-w" width="100" height="100" />
                                                <div className="card-body-right float-left m-l-20 text-left ">
                                                    <h2 className="card-title m-t-5 m-b-5 ">{this.state.title}</h2>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                    <div className="col-lg-3 float-right p-0 text-right">

                                        {isEditable ?
                                            <div className="m-t-10">
                                                <div>
                                                    {this.state.editbanner ?
                                                        <a onClick={this.editbanner} className="text-danger"><i className="fa fa-times"></i></a>
                                                        :
                                                        <a onClick={this.editbanner} className="text-info">Edit</a>
                                                    }
                                                </div>
                                            </div>
                                            :
                                            ""
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card" id="details">
                            <div className="card-body">
                                <div className="col-lg-12 p-0">
                                    <div className="col-lg-6 p-0 float-left">
                                        <h4>Basic Details</h4>
                                    </div>
                                    <div className="col-lg-3 float-right p-0  text-right">

                                        {isEditable ?
                                            <div className="m-t-10">
                                                <div>
                                                    {this.state.editBasicDetails ?
                                                        <a onClick={this.editBasicDetails} className="text-danger"><i className="fa fa-times"></i></a>
                                                        :
                                                        <a onClick={this.editBasicDetails} className="text-info">Edit</a>
                                                    }

                                                </div>
                                            </div>
                                            :
                                            ""
                                        }

                                    </div>
                                </div>

                                <div className="col-lg-12 p-0">
                                    {showOwner ?
                                        <div className="col-lg-12 p-0 float-left">
                                            <h5>Investment Opportunity Created by</h5>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <img src={ownerImage} className="img-circle pull-left m-r-20 m-b-10 float-left" width="60" />
                                                    <Link to={"/user/" + owner.uri}>
                                                        <div className="p-0 m-0">
                                                            <h6 className="font-bold text-info m-b-0 p-0 m-t-10">{owner.fullName}</h6>
                                                            {/* <p className="p-0 m-0">{this.state.ownerTitle}</p> */}
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        ""
                                    }


                                    {isEditable && this.state.editBasicDetails ?
                                        <div>
                                            <EditText title="Role of Opportunity Creator" name="role" value={this.state.role} placeholder="Role of opportunity creator" onValueChange={this.handleFieldValueChange} />
                                            <EditSelect title="Choose Country" name="country" value={this.state.country} options={countryOptions} onChange={this.handleSelectChange} additionalClassName="ediableInline " />
                                            <EditSelect title="Choose City/Town" name="city" value={this.state.city} options={cityOptions} onChange={this.handleSelectChange} additionalClassName="ediableInline " />
                                            {this.state.city && this.state.city.value == 'other' ?
                                                <EditText
                                                name="newCity"
                                                title=""
                                                value={this.state.newCity}
                                                placeholder={"Enter city name"}
                                                onValueChange={this.handleFieldValueChange}
                                                additionalClassName="col-6 p-0"
                                                />
                                                :
                                                ""
                                            }
                                            <EditSelect title="Secondary Investment Type" name="subType" value={this.state.subType} options={subTypeOptions} onChange={this.handleSelectChange} additionalClassName="ediableInline " />
                                            <EditMultiSelect title="Industries" name="industry" value={this.state.industry} options={industryOptions} onChange={this.handleSelectChange} additionalClassName="ediableInline " />
                                            <EditSelect title="Investment Amount" name="budgetRange" value={this.state.budgetRange} options={budgetRangeOptions} onChange={this.handleSelectChange} additionalClassName="ediableInline " />

                                            <div>
                                                <div className="col-lg-12 p-0 float-left">
                                                    <h5>Investment Deadline</h5>
                                                    <div className="col-md-12 p-0">
                                                        <div className="ediableInline">
                                                            {
                                                                this.state.deadline ?
                                                                    <DatePicker className="form-control"
                                                                        selected={this.state.deadline ? this.state.deadline : null}
                                                                        onChange={this.handleDateChange.bind(this)}
                                                                        placeholderText="When is the deadline for Investors to contact you?"
                                                                        peekNextMonth
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                        dateFormat={config.displayDateFormat}
                                                                    />
                                                                    :
                                                                    <DatePicker className="form-control"
                                                                        onChange={this.handleDateChange.bind(this)}
                                                                        placeholderText="When is the deadline for Investors to contact you?"
                                                                        peekNextMonth
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        dropdownMode="select"
                                                                    />
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <EditText title="Area" name="area" value={this.state.area} placeholder="Area (optional)" onValueChange={this.handleFieldValueChange} />
                                            <EditSelect title="Area Unit" name="areaUnit" value={this.state.areaUnit} placeholder="Area Unit (optional)" options={areaUnitOptions} onChange={this.handleSelectChange} additionalClassName="ediableInline " />

                                            <EditText title="Turnover" name="turnover" value={this.state.turnover} placeholder="Turnover (optional)" onValueChange={this.handleFieldValueChange} />
                                            <EditSelect title="Turnover Currency" name="turnoverCurrency" value={this.state.turnoverCurrency} options={currencies} onChange={this.handleSelectChange} additionalClassName="ediableInline " />

                                            <EditText title="Annual Growth" name="annualGrowth" value={this.state.annualGrowth} placeholder="Annual Growth (optional)" onValueChange={this.handleFieldValueChange} />
                                            <EditSelect title="Annual Growth Currency" name="annualGrowthCurrency" value={this.state.annualGrowthCurrency} options={currencies} onChange={this.handleSelectChange} additionalClassName="ediableInline " />

                                            <EditText title="Pitcher Contact" name="pitcherContact" value={this.state.pitcherContact} placeholder="Pitcher Contact" onValueChange={this.handleFieldValueChange} />
                                            <EditSelect title="Pitcher Capacity" name="pitcherCapacity" value={this.state.pitcherCapacity} options={pitcherCapacityOptions} onChange={this.handleSelectChange} additionalClassName="ediableInline " />

                                            <EditSelect title="Risk" name="risk" value={this.state.risk} options={riskOptions} onChange={this.handleSelectChange} additionalClassName="ediableInline " />
                                            <EditSelect title="Area Served" name="areaServed" value={this.state.areaServed} options={areaServedOptions} onChange={this.handleSelectChange} additionalClassName="ediableInline " />
                                        </div>
                                        :
                                        <div className="col-lg-12 p-0 float-left">
                                            <InfoText title="Role of Opportunity Creator" text={this.state.role} />
                                            <InfoText title="Location" text={location} />
                                            <InfoText title="Primary Investment Type" text={this.state.type ? this.state.type.name : ""} />
                                            <InfoText title="Secondary Investment Type" text={this.state.subType ? this.state.subType.label : ""} />



                                            <InfoTextArray
                                                title="Industries"
                                                textArray={Helper.selectOptionsToTextArray(this.state.industry)}
                                                additionalClassName="text-info"
                                            />
                                            <InfoText title="Investment Amount" text={this.state.budgetRange ? this.state.budgetRange.label : ""} />
                                            <InfoDate title="Investment Deadline" date={this.state.deadline} />
                                            <InfoText title="Area" text={area} />
                                            <InfoFinancial title="Turnover" value={this.state.turnover} currency={turnoverCurrency} />
                                            <InfoFinancial title="Annual Growth" value={this.state.annualGrowth} currency={annualGrowthCurrency} />
                                            <InfoText title="Pitcher Contact" text={this.state.pitcherContact} />
                                            <InfoText title="Pitcher Capacity" text={this.state.pitcherCapacity ? this.state.pitcherCapacity.label : null} />
                                            <InfoText title="Risk" text={this.state.risk ? this.state.risk.label : null} />
                                            <InfoText title="Areas Served" text={this.state.areaServed ? this.state.areaServed.label : null} />
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>

                        <div className="card" id="description">
                            <div className="card-body">

                                <div className="col-lg-12 p-0">
                                    <div className="col-lg-6 p-0 float-left">
                                        <h4>Description</h4>
                                    </div>
                                    <div className="col-lg-3 float-right p-0  text-right">

                                        {isEditable ?
                                            <div className="m-t-10">
                                                <div>
                                                    {this.state.editDescription ?
                                                        <a onClick={this.editDescription} className="text-danger"><i className="fa fa-times"></i></a>
                                                        :
                                                        <a onClick={this.editDescription} className="text-info">Edit</a>
                                                    }

                                                </div>
                                            </div>
                                            :
                                            ""
                                        }

                                    </div>
                                </div>

                                {isEditable && this.state.editDescription ?
                                    <EditTextArea value={this.state.description} name="description" onValueChange={this.handleFieldValueChange} />
                                    :
                                    <div className="col-lg-12 p-0 clearfix">
                                        {this.state.description ? Helper.newLineToBr(this.state.description) : ""}
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="card" id="contact">
                            <div className="card-body">
                                <div className="col-lg-12 p-0">
                                    <div className="col-lg-6 p-0 float-left">
                                        <h4>Contact Details</h4>
                                    </div>
                                    <div className="col-lg-3 float-right p-0  text-right">
                                        {isEditable ?
                                            <div className="m-t-10">
                                                <div>
                                                    {this.state.editContactDetails ?
                                                        <a onClick={this.editContactDetails} className="text-danger"><i className="fa fa-times"></i></a>
                                                        :
                                                        <a onClick={this.editContactDetails} className="text-info">Edit</a>
                                                    }
                                                </div>
                                            </div>
                                            :
                                            ""
                                        }

                                    </div>
                                </div>

                                <div className="col-lg-12 p-0 float-left">

                                    {isEditable && this.state.editContactDetails ?
                                        <div>
                                            <EditText title="Address" name="addressLine1" value={this.state.addressDto.addressLine1} placeholder="Address Line one" onValueChange={this.handleFieldValueChange} />
                                            <EditText name="addressLine2" value={this.state.addressDto.addressLine2} placeholder="Address Line two (optional)" onValueChange={this.handleFieldValueChange} />
                                            <EditText name="addressLine3" value={this.state.addressDto.addressLine3} placeholder="Address Line three (optional)" onValueChange={this.handleFieldValueChange} />
                                            <EditText name="stateOrProvince" value={this.state.addressDto.stateOrProvince} placeholder="State/Province/Region (optional)" onValueChange={this.handleFieldValueChange} />
                                            <EditText name="pobox" value={this.state.addressDto.pobox} placeholder="PO Box (optional)" onValueChange={this.handleFieldValueChange} />
                                            <EditText name="zip" value={this.state.addressDto.zip} placeholder="Zip (optional)" onValueChange={this.handleFieldValueChange} />

                                            <EditSelect title="Country" name="addressCountry" value={this.state.addressCountry} options={countryOptions} onChange={this.handleSelectChange} additionalClassName="ediableInline " />
                                            <EditSelect title="City/Town" name="addressCity" value={this.state.addressCity} options={addressCityOptions} onChange={this.handleSelectChange} additionalClassName="ediableInline " />
                                            {this.state.addressCity && this.state.addressCity.value == 'other' ?
                                                <EditText title="" name="newCityAddress" value={this.state.newCityAddress} placeholder="Enter city/town name" onValueChange={this.handleFieldValueChange} />
                                                :
                                                ""
                                            }
                                            <EditText title="Phone" name="phoneNumber" value={this.state.phoneNumber} placeholder="Enter phone number (optional)" onValueChange={this.handleFieldValueChange} />
                                            <EditText title="Email" name="email" value={this.state.email} placeholder="Enter email address" onValueChange={this.handleFieldValueChange} />
                                            <EditText title="Website" name="website" value={this.state.website} placeholder="Enter website (optional)" onValueChange={this.handleFieldValueChange} />
                                        </div>
                                        :
                                        <div>
                                            <InfoAddress address={this.state.addressDto} title="Address" />
                                            <InfoText text={this.state.phoneNumber} title="Phone Number" />
                                            <InfoEmail text={this.state.email} title="Email" />
                                            <ExternalLink link={this.state.website} title="Website" />
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>

                        <div className="card" id="photos">
                            <div className="card-body">
                                <Upload
                                    title="Photos"
                                    description="Upload all relevant photos here"
                                    icon="plus"
                                    items={this.state.photos}
                                    itemType="image"
                                    itemKey="photos"
                                    itemRepoType="OPP_IMAGES"
                                    itemChange={this.handleUpload}
                                    isEditable={isEditable}
                                />
                            </div>
                        </div>

                        <div className="card" id="videos">
                            <div className="card-body">
                                <Videos
                                    title="Videos"
                                    description="Include your videos here"
                                    icon="plus"
                                    items={this.state.videos}
                                    itemKey="videos"
                                    itemChange={this.handleVideoChange}
                                    isEditable={isEditable}
                                />
                            </div>
                        </div>

                        <div className="card" id="attachment">
                            <div className="card-body">
                                <Upload
                                    title="Attachments"
                                    description="Upload any attachments that are relevant to your Investment Opportunity"
                                    icon="cloud-upload-alt"
                                    items={this.state.attachments}
                                    itemType="doc"
                                    itemKey="attachments"
                                    itemRepoType="OPP_ATTACHMENTS"
                                    itemChange={this.handleUpload}
                                    isEditable={isEditable}
                                />
                            </div>
                        </div>

                        <div className="col-sm-12 col-xs-12 p-0">
                            <DeletePage type="Opportunity" uri={idUri} />
                            {this.state.loading ?
                                <button type="submit" className="btn btn-white m-r-0 float-right" >{STR.LOADING}</button>
                                :
                                <button type="submit" className="btn btn-info waves-effect waves-light m-r-0 float-right" onClick={this.handleSubmit}>Submit for Approval</button>
                            }

                        </div>

                    </div>
                </div>

            </div>
        )
    }
}
/*export default  OppContent;*/
export default (OppContentNew);