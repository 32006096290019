import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Helper from "../../shared/custom";
import store from '../../store';
import Helpers from '../../shared/custom';

class NotUser extends Component {

  constructor(props){
    super(props);
    this.state = {
      isAuthenticated:false,
      privileges:{},
      pageViewsLeft:0, 
    }
  }

  componentDidMount(){    
    let isAuthenticated = Helpers.authStatus();
    let pageViewsLeft = 0;
    if(isAuthenticated){
      pageViewsLeft = Helpers.getPageViewsLeft();
    }
    this.setState({
      isAuthenticated:isAuthenticated,
      pageViewsLeft:pageViewsLeft
    })
  }

  componentWillReceiveProps(){
    let newAuth = Helpers.authStatus();
    let currentAuth = Helpers.authStatus();
    if(newAuth !== currentAuth){
      this.setState({isAuthenticated:newAuth})
    }
    
  }

  render() {
    const title = this.props.title;
    let pageViewsLeft = this.props.pageViewsLeft || this.state.pageViewsLeft;

    return (
      <div className="card-body notUser">
         <h5 className="text-black">{title}</h5>

         {!this.props.showContent ? 
            <div className="text-center col-8 m-a text-black m-t-40">
              <div>
                  <i className="fa fa-lock login-icon"></i>
              </div>
              <div>
                  <h2 className="text-black">Sign in or create a free account to unlock this data</h2>
                  <button className="btn btn-info full-width" onClick={Helper.triggerLogin}>Sign In</button>
              </div>
              <div className="m-t-10">
              <Link to="/register">
                  <span className="text-center m-t-20 text-black"><b>Don't have an account? <span className="text-info">Create One</span></b></span>
              </Link>                 
              </div>
            </div>
          :
          ""}

         {pageViewsLeft < 1 && this.props.showContent ?
            <div className="text-center col-8 m-a text-black m-t-40">
              <div>
                  <i className="fa fa-lock login-icon"></i>
              </div>
              <div>
                  <h2 className="text-black">Upgrade your subscription to get more page views and unlock this data.</h2>
                  <Link to={Helpers.upgradeUrl()} className="btn btn-info full-width">
                    Upgrade
                  </Link>
                  
              </div>              
            </div>
          :
          "" 
          }
      </div>
    )
  }
}
export default withRouter(NotUser);
