import React, { Component } from 'react';
import { RealTimeChartWidget } from 'react-tradingview-widgets';
import Helper from '../../shared/custom';
import InfoText from "../ui/InfoText";
import NotUser from './NotUser';
import MiniCard from "../ui/MiniCard";


export default class Stockgraph extends Component {

  constructor(props){
    super(props);
    this.state ={
      companySymbol : undefined,
      stockData:{},
      status:"",
      currency:"",
      valuation:"",
      moneyRaised:"",
      sharePrice:"",
      date:"",
      exchange:"",
      ipoData:{},
      _empty:true
    }
  }


  componentWillReceiveProps(props){
    let stockData = props.stockData;
    if(!Helper.isEmpty(stockData) && stockData.status != "Private"){
      this.setState({
          companySymbol:stockData.stockSymbol?stockData.stockSymbol : "",
          status:stockData.status ? stockData.status:"",
          currency:stockData.currency?stockData.currency:"",
          valuation:stockData.valuation?stockData.valuation:"",
          moneyRaised:stockData.moneyRaised?stockData.moneyRaised:"",
          sharePrice:stockData.sharePrice?stockData.sharePrice:"",
          date:stockData.date ? stockData.date:"",
          exchange:stockData.exchange ? stockData.exchange:"",
          _empty:false,
          ipoData:stockData
      })
    }else{
      this.setState({
        _empty:true
      })
    }
  }
  

  render() {
    let showContent = this.props.showContent;
    let ipoData = this.state.ipoData;
    if(this.state._empty){
      return (<div></div>);
    }

    let companySymbol = this.state.companySymbol;
    let status = this.state.status;
    let currency=this.state.currency;
    let valuation=this.state.valuation;
    let moneyRaised=this.state.moneyRaised;
    let sharePrice=this.state.sharePrice;
    let date=this.state.date;
    let exchange = this.state.exchange;
    
    let addClass = "";

    if(!Helper.isEmpty(companySymbol) && companySymbol !== "N/A" ){
      addClass = "widgetHolder";
    }else{
      companySymbol = null;
    }

    return (
      <div>
         {showContent ?
            <div className="card">
              <div className="card-header text-left light-blue-header">
              <h5 className="m-b-0 m-t-0 text-white">IPO Details</h5>
            </div>

            <div className="card-body text-left">

            <div className="col-12 p-0">
                  <MiniCard
                      label="Stock Symbol"
                      value={
                       ipoData
                          ?ipoData.stockSymbol
                          : "NA"
                      }
                    />
                    <MiniCard
                      label="Status"
                      value={
                       ipoData && ipoData.status
                          ?ipoData.status
                          : "NA"
                      }
                    />
                    <MiniCard
                      label="Revenue Generating"
                      value={
                        ipoData && ipoData.moneyRaised
                           ?ipoData.moneyRaised
                           : "NA"
                       }
                    />
                    <MiniCard
                      label="Exchange"
                      value={
                        ipoData && ipoData.exchange
                           ?ipoData.exchange
                           : "NA"
                       }
                    />
                    <MiniCard
                      label="Date"
                      value={
                        ipoData &&ipoData.date
                           ?ipoData.date
                           : "NA"
                       }
                    />
                    <MiniCard
                      label="Currency"
                      value={
                       ipoData &&ipoData.currency
                          ?ipoData.currency.name
                          : "NA"
                      }
                    />
                     <MiniCard
                      label="Share Price"
                      value={
                       ipoData &&ipoData.sharePrice
                          ?ipoData.sharePrice
                          : "NA"
                      }
                    />
                     <MiniCard
                      label="Valuation"
                      value={
                       ipoData &&ipoData.valuation
                          ?ipoData.valuation
                          : "NA"
                      }
                    />
                  </div>
                  </div>
            </div>
            :
            ""

         } 
         {/* <div className={"card text-left bold-headers "+addClass} id={this.props.id}>
         { showContent ?
          <div>
             <div className="card-header text-left light-blue-header">
              <h5 className="m-b-0 m-t-0 text-white">IPO Graph</h5>
            </div>
            <div className="card-body text-left">
                {companySymbol? 
                    <div className="m-t-10">
                      <RealTimeChartWidget
                        container_id ="tradingview_6bbf4"
                        height ="610"
                        width ="100%"
                        symbol={companySymbol}
                        locale="en"
                        interval="D"
                        news = "headlines"
                        details = "true"
                        BarStyles = "LINE"
                      />
                  </div>
                    :
                  <div className="text-center"></div>
                }
            </div>
            </div>
         :
         <NotUser title={"IPO Details"}/>
         }
                
         </div>             */}
      </div>
    )
  }
}
