import React, { Component } from 'react';
import Axios from 'axios';
import Helper from "../../shared/custom";
import config from '../../shared/config';
// import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { withRouter, Link } from 'react-router-dom';
import Loader from "../ui/Loader";
import store from '../../store';

import { fetchUserInfo } from '../../actions/authAction';

import GAEventsTracker from "../../utils/GAEventsTracker";
const GAEventsTrackerTrigger = GAEventsTracker("Subscription")

export default class PaymentStatus extends Component {
  constructor(props){
      super(props);
      this.state = {
          paymentSuccess:false,
          loading:false,
          localProps:{},
          isAutheticated:false,
      }
      this.confirmPayment = this.confirmPayment.bind(this);
  }

  componentDidMount(){
        var storeState = store.getState();  
        this.setState({
            isLoggedIn: storeState.auth.isAuthenticated,
            userAuth:storeState.auth.user
        });

        if(storeState.auth.isAuthenticated){
            let paymentResponse = Helper.getQueryParameter("paymentResponse")  
            this.confirmPayment(paymentResponse);
        }

  }

  componentWillReceiveProps(props){
    // if(JSON.stringify(props) != JSON.stringify(this.state.localProps)){
    //     this.setState({
    //         localProps:props,
    //     });

      

    //     var storeState = store.getState();  
    //     this.setState({
    //         isLoggedIn: storeState.auth.isAuthenticated,
    //         userAuth:storeState.auth.user
    //     },()=>{

    //         if(this.state.isLoggedIn){
    //             let paymentResponse = Helper.getQueryParameter("paymentResponse")  
    //             this.confirmPayment(paymentResponse);
    //         }
    //     })  

      
    // }

  }

  backToPage = ()=>{
    //this.props.history.push('/');
    window.location.replace("/")
  }

  confirmPayment(paymentResponse){
     this.setState({
        loading:true
     }) 
    paymentResponse = atob(paymentResponse);
    paymentResponse = JSON.parse(paymentResponse);

    let payload ={
        id:null,
        payerId:null
    }

    if(paymentResponse.id){
        payload.id = paymentResponse.id;
    }

    if(paymentResponse.payerId){
        payload.payerId = paymentResponse.payerId;
    }


    Axios.post(
        config.api("payment/finalize"),
        paymentResponse)
    .then( (response) => {
        let res = response.data;        
        if(res.status == 200){
            this.setState({
                loading:false,
                paymentSuccess:true
            });
            GAEventsTrackerTrigger("Subcription happened, Status: Success",window.location.pathname);
            // setTimeout(()=>{
            //         this.backToPage();
            // },3000)
        }else{
            this.setState({
                loading:false,
                paymentSuccess:false
            });
            GAEventsTrackerTrigger("Subcription happened, Status: Failed",window.location.pathname);
            // setTimeout(()=>{
            //         this.backToPage();
            // },3000)
        }
        fetchUserInfo();
       
    })
    .catch((err) => {
        this.setState({
            loading:false,
            paymentSuccess:false
        });
        GAEventsTrackerTrigger("Subcription happened, Status: Failed",window.location.pathname);
        fetchUserInfo();
    });
  }


  render() {
      let loading = this.state.loading;
      let paymentSuccess = this.state.paymentSuccess;
    return (
        <div className="login-register">
         <div className="row content-in m-t-50 p-t-10">
            <div className="card text-center m-a col-lg-6 auth-card">
                <div class="card-body">
                    { loading ?
                    <div className="col-12">
                        <h1 className="text-default">Processing...</h1>
                        <img src={config.cdn +'theme/images/small-loader.gif'} className="img-processing"/>
                    </div>
                    :
                    <div className="col-12 payment-content">
                        {paymentSuccess ? 
                            <div>
                                <h1 className="text-green">Payment Successfull. <i className="fa fa-smile-o" aria-hidden="true"></i></h1> 
                                <h3 className="text-info">Thank you for subscribing with us.</h3>
                                <p className="lead">You will receive shortly an email with payment confirmation details.</p>
                                <p><a href="/" className="">Click here to redirect to homepage</a></p>
                            </div>
                            :
                            <div>
                                <h1 className="text-danger">Payment failure! <i className="fa fa-frown-o" aria-hidden="true"></i></h1>
                                <h3 className="text-info">Unable to authorize your payment.</h3>
                                <p className="lead">We are unable to authorize your payment at the moment, You may try again.</p> 
                                <p><a href="/" className="">Click here to redirect to homepage</a></p>
                            </div>
                        }
                        <hr/>
                        <p className="lead">
                            <Link className="btn btn-info"  to= {paymentSuccess ? "/" : "/subscribe"} role="button">Continue</Link>
                        </p>
                        <p>
                            Having trouble? <a  href="/contact" target="_blank" >Contact us</a>
                        </p>
                     </div>
                    }
                   </div>
            </div>
         </div>
        </div>
    )
  }
}
