import React, { Component } from "react";
import Helper from "../../shared/custom";
import config from "../../shared/config";
import { withRouter, Link } from 'react-router-dom';

import CareerHistory from "./CareerHistory";
import CreateInvestment from "./CreateInvestment";
import EducationHistory from "./EducationHistory";

import InfoTextArray from "../ui/InfoTextArray";

import InfoText from "../ui/InfoText";
import ExternalLink from "../ui/ExternalLink";
import ReadMoreAndLess from "react-read-more-less";
import InfoAddress from "../ui/InfoAddress";

import BoxSlider from "../wigets/BoxSlider";
import store from "../../store";

import Investments from "../profile/Investments";
import Follow from "../uiActions/Follow";
import ProductService from "../createCompany/ProductService";

import SimularPeople from "./SimularPeople";

class UserProfileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContent: true,
      peopleDetails: {},
      pageDetails: {},
      stats:{},
      uri: "",
      isOwner:false,
      users:{},
    };
    // store.subscribe(() => {
     

    // })
  }

  componentWillReceiveProps(props) {
    let peopleDetails = props.peopleDetails || {};
    let pageDetails = props.pageDetails || {};

    var storeState = store.getState();
    let storeAuth = storeState.auth.isAuthenticated;
    let users = storeState.auth.user;
    this.setState({users:users})

    let ownerDetails = pageDetails && pageDetails.owner ? pageDetails.owner.id : null;

    let isOwner = (users.id == ownerDetails || users.adminOwner);
    this.setState({isOwner:isOwner})

    this.setState({
      peopleDetails: peopleDetails,
      pageDetails: pageDetails,
      uri: this.props.uri,
      stats:this.props.stats
    });


  }

  componentDidMount() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
    this.setState({
      uri: this.props.uri,
      stats:this.props.stats
    });
  }

  compare(a, b) {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
  }

  render() {
    
    let peopleDetailsObj = this.state.peopleDetails;
    let pageDetailsObj = this.state.pageDetails;
    let isOwner = this.state.isOwner;


    let profilePicUrl = !Helper.isEmpty(pageDetailsObj.image)
      ? Helper.dataPath(pageDetailsObj.image)
      : Helper.dataPath(config.defaultUserImage);
    let careerHistory = peopleDetailsObj.careerHistory
      ? peopleDetailsObj.careerHistory
      : [];
    let educationHistory = peopleDetailsObj.educationHistory
      ? peopleDetailsObj.educationHistory
      : [];

    let pQualification = peopleDetailsObj.profQualifications;

    let lastCareer = careerHistory[0];
    let lastCompanyName = "";
    let lastTitleName = "";

    if(peopleDetailsObj.primaryTitle){
        lastTitleName = peopleDetailsObj.primaryTitle.title ? peopleDetailsObj.primaryTitle.title.name : "";
        lastCompanyName = peopleDetailsObj.primaryTitle.company ? peopleDetailsObj.primaryTitle.company.name : "";
    }else{
      let title =
        peopleDetailsObj.titles && peopleDetailsObj.titles[0]
          ? peopleDetailsObj.titles[0]
          : undefined;
      if (title) {
        lastCompanyName = title.company.name || "";
        lastTitleName = title.title.name || "";
      }else{
        if (lastCareer) {
          lastCompanyName = lastCareer.company.name;
          lastTitleName = lastCareer.title.name;
        } 
      }
    }
    

    let priInd = peopleDetailsObj.industries ? peopleDetailsObj.industries : [];
    let primaryIndustry = priInd[0];
    let primaryIndustryName =
      primaryIndustry && primaryIndustry.industry
        ? primaryIndustry.industry.name
        : "";
    let cityName = pageDetailsObj.city ? pageDetailsObj.city.name : "";
    let regions = peopleDetailsObj.regions ? peopleDetailsObj.regions:[]
    let countryName = pageDetailsObj.country ? pageDetailsObj.country.name : "";
    let sectorsList = peopleDetailsObj.sectors ? peopleDetailsObj.sectors : [];

    let formatedRegion =  [];
    
    if(!Helper.isEmpty(regions)){
      regions.forEach((list)=>{
        formatedRegion.push(list.name)
      })
    } 

    let sectorName = "";
    if (!Helper.isEmpty(sectorsList)) {
      let sectorObj = sectorsList[0];
      sectorName = sectorObj ? sectorObj.sector.name : "";
    }

    let industiresNames = [];
    priInd.forEach(function(ind) {
      industiresNames.push(ind.industry.name);
    });

    let location = "";

    location += cityName;
    location += cityName ? ", " + countryName : countryName;

  



    let summary = peopleDetailsObj.summary
      ? peopleDetailsObj.summary
      : peopleDetailsObj.description;
    summary = summary || "";

 
    let banner = pageDetailsObj.banner;
    let bannerUri = !Helper.isEmpty(banner) ? banner.uri : "";
    let bannerType = !Helper.isEmpty(banner) ? banner.type : "";;
    

    let titles = this.state.peopleDetails && this.state.peopleDetails.titles?
                this.state.peopleDetails.titles.map(x=>{
                    return (<span>{x && x.title && x.title.name ?  x.title.name + " at " : "" } {x && x.company && x.company.uri ? <Link to={"/company/"+x.company.uri}>{x.company.name}</Link> : x.company.name} </span>)
                  })
                  :null;

    let professionalBody = this.state.peopleDetails && this.state.peopleDetails.professionalBody ?  this.state.peopleDetails.professionalBody[0] : null;
  

    return (
      <div>
        <div className="row bold-headers text-left p-b-30">
          <div className="col-md-12 col-lg-12 col-xlg-12 p-0">
            <div className="card" id="overview">
              <div className="card-body banner-small">
                <div className="col-lg-12 p-0 ">
                  <div className="col-lg-12 p-0">
                    <div className="m-t-0">
                      <img
                        src={profilePicUrl}
                        className="img-circl float-left"
                        width="100"
                      />
                      <div className="card-body-right float-left m-l-20 text-left">
                        <h2 className="card-title m-t-5 m-b-0">
                          {pageDetailsObj.name}
                          <span className="text-info" />
                        </h2>
                        {/* <h5 className="m-0 font-16 font-normal"> */}
                          {/* {lastTitleName}
                          {lastTitleName && lastCompanyName ? ", " : ""}
                          {!Helper.isEmpty(bannerUri) && !Helper.isEmpty(bannerType) ? <Link to={'/'+bannerType+'/'+bannerUri}>{lastCompanyName}</Link> : lastCompanyName} */}
                          {titles?titles.map(x=>{return (<h5 className="m-0 font-12 font-normal">{x}</h5>) }):""}
                        
                        {/* {!Helper.isEmpty(primaryIndustryName) ? <h6 className="font-16 font-normal">
                          {primaryIndustryName}
                        </h6>:""} */}
                        <h6 className="font-14 m-0 text-grey font-normal">{location}{!Helper.isEmpty(formatedRegion) ? " | "+formatedRegion.join(","):""}</h6>
                        {pQualification && <h4 className="m-t-10 font-14  text-grey font-normal">Profession &amp; Qualification: {pQualification}</h4>}
                        {!Helper.isEmpty(professionalBody)   && <h4 className="m-t-0 font-14  text-grey font-normal">Professional Body: {professionalBody.name}</h4>}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="clearfix" />
                <div className="col-md-12 p-0">
                  <hr />
                  <div className="col-md-2 float-left">
                    <h3 className="font-16 l-h-0">Following</h3>
                    <div className="text-info font-bold">
                      {peopleDetailsObj.following}
                    </div>
                  </div>
                  <div className="col-md-2 float-left">
                    <h3 className="font-16 l-h-0">Followers</h3>
                    <div className="text-info font-bold">
                      {0}
                    </div>
                  </div>
                  <div className="col-md-3 float-left">
                    <h3 className="font-16 l-h-0">Profile Views</h3>
                    <div className="text-info font-bold">
                      {this.state.stats.viewsCount}
                    </div>
                  </div>
                </div> */}
                <div className="clearfix" />
                <div className="col-md-12 p-0">
                  <hr />
                  <div className="col-md-12 float-left">
                    
                    {isOwner ? <Link to={'/people-details/create?uri='+pageDetailsObj.uri} className={"btn btn-info float-left m-l-5"}><i className="fa fa-edit"></i>Edit</Link> : <Follow uri={pageDetailsObj.uri} type={"PEOPLE"} showEdit={false} />}
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
               <div class="card-header text-left light-blue-header"><h5 class="m-b-0 m-t-0 text-white">Summary</h5></div>
              <div className="card-body">
                <div className="col-lg-12 p-0 m-h-30">
                   {summary ? <div className="content font-14" dangerouslySetInnerHTML={{__html:summary}}></div>  : "No data available"}

                   <InfoText title="Professional" text={lastTitleName} />
                </div>
               </div> 
            </div> 

           

           {!Helper.isEmpty(peopleDetailsObj.psl)  ?  <ProductService psl={peopleDetailsObj.psl} title="Services Offered"/> :  <ProductService psl={"-"} type="text"  title="Services Offered"/> }
            

            {/* <div className="card">
               <div class="card-header text-left light-blue-header"><h5 class="m-b-0 m-t-0 text-white">Overview</h5></div>
              <div className="card-body">
                <div>
                             
              
                  <InfoText
                    title="Gender"
                    text={
                      peopleDetailsObj.gender
                        ? peopleDetailsObj.gender.name
                        : ""
                    }
                  />
                  <InfoText title="Title" text={lastTitleName} />
                  <InfoText title="Company" text={lastCompanyName} uri={bannerUri} type={bannerType}/>
                  <InfoText title="Location" text={location} />
                  <InfoText title="Sector" text={sectorName} />
                  <InfoTextArray
                    textArray={industiresNames}
                    title="Industries"
                  />
                </div>
              </div>
            </div> */}

            <div className="card text-left bold-headers" id="contact">
              <div class="card-header text-left light-blue-header"><h5 class="m-b-0 m-t-0 text-white">Contact</h5></div>
              <div className="card-body">
                
                <div>
                  <div>
                    {/* <InfoAddress
                      address={peopleDetailsObj.address}
                      title="Address"
                    /> */}
                    
                      <InfoTextArray title="Phone" textArray={pageDetailsObj.contactNumbers} /> 
                      <InfoTextArray textArray={[peopleDetailsObj.email]} title="Email Address"  type="email"/>
                   
                    {/* <InfoText text={peopleDetailsObj.phone} title="Phone" fieldType="phone"/>
                    <InfoText text={peopleDetailsObj.email} title="Email" /> */}
                      {pageDetailsObj.facebook ||
                      pageDetailsObj.twitter ||
                      pageDetailsObj.linkedIn ? (
                        <h6>Social Media</h6>
                      ) : (
                        ""
                      )}
                      <ExternalLink
                        link={pageDetailsObj.facebook}
                        text="Facebook"
                      />
                      <ExternalLink
                        link={pageDetailsObj.twitter}
                        text="Twitter"
                      />
                      <ExternalLink
                        link={pageDetailsObj.linkedIn}
                        text="LinkedIn"
                      />
                      <ExternalLink
                        title="Website"
                        link={pageDetailsObj.website}
                      />
                  </div>
                </div>
              </div>
            </div>
            <CareerHistory careerHistory={careerHistory} />
            <Investments 
              id="investment"
              theme="white"
              entityUri={this.state.uri}
              showContent={this.state.showContent}
              entityType="people"
              showContent={true}
              pageViewsLeft={1}
            />
            <EducationHistory educationHistory={educationHistory} />
            <div className="card text-left bold-headers" id="certificates">
              <div class="card-header text-left light-blue-header"><h5 class="m-b-0 m-t-0 text-white">Certifications</h5></div>
              <div className="card-body">
                    {pageDetailsObj && pageDetailsObj.documents ? 
                      pageDetailsObj.documents.map((item,i)=>(
                      <p className="m-b-0 docAll"><a  href={Helper.viewFile(item.uniqueId)} target="_blank">{Helper.getFileExtension(item.name) == "pdf" ? <i class="fa fa-file-pdf-o" aria-hidden="true"></i>: <i class="fa fa-file" aria-hidden="true"></i>}&nbsp;{item.name}</a></p>
                      ))
                      :
                      <p className="text-center">No data found</p>
                    }
              </div>
            </div>

            {pageDetailsObj && pageDetailsObj.uri &&<SimularPeople uri={pageDetailsObj.uri}/>}
            {/* <CreateInvestment editable={false} /> */}
            {/* <BoxSlider
              type="events"
              uri="events"
              title="Events"
              items="3"
              id="events"
            /> */}
            {/* <Relatednews entityUri={this.state.uri} type="PEOPLE" /> */}
          </div>
        </div>
      </div>
    );
  }
}

export default UserProfileView;
