import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Helper from '../../shared/custom';
import Select from 'react-select';

import SubForm from "./SubForm1";
import SubForm2 from "./SubForm2";

class Forms extends Component {
    constructor(props){
        super(props);
        
        this.state = {
            metaList:null,
            business:null,
            
            step:1,

            type:undefined,
            ownershipStatus:undefined,
            name:undefined,
            legalName:undefined,
            companySize:undefined,
            headquartersCountry:undefined,
            headquartersCity:undefined,
            founded:undefined,
            parentCompany:undefined,
            areasServed:[],
            sector:[],
            industries:[],
            modeFrom:"create"
        }

      this.handleInputChange = this.handleInputChange.bind(this); 
      this.handleChildInputChange = this.handleChildInputChange.bind(this);   
      this.handleChildSelectChange = this.handleChildSelectChange.bind(this);
     
      this.validateFormAndProgress = this.validateFormAndProgress.bind(this);
      this.nextForm = this.nextForm.bind(this);
      this.prevForm = this.prevForm.bind(this);
      this.showStep = this.showStep.bind(this);
    }
    
    handleInputChange(e){
        var elem = e.target;
    }
    
    handleChildInputChange(e){        
        let name = e.target.name;
        let value = e.target.value;
        if(name){
            this.setState({[name]:value});
        }
    }

    handleChildSelectChange(options,e){        
        let name = e.name;
        if(name){
            switch(name){
                case "headquartersCountry":
                this.setState({
                    "headquartersCountry":options,
                    "headquartersCity":null,
                })
                break;

                default:
                this.setState({[name]:options});
                break;
            }            
        }
    }

    nextForm(e){
        e.preventDefault();
        const { step } = this.state;
        this.showStep(step+1);
    }

    prevForm(e){
        e.preventDefault();
        const { step } = this.state;
        this.showStep(step-1);
    }

    showStep(step){
        step = step || 1;
        this.setState({ step: step });
    }

    validateFormAndProgress(e){
        e.preventDefault();
        let step = this.state.step;
        switch(step){
            case 1:
                if(Helper.isEmpty(this.state.areasServed) || this.state.areasServed.length == 0){
                    Helper.pushTError("SELECT_AREA_SERVED");
                    return false;
                }                
                this.showStep(2);
                break;

            case 2:
                if(Helper.isEmpty(this.state.name)){
                    Helper.pushTError("ENTER_COMPANY_NAME");
                    return false;
                }   
                
                if(Helper.isEmpty(this.state.legalName)){
                    Helper.pushTError("ENTER_LEGAL_NAME");
                    return false;
                }

                if(Helper.isEmpty(this.state.companySize)){
                    Helper.pushTError("CHOOSE_COMPANY_SIZE");
                    return false;
                }

                if(Helper.isEmpty(this.state.headquartersCountry)){
                    Helper.pushTError("CHOOSE_HQ_COUNTRY");
                    return false;
                }

                if(Helper.isEmpty(this.state.headquartersCity)){
                    Helper.pushTError("CHOOSE_HQ_CITY");
                    return false;
                }

                if(Helper.isEmpty(this.state.founded)){
                    Helper.pushTError("ENTER_FOUNDED_YEAR");
                    return false;
                }
                this.showStep(5);
                break;
            case 3:
                if(Helper.isEmpty(this.state.sector)){
                    Helper.pushTError("CHOOSE_COMPANY_SECTOR");
                    return false;
                }                
                this.showStep(4);
                break;
            case 4:
                if(Helper.isEmpty(this.state.industries) || this.state.industries.length == 0 ){
                    Helper.pushTError("CHOOSE_MIN_ONE_IND");
                    return false;
                }  
                this.submitForm();
                break;
            default:
                this.showStep(1);
                break;  
        }
    }

    getForm(step, type){
        let business = null;
        switch(step){
            case 1:
             return (<SubForm 
                        onSelectChange={this.handleChildSelectChange} 
                        values={this.state}                         
                        metaList = {this.props.metaList}
                        type = {type}
                    />)
            case 2:
             return (<SubForm2 
                        onInputChange={this.handleChildInputChange}
                        onSelectChange={this.handleChildSelectChange}
                        values={this.state}
                        business={this.props.business}
                        metaList = {this.props.metaList}
                     />)
            //  case 3:
            //   business = this.props.business;
            //   let sectorOptions = [];
              
            //   if(business && business.sectorsArray){
            //     sectorOptions = Helper.makeSelectOptions(business.sectorsArray);
            //   }

            //   return (<div className="form-body opp-form">
            //              <div className="row">
            //                  <div className="col-md-12">
            //                     <div className="form-group">
            //                         <label className="control-label isMandatoryLabel">Sector</label>
            //                         <Select
            //                             name="sector"
            //                             class="select-2"
            //                             options={sectorOptions}
            //                             value={this.state.sector}
            //                             onChange={this.handleChildSelectChange}
            //                             placeholder="Choose the sector under which your company is falling."
            //                             isMulti
            //                         />
            //                     </div>
            //                  </div>
            //              </div>
            //            </div>)
            // case 4:

            // business = this.props.business;
            // let industryOptions = [];
            
            // if(business && business.industriesArray){
            //     industryOptions = Helper.industriesBySector(business.industriesArray, this.state.sector)
            //     //Helper.makeSelectOptions(business.industriesArray);
            // }

            // return (<div className="form-body opp-form">
            //             <div className="row">
            //                 <div className="col-md-12">
            //                     <div className="form-group">
            //                         <label className="control-label isMandatoryLabel">Industries</label>
            //                         <Select
            //                             name="industries"
            //                             class="select-2"                                        
            //                             isMulti
            //                             options={industryOptions}
            //                             value={this.state.industries}
            //                             onChange={this.handleChildSelectChange}
            //                             placeholder="What industries is your company in? Choose as many industries as needed."
            //                         />
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>)
             case 5:
             return(
                 this.submitForm()
             )
        }
    }

    submitForm(){     
        let state = this.state;
        state.type = this.props.type;

        this.props.history.push({
            pathname:'/company-details/new',
            state:state
        });
    }

    render() {
    let step = this.state.step;
    let type = this.props.type;
    
    return (
      <div>
          <div className="card-body opp-card text-left">
                <h4 className="card-title">{type.name}</h4>
                <p className="card-text">{this.props.typeDescription}</p>
                  <form className="m-0">
                  
                    {this.getForm(step,type)}
                     <div className="col-md-12 text-center">
                        {step > 1 ?<button className="btn btn-danger btn-md btn-custom-md m-b-10" onClick={this.prevForm}>Back</button>:""}
                        <button className="btn btn-info btn-md btn-custom-md m-l-10" onClick={this.validateFormAndProgress}>Continue</button>
                      </div>
                  </form>   
           </div>
      </div>
    )
  }
}

export default withRouter(Forms);
