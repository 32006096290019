import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Sidemenu from '../../sidemenu/Sidemenu';
import UserFeedRightPanel from '../../homepage/UserFeedRightPanel';
import Helpers from "../../../shared/custom";
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import Axios from "axios";
import OppCard from  "../Summary/OppCard";
import MyPagination from "../../ui/MyPagination";

export class MyOpp extends Component {
    constructor(props){
        super(props);
        this.state = {
            status:"",
            data:{},
            enums:[],
            activePage:1,
            totalRecords:0,
            loading:false
        }

    }  
    componentDidMount(){
        this.fetchEnum();
        this.fetchSummaryData()
        setTimeout(() => {
            window.scrollTo(0, 0);
          });
    }

    handlePageChange = (pageNumber)=> {
        this.setState({
            activePage:pageNumber
        },()=>{
            this.fetchSummaryData()
        })
    }

    delistAnOpp =(id,idx)=>{
        let dataset  =this.state.data;
       
        this.setState({
            loading:true
        })
        Axios.put(Helpers.apiURL({ uri: `api/opportunity/${id}/delist` }))
        .then(response => {
            this.setState({
                loading:false
            })

            Helpers.pushSuccess("Opportunity has been delisted successfully!");
            
            dataset[idx].status =  {
                label: "Delisted",
                value: "DELISTED"
            }
            this.setState({
                data:dataset
            })
           // this.fetchSummaryData()
        })
        .catch(err => {
            this.setState({
                loading:false
            })
          Helpers.pushError(err.response.data.message)
        });
    }

   fetchEnum = ()=>{
        
        Axios.get(
            Helpers.apiURL({
                uri:`api/enum/list`,
            })
        )
        .then( (response) => {
            let data  = response.data.result || [];  
            this.setState({
                enums:data
            })
        })
    }

    static getDerivedStateFromProps(nextProps, prevState){
        let newLocation = nextProps.location.pathname;
        let uri = Helpers.lastSegment(newLocation);
        if(!Helpers.isEmpty(uri)){
            return { newUri: uri};
        }
        else return null;
      }

      componentDidUpdate(){
          if(this.state.uri != this.state.newUri){
              this.setState({
                    uri :this.state.newUri
              })
          }
          setTimeout(() => {
            window.scrollTo(0, 0);
          });
      }

      fetchSummaryData = ()=>{
        var obj = {};
        let sortBy = this.state.status;

        obj.page = this.state.activePage;
        obj.size = 10;
        obj.listingStatuses = !Helpers.isEmpty(sortBy) ? sortBy : "";
        obj.includeRating = true;
           
           this.setState({loading:true})
            Axios.get(
                Helpers.apiURL({
                    uri:"api/opportunity/my-opportunities/",
                    query:obj
                })
                )
                .then((response) => {
                     let result = response.data.content;
                     this.setState({
                        data:result,
                        totalRecords:response.data.totalElements,
                        loading:false
                     })
                })
                .catch((response) => {
                    this.setState({
                        loading:false
                     })
                })
 

    }
    filterData = (e)=>{
        this.setState({
            status:e.target.value,
            activePage:1,
            totalRecords:0
        },()=>{
            this.fetchSummaryData()
        })
    }

    render() {
        let {uri,loading,enums,data} = this.state;
        return (
            <div>
              <div className="container-fluid">
                  <div className="row content-in m-t-80 p-t-10">
                    <div className="col-lg-3 col-xlg-3 col-md-3 searchMenu sideMenuCont">
                         <Sidemenu />
                    </div>
                    <div className="col-lg-6 col-xlg-6 col-md-6 centerDiv">
                            <div className="col-md-12 p-0">
                                <LoadingOverlay>
                                <Loader fullPage loading={loading} />
                                </LoadingOverlay>
                                <div className="card text-left b-header">
                                        <div className="card-header text-left p-0">
                                            <div className="col-md-8 float-left light-blue-header-half header-div">My Opportunities</div>
                                            <div className="col-md-4 float-left text-right">
                                            </div>
                                        </div>
                                        <div className="card-header text-left p-0">
                                            <div className="col-md-12 float-left light-blue-header text-white header-div">Search Results for:</div>
                                        </div>
                                        <div className="card-body p-t-10 p-b-5 bg-white filterBody">
                                                   <label className="float-right align-flex">Filter: 
                                                       <select className="form-control filterSelect m-l-5" onChange={this.filterData}>
                                                            <option value="null">Select status</option>
                                                            {
                                                                enums && enums.ListingStatus &&  enums.ListingStatus.map((list,i)=>(
                                                                <option value={list.value}>{list.key}</option>
                                                                ))
                                                            }
                                                        </select>
                                                </label>
                                        </div>
                                </div>
                                <div className="card">
                                       <div className="card-body">
                                                {
                                                    !Helpers.isEmpty(data) ? data.map((list,i)=>(
                                                        <>
                                                            <OppCard data={list} mode="list" delistOpp={this.delistAnOpp} idx={i}/>   
                                                        </>
                                                    ))
                                                    :
                                                    <div className="textx-center">No data available</div>
                                                }
                                            {this.state.totalRecords > 9 &&
                                                <MyPagination
                                                        activePage={this.state.activePage}
                                                        totalRecords={this.state.totalRecords}
                                                        onChange={this.handlePageChange}
                                                        itemsCountPerPage={10}
                                                    />}
                                                
                                        </div>
                                </div>
                            </div>
                    </div>
                    <div className="col-lg-3 col-xlg-3 col-md-3 m-t-2 right-side">
                        <UserFeedRightPanel />
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default withRouter(MyOpp);
