import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import config from '../../shared/config';
import Forms from './Forms';
import Axios from 'axios';

import { connect } from 'react-redux';
import { getMetaData } from '../../actions/metaAction';
import { getSectorData } from '../../actions/businessAction';
import store from '../../store';
import Helper from '../../shared/custom';
import { type } from 'os';

class Cards extends Component {

  constructor(props){
      super(props)
      this.state = {           
            formActive:{},
            metaList:{},
            business:{},
            types : []
      };

      this.switchForm = this.switchForm.bind(this);
      store.subscribe(() => { 

        var storeState = store.getState();
        var localMetaList = this.state.metaList;
        var storeMetaList = storeState.meta.metaList; 
        
        if(!localMetaList || JSON.stringify(localMetaList) != JSON.stringify(storeMetaList)){
            this.setState({metaList:storeMetaList});
        }

        var localBusiness = this.state.business;
        var storeBusiness = storeState.business.data;
        if(!localBusiness || JSON.stringify(localBusiness) != JSON.stringify(storeBusiness)){
            this.setState({business:storeBusiness});
        }

    });
  }
 
  getOpportunityTypes(){    
    Axios.get(
        Helper.apiURL({
            uri:"opportunity/type",
            query:{includeSubTypes:true}
        })
    )
    .then( (response) => {
        let types  = response.data.result || [];
        this.setState({types:types}); 
    })
    .catch((err) => {
        //throw error here
    });
  }

  componentDidMount() {
    var storeState = store.getState();      
    let meta = storeState.meta;
    let business = storeState.business;
    
    if(Helper.isEmpty(business.data) == true){            
        this.props.getSectorData();
    }else{
        this.setState({business:business.data});
    }
 
    if(Helper.isEmpty(meta.metaList) == true){
        this.props.getMetaData();
    }else{
        this.setState({metaList:meta.metaList});
    }

    this.getOpportunityTypes();
  }

  switchForm(e){
    e.preventDefault();
    var formActive = this.state.formActive;
    formActive[e.target.name] = true;
    this.setState({formActive:formActive});
  }

  render() {
    let types = this.state.types;
    let formActive = this.state.formActive;
    let metaList = this.state.metaList;
    let budgetRange = metaList.BUD || [];

    
    let business = this.state.business;
    let industryList = business?business.industriesArray:[];
   
    return (
      <div className="row full-width bold-headers">
        <div className="col-md-12 text-left m-t-20 m-b-20"> 
          <div className="col-md-12">
            <h1>Create an Investment Opportunity</h1>
            <h5 className="font-normal">Connect your opportunity to potential investors on Investera + ! Start off by choosing the Primary Investment Type below.</h5>
          </div>     
       </div>
       
         <div className="col-md-12">
                
                {
                    types.map((type, index) => (
                        <div className="col-md-6 float-left" key={index}>
                            <div className="card text-center">
                                {!formActive[type.id] ? 
                                    <div className="card-body opp-card">
                                        <h2> <img src={type.image ? Helper.dataPath(type.image) : Helper.dataPath(config.defaultOpportunityIcon)} className="rounded-img img-circle" width="150"/></h2>
                                        <h4 className="card-title">{type.name}</h4>
                                        <p className="card-text">{type.description}</p>
                                        <button className="btn btn-theme-white m-t-80" onClick={this.switchForm} name={type.id} >Get Started</button>
                                    </div>
                                    :
                                    <div>
                                        {/* <div className="col-md-12"><a className="float-right p-10 text-danger" onClick={this.hideForm}><i className="fa fa-times"></i></a></div> */}
                                        <Forms type={type} budgetRangeList={budgetRange} industryList={industryList}  />
                                    </div>
                                }                                
                            </div>
                        </div>
                    ))
                }
             
        </div>
      </div>
    )
  }
}

export default connect(null,{ getMetaData , getSectorData})(withRouter(Cards));
