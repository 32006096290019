import React, { Component } from 'react'

export default class CommentsAction extends Component {

  constructor(props){
        super(props);
        this.state={

        }
        this.deleteComment = this.deleteComment.bind(this);
        this.enableEdit = this.enableEdit.bind(this);
  }   

  deleteComment(event){
     event.preventDefault();
     let ids = {}
     ids.id = event.currentTarget.getAttribute("id");
     ids.uniqueid = event.currentTarget.getAttribute("data-uniqueid");
     this.props.deleteComment(ids);
  }
  
  enableEdit(event){
        let ids = {}
        ids.id = event.currentTarget.getAttribute("id");
        ids.uniqueid = event.currentTarget.getAttribute("data-uniqueid");
        ids.index = event.currentTarget.getAttribute("index");
        this.props.enableEdit(ids);
        event.preventDefault();
  }

  
  
  render() {
    let data = this.props.data;
    let index = this.props.index;
    return (
      <div>
            <div className="dropdown">
                <a className="text-muted dropdown-toggle newsDropdown"  id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-ellipsis-v"></i>
                </a>
                <div className="dropdown-menu dropdown-menu-right mailbox ddMailbox font-12" aria-labelledby="dropdownMenuButton1">
                    <div className="message-center">
                            <a className="dropdown-item" href="javascript:void(0)" id={data.id} data-uniqueid={data.feedUniqueId}  index={index} onClick={this.enableEdit}>
                                    <div className="dpIcon m-r-5">   
                                            <i className="fas fa-edit"></i>
                                    </div>
                                    <div className="mail-contnet dd-content">
                                        <h5 className="font-medium font-14">Edit Comment</h5>
                                    </div>
                            </a>
                            <a className="dropdown-item" href="javascript:void(0)" id={data.id} data-uniqueid={data.feedUniqueId}  onClick={this.deleteComment}>
                                    <div className="dpIcon m-r-5">   
                                            <i className="fas fa-trash"></i>
                                    </div>
                                    <div className="mail-contnet dd-content">
                                        <h5 className="font-medium font-14">Delete Comment</h5>
                                    </div>
                            </a>
                        
                        </div>
                    </div>
            </div>
      </div>
    )
  }
}
