import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import store from '../../store';
import Helpers from '../../shared/custom';
import moment from "moment";
import { fundingRound } from '../../actions/fundingRoundAction';
import NotUser from './NotUser';
import Axios from 'axios';
import STR from '../../shared/strings';
import { MDBDataTable } from 'mdbreact';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import Collapse, { Panel } from 'rc-collapse';
import config from "../../shared/config";


const $ = require('jquery');
$.DataTable = require('datatables.net');

class Funding extends Component {


    constructor(props) {
        super(props);
        this.state = {
            uri: undefined,
            fundingRound: [],
            total: 0,
            usedCurrency: "",
            company: [],
            fundingSeeMore:false
        }

        store.subscribe(() => {
            var fundRound = store.getState();
            let company = fundRound.entity && fundRound.entity.company && fundRound.entity.company.entityInfo ? fundRound.entity.company.entityInfo.company : []
            fundRound = fundRound.fundRound.data || null;

            this.setState({
                fundingRound: fundRound.fundingRounds,
                company: company
            }, () => {

            });

            var total = 0;
            //let usedCurrency = this.state.fundingRound && this.state.fundingRound[0] && this.state.fundingRound[0].currency.shortName;
            //let unifiedCurrency = true;
            let usedCurrency = "USD";
            if (!Helpers.isEmpty(this.state.fundingRound)) {
                this.state.fundingRound.forEach((data, index) => {
                    total += data.amountRaisedInSelectedCurrency ? data.amountRaisedInSelectedCurrency : 0;
                    //if (usedCurrency != data.currency.shortName){
                    //unifiedCurrency = false;
                    //}
                });
            }


            // if (unifiedCurrency !== true){
            //     total = 0;
            // }

            this.setState({
                total: total,
                usedCurrency: usedCurrency
            })


        });

        this.fetchFundingRounds = this.fetchFundingRounds.bind(this);
        this.populateTableData = this.populateTableData.bind(this);

    }

    // componentWillUnmount(){
    //     $('.data-table-wrapper')
    //     .find('table')
    //     .DataTable()
    //     .destroy(true);
    // }

    componentWillReceiveProps(nextProps) {
        let currentUri = this.state.uri;
        let newUri = nextProps.entityUri;
        if (currentUri && currentUri == newUri) {
            return false;
        }

        if (!nextProps.showContent) {
            return false;
        }

        this.setState({
            uri: newUri,
            fundingRound: [],
            total: 0,
        }, () => {

            this.fetchFundingRounds();
        })

    }

    fetchFundingRounds() {
        let uri = this.state.uri;
        let type = "company";

        Axios.get(
            Helpers.apiURL({
                uri: "funding-round/:uri",
                pathVar: {"uri": uri},
                query: {
                    "type": type
                }
            })
        )
            .then((response) => {
                let frData = response.data.result || {};
                this.setState({
                    fundingRound: frData.fundingRounds
                }, function () {
                    this.populateTableData();

                })
            })
            .catch((err) => {
                this.setState({
                    fundingRound: []
                })
            });
    }

    populateTableData = () => {

        let dataset = this.state.fundingRound;
        let array = {
            columns: [
                {
                    label: 'Announced Date',
                    field: 'date',
                    sort: 'asc',
                },
                //   {
                //     label: 'Transaction Name',
                //     field: 'name',
                //     sort: 'asc',
                //   },
                {
                    label: 'Funding Type',
                    field: 'fundingType',
                    sort: 'asc',
                },
                {
                    label: 'No. Of Investors',
                    field: 'numOfInvestors',
                    sort: 'asc',
                },
                {
                    label: 'Funding Stage',
                    field: 'fundingStage',
                    sort: 'asc',
                },
                {
                    label: 'Pre Money Valuation',
                    field: 'preMoneyValuation',
                    sort: 'asc'
                },
                {
                    label: 'Money Raised',
                    field: 'amountRaised',//respective currency
                    sort: 'asc',
                },
                {
                    label: 'Equity Only',
                    field: 'isEquityOnlyFunding',
                },
                {
                    label: 'Lead Investors',
                    field: 'leadInvestor',
                },
            ],
            rows: [],
        };
      
        dataset.forEach((data) => {
            let date = "";
            // let name = "";
            let fundingType = "";
            let numOfInvestors = "";
            let amountRaised = "";
            let fundingStage = "";
            let leadInvestor = [];
            let preMoneyValuation = "";
            let isEquityOnlyFunding = "";
            var dateVal = moment(data.date).format('DD-MMM-YYYY');
            if (!Helpers.isEmpty(data.date)) {

                date = dateVal
            }
            // if(!Helpers.isEmpty(data.name)){
            //     name = data.name
            // }
            if (!Helpers.isEmpty(data.numOfInvestors)) {
                numOfInvestors = data.numOfInvestors
            }
            if (!Helpers.isEmpty(data.fundingStage)) {
                fundingStage = data.fundingStage
            }
            if (!Helpers.isEmpty(data.fundingType)) {
                fundingType = data.fundingType
            }
            if (!Helpers.isEmpty(data.amountRaised && data.currency)) {
                amountRaised = Helpers.displayAmount({amount: data.amountRaised.toFixed(0), currency: data.currency});
            } else {
                amountRaised = "Undisclosed"
            }
            if (!Helpers.isEmpty(data.leadInvestors)) {
                data.leadInvestors.forEach((inv, index) => {
                    let link = "/" + inv.type + "/" + inv.uri;
                    let structuredLink = <Link to={link}> {((index == 0) ? "" : ", ") + inv.name} </Link>;
                    if (inv.uri && inv.type) {
                        leadInvestor.push(structuredLink)
                    } else {
                        leadInvestor.push(<span>{inv.name(index == 0) ? "" : ", "}</span>)
                    }

                })
            }
            if (!Helpers.isEmpty(data.preMoneyValuation)) {
                preMoneyValuation = Helpers.displayAmount({amount: data.preMoneyValuation, currency: data.currency});
            } else {
                preMoneyValuation = "Undisclosed"
            }
            if (!Helpers.isEmpty(data.isEquityOnlyFunding)) {
                isEquityOnlyFunding = data.isEquityOnlyFunding ? 'Yes' : 'No';
            }
            array.rows.push({
                ...data,
                date: date,
                // name:name,
                fundingType: data && data.fundingType && data.fundingType.name ? data.fundingType.name : "",
                numOfInvestors: numOfInvestors,
                fundingStage: data && data.fundingStage && data.fundingStage.name ? data.fundingStage.name : '',
                preMoneyValuation: preMoneyValuation,
                amountRaised: amountRaised,
                isEquityOnlyFunding: isEquityOnlyFunding,
                leadInvestor: leadInvestor,
                expanded:false
            });
        })
        this.setState({
            tableData: array,
            tableSubData:{
                ...array,
                rows: array.rows.slice(0,4)
            }
        })
    }

    handleSort = (sort) => {
        if (sort.column == "date") {
            var sortedRows = [...this.state.tableData.rows];
            sortedRows = sortedRows.sort(Helpers.dateCompareFun);
            if (sort.direction == "desc") {
                sortedRows = sortedRows.reverse();
            }
            this.setState({
                tableData: {
                    ...this.state.tableData,
                    rows: sortedRows
                }
            })
        }
    }
    collapseChange = (activeKey) => {
        this.setState({
            activeKey,
        });
    }
    expandIcon = object => {
        return <i className="mr-2"> <img style={{width:"15px"}} src={config.cdn+"theme/images/"+(object.isActive?"faq-minus-icon.svg":"faq-plus-icon.svg")}></img></i>
    }

    handleFundingSeeMore = () => {
        let fundingSeeMore = this.state.fundingSeeMore;
        let tableData = this.state.tableData;
        if(fundingSeeMore){
            this.setState({
                tableSubData:{
                    ...tableData,
                    rows: [...tableData.rows.slice(0,4)]
                },
                fundingSeeMore: !fundingSeeMore
            });
        } else {
            this.setState({
                tableSubData:{...tableData},
                fundingSeeMore: !fundingSeeMore
            });
        }
    }

    handleTableDataExpand(row){
        row.expanded = !row.expanded;
        let rows = this.state.tableSubData.rows.map(x=>x.fundingRoundId == row.fundingRoundId ? row : x);
        this.setState({
            tableSubData:{
                rows
            }
        });
    }
    getFundingTable(){
        const context = this;
        function getRow(rowData) {
            function header() {
                return <div className="justify-content-between flex-row d-flex col-xs-12 funding-table-row panel panel-default">
                    <div id="faq" role="tablist" aria-multiselectable="true" className="col-md-12 p-0">
                        <div className="panel panel-default">
                            <div className="panel-heading" role="tab" id="questionOne">
                                <h5 data-toggle="collapse"
                                    data-parent="#faq"
                                    href={"#collapse"+rowData.fundingRoundId}
                                    aria-expanded="false"
                                    aria-controls="answerOne"
                                    onClick={()=>{context.handleTableDataExpand(rowData)}}
                                    className="col-xs-12 p-1 m-1 d-flex flex-row font-14">
                                        <div className="col-md-3" >{rowData.fundingType}</div>
                                        <div className="col-md-3">{rowData.date}</div>
                                        <div className="col-md-2">{rowData.numOfInvestors}</div>
                                        <div className="col-md-3">{rowData.amountRaised}</div>
                                        <div className="col-md-1"><i className={"m-l-5 cursor-pointer fa "+ (rowData.expanded?"fa-chevron-up":"fa-chevron-down")+" font-18"}></i></div>
                                </h5>
                            </div>
                            <div id={"collapse"+rowData.fundingRoundId}
                                 className="panel-collapse collapse"
                                 role="tabpanel"
                                 aria-labelledby="questionOne">
                                <div className="panel-body col-md-12 p-0">
                                    <table className="funding-sub-table">
                                        <tbody>
                                            <tr>
                                                <td><strong className="font-bold">Funding Stage:</strong> {rowData.fundingStage}</td>
                                                <td><strong className="font-bold">Pre Money Valuation: </strong> {rowData.preMoneyValuation}</td>
                                            </tr>
                                            <tr>
                                                <td><strong className="font-bold">Equity Only:</strong> {rowData.isEquityOnlyFunding}</td>
                                                <td><strong className="font-bold">Lead Investors:</strong>
                                                    {(rowData.leadInvestors || []).map((inv, index) => {
                                                        let link = "/" + inv.type + "/" + inv.uri;
                                                        let structuredLink = <Link to={link}> {((index == 0) ? "" : ", ") + inv.name} </Link>;
                                                        if (inv.uri && inv.type) {
                                                            return structuredLink
                                                        } else {
                                                            return <span>{inv.name(index == 0) ? "" : ", "}</span>
                                                        }

                                                    })}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                  {rowData.investors &&  <React.Fragment>
                                    <div className="panel-heading "><h5 className="m-l-20"><strong className="font-bold" colSpan="2">Investors:</strong></h5></div>
                                        <table className="funding-sub-table">
                                            <tbody>
                                                {rowData.investors.map((item, index) => <tr  key={item.uniqueId}>
                                                    <td><strong className="font-bold">Name:</strong>  <Link to={`/${item.type}/${item.uri}`}> {item.name} </Link></td>
                                                    <td><strong className="font-bold">Investor Type:</strong> {item.investorTypes ? item.investorTypes.map((type, index) => type.name + (item.investorTypes.length > index + 1 ? ", " : "")) : '-'}</td>
                                                </tr>)}

                                            </tbody>
                                        </table>
                                    </React.Fragment>
                               }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            return header();


        }
        let tableData = this.state.tableSubData;
        return <div className="d-flex funding-table flex-column" role="tablist" id="fundingCollapse">
                    <div className="justify-content-between flex-row d-flex col-xs-12 header">
                        <div className="col-md-3">Funding Type</div>
                        <div className="col-md-3">Announced Date</div>
                        <div className="col-md-2">No. of Investors</div>
                        <div className="col-md-3">Money Raised</div>
                        <div className="col-md-1"></div>
                    </div>
                    {
                        tableData && tableData.rows ? tableData.rows.map(x=>getRow(x)):""
                    }
                    <div className="justify-content-center flex-row d-flex col-xs-12">
                        <h5 className="text-blue m-2 cursor-pointer" onClick={this.handleFundingSeeMore}>See More <i className={"m-l-5 fa "+ (this.state.fundingSeeMore?"fa-chevron-up":"fa-chevron-down")+" font-18"}></i></h5>
                    </div>
                </div>
    }

    render() {
        let showContent = this.props.showContent;
        let pageViewsLeft = this.props.pageViewsLeft;
        let fundingRound = this.state.fundingRound;
        let tableData = this.state.tableData;

        let company = this.state.company;
        if (!company) {
            return (<div></div>)
        }

        return (
            <div>
                {tableData && !Helpers.isEmpty(tableData.rows) ?
                    <div className="card text-left bold-headers" id={this.props.id}>
                        {showContent && pageViewsLeft > 0 ?
                            <div>
                                <div className="card-header text-left p-0">
                                    <div className="col-md-6 float-left light-blue-header-half header-div">
                                        Funding
                                    </div>
                                    {tableData && !Helpers.isEmpty(tableData.rows) ?
                                        <div className="col-md-6 float-left text-right">
                                            <span className="font-12">{!Helpers.isEmpty(fundingRound) ? fundingRound.length : 0} Results</span>
                                        </div>
                                        :
                                        ""}
                                </div>

                                <div className="card-body blue-border clearfix">
                                    {tableData && !Helpers.isEmpty(tableData.rows) ?
                                        <div className="row">
                                            {
                                                (company.fundingStatus && company.fundingStatus.name) ?
                                                    <div className="col-md-6 float-left ">
                                                        <div className="col-md-12 float-left grey-border round-border p-2">
                                                            <div className="col-md-12 float-left topLabel font-12">Funding
                                                                                                                   Status
                                                            </div>
                                                            <div className="col-md-12 float-right font-12 text-right">{company.fundingStatus.name}</div>

                                                        </div>
                                                    </div>
                                                    : ""

                                            }
                                            {
                                                (company.lastFundingType && company.lastFundingType.name) ?
                                                    <div className="col-md-6 float-left m-0">
                                                        <div className="col-md-12 float-left grey-border round-border p-2">
                                                            <div className="col-md-12 float-left topLabel font-12">Last
                                                                                                                   Funding
                                                                                                                   Type
                                                            </div>
                                                            <div className="col-md-12 float-right font-12 text-right">{company.lastFundingType.name}</div>

                                                        </div>
                                                    </div>
                                                    : ""

                                            }

                                        </div>
                                        :
                                        ""
                                    }
                                    {tableData && !Helpers.isEmpty(tableData.rows) ?
                                        <div className="row m-t-10">
                                            {
                                                (this.state.total > 0) ?
                                                    <div className="col-md-12 float-left ">
                                                        <div className="col-md-12 float-left grey-border round-border p-2">
                                                            <div className="col-md-12 float-left topLabel font-12">Total
                                                                                                                   Funding
                                                            </div>
                                                            <div className="col-md-4 float-right font-12 text-right">{this.state.usedCurrency + " " + Helpers.displayAmount({amount: Math.round(this.state.total)})}</div>

                                                        </div>
                                                    </div>
                                                    : ""

                                            }
                                        </div>
                                        :
                                        ""
                                    }
                                    <div className="table-responsive">
                                        {tableData && !Helpers.isEmpty(tableData.rows) ?
                                            <div className="col-xs-12 mt-3">
                                                {this.getFundingTable()}
                                            </div>
                                            :
                                            <div className="table-responsive text-center m-t-10">
                                                No Data Available
                                            </div>

                                        }

                                    </div>
                                </div>
                            </div>
                            :
                            <NotUser title="Funding"
                                     showContent={this.props.showContent}
                                     pageViewsLeft={this.props.pageViewsLeft} />
                        }
                    </div>
                    :
                    ""}
            </div>
        )
    }
}


export default connect(null, {fundingRound})(withRouter(Funding));


