
import React, { Component } from 'react';
import config from '../../shared/config';
import Helper from '../../shared/custom';
import CardActions from './CardActions';
import Comments from './Comments';
import CommentShareCount from './CommentShareCount';
import CommentShareBtn from './CommentShareBtn';
import AuthorDetails from './AuthorDetails';
import ReadMoreAndLess from 'react-read-more-less';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


export default class AdFeedCard extends Component {

    constructor(props){
        super(props);
        this.state = {
            focusInput:false
        }
        this.focusInputFiled = this.focusInputFiled.bind(this);
    }

focusInputFiled(){
      this.setState({
        focusInput: !this.state.focusInput
      })
  }

  render() {
    let data = {
        "name": null,
        "summary": null,
        "images": null,
        "videos": null,
        "profilePic": null,
        "entityType": "AD",
        "time": new Date(),
        "profileImage": null,
        "feedUniqueId": "",
        "uniqueId": null,
        "uri": null,
        "title": null,
        "action": "AD",
        "author": {
            "name": "Investera+",
            "uri": "/features",
            "image": "theme/images/logo.png",
            "type": "Company"
        },
        "shareAuthor": null,
        "stats": {
            "comments": 0,
            "shares": 0,
            "interested": null
        },
       
    }
    let shared = this.props.shared;
    let mode = this.props.mode;
    return (
      <div className="feedCards">
     {  mode == "public" ? "" :  <div className="card">
                <div className="card-body p-b-0">
                    {
                        shared ? 
                            <div className="col-lg-12 p-0">
                                    <AuthorDetails data={data} type="shared" />
                                <div className="clearfix"></div>

                                <div className="col-lg-12 p-0 m-t-20">
                                <hr/>
                                        <AuthorDetails data={data}/>
                                </div>
                                <div className="clearfix"></div>
                    </div>
                    :
                            <div className="col-lg-12 p-0">
                                <Link to={"/features"}>
                                        <div className="inv-logo-cont float-left ">
                                            <img src={config.siteUrl+'favicon.ico'}  className="float-left inv-logo" /> 
                                        </div>
                                        <div className="m-l-10 m-t-0 float-left text-default">
                                            <div className="font-14 font-bold m-b-u-10 ">Investera+ Africa</div>
                                            <span className="font-10">{Helper.getDateInAgoFormat(data.time ? data.time :new Date())}</span>
                                        </div>
                                </Link>
                            </div>

                    }
                    <div className="clearfix"></div>
                        <div className="col-lg-12 p-0 m-t-10 ">
                        <h2 className="font-16 ad-para font-bold text-center font-20">Welcome to Investera+ Africa</h2>
                        </div>
                </div>
                <div className="card-body laptop p-0">
                        <div className="col-lg-12 p-0">
                            <p className="p-20 p-t-0 p-b-0">Investera Plus Africa provides a digital platform which serves as an International business resource centre that brings together businesses, government entities and investors for Africa.</p>
                            <div className="m-a text-center">
                                <img src={config.dataCdnStatic +'plus-africa-welcome.png?'}  className="full-width feed-img" />
                            </div>
                        </div>
                        
                </div>
                        
            </div>
           }


          <div className="card">
                    <div className="card-body">
                    {
                        shared ? 

                            <div className="col-lg-12 p-0">
                                    <AuthorDetails data={data} type="shared" />
                                <div className="clearfix"></div>

                                <div className="col-lg-12 p-0 m-t-20">
                                <hr/>
                                        <AuthorDetails data={data}/>
                                </div>
                                <div className="clearfix"></div>
                       </div>
                       :
                            <div className="col-lg-12 p-0">
                                <a href="https://investera.com/info" target="_blank">
                                        <div className="inv-logo-cont float-left ">
                                              <img src={config.siteUrl+'favicon.ico'}  className="float-left inv-logo" /> 
                                        </div>
                                        <div className="m-l-10 m-t-0 float-left text-default">
                                            <div className="font-14 font-bold m-b-u-10 ">{data && data.author && data.author.name ? data.author.name  :""}</div>
                                            <span className="font-10">{Helper.getDateInAgoFormat(data.time ? data.time :new Date())}</span>
                                            <h6 className="text-grey font-10 font-bold m-t-0 l-h-5">Promoted Post</h6>
                                        </div>
                                </a>
                            </div>

                    }
                        <div className="clearfix"></div>
                            <div className="col-lg-12 p-0 m-t-10 ">
                                <h2 className="font-16 ad-para font-normal">A holistic end-to-end platform providing all the tools needed for 
                                    effective Investment Management. Whether it’s managing fund 
                                    workflow transactions, building structures, generating reports or 
                                    managing documents, Investera is where it all comes together.</h2>
                            </div>
                    </div>
                    <div className="card-body laptop p-0">
                         <div className="col-lg-12 p-0">
                                <div className="m-a text-center">
                               
                                    <img src={config.cdn +'theme/images/investera-banner.png'}  className="full-width feed-img" />
                                </div>
                            </div>
                    </div>
                    <div className="card-body text-left p-t-0">
                         <div className="col-lg-12 p-0 m-t-10">
                                <h3 className="text-black font-bold font-16">{data.name}</h3>
                                <ReadMoreAndLess
                                          ref={this.readMore}
                                          className="read-more-content font-14 text-default"
                                          charLimit={config.summaryLimit}
                                          readMoreText="Read more"
                                          readLessText="Read less"
                                      >
                                      {data.summary?data.summary:""}
                                  </ReadMoreAndLess>
                        </div>
                        <div className="col-lg-12 m-a text-center m-t-5">
                              <a className="btn btn-info btn-card" 
                              href={"https://investera.com/info"}  target="_blank">View More</a>
                        </div>
                        {/* <CommentShareCount data={data} />
                        <CommentShareBtn data={data} showInterested={true} focusInputArea={this.focusInputFiled}/>
                        <Comments data={data} enableFocus={this.state.focusInput} /> */}
                    </div>
        </div>
      </div>
    )
  }
}

