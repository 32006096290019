import React, { Component } from 'react';
import Axios from 'axios';
import config from "../../shared/config";
import Helper from '../../shared/custom';
import { Link } from "react-router-dom";

class RelatedReports extends Component {
    constructor(props){
        super(props);
        this.state ={
            reports:[],
            entityUri:null,
            entity:null
        }
    }
    componentDidMount(){
        // var entityUri = this.props.entityUri;
        // this.fetchRelatedReports(entityUri)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // do things with nextProps.someProp and prevState.cachedSomeProp
       
      
        if(JSON.stringify(nextProps.entity) !== JSON.stringify(prevState.entity)){
            return { entity: nextProps.entity};
        }
        return null;
      }
      
      componentDidUpdate(prevProps, prevState) {
      
         if(JSON.stringify(prevProps.entity) !== JSON.stringify(this.state.entity)){
           this.setState({
            entity: prevProps.entity,
           },()=>{
            this.fetchRelatedReports(this.state.entity.uri)
           });
          
         }
       }

     addDefaultreportsImage(ev){
        ev.target.src = Helper.dataPath("default/reports-default-image.png");
     }


    fetchRelatedReports = (entityUri) =>{
        var obj = {
            entityUri:entityUri,
        }
        
        Axios.get(
            Helper.apiURL({
                uri:`page/:entityUri/reports?size=2&page=0`,
                pathVar:{entityUri }
            })
        )
        .then( (response) => {     
            var reports = [];
            var images = ["theme/images/report1.jpg", "theme/images/report2.jpg", "theme/images/report3.jpg", "theme/images/report4.jpg"]
            if (response.data) {
                response.data.forEach((obj, i) => {
                    if (obj.status == "PUBLISHED") {
                        reports.push({
                            name: obj.reportTitle,
                            image:obj.coverImage ?
                                Helper.viewFile(obj.coverImage.uniqueId):
                                config.cdn + images[i % images.length],
                            uri: "report/view/" + obj.reportUri,
                            date: obj.reportCreationDate,
                            reportSummary: Helper.truncate(obj.reportSummary.replace(/<[^>]*>?/gm, ''),150)
                        });
                    }
                });
            }
            this.setState({reports});       
            
        })
        .catch((err) => { 
            //Helper.pushTError("PROFILE_NOT_FOUND");
        });

    }
    render() {
        let reports = this.state.reports;
        let page = this.state.entity;
        let reportsFilterCriteria = {uri:page.uri,name:page.name,id:page.id};
        let linkToFilteredreports = "/report"+Helper.jsonToQueryString(reportsFilterCriteria);
        return (
           <>
           {!Helper.isEmpty(reports) && 
           <div id="relatedReports">
                <div className="card text-left b-header">
                    <div className="card-header text-left light-blue-header text-white">
                            Related Reports
                    </div>
                    <div className="card-body">
                        {!Helper.isEmpty(reports) ?
                            reports.map((data, i) => (
                                <div className="col-md-12 p-0 m-t-15" key={i}>
                                    <div className="card text-center">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-4 p-0">
                                                    <div className="pro-img">
                                                        <Link to={"/" + data.uri}>
                                                            <img src={data.image}
                                                                className="img-responsive report-img-border report-img-logo" />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-md-8 text-left">
                                                    <div className="col-md-12 p-0">
                                                        <Link to={"/" + data.uri}>
                                                            <h6 className="text-blue">{data.name}</h6>
                                                        </Link>
                                                    </div>
                                                    <div className="col-md-12 h-50 p-0">
                                                        <p className="font-12 m-b-0">{data.reportSummary}</p>
                                                    </div>
                                                    <div className="d-flex flex-row justify-content-between align-items-end p-0 font-12">
                                                                        <span>
                                                                            <i className="mdi mdi-calendar font-16 vm m-r-5"></i>
                                                                            {data.date}
                                                                        </span>
                                                        <Link class="blue-bg font-12 text-white btn btn-sm btn-info"
                                                            to={"/" + data.uri}>
                                                            View Report
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            ))
                            :
                            ""
                        }
                        <div className="m-t-10">                                
                                        <a href={linkToFilteredreports}>
                                            <span className="float-right text-blue">View all</span>
                                        </a>
                                </div>
                    </div>
                </div>
            </div>
    }
    </>
        )
    }
}
export default RelatedReports;