import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import store from '../../store';
import Helpers from '../../shared/custom';
import { fundingRound } from '../../actions/fundingRoundAction';
import NotUser from './NotUser';
import { MDBDataTable } from 'mdbreact';
import ListCard from '../wigets/ListCard';

const $ = require('jquery');
$.DataTable = require('datatables.net');

class Investors extends Component {


 constructor(props){
    super(props);
    this.state = {
        uri:undefined,
        fundRound:[],
        fundingRound :[],
        investorsList :[],
        investorsListNew:[],
        noOfInvestor:0,
        noOfLeadInvestor:0,
        total:0,
        loading:false,
        newData:{},
        fundingSeeMore:false
    }

    store.subscribe(() => { 

        var fundRound = store.getState();
        let noOfInvestors = 0 ;
        try {
            noOfInvestors = fundRound.fundRound.data.noOfInvestors;
        } catch (e){
            console.log(e);
        }
        fundRound = fundRound.fundRound.data.fundingRounds || null;



        let investors = [];
        let uniqueInvestors = {};
        let totalLeadInvestors = {};
        let fundingName = "";

        if(JSON.stringify(this.state.fundRound) != JSON.stringify(fundRound)){
            this.setState({
                fundRound:fundRound
            })
            if(!Helpers.isEmpty(fundRound)){
                fundRound.forEach((data,index)=>{
                    let allInvestors = data.investors
                    
                    let leadInvestors = [];
                    if(!Helpers.isEmpty(data.leadInvestors)){
                        data.leadInvestors.forEach((list,i)=>{
                            leadInvestors.push(list.uniqueId);
                        })
                    }else{
                        leadInvestors.push({});
                    }
        
                   
                    if(!Helpers.isEmpty(allInvestors)){
                        allInvestors.forEach((list,i)=>{
                            list.leadInvestor = false;
                            if(leadInvestors.indexOf(list.uniqueId) > -1 ){
                                list.leadInvestor = true;
                                totalLeadInvestors[list.uniqueId] = true;
                            }
                            list.fundingName = data.name;
                           
                            investors.push(list);
                            uniqueInvestors[list.uniqueId] = list;
            
                        })
                    }else{
                        //investors.push({});
                    }
              
                    
                })

                var totalOthers = 0;
                var totalLead = 0;
                investors.forEach((item)=>{
                    if(item.leadInvestor){
                        totalLead++;
                    }else{
                        totalOthers++;
                    }
                })

           
                    this.setState({
                        investorsList:investors,
                        investorsListNew:investors.slice(0,4),
                        noOfInvestor:noOfInvestors,
                        noOfLeadInvestor:totalLead,
                    })
               
            
               
              
              
               
            }else{
                this.setState({
                    investorsList:[],
                    noOfInvestor:0,
                    noOfLeadInvestor:0,
                })
            }
        }

    });

    this.renderInvestorRow = this.renderInvestorRow.bind(this);
    this.investorDiv = React.createRef();

 }

 componentWillUnmount(){
  
}

 componentWillReceiveProps(nextProps){
    
    let currentUri = this.state.uri;
    let newUri = nextProps.entityUri;


    var query = Helpers.getQueryParameter("div");
    if(query == "investor"){
      this.scrollToInvestor()
    }


    if(currentUri && currentUri == newUri){
         return false;         
    }

    if(!nextProps.showContent){
        return false;
    }

    let type = "company";
     this.setState({
        uri:newUri,
        fundingRound :[],
        total:0,
     },()=>{
        this.props.fundingRound(nextProps.entityUri,type, () => {
            // if($.fn.DataTable.isDataTable(this.refs.investorTable) == false){
            //     $(this.refs.investorTable).DataTable({searching: false, info: false, });
            // }
            this.renderInvestorRow()
        });
     });
    
 }



 renderInvestorRow =()=>{

        let dataset = this.state.investorsList;
        let array = {
            columns: [
                 {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                  },
                  {
                    label: 'Lead Investor',
                    field: 'leadInvestor',
                    sort: 'asc',
                  },
                  {
                    label: 'Investor Type',
                    field: 'investorTypesLabel',
                    sort: 'asc',
                  },
                  {
                    label: 'Funding Round',
                    field: 'fundingName',
                    sort: 'asc',
                  },
                  {
                    label: 'Partners',
                    field: 'partners',
                    sort: 'asc',
                  },
            ],
            rows: [

            ]
        };
        dataset.forEach((data,index)=>{
            var obj = {}
          
            if(data && data.uri){
              obj.investorName =  <Link to={"/"+data.type+"/"+data.uri}> {data.name} </Link>;
            }else{
                obj.investorName = data.name;
            }

            var investorTypesLabel = [];
            if(data && !Helpers.isEmpty(data.investorTypes)){
                data.investorTypes.forEach((item,i)=>{
                    investorTypesLabel.push(item.name)
                })
            }

          
            obj.leadInvestor = data && data.leadInvestor ? "Yes":"No";
            obj.investorTypesLabel = !Helpers.isEmpty(investorTypesLabel) ?  investorTypesLabel.join(",") : "-" // hard coded, change for page type
            obj.fundingName =  data && data.fundingName ? data.fundingName:""
            obj.partners = "";
            if(data && data.partners && data.partners.length > 0){
                obj.partners = data.partners.join(", ");
            }
            
            array.rows.push(obj);
        })
        this.setState({
              newData:array  
        })
        
 }

 handleFundingSeeMore = () => {
    let fundingSeeMore = this.state.fundingSeeMore;
    let investors = this.state.investorsList;
    if(fundingSeeMore){
        this.setState({
            investorsListNew: investors.slice(0,4),
            fundingSeeMore: !fundingSeeMore
        });
    } else {
        this.setState({
            investorsListNew:investors,
            fundingSeeMore: !fundingSeeMore
        });
    }
}

scrollToInvestor=()=>{
    if(this.investorDiv.current){
        this.investorDiv.current.scrollIntoView({
            behavior: "smooth",
            // block: "nearest",
        })
    }
  }

  render() {
    let showContent = this.props.showContent;
    let pageViewsLeft = this.props.pageViewsLeft;      
    let investors = this.state.investorsListNew;
    let data = this.state.newData;
    let type = this.props.type;
    let newData = this.state.newData;
    let uri = this.props.entityUri;
    let mode = this.props.mode;

    
    return (
      <div>
       {!Helpers.isEmpty(investors) && this.state.noOfInvestor ?
        <div className="card text-left bold-headers" id={this.props.id} ref={this.investorDiv}>
                        { showContent && pageViewsLeft > 0 ?
                        <div>
                            <div className="card-header text-left  p-0">
                                <div className="light-blue-header-half float-left col-md-6 header-div">
                                    Investors
                                </div>
                                <div className="float-left col-md-6 text-right">
                                    <span className="font-12">Number of Investors  {this.state.noOfInvestor}</span><br/>
                                    <span className="font-12">Number of Lead Investors  {this.state.noOfLeadInvestor}</span>
                                </div>
                            </div>
                            <div className="card-body blue-border clearfix">
                                {!Helpers.isEmpty(investors) ?
                                   <div>
                                     
                                         { type != "card" ?
                                          <div className="table-responsive">
                                             <MDBDataTable
                                                className="muted-table"
                                                hover
                                                searching={false}
                                                data={data}
                                                 />
                                                 </div>
                                                 :
                                                 <div className="row">
                                                     { 
                                                       investors.map((row,i)=>(
                                                         <ListCard dataset={row} mode={{type:"investor"}} newData={newData.columns}/>
                                                     ))}
                                                 </div>

                                            }
                                     </div>
                                    :
                                    <div className="text-center m-t-10">
                                        No Data Available
                                    </div>
                                 
                                }

                                {mode == "funding" ? 
                                    <div className="justify-content-center flex-row d-flex col-xs-12">
                                        <h5 className="text-blue m-2 cursor-pointer" onClick={this.handleFundingSeeMore}>See More <i className={"m-l-5 fa "+ (this.state.fundingSeeMore?"fa-chevron-up":"fa-chevron-down")+" font-18"}></i></h5>
                                    </div> 
                                    :
                                    <div className="col-md-12 font-14 text-right float-right p-0"><Link to={`/company-funding-details/${uri}?div=investor`}>View All</Link></div>
                                }
                                  
                            </div>
                         </div>
                         :
                         <NotUser title="Investors" showContent={this.props.showContent} pageViewsLeft={this.props.pageViewsLeft} />
                        }
                          
                    </div>
                    :
                    ""}
                  
      </div>
    )
  }
}


const mapStateToProps = (state) =>({
    fundRound:state.fundRound
});

export default connect(mapStateToProps,{fundingRound})(withRouter(Investors));
