import React, { Component , useEffect, useState , useRef } from 'react';


import config from '../../shared/config';
import Helpers from '../../shared/custom';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import Axios from "axios";

import EditText from "../ui/EditText";
import EditDate from "../ui/EditDate";
import EditSelect from "../ui/EditSelect";
import EditTextArea from "../ui/EditTextArea";
import ApiHelper from "../../shared/ApiHelper";
import CommonSearch from "../wigets/CommonSearch";
import DeletePage from '../wigets/DeletePage';




function JobForm(props) {

    const [loading, setLoading] = useState(() => false);
    const [errors, setErrors] = useState(() => {});
    const [state, setState] = useState(() => {});
    const [owner, setOwner] = React.useState(false);
    const imgUploader = useRef(null);

    useEffect(() => {
        if(!Helpers.isEmpty(props.data)){
            var dataset = props.data;
            setOwner(dataset.owner)
            dataset["lastDate"] = props.data && props.data.submitLastDate ? new Date(props.data.submitLastDate) : new Date()
            setState(dataset);
        }
    },[props.data])

    const handleFieldValueChange = (e) => {
        e.preventDefault();
        let name = e.target.name;
        let value = e.target.value;
        if (name) {
            state[name] =value;
            setState((prevState) =>  {
                return {...state}
              });
        }
        e.preventDefault();
    }
    const handleTitleChange = (e)=>{
      state["jobPosition"] = e[0];
      setState((prevState) =>  {
          return {...state}
        });
      
    }

    const uploadDocs = (e)=>{
        let file = e.target.files ? e.target.files[0] : null;
       let {name} = e.target;
        setLoading(true)
        if (file) {
          ApiHelper.uploadFile({
            repoType: "OPP_DOCS",
            docTypeName: "OPP_JOB_ADVRT",
            fileType: "DOC",
            metaType:"OPPDOC",
            file: file,
            callback: result => {
              setState((prevState) => ({...prevState, ["advert"]: result }));
              setLoading(false)
            },
            errCallback:err=>{
               setLoading(false)
            }
          });
        }else{
            setLoading(false)
        }
    }

 
    const handleEndDateValueChange = (date) =>{
        if(date != state["lastDate"]){
        state["lastDate"] = date;
        setState((prevState) => {
          return {...state}
        });
    }
    }

    const uploadProfileImage = (e) =>{
        setLoading(true)
        let file = e.target.files ? e.target.files[0] : null;
        if (file) {
          ApiHelper.uploadFile({
            repoType: "COMPANY_IMAGES",
            fileType: "IMAGE",
            file: file,
            callback: result => {
                setLoading(false);
                setState((prevState) => ({...prevState, profilePic: result , profilePicUrl: Helpers.viewFile(result.uniqueId)}));
            }
          });
        }
      }

    const  handleSelectChange = (option, e) => {
        let thisFieldName = e.name;
        setState((prevState) => ({...prevState, [thisFieldName]: option })); 
     }

    const triggerProfileImageUpload = (e) =>{
        imgUploader.current.click();
    }

    const vaidateForm = () => {
        let isValid = true;
        let fields = [
            "companyName",
            "businessNature",
            "jobPosition",
            "noOfOpenings",
            "jobDescription",
            "qualifications",
            "lastDate",
            "advert"
        ]
        let errors = {}
    
        fields.forEach((field) => {
          if(field == "lastDate" && state[field]){
              state[field] = state[field] &&  (state[field]).toString(); 
          }
          if (Helpers.isEmpty(state[field])) {
            isValid = false;
            errors[field] = "This field is mandatory"
          } else {
            errors[field] = ""
          }
        })
    
        setErrors(errors);
        return isValid;
    }

    const saveAndProceed = ()=>{
        var formValid = vaidateForm();
        if(!formValid){
            Helpers.pushError("Please fill all the required fields.");
            return false;
        };
        setLoading(true)
        var payload = state;
        payload.advert = state.advert;
        payload.submitLastDate =  Helpers.getDateInInputFormat(state.lastDate);
        payload.jobPosition = state.jobPosition ? {
          "name":state.jobPosition.name,
          "id":state && state.jobPosition && state.jobPosition.value ? state.jobPosition.value : null,
        }:null

            

        Axios.put(Helpers.apiURL({ uri: "api/opportunity/" }), payload)
        .then(response => {
           setLoading(false)
            Helpers.pushSuccess("Opportunity Details has been updated!");
            let data = response.data;
            let id = data.id ? data.id : null;
            let paid = payload && payload.page.paid;
            let isPublished = payload && payload.page.regStatus && payload.page.regStatus.value == "PUBLISHED";
            if (id) {
              if(config.oppDirectPayment){
                window.location = paid ? `/opportunity/${payload.id}?isPublished=${isPublished}`:`/opportunity/proceed?targetEntityId=${payload.id}&paymentCategory=OPPORTUNITY`;
              }else{
                window.location = `/opportunity/${payload.id}?isPublished=${isPublished}`
              }
            }
        })
        .catch(err => {
          setLoading(false)
          Helpers.pushError(err.response.data.message)
        });

    }

    const deleteDoc = (e)=>{
        e.stopPropagation();
        var name = e.currentTarget.dataset && e.currentTarget.dataset.name;
        if(name){
          setState((prevState) => ({...prevState, [name]: {} }));
        }
      }

    

    return (
        <div className="row full-width bold-headers">
            <LoadingOverlay>
              <Loader fullPage loading={loading} />
            </LoadingOverlay>
            <div className="col-md-12 text-left m-t-20 m-b-20">
                <div className="col-md-12">
                    <h1>Jobs</h1>
                </div>
            </div>
            <div className="col-md-12">
                <div className="card">
                    <div className="card-body text-left">
                         <h5 className="m-t-0">Opportunity Details</h5>
                         <EditText
                                name="companyName"
                                title="Company Name"
                                value={state && state.companyName}
                                placeholder={""}
                                required={true}
                                onValueChange={handleFieldValueChange}
                                errorMsg={errors && errors["companyName"] ? errors["companyName"]  : ""}
                            />


                        <EditText
                                name="businessNature"
                                title="Nature of Business"
                                value={state &&  state.businessNature}
                                placeholder={""}
                                required={true}
                                onValueChange={handleFieldValueChange}
                                errorMsg={errors && errors["businessNature"] ? errors["businessNature"]  : ""}
                            />

                        
                        <div className="col-lg-12 p-0 float-left">
                               <h5>Position being Advertised <span className="text-danger font-bold">*</span></h5> 
                                <div className="col-md-12 p-0">
                                  <CommonSearch
                                        title=""
                                        allowNew={true}
                                        onChange={event => handleTitleChange(event)}
                                        name="titleObj"
                                        defaultValue={state && state.jobPosition ? state.jobPosition.name : ""}
                                        defaultSelected={state && !Helpers.isEmpty(state.jobPosition) ? [state.jobPosition] : []}
                                    />
                                    {errors && errors["jobPosition"] && <small class="text-danger errorMsgCustom">{errors["jobPosition"]}</small>}
                                </div>
                          </div>
{/* 
                            <EditText
                                name="jobPosition"
                                title="Position being Advertised"
                                value={state &&  state.jobPosition}
                                placeholder={""}
                                required={true}
                                onValueChange={handleFieldValueChange}
                                errorMsg={errors && errors["jobPosition"] ? errors["jobPosition"]  : ""}
                            /> */}

                            <EditText
                                name="noOfOpenings"
                                title="Number of Employees to be Hired"
                                value={state && state.noOfOpenings}
                                placeholder={""}
                                required={true}
                                onValueChange={handleFieldValueChange}
                                errorMsg={errors && errors["noOfOpenings"] ? errors["noOfOpenings"]  : ""}
                            />

                            <EditTextArea
                                    name="jobDescription"
                                    addLabelclassName="isMandatoryLabel"
                                    title="Job Description"
                                    value={state && state.jobDescription}
                                    onValueChange={handleFieldValueChange}
                                    required={true}
                                    rowclassName={'form-control textarea-sm'}
                                    errorMsg={errors &&  errors["jobDescription"] ? errors["jobDescription"]  : ""}
                                    
                                />

                            <EditTextArea
                                    name="qualifications"
                                    addLabelclassName="isMandatoryLabel"
                                    title="Qualifications and Criteria for Selection"
                                    value={state && state.qualifications}
                                    onValueChange={handleFieldValueChange}
                                    required={true}
                                    rowclassName={'form-control textarea-sm'}
                                    errorMsg={errors &&  errors["qualifications"] ? errors["qualifications"]  : ""}
                                    
                                />

                      

                            <EditDate
                                    name="lastDate"
                                    title="Last Date for Submission of Application"
                                    showTimeSelect={false}
                                    value={state &&  state.lastDate}
                                    placeholder={""}
                                    required={true}
                                    onValueChange={handleEndDateValueChange}
                                    errorMsg={errors && errors["lastDate"] ? errors["lastDate"]  : ""}
                                    />
                           
                            <div className="docUploadFields float-left col-md-12 p-0">
                                      <h5 className="font-14">Advert on Company Letterhead <i>(Signed & Stamped)</i><span style={{color:"red"}}>*</span></h5>
                                        <label className="custom-file-upload col-md-6">
                                            <input type="file" 
                                                    name="advert" 
                                                    value=""
                                                    className="docUploader" 
                                                    // ref={"uploader"} 
                                                    onChange={uploadDocs}
                                                    accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"/> 
                                        </label>
                                        {
                                          state && !Helpers.isEmpty(state.advert) && 
                                                        <div key={state.advert.uniqueId} id={state.advert.uniqueId}> 
                                                        <a href={Helpers.viewFile(state.advert.uniqueId)} target="_blank">{state.advert.name}</a>
                                                        <a key={state.advert.uniqueId+"trigger"} data-id={state.advert.uniqueId} data-mainId={state.advert.id}
                                                                onClick={deleteDoc} data-name="advert"
                                                                className="text-danger m-l-5"
                                                                >
                                                                <i className="fa fa-times" />
                                                                </a>
                                                    </div>
                                            }
                            </div>
                            
                    </div>
                </div>
                <div className="col-sm-12 col-xs-12 p-0 m-t-10">
                               {state && !Helpers.isEmpty(state.id) && owner && <DeletePage type="Opportunity" uri={state.id} />}
                                <button
                                    type="submit"
                                    className="btn btn-info waves-effect- waves-light- float-right"
                                    onClick={saveAndProceed}
                                >
                                  {config.oppDirectPayment ? "Save and Proceed to Payment" : "Save"} 
                                </button>
                        </div>
            </div>
        </div>
    )
}

export default JobForm
