import React, { Component } from 'react';
import Helpers from '../../shared/custom';
import Sidemenu from '../sidemenu/Sidemenu';
import Search from '../search/Search';
import Searchrightpanel from './Searchrightpanel'; 
import UserFeedRightPanel from '../homepage/UserFeedRightPanel';

export default class SearchPage extends Component {

  constructor(props){
    super(props);
  }

  render() {
    let props = this.props;
    let history = props.history;
   
    return (
      <div>
          <div>
              <div className="row content-in m-t-80 p-t-10">
                <div className="col-lg-3 col-xlg-3 col-md-3 searchMenu sideMenuCont">
                   <Sidemenu mode="search"/>
                </div>
                <div className="col-lg-6 col-xlg-6 col-md-6 col-sm-12 centerDiv">
                    <Search />
                </div>
                <div className="col-lg-3 col-xlg-3 col-md-3 col-sm-12 m-t-2">
                  <UserFeedRightPanel />
                </div>
            </div>
          </div>        
      </div>
    )
  }
}
