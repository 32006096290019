import React, { Component } from 'react';
import SimilarNews from '../wigets/SimilarNews';
import ExpireDate from "../wigets/ExpireDate";

export default class NewsRightPanel extends Component {
    constructor(props){
        super(props);
    }

  render() {
    return (
      <div>
          {/* <ExpireDate/> */}
          <SimilarNews uri={this.props.uri} />
      </div>
    
    )
  }
}
