import React, { useState, useEffect } from 'react';
  
  
// const GoogleAuth = ({ label: label, ...rest }) => (
//     <div className="btn btn-info btn-lg btn-block btn-normal font-14 waves-effect waves-light text-white no-hover btn-google" title=""> 
//         <i aria-hidden="true" className="fab fa-google font-14"></i> &nbsp; | &nbsp;{label} 
//     </div>
// )

// export default GoogleAuth;


function GoogleAuth(props) {
    // const [count, setCount] = useState(0);
  
    // // Similar to componentDidMount and componentDidUpdate:
    // useEffect(() => {
    //   // Update the document title using the browser API
    //   document.title = `You clicked ${count} times`;
    // });

    return (
      <React.Fragment>
        {props.mode =="topbar" ? 
          //  <div className="empty-buttons smallAuto">
          //     <a className="btn btn-info btn-lg btn-block btn-normal top-login-btn font-14 waves-effect waves-light text-white no-hover btn-google" title="" href={props.url}> 
          //       <i aria-hidden="true" className="fab fa-google font-14"></i> &nbsp; | &nbsp;{props.label} 
          //     </a>
          //   </div>
          <a href={props.url} className="p-0 no-hover">
                <button type="button" className="btn btn-google text-white">
                <i aria-hidden="true" className="fab fa-google font-14"></i> &nbsp; | &nbsp;{props.label}
                </button> 
          </a>
            :
          <a href={props.url}>
                <button type="button" className="btn btn-google text-white">
                 <i aria-hidden="true" className="fab fa-google font-14"></i> &nbsp; | &nbsp;{props.label}
                </button> 
          </a>
        
        }
        
      </React.Fragment>
    );
  }

  export default GoogleAuth;
