import React, { Component } from 'react';
import config from '../../shared/config';
import EditableFields from './EditableFields';
import Videos from './Videos'
import Upload from './Upload';
import Helpers from '../../shared/custom';
import Axios from 'axios';

class EventContent extends Component {

constructor(props){
    super(props);
    this.state={
        editBanner:false,
        coverPic:null,
        eventId:null,
    }

    this.editbanner = this.editbanner.bind(this);
    this.editBasicDetails = this.editBasicDetails.bind(this);
    this.uploadCoverImg = this.uploadCoverImg.bind(this);
    this.showSummary = this.showSummary.bind(this);
    this.uploadNewImg = this.uploadNewImg.bind(this);
}

uploadCoverImg(e) {
    this.refs.coverImg.click();
}

componentDidMount(){
    let uri = this.props.uri;

    Axios.get(config.api("event/"+uri+"/details"))
    .then( (response) => {
    })
    .catch((err) => {
       
    });
}

editbanner(){
    this.setState({
        editbanner: this.state.editbanner? false : true
       })
}

showSummary(){
    this.setState({
        showSummary: this.state.showSummary? false : true
       })
}

editBasicDetails(){
    this.setState({
        editBasicDetails: this.state.editBasicDetails? false : true
      })
}


uploadNewImg(e){
    const file   = this.refs.coverImg.files[0];
    const  fileType = file['type'];
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
   
    if (!validImageTypes.includes(fileType)) {
        Helpers.pushError("Please select an image to upload");
        return false;
    }
   
    const reader  = new FileReader();
    reader.onloadend = () => {
        this.setState({
            coverPic: reader.result
        })
    }
    if (file) {
        reader.readAsDataURL(file);
        this.setState({
            coverPic :reader.result
        })
    } 
   
}

  render() {
    
    return (
      <div>
        <div className="row bold-headers text-left">
            <div className="col-md-12 col-lg-12 col-xlg-12 p-0">
            {Helpers.isEmpty(this.state.coverPic)
            ? "" :  
            <div className="coverPic text-center">
                    <img src={this.state.coverPic} className="img-responsive"/>
                    <div className="changeCoverPic">
                        <a href="javascript:void(0)" className="text-white" onClick={this.uploadCoverImg}><i className="fa fa-camera font-40"></i><div className="font-10 font-bold">Change cover photo</div></a>
                    </div>
            </div>

            }
           
            <div className="card">
                    <div className="card-body banner-small">
                        <div className="col-lg-12 p-0 banner-small">
                            <div className="col-lg-6 p-0 float-left">
                                { this.state.editbanner ?
                                    <div className="m-t-0">
                                     <a href="javascript:void(0)" onClick={this.uploadImg}>
                                        <img src={this.state.coverPic} className="float-left newImg" width="100" />
                                        <input type="file" id="userImg" name="userImg"  style={{display: "none"}} ref="imgUploader" onChange={this.uploadNewImg}/>
                                    </a>
                                    <div className="card-body-right float-left m-l-20 text-left verticle-h-banner">
                                        <h2 className="card-title m-t-5 m-b-5 ">
                                            <input type="input" name="title" className="form-control" value="Dubai Creek"/>
                                        </h2>
                                    </div>
                                    </div>
                                    :

                                 <div className="m-t-0">
                                        <h2>Middle East Fintech Summit</h2>
                                        <h6 className="font-normal"><i class="material-icons">calendar_today</i>Wednesday, 23 January 2019 at 10:00 UTC+02</h6>
                                        <h6 className="font-normal">Private Event - Created by <span className="text-info">John Smith</span></h6>
                                        <h6 className="font-normal">Beirut, Lebanon</h6>
                                   
                                  </div>

                                }
                               
                            </div>
                            <div className="col-lg-3 float-right p-0 verticle-h-banner text-right">
                                <div className="m-t-10">
                                        <div> 
                                            { this.state.editbanner ? 
                                                    <a onClick={this.editbanner}  className="text-danger"><i className="fa fa-times"></i></a>
                                                    :
                                                    <a onClick={this.editbanner}  className="text-info">Edit</a>
                                            }
                                        </div>
                                </div>
                            </div>
                        </div>
                        <hr/>

                        <div className="text-info">
                            <a href="javascript:void(0)" onClick={this.uploadCoverImg}>
                                <i className={"fas fa-plus"}></i> Upload cover photo
                                <input type="file" id="coverImg" name="coverImg" ref="coverImg" style={{display: "none"}} onChange={this.uploadNewImg}/>
                            </a>
{/* 
                            <div className="col-lg-12 float-left p-0  m-t-10 b-t">
                                <div className="button-box m-t-10  float-left p-0">
                                    <a  className="btn btn-block btn-outline-info" href="#">Interested</a>
                                </div>
                            </div> */}
                        </div>                        
                    </div>
                </div>

                {/* card end */}
                <div className="card" id="section1">
                    <div className="card-body banner-small">
                       <div className="col-lg-12 p-0">
                            <div className="col-lg-6 p-0 float-left">
                                <h4>Basic Details</h4>
                            </div>
                            <div className="col-lg-3 float-right p-0  text-right">
                                <div className="m-t-10">
                                        <div> 
                                            { this.state.editBasicDetails ? 
                                                    <a onClick={this.editBasicDetails}  className="text-danger"><i className="fa fa-times"></i></a>
                                                    :
                                                    <a onClick={this.editBasicDetails}  className="text-info">Edit</a>
                                            }
                                              
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 p-0">
                            <EditableFields value="Abudhabi" name="address" fieldType ="address" heading="Address" editBasicDetails={this.state.editBasicDetails} />
                            <EditableFields value="+971-57878678" name="phone" fieldType ="" heading="Phone Number(Optional)" editBasicDetails={this.state.editBasicDetails} />
                            <EditableFields value="Enter Email Address" name="email" fieldType ="" heading="Email" editBasicDetails={this.state.editBasicDetails} />
                            <EditableFields value="Enter Website Address" name="website" fieldType ="web" heading="Website (Optional)" editBasicDetails={this.state.editBasicDetails} />
                        </div>
                     </div>
                  </div>      


                  <div className="card" id="section2">
                    <div className="card-body">
                       <div className="col-lg-12 p-0">
                       <h4>Summary</h4>
                        <div className="text-info">
                        {this.state.showSummary ?
                            <div>
                                <a onClick={this.showSummary} className="text-danger float-right"><i className="fa fa-times"></i></a>
                                <br/>
                                <textarea className="form-control textarea-lg" row="5"></textarea>
                            </div>

                            :
                            <a href="javascript:void(0)" onClick={this.showSummary}>
                                 <i className={"fas fa-plus"}></i> Enter a detailed description of your Investment Opportunity. This field is Mandatory.

                            </a>
                        }
                               
                         </div>   
                         
                       </div>
                    </div>
                  </div>   


                  <div className="card" id="section3">
                    <div className="card-body">
                         <Upload value={this.state.photos} name="image" fieldType ="image" heading="Photos" showText="Upload all relevant photos here" icon="plus"/>
                    </div>
                 </div>      



                <div className="card" id="section4">
                    <div className="card-body">
                         <Videos value={this.state.videos} name="video" fieldType ="video" heading="Videos" showText="Upload all relevant videos here" icon="plus"/>
                    </div>
                </div>

                <div className="col-sm-12 col-xs-12 p-0">
                    <button className="btn btn-danger waves-effect- waves-light- m-r-10 ">Delete Event</button>
                    <button type="submit" className="btn btn-info waves-effect- waves-light- m-r-10 float-right">Submit for Moderation</button>
                </div>

            </div>
        </div>
      </div>
    )
  }
}

export default EventContent;