import React, { Component } from 'react'

class Videos extends Component {
   constructor(props){
       super(props);
       this.state ={
           showTextOption:false,
           cloneTextbox:false
       }
       this.handleClick =this.handleClick.bind(this);
   } 

   handleClick(){
        this.setState({
            showTextOption: this.state.showTextOption?false:true,
            cloneTextbox:true
        })
   }

  render() {
    let heading = this.props.heading;
    let showText = this.props.showText;
    let icon = this.props.icon;
    let values = this.props.value;
    let name = this.props.name;

    return (
      <div>
        <div className="col-lg-12 p-0 float-left">
                <h5>{heading}</h5>
                <div className="uploadTag">
                     <a href="javascript:void(0)" onClick={this.handleClick}>
                        <i className={"fas fa-"+icon}></i> {showText}
                     </a>
                     
                </div>
                <div className="result m-t-20">
                    <div className="row">
                    <div className="col-lg-12 col-md-6 m-b-20">
                            {this.state.showTextOption ? 
                                <div>
                                    <a onClick={this.handleClick} className="text-danger float-right"><i className="fa fa-times"></i></a>
                                    <br/>

                                    <input className="form-control" name={name} placeholder="Paste a Youtube, Vimeo, or other video link, and press Enter"/>
                                </div>
                                :
                                <div>
                                   
                                    <div className="col-md-3 p-0">
                                    
                                        <embed 
                                        src="https://www.youtube.com/embed/tgbNymZ7vqY" />
                                    </div>
                                </div>

                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        
      </div>
    )
  }
}



export default  Videos;