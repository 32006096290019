
import React, { Component } from 'react';
import Axios from 'axios';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import EditText from "../ui/EditText";
import EditSelect from "../ui/EditSelect";
import EditTextArea from "../ui/EditTextArea";
import EditMultiSelect from "../ui/EditMultiSelect";
import Mobile from "../ui/Mobile";
import ApiHelper from "../../shared/ApiHelper";
import countries from "../../shared/country";
import areas from '../../shared/areas';
import Helpers from '../../shared/custom';
import ManageEducationHistory from '../people/ManageEducationHistory';
import ManageCareerHistory from '../people/ManageCareerHistory';
import ObjectsSearch from "../wigets/ObjectsSearch";
import store from '../../store';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import moment from 'moment';
import Ps from "../wigets/Ps";

import { getMetaData } from '../../actions/metaAction';
export class Card extends Component {
    constructor(props){
        super(props);
        this.state ={
            loading:false,
            localProps:{},
            uri:null,
            errors:{},
            title:null,
            firstName:null,
            lastName:null,
            nationalId:null,
            dob:Helpers.getDateInDisplayFormat(new Date()),
            mobileCode:"+254",
            mobile:null,
            email:null,
            confirmEmail:null,
            country:null,
            regions:null,
            summary:null,
            professionalBody:null,
            qualification:null,
            regNo:null,
            psl:null,
            facebook:null,
            twitter:null,
            linkedIn:null,
            careerHistory:[],
            educationHistory:[],

            titlePrefix:[],
            pbOption:[],
            degreesOptions:{},
            profilePic:null,
            otherProfessionalBodies:null

            

        }
        store.subscribe(() => {
            var storeState = store.getState();
            var localMetaList = this.state.metaList;
            var storeMetaList = storeState.meta.metaList; 
            
            if(!localMetaList || JSON.stringify(localMetaList) != JSON.stringify(storeMetaList)){
                this.setState({metaList:storeMetaList});
            }
          });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(JSON.stringify(nextProps) != JSON.stringify(prevState.localProps)){
          let localMetaList = JSON.parse(localStorage.getItem("metaList"));
          if(!Helpers.isEmpty(localMetaList)){
            prevState.metaList = localMetaList;
           // prevState.degreesOptions = Helpers.makeSelectOptions(localMetaList.DEG);
           // prevState.pbOption = Helpers.makeSelectOptions(localMetaList.PRB);
            return {prevState}
          }
        }
     }

     mobileValues = (data)=>{
        this.setState({
            mobileCode:data.ext,
            mobile:data.mobile,
        })
    }

    vaidateForm = ()=>{
        let isValid = true;
        let fields = [
                      "title",
                      "firstName",
                      "lastName",
                      "nationalId",
                      "dob",
                      "mobileCode",
                      "mobile",
                      "email",
                      "confirmEmail",
                      "country",
                      "regions",
                      "summary",
                      "professionalBody",
                    ];
            
           if(this.state.professionalBody && this.state.professionalBody.label == "Other"){
              fields.push("otherProfessionalBodies")
           }else{
            fields  = Helpers.removeItemFromArray(fields,"otherProfessionalBodies")
           }
    
            let errors = {}

            
    
            fields.forEach((field)=>{
              if(Helpers.isEmpty(this.state[field])){
                  isValid = false;
                  errors[field] = "This field is mandatory"
              }else{
                 errors[field] = ""
              }
            })

          
    
           this.setState({
                errors:errors
           })

        
           return isValid;
      }

      handleDateChange = (date, e) =>{
        this.setState({ dob: date });
      }
    
   
    componentDidMount(){
        let countryOptions = Helpers.makeSelectOptions(countries);
        let areasServedOptions =  Helpers.makeSelectOptions(areas);
        this.setState({
            countryOptions:countryOptions,
            areasServedOptions:areasServedOptions
        });
        this.fetchEnums();
        this.fetchMeta();
        this.fetchDegree()
        let localMetaList = JSON.parse(localStorage.getItem("metaList"));
        if(Helpers.isEmpty(localMetaList)){
            this.props.getMetaData();
        }

       
    }

    fetchDegree= ()=>{
        Axios.get(
          Helpers.apiURL({
              uri:"meta/list/DEG",
            })
        )
        .then( (response) => {
            let degrees = response.data.result || {};
            this.setState({
                degreesOptions:Helpers.makeSelectOptions(degrees)
            })
            
        })
        .catch((err) => {
           
        });
      }

    fetchMeta= ()=>{
        Axios.get(
          Helpers.apiURL({
              uri:"meta/list/PRB",
            })
        )
        .then( (response) => {
            let pbOption = response.data.result || {};
            this.setState({
                pbOption:Helpers.makeSelectOptions(pbOption)
            })
            
        })
        .catch((err) => {
           
        });
      }

    componentDidUpdate(prevProps, prevState) {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.state.localProps)) {
            this.setState({localProps:prevProps})
            let userProfile = localStorage && !Helpers.isEmpty(localStorage.userInfo) ? JSON.parse(localStorage.userInfo)  : null;
            let pageDetailsUri = null;

            if(userProfile && userProfile.pageDetails && userProfile.pageDetails.pageDto && userProfile.pageDetails.pageDto.uri){
                pageDetailsUri = userProfile.pageDetails.pageDto.uri
            }
            
            let uri = Helpers.getQueryParameter("uri");

            if(!Helpers.isEmpty(uri)){
                this.setState({uri:uri})
                this.fetchIndividual(uri)
            }else if(!Helpers.isEmpty(pageDetailsUri)){
                this.setState({uri:pageDetailsUri})
                this.fetchIndividual(pageDetailsUri)
            }else{
                this.fetchProfile()
            }
        }
   }


    fetchProfile = ()=>{
        let profile  = localStorage && !Helpers.isEmpty(localStorage.profile) ?  JSON.parse(localStorage.profile) : {};

        if(!Helpers.isEmpty(profile)){
            let uri = profile.user.uri;
            let userId = profile.id;
            Axios.get(
                Helpers.apiURL({
                  uri: "user/:uri/profile",
                  pathVar: { uri: uri }
                })
              )
                .then(response => {
                  let result = response.data.result || null;
                  if (!Helpers.isEmpty(result)) {
                      localStorage.setItem("profile",JSON.stringify(result)); 
                      this.setState({
                        //uri:result.user.uri,
                        //firstName:result.displayName,
                        country:Helpers.makeSelectOption(result.country),
                        summary:result.summary,
                        careerHistory:result.careerDtos,
                        educationHistory:result.educationDtos,
                        profilePic:result.profilePic,
                      })
                  } 
                })
                .catch(err => {
                  Helpers.pushTError("PROFILE_NOT_FOUND");
            });
    
        
    
            Axios.get(
                Helpers.apiURL({
                  uri: "user/user-info",
                  query: { userId: userId }
                })
              )
                .then(response => {
                  let result = response.data|| null;
                
                  if (!Helpers.isEmpty(result)) {
                      this.setState({
                        firstName:Helpers.isEmpty(result.lastname) ? result.firstname.substr(0,result.firstname.indexOf(' ')) : result.firstname,
                        lastName:Helpers.isEmpty(result.lastname) ? result.firstname.substr(result.firstname.indexOf(' ')+1) : result.lastname,
                        email:result.email,
                        confirmEmail:result.email,
                        dob:result.dob,
                        mobileCode:result.mobileCode,
                        mobile:result.mobile,
                        profilePic:result.profilePic,
                        regNo:result.regNo
                      })
                  } 
                })
                .catch(err => {
                  Helpers.pushTError("PROFILE_NOT_FOUND");
            });
        }
       
    }

    fetchIndividual =(uri)=>{
        this.setState({loading:true});
        Axios.get(
            Helpers.apiURL({
                uri:`people/:uri/details`,
                pathVar:{uri}
            })
        )
        .then( (response) => {     
           let result = response.data.result;
           
           this.setState({
                loading:false,
                title:result.title,
                firstName:result.firstName,
                lastName:result.lastName,
                nationalId:result.nationalId,
                dob:result.dob,
                mobileCode:result.mobileCode,
                mobile:result.mobile,
                email:result.email,
                confirmEmail:result.email,
                country:Helpers.makeSelectOption(result.country),
                regions:result.regions.map((list)=>{
                    return {
                        label:list.name,
                        value:list.id
                    }
                }),
                summary:result.summary,
                professionalBody:Helpers.makeSelectOption(result.professionalBody[0]),
                otherProfessionalBodies:!Helpers.isEmpty(result.otherProfessionalBodies) ?  result.otherProfessionalBodies : null,
                qualification:result.profQualifications,
                regNo:result.regMembershipNo,
                psl:!Helpers.isEmpty(result.psl) ? result.psl.map((list)=>{
                    return {
                        name:list.name,
                        value:list.id
                    }
                }):[],
                facebook:result.facebook,
                twitter:result.twitter,
                linkedIn:result.linkedIn,
                documents:result.documents,
                careerHistory:result.careerHistory,
                educationHistory:result.educationHistory,
                profilePic:result.profilePic,
           })

        })
        .catch((err) => { 
            this.setState({loading:false});
        });
    }

    handleFieldValueChange = (e) =>{
      
        let name = e.target.name;
        let value = e.target.value;
        if (name) {
          this.setState({ [name]: value });
        }
      }


    handleSubmit = ()=>{
        let isValid = this.vaidateForm();
        if(!isValid){
          Helpers.pushError("Please fill all required fields");
          return false;
        }

        this.setState({loading:true});

        let {
                title,
                firstName,
                lastName,
                nationalId,
                dob,
                mobileCode,
                mobile,
                email,
                confirmEmail,
                country,
                regions,
                summary,
                professionalBody,
                qualification,
                regNo,
                psl,
                facebook,
                twitter,
                linkedIn,
                documents,
                profilePic,
                careerHistory,
                educationHistory,
                otherProfessionalBodies
                 } = this.state;

        let payload = {
            title:title,
            firstName:firstName,
            lastName:lastName,
            country:{"id":country.value},
            regions:!Helpers.isEmpty(regions) ? regions.map((item)=>{
                return{
                    id:item.value,
                    name:item.label
                }
            }) : [],
            nationalId:nationalId,
            dob:dob,
            mobileCode:mobileCode,
            mobile:mobile,
            email:email,
            confirmEmail:confirmEmail,
            summary:summary,
            professionalBody:!Helpers.isEmpty(professionalBody) ? [{"id": professionalBody.value}] :null,
            profQualifications:qualification,
            regMembershipNo:regNo,
            facebook:facebook,
            twitter:twitter,
            linkedIn:linkedIn,
           
            // careerHistory:careerHistory,
            // educationHistory:educationHistory,
        };



        if(professionalBody.label.toLowerCase() == "other"){
            payload.otherProfessionalBodies = otherProfessionalBodies;
        }

        if(!Helpers.isEmpty(psl)){
            var pslVal = psl.map((item)=>{
                 if(item.customOption){
                    return{
                        id:null,
                        name:item.name
                    }
                 }else{
                    return{
                        id:item.value,
                        name:item.name
                    }
                 }
                
            })
            payload.psl = pslVal;
         }

    
            let dateOfBirth =  moment(new Date(dob)).format("DD/MM/YYYY");
           
            if(!Helpers.isEmpty(dateOfBirth) && Helpers.getAge(dateOfBirth) < 18){

                Helpers.pushError("you must be atleast 18 years old to proceed further");
                this.setState({loading:false});
                return false;
            }

            if(!Helpers.isEmpty(facebook) && !Helpers.isValidFb(facebook)){
                Helpers.pushError("Please enter a valid facebook url");
                this.setState({loading:false});
                return false;
            }

            if(!Helpers.isEmpty(twitter) && !Helpers.isValidTwitter(twitter)){
                Helpers.pushError("Please enter a valid twitter url");
                this.setState({loading:false});
                return false;
            }

            if(!Helpers.isEmpty(linkedIn) && !Helpers.isValidLinkedin(linkedIn)){
                Helpers.pushError("Please enter a valid linkedIn url");
                this.setState({loading:false});
                return false;
            }

            if(!Helpers.isEmpty(profilePic)){
                payload.profilePic = {
                    uniqueId:profilePic.uniqueId,
                    publicUri:profilePic.uri,
                    name:profilePic.name
                };
            }
     

        let uri = this.state.uri;

        if(!Helpers.isEmpty(uri)){
            payload.pageDto= {"uri":uri}
          
            payload.documents =  !Helpers.isEmpty(documents) ? documents.map((x)=>{
                return {
                    "uniqueId":x.uniqueId, 
                    "docType":{"id":x && x.docType && x.docType.id ? x.docType.id :null}}
            }):[];
    

            
          
            Axios.put(
                Helpers.apiURL({
                    uri:`people/:uri/edit`,
                    pathVar:{uri}
                }),
                payload
            ).then((response) => {
                this.setState({loading:false});
                let result = response.data.result;
                Helpers.pushSuccess("Profile details saved successfully.")
                if(result){
                    window.location.replace("/people-details/upload?uri="+result.pageDto.uri);
                }
            }).catch((err) => {
                //error
                this.setState({loading:false});
                Helpers.pushError(err.response.data.message)
            })

        }else{
            Axios.post(
                Helpers.apiURL({
                    uri:"api/individual/add",
                }),
                payload
            ).then((response) => {
                this.setState({loading:false});
                let result = response.data.result;
                Helpers.pushSuccess("Details are saved successfully!")
                if(result){
                    window.location.replace("/people-details/upload?uri="+result.pageDto.uri);
                }
            }).catch((err) => {
                //error
                this.setState({loading:false});
                Helpers.pushError(err.response.data.message)
            })
        }

    }

    handleObjectChange = (e)=>{
        let psl = e.filter((tag, index, array) => array.findIndex(t => t.name == tag.name) == index);
        this.setState({psl: psl});
    }

    fetchCareer = (data)=>{
        let ids = [];
        if(!Helpers.isEmpty(data)){
            data.forEach((item,i)=>{
                ids.push(item.id)
            })
        }
        this.setState({
            careerHistory:ids ? ids :[]
        });

       // console.log(ids,"fetchCareer")
        return ids ? ids :[];
    }

    fetchEducation =(data)=>{
        let ids = [];
        if(!Helpers.isEmpty(data)){
            data.forEach((item,i)=>{
                ids.push(item.id)
            })
        }
        this.setState({
            educationHistory:ids ? ids :[]
        });
        //console.log(ids,"fetchEducation")
        return ids ? ids :[];
    }

    handleSelectChange=(value,element)=>{
        let name = element.name;
        this.setState({[name]:value})
    }

    fetchEnums = ()=>{
       // api/enum/lis
       Axios.get(
        Helpers.apiURL({
            uri:`api/enum/list`,
        })
        )
        .then( (response) => {     
            let enums  = response.data.result || null;   
            this.setState({
                titlePrefix:Helpers.makeSelectOptionsFromEnum(enums.TitlePrefix)
            })
        })
        .catch((err) => { 
            //Helpers.pushTError("PROFILE_NOT_FOUND");
        });
    }
    
    render() {
        let { loading ,errors , titlePrefix , pbOption , careerHistory, professionalBody } = this.state;

        
        return (
            <div className="container-fluid"> 
                 <LoadingOverlay>
                    <Loader fullPage loading={loading}/>
                </LoadingOverlay>
                <div className="row content-in m-t-80 p-t-10">
                 <div className="row full-width bold-headers">
                 <div className="col-md-12 text-left m-t-20 m-b-20"> 
                    <div className="col-md-12">
                        <h1 className="font-22">Premium Plus <i className="font-16">(for Professionals)</i></h1>
                    </div>     
                </div>
            <div className="col-md-12"> 
                        <div className="card">
                            <div className="card-body text-left">
                            <h5 className="m-t-0">Personal Information</h5>
                            <EditSelect
                                name="title"
                                title="Title"
                                required={true}
                                value={this.state.title}
                                options={titlePrefix}
                                onChange={this.handleSelectChange}
                                additionalClassName="ediableInline preFilled"
                                errorMsg={errors["title"]}
                            />
                            <EditText
                                name="firstName"
                                title="First Name"
                                value={this.state.firstName}
                                placeholder={"First Name"}
                                required={true}
                                onValueChange={this.handleFieldValueChange}
                                errorMsg={errors["firstName"]}
                            />
                            <EditText
                                name="lastName"
                                title="Last Name"
                                value={this.state.lastName}
                                placeholder={"Last Name"}
                                required={true}
                                onValueChange={this.handleFieldValueChange}
                                errorMsg={errors["lastName"]}
                            />
                            <EditText
                                name="nationalId"
                                title="National Identification/Passport Number"
                                value={this.state.nationalId}
                                placeholder={"National Identification/Passport Number"}
                                required={true}
                                onValueChange={this.handleFieldValueChange}
                                errorMsg={errors["nationalId"]}
                            />
                            {/* <EditText
                                name="dob"
                                title="Date of Birth"
                                value={this.state.dob}
                                placeholder={"Date of Birth : yyy-MM-dd"}
                                required={true}
                                onValueChange={this.handleFieldValueChange}
                                errorMsg={errors["dob"]}
                            /> */}

                            <EditText
                                            required={true}
                                            value={this.state.dob}
                                            name="dob"
                                            title="Date Of Birth"
                                            inputType="date"
                                            onValueChange={this.handleFieldValueChange}
                                            errorMsg={errors["dob"]}
                                          />
                            {/* <div>
                                <div className="col-lg-12 p-0 float-left">
                                <h5>Date of Birth</h5>
                                <div className="col-md-12 p-0">
                                    <div className="ediableInline">
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        className="form-control"
                                        selected={this.state.dob}
                                        onChange={this.handleDateChange}
                                        placeholderText="Choose as of date"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                    </div>
                                </div>
                                </div>
                            </div> */}
                            <Mobile 
                                sendValue={this.mobileValues}
                                extVal={this.state.mobileCode}
                                mobileVal={this.state.mobile}
                                required={true}
                                mobileName={"mobile"}
                                extName={"mobileCode"}
                                title="Mobile Number"
                                error={errors}
                                /> 
                            <EditText
                                    name="email"
                                    title="Email Address"
                                    value={this.state.email}
                                    placeholder={"Email"}
                                    required={true}
                                    onValueChange={this.handleFieldValueChange}
                                    errorMsg={errors["email"]}
                                    />
                            <EditText
                                    name="confirmEmail"
                                    title="Confirm Email Address"
                                    value={this.state.confirmEmail}
                                    placeholder={"Confirm Email Address"}
                                    required={true}
                                    onValueChange={this.handleFieldValueChange}
                                    errorMsg={errors["confirmEmail"]}
                                    />
                                <EditSelect
                                        title="Country"
                                        headingclassName="isMandatoryLabel"
                                        name="country"
                                        value={this.state.country}
                                        options={this.state.countryOptions}
                                        onChange={this.handleSelectChange}
                                        additionalClassName="ediableInline "
                                        errorMsg={errors["country"]}
                                        required={true}
                                        />

                            <EditMultiSelect
                                title="Region"
                                name="regions"
                                required={true}
                                value={this.state.regions}
                                options={this.state.areasServedOptions}
                                onChange={this.handleSelectChange}
                                additionalClassName="ediableInline preFilled"
                                errorMsg={errors["regions"]}
                                />

                            <EditTextArea
                                name="summary"
                                addLabelclassName="isMandatoryLabel col-md-6"
                                title="Provide a Summary About Yourself"
                                value={this.state.summary}
                                onValueChange={this.handleFieldValueChange}
                                required={true}
                                rowclassName={'form-control textarea-sm'}
                                errorMsg={errors["summary"]}
                            />


                            <EditSelect
                                    title="Professional Body"
                                    allow
                                    headingclassName="isMandatoryLabel"
                                    name="professionalBody"
                                    value={this.state.professionalBody}
                                    options={this.state.pbOption}
                                    onChange={this.handleSelectChange}
                                    additionalClassName="ediableInline "
                                    errorMsg={errors["professionalBody"]}
                                    required={true}
                                    />


                            {professionalBody && professionalBody.label && professionalBody.label.toLowerCase() == "other" &&

                                    <EditText
                                        name="otherProfessionalBodies"
                                        title=""
                                        value={this.state.otherProfessionalBodies}
                                        placeholder={"Enter Professional Body"}
                                        onValueChange={this.handleFieldValueChange}
                                        errorMsg={errors["otherProfessionalBodies"]}
                                        />
                                
                            }

                           
                               

                            <EditTextArea
                                name="qualification"
                                addLabelclassName="isMandatoryLabel"
                                title="Profession &amp; Qualifications"
                                value={this.state.qualification}
                                onValueChange={this.handleFieldValueChange}
                                rowclassName={'form-control textarea-sm'}
                                errorMsg={errors["qualification"]}
                            />
                            <EditText
                                    name="regNo"
                                    title="Registration/Membership No."
                                    value={this.state.regNo}
                                    placeholder={"Registration/Membership No."}
                                    onValueChange={this.handleFieldValueChange}
                                    errorMsg={errors["regNo"]}
                                    />
                             {/* <ObjectsSearch
                                                allowNew={true}
                                                multiple={true}
                                                title="Services Offered"
                                                endPoint="api/people-psl/search"
                                                headingClass=""
                                                className="serviceOffered ediableInline"
                                                onChange={event => this.handleObjectChange(event)}
                                                name="psl"
                                                defaultValue={this.state.psl?this.state.psl.name:null}
                                                defaultSelected={!Helpers.isEmpty(this.state.psl) ? [this.state.psl] : []}
                                            /> */}

                            <div>
                                <div className="col-lg-12 p-0 float-left">
                                <h5>Services Offered</h5>
                                <div className="col-md-12 p-0">
                                    <div className="ediableInline">
                                        <Ps 
                                        onChange={event => this.handleObjectChange(event)}
                                        defaultValue={this.state.psl?this.state.psl.name:null}
                                        defaultSelected={!Helpers.isEmpty(this.state.psl) ? this.state.psl : []}
                                        />
                                    </div>
                                </div>
                                </div>
                            </div>

                                
                            </div>
                        </div>
                        <ManageCareerHistory 
                                fetchCareer={this.fetchCareer} 
                                careerHistory={this.state.careerHistory}/>
                        <ManageEducationHistory 
                                    degreesOptions={this.state.degreesOptions} 
                                    fetchEducation={this.fetchEducation}
                                    educationHistory={this.state.educationHistory}/>
                        <div className="card">
                            <div className="card-body text-left">
                               <h5 className="m-t-0">Social Media</h5>
                               <EditText
                                name="facebook"
                                title="Facebook"
                                value={this.state.facebook}
                                placeholder={""}
                                onValueChange={this.handleFieldValueChange}
                                errorMsg={errors["facebook"]}
                            />
                              <EditText
                                name="twitter"
                                title="Twitter"
                                value={this.state.twitter}
                                placeholder={""}
                                onValueChange={this.handleFieldValueChange}
                                errorMsg={errors["twitter"]}
                            />
                              <EditText
                                name="linkedIn"
                                title="LinkedIn"
                                value={this.state.linkedIn}
                                placeholder={""}
                                onValueChange={this.handleFieldValueChange}
                                errorMsg={errors["linkedIn"]}
                            />
                            </div>
                        </div>
                        <div className="col-sm-12 col-xs-12 p-0">
                            <button
                                type="submit"
                                className="btn btn-info waves-effect- waves-light- float-right"
                                onClick={this.handleSubmit}
                            >
                                Save and Continue
                            </button>
                        </div>
                  </div>
                 </div>
                </div>
            </div>
        )
    }
}


export default  connect(null,{ getMetaData})(withRouter(Card));