import React, { Component } from 'react'
import Helpers from '../../shared/custom';
import { TwitterTimelineEmbed } from 'react-twitter-embed';

export default class TwitterFeed extends Component {

    constructor(props){
        super(props);
    }
    render() {

        let twitter = undefined;
        if(!Helpers.isEmpty(this.props.url)){
            twitter = Helpers.lastSegment(this.props.url);
        }else{
            twitter = null
        }

        
        return (
            <div>
                {twitter?
                    <div className="card">
                        <div className="card-body">
                            <TwitterTimelineEmbed
                                sourceType="profile"
                                screenName={twitter}
                                options={{height: 600}}
                                key={twitter}
                            />
                        </div>
                    </div>
                :
                ""
                }
            </div>
        )
    }
}
