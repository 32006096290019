import React from 'react';
import config from '../../../shared/config';
import Helpers from '../../../shared/custom';

function Payment({url,fee,currency}) {
    return (
        <div  className="card">
            <div className="card-body">
                <div className="col-md-12 dflex-align">
                    <h5>
                        <img src={config.dataCdnStatic +'payment_card.png'} className="thumbnail-img" alt="proceed to pay"/>
                        <span className="m-l-10 font-bold">PROCEED TO PAYMENT: <span className="font-14">{currency+' '+Helpers.prettyNumber(fee)}</span></span>
                        
                    </h5>
                    <a className="btn btn-theme-white" href={url}>Pay</a>
                </div>
            </div>
        </div>
    )
}

export default Payment
