import React, { Component } from 'react';
import { withRouter,Redirect } from 'react-router-dom';
import { getMetaData } from '../../actions/metaAction';
import { getSectorData } from '../../actions/businessAction';
import { connect } from 'react-redux';
import Helper from "../../shared/custom";
import store from '../../store';

class SectorFilter extends Component {
    constructor(props){
        super(props);
        this.state={
            criteriaUpdate:undefined,
            business:null,
            metaList:[],
            type:"",
            localProps:[],
            sectorId:null,
        }

        store.subscribe(() => {
            var storeState = store.getState();
            var auth = storeState.auth;
            var localAuth = this.state.auth;
      
            var localMetaList = this.state.metaList;
            var storeMetaList = storeState.meta.metaList; 
            
            if(!localMetaList || JSON.stringify(localMetaList) != JSON.stringify(storeMetaList)){
                this.setState({metaList:storeMetaList},function(){
               
                });
                
            }
      
            var localBusiness = this.state.business;
            var storeBusiness = storeState.business.data;
            if(!localBusiness || JSON.stringify(localBusiness) != JSON.stringify(storeBusiness)){
                this.setState({business:storeBusiness});
            }
      
            this.setState({
              isLoggedIn:auth.isAuthenticated
            },()=>{
              
            })
          }); 
    }

    componentDidMount(){
        this.setState({
            type:this.props.type
        });

        var storeState = store.getState();
    
        let meta = storeState.meta;
    
        if(Helper.isEmpty(meta.metaList) == true){
          this.props.getMetaData();
        }else{
          this.setState({metaList:meta.metaList});
        }
    
        let business = storeState.business;
        
        if(Helper.isEmpty(business.data) == true){            
            this.props.getSectorData();
        }else{
            this.setState({business:business.data});
        }
    
        var auth = storeState.auth;
        var localAuth = this.state.auth;
        this.setState({
          isLoggedIn:auth.isAuthenticated
        },()=>{
          
        })
        let sectorId =  Helper.lastSegment(this.props.location.pathname);
       
        this.setState({
          sectorId:sectorId
        })

     
     
      }


    componentWillReceiveProps(props){
      
      this.setState({
          // criteriaUpdate:props.criteriaUpdate,
          // business:props.business,
          // metaList:props.metaList,
          type:props.type
      });
    }
      
    openEventsSectorPage(e){
        let type = this.state.type
        this.props.history.push('/'+type+'/sector/'+e.target.name);
        var sectorId = e.target.name;
        let sectorLink = document.getElementsByClassName("sectorLink");
        let activeLink = document.getElementsByClassName("activeSector");
        let navLink = document.getElementsByClassName("nav-link active");
    

        if (activeLink.length > 0) {
          activeLink[0].classList.remove("activeSector")
        }
        
        if (navLink.length > 0) {
          navLink[0].classList.remove("active")
         
        }
            
        e.target.classList.add("activeSector");
    }
      
  render() {
      let sectorsList = null;
      if (this.state.business){
        sectorsList = this.state.business.sectorsArray;
      }
      let sectorId = this.state.sectorId;



    return (
     <div>
          <div className="card text-left bold-headers">
               <div class="card-header text-left light-blue-header"><h5 class="m-b-0 m-t-0 text-white">Filter By Sector</h5></div>  
                <div className="card-body p-2 font-14">
                    <ul className="list-group no-list-style sectorMenu">
                        {
                          (Helper.isEmpty(sectorsList) == false)?
                            sectorsList.map((sector, index) => (
                              <li key={index} className="m-t-5"><i className="mdi mdi-target font-20"></i><a href="#" name={sector.id} className={sectorId == sector.id ? "text-info m-l-5 font-medium sectorLink activeSector" : "text-info m-l-5 font-medium sectorLink"} onClick={this.openEventsSectorPage.bind(this)}>{sector.name}</a></li>
                            ))
                          :
                          ''
                      }
                    </ul>
                </div>
            </div>
      </div>
    )
  }
}

export default  connect(null,{ getMetaData, getSectorData})(withRouter(SectorFilter));
