import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link ,withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import store from '../../store';
import { getPackageDetails } from '../../actions/packageAction';
import Helpers from '../../shared/custom';
import {login, fetchUserInfo , removeError} from '../../actions/authAction';
import Axios from 'axios';
import config from '../../shared/config';
import ExpireDate from "../wigets/ExpireDate";
import Slider from "react-slick";
import Entity from "../ui/Entity";
import { SET_CURRENT_USER_PROFILE }  from '../../actions/types';



const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };


class Defaultmenu extends Component {

  constructor(props){
    super(props);
    this.state = {
        privileges:{},
        localProps:{},
        user:{},
        userLoaded:false,
        companies:null,
        activePage: 1,
        totalRecords: 0,
        itemsCountPerPage:config.defaultPageSize,
        viewProfile:false,
        viewBtn:false,
        userDetails:{},
        pageDetails:{},
        regStatus:null
    }
    store.subscribe(() => {
        var storeState = store.getState();
        let storeAuth = storeState.auth.isAuthenticated;
        this.setState({auth:storeAuth})
    })
  }

  componentDidMount(){
    let currentPath = this.props.location;
    let construtedPath = currentPath.pathname+currentPath.search;

    let menuList = document.querySelectorAll('.nav-link');
    menuList.forEach((elm)=>{
        if(elm.getAttribute("href") == construtedPath){
            elm.classList.add('active');
        }else{
            elm.classList.remove('active');
        }
    });

    this.fetchCompanies();
  }


  fetchCompanies = () => {
    this.setState({
      loading:true,
    })

    let qs = config.paginationQS({"page":this.state.activePage, "length":this.state.itemsCountPerPage});
    qs.countryIds = this.state.countryIds;

    Axios.get(
        Helpers.apiURL({
            uri:"companies/my-companies/summary",
           query:qs
        })
    )
    .then( (response) => {           
      let companies = response.data.result.aaData || [];
      let totalRecords = response.data.result.iTotalRecords || 0;
        this.setState({
            companies:companies,
            totalRecords:totalRecords,
             loading:false,
            });
       })
    .catch((err) => {
        this.setState({
          loading:false,
        })
    });
}

  getUserDetails(nextProps){
    var storeState = nextProps;   
    let storeUri = storeState.auth.user.uri;
    let userDetails = storeState.auth.user;

    // if(!Helpers.isEmpty(userDetails.pageDetails)){
    //     this.setState({
    //         viewBtn:true,
    //         viewProfile:pageDetails && pageDetails.pageDto && pageDetails.pageDto.view ? pageDetails.pageDto.view : false
    //     })
    // }

    this.setState({
        storeUser:storeState.auth && storeState.auth? storeState.auth.user.fullName :"",
        storeUri:storeUri,
        userDetails:userDetails
    },()=>{

        let userLoaded = this.state.userLoaded;
        let profileDetails = localStorage && localStorage.profile ? JSON.parse(localStorage.profile) : {};
        if(!Helpers.isEmpty(this.state.storeUri) &&  Helpers.isEmpty(profileDetails)){
            Axios.get(
                Helpers.apiURL({
                    uri:"user/:uri/profile",
                    pathVar:{uri:storeUri}
                })
            )
            .then( (response) => {           
                let profileDetails  = response.data.result || null;                
                this.setState({
                    user:profileDetails,
                    userLoaded:true
                });

                localStorage.setItem("profile",JSON.stringify(profileDetails)); 

                store.dispatch({
                    type:SET_CURRENT_USER_PROFILE,
                    payload:profileDetails
                });

            })
            .catch((err) => { 
                //Helpers.pushTError("PROFILE_NOT_FOUND");
            });
        }else{
            this.setState({
                user:profileDetails,
            })
        }
    })
   
}

  componentWillReceiveProps(nextProps){
    let store = nextProps;
    let storeState = nextProps;
    let packageDetails = nextProps.packageDetails;
    let privileges = packageDetails.privileges;

    if(JSON.stringify(nextProps) != JSON.stringify(this.state.localProps)){
        let userDetails = nextProps.auth.user;
        if(!Helpers.isEmpty(userDetails.pageDetails)){
            var pageDetails = userDetails.pageDetails;
            this.setState({
                pageDetails:pageDetails,
                viewBtn:true,
                viewProfile:pageDetails && pageDetails.pageDto && pageDetails.pageDto.regStatus && pageDetails.pageDto.regStatus.value == "PUBLISHED" ? true : false,
                regStatus:pageDetails && pageDetails.pageDto && pageDetails.pageDto.regStatus && pageDetails.pageDto.regStatus.value ? pageDetails.pageDto.regStatus.value  : null
            })
        }

        if(!Helpers.isEmpty(privileges)){
            this.setState({
                privileges:privileges,
                localProps:nextProps
            })
        }

    }
    let profileDetails = localStorage && localStorage.profile ? JSON.parse(localStorage.profile) : {};

   
    if(Helpers.isEmpty(profileDetails)){
        this.getUserDetails(nextProps)
    }else{
        this.setState({
            user:profileDetails,
        })
    }
  

    // if(storeState.auth.isAuthenticated){
    //     let profileDetails = storeState && storeState.auth && storeState.auth.userProfile ? storeState.auth.userProfile : JSON.parse(localStorage.profile);
    //     this.setState({user:profileDetails})
    // }
  }




  render() {
    let privileges = this.state.privileges;
    let user = this.state.user;
    let {regStatus} = this.state;
   
    let profilePicUrl = !Helpers.isEmpty(user.profilePic)
      ? Helpers.isEmpty(regStatus) ? Helpers.dataPath(user.profilePic.publicUri) 
      : !Helpers.isEmpty(regStatus) && regStatus == "PUBLISHED" ? Helpers.dataPath(user.profilePic.publicUri) : Helpers.dataPath(user.profilePic.uri)
      : Helpers.dataPath(config.defaultUserImage);
      let userUri = user && user.user && user.user.uri ? user.user.uri : "";
      
    let {companies,viewBtn,viewProfile,pageDetails} = this.state;

   
    return (
      this.state.auth?
        <div>
            <div className="card">
                <div className="card-body">
                     {viewBtn ?
                           <React.Fragment>
                               {viewProfile ?   
                                <Link  to={"/people/"+pageDetails.pageDto.uri}><img src={profilePicUrl} className="img-circle" width="50"  height="50"/></Link> :
                                <Link  to={"/people-details/create?uri="+pageDetails.pageDto.uri}><img src={profilePicUrl} className="img-circle" width="50" height="50"/></Link>}
                                <h4 className="card-title m-t-10">{user.displayName}</h4>
                                <h6 className="text-side-i card-subtitle">{user && user.title ? user.title.name : ""}  {user && user.company && user.company.name ?"at "+user.company.name : ""}</h6>
                                <p className={pageDetails.pageDto.verificationStatus}>{pageDetails.pageDto.verificationStatus}</p>
                             </React.Fragment>
                            :
                            <React.Fragment>
                                <img src={profilePicUrl} className="img-circle" width="50" height="50" />
                                <h4 className="card-title m-t-10">{user.displayName}</h4>
                                <h6 className="text-side-i card-subtitle">{user && user.title ? user.title.name : ""}  {user && user.company && user.company.name ?"at "+user.company.name : ""}</h6>
                           </React.Fragment>
                          }
                        
                        
                        <hr className="hrBlack"/>

                        <div className="row">
                            {/* <div className="col-12">
                                <span className="float-left font-12 text-default">Followers:</span>
                                <span className="float-right font-10 text-default">{user.followers}</span>
                            </div>
                            <div className="col-12">
                                <span className="float-left font-12 text-default">Following:</span>
                                <span className="float-right font-10 text-default">{user.following}</span>
                            </div> */}
                             <div className="col-12">
                                <Link to={"/my-opportunities"} className="link-color">
                                    <span className="float-left  font-12">My Opportunities:</span>
                                    <span className="float-right font-10">{user.myOpportunitiesCount}</span>
                                </Link>
                            </div>
                            <div className="col-12">
                                <Link to={"/opportunities/interested"} className="link-color">
                                    <span className="float-left font-12">Interested Opportunities:</span>
                                    <span className="float-right font-10">{user.intrestedOpportunityCount}</span>
                                </Link>
                            </div>

                            <div className="col-12 menu m-t-20">
                                {
                                    !Helpers.isEmpty(companies) &&
                                    <Slider {...settings}>
                                        {companies.map((x,i)=>(<Entity dataset={x} index={i}/>))}
                                    </Slider>
                                }
                                {/* <span className="float-left font-12 text-default">Profile View:</span>
                                <span className="float-right font-10 text-default">{user.profileViewCount}</span> */}
                            </div>

                           
                            
                            {/* <div className="col-12">
                                <Link to={"/user/"+userUri+"?section=companies"} className="link-color">
                                    <span className="float-left  font-12">Companies You Follow:</span>
                                    <span className="float-right font-10">{user.intrestedCompanyCount}</span>
                                </Link>
                            </div>
                            <div className="col-12">
                                <Link to={"/user/"+userUri+"?section=opportunities"} className="link-color">
                                    <span className="float-left font-12">Interested Opportunities:</span>
                                    <span className="float-right font-10">{user.intrestedOpportunityCount}</span>
                                </Link>
                            </div>
                            <div className="col-12">
                                <Link to={"/user/"+userUri+"?section=events"} className="link-color">
                                    <span className="float-left font-12">Interested Events:</span>
                                    <span className="float-right font-10">{user.intrestedEventCount}</span>
                                </Link>
                            </div>
                            <div className="col-12">
                                <Link to={"/bookmarks"} className="link-color">
                                    <span className="float-left font-12">Bookmarks:</span>
                                    <span className="float-right font-10">{user.userBookmark}</span>
                                </Link>
                            </div>
                            <div className="col-12">
                                <Link to={"/companies/my-companies"} className="link-color">
                                    <span className="float-left font-12">My Companies</span>
                                    {/* <span className="float-right font-10">{user.userBookmark}</span> */}
                                {/* </Link>
                            </div> */} 
                            

                        </div>
                
                </div>
                <ExpireDate/>
            </div>
            
        </div>
        :""
    )
  }
}

const mapStateToProps = (state) =>({
    packageDetails:state.packageDetails,
    auth:state.auth,
    errors:state.errors
});

export default connect(mapStateToProps,{getPackageDetails,fetchUserInfo,login})(withRouter(Defaultmenu));
