import React, { Component } from 'react';
import Helper from '../../shared/custom';
import store from '../../store';

class CommentShareCount extends Component {

  constructor(props){
    super(props);
    this.state = {
      shares:0,
      comments:0
    }
  }

  componentDidMount(){
    let data = !Helper.isEmpty(this.props.data) ? this.props.data : [];     
    
    if(!Helper.isEmpty(data) && !Helper.isEmpty(data.stats.shares)){
       this.setState({shares :data.stats.shares })
    }
    if(!Helper.isEmpty(data) && !Helper.isEmpty(data.stats.comments)){
      this.setState({comments :data.stats.comments })
    }
  }

  componentWillReceiveProps(props){
    if(!Helper.isEmpty(props.updatedId)){
        this.setState({
          shares : this.state.shares + 1
        })
    }
    
  }
  
  render() {
    let shares = this.state.shares;
    let comments = this.state.comments;
  
    return (
      <div>
      { Helper.isEmpty(shares) == true && Helper.isEmpty(comments) == true?
       ""
       :
       shares >0 || comments >0 ?
        <div>
          <hr/>
          <div className="col-lg-12 m-a text-right">
            { !Helper.isEmpty(shares) && shares > 0 ?  <a href="javascript:void(0)" className="p-10 font-12 text-muted">{shares} Shares</a> : ""}
            { !Helper.isEmpty(comments) && comments > 0 ?   <a href="javascript:void(0)" className="p-10 font-12 text-muted">{comments} Comments</a> : ""}
          </div>
        </div>
        :
        ""
      }
        
      </div>
    )
  }
}

export default  CommentShareCount;