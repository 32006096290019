import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import config from '../../shared/config';
import Helper from "../../shared/custom";
import { Link } from "react-router-dom";
import MiniCard from "../ui/MiniCard";
function Entity({dataset,index}) {

    const [modal, setModal] = useState(false);


   


    let data = dataset.pageDto;
    let statusData = dataset.verificationRequest;

    let image = config.defaultUserImage;
    let uri = undefined;

    if(data){
        image = !Helper.isEmpty(data.image) ? data.image : config.defaultUserImage;
        uri = data.uri ? data.uri : undefined;
    }

    image = Helper.dataPath(image);

     const addDefaultNewsImage = (ev)=>{
        ev.target.src = Helper.dataPath("default/company.png");
     }

     const openVerificationDetails = (e)=>{
        e.preventDefault();
        setModal(!modal)
     }
    
    return (
        <div className="col-md-12 p-1 float-left">

        <Modal isOpen={modal} toggle={openVerificationDetails}>
            <ModalHeader toggle={openVerificationDetails}>Verification Details</ModalHeader>
            <ModalBody>
                <div className="card-body text-left verifyDiv">
                            {!Helper.isEmpty(statusData && statusData.approvalDate) && 
                                <MiniCard label="Verification Date"
                                    type="left"
                                    view="full"
                                    value={
                                        Helper.getDateInInputFormat(new Date(statusData.approvalDate))
                                    }
                                />
                            }
                            {!Helper.isEmpty(statusData && statusData.reportLinks) &&
                                statusData.reportLinks.map((links,i)=>(
                                    <MiniCard label="Verification Report"
                                    type="link"
                                    show={true}
                                    view="full"
                                        value={
                                            links
                                        }
                                    />
                                ))
                            }
                            {!Helper.isEmpty(statusData && statusData.accManagerName) &&
                                <MiniCard label="Account Manager"
                                    show={true}
                                    type="canHide"
                                    view="full"
                                    value={
                                        statusData.accManagerName
                                    }
                                />
                            }
                            {!Helper.isEmpty(statusData && statusData.comments) &&
                                <MiniCard label="Comments"
                                    show={true}
                                    type="canHide"
                                    view="full"
                                    value={
                                        statusData.comments
                                    }
                                />
                            }
                            
                </div>
            </ModalBody>
       </Modal>
        <div className="col-md-12 mini-round grey-border round-border p-1" key={"person-"+index}>
                    {uri ? 
                        <Link to={!Helper.isEmpty(statusData) && statusData.approvalStatus == "APPROVED" ? "/company/"+uri :  "/company-details/"+uri}>
                            <center> 
                                <div className="col-12 float-left d-flex justify-content-center m-t-10" style={{height:"45px"}} >
                                    <img onError={addDefaultNewsImage} src={image} className="img-circle float-left img-entity"  />
                                </div>
                                <div className="d-flex flex-column justify-content-center text-left col-12 p-t-10 m-t-10">
                                    <h4 className="card-title text-center text-info m-b-0 m-t-0 font-14 m-h-50">{data.name}</h4>
                                    {
                                        !Helper.isEmpty(statusData) && 
                                         <div className={statusData.approvalStatus == "APPROVED" ? "text-success font-14 text-center" : "font-14 text-center"}>
                                             {statusData.approvalStatus}
                                             <a onClick={openVerificationDetails} className="m-l-5"><i className="fa fa-eye"></i></a>
                                         </div>
                                    }
                                    
                                    {/* <h5 className="m-t-0 font-10 text-center text-default p-1 font-12 font-normal"><i class="fa fa-suitcase m-r-8" aria-hidden="true"></i>{people.banner}</h5> */}
                                </div>
                            </center>
                        </Link>
                    :
                    <center> 
                        <div className="col-4 float-left p-0  m-t-10" style={{height:"45px"}} >
                            <img onError={addDefaultNewsImage} src={image} className="img-circle float-left img-entity"  />
                        </div>
                        <div className="d-flex flex-column col-12 p-t-10 m-t-10">
                            <h4 className="text-info text-center text-blue m-b-0 m-t-0 font-14 m-h-50">{data.name}</h4>
                            {/* <h5 className="m-t-0 text-default text-center p-1 font-12 font-normal"><i class="fa fa-suitcase m-r-8" aria-hidden="true"></i>{people.banner}</h5> */}
                        </div>
                    </center>
                    }
                </div>
          </div>
    )
}

export default Entity
