import React, { Component , useEffect, useState , useRef } from 'react';


import config from '../../shared/config';
import Helpers from '../../shared/custom';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import Axios from "axios";

import EditText from "../ui/EditText";
import EditDate from "../ui/EditDate";
import EditSelect from "../ui/EditSelect";
import EditTextArea from "../ui/EditTextArea";
import ApiHelper from "../../shared/ApiHelper";
import DeletePage from '../wigets/DeletePage';


function TenderForm(props) {

    const [loading, setLoading] = useState(() => false);
    const [errors, setErrors] = useState(() => {});
    const [state, setState] = useState(() => {});
    const [owner, setOwner] = React.useState(false);

    useEffect(() => {
        if(!Helpers.isEmpty(props.data)){
            setState(props.data);
            setOwner(props.data.owner)
        }
    },[props.data])

    const uploadDocs = (e)=>{
        let file = e.target.files ? e.target.files[0] : null;
       let {name} = e.target;
        setLoading(true)
        if (file) {
          ApiHelper.uploadFile({
            repoType: "OPP_DOCS",
            docTypeName: "OPP_JOB_ADVRT",
            fileType: "DOC",
            metaType:"OPPDOC",
            file: file,
            callback: result => {
              setState((prevState) => ({...prevState, [name]: result }));
              setLoading(false)
            },
            errCallback:err=>{
               setLoading(false)
            }
          });
        }else{
            setLoading(false)
        }
    }

   const handleFieldValueChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name) {
            setState((prevState) => ({...prevState, [name]: value }));
        }
    }

   
    const handleEndDateValueChange = (date) =>{
        setState((prevState) => ({...prevState, submissionDate: date}));
    }

    const vaidateForm = () => {
        let isValid = true;
        let fields = [
          "procuringEntity",
          "tenderNumber",
          "tenderName",
          "tenderSecurityAmount",
          "tenderDocument"
        ]
    
        let errors = {}
    
        fields.forEach((field) => {
          if (Helpers.isEmpty(state[field])) {
            isValid = false;
            errors[field] = "This field is mandatory"
          } else {
            errors[field] = ""
          }
        })
    
        setErrors(errors);
        return isValid;
    }
    
        const saveAndProceed = ()=>{
            var formValid = vaidateForm();
           
            if(!formValid){
                Helpers.pushError("Please fill all the required fields.");
                return false;
            };
            setLoading(true)
            var payload = state;
            payload.submissionDate = state.submissionDate != null ? Helpers.getDateAndTimeInputFormat(state.submissionDate) : Helpers.getDateAndTimeInputFormat(new Date());
          //  payload.tenderDocument = tenderDocument;
           
        
            Axios.put(Helpers.apiURL({ uri: "api/opportunity/" }), payload)
            .then(response => {
               setLoading(false)
                Helpers.pushSuccess("Opportunity Details has been updated!");
                let data = response.data;
                let id = data.id ? data.id : null;
                let paid = payload && payload.page.paid;
                let isPublished = payload && payload.page.regStatus && payload.page.regStatus.value == "PUBLISHED";
                if (id) {
                  if(config.oppDirectPayment){
                    window.location = paid ? `/opportunity/${payload.id}?isPublished=${isPublished}`:`/opportunity/proceed?targetEntityId=${payload.id}&paymentCategory=OPPORTUNITY`;
                  }else{
                    window.location = `/opportunity/${payload.id}?isPublished=${isPublished}`
                  }
                }
      
            })
            .catch(err => {
              setLoading(false)
              Helpers.pushError(err.response.data || err.response.data.message)
            });
    
        }

        const deleteDoc = (e)=>{
            e.stopPropagation();
            var name = e.currentTarget.dataset && e.currentTarget.dataset.name;
            if(name){
              setState((prevState) => ({...prevState, [name]: {} }));
            }
          }


    return (
        <div className="row full-width bold-headers">
            <LoadingOverlay>
              <Loader fullPage loading={loading} />
            </LoadingOverlay>
            <div className="col-md-12 text-left m-t-20 m-b-20">
                <div className="col-md-12">
                    <h1>Tender</h1>
                </div>
            </div>
            <div className="col-md-12">
                <div className="card">
                    <div className="card-body text-left">
                         <h5 className="m-t-0">Opportunity Details</h5>
                         <EditText
                                name="procuringEntity"
                                title="Procuring Entity"
                                value={state && state.procuringEntity}
                                placeholder={""}
                                required={true}
                                onValueChange={handleFieldValueChange}
                                errorMsg={errors && errors["procuringEntity"] ? errors["procuringEntity"]  : ""}
                            />
                             <EditText
                                name="tenderNumber"
                                title="Tender No"
                                value={state &&  state.tenderNumber}
                                placeholder={""}
                                required={true}
                                onValueChange={handleFieldValueChange}
                                errorMsg={errors && errors["tenderNumber"] ? errors["tenderNumber"]  : ""}
                            />
                             <EditText
                                name="tenderName"
                                title="Tender Name"
                                value={state &&  state.tenderName}
                                placeholder={""}
                                required={true}
                                onValueChange={handleFieldValueChange}
                                errorMsg={errors && errors["tenderName"] ? errors["tenderName"]  : ""}
                            />
                             <EditText
                                name="tenderSecurityAmount"
                                title="Tender Security Amount (USD)"
                                value={state &&  state.tenderSecurityAmount}
                                placeholder={""}
                                required={true}
                                inputType="number"
                                onValueChange={handleFieldValueChange}
                                errorMsg={errors && errors["tenderSecurityAmount"] ? errors["tenderSecurityAmount"]  : ""}
                            />

                            <EditDate
                                    name="submissionDate"
                                    title="Submission Date & Time"
                                    value={state &&  state.submissionDate ? state.submissionDate  : new Date()}
                                    placeholder={""}
                                    required={true}
                                    showTimeSelect={true}
                                    onValueChange={handleEndDateValueChange}
                                    isDateTime={true}
                                    errorMsg={errors && errors["submissionDate"] ? errors["submissionDate"]  : ""}
                                    />

                            <div className="docUploadFields float-left col-md-6 p-0">
                                      <h5 className="font-14">Tender Document<span style={{color:"red"}}>*</span></h5>
                                        <label className="custom-file-upload">
                                            <input type="file" 
                                                    name="tenderDocument" 
                                                    value=""
                                                    className="docUploader" 
                                                    // ref={"uploader"} 
                                                    onChange={uploadDocs}
                                                    accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"/> 
                                        </label>

                                        {
                                          state && !Helpers.isEmpty(state.tenderDocument) && 
                                                        <div key={state.tenderDocument.uniqueId} id={state.tenderDocument.uniqueId}> 
                                                        <a href={Helpers.viewFile(state.tenderDocument.uniqueId)} target="_blank">{state.tenderDocument.name}</a>
                                                        <a key={state.tenderDocument.uniqueId+"trigger"} data-id={state.tenderDocument.uniqueId} data-mainId={state.tenderDocument.id}
                                                                onClick={deleteDoc} data-name="tenderDocument"
                                                                className="text-danger m-l-5"
                                                                >
                                                                <i className="fa fa-times" />
                                                                </a>
                                                    </div>
                                            }
                            </div>
                            
                    </div>
                </div>
                <div className="col-sm-12 col-xs-12 p-0 m-t-10">
                                 {state && !Helpers.isEmpty(state.id) && owner && <DeletePage type="Opportunity" uri={state.id} />}
                                <button
                                    type="submit"
                                    className="btn btn-info waves-effect- waves-light- float-right"
                                    onClick={saveAndProceed}
                                >
                                  {config.oppDirectPayment ? "Save and Proceed to Payment" : "Save"} 
                                </button>
                        </div>
            </div>
        </div>
    )
}

export default TenderForm
