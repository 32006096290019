import React, { Component } from 'react';
import Select from 'react-select';
import Axios from 'axios';
import Helpers from '../../shared/custom';
import Loader from '../ui/Loader';
import STR from '../../shared/strings';
import ObjectsSearch from "../wigets/ObjectsSearch";

export default class AddPeople extends Component {

   constructor(props){
       super(props);
       this.state = {
           loading:false,
           firstName:undefined,
           lastName:undefined,           
           title:undefined,
           keyPerson:true,
           bod:false,
       }
       this.cancelAddNew = this.cancelAddNew.bind(this);
       this.handleSelectChange = this.handleSelectChange.bind(this);
       this.handleFieldChange = this.handleFieldChange.bind(this);
       this.createIndividual = this.createIndividual.bind(this);
       this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
   } 

   cancelAddNew(){
       this.props.enableAdd();
   }

   handleSelectChange(value,element){
        let name = element.name;
        this.setState({[name]:value})
   }
   handleFieldChange(e){
    let name = e.target.name;
    let value = e.target.value;   
    this.setState({
           [name]:value
       })
   }
   handleCheckboxChange(e){
    if(e.target.checked){
        this.setState({
            bod:true
        })
    }else{
        this.setState({
            bod:false
        })
    }
   }

   createIndividual(){

        if(Helpers.isEmpty(this.state.firstName)){
            Helpers.pushTError("FIRST_NAME_REQUIRED");
            return false;
        }

        if(Helpers.isEmpty(this.state.lastName)){
            Helpers.pushTError("LAST_NAME_REQUIRED");
            return false;
        }
        
        if(Helpers.isEmpty(this.state.title)){
            Helpers.pushTError("SELECT_INDIVIDUAL_TITLE");
            return false;
        }


        let uri = this.props.uri;
        let type = this.props.type;

        let payload = [{
            "individual": {"name": this.state.firstName+" "+this.state.lastName},
            "title":  {"id": this.state.title.value},
            "keyPerson":this.state.keyPerson,
            "bod":this.state.bod,
        }];
        
        if(uri){
            this.setState({loading:true});

            Axios.post(
                Helpers.apiURL({
                    uri:":type/:uri/individuals",
                    pathVar:{uri:uri, type:type}
                }),
                payload
            ).then((response) => {
                let result = response.data.result;
                if(result){
                    let person = result[0];
                    this.setState({
                        firstName:"",
                        lastName:"",
                        title:null,
                        bod:false,
                        loading:false
                    },() => {
                        Helpers.pushTSuccess("PERSON_ADDED");
                        this.props.onAdd(person);
                        this.props.enableAdd();
                    })
                }
            }).catch((err) => {
                //error
                this.setState({loading:false});
                this.props.enableAdd();
            })
        }
   }


        
  handleTitleChange = (event) => {
    this.setState({ title: event[0] });
  }

  render() {

    let loading = this.state.loading;
    
    return (
      <div>
            <div className="">
                <h5>Add New Person</h5>
                <form action="#" className="box">
                     <div className="form-body">
                          <div className="row p-t-20">
                                 <div className="col-md-6">
                                     <div className="form-group">
                                           <label className="control-label">First Name</label>
                                           <input type="text" name="firstName" value={this.state.firstName} id="firstName" className="form-control" placeholder="First Name" onChange={this.handleFieldChange}></input>
                                     </div>
                                 </div>
                                 <div className="col-md-6">
                                     <div className="form-group">
                                           <label className="control-label">Last Name</label>
                                           <input type="text" name="lastName" value={this.state.lastName} id="lastname" className="form-control" placeholder="Last Name" onChange={this.handleFieldChange}></input>
                                     </div>
                                 </div>
                                 <div className="col-md-6">
                                     <div className="form-group">
                                           <label className="control-label">Job Title</label>
                                           <ObjectsSearch
                                                    title=""
                                                    headingClass=""
                                                    onChange={event => this.handleTitleChange(event)}
                                                    name="title"
                                                    defaultValue={this.state.title?this.state.title:null}
                                                    defaultSelected={!Helpers.isEmpty(this.state.title) ? [this.state.title] : []}
                                                />
                                           {/* <Select className="font-14 col-md-12 p-0 m-0" name="title" options={this.props.titleList} value={this.state.title} placeholder="Position in company" onChange={this.handleSelectChange}   /> */}
                                     </div>
                                 </div>
                                 <div className="col-md-6">
                                     
                                 </div>
                          </div>
                     </div>

                     <div className="form-actions">
                            <div className="row">
                                <div className="col-md-6">
                                     <div className="checkbox checkbox-success">
                                                <input id="cb-cr-bod" type="checkbox" defaultChecked={this.state.bod} value={this.state.bod} onChange={this.handleCheckboxChange} />
                                                <label htmlFor="cb-cr-bod">Include in Board of Directors</label>
                                      </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="col-md-12 p-0 float-right">
                                        {loading ?
                                            <button className="btn btn-white float-right">{STR.SAVING}</button>
                                         :
                                            <div>
                                                <button type="button" className="btn btn-success float-right m-l-10" onClick={this.createIndividual}>Add</button>
                                                <button type="button" className="btn btn-inverse float-right" onClick={this.cancelAddNew}>Cancel</button>
                                            </div>                                            
                                        }                                            
                                    </div>
                                </div>
                            </div>
                       </div>
                </form>
            </div>
      </div>
    )
  }
}
