import React, { Component } from 'react';
import config from '../../shared/config';
import Helper from '../../shared/custom';
import CardActions from './CardActions';
import Comments from './Comments';
import CommentShareCount from './CommentShareCount';
import CommentShareBtn from './CommentShareBtn';
import AuthorDetails from './AuthorDetails';
import ReadMoreAndLess from 'react-read-more-less';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";


export default class EventFeedCard extends Component {

    constructor(props){
        super(props);
        this.state = {
            focusInput:false,
            updatedId :null
        }
        this.focusInputFiled = this.focusInputFiled.bind(this);
        this.updateCounts = this.updateCounts.bind(this);
    }

 focusInputFiled(){
      this.setState({
        focusInput: !this.state.focusInput
      })
  }

  updateCounts(id){
    if(!Helper.isEmpty(id)){
        this.setState({
            updatedId:id
        })
    }
  }

  render() {
    let data = this.props.data;
    let shared = this.props.shared;
    return (
      <div>
          <div className="card">
                    <div className="card-body">
                    {
                        shared ? 

                            <div className="col-lg-12 p-0">
                                    <AuthorDetails data={data} type="shared" />
                                    <div className="col-lg-3 p-0 float-right text-right">
                                    <CardActions data={data}/>
                                    </div>
                                <div className="clearfix"></div>

                                <div className="col-lg-12 p-0 m-t-20">
                                <hr/>
                                        <AuthorDetails data={data}/>
                                </div>
                                <div className="clearfix"></div>
                       </div>
                       :
                            <div className="col-lg-12 p-0">
                                <AuthorDetails data={data}/>
                                <div className="col-lg-3 p-0 float-right text-right">
                                    <CardActions data={data}/>
                                </div>
                            </div>

                    }
                        <div className="clearfix"></div>
                            <div className="col-lg-12 p-0 m-t-40 ">
                                <div className="b-b-2 p-b-5">
                                  <span> <i className="mdi mdi-calendar font-24 vm m-r-5"></i> <Link className="font-bold" to={"/"}>{data.author.name}</Link> Added a new event</span>
                                </div>
                            </div>
                    </div>
                    <div className="card-body laptop p-t-0">
                         <div className="col-lg-12 p-0">
                                <div className="m-a text-center">
                                    <img src={Helper.dataPath(data.profilePic && data.profilePic.publicUri ? data.profilePic.publicUri : config.defaultCoverPhoto)} className="m-w rounded feed-img" />
                                </div>
                                <span className="font-12 m-t-5"> <i className="mdi font-14 mdi-calendar "></i> {Helper.getShortDayNameFromDate(data.time)}, {Helper.getDateInInputFormat(data.time)}&nbsp;&nbsp;|&nbsp;&nbsp;<i className="mdi mdi-map-marker "></i> {data.city.name}{', '}{data.country.name}&nbsp;&nbsp;|&nbsp;&nbsp;<i className="mdi mdi-star "></i> {data.stats.interested} Interested</span>
                            </div>
                    </div>
                    <div className="card-body text-left p-t-0">
                         <div className="col-lg-12 p-0  font-14">
                         <Link className="font-bold font-14 col-md-12 row m-b-10"  to={"/event/"+data.uri}>
                                {data.name}
                                </Link>
                                {/* <ReadMoreAndLess
                                          ref={this.readMore}
                                          className="read-more-content font-14 text-default"
                                          charLimit={config.summaryLimit}
                                          readMoreText="Read more"
                                          readLessText="Read less"
                                      >
                                      {data.summary?data.summary:""}
                                  </ReadMoreAndLess> */}
                                   {data && data.summary?Helper.truncate(data.summary,200):"-"}
                        </div>
                        {/* <CommentShareCount data={data} updatedId={this.state.updatedId}/> */}
                        <CommentShareBtn data={data} showInterested={true} focusInputArea={this.focusInputFiled} updateCounts={this.updateCounts}/>
                        <Comments data={data} enableFocus={this.state.focusInput} />
                    </div>
        </div>
      </div>
    )
  }
}
