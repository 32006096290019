import React, { Component } from 'react';
import config from '../../shared/config';
import Axios from 'axios';
import Helper from "../../shared/custom";
import ReadMoreAndLess from 'react-read-more-less';
import store from '../../store';
import { withRouter, Redirect , Link} from 'react-router-dom';

class CompanyNews extends Component {

    constructor(props){
        super(props);
        this.state = {
            uri : undefined,
            type : undefined,
            companyName:"",
            news : [],
            localProps:{}
        }
    }

    componentDidMount(){
        let storeState = store.getState();
        this.setState({
            type: this.props.type,
            uri: this.props.entityUri,
            companyName: this.props.entity?this.props.entity.name:''
        },() => {
            this.fetchNews(this.state.uri);
        })

    }

    componentWillReceiveProps(nextProps){
        let type = this.props.type;
        let uri = nextProps.entityUri;
        let companyName = nextProps.entity?nextProps.entity.name:'';
        let storeState = store.getState();
        
        if(!storeState.auth || !storeState.auth.isAuthenticated){
            this.setState({
                type: undefined,
                uri: undefined,
                news: [],
                companyName:companyName
            })
            return false;
        }


        if(this.state.uri && this.state.uri == uri && this.state.type && this.state.type == type && this.state.companyName && this.state.companyName == companyName){
            return false;
        }

        if(JSON.stringify(this.state.localProps) != JSON.stringify(nextProps)) {
            this.setState({
                type: nextProps.type,
                uri: uri,
                companyName: companyName,
                localProps:nextProps
            }, () => {
                this.fetchNews(uri);
            })
        }
        
    }

    fetchNews(entityUri){
        let type = this.props.type;
        let uri = entityUri;
       
        if(!uri || !type){
            this.setState({
                type: undefined,
                uri: undefined,
                news: []
            })
            return false;
        }

        Axios.get(
            Helper.apiURL({
                uri:":type/:uri/news?limit=10",
                pathVar:{type: type, uri: uri}
            })
        )
        .then( (response) => {     
            var news = response.data.result || [];
            this.setState({
                news: news, 
                uri: uri, 
                type: type
            })
        })
        .catch((err) => {
            
        });
    }
    addDefaultNewsImage(ev){
        ev.target.src = Helper.dataPath("default/news-default-image.png");
    }
  render() {
    let news = this.state.news;
    let newsLength = news.length;
    let type = this.state.type;
    let uri = this.state.uri
    let companyName = this.state.companyName

    let newsFilterCriteria = {entityType:type, entityUri:uri, entityName:companyName};
    let linkToFilteredNews = "/news"+Helper.jsonToQueryString(newsFilterCriteria);


    return (
        <>
           {!Helper.isEmpty(news) && 
        <div>
            <div className="card text-left bold-headers">
                <div className="card-header text-left light-blue-header">
                    <h5 className="m-b-0 m-t-0 text-white">Company News</h5>
                </div>
                <div className="card-body">
                    <div className="profiletimeline m-0 ">
                        {newsLength > 0 ?
                            <div>
                                    {news.map((news, index) => (
                                        // <div className="sl-item" key={index}>
                                        //     <div className="sl-right">
                                        //         <div className="row">
                                        //             <div className="col-md-4 col-xs-12 p-r-0 newsImgContainer text-center">
                                        //                 <Link to={{pathname:"/news/"+news.uri,"parentUrl": window.location.pathname}} >
                                        //                     <img onError={this.addDefaultNewsImage}  src={!Helper.isEmpty(news.image) ? news.image : Helper.dataPath(config.defaultNewsIcon)} alt="user" className="img-responsive imgNews" />
                                        //                 </Link>
                                        //             </div>
                                        //             <div className="col-md-8 col-xs-12 font-12">
                                        //                 <Link to={{pathname:"/news/"+news.uri,"parentUrl": window.location.pathname}}>
                                        //                     <h6 className="font-medium font-12 m-b-5 m-t-0 m-0-m">{news.headLine}</h6> 
                                        //                     <div className="text-default font-medium">{news.date}</div>
                                        //                 </Link>
                                        //             </div>
                                        //         </div>
                                        //         </div>
                                        //     <hr/>
                                        // </div>
                                        <div className="col-md-12 newsDiv p-10" key={index}>
                                            <div className="col-md-6 news-img float-left">
                                                <img onError={this.addDefaultNewsImage}  src={!Helper.isEmpty(news.image) ? news.image : Helper.dataPath(config.defaultNewsIcon)} alt="user" className="img-responsive imgNews"/>
                                            </div>
                                            <div className="col-md-6 float-left">
                                                <h2 className="text-blue font-14 m-t-0"><Link to={'/news/'+news.uri}>{Helper.truncate(news.headLine,50)}</Link></h2>
                                                <p className="font-12">{Helper.truncate(news.description,100)}</p>
                                                <div className="font-12">
                                                    <i class="material-icons font-12 text-blue v-a">language</i><a href={news.url} target="_blank"> {Helper.domainOnly(news.url)}</a> | <i class="mdi mdi-calendar"></i> {Helper.getDateInInputFormat(news.date)}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            
                                <div className="m-t-10">                                
                                        <Link to={linkToFilteredNews}>
                                            <span className="float-right text-blue">View all</span>
                                        </Link>
                                </div>
                            </div>
                        :
                        <div>
                            <center>No data found!</center>
                        </div>
                        }                
                    </div>
                </div>
            </div>
      </div>
  }
  </>
    )
  }
}

export default withRouter(CompanyNews);
