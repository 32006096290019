import React, {Component} from "react";
import DatePicker from "react-datepicker";
import CompanySearch from "../wigets/CompanySearch";
import EntitySearch from "../wigets/EntitySearch";
import CommonSearch from "../wigets/CommonSearch";
import Helpers from "../../shared/custom";
import Select from "react-select";
import Axios from "axios";
import STR from "../../shared/strings";
import config from '../../shared/config';

export default class CareerRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editableRow: false,
            id: null,
            company: null,
            titleObj: null,
            startDate: new Date(),
            endDate: new Date(),
            isCurrent: false,

            unmount: false,
            loading: false,
            savedData:[]
        };

        this.handleSave = this.handleSave.bind(this);
        this.handleFieldValueChange = this.handleFieldValueChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);

        this.validateForm = this.validateForm.bind(this);
        this.setupRow = this.setupRow.bind(this);
        this.handleEditableNotEditable = this.handleEditableNotEditable.bind(this);
        this.unmount = this.unmount.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);

        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);

        this.handleDelete = this.handleDelete.bind(this);
    }

    componentDidMount() {
        this.setupRow(this.props.row);
    }

    setupRow(row) {
        
        this.setState({
            id: row.id,
            company: row.company,
            titleObj: Helpers.makeSelectOption(row.title),
            isCurrent: row.isCurrent,
            startDate: row.startDate
                ? Helpers.getDateInInputFormat(row.startDate, true)
                : undefined,
            endDate: row.endDate
                ? Helpers.getDateInInputFormat(row.endDate, true)
                : undefined
        });

        if (Helpers.isEmpty(row.id)) {
            this.setState({
                editableRow: true
            });
        }
    }

    unmount() {
        this.setState({
            unmount: true
        });
    }

    handleEditableNotEditable() {
        this.setState(
            {
                editableRow: this.state.editableRow ? false : true
            },
            function () {
                if (Helpers.isEmpty(this.state.id) && this.state.editableRow == false)
                    this.unmount();
            }
        );
    }

    handleCompanyChange(event) {
        this.setState({company: event[0]});
    }

    handleFieldValueChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        if (name) {
            this.setState({[name]: value});
        }
    }

    handleIsCurrent(event) {
        let value = false;
        if (event && event.target && event.target.checked) {
            value = true;
        }
        this.setState({
            isCurrent: value
        });
    }

    handleStartDateChange(date) {
        this.setState({startDate: date});
    }

    handleEndDateChange(date) {
        this.setState({endDate: date});
    }

    handleSelectChange(option, e) {
        let name = e.name;
        this.setState({[name]: option});
    }

    validateForm() {
        let isValid = true;
        let message = [];

        if (!Helpers.isValidDate(this.state.startDate)) {
            isValid = false;
            message.push("START_DATE_REQUIRED");
        }


       
        if (
            Helpers.isEmpty(this.state.titleObj.name)
        ) {
            isValid = false;
            message.push("CAREER_TITLE_REQUIRED");
        }

        if (
            Helpers.isEmpty(this.state.company.name)
        ) {
            isValid = false;
            message.push("CAREER_COMPANY_REQUIRED");
        }

        if (
            !Helpers.isValidDate(this.state.endDate) &&
            this.state.isCurrent == false
        ) {
            isValid = false;
            message.push("END_DATE_REQUIRED");
        }

        if (message.length > 0) {
            Helpers.pushTError(message);
            return isValid;
        }

        return isValid;
    }

    handleDelete(target) {
        if (target && target.dataset.careerid) {
            let id = target.dataset.careerid;

            Axios.delete(
                Helpers.apiURL({
                    uri: "user/profile/career/" + id
                })
            )
                .then(response => {
                    if (response.status == 200) {
                        this.unmount();
                    }
                    let savedData = this.state.savedData;
                    savedData = savedData.filter((x)=>x.id != id);
                    //this.props.fetchCareerChild(savedData)
                    Helpers.pushSuccess("Career is deleted successfully");
                })
                .catch(err => {
                    Helpers.pushError("Career cannot be deleted");
                });
        } else {
            this.unmount();
        }
    }

    handleSave() {
        if (true !== this.validateForm()) return;

        let toSaveRow = {};

        let title =  this.state.titleObj;
        let company = this.state.company;
        toSaveRow.title = title.customOption ? {"id":null,name:title.name} : title; //Helpers.makeSelectOption(this.state.title);
        toSaveRow.company =  company.customOption ? {"id":null,name:company.name} : company;
        toSaveRow.id = this.state.id;
        toSaveRow.isCurrent = this.state.isCurrent;
        toSaveRow.startDate = Helpers.getDateInInputFormat(this.state.startDate);
        toSaveRow.endDate = Helpers.getDateInInputFormat(this.state.endDate);
        let payload = [];
        payload.push(toSaveRow);

        this.saveData(payload);
    }

    saveData(payload) {
        let payloadData = payload;

        Axios.post(
            Helpers.apiURL({
                uri: "user/profile/career"
            }),
            payloadData
        )
            .then(response => {
                Helpers.pushTSuccess("SAVE_SUCCESS");
                let result = response.data.result;
                let addedUpdatedCareer = result[0];
                let savedData = this.state.savedData;
                savedData.push(addedUpdatedCareer);

                //let careerHistory = this.state.careerHistory;
                let rows = this.state.rows;
               // this.props.fetchCareerChild(savedData)
                // if (!Helpers.isEmpty(rows[addedUpdatedCareer.id])){

                // }
                // careerHistory.push(addedUpdatedCareer);
                addedUpdatedCareer.isCurrent = addedUpdatedCareer.current;

                this.setState(
                    {
                        // editCareerHistory: this.state.editCareerHistory?false:true,
                        row: addedUpdatedCareer,
                        id: addedUpdatedCareer.id,
                        editableRow: false
                    },
                    function () {

                    }
                );
            })
            .catch(error => {
                Helpers.pushTError("Cannot Save the Career");
            });
    }

    handleClick() {
        //this.handleCompanyChange();
        this.setState({company: this.state.company});
        var event = new Event("input", {bubbles: true});
        this.myinput.dispatchEvent(event);
    }

    handleTitleChange = (event) => {
     
        this.setState({titleObj: event[0]});
    }

    render() {
        if (this.state.unmount) {
            return <React.Fragment></React.Fragment>;
        }

        let editableRow = this.state.editableRow;
        let company = this.state.company;
        let titleObj = this.state.titleObj;
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        let objId = this.state.id;
        let isCurrent = this.state.isCurrent;
        let editCareerHistory = this.props.editCareerHistory;

        let deleteConfig = {
            proceed: this.handleDelete
        };

        let key = this.props.index;

       
        return (
            <tr className={"rbtWidthFix"} key={key}>
                {editableRow == true? (
                    <td className={'select-word-break-fix'}>
                        {/* <CompanySearch
                            title=""
                            allowNew={true}
                            headingClass="hidden"
                            onChange={event => this.handleCompanyChange(event)}
                            name="company"
                            defaultValue={company ? company.name : ""}
                            defaultSelected={!Helpers.isEmpty(company) ? [company] : []}
                        /> */}
                        <EntitySearch 
                            onChange={event => this.handleCompanyChange(event)}
                            defaultValue={this.state.company?this.state.company.name:null}
                            defaultSelected={!Helpers.isEmpty(this.state.company) ? [this.state.company] : []}
                            />
                       
                    </td>
                ) : (
                    <td>{company && company.name ? company.name : ""}</td>
                )}

                {editableRow == true ? (
                    <td className={'select-word-break-fix'}>
                        <CommonSearch
                            title=""
                            allowNew={true}
                            onChange={event => this.handleTitleChange(event)}
                            name="titleObj"
                            defaultValue={titleObj ? titleObj.name : ""}
                            defaultSelected={!Helpers.isEmpty(titleObj) ? [titleObj] : []}
                        />
                        {/* <Select
              name="title"
              value={title}
              className="inline-minimal-width font-14 col-md-12 p-0 m-0"
              options={careerTitles}
              onChange={this.handleSelectChange}
            /> */}
                    </td>
                ) : (
                    <td>{titleObj && titleObj.name ? titleObj.name : ""}</td>
                )}
                {editableRow == true ? (
                    <td className="text-center" style={{"minWidth": "100px"}}>
                        <DatePicker
                            onChange={this.handleStartDateChange.bind(this)}
                            selected={startDate ? new Date(startDate) : this.state.startDate}
                            className="form-control date"
                            todayButton={"Today"}
                            placeholderText="Please enter Start Date"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            //dateFormat={config.getDateInDisplayFormat}
                        />
                    </td>
                ) : (
                    <td>{startDate ? Helpers.getDateInInputFormat(startDate) : ""}</td>
                )}
                {editableRow == true ? (
                    <td className="text-center">
                        {isCurrent == true ? (
                            ""
                        ) : (
                            <DatePicker
                                onChange={this.handleEndDateChange.bind(this)}
                                selected={endDate ? new Date(endDate) : this.state.endDate}
                                className="form-control date"
                                todayButton={"Today"}
                                placeholderText="Please enter End Date"
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                // dateFormat={config.getDateInDisplayFormat}
                            />
                        )}
                        <div className="checkbox checkbox-success">
                            <input
                                id={"cb-cr-bod"+key}
                                type="checkbox"
                                checked={isCurrent}
                                onChange={event => this.handleIsCurrent(event)}
                            />
                            <label htmlFor={"cb-cr-bod"+key} class="checkboxLabel">Present Job</label>
                        </div>
                    </td>
                ) : (
                    <td>
                        {isCurrent == true
                            ? "Current"
                            : endDate
                                ? Helpers.getDateInInputFormat(endDate)
                                : ""}
                    </td>
                )}
                {editCareerHistory ? (
                    <td className="text-center">
                        {editableRow == true ? (
                            <div className="horizontalChilds">
                                <a
                                    href="javascript:void(0)"
                                    className="btn btn-xs btn-info text-white m-t-5 m-l-5"
                                    onClick={event => this.handleSave(objId, event)}
                                >
                                    {" "}
                                    Save{" "}
                                </a>
                                <a
                                    onClick={this.handleEditableNotEditable}
                                    className="btn btn-xs btn-danger text-white m-t-5 m-l-5"
                                >
                                    {" "}
                                    Cancel{" "}
                                </a>
                            </div>
                        ) : (
                            <React.Fragment>
                                <a
                                    href="javascript:void(0)"
                                    index={objId}
                                    id={objId}
                                    className="btn btn-xs btn-danger text-white m-t-5"
                                    data-careerid={objId}
                                    onClick={event =>
                                        Helpers.actionConfirm(event, deleteConfig)
                                    }
                                >
                                    Delete
                                </a>
                                <a
                                    href="javascript:void(0)"
                                    index={objId}
                                    className="btn btn-xs btn-info text-white m-t-5 m-l-5"
                                    id={objId}
                                    onClick={this.handleEditableNotEditable}
                                >
                                    Edit
                                </a>
                            </React.Fragment>
                        )}
                    </td>
                ) : (
                    ""
                )}
            </tr>
        );
    }
}
