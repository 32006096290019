import React, { Component } from 'react';
import EditMultiSelect from '../ui/EditMultiSelect';

export default class Investor extends Component {

    constructor(props){
        super(props);
        this.state={
            showForm :false,
            date:new Date(),
            addNewInvestor:false,
            addInvestor:false,
            showTable:false,
            rows:[1],
        }
  
        this.showForm = this.showForm.bind(this);
       this.addNewInvestor = this.addNewInvestor.bind(this);
        
    }  

    showForm(){
        this.setState({
            showForm:this.state.showForm?false:true
        })
    }
    addNewInvestor(){
        this.setState({
            addNewInvestor:this.state.addNewInvestor?false:true
        })
    }

  render() {
    let showForm = this.state.showForm;
    let addNewInvestor = this.state.addNewInvestor;
    return (
      <div className="m-t-20">
       <h5 className="text-black">Investors</h5>
        <div className="grayDiv">
          {showForm ?
              <div className="card card-gray ">
                <div className="card-body">
                  <h4 className="card-title font-medium">Investors Details <a href="javascript:void(0)" className="float-right" onClick={this.showForm}><i className="fa fa-times text-danger"></i></a></h4>
                  <table className="table font-14">
                                          <thead>
                                                <tr>
                                                    <th>Investor Name</th>
                                                    <th>Lead Investor</th>
                                                    <th>Investor Type</th>
                                                    <th>Funding Round</th>
                                                    <th>Partners</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                         </thead>
                                         <tbody className="bg-white">
                                             <tr>
                                                     <td>
                                                       Test
                                                     </td>
                                                     <td>
                                                       Yes
                                                     </td>
                                                     <td>
                                                       Angel
                                                     </td>
                                                     <td>
                                                       Funding Round
                                                     </td>
                                                     <td className="text-info">
                                                       Partner 1,Partner 2,Partner 3
                                                     </td>
                                                     <td>
                                                        <a href="javascript:void(0)" onClick={this.showTable} >Edit</a>
                                                     </td>
                                                     <td>
                                                         <a href="">Delete</a>
                                                     </td>
                                             </tr>
                                         </tbody>
                                        </table>


                    </div>
                    <div>
                        {addNewInvestor ?
                           <div className="card-body">
                           <h4 className="card-title font-medium">Add New Investor<a href="javascript:void(0)" className="float-right" onClick={this.addNewInvestor}><i className="fa fa-times text-danger"></i></a></h4>
                            <div className="col-md-12 row">
                               <div className="col-md-3 col-sm-12 p-0 float-left">
                                   <h5 className="m-t-10 m-b-10 font-12">Investor Name</h5>
                                   <input className="form-control" type="text"/>
                               </div>
                               <div className="col-md-3 col-sm-12 float-left">
                                    <h5 className="m-t-10 m-b-10 font-12">Lead Investor</h5>
                                    <div className="m-b-10">
                                        <div className="demo-radio-button">
                                            <div className="float-left col-3 p-0">
                                                <input name="group5" type="radio" id="radio_30" className="with-gap radio-col-blue" />
                                                <label htmlFor="radio_30" className="l-h-0">Yes</label>
                                            </div>
                                            <div className="float-left col-3 p-0">
                                                <input name="group5" type="radio" id="radio_31" className="with-gap radio-col-blue" />
                                                <label htmlFor="radio_31" className="l-h-0">No</label>
                                            </div>
                                    </div>
                                    </div>
                                </div>
                               <div className="col-md-3 col-sm-12  float-left">
                                   <h5 className="m-t-10 m-b-10 font-12">Investor Type</h5>
                                   <select className="form-control"></select>
                               </div>
                               <div className="col-md-3 col-sm-12  float-left">
                                   <h5 className="m-t-10 m-b-10 font-12">Funding Round</h5>
                                   <select className="form-control"></select>
                               </div>
                               </div>
                               <div className="col-md-12 row">
                                    <div className="col-md-3 col-sm-12 float-left p-0">
                                        <EditMultiSelect titleClass="m-t-10 m-b-10 font-12" title="Partners"/>
                                    </div>  
                                    <div className="col-md-12 p-0">
                                        <button className="btn btn-info m-t-5 float-right">Save</button>
                                    </div>
                                </div>
                           </div>
                         :
                         <div className="col-12 m-t-10">
                             <a href="javacript:void(0)" className="float-left" onClick={this.addNewInvestor}>+ Add Investor</a>
                         </div>

                        }
                       
                    </div>
               </div>
           :
           <div className="m-a text-center col-12 m-b-10">
                <a href="javascript:void(0)" className="text-default custom-plus-icon" onClick={this.showForm}></a>
                <div className="font-12 font-medium">Add Investors</div>
            </div>
        
           }
        </div>
      </div>
    )
  }
}
