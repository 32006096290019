import React, { Component } from 'react';
import Helpers from '../../shared/custom';
import config from '../../shared/config';
import Axios from 'axios';
import {AsyncTypeahead, Highlighter, Menu, MenuItem} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { BrowserRouter as Router, Route, Link ,Redirect ,withRouter } from "react-router-dom";

class TopSearch extends Component {
    
  constructor(props){
    super(props);
    this.state = {
      allowNew: false,
      isLoading: false,
      multiple: false,
      options: [],
      query: '',
      inputval:undefined,
      privileges:{},
      localProps:{}
    }

    this._handleSearch = this._handleSearch.bind(this);
    this._onChange = this._onChange.bind(this);
    this._clearSearchInput = this._clearSearchInput.bind(this);
    this.dataSearch = React.createRef();
    this._handleInputChange = this._handleInputChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.getType = this.getType.bind(this);
    this.openAdvSearch = this.openAdvSearch.bind(this);
  }
  componentDidMount(){
    this.setState({
      multiple:this.props.multiple,
      privileges:this.props.privileges
    });

    if(!Helpers.isEmpty(this.props.clearSearch)){
      this.props.clearSearch(this._clearSearchInput);
    }

   
  }

  componentWillReceiveProps(props){
    this.setState({
      inputval:props.value
    })

    if(JSON.stringify(props) != JSON.stringify(this.state.localProps)){

      var pathName = window.location.pathname;
      var mainType = pathName.split("/")[1];
      if(!Helpers.isEmpty(mainType)){
        mainType =  mainType.trim()
      }
  
      if(mainType != "company" && mainType != "event" && mainType != "people"){
        this._clearSearchInput()
     }

     this.setState({localProps:props})
    }
   

  }

  onKeyDown(e){
    if(!Helpers.isEmpty(this.props.onKeyDown)){
      this.props.onKeyDown(e);
      if(e.keyCode == 13){
        if(!Helpers.isEmpty(this.dataSearch)){
          this.dataSearch.getInstance().blur();
        }
      }
    }
  }
  _onChange(e){
    this.props.onChange(e);
  }

  _clearSearchInput(){
    setTimeout(()=>{ 
      if(this.refs.dataSearch){
        this.refs.dataSearch.getInstance().clear()
      }
      
     // this.dataSearch.getInstance().clear()
    },0)
  }

  _handleInputChange(e){
    if(!Helpers.isEmpty(this.props.onInputChange)){
        this.props.onInputChange(e);
    }
  }

  getType(page){
    let type = "event";
    if(page.primaryPageType){
        type = page.primaryPageType.type.type;
        type = (type+"").toLowerCase();
    }    
    return type;
 }

  _handleSearch = (query) => {
    setTimeout(()=>{
    Axios.get(
        Helpers.apiURL({
          uri:`v2/top-search`,
          query:{term:query}
        })
      )
      .then((response) => {
         let dataset = response.data; //response.data.result;
         let allOptions = [];
      
          dataset.forEach(data => {      
            let entry = data.page ? data.page : data;
            let type = this.getType(entry);
            let image = Helpers.isEmpty(entry.image) ? Helpers.dataPath(Helpers.getDefaultImage(type)) : Helpers.dataPath(entry.image);
            if(type == "event"){
                image = entry.coverPhoto && entry.coverPhoto.publicUri ? Helpers.dataPath(entry.coverPhoto.publicUri) : Helpers.getDefaultImage(type)
            }
            let name = entry.name? entry.name : "";
            let banner = type;
            // banner += entry.primaryPageType &&  entry.primaryPageType.subType ? ' - '+entry.primaryPageType.subType.type : "";
            let uri = entry.uri;
            if(type && entry.uri){
               uri = "/"+type+"/"+entry.uri;
             }
       
              allOptions.push(
                  {
                    uniqueId:entry.score,
                    name: name,
                    uri: uri,
                    value:uri,
                    data:data,
                    image:image,  
                    type:banner
                  }
                )             
          });

      
          this.setState({
            options: allOptions,
          })
       
      })
      .catch((err) => {
        
        })
      },200)
    }

  _renderMenu = (options, menuProps) => {
   
    let idx = 0;
    const items = [
      <div key={"data-adv-1"} className="text-black advSearch font-14">Didn’t find what you were looking for? <a href="javascript:void(0)" onClick={this.openAdvSearch}> Try advanced search</a></div>,
      options.map((option) => {
        const item =
          <MenuItem key={idx} option={option} position={idx}>
                <img src={option.image}  className="img-circle pull-left m-r-20 m-b-10 float-left" width="30" />
                <div>
                    <h6 className="font-normal  m-t-5">{option.name} <span className="font-10 text-grey">{option.type}</span>
                    </h6> 
                </div>
          </MenuItem>;

        idx++; 
        return item;
      }),
    ];

    return <Menu {...menuProps}>{items}</Menu>;
    
  }

  openAdvSearch(){
      this.refs.dataSearch.getInstance().blur();
      this.props.history.push("/search/advanced?t=common");
  }

  render() {

    let title = this.props.title;
    let headingClass = this.props.headingClass? this.props.headingClass : "";
    let defaultSelected = this.props.defaultSelected;
    let selected = this.props.selected;
    let options = this.state.options;
    let value = this.state.inputval;


 
    return (
      <div>
         {!Helpers.isEmpty(title) ? <h5 className={headingClass}>{title}</h5> : ""}
         <div className="col-12 p-0">
         <AsyncTypeahead
            isLoading = {this.state.isLoading}
            onInputChange={this._handleInputChange}
            minLength={1}
            filterBy={() => true}
            labelKey="name"
            ref="dataSearch"
            placeholder={"Find Opportunities, Investors and more…"}
            selected={selected}
            onSearch={this._handleSearch}
            onChange={this._onChange}
            multiple= {this.state.multiple}
            onKeyDown={this.onKeyDown}
            clearButton={true}
            highlightOnlyResult={false}
            options={options}
            renderMenu = {this._renderMenu}
            defaultInputValue={!Helpers.isEmpty(Helpers.getQueryParameter("query")) ? Helpers.getQueryParameter("query") : "" }
          />
         </div>
      </div>
    )
  }
    
}

export default withRouter(TopSearch)