import React, { Component } from 'react';
import CompanySearchPublished from '../wigets/CompanySearchPublished';
// import Select from 'react-select';
// import ObjectsSearch from "../wigets/ObjectsSearch";
import Helpers from "../../shared/custom";
import EditSelect from "../ui/EditSelect";
import Ps from "../wigets/Ps";
export default class PeopleForm  extends Component {
    constructor(props){
        super(props);
        this.state = {
            countryOptions:undefined
        }
        this.onChangeCompany = this.onChangeCompany.bind(this);
      
    }

    onChangeCompany(e){
    }

    render() {
        let values = this.props.values;
        let metaList = this.props.metaList;

        
        return (
            <div>                
                <div className="row">
                    
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label font-bold font-18">Professional Body</label>
                            {/* <input type="text" id="peopleJobTitle" className="form-control" name="peopleJobTitle" value={values.peopleJobTitle} onChange={this.props.onChange} /> */}
                            <EditSelect
                                    title=""
                                    name="professionalBody"
                                    isClearable={true}
                                    value={values && values.professionalBodies ? values.professionalBodies : []}
                                    options={this.props.pbOption}
                                    onChange={this.props.professionalBodies}
                                    additionalClassName="full-width "
                                  
                                    />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            
                            <label className="control-label font-bold font-18">Services Offered</label>
                            {/* <CompanySearchPublished onChange={this.props.onCompanyChange} defaultSelected={values.peopleCompany} /> */}
                            <Ps 
                                    allowNew={'no'}
                                    onChange={event => this.props.peoplePslChange(event)}
                                    defaultValue={values.peoplePsl?values.peoplePsl.name:null}
                                    defaultSelected={!Helpers.isEmpty(values.peoplePsl) ? values.peoplePsl : []}
                                    />
                        </div>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label font-bold font-18">Role on Investera</label>
                            <Select                            
                                className="font-14 col-md-12 p-0 m-0"
                                name="peopleRole"
                                options={values.roleOptions}
                                placeholder="Select role"
                                value={values.peopleRole}
                                onChange={this.props.onSelectChange}
                            />
                        </div>
                    </div>
                </div> */}
            </div>
        )
    }
}
