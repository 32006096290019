import React, { Component } from "react";

export default class SelectableTag extends Component {

    constructor(props){
        super(props)
        this.state = {
            option:props.option,
            selected:props.selected || false,
            enableChange:props.enableChange  
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            option: nextProps.option,
            enableChange:nextProps.enableChange  
        };
    }
    onClick = ()=>{
        if(this.state.enableChange){
            this.setState({
                selected:!this.state.selected
            },()=>{
                if(this.props.onChange){
                    this.props.onChange(this.state)
                }
            });
        }
    }
    render(){
        let option = this.state.option;
        let selected = this.state.selected;
        return (
            <div id={this.props.id+"selectable_tag____"+option.value} key={this.props.id+"selectable_tag____"+option.value} className={"d-flex d-inline-flex selectable-tag p-2 " + (selected?"selected":"")} onClick={this.onClick}>
                {option?option.label:""}
            </div>
        )
    }

}