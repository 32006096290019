import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Sidemenu from "../sidemenu/Sidemenu";
import MainContent from "./MainContent";
import Rightpanel from "./Rightpanel";
import { connect } from "react-redux";
import store from "../../store";
import { getMetaData } from "../../actions/metaAction";
import { getSectorData } from "../../actions/businessAction";
import Helper from "../../shared/custom";
import { checkServerIdentity } from "tls";
import MetaList from "../ui/MetaList";
import ApiHelper from "../../shared/ApiHelper";
import Helpers from "../../shared/custom";
import EditableFields from "./EditableFields";
import Videos from "../opportunity/Videos";
import Upload from "../opportunity/Upload";
import Axios from "axios";
import qs from "query-string";

class CreateEventDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      location: props.location,
      metaList: {},
      business: {},
      industryList: [],
      types: [],
      lastPageState: null
    };

    store.subscribe(() => {
      var storeState = store.getState();
      var localMetaList = this.state.metaList;
      var storeMetaList = storeState.meta.metaList;

      if (
        !localMetaList ||
        JSON.stringify(localMetaList) != JSON.stringify(storeMetaList)
      ) {
        this.setState({ metaList: storeMetaList });
      }

      var localBusiness = this.state.business;
      var storeBusiness = storeState.business.data;
      if (
        !localBusiness ||
        JSON.stringify(localBusiness) != JSON.stringify(storeBusiness)
      ) {
        this.setState({ business: storeBusiness });
      }
    });

    //   this.setMetaList = this.setMetaList.bind(this);
  }

  componentDidMount() {
    let lastPageState = this.state.location.state;
    let uri = this.props.match.params.uri;
    //let pageUri = this.props.match.params.pageUri;
    let pageUri = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    }).pageUri;
    this.setState({
      pageUri: pageUri
    });

    var storeState = store.getState();
    let meta = storeState.meta;
    let business = storeState.business;

    if (Helper.isEmpty(business.data) == true) {
      this.props.getSectorData();
    } else {
      this.setState({ business: business.data }, function() {});
    }

    if (Helper.isEmpty(meta.metaList) == true) {
      this.props.getMetaData();
    } else {
      this.setState({ metaList: meta.metaList });
    }

    if (!uri) {
      this.props.history.push({
        pathname: "/create-event/start"
      });
    }

    if (uri == "new") {
      //new event
      lastPageState = this.state.location.state;
      if (!lastPageState || !lastPageState.country.value) {
        this.props.history.push({
          pathname: "/create-event/start"
        });
      }
      this.setState({
        lastPageState: lastPageState
      });
    } else {
      Axios.get(
        Helper.apiURL({
          uri: "event/:uri/details",
          pathVar: { uri: uri }
        })
      )
        .then(response => {
          let event = response.data.result || null;

          // event.status = "APPROVED";

          if (!event) {
            Helper.pushTError("EVENT_NOT_FOUND");
          } else {
            this.setState({
              eventDetails: event
            });
          }
        })
        .catch(err => {
          Helper.pushTError("EVENT_NOT_LOADED");
        });
    }
  }

  render() {
    let lastPageState = this.state.lastPageState;
    let eventDetails = this.state.eventDetails || null;
    let business = this.state.business;
    let industryList = business ? business.industriesArray : [];
    return (
      <div>
        <div className="container-fluid">
          <div className="row content-in m-t-80 p-t-10">
            <div className="col-lg-3 col-xlg-3 col-md-3 searchMenu sideMenuCont">
                <Sidemenu mode="events" />
            </div>
            <div className="col-lg-6 col-xlg-6 col-md-6 centerDiv">
              <MainContent
                lastPageState={lastPageState}
                eventDetails={eventDetails}
                industryList={industryList}
              />
            </div>
            <div className="col-lg-3 col-xlg-3 col-md-2 m-t-2 right-side">
              <Rightpanel
                lastPageState={lastPageState}
                eventDetails={eventDetails}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(
  null,
  { getMetaData, getSectorData }
)(withRouter(CreateEventDetails));
