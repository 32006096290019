import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import config from '../../shared/config';
import Helper from '../../shared/custom';
import { withRouter, Redirect ,Link} from 'react-router-dom';



export default class UserReport extends Component {
    render() {
        return (
            <div className="card additionalClass">
                 <div className="card-body">
                     <div class="page">
                         <img src={config.reportFolder+'001-Cover-Page.png'} className="img-fluid"/>
                     </div>
                     {/* <div class="page">
                         <img src={config.reportFolder+'001-E-commerce Graph.png'} className="img-fluid"/>
                     </div> */}
                     <div class="page">
                         <img src={config.reportFolder+'002-Funding-Overview01.png'} className="img-fluid"/>
                     </div>
                     {/* <div class="page">
                         <img src={config.reportFolder+'002-UAE & Turkey Table.png'} className="img-fluid"/>
                     </div> */}
                     <div class="page">
                         <img src={config.reportFolder+'003-Funding-Overview02.png'} className="img-fluid"/>
                     </div>
                     {/* <div class="page">
                         <img src={config.reportFolder+'003-Number of Deals Table.png'} className="img-fluid"/>
                     </div> */}

                     <div class="page">
                         <img src={config.reportFolder+'004-Country-Breakdown.png'} className="img-fluid"/>
                     </div> 

                     <div class="page">
                         <img src={config.reportFolder+'005-UAE01.png'} className="img-fluid"/>
                     </div>

                     <div class="page">
                         <img src={config.reportFolder+'006-UAE02.png'} className="img-fluid"/>
                     </div>
                     <div class="page">
                         <img src={config.reportFolder+'007-Turkey01.png'} className="img-fluid"/>
                     </div>

                     <div class="page">
                         <img src={config.reportFolder+'008-Turkey02.png'} className="img-fluid"/>
                     </div>

                     <div class="page">
                         <img src={config.reportFolder+'009-Egypt01.png'} className="img-fluid"/>
                     </div>

                     <div class="page">
                         <img src={config.reportFolder+'010-Egypt02.png'} className="img-fluid"/>
                     </div>

                     <div class="page">
                         <img src={config.reportFolder+'011-Saudi-Arabia01.png'} className="img-fluid"/>
                     </div>

                     <div class="page">
                         <img src={config.reportFolder+'012-Saudi-Arabia02.png'} className="img-fluid"/>
                     </div>
                     <div class="page">
                         <img src={config.reportFolder+'013-Lebanon01.png'} className="img-fluid"/>
                     </div>

                     <div class="page">
                         <img src={config.reportFolder+'014-Lebanon02.png'} className="img-fluid"/>
                     </div>

                     <div class="page">
                         <img src={config.reportFolder+'015-Industry-Breakdown.png'} className="img-fluid"/>
                     </div>

                     <div class="page">
                         <img src={config.reportFolder+'016-E-Commerce.png'} className="img-fluid"/>
                     </div>

                     <div class="page">
                         <img src={config.reportFolder+'017-Logistics-&-Supply-Chain.png'} className="img-fluid"/>
                     </div>

                     <div class="page">
                         <img src={config.reportFolder+'018-Real-Estate.png'} className="img-fluid"/>
                     </div>

                     <div class="page">
                         <img src={config.reportFolder+'019-Financial-Services-.png'} className="img-fluid"/>
                     </div>

                     <div class="page">
                         <img src={config.reportFolder+'020-Information-Technology.png'} className="img-fluid"/>
                     </div>

                     <div class="page">
                         <img src={config.reportFolder+'021-Funding-Institutions01.png'} className="img-fluid"/>
                     </div>
                     <div class="page">
                         <img src={config.reportFolder+'022-Funding-Institutions02.png'} className="img-fluid"/>
                     </div>
                     <div class="page">
                         <img src={config.reportFolder+'023-2019-Acquisitions01.png'} className="img-fluid"/>
                     </div>
                     <div class="page">
                         <img src={config.reportFolder+'024-2019-Acquisitions02.png'} className="img-fluid"/>
                     </div>
                     <div class="page">
                         <img src={config.reportFolder+'025-2019-Acquisitions03.png'} className="img-fluid"/>
                     </div>
                    
                    <div className="m-t-20 clearfix">
                      <a href="http://plus-cdn.investera.com/pdf/2019 MENA Venture Investment Report.pdf" className="btn btn-action" download>Download PDF</a>
                     </div>
                 </div>
            </div>
        )
    }
}
