import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link , withRouter, Switch} from 'react-router-dom';
import Eventsummarymenu from './Eventsummarymenu';
import Helpers from '../../shared/custom';


import store from '../../store';
import { connect } from 'react-redux';
import { getPackageDetails } from '../../actions/packageAction';
import { login } from '../../actions/authAction';

class SidemenuEvents extends Component {
   constructor(props){
        super(props)
        this.state = {            
            isLoggedIn:false,
            context:undefined,
            privileges:[],
            privilegesLoaded:false,
        }
        this.getLocationId = this.getLocationId.bind(this);        
        this.getDateFilterVal = this.getDateFilterVal.bind(this);
    } 

    componentDidMount(){
        
        var storeState = store.getState();
        this.setState({
            isLoggedIn: storeState.auth.isAuthenticated
        },()=>{
            if(this.state.isLoggedIn && this.props.getPackageDetails){
                this.props.getPackageDetails();
            }
        }) 
    } 

    componentWillReceiveProps(props){
        var component = this;
        var storeState = store.getState();
        let newLogin = storeState.auth.isAuthenticated;
        let previousLogin = this.state.isLoggedIn;
        let privilegesLoaded = this.state.privilegesLoaded;
        let packageDetails = storeState.packageDetails;

        if(!previousLogin && newLogin){
            this.setState({
                isLoggedIn: newLogin,
                privilegesLoaded:false,
                privileges:[],
            },()=>{ 
                //component.props.getPackageDetails();
            }) 

        }else if(previousLogin && !privilegesLoaded){
            //this.props.getPackageDetails();
        }else if(!privilegesLoaded && packageDetails){
            let privileges = packageDetails.privileges;
            
        }

    }

    getLocationId(locationIds){
        this.props.sendLocationToParent(locationIds);
    }

    getDateFilterVal(filterDates){
        this.props.getDateFilterValues(filterDates);
        this.setState({
           filterDates:filterDates
        })
    }

    render() {

        let absolutedMenu = "abs-sidebar";
        let sideMenuBg = this.state.isLoggedIn ? "sideMenuBg" : "";
        return (
            <Router>
                <div className={"left-sidebar sticky-nav nav lefted "+sideMenuBg}> 
                    <div className="scroll-sidebar ">
                        <nav className={"sidebar-nav "+sideMenuBg}>
                        <div className={"left-aside "+absolutedMenu+" "+sideMenuBg}>
                                <div className={sideMenuBg}>                             
                                    <Eventsummarymenu isLoggedIn={this.props.isLoggedIn}  context={this.state.context} getLocationIdFunc={this.getLocationId} getDateFilterValues={this.getDateFilterVal} />
                                    <br className="clearFix" />
                                </div>                         
                            </div>
                        </nav>
                    </div>
                </div> 
            </Router>  
        )
        
    }
}

const mapStateToProps = (state) =>({
    auth:state.auth,
    packageDetails:state.packageDetails,
    errors:state.errors
});

//export default connect(mapStateToProps,{getPackageDetails,login})(withRouter(SidemenuEvents));
//export default connect(mapStateToProps,{getPackageDetails,login})(withRouter(SidemenuEvents));
export default (withRouter(SidemenuEvents));
