import { GET_SUB_INDUSTRY_DATA, GET_ERRORS } from './types';
import Axios from 'axios';
import Helper from "../shared/custom";

export const getSubIndustriesData = () => dispatch => {
    let industryIds = "";
    Axios.get(
        Helper.apiURL({
            uri: 'api/gics/sub-industries',
            query: { 'industry-ids': industryIds }

        })).then((response) => {
            const data = response.data;
            let subIndustriesObj = {};
            var subIndustries = {};
            var subIndustriesArray = [];

            data.forEach((item, index) => {
                item = {
                    id: item.id,
                    name: item.name
                }
                subIndustries[item.id] = item;
                subIndustriesArray.push(item);
            });

            subIndustriesObj.subIndustries = subIndustries;
            subIndustriesObj.subIndustriesArray = subIndustriesArray;

            dispatch({
                type: GET_SUB_INDUSTRY_DATA,
                payload: subIndustriesObj,
            })

        })
        .catch((err) => {
            dispatch({
                type: GET_ERRORS,
                payload: err
            })
        });
}