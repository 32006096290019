import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import store from '../../store';
import Helpers from '../../shared/custom';
import moment from "moment";
import { fundingRound } from '../../actions/fundingRoundAction';
import NotUser from './NotUser';
import Axios from 'axios';
import STR from '../../shared/strings';
import { MDBDataTable } from 'mdbreact';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';


const $ = require('jquery');
$.DataTable = require('datatables.net');

class Funding extends Component {

   
    constructor(props) {
        super(props);
        this.state = {
            uri: undefined,
            fundingRound: [],
            total: 0,
            usedCurrency: "",
            company: []
        }

        store.subscribe(() => {
            var fundRound = store.getState();
            let company = fundRound.entity && fundRound.entity.company && fundRound.entity.company.entityInfo ? fundRound.entity.company.entityInfo.company : []
            fundRound = fundRound.fundRound.data || null;

            this.setState({
                fundingRound: fundRound.fundingRounds,
                company: company
            }, () => {

            });

            var total = 0;
            //let usedCurrency = this.state.fundingRound && this.state.fundingRound[0] && this.state.fundingRound[0].currency.shortName;
            //let unifiedCurrency = true;
            let usedCurrency = "USD";
            if (!Helpers.isEmpty(this.state.fundingRound)) {
                this.state.fundingRound.forEach((data, index) => {
                    total += data.amountRaisedInSelectedCurrency ? data.amountRaisedInSelectedCurrency : 0;
                    //if (usedCurrency != data.currency.shortName){
                    //unifiedCurrency = false;
                    //}
                });
            }


            // if (unifiedCurrency !== true){
            //     total = 0;
            // }

            this.setState({
                total: total,
                usedCurrency: usedCurrency
            })


        });

        this.fetchFundingRounds = this.fetchFundingRounds.bind(this);
        this.populateTableData = this.populateTableData.bind(this);

    }

    // componentWillUnmount(){
    //     $('.data-table-wrapper')
    //     .find('table')
    //     .DataTable()
    //     .destroy(true);
    // }

    componentWillReceiveProps(nextProps) {
        let currentUri = this.state.uri;
        let newUri = nextProps.entityUri;
        if (currentUri && currentUri == newUri) {
            return false;
        }

        if (!nextProps.showContent) {
            return false;
        }

        this.setState({
            uri: newUri,
            fundingRound: [],
            total: 0,
        }, () => {

            this.fetchFundingRounds();
        })

    }

    fetchFundingRounds() {
        let uri = this.state.uri;
        let type = "company";

        Axios.get(
            Helpers.apiURL({
                uri: "funding-round/:uri",
                pathVar: {"uri": uri},
                query: {
                    "type": type
                }
            })
        )
            .then((response) => {
                let frData = response.data.result || {};
                this.setState({
                    fundingRound: frData.fundingRounds
                }, function () {
                    this.populateTableData();

                })
            })
            .catch((err) => {
                this.setState({
                    fundingRound: []
                })
            });
    }

    populateTableData = () => {

        let dataset = this.state.fundingRound;
        let array = {
            columns: [
                {
                    label: 'Announced Date',
                    field: 'date',
                    sort: 'asc',
                },
                //   {
                //     label: 'Transaction Name',
                //     field: 'name',
                //     sort: 'asc',
                //   },
                {
                    label: 'Funding Type',
                    field: 'fundingType',
                    sort: 'asc',
                },
                {
                    label: 'No. Of Investors',
                    field: 'numOfInvestors',
                    sort: 'asc',
                },
                {
                    label: 'Funding Stage',
                    field: 'fundingStage',
                    sort: 'asc',
                },
                {
                    label: 'Pre Money Valuation',
                    field: 'preMoneyValuation',
                    sort: 'asc'
                },
                {
                    label: 'Money Raised',
                    field: 'amountRaised',//respective currency
                    sort: 'asc',
                },
                {
                    label: 'Equity Only',
                    field: 'isEquityOnlyFunding',
                },
                {
                    label: 'Lead Investors',
                    field: 'leadInvestor',
                },
            ],
            rows: [],
        };
       
        dataset.forEach((data) => {
            let date = "";
            // let name = "";
            let fundingType = "";
            let numOfInvestors = "";
            let amountRaised = "";
            let fundingStage = "";
            let leadInvestor = [];
            let preMoneyValuation = "";
            let isEquityOnlyFunding = "";
            var dateVal = moment(data.date).format('DD-MMM-YYYY');
            if (!Helpers.isEmpty(data.date)) {

                date = dateVal
            }
            // if(!Helpers.isEmpty(data.name)){
            //     name = data.name
            // }
            if (!Helpers.isEmpty(data.numOfInvestors)) {
                numOfInvestors = data.numOfInvestors
            }
            if (!Helpers.isEmpty(data.fundingStage)) {
                fundingStage = data.fundingStage
            }
            if (!Helpers.isEmpty(data.fundingType)) {
                fundingType = data.fundingType
            }
            if (!Helpers.isEmpty(data.amountRaised && data.currency)) {
                amountRaised = Helpers.displayAmount({amount: data.amountRaised.toFixed(0), currency: data.currency});
            } else {
                amountRaised = "Undisclosed"
            }
            if (!Helpers.isEmpty(data.leadInvestors)) {
                data.leadInvestors.forEach((inv, index) => {
                    let link = "/" + inv.type + "/" + inv.uri;
                    let structuredLink = <Link to={link}> {((index == 0) ? "" : ", ") + inv.name} </Link>;
                    if (inv.uri && inv.type) {
                        leadInvestor.push(structuredLink)
                    } else {
                        leadInvestor.push(<span>{inv.name(index == 0) ? "" : ", "}</span>)
                    }

                })
            }
            if (!Helpers.isEmpty(data.preMoneyValuation)) {
                preMoneyValuation = Helpers.displayAmount({amount: data.preMoneyValuation, currency: data.currency});
            } else {
                preMoneyValuation = "Undisclosed"
            }
            if (!Helpers.isEmpty(data.isEquityOnlyFunding)) {
                isEquityOnlyFunding = data.isEquityOnlyFunding ? 'Yes' : 'No';
            }
            array.rows.push({
                date: date,
                // name:name,
                fundingType: data && data.fundingType && data.fundingType.name ? data.fundingType.name : "",
                numOfInvestors: numOfInvestors,
                fundingStage: data && data.fundingStage && data.fundingStage.name ? data.fundingStage.name : '',
                preMoneyValuation: preMoneyValuation,
                amountRaised: amountRaised,
                isEquityOnlyFunding: isEquityOnlyFunding,
                leadInvestor: leadInvestor
            });
        })
        this.setState({
            tableData: array
        })
    }

    handleSort = (sort) => {
        if (sort.column == "date") {
            var sortedRows = [...this.state.tableData.rows];
            sortedRows = sortedRows.sort(Helpers.dateCompareFun);
            if (sort.direction == "desc") {
                sortedRows = sortedRows.reverse();
            }
            this.setState({
                tableData: {
                    ...this.state.tableData,
                    rows: sortedRows
                }
            })
        }
    }

    render() {
        let showContent = this.props.showContent;
        let pageViewsLeft = this.props.pageViewsLeft;
        let fundingRound = this.state.fundingRound;
        let tableData = this.state.tableData;

        let company = this.state.company;
        if (!company) {
            return (<div></div>)
        }
        let chartData = {
            data: {}
        };
        let fundingPerRoundOptions = null;
        if (fundingRound && fundingRound.length != 0) {
            let fundingRoundMap = new Map();
            
            let newFundingGroup = [];
            fundingRound.forEach(x=>{
                let res =  x.fundingType ? newFundingGroup.filter(nfg => nfg.fundingType.id == x.fundingType.id ) : [];
                if(res.length === 0){
                 newFundingGroup.push(x);
                }else{
                 let index = newFundingGroup.findIndex(nfg => nfg.fundingType && nfg.fundingType.id == x.fundingType.id);
                 let currentElement =  Object.assign({} , newFundingGroup[index]);
                 currentElement["amountRaised"] = ((currentElement.amountRaised||0)+(x.amountRaised||0));
                 currentElement["numOfInvestors"] = ((currentElement.numOfInvestors||0)+(x.numOfInvestors||0));
                newFundingGroup[index] = currentElement;     
            }
            });
            fundingRound = newFundingGroup;
            fundingRound.forEach(x=>fundingRoundMap.set(x.fundingRoundId, x));
            fundingPerRoundOptions = {
                title: {
                    text: 'Funding per Round',
                    align: 'left',
                    style: {
                        color:'#2a2f45',
                        fontWeight: '500',
                        fontSize: '12px'
                    }
                },
                tooltip: {
                    pointFormatter: function (){
                        return `Number of Investors: ${fundingRoundMap.get(this.id).numOfInvestors}<br/>Date: ${fundingRoundMap.get(this.id).date}<br/>Amount: $ ${(this.y).toLocaleString()}`

                    },
                },
                categories:fundingRound.filter(x=>x.fundingType && x.amountRaised).map(x=>x.fundingType.name),
                series: [{
                    data:fundingRound.filter(x=>x.fundingType && x.amountRaised).map(x => {return {y:x.amountRaised, id:x.fundingRoundId}}),
                    dataLabels: {
                        enabled: true,
                        formatter: function (point) {
                            return  Helpers.displayAmount({
                                 amount: this.y ,
                                 intlFormat: this.series.dataMax >= 1000000 ? true : false,
                                 currency:{shortName:`$ `}
                            })
                        }
                    },
                }],
                chart: {
                    type: 'column',
                    width: 500,
                    height: 300
                },
                plotOptions: {
                    series: {
                        color: '#4D7AC6'
                    },
                    dataLabels: {
                        enabled: true
                    }
                },
                yAxis: {
                    visible:false
                },
                xAxis: {
                    categories:fundingRound.filter(x=>x.fundingType && x.amountRaised).map(x=>x.fundingType.name),
                },
                legend: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
            };
        }

        let fundingPerYearOptions = null;
        if (fundingRound && fundingRound.length != 0) {
            let years = new Map();
            let noOfInvestment = new Map();
            fundingRound.forEach(x=>{
                let year = moment(x.date).format("YYYY");
                noOfInvestment.set(year, (noOfInvestment.get(year) || 0) + 1);
                years.set(year,(years.get(year) || 0) + x.amountRaised)
            });
            fundingPerYearOptions = {
                title: {
                    text: 'Funding per Year',
                    align: 'left',
                    style: {
                        color:'#2a2f45',
                        fontWeight: '500',
                        fontSize: '12px'
                    }
                },
                categories:Array.from(years.keys()).reverse(),
                series: [{
                    data:Array.from(years.values()).reverse(),
                   
                    dataLabels: {
                        enabled: true,
                        formatter: function (point) {
                            return  Helpers.displayAmount({
                                amount:this.y,
                                intlFormat: this.series.dataMax >= 1000000 ? true : false,
                                currency:{shortName:"$"}
                            })
                        }
                    },
                },
                {
                    data:Array.from(years.values()).reverse(),
                    type: 'line',
                    color: "#FFCD00"
                },
                ],
                chart: {
                    type: 'column',
                    width: 500,
                    height: 300
                },
                plotOptions: {
                    series: {
                        color: '#4D7AC6'
                    },
                    dataLabels: {
                        enabled: true
                    }
                },
                yAxis: {
                    visible:false
                },
                xAxis: {
                    categories:Array.from(years.keys()).reverse(),

                },
                legend: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                tooltip: {
                    pointFormatter: function (){
                        return `Number of Investment: ${noOfInvestment.get(this.category)}<br/>Amount: $ ${(this.y).toLocaleString()}`
                    },
                },
            };
        }

        let redirectToFundingDetails = ()=>{this.props.history.push(`/company-funding-details/${this.state.uri}`)};
        return (
          <>
          {(fundingPerYearOptions || fundingPerRoundOptions || this.state.total || (company && ((company.lastFundingType && company.lastFundingType.name) || (company.fundingStatus && company.fundingStatus.name))))  &&
          <div>
                {tableData && !Helpers.isEmpty(tableData.rows) ?
                    <div className="card text-left bold-headers" id={this.props.id}>
                        {showContent && pageViewsLeft > 0 ?
                            <div>
                                <div className="card-header text-left p-0">
                                    <div className="col-md-6 float-left light-blue-header-half header-div">
                                        Funding
                                    </div>
                                    {tableData && !Helpers.isEmpty(tableData.rows) ?
                                        <div className="col-md-6 float-left text-right">
                                            <span className="font-12">Number of Funding Rounds {!Helpers.isEmpty(fundingRound) ? fundingRound.length : 0}</span>
                                        </div>
                                        :
                                        ""}
                                </div>

                                <div className="card-body blue-border clearfix">
                                    {tableData && !Helpers.isEmpty(tableData.rows) ?
                                        <div className="row">
                                            {
                                                (company.fundingStatus && company.fundingStatus.name) ?
                                                    <div className="col-md-6 float-left ">
                                                        <div className="col-md-12 float-left grey-border round-border p-2">
                                                            <div className="col-md-12 float-left topLabel font-12">Funding
                                                                                                                   Status
                                                            </div>
                                                            <div className="col-md-12 float-right font-12 text-right">{company.fundingStatus.name}</div>

                                                        </div>
                                                    </div>
                                                    : ""

                                            }
                                            {
                                                (company.lastFundingType && company.lastFundingType.name) ?
                                                    <div className="col-md-6 float-left m-0">
                                                        <div className="col-md-12 float-left grey-border round-border p-2">
                                                            <div className="col-md-12 float-left topLabel font-12">Last
                                                                                                                   Funding
                                                                                                                   Type
                                                            </div>
                                                            <div className="col-md-12 float-right font-12 text-right">{company.lastFundingType.name}</div>

                                                        </div>
                                                    </div>
                                                    : ""

                                            }

                                        </div>
                                        :
                                        ""
                                    }
                                    {tableData && !Helpers.isEmpty(tableData.rows) ?
                                        <div className="row m-t-10">
                                            {
                                                (this.state.total > 0) ?
                                                    <div className="col-md-12 float-left ">
                                                        <div className="col-md-12 float-left grey-border round-border p-2">
                                                            <div className="col-md-12 float-left topLabel font-12">Total
                                                                                                                   Funding
                                                            </div>
                                                            <div className="col-md-4 float-right font-12 text-right">{this.state.usedCurrency + " " + Helpers.displayAmount({amount: Math.round(this.state.total)})}</div>

                                                        </div>
                                                    </div>
                                                    : ""

                                            }
                                        </div>
                                        :
                                        ""
                                    }
                                    {fundingPerRoundOptions ?
                                        <div className="row">
                                            <div className="col-md-12 p-1 m-t-10" onClick={redirectToFundingDetails}>
                                                <HighchartsReact
                                                    highcharts={Highcharts}
                                                    options={fundingPerRoundOptions}
                                                />
                                            </div>
                                        </div>
                                        : ''}
                                    {fundingPerYearOptions ?
                                        <div className="row">
                                            <div className="col-md-12 p-1">
                                                <hr className="greyLine"/>
                                            </div>
                                            <div className="col-md-12 p-1 m-t-10" onClick={redirectToFundingDetails}>
                                                <HighchartsReact
                                                    highcharts={Highcharts}
                                                    options={fundingPerYearOptions}
                                                />
                                            </div>
                                        </div>
                                        : ''}
                                    {/*<div className="table-responsive">*/}
                                    {/*    {tableData && !Helpers.isEmpty(tableData.rows) ?*/}
                                    {/*        <MDBDataTable*/}
                                    {/*            className="muted-table"*/}
                                    {/*            searching={false}*/}
                                    {/*            data={tableData}*/}
                                    {/*            onSort={this.handleSort}*/}
                                    {/*        />*/}
                                    {/*        :*/}
                                    {/*        <div className="table-responsive text-center m-t-10">*/}
                                    {/*            No Data Available*/}
                                    {/*        </div>*/}

                                    {/*    }*/}

                                    {/*</div>*/}
                                </div>
                            </div>
                            :
                            <NotUser title="Funding"
                                     showContent={this.props.showContent}
                                     pageViewsLeft={this.props.pageViewsLeft} />
                        }
                    </div>
                    :
                    ""}
            </div>
    }
    </>
        )
    }
}


export default connect(null, {fundingRound})(withRouter(Funding));


