import React, { Component } from 'react'
import UserFeedRightPanel from '../../homepage/UserFeedRightPanel';
import Helpers from "../../../shared/custom";
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import Axios from "axios";
import OppCard from  "../Summary/OppCard";
import MyPagination from "../../ui/MyPagination";

export class InterestedOpp extends Component {
    constructor(props){
        super(props);
        this.state = {
            keyName:"title",
            valueName:null,
            data:{},
            enums:[],
            activePage:1,
            totalRecords:0
        }

    }  

    componentDidMount(){
        this.fetchEnum();
        this.fetchSummaryData()
        setTimeout(() => {
            window.scrollTo(0, 0);
          });
    }


    fetchEnum = ()=>{
        
        Axios.get(
            Helpers.apiURL({
                uri:`api/enum/list`,
            })
        )
        .then( (response) => {
            let data  = response.data.result || [];  
            this.setState({
                enums:data
            })
        })
    }
    handlePageChange = (pageNumber)=> {
        this.setState({
            activePage:pageNumber
        },()=>{
            this.fetchSummaryData()
        })
    }

    fetchSummaryData = ()=>{
        var obj = {};
        obj[this.state.keyName] =  this.state.valueName;
        obj.page = this.state.activePage;
        obj.size = 9;
       
        obj.includeRating = true;
        obj.interested = true;
           
           this.setState({loading:true})
            Axios.get(
                Helpers.apiURL({
                    uri:"api/opportunity/search",
                    query:obj
                })
                )
                .then((response) => {
                     let result = response.data.content;
                     this.setState({
                        data:result,
                        totalRecords:response.data.totalElements,
                        loading:false
                     })
                })
                .catch((response) => {
                    this.setState({
                        loading:false
                     })
                })
 

    }

    filterData = (e)=>{
        this.setState({
            keyName:e.target.value,
        })
    }

    onchangeFilter = (e)=>{
        this.setState({
            valueName:e.target.value,
            activePage:1,
            totalRecords:0
        })
    }

    doSearch = ()=>{
        this.fetchSummaryData()
    }
    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.fetchSummaryData()
        }
      }

    render() {
        let {uri,loading,enums,data} = this.state;
        console.log(data)
        return (
            <div>
                 <div className="container-fluid">
                  <div className="row content-in m-t-80 p-t-10">
                    <div className="col-lg-9 col-xlg-9 col-md-9 centerDiv">
                            <div className="col-md-12">
                                <LoadingOverlay>
                                <Loader fullPage loading={loading} />
                                </LoadingOverlay>
                                <div className="card text-left b-header">
                                        <div className="card-header text-left p-0">
                                            <div className="col-md-8 float-left light-blue-header-half header-div">Interested Opportunities</div>
                                            <div className="col-md-4 float-left text-right">
                                            </div>
                                        </div>
                                        {/* <div className="card-header text-left p-0">
                                            <div className="col-md-12 float-left light-blue-header text-white header-div">Search Results for:</div>
                                        </div> */}
                                        <div className="card-body p-t-10 p-b-5 bg-white filterBody">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                         <select className="form-control group-fields filterSelect m-l-5 font-12" onChange={this.filterData}>
                                                            {
                                                                enums && enums.OppSearchFields &&  enums.OppSearchFields.map((list,i)=>(
                                                                <option value={list.value} selected={this.state.keyName == list.value}>{list.key}</option>
                                                                ))
                                                            }
                                                        </select>
                                                </div>
                                                <>
                                                <input placeholder="Search for…" type="text" class="form-control searchBar" onKeyDown={this._handleKeyDown} onChange={this.onchangeFilter} defaultValue={this.state.valueName}/>
                                                <i class="fa fa-search searchIcon anchor" onClick={this.doSearch}></i>
                                                </>
                                         </div>
                                         {/* <div className="row clearfix m-t-20">
                                            <div className="col-md-12">
                                                    <button className="btn btn-theme float-right text-white btn-normal font-16" onClick={this.doSearch}>Search</button>
                                                </div>
                                         </div> */}
                    
                                        </div>

                                       
                                </div>
                                    <div className="card">
                                       <div className="card-body">
                                             <div className="col-md-12 p-0">
                                                {
                                                    !Helpers.isEmpty(data) ? data.map((list,i)=>(
                                                            <OppCard data={list} className="ntg" mainClass="col-md-4 p-1 float-left"/>
                                                    ))
                                                    :
                                                    <div className="text-center">No data available</div>
                                                }
                                            </div>
                                        
                                            {this.state.totalRecords > 9 &&<div className="col-md-12 p-0 clearfix"> <MyPagination
                                                            activePage={this.state.activePage}
                                                            totalRecords={this.state.totalRecords}
                                                            onChange={this.handlePageChange}
                                                        /></div>}
                                                
                                        </div>
                                </div>
                            </div>
                    </div>
                    <div className="col-lg-3 col-xlg-3 col-md-3 m-t-2 right-side">
                        <UserFeedRightPanel />
                    </div>
                </div>
               </div>
            </div>
        )
    }
}

export default InterestedOpp
