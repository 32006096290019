

import React, { useState, useEffect } from 'react';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import config from '../../shared/config';
import Helpers from '../../shared/custom';
import Axios from 'axios';

const jsonToQueryString = obj => {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

const CommonSearch = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleSearch = (query) => {
    setIsLoading(true);
    var payload = {
        query:query
    }



    if(!Helpers.isEmpty(props.extraQuery)){
        props.extraQuery.forEach((item,i)=>{
           payload[item.key] = item.value;
       })
    }else{
       payload.all = 1
    }

    payload = jsonToQueryString(payload);

    let endPoint = props.endPoint ? props.endPoint:"title";
    let url = config.api(endPoint)
    const SEARCH_URI = `${url}?${payload}`;

    Axios.get(
        SEARCH_URI
      )
      .then((response) => {
         let objects = response.data.result;

         const options = objects.map((obj) => ({
            name: obj.name,
            shortName: obj.shortName || obj.description,
            value:obj.id
          }));
        
          setOptions(options);
          setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      })

    
  };

  const _onChange = (e) =>{
      props.onChange(e);
  }


  const filterBy = () => true;

  return (
    <AsyncTypeahead
      filterBy={filterBy}
     // id="async-example"
      allowNew
      newSelectionPrefix = "Add a new item: "
      //multiple={}
      isLoading={isLoading}
      labelKey="name"
      minLength={1}
      onSearch={handleSearch}
      options={options}
      onChange={_onChange}
      selected={props.defaultSelected || []}
      placeholder="Type to search"
      renderMenuItemChildren={(option, props) => (
        <React.Fragment>
             <div key={"obj-"+option.value} user={option} >
                  <div>
                      <h6 className="font-bold  m-t-5">{option.name} 
                      </h6> 
                  </div>
              </div>
        </React.Fragment>
      )}
    />
  );
};

export default CommonSearch;