import React from 'react';
import { Link } from 'react-router-dom';
import Helpers from '../../shared/custom';
import StartRating from '../ui/StarRating';
import Follow from '../uiActions/Follow';
import Interest from '../uiActions/Interest';
import ForceUpgrade from './ForceUpgrade';


export function EntityBlock(props) {
  let result = props.result;
  let index = props.index;
  let showPeople = props.showPeople;

  return (
    <div className="card card-body" key={"ad-s-" + index}>
      {result.type == "people"  && !showPeople ? 
       <ForceUpgrade/>
      :
      <div className="row">
      <Link to={"/" + result.type + "/" + (result.type == "OPPORTUNITY" ? result.id : result.uri)} >
        <img
          src={
            result.image
              ? Helpers.dataPath(result.image)
              : Helpers.dataPath(Helpers.getDefaultImage(result.type))
          }
          className="img-circle m-l-10"
          width="48"
          height="48"
        />
      </Link>
      <div className="col-md-10 col-lg-10 col-sm-10 text-left">
        <h3 className="box-title m-b-0 text-info font-14 m-t-0 l-h-18">
          <Link to={"/" + result.type + "/" + (result.type == "OPPORTUNITY" ? result.id : result.uri)}> 
            {result.title} {result.type == "OPPORTUNITY"  ? result && result.oppType ? <i className="font-12 text-black">- {result.oppType}</i>  : "" : "" }
          </Link>
          {result.type == "Event" ?
            <div className="float-right col-md-5 col-sm-12 p-r-0">
              <Interest id={result.id} type={result.type} interested={result._isIntrested} masterClassName="float-right  m-l-5 font-12" />
            </div>
            :
            ""
          }
          {result.type == "company" || result.type == "people" ?
            <div className="float-right col-md-5 col-sm-12 p-r-0">
              <Follow uri={result.uri ? result.uri : ""} masterClassName={"like-comm btn-lil text-left float-right m-l-5"} type={"COMPANY"} />

              {result.type == "company" ?
                <Link to={"/graph?uri=" + result.uri + "&type=" + result.type} title="Knowladge Graph" className="float-right m-l-5 m-t-2">
                  <i className="material-icons font-24"> scatter_plot</i>
                </Link>
                :
                ""
              }
              {Helpers.isEmpty(result.website) ?
                "" :
                <a rel="noreferrer" href={Helpers.properExternalUrl(result.website)} target="_blank" className="float-right m-l-5 m-t-2"><i className="material-icons font-24">language</i></a>

              }

            </div>
            :
            ""
          }

        </h3>
        {result.type == "Event" ? (
          <div className="text-grey font-10 m-t-5">
            <div>
              <span> <i className="material-icons  font-10" title="location">place</i> {result && result.location ? result.location : ""}    &nbsp;&nbsp; &nbsp;|    &nbsp;&nbsp; &nbsp; {result.industry}</span>
            </div>
            <div className="m-t-5">
              {result && result.startTime
                ? <span><i className="mdi mdi-calendar font-16 vm"></i> {Helpers.getDateAndTimeInDisplayFormat(result.startTime)}</span>
                : ""}
            </div>
          </div>
        ) : (
            ""
          )}

        {result.type == "opportunity" ? (
          <div className="text-grey font-10 m-t-5">
            <div>
              <span> <i className="material-icons  font-10" title="location">place</i> {result && result.subTitle ? result.subTitle : ""}    &nbsp;&nbsp; &nbsp;|    &nbsp;&nbsp; &nbsp;  </span>
              {result && result.investmentDeadline
                ? <span><i className="mdi mdi-calendar font-16 vm"></i> {Helpers.getDateInInputFormat(result.investmentDeadline)}</span>
                : ""}
            </div>
          </div>
        ) : (
            ""
          )}
        {/* {result.type == "Company" ? (
          <div className="text-grey font-10 m-t-5">
            <div>
              <span> <i className="material-icons  font-16" title="amount">attach_money</i> {result && result.amountRaised ? result.amountRaised : null}    &nbsp;&nbsp; &nbsp;|    &nbsp;&nbsp; &nbsp;  </span>
              {result && result.fundingStage ?
                // ? <span><i className="mdi mdi-calendar font-16 vm"></i> {result.fundingStage}</span>
                (result.fundingStage)
                : ""}
            </div>
          </div>
        ) : (
            ""
          )} */}

        <div className="text-black font-14">
          {result.primaryTitleDesignation && result.primaryTitleDesignation ? result.primaryTitleDesignation + " at " : ""}
          {result.primaryTitleDesignation && result.primaryTitleDesignation ? (
            <Link to={"/company/" + result.primaryTitleCompanyUri} >
              {result.primaryTitleCompanyName}
            </Link>
          ) : result.type == "Event" || result.type == "opportunity" ? (
            ""
          ) : (
                result.subTitle
              )}


          {
            result.type == "company" || result.type == "startup" ?
              <StartRating pointerStyle={props.pointerStyle} rating={!Helpers.isEmpty(result.ranking) ? result.ranking : 0} />
              :
              ""
          }

        </div>
        {result.type == "Event" ?
          ""
          :
          <div className="text-grey font-14">{result.industry}</div>
        }
        {result.description ? (
          <div className="m-b-10">
            <small className="paragraph-with-margin-top">
              {Helpers.truncate(Helpers.getCleanText(result.description), 200)}
            </small>
          </div>
        ) : (
            ""
          )}
      </div>
      {/* <div className="col-md-2 col-lg-2 col-sm-12 p-0">
        <div className="button-box m-t-10 float-right">
          <Link to={"/" + result.type + "/" + result.uri}>
            <button className="btn btn-theme-white btn-wide">View</button>
          </Link>
        </div>
      </div> */}
    </div>

      }
     
    </div>
  );
}
