import React, { Component } from 'react'
import Helper from "../../shared/custom";
import config from "../../shared/config";
import entityReducer from '../../reducers/entityReducer';
import SearchBannerCard from "./SearchBannerCard";
import SearchBannerBox from "./SearchBannerBox";
import { withRouter , Link } from 'react-router-dom';


export default class SearchBanner extends Component {
  render() {
    let entry = this.props.entry;
    let company = this.props.company;
  

    let type = this.props.type;
    let title = null;
    let subTitle = null;
    let url = "#";
    let view = "all";
    let description = entry.bannerDescription;
    let designation = "";

    if(type == "all"){        
        if(entry.primaryPageType){
            type = entry.primaryPageType?(entry.primaryPageType.type.type+"").toLowerCase():"all";   
        }else{
            type = "event";//anything without page considered as event
        }
        view = "all";
    }else{
        
        view = "tab";
        let entrypageTypes = []
        if(!Helper.isEmpty(entry.pageTypes)){
            entry.pageTypes.forEach(pageType => {
                entrypageTypes.push((pageType.type.type+"").toLowerCase())
                let subType = pageType.subType ? pageType.subType : null;
                if(subType){
                    entrypageTypes.push((subType.type+"").toLowerCase());
                }
            });            
        }else{
            entrypageTypes = ["event"];//anything without page type considered as event
        }
        
        if(entrypageTypes.indexOf(type) == -1){
            return (<div></div>);
        }
    }

   
    let titles = [];
    let card = {};
    card.name = entry.name;    
    card.description = null;
    card.image = entry.image;
    if(entry.image == "logos/null"){
        card.image = undefined;
    }    
   
    switch(type){
       
        case 'company':
            card.image = entry.image || config.defaultCompanyImage;
            card.title = entry.banner.company || null;
            card.subTitle = entry.subBanner ? entry.subBanner.company : null;
            card.url = Helper.pageURL("company",entry.uri);
            card.description = description && description.company ? description.company : null;
            card.ranking = company && company.rankingDetails && company.rankingDetails.ranking ? company.rankingDetails.ranking : 0;
            card.star = true;
            return(<SearchBannerCard card={card} type={type} />);

        case 'people':
           
            card.image = entry.image || config.defaultUserImage;
            // card.title = entry.banner.people || null;
            // card.companyUri = entry.banner && entry.banner.uri ?  Helper.pageURL("company",entry.banner.uri) : null;
            //card.subTitle = entry.banner ? entry.banner.primary : null;
            card.designation = entry.banner.designation;
            var compHtml = <span>{entry.banner.designation} at <Link to={entry.banner && entry.banner.uri ?  Helper.pageURL("company",entry.banner.uri) : ""}>{entry.banner.company}</Link></span>;
            card.title = compHtml;
            card.url = Helper.pageURL("people",entry.uri);
            card.description = description && description.people ? description.people : null;
            return(<SearchBannerCard card={card} type={type} />);
        
        case 'startup':
            card.image = entry.image || config.defaultCompanyImage;
            card.title = entry.banner.company || null;
            card.subTitle = entry.subBanner ? entry.subBanner.company : null;
            card.url = Helper.pageURL("company",entry.uri);
            card.ranking = company && company.rankingDetails && company.rankingDetails.ranking ? company.rankingDetails.ranking : 0;
            card.star = true;
            return(<SearchBannerCard card={card}  type={type} />);

        case 'opportunity':
            titles = [];
            card.title = "";
            card.subTitle = null;            
            if(entry.country || card.city){
                card.title = entry.country?titles.push(entry.country.name):"";
                card.title = entry.city?titles.push(entry.city.name):"";
                card.title = titles.join(", ");
            }
            card.url = Helper.pageURL("opportunity",entry.uri);
            let descriptions = entry.bannerDescription ? entry.bannerDescription : {};
            card.description = descriptions["opportunity"] ? descriptions["opportunity"] : "";
            card.image = entry.image;
            if(!card.image){
                card.image = view == "all" ? config.defaultOpportunityIcon : config.defaultOpportunityCover;
            }
            return(
                true || view == "all"?
                    <SearchBannerCard card={card} type={type} />
                :                    
                    <SearchBannerBox card={card} />
            );            
        case 'investor':

         
            card.title = entry.banner?entry.banner.primary: null;
            card.subTitle = entry.subBanner ? entry.subBanner.primary : null;
            description = entry && entry.bannerDescription ? entry.bannerDescription : null;
            if (entry.pageTypes && entry.pageTypes[0] && entry.pageTypes[0].type && entry.pageTypes[0].type.type == "COMPANY"){
                card.url = Helper.pageURL("company",entry.uri);
                card.image = entry.image || config.defaultCompanyImage;
                card.description = description && description.company ? description.company : "";
            }else{
                card.url = Helper.pageURL("people",entry.uri);
                card.image = entry.image || config.defaultUserImage;
                card.description = description && description.people ? description.people : "";
            }
      
         

            return(<SearchBannerCard card={card} type={type} />);
        case 'event':
            titles = [];
            card.title = "";
            card.subTitle = null;
            
            if(entry.startDate){
                if(entry.country || card.city){
                    card.title = entry.country?titles.push(entry.country.name):"";
                    card.title = entry.city?titles.push(entry.city.name):"";
                    card.title = titles.join(", ");
                }
                card.title += Helper.getDateAndTimeInDisplayFormat(entry.startDate);
            }else{
                card.title = entry.name;
            }
            card.url = "event/"+entry.uri;
            card.description = entry.summary ? entry.summary : "";
            card.image = config.defaultEventIcon;
            if(entry.coverPhoto){
                card.image = entry.coverPhoto.publicUri ? entry.coverPhoto.publicUri : Helper.getDefaultImage(type)
            }else{
                
            }
          
            return(
                true || view == "all"?
                    <SearchBannerCard card={card} type={type} />
                :   
                    <SearchBannerBox card={card} />
            );

        default:
            card.title = entry.banner?entry.banner.primary: null;
            card.subTitle = entry.subBanner ? entry.subBanner.primary : null;
            card.description = entry.summary ? entry.summary : "";
            card.url = "#";
            card.image = entry.image || config.defaultCompanyImage;
            return(<SearchBannerCard card={card} type={type} />);
        
    }

  }
}