import React, { Component } from 'react';
import Helper from "../../shared/custom";
import config from '../../shared/config';
import CompanyCard from './CompanyCard';

export default class AllCompaniesPublic extends Component {

  constructor(){
    super();
    this.state ={
      companies:[],
    }
    this.handleLogin = this.handleLogin.bind(this);
  }

  handleLogin(){
    Helper.pushError("Please Login or Register with us to follow a company")
  }

  componentWillReceiveProps(props){
    this.setState({
      companies:props.followingCompanies
    },
    function(){
    })
  }
  render() {
    let companies = this.state.companies;

    return (
      <div>
        <div>
        
        <div className="card text-left bold-headers">
          <div className="card-body">
              <h5><b>Companies</b></h5>
              <div className="col-md-12 row">
                { companies.map((list,index)=>(
                    <div className="col-md-6 m-t-10 float-left" key={index}>
                      <div className="col-md-12 border p-10">
                        <CompanyCard data ={list} key={"PC"+index}/>
                     </div>
                    </div>  
                ))
                }
                </div>
            </div>
        </div>
      </div>
      </div>
    )
  }
}
