import Axios from 'axios';
import config from '../shared/config';
import { GET_ERRORS , REGISTER_NEW_USER ,REGISTER_AUTHENTICATED , SET_TOKEN}  from './types';
import setAuthTokenHeader from '../utils/setAuthTokenHeader';
import Helpers from '../shared/custom';
import { fetchUserInfo } from './authAction';



export const register = (regData, history) => dispatch => {
    Axios.post(
        config.api("user/register"),
        regData)
    .then( (response) => {
            
            //check status here
            // written to handle new scenario issue
            // need to re-write
            if(response.data && response.data.status != 200){
                dispatch({
                    type: GET_ERRORS,
                    payload: response
                })
            }else{
                const token  = response.data.result.jwtToken; 
                if(token){
                    setAuthTokenHeader(token);
                    dispatch(setToken(token));
                    fetchUserInfo();
                    //dispatch(getUserData(response)); 
                    // dispatch({
                    //     type: GET_ERRORS,
                    //     payload: null
                    // });
                }else{
                   // history.push("/");
                    window.location.replace("/")
                    Helpers.pushInfo("Thank you for registering. Please check your email for more details.");

                }              
                
            }                   
    })
    .catch((err) => {
        dispatch({
            type: GET_ERRORS,
            payload: err.response
        })
    });

}

export const registerAuthenticated = () => dispatch => {
    dispatch({
        type : REGISTER_AUTHENTICATED,
        payload : null
    });
}


export const getUserData = (data) =>{
    return {
        type : REGISTER_NEW_USER,
        payload : data
    }
   
}

export const setToken = (token) =>{
    return {
        type : SET_TOKEN,
        payload : token
    }
}