import React, { Component , useEffect, useState , useRef } from 'react';


import config from '../../shared/config';
import Helpers from '../../shared/custom';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import Axios from "axios";

import EditText from "../ui/EditText";
import EditSelect from "../ui/EditSelect";
import EditTextArea from "../ui/EditTextArea";
import ApiHelper from "../../shared/ApiHelper";

import countries from "../../shared/country";
import MetaEdit from '../ui/MetaEdit';
import EditDate from "../ui/EditDate";
import Mobile from "../ui/Mobile";
import Upload from './Upload';
import Videos from './Videos';
import DeletePage from '../wigets/DeletePage';
import { withRouter } from "react-router-dom";
import { getPackageDetails } from '../../actions/packageAction';
import { connect } from 'react-redux';

function Form(props) {

    const [loading, setLoading] = useState(() => false);
    const [errors, setErrors] = useState(() => {});
    const [typeOption, setTypeOption] = useState(() => []);
    const [countryOptions, setCountryOptions] = useState(() => []);
    const [cityList, setCityList] = useState(() => []);
    const [cityOptions, setCityOptions] = useState(() => []);
    const [state, setState] = useState({});
    const [isEditable, setIsEditable] = useState(true);
    const [featured, setFeatured] = React.useState(false);
    const [oppId, setOppId] = React.useState(null);
    const [data, setData] = useState({})
    const [owner, setOwner] = React.useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(() => false);
    const [privileges, setPrivileges] = useState(() => {});

    const imgUploader = useRef(null);
    
    useEffect(() => {
        let userInfo = JSON.parse(localStorage.userInfo);
        setIsSuperAdmin(userInfo.adminOwner)
        let countryOptions = Helpers.makeSelectOptions(countries);
        setCountryOptions(countryOptions)
        let id = Helpers.getQueryParameter("id");
        if(id){
            setOppId(id);
            fetchOpportunity(id);
        }
       
        //console.log(props,"props")
        
    }, [])

    useEffect(() => {
        var packageDetails = props.packageDetails;
       
        if(packageDetails && packageDetails.privileges){
            setPrivileges(packageDetails.privileges);
        }else{
            props.getPackageDetails()
        }
      
    }, [props])

  

    const fetchOpportunity = (id)=>{
        Axios.get(
            Helpers.apiURL({
                uri:`api/opportunity/${id}`,
            })
        )
        .then( (response) => {
            let data  = response.data || [];  
            // var obj = 
            // var obj = {
                setOwner(data.owner);
                data["type"]=data.oppType;
                data["profilePicUrl"]=  Helpers.viewFile(data.coverPhoto.uniqueId);
                data["profilePic"]=data.coverPhoto;
                data["title"]=data.title;
                data["country"]={
                    label:data.page.country.name,
                    value:data.page.country.id,
                };
                data["city"]={
                    label:data.page.city.name,
                    value:data.page.city.id,
                };
                data["metaDatam"]={
                    "sectors":data.sectors.map(x=> ({label:x.name,value:x.id})),
                    "industryGroups":data.industryGroups.map(x=> ({label:x.name,value:x.id})),
                    "subIndustries":data.subIndustries.map(x=> ({label:x.name,value:x.id})),
                    "industries":data.industries.map(x=> ({label:x.name,value:x.id})),
                };
                data["sectors"]=data.sectors.map(x=> ({label:x.name,value:x.id}));
                data["industryGroups"]=data.industryGroups.map(x=> ({label:x.name,value:x.id}));
                data["subIndustries"]=data.subIndustries.map(x=> ({label:x.name,value:x.id}));
                data["industries"]=data.industries.map(x=> ({label:x.name,value:x.id}));
                data["opportunitySummary"]=data.summary;
                data["detailedDesc"]=data.description;
                data["contactName"]=data.contactPersons[0].firstname;
                data["contactId"]=data.contactPersons[0].id;
                data["role"]=data.contactPersons[0].role;
                data["mobileCode"]=data.contactPersons[0].mobileCode;
                data["mobile"]=data.contactPersons[0].mobile;
                data["contactEmail"]=data.contactPersons[0].email;
                data["website"]=data.page.website;
                data[ "photos"]=data.photos;
                data["videos"]=data.videoLinks;
                data[ "attachments"]=data.attachments;
                //"featured":data.featured,
            // }
            if(data && data.page && data.page.country && data.page.country.id){ApiHelper.getCities(data.page.country.id, setAddressCityList);}
            setState(prevState => data);
            setFeatured(data.featured)
          
        })
    }
    


    const  handleSelectChange = (option, e) => {
        let thisFieldName = e.name;

        if (thisFieldName == "country") {
            setLoading(false)
            ApiHelper.getCities(option.value, setAddressCityList);
          }
      
          setState((prevState) => ({...prevState, [thisFieldName]: option }));
        
    }

    const setAddressCityList = (cityList) => {
        let cityOptions = Helpers.makeSelectOptions(cityList);
        setCityList(cityList);
        setCityOptions([...cityOptions, { value: 'other', label: 'Other' }]);
       // setCityOptions([...cityOptions]);
        setLoading(false)
    }


    const mobileValuesContact = (data) => {
        setState((prevState) => ({...prevState, mobileCode: data.ext,mobile: data.mobile }));
    }

   const handleFieldValueChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let lclState = state;
        if (name) {
            setState((prevState) => ({...prevState, [name]: value }));
        }
    }

  
    const onChangeMeta = (name, slug) => {
        setState((prevState) => ({...prevState, [name]: slug }));
    }

    const handlefeaturedToValueChange = (date) =>{
        state["featuredTo"] = date; //!Helpers.isEmpty(date) ? date : new Date();
        setState((prevState) => {
          return {...state}
        });
    }

    const handlefeaturedFromValueChange = (date) =>{
        state["featuredFrom"] = date;
        setState((prevState) => {
          return {...state}
        });
    }

    const triggerProfileImageUpload = (e) =>{
        imgUploader.current.click();
    }

    const uploadProfileImage = (e) =>{
        setLoading(true)
        let file = e.target.files ? e.target.files[0] : null;
        if (file) {
          ApiHelper.uploadFile({
            repoType: "OPP_DOCS",
            fileType: "IMAGE",
            metaType:"OPPDOC",
            docTypeName:"OPP_PHOTOS",
            file: file,
            callback: result => {
                setLoading(false);
                setState((prevState) => ({...prevState, profilePic: result , profilePicUrl: Helpers.viewFile(result.uniqueId)}));
            },
            errCallback:err=>{
                setLoading(false)
             }
          });
        }
      }

     const handleUpload = (itemKey, newItems) =>{
        setState((prevState) => ({...prevState, [itemKey]: newItems  }));
     }

     const handleVideoChange = (itemKey, newItems) => {
        setState((prevState) => ({...prevState, [itemKey]: newItems  }));
     }

     const changeFeatured = (e)=>{
         setFeatured(e.target.checked);
     }

    const vaidateForm = () => {
        let isValid = true;
        let fields = [
          "type",
          "profilePicUrl",
          "title",
          "country",
          "city",
          "sectors",
          "industryGroups",
          "subIndustries",
          "industries",
          "opportunitySummary",
          "detailedDesc",
          "contactName",
          "role",
          "mobileCode",
          "mobile",
          "website",
        //   "photos",
        //   "videos",
        //   "attachments",
        ];

        if(state && state.city && state.city.value == "other"){
            fields.push('newCity');
        }
    
     
        let errors = {}
    
        fields.forEach((field) => {
            // if(field == "featuredFrom" || field == "featuredTo"){
            //     state[field] =  Helpers.getDateInInputFormat(state[field]);
            // }
          if (Helpers.isEmpty(state[field])) {
            isValid = false;
            errors[field] = "This field is mandatory"
          } else {
            errors[field] = ""
          }
        })
    
        setErrors(errors);
        return isValid;
      }

      const handleSubmit = ()=>{
          var formValid = vaidateForm();
          if(!formValid){
              Helpers.pushError("Please fill all the required fields.");
              return false;
          };
          let id = Helpers.getQueryParameter("id");
          setLoading(true)
          var payload =  state;
          
          payload["oppType"]= id ? state.type : state.type.value;
          payload["featured"]=featured;

          if(featured){
            payload.featuredFrom = state.featuredFrom != null? Helpers.getDateInInputFormat(state.featuredFrom) :  Helpers.getDateInInputFormat(new Date());
            payload.featuredTo = state.featuredTo != null ?  Helpers.getDateInInputFormat(state.featuredTo) : Helpers.getDateInInputFormat(new Date());
          }

          payload["page"]={
               country:{
                  id:state.country.value
               },
               city:state.city.value == "other" ? {
                id: null,
                name:state.newCity
               }:{
                  id: state.city.value,
               },
               website:state.website,
            };
          payload["title"]=state.title;
          payload["coverPhoto"] = {
               uniqueId:state.profilePic.uniqueId
            };
            payload["sectors"] = state.sectors.map((x) => ({ "id": x.value }));
            payload["industryGroups"] = state.industryGroups.map((x) => ({ "id": x.value }));
            payload["industries"]=state.industries.map((x) => ({ "id": x.value }));
            payload["subIndustries"]=state.subIndustries.map((x) => ({ "id": x.value }));
            payload["summary"]=state.opportunitySummary;
            payload["description"]=state.detailedDesc;
            payload["contactPersons"]=[
               {
                  firstname:state.contactName,
                  lastname:"",
                  email:state.contactEmail,
                  mobile:state.mobile,
                  mobileCode:state.mobileCode,
                  primary:true,
                  role:state.role,
                  id:state && state.contactId ? state.contactId: null
               }
            ];
            payload["photos"]=state &&  state.photos ? state.photos.map((x) => ({ uniqueId: x.uniqueId })): null;
            payload["videoLinks"]=state &&  state.videos ? state.videos : null;
            payload["attachments"]=state &&  state.attachments ?  state.attachments.map((x) => ({ uniqueId: x.uniqueId })) : null;
         
         if(id){
             payload.id = id;
         }

       //  delete payload.metaDatam;

       
         if(id){
            Axios.put(Helpers.apiURL({ uri: "api/opportunity/" }), payload)
            .then(response => {
               setLoading(false)
                //Helpers.pushSuccess("Opportunity Details has been saved!");
                let data = response.data;
                let id = data.id ? data.id : null;
                if (id) {
                    props.naviagation("/create-opportunity/next?id="+ id)
                }
             
      
            })
            .catch(err => {
              setLoading(false)
              Helpers.pushError(err.response.data.message)
            });
         }else{
            Axios.post(Helpers.apiURL({ uri: "api/opportunity/" }), payload)
            .then(response => {
               setLoading(false)
                // Helpers.pushSuccess("Opportunity Details has been saved!");
                let data = response.data;
                let id = data.id ? data.id : null;
                if (id) {
                    props.naviagation("/create-opportunity/next?id="+ id)
                }
            })
            .catch(err => {
              setLoading(false)
              Helpers.pushError(err.response.data.message)
            });
         }

         
         
      }


    return (
        <div className="row full-width bold-headers">
            <LoadingOverlay>
             <Loader fullPage loading={loading} />
            </LoadingOverlay>
            <div className="col-md-12 text-left m-t-20 m-b-20">
                <div className="col-md-12">
                    <h1>Create a Opportunity</h1>
                </div>
            </div>
            <div className="col-md-12">
                <div className="card">
                    <div className="card-body text-left">
                         <h5 className="m-t-0">Opportunity Type</h5>
                         {Helpers.isEmpty(oppId) ? 
                            <EditSelect
                                name="type"
                                title="Type"
                                required={true}
                                value={state.type}
                                options={config.opportunityTypes}
                                onChange={handleSelectChange}
                                additionalClassName="ediableInline preFilled"
                                errorMsg={errors && errors["type"] ? errors["type"]  : ""}
                            />
                            :
                            <div className="col-md-12">
                                <p>{state && state.type}</p>
                            </div>

                         }
                         
                    </div>
                    <div className="card-body text-left">
                         <h5 className="m-t-0">Opportunity Information</h5>
                         <div className="m-t-50 col-md-12 p-0">
                            <h6 className="">Photo<span style={{color:"red"}}>*</span></h6>
                                <a
                                href="javascript:void(0)"
                                    onClick={triggerProfileImageUpload}
                                    >
                                    {Helpers.isEmpty(state.profilePicUrl) ? (
                                        <div className="float-left newImgCard">
                                        <i className="fa fa-camera text-black m-t-20 font-40 " />
                                        <div className="font-10">Upload photo</div>
                                        </div>
                                    ) : (
                                        <img
                                        src={state.profilePicUrl}
                                        className="float-left newImgCard"
                                        width="100"
                                        />
                                    )}
                                    <input
                                        type="file"
                                        id="userImg"
                                        name="userImg"
                                        style={{ display: "none" }}
                                        ref={imgUploader}
                                        onChange={uploadProfileImage}
                                    />
                                    </a>
                         </div>
                           <EditText
                                name="title"
                                title="Title"
                                value={state.title}
                                placeholder={"Opportunity Title"}
                                required={true}
                                onValueChange={handleFieldValueChange}
                                errorMsg={errors && errors["title"] ? errors["title"]  : ""}
                            />
                            {isSuperAdmin &&
                                <div className="form-check clearfix p-l-0">
                                    <input className="form-check-input" type="checkbox" id="featured" name="featured" checked={featured} onChange={changeFeatured} />
                                    <label className="form-check-label" htmlFor="featured">Is Featured?</label>
                                </div>
                            }
                            {isSuperAdmin && featured &&
                                <div className="col-md-6 p-0">
                                    <div className="col-md-6 float-left">
                                        <EditDate
                                            name="featuredFrom"
                                            title="From Date"
                                            showTimeSelect={false}
                                            value={state &&  state.featuredFrom ? state.featuredFrom  : new Date()}
                                            placeholder={""}
                                            required={true}
                                            onValueChange={handlefeaturedFromValueChange}
                                            errorMsg={errors && errors["featuredFrom"] ? errors["featuredFrom"]  : ""}
                                            extraClass="ntg"
                                            />
                                    </div>
                                    <div className="col-md-6 float-left">
                                        <EditDate
                                            name="featuredTo"
                                            title="To Date"
                                            showTimeSelect={false}
                                            value={state &&  state.featuredTo ? state.featuredTo  : new Date()}
                                            placeholder={""}
                                            required={true}
                                            onValueChange={handlefeaturedToValueChange}
                                            errorMsg={errors && errors["featuredTo"] ? errors["featuredTo"]  : ""}
                                            extraClass="ntg"
                                            />
                                       </div>
                                </div>
                            }


                            <EditSelect
                                title="Country"
                                headingclassName="isMandatoryLabel"
                                name="country"
                                value={state.country}
                                options={countryOptions}
                                onChange={handleSelectChange}
                                additionalClassName="ediableInline "
                                errorMsg={errors &&  errors["country"] ? errors["country"]  : ""}
                                required={true}
                            />
                            <EditSelect
                                title="City/Town"
                                headingclassName="isMandatoryLabel"
                                name="city"
                                value={state.city}
                                options={cityOptions}
                                onChange={handleSelectChange}
                                additionalClassName="ediableInline mt-10"
                                errorMsg={errors &&  errors["city"] ? errors["city"]  : ""}
                                required={true}
                            />
                             {
                                state.city && state.city.value == 'other' ?
                                <EditText
                                    name="newCity"
                                    title=""
                                    value={state.newCity}
                                    placeholder={"Enter city name"}
                                    onValueChange={handleFieldValueChange}
                                    errorMsg={errors && errors["newCity"] ? errors["newCity"]  : ""}
                                />
                                :
                                ""
                            }

                            <MetaEdit
                                    options={["sectors", "industryGroups", "industries", "sub_industries"]}
                                    data={state.metaDatam}
                                    onChangeMeta={onChangeMeta}
                                    error={errors}
                                    key={"metaEdit"}
                                />
                            <EditText
                                name="opportunitySummary"
                                title="Opportunity Summary"
                                value={state.opportunitySummary}
                                placeholder={"Describe your opportunity"}
                                required={true}
                                onValueChange={handleFieldValueChange}
                                errorMsg={errors &&  errors["opportunitySummary"] ? errors["opportunitySummary"]  : ""}
                            />
                             <EditTextArea
                                    name="detailedDesc"
                                    addLabelclassName="isMandatoryLabel"
                                    title="Detailed Description"
                                    value={state.detailedDesc}
                                    onValueChange={handleFieldValueChange}
                                    required={true}
                                    rowclassName={'form-control textarea-sm'}
                                    errorMsg={errors &&  errors["detailedDesc"] ? errors["detailedDesc"]  : ""}
                                    
                                />
                    </div>
                </div>
                <div className="card">
                    <div className="card-body text-left">
                    <h5 className="m-t-0">Contact Person Information</h5>
                    <EditText
                        name="contactName"
                        title="Name"
                        value={state.contactName}
                        placeholder={""}
                        required={true}
                        onValueChange={handleFieldValueChange}
                        errorMsg={errors &&  errors["contactName"] ? errors["contactName"]  : ""}
                    />
                    <EditText
                        name="role"
                        title="Role"
                        value={state.role}
                        placeholder={""}
                        required={true}
                        onValueChange={handleFieldValueChange}
                        errorMsg={errors &&  errors["role"] ? errors["role"]  : ""}
                    />

                    <Mobile
                        sendValue={mobileValuesContact}
                        required={true}
                        extVal={state && state.mobileCode ? state.mobileCode : "+256"}
                        mobileVal={state.mobile}
                        mobileName={"mobile"}
                        extName={"mobileCode"}
                        title="Contact Number"
                        name=""
                        error={errors}
                    />
                     <EditText
                        name="contactEmail"
                        title="Email Address"
                        value={state.contactEmail}
                        placeholder={""}
                        required={true}
                        onValueChange={handleFieldValueChange}
                        errorMsg={errors &&  errors["contactEmail"] ? errors["contactEmail"]  : ""}
                    />
                      <EditText
                        name="website"
                        title="Website"
                        value={state.website}
                        placeholder={""}
                        required={true}
                        onValueChange={handleFieldValueChange}
                        errorMsg={errors &&  errors["website"] ? errors["website"]  : ""}
                    />

                    </div>
                </div>
                <div className="card" id="photos">
                    <div className="card-body text-left">
                        <Upload
                            title="Photos"
                            description="Upload all relevant photos here"
                            icon="plus"
                            items={state.photos}
                            itemType="image"
                            itemKey="photos"
                            itemRepoType="OPP_IMAGES"
                            itemChange={handleUpload}
                            isEditable={isEditable}
                        />
                    </div>
                </div>

                <div className="card" id="videos">
                    <div className="card-body text-left">
                        <Videos
                            title="Videos"
                            description="Add all relevant videos here"
                            icon="plus"
                            items={state.videos}
                            itemKey="videos"
                            itemChange={handleVideoChange}
                            isEditable={isEditable}
                        />
                    </div>
                </div>

                <div className="card" id="attachment">
                    <div className="card-body text-left">
                        <Upload
                            title="Attachments"
                            description="Add all relevant documents here"
                            icon="cloud-upload-alt"
                            items={state.attachments}
                            itemType="doc"
                            itemKey="attachments"
                            itemRepoType="OPP_ATTACHMENTS"
                            itemChange={handleUpload}
                            isEditable={isEditable}
                        />
                    </div>
                </div>
                <div className="col-sm-12 col-xs-12 p-0">
                   {!Helpers.isEmpty(oppId) && owner && <DeletePage type="Opportunity" uri={oppId} />}
                    <button
                    type="submit"
                    className="btn btn-info waves-effect- waves-light- float-right"
                    onClick={handleSubmit}
                    >
                    Save and Continue
                    </button>
                </div>
                
            </div>
            
        </div>
    )
}

// export default Form

const mapStateToProps = (state) => ({
    packageDetails: state.packageDetails,
    errors: state.errors
  });
  
  export default connect(mapStateToProps, { getPackageDetails })(withRouter(Form));
  