import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import { withRouter , Link} from 'react-router-dom';
import Helper from "../../shared/custom";
import config from "../../shared/config";
import { connect } from 'react-redux';
import store from '../../store';
import { login } from '../../actions/authAction';
import Axios from 'axios';

class CurrentPlan extends Component {
  constructor(props){
    super(props);
    this.state = {
      paymentHistory:[],
      subscription:[],
      allowCancel:true,
    }
    this.getPlanDetails = this.getPlanDetails.bind(this);
    this.getPaymentHistory = this.getPaymentHistory.bind(this);
    this.cancelSubscription = this.cancelSubscription.bind(this);
  }

  componentDidMount(){
    this.getPlanDetails();
    this.getPaymentHistory()
  }

  cancelSubscription(e){
    Axios.post(
      config.api("user/subscription/cancel"),
      )
    .then( (response) => {
       //window.location.reload();
       this.getPlanDetails();
    })
    .catch((err) => {
        Helper.pushTError("Cannot cancel your subscription now ! Please try again later")
    });
  }

  getPlanDetails(){
    Axios.get(
      Helper.apiURL({
          uri:"user/subscription/details",
      })
    )
    .then( (response) => {
        let data = response.data.result;
        this.setState({
          subscription:data
        });
        if(data.subscriptionTypeDto.name == "BASIC"){
          this.setState({
            allowCancel:false
          })
        }
        
    })
    .catch((err) => {
        Helper.pushTError("Error fetching your currenct plan");
    });
  }

  getPaymentHistory(){
    Axios.get(
      Helper.apiURL({
          uri:"user/payment/history",
      })
    )
    .then( (response) => {
        let data = response.data.result;
        if(response.data.status == 200){
          this.setState({
            paymentHistory:data
         })
        }
       
    })
    .catch((err) => {
       Helper.pushTError("Error fetching your payment history");
    });
  }

  render() {
    let paymentHistory = this.state.paymentHistory;
    let subscription = this.state.subscription;
    let details = subscription ? subscription.subscriptionTypeDto : null;
    let privileges = subscription ? subscription.privileges : null;
    let dataset = [];
    let daysLeft = 0;
    let allowCancel = this.state.allowCancel;

    let cancelConfig = {
      proceed: this.cancelSubscription
   };

    if(subscription && subscription.nextBillingDate){
      daysLeft = Helper.getDaysDiffF(subscription.nextBillingDate);
      daysLeft = daysLeft < 0 ? 0 : daysLeft;
    }


    
   
    if(!Helper.isEmpty(paymentHistory)){
      paymentHistory.forEach((data,i)=>{
          var obj = {};
          obj.date = data.date;
          obj.refNum = data.identifier;
          obj.plan = data.subscriptionTypeDto ? data.subscriptionTypeDto.name : "NA";
          obj.category = data.category ? data.category : "";
          obj.status = data.status ? data.status == "SUCCESS" ? <span className="text-green">{data.status}</span> : <span className="text-danger">{"FAILED"}</span> : "NA";
          obj.price = data.requestAmount ? (data.currency ? data.currency :"")+" "+Helper.prettyNumber(data.requestAmount) : "0";
          dataset.push(obj)
        
      })
    }
  
    const columns = [
      {
        name: 'Date',
        selector: 'date',
        sortable: true,
      },
      {
        name: 'Ref. Number', 
        selector: 'refNum',
        sortable: true,
      },
      {
        name: 'Plan', 
        selector: 'plan',
        sortable: true,
      },
      {
        name: 'Category', 
        selector: 'category',
        sortable: true,
      },
      {
        name: 'Status', 
        selector: 'status',
        sortable: true,
      },
      {
        name: 'Price', 
        selector: 'price',
        sortable: true,
        right: true,
      }
      
    ];

    
    return (
      <div>
         <div className="row bold-headers p-b-30" id="currentPlan">
              <div className="col-md-12 col-lg-12 col-xlg-12 p-0">
                <div className="card">
                  <div className="card-header bg-lBlue small-header">
                      <h4 className="m-b-0 m-t-0 text-white font-16 text-left">Current Plan</h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                          <div className="col-md-6 col-lg-6 col-sm-12 float-left">
                              {!Helper.isEmpty(details) ? 
                                  <div className="card sub-card">
                                    <div className="card-header bg-lBlue" style={{background:details.color ? details.color : "#000"}}>
                                        <h4 className="m-b-0 text-white font-24 m-t-0">{details.name}</h4></div>
                                    <div className="card-body bg-grey">
                                        <h3 className="card-title font-24 m-t-0 m-b-0">{Helper.getCurrencySymbol(details.currency ? details.currency.shortName :"")}{" "}{subscription.amount ? Helper.prettyNumber(subscription.amount) : 0} {details.duration}</h3>
                                        {!Helper.isEmpty(details)? 
                                              <p className="card-text text-grey m-b-0">{details.description}</p> 
                                            :
                                            ""
                                        }
                                        
                                        {/* <p className="card-text font-bold">Access {subscription.pageViews} pages per month</p> */}
                                    </div>
                                  </div>
                                  :
                                  ""

                              }
                            
                              <div className="m-t-10">
                                <Link to={"/subscribe"} className="btn btn-md bg-green-only text-white">View Plan</Link>
                              </div>
                              <div className="clearfix">
                               {  allowCancel?
                                    <a href="javascript:void(0)" onClick={event =>
                                                  Helper.actionConfirm(event, cancelConfig)
                                        }className="text-danger clearfix">Cancel Plan</a>
                                      :
                                      ""
                               }
                                
                              </div>
                          </div>
                          {!Helper.isEmpty(subscription) ?
                              <div className="col-md-6 col-lg-6 col-sm-12 float-left">
                                {/* <h3 className="card-title font-24 m-t-10 m-b-0 font-normal text-left"><b>{Helper.prettyNumber(subscription.pageViewsLeft)}/{Helper.prettyNumber(subscription.pageViews)}</b> Page views left</h3> */}
                                <h3 className="card-title font-18 m-t-10 m-b-0 font-normal text-left">Amount: {Helper.getCurrencySymbol(details.currency ? details.currency.shortName+"" :"")}{" "}{Helper.prettyNumber(details.amount)}</h3>
                                <h3 className="card-title font-18 m-t-10 m-b-0 font-normal text-left"><b>{daysLeft > 0 && subscription.nextBillingDate ? "Plan valid till: " + subscription.nextBillingDate : ""}</b> </h3>
                              </div>
                              :
                              ""
                          }
                          
                         
                      </div>
                  </div>
                </div>

                <div className="card" id="paymentHistory">
                  <div className="card-header bg-lBlue small-header text-left">
                      <h4 className="m-b-0 m-t-0 text-white font-16">Payment History</h4>
                  </div>
                  <div className="card-body">
                    <div className="row paymentDt">
                      <DataTable
                        columns={columns}
                        data={dataset}
                      />
                    </div>
                    </div>
                </div>

              </div>
           </div>
      </div>
    )
  }
}


export default withRouter(CurrentPlan)