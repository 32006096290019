import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import 'react-phone-number-input/style.css'
import PhoneInput , { getCountries ,parsePhoneNumber }from 'react-phone-number-input';
import Helper from "../../shared/custom";

let cCode;
export class CountryCode extends Component {

    constructor(props){
        super(props);
        this.state={
            country:"KE",
            defaultValue:"+254"
        }
    }
   
    componentDidMount(){
        let elm = document.querySelectorAll(".PhoneInputInput");
        if(!Helper.isEmpty(elm)){
            elm.forEach((el,i)=>{
                el.setAttribute("maxLength",5);
            })
        }

        // console.log(ext,"extValextVal")
        // this.setState({
        //     defaultValue:this.props.value
        // })
    }



    static getDerivedStateFromProps(nextProps, prevState) {
     if(JSON.stringify(nextProps) != JSON.stringify(prevState)){
        prevState.country = nextProps.defaultCountry;
        prevState.defaultValue = nextProps.defaultValue;
        return prevState;
     }
     return {
     }
  }


    onChange =(code) =>{
        let country= cCode;
        this.props.onChange(code,country)
    }

    onCountryChange = (code)=>{
        cCode = code
        this.setState({country:cCode})
    }
    render() {

        let { country , defaultValue } =  this.state;


        return (
            <PhoneInput
                international={true}
                //value={Helper.isEmpty(this.props.value) ? this.state.defaultValue : this.props.value}
                value={defaultValue}
                defaultCountry={country}
                country={country}
                countryCallingCodeEditable={true}
                onChange={code => this.onChange(code)}
                onCountryChange= {code=>this.onCountryChange(code)}
                className={this.props.className+" ext" || "ext"}
                name={this.props.name || "ext"}
                data-testid={this.props.name || "ext"}
                initialValueFormat="international"
                limitMaxLength={true}
                />
        )
    }
}

export default CountryCode
