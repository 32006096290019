import React, { Component , useEffect, useState , useRef } from 'react';
import { Link } from 'react-router-dom';
import config from '../../../shared/config';
import Slider from "react-slick";
import OppCard from './OppCard';
import Helpers from "../../../shared/custom";

function Carousal(props) {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: props && props.data && props && props.data.length < 3 || Helpers.isMobile() ? props.data.length : 3,
        slidesToScroll: props && props.data && props && props.data.length < 3 || Helpers.isMobile() ? props.data.length : 3,
      };

      const triggerSearch =(e)=>{
          let mode = e.target.dataset.mode; 
          props.doSearch(mode)
      }

    return (
         <React.Fragment>
           <h2 className="text-left font-14">{props.heading}</h2>
           {props && props.data && props.data.length > 0?
                <div className="documents">
                    <Slider {...settings}>
                        {
                          props.data.map((list,i)=>(
                            <OppCard className="col-md-12" data={list} keyName={i+"oppcard"} from="slider"/>
                          ))
                        }

                    </Slider>
                    <div className="col-md-12 text-right m-t-10">
                      {/* <Link  to={privileges && privileges["INV_OPP"] ? "/" + uri : upgradeUrl } > */}
                      <a onClick={(e)=>triggerSearch(e)} className="text-blue" data-mode={props.mode}>
                        View All
                      </a>
                    </div>
                  </div>

                :
                <div className="text-center">
                  No Data Found
                </div>
           } 
        </React.Fragment>
    )
}

export default Carousal
