import React, { Component } from 'react';
import { BrowserRouter as Router, Route,Redirect, Link ,Switch ,withRouter} from "react-router-dom";
import EventSummary from './EventSummary';
import Allevents from './Allevents';
import Upcoming from './Upcoming';
import Myevent from "./Myevent";
import EventsBySector from "./EventsBySector";
import store from '../../store';
import { connect } from 'react-redux';
import PrivateRouter from '../../utils/PrivateRouter';



class EventRouter extends Component {

  constructor(){
    super();
    this.state ={
        searchCriteria:{},
        criteriaUpdate:undefined,
        isLoggedIn :false,
        setPageContext:undefined,
        context:undefined,
        locationIds:"",
        filterDates:""
    }

      

  }


  componentWillReceiveProps(props){

    this.setState({criteriaUpdate:props.criteriaUpdate,
                   searchCriteria:props.searchCriteria,
                   setPageContext:props.setPageContext,
                   context:props.context,
                   metaList:props.metaList,
                   business:props.business,
                   locationIds:props.locationIds,
                   filterDates:props.filterDates
                  });
  }

  render() {



   
    return (
      <Switch>
            <Route
                path='/events/upcoming'
                exact={true}
                render={(props) => (
                  true
                    ? <Upcoming {...props}  
                            criteriaUpdate={this.state.criteriaUpdate}
                            searchCriteria={this.state.searchCriteria}
                            setPageContext={this.state.setPageContext}
                            locationIds = {this.state.locationIds}
                            filterDates = {this.state.filterDates}
                            criteriaUpdate />
                    : <Redirect to={{
                          pathname: '/login',
                          state: { from: props.location }
                        }}
                      />
                )} />


              <Route
                path='/events/my-events'
                exact={true}
                render={(props) => (
                  true
                    ? <Myevent {...props}  
                              criteriaUpdate={this.state.criteriaUpdate} 
                              searchCriteria={this.state.searchCriteria} 
                              setPageContext={this.state.setPageContext}
                              locationIds = {this.state.locationIds} />
                    : <Redirect to={{
                          pathname: '/login',
                          state: { from: props.location }
                        }}
                      />
                )} />

              <Route
                path='/events/sector/:sector'
                exact={true}
                render={(props) => (
                  true
                    ? <EventsBySector {...props}  
                              criteriaUpdate={this.state.criteriaUpdate} 
                              searchCriteria={this.state.searchCriteria} 
                              setPageContext={this.state.setPageContext}
                              metaList={this.state.metaList} 
                              business={this.state.business}  
                              locationIds = {this.state.locationIds} 
                              filterDates = {this.state.filterDates}
                            />
                    : <Redirect to={{
                          pathname: '/login',
                          state: { from: props.location }
                        }}
                      />
                )} />
              <Route
                path='/events'
                exact={true}
                render={(props) => (
                    <Allevents {...props}  
                            criteriaUpdate={this.state.criteriaUpdate} 
                            searchCriteria={this.state.searchCriteria} 
                            setPageContext={this.state.setPageContext} 
                            metaList={this.state.metaList} 
                            business={this.state.business} 
                    />
                )} />

              <Route
                path='/'
                exact={true}
                render={(props) => (
                    <Allevents {...props}  
                            criteriaUpdate={this.state.criteriaUpdate} 
                            searchCriteria={this.state.searchCriteria} 
                            setPageContext={this.state.setPageContext}  
                            metaList={this.state.metaList} 
                            business={this.state.business} 
                            />
                )} />

      </Switch>
    )
  }
}


export default withRouter(EventRouter);

