import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import config from '../../shared/config';
import Feeds from '../wigets/Feeds';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getMetaData } from '../../actions/metaAction';
import { getSectorData } from '../../actions/businessAction';
import store from '../../store';
import Helper from "../../shared/custom";
import moment from "moment";
import queryString from 'query-string';
import { storeNewsSearch } from '../../actions/searchAction';
import Helpers from '../../shared/custom';
import SelectableTags from '../ui/SelectableTags'
import countries from "../../shared/country";
import SelectableTagsOptionalAdd from '../ui/SelectableTagsOptionalAdd';
import CompanySearch from '../wigets/CompanySearch';
import EditMultiSelect from "../ui/EditMultiSelect";
import DatePicker from "react-datepicker";

class NewsContent extends Component {
  constructor(props){
    super(props)
    this.state={
       mode:undefined,
       pageLoadIndex:0,
       enableSearch:false,
       editSearch:true,
       sectorOptions : [],
       industriesArray:[],
       selectedSectors:[],
       countryCodes:[],
       selectedInds:[],
       companyValue:[],
       sectorsValue:[],
       privileges:{},
       localProps:{},
       indValue:[],
         companyName:undefined,
          companyUri:undefined,
          search: undefined,  
          startDate: new Date(moment().subtract(1, 'months')),
          endDate: new Date(),
          industryIds: undefined,
          sectorIds: undefined,
          metaList:undefined,
          business:undefined,
          payload:{
            enableSearch:true
          },
          sectionFilter:"sector",
          loading:false,
          sectorTemporaryOptions:[],
          countryTemporaryOptions:[]
    }
    this.doSearch = this.doSearch.bind(this);
    this.editSearch = this.editSearch.bind(this);
    this.onChangeCompany = this.onChangeCompany.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.changeWords = this.changeWords.bind(this);
    this.handleSectorChange = this.handleSectorChange.bind(this);
    this.handleIndustryChange = this.handleIndustryChange.bind(this);
    this.getBusinessData = this.getBusinessData.bind(this);

    this.setMinimumSectors = this.setMinimumSectors.bind(this);

    store.subscribe(() => {
      var storeState = store.getState();
      let localBusiness = this.state.business;
      var storeBusiness = storeState.business.data;
      let searchState = storeState.search;
      if(storeBusiness && storeBusiness.sectorsArray){

        //console.log(storeState.auth.userProfile,"storeState.auth.userProfile.sectors")

        this.setState({
          userSectorOptions:storeBusiness.sectorsArray
        })
      }
      if(searchState &&  searchState.feedLoading != null && searchState.feedLoading != this.state.loading){
        this.setState({
          loading:searchState.feedLoading
        })
      }
      if(!localBusiness || JSON.stringify(localBusiness) != JSON.stringify(storeBusiness)){
        this.setState({business:storeBusiness.data},()=>{
          if (!Helper.isEmpty(storeBusiness)) {
            this.setState({
              sectorOptions : Helper.makeSelectOptions(storeBusiness.sectorsArray),
              industriesOption : Helper.makeSelectOptions(storeBusiness.industriesArray),
              industriesAllOption : storeBusiness.industriesArray
            })
            
          }
        });
      }
      let packageDetails =  storeState.packageDetails;
      let privileges = packageDetails.privileges;
         
          if(JSON.stringify(privileges) != JSON.stringify(this.state.privileges)){
              this.setState({
                  privileges:privileges
              },()=>{
                if(!Helper.isEmpty(privileges) && !privileges["NWS"]){
                  this.props.history.push(Helpers.upgradeUrl());
                  return false;
                }
              })
          }
    });
    var storeState = store.getState();
    if(storeState.search && storeState.search.newsSearchCriteria){
      this.state.sectorOptions = Helper.makeSelectOptions(storeState.business.data.sectorsArray);
      this.state.sectorIds = storeState.search.newsSearchCriteria.sectorIds;
      this.state.countryCodes = storeState.search.newsSearchCriteria.countryCodes;
      this.state.sectionFilter = storeState.search.newsSearchCriteria.sectionFilter || "sector";
      if(this.state.sectionFilter =="sector"){
        //this.state.sectorTemporaryOptions = storeState.search.newsFeedSearch.sectorTemporaryOptions;
      }else {
        //this.state.countryTemporaryOptions = storeState.search.newsFeedSearch.countryTemporaryOptions;
      }
      
    }
    
  }

  
//{uniqueId: "ad28c376-dcb9-412d-b5ce-78618a890d7a", name: "MA Partners DMCC", uri: "MA-Partners-DMCC", value: "MA-Partners-DMCC"}
  componentDidMount(){

  
    //this.doSearch()
    window.scrollTo(0, 0);
    this.setState({
      mode:"news"
    });
    this.getBusinessData();


    var storeState = store.getState();
        
    let searchCriteria = !Helper.isEmpty(storeState.search) ? storeState.search.newsSearchCriteria : undefined;

   
    if(searchCriteria){

      this.setState(
          {
            companyValue: !Helper.isEmpty(searchCriteria.companyValue) ? searchCriteria.companyValue : null,
            companyName:!Helper.isEmpty(searchCriteria.companyValue) ? searchCriteria.companyValue[0].name : null,
            search: searchCriteria.search, 
            startDate: searchCriteria.startDate ? new Date(searchCriteria.startDate) : null,
            endDate: searchCriteria.endDate ? new Date(searchCriteria.endDate) : null,
            indValue: searchCriteria.indValue,
            sectorsValue: searchCriteria.sectorsValue,
            selectedSectors:searchCriteria.selectedSectors,
            selectedInds:searchCriteria.selectedInds,
            countryCodes:searchCriteria.countryCodes
          },()=>{
              if(!Helper.isEmpty(searchCriteria)){
                  this.doSearch()
              }
          }
       ) 
      }else{
        this.doSearch()
      }

     let userProfile = JSON.parse(localStorage.getItem("profile"));
     if(userProfile){
      //console.log(storeState.auth.userProfile,"storeState.auth.userProfile.sectors")
      this.setState({
        userSectorOptions:userProfile.sectors
      })
    }
    

    let query = this.props.location.search;
    let querySearch = queryString.parse(query);

    if(querySearch && querySearch.entityUri && querySearch.entityName && querySearch.entityType){
      //based on the entity type, the behaviour may change later
        this.setState({
            companyUri:querySearch.entityUri,
            companyName:querySearch.entityName,
            companyValue:[{name: querySearch.entityName, uri: querySearch.entityUri, value: querySearch.entityUri}],
            preSearch:true,
        },() => {
           this.doSearch();
        })
    }else if(querySearch && querySearch.industries){
      
      this.setState({
            industryIds:querySearch.industries.split(","),
            preSearch:true,
        },() => {
          this.doSearch();
        })
    }else{
      this.setState({
        enableSearch:true
      }, function(){
      })
    }


  }

  componentWillReceiveProps(props){
    
     this.getBusinessData();
     this.setState({
       localProps:props,
     })
     if(JSON.stringify(this.state.localProps) != JSON.stringify(props)){
      //  this.setState({editSearch:false})
        this.doSearch()
     }
     
  }

  setMinimumSectors(){
    let userSectorOptions = this.state.userSectorOptions;
    if(userSectorOptions && userSectorOptions.length < 4){
      //TODO :
      //set minimum 5

    }
  }
  editSearch(){
    this.setState({
      editSearch:true,
    })
  }
  getBusinessData(){
    var storeState = store.getState();
    let business = storeState.business;
  
  
    if(Helper.isEmpty(business.data) == true){
        this.props.getSectorData();
    }else{
        this.setState({business:business.data},()=>{
          if (!Helper.isEmpty(this.state.business)) {
            let business = this.state.business;
            this.setState({
              sectorOptions : Helper.makeSelectOptions(business.sectorsArray),
              industriesOption : Helper.makeSelectOptions(business.industriesArray),
              industriesAllOption : business.industriesArray
            })
            
          }
        });
    }
  }

  onChangeCompany(e){
      this.setState({
        companyUri:Helper.isEmpty(e[0])?null:e[0].uri,
        companyName:Helper.isEmpty(e[0])?null:e[0].name,
        companyValue:Helper.isEmpty(e)?null:e
      }, function(){
      });
  }

  handleStartDateChange(date){
    this.setState({
        startDate:date
    });
  }

  handleEndDateChange(date){
    this.setState({
      endDate:date
    });
  }

  changeWords(e){
      this.setState({
        search:e.target.value
      })
  }

  doSearch(e){
   
    if(e){
      e.preventDefault();
    }

    
    this.setState({
      enableSearch:true,
    },()=>{
      this.setState({
        editSearch:false,
        pageLoadIndex:this.state.pageLoadIndex+1,
        payload:{
           companyUri:this.state.companyUri,
           search: this.state.search, 
           startDate: this.state.startDate ? Helper.getDateInInputFormat(this.state.startDate) : null,
           endDate: this.state.endDate ? Helper.getDateInInputFormat(this.state.endDate) : null,
           industryIds: this.state.industryIds,
           sectorIds: this.state.sectorIds,
           countryCodes:this.state.countryCodes,
           reqMode:"filter",
        }
      }, function(){
        let storeData = this.state.payload;
        storeData.sectorsValue = this.state.sectorsValue;
        storeData.selectedSectors = this.state.selectedSectors;
        storeData.selectedInds = this.state.selectedInds;
        storeData.countryCodes = this.state.countryCodes;
        storeData.indValue = this.state.indValue;
        storeData.companyValue = this.state.companyValue;
        storeData.sectionFilter = this.state.sectionFilter;
        storeData.sectorTemporaryOptions = this.state.sectorTemporaryOptions;
        storeData.countryTemporaryOptions = this.state.countryTemporaryOptions;
        this.props.storeNewsSearch(storeData);
      })
    })
    
   
   } 

  // handleSectorChange(e){
  //   let sectorsValue = []
  //   let sectorIds = [];
  //   let sectorLabels = [];
  //   e.forEach((data,i)=>{
  //     sectorIds.push(data.value);
  //     sectorLabels.push(data.label);
  //     sectorsValue.push(data)
  //   });
  //   this.setState({
  //     sectorIds:sectorIds,
  //     selectedSectors:sectorLabels,
  //     sectorsValue:sectorsValue,
  //     industriesOption:Helpers.industriesBySector(this.state.industriesAllOption, sectorsValue)
  //   })
  // }

  handleIndustryChange(e){
    let industryIds = [];
    let industryLabels = [];
    let indValue =[]
    e.forEach((data,i)=>{
      industryIds.push(data.value);
      industryLabels.push(data.label);
      indValue.push(data);
    });
    this.setState({
      industryIds:industryIds,
      selectedInds:industryLabels,
      indValue:indValue
    })
  }

  handleSectionFilterChange = (e)=>{
    let countryCodes = this.state.countryCodes;
    let sectorIds = this.state.sectorIds;
    let newState = {
      sectionFilter:e.target.value
    }
    if(e.target.value=="sector"){
      newState.countryCodes = [];
      newState.countryTemporaryOptions = []
    }
    else{
      newState.sectorIds = [];
      newState.sectorTemporaryOptions = []
    }
    this.setState(
      {
        ...newState
      }
    )
  }

  handleSectorChange = (options)=>{
    this.setState({
      sectorIds:options.map(x=>x.value)
    },()=>{this.doSearch()})
  }

  handleCountryChange = (countryOptions)=>{
    this.setState({
      countryCodes:countryOptions.map(x=>x.value)
    },()=>{this.doSearch()})
  }

  handleCountryDropdownChange = (options)=>{
    this.setState({
      countryTemporaryOptions: options
    })
  }
  handleSectorDropdownChange = (options)=>{
    this.setState({
      sectorTemporaryOptions:options
    })
  }
  // doSearch = ()=>{
  //   if(this.state.sectionFilter == "sector"){
  //     this.setState({
  //       selectedSectors:this.state.selectedSectors
  //     })
  //     //  this.props.storeNewsSearch({
  //     //    selectedSectors:this.state.selectedSectors
  //     //  });
  //   }
  //   else if (this.state.sectionFilter == "country"){
  //     //this.props.storeNewsSearch(storeData);
  //   }
  // }

  render() {
    let editSearch = this.state.editSearch;
    let sectorOptions = this.state.sectorOptions;
    let industriesOption = this.state.industriesOption;
    let payload = this.state.payload;

    let companyName = this.state.companyName;
    let selectedSectors = this.state.selectedSectors;
    let selectedInds = this.state.selectedInds;
    let sectorsValue = this.state.sectorsValue;
    let indValue = this.state.indValue;

    let startDate = Helper.getDateInInputFormat(this.state.startDate);
    let endDate = Helper.getDateInInputFormat(this.state.endDate);

    let enableSearch = this.state.enableSearch;
    let page = this.props.page;
    let countryOptions = Helper.makeSelectOptions(countries.map(x=>{return {id:x.iso2,name:x.name}}));
    let africaCountries = Helper.makeSelectOptions(countries.filter(x=> x.region=="africa" && x.preffered != true && x.iso2 !="QA").map(x=>{return {id:x.iso2,name:x.name}}));
    let africaPrefferedCountries = Helper.makeSelectOptions(countries.filter(x=>x.region == "africa" && x.preffered == true).map(x=>{return {id:x.iso2,name:x.name}}));
    
    let sectorSubOptions = [];
    if(this.state.userSectorOptions){
      sectorSubOptions = sectorOptions.filter(x=>!this.state.userSectorOptions.map(y=>y.id).includes(x.value));
    }

   // console.log(this.state.userSectorOptions,this.state.sectorTemporaryOptions,"sectorTemporaryOptionssectorTemporaryOptionssectorTemporaryOptions")


    return (
      <div>

          <div className="card bold-headers p-b-10 top-radius">
              <div className="col-12 p-0 text-left">
                    <div className="col-md-4 float-left p-15 crossCut top-radius">
                        <h5 className="m-b-0 m-t-0 text-white">News Search</h5>
                    </div>
                    {
                     editSearch ? 
                            ""
                            :
                            <div className="col-md-8 text-right float-left bg-white p-10">
                              <a href="javascript:void(0)" className="text-dark-info  font-14" onClick={this.editSearch}>Edit Search Criteria</a>
                            </div>
                     }
            {page == "public" ? 
                  ""
                  :
              <div className="col-12 p-0 m-0 float-left ">
                  <div className="col-6 p-1 d-inline-flex d-flex float-left">
                        <div className="col-md-12 d-flex justify-content-start p-0 m-t-4 font-I-10">
                          <input
                          id="sectorFilter"
                          className="with-gap radio-col-indigo" 
                          name="section-filter"
                          type="radio"
                          value="sector" 
                          defaultChecked={this.state.sectionFilter == "sector"}
                          onChange={this.handleSectionFilterChange}/>
                        <label htmlFor="sectorFilter" className="m-r-8">Sector</label>
                        <input
                              id="countryFilter"
                              className="with-gap radio-col-indigo" 
                              name="section-filter"
                              type="radio"
                              value="country" 
                              defaultChecked={this.state.sectionFilter == "country"}
                              onChange={this.handleSectionFilterChange} />
                        <label htmlFor="countryFilter">Country</label>                    
                        </div>                   
                  </div>
                  <div className="col-6 d-inline-flex p-0 m-t-5">
                    {this.state.sectionFilter !="sector" && <SelectableTagsOptionalAdd
                        id={this.state.sectionFilter}
                        options={this.state.sectionFilter=="sector"?sectorSubOptions:africaCountries}
                        onChange={this.state.sectionFilter=="sector"?this.handleSectorDropdownChange:this.handleCountryDropdownChange}
                    />   }               
                  </div>
                  <div className="col-12 d-flex d-inline-flex p-0 text-left justify-content-start">
                    {
                      this.state.sectionFilter=="sector"?
                      <SelectableTags
                        id={"sectors"}
                        key={"sectors"}
                        permanentOptions={Helper.makeSelectOptions(this.state.userSectorOptions)}
                        options={this.state.sectorTemporaryOptions}
                        selectedOptions={sectorOptions.filter(x=>this.state.sectorIds?this.state.sectorIds.includes(x.value):false)}
                        enableChange={!this.state.loading} 
                        onChange={this.handleSectorChange}
                        />
                        :
                        <SelectableTags
                        id={"countries"}
                        key={"countries"}
                        permanentOptions={africaPrefferedCountries}
                        options={this.state.countryTemporaryOptions}
                        selectedOptions={countryOptions.filter(x=>this.state.countryCodes.includes(x.value))}
                        enableChange = {!this.state.loading} 
                        onChange={this.handleCountryChange}
                        />
                    }
                    
                  </div>
               </div>
              }
                    
               </div>
               {
                   editSearch ? 
                   <div className="col-md-12 float-left text-left">
                     {/* <h3 className="text-black font-16 p-0 underline">Search for: </h3> */}
                   </div>
                   :
                    <div className="lcol-md-12 float-left text-left p-0">
                      
                   </div>
               }
             { editSearch  ? 
                  <div className="card-body">
                  <div className="col-12 p-0 text-left">
                  <form className="shortForm">
                  <div className="row">
                      <div className="col-md-12">
                          <div className="form-group">
                              <label className="control-label font-bold font-18">Company</label>
                              <CompanySearch onChange={this.onChangeCompany} defaultSelected={!Helper.isEmpty(this.state.companyValue) ? this.state.companyValue : ""}/>
                          </div>
                      </div>
                    
                  </div>
                  <div className="row">
                      <div className="col-md-12">
                          <div className="form-group">
                              <label className="control-label font-bold font-18">Contains Words</label>
                              <input type="text" id="contain-words" className="form-control" name="contain-words" onChange={this.changeWords} defaultValue={this.state.search}/>
                          </div>
                      </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                        <div className="form-group has-search">
                            <label className="control-label font-bold font-18">From Date</label>
                               <span className="form-control-feedback"><i className="mdi mdi-calendar-range font-24"></i></span>
                               <DatePicker className="form-control"
                                    selected={this.state.startDate}
                                    onChange={this.handleStartDateChange}
                                    dateFormat={config.getDateInDisplayFormat}
                                    placeholderText="From Date"
                                />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group has-search">
                            <label className="control-label font-bold font-18">To Date</label>
                                <span className="form-control-feedback"><i className="mdi mdi-calendar-range font-24"></i></span>
                                 <DatePicker className="form-control"
                                    selected={this.state.endDate}
                                    onChange={this.handleEndDateChange}
                                    dateFormat={config.getDateInDisplayFormat}
                                    placeholderText="To Date"
                                />
                        </div>
                    </div>
                  </div>
                 
                  <div className="row m-t-10">
                      <div className="col-md-12">
                            <button className="btn btn-theme float-right text-white btn-normal font-16" onClick={this.doSearch}>Search</button>
                        </div>
                   </div>
                 </form>
                </div>
                </div>

                :
                <div className="card-body p-t-0">
                    <div className="col-12 p-0 text-left m-t-10">
                      {/* <h3 className="text-dark-info font-18">Search Results for:</h3> */}
                      {!Helper.isEmpty(this.state.search) ? <h3 className="font-12 m-t-0 l-h-22 m-b-5">Contains words: <span className="font-normal">{this.state.search}</span></h3> : ""}
                      {!Helper.isEmpty(this.state.companyName) ? <h3 className="font-12 m-t-0 l-h-22 m-b-5">Company: <span className="font-normal">{companyName}</span></h3> : ""}
                      {!Helper.isEmpty(this.state.selectedSectors) ? <h3 className="font-12 m-t-0 l-h-22 m-b-5">Sector: <span className="font-normal">{selectedSectors.join(", ")}</span></h3> : ""}
                      {!Helper.isEmpty(this.state.selectedInds) ? <h3 className="font-12 m-t-0 l-h-22 m-b-5">Industry: <span className="font-normal">{selectedInds.join(", ")}</span></h3> : ""}
                      <h3 className="font-12  m-t-0 l-h-22 text-black m-b-5">
                          {Helper.isValidDate(this.state.startDate) && Helper.isValidDate(this.state.endDate) ? "Date: " :""}
                          {Helper.isValidDate(this.state.startDate) && !Helper.isValidDate(this.state.endDate) ? "From: " :""}
                          {!Helper.isValidDate(this.state.startDate) && Helper.isValidDate(this.state.endDate) ? "To: " :""}
                          {Helper.isValidDate(this.state.startDate) ? <span className="font-normal text-black">{startDate }</span> : ""}
                          {Helper.isValidDate(this.state.startDate) && Helper.isValidDate(this.state.endDate) ? " to " :""}
                          {Helper.isValidDate(this.state.endDate) ? <span className="font-normal text-black">{endDate }</span>: ""}
                        </h3>
                    </div>
                    {/* <div className="col-12 p-0 text-left">
                        <a href="javascript:void(0)" className="text-dark-info float-right font-16" onClick={this.doSearch}>Edit Search Criteria</a>
                    </div> */}
                </div>
            
             }
              
          </div>
          {enableSearch ?
          <Feeds mode={this.state.mode} payload={payload} pageLoadIndex={this.state.pageLoadIndex} page={page}/>
          :
          ""
          }
          
      </div>
    )
  }
}

export default connect(null,{ getMetaData , getSectorData ,storeNewsSearch})(withRouter(NewsContent));