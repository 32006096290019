import React, { Component } from 'react';
import Register from "../auth/Register";
import config from '../../shared/config';
import { login , fetchUserInfo } from '../../actions/authAction';
import { withRouter ,Redirect ,Link} from 'react-router-dom';
import store from '../../store';
import Helpers from '../../shared/custom';
import { connect } from 'react-redux';
import LinkedInAuth from '../auth/LinkedInAuth';

class PublicHomepage extends Component {

    constructor(props){
        super(props)
        this.state = {
            query:undefined,
            auth:{},
            showLogin:true,
            localProps:{}
        }
        this.openSearch = this.openSearch.bind(this);
        this.handleFieldValueChange = this.handleFieldValueChange.bind(this);
        this.showNextForm = this.showNextForm.bind(this);
    }  

    
    openSearch(e){
        let currentPath = window.location.pathname;
        let newPath = "/search"; 
        if(e.keyCode == 13){
            if(currentPath != newPath){
                let query = e.target.value;
                this.props.history.push({
                    pathname: newPath,
                    search: '?from=home&query='+query
                });
            }
        }
        
    }

    showNextForm(e){
        e.preventDefault();
        this.setState({
            showLogin : this.state.showLogin ? false :true
        })
    }

    componentDidMount(){
      
        var storeState = store.getState();
  
        var auth = storeState.auth;
        if(auth.isAuthenticated == true){                   
            let user = auth.user;
            setTimeout(() => {
               
                //this.props.fetchUserInfo(); 
                if(user.fullName){
                    
                }
            });
           
        }
            
    }

    componentWillReceiveProps(props){
     if(JSON.stringify(this.state.localProps) != JSON.stringify(props)){
         this.setState({
             localProps:props
         })
        var auth = props.auth;
        if(auth.isAuthenticated == true){                   
            let user = auth.user;
            setTimeout(() => {
               
                //this.props.fetchUserInfo(); 
                if(user.fullName){
                    //Helpers.pushSuccess("Welcome "+user.fullName+".");
                }
            });
           
        }
     }
    }

    handleFieldValueChange(e){
        let query = e.target.value;
        this.setState({ query:query })
    }

  render() {
    var showLogin = this.state.showLogin;
    return (
      <div>

      <div className="container-fluid p-0 plusBg">
        { showLogin ? 
            <div className="row parent home-component m-a">
                <div className="col-md-6">
                        <div className="logoContainer">
                              <img src={config.dataCdnStatic +'plus-logo.png?'}  alt="homepage" className="logo-home-new" />
                        </div>
                        <div className="home-desc">
                            <h3 className="desc-head">Join Investera Plus Today</h3>
                            <div className="col-md-8 m-a">
                                <p className="desc-detail">
                                    Enjoy full data coverage on public and private companies with a suite of financial intelligence features as easy as one click!
                                </p>
                            </div>
                            <p className="font-12 font-bold">Startups, investors, opportunities, trending events, and more</p>
                        </div>
                        <button onClick={this.showNextForm} className="registerBtn btn createAccBtn font-14 btn-block text-uppercase waves-effect waves-light m-t-10">Create Account</button>
                </div>
                <div className="col-md-6">
                        <p className="text-black slogan m-b-5 font-bold"><label className="" htmlFor="globalSearch">Looking for an investor or investment opportunity?</label></p>
                        <div className="form-group has-search searchContainer text-center">
                            <i className="material-icons form-control-feedback icon" aria-label="Search Icon" aria-pressed="false">
                                search
                            </i>
                            
                            <input type="text" className="form-control globalSearch loginInput" name="globalSearch" id="globalSearch" placeholder="Search 1000’s of companies and investors on Investera" onKeyDown={this.openSearch}  />
                        </div>
                        <div className="login-box-new clearfix">
                                <h3 className="desc-head">Log In </h3>
                                <div className="col-md-12">
                                  <Register page="newHome" subPage="login"/>
                                </div>
                               
                        </div>
                </div>
            </div>
            :
            <div className="row parent home-component">
                <div className="col-md-6">
                <div className="logoContainer">
                          <img src={config.dataCdnStatic +'plus-logo.png?'} alt="homepage" className="logo-home-new" />
                        </div>
                        <div className="home-desc">
                            <h3 className="desc-head">Welcome Back</h3>
                            <p className="desc-detail">To keep connected with us please login with your personal info.</p>
                        </div>
 
                        <button onClick={this.showNextForm} className="registerBtn btn createAccBtn font-14 btn-block text-uppercase waves-effect waves-light  m-t-10">Log In</button>
                </div>
                <div className="col-md-6">
                         <p className="text-black slogan m-b-5 font-bold">Looking for an investor or investment opportunity?</p>
                        <div className="form-group has-search searchContainer text-center">
                                    <i className="material-icons form-control-feedback icon" aria-label="Search Icon" aria-pressed="false">
                                        search
                                    </i>
                                    <label className="hidden" htmlFor="globalSearch">Looking for an investor or investment opportunity?</label>
                                    <input type="text" className="form-control globalSearch loginInput"  name="globalSearch" id="globalSearch" placeholder="Search 1000’s of companies and investors on Investera" onKeyDown={this.openSearch}  />
                                </div>
                                <div className="login-box-new clearfix">
                                        <h3 className="desc-head">Register New Account </h3>
                                        <div className="col-md-12">
                                          <Register page="newHome"/>
                                        </div>
                                    
                                </div>
                        </div>
            </div>

        }
               
         <footer className="homeFooter col-12">
                <div className="container-fluid footer-fluid">
                        <ul className="bd-footer-links text-left text-black font-14 font-medium">
                            <li className="text-black">Investera Plus Africa &copy; 2021</li>
                            <li><a href="#" target="_blank" className="text-black" title="about us" rel="noreferrer">About Us</a></li>
                            <li><a href="#" target="_blank" className="text-black" title="Privacy Policy" rel="noreferrer">Privacy Policy</a></li>
                            <li><a href="#" target="_blank" className="text-black" title="End User License" rel="noreferrer">End User License</a></li>
                            <li><a href="#" target="_blank" className="text-black" title="Cookies Policy" rel="noreferrer">Cookies Policy</a></li>
                        </ul>
                </div>
        </footer>
       </div>

      
     </div>
    )
  }
}



const mapStateToProps = (state) =>({
    auth:state.auth,
    errors:state.errors
});

export default connect(mapStateToProps,{login,fetchUserInfo})(withRouter(PublicHomepage));
