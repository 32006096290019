import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import DatePicker from "react-datepicker";
import config from "../../shared/config"
import Select from 'react-select';
export default class EventForm  extends Component {
  render() {
    let values = this.props.values;
    return (
      <div>
                <div className="row">
                    {/* <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label font-bold font-18">Sector</label>
                            <Select                            
                                className="font-14 col-md-12 p-0 m-0 zIndexIncreased"
                                name="sector"
                                options={values.sectorOptions}
                                placeholder="Select sector"
                                value={values.sector}
                                onChange={this.props.onSectorChange}
                                isClearable={true}
                            />
                        </div>
                    </div> */}
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label font-bold font-18">Industry</label>
                            <Select                            
                                className="font-14 col-md-12 p-0 m-0 zIndexIncreased"
                                name="industry"
                                options={values.industryOptions}
                                placeholder="Select industry"
                                value={values.industry}
                                onChange={this.props.onSelectChange}
                                isClearable={true}
                                isMulti
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                                <div className="form-group  has-search">
                                    <label className="control-label font-bold font-18">Date</label>
                                    <span className="form-control-feedback"><i className="mdi mdi-calendar-range font-24"></i></span>
                                    <DatePicker className="form-control"
                                        name="date"
                                        selected={values.date}
                                        onChange={(event) => this.props.onDateChange(event,"date")}   
                                        dateFormat="d-MMM-yyyy"                                  
                                    />
                                </div>
                            </div>
                </div>
      </div>
    )
  }
}
