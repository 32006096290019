import React, { Component } from 'react';
import config from '../../shared/config';
import { connect } from 'react-redux';
import { getMetaData } from '../../actions/metaAction';
import { withRouter } from 'react-router-dom';
import EditMultiSelect from "../ui/EditMultiSelect";
import { getSectorData } from '../../actions/businessAction';
import Axios from 'axios';
import store from '../../store';
import Helper from "../../shared/custom";
import ApiHelper from "../../shared/ApiHelper";
import Select from 'react-select';
import CompanySearchPublished from '../wigets/CompanySearchPublished';


import countries from "../../shared/country";
import { checkServerIdentity } from 'tls';
import Helpers from '../../shared/custom';

class GraphFilter extends Component {

  constructor(props){
      super(props);
      this.state = {
        countryOptions:[],
        cityList:[],
        
        sectorOptions : [],
        industriesOption:[],
        industriesAllOption:[],
        industriesArray:[],
        sectorsValue:[],
        indValue:[],
        business:[],
        metaList:{},
        excludeUnlink:true,
       
        ownershipStatusOptions:[],
        localMetaList:{},
        companyType:{},

        country:{value: "103", label: "Kenya"},
        city:{value: "3857", label: "Nairobi"},
        ownershipStatus:undefined,
        sectorIds:[],//sector
        industryIds:[],//industry
        companyUri:undefined,
        selectedCompany:null,
        selectedCompanyType:[],//companyType
        mode:undefined,
        tag:undefined,
        areaServed:undefined,
        companyValue : undefined,
        showFilter:false
        

      }

    //   this.openNav = this.openNav.bind(this);
      this.toggleFilter = this.toggleFilter.bind(this);
      this.hideFilter = this.hideFilter.bind(this);
      this.setCityList = this.setCityList.bind(this);
      this.handleCityChange  = this.handleCityChange.bind(this);
      this.handleCountryChange = this.handleCountryChange.bind(this);
      this.handleSectorChange = this.handleSectorChange.bind(this);
      this.handleIndustryChange = this.handleIndustryChange.bind(this);
      this.filterGraph = this.filterGraph.bind(this);
      this.ownershipChange = this.ownershipChange.bind(this);
      this.onChangeSearch =  this.onChangeSearch.bind(this);
      this.handleCompanyType = this.handleCompanyType.bind(this);
      this.onChangeExclude = this.onChangeExclude.bind(this);
      this.clearCompanySearch = this.clearCompanySearch.bind(this);
      this.getCurrentLocation =  this.getCurrentLocation.bind(this);

      this.companySearch = React.createRef();
     


      store.subscribe(() => {
        var storeState = store.getState();
        let localBusiness = this.state.business;
        var storeBusiness = storeState.business.data;
        if(!localBusiness || JSON.stringify(localBusiness) != JSON.stringify(storeBusiness)){
          this.setState({business:storeBusiness.data},()=>{
            if (!Helper.isEmpty(storeBusiness)) {
              this.setState({
                sectorOptions : Helper.makeSelectOptions(storeBusiness.sectorsArray),
                industriesOption : Helper.makeSelectOptions(storeBusiness.industriesArray),
                industriesAllOption : storeBusiness.industriesArray
              })
              
            }
          });
        }

        var localMetaList = this.state.metaList;
        var storeMetaList = storeState.meta.metaList; 
        // if(!localMetaList || JSON.stringify(localMetaList) != JSON.stringify(storeMetaList)){
        //     this.setState({metaList:storeMetaList},()=>{
        //         let ownershipStatusOptions = Helper.makeSelectOptions(storeMetaList.COS);
        //         this.setState({
        //           ownershipStatusOptions:ownershipStatusOptions,
        //          })
        //     });

           
        // }

      });

  }  

  toggleFilter(){
    var element = document.getElementById("absolutedDiv");
    element.classList.toggle("divShow");
    this.setState({
      showFilter: this.state.showFilter ? false:true,
    })
  }

  hideFilter(){
    var element = document.getElementById("absolutedDiv");
    element.classList.remove("divShow");
    this.setState({
      showFilter: false,
    })
  }

  fetchMeta= ()=>{
    Axios.get(
      Helpers.apiURL({
          uri:"meta/list/COS",
        })
    )
    .then( (response) => {
        let pbOption = response.data.result || {};
        this.setState({
          ownershipStatusOptions:Helpers.makeSelectOptions(pbOption)
        })
        
    })
    .catch((err) => {
       
    });
  }


  onChangeSearch(e){
    if(!Helpers.isEmpty(e[0])){
      this.setState({
        companyUri:e[0].uri,
        mode:"details",
        companyValue:e,
      },()=>{
        this.setState({
           country:null,
           city:null,
           sectorsValue:null,
           indValue:null,
           ownershipStatus:null,
        });
        document.querySelectorAll('.companyTypeElm').forEach((e)=>{
          e.checked = false;
        });
        this.props.onChangeCompany(this.state.companyUri);
        this.hideFilter();
      })
    }
  }

  componentDidMount(){
    this.getCurrentLocation();
    var storeState = store.getState();      
    let meta = storeState.meta;
    let countryOptions = Helper.makeSelectOptions(countries);
    let cityOptions = [];
    let ownershipStatusOptions = [];
    this.toggleFilter();
    
    this.fetchMeta();

    this.setState({
        countryOptions:countryOptions,
    });

    this.getBusinessData();

    if(Helper.isEmpty(meta.metaList) == true){
        this.props.getMetaData();
     }else{
        this.setState({metaList:meta.metaList});
     }

    // if(meta.metaList && meta.metaList.COS){
    //     ownershipStatusOptions = Helper.makeSelectOptions(meta.metaList.COS);
    //     this.setState({
    //         ownershipStatusOptions:ownershipStatusOptions,
    //     })
    // }

    if(!Helper.isEmpty(config.companyTypes)){
        this.setState({
            companyType:config.companyTypes
        })
    }

    let cityOptionLength = this.state.cityOptions ? this.state.cityOptions.length : 0;
    if(this.state.country && this.state.country.value && cityOptionLength == 0){
      this.handleCountryChange(this.state.country);
    }
  }




  componentWillReceiveProps(props){
    this.getBusinessData();
  
  }


  getCurrentLocation(){
   
  }

 getBusinessData(){
   var storeState = store.getState();
   let business = storeState.business;
 
   if(Helper.isEmpty(business.data) == true){
       this.props.getSectorData();
   }else{
       this.setState({business:business.data},()=>{
         if (!Helper.isEmpty(this.state.business)) {
           let business = this.state.business;
           this.setState({
             sectorOptions : Helper.makeSelectOptions(business.sectorsArray),
             industriesOption : Helper.makeSelectOptions(business.industriesArray),
             industriesAllOption : business.industriesArray
           })
           
         }
       });
   }
 }


  handleCityChange(option){
    this.setState({city: option});
  }

  handleCountryChange(option){   
    this.setState({country: option});  
    if(this.state.country.value !== option.value){
      this.setState({
        city:null
      })
    }  
    let countryId = option ? option.value : null;
    if(countryId){
        ApiHelper.getCities(countryId,this.setCityList);
    }
 }

 ownershipChange(option){
    this.setState({
      selectedOwnershipStatus:option.value
    })
 }
 handleSectorChange(e){
    let sectorsValue = []
    let sectorIds = [];
    let sectorLabels = [];
    e.forEach((data,i)=>{
      sectorIds.push(data.value);
      sectorLabels.push(data.label);
      sectorsValue.push(data)
    });
    this.setState({
      sectorIds:sectorIds,
      selectedSectors:sectorLabels,
      sectorsValue:sectorsValue,
      industriesOption:Helpers.industriesBySector(this.state.industriesAllOption, sectorsValue)
    });
    
  }

  handleCompanyType(e){
    let selectedCompanyType  = this.state.selectedCompanyType;
    var selectedValue = e.target.value;
    if(e.target.checked){
      selectedCompanyType.push(selectedValue)
    }
    else{
      let index = selectedCompanyType.indexOf(selectedValue);
      if ((index !== -1)) {
        selectedCompanyType.splice(index, 1);
      }
    }
    this.setState({
      selectedCompanyType:selectedCompanyType
    })
  }
  onChangeExclude(e){
    var excludeUnlink = e.target.value;
      this.setState({
        excludeUnlink:e.target.checked
      })
  }

  handleIndustryChange(e){
    let industryIds = [];
    let industryLabels = [];
    let indValue =[]
    e.forEach((data,i)=>{
      industryIds.push(data.value);
      industryLabels.push(data.label);
      indValue.push(data);
    });
    this.setState({
      industryIds:industryIds,
      selectedInds:industryLabels,
      indValue:indValue
    })
  }

setCityList(cityList){
    this.setState({cityList:cityList})
}

filterGraph(){
  
  let state = this.state;
  let payload = {};
  payload.country = state.country && state.country.value ? state.country.value : null;
  payload.countryName = state.country && state.country.label ? state.country.label : null;
  payload.city = state.city && state.city.value ? state.city.value :null;
  payload.cityName = state.city && state.city.label ? state.city.label :null;
  payload.sector = state.sectorIds.join(",");
  payload.sectorNames = this.state.selectedSectors?this.state.selectedSectors.join(","):[]
  payload.industry = state.industryIds.join(",");
  payload.industryNames = state.selectedInds? state.selectedInds.join(","):[];
  payload.companyType = state.selectedCompanyType.join(",");
  payload.ownershipStatus = state.selectedOwnershipStatus;
  payload.linked = state.excludeUnlink;
  payload.mode= "filter";
  this.setState({
    mode:"filter",
    companyUri:null,
    companyValue:null,
  });
  this.props.getFiterData(payload);
  this.hideFilter();
  this.clearCompanySearch();

}

clearCompanySearch(){
 
}

  render() {
    let countryOptions = this.state.countryOptions;
    let cityList = this.state.cityList;
    let cityOptions = [];
    cityList.forEach(function(item,index){
      cityOptions.push({value:item.id,label:item.name});
    });
    let sectorOptions = this.state.sectorOptions;
    let industriesOption = this.state.industriesOption;
    let sectorsValue = this.state.sectorsValue;
    let indValue = this.state.indValue;
    let ownershipStatusOptions = this.state.ownershipStatusOptions;
    let companyType = this.state.companyType;
    let icon = this.state.showFilter ? 'fa-times' : 'fa-list';
 

    return (
      <div className="absolutedDiv " id="absolutedDiv">
      <div  className="filter">
      <a href="javascript:void(0)" className="closebtn" onClick={this.toggleFilter}><i className={"fa "+icon}></i></a>
       <div className="row">
            <div className="graphSearch">
                <div className="form-group col-12 p-b-0">
                    <h6 className="font-bold text-white">Search <i className="r-font-family font-14 ">i</i>Knowledge Graph</h6>
                    <div className="form-group has-search m-b-0 k-graph">
                      <i className="material-icons form-control-feedback">search</i>
                          <CompanySearchPublished 
                                title="" 
                                headingclassName="hidden" 
                                onChange={this.onChangeSearch} 
                                name="acquiredCompanyName"  
                                onClear={this.clearSearchInput}
                             //   multiple = {true}
                                ref="companySearch"
                                clearSearch = {click => this.clearCompanySearch = click}
                          />
                        </div>
                    
                </div>
            </div>
        </div>
        <div className="col-12 m-t-20 p-0">
            <h4 className="box-title m-b-10 font-medium line-header">or Search By:</h4>
            <div className="form-group m-b-10">
                <h6 className="font-bold">Country</h6>
                <Select name="country" value={this.state.country} className="font-14" options={countryOptions}  onChange={this.handleCountryChange.bind(this)} isClearable={false}/> 
            </div>
            <div className="form-group m-b-10">
                <h6 className="font-bold">City</h6>
                <Select value={this.state.city}  className="font-14"  options={cityOptions} onChange={this.handleCityChange.bind(this)} isClearable={true} />
            </div>
        
            <div style={{maxWidth:"250px"}}>
            <EditMultiSelect
                    name="sector"
                    options={sectorOptions}
                    onChange={this.handleSectorChange}
                    additionalClassName="form-group m-b-enable"
                    value={sectorsValue}
                    title="Sector"
                    titleclassName="font-bold font-14"
                    />
            </div>
            <div style={{maxWidth:"250px"}}>
            <EditMultiSelect
                    name="industry"
                    value={this.state.industryIds}
                    options={industriesOption}
                    onChange={this.handleIndustryChange}
                    additionalClassName="form-group m-b-enable"
                    value={indValue}
                    title="Industry"
                    titleclassName="font-bold font-14"
                    />
              </div>
         
            <div className="form-group m-b-10">
                <h6 className="font-bold">Company Type</h6>
                <ul className="list-style-none ">
                  {!Helper.isEmpty(companyType) ?
                      companyType
                          .map((list, index) => (
                              <li key={index}>
                                  <input type="checkbox" id={list.type}  value={list.type} index={index} className="filled-in chk-col-blue selectFilter companyTypeElm" onChange={this.handleCompanyType}/>
                                  <label htmlFor={list.type}>{list.name}</label>
                              </li>
                            
                        ))
                      :
                      ""
                  }
                </ul>               
            </div>

            <div className="form-group m-b-10">                
                <ul className="list-style-none ">
                        <li>
                            <input type="checkbox" id={"linked"} name="linked"  value={"value"} className="filled-in chk-col-blue selectFilter" defaultChecked={this.state.excludeUnlink} onChange={this.onChangeExclude}/>
                            <label htmlFor={"linked"}>Show Linked Entities Only</label>
                        </li>
                </ul>               
            </div>

            <div className="form-group m-t-0 text-center">
                <a href="javascript:void(0)" onClick={this.filterGraph} className="btn btn-info">Apply Filters</a>
            </div>            
            <div className="form-group m-b-10">
              <hr/>
            </div>
        </div> 
 
</div>
        
      </div>
    
    )
  }
}


export default connect(null,{ getMetaData , getSectorData})(withRouter(GraphFilter));