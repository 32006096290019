import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import store from "../../store";
import createHistory from 'history/createBrowserHistory';
import Helpers from '../../shared/custom';
import { globalSearch , setTabStatus} from "../../actions/searchAction";
import SearchBanner from "./SearchBanner";
import Loader from "../ui/Loader";
import STR from "../../shared/strings";

class Search extends Component {
    
    constructor(props){
        super(props);        
        this.state = {
            tabStatus:"all",
            searchResults:[],
            renderTime:null,
            loading:false,   
            localProps:{}      
        }        

        store.subscribe(() => {
            this.updateSearchResults();
        });  
       // this.props.setTabStatus("all");
        this.handleTabStatus = this.handleTabStatus.bind(this);
        this.renderSearchBanner = this.renderSearchBanner.bind(this);
    }
    

    updateSearchResults = () => {
        var storeState = store.getState();
        var searchState = storeState.search;
        if(!searchState){
            return false;
        }

        var renderTime = this.state.renderTime || new Date();
        var searchTime = searchState.searchTime || (renderTime+1);
        this.setState({
             renderTime:searchTime,
             searchResults:searchState.searchResults,
         }); 
    }

    componentDidMount(){
        const history = createHistory();
        //if coming back from another page
        if (history.action == "POP"){
            this.updateSearchResults();
        }

        // this.setState({
        //     tabStatus:"all"
        // })
        // this.props.setTabStatus("all");

        let searchResults = this.props.searchResults;
        let currentTab = searchResults.tabStatus;

        this.setState({
            tabStatus:currentTab
        })
        window.scrollTo(0,0);

    }
    componentWillReceiveProps(props){
            if(JSON.stringify(this.state.localProps) != JSON.stringify(props)){
                let searchResults = props.searchResults;
                let currentTab = searchResults.tabStatus;
            }
    }


    handleTabStatus(event){
         let tabStatus = event.target.getAttribute("tabtype");
         this.setState({
            tabStatus: tabStatus
         });
         this.props.setTabStatus(tabStatus);
    }
    

 //   Next function replacment to show Entities without pages
    renderSearchBanner(item,type,index){

       
        let uniqueStr = "";
        let entry = null;
        let company = null;
        if(item.page){
            entry = item.page;
            uniqueStr = item.page.uri;
            company = item.company
        }else{
            entry = item;
            uniqueStr = item.uniqueId;
        }

       
        
        return (<div key={"sc-"+type+"-"+uniqueStr+index}>
                    <SearchBanner entry={entry} type={type} company={company}/>
                </div>);
    }


    
    // renderSearchBanner(item,type){
    //     if(!item.page){
    //         return (<div></div>);
    //     }
    //     let entry = item.page;
    //     return (<div key={"sc-"+type+"-"+item.page.uniqueId}>
    //                 <SearchBanner entry={entry} type={type} />
    //             </div>);
    // }

    render() {

    let searchResults = this.state.searchResults;
    let searchEmpty = searchResults && searchResults.length > 0 ? false : true;
    let loading = this.state.loading;

   
    return (
      <div>
        <div className="row contact-result">
     
                <div className="col-md-12 col-lg-12 col-xlg-12 p-0">
                    <div className="card card-body">
                        <h5 className="font-bold text-left navHeader">Result</h5>
                        <ul className="nav nav-pills tabView nav-justified horiScroll font-14 text-left">
                            <li className="nav-item">
                                 <a className={this.state.tabStatus == "all"  ? "nav-link-c active" : "nav-link-c"} data-toggle="tab" href="#allSearch" tabtype="all" onClick={this.handleTabStatus}>All</a>
                            </li>
                            <li className="nav-item">
                                <a className={this.state.tabStatus == "companies"  ? "nav-link-c active" : "nav-link-c"} data-toggle="tab" href="#companySearch" tabtype="companies" onClick={this.handleTabStatus}>Companies</a>
                            </li>
                            <li className="nav-item">
                                <a className={this.state.tabStatus == "startups"  ? "nav-link-c active" : "nav-link-c"} data-toggle="tab" href="#startupSearch" tabtype="startups" onClick={this.handleTabStatus}>Startups</a>
                            </li>
                            <li className="nav-item">
                                <a className={this.state.tabStatus == "investors"  ? "nav-link-c active" : "nav-link-c"} data-toggle="tab" href="#investorsSearch" tabtype="investors" onClick={this.handleTabStatus}>Investors</a>
                            </li>
                            <li className="nav-item">
                                <a className={this.state.tabStatus == "people"  ? "nav-link-c active" : "nav-link-c"} data-toggle="tab" href="#peopleSearch" tabtype="people" onClick={this.handleTabStatus}>People</a>
                            </li>
                            
                            <li className="nav-item">
                                <a className={this.state.tabStatus == "opportunities"  ? "nav-link-c active" : "nav-link-c"} data-toggle="tab" href="#opportunitySearch" tabtype="opportunities"  onClick={this.handleTabStatus}>Opportunites</a>
                            </li>
                            <li className="nav-item">
                                <a className={this.state.tabStatus == "events"  ? "nav-link-c active" : "nav-link-c"} data-toggle="tab" href="#eventSearch" tabtype="events"  onClick={this.handleTabStatus}>Events</a>
                            </li>
                        </ul>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 col-xlg-12 p-0 text-right">
                    <div className="text-black font-14 m-t-5 m-b-5">
                        Didn’t find what you were looking for? 
                        <Link to="/search/advanced?t=common"> Try advanced search</Link>
                    </div>
                </div>
                <div className="tab-content">
                    <div id="allSearch" className={this.state.tabStatus == "all" ? "tab-pane fade in active show" : "tab-pane fade in "}>
                        {
                            searchResults && !searchEmpty? 
                                searchResults.map((result, index) => (
                                    this.renderSearchBanner(result,"all",index)
                                )):
                            STR.NO_DATA_FOUND
                        }
                    </div>

                   

                    <div id="companySearch" className={this.state.tabStatus == "companies" ? "tab-pane fade in active show" : "tab-pane fade in"}>
                        {
                        searchResults && !searchEmpty?
                            searchResults.map((result, index) => (
                                this.renderSearchBanner(result,"company",index)
                            )):
                        STR.NO_DATA_FOUND
                        }
                    </div>
                    <div id="startupSearch" className={this.state.tabStatus == "startups" ? "tab-pane fade in active show" : "tab-pane fade in"}>
                        {
                        searchResults && !searchEmpty?
                            searchResults.map((result, index) => (
                                this.renderSearchBanner(result,"startup",index)
                            )):
                        STR.NO_DATA_FOUND
                        }
                    </div>
                    
                    <div id="investorsSearch" className={this.state.tabStatus == "investors" ? "tab-pane fade in active show" : "tab-pane fade in"}>
                        {
                        searchResults && !searchEmpty?
                            searchResults.map((result, index) => (
                                this.renderSearchBanner(result,"investor",index)
                            )):
                        STR.NO_DATA_FOUND
                        }

                    </div>

                    <div id="peopleSearch" className={this.state.tabStatus == "people" ? "tab-pane fade in active show" : "tab-pane fade in"}>
                        {
                        searchResults && !searchEmpty?
                            searchResults.map((result, index) => (
                                this.renderSearchBanner(result,"people",index)
                            ))
                        :
                        STR.NO_DATA_FOUND
                        }
                    </div>

                    <div id="opportunitySearch" className={this.state.tabStatus == "opportunities" ? "tab-pane fade in active show" : "tab-pane fade in"}>
                        {
                        searchResults && !searchEmpty?
                            searchResults.map((result, index) => (
                                this.renderSearchBanner(result,"opportunity",index)
                            )):
                        STR.NO_DATA_FOUND
                        }                   
                    </div>
                    <div id="eventSearch" className={this.state.tabStatus == "events" ? "tab-pane fade in active show" : "tab-pane fade in"}>
                        {
                        searchResults && !searchEmpty?
                            searchResults.map((result, index) => (
                                this.renderSearchBanner(result,"event",index)
                            )):
                        STR.NO_DATA_FOUND
                        }               
                    </div>

                </div>

           </div>
      </div>
    )
  }
}

const mapStateToProps = (state) =>({
    searchResults:state.search,
    errors:state.errors,
    tabStatus:state.tabStatus,
});

export default connect(mapStateToProps,{globalSearch,setTabStatus})(withRouter(Search));
