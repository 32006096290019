import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Helpers from '../../shared/custom';
import Axios from 'axios';
import STR from '../../shared/strings';
import $ from 'jquery';

class ViewInvestors extends Component {

 constructor(props){
    super(props);
    this.state = {
        fundingRounds: [],
        fundingRoundInvestors:[],
        totalInvestors:0,
        leadInvestors:0,
        uri:undefined
    }
    this.fetchFundingRounds = this.fetchFundingRounds.bind(this);
    this.renderFundingRoundRow = this.renderFundingRoundRow.bind(this);
    this.processInvestors = this.processInvestors.bind(this);
 }

 
 componentDidMount(){
     this.setState({
         uri:this.props.uri
     },()=>{
        this.fetchFundingRounds();
    })
 }

 componentWillReceiveProps(nextProps){
     let newUri = nextProps.uri;
     let currentUri = this.state.uri;

     if(!currentUri || currentUri != newUri){
         this.setState({
             uri:newUri
         },()=>{
             this.fetchFundingRounds();
         })
     }
 }

 fetchFundingRounds(){
    let uri = this.state.uri;
    if(!uri){
      return false;
    }

    Axios.get(
      Helpers.apiURL({
        uri:"company/:uri/funding-rounds",
        pathVar: {uri:uri}
      })
    )
    .then((response) => {
      let result = response.data.result;
      if(result){       
        this.processInvestors(result); 
      }
    })
    .catch((response) => {
      
    })
 }

 processInvestors(fundingRounds){
    if(!fundingRounds){
        this.setState({
            fundingRounds:[],
            fundingRoundInvestors:[]
        })
    }

    let fundingRoundInvestors = [];
    let uniqueInvestors = [];
    let leadInvestors = [];
    fundingRounds.forEach(round => {
        let investorMappings = round.investorMappings;
        if(investorMappings){
            investorMappings.forEach(entry => {
                let investor = {
                    name:entry.investor ? entry.investor.name : "-",
                    uri:entry.investor ? entry.investor.uri : undefined,
                    type:entry.investor ? entry.investor.type : undefined,
                    partners:entry.partners,
                    amount:entry.amount,
                    currency:entry.currency,
                    lead:entry.lead,
                    investorType:entry.investorType,
                    investorTypes:entry && entry.investor && entry.investor.investorTypes ? entry.investor.investorTypes : [],
                    fundingStage:round.fundingStage,
                    fundingType:round.fundingType
                };
                fundingRoundInvestors.push(investor);
                if(uniqueInvestors.indexOf(entry.investor.uniqueId) < 0 ){
                    uniqueInvestors.push(entry.investor.uniqueId)
                }
                if(entry.lead){
                    leadInvestors.push(entry.lead);
                }
            });
        }
    });

    this.setState({
        fundingRounds:fundingRounds,
        fundingRoundInvestors:fundingRoundInvestors,
        totalInvestors:uniqueInvestors.length,
        leadInvestors:leadInvestors.length
    }, () => {
        if ($.fn.DataTable.isDataTable(this.refs.investorTable) === false){
            $(this.refs.investorTable).DataTable({searching: false, info: false, });
        }
    })
 }

 renderFundingRoundRow(data,index){    
    let fundingRound = data.fundingType ? data.fundingType.name : "";
    
    let investorName = data.name;
    let type = data.type || "company";
    type = type.toLowerCase();

    if(data.uri){
        investorName = (<Link to={"/"+type+"/"+data.uri}> {investorName} </Link>);
    }
    
    let partners = "";
    if(data.partners && data.partners.length > 0){
        partners = data.partners.join(", ");
    }

    let investorTypes = [];
  
    if(!Helpers.isEmpty(data.investorTypes)){
        data.investorTypes.forEach((item,i)=>{
            investorTypes.push(item.name)
        })
    }


  
    
    return(
        <tr key={"FRIN-"+index}>           
            <td>{investorName}</td>
            <td>{data.lead? STR.YES : STR.NO }</td>
            <td>{!Helpers.isEmpty(investorTypes) ? investorTypes.join(",") : "-"}</td>
            <td>{fundingRound}</td>            
            <td>{partners}
            </td>
        </tr>
    )
    
 }

 render() {
    let uri = this.state.uri;     
    let fundingRoundInvestors = this.state.fundingRoundInvestors;
    let totalInvestors = this.state.totalInvestors;
    let leadInvestors = this.state.leadInvestors;
    return (
      <div className="col-md-12 col-lg-12 col-xlg-12 p-0">
        <div className="card text-left bold-headers" id="investors">                        
            <div className="card-body">
                
                <div className="row m-b-10">
                    <div className="col-md-10 float-left">
                        <h5 className="m-t-0">Investors</h5>
                    </div>
                    <div className="col-md-2 text-right">
                        <Link to={"/financials/"+uri}>
                            <span data-type="ipo" className="text-info point">Edit</span>
                        </Link>
                        
                    </div>
                </div>
                
                <div className="row ">
                    <div className="col-md-6 float-left">
                        <div className="col-md-12 box float-left">
                            <div className="col-md-8 float-left topLabel">Number of Investors </div>
                            <div className="col-md-4 float-left font-bold font-14 text-right">{totalInvestors}</div>
                        </div>
                    </div>
                    <div className="col-md-6 float-left">
                        <div className="col-md-12 box float-left">
                            <div className="col-md-8 float-left topLabel">Total Lead Investors </div>
                            <div className="col-md-4 float-left font-bold font-14 text-right">{leadInvestors}</div>
                        </div>
                    </div>
                </div>
                {/* <h6>Funding Rounds</h6> */}
                <div className="table-responsive">
                        <table ref="investorTable" className="table color-table muted-table dataTable">
                            <thead>
                                <tr>
                                    <th>Investor Name</th>
                                    <th>Lead Investor</th>
                                    <th>Investor Type</th>
                                    <th>Funding Round</th>                               
                                    <th>Partners</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fundingRoundInvestors.map((data,index)=>(
                                    this.renderFundingRoundRow(data,index)
                                ))}                                            
                            </tbody>
                        </table>
                    </div>
                </div>                                
        </div>
      </div>
    )
  }
}
export default (withRouter(ViewInvestors));