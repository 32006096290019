import React, { Component } from 'react';
import Axios from 'axios';
import config from '../../shared/config';
import Helpers from '../../shared/custom';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import {fetchUserInfo, setToken} from '../../actions/authAction';
import setAuthTokenHeader from '../../utils/setAuthTokenHeader'; 
import store from '../../store';

export default class EmailConfirm extends Component {
  constructor(props){
      super(props)
      this.state ={
          loading : false
      }
  }  

  componentDidMount(){
    this.setState({
        loading : true
    });


    let location = window.location.pathname
    let confirmId = Helpers.lastSegment(location);

    Axios.post(
        config.api("user/confirm/email/"+confirmId),
        )
    .then((response) => {
        if(response.data.statusCode == null){
          Helpers.pushError("Unable to confirm Email, Please check the link.");
          setTimeout(()=>{
              this.setState({
                  loading : false
              });
              this.props.history.push('/login');
          },1000)
        }else {
          Helpers.pushSuccess("Thank you for confirming your Email.")
          setTimeout(()=>{
              this.setState({
                  loading : false
              });
              if('EMAIL_CONFIRMED_OTT' == response.data.statusCode){
                let token = response.data.result;
                setAuthTokenHeader(token);
                store.dispatch(setToken(token))
                fetchUserInfo();
                this.props.history.push('/');
              }else{
                this.props.history.push('/login');
              }
          },1000)
         
        }
    })
    .catch((err) => {
        Helpers.pushError("Unable to confirm Email, Please check the link.");
        setTimeout(()=>{
            this.setState({
                loading : false
            });
            this.props.history.push('/login');
        },1000)
    });

  }
  render() {
    let loading = this.state.loading;
    return (
      <div>
        <LoadingOverlay>
             <Loader fullPage loading={loading}/>
        </LoadingOverlay>
      </div>
    )
  }
}
