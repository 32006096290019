import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import CompanyForm from './CompanyForm';
import StartupForm from './StartupForm';
import InvestorsForm from './InvestorsForm';
import PeopleForm from './PeopleForm';
import OppForm from './OppForm';
import EventForm from './EventForm';
import FundingForm from './FundingForm';
import queryString from 'query-string';

import Helpers from '../../shared/custom';
import countries from '../../shared/country';
import ApiHelper from '../../shared/ApiHelper';
import Select, { createFilter } from 'react-select';
import store from '../../store';
import { getMetaData } from '../../actions/metaAction';
import { getSectorData } from '../../actions/businessAction';
import { getSubIndustriesData } from '../../actions/SubIndustryAction';
import { getIndustryGroupsData } from '../../actions/IndustryGroupAction';
import { storeAdvanceSearch } from '../../actions/searchAction';
import { EntityBlock } from './EntityBlock';
import { Loader } from 'react-overlay-loader';
import Axios from 'axios';
import { CreatePeople } from '../people/CreatePeople';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';



class AdvSearchContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "company",
            editSearch: true,
            preSearch: false,
            searchResults: [],
            loading: false,
            cityDisabled: false,
            metaList: undefined,
            business: undefined,
            subIndustries: undefined,
            industryGroups: undefined,
            metaReady: false,
            businessReady: false,
            roles: [],
            roleOptions: [],
            yearOptions: Helpers.yearOptions(),
            booleanOptions: Helpers.booleanOptions(),
            oppType:null,
            query: "",
            country: [],
            city: [],
            sector: null,
            industry: null,
            subIndustry: null,
            industryGroup: null,
            companyPsl: [],
            companySize: null,
            foundedYear: null,
            rating: null,
            revenueGenerating: null,
            isRaisingFund: null,
            fundRaisingAmount: null,
            currentInvestors: [],
            investorType: [],
            investorStage: null,
            peopleCompany: [],
            peoplePsl: [],
            professionalBodies: [],
            peopleJobTitle: undefined,
            peopleRole: null,
            investmentAmount: null,
            deadline: null,
            date: null,
            privileges: {},
            industryAllOptions: [],
            industryOptions: [],
            industryGroupOptions: [],
            subIndustryOptions: [],
            fundingStage: null,
            fundingType: null,
            isEquityFunding: null,
            showModal: false,
            localProps: {},
            showPeople: false,
            modal: false,
            pbOption: []
        }

        store.subscribe(() => {
            var storeState = store.getState();

            var storeMetaList = storeState.meta ? storeState.meta.metaList : null;
            if (storeMetaList) {
                this.setMetaOptions(storeMetaList);
            }

            var privileges = storeState && storeState.packageDetails && storeState.packageDetails.privileges ? storeState.packageDetails.privileges : {};


            if (privileges) {
                this.setState({
                    privileges: privileges
                });
                let q = Helpers.getQueryParameter("t")
                let type = this.state.type;

                if ((q == "people" || type == "people") && (privileges["FKP_BOD"] || privileges["PPL_ACCESS"])) {
                    this.setState({
                        showPeople: true,
                        modal: false
                    })
                }

                if ((q == "people" || type == "people") && (!privileges["FKP_BOD"] && !privileges["PPL_ACCESS"])) {
                    this.setState({
                        showPeople: false,
                        modal: true
                    })
                }
            }
            var storeBusinessList = storeState.business ? storeState.business.data : null;
            if (storeBusinessList) {
                this.setBusinessOptions(storeBusinessList);
            }

            var storeSubIndustriesList = storeState.subIndustries ? storeState.subIndustries.data : null;
            if (storeSubIndustriesList) {
                this.setSubIndustryOptions(storeSubIndustriesList);
            }

            var storeIndustryGroupList = storeState.industryGroups ? storeState.industryGroups.data : null;
            if (storeIndustryGroupList) {
                this.setindustryGroupOptions(storeIndustryGroupList);
            }


            let productServices = Helpers.makeSelectOptions(storeMetaList.PSL);
            this.setState(
                {
                    productServices: productServices
                })
        });

        this.changeType = this.changeType.bind(this);
        this.getForm = this.getForm.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.doSearch = this.doSearch.bind(this);
        this.editSearch = this.editSearch.bind(this);
        this.setMetaOptions = this.setMetaOptions.bind(this);
        this.setBusinessOptions = this.setBusinessOptions.bind(this);
        this.setindustryGroupOptions = this.setindustryGroupOptions.bind(this);
        this.setSubIndustryOptions = this.setSubIndustryOptions.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.getCriteriaFor = this.getCriteriaFor.bind(this);
        this.renderSearchCriteria = this.renderSearchCriteria.bind(this);
        this.renderSearchResults = this.renderSearchResults.bind(this);

    }

    componentWillReceiveProps(newProps) {


        let type = newProps.type;
        let option = newProps.option;
        let q = Helpers.getQueryParameter("t")



        if (JSON.stringify(this.state.localProps) != JSON.stringify(newProps)) {
            this.fetchPrb()
            if (!Helpers.isEmpty(type) && type != this.state.type && (this.props.type != newProps.type)) {
                let topType = newProps.type;
                type = (newProps.type == "" || newProps.type == "common") ? "company" : newProps.type;

                this.setState({
                    type: type,
                    preSearch: true,
                    editSearch: type == (topType == "" || topType == "common") ? true : false
                }, () => {
                    if (topType != "" || topType != "common") {
                        this.doSearch();
                    }

                })
            } else {

                type = (newProps.type == "" || newProps.type == "common") ? "company" : newProps.type;
                this.setState({
                    type: type,
                    editSearch: true
                })
            }


            this.setState({ localProps: newProps })

            if (type == "investor" && !Helpers.isEmpty(option)) {
                this.setState({
                    investorType: [option]
                }, () => {
                    this.doSearch();
                })
            } else if (type == "investor") {
                this.setState({
                    investorType: []
                }, () => {
                    this.doSearch();
                })
            } else {
                this.setState({
                    investorType: []
                })
            }
        }

    }

    fetchPrb = () => {
        Axios.get(
            Helpers.apiURL({
                uri: "meta/list/PRB",
            })
        )
            .then((response) => {
                let pbOption = response.data.result || {};
                this.setState({
                    pbOption: Helpers.makeSelectOptions(pbOption)
                })

            })
            .catch((err) => {

            });
    }


    componentDidMount() {

        window.scrollTo(0, 0)
        //Helpers.demand(this,{code:"AD_SER"});
        var storeState = store.getState();
        let meta = storeState.meta;
        let business = storeState.business;
        let industryGroups = storeState.industryGroups
        let subIndustries = storeState.subIndustries

        let searchCriteria = !Helpers.isEmpty(storeState.search) ? storeState.search.advanceSearchCriteria : undefined;

        let option = this.props.option;

        let query = this.props.location.search;
        let querySearch = queryString.parse(query);

        let q = Helpers.getQueryParameter("t");
        let id = Helpers.getQueryParameter("id");
        if (q && q != "common") {

            if (q == "investor") {
                this.setState({
                    type: q,
                    preSearch: true,
                    editSearch: false,
                    investorType: q == "investor" && !Helpers.isEmpty(id) ? [id] : []
                }, () => {
                    this.doSearch(null, [id]);
                })
            } else {
                this.setState({
                    type: q,
                    preSearch: true,
                    editSearch: false,
                }, () => {
                    this.doSearch();
                })
            }

        }
        else if (q == 'common') {
            this.setState({
                type: q,
                preSearch: true,
                editSearch: false,
            }, () => {
                this.doSearch();
            })
        }
        else {
            if (searchCriteria && searchCriteria.type != "common") {
                this.setState(
                    {
                        type: searchCriteria.type,
                        query: searchCriteria.query,
                        country: searchCriteria.country,
                        city: searchCriteria.city,
                        sector: searchCriteria.sector,
                        industry: searchCriteria.industry,
                        subIndustry: searchCriteria.subIndustry,
                        industryGroup: searchCriteria.industryGroup,
                        companyPsl: searchCriteria.companyPsl,
                        companySize: searchCriteria.companySize,
                        foundedYear: searchCriteria.foundedYear,
                        rating: searchCriteria.rating,
                        revenueGenerating: searchCriteria.revenueGenerating,
                        isRaisingFund: searchCriteria.isRaisingFund,
                        fundRaisingAmount: searchCriteria.fundRaisingAmount,
                        currentInvestors: searchCriteria.currentInvestors,
                        investorType: searchCriteria.investorType,
                        investorStage: searchCriteria.investorStage,
                        peopleCompany: searchCriteria.peopleCompany,
                        peoplePsl: searchCriteria.peoplePsl,
                        professionalBodies: searchCriteria.professionalBodies,
                        peopleJobTitle: searchCriteria.peopleJobTitle,
                        peopleRole: searchCriteria.peopleRole,
                        investmentAmount: searchCriteria.investmentAmount,
                        deadline: searchCriteria.deadline,
                        date: searchCriteria.date,
                        fundingStage: searchCriteria.fundingStage,
                        fundingType: searchCriteria.fundingType,
                        isEquityFunding: searchCriteria.isEquityFunding,
                        oppType:searchCriteria.oppType
                    }, () => {
                        if (!Helpers.isEmpty(searchCriteria)) {
                            this.doSearch()
                        }
                    }
                )
            } else {
                this.setState({
                    type: q,
                    preSearch: false,
                    editSearch: true
                }, () => {
                    this.doSearch();
                })

            }


        }


        //Get sectors and industies
        if (Helpers.isEmpty(business.data)) {
            this.props.getSectorData();
        } else {
            this.setBusinessOptions(business.data);
        }

        //Get industry groups data
        if (Helpers.isEmpty(industryGroups.data)) {
            this.props.getIndustryGroupsData();
        } else {
            this.setindustryGroupOptions(industryGroups.data);
        }


        //Get sub industries data
        if (Helpers.isEmpty(subIndustries.data)) {
            this.props.getSubIndustriesData();
        } else {
            this.setSubIndustryOptions(subIndustries.data);
        }

        if (Helpers.isEmpty(meta.metaList)) {
            this.props.getMetaData();
        } else {
            this.setMetaOptions(meta.metaList);
        }

        let countryOptions = Helpers.makeSelectOptions(countries)
        this.setState({ countryOptions: countryOptions })
        this.getRoles();

        let sectors = localStorage.getItem("gSectors");
        if (Helpers.isEmpty(sectors)) {
            this.fetchSectors();
        } else {
            sectors = JSON.parse(sectors);
            this.setState(prevState => {
                return { sectors }
            })
        }
    }


    setMetaOptions(metaList) {

        let companySizeOptions = [];
        let fundRaisingAmountOptions = [];
        let investorTypeOptions = [];
        let investmentStageOptions = [];
        //let peopleJobTitleOptions  = [];
        let budgetRangeOptions = [];
        let fundingStageOptions = [];
        let fundingTypeOptions = [];

        if (!Helpers.isEmpty(metaList)) {
            companySizeOptions = Helpers.makeSelectOptions(metaList.CSI);
            fundRaisingAmountOptions = Helpers.makeSelectOptions(metaList.FRA);
            investorTypeOptions = Helpers.makeSelectOptions(metaList.ITY);
            investmentStageOptions = Helpers.makeSelectOptions(metaList.IST);
            //peopleJobTitleOptions = Helpers.makeSelectOptions(metaList.CTI);
            budgetRangeOptions = Helpers.makeSelectOptions(metaList.BUD);
            fundingStageOptions = Helpers.makeSelectOptions(metaList.FUS);
            fundingTypeOptions = Helpers.makeSelectOptions(metaList.FUT);

        }

        this.setState({
            metaList: metaList,
            metaReady: true,
            companySizeOptions: companySizeOptions,
            fundRaisingAmountOptions: fundRaisingAmountOptions,
            investorTypeOptions: investorTypeOptions,
            investmentStageOptions: investmentStageOptions,
            //peopleJobTitleOptions:peopleJobTitleOptions,
            budgetRangeOptions: budgetRangeOptions,
            fundingStageOptions: fundingStageOptions,
            fundingTypeOptions: fundingTypeOptions
        })

    }

    setSubIndustryOptions(subIndustries) {
        let subIndustryOptions = [];
        if (!Helpers.isEmpty(subIndustries)) {
            subIndustryOptions = Helpers.makeSelectOptions(subIndustries.subIndustriesArray);
        }

        this.setState({
            subIndustryOptions: subIndustryOptions,
        })
    }

    setindustryGroupOptions(industryGroups) {
        let industryGroupOptions = [];
        if (!Helpers.isEmpty(industryGroups)) {
            industryGroupOptions = Helpers.makeSelectOptions(industryGroups.industryGroupsArray);
        }

        this.setState({
            industryGroupOptions: industryGroupOptions,
        })
    }

    setBusinessOptions(business) {
        let sectorOptions = [];
        let industryOptions = [];
        let industryAllOptions = [];

        if (!Helpers.isEmpty(business)) {
            sectorOptions = Helpers.makeSelectOptions(business.sectorsArray);
            industryOptions = Helpers.makeSelectOptions(business.industriesArray);

            industryAllOptions = business.industriesArray;
        }

        this.setState({
            business: business,
            businessReady: true,
            sectorOptions: sectorOptions,
            industryOptions: industryOptions,
            industryAllOptions: industryAllOptions
        })
    }

    getRoles() {
        Axios.get(
            Helpers.apiURL({
                uri: "user/roles/list ",
            })
        )
            .then((response) => {
                let roles = response.data.result || [];
                this.setState({
                    roles: roles,
                    roleOptions: Helpers.makeSelectOptions(roles)
                })
            })
    }

    // handleSelectChange(value, element) {
    //     let name = element.name;
    //     this.setState({ [name]: value })
    // }


    handleSectorChange = (value, element) => {
        let name = element.name;
        this.setState({ [name]: value });

        this.setState({
            industryOptions: Helpers.industriesBySector(this.state.industryAllOptions, value)
        });

    }

    handleFieldChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({
            [name]: value
        })
    }

    handleCompanyChange(value) {
        this.setState({
            "peopleCompany": value
        })
    }

    handlePeoplePslChange = (value) => {
        this.setState({
            "peoplePsl": value
        })
    }

    handleCompanyPslChange = (value) => {
        this.setState({
            companyPsl: value
        });
    }

    professionalBodies = (value) => {
        this.setState({
            "professionalBodies": value
        })
    }

    handleDateChange(value, name) {
        this.setState({
            [name]: value
        })
    }

    editSearch() {
        this.setState({
            editSearch: true
        })
    }

    doSearch(e, investorType) {



        if (e) {
            e.preventDefault();
        }
        this.setState({
            loading: true
        })
        window.scrollTo(0, 0)
        let type = this.state.type;


        var countryQuery = [];
        if (!Helpers.isEmpty(this.state.country)) {
            this.state.country.forEach((val, i) => {
                countryQuery.push(val.value)
            })
        }


        var cityQuery = [];
        if (!Helpers.isEmpty(this.state.city)) {
            this.state.city.forEach((val, i) => {
                cityQuery.push(val.value)
            })
        }

        let criteria = {
            type: type,
            query: this.state.query,
            country: this.state.country,
            city: this.state.city,
            sector: this.state.sector,
            industry: this.state.industry,
            subIndustry: this.state.subIndustry,
            industryGroup: this.state.industryGroup,
            companyPsl: this.state.companyPsl,
            companySize: this.state.companySize,
            foundedYear: this.state.foundedYear,
            rating: this.state.rating,
            revenueGenerating: this.state.revenueGenerating,
            isRaisingFund: this.state.isRaisingFund,
            fundRaisingAmount: this.state.fundRaisingAmount,
            currentInvestors: this.state.currentInvestors,
            investorType: this.state.investorType,
            investorStage: this.state.investorStage,
            peopleCompany: this.state.peopleCompany,
            peoplePsl: this.state.peoplePsl,
            professionalBodies: this.state.professionalBodies,
            peopleJobTitle: this.state.peopleJobTitle,
            peopleRole: this.state.peopleRole,
            investmentAmount: this.state.investmentAmount,
            deadline: this.state.deadline,
            date: this.state.date,
            fundingStage: this.state.fundingStage,
            fundingType: this.state.fundingType,
            isEquityFunding: this.state.isEquityFunding,
            oppType:this.state.oppType
        }

        if (!Helpers.isEmpty(investorType)) {
            criteria.investorType = investorType;
        }

        this.props.storeAdvanceSearch(criteria)
        //build query for search
        let query = {
            searchType: type && (type != "" && type != 'common') ? type.toUpperCase() : 'COMPANY',
            searchText: this.state.query ? this.state.query : null,
            country: this.state.country ? countryQuery.join(",") : null,
            city: this.state.city ? cityQuery.join(",") : null,

            sector: null,
            industry: null,
            subIndustry: null,
            industryGroup: null,
            companyPsl: null,
            companySize: null,
            foundedYear: null,
            rating: null,
            revenueGenerating: null,
            isRaisingFund: null,
            fundRaisingAmount: null,
            investorType: [],
            investorStage: null,
            peopleCompany: null,
            peoplePsl: null,
            professionalBodies: null,
            peopleJobTitle: undefined,
            peopleRole: null,
            investmentAmount: null,
            investmentDeadline: null,
            date: null,
            fundingStage: null,
            fundingType: null,
            isEquityFunding: null,
            oppType:null
        };


        var sectorQuery = [];
        if (!Helpers.isEmpty(this.state.sector)) {
            this.state.sector.forEach((val, i) => {
                sectorQuery.push(val.value)
            })
        }


        var industryQuery = [];
        if (!Helpers.isEmpty(this.state.industry)) {
            this.state.industry.forEach((val, i) => {
                industryQuery.push(val.value)
            })
        }
        var subIndustryQuery = [];
        if (!Helpers.isEmpty(this.state.subIndustry)) {
            this.state.subIndustry.forEach((val, i) => {
                subIndustryQuery.push(val.value)
            })
        }
        var industryGroupQuery = [];
        if (!Helpers.isEmpty(this.state.industryGroup)) {
            this.state.industryGroup.forEach((val, i) => {
                industryGroupQuery.push(val.value)
            })
        }



        var investorTypes = [];

        if (!Helpers.isEmpty(this.state.investorType)) {
            this.state.investorType.forEach((val, i) => {
                investorTypes.push(val.value)
            })
        }

        if (!Helpers.isEmpty(investorType)) {
            investorTypes = investorType
        }

        var psl = null;
        if (!Helpers.isEmpty(this.state.psl)) {
            psl = this.state.psl.map((val, i) => val.value).join(",")
        }

        type = (type == "" || type == "common") ? "company" : type;

        switch (type) {

            case "company":

                query.sector = !Helpers.isEmpty(sectorQuery) ? sectorQuery.join(",") : null;
                query.industry = !Helpers.isEmpty(industryQuery) ? industryQuery.join(",") : null;
                query.industryGroup = !Helpers.isEmpty(industryGroupQuery) ? industryGroupQuery.join(",") : null;
                query.subIndustry = !Helpers.isEmpty(subIndustryQuery) ? subIndustryQuery.join(",") : null;
                query.companySize = this.state.companySize ? this.state.companySize.value : null;
                query.foundedYear = this.state.foundedYear ? this.state.foundedYear.value : null;

                let companyPsl = this.state.companyPsl;
                let arr = [];
                if (!Helpers.isEmpty(companyPsl)) {
                    companyPsl.forEach((list) => {
                        arr.push(list.id)
                    })
                    query.companyPsl = arr.join(",");
                };

                if (!Helpers.isEmpty(this.state.rating)) {
                    query.fromRating = this.state.rating ? this.state.rating.value : null;
                    query.toRating = this.state.rating ? this.state.rating.value : null;
                }
                break;

            case "startup":
                let startupPsl = this.state.companyPsl;
                let arr1 = [];
                if (!Helpers.isEmpty(startupPsl)) {
                    startupPsl.forEach((list) => {
                        arr1.push(list.id)
                    })
                    query.companyPsl = arr1.join(",");
                };
                query.industryGroup = !Helpers.isEmpty(industryGroupQuery) ? industryGroupQuery.join(",") : null;
                query.subIndustry = !Helpers.isEmpty(subIndustryQuery) ? subIndustryQuery.join(",") : null;
                query.revenueGenerating = this.state.revenueGenerating && this.state.revenueGenerating.value != "any" ? (this.state.revenueGenerating.value == "yes" ? true : false) : null;
                query.isRaisingFund = this.state.isRaisingFund && this.state.isRaisingFund.value != "any" ? (this.state.isRaisingFund.value == "yes" ? true : false) : null;
                query.fundRaisingAmount = this.state.fundRaisingAmount ? this.state.fundRaisingAmount.value : null;
                query.sector = !Helpers.isEmpty(sectorQuery) ? sectorQuery.join(",") : null;
                query.industry = !Helpers.isEmpty(industryQuery) ? industryQuery.join(",") : null;
                query.foundedYear = this.state.foundedYear ? this.state.foundedYear.value : null;
                if (!Helpers.isEmpty(this.state.rating)) {
                    query.fromRating = this.state.rating ? this.state.rating.value : null;
                    query.toRating = this.state.rating ? this.state.rating.value : null;
                }
                query.companySize = this.state.companySize ? this.state.companySize.value : null;
                //add current investors here
                break;

            case "investor":
                query.investorTypes = !Helpers.isEmpty(investorTypes) ? investorTypes.join(",") : null;
                query.investorStage = this.state.investorStage ? this.state.investorStage.value : null;

                break;

            case "people":
                //query.peopleJobTitle = this.state.peopleJobTitle ? this.state.peopleJobTitle.value : null;
                query.peopleJobTitle = this.state.peopleJobTitle ? this.state.peopleJobTitle : null;
                query.peopleRole = this.state.peopleRole ? this.state.peopleRole.value : null;
                let company = this.state.peopleCompany;
                let peoplePsl = this.state.peoplePsl;
                let professionalBodies = this.state.professionalBodies;

                let array = [];
                if (!Helpers.isEmpty(peoplePsl)) {
                    peoplePsl.forEach((list) => {
                        array.push(list.value)
                    })
                    query.peoplePsl = array.join(",");
                };


                query.professionalBodies = !Helpers.isEmpty(professionalBodies) ? professionalBodies.value : null;

                company = company[0] ? company[0] : undefined;
                if (company) {
                    query.peopleCompany = company.uri;
                }
                break;

            case "opportunity":
                query.sector = !Helpers.isEmpty(sectorQuery) ? sectorQuery.join(",") : null;
                query.industry = !Helpers.isEmpty(industryQuery) ? industryQuery.join(",") : null;
                query.industryGroup = !Helpers.isEmpty(industryGroupQuery) ? industryGroupQuery.join(",") : null;
                query.subIndustry = !Helpers.isEmpty(subIndustryQuery) ? subIndustryQuery.join(",") : null;
                if(this.state.oppType && this.state.oppType.value == "INVESTMENT"){
                    query.investmentAmount = this.state.investmentAmount ? this.state.investmentAmount.value : null;
                    query.investmentTargetDate = this.state.investmentDeadline ? Helpers.getDateInInputFormat(this.state.investmentDeadline) : null;
                }
         
                query.oppType = this.state.oppType ?  this.state.oppType.value : null;
                break;

            case "event":
                query.sector = !Helpers.isEmpty(sectorQuery) ? sectorQuery.join(",") : null;
                query.industry = !Helpers.isEmpty(industryQuery) ? industryQuery.join(",") : null;
                query.date = this.state.date ? Helpers.getDateInInputFormat(this.state.date) : null;
                break;

            case "funding":
                query.sector = !Helpers.isEmpty(sectorQuery) ? sectorQuery.join(",") : null;
                query.industry = !Helpers.isEmpty(industryQuery) ? industryQuery.join(",") : null;
                query.companySize = this.state.companySize ? this.state.companySize.value : null;
                query.foundedYear = this.state.foundedYear ? this.state.foundedYear.value : null;
                query.fundingStage = this.state.fundingStage ? this.state.fundingStage.value : null;
                query.fundingType = this.state.fundingType ? this.state.fundingType.value : null;
                query.isEquityFunding = this.state.isEquityFunding && this.state.isEquityFunding.value != "any" ? (this.state.isEquityFunding.value == "yes" ? true : false) : null;
                break;

            case "SMALL_BUSINESS":
            case "INTERNATIONAL_COMPANY":
                let pslArray = this.state.companyPsl;
                query.sector = !Helpers.isEmpty(sectorQuery) ? sectorQuery.join(",") : null;
                query.industry = !Helpers.isEmpty(industryQuery) ? industryQuery.join(",") : null;
                query.industryGroup = !Helpers.isEmpty(industryGroupQuery) ? industryGroupQuery.join(",") : null;
                query.subIndustry = !Helpers.isEmpty(subIndustryQuery) ? subIndustryQuery.join(",") : null;
                let arr2 = [];
                if (!Helpers.isEmpty(pslArray)) {
                    pslArray.forEach((list) => {
                        arr2.push(list.id)
                    })
                    query.companyPsl = arr2.join(",");
                };

                break;

            default:
                break;
        }

        console.log("FULL QUERY", query);
        Axios.get(
            Helpers.apiURL({
                uri: "advance-search",
                query: query
            })
        ).then((response) => {
            let result = response.data.result || response.data;
            this.setState({
                editSearch: false,
                searchResults: result,
                loading: false
            })

        }).catch((response) => {
            this.setState({
                loading: false
            })
        })

    }

    changeType(e) {
        let type = e.target.value;

        if (["company", "startup", "SMALL_BUSINESS", "INTERNATIONAL_COMPANY"].includes(type)) {
            this.reset("sector");
        }
        // this.props.getTypeFromChild(type)
        this.setState({
            type: type,
            query: "",
            country: null,
            city: null,
            sector: null,
            industry: null,
            industryGroup: null,
            subIndustry: null,
            companySize: null,
            foundedYear: null,
            rating: null,
            revenueGenerating: null,
            isRaisingFund: null,
            fundRaisingAmount: null,
            currentInvestors: [],
            investorType: [],
            investorStage: null,
            peopleCompany: [],
            peopleCompany: [],
            peopleJobTitle: undefined,
            peopleRole: null,
            investmentAmount: null,
            investmentDeadline: null,
            date: null,
            fundingStage: null,
            fundingType: null,
            isEquityFunding: null,
            psl: null
        }, () => {
            this.getForm(type);
        });
    }

    handleCountryChange(option, e) {

        if (Helpers.isEmpty(option)) {
            this.setState({
                cityOptions: [],
                city: null,
                country: null,
            })
            return;
        }


        this.setState({
            cityDisabled: option.length > 1
        })


        let country = option && option[0] && option[0].value ? option[0].value : null;
        let name = e.name;
        this.setState({ [name]: option });
        ApiHelper.getCities(country, (cityList) => {
            let cityOptions = Helpers.makeSelectOptions(cityList);
            this.setState({
                cityOptions: cityOptions,
                city: null
            })
        })


    }

    handleTitleChange = (event) => {
        this.setState({ peopleJobTitle: event[0] });
    }


    getForm(type) {
        switch (type) {
            case "startup":
                return <StartupForm onChange={this.handleFieldChange} onSelectChange={this.handleSelectChange} onSectorChange={this.handleSectorChange} companyPslChange={this.handleCompanyPslChange} values={this.state} />;
            case "investor":
                return <InvestorsForm onChange={this.handleFieldChange} onSelectChange={this.handleSelectChange} onSectorChange={this.handleSectorChange} values={this.state} />;
            case "people":
                return <PeopleForm professionalBodies={this.professionalBodies} handleTitleChange={this.handleTitleChange} onChange={this.handleFieldChange} onSelectChange={this.handleSelectChange} onSectorChange={this.handleSectorChange} values={this.state} peoplePslChange={this.handlePeoplePslChange} pbOption={this.state.pbOption} />;
            case "opportunity":
                return <OppForm onChange={this.handleFieldChange} onSelectChange={this.handleSelectChange} onSectorChange={this.handleSectorChange} values={this.state} onDateChange={this.handleDateChange} />;
            case "event":
                return <EventForm onChange={this.handleFieldChange} onSelectChange={this.handleSelectChange} onSectorChange={this.handleSectorChange} values={this.state} onDateChange={this.handleDateChange} />;
            // case "funding":
            //     return <FundingForm onChange={this.handleFieldChange} onSelectChange={this.handleSelectChange} onSectorChange={this.handleSectorChange} values={this.state} onDateChange={this.handleDateChange} />;
            default:
                return <CompanyForm onChange={this.handleFieldChange} onSelectChange={this.handleSelectChange} onSectorChange={this.handleSectorChange} values={this.state} companyPslChange={this.handleCompanyPslChange} />;
        }

    }

    getCriteriaFor(type) {
        var sectorLabel = [];
        if (!Helpers.isEmpty(this.state.sector)) {
            this.state.sector.forEach((val, i) => {
                sectorLabel.push(val.label)
            })
        }

        var industryLabel = [];
        if (!Helpers.isEmpty(this.state.industry)) {
            this.state.industry.forEach((val, i) => {
                industryLabel.push(val.label)
            })
        }

        var industryGroupLabel = [];
        if (!Helpers.isEmpty(this.state.industryGroup)) {
            this.state.industryGroup.forEach((val, i) => {
                industryGroupLabel.push(val.label)
            })
        }

        var subIndustryLabel = [];
        if (!Helpers.isEmpty(this.state.subIndustry)) {
            this.state.subIndustry.forEach((val, i) => {
                subIndustryLabel.push(val.label)
            })
        }

        var industryLabel = [];
        if (!Helpers.isEmpty(this.state.industry)) {
            this.state.industry.forEach((val, i) => {
                industryLabel.push(val.label)
            })
        }

        var countryLabel = [];
        if (!Helpers.isEmpty(this.state.country)) {
            this.state.country.forEach((val, i) => {
                countryLabel.push(val.label)
            })
        }
        var cityLabel = [];
        if (!Helpers.isEmpty(this.state.city)) {
            this.state.city.forEach((val, i) => {
                cityLabel.push(val.label)
            })
        }

        var investorTypeLabel = []
        if (!Helpers.isEmpty(this.state.investorType)) {
            this.state.investorType.forEach((val, i) => {
                investorTypeLabel.push(val.label)
            })
        }


        switch (type) {

            case "company":

                let arr = [];
                let companyPsl = this.state.companyPsl;
                let coPslLabel = "";
                if (!Helpers.isEmpty(companyPsl)) {
                    companyPsl.forEach((list) => {
                        arr.push(list.name)
                    })
                    coPslLabel = arr.join(",")
                };
                return (
                    <div>
                        {!Helpers.isEmpty(this.state.sector) ? <h3 className="font-12 m-t-0 l-h-22">Sectors: <span className="font-normal">{sectorLabel.join(",")}</span></h3> : ""}
                        {!Helpers.isEmpty(this.state.industryGroup) ? <h3 className="font-12 m-t-0 l-h-22">Industry Groups: <span className="font-normal">{industryGroupLabel.join(",")}</span></h3> : ""}
                        {!Helpers.isEmpty(this.state.industry) ? <h3 className="font-12 m-t-0 l-h-22">Industries: <span className="font-normal">{industryLabel.join(",")}</span></h3> : ""}
                        {!Helpers.isEmpty(this.state.subIndustry) ? <h3 className="font-12 m-t-0 l-h-22">Sub Industries: <span className="font-normal">{subIndustryLabel.join(",")}</span></h3> : ""}
                        {!Helpers.isEmpty(coPslLabel) && <h3 className="font-12 m-t-0 l-h-22">Products/Services: <span className="font-normal">{coPslLabel}</span></h3>}
                        {this.state.companySize ? <h3 className="font-12 m-t-0 l-h-22">Company Size: <span className="font-normal">{this.state.companySize.label}</span></h3> : ""}
                        {this.state.foundedYear ? <h3 className="font-12 m-t-0 l-h-22">Found Year: <span className="font-normal">{this.state.foundedYear.label}</span></h3> : ""}
                        {this.state.rating ? <h3 className="font-12 m-t-0 l-h-22">Rating: <span className="font-normal" style={{ color: "rgb(255, 205, 0)" }}>{this.state.rating.label}</span></h3> : ""}
                    </div>
                )
                break;

            case "startup":
                let arr2 = [];
                let companyPsl2 = this.state.companyPsl;
                let coPslLabel2 = "";
                if (!Helpers.isEmpty(companyPsl2)) {
                    companyPsl2.forEach((list) => {
                        arr2.push(list.name)
                    })
                    coPslLabel2 = arr2.join(",")
                };;
                return (
                    <div>
                        {!Helpers.isEmpty(this.state.sector) ? <h3 className="font-12 m-t-0 l-h-22">Sectors: <span className="font-normal">{sectorLabel.join(",")}</span></h3> : ""}
                        {!Helpers.isEmpty(this.state.industry) ? <h3 className="font-12 m-t-0 l-h-22">Industries: <span className="font-normal">{industryLabel.join(",")}</span></h3> : ""}
                        {!Helpers.isEmpty(this.state.subIndustry) ? <h3 className="font-12 m-t-0 q-0 l-h-22">Sub Industries: <span className="font-normal">{subIndustryLabel.join(",")}</span></h3> : ""}
                        {!Helpers.isEmpty(this.state.industryGroup) ? <h3 className="font-12 m-t-0 l-h-22">Industry Groups: <span className="font-normal">{industryGroupLabel.join(",")}</span></h3> : ""}
                        {!Helpers.isEmpty(this.state.industry) ? <h3 className="font-12 m-t-0 l-h-22">Industries: <span className="font-normal">{industryLabel.join(",")}</span></h3> : ""}
                        {!Helpers.isEmpty(this.state.subIndustry) ? <h3 className="font-12 m-t l-h-22">Sub Industries: <span className="font-normal">{subIndustryLabel.join(",")}</span></h3> : ""}
                        {!Helpers.isEmpty(coPslLabel2) && <h3 className="font-12 m-t-0 l-h-22">Products/Services: <span className="font-normal">{coPslLabel2}</span></h3>}
                        {!Helpers.isEmpty(this.state.companySize) ? <h3 className="font-12 m-t-0 l-h-22">Company Size: <span className="font-normal">{this.state.companySize.label}</span></h3> : ""}
                        {!Helpers.isEmpty(this.state.foundedYear) ? <h3 className="font-12 m-t-0 l-h-22">Found Year: <span className="font-normal">{this.state.foundedYear.label}</span></h3> : ""}
                        {!Helpers.isEmpty(this.state.revenueGenerating) ? <h3 className="font-12 m-t-0 l-h-22">Revenue Generating: <span className="font-normal">{this.state.revenueGenerating.label}</span></h3> : ""}
                        {!Helpers.isEmpty(this.state.isRaisingFund) ? <h3 className="font-12 m-t-0 l-h-22">Currently Raising Fund: <span className="font-normal">{this.state.isRaisingFund.label}</span></h3> : ""}
                        {!Helpers.isEmpty(this.state.fundRaisingAmount) ? <h3 className="font-12 m-t-0 l-h-22">Fund Raising Amount: <span className="font-normal">{this.state.fundRaisingAmount.label}</span></h3> : ""}
                        {!Helpers.isEmpty(this.state.rating) ? <h3 className="font-12 m-t-0 l-h-22">Rating: <span className="font-normal" style={{ color: this.state.rating.color }}>{this.state.rating.label}</span></h3> : ""}
                    </div>
                )
                break;

            case "investor":
                return (
                    <div>
                        {!Helpers.isEmpty(investorTypeLabel) ? <h3 className="font-12 m-t-0 l-h-22">Investor Types: <span className="font-normal">{investorTypeLabel.join(",")}</span></h3> : ""}
                        {this.state.investorStage ? <h3 className="font-12 m-t-0 l-h-22">Investor Stage: <span className="font-normal">{this.state.investorStage.label}</span></h3> : ""}
                    </div>
                )
                break;

            case "people":

                let array = [];
                let peoplePsl = this.state.peoplePsl;
                let pslLabel = "";
                if (!Helpers.isEmpty(peoplePsl)) {
                    peoplePsl.forEach((list) => {
                        array.push(list.name)
                    })
                    pslLabel = array.join(",")
                };

                let professionalBodies = this.state.professionalBodies;
                let pbLabel = !Helpers.isEmpty(professionalBodies) ? professionalBodies.label : null;
                return (
                    <div>
                        {/* {this.state.peopleCompany && this.state.peopleCompany[0] ? <h3 className="font-12 m-t-0 l-h-22">Company: <span className="font-normal">{this.state.peopleCompany[0].name}</span></h3> : ""}
                        {this.state.peopleJobTitle ? <h3 className="font-12 m-t-0 l-h-22">Job Title: <span className="font-normal">{this.state.peopleJobTitle}</span></h3> : ""} */}
                        {/* {this.state.peopleRole ? <h3 className="font-16 m-t-0 l-h-22">Role on Investera: <span className="font-normal">{this.state.peopleRole.label}</span></h3>:""} */}
                        {!Helpers.isEmpty(pbLabel) && <h3 className="font-12 m-t-0 l-h-22">Professional Bodies: <span className="font-normal">{pbLabel}</span></h3>}
                        {!Helpers.isEmpty(pslLabel) && <h3 className="font-12 m-t-0 l-h-22">Service Offered: <span className="font-normal">{pslLabel}</span></h3>}



                    </div>
                )
                break;

            case "opportunity":
                return (
                    <div>
                        {!Helpers.isEmpty(this.state.oppType) ? <h3 className="font-12 m-t-0 l-h-22">Type: <span className="font-normal">{this.state.oppType.label}</span></h3> : ""}
                        {!Helpers.isEmpty(this.state.sector) ? <h3 className="font-12 m-t-0 l-h-22">Sectors: <span className="font-normal">{sectorLabel.join(",")}</span></h3> : ""}
                        {!Helpers.isEmpty(this.state.industry) ? <h3 className="font-12 m-t-0 l-h-22">Industries: <span className="font-normal">{industryLabel.join(",")}</span></h3> : ""}
                        {!Helpers.isEmpty(this.state.subIndustry) ? <h3 className="font-12 m-t-0 q-0 l-h-22">Sub Industries: <span className="font-normal">{subIndustryLabel.join(",")}</span></h3> : ""}
                        {!Helpers.isEmpty(this.state.industryGroup) ? <h3 className="font-12 m-t-0 l-h-22">Industry Groups: <span className="font-normal">{industryGroupLabel.join(",")}</span></h3> : ""}
                        {this.state.investmentAmount && this.state.oppType && this.state.oppType.value =="INVESTMENT" ? <h3 className="font-12 m-t-0 l-h-22">Investment Amount: <span className="font-normal">{this.state.investmentAmount.label}</span></h3> : ""}
                        {this.state.investmentDeadline && this.state.oppType && this.state.oppType.value =="INVESTMENT"  ? <h3 className="font-12 m-t-0 l-h-22">Investment Deadline: <span className="font-normal">{Helpers.getDateInInputFormat(this.state.investmentDeadline)}</span></h3> : ""}
                    </div>
                )
                break;

            case "event":
                return (
                    <div>
                        {!Helpers.isEmpty(this.state.sector) ? <h3 className="font-12 m-t-0 l-h-22">Sectors: <span className="font-normal">{sectorLabel.join(",")}</span></h3> : ""}
                        {!Helpers.isEmpty(this.state.industry) ? <h3 className="font-12 m-t-0 l-h-22">Industries: <span className="font-normal">{industryLabel.join(",")}</span></h3> : ""}
                        {this.state.date ? <h3 className="font-12 m-t-0 l-h-22">Date: <span className="font-normal">{Helpers.getDateInInputFormat(this.state.date)}</span></h3> : ""}
                    </div>
                )
                break;

            case "funding":
                return (
                    <div>
                        {!Helpers.isEmpty(this.state.sector) ? <h3 className="font-12 m-t-0 l-h-22">Sectors: <span className="font-normal">{sectorLabel.join(",")}</span></h3> : ""}
                        {!Helpers.isEmpty(this.state.industry) ? <h3 className="font-12 m-t-0 l-h-22">Industries: <span className="font-normal">{industryLabel.join(",")}</span></h3> : ""}
                        {this.state.companySize ? <h3 className="font-12 m-t-0 l-h-22">Company Size: <span className="font-normal">{this.state.companySize.label}</span></h3> : ""}
                        {this.state.foundedYear ? <h3 className="font-12 m-t-0 l-h-22">Found Year: <span className="font-normal">{this.state.foundedYear.label}</span></h3> : ""}
                        {this.state.revenueGenerating ? <h3 className="font-12 m-t-0 l-h-22">Funding Stage: <span className="font-normal">{this.state.fundingStage.label}</span></h3> : ""}
                        {this.state.isRaisingFund ? <h3 className="font-12 m-t-0 l-h-22">Funding Type: <span className="font-normal">{this.state.fundingType.label}</span></h3> : ""}
                        {this.state.fundRaisingAmount ? <h3 className="font-12 m-t-0 l-h-22">Equity Funding: <span className="font-normal">{this.state.isEquityFunding.label}</span></h3> : ""}
                        {/* {this.state.rating ? <h3 className="font-12 m-t-0 l-h-22">Rating: <span className="font-normal" style={{ color: this.state.rating.color }}>{this.state.rating.label}</span></h3> : ""} */}
                    </div>
                )
                break;
            case "SMALL_BUSINESS":
            case "INTERNATIONAL_COMPANY":
                let arr3 = [];
                let companyPsl3 = this.state.companyPsl;
                let coPslLabel3 = "";
                if (!Helpers.isEmpty(companyPsl3)) {
                    companyPsl3.forEach((list) => {
                        arr3.push(list.name)
                    })
                    coPslLabel3 = arr3.join(",")
                };;
               
                return (
                    <div>
                        {!Helpers.isEmpty(this.state.sector) ? <h3 className="font-12 m-t-0 l-h-22">Sectors: <span className="font-normal">{sectorLabel.join(",")}</span></h3> : ""}
                        {!Helpers.isEmpty(this.state.industryGroup) ? <h3 className="font-12 m-t-0 l-h-22">Industry Groups: <span className="font-normal">{industryGroupLabel.join(",")}</span></h3> : ""}
                        {!Helpers.isEmpty(this.state.industry) ? <h3 className="font-12 m-t-0 l-h-22">Industries: <span className="font-normal">{industryLabel.join(",")}</span></h3> : ""}
                        {!Helpers.isEmpty(this.state.subIndustry) ? <h3 className="font-12 m-t-0 l-h-22">Sub Industries: <span className="font-normal">{subIndustryLabel.join(",")}</span></h3> : ""}
                        {!Helpers.isEmpty(coPslLabel3) && <h3 className="font-12 m-t-0 l-h-22">Products/Services: <span className="font-normal">{coPslLabel3}</span></h3>}
                    </div>
                )

        }

    }


    showPopup = () => {
        this.setState({
            modal: !this.state.modal
        })
    }

    renderSearchCriteria() {

        let types = {
            company: "Company",
            startup: "Startup",
            investor: "Investor",
            people: "People",
            opportunity: "Opportunity",
            event: "Event",
            funding: "Funding"
        }

        var countryLabel = [];
        if (!Helpers.isEmpty(this.state.country)) {
            this.state.country.forEach((val, i) => {
                countryLabel.push(val.label)
            })
        }
        var cityLabel = [];
        if (!Helpers.isEmpty(this.state.city)) {
            this.state.city.forEach((val, i) => {
                cityLabel.push(val.label)
            })
        }


        let searchResults = this.state.searchResults;
        let total = searchResults ? searchResults.length : 0;
        return (<div className="col-12 p-0 text-left m-t-10">

            {this.state.query ? <h3 className="font-12 m-t-0 l-h-22">Name / Contains Words: <span className="font-normal">{this.state.query}</span></h3> : ""}
            {!Helpers.isEmpty(this.state.country) ? <h3 className="font-12 m-t-0 l-h-22">Country: <span className="font-normal">{countryLabel.join(",")}</span></h3> : ""}
            {!Helpers.isEmpty(this.state.city) ? <h3 className="font-12 m-t-0 l-h-22">City: <span className="font-normal">{cityLabel.join(",")}</span></h3> : ""}

            {this.getCriteriaFor(this.state.type)}


        </div>
        );
    }

    renderSearchResults(searchResults) {
        let showPeople = this.state.showPeople;

        return (
            <div>
                {!Helpers.isEmpty(searchResults) ? searchResults.map((result, index) => (
                    <EntityBlock pointerStyle={"noLink"} key={index} index={index} result={result} showPeople={showPeople}></EntityBlock>
                )) : ""}
            </div>
        )

    }

    toggleModal = () => {
        this.setState(prevState => {
            let showModal = !prevState.showModal;
            return { showModal }
        })
    }




    handleSelectChange = (value, element) => {
        let name = element.name;
        this.setState({ [name]: value });
        this.reset(name);
        if (name == "sector") {
            this.fetchIG(value)
        }
        else if (name == 'industryGroup') {
            this.fetchIndustries(value)
        }
        else if (name == 'industry') {
            this.fetchSi(value)
        } else if (name == "subIndustry") {

        }
        else {
            console.warn("No element has been rendered!")
        }
    }



    fetchIG = (e) => {
        let sectors = !Helpers.isEmpty(e) ? e.map((e) => e.value).join(",") : null;

        if (sectors) {
            this.setState(prevState => {
                let loading = true;
                return { loading };
            })
            Axios.get(
                Helpers.apiURL({
                    uri: "api/gics/industry-groups",
                    query: { "sector-ids": sectors }
                })
            )
                .then((response) => {
                    const result = response.data || {};
                    let industryGroups = [];

                    if (result) {
                        industryGroups = Helpers.makeSelectOptions(result)
                    }
                    let loading = false;
                    this.setState(prevState => {
                        return { industryGroups, loading };
                    })
                })
                .catch((err) => {
                });
        }

    }


    reset = (element) => {
        if (element == "sector") {
            this.setState(prevState => {
                let industryGroups = [];
                let industries = [];
                let subIndustries = [];
                let industryGroup = [];
                let industry = [];
                let subIndustry = [];
                let companyPsl = [];
                return { industryGroups, industries, subIndustries, industryGroup, industry, subIndustry, companyPsl }
            })
        } else if (element == "industryGroup") {
            this.setState(prevState => {
                let industries = [];
                let subIndustries = [];
                let industry = [];
                let subIndustry = [];
                let companyPsl = [];
                return { industries, subIndustries, industry, subIndustry, companyPsl }
            })
        }
        else if (element == "industry") {
            this.setState(prevState => {
                let subIndustries = [];
                let subIndustry = [];
                let companyPsl = [];
                return { subIndustries, subIndustry, companyPsl }
            })
        }
    }

    fetchIndustries = (e) => {
        let igs = !Helpers.isEmpty(e) ? e.map((e) => e.value).join(",") : null;

        if (igs) {
            this.setState(prevState => {
                let loading = true;
                return { loading };
            })
            Axios.get(
                Helpers.apiURL({
                    uri: "api/gics/industries",
                    query: { "industry-group-ids": igs }
                })
            )
                .then((response) => {


                    const result = response.data || {};
                    let industries = [];
                    if (result) {
                        industries = Helpers.makeSelectOptions(result)
                    }
                    let loading = false;
                    this.setState(prevState => {
                        return { industries, loading };
                    })

                })
                .catch((err) => {
                });
        }
    }

    fetchSi = (e) => {
        let inds = !Helpers.isEmpty(e) ? e.map((e) => e.value).join(",") : null;

        if (inds) {
            this.setState(prevState => {
                let loading = true;
                return { loading };
            })
            Axios.get(
                Helpers.apiURL({
                    uri: "api/gics/sub-industries",
                    query: { "industry-ids": inds }
                })
            )
                .then((response) => {

                    const result = response.data || {};
                    let subIndustries = [];

                    if (result) {
                        subIndustries = Helpers.makeSelectOptions(result)
                    }
                    let loading = false;
                    this.setState(prevState => {
                        return { subIndustries, loading };
                    })
                })
                .catch((err) => {
                });
        }
    }

    fetchSectors = () => {
        Axios.get(
            Helpers.apiURL({
                uri: "api/gics/sectors",
            })
        )
            .then((response) => {
                const result = response.data || {};
                let sectors = [];
                if (result) {
                    sectors = Helpers.makeSelectOptions(result)
                }
                this.setState(prevState => {
                    return { sectors }
                })
                let loadedSectors = true;
                return { sectors, loadedSectors };
                localStorage.setItem("gSectors", JSON.stringify(Helpers.makeSelectOptions(result)))
            })
            .catch((err) => {

            });
    }

    render() {

        let form = this.getForm(this.state.type);
        let editSearch = this.state.editSearch;
        let searchResults = this.state.searchResults;
        let type = this.state.type;
        let loading = this.state.loading;

        let total = searchResults ? searchResults.length : 0;
        let types = {
            company: "Company",
            startup: "Startup",
            investor: "Investor",
            people: "People",
            opportunity: "Opportunity",
            event: "Event",
            funding: "Funding",
            SMALL_BUSINESS: "Small Business",
            INTERNATIONAL_COMPANY: "International Company",
        }
        let countrySearchConfig = {
            matchFrom: 'start'
        }

        let { showModal, privileges, showPeople, modal } = this.state;


        return (
            <div>
                <Modal isOpen={modal} toggle={this.showPopup} className={"forceUpgradeModel"}>
                    <ModalHeader toggle={this.showPopup}>

                    </ModalHeader>
                    <ModalBody>
                        <div className="col-md-12 text-center body">
                            <i class="material-icons peopleIcon">people</i>
                            <h2 className="font-18 m-t-20">“People” mean individuals who have a service to<br />
                                offer to the members of Investera Plus Africa</h2>
                        </div>
                        <ul className="font-14 m-t-20">
                            <li>To be listed and have access on “People”, upgrade to <Link to="/proceed?type=premium_plus_monthly">Premium Plus <i className="font-10">(for
                                Professionals)</i></Link> and update you profile.</li>
                            <li>To access “People”, please upgrade to <Link to="/proceed?type=premium">Premium</Link></li>
                        </ul>
                        <div className="text-center m-t-50 p-b-20">
                            <Link className="btn btn-info" to={"/subscribe"}>Upgrade Now</Link>
                        </div>
                    </ModalBody>
                </Modal>
                <Loader loading={loading} fullPage />
                <div className="card bold-headers p-b-10 resultMob">
                    <div className="card-header text-left  custom-card-header p-0 top-radius">
                        <div className="col-12 p-0 text-left">
                            <div className="col-md-4 float-left p-15 crossCut top-radius">
                                <h5 className="m-b-0 m-t-0 text-white">Advanced Search</h5>
                            </div>
                            {
                                editSearch ?
                                    ""
                                    :
                                    <div className="col-md-8 text-right float-left bg-white p-10 ">
                                        <a href="javascript:void(0)" className="text-dark-info float-right font-14" onClick={this.editSearch}>Edit Search Criteria</a>
                                    </div>
                            }

                        </div>
                        {
                            editSearch ?
                                <div className="light-blue-header col-md-12 float-left">
                                    <h3 className="text-white font-16 p-b-10">Search for: </h3>
                                </div>
                                :
                                <div className="light-blue-header col-md-12 float-left">
                                    {/* <span className="text-white">{total}</span> */}
                                    <h3 className="text-white font-16 p-b-10">Search Results for: <span className="font-normal text-white">{types[this.state.type]}</span></h3>
                                </div>
                        }
                    </div>
                    {editSearch ?
                        <div className="card-body">
                            <div className="col-12 p-0 text-left">
                                <div className="demo-radio-button responsiveRadio col-12 p-0 font-14">

                                    <input name="type" type="radio" id="company" className="with-gap radio-col-indigo" value="company" onChange={this.changeType} defaultChecked={type == "company" ? true : false} /*checked={type=="company" ? true : false }*/></input>
                                    <label htmlFor="company" className="col-xlg-2 col-lg-3 col-md-3 col-sm-6 font-16" > <i className="material-icons font-20 vm">business</i> Company</label>

                                    <input name="type" type="radio" id="startup" className="with-gap radio-col-indigo" value="startup" onChange={this.changeType} defaultChecked={type == "startup" ? true : false} /*checked={type=="startup" ? true : false }*/></input>
                                    <label htmlFor="startup" className="col-xlg-2 col-lg-3 col-md-3 col-sm-6 font-16"><i className="mdi mdi-rocket font-20 vm"></i> Startup</label>

                                    <input name="type" type="radio" id="small_business" className="with-gap radio-col-indigo" value="SMALL_BUSINESS" onChange={this.changeType} defaultChecked={type == "SMALL_BUSINESS" ? true : false} /*checked={type=="company" ? true : false }*/></input>
                                    <label htmlFor="small_business" className="col-xlg-2 col-lg-3 col-md-3 col-sm-6 font-16" > <i className="material-icons font-20 vm">business</i> Small Business</label>

                                    <input name="type" type="radio" id="international_company" className="with-gap radio-col-indigo" value="INTERNATIONAL_COMPANY" onChange={this.changeType} defaultChecked={type == "INTERNATIONAL_COMPANY" ? true : false} /*checked={type=="company" ? true : false }*/></input>
                                    <label htmlFor="international_company" className="col-xlg-2 col-lg-3 col-md-3 col-sm-6 font-16" > <i className="material-icons font-20 vm">business</i> International Company</label>

                                    <input name="type" type="radio" id="investor" className="with-gap radio-col-indigo" value="investor" onChange={this.changeType} defaultChecked={type == "investor" ? true : false} /*checked={type=="investor" ? true : false }*/></input>
                                    <label htmlFor="investor" className="col-xlg-2 col-lg-3 col-md-3 col-sm-6 font-16"><i className="material-icons font-20 vm">monetization_on</i> Investor</label>


                                    <input name="type" type="radio" id="people" className="with-gap radio-col-indigo" value="people" onChange={this.changeType} defaultChecked={type == "people" ? true : false} /*checked={type=="people" ? true : false }*/></input>
                                    <label htmlFor="people" className="col-xlg-2 col-lg-3 col-md-3 col-sm-6 font-16"><i className="material-icons font-20 vm">people</i> People</label>


                                    <input name="type" type="radio" id="opportunity" className="with-gap radio-col-indigo" value="opportunity" onChange={this.changeType} defaultChecked={type == "opportunity" ? true : false} /*checked={type=="opportunity" ? true : false }*/></input>
                                    <label htmlFor="opportunity" className="col-xlg-2 col-lg-3 col-md-3 col-sm-6 font-16"><i className="mdi mdi-trending-up font-20 vm"></i>  Opportunity</label>


                                    <input name="type" type="radio" id="event" className="with-gap radio-col-indigo" value="event" onChange={this.changeType} defaultChecked={type == "event" ? true : false} /*checked={type=="event" ? true : false }*/></input>
                                    <label htmlFor="event" className="col-xlg-2 col-lg-3 col-md-3 col-sm-6 font-16"> <i className="mdi mdi-calendar font-20 vm"></i>  Event</label>

                                    {/* <input name="type" type="radio" id="funding" className="with-gap radio-col-indigo" value="funding" onChange={this.changeType} defaultChecked={type == "funding" ? true : false} /*checked={type=="event" ? true : false }></input>
                                    <label htmlFor="funding" className="col-xlg-2 col-lg-3 col-md-3 col-sm-6 font-16"> <i className="material-icons font-20 vm">local_atm</i>  Funding Rounds</label>
                                 */}

                                </div>
                            </div>


                            <form action="#" className="shortForm">
                                <div className="form-body clearfix">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="control-label font-bold font-18">Name / Contains Words</label>
                                                <input type="text" id="contain-words" className="form-control" name="query" value={this.state.query} onChange={this.handleFieldChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="control-label font-bold font-18">Country</label>
                                                <Select
                                                    className="font-14 col-md-12 p-0 m-0"
                                                    name="country"
                                                    options={this.state.countryOptions}
                                                    placeholder="Select country"
                                                    value={this.state.country}
                                                    onChange={this.handleCountryChange}
                                                    isClearable={true}
                                                    filterOption={createFilter(countrySearchConfig)}
                                                    isMulti
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="control-label font-bold font-18">City/Town</label>
                                                <Select
                                                    className="font-14 col-md-12 p-0 m-0"
                                                    name="city"
                                                    options={this.state.cityOptions}
                                                    placeholder="Select city/town"
                                                    value={this.state.city}
                                                    onChange={this.handleSelectChange}
                                                    isClearable={true}
                                                    isDisabled={this.state.cityDisabled}
                                                    isMulti
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {form}
                                    <div className="row">
                                        <div className="col-md-12">
                                            <button className="btn btn-theme float-right text-white btn-normal font-16" onClick={this.doSearch}>Search</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        :
                        <div className="card-body p-t-0">
                            {this.renderSearchCriteria()}
                        </div>
                    }
                </div>

                {/* {!editSearch && type == "people"  && <div className="like-comm btn-lil text-left float-right m-l-5 col-md-12 row" style={{ justifyContent: 'flex-end', top: '-39px', right: 7 }}>
                    <button style={{ width: '100px' }} onClick={this.toggleModal} className="btn btn-theme-white"> <i className="fas fa-user-plus"></i> Add People</button>
                </div>
                } */}
                {editSearch ? "" : !Helpers.isEmpty(searchResults) ? this.renderSearchResults(searchResults) : ""}


                {showModal && <CreatePeople toggleModal={this.toggleModal} showModal={showModal} />}
            </div>
        )
    }
}
export default connect(null, { getMetaData, getSectorData, getIndustryGroupsData, getSubIndustriesData, storeAdvanceSearch })(withRouter(AdvSearchContent));