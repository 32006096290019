import React, { Component } from 'react';
import config from '../../shared/config';
import InvestorSearch from '../wigets/InvestorSearch';
import Axios from 'axios';
import Helpers from '../../shared/custom';
import STR from '../../shared/strings';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default class FundingRoundInvestors extends Component {

    constructor(props){
        super(props);
        this.state={
            addNewInvestor:false,
            addInvestor:false,
            rows:[],
            fundingCurrency:undefined,
            modal:false,
            investors:[],
            loading:false,
            fundingUniqueId:undefined,

            selectedInvestor:[],
            amount:undefined,
            lead:false,
            selectedInvestorVal:[],

            investorId:null,
        }

        this.addNewInvestor = this.addNewInvestor.bind(this);
        this.addInvestor = this.addInvestor.bind(this);
        this.hideInvestorForm = this.hideInvestorForm.bind(this);

        this.fetchFundingInvestors = this.fetchFundingInvestors.bind(this);
        this.renderInvestorRow = this.renderInvestorRow.bind(this);
        this.selectInvestor = this.selectInvestor.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.mapInvestorToFundingRound = this.mapInvestorToFundingRound.bind(this);
        this.removeInvestorFromFundingRound = this.removeInvestorFromFundingRound.bind(this);
        this.handleFieldValueChange = this.handleFieldValueChange.bind(this);
    }  

    componentDidMount(){
        let fundingUniqueId = this.state.fundingUniqueId;
        if(!fundingUniqueId || fundingUniqueId != this.props.fundingUniqueId){
            this.setState({
                fundingUniqueId:this.props.fundingUniqueId,
                fundingCurrency:this.props.fundingCurrency
            },
            () => {
                this.fetchFundingInvestors();
            })
        }
        
    }

    componentWillReceiveProps(){
        let fundingUniqueId = this.state.fundingUniqueId;
        if(!fundingUniqueId || fundingUniqueId != this.props.fundingUniqueId){
            this.setState({
                fundingUniqueId:this.props.fundingUniqueId,
                fundingCurrency:this.props.fundingCurrency
            },
            () => {
                this.fetchFundingInvestors();
            })
        }
    }

    fetchFundingInvestors(){
        this.setState({loading:true})
        Axios.get(
            Helpers.apiURL({
                uri:"funding-round/:fundingUniqueId/investors",
                pathVar:{fundingUniqueId:this.props.fundingUniqueId}
            })
        )
        .then((response) => {
            this.setState({loading:false})
            let investors = response.data.result;
            if(investors){
                this.setState({investors:investors})
            }
        })
        .catch((err) => {
            this.setState({loading:true})
        })

        //fetch here
       
    }
    
    addNewInvestor(){
        this.props.addNewInvestor()
    }

    addInvestor(){
        this.setState({
            addInvestor:true
        })
    }

    hideInvestorForm(){
        this.setState({
            addInvestor:false
        })
    }
    
    handleFieldValueChange(e){        
        let name = e.target.name;
        let value = e.target.value;
        if(name){
            this.setState({[name]:value});
        }
    }      

    selectInvestor(option){        
        this.setState({
            selectedInvestor:option
        })
    }
    
    validateForm(){
        let isValid = true;
        let message = [];
        
        if(!this.state.selectedInvestor){
            isValid = false;
            message.push("INVESTOR_REQUIRED");
        }
    
        // if(Helpers.isEmpty(this.state.amount)){
        //   isValid = false;
        //   message.push("INVESTED_AMOUNT_REQUIRED");
        // }

        
        if(Helpers.isEmpty(this.state.selectedInvestor)){
            isValid = false;
            message.push("INVESTOR_SELECTION_REQUIRED");
          }
    
        if(message.length > 0){
            Helpers.pushTError(message);
            return isValid;
        }
        return isValid;
    }

    mapInvestorToFundingRound(){
        
        let isValid = this.validateForm();
        if(!isValid){
            return false;
        }
        
        let selectInvestor = this.state.selectedInvestor;
        selectInvestor = selectInvestor[0];

        let payload = {
            "amount": Helpers.cleanNumber(this.state.amount),
            "currency": {
              "id": this.state.fundingCurrency?this.state.fundingCurrency.value:"",
            },
            "investor": {
              "uniqueId": selectInvestor.uniqueId
            },
            "lead": this.state.lead,
            "partners": []
        }

        this.setState({loading:true})        
        Axios.post(
            Helpers.apiURL({
                uri:"funding-round/:fundingUniqueId/investor",
                pathVar:{
                    fundingUniqueId:this.props.fundingUniqueId
                }
            }),
            payload
        )
        .then((response) => {
            Helpers.pushTSuccess("SAVE_SUCCESS")
            this.setState({
                addInvestor:false,
                loading:false,
                selectedInvestor:undefined,
                amount:undefined,
                lead:false,
                modal:false,
            })
            this.fetchFundingInvestors();
        })
        .catch((err) => {
            this.setState({loading:false})
        })
       
    }
    removeInvestorFromFundingRound(target){
        let dataset = target.dataset;
        let investorUniqueId = undefined;
        let index = dataset.index || undefined;

        if(dataset.uniqueid){
            investorUniqueId = dataset.uniqueid;
        }

        if(!investorUniqueId){
            return false;
        }

        this.setState({loading:true })
        Axios.delete(
            Helpers.apiURL({
                uri:"funding-round/:fundingUniqueId/investor/:investorUniqueId",
                pathVar:{
                    fundingUniqueId:this.props.fundingUniqueId,
                    investorUniqueId:investorUniqueId
                }
            })
        )
        .then((response) => {
            Helpers.pushTSuccess("SAVE_SUCCESS")
            
            if(index){
                let investors = this.state.investors;

                investors.splice(index,1);
                this.setState({
                    investors:investors,
                    addInvestor:false,
                    loading:false
                })
            }else{
                this.fetchFundingInvestors();
            }
            
        })
        .catch((err) => {
            this.setState({loading:false})
        })
    }

    editInvestor =(e)=>{
        let id = e.currentTarget.getAttribute('data-id');
        this.setState({

            id:id
        })
        this.fetchInvestorDetails(id);
    }
   
    fetchInvestorDetails = (id)=>{

        Axios.get(
            Helpers.apiURL({
                uri:`funding-round-investor-map/:id`,
                pathVar:{'id':id}
            })
        )
        .then( (response) => {     
            let result = response.data.result;
            
            this.setState({
                selectedInvestorVal:Helpers.groupIdNameToValueName([result.investor]),
                selectedInvestor:[result.investor],
                lead:result.lead,
                currency:result.currency,
                amount:result.amount,
            },()=>{
                this.setState({
                    modal:true,
                })
            })
        })
        .catch((err) => { 
            //Helper.pushTError("PROFILE_NOT_FOUND");
        });
    }
    closeMode =()=>{
        this.setState({
            modal:false,
            investorId:null,
            addInvestor:false,
            loading:false,
            selectedInvestor:undefined,
            amount:undefined,
            lead:false,
        })
    }

    renderInvestorRow(entry, index){
        let image = entry.investor && entry.investor.image ? Helpers.dataPath(entry.investor.image) : Helpers.dataPath(config.defaultUserImage);
        let currency = entry.currency?entry.currency.shortName:"";
        let removeConfirm = {
            proceed:this.removeInvestorFromFundingRound
        }
        return(
            <tr key={"FR-"+this.state.fundingUniqueId+"-"+index}>
                <td>
                    <img src={image}  className="img-circle" width="30" /> &nbsp;{entry.investor.name}
                </td>
                <td>
                    {entry.amount && entry.amount != 0 ? Helpers.prettyNumber(entry.amount) : STR.UNDISCLOSED} 
                    {entry.amount && entry.amount != 0 ? " "+currency : ""}
                </td>
                <td>{entry.lead ? STR.YES : STR.NO }</td>
                {/* <td><a className="point">{this.state.loading? "..." : STR.EDIT}</a></td> */}
                <td>
                    <a className="point m-r-10" onClick={this.editInvestor} data-index={index} data-id={entry.id}><i className="fa fa-edit"></i></a>
                    <a className="point" onClick={(event) => Helpers.actionConfirm(event,removeConfirm)} data-index={index} data-uniqueid={entry.investor.uniqueId}>{this.state.loading? "..." : STR.DELETE}</a>
                </td>
            </tr>
        )
    }
  render() {
    let addInvestor = this.state.addInvestor;
    let investors = this.state.investors;
    let investorsLength = investors.length;
    let { modal } = this.state;

    return (
      <div>    
              <div>
                <Modal isOpen={modal}>
                    <ModalHeader>Edit Investor</ModalHeader>
                    <ModalBody>
                        <div className="col-md-12 col-sm-12">
                            <InvestorSearch title="Investor Name" headingClass="font-12 isMandatoryLabel" onChange={this.selectInvestor} defaultSelected={this.state.selectedInvestorVal}/>
                        </div>
                        <br/>
                        <div className="col-md-12 col-sm-12">
                            <h5 className=" m-b-10 font-12">Amount</h5>
                            <input className="form-control" type="text" name="amount" onChange={this.handleFieldValueChange} value={this.state.amount} defaultValue={this.state.amount}/>
                        </div>
                        <br/>
                        <div className="col-md-12 col-sm-12">
                            <h5 className="m-b-10 font-12">Lead Investor</h5>
                            <div className="m-b-10">
                                <div className="demo-radio-button">
                                    <div className="float-left col-md-6 col-lg-3 p-0">
                                        <input name="lead" type="radio" id={this.props.identify+"-lead1"} className="with-gap radio-col-blue" value={true} defaultChecked={this.state.lead ? true : false} onChange={this.handleFieldValueChange} />
                                        <label htmlFor={this.props.identify+"-lead1"} className="l-h-0">Yes</label>
                                    </div>
                                    <div className="float-left col-md-6 col-lg-3 p-0">
                                        <input name="lead" type="radio" id={this.props.identify+"-lead2"} className="with-gap radio-col-blue" value={false} defaultChecked={!this.state.lead ? true : false} onChange={this.handleFieldValueChange} />
                                        <label htmlFor={this.props.identify+"-lead2"} className="l-h-0">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                    <Button color="primary" onClick={this.mapInvestorToFundingRound}>Save</Button>{' '}
                    <Button color="secondary" onClick={this.closeMode}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                </div>
            <div>
                <h4 className="card-title font-medium">Investors in Funding Round</h4>
                <div className="row">
                    <div className="table-responsive col-md-8 col-xs-12" >
                    {investorsLength > 0 ?
                        <table className="table font-14">
                            <thead>
                                <tr>
                                    <th>Investor Name</th>
                                    <th>Invested Amount</th>
                                    <th>Lead Investor</th>
                                    {/* <th></th> */}
                                    <th></th>
                                </tr>                       
                            </thead>
                            <tbody className="bg-white">
                                {investors.map((item,index) => (
                                    this.renderInvestorRow(item,index)
                                ))}                            
                            </tbody>
                        </table>
                    :""}   
                    </div>
                </div> 
            </div>
          
            {addInvestor ?
                <div className="row addNewInvestor">
                    <div className="col-md-3 col-sm-12">
                        <InvestorSearch title="Investor Name" headingClass="font-12 isMandatoryLabel" onChange={this.selectInvestor}/>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <h5 className=" m-b-10 font-12">Amount</h5>
                        <input className="form-control" type="text" name="amount" onChange={this.handleFieldValueChange} value={this.state.amount}/>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <h5 className="m-b-10 font-12">Lead Investor</h5>
                        <div className="m-b-10">
                            <div className="demo-radio-button">
                                <div className="float-left col-md-6 col-lg-3 p-0">
                                    <input name="lead" type="radio" id={this.props.identify+"-lead1"} className="with-gap radio-col-blue" value={true} defaultChecked={this.state.lead ? true : false} onChange={this.handleFieldValueChange} />
                                    <label htmlFor={this.props.identify+"-lead1"} className="l-h-0">Yes</label>
                                </div>
                                <div className="float-left col-md-6 col-lg-3 p-0">
                                    <input name="lead" type="radio" id={this.props.identify+"-lead2"} className="with-gap radio-col-blue" value={false} defaultChecked={!this.state.lead ? true : false} onChange={this.handleFieldValueChange} />
                                    <label htmlFor={this.props.identify+"-lead2"} className="l-h-0">No</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <h5 className="m-b-10 font-12">&nbsp;</h5>
                        {this.state.loading?
                            <button className="btn btn-white" >{STR.SAVING}</button>
                        :
                            <div>
                                <button className="btn btn-info m-r-10" onClick={this.mapInvestorToFundingRound} >Add</button>
                                <button className="btn btn-danger" onClick={this.hideInvestorForm} >Cancel</button>
                            </div>
                        }
                        
                    </div>
                    <div className="col-md-1 col-sm-12">
                        
                    </div>
                </div>
                :
                <div className="row">
                    <div className="col-12 m-t-10">
                        <a href="javacript:void(0)" className="float-left" onClick={this.addInvestor}>+ Add Investor</a>
                        <a href="javacript:void(0)" className="float-left m-l-20"  onClick={this.addNewInvestor} >+ Add New Investor</a>
                    </div>
                    <div className="clearfix"></div>
                </div>
                
                
            }        
      </div>
    )
  }
}
