import React from 'react';
import { Link } from "react-router-dom";
import Helpers from '../../../shared/custom';
import config from '../../../shared/config';
import StarRating from "../Details/StarRating";
import Interest from '../../uiActions/Interest';

function OppCard(props) {
    let list = props.data;
    let image = list && list.image ? Helpers.dataPath(list.image) : Helpers.dataPath(config.defaultOpportunityCover);
    let mode = props.mode;
    let interest =  props.interest;

    const delistOpp = (e)=>{
        let id  = e.dataset.id;
        let idx  = e.dataset.index;
        props.delistOpp(id,idx)
    }

    let delistConfig = {
        proceed: delistOpp,
        message:"Are you sure to delist this opportunity!"
    };
    
    return (
        <div className={props.from == "slider" ? "m-10" : props.mainClass ? props.mainClass : ""}>
            {
                mode == "list" ? 
                <div className="card-body banner-small list">
                <div className="col-lg-12 p-0">
                    <div className="col-lg-12 p-0 d-flex align-center">
                        <div className="m-t-0 img-holder">
                            <img src={Helpers.dataPath(image)} className="" width="150"/>
                        </div>
                        <div className="text-left">
                              <h2 className="card-title m-t-5 m-b-0 font-18 text-default">
                                  {list && list.uri ?<Link to={`/opportunity/${list.id}?isPublished=${list && list.status  && list.status.value == "LISTED"}`} className="font-bold">{list && list.title ? list.title : "-"}</Link> : list && list.title ? list.title:""}
                                  <a className="font-12 m-l-5" data-id={list.id} data-index={props.idx} onClick={event =>
                                                  Helpers.actionConfirm(event, delistConfig)
                                        }><i class="fa fa-unlink" data-id={list.id} data-index={props.idx} ></i></a>
                              </h2>
                              <span className="btn oppBtn font-10 m-t-0">
                                    {list && list.oppTypeLabel ? list.oppTypeLabel  : "--"}
                              </span>
                              <p className="m-b-0 font-10 m-t-5">Status: {list && list.status  && list.status.value ? <span className={list.status.value == "LISTED" ? "text-green":"text-danger"}>{list.status.label}</span>: "-"}</p>
                              <p className="m-b-0 font-10"><i className="fa fa-eye"></i> {list && list.viewsCount ? list.viewsCount : "-"} Views</p>
                            
                              {list  && list.ratingDetails && list.ratingDetails && 
                              <p>
                                <StarRating pointerStyle={"noLink"} masterClass="float-left m-t-2" rating={list.ratingDetails && list.ratingDetails.rating ? list.ratingDetails.rating: 0} /> 
                                {list.ratingDetails && list.ratingDetails.rating && list.ratingDetails.rating  > 0 ? <span className="float-left font-medium font-14 m-l-5">{`${list.ratingDetails.rating}/5`}</span>:""} 
                                {list.ratingDetails && list.ratingDetails.noOfRatings && list.ratingDetails.noOfRatings  > 0  ? <span className="float-left font-medium font-14 m-l-5"> {` (${list.ratingDetails.noOfRatings} ${list.ratingDetails.noOfRatings > 1 ? "reviews":"review"})`} </span>:""}
                              </p>}
                        </div>
                    </div>
                </div>
            </div>
                :
                <div className={`card oppCard ${props.className  ? props.className : "col-md-4"} p-0`}>
                    <div className="card-body p-10 header">
                        <div className="d-flex oppCardin">
                            <div className="">
                            <Link to={`/opportunity/${list.id}`}>
                                <img 
                                    src={image}
                                    className="oppCardImg"/>
                            </Link>
                            </div>
                            <div className="m-l-10 text-left font-10">
                                <span className="btn oppBtn font-10">
                                    {list && list.oppTypeLabel ? list.oppTypeLabel  : "--"}
                                </span>
                                <p className="m-b-5"><i className="fa fa-star"></i> {list && list.interestCount ? list.interestCount : "0"} Interested</p>
                                <p className="m-b-0"><i className="fa fa-eye"></i> {list && list.viewsCount ? list.viewsCount : "0"} Views</p>
                            </div>
                        </div>

                    </div>
                    <div className="card-body p-10 header text-left">
                        <h5 className="font-12">{list && list.uri ?<Link to={`/opportunity/${list.id}`} className="font-bold">{list && list.title ? list.title : "-"}</Link> : list && list.title ? list.title:""}</h5>
                        {/* {interest &&  <p className="font-10"><b>{list && list.oppTypeLabel ? list.oppTypeLabel  : "--"}</b></p> } */}
                        <p className="font-10 oppDescription">{list && list.description ? Helpers.truncate(list.description,100): "-"}</p>
                        {list && list.oppType == "INVESTMENT" ? 
                            <p className="font-10">Investment Amount:<span className="font-bold">{list && list.investmentAmount ? Helpers.prettyNumber(list.investmentAmount) : "-"}</span> </p>
                            :
                            <p className="font-10">&nbsp;</p>
                        }
                        {interest && 
                            <div className="like-comm text-left"> 
                                <Interest id={list.id} type={"OPPORTUNITY"} interested={list._isIntrested} masterClassName="float-left font-12"/>
                            </div>
                        }
                        {/* {!interest &&
                            <span className="btn oppBtn font-10">
                                {list && list.oppTypeLabel ? list.oppTypeLabel  : "--"}
                            </span>
                        } */}
                       
                        
                    </div>
             </div>
            }
            
        </div>
    )
}

export default OppCard
