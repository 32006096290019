import React, { Component } from 'react';
import CommentsAction from './CommentsAction';
import config from '../../shared/config';
import Axios from 'axios';
import { withRouter, Link } from 'react-router-dom';
import Helpers from '../../shared/custom';
import store from '../../store';
import ReactTimeAgo from 'react-time-ago';
import JavascriptTimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
JavascriptTimeAgo.locale(en)

export default class Comments extends Component {


  constructor(props){
      super(props);
      this.state={
        id:null,
        feedUniqueId: null,
        comment:null,
        savedComments:[],
        user:undefined,
        limitTo:-5,
        remaning:0,
        editComment:false,
        canEdit:1,
        localAuth:{},
        loading:false,
        profileDetails:{}
      }
      this.enterPressed = this.enterPressed.bind(this);
      this.onChangeComment = this.onChangeComment.bind(this);
      this.showMore = this.showMore.bind(this);
      this.getComments = this.getComments.bind(this);
      this.deleteComment = this.deleteComment.bind(this);
      this.enableEdit = this.enableEdit.bind(this);
      this.postComment = this.postComment.bind(this);

      this.saveComment =this.saveComment.bind(this);
      this.updateComment = this.updateComment.bind(this);
      this.focusInput = this.focusInput.bind(this);
      this.cancelEdit = this.cancelEdit.bind(this);

       store.subscribe(() => { 
           this.getUserDetails();
          
       })

}


   componentDidMount(){
        let mainData = this.props.data;
        let feedUniqueId = mainData.feedUniqueId;
        this.getUserDetails();
        
        this.setState({
             profileDetails:localStorage && localStorage.getItem("profile") ? JSON.parse(localStorage.getItem("profile")) : null
        }) 
        this.setState({
            feedUniqueId:feedUniqueId
        },()=>{
            this.getSavedComments();
        });
   }

   focusInput(){
    let textarea = this.refs.commentsField;
    textarea.focus()
   }
  


   showMore(){
       if(this.state.limitTo < this.state.remaning){
        this.setState({
            limitTo:-this.state.savedComments.length,
            remaning:0
        })
       }
   }
  

   getUserDetails(){
       var storeState = store.getState();   
       let user = localStorage && localStorage.userInfo ? JSON.parse(localStorage.userInfo) :{} ;
        if(!Helpers.isEmpty(user)){
        this.setState({
            user:user
            })
        }
      
   }

   deleteComment(ids){
        this.setState({
            loading:true
        })
       let id = ids.id;
       let feedUniqueId = ids.feedUniqueId;
       Axios.delete(
        Helpers.apiURL({
            uri:"comment/"+id
        })
        )
        .then( (response) => { 
            this.setState({
                loading:false
            })
            Helpers.pushSuccess("Comment deleted successfully")
            this.getSavedComments();
        })
        .catch((err) => {
            this.setState({
                loading:false
            })
            Helpers.pushError("Comment cannot be deleted")
        });


   }

   getSavedComments(){
    let feedUniqueId = this.state.feedUniqueId;
    Axios.get(
        Helpers.apiURL({
            uri:"comments/parent/:feedUniqueId",
            pathVar:{feedUniqueId:feedUniqueId},
        })
    )
    .then( (response) => {
          let dataset = response.data.result;
          let comments = [];
          let user = this.state.user;

          if(!Helpers.isEmpty(dataset)){
            dataset.forEach((data,i)=>{
                if(data.user.uniqueId == user.uniqueId){
                     data.editableCmt = true;
                }else{
                    data.editableCmt = false;
                }
                comments.push(data);
            })
          }
          
          this.setState({
             savedComments:comments,
             remaning:comments.length > 5 ?  comments.length + this.state.limitTo : 0
          },()=>{
              if(this.state.remaning <= 0){
                    this.setState({
                        remaning:0
                    })
              }
          })
    })
    .catch((err) => {
        
    });
   }


   enableEdit(ids){
      
       this.setState({
            editComment : true
       });

       let id = ids.id;
       let feedUniqueId = ids.uniqueid;
       let index = ids.index;
       this.setState({
           id:id,
           feedUniqueId:feedUniqueId,
           canEdit:index
       })
   }
   
   enterPressed(event) {
    var code = event.keyCode || event.which;
    
   }

   postComment(event){
       event.preventDefault();
       let payload ={};
       if(Helpers.isEmpty(this.state.comment)){
           Helpers.pushTError("Comments field should not be empty");
           return false;
       }
       payload.comment = this.state.comment;
       payload.feedUniqueId = this.state.feedUniqueId;
   
      
       let id = this.state.id;
   
       if(!Helpers.isEmpty(id)){
           payload.id = id;
       }
   
    //    if(code === 13 && !event.shiftKey) { 
    //        event.preventDefault();
           if(Helpers.isEmpty(id)){
               this.saveComment(payload);
           }else{
               this.updateComment(payload)
           }
    //    } 
   }


   saveComment(payload){
        this.setState({
            loading:true
        })
        Axios.post(
            config.api("comment/"),
            payload)
        .then( (response) => {
            this.setState({
                loading:false
            })
            let data = response.data.result;
            let textarea = this.refs.commentsField;
            textarea.value ="";
            this.getSavedComments();
        })
        .catch((err) => {
            this.setState({
                loading:false
            })
        });
   }

   updateComment(payload){
        this.setState({
            loading:true
        })
        Axios.put(
            config.api("comment/"),
            payload)
        .then( (response) => {
            this.setState({
                loading:false
            })
            let data = response.data.result;
            this.getSavedComments();
            this.setState({
                id:null,
                editComment:false
            })
        })
        .catch((err) => {
            this.setState({
                loading:false
            })
        });
   }

   onChangeComment(event){
        this.setState({
            comment:event.target.value
        })
   }
   cancelEdit(){
    this.setState({
        editComment:false
    })
   }

   getComments(){
       let savedComments = this.state.savedComments;
       savedComments = savedComments.slice(this.state.limitTo);
       return savedComments;
   }

  render() {
    let loading =  this.state.loading;
    let savedComments = this.getComments();
    let user = this.state.user;
    let editComment = this.state.editComment;
    let additionalClass = this.props.additionalClass;
    let enableFocus = this.props.enableFocus;
    let profileDetails = this.state.profileDetails;

    let pageDetails = user && user.pageDetails ? user.pageDetails : null;

    var regStatus = pageDetails && pageDetails.pageDto && pageDetails.pageDto.regStatus && pageDetails.pageDto.regStatus.value ? pageDetails.pageDto.regStatus.value  : null;



    let profilePicUrl =  !Helpers.isEmpty(profileDetails) && !Helpers.isEmpty(profileDetails.profilePic)
    ? Helpers.isEmpty(regStatus) ? Helpers.dataPath(profileDetails.profilePic.publicUri) 
    : !Helpers.isEmpty(regStatus) && regStatus == "PUBLISHED" ? Helpers.dataPath(profileDetails.profilePic.publicUri) : Helpers.dataPath(profileDetails.profilePic.uri)
    : Helpers.dataPath(config.defaultUserImage);

    if(enableFocus){
        let textarea = this.refs.commentsField;
        textarea.focus()
    }

  

    return (
      <div className="card-body p-0">
            <LoadingOverlay>
                    <Loader fullPage loading={loading}/>
            </LoadingOverlay>
           <div className={"text-left "+additionalClass?additionalClass:""}>
                        <hr/>
                            {this.state.remaning >0 ?
                                <a href="javascript:void(0)" className="font-14 col-12 text-left" onClick={this.showMore}>View All Comments({this.state.remaning})</a>
                                :
                                ""
                            }
                            <div className="profiletimeline text-left">
                            {!Helpers.isEmpty(savedComments) ?
                              savedComments.map((data,i)=>(
                                <div className="sl-item font-12 m-b-5-i" key={"comment"+i} id={data.id}>
                                    <div className="sl-left"> <img src={Helpers.dataPath(data.user.image ? data.user.image : config.defaultUserImage)}  alt="user" className="img-circle" /> </div>
                                    <div className="sl-right">
                                        <div>
                                            <div className="col-12 p-0">
                                                <div className="col-md-8 p-0 col-sm-8 float-left cmt-cont">
                                                {editComment && i == this.state.canEdit?
                                                    <div>
                                                     <textarea 
                                                            className="form-control commentInput commentEditInput font-12 col-md-8 col-sm-8 float-left"
                                                            placeholder="Type your comments ...."
                                                            onKeyPress={this.enterPressed}
                                                            onChange={this.onChangeComment}
                                                            defaultValue={data.comment}
                                                        >
                                                      </textarea>
                                                        <a className="m-l-5 float-left text-info p-2" onClick={this.postComment}>Save</a>
                                                        <a className="m-l-5 float-left text-danger p-2" onClick={this.cancelEdit}>Cancel</a>
                                                      </div>

                                                    :
                                                    <div className="comments"> 
                                                        <Link to={"/user/"+data.user.uri} className="link font-14 text-info" target="_blank">{data.user.fullName}</Link> <span className="sl-date"> <ReactTimeAgo date={new Date(data.date)} /></span>
                                                        <p className="m-b-0">{data.comment}</p>
                                                    </div>
                                                }
                                                    
                                                </div>
                                                <div className="col-sm-2 col-md-2 float-left p-1 m-l-5">
                                                {data.editableCmt ?
                                                        <CommentsAction data={data} index={i} deleteComment={this.deleteComment} enableEdit={this.enableEdit}/>
                                                    :
                                                    ""
                                                }
                                                </div>
                                            </div>
                                        <div className="clearfix"></div>
                                            <a href="javascript:void(0)" className="link text-info hidden">Reply</a> 
                                        </div>
                                    </div>
                                </div>
                              ))
                                :
                                ""

                             }

                                <div className="sl-item font-12 m-b-5-i d-flex flex-row">
                                        <div className="sl-left"> <img src={profilePicUrl} alt="user" className="img-circle" /> </div>
                                            <div className="d-flex flex-row col-12">
                                                <textarea 
                                                    className="form-control commentInput font-10 float-left"
                                                     placeholder="Type your comments ...."
                                                     onKeyPress={this.enterPressed}
                                                     onChange={this.onChangeComment}
                                                     ref="commentsField"
                                                >
                                                </textarea>
                                                <button className="btn btn-info float-left m-l-5" onClick={this.postComment}>Post</button>
                                            </div>
                                    </div>
                               </div>
                        </div>
        
      </div>
    )
  }
}
