import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import Helper from "../../shared/custom";
import { connect } from 'react-redux';
import store from "../../store";
import Axios from 'axios';
import config from '../../shared/config';
import StarRating from "../ui/StarRating";
import BatchList from "../ui/BatchList";
import MiniCard from "../ui/MiniCard";
import MicroCard from "../ui/MicroCard";
import SocialCard from "../ui/SocialCard";
import AddressCard from "../ui/AddressCard";
import CompanyIcon from "../../components/companyDetails/CompanyIcon";





import "../../css/print.css";


class CompanyPrintPage extends Component {
    constructor(props){
        super(props);

        this.state = {
            localStore:{},
            companyDetails:{},
            userDetails:{},
            overview:{},
            page:{},
            startup: {},
        }


    }

    componentDidMount(){
       
         var storeState = store.getState();
         var userDetails  = storeState.auth.user;
         document.body.classList.add('bg-white');

         var topBar = document.getElementsByClassName("topbar");
         var subMenu =  document.getElementsByClassName("subMenu")
         setTimeout(()=>{
            if(topBar.length > 0){
                topBar[0].classList.add('hidden');
             }
             if(subMenu.length > 0){
                subMenu[0].classList.add('hidden');
             }
         })
        
    
         this.setState({
             userDetails:userDetails
         })

        
        this.getCompanyDetails()

    }

    getCompanyDetails = () => {
        let uri = Helper.lastSegment(window.location.pathname)
        Axios.get(
            Helper.apiURL({
                uri:"COMPANY/"+uri,
                query:{facets:"page,company,keyPeople,bod,companyAquisitions,investor,startup"}
            })
        )
        .then( (response) => {          
              let companyDetails = response.data.result || [];
             this.setState({
                 companyDetails:companyDetails,
                 overview: companyDetails.company ? companyDetails.company : null,
                 page: companyDetails.page ? companyDetails.page : null,
                 startup: companyDetails.startup ? companyDetails.startup : null,
                });

            
           })
        .catch((err) => {
            
        });
    }
    render() {
        let userDetails = this.state.userDetails;
        let todayDate = Helper.getDateInFormat(new Date());

        var empty = Helper.isEmpty(this.state.page);
        var page = this.state.page;
      
        var location = "";
        let loading = this.state.loading;
        let startup = this.state.startup;
        let company = this.state.company;
        let pageImage = config.defaultCompanyImage;
        if (!empty) {
        if (page.city) {
            location += page.city.name;
        }
        if (page.country) {
            location = Helper.isEmpty(location)
            ? page.country.name
            : location + ", " + page.country.name;
        }

        pageImage = page.image || config.defaultCompanyImage;
        } else {
        loading = false;
        }
        var overview = this.state.overview;
        let pageTypes = page && page.pageTypes?page.pageTypes:[];
        let companyType = overview && overview.companyType?overview.companyType:null;
    
    
        return (
            <div>
                 <div >
                        <div className="row content-in m-t-80 p-t-10">
                            <div className="col-lg-12 col-xlg-12 col-md-12 centerDiv">
                                <div className="book">
                                    {/* page 1 */}
                                    <div className="page">
                                        <div className="subpage">
                                           <table class="titleTable">
                                                <tbody>
                                                    <tr>
                                                        <td class="no-border pdf-logo-cont" >
                                                            <div class="logo">
                                                                 {/* <img src={config.cdn +'theme/images/plus-logo.png?'} alt="Investera +" className="dark-logo logo-pdf" /> */}
                                                                 <img src={config.dataCdnStatic +'plus-logo-white.png?'} alt="Investera +" className="dark-logo logo-pdf" />
                                                            </div>
                                                        </td>
                                                        <td class="no-border pdf-title">
                                                            Generate By Investera + for {Helper.isEmpty(userDetails)  ? "" : userDetails.fullName}
                                                        </td>
                                                        <td class="no-border pdf-date">
                                                            <div className="float-right">Last Updated: {todayDate}</div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>    
                                            <hr/>
                                            <div className="card bold-headers">
                                                <div className="card-body">
                                                    <div className="col-lg-12 p-0">
                                                        <div className="m-t-5">
                                                        <img
                                                            src={Helper.dataPath(pageImage)}
                                                            className="float-left img-rounded"
                                                            width="100" height="100"
                                                        />
                                                        <div className="card-body-right float-left m-l-10 text-left">
                                                            <h6 className="card-title m-t-10 m-b-5 l-h-25 font-14">
                                                            <CompanyIcon page={this.state.page} />&nbsp;
                                                            {page.name}
                                                            </h6>
                                                            {overview.companyType ? (
                                                            <h6 className="m-t-0 m-b-5 l-h-25  font-medium font-14"><i class="mdi mdi-domain"></i>&nbsp;{overview.companyType.name}</h6>
                                                            ) : (
                                                            ""
                                                            )}
                                                            {overview.primarySector ? (
                                                            <h6 className="card-custom-subtitle l-h-25  m-t-0 font-medium font-14">
                                                                 <i class="mdi mdi-bank"></i> {overview.primarySector.sector.name}<span>{!Helper.isEmpty(pageTypes) && pageTypes[0] && pageTypes[0].subType && pageTypes[0].subType.id == config.startupPageSubTypeId ? !Helper.isEmpty(overview.primarySector.sector.name) ?' -- '+pageTypes[0].subType.type : pageTypes[0].subType.type : ""}</span>
                                                            </h6>
                                                            ) : (
                                                            ""
                                                            )}
                                                            {location ? (
                                                            <h6 className="text-grey m-t-0 m-b-5 l-h-25  font-medium font-14"><i class="mdi mdi-map-marker"></i>&nbsp;{location}</h6>
                                                            ) : (
                                                            ""
                                                            )}
                                                            {page.website ? (
                                                                <h6 className="text-grey m-t-0 m-b-5 l-h-25  font-medium font-14"><i class="mdi mdi-earth"></i> <a
                                                                href={Helper.externalURL(page.website)}
                                                                target="_blank"
                                                                >
                                                                {page.website}
                                                                </a></h6>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <StarRating rating={overview && overview.rankingDetails && overview.rankingDetails.ranking ? overview.rankingDetails.ranking : 0} page={this.state.page} scrollToDiv={this.scrollToDivBanner} />
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                <br/>
                                                <div className="card bold-headers" id="summary">
                                                    <div className="card-header text-left light-blue-header">
                                                        <h5 className="m-b-0 m-t-0 text-white">Summary</h5>
                                                    </div>
                                                    <div className="card-body text-left font-12">
                                                        {overview.longDescription || overview.shortDescription ? (
                                                        !Helper.isEmpty(overview.longDescription) ? Helper.newLineToBr(overview.longDescription) : Helper.newLineToBr(overview.shortDescription)
                                                        ) : (
                                                        ""
                                                        )}
                                                    </div>
                                                </div>
                                                <br/>
                                                <div className="card bold-headers" id="classification">
                                                        {!Helper.isEmpty(overview.sectors) ||
                                                        !Helper.isEmpty(overview.industries) ||
                                                        !Helper.isEmpty(page.tags) ? (
                                                        <div>
                                                        <div className="card-header text-left light-blue-header">
                                                            <h5 className="m-b-0 m-t-0 text-white">Classification</h5>
                                                        </div>
                                                        <div className="card-body text-left">
                                                            {overview.sectors ? (
                                                            <BatchList
                                                                title="Sectors"
                                                                items={overview.sectors}
                                                                type="sectors"
                                                            />
                                                            ) : (
                                                            ""
                                                            )}
                                                            {overview.industries ? (
                                                            <BatchList
                                                                title="Industries"
                                                                items={overview.industries}
                                                                type="industries"
                                                            />
                                                            ) : (
                                                            ""
                                                            )}
                                                            {page.tags ? (
                                                            <BatchList
                                                                title="Categories"
                                                                items={page.tags}
                                                                extraClass="label-muted"
                                                                type="categories"
                                                            />
                                                            ) : (
                                                            ""
                                                            )}
                                                        </div>
                                                        </div>
                                                        ) : (
                                                        ""
                                                        )}
                                             </div>
                                             <br/>
                                             <div className="card bold-headers" id="contact">
                                                    <div>
                                                        <div className="card-header text-left light-blue-header">
                                                            <h5 className="m-b-0 m-t-0 text-white">Contact</h5>
                                                        </div>
                                                        <div className="card-body text-left">
                                                            <div className="col-12 p-0 free-boxes-container" >
                                                            {!Helper.isEmpty(page.website) ? (
                                                                <MicroCard label="Website" value={page.website} type="web"
                                                                />
                                                            ) : ""
                                                            }
                                                            {!Helper.isEmpty(page.contactEmails) ? (
                                                                <MicroCard label="Email" value={page.contactEmails} type="email" />
                                                            ) : ""
                                                            }
                                                            {!Helper.isEmpty(page.contactNumbers) ? (
                                                                <MicroCard label="Phone" value={page.contactNumbers} type="phone"/>
                                                            ) : ""
                                                            }

                                                            {!Helper.isEmpty(page.addressDto) ? (
                                                                <AddressCard label="Address" value={page.addressDto} type="address" />
                                                            ) : (
                                                                ""
                                                            )}

                                                            {!Helper.isEmpty(page.facebook)  || !Helper.isEmpty(page.twitter) || !Helper.isEmpty(page.linkedIn) ?

                                                            <div className="col-md-4 col-sm-12 float-left p-1">                   
                                                                <div className="col-12 mini-card float-left">
                                                                    <div className="font-bold float-left font-12">
                                                                    Social Media Presence
                                                                    </div>
                                                                    <div className="m-t-30">
                                                                    {page.facebook ? 
                                                                    <SocialCard
                                                                        label="Facebook"
                                                                        value={page.facebook}
                                                                        type="facebook"
                                                                    /> : " "}

                                                                    {page.twitter ? 
                                                                    <SocialCard
                                                                        label="Twitter"
                                                                        value={page.twitter}
                                                                        type="twitter"
                                                                    />
                                                                    :
                                                                    " "
                                                                    }

                                                                    {page.linkedIn ? 
                                                                    <SocialCard
                                                                        label="LinkedIn"
                                                                        value={page.linkedIn}
                                                                        type="linkedin"
                                                                    />
                                                                    :
                                                                    ""
                                                                    }

                                                                    </div>
                                                                </div>                    
                                                            </div>
                                                            :
                                                            ""
                                                            }

                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                        </div>    
                                    </div>

                                    {/* Page 2 */}

                                    <div className="page">
                                        <div className="subpage">
                                            <div className="card bold-headers" id="overview">
                                                <div className="card-header text-left light-blue-header">
                                                    <h5 className="m-b-0 m-t-0 text-white">Overview</h5>
                                                </div>
                                                <div className="card-body text-left">
                                                    <div className="col-md-12 p-0 d-flex grid-container">
                                                    <div className="col-md-6 flex-column p-0">
                                                        {!Helper.isEmpty(overview && overview.legalName) ? (
                                                            <MiniCard label="Legal Name" value={overview.legalName} />
                                                        ) : (
                                                            ""
                                                        )}
                                                        {!Helper.isEmpty(overview && overview.foundedDate) ? (
                                                            <MiniCard
                                                                label="Year Founded"
                                                                value={overview.foundedDate}
                                                            />
                                                            ) : (
                                                            ""
                                                            )}
                                                            {!Helper.isEmpty(overview && overview.companyType) ? (
                                                        <MiniCard
                                                        label="Company Type"
                                                        value={
                                                            !Helper.isEmpty(overview && overview.companyType)
                                                            ? overview.companyType.name
                                                            : ""
                                                        }
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                    {!Helper.isEmpty(overview && overview.employeeCount) ? (
                                                        <MiniCard
                                                        label="Employee Count"
                                                        value={
                                                            !Helper.isEmpty(overview && overview.employeeCount)
                                                            ? overview.employeeCount
                                                            : ""
                                                        }
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                    </div>
                                                    <div className="col-md-6 flex-column p-0">
                                                            {!Helper.isEmpty(overview && overview.otherName) ? (
                                                            <MiniCard label="Other Name" value={overview.otherName} />
                                                            ) : (
                                                            ""
                                                            )}
                                                            
                                                            {!Helper.isEmpty(overview && overview.ownershipStatus) ? (
                                                            <MiniCard
                                                                label="Ownership Status"
                                                                value={
                                                                !Helper.isEmpty(overview && overview.ownershipStatus)
                                                                    ? overview.ownershipStatus.name
                                                                    : ""
                                                                }
                                                            />
                                                            ) : (
                                                            ""
                                                            )}
                                                            
                                                            {!Helper.isEmpty(overview && overview.companySize) ? (
                                                            <MiniCard
                                                                label="Company Size"
                                                                value={
                                                                !Helper.isEmpty(overview && overview.companySize)
                                                                    ? overview.companySize.name
                                                                    : ""
                                                                }
                                                            />
                                                            ) : (
                                                            ""
                                                            )}
                                                            
                                                            {!Helper.isEmpty(overview && overview.areasServed) ? (
                                                            <MiniCard label="Area Served"
                                                                value={
                                                                overview.areasServed ?                         
                                                                    overview.areasServed.map((obj, i) => {
                                                                        return (i!=0 ?", ":"")+obj.name
                                                                    })
                                                                    : 
                                                                    "N/A"
                                                                }
                                                            />
                                                            ) : (
                                                            ""
                                                            )}
                                                        </div>    
                                                    </div>
                                                </div>
                                            </div>  

                                             <div className="card bold-headers" id="financial">
             
                                                {!Helper.isEmpty(overview.financialData) ?
                                                <div>
                                                    <div className="card-header text-left light-blue-header">
                                                    <h5 className="m-b-0 m-t-0 text-white">Financial Details</h5>
                                                </div>
                                                    <div className="card-body text-left">
                                                    <div className="col-12 p-0">
                                                    <MicroCard
                                                        label="Revenue"
                                                        value={overview.financialData && overview.financialData.revenue ? Helper.displayAmount({amount: overview.financialData.revenue,currency:overview.financialData && overview.financialData.currency? overview.financialData.currency : null}): ""}
                                                    />
                                                    <MicroCard
                                                        label="Gross Profit"
                                                        value={overview.financialData && overview.financialData.grossProfit ? Helper.displayAmount({amount: overview.financialData.grossProfit,currency:overview.financialData && overview.financialData.currency? overview.financialData.currency : null}) : ""}
                                                    />
                                                    <MicroCard
                                                        label="Net Income"
                                                        value={overview.financialData && overview.financialData.netIncome ? Helper.displayAmount({amount: overview.financialData.netIncome,currency:overview.financialData && overview.financialData.currency? overview.financialData.currency : null}) : ""}
                                                    />
                                                    </div>
                                                </div>
                                                </div>
                                                : ""
                                                }
                                                
                                            </div>


                                            {Helper.isStartupCompany(pageTypes) && startup ? (
                                            <div className="card bold-headers"  id="startup">
                                            <div className="card-header text-left light-blue-header">
                                                <h5 className="m-b-0 m-t-0 text-white">Startup Details</h5>
                                            </div>
                                            <div className="card-body text-left">
                                                <div className="col-12 p-0">
                                                <MiniCard
                                                    label="Business Type"
                                                    value={
                                                    startup.businessTypes
                                                        ? startup.businessTypes.map((answer) => {
                                                            return answer.name;
                                                        })
                                                        : "N/A"
                                                    }
                                                    type="array"
                                                />
                                                <MiniCard
                                                    label="Development Stage"
                                                    value={
                                                    startup.developmentStage
                                                        ? startup.developmentStage.name
                                                        : ""
                                                    }
                                                />
                                                <MiniCard
                                                    label="Revenue Generating"
                                                    value={startup.isRevenueGenerating}
                                                    type="boolean"
                                                />
                                                <MiniCard
                                                    label="Two Year Projection"
                                                    value={startup.isWithTwoYrProjection}
                                                    type="boolean"
                                                />
                                                <MiniCard
                                                    label="Business Plan Ready"
                                                    value={startup.hasBusinessPlan}
                                                    type="boolean"
                                                />
                                                <MiniCard
                                                    label="Current Fundraising Amount"
                                                    value={
                                                    startup.fundRaisingAmount
                                                        ? startup.fundRaisingAmount.name
                                                        : "NA"
                                                    }
                                                />
                                                <MiniCard
                                                    label="Previous Fundraising Amount"
                                                    value={
                                                    startup.previousAmountRaised
                                                        ? startup.previousAmountRaised.name
                                                        : "NA"
                                                    }
                                                />
                                                <MiniCard
                                                    label="Previous Fund Source"
                                                    value={
                                                    startup.previousFundSource
                                                        ? startup.previousFundSource.name
                                                        : "NA"
                                                    }
                                                />
                                                <MiniCard
                                                    label="Currently Raising Funds"
                                                    value={startup.isRaisingFunds}
                                                    type="boolean"
                                                />
                                                </div>
                                            </div>
                                            </div>
                                            ) : (
                                            ""
                                            )}

                                            
                                        </div>    
                                    </div>

                                    {/* Page 3 */}

                                    <div className="page">
                                        <div className="subpage">
                                            dfdf
                                        </div>
                                    </div>

                                </div>
                            </div>
                       </div>
                    </div>
            </div>
        )
    }
}

export default withRouter(CompanyPrintPage);
