import React, { Component } from 'react';
import Helpers from '../../shared/custom';
import { withRouter } from 'react-router-dom';
import CareerRow from './CareerRow';

class ManageCareerHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            uri: null,
            editCareerHistory: true,
            rows: [],
            currency: "",
            date: new Date(),
            confirmDelete: false,
            total: 0,
            careerHistory: [],
            amount: ""
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleAddMore = this.handleAddMore.bind(this);


    }


    handleClick() {
        this.setState({
            editCareerHistory: this.state.editCareerHistory ? false : true,
        })

        let careerHistory = this.state.careerHistory;
        if (!Helpers.isEmpty(careerHistory)) {
            this.state.rows = careerHistory;
            let rows = this.state.rows;
        }
    }


    componentDidMount() {
        let uri = this.props.uri ? this.props.uri : Helpers.lastSegment(window.location);
        this.setState({
            uri: uri
        }, () => {
        });
        //this.getCompanyDetails(uri)
    }

    componentWillReceiveProps(props) {
        let careerHistory = props.careerHistory;
        
        if (!Helpers.isEmpty(careerHistory)) {
            let rows = [];
            careerHistory.forEach(career => {
                rows[career.id] = career;
            })
            this.setState({
                careerHistory: careerHistory,
                rows: rows
            }, function () {
               
            })
        }
    }

    handleAddMore() {
        var rows = this.state.rows;
        rows.push({
            id: null,
            company: null,
            title: null,
            startDate: new Date(),
            endDate: new Date(),
            isCurrent: false
        })
        this.setState({ rows: rows })
    }

    fetchCareerChild = (savedData)=>{
        if(this.props && this.props.fetchCareer){
             this.props.fetchCareer(savedData)
        }
    }





    render() {
        let rows = this.state.rows;
        let rowData = this.state.rowData;
        let careerHistory = this.state.careerHistory;

        let total = careerHistory.length;
        let careerTitles = this.props.titlesOptions;
        let fixedCareerHistory = [];
        rows.forEach(career => {
            career.isCurrent = career.current;
            fixedCareerHistory.push(career);
        });

        // if(this.props && this.props.fetchCareer){
        //     this.props.fetchCareer()
        // }

        
        careerHistory = fixedCareerHistory;


        return (
            <div>
                <div className="card text-left" id="career">
                    <div className="card-body">
                        <div className="col-lg-12 p-0 float-left">
                            <h5>Career History</h5>

                            <div>
                                <div className="col-md-12 p-0">
                                    <div className="row ">
                                        <div className="col-md-12 float-left">
                                            <div className="col-md-12 box float-left">
                                                <div className="col-md-9 float-left">Number of Occupations </div>
                                                <div className="col-md-3 float-left font-bold text-right">{total}</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="result m-t-20">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 m-b-20">
                                        <div>
                                            <table className="table color-table muted-table aqTable">
                                                <thead>
                                                    <tr>
                                                        <th>Organization Name</th>
                                                        <th>Position</th>
                                                        <th>Start Date</th>
                                                        <th>End Date</th>
                                                        {this.state.editCareerHistory ?
                                                            <th className="text-center">Action</th>
                                                            :
                                                            ""
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        !Helpers.isEmpty(rows) ? 
                                                            rows.map((row, i) => (
                                                                <CareerRow key={i} index={i} row={row} careerTitles={careerTitles} editCareerHistory={this.state.editCareerHistory} fetchCareerChild={this.fetchCareerChild}></CareerRow>
                                                            ))
                                                        :
                                                        <tr><td colspan="5" className="text-center">No Data Found</td></tr>

                                                    }
                                                </tbody>
                                            </table>
                                            {
                                                this.state.editCareerHistory ?
                                                    <div className="uploadTag">
                                                        <a onClick={this.handleAddMore} className="text-blue"> + Add More </a>
                                                    </div>
                                                    :
                                                    ""
                                            }

                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}


export default withRouter(ManageCareerHistory)
