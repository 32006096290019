const countryCode = 
{
    "+213":"DZ",
    "+35818":"AX",
    "+376":"AD",
    "+244":"AO",
    "+1264":"AI",
    "+1268":"AG",
    "+54":"AR",
    "+374":"AM",
    "+297":"AW",
    "+61":"AU",
    "+43":"AT",
    "+994":"AZ",
    "+1242":"BS",
    "+973":"BH",
    "+880":"BD",
    "+1246":"BB",
    "+375":"BY",
    "+32":"BE",
    "+501":"BZ",
    "+229":"BJ",
    "+1441":"BM",
    "+975":"BT",
    "+591":"BO",
    "+387":"BA",
    "+267":"BW",
    "+55":"BR",
    "+673":"BN",
    "+359":"BG",
    "+226":"BF",
    "+257":"BI",
    "+855":"KH",
    "+237":"CM",
    "+1":"CA",
    "+238":"CV",
    "+1345":"KY",
    "+236":"CF",
    "+56":"CL",
    "+86":"CN",
    "+57":"CO",
    "+269":"KM",
    "+242":"CG",
    "+682":"CK",
    "+506":"CR",
    "+385":"HR",
    "+53":"CU",
    "+90392":"CY",
    "+357":"CY",
    "+42":"CZ",
    "+45":"DK",
    "+253":"DJ",
    "+1809":"DM",
    "+1809":"DO",
    "+593":"EC",
    "+20":"EG",
    "+503":"SV",
    "+240":"GQ",
    "+291":"ER",
    "+372":"EE",
    "+251":"ET",
    "+500":"FK",
    "+298":"FO",
    "+679":"FJ",
    "+358":"FI",
    "+33":"FR",
    "+594":"GF",
    "+689":"PF",
    "+241":"GA",
    "+220":"GM",
    "+7880":"GE",
    "+49":"DE",
    "+233":"GH",
    "+350":"GI",
    "+30":"GR",
    "+299":"GL",
    "+1473":"GD",
    "+590":"GP",
    "+671":"GU",
    "+502":"GT",
    "+224":"GN",
    "+245":"GW",
    "+592":"GY",
    "+509":"HT",
    "+504":"HN",
    "+852":"HK",
    "+36":"HU",
    "+354":"IS",
    "+91":"IN",
    "+62":"ID",
    "+98":"IR",
    "+964":"IQ",
    "+353":"IE",
    "+972":"IL",
    "+39":"IT",
    "+1876":"JM",
    "+81":"JP",
    "+962":"JO",
    "+7":"KZ",
    "+254":"KE",
    "+686":"KI",
    "+850":"KP",
    "+82":"KR",
    "+965":"KW",
    "+996":"KG",
    "+856":"LA",
    "+371":"LV",
    "+961":"LB",
    "+266":"LS",
    "+231":"LR",
    "+218":"LY",
    "+417":"LI",
    "+370":"LT",
    "+352":"LU",
    "+853":"MO",
    "+389":"MK",
    "+261":"MG",
    "+265":"MW",
    "+60":"MY",
    "+960":"MV",
    "+223":"ML",
    "+356":"MT",
    "+692":"MH",
    "+596":"MQ",
    "+222":"MR",
    "+269":"YT",
    "+52":"MX",
    "+691":"FM",
    "+373":"MD",
    "+377":"MC",
    "+976":"MN",
    "+1664":"MS",
    "+212":"MA",
    "+258":"MZ",
    "+95":"MN",
    "+264":"NA",
    "+674":"NR",
    "+977":"NP",
    "+31":"NL",
    "+687":"NC",
    "+64":"NZ",
    "+505":"NI",
    "+227":"NE",
    "+234":"NG",
    "+683":"NU",
    "+672":"NF",
    "+670":"NP",
    "+47":"NO",
    "+968":"OM",
    "+680":"PW",
    "+507":"PA",
    "+675":"PG",
    "+595":"PY",
    "+51":"PE",
    "+63":"PH",
    "+48":"PL",
    "+351":"PT",
    "+1787":"PR",
    "+974":"QA",
    "+262":"RE",
    "+40":"RO",
    "+7":"RU",
    "+250":"RW",
    "+378":"SM",
    "+239":"ST",
    "+966":"SA",
    "+221":"SN",
    "+381":"CS",
    "+248":"SC",
    "+232":"SL",
    "+65":"SG",
    "+421":"SK",
    "+386":"SI",
    "+677":"SB",
    "+252":"SO",
    "+27":"ZA",
    "+34":"ES",
    "+94":"LK",
    "+290":"SH",
    "+1869":"KN",
    "+1758":"SC",
    "+249":"SD",
    "+597":"SR",
    "+268":"SZ",
    "+46":"SE",
    "+41":"CH",
    "+963":"SI",
    "+886":"TW",
    "+7":"TJ",
    "+66":"TH",
    "+228":"TG",
    "+676":"TO",
    "+1868":"TT",
    "+216":"TN",
    "+90":"TR",
    "+7":"TM",
    "+993":"TM",
    "+1649":"TC",
    "+688":"TV",
    "+256":"UG",
    "+44":"GB",
    "+380":"UA",
    "+971":"AE",
    "+598":"UY",
    "+1":"US",
    "+7":"UZ",
    "+678":"VU",
    "+379":"VA",
    "+58":"VE",
    "+84":"VN",
    "+84":"VG",
    "+84":"VI",
    "+681":"WF",
    "+969":"YE",
    "+967":"YE",
    "+260":"ZM",
    "+263":"ZW"
}

export default countryCode;