import React, { Component } from 'react';
import { createContext } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Footer from './Footer';
import config from '../../shared/config';

import AboutUs from './AboutUs';
import Membership from './Membership';
import Faq from './Faq';
import Contact from './Contact';

import { connect } from 'react-redux';
import { oauthLogin , fetchUserInfo } from '../../actions/authAction';


import Helpers from '../../shared/custom';

 class Home extends Component {
    constructor(props){
        super(props);
        this.counts = React.createRef();
        this.about = React.createRef();
        this.membership = React.forwardRef();
        this.faq = React.forwardRef();
        this.contact = React.forwardRef();
        
        this.state ={
            groups:[{
                        "image":"startup.png",
                        "description":`Anyone with an interest in
                                the business ecosystem in
                                Africa and beyond.`,
                        "title":"Individual",
                        "url":"/market-researchers.html",
                   },{
                        "image":"startup-eco.png",
                        "description":`Ranging from small
                                enterprises, to startups
                                and established
                                companies that want to
                                sell, scale, collaborate or
                                access investments.`,
                        "title":"Business",
                        "url":"/startup.html"
                    },{
                        "image":"funding.png",
                        "description":`Interested in legitimate
                            businesses and
                            opportunities in Africa to
                            invest in.`,
                        "title":"Investor",
                        "url":"/venture-capital.html"
                    },{
                        "image":"network.png",
                        "description":`Government body looking
                            to inform businesses on
                            legislation both new and
                            present as well as identify
                            new business
                            opportunities.`,
                        "title":<span>Government <br/>
                                    Entity</span>,
                        "url":"/venture-capital.html"
                   }]
        }
    }

    scroll = () =>{
        this.counts.current.scrollIntoView({behavior: 'smooth'})
    }

    scrollToAbout = () =>{

        this.about.current.scrollIntoView({behavior: 'smooth'})
    }


    componentDidMount(){
        const token = Helpers.getQueryParameter("token");
        const error = Helpers.getQueryParameter("error");
        if(!Helpers.isEmpty(token)){
             this.props.oauthLogin(token);
        }
        if(!Helpers.isEmpty(error)){
            Helpers.pushError(error);
       }
    }


    render() {
        return (
        <React.Fragment>
         <div className="new_home m-t-60">
            <div className="container-fluid main-content relative">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-12 sub-content text-left">
                            <h1 className="custom-heading">The Future of Business <br/> Transformation 
                            </h1>
                            <p>African business resource centre that brings together businesses
                                government and investors from across the world.</p>
                            <p className="btns">
                                <a onClick={this.scrollToAbout} className="btn btn-theme explore my-2 my-sm-0" >Explore</a>
                                <Link  to={{pathname:"/auth", activeTab:"register"}}  className="btn btn-theme-white join-now m-l-10 my-2 my-sm-0" >Join Today</Link>
                            </p>
                        </div>
                        <a onClick={this.scroll} className="down-btn">
                            <i className="fa fa-chevron-down" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>
            </div>
            <div className="container count-container" ref={this.counts}>
                <div className="col-md-12">
                            <h2 className="h2 custom-heading">Media coverage</h2>
                </div>
                <div className="row medias">
                    <div className="col-md-6 col-lg-2 count-wrap">
                            <a href="https://africaincmag.com/uae-company-investera-opens-kenya-office-to-link-start-ups-with-global-funds/">
                                 <img className="card-img-logo" src={config.dataCdnStatic + "africa_inc.png"} alt={"Africa INC"}/>
                             </a>
                    </div>
                    <div className="col-md-6 col-lg-2 count-wrap">
                         <a href="https://www.businessdailyafrica.com/bd/markets/capital-markets/uae-company-kenya-startups-with-global-funds-3470598?fbclid=IwAR1FSbZ6FJ7jQrzlfTK7WTdxkkLIdkrTA7B3Zxm-HzvCy_zIMdY686KqHrQ">
                             <img className="card-img-logo" src={config.dataCdnStatic + "business_daily.png"} alt={"Business Daily"}/>
                          </a>
                    </div>
                    <div className="col-md-6 col-lg-2 count-wrap">
                         <a href="https://twitter.com/citizentvkenya/status/1420456431468355590">
                             <img className="card-img-logo" src={config.dataCdnStatic + "citizentv.png"} alt={"Citizen Tv"}/>
                          </a>
                    </div>

                   
                    <div className="col-md-6 col-lg-2 count-wrap">
                            <a href="https://nation.africa/kenya/business/uae-firm-investera-to-link-kenyan-startups-with-global-funds-3474920">
                                 <img className="card-img-logo" src={config.dataCdnStatic + "nation.png"} alt={"Nation"}/>
                            </a>
                    </div>
                    <div className="col-md-6 col-lg-2 count-wrap">
                             <a href="https://theinformer.co.ke/34684/uae-based-firm-partners-with-africa-subsidiary-investera-plus-africa/">
                                 <img className="card-img-logo" src={config.dataCdnStatic + "the_informer.png"} alt={"The Informer"}/>
                              </a>
                    </div>
                  
                </div>
             </div>
             <div className="container-fluid group-container">
                 <div className="container">
                    <div className="row">
                        {this.state.groups.map((item,i)=>(
                            <div className="col-md-6 col-sm-12 col-lg-3" key={i}>
                                <div className="card">
                                    <img className="card-img-top" src={config.dataCdnStatic + item.image} alt={item.title}/>
                                    <div className="card-body">
                                        <h2 className="card-title">{item.title}</h2>
                                        <p className="card-text">{item.description}</p>
                                        <a href={item.url} className="btn btn-theme-white">Find out more</a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    </div>
              </div>
              <div ref={this.about} id="aboutus">
                 <AboutUs from="home"/>
              </div>
              <Membership  innerRef={this.membership}/>
              <Faq from="home" innerRef={this.faq}/>
              <Contact from="home" innerRef={this.contact}/>
              <Footer/>
             </div>
         </React.Fragment>
        )
    }
}

const mapStateToProps = (state) =>({
    auth:state.auth,
    errors:state.errors
});

export default connect(mapStateToProps,{oauthLogin,fetchUserInfo})(withRouter(Home));
