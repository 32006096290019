import React from 'react';
import { Link } from 'react-router-dom';
import config from '../../shared/config';
import Helper from '../../shared/custom';

export const Report = ({ data }) => {
    data = {
            name: data.reportTitle, image: data.coverImage ?
            Helper.viewFile(data.coverImage.uniqueId) :
            config.cdn + images[0 % images.length],
            uri :  "report/view/" + data.reportUri,
            date : data.reportCreationDate,
            reportSummary : Helper.truncate(data.reportSummary.replace(/<[^>]*>?/gm, ''), 150)

    }

    // const addDefaultReportImage =(ev) =>{
    //     ev.target.src = Helper.dataPath("default/news-default-image.png");
    // }
    var images = ["theme/images/report1.jpg", "theme/images/report2.jpg", "theme/images/report3.jpg", "theme/images/report4.jpg"]

        return (
            <div className="col-md-12 p-0 m-t-15" key={data.uniqueId}>
                <div className="card text-center">
                    <div className="card-body  p-10 m-l-15" style={{padding : '10px !important'}}>
                        <div className="row">
                            <div className="col-md-4 p-0">
                                <div className="pro-img">
                                    <Link to={"/" + data.uri}>
                                        <img src={data.image}
                                            className="img-responsive report-img-border report-img-logo" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-8 text-left">
                                <div className="col-md-12 p-0" style={{marginTop  : '-15px' , marginBottom : '10px'}}>
                                    <Link to={"/" + data.uri}>
                                        <h6 className="text-blue">{data.name}</h6>
                                    </Link>
                                </div>
                                <div className="col-md-12 h-50 p-0" >
                                    <p className="font-12 m-b-0">{Helper.truncate(data.reportSummary.replace(/<[^>]*>?/gm, ''), 150)}</p>
                                </div>
                                <div className="d-flex flex-row justify-content-between align-items-end p-0 font-12">
                                    <span>
                                        <i className="mdi mdi-calendar font-16 vm m-r-5"></i>
                                        {data.date}
                                    </span>
                                    <Link   class="blue-bg report-action-btn  text-white btn btn-sm btn-info"
                                        to={"/" + data.uri}>
                                        View Report
                                 </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }

