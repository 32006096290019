import React, { Component } from "react";
import config from "../../shared/config";
import Helpers from "../../shared/custom";
import InfoText from "../ui/InfoText";
import Wall from "../ui/Wall";
import ReadMoreAndLess from "react-read-more-less";
import PostPopup from "../post/PostPopup";
import InfoSectorLinks from "../ui/InfoSectorLinks";
import InfoIndustryLinks from "../ui/InfoIndustryLinks";
import CareerHistory from "../people/CareerHistory";
import EducationHistory from "../people/EducationHistory";
import Axios from 'axios';

import AllCompanies from "../companiessummary/AllCompanies";
import AllOpportunities from "../Oppsummary/AllOpportunities";
import Allevents from "../eventSummary/Allevents";

import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Follow from "../uiActions/Follow";
import EntityExtraOptions from "../uiActions/EntityExtraOptions";
import store from '../../store';
import { SET_CURRENT_USER_PROFILE }  from '../../actions/types';


export default class UserProfileContentNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileDetails: {},
      userMainId:null,
      uri: "",
      showExtraOptions:true,
      open: false,
      mobileCode:"",
              mobile:"",
              email:"",
              gender:"",
              dob:"",
              regNo:"",
              regYear:"",
              category:"",
              noOfEmployees:"",
              natureOfBusiness:"",
              phoneCode:"",
              phone:"",
              companyEmail:"",
              kraPin:"",
              
              addressLine1:"",
              poBox:"",
              
              firstNameC1:"",
              lastNameC1:"",
              mobileCodeC1:"",
              mobileC1:"",
              emailC1:"",
              genderC1:"",
              dobC1:"",
              
              firstNameC2:"",
              lastNameC2:"",
              mobileCodeC2:"",
              mobileC2:"",
              emailC2:"",
              genderC2:"",
              dobC2:"",
    };
    this.openModal = this.openModal.bind(this);
    this.companies = React.createRef()
    this.opportunities = React.createRef()
    this.events = React.createRef()
  }

  openModal() {
    this.setState({ open: true }, function() {
      this.setState({ open: true });
    });
  }

  componentDidMount(){
    this.setState({ uri: this.props.uri });
    let userInfo = JSON.parse(localStorage.userInfo);
    this.setState({userMainId:userInfo.id})
    
  }

  componentWillReceiveProps(props) {
    this.setState({ uri: props.uri });
    let profileDetails = props.profileDetails;
   
    if (!Helpers.isEmpty(profileDetails)) {
      this.setState(
        {
          profileDetails: profileDetails
        },
        ()=> {
          this.fetchAddFields();
        }
      );
    }
  }

  scrollToDiv = ()=>{
    setTimeout(()=>{
      let scrollToRef  = Helpers.getQueryParameter("section");
      if(!Helpers.isEmpty(scrollToRef)){
        let constRef = document.getElementById(scrollToRef);
        if(!Helpers.isEmpty(constRef)){
          window.scrollTo(0, constRef.offsetTop) 
        }
      }
    },1000)
  }


  fetchAddFields = () =>{
    Axios.get(
      Helpers.apiURL({
          uri:"user/user-info?userId="+this.state.userMainId,
            })
        )
        .then( (response) => {           
         
            let data = response.data;
           // localStorage.setItem("profile",JSON.stringify(data));
          
          
            // store.dispatch({
            //     type:SET_CURRENT_USER_PROFILE,
            //     payload:data
            // });
            
            this.setState({
              mobileCode:data.mobileCode,
              mobile:data.mobile,
              email:data.email,
              gender: data.gender ? {
                    "value":data.gender,
                    "label":data.gender
                  }:"",
              dob:data.dob,
              regNo:data.regNo,
              regYear:data.regYear,
              category:data.category  ? {
                "value":data.category,
                "label":Helpers.removeHypen(data.category)
              }:"",
              noOfEmployees:data.noOfEmployees,
              natureOfBusiness:data.natureOfBusiness,
              phoneCode:data.phoneCode,
              phone:data.phone,
              companyEmail:data.companyEmail,
              kraPin:data.kraPin,
              addressLine1:data.address.addressLine1,
              poBox:data.address.poBox,
              
          })

             const contacts1 = data && data.contactPersons && data.contactPersons[0] ? data.contactPersons[0] : []

             if(contacts1){
              this.setState({
                firstNameC1:data.contactPersons[0].firstname,
                lastNameC1:data.contactPersons[0].lastname,
                mobileCodeC1:data.contactPersons[0].mobileCode,
                mobileC1:data.contactPersons[0].mobile,
                emailC1:data.contactPersons[0].email,
                genderC1:data.contactPersons[0].gender  ? {
                  "value":data.contactPersons[0].gender,
                  "label":data.contactPersons[0].gender
                }:"",
                dobC1:data.contactPersons[0].dob,
               })
             }

             const contacts2 = data && data.contactPersons && data.contactPersons[1] ? data.contactPersons[1] : []

             if(contacts2){
              this.setState({
                firstNameC2:data.contactPersons[1].firstname,
                lastNameC2:data.contactPersons[1].lastname,
                mobileCodeC2:data.contactPersons[1].mobileCode,
                mobileC2:data.contactPersons[1].mobile,
                emailC2:data.contactPersons[1].email,
                genderC2:data.contactPersons[1].gender  ? {
                  "value":data.contactPersons[1].gender,
                  "label":data.contactPersons[1].gender
                }:"",
                dobC2:data.contactPersons[1].dob,
               })
             }
            

          })
        .catch((err) => {
        });
 }



  shouldComponentUpdate() {
      this.scrollToDiv()
      return true;
  }
  //scrollToMyRef = (scrollTo) => window.scrollTo(0, scrollTo.offsetTop)  

  render() {
    let profileDetails = this.state.profileDetails;

    let user = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : null;

    let pageDetails = user && user.pageDetails ? user.pageDetails : null;

    console.log(user,"pageDetails")

    var regStatus = pageDetails && pageDetails.pageDto && pageDetails.pageDto.regStatus && pageDetails.pageDto.regStatus.value ? pageDetails.pageDto.regStatus.value  : null;

    // let profilePicUrl = !Helpers.isEmpty(this.state.profileDetails.profilePic)
    //   ? Helpers.dataPath(this.state.profileDetails.profilePic.publicUri)
    //   : Helpers.dataPath(config.defaultUserImage);

     let profilePicUrl = !Helpers.isEmpty(profileDetails.profilePic)
      ? Helpers.isEmpty(regStatus) ? Helpers.dataPath(profileDetails.profilePic.publicUri) 
      : !Helpers.isEmpty(regStatus) && regStatus == "PUBLISHED" ? Helpers.dataPath(profileDetails.profilePic.publicUri) : Helpers.dataPath(profileDetails.profilePic.uri)
      : Helpers.dataPath(config.defaultUserImage);
      let userUri = user && user.user && user.user.uri ? user.user.uri : "";


    let cityName = !Helpers.isEmpty(this.state.profileDetails.city)
      ? this.state.profileDetails.city.name
      : "";

    let countryName = !Helpers.isEmpty(this.state.profileDetails.country)
      ? this.state.profileDetails.country.name
      : "";
    let companyName = !Helpers.isEmpty(this.state.profileDetails.company)
      ? this.state.profileDetails.company.name
      : "";

    let companyUri = !Helpers.isEmpty(this.state.profileDetails.company)
    ? this.state.profileDetails.company.uri
    : ""; 
    let titleName = !Helpers.isEmpty(this.state.profileDetails.title)
      ? this.state.profileDetails.title.name
      : "";

    let profileName = profileDetails.displayName
      ? profileDetails.displayName
      : "";
    let sectors = Helpers.isEmpty(profileDetails.sectors)
      ? []
      : profileDetails.sectors;
    let industries = Helpers.isEmpty(profileDetails.industries)
      ? []
      : profileDetails.industries;

    let careerHistory = profileDetails.careerDtos;
    let educationHistory = profileDetails.educationDtos;

   
    let location = cityName; 
    location += countryName && cityName ? ", " : "";
    location += countryName;

    let packageName = this.props.packageName;

    
    
    //this.scrollToDiv()

   // let scroll 
    return (
      <div>
        <div className="row bold-headers text-left p-b-30">
          <div className="col-md-12 col-lg-12 col-xlg-12 p-0">
            {/* card */}

            <div className="card">
              <div className="card-body">
                <div className="float-right text-right">
                {
                  (this.state.showExtraOptions) && <EntityExtraOptions objectType="user" uri={this.props.uri}/>
                }
                </div>
                <div className="col-lg-12 p-0">
                  <div className="col-lg-6 p-0">
                    <div className="m-t-0">
                      <div>
                        <img
                          src={profilePicUrl}
                          className="img-circle float-left"
                          width="100"
                        />
                        <div className="card-body-right float-left m-l-20 text-left  half-width">
                          <h2 className="card-title m-b-0 m-t-5 "> {profileName} </h2>
                          <p className="m-0 font-16">{titleName} {companyName ? (<span> at </span>) : ("")}
                            {!Helpers.isEmpty(companyUri)?
                              <Link to={'/company/'+companyUri} className="m-l-5">{companyName}</Link>
                              :
                              <span>{companyName}</span>
                            }
                          </p>
                          <p className="m-0 font-14 text-grey">
                            {cityName}
                            {cityName && countryName ? ", " : ""}
                            {countryName}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clearfix" />
                {/* <div className="col-md-12 p-0">
                  <hr />
                  <div className="col-md-3 float-left">
                    <h3 className="font-16 l-h-0">Following</h3>
                    <div className="text-info font-bold">
                      {this.state.profileDetails.following}
                    </div>
                  </div>
                  <div className="col-md-3 float-left">
                    <h3 className="font-16 l-h-0">Followers</h3>
                    <div className="text-info font-bold">
                      {0}
                    </div>
                  </div>
                  <div className="col-md-3 float-left">
                    <h3 className="font-16 l-h-0">Profile Views</h3>
                    <div className="text-info font-bold">
                      {this.state.profileDetails.profileViewCount}
                    </div>
                  </div>
                </div> */}
                <div className="clearfix" />
                <div className="col-md-12 p-0">
                  {/* <hr /> */}
                  {/* <div className="col-md-2 float-left">
                    <Follow uri={this.state.uri} type={"PEOPLE"} showEdit={false} />
                  </div> */}
                </div>
                {/* <div className="clearfix"></div>
                        <div className="col-md-12 p-0">
                            <hr/>
                            <div className="col-md-3 float-left font-14 p-0">
                            <div className="" target="_blank"  onClick={this.openModal}><i className="fa fa-pencil-alt"></i>&nbsp;Create New Post</div>
                            </div>
                            <div className="col-md-3 float-left font-14 p-0">
                            <a className="" target="_blank" href="/create-opportunity/start"><i className="fa fa-pencil-alt"></i>&nbsp;Create New Opportunity</a>
                            </div>
                            <div className="col-md-3 float-left font-14">
                            <a className="" target="_blank" href="/create-event/start"><i className="fa fa-calendar-check"></i>&nbsp;Create New Event</a>
                            </div>
                            <div className="col-md-3 float-left font-14 p-0">
                                <a className="" target="_blank" href="/create-company/start"><i className="fa fa-pencil-alt"></i>&nbsp;Create New Company</a>
                            </div>
                        </div> */}
              </div>
            </div>

            {/* card */}

            <div className="card" id="section2">
              <div className="card-body">
                <div>
                  <h6>Summary</h6>
                  <ReadMoreAndLess
                    ref={this.readMore}
                    className="read-more-content"
                    charLimit={config.summaryLimit}
                    readMoreText="Read more"
                    readLessText="Read less"
                  >
                    {this.state.profileDetails.summary
                      ? this.state.profileDetails.summary
                      : ""}
                  </ReadMoreAndLess>
                  <InfoText title="Title" text={titleName} />
                  <InfoText title="Company" text={companyName} />
                  {cityName  || countryName ? 
                    <InfoText
                      title="Location"
                      text={location}
                    />
                  :""

                  }
                  <InfoSectorLinks items={sectors} title="Sectors" />
                  <InfoIndustryLinks items={industries} title="Industries" />
                </div>
              </div>
            </div>

            {/* { !Helpers.isEmpty(this.state.mobile) &&   */}
              <div className="card">
                <div className="card-header text-left light-blue-header"><h5 className="m-b-0 m-t-0 text-white">Additional Info</h5></div>
                 <div className="card-body">
                      
                       
                        {this.state.mobile && <InfoText title="Mobile No" text={this.state.mobileCode+'-'+this.state.mobile} />}
                        {this.state.email && <InfoText title="Email" text={this.state.email} />} 
                        {this.state.gender && <InfoText title="Gender" text={this.state.gender.label} />} 
                        {this.state.dob && <InfoText title="Date Of Birth" text={this.state.dob} />} 
                      
              
                      {(packageName == "company"  || packageName == "startup")  && 
                        <React.Fragment>
                          {this.state.regNo && <InfoText title="Registration Number" text={this.state.regNo} />} 
                          {this.state.regYear && <InfoText title="Year of Registration / Incorporation" text={this.state.regYear} />} 
                          {this.state.category && <InfoText title="Category" text={this.state.category.label} />} 
                          {this.state.noOfEmployees && <InfoText title="Number of Employees" text={this.state.noOfEmployees} />} 
                          {this.state.natureOfBusiness && <InfoText title="Nature of Business" text={this.state.natureOfBusiness} />}    
                          {this.state.phone && <InfoText title="Telephone No" text={this.state.phoneCode+'-'+this.state.phone} />} 
                          {this.state.companyEmail && <InfoText title="Company email" text={this.state.companyEmail} />} 
                          {this.state.kraPin && <InfoText title="KRA PIN" text={this.state.kraPin} />} 
                          
                          {this.state.addressLine1 &&  <h5>Address</h5>}
                          {this.state.addressLine1 && <InfoText title="Physical Address" text={this.state.addressLine1} />} 
                          {this.state.poBox && <InfoText title="Postal Address" text={this.state.poBox} />}    

                          {this.state.firstNameC1 && <h5>Contact person 1</h5> }

                          {this.state.firstNameC1 && <InfoText title="First Name" text={this.state.firstNameC1} />} 
                          {this.state.lastNameC1 && <InfoText title="Last Name" text={this.state.lastNameC1} />} 
                          {this.state.mobileC1 && <InfoText title="Mobile No" text={this.state.mobileCodeC1+'-'+this.state.mobileC1} />} 
                          {this.state.emailC1 && <InfoText title="Email" text={this.state.emailC1} />} 
                          {this.state.genderC1 && <InfoText title="Gender" text={this.state.genderC1.label} />}    
                          {this.state.dobC1 && <InfoText title="Date Of Birth" text={this.state.dobC1} />} 

                          {this.state.firstNameC2 && <h5>Contact person 2</h5>}

                          {this.state.firstNameC2 && <InfoText title="First Name" text={this.state.firstNameC2} />} 
                          {this.state.lastNameC2 && <InfoText title="Last Name" text={this.state.lastNameC2} />} 
                          {this.state.mobileC2 && <InfoText title="Mobile No" text={this.state.mobileCodeC2+'-'+this.state.mobileC2} />} 
                          {this.state.emailC2 && <InfoText title="Email" text={this.state.emailC2} />} 
                          {this.state.genderC2 && <InfoText title="Gender" text={this.state.genderC2.label} />}    
                          {this.state.dobC2 && <InfoText title="Date Of Birth" text={this.state.dobC2} />} 
                        
                        </React.Fragment>
                      }
                     


                  </div>
            </div>
            {/* } */}

            <CareerHistory careerHistory={careerHistory} />
            <EducationHistory educationHistory={educationHistory} />
            <div ref={this.companies} id="companies">
              <AllCompanies onlyFollow={true}/>
            </div>
            <div ref={this.opportunities} id="opportunities">
              <AllOpportunities onlyInterested={true} items="2"/>
            </div>
            <div ref={this.events} id="events">
               <Allevents onlyInterested={true} items="2"/>
            </div>
            <Wall uri={this.state.uri} />
          </div>
          <PostPopup open={this.state.open} />
        </div>
      </div>
    );
  }
}
