import Axios from 'axios';
import React, { Component } from 'react';
import Select, { components } from 'react-select';
import Helpers from "../../shared/custom";
import CompanyPs from "../wigets/CompanyPs";


export default class CompanyForm extends Component {
    //Since the Select Component uses strict equality operator for comparion of options and value to correctly scroll to the select value,
    // the selected option that mataches the given array is being returned
   

    render() {
        let values = this.props.values;
        const Option = props => {
            return (
                <div style={{ color: props.data.color }}>
                    <components.Option {...props} />
                </div>
            );
        };
        let {sectors,industryGroups,subIndustries,industries,industry} = this.props.values;
        const customStyles = {
            singleValue: (provided, state) => {
                return { ...provided, color: state.data.color };
            }
        }

        return (
            <div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group has-search">
                            <label className="control-label font-bold font-18">Year Founded</label>
                            <Select
                                className="font-14 col-md-12 p-0 m-0"
                                name="foundedYear"
                                options={values.yearOptions}
                                placeholder="Select founded year"
                                value={values.foundedYear}
                                onChange={this.props.onSelectChange}
                                isClearable={true}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label font-bold font-18">Sector</label>
                            <Select
                                className="font-14 col-md-12 p-0 m-0"
                                name="sector"
                                options={sectors}
                                placeholder="Select sector"
                                value={values.sector}
                                onChange={this.props.onSelectChange}
                                isClearable={true}
                                isMulti
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group has-search">
                            <label className="control-label font-bold font-18">Industry Groups</label>
                            <Select
                                className="font-14 col-md-12 p-0 m-0"
                                name="industryGroup"
                                options={industryGroups}
                                placeholder="Select industry group"
                                value={values.industryGroup}
                                onChange={this.props.onSelectChange}
                                isClearable={true}
                                isMulti
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label font-bold font-18">Industry</label>
                            <Select
                                className="font-14 col-md-12 p-0 m-0"
                                name="industry"
                                options={industries}
                                placeholder="Select industry"
                                value={industry}
                                onChange={this.props.onSelectChange}
                                isClearable={true}
                                isMulti
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group has-search">
                            <label className="control-label font-bold font-18">Sub Industry</label>
                            <Select
                                className="font-14 col-md-12 p-0 m-0"
                                name="subIndustry"
                                options={subIndustries}
                                placeholder="Select sub industry"
                                value={values.subIndustry}
                                onChange={this.props.onSelectChange}
                                isClearable={true}
                                isMulti
                            />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label font-bold font-18">Products/Services</label>
                            <CompanyPs
                                allowNew={'no'}
                                onChange={event => this.props.companyPslChange(event)}
                                defaultValue={values.companyPsl ? values.companyPsl.name : null}
                                defaultSelected={!Helpers.isEmpty(values.companyPsl) ? values.companyPsl : []}
                            />
                        </div>
                    </div>
                </div>

                {/* <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label font-bold font-18">Company Size</label>
                            <Select
                                className="font-14 col-md-12 p-0 m-0"
                                name="companySize"
                                options={values.companySizeOptions}
                                placeholder="Select company size"
                                value={values.companySize}
                                onChange={this.props.onSelectChange}
                                isClearable={true}
                            />
                        </div>
                    </div> */}

                {/* <div className="col-md-6">
                        <div className="form-group  has-search">
                            <label className="control-label font-bold font-18">Rating</label>
                            <Select
                                className="bold-options font-14 col-md-12 p-0 m-0"
                                name="rating"
                                options={config.ratings}
                                placeholder="Select ratings"
                                value={values.rating}
                                onChange={this.props.onSelectChange}
                                isClearable={true}
                                components={{ Option }}
                                styles={customStyles}
                            />
                        </div>
                    </div> */}

                {/* <div className="col-md-6">
                    <div className="form-group  has-search">
                        <label className="control-label font-bold font-18">Product Service</label>
                        <Select                            
                            className="bold-options font-14 col-md-12 p-0 m-0"
                            name="psl"
                            options={values.productServices}
                            placeholder="Select product Service"
                            value={values.psl}
                            onChange={this.props.onSelectChange}
                            isClearable={true}
                            components={{Option}}
                            styles={customStyles}
                            isMulti={true}
                        />
                    </div>
                </div>  */}
            </div>
        )
    }
}
