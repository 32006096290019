import React, { Component } from 'react'
import Axios from 'axios';
import Helpers from '../../shared/custom';
import queryString from 'query-string';
import { withRouter, Redirect } from 'react-router-dom';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import store from '../../store';
import { login } from '../../actions/authAction';
import { connect } from 'react-redux';
import Mobile from "../ui/Mobile";



class Forgotpw extends Component {
    constructor(props,context){
        super(props);
        this.state = {
            email : "",
            loading:false,
            toggleForm:true,
            mobile:"",
            phoneCode:"",
            phone:"",
            errors:{}
        }
       
       // this.validateForm    = this.validateForm.bind(this);       
       // this.resetPassword    = this.resetPassword.bind(this);       
      //  this.handleInputChange = this.handleInputChange.bind(this);
      //  this.validateUser = this.validateUser.bind(this);
        
    }

    handleInputChange = (e)=>{
        this.setState({[e.target.name]:e.target.value});
    }

    toggleForm =()=>{
        this.setState(prevState=>{
            prevState.toggleForm = !prevState.toggleForm;
            prevState.mobile = "";
            prevState.email = "";
            prevState.errors = {}
            return prevState;
        })
    }
    
    componentDidMount() {
       
        this.validateUser();
        const values = queryString.parse(this.props.location.search)
        this.setState({'email':values.email});
    }

    validateUser=()=>{
        var storeState = store.getState();  
        let loggedIn = storeState.auth.isAuthenticated;

        if(loggedIn){
            this.props.history.push("/");
            return;
        }
    }
    
    componentWillReceiveProps(nextProps){
        if (nextProps.auth !== this.props.auth) {
            let loggedIn = nextProps.auth.isAuthenticated;
            if(loggedIn){
                this.props.history.push("/");
                return;
            }
        
        }
    }

    mobileValues = (data)=>{
        this.setState({
            phoneCode:data.ext,
            phone:data.mobile,
        })
    }

    validateForm = ()=>{
        let email = this.state.email;    
        let toggleForm = this.state.toggleForm;

        let valid = true;
        let error = {};
        
        
        if(toggleForm){
            if(Helpers.isEmpty(email)){
                Helpers.pushTError("INVALID_EMAIL");
                error["email"] = "This is a mandatory field";
                valid = false;
            }
        }else{
            if(Helpers.isEmpty(this.state.phoneCode)){
               // Helpers.pushTError("INVALID_MOBILE");
                error["phoneCode"] = "This is a mandatory field";
                valid =  false;
            }
            if(Helpers.isEmpty(this.state.phone)){
                Helpers.pushTError("INVALID_MOBILE");
                error["phone"] = "This is a mandatory field";
                valid =  false;
            }
        }

        this.setState({errors:error});
        return valid;
    }

    resetPassword = (e) => {
        e.preventDefault();   
        let { toggleForm } = this.state;
        let isValid = this.validateForm();
        if(!isValid){
            return false;
        }


        let mobile = this.state.phoneCode+this.state.phone


        this.setState({
            loading:true,
        })
        let userData = {}
        let uri = null;
        if(toggleForm){
            userData.email = this.state.email;
            uri = "user/forgot-password"
        }else{
            userData.mobile = mobile;
            uri = "user/forgot-password/mobile"
        }
      
        Axios.post(
            Helpers.apiURL({
                uri:uri
            })        
            , userData
        )
        .then( (response) => {         
            this.setState({
                loading:false,
            })
            if(toggleForm){
                Helpers.pushTSuccess("RESET_EMAIL_SENT");
                this.props.history.push({pathname:"/auth", activeTab:"login"});
            }else{
                Helpers.pushTSuccess("OTP_SENT");
                this.props.history.push({pathname:"/reset-password", mobile:mobile});
            }
           
        })
        .catch((err) => {
            this.setState({
                loading:false,
            })

            Helpers.pushError(err.response.data.message);
            //this.props.history.push({pathname:"/reset-password", mobile:this.state.mobile});
            // if(toggleForm){
            //     Helpers.pushTError("EMAIL_NOT_REGISTERED");
            // }else{
            //     Helpers.pushTError("MOBILE_NOT_REGISTERED");
            // }
        });
       
    }

      
    


  render() {
    const { loading , toggleForm , errors} = this.state;
    return (
      <div>
          
          <div className="login-register customCard">
            <div className="col-lg-8 m-a auth-card">
            <LoadingOverlay>
              <Loader loading={loading}/>
                <div className="card-body m-t-40">
                    <h1 className="m-t-40 font-normal">Forgot your password?</h1>
                    <p className="font-medium m-t-40">How would you like to reset your password</p>

                   
                    <div className="row">
                        <div className="col-md-6">
                             <div class="form-check">
                               <input type="radio" class="" name="mode" id="byEmail" defaultChecked={true} onClick={this.toggleForm}/>
                               <label class="form-check-label" for="byEmail">
                                 By Email
                               </label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div class="form-check">
                                 <input type="radio" class="" name="mode" id="byMobile" onClick={this.toggleForm}/>
                                <label class="form-check-label" for="byMobile">
                                    By Mobile
                                </label>
                            </div>
                        </div>
                    </div>

                    <span id="error-message" className="error-message">{this.state.validationMsg}</span>
                    <span id="success-message" className="success-message">{this.state.successMsg}</span>
                    {toggleForm ? 
                        <form className="form-horizontal form-material m-t-20">
                            <p className="font-medium m-t-40 text-center">
                                Enter the email address for your account 
                                and we’ll send you a link to reset your password..
                            </p>
                            <div className="form-group">
                                <div className="col-xs-12 col-md-8 col-lg-8 m-a">
                                    <input className="form-control" type="text" required="" id="email" name="email" placeholder="Email"  onChange={this.handleInputChange}  /> 
                                    <small className="text-danger">{errors["email"]}</small>
                                    
                                </div>
                            </div>
                        
                            <div className="form-group text-center m-t-20 clearfix">
                                <div className="col-xs-12 col-lg-8 m-a">
                                    <button className="btn btn-info btn-md-h btn-block text-uppercase waves-effect waves-light" type="button" onClick={this.resetPassword}>Reset Password</button>
                                </div>
                            </div>
                        </form>
                        :
                        <form className="form-horizontal m-t-20">
                            <p className="font-medium m-t-40 text-center">
                                 Enter the registered mobile no to send the OTP..
                            </p>
                            <div className="form-group">
                                <div className="col-xs-12 col-md-8 col-lg-8 m-a">
                                 <Mobile 
                                            sendValue={this.mobileValues}
                                            extVal={this.state.phoneCode}
                                            mobileVal={this.state.phone}
                                            mobileName={"phone"}
                                            extName={"phoneCode"}
                                            error={errors}
                                            full={true}
                                            /> 
                                </div>
                            </div>
                        
                            <div className="form-group text-center m-t-20 clearfix">
                                <div className="col-xs-12 col-lg-8 m-a">
                                     <button className="btn btn-info btn-md-h btn-block text-uppercase waves-effect waves-light" type="button" onClick={this.resetPassword}>Reset Password</button>
                                </div>
                            </div>
                        </form>

                    }
                    
                </div>
                </LoadingOverlay>
            </div>
        </div>
     
      </div>
    )
  }
}

const mapStateToProps = (state) =>({
    auth:state.auth,
});

export default connect(mapStateToProps,{login})(withRouter(Forgotpw))