import React, { Component } from 'react'

export default class SubscriptionNew extends Component {
    render() {
        return (
            <div>
          <div>
            <div>
              <div className="row content-in m-t-80 p-t-10">
                <div className="col-lg-12 col-xlg-12 col-md-12">
                    <div className="row">
                     <div className="col-12 subscribe new-panel">
                        <div className="card mainCard">
                            <div className="card-body">
                                    <div className="header">
                                        <h2 className="subHeader">Investera Plus Africa Subscription Plan</h2>
                                        {/* <h2 className="text-normal subHeaderSmall">Choose the plan that best suits your needs</h2> */}
                                    </div>
                                
                                <div className="col-md-12 p-0 pricing-plan">
                                    <div className="col-md-4 col-xs-12 col-sm-6  float-left freeCont planCont" onClick={this.onClickPlan}>
                                        <div className="pricing-box">
                                            <div className="pricing-body">
                                                <div className="pricing-header">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="m-b-0 font-22 text-black">Researcher</h4>
                                                        </div>
                                                        <div className="card-body header-body">
                                                            <p className="card-text text-black">$2,000/year</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="price-table-content freePlan isPlanned">
                                                    <div className="price-row even">Market insights</div>
                                                    <div className="price-row odd">Founders</div>
                                                    <div className="price-row even">Board members</div>
                                                    <div className="price-row odd">Team</div>
                                                    <div className="price-row even">Contact details</div>
                                                    <div className="price-row odd">Comment</div>
                                                    <div className="price-row even">Financial details</div>
                                                    <div className="price-row odd">Funding</div>
                                                    <div className="price-row even">Investments</div>
                                                    <div className="price-row odd">Funding rounds</div>
                                                    <div className="price-row even">Investors</div>
                                                    <div className="price-row bottom-btn">
                                                       <a href="javascript:void(0)" className="text-white m-t-5 btn btn-info subscribeBtn">Subscribe now</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-xs-12 col-sm-6 p-0 freeCont  float-left activeCont planCont" onClick={this.onClickPlan}>
                                        <div className="pricing-box">
                                            <div className="pricing-body">
                                                <div className="pricing-header">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="m-b-0  font-22 text-black">Researcher</h4>
                                                        </div>
                                                        <div className="card-body header-body">
                                                            <p className="card-text text-black">$2,000/year</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="price-table-content freePlan isPlanned">
                                                   <div className="price-row even">Market insights</div>
                                                    <div className="price-row odd">Founders</div>
                                                    <div className="price-row even">Board members</div>
                                                    <div className="price-row odd">Team</div>
                                                    <div className="price-row even">Contact details</div>
                                                    <div className="price-row odd">Comment</div>
                                                    <div className="price-row even">Financial details</div>
                                                    <div className="price-row odd">Funding</div>
                                                    <div className="price-row even">Investments</div>
                                                    <div className="price-row odd">Funding rounds</div>
                                                    <div className="price-row even">Investors</div>
                                                    <div className="price-row bottom-btn">
                                                       <a href="javascript:void(0)" className="text-white m-t-5 btn btn-info subscribeBtn">Subscribe now</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-xs-12 col-sm-6  freeCont  float-left planCont" onClick={this.onClickPlan}>
                                        <div className="pricing-box">
                                            <div className="pricing-body">
                                                <div className="pricing-header">
                                                    <div className="card">
                                                        <div className="card-header">
                                                            <h4 className="m-b-0  font-22 text-black">Researcher</h4>
                                                        </div>
                                                        <div className="card-body header-body">
                                                            <p className="card-text text-black">$2,000/year</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="price-table-content freePlan isPlanned">
                                                    <div className="price-row even">Market insights</div>
                                                    <div className="price-row odd">Founders</div>
                                                    <div className="price-row even">Board members</div>
                                                    <div className="price-row odd">Team</div>
                                                    <div className="price-row even">Contact details</div>
                                                    <div className="price-row odd">Comment</div>
                                                    <div className="price-row even">Financial details</div>
                                                    <div className="price-row odd">Funding</div>
                                                    <div className="price-row even">Investments</div>
                                                    <div className="price-row odd">Funding rounds</div>
                                                    <div className="price-row even">Investors</div>
                                                    <div className="price-row bottom-btn">
                                                       <a href="javascript:void(0)" className="text-white m-t-5 btn subscribeBtn gold-bg">Subscribe now</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
          </div>
        </div>
        )
    }
}
