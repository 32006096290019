import React, { Component } from 'react';
import config from '../../shared/config';
import Helper from '../../shared/custom';
import { GET_ERRORS , SET_USER }  from '../../actions/types';
import Axios from 'axios';
import store from '../../store';
import storeAuth from '../../utils/storeAuth';

export default class Loading extends Component {
   constructor(props){
       super(props)
       this.removeError = this.removeError.bind(this);
       this.setUser = this.setUser.bind(this);
       this.fetchUserInfo = this.fetchUserInfo.bind(this);
   } 

   removeError ( dispatch) {
    dispatch({
        type: GET_ERRORS,
        payload: null
    })
 }
  setUser(userInfo) {
    return {
        type : SET_USER,
        payload : userInfo
    }
  }
  fetchUserInfo() {
    
  }
   componentDidMount(){
      let referal = localStorage.getItem("referal");
      let redirectTo = Helper.isEmpty(referal) || referal == undefined ? "/" : referal;
      
      // if(redirectTo == "undefined"){
      //   redirectTo = "/";
      // }
      
      var context = this;

     // debugger;
     
      setTimeout(()=>{
            localStorage.removeItem("referal");
            Axios.get(config.api("user-details"))
            .then( (response) => {
              
                const userData  = response.data.result; 
                localStorage.setItem("userInfo",JSON.stringify(userData));
                userData.image = userData.image || config.defaultUserImage;
                store.dispatch(context.setUser(userData));
                storeAuth()

               
               // this.props.history.push(redirectTo);
               let packageType = userData.promptSubUpgradeTo ? userData.promptSubUpgradeTo.value.toLowerCase() : null;
           
               if(userData.emailConfirmed){
            
                   if(!userData.registrationCompleted && window.location.pathname !=  "/welcome"){
                    this.props.history.push("/register/sectors")
                   }else if(userData.registrationCompleted && userData.resetPassword && window.location.pathname !=  "/change-password"){
                        this.props.history.push("/change-password")
                   }else{
                       if(userData.promptUpgrade && packageType && window.location.pathname !=  "/proceed"){
                        this.props.history.push("/proceed?type="+packageType)
                       }else{
                          this.props.history.push(redirectTo);
                       }
                   }
               }else{
                this.props.history.push("/verify-email")
              }

            })
      },3000)
   }
  render() {
    return (
      <div>
         <div className="row">
            <div className="col-12 verticle-cente-m">
                <div className="text-center">
                    <img src={config.cdn +'theme/images/loader.gif'} /> 
                    <h1>We’re creating your personalized homepage</h1>
                    <h2>Please wait a few moments while we 
                        get things ready for you</h2>
                </div>     
            </div>
        </div>
      </div>
    )
  }
}
