import React, { Component } from 'react';import { withRouter, Link } from 'react-router-dom';
import Helpers from '../../shared/custom';
import Loader from '../ui/Loader';
import Axios from 'axios';
import TwitterFeed from '../wigets/TwitterFeed';
import FacebookFeed from '../wigets/FacebookFeed';
import Relatednews from '../profile/Relatednews';

import MiniGraph from "../profile/MiniGraph";


import store from '../../store';

import { connect } from 'react-redux';
import { getPackageDetails } from '../../actions/packageAction';


class Rightpanel extends Component {
    constructor(props){
        super(props);
        this.state ={
            loading:true,
            entity:null,
            twitter:"",
            facebook:"",
            uri:undefined,
            entityUri:"",
            privileges:{},
            localProps:{}
        }
        this.updateWidgets = this.updateWidgets.bind(this);
    }

    componentDidMount(){        
        var location = window.location.pathname;
        let entityUri = Helpers.lastSegment(location)
        this.updateWidgets(entityUri);
        this.setState({
            entityUri:entityUri
        })
    }
  
    componentWillReceiveProps(nextProps){    
        
        if(JSON.stringify(nextProps) != JSON.stringify(this.state.localProps)){            
            this.setState({
                localProps:nextProps
            });

            var storeState = store.getState(); 
            let privileges = storeState.packageDetails.privileges;
            this.setState({
                privileges:privileges
            });           
        }  

        var location = window.location.pathname;        
        let entityUri = Helpers.lastSegment(location);       
        this.updateWidgets(entityUri);
        this.setState({
            entityUri:entityUri
        });
        
    }

    updateWidgets(entityUri){

        let currentUri = this.state.uri;
        if(currentUri && currentUri == entityUri){
            return false;
        }

        Axios.get(
            Helpers.apiURL({
                uri:"COMPANY/:entityUri",
                pathVar:{entityUri:entityUri},
                query:{facets:"company"}
            })
        )
        .then( (response) => {
            let companyDetails  = response.data.result || {};           
            if(companyDetails.page){
                this.setState({
                    uri:entityUri,
                    entity:companyDetails.page,
                    twitter:companyDetails.page.twitter ? companyDetails.page.twitter : undefined,
                    facebook:companyDetails.page.facebook ? companyDetails.page.facebook : undefined,
                    loading:false
                }, function(){
                })
            }
        })
        .catch((err)=>{
            this.setState({
                loading:false
            })
        })
        
       
    }


    render() {
        let loading = this.state.loading;
        let twitter = this.state.twitter ? this.state.twitter : undefined; 
        let facebook = this.state.facebook ?  this.state.facebook : undefined;
        let entity = this.state.entity ?  this.state.entity : null;
        let privileges = this.state.privileges;
        
        if(loading){
            return (<div><Loader/></div>);
        }
        return (
        <React.Fragment>
                            
               
                <div>
                    {twitter?
                        <TwitterFeed url={twitter} />
                    :
                    ""
                    }
                 <div className="sticky rightItems">      
                        {facebook?        
                                
                                <FacebookFeed url={facebook} />
                        
                            :
                            ""
                        }
                       </div>
                </div>

                {privileges && privileges["KG"] ? <MiniGraph uri={this.state.entityUri} name={entity?entity.name:""} image={entity?entity.image:""}   /> : ""}  
                {/* { privileges && privileges["NWS"] ? 
                <Relatednews entityUri={this.state.entityUri} type="COMPANY" entity={entity} />
                :
                ""
                } */}
               
        </React.Fragment>
        )
    }
}



// export default withRouter(Rightpanel);
const mapStateToProps = (state) =>({
    packageDetails:state.packageDetails,
    errors:state.errors
});
  
  export default connect(mapStateToProps,{getPackageDetails})(withRouter(Rightpanel));
  