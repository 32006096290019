import React, { Component } from 'react'
import classNames from 'classnames/bind';
import Helper from "../../shared/custom";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

export default class InfoText extends Component {
  render() {
    let text = this.props.text;
    let title = this.props.title;
    let uri = this.props.uri;
    let type = this.props.type;
    let childClass=this.props.childClass;
    let fieldType = this.props.fieldType;
    let noborder= this.props.noborder;
    return (
      <div  className={classNames(!Helper.isEmpty(this.props.additionalClassName)?this.props.additionalClassName:'')}>
        {text?
            <div className={childClass}>
            {title?<h6 className={noborder?"m-t-0":""}>{title}</h6>:""}
            {fieldType == "phone" ? 
                <div><a href={"tel:"+text} className="m-l-5" target="_blank">{text}</a></div>
                :
                <div>{!Helper.isEmpty(uri) ?  <a href={'/'+type+'/'+uri} className="m-l-5" target="_blank">{text}</a> : text}</div>
            }
            </div>
            :
            ""
        }
        </div> 
    )
  }
}
