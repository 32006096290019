import React, { Component } from 'react';
import Helper from '../../shared/custom';
import config from '../../shared/config';
import MetaTags from 'react-meta-tags';

export default class IndustryBreakdown extends Component {
    constructor(props){
        super(props);
        this.onClickLogin =  this.onClickLogin.bind(this);

    }

    onClickLogin = () =>{
        document.getElementById("loginDropdown").click()
    }

    render() {
        return (
            <div className="col-md-12 col-lg-12 col-xlg-12 p-0">
              <div className="card additionalClass">
                 <div className="card-body">
                            <h3 class="card-title text-blue text-left report-title">Industry Breakdown</h3>
                            <div className="page">
                                <img src={config.reportFolder+'industry-breakdown-1.png'} className="img-fluid"/>
                                <img src={config.reportFolder+'industry-breakdown-2.png'} className="img-fluid"/>
                            </div>
                            <a href="javascript:void(0)" className="btn btn-action btn-report" onClick={this.onClickLogin}>Login to read more...</a>
                        </div>
                    </div>
            </div>
        )
    }
}
