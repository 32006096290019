import React from 'react';
import Form from './Form';

function CreateOpportunities(props) {
    
  const naviagation = (url) => {
       props.history.push(url);
  }

    return (
        <div className="container-fluid"> 
            <div className="row content-in m-t-80 p-t-10">
                 <Form naviagation={naviagation} />
            </div>
        </div>
       
    )
}

export default CreateOpportunities
