import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Sidemenu from '../sidemenu/Sidemenu';
import AdvSearchContent from './AdvSearchContent';
import EventsAdvSearch from './EventsAdvSearch';
import queryString from 'query-string';
import UserFeedRightPanel from '../homepage/UserFeedRightPanel';
import Helpers from '../../shared/custom';
import {DefaultMeta} from '../../shared/MetaHelper';

class AdvanceSearch extends Component {
  
  constructor(props){
    super(props);
    this.state={
      type:undefined,
      option:{}
    }
  }  

  componentDidMount(){
    
  }

  // getTypeFromChild =(data)=>{
  //     this.setState({
  //       type:data
  //     })
  // }

  componentWillReceiveProps(newProps){

    let query = newProps.location.search;
    let querySearch = queryString.parse(query);
    let id = Helpers.getQueryParameter("id");
    let l = Helpers.getQueryParameter("l");



    if(querySearch && querySearch.t){
    
      let type = this.state.type;
      if(type != querySearch.t){
        this.setState({
          type:querySearch.t,
        })

      
      }
      let option = {}
      if(!Helpers.isEmpty(id)){
        option.label = l;
        option.value = id;
        this.setState({
          option:option
        })
      }else{
        this.setState({
          option:option
        })
      }
    }

  }
  render() {
   let option = this.state.option;

 
   return (
      <div>
        <DefaultMeta/>
        <div className="container-fluid">
                <div className="row content-in m-t-80 p-t-10">
                    <div className="col-lg-3 col-xlg-3 col-md-3 searchMenu sideMenuCont">
                       <Sidemenu />
                    </div>
                    <div className="col-lg-6 col-xlg-6 col-md-6 col-sm-12 p-0">
                       { this.state.type=="event"? <EventsAdvSearch type={this.state.type}/> :<AdvSearchContent type={this.state.type} option={option} />}
                    </div>
                    <div className="col-lg-3 col-xlg-3 col-md-3 m-t-2 col-sm-12">
                        <UserFeedRightPanel />
                    </div>
                </div>
         </div>
      </div>
    )
  }
}

export default withRouter(AdvanceSearch);