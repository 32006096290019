import React, { Component } from 'react';
import Sidemenu from '../sidemenu/Sidemenu';
import SidemenuCompanies from '../sidemenu/SidemenuCompanies';
import store from '../../store';

import OwlCarousel, { Options } from 'react-owl-carousel';
import config from '../../shared/config';
import classNames from 'classnames/bind';
import Axios from 'axios';
import Helper from "../../shared/custom";

import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { getMetaData } from '../../actions/metaAction';
import { getSectorData } from '../../actions/businessAction';

//import AllCompanies from '../companiessummary/AllCompanies';
import CompanyRightpanel from '../companiessummary/CompanyRightpanel';
import CompanyRouter from './CompanyRouter';
import Helpers from '../../shared/custom';

let locationIdsRevd;

class CompanySummary extends Component {

    constructor(props){
        super(props)
        this.state ={
          isLoggedIn :false,
          searchCriteria:{},
          context:undefined,
          privileges:{},
          metaList:undefined,
          business:undefined,
          locationIds:[]
        }
        
        store.subscribe(() => {
          var storeState = store.getState();
          var auth = storeState.auth;
          var localAuth = this.state.auth;
    
          var localMetaList = this.state.metaList;
          var storeMetaList = storeState.meta.metaList; 
          
          if(!localMetaList || JSON.stringify(localMetaList) != JSON.stringify(storeMetaList)){
              this.setState({metaList:storeMetaList},function(){
          });
          
          // let packageDetails =  storeState.packageDetails;
          // let privileges = packageDetails.privileges;
         
          // if(JSON.stringify(privileges) != JSON.stringify(this.state.privileges)){
          //     this.setState({
          //         privileges:privileges
          //     },()=>{
          //       if(!Helper.isEmpty(privileges) && !privileges["AED_CI"]){
          //         this.props.history.push(Helpers.upgradeUrl());
          //         return false;
          //       }
          //     })
          //  }

          }
    
          var localBusiness = this.state.business;
          var storeBusiness = storeState.business.data;
          if(!localBusiness || JSON.stringify(localBusiness) != JSON.stringify(storeBusiness)){
              this.setState({business:storeBusiness});
          }
    
          this.setState({
            isLoggedIn:auth.isAuthenticated
          });
          
        }); 

        this.criteriaUpdate = this.criteriaUpdate.bind(this);
        this.setPageContext = this.setPageContext.bind(this);

      }

      getSelectedLocation(locationIds){
          locationIdsRevd = locationIds;
      }
    
      criteriaUpdate(name, value){
    
        let sc = this.state.searchCriteria
        sc[[name]] = value;
        this.setState({searchCriteria:sc},
          function(){
       
          });
      }
    
      setPageContext(context){
        if (this.state.context != context){
          this.setState({context:context});
        }          
      }
    
     
      componentDidMount(){
        var storeState = store.getState();
    
        let meta = storeState.meta;    
        if(Helper.isEmpty(meta.metaList) == true){
          this.props.getMetaData();
        }else{
          this.setState({metaList:meta.metaList});
        }
    
        let business = storeState.business;        
        if(Helper.isEmpty(business.data) == true){            
            this.props.getSectorData();
        }else{
            this.setState({business:business.data});
        }
    
        var auth = storeState.auth;
        // dont remove
        // var localAuth = this.state.auth;
        // if(auth.isAuthenticated){
        //   if(!Helpers.permitted("AED_CI")){
        //     this.props.history.push(Helpers.upgradeUrl());
        //     return false;
        //   }
        // }

        this.setState({
          isLoggedIn:auth.isAuthenticated
        },()=>{

        })
      }


  render() {
    let isLoggedIn = this.state.isLoggedIn;
    return (
       <div>
            <div>
                <div >
                  <div className="row content-in m-t-80 p-t-10">
                    <div className="col-lg-3 col-xlg-3 col-md-3 sideMenuCont">
                       <SidemenuCompanies mode="companySummary" isLoggedIn={this.state.isLoggedIn}  criteriaUpdate={this.criteriaUpdate}  searchCriteria={this.state.searchCriteria} setPageContext={this.setPageContext}  context={this.state.context} sendLocationToParent={this.getSelectedLocation}/>
                    </div>
                    <div className="col-lg-6 col-xlg-6 col-md-6 centerDiv">
                       <CompanyRouter criteriaUpdate={this.criteriaUpdate} searchCriteria={this.state.searchCriteria} setPageContext={this.setPageContext} context={this.state.context}  metaList={this.state.metaList} business={this.state.business} locationIds={locationIdsRevd}/>
                    </div>
                    <div className="col-lg-3 col-xlg-3 col-md-3 m-t-2 right-side">
                        <CompanyRightpanel isLoggedIn={this.state.isLoggedIn}  criteriaUpdate={this.criteriaUpdate}  searchCriteria={this.state.searchCriteria} setPageContext={this.setPageContext} context={this.state.context} metaList={this.state.metaList} business={this.state.business} />
                    </div>
                </div>
              </div>
            </div>
        
      </div>
    )
  }
}

export default  connect(null,{ getMetaData, getSectorData})(withRouter(CompanySummary));