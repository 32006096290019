import React, { Component } from "react";
import OwlCarousel, { Options } from "react-owl-carousel";
import OppCard from "../Opportunities/Summary/OppCard";
import EventCard from "../eventSummary/EventCard";
import config from "../../shared/config";
import Axios from "axios";
import classNames from "classnames/bind";
import Helper from "../../shared/custom";
import { withRouter, Link } from "react-router-dom";

import store from '../../store';
import $ from "jquery";

export default class BoxSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
     reRenderBox : true,
     privileges:{}
    }

    const unsubscribe = store.subscribe(() => {
      var storeState = store.getState();
      var newState = {};
      var stateUpdateRequired = false;

      let subscription =  storeState.packageDetails;
      let privileges = subscription.privileges;
      if(this.state.privileges != privileges){
          this.setState({
              privileges:subscription.privileges
          })
      }
      }); 

  }

  componentDidUpdate(prevProps) {
    if(this.props.dataset != prevProps.dataset)
    {
     this.setState({reRenderBox : 1});
    }
  } 



  render() {
    let items = this.props.items ? this.props.items : this.state.items;
    let title = this.props.title;
    let type = this.props.type;
    let sectionId = this.props.id;
    let dataset = this.props.dataset;
    let uri = this.props.uri;
    let mode = this.props.mode;
    let cardClass = mode == "public" ? "public-card card" : "card";

    let page = this.props.page;
    let privileges = this.state.privileges;
    let upgradeUrl = Helper.upgradeUrl();

    
    return (
      <div>
        <div className={cardClass} id={sectionId}>
          {/* {mode =="public" ? 
             <div className="card-header text-left font-24">
                  <h5 className="m-b-0 m-t-0">{title}</h5>
            </div>
            : */}
            <div className="card-header text-left light-blue-header">
                  <h5 className="m-b-0 m-t-0 text-white">{title}</h5>
            </div>
          {/* } */}
        
          <div className="card-body p-b-0 no-dots">
            {type === "opportunity" ? (
              !Helper.isEmpty(dataset) ? (
                <OwlCarousel key={"opp"}
                  ref="opp"
                  className="owl-theme"
                  items={items}
                  callbacks={true}
                  onInitialized={this.onInitialized}
                  autoplay="true"
                  loop={dataset.length > 1 ? true : false}
                  margin={10}
                  autoWidth={false}
                  nav={true}
                >
                  {dataset.map((data, index) => (
                    <OppCard data={data} key={"box-trendopp-"+index} keyName={"trendOpp" + index} mode={mode} interest={true}/>
                  ))}
                </OwlCarousel>
              ) : (
                <div className="col-12 text-center">No Data Available</div>
              )
            ) : !Helper.isEmpty(dataset) ? (
              <OwlCarousel
                ref="events"
                className={"owl-theme events " + new Date() }
                items={items}
                callbacks={true}
                onInitialized={this.onInitialized}
                autoplay="true"
                loop={dataset.length > 1 ? true : false}
                margin={10}
                autoWidth={false}
                nav={true}
                key={"events"}
              >
                {dataset.map((data, index) => (
                  <EventCard data={data} keyName={"trendEvents"+index}   mode={mode} page={page}/>
                ))}
              </OwlCarousel>
            ) : (
              <div className="col-12 text-center">No Data Available</div>
            )}
          </div>
          {mode != "public" ? 
            <div className="col-md-12 text-right m-t-10">
             
              {/* <Link  to={privileges && privileges["INV_OPP"] ? "/" + uri : upgradeUrl } className="font-14" > */}
              {
                type == "opportunity" ? 
                  <Link to="/opportunities" className="font-14" >
                   View All opportunities
                 </Link>
                :
                <Link to={privileges && privileges["EVT"] ? "/" + uri : upgradeUrl }  className="font-14" >
                  View All {type == "opportunity" ? "opportunities" : type}
                </Link>
              }
              {/* <Link to={type == "opportunity" ? "/opportunities" : "/events"} className="font-14" >
                View All {type == "opportunity" ? "opportunities" : type}
              </Link> */}
            </div>
            :
            ""
          }
        </div>
      </div>
    );
  }
}
