import React, { Component } from 'react';
import Helper from '../../shared/custom';
import config from '../../shared/config';
import store from '../../store';
import Axios from "axios";
import { LoadingOverlay, Loader } from 'react-overlay-loader';

class ManageGuests extends Component {

  constructor(props){
    super(props);
    
    this.state={
        auth:{},
        admins:[],
        adminEmails:[],
        uri:null,
        type:null,
        owner:null,
        email:undefined,
        isEditable:false,
        showForm:false,     
        loading:false
    }

    this.showForm = this.showForm.bind(this);
    this.hideForm = this.hideForm.bind(this);
    this.checkEmail = this.checkEmail.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.fetchGuests = this.fetchGuests.bind(this);
    this.handleFetchGuests = this.handleFetchGuests.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

   
  }
  
  componentDidMount() {
    this.fetchGuests();  
  }


  componentWillReceiveProps(props){
    let uri = props.uri;
    let owner = props.owner;
    let admins = props.admins;
    
    if (!Helper.isEmpty(owner)){
        
        let isEditable = false;
        let storeState = store.getState();
        let storAuth = storeState.auth;
        let loggedInUser = {uniqueId:null}
    
        if(storeState.auth && storeState.auth.user){
          loggedInUser = storeState.auth.user;
        }

        if(admins && admins.length > 0){
          admins.forEach(admin => {
  
            if(admin.adminOwner === true){
              owner = admin;
              if(loggedInUser.uniqueId && loggedInUser.uniqueId == admin.uniqueId){
                isEditable = true;
              }
            }        
  
          });
        }


        if(loggedInUser.uniqueId && loggedInUser.uniqueId == owner.uniqueId){
          this.setState({
            showForm:false,
            owner:owner,
            uri:uri,
            isEditable:isEditable,
            auth:storAuth
          }, function(){
          })
        }
      }

    this.fetchGuests();  
  }

  showForm() {
    this.setState({showForm:true});
  }

  hideForm() {
    this.setState({showForm:false});
  }

  fetchGuests(reload){
    if(!reload && this.state.uri && this.props.uri == this.state.uri ){
      return false;
    }
    this.setState({loading:true});
    Axios.get(
      Helper.apiURL({ 
        uri:"event/:uri/guest/list",
        pathVar:{
          type:this.props.type,
          uri:this.props.uri,
        }
      })
    )
    .then((response) => { this.handleFetchGuests(response); })
    .catch((response) => { 
    
      this.handleResponse("error",response); 
      
    });
    
  }

  
  createGuestObject(inviteeOrEmail){
    if (typeof inviteeOrEmail === 'string') {
      return {email:inviteeOrEmail, fullName:""};
    }else if (Helper.isEmpty(inviteeOrEmail.id)){
      return {email:inviteeOrEmail.email, fullName:""};
    }
    return inviteeOrEmail;
  }


  handleFetchGuests(response) {
    this.setState({loading:false}); 
    var data = response.data;    
    if(data.status == 200){

      let guestsComposit = data.result || [];      

      let extGuests = guestsComposit.externalGuests?guestsComposit.externalGuests:[];
      let intGuests = guestsComposit.internalGuests?guestsComposit.internalGuests:[];
      
      let guests = intGuests;

      if(extGuests && extGuests.length > 0){
        extGuests.forEach(guestEmail => {
          
          guests.push(this.createGuestObject(guestEmail));
        });
      }

      let owner = null;
      
      let storeState = store.getState();
      let storAuth = storeState.auth;
      let loggedInUser = {uniqueId:null}

      if(storeState.auth && storeState.auth.user){
        loggedInUser = storeState.auth.user;
      }

      this.setState({
        guests:guests,
        showForm:false,
        owner:owner,
        auth:storAuth
      }, function(){
      })

    }else{
      Helper.handleError(data);
    }
  }
 
  checkEmail(){
    let email = this.state.email;

    if(Helper.isEmpty(email)){
      Helper.pushTError("EMAIL_REQUIRED");
      return false;
    }

    if(!Helper.isValidEmail(email)){
      Helper.pushTError("INVALID_EMAIL");
      return false;
    }

    let adminsEmails = this.state.adminEmails
    if(adminsEmails.indexOf(email) > -1){
      Helper.pushTError("ADMIN_EMAIL_EXIST");
      return false;
    }
    this.setState({loading:true});
    Axios.post(
      Helper.apiURL({ 
        uri:"event/:uri/guest/add",
        pathVar:{
          uri:this.state.uri,
        } 
      }),
      {
        email:email
      }
    )
    .then((response) => {
      this.handleResponse("success",response,"ADD"); 
    })
    .catch(err => {       
      this.handleResponse("error",err.response);
    });

  }

  handleResponse (type,response, op, subjectObject){
    this.setState({loading:false}); 
    var data = response.data; 
    if(type == "success" && data.status == 200){
      let successMsg = "";
      let guests = [];
      if (op == "ADD"){
        successMsg = "GUEST_ADDED";
        //fixing backend response
        let emailOrObject = null;
        if (Helper.isEmpty(data.result.user))
          emailOrObject = data.result;
        else{
          emailOrObject = data.result.user;
          emailOrObject.email = data.result.email;
          emailOrObject.fullName = data.result.user.name;
        }
        
        let invitee = this.createGuestObject(emailOrObject);
        guests = [...this.state.guests, invitee];
      }
      if (op == "DELETE"){
        successMsg = "GUEST_REMOVED";
        let listOfGuests = this.state.guests;
        
        guests = listOfGuests.filter(function( obj ) {
          return obj.email !== subjectObject;
        });
      }
      this.setState({
        guests: guests,
        email:""
      });
      
      Helper.pushTSuccess(successMsg);

    }else{ 
      Helper.handleError(data);
    }
  }

  handleFieldChange(e){
    let field = e.target.name;
    let value = e.target.value;    
    if(field){
      this.setState({[field]:value}, function(){

      });
    }    

  }

  handleDelete(e){
    this.setState({loading:true});
    let email = e.target.dataset.email;
    if(!email){
      return false;
    }

    Axios.post(
      Helper.apiURL({
        uri:"event/:uri/guest/remove",
        pathVar:{
          uri:this.state.uri,
        },
        query:{
          email:email
        } 
      }),
      {
        email:email
        //,type:"EXTERNAL"
      }
    )
    .then((response) => { this.handleResponse("success",response,"DELETE",email); })
    .catch((response) => { this.handleResponse("error",response); });
  }

  renderGuest(admin, loggedInUserUniqueId){

    let isEditable = this.state.isEditable;
    let owner = this.state.owner;
    let isOwner = false;
    let adminImage = admin.image || config.defaultUserImage;
    adminImage = Helper.dataPath(adminImage);

    let showDelete = isEditable;
    
    if(loggedInUserUniqueId && loggedInUserUniqueId == admin.uniqueId){      
      showDelete = true;
    }

    if(!Helper.isEmpty(admin) && !Helper.isEmpty(owner) && admin.uniqueId == owner.uniqueId){
      showDelete = false;
      isOwner = true;
    }

    return(
      <div className="row">
          <div className="col-md-12">
              <img src={adminImage} className="img-circle pull-left m-r-20 m-b-10 float-left" width="60" />
              <div className="verticle-h">
                  <h6 className="font-bold text-info">{admin.fullName}</h6>                  
                  {showDelete?<span><button className="point btn-plain fa fa-times text-danger" title="Remove Admin" data-email={admin.email} name="" onClick={this.handleDelete}></button></span>:""}  
                  {isOwner?<span><button className="point btn-plain fa fa-user text-success" title="Page Owner"></button></span>:""}
                  <span>{admin.email}</span> 
              </div>
          </div>
          
      </div>
    )
    
  }

  render() {
    let guests = this.state.guests;
    let loggedInUserUniqueId = null;
    let auth = this.state.auth;
    let {loading} = this.state;
    if(auth.isAuthenticated && auth.user.uniqueId){
      loggedInUserUniqueId = auth.user.uniqueId;
    }
    let email = this.state.email;
    
    return (
      <div>
          <div className="card text-left">
                {/* <LoadingOverlay>
                   
                </LoadingOverlay> */}
                 <LoadingOverlay>
                <div className="card-body">
                <Loader  loading={loading}/>
                    <h5 className="font-bold">
                      Event Guests
                    </h5>
                      {
                        (guests && guests.length>0)?
                          guests.map((guest, index) => (
                            <div key={"invAdmin"+index}>
                              {this.renderGuest(guest,loggedInUserUniqueId)}
                            </div>                            
                          ))
                          :
                          ''
                    }
                    
                    {this.state.isEditable ?
                      <ul className="list-style-none">                            
                            {!this.state.showForm ?
                                <li className="box-label">  <a onClick={this.showForm}  className="text-info">+ Invite New Guests</a></li>
                                :
                                <li className="input-group col-md-12 p-0">
                                        <input type="text" className="form-control" name="email" value={email} placeholder="Enter email address" onChange={this.handleFieldChange} />
                                        <div className="input-group-append closeInputFilter" onClick={this.checkEmail}>
                                            <span className="input-group-text" id="basic-addon2">
                                                <i className="ti-check"></i>
                                            </span>
                                        </div>
                                        <div className="input-group-append closeInputFilter" onClick={this.hideForm}>
                                            <span className="input-group-text" id="basic-addon2">
                                                <i className="ti-close"></i>
                                            </span>
                                        </div>
                                </li>
                            } 
                            
                        </ul>
                    :
                    ""
                    }                    
                </div>
                </LoadingOverlay>
            </div>    
      </div>
    )
  }
}
export default (ManageGuests);
