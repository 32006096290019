import React, { Component } from 'react';
import Axios from 'axios'
import Helper from '../../shared/custom';
import config from "../../shared/config";
let element = [];

class Upload extends Component {
  constructor(props){
    super(props)

    this.handleClick = this.handleClick.bind(this);
    this.onChangeUpload = this.onChangeUpload.bind(this);
   
    this.state ={
        fileName: '',
        uploading:false,
        isEditable:false,

    }
    
    this.startUpload = this.startUpload.bind(this);
    this.finishUpload = this.finishUpload.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

  }   
  
  handleClick(e) {
    this.refs.fileUploader.click();
  }
  
  onChangeUpload(e){
        
        let itemKey = this.props.itemKey;
        let items = this.props.items || [];
        let itemType = this.props.itemType;
        let itemRepoType = this.props.itemRepoType;
        let itemChange = this.props.itemChange;


        if(e.target.files.length > 0) {
            
            let file = e.target.files[0];
            if(!Helper.isValidFileType(file.type,itemType)){
              Helper.pushError("Invalid file format, please try a different file.");
              return false;
            }

            this.startUpload(file.name);
            let thisComponent = this;

            let formData = new FormData();
            formData.append("file", file);
            
            var uploadUrl = Helper.apiURL({
                                uri:"ws/file/upload",
                                query:{repositoryType:itemRepoType}
                            })
            Axios.post(uploadUrl, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            }).then(function (response) {
              
              thisComponent.finishUpload();
             
              if(response.data.result){
                items.push(response.data.result);               
                itemChange(itemKey,items);
              }else{
                Helper.pushError("Unable to upload the selected file");
              }
              
              
            })
            .catch(function () {
              Helper.pushError("Unable to upload the selected file");
              thisComponent.finishUpload();
            });

        }else{
          //no file found
          return false;
        }
        
        
        //call itemChange here
  }
  
  startUpload(fileName){
    this.setState({
      fileName:fileName,
      uploading:true
    })
  }

  finishUpload(){
    this.setState({
      fileName:null,
      uploading:false
    })
  }

  handleDelete(e){
     
    let itemKey = this.props.itemKey;    
    let itemChange = this.props.itemChange;

    let targetId = e.target.name;
    let items = this.props.items;
    let newItems = [];
    if(targetId){
      items.forEach(item => {
        if(item.uniqueId != targetId){
          newItems.push(item);
        }
      });
      itemChange(itemKey,newItems);
    }

  }

  renderItem(item,itemType,isEditable){

    let uri = item.uri;
    let name = item.name || null;
    let id = item.uniqueId?item.uniqueId:null;
    let itemRepoType = this.props.itemRepoType;
    

    if(item.publicUri){
      uri = Helper.dataPath(item.publicUri);
    }else{
      if(isEditable || this.props.isAuthenticated){
        let extraParams = {repositoryType:itemRepoType};
        uri = Helper.viewFile(item.uniqueId,extraParams);
      }else{     
          uri = undefined;        
      }
    }
    
    switch(itemType){
        case "DOC":
        //uri = Helper.dataPath(item.uri);        
        return <div className="col-lg-4 col-md-4 m-b-20" key={uri}>
                  <div className="file-card">
                    <div className="col-md-10 float-left p-0">                        
                          {uri ?
                            <div className="font-12 float-left break-all">
                              <a href={uri} target="_blank">
                                <i className="fas fa-file-pdf font-20 text-danger"></i>
                                &nbsp; {name}
                              </a>
                            </div>
                            :
                            <div className="font-12 float-left break-all">
                              <i className="fas fa-file-pdf font-20 text-danger"></i>
                              &nbsp; {name}
                            </div>
                          }                       
                    </div> 
                    {isEditable? <div className="col-md-1 font-12 float-right text-right"><button className="text-inverse point btn-plain fa fa-times" name={id} onClick={this.handleDelete}></button></div> : ""} 
                    <div className="clearfix"></div>
                  </div>                  
                </div>
        default:
        return  <div className="col-lg-3 col-md-3 m-b-20" key={uri}>
                    <a href={uri} target="_blank">
                      <img src={uri}  alt="user" className="img-responsive radius" />
                    </a>
                    {isEditable? <div className="col-md-1 font-12 float-right text-right " ><button className="text-inverse point btn-plain fa fa-times" name={id} onClick={this.handleDelete}></button></div> : ""}
                  </div>
    }
  }


  render() {
    
    let title = this.props.title;
    let description = this.props.description;
    let chooseFileLabel = this.props.chooseFileLabel || "Choose a file...";
    let noItemsUploadedLabel = this.props.noItemsUploadedLabel || "No items uploaded!";
    let chooseFileLabelAsHtml = this.props.chooseFileLabelAsHtml;
    let icon = this.props.icon;
    
    let items = this.props.items;
    let itemType = (this.props.itemType+"").toUpperCase();
    let itemRepoType = this.props.itemRepoType;
    let itemKey = this.props.itemKey;
    let isEditable = this.props.isEditable;
    let itemChange = this.props.itemChange;
   
   
    let file = this.state.fileName;
    file = file ? ( <span>Uploading - {file}</span>) : 
    ( 
      chooseFileLabelAsHtml ?
      (<span>{chooseFileLabelAsHtml}</span> )
      :
      (<span>{chooseFileLabel}</span> )
    );

    return (
        <div>
            <div className="col-lg-12 p-0 float-left">
                {title ? <h5>{title}</h5> : ""}
                {isEditable?
                    <div className="uploadTag">
                        <a href="javascript:void(0)" onClick={this.handleClick}>
                          <i className={"fas fa-"+icon}></i> {description}
                          <input type="file" id="fileUploaded" name="fileUpload" ref="fileUploader" style={{display: "none"}} onChange={this.onChangeUpload}/>
                          { chooseFileLabelAsHtml ? '':<br />}
                          <label htmlFor="fileUploader" className="text-success">{file}</label>
                        </a>
                    </div>
                :
                    ""
                }  

                {items && items.length>0?
                    <div className="result m-t-20">
                    <div className="row">                        
                        {items.map((item, index) => (
                            this.renderItem(item,itemType,isEditable)
                        ))}
                    </div>
                </div>
                :
                <div>
                    <center>{noItemsUploadedLabel}</center>
                </div>}              
                
            </div>
      </div>
    )
  }
}

export default Upload;