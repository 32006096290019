import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { login, fetchUserInfo, logoutUser  } from '../../actions/authAction';
import ApiHelper from "../../shared/ApiHelper";
import store from '../../store';
import Helpers from '../../shared/custom';
import STR from '../../shared/strings';
import Axios from 'axios';

class Follow extends Component {

  constructor(props,context){
      super(props);
      this.state = {
          auth:{},
          isAuthenticated:false,
          isEditable:false,
          uri:undefined,
          type:undefined,
          info:{},
          admins:[]
      }
      

      store.subscribe(() => {
        var storeState = store.getState();
        var auth = storeState.auth;
        this.setState({
            auth:auth, 
            isAuthenticated:auth.isAuthenticated
        //let isAuthenticated = auth.isAuthenticated?true:false;        

        });
      }); 

      
      this.performAction = this.performAction.bind(this);
      this.followAction = this.followAction.bind(this);
      this.updateEntityInfo = this.updateEntityInfo.bind(this);
      this.checkEditable = this.checkEditable.bind(this);
  }

  componentDidMount(){
    
    let storeState = store.getState();
    let isAuthenticated = storeState.auth && storeState.auth.isAuthenticated ? true : false;

    this.setState({
        uri:this.props.uri,
        type:this.props.type,
        isAuthenticated:isAuthenticated,
        auth:storeState.auth
    },() => {
        this.updateEntityInfo();
    })
  }

//   componentWillReceiveProps(nextProps){
//     let currentUri = this.state.uri;
//     let currentType = this.state.type;
//     if(nextProps.type == currentType && nextProps.uri == currentUri){
//         return false;
//     }
    
//     this.setState({
//         uri:nextProps.uri,
//         type:nextProps.type,
//     },() => {

//         this.updateEntityInfo();
//     })
//   }
  static getDerivedStateFromProps(nextProps, prevState){
   
    let newUri = nextProps &&  nextProps.match && nextProps.match.params ? nextProps.match.params.uri  : undefined;

    let currentUri = prevState.uri;
    let currentType = prevState.type;
    if (nextProps.type !== currentType || nextProps.uri !== currentUri){
        return { uri: nextProps.uri, type: nextProps.type};
    }
    return null;
  }


  componentDidUpdate(prevProps){
    let currentUri = this.state.uri;
    let currentType = this.state.type;

    let storeState = store.getState();
    let isAuthenticated = storeState.auth && storeState.auth.isAuthenticated ? true : false;

    if (prevProps.type !== currentType || prevProps.uri !== currentUri || this.state.isAuthenticated != isAuthenticated){
        this.setState({
            uri:prevProps.uri,
            type:prevProps.type,
            //auth:storeState.auth,
            isAuthenticated:isAuthenticated
        },() => {
            this.updateEntityInfo();
        })
    }
  }




//   componentWillReceiveProps(nextProps){
//     // let currentUri = this.state.uri;
//     // let currentType = this.state.type;

//     // if(nextProps.type == currentType && nextProps.uri == currentUri){
//     //     return false;
//     // }

    
//     // this.setState({
//     //     uri:this.props.uri,
//     //     type:this.props.type,
//     // },() => {
//     //     this.updateEntityInfo();
//     // })

//   }

  performAction(e){
    let dataSet = e.target.dataset;
    let following = !Helpers.isEmpty(dataSet.following) ? dataSet.following : e.currentTarget.getAttribute('data-following');
    if(following == 'true'){
        this.followAction(false);
    }else{
        this.followAction(true);
    }
  }

  followAction(type){
    let info = this.state.info;
    if(!info.id){
        return false;
      }
    Axios.post(
        Helpers.apiURL({
            uri:"user-follow"
        }),
        {
            objectType:"PAGE",
            objectIds:[info.id],
            following:type
        }
    )
    .then( (response) => {
        //let info  = response.data.result || undefined;
        let info = this.state.info;
        info.following = type;
        this.setState({info:info});
    })
    .catch((err) => {
        
    });
  }

  updateEntityInfo(){
    let info = this.props.info;
    let component = this;
   
    if (Helpers.isEmpty(this.state.uri))
        return;
    if(!info){
       
        ApiHelper.fetchEntityStats({
            type:this.state.type,
            uri:this.state.uri,
            callback:(info)=>{
                let admins = [];
                let infoAdmins = info.admins;
                if(infoAdmins){
                    infoAdmins.forEach(admin => {
                        admins.push(admin.uniqueId);
                    });
                }
                component.setState({
                    info:info,
                    admins:admins
                })
            }
        })
    }else{
        component.setState({
            info:info
        })
    }    
  }
  
  checkEditable(){
    let isEditable = false;
    let auth = this.state.auth;

    if(auth.isAuthenticated && auth.user && auth.user.uniqueId){
        let admins = this.state.admins;
       
        if(admins.indexOf(auth.user.uniqueId) > -1){
            isEditable = true;
        }
    }    
    return isEditable;
  }

  render() {
 
    if(!this.state.isAuthenticated){
        return(<div></div>);
    }
    let masterClassName = this.props.masterClassName || "float-left";
    let info = this.state.info;
    if(Helpers.isEmpty(info)){
        return(<div></div>);
    }

    let btnClass = "btn-theme-white";
    let btnText = STR.FOLLOW;
    if(info.following){
        btnText = STR.FOLLOWING;
        btnClass = "btn-info";
    }

    let fieldType = this.props.fieldType;
    let showEdit = this.props.showEdit;


    let isEditable = false;
    if(showEdit){
        isEditable = this.checkEditable();
    }
  
    return (
      <div className={masterClassName}>
       {isEditable && showEdit ? 
            <Link to={"/company-details/"+this.props.uri}>
                {/* <button className={"btn btn-info"}>{STR.EDIT}</button> */}
                <button className={"btn btn-info"}><i className="fa fa-edit"></i> {STR.EDIT}</button>
            </Link>            
        :
        fieldType === "anchor" ?
            info.following ? 
            <a className="dropdown-item" href="javascript:void(0)" data-following={info.following} onClick={this.performAction} >
                    <div className="dpIcon m-r-5">   
                        <i className="fas fa-eye-slash"></i>
                    </div>
                    <div className="mail-contnet dd-content">
                        <h5 className="font-medium font-14">Unfollow</h5>
                        <span className="mail-desc font-I-10">Stop seeing post from this company</span> 
                    </div>
            </a>  
            :
            ""
        :
        <button className={"btn "+btnClass} data-following={info.following} onClick={this.performAction} >{btnText}</button>
       }
      </div>
    )
  }

}

export default connect(null,{login,fetchUserInfo,logoutUser}) (withRouter (Follow));