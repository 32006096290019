import React, { Component } from 'react';
import Cards from './Cards';

class Opportunities extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row content-in m-t-80 p-t-10">
          <Cards />
        </div>
      </div>
    )
  }
}
export default  Opportunities;