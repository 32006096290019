import React, { Component } from 'react';
import Sidemenu from '../sidemenu/Sidemenu';
import Subrouter from '../../utils/Subrouter';
import Rightpanel from './Rightpanel';
import Helpers from '../../shared/custom';

export class content extends Component {
  _isMounted = false;
  constructor(props){
    super(props)
      this.state = {
          view :"default",   
          uri:undefined,
      }

  } 

  componentDidMount(){
    this._isMounted = true;
  }
  
  componentWillReceiveProps(nextprops){
    
    let newUri = nextprops.location ? Helpers.lastSegment(nextprops.location.pathname) : undefined;    
    if(!newUri){
      return false;
    }
    
    let uri = this.state.uri;
    if(!uri || ( uri != newUri) ) {
      this.setState({
        uri:newUri
      })
    }

  }
      
  render() {   
    return (
      <div>
          {
              this.props.location.pathname.includes("company-funding-details") ?
                  <div >
                      <div className="row content-in m-t-80 p-t-10">
                          <div className="col-lg-9 col-xlg-9 col-md-9 centerDiv">
                              <Subrouter />
                          </div>
                          <div className="col-lg-3 col-xlg-3 col-md-3 right-side">
                              <Rightpanel uri={this.state.uri} />
                          </div>
                      </div>
                  </div>
                  :
                  <div >
                      <div className="row content-in m-t-80 p-t-10">
                          <div className="col-lg-3 col-xlg-3 col-md-3 sideMenuCont">
                            <Sidemenu mode="company"/>
                          </div>
                          <div className="col-lg-6 col-xlg-6 col-md-6 centerDiv">
                              <Subrouter />
                          </div>
                          <div className="col-lg-3 col-xlg-3 col-md-3 right-side">
                              <Rightpanel uri={this.state.uri} />
                          </div>
                      </div>
                  </div>
          }

      </div>
    )
  }
}

export default content
