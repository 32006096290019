import React, { Component } from 'react';
import IndividualSearch from '../wigets/IndividualSearch';
import AddPeople from './AddPeople';
import Helpers from '../../shared/custom';
import config from '../../shared/config';
import store from '../../store';
import Select from 'react-select';
import Axios from 'axios';
import Loader from '../ui/Loader';
import ObjectsSearch from "../wigets/ObjectsSearch";
import STR from '../../shared/strings';
export default class CreatePeople extends Component {

    constructor(props){
        super(props);
        this.state={
            loadingPerson:false,
            loading:false,

            editPeople:false,
            enableAddNew:false,

            selectedIndividual:[],
            selectedTitle:undefined,
            
            uri:undefined,
            titleList:[],
            entries: []
        }
        this.handleClick =this.handleClick.bind(this);
        this.closeEdit =this.closeEdit.bind(this);
        this.handleAddMore = this.handleAddMore.bind(this);
        this.enableAddNew = this.enableAddNew.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.handleIndividualChange = this.handleIndividualChange.bind(this);
        //this.handleTitleChange = this.handleTitleChange.bind(this);
        this.addSelectedIndividual = this.addSelectedIndividual.bind(this);
        this.createOrUpdateIndividual = this.createOrUpdateIndividual.bind(this);
        this.handlePesonAdded = this.handlePesonAdded.bind(this);
        this.handlePersonDelete = this.handlePersonDelete.bind(this);
        this.handleCheckBox = this.handleCheckBox.bind(this);

    }  

    componentDidMount(){
        if(this.props.uri){
            this.setState({
                    uri:this.props.uri               
                },
                () => {
                    this.fetchIndividuals(this.props.uri);
                }
            )
        }

        let titleList =  Helpers.makeSelectOptions(this.props.titleList);
        this.setState({
            titleList:titleList
        })
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.uri){
            let newUri = nextProps.uri;
            let currentUri = this.state.uri;

            if(!currentUri || currentUri != newUri ){                
                this.setState({
                        uri:newUri               
                    },
                    () => {
                        this.fetchIndividuals(newUri);
                    }
                )
            }
           
            
        }
        let titleList =  Helpers.makeSelectOptions(nextProps.titleList);
        this.setState({
            titleList:titleList
        })
    }

    fetchIndividuals(){
        let uri = this.state.uri;
        let type = this.props.type;
        if(uri){
            Axios.get(
                Helpers.apiURL({
                    uri:":type/:uri/individuals",
                    pathVar:{uri:uri, type:type}
                })
            ).then((response) => {
                let result = response.data.result;
                
                if(result){
                    this.setState({
                        entries:result,
                    });
                }
            }).catch((err) => {
                
            })
        }
    }

    handleClick(){
        this.setState({
            editPeople: true,
        })
    }
    closeEdit(){
        this.setState({
            editPeople: false,
        })
    }
    handleAddMore(){
        var entries = this.state.entries
        entries.push('new row')
        this.setState({entries: entries})
     }
     deleteRow(index){
         var entries = this.state.entries;
         entries.splice(index, 1);
         this.setState({entries:entries, loading:false});
     }
     enableAddNew(){
         this.setState({
             enableAddnew:this.state.enableAddnew?false:true
         })
     }

     handleIndividualChange(option){        
        this.setState({
            selectedIndividual:option
        })
     }

    //  handleTitleChange(option){
    //     this.setState({
    //         selectedTitle:option
    //     })
    //  }

     addSelectedIndividual(){
        
        if(this.state.selectedIndividual.length == 0 ){
            Helpers.pushTError("SELECT_INDIVIDUAL");
            return false;
        }

        if(!this.state.selectedTitle){
            Helpers.pushTError("SELECT_INDIVIDUAL_TITLE");
            return false;
        }

        
        let title = this.state.selectedTitle.value;
        let individual = this.state.selectedIndividual[0];
        
        let payload = [{
            "individual": {"uniqueId": individual.uniqueId,"name": individual.name},
            "title":  {"id": title},
            "keyPerson":true,
            "bod":false,
        }]
        
        this.createOrUpdateIndividual(payload);

     }


     createOrUpdateIndividual(payload,index){
        let uri = this.state.uri;
        let type = this.props.type;

        if(uri){
            this.setState({
                addingPerson:true
            })
            Axios.post(
                Helpers.apiURL({
                    uri:":type/:uri/individuals",
                    pathVar:{uri:uri, type:type}
                }),
                payload
            ).then((response) => {
                let result = response.data.result;
                if(result){
                    let entries = this.state.entries;
                    let message = "PERSON_ADDED";
                    if(index){
                        entries[index] = result[0];
                        message = "SAVE_SUCCESS"
                    }else{
                        entries.push(result[0]);
                    }
                    
                    Helpers.pushTSuccess(message);
                    this.setState({
                        entries:entries,
                        addingPerson:false,
                        selectedTitle:null,
                    })
                }
            }).catch((err) => {
                //error
                let entries = this.state.entries;
                this.setState({
                    entries:entries,
                    addingPerson:false
                })
            })
        }

     }

     handlePesonAdded(person){       
        if(person){
            let entries = this.state.entries;
            entries.push(person);
            this.setState({
                entries:entries
            })
        }        
     }

     handlePersonDelete(target){
        
        
        let dataset = target.dataset;
        if(dataset.uniqueid){
            this.setState({loading:true});
            Axios.delete(
                Helpers.apiURL({
                    uri:"company/:uri/individual/:id/remove",
                    pathVar:{uri:this.props.uri, uniqueId:dataset.uniqueid, id:dataset.id}
                })
                
            )
            .then(() => {
                if(dataset.index){
                    this.deleteRow(dataset.index);
                    Helpers.pushSuccess("Person has been deleted successfully")
                }else{
                    this.setState({loading:false});
                }
            })
            .catch(() => {
                this.setState({loading:false});
            })
        }

     }

     handleCheckBox(e){

        let dataSet = e.target.dataset;
        if(dataSet.index){
            let entries = this.state.entries;
            let index = dataSet.index;
            if(entries[index]){
                let entry = entries[index];
                let key = e.target.name;
                let value = e.target.checked ? true : false;
                entry[key] = value;

                let payload = [{
                    "individual": {"uniqueId": entry.individual.uniqueId},
                    "title":  {"id": entry.title.id},
                    "keyPerson":entry.keyPerson,
                    "bod":entry.bod,
                }]

                this.createOrUpdateIndividual(payload,dataSet.index);
            }
            
        }
     }
     
     renderCard(user,index){
        if(Helpers.isEmpty(user)){
            return(<div></div>);
        }
        let individual = user.individual;
        let image = !Helpers.isEmpty(individual.profilePic)  && !Helpers.isEmpty(individual.profilePic.publicUri) ? Helpers.dataPath(individual.profilePic.publicUri) : Helpers.dataPath(config.defaultUserImage);
        let deleteDialogue = {
            proceed:this.handlePersonDelete,
            okStyle:"danger"
        }
        return(
            <div className="col-md-6 m-b-20" key={"KP-"+index} >
                <div className="row">
                    <div className="col-md-3">
                        <img src={image}  className="img-circle pull-left m-r-20 m-b-10 float-left m-t-10" width="50" />
                    </div>
                    <div className="col-md-7">
                        <div className="bod">
                            <h6 className="font-bold  m-t-0">{individual.name}</h6>
                            {/* <span className="font-10">{user.title.name}</span> */}
                            <div className="checkbox checkbox-success">
                                    <input id={"bod-cb-"+index} type="checkbox" data-index={index} name="bod" defaultChecked={user.bod ? true : false } onChange={this.handleCheckBox} />
                                    <label htmlFor={"bod-cb-"+index}>Board of Directors</label>
                            </div>
                            <div className="checkbox checkbox-success">
                                    <input id={"kp-cb-"+index} type="checkbox" data-index={index} name="keyPerson" defaultChecked={user.keyPerson ? true : false } onChange={this.handleCheckBox} />
                                    <label htmlFor={"kp-cb-"+index}>Key People</label>
                            </div>
                            <a 
                                className="btn btn-danger btn-xs m-r-10 text-white" 
                                data-index={index}
                                data-uniqueid={individual.uniqueId}
                                data-id={user.id}
                                onClick={(event) => Helpers.actionConfirm(event,deleteDialogue)}> 
                            {this.state.loading ? STR.LOADING : STR.DELETE} 
                            </a>
                        </div>
                    </div>
                </div>
                
                {/* 
                          
                 */}
            </div>
        )
        
     }
     
  handleTitleChange = (event) => {
    this.setState({ selectedTitle: event[0] });
  }

  render() {
    let entries = this.state.entries;
    let sectionTitle = this.props.sectionTitle;
    let titleList = this.state.titleList;

    return (
      <div>
        <div>
            <div className="card text-left" id="people">
                <div className="card-body">
                <div className="col-lg-12 p-0 float-left">
                    <h5 className="m-t-5">{sectionTitle}</h5>
                    <div className="uploadTag m-t-10 m-b-10">
                        <div className="row">
                            {entries.map((entry, index) => (
                                this.renderCard(entry,index)
                            ))}
                        </div>                        
                    </div>

                    {this.state.editPeople ? 

                    <div className="row m-t-20">
                        <div className="col-lg-12 col-md-6 m-b-20 m-t-10">                         
                            <div>                                
                                {this.state.enableAddnew ?
                                    <AddPeople 
                                        enableAdd={this.enableAddNew} 
                                        onAdd={this.handlePesonAdded} 
                                        titleList={titleList} 
                                        type={this.props.type} 
                                        uri={this.state.uri}
                                    />
                                    :
                                    <div className="row">
                                            <div className="col-md-5">
                                                <IndividualSearch title="Person" onChange={this.handleIndividualChange} />
                                            </div>
                                            <div className="col-md-5">
                                                <h5>Designation</h5>
                                                <ObjectsSearch
                                                    title=""
                                                    headingClass=""
                                                    onChange={event => this.handleTitleChange(event)}
                                                    name="selectedTitle"
                                                    defaultValue={this.state.selectedTitle?this.state.selectedTitle:null}
                                                    defaultSelected={!Helpers.isEmpty(this.state.selectedTitle) ? [this.state.selectedTitle] : []}
                                                />
                                                {/* <Select className="font-14 col-md-12 p-0 m-0" name="title" options={titleList} value={this.state.selectedTitle} placeholder="Position in company" onChange={this.handleTitleChange} /> */}
                                            </div>   
                                            <div className="col-md-2">
                                                <h5>&nbsp;</h5>
                                                {this.state.addingPerson?
                                                    <button className="btn btn-white">{STR.SAVING}</button>
                                                : 
                                                    <div>
                                                        <button className="btn btn-info m-r-10" onClick={this.addSelectedIndividual.bind(this)}> Add </button>
                                                        <button className="btn btn-white" onClick={this.closeEdit}> Cancel </button>
                                                    </div>
                                                    
                                                }                                                
                                            </div> 
                                            <div className="col-md-12">
                                                <div className="m-t-20">
                                                    <a onClick={this.enableAddNew} className="m-t-10">
                                                        + Want to add someone who’s not on Investera? <span className="text-info">Click here</span>
                                                    </a> 
                                                </div>                                                
                                            </div>                           
                                    </div>
                                }                                
                            </div> 
                        </div>
                    </div>
                    :
                        <a href="javascript:void(0)" className="m-t-20" onClick={this.handleClick}> + Add your company’s key people</a>
                    }
                 </div>
                </div>
            </div>
           
        </div>
      </div>
        
    
    )
  }
}
