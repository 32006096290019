import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import config from '../../shared/config';
import Forms from './Forms';
import Axios from "axios";


import { connect } from 'react-redux';
import { getMetaData } from '../../actions/metaAction';

import store from '../../store';
import Helpers from '../../shared/custom';

import { LoadingOverlay, Loader } from 'react-overlay-loader';
import EditText from "../ui/EditText";
import EditSelect from "../ui/EditSelect";
import ObjectsSearch from '../wigets/ObjectsSearch';
import MetaEdit from '../ui/MetaEdit';
import EditTextArea from "../ui/EditTextArea";
import EditMultiSelect from "../ui/EditMultiSelect";
import ApiHelper from "../../shared/ApiHelper";
import countries from "../../shared/country";
import areas from '../../shared/areas';

import Mobile from "../ui/Mobile";
import CompanyPs from "../wigets/CompanyPs";


class Cards extends Component {

  constructor(props) {
    super(props)
    this.state = {
      formActive: {},
      metaList: {},
      business: {},
      metaDatam: [],
      ownershipStatusOptions: [],
      areasServedOptions: [],
      productServicesOptions: [],
      companyCatagories: [],
      countryOptions: [],
      addressCityOptions: [],
      newCity: "",
      showKrpin: false,
      name: "",
      regNo: "",
      foundedDate: "",
      kraPin: "",
      ownershipStatus: {},
      category: {},
      tags: [],
      industryClassification: {},
      sectors: {},
      industryGroups: {},
      subIndustries: {},
      industries: {},
      areasServed: [],
      employeeCount: "",
      natureOfBusiness: "",
      psl: [],
      psText: "",
      email: "",
      website: "",
      phoneCode: "+254",
      phone: "",
      addressLine1: undefined,
      addressLine2: undefined,
      addressLine3: undefined,
      stateOrProvince: undefined,
      pobox: undefined,
      zip: undefined,
      addressCity: undefined,
      addressCountry: undefined,
      contactName: "",
      contactEmail: "",
      mobile: "",
      mobileCode: "+254",
      errors: {}

    };

    this.switchForm = this.switchForm.bind(this);

    store.subscribe(() => {
      var storeState = store.getState();
      var localMetaList = this.state.metaList;
      var storeMetaList = storeState.meta.metaList;
      var loclMeta = localStorage.getItem("metaList");

      if (!localMetaList || JSON.stringify(localMetaList) != JSON.stringify(storeMetaList)) {
        if (!Helpers.isEmpty(storeMetaList)) {
       
          let productServices = Helpers.makeSelectOptions(storeMetaList.PSL);
          this.setState({ metaList: storeMetaList, productServicesOptions: productServices });

        }
      }
      var localBusiness = this.state.business;
      var storeBusiness = storeState.business.data;
      if (!localBusiness || JSON.stringify(localBusiness) != JSON.stringify(storeBusiness)) {
        this.setState({ business: storeBusiness });
      }

    });

  }

  componentDidMount() {
    var storeState = store.getState();
    let meta = storeState.meta;
    let business = storeState.business;

    let countryOptions = Helpers.makeSelectOptions(countries);
    let areasServedOptions = Helpers.makeSelectOptions(areas);
    let companyCatagories = config.companyCatagories;
    let cityOptions = [];
    this.setState({
      areasServedOptions: areasServedOptions,
      companyCatagories: companyCatagories,
      countryOptions: countryOptions,
    })

    this.fetchMeta()


    let localMetaList = JSON.parse(localStorage.getItem("metaList"));
    if (Helpers.isEmpty(localMetaList)) {
      this.props.getMetaData();
    } else {
      let localMetaList = JSON.parse(localStorage.getItem("metaList"));
      this.setState(prevState => {
       // prevState.ownershipStatusOptions = Helpers.makeSelectOptions(localMetaList.COS);
        prevState.productServicesOptions = Helpers.makeSelectOptions(localMetaList.PSL);
        prevState.metaList = localMetaList;
        return { prevState }
      })
    }


  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (JSON.stringify(nextProps) != JSON.stringify(prevState.localProps)) {
      let localMetaList = JSON.parse(localStorage.getItem("metaList"));
      if (!Helpers.isEmpty(localMetaList)) {
       // this.fetchMeta()
       // prevState.ownershipStatusOptions = Helpers.makeSelectOptions(localMetaList.COS);
        prevState.productServicesOptions = Helpers.makeSelectOptions(localMetaList.PSL);
        prevState.metaList = localMetaList;
        return { prevState }
      } else {
        return {}
      }
    }
  }

  fetchMeta= ()=>{
    Axios.get(
      Helpers.apiURL({
          uri:"meta/list/COS",
        })
    )
    .then( (response) => {
        let pbOption = response.data.result || {};
        this.setState({
          ownershipStatusOptions:Helpers.makeSelectOptions(pbOption)
        })
        
    })
    .catch((err) => {
       
    });
  }




  handleFieldValueChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name) {
      // if(name == 'newCity'){
      //   this.setState({
      //     // addressCity:{
      //     //   id:null,
      //     //   name:e.target.value
      //     // },
      //     newCity:e.target.value
      //   })
      // }else{
        this.setState({ [name]: value });
      // }
      
    }
  }

  handleSelectChange = (option, e) => {
    let thisFieldName = e.name;
    if (thisFieldName == "areasServed" && option.some(e => e.label == 'Kenya')) {
      this.setState({
        showKrpin: true
      })
    } else {
      this.setState({
        showKrpin: false
      })
    }

    if (thisFieldName == "addressCountry") {
      this.setState({
        addressCityOptions: [],
        addressCity: null,
        loading: true
      })
      ApiHelper.getCities(option.value, this.setAddressCityList);
    }

    this.setState({ [thisFieldName]: option });
  }


  handleObjectChange = (e) => {
    let psl = e;
    let existingPsl = this.state.psl;

    existingPsl = psl.map((item) => {
      let localPsl = existingPsl.filter((x) => item.name ? item.name == x.name : item == x.name);
      if (localPsl.length == 0) {
        let res = item.customOption ? {
          id: null,
          name: item.name,
        }
          : item;
        return res;
      } else {

        return localPsl[0]

      }
    })

    console.log("psl/event", existingPsl);
    this.setState({ psl: existingPsl });
  }


  onChangeMeta = (name, slug) => {
    this.setState(prevState => {
      prevState[name] = slug;
      return prevState;
    })
  }

  setAddressCityList = (cityList) => {
    let cityOptions = Helpers.makeSelectOptions(cityList);
   
    this.setState({
      addressCityList: cityList,
      addressCityOptions: [...cityOptions, { value: 'other', label: 'Other' }],
      loading: false
    });
  }

  mobileValues = (data) => {
    this.setState({
      phoneCode: data.ext,
      phone: data.mobile,
    })
  }
  mobileValuesContact = (data) => {
    this.setState({
      mobileCode: data.ext,
      mobile: data.mobile,
    })
  }

  switchForm(e) {
    e.preventDefault();
    var formActive = this.state.formActive;
    formActive[e.target.name] = true;
    this.setState({ formActive: formActive });
  }

  vaidateForm = () => {
    let isValid = true;
    let fields = [
      "name",
      "regNo",
      "foundedDate",
      // "kraPin",  
      "ownershipStatus",
      "category",
      "sectors",
      "industryGroups",
      "subIndustries",
      "industries",
      "areasServed",
      "employeeCount",
      "natureOfBusiness",
      // "psText",
      "psl",
      "email",
      "addressLine1",
      "contactName",
      "contactEmail",
      "mobile",
      "mobileCode",
      "addressCity",
      "addressCountry"
    ];

    if (this.state.showKrpin) {
      fields.push("kraPin")
    } else {
      Helpers.removeByValue(fields, "kraPin")
    }

    let errors = {}

    fields.forEach((field) => {
      if (Helpers.isEmpty(this.state[field])) {
        isValid = false;
        errors[field] = "This field is mandatory"
      } else {
        errors[field] = ""
      }
    })

    this.setState({
      errors: errors
    })

    return isValid;
  }

  handleSubmit = () => {
    let isValid = this.vaidateForm();
    if (!isValid) {
      Helpers.pushError("Please fill all required fields");
      return false;
    }
    this.setState({
      loading: true
    });
    let {
      name,
      regNo,
      foundedDate,
      kraPin,
      ownershipStatus,
      category,
      industryClassification,
      sectors,
      industryGroups,
      subIndustries,
      industries,
      areasServed,
      employeeCount,
      natureOfBusiness,
      psl,
      email,
      website,
      addressLine1,
      addressLine2,
      addressLine3,
      pobox,
      zip,
      contactName,
      contactEmail,
      mobile,
      mobileCode,
      phoneCode,
      phone,
      newCity,

    } = this.state;


    let payload = {
      // profilePic: { uniqueId: this.state.profilePic },
      // documents:documents,
      pageDto: {
        name: name,
        website: website,
        contactEmails: [email],

        addressDto: {
          addressLine1: addressLine1,
          addressLine2: addressLine2,
          addressLine3: addressLine3,
          poBox: pobox,
          zip: zip,
          city: this.state.addressCity.value != "other" ? 
          {
            id: this.state.addressCity && this.state.addressCity.value ? this.state.addressCity.value : null
          }
          :
          {
              id: null,
              name:this.state.newCity
          },
          country: {
            id: this.state.addressCountry
              ? this.state.addressCountry.value
              : null
          }
        }
      },
      employeeCount: employeeCount,
      natureOfBusiness: natureOfBusiness,
      contactPersons: [
        {
          firstname: contactName,
          email: contactEmail,
          mobile: mobile,
          mobileCode: mobileCode
        }
      ]
    };
    payload.foundedDate = parseInt(foundedDate);
    payload.ownershipStatus = {
      id: ownershipStatus ? ownershipStatus.value : null
    }

    payload.contactNumbers = [{
      code: phoneCode,
      number: phone,
    }];
    payload.kraPin = kraPin;
    payload.regNo = regNo;
    payload.category = category;


    if (areasServed) {
      let areasServedPayload = [];
      areasServed.forEach(area => {
        areasServedPayload.push({ id: area.value });
      });
      payload.areasServed = areasServedPayload;
    }

    // let industryClassification = {};


    if (!Helpers.isEmpty(sectors)) {
      industryClassification.sectors = sectors.map((x) => ({ "id": x.value }));
    }

    if (!Helpers.isEmpty(industryGroups)) {
      industryClassification.industryGroups = industryGroups.map((x) => ({ "id": x.value }));
    }

    if (!Helpers.isEmpty(industries)) {
      industryClassification.industries = industries.map((x) => ({ "id": x.value }));
    }

    if (!Helpers.isEmpty(subIndustries)) {
      industryClassification.subIndustries = subIndustries.map((x) => ({ "id": x.value }));
    }

    // if(!Helper.isEmpty(sectors) || !Helper.isEmpty(industryGroups) || !Helper.isEmpty(industries) || !Helper.isEmpty(this.state.subIndustries)){
    payload.industryClassification = industryClassification;
    // }


    // if(!Helpers.isEmpty(psl)){
    //   payload.psl = psl.map((x)=>({id : x.value}));
    // }
    //payload.psText = psText;


    if (!Helpers.isEmpty(psl)) {
      payload.psl = psl
    }

    Axios.post(Helpers.apiURL({ uri: "company/create" }), payload)
      .then(response => {
        this.setState({
          loading: false
        });
        if (response.data.status == 200) {
          Helpers.pushSuccess("Company Details has been saved!");
          let data = response.data.result;
          let uri = data.pageDto.uri ? data.pageDto.uri : null;
          if (uri) {
            window.location = "/company-details/" + uri + "?create=true";
          }
        } else {
          Helpers.pushTError(response.data.message);
        }

      })
      .catch(err => {
        this.setState({
          loading: false
        });
        //Helpers.handleError(err.response);
        Helpers.pushError(err.response.data.message)
      });


  }



  render() {
    let formActive = this.state.formActive;
    let metaList = this.state.metaList;
    let business = this.state.business;
    let types = metaList && metaList.CTU ? metaList.CTU : [];
    let startUpType = Helpers.getStartupType(types);
    let { metaDatam, errors, loading, showKrpin, addressCityOptions } = this.state;

    return (
      <div className="row full-width bold-headers">
        <LoadingOverlay>
          <Loader fullPage loading={loading} />
        </LoadingOverlay>
        <div className="col-md-12 text-left m-t-20 m-b-20">
          <div className="col-md-12">
            <h1>Create a Company</h1>
            <h5 className="font-normal">Adding your organization on Investera+ allows you to promote it to all our users. Start off by letting us know some of the basic information about your organization.</h5>
          </div>
        </div>
        <div className="col-md-12">
          <div className="card">
            <div className="card-body text-left">
              <h5 className="m-t-0">Company Information</h5>
              <EditText
                name="name"
                title="Company Name"
                value={this.state.name}
                placeholder={"Your company name"}
                required={true}
                onValueChange={this.handleFieldValueChange}
                errorMsg={errors["name"]}
              />
              <EditText
                name="regNo"
                title="Business Registration Number"
                value={this.state.regNo}
                placeholder={"Business registration number of your company"}
                required={true}
                onValueChange={this.handleFieldValueChange}
                errorMsg={errors["regNo"]}
              />
              <EditText
                name="foundedDate"
                title="Year of Registration/Incorporation"
                value={this.state.foundedDate}
                placeholder={"Year in which your company was registered/incorporated"}
                required={true}
                onValueChange={this.handleFieldValueChange}
                errorMsg={errors["foundedDate"]}
              />

              <EditSelect
                name="ownershipStatus"
                title="Ownership Status"
                required={true}
                value={this.state.ownershipStatus}
                options={this.state.ownershipStatusOptions}
                onChange={this.handleSelectChange}
                additionalClassName="ediableInline preFilled"
                errorMsg={errors["ownershipStatus"]}
              />
              <EditSelect
                name="category"
                title="Category"
                required={true}
                value={this.state.category}
                options={this.state.companyCatagories}
                onChange={this.handleSelectChange}
                additionalClassName="ediableInline preFilled"
                errorMsg={errors["category"]}
              />


              <MetaEdit
                options={["sectors", "industryGroups", "industries", "sub_industries"]}
                data={metaDatam}
                onChangeMeta={this.onChangeMeta}
                error={errors}
                key={"metaEdit"}
              />

              <EditMultiSelect
                title="Countries/Regions of Operation"
                name="areasServed"
                required={true}
                value={this.state.areasServed}
                options={this.state.areasServedOptions}
                onChange={this.handleSelectChange}
                additionalClassName="ediableInline preFilled"
                errorMsg={errors["areasServed"]}
              />

              {showKrpin && <EditText
                name="kraPin"
                title="KRA Pin Number"
                value={this.state.kraPin}
                placeholder={"KRA pin number of your company"}
                required={showKrpin ? true : false}
                onValueChange={this.handleFieldValueChange}
                errorMsg={errors["kraPin"]}
              />}
              <EditText
                name="employeeCount"
                title="Number of Employees"
                value={this.state.employeeCount}
                placeholder={"Number of Employees"}
                required={true}
                onValueChange={this.handleFieldValueChange}
                errorMsg={errors["employeeCount"]}
              />

              <EditTextArea
                name="natureOfBusiness"
                addLabelclassName="isMandatoryLabel"
                title="Nature of Business"
                value={this.state.natureOfBusiness}
                onValueChange={this.handleFieldValueChange}
                required={true}
                rowclassName={'form-control textarea-sm'}
                errorMsg={errors["natureOfBusiness"]}
              />

              <div>
                <div className="col-lg-6 p-0 float-left">
                  <h5>{"Products/Services"}</h5>
                  <CompanyPs
                    required={true}
                    onChange={event => this.handleObjectChange(event)}
                    defaultValue={this.state.psl ? this.state.psl : []}
                    defaultSelected={!Helpers.isEmpty(this.state.psl) ? this.state.psl : null}
                  />
                </div>
              </div>

              <Mobile
                sendValue={this.mobileValues}
                extVal={this.state.phoneCode}
                mobileVal={this.state.phone}
                required={true}
                mobileName={"phone"}
                extName={"phoneCode"}
                title="Tel. Number"
                error={errors}
              />
              <EditText
                name="email"
                title="Company Email"
                value={this.state.email}
                placeholder={"Company email"}
                required={true}
                onValueChange={this.handleFieldValueChange}
                errorMsg={errors["email"]}
              />
              <EditText
                name="website"
                title="Website"
                value={this.state.website}
                placeholder={"Company website"}
                // required={true}
                onValueChange={this.handleFieldValueChange}
                errorMsg={errors["website"]}
              />
            </div>
          </div>
          <div className="card">
            <div className="card-body text-left">
              <h5 className="m-t-0">Contact Details</h5>
              <EditText
                name="addressLine1"
                title="Address"
                value={this.state.addressLine1}
                placeholder={"Address line one"}
                required={true}
                onValueChange={this.handleFieldValueChange}
                errorMsg={errors["addressLine1"]}
              />
              <EditText
                name="addressLine2"
                title=""
                value={this.state.addressLine2}
                placeholder={"Address line two (optional)"}
                onValueChange={this.handleFieldValueChange}
              />
              <EditText
                name="addressLine3"
                title=""
                value={this.state.addressLine3}
                placeholder={"Address line three (optional)"}
                onValueChange={this.handleFieldValueChange}
              />
              <EditText
                name="stateOrProvince"
                title=""
                value={this.state.stateOrProvince}
                placeholder={"State/Province/Region (optional)"}
                onValueChange={this.handleFieldValueChange}
              />
              <EditText
                name="pobox"
                title=""
                value={this.state.pobox}
                placeholder={"PO Box (optional)"}
                onValueChange={this.handleFieldValueChange}
              />
              <EditText
                name="zip"
                title=""
                value={this.state.zip}
                placeholder={"Zip Code (optional)"}
                onValueChange={this.handleFieldValueChange}
              />
              <EditSelect
                title="Country"
                headingclassName="isMandatoryLabel"
                name="addressCountry"
                value={this.state.addressCountry}
                options={this.state.countryOptions}
                onChange={this.handleSelectChange}
                additionalClassName="ediableInline "
                errorMsg={errors["addressCountry"]}
                required={true}
              />
              <EditSelect
                title="City/Town"
                headingclassName="isMandatoryLabel"
                name="addressCity"
                value={this.state.addressCity}
                options={this.state.addressCityOptions}
                onChange={this.handleSelectChange}
                additionalClassName="ediableInline mt-10"
                errorMsg={errors["addressCity"]}
                required={true}
              />
              {
                this.state.addressCity && this.state.addressCity.value == 'other' ?
                  <EditText
                    name="newCity"
                    title=""
                    value={this.state.newCity}
                    placeholder={"Enter city name"}
                    onValueChange={this.handleFieldValueChange}
                  />
                  :
                  ""
              }
            </div>
          </div>

          <div className="card">
            <div className="card-body text-left">
              <h5 className="m-t-0">Company Contact Person Information</h5>
              <EditText
                name="contactName"
                title="Name"
                value={this.state.contactName}
                placeholder={""}
                required={true}
                onValueChange={this.handleFieldValueChange}
                errorMsg={errors["contactName"]}
              />
              <EditText
                name="contactEmail"
                title="Email"
                value={this.state.contactEmail}
                placeholder={""}
                required={true}
                onValueChange={this.handleFieldValueChange}
                errorMsg={errors["contactEmail"]}
              />

              <Mobile
                sendValue={this.mobileValuesContact}
                required={true}
                extVal={this.state.mobileCode}
                mobileVal={this.state.mobile}
                mobileName={"mobile"}
                extName={"mobileCode"}
                title="Contact Number"
                name=""
                error={errors}
              />

            </div>
          </div>

          <div className="col-sm-12 col-xs-12 p-0">
            <button
              type="submit"
              className="btn btn-info waves-effect- waves-light- float-right"
              onClick={this.handleSubmit}
            >
              Save and Continue
            </button>
          </div>
        </div>

        {/* <div className="col-md-12">     
                {
                  types.map((type, index) => (
                    <div className="col-md-4 float-left" key={"typeCard"+type.id}>
                        {this.renderForm(type)}
                    </div>
                  ))
                }

                {
                  startUpType.id?
                    <div className="col-md-4 float-left" key={"typeCardStartup"+startUpType.id}>
                      {this.renderForm(startUpType)}
                    </div>
                  :
                  ""
                }
              
        </div> */}
      </div>
    )
  }
}

export default connect(null, { getMetaData })(withRouter(Cards));
