import React, { Component } from 'react';
import MergerCard from '../feeds/MergerCard';
import ContentCard from '../feeds/ContentCard';
import Helper from '../../shared/custom';
import EventFeedCard from '../feeds/EventFeedCard';
import UserPostCard from '../feeds/UserPostCard';
import OppFeedCard from '../feeds/OppFeedCard';
import UpdatesCard from '../feeds/UpdatesCard';
import { NewStartUp } from '../feeds/NewStartUp';
import { NewMember } from '../feeds/NewMember';
import { NewInvestment } from '../feeds/NewInvestment';
import { NewAcquisitions } from '../feeds/NewAcquisitions';
import { NewReport } from '../feeds/NewReport';



class FeedCards extends Component {
  constructor(props){
    super(props);
    this.state={
        feeds:[]
    }
    this.getFeed = this.getFeed.bind(this);

   
  }

  componentWillReceiveProps(props){
    if(!Helper.isEmpty(props)){
      this.setState({
        feeds:props.data
      })
    }
  }

  componentDidMount(){
      if(!Helper.isEmpty(this.props)){
        this.setState({
          feeds:this.props.data
        })
      }
      
  }


  getFeed(data,index){
        data.mode = !Helper.isEmpty(this.props.mode) ? this.props.mode : null;
        let type = data.action;
        let shared = !Helper.isEmpty(data.shared)? data.shared : false;
        switch(type){
             case 'ACQUISITION':
              return <MergerCard data={data} key={type + index} shared={shared} />;
            case 'MERGER':
              return <MergerCard data={data} key={type + index} shared={shared} />;
            case 'NEW_POST':
              return <UserPostCard data={data} key={type + index} shared={shared} />;
            case 'NEW_OPPORTUNITY':
              return <OppFeedCard data={data} key={type + index} shared={shared} />
            case 'NEW_EVENT':
              return <EventFeedCard data={data} key={type + index} shared={shared} />
            case 'FIELD_UPDATE':
              return <UpdatesCard data={data} key={type + index} shared={shared} />
            case 'NEWS':
              return <ContentCard data={data} key={type + index} shared={shared} page={this.props.page} />
            case 'GENERAL_UPDATE':
              return <NewStartUp data={data} key={type + index} shared={shared} page={this.props.page} />
            case 'PEOPLE_UPDATE':
              return <NewMember data={data} key={type + index} shared={shared} page={this.props.page} />
            case 'NEW_INVESTMENT':
              return <NewInvestment data={data} key={type + index} shared={shared} page={this.props.page} />
            case 'NEW_ACQUISITION':
              return <NewAcquisitions data={data} key={type + index} shared={shared} page={this.props.page} />
            case 'NEW_REPORT':
              return <NewReport data={data} key={type + index} shared={shared} page={this.props.page} />

    }
  }

  render() {
    let data = this.state.feeds;
    return (
      <div className="feedCards">
          {
            !Helper.isEmpty(data) ?
                data.map((data,index)=>(
                    this.getFeed(data,index)
                ))
                :
                ""
          }
      </div>
    )
  }
}


// const mapStateToProps = (state) => ({
//      deletedFeeds:state.deletedFeeds,
// })

export default (FeedCards)