import React, { Component } from 'react';
import config from '../../shared/config';
import { withRouter } from 'react-router-dom';
import Sidemenu from '../sidemenu/Sidemenu';
import EventContent from './EventContent';
import Helper from "../../shared/custom";
import RightpanelView from './RightpanelView';
import EventDetailsContent from './EventDetailsContent';
import Axios from 'axios';
import {EventMeta} from "../../shared/MetaHelper";


class EventDetails extends Component {
    constructor(props){
        super(props);
        this.state={
            eventDetails:null,
            addAdmin:false,
            invite:false,
            uri:null,
            id:null,
            location:props.location,
        }
        this.addAdmin = this.addAdmin.bind(this);
        this.hideAddAdmin = this.hideAddAdmin.bind(this);
        this.invite = this.invite.bind(this);
        this.loadEvent = this.loadEvent.bind(this);
    }  

    standardizedEvent(event){
        if (event.type == "PRIVATE"){
            event.coverPhotoUrl = event.coverPhoto.uniqueId?Helper.viewFile(event.coverPhoto.uniqueId):Helper.dataPath(config.defaultEventCover);
        }else{
            event.coverPhotoUrl = (event.coverPhoto && event.coverPhoto.publicUri) ? Helper.dataPath(event.coverPhoto.publicUri) : Helper.dataPath(config.defaultEventCover)
        }
        return event;
    }

    loadEvent(){
        var uri = this.props.match.params.uri;        
        var privateEvent = this.props.match.params.private;   

        if(!Helper.isEmpty(uri)){
            this.setState({"uri":uri}, function(){
                let eventType = "PUBLIC";
                if (!Helper.isEmpty(privateEvent) && privateEvent == "private"){
                    eventType = "PRIVATE";
                }

                let requestURI = "event/:uri"
                if (eventType == "PRIVATE")
                    requestURI = "event/:uri/details";


                Axios.get(
                    Helper.apiURL({
                        uri:requestURI,
                        pathVar:{uri:uri}
                    })
                )
                .then( (response) => {
               
                    let event  = response.data.result || null;   
                    let event1 = this.standardizedEvent(event);
                    
                    if(!event1){
                        Helper.pushTError("EVENT_NOT_FOUND");
                    }else{
                        this.setState({
                            eventDetails:event1
                        })
                    }
                })
                .catch((err) => { Helper.pushTError("EVENT_NOT_LOADED"); });


            });
        }
    }
   
    componentDidMount(){
        let location = this.state.location.pathname;
        let uri = Helper.lastSegment(location);
        this.setState({"uri":uri},()=>{
            this.loadEvent();
        });
        setTimeout(() => {
            window.scrollTo(0, 0);
          });
    }

    componentWillReceiveProps(nextProps){
        let currentUri = this.state.uri;
        let nextUri = nextProps.match && nextProps.match.params ? nextProps.match.params.uri : undefined;

        if(nextUri && nextUri != currentUri){
            this.setState({"uri":nextUri},() => {
                this.loadEvent();
            });
        }
        setTimeout(() => {
            window.scrollTo(0, 0);
          });
    }

    addAdmin(e){
        this.setState({
            addAdmin:true
        })
    }
    hideAddAdmin(e){
        this.setState({
            addAdmin:false
        })
    }

    invite(e){
    this.setState({
        invite:this.state.invite ? false:true,
    })
    }

  render() {
    let uri = this.state.uri;
    let eventDetails = this.state.eventDetails;
    let entityName = this.state.eventDetails && this.state.eventDetails.name?this.state.eventDetails.name:"";
    let description = this.state.eventDetails && this.state.eventDetails.summary?this.state.eventDetails.summary:"";
    let image = this.state.eventDetails?this.state.eventDetails.coverPhotoUrl:"";

    return (
      <div>
         <EventMeta
            entityUri = {entityName}
            description = { description}
            image = {image}
         />
         <div className="container-fluid">
            <div className="row content-in m-t-80 p-t-10">
                
                <div className="col-lg-3 col-xlg-3 col-md-3 searchMenu sideMenuCont">
                       <Sidemenu mode="events" uri={uri} />
                </div>
                <div className="col-lg-6 col-xlg-6 col-md-6 centerDiv">
                    <EventDetailsContent uri={uri}  eventDetails={eventDetails} />
                </div>
                <div className="col-lg-3 col-xlg-3 col-md-3 m-t-2 right-side">
                    <RightpanelView uri={uri}  eventDetails={eventDetails}/>
                </div>
            </div>
        </div>
      </div>
    )
  }
}


export default withRouter(EventDetails);