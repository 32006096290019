import React, { Component } from 'react';
import Helper from "../../shared/custom";
import config from "../../shared/config";
import { connect } from 'react-redux';
import store from '../../store';
import { login } from '../../actions/authAction';
import { getPackageDetails } from '../../actions/packageAction';
import { withRouter ,Link} from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago';

class ExpireDate extends Component {
    constructor(props){
        super(props);
        this.state = {
            createdDate : undefined,
            expireDate : undefined,
            expired: false,
            loggedIn:false,
            localAuth:false,
            enableSubscribe:false,
            subscription:[],
            gonnaExpire:false,
            isAdmin:false,
        }

       this.setExpireDate = this.setExpireDate.bind(this);
       this.getPlanDetails = this.getPlanDetails.bind(this);
       this.loadSubscription = this.loadSubscription.bind(this);

       store.subscribe(()=>{
           var storeState = store.getState();  
           let localAuth = this.state.loggedIn;
           let storeAuth = storeState.auth.isAuthenticated;
           let users = storeState && storeState.auth && storeState.auth.user ? storeState.auth.user : {};

            if(!Helper.isEmpty(users)){
              this.setState({
                isAdmin:users.adminOwner
              })
            }
            if(!this.state.enableSubscribe){
                return;
            }
            if(this.state.localAuth.toString() != storeAuth.toString()){
                this.setState({
                    loggedIn:storeAuth
                },()=>{
                    this.setExpireDate(this.state.loggedIn)
                })
            }
       })
    }


    componentDidMount(){
        
        
        setTimeout(()=>{
            var storeState = store.getState();  
            let loggedIn = storeState.auth.isAuthenticated;

            let users = storeState && storeState.auth && storeState.auth.user ? storeState.auth.user : {};

          
            if(!Helper.isEmpty(users)){
              this.setState({
                isAdmin:users.adminOwner
              })
            }

            this.setState({
                loggedIn:loggedIn,
                enableSubscribe:true
            },()=>{
              this.getPlanDetails();
              this.setExpireDate(loggedIn)
            })
            
        },1000);
    }

    getPlanDetails(){
      var storeState = store.getState(); 
      let subscription =  storeState.packageDetails;
      this.setState({
        subscription:subscription.packageDetails
      })
    }

    setExpireDate(loggedIn){
        var storeState = store.getState(); 
        let subscription =  storeState.packageDetails.packageDetails;
      
        if(loggedIn){
            //let createdDate =  storeState.auth.user ? new Date(storeState.auth.user.dateOfRegistration) : undefined;
            this.setState({
                //createdDate:createdDate,
                loggedIn:true,
            },()=>{
                let expireDate = subscription && subscription.nextBillingDate ? Helper.getDateInInputFormat(subscription.nextBillingDate)  : Helper.getDateInInputFormat(new Date());


                let expDate = new Date(expireDate)
                let now = new Date();


              

                const oneDay = 24 * 60 * 60 * 1000;

                const diffDays = Math.round(Math.abs((now - expDate) / oneDay));

               

                this.setState({
                    expireDate:expireDate,
                    expired: now.getTime() > expDate.getTime() ? true : false,
                    loggedIn:true,
                    gonnaExpire: diffDays <= 7  ? true :false,
                })  
            })
        }
    }

  loadSubscription(e){
        this.props.history.push("/subscription");
  }
    
  render() {
    
    let loggedIn = this.state.loggedIn;
    let expireDate = this.state.expireDate;
    let expired = this.state.expired;
    let subscription = this.state.subscription;
    let details = subscription && subscription.subscriptionTypeDto ? subscription.subscriptionTypeDto :null;
    let usedFullPlan = false;
    let usedPercentage;

    let pageViews = subscription? subscription.pageViews : 0;
    let pageViewsLeft = subscription? subscription.pageViewsLeft : 0;
    let pagesViewedSoFar = pageViews - pageViewsLeft;
    if(pageViewsLeft == 0 ){
      usedFullPlan = true;
    }
    let { gonnaExpire,isAdmin } = this.state;
   

    if(pageViews > 0){
      usedPercentage = (subscription.pageViewsLeft/pageViews) * 100;
      usedPercentage = 100 - Math.round(usedPercentage);
    }else{
      usedPercentage = 100;
    }


    if(!loggedIn){
      return(<div></div>)
    }

    return (
      <div>
        
        {/* {Helper.isEmpty(subscription) ?
          <div className="">
            <div className="card-body  yellow-bg-dark">
              <i className="mdi mdi-calendar biggerIcon"></i>
              {!expired ? 
                <div>
                  <h6 className="font-16 font-normal l-h-15 m-t-10">Your 3 month free trial version ends on:</h6>
                  <div className="font-16 text-default font-bold text-black">{expireDate}</div>
                </div>
              :
                <div>
                  <h6 className="font-16 font-normal l-h-15 m-t-10">Your package ended on:</h6>
                  <div className="font-16 text-default font-bold text-black">{expireDate}</div>
                </div>
              }
              
            </div>
          </div>

          : */}
          <div className="">
              {!Helper.isEmpty(details) &&  !expired ?
              <div>
                <div className="card-body  yellow-bg-dark expire">
                  <h3 className="font-24 font-normal">{!isAdmin  ? details.name : "Admin"}</h3>
                  {!isAdmin &&
                    details.name.toLowerCase() == 'basic' ?
                        <div> 
                          {/* <Link to="/subscribe" className="btn btn-white text-black font-16 m-t-10">Upgrade Your Package</Link>  */}
                          <Link to="/proceed?type=premium" className="btn btn-white text-black font-14 m-t-10 hoverable full-width break-word-all">Upgrade to Premium</Link> 
                          <Link to="/proceed?type=premium_plus_monthly" className="btn btn-white text-black font-14 m-t-10 hoverable full-width break-word-all">Upgrade to Premium Plus<p className="font-10 m-b-5">(for Professionals)</p></Link> 
                        </div>
                        :
                        !isAdmin  ? gonnaExpire ?
                            <div>
                              <h6 className="font-16 font-normal l-h-15 m-t-10">Your package expires: </h6>
                              <div className="font-16 text-default font-bold text-black">{!Helper.isEmpty(expireDate) && <ReactTimeAgo date={new Date(expireDate)} />}</div>
                            </div>
                            :
                            <div>
                              <h6 className="font-16 font-normal l-h-15 m-t-10">Your package expires on:</h6>
                              <div className="font-16 text-default font-bold text-black">{!Helper.isEmpty(expireDate) && expireDate}</div>
                            </div>
                            :
                            ""
                      
                  }
                 
                  {/* <h4 className="font-22">{Helper.prettyNumber(pagesViewedSoFar)}/{Helper.prettyNumber(pageViews)}</h4> */}
                  {/* <h4 className="font-12 font-normal">Pages Viewed</h4>
                  <div className="progress progressBar-plan">
                    <div className="progress-bar bg-plan" role="progressbar" style={{width:usedPercentage+"%"}}>{!Helper.isEmpty(usedPercentage) ? usedPercentage+"%" : "0%"}</div>
                  </div> */}
                </div>

                {/* <hr className="whiteHr"/> */}
               
              </div>
         
                :
                !isAdmin &&
                  <div className="card-body yellow-bg-dark">
                      <div>
                        <h6 className="font-16 font-normal">Your package expired  {!Helper.isEmpty(expireDate) && <ReactTimeAgo date={new Date(expireDate)} />}</h6>
                        <div> <Link to="/subscribe" className="btn btn-white text-black font-16 m-t-10">Renew Your Package</Link> </div>
                        {/* <div> <Link to="/contact" className="font-14">or Contact us for custom packages</Link> </div> */}
                      </div>
                
                </div>
              }
             </div>
{/*              
           }  */}
          </div>
    )
  }
}

const mapStateToProps = (state) =>({
    auth:state.auth.isAuthenticated,
    errors:state.errors
});

export default connect(mapStateToProps,{login,getPackageDetails})(withRouter(ExpireDate));
