import React, { Component } from 'react';
import Axios from 'axios';
import Helper from "../../shared/custom";
import config from '../../shared/config';
import classNames from 'classnames/bind';
import MyPagination from "../ui/MyPagination"
import OppCard from "./OppCard";
import store from '../../store';
import { BrowserRouter as Router, Route, Link ,Switch ,withRouter} from "react-router-dom";


class MyOpportunity extends Component {

  constructor(){
    super();
    this.state ={
      opps:[],
      pageOfItems: [],
      allItems: [],
      activePage: 1,
      totalRecords: 0,
      itemsCountPerPage:config.defaultPageSize,
      countryIds:[],
      dateFilter:{}
    }
    this.handlePageChange = this.handlePageChange.bind(this);
  }



  handlePageChange(pageNumber) {
    this.setState({activePage: pageNumber}, function(){
      this.fetchOpps();
    });
  }

  componentWillReceiveProps(props){

    let locationIds=""
    if(props && props.locationIds){
       locationIds = props.locationIds.join(",");
    }

    
    let filterDates={};
    if(props && props.filterDates){
      filterDates = props.filterDates;
    }

   
    this.setState({countryIds:locationIds ,dateFilter:filterDates},()=>{
      this.fetchOpps();
    });
  }

  
  componentDidMount(){
    var storeState = store.getState();
    var auth = storeState.auth;
    this.setState({
      isLoggedIn:auth.isAuthenticated
    },()=>{
      if(!this.state.isLoggedIn){
        this.props.history.push("/opportunities")
      }else{
        this.fetchOpps();
      }
    })
  }

  


  fetchOpps = () => {
    let qs = Helper.generatePaginationQS({"page":this.state.activePage, "length":this.state.itemsCountPerPage});
    qs.countryIds = this.state.countryIds;
    qs.startDate = this.state.dateFilter.from;
    qs.endDate = this.state.dateFilter.to;
    
    Axios.get(
        Helper.apiURL({
            uri:"opportunities/my-opportunities/summary",
            query:qs
        })
    )
    .then( (response) => {           
       let opps = response.data.result.aaData || [];
       let totalRecords = response.data.result.iTotalRecords || 0;
        this.setState({
                opps:opps,
                totalRecords:totalRecords
                });
       })
    .catch((err) => {
        
    });
}
  render() {
    let data = this.state.opps;
    return (
      <div>

            <div className="card text-left bold-headers">
                <div className="card-body">
                    <h5><b>My Opportunities</b></h5>
                      <div className="row">
                     { (!Helper.isEmpty(this.state.opps) && (this.state.opps.length > 0))?
                              this.state.opps.map((data,index )=> (
                                <div className="col-md-6 m-t-10 grey-border" key={index}>
                                   <OppCard data={data} mode="my-opps"/>
                                </div>
                          ))
                          :
                          <div className="text-center col-12">No opportunities Found</div>
                        } 
                            
                      </div>

               </div> 
             </div>     
        

             <MyPagination
                    activePage={this.state.activePage}
                    totalRecords={this.state.totalRecords}
                    onChange={this.handlePageChange}
                />
        
      </div>
    )
  }
}



export default  withRouter(MyOpportunity);