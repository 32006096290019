import React, { Component } from 'react';
import Sidemenu from '../sidemenu/Sidemenu';
import store from '../../store';
import Helper from "../../shared/custom";
import config from '../../shared/config';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import CurrentPlan from './CurrentPlan';

class PaymentSummary extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
       
    }
    componentDidMount(){
    }


  
  render() {
    return (
        <div>
            <div>
                <div >
                <div className="row content-in m-t-80 p-t-10">
                    <div className="col-lg-3 col-xlg-3 col-md-5 sideMenuCont">
                         <Sidemenu mode="payment"/>
                    </div>
                    <div className="col-lg-8 col-xlg-8 col-md-12">
                        <CurrentPlan />
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    )
  }
}



export default PaymentSummary;