import React, { Component } from 'react';
import { withRouter,Redirect } from 'react-router-dom';
import Helper from "../../shared/custom";
import SectorFilter from "../wigets/SectorFilter";
import UserFeedRightPanel from '../homepage/UserFeedRightPanel';

class EventRightPanel extends Component {
  constructor(props){
    super(props);
    this.state={
        startDate: new Date(),
        endDate: new Date(),
        criteriaUpdate:undefined,
        searchCriteria:{},
        business:null,
        metaList:[]
    }

    this.handleDateChange = this.handleDateChange.bind(this);
    
}


handleDateChange(date){
  this.setState({
    startDate:date
  })
}
  render() {
    let isLoggedIn = this.props.isLoggedIn;
    //sectorOptions = Helper.makeSelectOptions(business.sectorsArray);  
    let sectorsList = null;
    if (this.state.business){
      sectorsList = this.state.business.sectorsArray;
    }
  
    return (
      <div>

        {/* <div className="card-body">
                   <h5><b>Search events</b></h5>
                   <DatePicker
                    inline
                      selected={this.state.startDate}
                      onChange={this.handleDateChange}
                  />
                  <button className="btn btn-info clearfix">Search</button>
                </div> */}
        
        {isLoggedIn?
       
             this.props.context == "eventsBySector" 
              ?
              <SectorFilter type="events"/>
              :
              <UserFeedRightPanel />
            :
            <div className="card text-left bold-headers">
              <div className="card-body m-a">
                  <a href="/register" className="btn btn-info">Register New User</a>
              </div>
            </div>

        }
            
      </div>
    )
  }
}
export default withRouter(EventRightPanel);