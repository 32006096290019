import React, { Component } from 'react';
import config from '../../shared/config';
import Helper from "../../shared/custom";
import Interest from '../uiActions/Interest';
import Helpers from '../../shared/custom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

export default class OppCard extends Component {

  
  getStructuredData(){
    let data = this.props.data;
    let structuredData = {}

    if(!Helper.isEmpty(data)){
      if(data.page || data.opportunity){
        let image = data.page.image ? Helper.dataPath(data.page.image) : Helper.dataPath(config.defaultOpportunityCover);
        data.page.image = image;

        structuredData = data;
      }else{
          let image = data.profilePic && data.profilePic.uniqueId ? Helper.viewFile(data.profilePic.uniqueId) : Helper.dataPath(config.defaultOpportunityCover);
          structuredData.page = {"name":data.pageDto.name,"image":image,"uri":data.pageDto.uri};
          structuredData.opportunity = {subType:data.subType,"description": data.description};
          if(!Helpers.isEmpty(data._isIntrested)){
             structuredData._isIntrested = data._isIntrested
          }
          if(!Helpers.isEmpty(data.id)){
            structuredData.id = data.id
         }
          if(!Helpers.isEmpty(data._intrestedCount)){
            structuredData._intrestedCount = data._intrestedCount
         }
      }
    }
    return structuredData;
  }

  

  
  render() {
    let data = this.getStructuredData();   
    let keyName =this.props.keyName;
    let url;

    if(this.props.mode =="my-opps"){
      url = data.page.uri ? "/opportunity-details/"+data.page.uri :""
    }else{
      url = data.page.uri ? "/opportunity/"+data.page.uri :""
    }

    let parentClass = Helper.isEmpty(this.props.from) ?  "companyCard" : "logoutCard";

    let mode = this.props.mode;

    return (
      <div key={keyName+"parent"} className={parentClass}>
          {!Helper.isEmpty(data) ?
             <div className="col-md-12 p-0 m-t-5 topDiv text-left">
              <Link to={"/opportunity/"+data.page.uri} className="col-12 p-0 card-title-block">
                   <div className="roundImgCont"><img src={data.page && data.page.image ? Helper.dataPath(data.page.image) : Helper.dataPath(config.defaultCompanyImage)} className="float-left col-md-4 p-0" /></div>
                   <div className="col-md-7 float-left  p-0 text-left m-l-5">
                       <h5 className="text-info font-14 m-0 m-t-5"> 
                       {/* <a href={url} className="text-default"> */}
                         <span className="text-blue">{Helper.truncate(data.page.name,15)}</span>
                         {/* </a> */}
                         </h5>
                       <p className="font-12 p-0 text-info font-normal m-b-0">{data.opportunity && data.opportunity.subType? data.opportunity.subType.name :""}</p>
                       <p className="font-12 p-0 text-grey m-t-0  m-b-0"><i className="mdi mdi-star"></i> {data._intrestedCount ? data._intrestedCount :0} Interested</p>
                   </div>
                   <div className="font-bold col-md-1 float-right  p-0 investeraRank">
                       87
                   </div>
               </Link>

               {mode != "public" ? 
                  <div className="col-md-12 p-0  desc descDiv clearfix m-t-10">
                      <p className="text-left font-12 font-medium card-text">
                      {data.opportunity && data.opportunity.description? Helper.truncate(data.opportunity.description,120) :""} 
                      </p>
                  </div>
                  :
                  ""
               }

             
               
                <div className="col-md-12 m-t-10 font-14 p-0 clearfix">
                   <div className="like-comm text-left"> 
                     <Interest id={data.id} type={"OPPORTUNITY"} interested={data._isIntrested} masterClassName="float-right m-t-5 m-l-5 font-12"/>
                   </div>
                   {mode != "public" ? 
                       Helper.isEmpty(data.page.website) ? 
                            "": 
                          <a href={Helper.properExternalUrl(data.page.website)}  target="_blank" className="float-right m-l-5 m-t-2"><i className="material-icons font-24">language</i></a>
                        :
                       ""
                     }
                </div>
               
               
             </div> 
            :
            ""
          }            
      </div>
    )
   
  }
}
