import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import config from "../../shared/config";
import { getCompanyInfo } from "../../actions/entityAction";
import store from "../../store";
import Helper from "../../shared/custom";
import BatchList from "../ui/BatchList";
import MiniCard from "../ui/MiniCard";
import MicroCard from "../ui/MicroCard";
import AddressCard from "../ui/AddressCard";
import SocialCard from "../ui/SocialCard";

import StarRating from "../ui/StarRating";

import Loader from "../ui/Loader";
import Follow from "../uiActions/Follow";
import CompanyIcon from "../../components/companyDetails/CompanyIcon";
import EntityExtraOptions from "../uiActions/EntityExtraOptions"
import CompanyProgresBar from "../ui/CompanyProgressBar";
import CompanyClaim from '../companyDetails/CompanyClaim';
import NotUser from "./NotUser";
import Axios from 'axios';
import ProductService from "../createCompany/ProductService";
import VerificationDetails from "../profile/VerificationDetails";

export class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: {},
      overview: null,
      showClaimMainAction: true,
      page: null,
      localStore: {},
      showProgress: false,
      profileCompletness: 0,
      entityUri: null,
      entityLoaded: false,
      startup: null,
      loading: true,
      localProps: {},
      showExtraOptions: false,
      showEdit: false,
      isAuthenticated: {
        isAuthenticated: false
      },
      isAnnotated: false,
      annotatedURL: null,
      _pageClaimStatus: null,
      isOwner: false,
      notFound: false,

    };

    store.subscribe(() => {
      var storeState = store.getState();
      var entityState = storeState.entity || null;
      var company = entityState ? entityState.company : null;
      if (!company) {
        return;
      }



      this.setState({
        localStore: storeState,
        overview: company.entityInfo ? company.entityInfo.company : null,
        page: company.entityInfo ? company.entityInfo.page : null,
        profileCompletness: company.entityInfo && company.entityInfo.company && company.entityInfo.company.profileCompletness ? company.entityInfo.company.profileCompletness : null,
        startup: company.entityInfo ? company.entityInfo.startup : null,
        auth: storeState.auth,
        _pageClaimStatus: entityState._pageClaimStatus,
        entityLoaded: true,
        showExtraOptions: storeState.auth && storeState.auth.isAuthenticated ? true : false,
        notFound: storeState.entity.notFound,
      });

      setTimeout(() => this.setState({ loading: entityState.loading }), 1000);
    });
    this.scrollToVerfiyDiv = React.createRef()
  }


  handleEnableClaimBanner = () => {
    this.setState({
      showClaimMainAction: true
    });
  }

  showClaimMainActionWhenHashed = () => {
    let currentHash = window.location.hash;
    if (!Helper.isEmpty(currentHash) && currentHash === "#claim")
      this.handleEnableClaimBanner();
  }

  componentDidMount() {
    this.showClaimMainActionWhenHashed();
  }

  scrollToDivBanner = () => {
    this.props.scrollToRank()
  }

  scrollToVerfiy = () => {
    if (this.scrollToVerfiyDiv.current) {
      this.scrollToVerfiyDiv.current.scrollIntoView({
        behavior: "smooth",
        // block: "nearest",
      })
    }
  }

  updateAnnotation = (entityUri) => {
    if (entityUri) {
      Axios.get(config.api(`page/${entityUri}/permalink`)).then((response) => {
        if (response.data) {
          this.setState({
            isAnnotated: true,
            annotatedURL: response.data
          })
        }
        else {
          this.setState({
            isAnnotated: false,
            annotatedURL: null
          })
        }
      })
    }
  }
  componentWillReceiveProps(nextProps) {

    var storeState = store.getState();

    let entityUri = this.state.entityUri || undefined;
    let showProgress = false;

    if (storeState.auth && storeState.auth.isAuthenticated) {
      // showProgress = nextProps.pageOwnerId && parseInt(storeState.auth.user.id) == parseInt(nextProps.pageOwnerId) ? true : false;
      // this.setState({
      //   showProgress:showProgress
      // })
    }


    if (this.state.entityUri != nextProps.entityUri) {
      this.updateAnnotation(nextProps.entityUri)
    }

    if (!entityUri || entityUri != nextProps.entityUri || storeState.auth.isAuthenticated != this.state.isAuthenticated.isAuthenticated) {

      let facets = "page,company";
      if (storeState.auth && storeState.auth.isAuthenticated) {
        facets = "page,company,keyPeople,bod,companyAquisitions,investor,startup";
      }
      this.setState({
        showClaimMainAction: false,
        isAuthenticated: storeState.auth,
        loading: true,
        entityLoaded: false,
        entityUri: nextProps.entityUri,
        showExtraOptions: showExtraOptions,
        notFound: false
      })

      if (entityUri != nextProps.entityUri || storeState.auth.isAuthenticated != this.state.isAuthenticated.isAuthenticated) {
        this.props.getCompanyInfo(
          nextProps.entityUri,
          facets,
          this.props.history
          , function () {
          });
      }

    }

    let showExtraOptions = false;
    let users = storeState.auth.user;
    let page = this.state.page;
    let ownerDetails = page && page.owner ? page.owner.id : null;

    let isOwner = (users.id == ownerDetails || users.adminOwner);


    if (storeState.auth && storeState.auth.isAuthenticated && isOwner) {
      showExtraOptions = true;
      this.setState({ showEdit: true, isOwner: isOwner })
    } else {
      showExtraOptions = false;
      this.setState({ showEdit: false })
    }
    this.showClaimMainActionWhenHashed();
  }

  render() {
    var empty = Helper.isEmpty(this.state.page) || this.state.notFound;

    var page = this.state.page;

    var location = "";
    let loading = this.state.loading;
    let startup = this.state.startup;
    let company = this.state.company;
    let pageImage = config.defaultCompanyImage;
    if (!empty) {
      if (page.city) {
        location += page.city.name;
      }
      if (page.country) {
        location = Helper.isEmpty(location)
          ? page.country.name
          : location + ", " + page.country.name;
      }

      pageImage = page.image || config.defaultCompanyImage;
    } else {
      loading = false;
    }
    var overview = this.state.overview;
    let pageTypes = page && page.pageTypes ? page.pageTypes : [];
    let companyType = overview && overview.companyType ? overview.companyType : null;
    let privileges = this.props.privileges;
    let showContent = this.props.showContent;
    let pageViewsLeft = this.props.pageViewsLeft;
    let mode = this.props.mode;
    //let website
    let { isAnnotated, annotatedURL, isOwner } = this.state;



    const formattedRevenueValue = overview && overview.financialData && overview.financialData.revenue
      ? (parseFloat(overview.financialData.revenue) ? Helper.displayAmount({ amount: overview.financialData.revenue, currency: overview.financialData && overview.financialData.currency ? overview.financialData.currency : null }) : overview.financialData.revenue)
      : "";




    return (
      <div>
        {loading ? (
          <Loader />
        ) : !empty ? (
          <div>
            <div className="card bold-headers">
              <div className="card-body">
                <div className="float-right text-right">
                  {
                    (this.state.showExtraOptions) && <EntityExtraOptions objectType="company" uri={this.state.entityUri} handleEnableClaimBanner={this.handleEnableClaimBanner} />
                  }
                </div>

                {/* <div className="col-lg-12 p-0"> */}
                <div className="col-lg-12 p-0">
                  <div className="m-t-5">
                    <img
                      src={Helper.dataPath(pageImage)}
                      className="float-left img-rounded"
                      width="100" height="100"
                    />
                    <div className="card-body-right float-left m-l-10 text-left">
                      <h6 className="card-title m-t-10 m-b-5 l-h-25 font-14">
                        <CompanyIcon page={this.state.page} />&nbsp;
                        {page.name}
                      </h6>
                      {overview.ownershipStatus ? (
                        <h6 className="m-t-0 m-b-5 l-h-25  font-medium font-14"><i className="mdi mdi-domain"></i>&nbsp;{overview.ownershipStatus.name}</h6>
                      ) : (
                        ""
                      )}
                      {overview.sectors && overview.sectors.length > 0 ? (
                        <h6 className="card-custom-subtitle l-h-25  m-t-0 font-medium font-14">
                          <i className="mdi mdi-bank"></i> {overview.sectors.map(sector => sector.sector.name).join(", ")}<span>{!Helper.isEmpty(overview.category) ? !Helper.isEmpty(overview.sectors) ? ' -- ' + overview.category.label : overview.category.label : ""}</span>
                        </h6>
                      ) : (
                        ""
                      )}
                      {location ? (
                        <h6 className="text-grey m-t-0 m-b-5 l-h-25  font-medium font-14"><i className="mdi mdi-map-marker"></i>&nbsp;{location}</h6>
                      ) : (
                        ""
                      )}
                      {page.website ? (
                        <h6 className="text-grey m-t-0 m-b-0 l-h-25  font-medium font-14"><i className="mdi mdi-earth"></i> <a
                          // className="font-16"
                          href={Helper.externalURL(page.website)}
                          target="_blank"
                        >
                          {page.website}
                        </a></h6>
                      ) : (
                        ""
                      )}
                      {
                        isAnnotated ?
                          <div className="crunchbase-small">
                            <a href={annotatedURL} target="_blank" rel="noreferrer"><img style={{ "max-width": "65px" }} className="crunchbase-logo" src="http://plus-cdn.investera.com/social-icons/powered_by_crunchbase.png" /></a>
                          </div>
                          : ""
                      }

                      <StarRating rating={overview && overview.rankingDetails && overview.rankingDetails.ranking ? overview.rankingDetails.ranking : 0} page={this.state.page} scrollToDiv={this.scrollToDivBanner} />
                      {page.verified && <div className="verified pointer" onClick={this.scrollToVerfiy}> <img src={config.dataCdnStatic + "verified.png"} alt={"verfified by investera"} /></div>}
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 float-right p-0">
                  {/* <div className="m-t-10">
                              <div><span className="bigger-text">74 </span><span className="text-megna">+3</span> </div>
                              <div>Investera Rank</div>                                       
                      </div> */}
                  <div className="button-box m-t-20 float-right p-0">
                    {mode == "funding" ? <Link to={'/company/' + this.state.entityUri} className="btn btn-info">Go back</Link> : ""}
                    <Follow uri={page.uri} type={"COMPANY"} showEdit={this.state.showEdit} />
                    {/* <Link to={"/graph?uri="+page.uri} className="btn btn-action font-16" >Explore</Link> */}


                  </div>
                </div>
                {/* </div> */}
                {/* <div className="col-lg-12 float-left p-0  m-t-10 b-t">
                    
                </div> */}
              </div>
            </div>

            {
              this.state.showClaimMainAction && <CompanyClaim path={"/company/claim/" + page.uri} parentStatus={this.state._pageClaimStatus}></CompanyClaim>
            }

            {this.state.showProgress && this.state.profileCompletness > 0 ? (
              <CompanyProgresBar progress={this.state.profileCompletness}></CompanyProgresBar>
            ) : (
              ""
            )}

            {mode == "funding" ?

              ""
              :
              <React.Fragment>
                {
                  privileges && privileges["SUM"] && <div className="card bold-headers" id="summary">
                    <div className="card-header text-left light-blue-header">
                      <h5 className="m-b-0 m-t-0 text-white">Summary</h5>
                    </div>
                    <div className="card-body text-left font-12">
                      {overview.longDescription || overview.shortDescription ? (
                        !Helper.isEmpty(overview.longDescription) ? Helper.newLineToBr(overview.longDescription) : Helper.newLineToBr(overview.shortDescription)
                      ) : (
                        <div className="col-12 text-center"> No Data Found</div>
                      )}
                    </div>
                  </div>
                }

                {privileges && privileges["CLASS"] &&
                  <div className="card bold-headers" id="classification">

                    {/* {(!Helper.isEmpty(overview.industryClassification) && 
                (!Helper.isEmpty(overview.industryClassification.sectors) || !Helper.isEmpty(overview.industryClassification.industryGroups) || !Helper.isEmpty(overview.industryClassification.subIndustries) || !Helper.isEmpty(overview.industryClassification.industries))) ||
                !Helper.isEmpty(page.tags) ? ( */}
                    <div>
                      <div className="card-header text-left light-blue-header">
                        <h5 className="m-b-0 m-t-0 text-white">Classification</h5>
                      </div>
                      <div className="card-body text-left">
                        {overview.industryClassification.sectors ? (
                          <BatchList
                            title="Sectors"
                            items={overview.industryClassification.sectors}
                            type="sectors"
                          />
                        ) : (
                          <BatchList
                            title="Sectors"
                            items={["-"]}
                          />
                        )}
                        {overview.industryClassification.industryGroups ? (
                          <BatchList
                            title="Industries Group"
                            items={overview.industryClassification.industryGroups}
                            type="industryGroup"
                          />
                        ) : (
                          <BatchList
                            title="Industries Group"
                            items={["-"]}
                          />
                        )}
                        {overview.industryClassification.industries ? (
                          <BatchList
                            title="Industries"
                            items={overview.industryClassification.industries}
                            type="industries"
                          />
                        ) : (
                          <BatchList
                            title="Industries"
                            items={["-"]}
                          />
                        )}
                        {overview.industryClassification.subIndustries ? (
                          <BatchList
                            title="Sub-Industries"
                            items={overview.industryClassification.subIndustries}
                            type="subIndustries"
                          />
                        ) : (
                          <BatchList
                            title="Sub-Industries"
                            items={["-"]}
                          />
                        )}

                      </div>
                    </div>
                    {/* // ) : (
                //   <div className="col-12 text-center m-t-10 m-b-10">
                //     {" "}
                //     No Data Found
                //   </div>
                // ) */}
                    {/* } */}
                  </div>
                }


                {/* {!Helper.isEmpty(this.props.psl)  && <ProductService psl={this.props.psl} /> } */}

                {!Helper.isEmpty(this.props.psl) ? <ProductService psl={this.props.psl} /> : <ProductService psl={"-"} type="text" />}

                {privileges && privileges["AED_CI"] ?
                  // (!Helper.isEmpty(overview) && (overview.legalName || overview.foundedDate || overview.companyType || overview.employeeCount || overview.otherName || overview.ownershipStatus || overview.companySize ||overview.areasServed)) &&

                  <div className="card bold-headers" id="overview">
                    <div className="card-header text-left light-blue-header">
                      <h5 className="m-b-0 m-t-0 text-white">Overview</h5>
                    </div>
                    <div className="card-body text-left">
                      <div className="col-md-12 p-0 d-flex grid-container">
                        <div className="col-md-6 flex-column p-0">
                          {!Helper.isEmpty(overview && overview.category) ? (
                            <MiniCard label="Category" value={overview.category.label} />
                          ) : (
                            <MiniCard label="Category" value={"-"} />
                          )}
                          {!Helper.isEmpty(overview && overview.legalName) ? (
                            <MiniCard label="Legal Name" value={overview.legalName} />
                          ) : (
                            <MiniCard label="Legal Name" value={"-"} />
                          )}
                          {!Helper.isEmpty(overview && overview.foundedDate) ? (
                            <MiniCard
                              label="Year Founded"
                              value={overview.foundedDate}
                            />
                          ) : (
                            <MiniCard
                              label="Year Founded"
                              value={"-"}
                            />
                          )}
                          {!Helper.isEmpty(overview && overview.companyType) ? (
                            <MiniCard
                              label="Company Type"
                              value={
                                !Helper.isEmpty(overview && overview.companyType)
                                  ? overview.companyType.name
                                  : "-"
                              }
                            />
                          ) : (
                            <MiniCard
                              label="Company Type"
                              value={
                                "-"
                              }
                            />
                          )}
                          {!Helper.isEmpty(overview && overview.employeeCount) ? (
                            <MiniCard
                              label="Employee Count"
                              value={
                                !Helper.isEmpty(overview && overview.employeeCount)
                                  ? overview.employeeCount
                                  : ""
                              }
                            />
                          ) : (
                            <MiniCard
                              label="Employee Count"
                              value={
                                "-"
                              }
                            />
                          )}
                          {!Helper.isEmpty(overview && overview.regNo) ? (
                            <MiniCard label="Business Registration Number"
                              value={
                                !Helper.isEmpty(overview && overview.regNo)
                                  ? overview.regNo
                                  : ""
                              }
                            />
                          ) : (
                            <MiniCard label="Business Registration Number"
                              value={
                                "-"
                              }
                            />
                          )}
                          {!Helper.isEmpty(overview && overview.natureOfBusiness) ? (
                            <MiniCard label="Nature of Business"
                              value={
                                !Helper.isEmpty(overview && overview.natureOfBusiness)
                                  ? overview.natureOfBusiness
                                  : ""
                              }
                            />
                          ) : (
                            <MiniCard label="Nature of Business"
                              value={
                                "-"
                              }
                            />

                          )}
                        </div>
                        <div className="col-md-6 flex-column p-0">
                          {!Helper.isEmpty(overview && overview.otherName) ? (
                            <MiniCard label="Other Name" value={overview.otherName} />
                          ) : (
                            <MiniCard label="Other Name" value={"-"} />
                          )}

                          {!Helper.isEmpty(overview && overview.ownershipStatus) ? (
                            <MiniCard
                              label="Ownership Status"
                              value={
                                !Helper.isEmpty(overview && overview.ownershipStatus)
                                  ? overview.ownershipStatus.name
                                  : ""
                              }
                            />
                          ) : (
                            <MiniCard
                              label="Ownership Status"
                              value={
                                "-"
                              }
                            />
                          )}

                          <MiniCard
                            label="Company Size"
                            value={
                              !Helper.isEmpty(overview && overview.companySize)
                                ? overview.companySize.name
                                : "-"
                            }
                          />


                          <MiniCard label="Area Served"
                            value={
                              overview.areasServed ?
                                overview.areasServed.map((obj, i) => {
                                  return (i != 0 ? ", " : "") + obj.name
                                })
                                :
                                "-"
                            }
                          />


                          <MiniCard label="KRA Pin Number"
                            value={
                              !Helper.isEmpty(overview && overview.kraPin)
                                ? overview.kraPin
                                : "-"
                            }
                          />



                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  ""
                }

                {/* {overview && !Helper.isEmpty(overview.financialData) && (overview.financialData.revenue || overview.financialData.grossProfit || overview.financialData.netIncome) && 
              <div className="card bold-headers" id="financial">
              
                {overview && !Helper.isEmpty(overview.financialData) ?
                <div>
                  <div className="card-header text-left light-blue-header">
                  <h5 className="m-b-0 m-t-0 text-white">Financial Details</h5>
                </div>
                  <div className="card-body text-left">
                  <div className="col-12 p-0">
                  
                    {
                      !Helper.isEmpty(overview.financialData.revenue) ? <MicroCard
                          label="Revenue"
                          value={formattedRevenueValue}
                        />
                        :
                        ""
                    
                    }
                    {
                      !Helper.isEmpty(overview.financialData.grossProfit) ?
                        <MicroCard
                        label="Gross Profit"
                          value={overview.financialData && overview.financialData.grossProfit ? Helper.displayAmount({amount: overview.financialData.grossProfit,currency:overview.financialData && overview.financialData.currency? overview.financialData.currency : null}) : ""}
                      />
                      :
                      ""
                    }
                    {!Helper.isEmpty(overview.financialData.netIncome) ?
                      <MicroCard
                      label="Net Income"
                      value={overview.financialData && overview.financialData.netIncome ? Helper.displayAmount({amount: overview.financialData.netIncome,currency:overview.financialData && overview.financialData.currency? overview.financialData.currency : null}) : ""}
                      />
                      :
                      ""
                    }
                  
                  </div>
                </div>
                </div>
                : ""
                }
                
              </div>
             } */}

                {/* {Helper.isStartupCompany(pageTypes) && startup ? (
              <div className="card bold-headers"  id="startup">
                <div className="card-header text-left light-blue-header">
                  <h5 className="m-b-0 m-t-0 text-white">Startup Details</h5>
                </div>
                <div className="card-body text-left">
                  <div className="col-12 p-0">
                    <MiniCard
                      label="Business Type"
                      value={
                        startup.businessTypes
                          ? startup.businessTypes.map((answer) => {
                              return answer.name;
                            })
                          : "N/A"
                      }
                      type="array"
                    />
                    <MiniCard
                      label="Development Stage"
                      value={
                        startup.developmentStage
                          ? startup.developmentStage.name
                          : ""
                      }
                    />
                    <MiniCard
                      label="Revenue Generating"
                      value={startup.isRevenueGenerating}
                      type="boolean"
                    />
                    <MiniCard
                      label="Two Year Projection"
                      value={startup.isWithTwoYrProjection}
                      type="boolean"
                    />
                    <MiniCard
                      label="Business Plan Ready"
                      value={startup.hasBusinessPlan}
                      type="boolean"
                    />
                    <MiniCard
                      label="Current Fundraising Amount"
                      value={
                        startup.fundRaisingAmount
                          ? startup.fundRaisingAmount.name
                          : "NA"
                      }
                    />
                    <MiniCard
                      label="Previous Fundraising Amount"
                      value={
                        startup.previousAmountRaised
                          ? startup.previousAmountRaised.name
                          : "NA"
                      }
                    />
                    <MiniCard
                      label="Previous Fund Source"
                      value={
                        startup.previousFundSource
                          ? startup.previousFundSource.name
                          : "NA"
                      }
                    />
                    <MiniCard
                      label="Currently Raising Funds"
                      value={startup.isRaisingFunds}
                      type="boolean"
                    />
                  </div>
                </div>
              </div>
              ) : (
                ""
              )} */}


                {/* {page && ( page.website || page.contactEmails || page.contactNumbers || page.addressDto || page.facebook || page.twitter ||  page.linkedIn) &&  <div className="card bold-headers" id="contact"> */}
                {/* {
                  !showContent ||  pageViewsLeft < 1  ? 
                    <div className="text-left">
                        <NotUser title="Contact" showContent={this.props.showContent} pageViewsLeft={this.props.pageViewsLeft} />
                    </div>                  
                  : */}
                <div className="card" id="contact">
                  <div>
                    <div className="card-header text-left light-blue-header">
                      <h5 className="m-b-0 m-t-0 text-white">Contact</h5>
                    </div>
                    <div className="card-body text-left float-left">
                      <div className="col-12 p-0 free-boxes-container" >
                        {!Helper.isEmpty(page.website) ? (
                          <MicroCard label="Website" value={page.website} type="web"
                          />
                        ) : <MicroCard label="Website" value={"-"} />

                          // (
                          //   <MicroCard label="Website" value={["NA"]} type="web" link={false} />
                          // )
                        }
                        {!Helper.isEmpty(page.contactEmails) ? (
                          <MicroCard label="Email" value={page.contactEmails} type="email" />
                        ) : <MicroCard label="Email" value={page.contactEmails} />
                          // (
                          //   <MicroCard label="Email" value={["NA"]} type="email" link={false} />
                          // )
                        }
                        {!Helper.isEmpty(page.contactNumbers) ? (
                          <MicroCard label="Phone" value={page.contactNumbers} type="phone" />
                        ) : <MicroCard label="Phone" value={'-'} type="" />
                          // (
                          //   <MicroCard label="Phone" value={["NA"]} type="phone" link={false} />
                          // )
                        }

                        {!Helper.isEmpty(page.addressDto) ? (
                          <AddressCard label="Address" value={page.addressDto} type="address" />
                        ) : (
                          <AddressCard label="Address" value={"-"} type="address" link={false} />
                        )}

                        {/* {!Helper.isEmpty(page.facebook)  || !Helper.isEmpty(page.twitter) || !Helper.isEmpty(page.linkedIn) ? */}

                        <div className="col-md-4 col-sm-12 float-left p-1">
                          <div className="col-12 mini-card float-left">
                            <div className="font-bold float-left font-12">
                              Social Media Presence
                            </div>
                            <div className="m-t-30">
                              {page.facebook ?
                                <SocialCard
                                  label="Facebook"
                                  value={page.facebook}
                                  type="facebook"
                                /> : <SocialCard
                                  label="Facebook"
                                />}

                              {page.twitter ?
                                <SocialCard
                                  label="Twitter"
                                  value={page.twitter}
                                  type="twitter"
                                />
                                :
                                <SocialCard
                                  label="Twitter"
                                />
                              }

                              {page.linkedIn ?
                                <SocialCard
                                  label="LinkedIn"
                                  value={page.linkedIn}
                                  type="linkedin"
                                />
                                :
                                <SocialCard
                                  label="LinkedIn"
                                />
                              }

                            </div>
                          </div>
                        </div>
                        {/* :
                    ""
                    } */}


                      </div>

                      {/* {overview && overview.contactPersons && !Helper.isEmpty(overview.contactPersons[0]) && (overview.contactPersons[0].email || overview.contactPersons[0].firstname || overview.contactPersons[0].mobile) &&  */}
                      <React.Fragment>
                        <h5 className="m-t-10"><i>Company Contact Person Information</i></h5>
                        {
                          !Helper.isEmpty(overview && overview.contactPersons && !Helper.isEmpty(overview.contactPersons[0]) && overview.contactPersons[0].firstname) ? <MiniCard
                            label="Name"
                            value={overview.contactPersons[0].firstname}

                          />
                            :

                            <MiniCard
                              label="Name"
                              value={"-"}
                            />}
                        {
                          !Helper.isEmpty(overview && overview.contactPersons && !Helper.isEmpty(overview.contactPersons[0]) && overview.contactPersons[0].email) && isOwner ? <MiniCard
                            label="Email"
                            value={overview.contactPersons[0].email}
                            type="email"
                          />
                            :
                            <MiniCard
                              label="Email"
                              value={"-"}
                              type=""
                              pophover={!isOwner}
                              target={"email"}
                            />

                        }
                        {
                          !Helper.isEmpty(overview && overview.contactPersons && !Helper.isEmpty(overview.contactPersons[0]) && overview.contactPersons[0].mobile) && isOwner ? <MiniCard
                            label="Contact Number"
                            value={overview.contactPersons[0].mobileCode + overview.contactPersons[0].mobile}
                            type="phone"
                          />
                            :
                            <MiniCard
                              label="Contact Number"
                              value={"-"}
                              pophover={!isOwner}
                              target={"contactNumber"}
                            />

                        }
                      </React.Fragment>
                      {/* } */}
                    </div>
                  </div>

                  {/* // } */}
                </div>
                {/* // } */}


                {/* {overview && overview.verificationRequest && */}
                <div ref={this.scrollToVerfiyDiv}><VerificationDetails data={overview.verificationRequest} isOwner={isOwner} /></div>
                {/* } */}


                {/* {overview && overview.contactPersons && !Helper.isEmpty(overview.contactPersons[0]) && (overview.contactPersons[0].email || overview.contactPersons[0].firstname || overview.contactPersons[0].mobile) && 
              <div className="card bold-headers" id="financial">
              
                {overview && !Helper.isEmpty(overview.contactPersons[0]) ?
                <div>
                  <div className="card-header text-left light-blue-header">
                  <h5 className="m-b-0 m-t-0 text-white">Company Contact Person Information</h5>
                </div>
                  <div className="card-body text-left float-left full-width">
                  <div className="col-12 p-0 ">
                  
                    {
                      !Helper.isEmpty(overview.contactPersons[0].firstname) ? <MiniCard
                          label="Name"
                          value={overview.contactPersons[0].firstname}
                        />
                        :
                        ""
                    
                    }
                    {
                      !Helper.isEmpty(overview.contactPersons[0].email) ? <MiniCard
                          label="Email"
                          value={overview.contactPersons[0].email}
                        />
                        :
                        ""
                    
                    }
                    {
                      !Helper.isEmpty(overview.contactPersons[0].mobile) ? <MiniCard
                          label="Contact Number"
                          value={overview.contactPersons[0].mobileCode+overview.contactPersons[0].mobile}
                        />
                        :
                        ""
                    
                    }
                    
                  
                  </div>
                </div>
                </div>
                : ""
                }
                
              </div>
             } */}

              </React.Fragment>

            }


          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  company: state.company
});
export default connect(
  mapStateToProps,
  { getCompanyInfo }
)(Banner);
