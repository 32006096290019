import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Helpers from "../../shared/custom";
import config from "../../shared/config";

class CompanyIcon extends Component {
  constructor(props) {
    super(props);
  }

  getCompanySubTypeClass = () => {
    let page = this.props.page;
    let companySubType =
      page &&
      page.pageTypes &&
      page.pageTypes[0] &&
      page.pageTypes[0].subType &&
      page.pageTypes[0].subType.type
        ? page.pageTypes[0].subType.type
        : null;
    //companySubType = "INVESTOR";
    switch (companySubType) {
      case "STARTUP":
        return <i className="fas fa-rocket" />;
      case "INVESTOR":
        return (
          <img src={Helpers.dataPath(config.companyTypeIcon[companySubType])} />
        );
      case "SME":
        return "";
      case "VENTURE_CAPITALIST":
        return "";
    }
    return "";
  };

  render() {
    let subTypeClass = this.getCompanySubTypeClass();
    return <span className="text-info">{subTypeClass}</span>;
  }
}

export default withRouter(CompanyIcon);
