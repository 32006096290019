import React, { Component } from 'react';
import config from '../../shared/config';
import Helper from '../../shared/custom';
import { withRouter, Redirect ,Link} from 'react-router-dom';




import CountryBreakdown from './CountryBreakdown';
import IndustryBreakdown from './IndustryBreakdown';
import Categories from './Categories';
import Funding from './Funding';
import DefaultReport from './DefaultReport';


class PublicReport extends Component {
    constructor(props){
        super(props);
        this.state = {

        }

        this.redirectToComp = this.redirectToComp.bind(this);
    }

    componentDidMount(){
        //this.redirectToComp()
    }



    redirectToComp(){
        
        let type = Helper.lastSegment(this.props.location.pathname);

        switch(type){
            case 'country-breakdown':
                return  <CountryBreakdown/>
                break;
            case 'industry-breakdown':
                return <IndustryBreakdown/>
                break;
            case 'top-three-categories':
                return <Categories/>
                break;
            case 'funding-institutions':
                return <Funding/>
                break;
            default :
                return  <DefaultReport/>;

        }
        
    }




    render() {
        return (
            <div>
                {this.redirectToComp()}
            </div>
        )
    }
}

export default  withRouter(PublicReport);
