import React, { Component } from 'react';
import config from '../../shared/config';
import Helper from '../../shared/custom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import store from '../../store';

export default class AuthorDetails extends Component {
  constructor(props){
    super(props);
    this.state={
      user:[],
    }
     store.subscribe(() => { 
         this.getUserDetails()      
     })
}


 componentDidMount(){
   if(Helper.isEmpty(this.props && this.props.data && this.props.data.author)){
    this.getUserDetails();
   }
 }

  getUserDetails(){
    var storeState = store.getState();   
    let user = storeState.auth.user;
    this.setState({
         user:user
    })
}
 
  render() {
    let data = this.props.data;
    let userdata = this.state.user;
    let type = this.props.type;
    let url = "/"+data && data.author ? data.author.type+ "/"+data.author.uri : "";
    let sharedUrl = "/"+data && data.shareAuthor ? data.shareAuthor.type+ "/"+data.shareAuthor.uri : "";
    let postLink = "/"+data && data.url ? data.url : ""
    let cardType = data && data.action?data.action:"";
    return (
      <div>
           <div className="col-lg-12 p-0 text-left">
                <div>
                  {type == "shared" ?
                      <div>
                        <Link to={sharedUrl}><img src={Helper.dataPath(Helper.isEmpty(data.shareAuthor && data.shareAuthor.image) ? config.defaultUserImage : data.shareAuthor.image)} className="img-circle float-left" width="50" height="50"/> </Link>
                        <div className="m-l-10  m-t-10 float-left text-default">
                            <div className="font-14 font-bold m-b-u-10"><Link to={sharedUrl}>{data && data.shareAuthor && data.shareAuthor.name ? data.shareAuthor.name  :""}</Link> shared  {Helper.computeWithAOrAn(data.entityType)}
                               <Link to={postLink}>{data && data.entityType ? " "+data.entityType : ""}</Link>
                            <span className="text-info"></span></div>
                            {cardType !="NEW_EVENT"?<span className="font-10">{Helper.getDateInAgoFormat(data.time ? data.time :new Date())}</span>:""}
                        </div>
                      </div>
                    :
                    <Link to={url}>
                        <img src={Helper.dataPath(Helper.isEmpty(data.author && data.author.image) ? config.defaultUserImage : data.author.image)} className="img-circle float-left" width="50" height="50"/> 
                        <div className="m-l-10  m-t-10 float-left text-default">
                            <div className="font-14 font-bold m-b-u-10">{data && data.author && data.author.name ? data.author.name  :""}</div>
                            {cardType !="NEW_EVENT"?<span className="font-10">{Helper.getDateInAgoFormat(data.time ? data.time :new Date())}</span>:""}
                        </div>
                    </Link>

                  }

                 
                </div>
            </div>
      </div>
    )
  }
}
