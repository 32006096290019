import React, { Component } from 'react';
import Helper from '../../shared/custom';
import config from '../../shared/config';
import Follow from '../uiActions/Follow';
import LinesEllipsis from 'react-lines-ellipsis'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import StarRating from "../ui/StarRating";

export default class CompanyCard extends Component {

  render() {
    let list = this.props.data;
    let key = this.props.key;
    let mode = this.props.mode;
  
    return (
      <div>
            <div key={key} className="companyCard">
                    <div className="col-md-12 p-0 m-t-5 topDiv text-left">
                    <Link to={"/company/"+list.page.uri} className="col-12 p-0">
                         <div className="roundImgCont"><img src={list.page && list.page.image ? Helper.dataPath(list.page.image) : Helper.dataPath(config.defaultCompanyImage)} className="float-left col-md-4 p-0" /></div>
                          <div className="col-md-8 float-left  p-0 text-left m-l-5">
                              <h5 className="text-info font-14 m-0 m-t-5"><span className="text-blue">{Helper.truncate(list.page.name,15)}</span></h5>
                              <p className="font-12 p-0 text-info font-normal m-b-0">{list.company && list.company.companyType ? list.company.companyType.name :""}</p>
                              {/* <p className="font-12 p-0 text-grey m-t-0  m-b-0">{list && list.company && list.company.primarySector &&  ? list.company.primarySector.sector.name : (!Helper.isEmpty(list.company.sectors)?list.company.sectors[0].sector.name:"")}</p> */}
                          </div>
                          <div className="font-bold col-md-1 float-right  p-0 investeraRank">
                              87
                          </div>
                      </Link>
                    </div> 
                <div className="clearfix"></div>
                {mode == "public" ? 
                 ""
                 :
                 <StarRating rating={list.company && list.company.rankingDetails && list.company.rankingDetails.ranking ? list.company.rankingDetails.ranking : 0} page={list.page} />
                }
                {mode == "public" ? 
                 ""
                 :
                <div className="col-md-12 p-0  desc descDiv">
                    <p className="text-left font-12 font-medium">
                        {list && list.company && list.company.shortDescription ? Helper.truncate(list.company.shortDescription,config.defaultDescMaxLength) :list && list.company && list.company.longDescription  ?  Helper.truncate(list.company.longDescription,config.defaultDescMaxLength) : "-" }
                    </p>
                </div>
               }
                <div className="col-md-12 m-t-10 font-14 m-t-20 p-0 clearfix">
                     <Follow uri={list.page && list.page.uri ?list.page.uri:""} masterClassName={"like-comm btn-lil text-left float-right m-l-5"} type={"COMPANY"} />
                     {mode == "public" ? 
                      ""
                      :
                     <Link to={"/graph?uri=" + list.page.uri + "&type=COMPANY" } title="Knowladge Graph" className="float-right m-l-5 m-t-2">
                      <i className="material-icons font-24"> scatter_plot</i>
                     </Link>
                    }
                    {mode == "public" ? 
                      ""
                      :
                      Helper.isEmpty(list.page.website) ? 
                        "": 
                      <a rel="noreferrer" href={Helper.properExternalUrl(list.page.website)}  target="_blank" className="float-right m-l-5 m-t-2"><i class="material-icons font-24">language</i></a>
                     }
                     </div>
            </div>
      </div>
    )
  }
}
