import React, { Component } from 'react';
import Sidemenu from '../sidemenu/Sidemenu';
import store from '../../store';
import Helper from "../../shared/custom";
import config from '../../shared/config';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import AuthorDetails from '../feeds/AuthorDetails';
import ReadMoreAndLess from 'react-read-more-less';
import Axios from 'axios';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import {Helmet} from "react-helmet";
import MetaTags from 'react-meta-tags';
import NotFound from '../ErrorPages/NotFound';


import NewsRightPanel from './NewsRightPanel';
import NewsContent from './NewsContent';
import NewsSimilarPanel from './NewsSimilarPanel'

class NewsDetails extends Component {
  constructor(props){
    super(props);
    this.state ={
        uri:undefined,
        newsDetails:[],
        parentLocation:undefined,
        loading:false,
        notFound:false,
    }
    this.backToPage = this.backToPage.bind(this);
    this.getNewsDetails = this.getNewsDetails.bind(this);

  }

  

  componentDidMount(){
      this.setState({
          uri:this.props.match.params.uri,
          parentLocation:this.props.location.parentUrl,
      },()=>{
          if(!Helper.isEmpty(this.state.uri)){
            this.getNewsDetails(this.state.uri);
          }
          
      })
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 1000);
  }

  componentWillReceiveProps(props){
    if(this.state.uri != props.match.params.uri){
        this.setState({
            uri: props.match.params.uri,
            parentLocation:this.props.location.parentUrl,
        },()=>{
            if(!Helper.isEmpty(this.state.uri)){
                this.getNewsDetails(this.state.uri);
            }
            setTimeout(() => {
                window.scrollTo(0, 0);
              }, 1000);
        })
    }
  }
  getNewsDetails(uri){
    this.setState({
        loading:true
    })  
    Axios.get(
        Helper.apiURL({
            uri:"news/"+uri+"/details",
        })
    )
    .then( (response) => {     
        var news = response.data.result[0] || [];
        this.setState({
            newsDetails:!Helper.isEmpty(news) ? news : [],
            loading:false,
            notFound:false
        })
    })
    .catch((err) => {
        this.setState({
            newsDetails:[],
            loading:false,
            notFound:true
        })

    });
  }

    addDefaultNewsImage(ev){
        ev.target.src = Helper.dataPath("default/news-default-image.png");
    }

    backToPage(e){
        e.preventDefault();
        (Helper.isEmpty(this.state.parentLocation)) ? this.props.history.goBack() : this.props.history.push(this.state.parentLocation) ;
    }   

    goBack=()=>{
        (Helper.isEmpty(this.state.parentLocation)) ? this.props.history.goBack() : this.props.history.push(this.state.parentLocation) ;
    }
  render() {
    let data =  this.state.newsDetails;
    let loading = this.state.loading;
    let uri = data && data.uri?data.uri:null;
    let notFound = this.state.notFound;
    if(notFound){
       return <NotFound/>
    }
    return (
      <div>
              <Helmet>
                <title>{"Investera Plus | "+data.headLine}</title> 
                <meta property="og:url"                content={window.location.href} />
                <meta property="og:type"               content={"website"} />
                <meta property="og:title"              content={data.headLine} />
                <meta property="og:description"        content={data.headLine} />
                <meta property="og:image"              content={Helper.dataPath(Helper.isEmpty(data.image && data.image)?config.defaultNewsIcon : data.image)} />
                <meta property="fb:app_id"             content={config.facebookAppId} />
            </Helmet> 
          <div>
                {/* <MetaTags>
                    <meta property="og:url"                content={window.location.href} />
                    <meta property="og:type"               content={"website"} />
                    <meta property="og:title"              content={data.headLine} />
                    <meta property="og:description"        content={data.headLine} />
                    <meta property="og:image"              content={Helper.dataPath(Helper.isEmpty(data.image && data.image)?config.defaultNewsIcon : data.image)} />
                    <meta property="fb:app_id"             content={config.facebookAppId} />
                </MetaTags> */}

                <div>
                  <div className="row content-in m-t-80 p-t-10">
                    <div className="col-lg-3 col-xlg-3 col-md-3 sideMenuCont">
                       <Sidemenu/>
                    </div>
                    <div className="col-lg-6 col-xlg-6 col-md-6 centerDiv">
                        <div>
                        <LoadingOverlay>
                            <Loader fullPage loading={loading}/>
                        </LoadingOverlay>
                            {!Helper.isEmpty(data) ? 
                                <div className="card additionalClass" id="contentCard">
                                    
                                    <div className="card-body">
                                        <div className="col-lg-12 p-0 text-left">
                                            <h2 className="font-16 font-bold">{data.headLine}</h2>
                                            {/* <h6 className="m-t-5"><i>{data.date ? Helper.getDateInDisplayFormat(data.date) : ""} - {data.country}</i></h6> */}
                                            <hr className="greyLine"/>
                                            <a onClick={()=>this.goBack()} className="font-12"> <i class="fas fa-chevron-left"></i> Back</a>
                                        </div>
                                    </div>
                              
                                    <div className="card-body">
                                        <div className="col-lg-12 p-0">
                                                <div className="m-a text-center">
                                                    <img onError={this.addDefaultNewsImage} src={Helper.dataPath(Helper.isEmpty(data.image && data.image)?config.defaultNewsIcon : data.image)} className="controled-img m-w" />
                                                </div>
                                            </div>
                                    </div>

                                    <div className="card-body text-left">
                                   
                                        <div className="col-lg-12 p-0 font-14">
                                             <a rel="noreferrer" href={Helper.properExternalUrl(data.url)} target="_blank" className="m-0 font-14"><i class="material-icons font-12 v-a">language</i> {Helper.extractHostname(data.url)}</a>
                                             <span className="v-a">  |  <i class="material-icons font-12 v-a">calendar_today</i><span class="">{Helper.getDateInInputFormat(data.date)}</span>   <span className="v-a">|  {data.country} </span></span>
                                        </div>
                                        
                                        <div className="col-lg-12 p-0 m-t-5 font-14">
                                             {/* <ReadMoreAndLess
                                                          ref={this.readMore}
                                                          className="read-more-content"
                                                          charLimit={config.summaryLimit}
                                                          readMoreText="Read more"
                                                          readLessText="Read less"
                                                      >
                                                      {data.description?data.description:"-"}
                                            </ReadMoreAndLess>  */}
                                            {data.description ? Helper.newLineToBr(Helper.readableString(data.description)).slice(0,3) : "-"}
                                            <a rel="noreferrer noopener" href={Helper.properExternalUrl(data.url)} target="_blank" className="m-0 font-14"> Read More</a>  
                                            {}
                                        </div>
                                    </div>
                                </div>

                                :
                          
                                <div className="card additionalClass" id="contentCard">
                                    <div className="card-body">
                                        <div className="col-lg-12 p-0 text-center">
                                            No Data Available
                                        </div>
                                    </div>
                                </div>

                            }
                          
                      </div>
                      {uri?
                        <NewsSimilarPanel uri={uri}/>
                        :""
                      }
                    </div>
                    <div className="col-lg-3 col-xlg-3 col-md-3 m-t-2 right-side">
                        <NewsRightPanel params = {this.props.match.params} parentUrl= {this.props.parentLocation}/>
                    </div>
                </div>
            </div>
        </div>
      </div>
    )
  }
}

export default withRouter(NewsDetails);
