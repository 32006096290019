import React, { Component } from 'react';
import Sidemenu from '../sidemenu/Sidemenu';
class Home extends Component {
  render() {
    return (
      <div className="col-lg-6 col-xlg-6 col-md-7">
            <h2>Welcome</h2>
      </div>
    )
  }
}

export default Home;