import React, { Component } from 'react';
import config from '../../shared/config';
import Helper from '../../shared/custom';
let element = [];

class Upload extends Component {
  constructor(props){
      super(props)
    
    this.handleClick = this.handleClick.bind(this);
    this.onChangeUpload = this.onChangeUpload.bind(this);
   
    this.state ={
        fileName: '',
    }
  
  }   

  handleClick(e) {
    this.refs.fileUploader.click();
  }
  onChangeUpload(e){
        if(e.target.files.length > 0) {
            this.setState({ fileName: e.target.files[0].name });
        }
     
}

  getContent(fieldType,values){
      if(Helper.isEmpty(values)){
          return false;
      }
    for(var i =0; i<values.length; i++){
            switch(fieldType){
                case "VIDEO":
                return <div className="col-lg-3 col-md-6 m-b-20" key={i}>
                            <video controls="controls" preload="metadata">\
                                    <source src={values[i]} type="video/mp4" />\
                            </video>
                        </div>
                case "DOC":
                return <div className="col-lg-4 col-md-6 m-b-20 file-card" key={i}>
                           <div className="col-md-10 float-left p-0">
                               <div className="font-12 float-left break-all">
                                    <i className="fas fa-file-pdf font-20 text-danger"></i>
                                    &nbsp; {values[i]}
                                </div>
                           </div> 
                             <div className="col-md-1 font-12 float-right text-right"><a href="" className="text-inverse"><i className="fa fa-times"></i></a></div> 
                        </div>
                default:
                return  <div className="col-lg-3 col-md-6 m-b-20"><img src={values[i]}  alt="user" className="img-responsive radius" /></div>
        
            }
        }

  
  }


  render() {
    let heading = this.props.heading;
    let showText = this.props.showText;
    let icon = this.props.icon;
    let values = this.props.value;
    let fieldType = this.props.fieldType.toUpperCase();
    let name = this.props.name;

    const { fileName } = this.state;
    let file = null;

    file = fileName 
      ? ( <span>File Selected - {fileName}</span>) 
      : ( <span>Choose a file...</span> );

    return (
        <div>
        <div className="col-lg-12 p-0 float-left">
                <h5>{heading}</h5>
                <div className="uploadTag">
                     <a href="javascript:void(0)" onClick={this.handleClick}>
                        <i className={"fas fa-"+icon}></i> {showText}
                       <input type="file" id={name+'file'} name={name} ref="fileUploader" style={{display: "none"}} onChange={this.onChangeUpload}/>
                       <br />
                       <label htmlFor={name+'file'} className="text-success">{file}</label>
                     </a>
                </div>
                <div className="result m-t-20">
                    <div className="row">
                        {this.getContent(fieldType,values)}
                    </div>
                </div>
            </div>
      </div>
    )
  }
}

export default Upload;