import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Sidemenu from '../sidemenu/Sidemenu';
import UserFeed from './UserFeed'; 
import UserFeedRightPanel from './UserFeedRightPanel';
import {DefaultMeta} from "../../shared/MetaHelper";

class UserHomepage extends Component {
  render() {
    return (
        <div className="container-fluid">
              <DefaultMeta/>
              <div className="row content-in m-t-80 p-t-10">
                    
                  <div className="col-lg-3 col-xlg-3 col-md-3 sideMenuCont">
                      <Sidemenu mode="userprofile" />
                  </div>
                  <div className="col-lg-6 col-xlg-6 col-md-6 centerDiv">
                      <UserFeed/>
                  </div>
                  <div className="col-lg-3 col-xlg-3 col-md-2 m-t-2 userProfileRight right-side">
                      <UserFeedRightPanel />
                  </div>
              </div>
        </div>
    )
  }
}

export default withRouter(UserHomepage) ;