import React, { Component } from 'react';
import config from '../../shared/config';
import Helper from '../../shared/custom';
import { withRouter, Redirect } from 'react-router-dom';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import CardActions from './CardActions';
import CommentShareCount from './CommentShareCount';
import CommentShareBtn from './CommentShareBtn';
import Comments from './Comments';
import AuthorDetails from './AuthorDetails';

class MergerCard extends Component {

    constructor(props){
        super(props);
        this.state = {
            focusInput:false,
            updatedId :null
        }
        this.focusInputFiled = this.focusInputFiled.bind(this);
        this.updateCounts = this.updateCounts.bind(this);
    }    

    focusInputFiled(){
        this.setState({
            focusInput: !this.state.focusInput
        })
    }
    updateCounts(id){
        if(!Helper.isEmpty(id)){
            this.setState({
                updatedId:id
            })
        }
      }

  render() {
    let data = this.props.data;
    let icon =  data.merger ? "arrowIcon fas fa-plus" : "arrowIcon fas fa-arrow-right";
    let btnText =  data.merger ? "Merger" : "Aquisition";
    let info =  data.merger ? "merged with" : "has acquired";
    let shared = this.props.shared;

  

    return (
      <div>
          <div className="card" id="section2">
                  <div className="card-body">
                        
                    {
                        shared ? 

                            <div className="col-lg-12 p-0">
                                    <AuthorDetails data={data} type="shared" />
                                    <div className="col-lg-3 p-0 float-right text-right">
                                    <CardActions data={data}/>
                                    </div>
                                <div className="clearfix"></div>

                                <div className="col-lg-12 p-0 m-t-20">
                                <hr/>
                                        <AuthorDetails data={data}/>
                                </div>
                                <div className="clearfix"></div>
                        </div>
                       :
                            <div className="col-lg-12 p-0">
                                <AuthorDetails data={data}/>
                                <div className="col-lg-3 p-0 float-right text-right">
                                    <CardActions data={data}/>
                                </div>
                            </div>

                    }
                </div>
                    <div className="card-body">
                         <div className="col-lg-5  col-sm-12 p-0 m-a twinImg">
                                <div className="col-lg-5 float-left">
                                    <img src={Helper.dataPath(Helper.isEmpty(data.acquiredCompany && data.acquiredCompany.image)?config.defaultCompanyImage : data.acquiredCompany.image)} className="m-t-10 full-width img-rounded" height="100" width="100"/>
                                </div>
                                <div className="col-lg-1 float-left">
                                    <i className={icon}></i>
                                </div>
                                <div className="col-lg-5 float-left">
                                    <img src={Helper.dataPath(Helper.isEmpty(data.acquiringCompany && data.acquiringCompany.image)?config.defaultCompanyImage  : data.acquiringCompany.image)} className="m-t-10 full-width img-rounded" height="100" width="100"/>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                            <div className="col-lg-12 p-0 m-t-30 text-center">
                                 <h5 className="font-18"><span className="text-info">{Helper.isEmpty(data.acquiredCompany && data.acquiredCompany.name)?"":data.acquiredCompany.name}</span> {info} <span className="text-info">{Helper.isEmpty(data.acquiringCompany && data.acquiringCompany.name)?"":data.acquiringCompany.name}</span>.</h5>
                            </div>
                    </div>
                    <div className="card-body">
                        <div className="col-lg-12 m-a5 text-center">
                                 <Link className="btn btn-info btn-card" to={"/"+data.entityType+'/'+data.uri}>View {btnText} Details</Link>
                        </div>
                        <CommentShareCount data={data} updatedId={this.state.updatedId}/>
                        <CommentShareBtn data={data}  focusInputArea={this.focusInputFiled} updateCounts={this.updateCounts}/>
                        <Comments data={data} enableFocus={this.state.focusInput} />
                    </div>
                </div>
      </div>
    )
  }
}

export default withRouter(MergerCard);
