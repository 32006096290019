import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Helpers from '../../shared/custom';
import Axios from 'axios';
import STR from '../../shared/strings';
const $ = require('jquery');
$.DataTable = require('datatables.net');

class ViewFunding extends Component {

 constructor(props){
    super(props);
    this.state = {
        fundingRounds :[],
        total:0,       
        uri:undefined,
        type:"company",
    }
    this.fetchFundingRounds = this.fetchFundingRounds.bind(this);
    this.renderFundingRoundRow = this.renderFundingRoundRow.bind(this);
 }

 componentDidMount(nextProps){
     this.setState({
         uri:this.props.uri
     },()=>{
        this.fetchFundingRounds();
    })
 }

 componentWillReceiveProps(nextProps){
     let newUri = nextProps.uri;
     let currentUri = this.state.uri;

     if(!currentUri || currentUri != newUri){
         this.setState({
             uri:newUri
         },()=>{
             this.fetchFundingRounds();
         })
     }
 }

 fetchFundingRounds(){
    let uri = this.state.uri;
    if(!uri){
      return false;
    }

    Axios.get(
      Helpers.apiURL({
        uri:"company/:uri/funding-rounds",
        pathVar: {uri:uri}
      })
    )
    .then((response) => {
      let result = response.data.result;
      if(!result){
        //this.handleError(response);
      }else{
        this.setState({
          fundingRounds:result
        }, () => {
            if ($.fn.DataTable.isDataTable(this.refs.fundingTable) === false){
                $(this.refs.fundingTable).DataTable({searching: false, info: false, });
            }
        })
      }
    })
    .catch((response) => {
      //this.handleError(response);
    })
 }

 renderFundingRoundRow(data,index){
    let leadInvestors = [];
    if(data.investorMappings){
        data.investorMappings.forEach(mapping => {
            if(mapping.lead){
                leadInvestors.push(mapping.investor.name);
            }
        });
        leadInvestors = leadInvestors.join(", ");
    }
    return(
        <tr key={"FR-"+index}>
            <td>{Helpers.getDateInInputFormat(data.date)}</td>
            <td>{data.fundingType ? data.fundingType.name : "-"}</td>
            <td>{data.investorMappings ? data.investorMappings.length : "-"}</td>
            <td>{data.fundingStage? data.fundingStage.name: "-"}</td>
            <td>{data.preMoneyValuation ? Helpers.displayAmount({amount: data.preMoneyValuation, currency: data.currency}) : STR.UNDISCLOSED}</td>
            <td>{data.amountRaised? Helpers.displayAmount({amount: data.amountRaised, currency: data.currency}) : STR.UNDISCLOSED }</td>
            <td>{data.equityOnlyFunding? STR.YES : STR.NO }</td>
            <td>{leadInvestors}
            </td>
        </tr>
    )
    
 }

 render() {
    let uri = this.state.uri;     
    let fundingRounds = this.state.fundingRounds;
    return (
      <div>
        <div className="card text-left bold-headers" id="funding">                        
            <div className="card-body">
                
                <div className="row m-b-10">
                    <div className="col-md-10 float-left">
                        <h5 className="m-t-0">Funding</h5>
                    </div>
                    <div className="col-md-2 text-right">
                        <Link to={"/financials/"+uri}>
                            <span data-type="ipo" className="text-info point">Edit</span>
                        </Link>
                        
                    </div>
                </div>
                
                <div className="row ">
                    <div className="col-md-6 float-left">
                        <div className="col-md-12 box float-left">
                            <div className="col-md-8 float-left topLabel">Number of Funding Rounds </div>
                            <div className="col-md-4 float-left font-bold font-14 text-right">{this.state.fundingRounds.length}</div>
                        </div>
                    </div>
                    <div className="col-md-6 float-left">
                        <div className="col-md-12 box float-left">
                            <div className="col-md-8 float-left topLabel">Total Funding </div>
                            <div className="col-md-4 float-left font-bold font-14 text-right">{"-"}</div>
                        </div>
                    </div>
                </div>
                <h6>Funding Rounds</h6>
                <div className="table-responsive">
                        <table ref="fundingTable" className="table color-table muted-table dataTable">
                            <thead>
                                <tr>
                                    <th>Announced Date</th>
                                    <th>Funding Type</th>
                                    <th>No. of Investors</th>
                                    <th>Funding Stage</th>                                    
                                    <th>Pre Money Valuation</th>
                                    <th>Money Raised</th>
                                    <th>Equity Only</th>
                                    <th>Lead Investors</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fundingRounds.map((data,index)=>(
                                    this.renderFundingRoundRow(data,index)
                                ))}                                            
                            </tbody>
                        </table>
                    </div>
                </div>                                
        </div>
      </div>
    )
  }
}
export default (withRouter(ViewFunding));