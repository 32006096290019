import React, { Component } from 'react';
import BoxSlider from "./BoxSlider"; 
import Axios from 'axios';
import Helper from "../../shared/custom";

class Similar extends Component {
    constructor(props){
        super(props);
        this.state ={
            similar:[],
            scrollItems:1,
            localprops:{}
        }
        this.fetchSimilar = this.fetchSimilar.bind(this);
        this.fetchRelated = this.fetchRelated.bind(this);
    }

    componentDidMount(){
       
    }

    componentWillReceiveProps(props){
        if(JSON.stringify(props) != JSON.stringify(this.state.localprops)){
            this.setState({
                localprops:props
            })
            let uri = props.uri;
            let industries = props.industries;
            let page = props.page;        
            if(!Helper.isEmpty(page) && !Helper.isEmpty(industries)){
                this.fetchRelated(industries);
            }else{
                 this.fetchSimilar(uri);
            }

        }
    }

    fetchRelated(industries){
        Axios.get(
            Helper.apiURL({
                uri:"advance-search?searchType=EVENT&industry="+industries,
            })
        )
        .then( (response) => {     
            let similar = response.data && response.data.result ? response.data.result :  response.data.length ? response.data : [];
            this.setState({
                similar:similar
            })
        })
        .catch((err) => {
            this.setState({
                similar:[]
            })
        });
    }

    fetchSimilar(uri){
        Axios.get(
            Helper.apiURL({
                uri:uri+"/similar",
            })
        )
        .then( (response) => {     
            let similar = response.data && response.data.result ? response.data.result : response.data.length ? response.data :  [];
            this.setState({
                similar:similar
            })
        })
        .catch((err) => {
            this.setState({
                similar:[]
            })
        });
    }

  render() {
    let similar = this.state.similar;
    let type  = this.props.type;
    let title = this.props.title;
    let uri = this.props.uri;
    let scrollItems = this.state.scrollItems;
    let items = this.props.items;
    let page = this.state.page;
    return (
        <>
        {!Helper.isEmpty(similar) && 
      <div>
          <BoxSlider items={scrollItems} title={title} type={type} dataset={similar} uri={uri} key={uri} items={items} page={page}/>
      </div>
  }
      </>
    )
  }
}
export default  Similar;