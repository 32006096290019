import React, { Component } from 'react';
import OwlCarousel, { Options } from 'react-owl-carousel';
import OppCard from "./OppCard";
import Helpers from '../../shared/custom';

export default class OppCarousal extends Component {
    constructor(){
        super();
        this.state ={
            scrollItems:2,
            localProps:{},
            options: {
                loop: false,
                margin: 10,
                nav:true,
                autoplay:true,
                autoplaySpeed:400,
                items:2,
              }
        }
       
    }
    componentWillReceiveProps(props){

        if(JSON.stringify(props) != JSON.stringify(this.state.localProps)){
          this.setState({
            localProps:props,
            scrollItems:props.items,
          })
        }
    }

    componentDidMount(){
      if(Helpers.isMobile()){
        this.setState({
          scrollItems :1
        })
      }
    }
  render() {
    let title = this.props.title;  
    let dataset = this.props.data;
    let keyName = this.props.keyName;
    let scrollItems = this.state.scrollItems;
    return (
      <div>
        <div className="card">
            <div className="card-body">
                <h4 className="font-bold text-left">{title}</h4>
                <br/>
                {(Helpers.isEmpty(dataset)) || (dataset.length ==  0 )?
                     <div className="text-center">No Data Available</div>
                    :
                    <OwlCarousel  key={keyName+"car"} className="owl-theme" items={scrollItems} autoplay="false" loop={dataset.length>3 ? true :false}  margin={10} autoWidth={false} nav={true} option={this.state.options} >
                            {dataset.map((data,index)=>(
                                <OppCard data={data} keyName={keyName}/>
                            ))}  
                    </OwlCarousel>
                }
               
            </div>
        </div>
    </div>
    )
  }
}
