import React, { Component } from 'react';
import config from '../../shared/config';
import Helper from '../../shared/custom';
import Axios from 'axios';
import { BrowserRouter as Router, Route, Link, withRouter } from "react-router-dom";
import { logoutUser } from '../../actions/authAction';
import { connect } from "react-redux";
import $ from 'jquery';

class TopNotification extends Component {
    constructor(props) {
        super(props);
        this.state = {
          notifications:[],
          total:0,
          read:false,
          showHearBeat:false,
          opened:false
      }
      this.handleRead = this.handleRead.bind(this);
      this.openNotification = this.openNotification.bind(this);
  }  
  componentDidMount(){
        //     this.setState({
        //         opened: localStorage.getItem("notiOpen")
        // })
        this.getNotifications();
        setInterval(()=>{
            this.getNotifications();
        }, 30000);
       
 }

 componentWillReceiveProps(props){
    // this.setState({
    //     opened: localStorage.getItem("notiOpen")
    // })
 }





  getNotifications(){
    Axios.get(
        Helper.apiURL({
            uri:"user/notifications",
        })
       )
        .then( (response) => {
            if(response.data){
                this.checkSessionValidity(response.data.status)
            }else{
                this.checkSessionValidity(response.status)
             }

        let data = response.data.result;
        let entries = data.entries;
        let total = data.totalCount;
        // let allTotal =  localStorage.getItem("totalNoti");
        // let notiOpen = localStorage.getItem("notiOpen");

        let unread = entries.filter((item)=>!item.read);

        if(!Helper.isEmpty(unread)){
            this.setState({
                showHearBeat:true,
                opened:false,
            })
        }else{
            this.setState({
                showHearBeat:false,
            })
        }
        this.setState({
            notifications : entries,
            total:total
        });
        
    })
    .catch((err) => { 
        if(err.response) this.checkSessionValidity(err.response.status);
    });
  }

    checkSessionValidity = (errorCode) => {
        if (errorCode && (parseInt(errorCode) === 401 || parseInt(errorCode) === 400)) {
            this.props.logoutUser();
            this.props.history.push({pathname: "/auth" , activeTab: "login"});
        //Helper.pushTError("");
    }
  }


  openNotification(e){
     e.preventDefault();
     this.setState(prevState => ({
         showHearBeat:false,
         opened:!prevState.opened,
     }));
   }

  handleDelete(e){
         e.stopPropagation();
         let id = e.currentTarget.attributes.getNamedItem('data-id').value;
   
        Axios.delete(
            Helper.apiURL({ 
                uri: `user/notification/${id}`
            }),{
                headers: { 'Content-Type': 'text/plain' }
              }
        ).then(response => {
               document.getElementById(`${id}-div`).remove();
            })
            .catch(err => {
                Helper.pushError("Cannot delete at the moment!");
            });
  }



  handleRead(e,index){
        this.setState(prevState => ({
            showHearBeat:false,
            opened:!prevState.opened,
        }))
        let payload = {};
        let id = e.currentTarget.attributes.getNamedItem('data-id').value;
        let category = e.currentTarget.attributes.getNamedItem('data-category').value;
        let uri = e.currentTarget.attributes.getNamedItem('data-uri') && e.currentTarget.attributes.getNamedItem('data-uri').value;
        uri= !Helper.isEmpty(uri) ?  Helper.lastSegment(uri) : "";
        let feedUniqueId =  e.currentTarget.attributes.getNamedItem('data-feeduniqueid') ? e.currentTarget.attributes.getNamedItem('data-feeduniqueid').value : "";
       
        payload.isRead = true;
        payload.id = id;
        Axios.put(
        Helper.apiURL({
          uri: "user/notification/mark",
        }),
        payload
       )
        .then(response => {
          this.setState(prevState=>{
                let notifications = prevState.notifications;
                notifications[index]["read"] = true;
                return {notifications}
          },()=>{
            if(!Helper.isEmpty(feedUniqueId)){
                this.props.history.push("/feed/details/"+feedUniqueId);
            }else{
                this.props.history.push("/"+category+"/"+uri);
            }
          });
          
        })
        .catch(err => {
            if(!Helper.isEmpty(feedUniqueId)){
                this.props.history.push("/feed/details"+feedUniqueId);
            }else{
                this.props.history.push("/"+category+"/"+uri);
            }
        });
  }
  render() {
    let notifications = this.state.notifications;
    let showHearBeat = this.state.showHearBeat;
    let bellClass = showHearBeat ? "ringing-bell mdi mdi-bell-ring font-24" : "mdi mdi-bell";
    
    return (
        <ul className="navbar-nav mr-auto mt-md-0 navUl m-r-0-i">
           
           <li className={"nav-item dropdown " + (this.state.opened ? " show" : "" )} >
                <a onClick={(e)=>this.openNotification(e)} className="nav-link dropdown-toggle text-blue waves-effect waves-dark grid-div" href="" id="1" > <i className={bellClass} ></i>
                   {/* {showHearBeat ? <div className="notify"> <span className="heartbit"></span> <span className="point"></span></div> : ""}   */}
                   {/* <span className={"text-md font-medium font-14 text-desktop topColor"}>Notifications</span> */}
                </a>

               { this.state.opened &&   <div className="dropdown-menu mailbox notification dropdown-menu-right right-dd scale-up p-0 m-t-0 show" aria-labelledby="1">
                    <div className="arrow-up"></div>
                        <ul>
                            <li className="list-header">
                                <div className="drop-title text-16 text-white">Notifications</div>
                            </li>
                            <li>
                                <div className="message-center">
                                    {!Helper.isEmpty(notifications) ?
                                        notifications.map((data, index) => (
                                            <React.Fragment>
                                                <a style={{
                                                    backgroundColor: !data.read  ? '#edf7f0' : '#fff',
                                                    borderLeft : !data.read ? '2px solid #359f55' : 'none',
                                                    paddingInline: '20px',
                                                    paddingBlock: '15px'
                                                }} href="javascript:void(0)" data-category={data.category} data-uri={data.uri} data-id={data.id}  data-feeduniqueid={data.feedUniqueId} key={"notify" + index} id={data.id+'-div'} className={data.read ? "notiLink" : "notif-is-unread notiLink"} onClick={(e)=>this.handleRead(e,index)} >
                                                    <span className="noti-close no-default" data-id={data.id}  data-feeduniqueid={data.feedUniqueId}  onClick={this.handleDelete} >
                                                        <i className="fa fa-times"></i>
                                                    </span>
                                                    <div className="img-circle-noti">
                                                        <img src={!Helper.isEmpty(data.image) ? Helper.dataPath(data.image) : config.cdn + 'theme/images/notification.png'} className={!Helper.isEmpty(data.image) ? "original img-circle" : "default"} />
                                                    </div>
                                                    <div className="mail-contnet">
                                                        <h5 className="font-14 font-normal" style={{fontSize :'13px !important'}}>
                                                            {/* Tesla <span style={{fontWeight : 400}}>has an update.</span> */}
                                                            {!Helper.isEmpty(data.title) ? data.title : ""}
                                                            </h5>
                                                    </div>
                                                    <div className="noti-time">
                                                        <h5 style={{fontWeight : 300 , fontSize : '11px' , color : 'silver'}} className="font-14 font-normal"><i className="far fa-clock"></i>  {!Helper.isEmpty(data.date) ? Helper.getDateInAgoFormat(data.date, "twitter") : ""}</h5>
                                                    </div>
                                                </a>
                                            </React.Fragment>
                                        ))
                                        :
                                        <div className="text-center p-2">
                                            No Notifications
                                        </div>
                                    }
                                </div>
                            </li>
                            <li>
                                <a className="nav-link notification-padding font-14  link-color text-center" href="/notifications"> See all Notifications&nbsp;&nbsp;&nbsp;<i className="fa fa-angle-right"></i> </a>
                            </li>
                        </ul>
                    </div>}
                </li>
            </ul>
        )
    }
}

export default connect(null,{logoutUser})(withRouter(TopNotification));
