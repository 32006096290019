import React, { Component } from 'react';
import config from '../../shared/config';
import { getMetaData } from '../../actions/metaAction';
import { getSectorData } from '../../actions/businessAction';
import { connect } from 'react-redux';
import store from '../../store';
import Helper from '../../shared/custom';
import Axios from 'axios';

class Sectors extends Component {

   constructor(props){
      super(props);
      this.state ={
         sectors:[],
         selectedSectors:[],
         interestAdded:false,
         selectedCount:null,
         localBusiness:{}
      }

      this.handleSector =  this.handleSector.bind(this);
      this.onCheck = this.onCheck.bind(this);

      store.subscribe(() => { 

         var storeState = store.getState();       
         let storeBusiness = storeState.business;
         let localBusiness = this.state.localBusiness;

         if(JSON.stringify(localBusiness) != JSON.stringify(storeBusiness)){
            let storeSectorData = storeBusiness.data.sectorsArray;

            if(!Helper.isEmpty(storeSectorData)){
               this.setState({
                  sectors : storeSectorData,
                  interestAdded: storeState.meta.interestAdded
               }) 
            }

         }     

     }); 
   }

   onCheck(e){

      const selectedSectors = this.state.selectedSectors;
      let index;
      
      if (e.target.checked) {
         selectedSectors.push(+e.target.value)
       } else {
         index = selectedSectors.indexOf(+e.target.value)
         selectedSectors.splice(index, 1)
       }
       this.setState({ selectedSectors: selectedSectors },()=>{
          
       });

   }

  

   componentDidMount(){
      this.props.getMetaData();
      this.props.getSectorData();
   }
  

   handleSector(e){
      e.preventDefault();
      var selectedSectors = this.state.selectedSectors;
      this.setState({
         selectedCount:selectedSectors.length
      })
      if(selectedSectors.length < 3){
         Helper.pushError("Please select atleast 3 sectors to proceed");
         return false;
      }
   
      let selectedData = {
         objectType : "SECTOR",
         objectIds : selectedSectors,
         interested:true,
      }
      if(!Helper.isEmpty(this.state.selectedSectors)){
        // this.props.setSector(selectedData);
        Axios.post(
         config.api("user-interests"),
         selectedData)
            .then((response) => {
               this.props.history.push({
                  pathname:'/register/follow',
                  state:{sectorIds: this.state.selectedSectors}
               });
            })
            .catch((err) => {
               Helper.pushTError(err)
            });
      }
   }
  

  render() {
    let sectors = this.state.sectors;

    return (
      <div>
        <div className="grid-select">
            <div className="row p-20">
               <div className="col-lg-12 col-md-12 text-left font-bold">
               <h1 className="col-md-9 float-left font-24">Start off by selecting all the sectors that interest you. Please select at least 3 to continue.</h1>
                {this.state.selectedSectors.length < 3 ?
                     <button className="float-right m-r-20 btn waves-effect waves-light btn-theme-white btn-custom-md font-16">Select 3 or more to continue</button>
                     :
                     <button className="float-right m-r-20 btn waves-effect waves-light btn-info btn-custom-md font-16" onClick={this.handleSector}>Continue</button>
                }
               
               </div>
            </div>
            <div className="row p-20" id="sectors">
                  {sectors.map((sector, index) => (
                    <div className="col-lg-3 col-xlg-3 col-md-6 col-sm-12 checkboxHolder" key={index}>
                     <input type="checkbox" name={sector.id} id={sector.id+'id'} value={sector.id} className="hidden" onChange={this.onCheck}/>
                     <label className="m-b-20 col-md-12 p-0 imageHolder font-bold" htmlFor={sector.id+'id'}>
                        <div className="overlay">
                           <img src={Helper.dataPath(sector.image)}  className="img-responsive radius small-img" />
                           <div className="centered">{sector.name}</div>
                        </div>
                    </label>
                   </div>
                  ))}
              </div>
            </div>
        </div>
    )
  }
}

const mapStateToProps = (state) =>({
   meta:state.metaList,
});

export default connect(mapStateToProps,{getMetaData ,getSectorData})(Sectors);