

import React, { Component } from "react";
import ViewSDKClient from "./ViewSDKClient";
import Sidemenu from '../sidemenu/Sidemenu';
import UserFeedRightPanel from '../homepage/UserFeedRightPanel';

class Poc extends Component {
    componentDidMount() {
        const viewSDKClient = new ViewSDKClient();
        viewSDKClient.ready().then(() => {
            /* Invoke file preview */
            viewSDKClient.previewFile("pdf-div", {
                /* Pass the embed mode option here */
                embedMode: "IN_LINE"
            });
        });
    }

    render() {
        return (
            // <div>
            //      <div className="container-fluid">
            //          <div className="row content-in m-t-80 p-t-10">
            //          <div className="in-line-container full-width">
            //                 <div id="pdf-div" className="in-line-div"/>
            //             </div>
            //         </div>
            //      </div>
                 
            //  </div>
     
            <div className="container-fluid">
                <div className="row content-in m-t-80 p-t-10">
                    <div className="col-lg-9 col-xlg-9 col-md-9 centerDiv">
                        <div className="in-line-container full-width">
                            <div id="pdf-div" className="in-line-div"/>
                        </div>
                    </div>
                    <div className="col-lg-3 col-xlg-3 col-md-3 m-t-2 newsRightPanel right-side">
                        <UserFeedRightPanel />
                    </div>
                </div>
            </div>
        );
    }
}

export default Poc;