import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Helpers from '../../../shared/custom';

function Upgrade({title}) {
    return (
        // <div className="card">
            <div className="card-body notUser">
                <h5 className="text-black">{title}</h5>
                    <div className="text-center col-8 m-a text-black m-t-40">
                        <div>
                            <i className="fa fa-lock login-icon"></i>
                        </div>
                        <div>
                            <h2 className="text-black">Upgrade your subscription to view the information.</h2>
                            <Link to={Helpers.upgradeUrl()} className="btn btn-info full-width">
                                Upgrade
                            </Link>
                            
                        </div>              
                    </div>
                </div>
        // </div>
        
    )
}

export default Upgrade
