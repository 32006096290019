import React from 'react';
import MiniCard from "../ui/MiniCard";
import Helpers from "../../shared/custom";


function VerificationDetails({data , isOwner}) {

    //let user = JSON.parse(localStorage.userInfo);
    return (
        <div className="card bold-headers" id="verificationDetails">
             <div className="card-header text-left light-blue-header">
                 <h5 className="m-b-0 m-t-0 text-white">Verified by Investera Plus Africa</h5>
            </div>
            <div className="card-body text-left">
                         {/* {!Helpers.isEmpty(data && data.approvalDate) &&  */}
                            
                            <MiniCard label="Verification Date"
                                type="left"
                                view="full"
                                value={
                                    data && data.approvalDate  ?  Helpers.getDateInInputFormat(new Date(data.approvalDate)) : "-"
                                }
                            />
                         {/* } */}
                        {!Helpers.isEmpty(data && data.reportLinks) && isOwner ? 
                            data.reportLinks.map((links,i)=>(
                                <MiniCard label="Verification Report"
                                type="link"
                                show={isOwner}
                                view="full"
                                    value={
                                        links
                                    }
                                />
                            ))
                            :
                            <MiniCard label="Verification Report"
                                type="left"
                                view="full"
                                pophover={!isOwner}
                                target={"reportLinks"}
                                value={
                                    "-"
                                }
                            />
                         }
                          {!Helpers.isEmpty(data && data.accManagerName)  &&  isOwner? 
                            <MiniCard label="Account Manager"
                                show={isOwner}
                                type="canHide"
                                view="full"
                                value={
                                    data.accManagerName
                                }
                            />
                            :
                            <MiniCard label="Account Manager"
                                type="left"
                                view="full"
                                pophover={!isOwner}
                                target={"accManager"}
                                value={
                                    "-"
                                }
                            />
                        }
                         
            </div>
            
        </div>
    )
}

export default VerificationDetails;
