import React from "react";
import ReactGA from 'react-ga';
// ReactGA.initialize('AW-324604381');

const GAEventsTracker = (category="category") => {
  
    const trackEvent = (action="action", label="label")=>{
        console.log(category, action, label,"category, action, label")
        ReactGA.event({
            category: category,
            action: action,
            label: label
          });
    }
    return trackEvent;
}

export default GAEventsTracker