import React, { Component } from 'react';
import config from '../../shared/config';
import { connect } from 'react-redux';
import store from '../../store';
import Helper from '../../shared/custom';
import { withRouter, Redirect ,Link} from 'react-router-dom';
import {Helmet} from "react-helmet";
import Axios from 'axios';


import PublicReport from "./PublicReport";
import UserReport from "./UserReport";
import UserFeedRightPanel from "../homepage/UserFeedRightPanel";
import Sidemenu from '../sidemenu/Sidemenu';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ReportMeta } from "../../shared/MetaHelper";
import { getPackageDetails } from '../../actions/packageAction';





class ViewReport extends Component {
    constructor(props){
        super(props);
        
        this.state ={
            loggedIn:false,
            editorSummaryState:null,
            editorBodyState:null,
            link:"",
            fullData:null,
            pdfLink:"",
            privileges:null
        }

           
        store.subscribe(() => {   
            var storeState = store.getState();     
            this.setState({loggedIn:storeState.auth.isAuthenticated});
        }); 
        
        
    }
    editorBodyRef = React.createRef();
    editorSummaryRef = React.createRef();  
    

    componentDidMount(){
        var storeState = store.getState();  
        this.setState({
            loggedIn:storeState.auth.isAuthenticated,
        },()=>{
            if(this.state.loggedIn && this.props.getPackageDetails){
                this.props.getPackageDetails();
            }
        })   
        if(this.editorBodyRef.current){
            this.editorBodyRef.current.editor.enable(false);
        }
        if(this.editorSummaryRef.current){
            this.editorSummaryRef.current.editor.enable(false)
        }
        this.fetchReport();
    }

    componentWillReceiveProps(props){
        var storeState = store.getState();  
        if(JSON.stringify(props) != JSON.stringify(this.state.localProps)){
            
            let privileges = [];
            if(storeState && storeState.packageDetails && storeState.packageDetails.packageDetails && storeState.packageDetails.packageDetails.privileges){
                let packageDetails = storeState.packageDetails.packageDetails.privileges;
                 privileges = packageDetails.map((x)=>x.featureCode);
            }
            this.setState({
                loggedIn:storeState.auth.isAuthenticated,
                localProps:storeState,
                privileges:privileges,
            },()=>{
                if(this.state.loggedIn && Helper.isEmpty(privileges)){
                    this.props.getPackageDetails();
                }
            })
        }
        
    }
    componentWillUnmount(){
        localStorage.removeItem('referal');
    }
    fetchReport  = ()=>{
        const { uri } = this.props.match.params;
        Axios.get(Helper.apiURL({ uri: "report/"+uri })).then(response=>{
            if(response.data && response.data.result){
                this.setState({
                    editorSummaryState:response.data.result.reportSummary,
                    editorBodyState:response.data.result.reportBody,
                    link: response.data.result.reportUri,
                    pdfLink:response.data.result.linkPDF,
                    fullData:response.data.result,
                })
            }
        });
    }

    render() {
        

        let loggedIn = this.state.loggedIn;
        let isMobile = Helper.isMobile();

       

        let editorSummaryState = this.state.editorSummaryState ;
        let editorBodyState = this.state.editorBodyState ;
        let link = this.state.link;
        let fullData = this.state.fullData;
        let pdfLink = this.state.pdfLink;
        //this.editorBodyRef.current.editor.enable(false);
        //this.editorBodyRef.current.editor.enable(false);
        let privileges = this.state.privileges;
        let upgradeUrl = Helper.upgradeUrl();

      
     
        if(!loggedIn){
            localStorage.setItem("referal","/report/view/"+link);
        }else {
            localStorage.removeItem('referal');
        }
        return (
            <div className="m-t-80">
                <ReportMeta
                    entityUri={fullData?fullData.reportTitle:""}
                    description={fullData?fullData.reportTitle:""}
                    image= {""}
                />
               <div className="row content-in m-t-80 p-t-10">
                <div className="col-lg-9 col-xlg-9 col-md-9 centerDiv"> 
                    <div className="card text-left b-header">
                        <div className="card-header text-left p-0">
                            <div className="col-md-6 float-left text-right">
                            </div> 
                        </div>
                        <div className="card-body companies blue-border">
                            <div className="col-md-12 p-0">
                                <div className="col-12">
                                    <div className="pdfHeader">
                                        {Helper.isEmpty(fullData) ? "" : <h5>{fullData ?fullData.reportTitle : ""}</h5>}
                                        {Helper.isEmpty(fullData) ? "" : <h6 className="font-medium font-12">{fullData.reportCreationDate}</h6>}
                                    </div>
                                    <div className="editor-nobg">
                                        <ReactQuill 
                                        theme="snow" 
                                        ref={this.editorSummaryRef}
                                        value={editorSummaryState}
                                        modules = {{toolbar: false}}
                                        />
                                    </div>
                                </div>
                                {loggedIn ? <div className="col-12">
                                                <div className="editor-nobg">
                                                    <ReactQuill 
                                                    theme="snow" 
                                                    ref={this.editorBodyRef}
                                                    value={editorBodyState}
                                                    modules = {{toolbar: false}}
                                                    /> 
                                                </div>
                                            </div>
                                        :""
                                }
                                <div className="text-center">
                               
                                {   
                                    loggedIn?<div className="col-12 d-flex justify-content-center m-t-20 clearfix">
                                                 { privileges && privileges.includes("RPTS") ? 
                                                    <a href={pdfLink} className="btn btn-action" target="_blank" download="">Download the full report</a>
                                                    :
                                                    <a href={upgradeUrl} className="btn btn-action">Download the full report</a>
                                                 }
                                            </div>
                                    :
                                    <Link to={"/auth"} className="text-center font-14 btn btn-action">Please login to read full PDF</Link> 
                                }
                                
                                </div>
                            </div>
                        </div>
                    </div>

                     </div>
                    <div className="col-lg-3 col-xlg-3 col-md-2 m-t-2 userProfileRight right-side">
                        <UserFeedRightPanel />
                    </div>
                  </div>

          
             
            </div> 
        )
    }
}



// export default connect(null)(withRouter(ViewReport));


const mapStateToProps = (state) =>({
    auth:state.auth,
    packageDetails:state.packageDetails,
    errors:state.errors
});

export default connect(mapStateToProps,{getPackageDetails})(withRouter(ViewReport));