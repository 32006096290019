import React, { Component } from 'react';
import config from '../../shared/config';
import Helper from '../../shared/custom';
import Axios from 'axios';
import store from '../../store';
import { withRouter, Redirect , Link} from 'react-router-dom';

class TrendingNews extends Component {
    constructor(props){
        super(props);
        this.state = {
            news : [],
        }
        this.fetchNews = this.fetchNews.bind(this);
    }

    componentDidMount(){       
        this.fetchNews();
    }

    

    fetchNews(){
        Axios.get(
            Helper.apiURL({
                uri:"news/trending",
            })
        )
        .then( (response) => {     
            var news = response.data.result || [];
            this.setState({
                news:news
            })
        })
        .catch((err) => {
            
        });
    }
    addDefaultNewsImage(ev){
        ev.target.src = Helper.dataPath("default/news-default-image.png");
    }
  render() {
    let news = this.state.news;
    let newsLength = news.length;
    return (
        <div>
          <div className="card text-left bold-headers">
                <div className="card-header text-left light-blue-header">
                    <h5 className="m-b-0 m-t-0 text-white">Trending News</h5>
                </div>
                <div className="card-body p-t-2 font-14 trendingBody">
                   <div className="profiletimeline m-0 ">
                   <div>
                    {news.map((news,i)=>(
                        <div className="sl-item" key={i}>
                            <div className="sl-right">
                            <div className="row">
                                <div className="col-md-4 col-xs-12 p-r-0 newsImgContainer">
                                     <Link to={{pathname: "/news/"+news.uri , "parentUrl":this.props && this.props.parentUrl?this.props.parentUrl :"" }}>
                                       <img onError={this.addDefaultNewsImage} src={!Helper.isEmpty(news.image) ? news.image : Helper.dataPath(config.defaultNewsIcon)} alt="user" className="img-responsive" />
                                     </Link>
                               </div>
                                <div className="col-md-8 col-xs-12 font-12">
                                   <a rel="noreferrer" href={Helper.properExternalUrl(news.url)} target="_blank" className="m-0 font-12">{Helper.extractHostname(news.url)}</a>
                                   <Link to={{pathname: "/news/"+news.uri , "parentUrl":this.props && this.props.parentUrl?this.props.parentUrl :"" }}>
                                        <h6 className="font-medium font-12 m-b-5 m-t-0">{Helper.truncate(news.headLine,25)}</h6> 
                                   </Link>
                                   <p className="font-12 l-h-12 m-t-5">{Helper.truncate(news.description,60)}</p>
                                </div>
                            </div>
                            </div>
                            <hr/>
                        </div>
                    ))
                    }
                    </div>
                    </div>
                </div>
            </div>
      </div>
    
    )
  }
}

export default withRouter(TrendingNews)
