import React, { Component } from 'react';
import Footer from './Footer';
import config from '../../shared/config';
import MapContainer from "../../shared/Map";
import Helper from "../../shared/custom";
import Axios from 'axios';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import CountryCode from "../wigets/CountryCode";


export class Contact extends Component {
    constructor(props){
        super(props);

        this.state = {
            firstName:"",
            lastName:"",
            country:"",
            region:"",
            mobile:"",
            ipa:"",
            email:"",
            type:"Individual",
            company:"",
            position:"",
            comments:"",
            hearAboutUs:"",
            loading:false,
            errors:{},
            ext:"+254",
            cn:"KE",

        }
    }

    onChange = (e)=>{
        this.setState({
            [e.target.name] : e.target.value,
        })
    }

    onChangeExt = (code,cn) =>{
        this.setState({
            ext:code,
            cn:cn
        })
    }


    componentDidMount(){
        window.scrollTo(0, 0)
    }


    handleValidation(){
        let { firstName , lastName, country , region, ext , mobile  , email , type , company, position, comments ,hearAboutUs ,cn} = this.state;
        let errors = {};
        let formIsValid = true;
    
       
        if(!firstName){
          formIsValid = false;
          errors["firstName"] = "Cannot be empty";
        }

        if(!lastName){
            formIsValid = false;
            errors["lastName"] = "Cannot be empty";
        }

        if(!country){
            formIsValid = false;
            errors["country"] = "Cannot be empty";
            }

        if(!region){
            formIsValid = false;
            errors["region"] = "Cannot be empty";
        }

        if(!ext){
            formIsValid = false;
            errors["ext"] = "Cannot be empty";
        }

        if(Helper.isEmpty(cn)){
            formIsValid = false; 
            errors["ext"]= "Please enter a valid country code";
        }else{
            errors["ext"]= "";
        }


        if(!mobile){
            formIsValid = false;
            errors["mobile"] = "Cannot be empty";
        
        }

        if(!email){
            formIsValid = false;
            errors["email"] = "Cannot be empty";
        }

        if(!Helper.isValidEmail(email)){
            formIsValid = false;
            errors["email"] = "Please enter a valid email address";
        }

        if(!comments){
        formIsValid = false;
        errors["comments"] = "Cannot be empty";
        }

    
        this.setState({errors: errors});
        return formIsValid;
      }


    sendMail = (e)=>{
        e.preventDefault()
        var payload = {};

        let { firstName , lastName, region, ext ,country , mobile , ipa , email , type , company, position, comments ,hearAboutUs } = this.state;

        var isFormValid = this.handleValidation();


        if(!isFormValid){
            Helper.pushError("Please fill all the required fields.");
            return false;
        }
        

        var body =  `First Name : ${firstName}<br />
                     Last Name : ${lastName}<br />
                     Country : ${country}<br />
                     Region : ${region}<br />
                     Mobile No : ${ext+' -'+mobile}<br />
                     IPA No : ${ipa}<br />
                     Email : ${email}<br />
                     Company Type : ${type}<br />
                     Company / Business Name : ${company}<br />
                     Position : ${position}<br />
                     Comments : ${comments}<br />
                     How did you hear about us? : ${hearAboutUs}<br />
                     `
 


                     var url = "https://assets.investera.com/mail-proxy/send.php?from=alerts@investera.com&to=hassan.ghoul@investera.com,mutunga@investeraplusafrica.com&subject=Investeraplus.africa&body=" + encodeURIComponent(body);
        
        this.setState({loading:true})
         Axios.get(url) 
            .then( (response) => {     
                Helper.pushSuccess("Thank you for your interest, we will come back to you soon.");
                this.setState({
                    firstName:"",
                    lastName:"",
                    region:"",
                    country:"",
                    ext:"",
                    mobile:"",
                    ipa:"",
                    email:"",
                    type:"Individual",
                    company:"",
                    position:"",
                    comments:"",
                    hearAboutUs:"",
                    loading:false,
                })
            })
            .catch((err) => { 
                Helper.pushError("Please contact our adminstrator!");
                this.setState({
                    loading:false,
                })
            });

    }

    render() {
        const lat = "-1.2607937418653115";
        const lng =  "36.804522644049825";
        let { loading ,errors} = this.state;
        let { from , ref}  = this.props;
        
        return (
            <React.Fragment>
                <div className="new_home" id="contact" ref={ref}>
                <Loader loading={loading} fullPage></Loader>
                <div className="container contact-container">
                    <div className="row rowed text-left" >
                        <div className="col-md-12">
                              <h2 className="h2 custom-heading ml-0">Get in Touch</h2>
                              <p className="mt-5 u-heading">Contact our team</p>
                            
                             <div className="row form-container">
                                <form className="col-md-12">
                                    <div className="col-md-6 float-left">
                                    <div className="form-group  float-left full-width">
                                        <div className="col-md-12 p-0 float-left">
                                            <div className="col-md-6 p-0 float-left">
                                                <label htmlFor="firstName">First Name*</label>
                                                <input type="text" 
                                                        className="form-control" 
                                                        id="firstName" 
                                                        name="firstName"
                                                        aria-describedby="firstName" 
                                                        placeholder="First Name*" 
                                                        onChange={this.onChange}
                                                        value={this.state.firstName}/>
                                                        <small className="text-danger">{errors["firstName"]}</small>
                                            </div>
                                            <div className="col-md-6  p-r-0 m-p-0 float-left paralelInput">
                                                <label htmlFor="lastName">Last Name*</label>
                                                <input type="text" 
                                                        className="form-control" 
                                                        id="lastName" 
                                                        name="lastName"
                                                        aria-describedby="lastName" 
                                                        placeholder="Last Name*" 
                                                        onChange={this.onChange}
                                                        value={this.state.lastName}/>
                                                <small className="text-danger">{errors["lastName"]}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="country">Country*</label>
                                        <input type="text" 
                                                className="form-control" 
                                                id="country" 
                                                aria-describedby="country" 
                                                placeholder="Country*"
                                                name="country"
                                                onChange={this.onChange} 
                                                value={this.state.country}
                                                />
                                        <small className="text-danger">{errors["country"]}</small>
                                    </div>
                                    <div className="form-group">
                                         <label htmlFor="region">Region*</label>
                                        <input type="text" 
                                                className="form-control" 
                                                id="region" 
                                                aria-describedby="region" 
                                                placeholder="Region*"
                                                name="region"
                                                onChange={this.onChange} 
                                                value={this.state.region}
                                                />
                                        <small className="text-danger">{errors["region"]}</small>
                                    </div>

                                    {/* <div className="form-group">
                                        <label htmlFor="company">Company*</label>
                                        <input type="text" 
                                                className="form-control" 
                                                id="company" 
                                                aria-describedby="company" 
                                                placeholder="Company*"
                                                name="company"
                                                onChange={this.onChange} 
                                                value={this.state.company}
                                                />
                                    </div> */}

                                    <div className="form-group  float-left full-width">
                                        <div className="col-md-12 p-0 float-left">
                                            <div className="col-md-4 p-0 float-left mobileCodeDiv">
                                                 <label htmlFor="company">Mobile No*</label>
                                                  <CountryCode name="ext" value={this.state.ext} onChange={this.onChangeExt}/>
                                                  <small className="text-danger">{errors["ext"]}</small>
                                            </div>
                                            <div className="col-md-8 p-r-0 m-p-0 float-left mobileNumberDiv">
                                                <label htmlFor="mobile">&nbsp;</label>
                                                <input type="text" 
                                                        className="form-control" 
                                                        id="mobile" 
                                                        name="mobile"
                                                        aria-describedby="mobile" 
                                                        placeholder="Mobile No*" 
                                                        onChange={this.onChange}
                                                        value={this.state.mobile}/>
                                                 <small className="text-danger">{errors["mobile"]}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="ipa">IPA No</label>
                                        <input type="text" 
                                                className="form-control" 
                                                id="ipa" 
                                                name="ipa"
                                                aria-describedby="ipa" 
                                                placeholder="IPA No" 
                                                onChange={this.onChange}
                                                value={this.state.ipa}
                                                />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Email*</label>
                                        <input type="email" 
                                                className="form-control" 
                                                id="email" 
                                                name="email"
                                                aria-describedby="email" 
                                                placeholder="Email*" 
                                                onChange={this.onChange}
                                                value={this.state.email}
                                                />
                                            <small className="text-danger">{errors["email"]}</small>
                                    </div>
                                    </div>
                                    <div className="col-md-6 float-left">
                                        <div className="form-group">
                                            <label htmlFor="type">How would you classify yourself?</label>
                                            <select className="form-control" 
                                                    id="type" 
                                                    name="type"
                                                    aria-describedby="type" 
                                                    onChange={this.onChange}
                                                    value={this.state.type}
                                                    >
                                                    <option value="Individual">Individual</option>
                                                    <option value="Company">Company</option>
                                                    <option value="Start-up">Start-up</option>
                                                    <option value="Small Business">Small Business</option>
                                                    <option value="Investor">Investor</option> 
                                                    <option value="Government Entity">Government Entity</option>
                                                </select>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="company">Company / Business Name</label>
                                            <input type="text" 
                                                    className="form-control" 
                                                    id="company" 
                                                    aria-describedby="company" 
                                                    placeholder="Company / Business Name"
                                                    name="company"
                                                    onChange={this.onChange} 
                                                    value={this.state.company}
                                                    />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="position">Position</label>
                                            <input type="text" 
                                                    className="form-control" 
                                                    id="position" 
                                                    aria-describedby="position" 
                                                    placeholder="Position"
                                                    name="position"
                                                    onChange={this.onChange} 
                                                    value={this.state.position}
                                                    />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="comments">Comments*</label>
                                            <textarea type="text" 
                                                    className="form-control" 
                                                    id="comments" 
                                                    aria-describedby="comments" 
                                                    name="comments"
                                                    placeholder="Let us know how we can help*" 
                                                    onChange={this.onChange}
                                                    value={this.state.comments}></textarea>
                                                  <small className="text-danger">{errors["comments"]}</small>
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="hearAboutUs">How did you hear about us?</label>
                                            <textarea type="text" 
                                                    className="form-control" 
                                                    id="hearAboutUs" 
                                                    aria-describedby="hearAboutUs" 
                                                    name="hearAboutUs"
                                                    placeholder="How did you hear about us?" 
                                                    onChange={this.onChange}
                                                    value={this.state.hearAboutUs}></textarea>
                                        </div>

                                        
                                        <button  className="btn btn-theme my-2 my-sm-0 float-right" onClick={this.sendMail}>Send</button>
                                    </div>
                                </form>
                             </div>
                             <div className="row address">
                                 <div className="col-md-6">
                                    <h2 className="h2 custom-heading ml-0 p-0">Contact Us</h2> 
                                    <h4 className="mt-5 font-medium font-22">Kenrail Towers, Northern Wing, 7th Floor</h4>
                                    <h4 className="mt-2 font-24">Westlands, Nairobi</h4>
                                    <p className="mt-3 font-20">Kenya.</p>
                                    <p className="mt-1 font-20"><a href="tel:+254 796 000 666">+254 796 000 666</a></p>
                                    <p className="mt-1 font-20"><a href="mailto:info@investeraplusafrica.com">info@investeraplusafrica.com</a></p>
                                    <p className="mt-1 font-20"><a href="https://investeraplus.africa" target="_blank">www.investeraplus.africa</a></p>
                                    
                                 </div>
                                 <div className="col-md-6">
                                        <a href="https://goo.gl/maps/cY2bAFagVxaSbHeR6" target="_blank">
                                            <img src={config.dataCdnStatic + "map.png"} alt={"map"} className="img-responsive"/>
                                        </a>
                                 </div>
                             </div>


                             {/* <h2 className="h2 custom-heading ml-0">Get in Touch</h2> */}
                        </div>
                    </div>
                </div>
                {from != "home" && <Footer/>}
                </div>
            </React.Fragment>
        )
    }
}

export default Contact
